<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h4 class="modal-title" [translate]="'generateAbdes.title'" [translateParams]="{abonoDesconto: titleAbnDesc}"></h4>
  &nbsp;&#8209;&nbsp;
  <h4 *ngIf="!tipoConfigAbDescPreConfigurado" class="modal-title" [translate]="'abdes.btn.aPartirDeExistente' | translate | lowercase"></h4>
  <h4 *ngIf="tipoConfigAbDescPreConfigurado" class="modal-title" [translate]="'abdes.btn.aPartirDeConfigSistema' | translate | lowercase"></h4>
</div>

<div class="modal-body">
  <div class="entity-detail-form">
    <pl-form (formInstanceChange)="formPerfil = $event">
      <fieldset class="mb-3">
        <legend [translate]="'generateAbdes.selecionarAbonoDescontoPretendido'" [translateParams]="{abonoDesconto: titleAbnDesc}"></legend>
        <pl-group *ngIf="tipoConfigAbDescPreConfigurado">
          <label [translate]="'generateAbdes.fields.tipo'"></label>
          <edit>
            <pl-edit attrName="tipo" [type]="tipoAbonoAgrupadoType" [model]="tipo" (modelChange)="fnTipoChange($event)" [properties]="{disallowClear: true}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group *ngIf="tipoConfigAbDescPreConfigurado">
          <label [translate]="'generateAbdes.fields.perfilAbDesc'" [translateParams]="{abonoDesconto: titleAbnDesc}"></label>
          <edit>
            <pl-autocomplete
              attrName="perfilSistemaAbdes"
              [source]="listaPerfilSistemaAbdes"
              [model]="selectedPerfilSistemaAbdes"
              [rowTemplate]="perfilSistemaAbdesTemplate"
              [output]="perfilSistemaAbdesTemplate"
              (evtSelected)="fnPerfilSistemaAbdesChanged($event)"
              [properties]="{validators: {required: {value: true}}}"
              plAutoFocus>
            </pl-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="!tipoConfigAbDescPreConfigurado">
          <label [translate]="titleAbnDesc"></label>
          <edit>
            <entity-autocomplete
              attrName="codAbDesc"
              [entity]="codAbDescEntity"
              [outputKey]="'codAbDesc'"
              [outputDescription]="'designacaoBreve'"
              (evtSelected)="fnCodAbDescChanged($event)"
              [properties]="{allowInvalid: false, allowEmpty: false, showMaintenanceEditAction: false, validators: {required: {value: true}}}"
              plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>
      </fieldset>

      <pl-group>
        <label [translate]="'generateAbdes.fields.nome'"></label>
        <edit>
          <pl-edit-text attrName="nome" [(model)]="nome" [properties]="{validators: {required: {value: true}}}"></pl-edit-text>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'generateAbdes.fields.descricao'"></label>
        <edit>
          <pl-edit-text attrName="descricao" [(model)]="descricao" [properties]="{validators: {required: {value: true}}}"></pl-edit-text>
        </edit>
      </pl-group>

      <pl-group *ngIf="ausenciaVisible">
        <label [translate]="'generateAbdes.fields.ausencia'"></label>
        <edit>
          <pl-edit attrName="ausencia" type="tiposAusencias" [(model)]="ausencia" [properties]="{validators: {required: {value: true}}}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <pl-group *ngIf="valorLimiteIncVisible">
          <label [translate]="'abdes.fields.valorLimIncidencia'"></label>
          <edit>
            <pl-edit-number attrName="valorLimIncidencia" [(model)]="valorLimiteInc" [properties]="{disabled: selectedPerfilSistemaAbdes?.bloqueiaLimitesIncidencia}"></pl-edit-number>
          </edit>
        </pl-group>

        <pl-group *ngIf="valorUnitVisible">
          <label [translate]="'abdes.fields.valorFixo'"></label>
          <edit>
            <pl-edit-number attrName="valorUnitVisible" [(model)]="valorUnit"></pl-edit-number>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="percAumentHorExtraVisible">
        <label [translate]="'generateAbdes.fields.percAumentHExtra'"></label>
        <edit>
          <pl-edit-number attrName="percAumentHorExtraVisible" [(model)]="percAumentHorExtra"></pl-edit-number>
        </edit>
      </pl-group>
    </pl-form>
  </div>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-success me-1" [onClick]="fnGenerateABDES" [disabled]="closeDisabled"><i class="fa fa-fw fa-check"></i><span [translate]="'global.btn.ok'"></span></pl-button>
  <pl-button klass="btn-light action-dismiss me-1" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i><span [translate]="'global.btn.cancel'"></span></pl-button>
</div>
