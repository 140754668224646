<div class="configsSite entity-detail-form">
  <pl-form [properties]="{validators: {required: {value: true}}}" (ngFormChange)="form = $event">
    <div *ngFor="let config of configsEmail">
      <ng-container [ngSwitch]="config.type === 'boolean'">
        <pl-group>
          <label [translate]="'configsSite.configs.' + config.configId"></label>
          <edit>
            <pl-edit *ngSwitchCase="true" [type]="config.type" [attrName]="config.configId" [(model)]="booleanValue" [properties]="{readonly: config.readonly}"></pl-edit>
            <pl-edit
              *ngSwitchCase="false"
              [type]="config.type"
              [attrName]="config.configId"
              [(model)]="config.value"
              [properties]="{readonly: config.readonly, trueValue: '1', falseValue: '0'}"></pl-edit>
          </edit>
        </pl-group>
      </ng-container>
    </div>
  </pl-form>
</div>
