import {IJsonDocComercialLinha} from '../../entities/docscomerciais/jsonDocComercial.entity.interface';

export enum ETipoDevolucao {
  None,
  Documento,
  ArtigoValor,
  DocumentoExterno,
  ArtigoQuantidade
}

export interface IJsonDevolucao {
  nDocfaDraftDestino: number;
  nNumerDraftDestino: number;
  nDocDraftDestino: number;
  faccbIdOrigem: number;
  valorDevolucao: number;
  motivoDevolucao: string;
  nArtigoDevolucao: string;
  tipoDevolucao: number;
  linhas: Array<IJsonDevolucaoLinha>;
}

export interface IJsonDevolucaoLinha {
  facliIdOrigem: number;
  motivoDevolucao: string;
  qtdDevolucao: number;
  valorDevolucao: number;
  nArtigoDevolucao: string;
  lotesDevolucao: Array<IJsonDevolucaoLoteLinha>;
}

export interface IJsonArtigoDevolucao {
  nArtigo: string;
  nome: string;
  taxaIvaVenda: number;
  codIvaVenda: number;
}

export interface IJsonDevolucaoLoteLinha {
  nLote: number;
}

export interface IJsonLinhaParaDevolucao extends IJsonDocComercialLinha {
  faccbid: number;
  porDevolver: number;
  aDevolver: number;
  nDocStr: string;
  tipoDevolucao: number;
  nseqDestino?: number;
  totalDevolver?: number;
  nDocExterno?: string;
}

export interface IJsonDevolucaoMotivo {
  codMotivo: number;
  motivoStr: string;
}
