<div class="modal-header">
  <button *ngIf="showCloseBtn" type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title">
    <span *ngIf="icon"><i class="fa fa-fw" [ngClass]="iconClass" aria-hidden="true"></i>&nbsp;</span><span [innerHTML]="title | translate"></span>
  </h5>
  <ng-container *ngTemplateOutlet="templateHeader; context: modalOptions.templateRefHeaderContext"></ng-container>
</div>

<div class="modal-body">
  <div [innerHTML]="message | translate"></div>
  <ng-container *ngTemplateOutlet="templateContent; context: modalOptions.templateRefContentContext"></ng-container>
</div>

<div class="modal-footer" plNavigation selector="pl-button" *ngIf="showOkBtn || showCancelBtn">
  <pl-button *ngIf="showOkBtn" klass="btn-sm {{ btnOkCssClass }} action-close" (evtClicked)="close()" plAutoFocus>
    <i class="fa fa-fw" [ngClass]="btnOkIcon"></i>&nbsp;<span [translate]="btnOkText"></span>
  </pl-button>

  <pl-button *ngIf="showCancelBtn" klass="btn-sm {{ btnCancelCssClass }} action-dismiss" (evtClicked)="dismiss()">
    <i class="fa fa-fw" [ngClass]="btnCancelIcon"></i>&nbsp;<span [translate]="btnCancelText"></span>
  </pl-button>
</div>
