import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {Component, Injector, Input} from '@angular/core';
import {IJsonConcelho, IJsonDadosDerrama, IJsonDerramaCondicoesTaxa} from '../jsonConcelho.entity.interface';
import {ENTITY_NAME_CONCELHOS, IConcelhosEntityService} from '../concelhos.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {HttpResponse} from '@angular/common/http';
import {IJsonModelo22CompDerrama} from '../../../modules/portalcontabilidade/modelo22/jsonModelo22.module.interface';
import {Modelo22DerramaModalComponent} from '../../../modules/portalcontabilidade/modelo22/components/anexos/modal/modelo22.derrama.modal.component';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';

@Component({
  selector: 'concelhos-dadosderrama-modal',
  templateUrl: './concelhos.dadosDerrama.modal.component.html'
})
export class ConcelhosDadosDerramaModalComponent extends CGModalComponent<void> {
  @Input() public dadosDerrama: IJsonDadosDerrama;

  public readonly definitionDadosIntrod: IDevExpressDataGrid;
  public readonly definitionCondicoes: IDevExpressDataGrid;

  public concelhoService: IConcelhosEntityService;
  public promise: Promise<void>;

  private _dataGridInstanceCondicoes: dxDataGrid<IJsonDerramaCondicoesTaxa, IJsonDerramaCondicoesTaxa>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.concelhoService = this._entityServiceBuilder.build<IJsonConcelho, IConcelhosEntityService>(ENTITY_NAME_CONCELHOS);
    this.definitionDadosIntrod = {
      columns: [
        {dataField: 'tipoDadosSTR', dataType: 'string', caption: 'concelhos.modal.fields.tipoDadosSTR'},
        {dataField: 'valor', dataType: 'string', caption: 'concelhos.modal.fields.valor'}
      ],
      searchPanel: {visible: false},
      grouping: {contextMenuEnabled: false},
      columnFixing: {enabled: false},
      export: {enabled: false},
      columnChooser: {enabled: false},
      hoverStateEnabled: true
    };
    this.definitionCondicoes = {
      columns: [
        {dataField: 'condicaoCumprida', dataType: 'boolean', caption: 'concelhos.modal.fields.condicaoCumprida'},
        {dataField: 'tipoTaxaSTR', dataType: 'string', caption: 'concelhos.modal.fields.tipoTaxaSTR'},
        {dataField: 'taxa', dataType: 'number', caption: 'concelhos.modal.fields.taxa'},
        {dataField: 'ambito', dataType: 'string', caption: 'concelhos.modal.fields.ambito'}
      ],
      searchPanel: {visible: false},
      grouping: {contextMenuEnabled: false},
      columnFixing: {enabled: false},
      export: {enabled: false},
      columnChooser: {enabled: false},
      hoverStateEnabled: true,
      toolbar: {
        visible: false
      }
    };
  }

  public onDataGridInitializedCondicoes(event: IDevExpressDataGridEventOnInitialized<IJsonDerramaCondicoesTaxa, IJsonDerramaCondicoesTaxa>): void {
    this._dataGridInstanceCondicoes = event.component;
  }

  public editDerrama(): Promise<void> {
    this.promise = this.concelhoService.getCalculoDerrama(this.dadosDerrama.codConcelho, this.dadosDerrama.ano).then((response: HttpResponse<IJsonModelo22CompDerrama>) => {
      const modalInstance = this._cgModalService.showVanilla(Modelo22DerramaModalComponent);
      const componentInstance: Modelo22DerramaModalComponent = modalInstance.componentInstance;
      componentInstance.derrama = response.body;
      componentInstance.fromConcelhos = true;
      modalInstance.result.then(() => {
        return this.concelhoService.getDadosDerrama(this.dadosDerrama.codConcelho, this.dadosDerrama.ano).then((responseDerrama: HttpResponse<IJsonDadosDerrama>) => {
          this.dadosDerrama = responseDerrama.body;
          this._dataGridInstanceCondicoes.refresh();
        });
      });
    });
    return this.promise;
  }
}
