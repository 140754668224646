<div class="gestao-comunicacoes-message mt-3" [ngClass]="{'flex-row-reverse': mensagemObj.isMyMessage}">
  <div class="message-container" [ngClass]="{'my-message': mensagemObj.isMyMessage, 'other-message': !mensagemObj.isMyMessage}">
    <div [ngSwitch]="mensagemObj.isMessage">
      <ng-container *ngSwitchCase="true">
        <div class="message-text" [innerHTML]="mensagemObj.message"></div>
      </ng-container>

      <ng-container *ngSwitchCase="false">
        <pl-tooltip
          [config]="{
            text: mensagemObj.folder,
            container: 'message-file',
            tooltipClass: 'tooltip-success',
            placement: mensagemObj.isMyMessage ? 'left' : 'right',
            disabled: !this.documentoAdicionadoContabilidade
          }">
          <ng-container *plTooltipContent>
            <div class="message-file mb-2" [ngClass]="{'my-message': mensagemObj.isMyMessage}" (contextmenu)="onRightClick($event)" (click)="onClick($event)">
              <div class="message-file-outer-container">
                <div class="message-file-icon" [ngSwitch]="fileFormated.extension">
                  <ng-container *ngSwitchCase="eFileTypes.PDF"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></ng-container>
                  <ng-container *ngSwitchCase="eFileTypes.TXT"><i class="fa fa-file-text-o" aria-hidden="true"></i></ng-container>
                  <ng-container *ngSwitchCase="eFileTypes.DOC || eFileTypes.DOCX"><i class="fa fa-file-word-o" aria-hidden="true"></i></ng-container>
                  <ng-container *ngSwitchCase="eFileTypes.JPG || eFileTypes.JPEG || eFileTypes.PNG"><i class="fa fa-file-image-o" aria-hidden="true"></i></ng-container>
                  <ng-container *ngSwitchCase="eFileTypes.XLS || eFileTypes.XLSX || eFileTypes.CSV"><i class="fa fa-file-excel-o" aria-hidden="true"></i></ng-container>
                  <ng-container *ngSwitchDefault><i class="fa fa-file-o" aria-hidden="true"></i></ng-container>
                  <div class="badge-adicionado-contabilidade" *ngIf="this.documentoAdicionadoContabilidade"><i class="fa fa-check" aria-hidden="true"></i></div>
                </div>
                <div class="message-file-data-container">
                  <div class="message-file-name">{{ fileFormated.name }}</div>
                  <div class="message-file-data-information">
                    <span> {{ fileFormated.size }}</span>
                    <span> {{ fileFormated.extension }}</span>
                  </div>
                </div>
                <div ngbDropdown class="message-file-action" #dropdown="ngbDropdown" (click)="$event.stopPropagation()">
                  <span class="btn message-file-action-button" ngbDropdownToggle> <i class="fa fa-ellipsis-v" aria-hidden="true"></i></span>
                  <div ngbDropdownMenu>
                    <button type="button" class="dropdown-item" (click)="onClickDownloadFile()">
                      <i class="fa fa-fw fa-download"></i>&nbsp;<span [translate]="'gestaoComunicacoes.messageFile.downloadFile'"></span>
                    </button>
                    <button
                      type="button"
                      class="dropdown-item"
                      (click)="addDocumentToContabilidade()"
                      [disabled]="this.documentoAdicionadoContabilidade"
                      *ngIf="contabilista && validMimeTypeAdicionarContabilidade">
                      <i class="fa fa-fw fa-plus"></i>&nbsp;<span [translate]="'gestaoComunicacoes.messageFile.addToContab'"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </pl-tooltip>
      </ng-container>
    </div>

    <div class="message-date">{{ dateShow }}</div>
  </div>
</div>
