import {Component, Injector, OnInit} from '@angular/core';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {ETipoComCategoria, IJsonComCategoria} from '../../jsonComCategoria.entity.interface';
import {UntypedFormGroup} from '@angular/forms';
import {DATA_SOURCE_NAME_TIPO_COM_CATEGORIA} from '../../../../datasources/tipocomcategoria/tipoComCategoria.datasource.interface';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {GESTAO_COMUNICACAO_COLOR_DEFAULT, IGestaoComunicacoesEmpresa, isBackgroundTooLight} from '../../../../modules/portalcontabilidade/gestaocomunicacoes/gestaoComunicacoes.module.interface';
import {GestaoComunicacoesTopicoService} from '../../../../modules/portalcontabilidade/gestaocomunicacoes/services/gestaoComunicacoesTopico.module.service';
import {IPlTooltipConfig, isEmpty} from 'pl-comps-angular';

@Component({
  selector: 'com-categoria-edit',
  templateUrl: './comCategoria.entity.edit.component.html'
})
export class ComCategoriaEntityEditComponent extends ModuloEntityDetailComponent<IJsonComCategoria> implements OnInit {
  public readonly rowTemplateEmpresa: string;
  public formInstance: UntypedFormGroup;
  public tipoComCategoria: string;
  public colorInvalid: boolean;
  public empresasSource: Array<IGestaoComunicacoesEmpresa>;
  public entityStateDetailType: typeof EEntityStateDetailType;

  private _nEmpresaBackup: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _gestaoComunicacoesTopicoService: GestaoComunicacoesTopicoService
  ) {
    super(_injector);
    this.rowTemplateEmpresa = '{{nEmpresa}} - {{nome}}';
    this.tipoComCategoria = DATA_SOURCE_NAME_TIPO_COM_CATEGORIA;
    this.entityStateDetailType = EEntityStateDetailType;
    this.empresasSource = [];
    this.model = {
      comCategoriaId: '',
      nome: '',
      tipo: undefined,
      cor: '',
      todasEmpresas: false,
      nEmpresa: ''
    };
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    if ((this.type === this.stateTypes.EDIT || this.type === this.stateTypes.DETAIL) && this.model.tipo === ETipoComCategoria.Sistema) {
      const toolTip: IPlTooltipConfig = {
        disabled: false,
        text: 'comcategorias.categoriaSistema',
        placement: 'bottom'
      };
      this.btnEdit.disabled = true;
      this.btnSave.disabled = true;
      this.btnDelete.disabled = true;
      this.btnEdit.tooltip = this.btnSave.tooltip = this.btnDelete.tooltip = toolTip;
    }

    if (this.model.todasEmpresas) {
      this.model.nEmpresa = '';
    }

    this.empresasSource = (await this._gestaoComunicacoesTopicoService.getEmpresas()).body;
    this._nEmpresaBackup = this.model?.nEmpresa;
    if (this.type === this.stateTypes.NEW) {
      this.model.cor = GESTAO_COMUNICACAO_COLOR_DEFAULT;
      this.model.tipo = ETipoComCategoria.Utilizador;
    }
  }

  public evaluateColorValid(): void {
    this.colorInvalid = isBackgroundTooLight(this.model.cor);
    this.btnSave.disabled = this.colorInvalid;
  }

  public todasEmpresasChanged(checked: boolean): void {
    if (checked) {
      this.model.nEmpresa = '';
    } else {
      this.model.nEmpresa = this._nEmpresaBackup;
    }
  }

  public changedEmpresa(value: IGestaoComunicacoesEmpresa): void {
    this.model.nEmpresa = value?.nEmpresa;
    if (!isEmpty(value)) {
      this._nEmpresaBackup = value?.nEmpresa;
    }
  }
}
