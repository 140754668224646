export * from './articleFeedbackResource.service';
import { ArticleFeedbackResourceService } from './articleFeedbackResource.service';
export * from './articleResource.service';
import { ArticleResourceService } from './articleResource.service';
export * from './chatResource.service';
import { ChatResourceService } from './chatResource.service';
export * from './feedbackResource.service';
import { FeedbackResourceService } from './feedbackResource.service';
export * from './intentResource.service';
import { IntentResourceService } from './intentResource.service';
export const APIS = [ArticleFeedbackResourceService, ArticleResourceService, ChatResourceService, FeedbackResourceService, IntentResourceService];
