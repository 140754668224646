import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {
  EImportadorSaftDirectInvoiceType,
  EImportadorSaftDirectTipoAgrupamento,
  EImportadorSaftDirectTipoRetencao,
  IAgrupamentoDocsItem,
  IJsonImportadorSaftDirectTipoDocumento,
  ITipoRetencaoItem
} from '../../importadorSaftDirect.module.interface';
import {isNullOrUndefined} from '@uirouter/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'importador-saft-direct-tipos-docs-modal',
  templateUrl: './importadorSaftDirect.tiposDocs.modal.component.html'
})
export class ImportadorSaftDirectTiposDocsModalComponent extends CGModalComponent<IJsonImportadorSaftDirectTipoDocumento> implements OnInit {
  @Input() public model: IJsonImportadorSaftDirectTipoDocumento;
  @Input() public showCCusto: boolean;
  @Input() public showRepCC: boolean;

  public agrupamentoDocsSource: Array<IAgrupamentoDocsItem>;
  public agrupamentoDocsItem: IAgrupamentoDocsItem;

  public tipoRetencaoSource: Array<ITipoRetencaoItem>;
  public tipoRetencaoItem: ITipoRetencaoItem;

  public canChangeAgrupamentoDoc: boolean;
  public readonly invoiceTypes: typeof EImportadorSaftDirectInvoiceType;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: TranslateService
  ) {
    super(_injector);
    this.invoiceTypes = EImportadorSaftDirectInvoiceType;
  }

  public ngOnInit(): void {
    this.agrupamentoDocsSource = [
      {agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento.None, agrupamentoDocumentosStr: this._plTranslateService.instant('importadorSaftDirect.strings.semAgrupamento')},
      {agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento.PorDiaApenasCF, agrupamentoDocumentosStr: this._plTranslateService.instant('importadorSaftDirect.strings.porDiaApenasCF')},
      {agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento.PorDiaContribuinte, agrupamentoDocumentosStr: this._plTranslateService.instant('importadorSaftDirect.strings.porDiaContribuinte')},
      {agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento.ResumoDiaGlobal, agrupamentoDocumentosStr: this._plTranslateService.instant('importadorSaftDirect.strings.resumoDiaGlobal')},
      {agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento.ResumoMensalGlobal, agrupamentoDocumentosStr: this._plTranslateService.instant('importadorSaftDirect.strings.resumoMensalGlobal')},
      {
        agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento.ResumoMensalContribuinte,
        agrupamentoDocumentosStr: this._plTranslateService.instant('importadorSaftDirect.strings.resumoMensalContribuinte')
      }
    ];
    this.agrupamentoDocsItem = this.agrupamentoDocsSource[this.model?.agrupamentoDocumentos ? this.model.agrupamentoDocumentos : 0];

    this.tipoRetencaoSource = [
      {tipoRetencao: EImportadorSaftDirectTipoRetencao.None, tipoRetencaoStr: this._plTranslateService.instant('importadorSaftDirect.strings.none')},
      {tipoRetencao: EImportadorSaftDirectTipoRetencao.Imediata, tipoRetencaoStr: this._plTranslateService.instant('importadorSaftDirect.strings.imediata')},
      {tipoRetencao: EImportadorSaftDirectTipoRetencao.NoPagamento, tipoRetencaoStr: this._plTranslateService.instant('importadorSaftDirect.strings.noPagamento')},
      {tipoRetencao: EImportadorSaftDirectTipoRetencao.ImediataValorCCJaDeduzido, tipoRetencaoStr: this._plTranslateService.instant('importadorSaftDirect.strings.imediataValorCCJaDeduzido')}
    ];
    this.tipoRetencaoItem = this.tipoRetencaoSource[this.model?.tipoRetencao ? this.model.tipoRetencao : 0];
    this.canChangeAgrupamentoDoc =
      this.model.invoiceType === EImportadorSaftDirectInvoiceType.FaturaSimplificada ||
      this.model.invoiceType === EImportadorSaftDirectInvoiceType.Fatura ||
      this.model.invoiceType === EImportadorSaftDirectInvoiceType.FaturaRecibo ||
      this.model.invoiceType === EImportadorSaftDirectInvoiceType.VendaDinheiro ||
      this.model.invoiceType === EImportadorSaftDirectInvoiceType.TalaoVenda;
  }

  public changedTipoRetencao(value: number): void {
    this.model.tipoRetencao = this.tipoRetencaoSource[value].tipoRetencao;
    this.model.tipoRetencaoStr = this.tipoRetencaoSource[value].tipoRetencaoStr;
  }

  public fnAgrupamentoDocsChanged(value: number): void {
    this.model.agrupamentoDocumentos = this.agrupamentoDocsSource[value].agrupamentoDocumentos;
    this.model.agrupamentoDocumentosStr = this.agrupamentoDocsSource[value].agrupamentoDocumentosStr;
  }

  public onFormSubmit(): void {
    if (isNullOrUndefined(this.model.nDiario)) {
      this.model.nDiario = 0;
    }
    if (isNullOrUndefined(this.model.descritivo)) {
      this.model.descritivo = 0;
    }
    this.close(this.model);
  }
}
