import moment from 'moment';
import {isObject, merge} from 'lodash-es';
import type {Column, default as dxDataGrid, InitializedEvent} from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {
  copy,
  IPlNavWizardCallback,
  IPlNavWizardDefinition,
  IPlNavWizardEventBeforeChange,
  IPlNavWizardEventStep,
  isFunction,
  isUndefinedOrNull,
  PlAlertService,
  PlEditAutocompleteComponent
} from 'pl-comps-angular';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {CGStateService} from '../../../../components/state/cg.state.service';
import {DevolucoesService} from '../../../../services/devolucoes/devolucoes.service';
import {DocsComerciaisModalComponent} from '../docsComerciais.modal.component';
import {DocumentosService} from '../../service/documentos.entity.service';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {ENTITY_DOCS_COMERCIAIS_VENDAS} from '../../docsComerciais.entity';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ESAFTTipoDoc, IJsonDocfa} from '../../../docfas/jsonDocFa.entity.interface';
import {EStepsDevolucao, IDevolucaoDocExterno, MAX_STRING, MIN_STRING} from './devolucoes.modal.component.interface';
import {IApiQueryResponse} from '../../../../services/api/api.service.interface';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnEditorPreparing,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowUpdated,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDocFasEntityService} from '../../../docfas/docFas.entity.interface';
import {IJsonArtigo} from '../../../artigos/jsonArtigo.entity.interface';
import {ETipoDevolucao, IJsonArtigoDevolucao, IJsonDevolucao, IJsonDevolucaoMotivo, IJsonLinhaParaDevolucao} from '../../../../services/devolucoes/jsonDevolucao.interface';
import {IJsonDocComercial, IJsonDocComercialLinha} from '../../jsonDocComercial.entity.interface';
import {round} from '../../../../../common/utils/utils';
import {TDate} from '../../../../../common/dates';
import {IJsonIva} from '../../../ivas/jsonIva.entity.interface';
import {focusElement} from '../../../../../common/utils/element.utils';
import {EGrupoDoc} from '../../../../datasources/grupodoc/grupoDoc.datasource.interface';

@Component({
  selector: 'devolucoes-modal',
  templateUrl: './devolucoes.modal.component.html'
})
export class DevolucoesModalComponent extends DocsComerciaisModalComponent implements OnInit {
  @Input() public modoEdicao: boolean;
  @Input() public facliIdLinhaEdicao: number;
  @Input() public nArtigo: string;
  @Input() public saveDocEmMemoriaOnFinalize: boolean;

  public readonly tipoDevolucao: typeof ETipoDevolucao;
  public readonly stepsDevolucao: typeof EStepsDevolucao;
  public readonly definitionWizard: IPlNavWizardDefinition;
  public readonly navWizardCallback: IPlNavWizardCallback;
  public readonly motivosOutput: string = '{{motivoStr}}';
  public readonly decimaisValoresLinha: number;
  public readonly decimaisQtds: number;
  public readonly ivasOutput: string;

  public tipoSelecionado: ETipoDevolucao;
  public tipoSelecionadoStr: string;
  public dataGridLinhasDefinition: IDevExpressDataGrid<IJsonLinhaParaDevolucao, number>;
  public artigoDevolucao: IJsonArtigoDevolucao;
  public motivos: Array<IJsonDevolucaoMotivo>;
  public motivoSelecionado: IJsonDevolucaoMotivo;
  public dataSource: Array<IJsonLinhaParaDevolucao>;
  public devolucao: IJsonDevolucao;
  public modelDocfa: IJsonDocfa;
  public modelDocfaOrigem: IJsonDocfa;
  public nDocfaFilter: string;
  public nDocfaFilterOrigem: string;
  public docfaNumFilter: string;
  public deNArtigo: IJsonArtigo;
  public ateNArtigo: IJsonArtigo;
  public dataDoc: TDate;
  public temDocDestino: boolean;
  public modelDocExt: IDevolucaoDocExterno;
  public showPreview: boolean;
  public devolucaoDocExternoEnabled: boolean;

  @ViewChild('comboMotivos') private readonly _comboMotivos: PlEditAutocompleteComponent;
  private readonly _docFaService: IDocFasEntityService;

  private _dataGridInstance: dxDataGrid<IJsonLinhaParaDevolucao, number>;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _devolucoesService: DevolucoesService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgStateService: CGStateService,
    private readonly _tranlateService: TranslateService,
    private readonly _documentosService: DocumentosService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);

    this._docFaService = this._entityServiceBuilder.build<IJsonDocfa, IDocFasEntityService>('docfas');

    this.definitionWizard = {
      items: []
    };
    this.navWizardCallback = {};
    this.tipoDevolucao = ETipoDevolucao;
    this.stepsDevolucao = EStepsDevolucao;
    this.tipoSelecionadoStr = '';
    this.motivoSelecionado = {
      codMotivo: -1,
      motivoStr: ''
    };
    this.motivos = [];
    this.dataSource = [];
    this.deNArtigo = {...this.deNArtigo, nArtigo: ''};
    this.ateNArtigo = {...this.ateNArtigo, nArtigo: 'ZZZZ'};
    this.dataDoc = moment().startOf('year');
    this.temDocDestino = true;
    this.showPreview = false;
    this.modoEdicao = false;
    this.facliIdLinhaEdicao = 0;
    this.saveDocEmMemoriaOnFinalize = false;

    this.devolucao = {
      faccbIdOrigem: 0,
      motivoDevolucao: '',
      nArtigoDevolucao: '',
      nDocfaDraftDestino: 0,
      nNumerDraftDestino: 0,
      nDocDraftDestino: 0,
      valorDevolucao: 0,
      tipoDevolucao: ETipoDevolucao.None,
      linhas: []
    };
    this.modelDocExt = {
      nDocExterno: '',
      dataDoc: moment(),
      valor: 0,
      qtd: 1,
      codIva: 0,
      taxaIva: 0
    };

    this.devolucaoDocExternoEnabled = true;

    this.decimaisValoresLinha = this._configService.configurations.gestaoComercial.decimais.valoresLinha;
    this.decimaisQtds = this._configService.configurations.gestaoComercial.decimais.quantidades;

    this.ivasOutput = '{{codIva}} - {{taxaActual}} %';
  }

  public ngOnInit(): void {
    this.tipoSelecionado = ETipoDevolucao.None;
    this.nDocfaFilter = `saftTipoDoc=${ESAFTTipoDoc.SAFTNotaCredito}||saftTipoDoc=${ESAFTTipoDoc.SAFTNotaDebito}`;
    this.docfaNumFilter = `nDocFa=${this.devolucao.nDocfaDraftDestino}&encerrado=0&visivelERPCloud=1`;

    if (this.doc.cab.faccbId > 0 && !this.doc.cab.isDevolucao) {
      this.devolucao.faccbIdOrigem = this.doc.cab.faccbId;
    }

    if (this.nArtigo) {
      this.deNArtigo = {...this.deNArtigo, nArtigo: this.nArtigo};
      this.ateNArtigo = {...this.ateNArtigo, nArtigo: this.nArtigo};
    } else {
      this.deNArtigo = {...this.deNArtigo, nArtigo: MIN_STRING};
      this.ateNArtigo = {...this.deNArtigo, nArtigo: MAX_STRING};
    }

    if ((this.doc?.cab?.terminado || this.doc?.cab?.nDocumento > 0) && !this.doc?.cab?.isDevolucao) {
      this._fillNDocFaDestino();
    } else {
      this.temDocDestino = false;
      this.devolucao.nDocfaDraftDestino = this.doc.cab.nDocFa;
      this.devolucao.nNumerDraftDestino = this.doc.cab.nNumer;
      this.devolucao.nDocDraftDestino = this.doc.cab.nDocumentoDraft;

      if (this.doc.cab.isDevolucao) {
        if (this.modoEdicao) {
          setTimeout(() => {
            if (isFunction(this.navWizardCallback.setStep)) {
              this._carregaLinhasLigadas();
            }
          });
        } else {
          setTimeout(() => {
            if (isFunction(this.navWizardCallback.setStep)) {
              this.navWizardCallback.setStep(this.stepsDevolucao.Tipo, true);
            }
          });
        }
      }
      this._docFaService.get({id: this.doc.cab.nDocFa}).then((response: HttpResponse<IJsonDocfa>) => {
        if (response.body) {
          this.modelDocfa = {...(this.modelDocfa = response.body)};
          this.nDocfaFilterOrigem = `integraStocks=${this.modelDocfa.integraStocks}&tipoMovimentoStock<>${this.modelDocfa.tipoMovimentoStock}&grupoDocfa=0,1,5,6,11,12,13`;
          this._updateDocExternoVisibility();
        }
      });
    }

    this._devolucoesService.getMotivos(true).then((response: HttpResponse<Array<IJsonDevolucaoMotivo>>) => {
      if (response.body) {
        this.motivos = response.body;
        if (this.facliIdLinhaEdicao > 0) {
          const linha = this.doc.linhas.find((lin) => lin.facliId === this.facliIdLinhaEdicao);
          const motivoLinhaEmEdicao = this.motivos.find((item) => linha.docRegularizMotivo === item.motivoStr);
          if (motivoLinhaEmEdicao) {
            this.motivoSelecionado = motivoLinhaEmEdicao;
          } else {
            this.motivoSelecionado = {codMotivo: -1, motivoStr: linha.docRegularizMotivo};
          }
        }
      }
    });

    if (this.modoEdicao && this.facliIdLinhaEdicao > 0) {
      const linha = this.doc.linhas.find((lin) => lin.facliId === this.facliIdLinhaEdicao);
      if (linha) {
        this.artigoDevolucao = {nArtigo: linha.nArtigo, nome: linha.nomeArtigo, taxaIvaVenda: linha.taxaIva, codIvaVenda: linha.codIva};
        if (linha.docRegularizOrigExt && linha.docRegularizOrigExt !== '') {
          this.modelDocExt.valor = round(linha.prVendaIvaInc / linha.qtd1, this.decimaisValoresLinha);
          this.motivoSelecionado.motivoStr = linha.docRegularizMotivo;
          this.modelDocExt.nDocExterno = linha.docRegularizOrigExt;
          this.modelDocExt.dataDoc = linha.docRegularizData;
          this.modelDocExt.codIva = linha.codIva;
          this.modelDocExt.taxaIva = linha.taxaIva;
          this.modelDocExt.qtd = linha.qtd1;
        }
      }
    } else {
      this._devolucoesService.getNArtigoDevolucao().then((response: HttpResponse<IJsonArtigoDevolucao>) => {
        if (response.body) {
          this.artigoDevolucao = response.body;
          this.modelDocExt.codIva = this.artigoDevolucao.codIvaVenda;
          this.modelDocExt.taxaIva = this.artigoDevolucao.taxaIvaVenda;
        }
      });
    }
  }

  public tipoDevolucaoChanged(tipo: ETipoDevolucao): void {
    this.tipoSelecionado = tipo;
    this.dataSource = [];
    this._configuraGrid();
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonLinhaParaDevolucao>): void {
    if (event.rowType === 'data') {
      if (event.column.dataField === 'qtdDevolver') {
        event.cellElement.classList.add('datagrid-editable-cell');
      }
    }
  }

  public motivoChanged(motivo: IJsonDevolucaoMotivo | string): void {
    if (isObject(motivo) && motivo.codMotivo >= 0) {
      this.motivoSelecionado.codMotivo = motivo.codMotivo;
      this.motivoSelecionado.motivoStr = motivo.motivoStr;
    } else {
      this.motivoSelecionado.codMotivo = -1;
      this.motivoSelecionado.motivoStr = <string>motivo;
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonLinhaParaDevolucao, number>): void {
    this._dataGridInstance = component;
  }

  public pesquisaLinhasDevolucao(): void {
    if (!this.modelDocfaOrigem?.nDocFa) {
      this._plAlertService.error('devolucao.messages.erroTipoDoc');
      return;
    }
    this._devolucoesService
      .getLinhasDevolucao(
        this.devolucao.tipoDevolucao,
        0,
        this.doc.cab.nConta,
        this.modelDocfaOrigem.nDocFa,
        this.dataDoc,
        this.deNArtigo.nArtigo ? this.deNArtigo.nArtigo : MIN_STRING,
        this.ateNArtigo.nArtigo ? this.ateNArtigo.nArtigo : MAX_STRING
      )
      .then((response: HttpResponse<Array<IJsonLinhaParaDevolucao>>) => {
        if (response.body) {
          this.dataSource = copy(response.body);
          this._cardPanel.collapse();
          if (this.motivoSelecionado?.codMotivo === -1) {
            this._comboMotivos.clearViewValue();
          }
        }
      });
  }

  public editarDocumento(): void {
    this._cgStateService.redirectToState({stateOrName: ENTITY_DOCS_COMERCIAIS_VENDAS, stateType: EEntityStateDetailType.EDIT, params: {id: this.doc.cab.faccbId}});
    this.dismiss();
  }

  public adicionarMotivo(): void {
    if (this.motivoSelecionado.codMotivo < 0 && this.motivoSelecionado.motivoStr) {
      this._devolucoesService.adicionarMotivo(this.motivoSelecionado.motivoStr.trim()).then((response: HttpResponse<IJsonDevolucaoMotivo>) => {
        if (response.body) {
          response.body.codMotivo = this.motivos.length;
          this.motivos.push(response.body);
          this.motivoSelecionado.codMotivo = response.body.codMotivo;
        }
      });
    }
  }

  public limpaTudo(): void {
    if (this.dataSource.length) {
      for (const item of this.dataSource) {
        this.limpaLinha(item);
      }
    }
  }

  public receberTudo(): void {
    if (this.dataSource.length) {
      for (const item of this.dataSource) {
        this.receberTudoLinha(item);
      }
    }
  }

  public limpaLinha(item: IJsonLinhaParaDevolucao): void {
    const index = this.dataSource.findIndex((value: IJsonLinhaParaDevolucao) => value.facliId === item.facliId);
    if (index !== -1) {
      this.dataSource[index].aDevolver = 0;
      this._dataGridInstance?.getKeyByRowIndex(index);
      const selection: Array<number> = this._dataGridInstance?.getSelectedRowKeys();
      selection.splice(selection.indexOf(item.facliId), 1);
      this._dataGridInstance?.deselectAll();
      this._dataGridInstance?.selectRows(selection, true);
    }
  }

  public receberTudoLinha(item: IJsonLinhaParaDevolucao): void {
    const index = this.dataSource.findIndex((value: IJsonLinhaParaDevolucao) => value.facliId === item.facliId);
    if (index !== -1) {
      this.dataSource[index].aDevolver = this.dataSource[index].porDevolver;
      this._dataGridInstance?.getKeyByRowIndex(index);
      const selection: Array<number> = this._dataGridInstance?.getSelectedRowKeys();
      selection.push(item.facliId);
      this._dataGridInstance?.selectRows(selection, true);
    }
  }

  public onRowUpdated(item: IDevExpressDataGridEventOnRowUpdated<IJsonLinhaParaDevolucao>): void {
    if (item.data.aDevolver > 0) {
      this._dataGridInstance?.selectRows([item.data.facliId], true);
    }
  }

  public async stepChanged({currentStep}: IPlNavWizardEventStep): Promise<void> {
    if (currentStep.stepId === EStepsDevolucao.Preview) {
      for (const item of this.definitionWizard.items) {
        item.disabled = item.stepId !== EStepsDevolucao.Preview;
      }
    }

    return Promise.resolve();
  }

  public onSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IJsonLinhaParaDevolucao>): void {
    if (this.tipoSelecionado === ETipoDevolucao.Documento) {
      if (event.selectedRowKeys.length > 1) {
        const facclid = event.currentSelectedRowKeys;
        this._dataGridInstance?.deselectAll().then(() => {
          const id = <number>facclid[0];
          this._dataGridInstance?.selectRows([id], true);
        });
      }
    }
  }

  public onEditorPreparing(event: IDevExpressDataGridEventOnEditorPreparing<IJsonLinhaParaDevolucao, number>): void {
    if (event.type !== 'selection') {
      return;
    }

    if (!this.modoEdicao) {
      return;
    }

    if (event.parentType === 'headerRow') {
      const dataGrid = event.component;
      event.editorOptions.onInitialized = (e: InitializedEvent) => {
        if (e.component) {
          dataGrid.selectAll();
        }
      };
    }
  }

  public nartigoDeChanged(value: string): void {
    if (isUndefinedOrNull(value) || value === '') {
      this.deNArtigo = {...this.deNArtigo, nArtigo: MIN_STRING};
      this.ateNArtigo = {...this.deNArtigo, nArtigo: MAX_STRING};
      return;
    }
    this.deNArtigo = {...this.deNArtigo, nArtigo: value};
    this.ateNArtigo = {...this.deNArtigo, nArtigo: value};
  }

  public nartigoAteChanged(value: string): void {
    if (isUndefinedOrNull(value)) {
      this.ateNArtigo = {...this.deNArtigo, nArtigo: MAX_STRING};
      return;
    }
    this.ateNArtigo = {...this.deNArtigo, nArtigo: value};
  }

  public artigoChanged(artigo: IJsonArtigo): Promise<void> {
    if (isObject(artigo)) {
      if (artigo.movimStock) {
        this.artigoDevolucao = merge({}, {nArtigo: '', nome: '', taxaIvaVenda: 0, codIvaVenda: 0});
        this.modelDocExt = {...this.modelDocExt, codIva: 0, taxaIva: 0};
        this._plAlertService.error('devolucao.messages.artigoDescontoNaoPodeMovStock');
        this._setFocusNArtigo();
        return Promise.reject();
      }
      this.artigoDevolucao = artigo;
      this.modelDocExt = {...this.modelDocExt, codIva: artigo.codIvaVenda, taxaIva: artigo.taxaIvaVenda};
    }

    return Promise.resolve();
  }

  public onIvaChanged(iva: IJsonIva): void {
    if (isObject(iva)) {
      this.modelDocExt.codIva = iva.codIva;
      this.modelDocExt.taxaIva = iva.taxaActual;
    }
  }

  public onDocfaOrigemChanged(docfa: IJsonDocfa): void {
    if (isObject(docfa)) {
      this.modelDocfaOrigem = docfa;
      this._updateDocExternoVisibility();
    }
  }

  public readonly fnBeforeChangedStep = (event: IPlNavWizardEventBeforeChange): Promise<boolean> => this._beforeChangedStep(event);

  public readonly fnFinalize: () => Promise<void> = () => this._finalize();

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private async _finalize(): Promise<void> {
    const self: DevolucoesModalComponent = this;

    if (self.tipoSelecionado === ETipoDevolucao.DocumentoExterno) {
      if (!self.modelDocExt.nDocExterno) {
        self._plAlertService.error('devolucao.messages.erroDocExterno');
        return Promise.reject();
      }

      if (!self.motivoSelecionado.motivoStr) {
        self._plAlertService.error(this._tranlateService.instant('devolucao.messages.erroMotivo'));
        self._comboMotivos.clearViewValue();
        return Promise.reject();
      }

      if (self.modelDocExt.valor <= 0) {
        self._plAlertService.error('devolucao.messages.erroDocExternoValor');
        return Promise.reject();
      }

      let linhaEmEdicao = self.doc.linhas.find((item) => item.facliId === self.facliIdLinhaEdicao);

      const linha: IJsonDocComercialLinha = {
        nSeq: self.doc.linhas.length,
        nArtigo: self.artigoDevolucao.nArtigo,
        nomeArtigo: self.artigoDevolucao.nome,
        qtd1: self.modelDocExt.qtd,
        prVenda1: self.modelDocfa.faturaComIvaIncluido
          ? round(self.modelDocExt.valor / self.modelDocExt.qtd, this.decimaisValoresLinha)
          : round((100 * (self.modelDocExt.valor * self.modelDocExt.qtd)) / (100 + self.modelDocExt.taxaIva) / self.modelDocExt.qtd, this.decimaisValoresLinha),
        codIva: self.modelDocExt.codIva,
        docRegularizMotivo: self.motivoSelecionado.motivoStr,
        docRegularizOrigExt: self.modelDocExt.nDocExterno,
        docRegularizData: self.modelDocExt.dataDoc,
        nCCusto: self.doc.cab.nCCusto ? self.doc.cab.nCCusto : '',
        nrefprocesso: self.doc.cab.nrefprocesso ? self.doc.cab.nrefprocesso : ''
      };

      if (self.doc.cab.faccbId === 0) {
        self.doc.linhas = [];
        if (self.saveDocEmMemoriaOnFinalize) {
          return self._documentosService.postDoc(self.doc, false, false, true).then((responseAux) => {
            if (responseAux.body) {
              self.doc = responseAux.body;
              return self._devolucoesService.devolucaoLinhaDocExterno(linha, self.doc.cab.faccbId).then((response: HttpResponse<IJsonDocComercial>) => {
                if (response.body) {
                  self.doc = {...(self.doc = response.body)};
                  self.close(self.doc);
                }
              });
            }
            this._plAlertService.error('docscomerciais.erros.notaCreditoDocErro');
            return Promise.reject(new Error('docscomerciais.erros.notaCreditoDocErro'));
          });
        }
        return self._devolucoesService.devolucaoDocExterno(self.doc).then((response: HttpResponse<IJsonDocComercial>) => {
          if (response.body) {
            self.doc = {...(self.doc = response.body)};
            self.close(self.doc);
          }
        });
      }

      if (linhaEmEdicao && this._sameLinhaDocExterno(linhaEmEdicao)) {
        self.close(self.doc);
      } else {
        linhaEmEdicao = {
          ...linhaEmEdicao,
          nArtigo: linha.nArtigo,
          nomeArtigo: linha.nomeArtigo,
          qtd1: linha.qtd1,
          codIva: linha.codIva,
          prVenda1: linha.prVenda1,
          prVenda1Defined: true,
          prVendaIvaInc: 0,
          valorIva: 0,
          prVendaIvaIncME: 0,
          valorIvaME: 0,
          liquido: 0,
          docRegularizMotivo: linha.docRegularizMotivo,
          docRegularizOrigExt: linha.docRegularizOrigExt,
          docRegularizData: linha.docRegularizData
        };

        return self._devolucoesService.devolucaoLinhaDocExterno(linhaEmEdicao, self.doc.cab.faccbId).then((response: HttpResponse<IJsonDocComercial>) => {
          if (response.body) {
            self.doc = {...(self.doc = response.body)};
            self.close(self.doc);
          }
        });
      }
    }

    if (self._dataGridInstance?.getSelectedRowsData().length === 0) {
      if (self.modoEdicao) {
        return Promise.resolve(self.close(self.doc));
      }
      self._plAlertService.error('devolucao.messages.erroLinhas');
      return Promise.reject();
    }

    if (!self.motivoSelecionado.motivoStr) {
      self._plAlertService.error(this._tranlateService.instant('devolucao.messages.erroMotivo'));
      self._comboMotivos.clearViewValue();
      return Promise.reject();
    }

    if (self.tipoSelecionado !== ETipoDevolucao.ArtigoQuantidade && !self.artigoDevolucao.nArtigo) {
      self._plAlertService.error('devolucao.messages.erroArtigoDevolucao');
      return Promise.reject();
    }

    if (self.facliIdLinhaEdicao > 0) {
      const linha = self.doc.linhas.find((lin) => lin.facliId === self.facliIdLinhaEdicao);
      const linhas: Array<IJsonLinhaParaDevolucao> = self._dataGridInstance?.getSelectedRowsData();
      if (
        linha.qtd1 === linhas[0].aDevolver &&
        self.motivoSelecionado.motivoStr === linha.docRegularizMotivo &&
        (self.tipoSelecionado === ETipoDevolucao.ArtigoQuantidade || (self.tipoSelecionado === ETipoDevolucao.ArtigoValor && self.artigoDevolucao?.nArtigo === linha.nArtigo))
      ) {
        return Promise.resolve(self.close(self.doc));
      }
    }

    self.devolucao.linhas = [];
    const selectedRowsData = self._dataGridInstance?.getSelectedRowsData();
    for (const item of selectedRowsData) {
      if (item.aDevolver === undefined || item.aDevolver === 0) {
        self._plAlertService.error('devolucao.messages.erroValor');
        return Promise.reject();
      }

      if (item.porDevolver < item.aDevolver) {
        self._plAlertService.error(self._tranlateService.instant('devolucao.messages.erroValorSuperior', {porDevolver: item.porDevolver, aDevolver: item.aDevolver}));
        return Promise.reject();
      }

      if (self.tipoSelecionado === ETipoDevolucao.Documento) {
        self.devolucao.faccbIdOrigem = item.faccbid;
        self.devolucao.linhas.push({
          facliIdOrigem: item.facliId,
          lotesDevolucao: [],
          motivoDevolucao: '',
          nArtigoDevolucao: '',
          qtdDevolucao: 0,
          valorDevolucao: 0
        });
      }

      if (self.tipoSelecionado === ETipoDevolucao.ArtigoValor || self.tipoSelecionado === ETipoDevolucao.ArtigoQuantidade) {
        self.devolucao.linhas.push({
          facliIdOrigem: item.facliId,
          lotesDevolucao: [],
          motivoDevolucao: self.motivoSelecionado.motivoStr,
          nArtigoDevolucao: self.artigoDevolucao?.nArtigo ? self.artigoDevolucao?.nArtigo : '',
          qtdDevolucao: item.aDevolver,
          valorDevolucao: item.aDevolver
        });
      }
      self.devolucao.motivoDevolucao = self.motivoSelecionado.motivoStr;
      self.devolucao.nArtigoDevolucao = self.artigoDevolucao?.nArtigo ? self.artigoDevolucao?.nArtigo : '';
      self.devolucao.nDocfaDraftDestino = self.modelDocfa.nDocFa;
      self.devolucao.valorDevolucao = item.aDevolver;
    }

    if (self.modoEdicao) {
      if (self.tipoSelecionado !== ETipoDevolucao.Documento) {
        const linhasSelecionadas = self._dataGridInstance?.getSelectedRowsData();
        linhasSelecionadas.sort((a: IJsonLinhaParaDevolucao, b: IJsonLinhaParaDevolucao) => {
          if (a.nseqDestino < b.nseqDestino) {
            return 1;
          }
          if (a.nseqDestino >= b.nseqDestino) {
            return -1;
          }
          return 0;
        });
        for (const item of linhasSelecionadas) {
          if (item.nseqDestino) {
            self.doc.linhas.splice(item.nseqDestino - 1, 1);
          }
        }
      } else {
        self.doc.linhas.splice(0, 1);
      }
      const response: HttpResponse<IJsonDocComercial> = await self._documentosService.patchDoc(self.doc);
      if (response.body) {
        self.doc = response.body;
      }
    }

    if (self.saveDocEmMemoriaOnFinalize && self.doc.cab.faccbId === 0) {
      const response = await self._documentosService.postDoc(self.doc, false, false, true);
      if (response.body) {
        self.doc = response.body;
        self.devolucao.nDocfaDraftDestino = self.doc.cab.nDocFa;
        self.devolucao.nNumerDraftDestino = self.doc.cab.nNumer;
        self.devolucao.nDocDraftDestino = self.doc.cab.nDocumentoDraft;
      }
    }

    const showResumo = self.doc.cab.terminado;

    const faccbIdDestino = self.doc.cab.isDevolucao && self.doc.cab.nDocumentoDraft > 0 ? self.doc.cab.faccbId : 0;

    return self._devolucoesService
      .devolucao(self.devolucao, faccbIdDestino, self.tipoSelecionado)
      .then((response: HttpResponse<IJsonDocComercial>) => {
        if (response.body) {
          self.doc = {...response.body};
          if (!showResumo) {
            self.close(self.doc);
          } else {
            self._plAlertService.success('devolucao.messages.sucesso');
            self.showPreview = true;
            setTimeout(() => {
              return self.navWizardCallback.setStep(EStepsDevolucao.Preview, true);
            });
          }
        }
      })
      .catch((error: unknown) => {
        let errorMsg = '';
        if (error instanceof HttpErrorResponse) {
          const exception = self._cgExceptionService.get(error);
          errorMsg = exception ? exception.message : error.message;
          self._plAlertService.error(errorMsg);
        }
        return Promise.reject(new Error(errorMsg));
      });
  }

  private async _beforeChangedStep({currentStep, nextStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (this.showPreview && currentStep?.stepId === EStepsDevolucao.Preview) {
      return false;
    }

    if (currentStep && currentStep?.stepId === EStepsDevolucao.Tipo && (type === 'next' || nextStep?.stepId === EStepsDevolucao.Devolucao)) {
      if (!this._validateTipoSelecionado()) {
        return false;
      }

      if (this.doc.cab.terminado || this.doc.cab.nDocumento > 0) {
        if (this.dataSource.length === 0 || this.dataSource[0].tipoDevolucao !== this.devolucao.tipoDevolucao || this.dataSource[0].faccbid !== this.devolucao.faccbIdOrigem) {
          this.dataSource = [];
          return this._devolucoesService.getLinhasDevolucao(this.devolucao.tipoDevolucao, this.devolucao.faccbIdOrigem).then((response: HttpResponse<Array<IJsonLinhaParaDevolucao>>) => {
            if (response.body) {
              this.dataSource = copy(response.body);
              return true;
            }
            return false;
          });
        }
      }
    }
    return true;
  }

  private _validateTipoSelecionado(): boolean {
    switch (this.tipoSelecionado) {
      case ETipoDevolucao.None:
        this.tipoSelecionadoStr = '';
        return false;
      case ETipoDevolucao.ArtigoQuantidade:
        this.devolucao.tipoDevolucao = ETipoDevolucao.None;
        this.tipoSelecionadoStr = this._tranlateService.instant('devolucao.caption.artigoQuantidade');
        break;
      case ETipoDevolucao.ArtigoValor:
        this.devolucao.tipoDevolucao = ETipoDevolucao.ArtigoValor;
        this.tipoSelecionadoStr = this._tranlateService.instant('devolucao.caption.artigoValor');
        break;
      case ETipoDevolucao.Documento:
        this.tipoSelecionadoStr = this._tranlateService.instant('devolucao.caption.documentoValor');
        this.devolucao.tipoDevolucao = ETipoDevolucao.Documento;
        break;
      case ETipoDevolucao.DocumentoExterno:
        this.tipoSelecionadoStr = this._tranlateService.instant('devolucao.caption.documentoExterno');
        this.devolucao.tipoDevolucao = ETipoDevolucao.Documento;
        break;
    }
    return true;
  }

  private async _fillNDocFaDestino(): Promise<void> {
    const response: HttpResponse<IJsonDocfa> = await this._docFaService.get({id: this.doc.cab.nDocFa});
    if (response.body) {
      if (response.body.nDocfaDestino > 0) {
        const responseDestino: HttpResponse<IJsonDocfa> = await this._docFaService.get({id: response.body.nDocfaDestino});
        if (responseDestino.body) {
          this.modelDocfa = {...(this.modelDocfa = responseDestino.body)};
        }
      } else {
        const filter = `integraStocks=${response.body.integraStocks}&tipoMovimentoStock<>${response.body.tipoMovimentoStock}&grupoDocfa=0,1,5,6,11,12,13`;
        const responseDestinos: HttpResponse<IApiQueryResponse<IJsonDocfa>> = await this._docFaService.query({pesquisa: filter});
        if (responseDestinos?.body?.list?.length > 0) {
          this.modelDocfa = {...(this.modelDocfa = responseDestinos?.body?.list[0])};
        }
      }

      if (this.modelDocfa.docfanumList.length > 0) {
        this.devolucao.nNumerDraftDestino = this.modelDocfa.docfanumList[0].nNumer;
      } else {
        this.devolucao.nNumerDraftDestino = 1;
      }
    }
  }

  private _carregaLinhasLigadas(): Promise<void> {
    this.dataSource = [];
    if (this.doc.cab.docRegularizMotivo === '') {
      if (this.facliIdLinhaEdicao > 0) {
        const linha = this.doc.linhas.find((item) => item.facliId === this.facliIdLinhaEdicao);
        if (linha?.docRegularizOrigExt !== '') {
          this.tipoSelecionado = ETipoDevolucao.DocumentoExterno;
          setTimeout(() => {
            if (isFunction(this.navWizardCallback.setStep)) {
              this.navWizardCallback.setStep(this.stepsDevolucao.Devolucao, true).then(() => {
                this._validateTipoSelecionado();
              });
            }
          });
          return Promise.resolve();
        }

        return this._devolucoesService.linhaOrigem(this.facliIdLinhaEdicao).then((response: HttpResponse<IJsonLinhaParaDevolucao>) => {
          if (response.body && response.body.facliId > 0) {
            response.body.porDevolver += response.body.aDevolver;
            // Nc criada e em rascunho, ao tentar editar o valor não permite Finalizar
            response.body.totalDevolver = response.body.porDevolver + response.body.aDevolver;
            this.tipoSelecionado = response.body.tipoDevolucao === 2 ? ETipoDevolucao.ArtigoValor : ETipoDevolucao.ArtigoQuantidade;
            this.dataSource.push(response.body);
            this._refreshAndPrepareGrid();
          }
        });
      }
      return this._devolucoesService.linhasOrigem(this.doc.cab.nDocFa, this.doc.cab.nNumer, this.doc.cab.nDocumentoDraft).then((response: HttpResponse<Array<IJsonLinhaParaDevolucao>>) => {
        if (response.body) {
          this.dataSource = copy(response.body);
          for (const item of this.dataSource) {
            item.porDevolver += item.aDevolver;
            // Nc criada e em rascunho, ao tentar editar o valor não permite Finalizar
            item.totalDevolver = item.porDevolver + item.aDevolver;
          }
          this._refreshAndPrepareGrid();
        }
      });
    }

    return this._devolucoesService.linhaDocOrigem(this.doc.cab.nDocFa, this.doc.cab.nNumer, this.doc.cab.nDocumentoDraft).then((response: HttpResponse<IJsonLinhaParaDevolucao>) => {
      if (response.body) {
        response.body.porDevolver += response.body.aDevolver;
        // Nc criada e em rascunho, ao tentar editar o valor não permite Finalizar
        response.body.totalDevolver = response.body.porDevolver + response.body.aDevolver;
        this.dataSource.push(response.body);
        this.artigoDevolucao.nArtigo = this.doc.linhas[0].nArtigo;
        this.artigoDevolucao.nome = this.doc.linhas[0].nomeArtigo;
        this.artigoDevolucao.taxaIvaVenda = this.doc.linhas[0].taxaIva;
      }
      this.tipoSelecionado = ETipoDevolucao.Documento;
      const linhaMotivo: IJsonDevolucaoMotivo = {motivoStr: this.doc.cab.docRegularizMotivo, codMotivo: this.motivos.length};

      const index = this.motivos.findIndex((item) => item.motivoStr === linhaMotivo.motivoStr);
      if (index === -1) {
        this.motivos = [...this.motivos, linhaMotivo];
      }
      this.motivoSelecionado = {...linhaMotivo};
      this._configuraGrid();
      setTimeout(() => {
        if (isFunction(this.navWizardCallback.setStep)) {
          this.navWizardCallback.setStep(this.stepsDevolucao.Devolucao, true).then(() => {
            this._validateTipoSelecionado();
            this._dataGridInstance?._refresh();
          });
        }
      });
    });
  }

  private _refreshAndPrepareGrid(): void {
    this._configuraGrid();
    setTimeout(() => {
      if (isFunction(this.navWizardCallback.setStep)) {
        this.navWizardCallback.setStep(this.stepsDevolucao.Devolucao, true).then(() => {
          this._validateTipoSelecionado();
          this._dataGridInstance?._refresh();
        });
      }
    });
  }

  private _configuraGrid(): void {
    this.dataGridLinhasDefinition = {};
    if (this.tipoSelecionado === ETipoDevolucao.ArtigoQuantidade || this.tipoSelecionado === ETipoDevolucao.ArtigoValor) {
      this.dataGridLinhasDefinition = {
        columnHidingEnabled: false,
        columns: [
          {dataField: 'facliId', dataType: 'number', caption: 'devolucao.table.facliId', visible: false, allowEditing: false, showInColumnChooser: false},
          {dataField: 'nDocStr', dataType: 'string', caption: 'devolucao.table.nDoc', allowEditing: false},
          {dataField: 'nDocExterno', dataType: 'string', caption: 'docscomerciais.fields.nFactFornec', visible: false, allowEditing: false, showInColumnChooser: false},
          {dataField: 'nArtigo', dataType: 'string', caption: 'docscomerciais.doc.linhas.nArtigo', allowEditing: false},
          {dataField: 'nomeArtigo', dataType: 'string', caption: 'docscomerciais.doc.linhas.nome', allowEditing: false},
          {dataField: 'nArmazem', dataType: 'string', caption: 'docscomerciais.doc.linhas.nArmazem', visible: false, allowEditing: false},
          {
            dataField: 'prVenda1',
            dataType: 'double',
            caption: 'docscomerciais.doc.linhas.preco',
            format: {precision: this.decimaisValoresLinha, decimalsLimit: this.decimaisValoresLinha},
            allowEditing: false
          },
          {dataField: 'taxaIva', dataType: 'number', caption: 'docscomerciais.doc.linhas.taxaIva', allowEditing: false},
          {
            dataField: 'valorIva',
            dataType: 'double',
            caption: 'docscomerciais.doc.linhas.valorIva',
            format: {precision: this.decimaisValoresLinha, decimalsLimit: this.decimaisValoresLinha},
            allowEditing: false
          },
          {dataField: 'qtd1', dataType: 'double', caption: 'docscomerciais.doc.linhas.qtd', format: {precision: this.decimaisQtds, decimalsLimit: this.decimaisQtds}, allowEditing: false},
          {
            dataField: 'porDevolver',
            dataType: this.tipoSelecionado === ETipoDevolucao.ArtigoQuantidade ? 'double' : 'currency',
            format: {
              precision: this.tipoSelecionado === ETipoDevolucao.ArtigoQuantidade ? this.decimaisQtds : this.decimaisValoresLinha,
              decimalsLimit: this.tipoSelecionado === ETipoDevolucao.ArtigoQuantidade ? this.decimaisQtds : this.decimaisValoresLinha
            },
            caption: 'devolucao.table.porDevolver',
            allowEditing: false
          },
          {
            dataField: 'aDevolver',
            dataType: this.tipoSelecionado === ETipoDevolucao.ArtigoQuantidade ? 'double' : 'currency',
            format: {
              precision: this.tipoSelecionado === ETipoDevolucao.ArtigoQuantidade ? this.decimaisQtds : this.decimaisValoresLinha,
              decimalsLimit: this.tipoSelecionado === ETipoDevolucao.ArtigoQuantidade ? this.decimaisQtds : this.decimaisValoresLinha
            },
            caption: 'devolucao.table.aDevolver',
            allowEditing: true
          },
          {type: 'buttons', headerCellTemplate: 'headerCellTemplateBtns', cellTemplate: 'cellTemplateBtns', showInColumnChooser: false}
        ],
        keyExpr: 'facliId',
        selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
        filterRow: {visible: false},
        headerFilter: {visible: false},
        editing: {
          allowUpdating: true,
          mode: 'cell',
          selectTextOnEditStart: true,
          startEditAction: 'click'
        }
      };
    }
    if (this.tipoSelecionado === ETipoDevolucao.Documento) {
      this.dataGridLinhasDefinition = {
        columnHidingEnabled: false,
        columns: [
          {dataField: 'facliId', dataType: 'number', caption: 'devolucao.table.facliId', visible: false, allowEditing: false, showInColumnChooser: false},
          {dataField: 'nDocStr', dataType: 'string', caption: 'docscomerciais.fields.nDoc', allowEditing: false},
          {dataField: 'nDocExterno', dataType: 'string', caption: 'docscomerciais.fields.nFactFornec', visible: false, allowEditing: false, showInColumnChooser: false},
          {
            dataField: 'porDevolver',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: this.decimaisValoresLinha
            },
            caption: 'devolucao.table.porDevolver',
            allowEditing: false
          },
          {
            dataField: 'aDevolver',
            dataType: 'currency',
            format: {
              type: 'currency',
              precision: this.decimaisValoresLinha
            },
            caption: 'devolucao.table.aDevolver',
            allowEditing: true
          },
          {type: 'buttons', cellTemplate: 'cellTemplateBtns', showInColumnChooser: false}
        ],
        dataSource: this.dataSource,
        keyExpr: 'facliId',
        selection: {mode: 'multiple', allowSelectAll: false, showCheckBoxesMode: 'always'},
        filterRow: {visible: false},
        headerFilter: {visible: false},
        editing: {
          allowUpdating: true,
          mode: 'cell',
          selectTextOnEditStart: true,
          startEditAction: 'click'
        }
      };
    }
  }

  private _sameLinhaDocExterno(linha: IJsonDocComercialLinha): boolean {
    return (
      linha.nArtigo === this.artigoDevolucao.nArtigo &&
      linha.prVendaIvaInc === this.modelDocExt.valor &&
      linha.docRegularizMotivo === this.motivoSelecionado.motivoStr &&
      linha.docRegularizOrigExt === this.modelDocExt.nDocExterno &&
      linha.docRegularizData === this.modelDocExt.dataDoc &&
      linha.qtd1 === this.modelDocExt.qtd &&
      linha.codIva === this.modelDocExt.codIva
    );
  }

  private _updateDocExternoVisibility(): void {
    const isVisible = this._dataGridInstance.getVisibleColumns().findIndex((c: Column<IJsonLinhaParaDevolucao, number>) => c.dataField === 'nDocExterno') > -1;
    this._dataGridInstance.columnOption('nDocExterno', 'showInColumnChooser', this.modelDocfaOrigem.grupoDocfa === EGrupoDoc.ComprasEfectivas);
    this._dataGridInstance.columnOption('nDocExterno', 'visible', this.modelDocfaOrigem.grupoDocfa === EGrupoDoc.ComprasEfectivas && isVisible);
  }

  private _setFocusNArtigo(): void {
    focusElement(this._element.querySelector<HTMLInputElement>('.artigo-edit input[name="artigo"]'));
  }
}
