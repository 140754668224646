import {Component, Injector, Input, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../components/module/module.component';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {dataSourceTipoEscolhaUtlViatPatronal, ITipoEscolhaUtlViatPatronal, IUtlViatPatronal} from '../utlViatPatronal.module.interface';
import {ETipoOrdenacao} from '../jsonUtlViatPatronal.module.interface';
import {UtlViatPatronalService} from '../utlViatPatronal.module.service';
import {UtlViatPatronalModalComponent} from '../modals/utlViatPatronal.modal.component';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {copy} from 'pl-comps-angular';
import {HttpResponse} from '@angular/common/http';
import {ETipoAbonoDesconto} from '../../../entities/abdes/abdes.entity.interface';
import moment from 'moment';

@Component({
  selector: 'module-utl-viat-patronal',
  templateUrl: './utlViatPatronal.module.component.html'
})
export class UtlViatPatronalModuleComponent extends ModuloComponent implements OnInit {
  @Input() public utlViatPatronalList: Array<IUtlViatPatronal>;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public tipoEscolhaUtlViatura: Array<ITipoEscolhaUtlViatPatronal>;
  public estadoViatura: ITipoEscolhaUtlViatPatronal;

  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _utlViatPatronalService: UtlViatPatronalService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.pesquisar = this.pesquisar.bind(this);
    this.tipoEscolhaUtlViatura = <Array<ITipoEscolhaUtlViatPatronal>>copy(dataSourceTipoEscolhaUtlViatPatronal(this._translateService));
    this.estadoViatura = this.tipoEscolhaUtlViatura[0];
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: '_empDesc', dataType: 'string', caption: 'utlviatpatronal.fields.codEmp'},
        {dataField: '_abonoDesc', dataType: 'string', caption: 'utlviatpatronal.fields.codABDESC'},
        {dataField: 'anoProcessamento', dataType: 'number', caption: 'utlviatpatronal.fields.anoProcessamento'},
        {dataField: 'vAquisicaoViatura', dataType: 'double', caption: 'utlviatpatronal.fields.vAquisicaoViatura'},
        {dataField: 'vMercadoViatura', dataType: 'double', caption: 'utlviatpatronal.fields.vMercadoViatura'},
        {dataField: 'rhTipoProce', dataType: 'string', caption: 'utlviatpatronal.fields.rhTipoProce'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnsUtlViatPatronalEdit'}
      ],
      dataSource: [],
      export: {filename: 'global.menu.utlviatpatronal'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: 'rhUltViatPatronalID',
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'saveUtlViatPatronal',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-plus-circle fa-fw"></i>',
      class: 'btn-primary',
      caption: 'global.btn.new',
      click: () => this._openUtlViatPatronalModal(this._emptyUtlViatPatronal())
    });
    this._applyDataSourceUtlViatPatronal();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public pesquisar(): void {
    this._dataGridInstance.beginCustomLoading(undefined);

    this._utlViatPatronalService
      .getViatPatronal(this.estadoViatura.value, ETipoOrdenacao.CodEmp)
      .then((response: HttpResponse<Array<IUtlViatPatronal>>) => {
        this.utlViatPatronalList = response.body;
        this._applyDataSourceUtlViatPatronal();
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
  }

  public readonly fnEditUtlViatPatronal: (item: IUtlViatPatronal) => () => Promise<void> = (item: IUtlViatPatronal) => (): Promise<void> => this._openUtlViatPatronalModal(item);

  public readonly fnDeleteUtlViatPatronal: (item: IUtlViatPatronal) => () => Promise<void> = (item: IUtlViatPatronal) => (): Promise<void> => this._deleteUtlViatPatronal(item);

  private _applyDataSourceUtlViatPatronal(): void {
    for (const utlViatPatronal of this.utlViatPatronalList) {
      utlViatPatronal._empDesc = `${utlViatPatronal.codEmp} - ${utlViatPatronal.nome}`;
      utlViatPatronal._abonoDesc = `${utlViatPatronal.codABDESC} - ${utlViatPatronal.abdesNome}`;
    }
    this.dataGridDefinition.dataSource = this.utlViatPatronalList;
  }

  private _openUtlViatPatronalModal(utlViatPatronal: IUtlViatPatronal): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(UtlViatPatronalModalComponent, {size: 'xxl'});
    const componentInstance: UtlViatPatronalModalComponent = modalInstance.componentInstance;
    componentInstance.estado = this.estadoViatura.value;
    componentInstance.tipoOrdenacao = ETipoOrdenacao.CodEmp;
    componentInstance.utlViatPatronal = utlViatPatronal;
    return modalInstance.result.then((result: Array<IUtlViatPatronal>) => {
      this.utlViatPatronalList = result;
      this._applyDataSourceUtlViatPatronal();
    });
  }

  private _emptyUtlViatPatronal(): IUtlViatPatronal {
    return {
      nome: '',
      abdesNome: '',
      codABDESC: '',
      processaVenc: false,
      rhTipoProce: '',
      abonoDesc: ETipoAbonoDesconto.Abono,
      rhUltViatPatronalID: '',
      nCODABDESC: '',
      codEmp: undefined,
      vAquisicaoViatura: 0,
      vMercadoViatura: 0,
      anoProcessamento: moment().year(),
      mesInicioProce: moment().month(),
      observacoes: '',
      suspende: false,
      dataSuspensao: moment().month(),
      obsSupencao: '',
      tipoProcessamento: undefined,
      valorIncidenciaSS: 0,
      valorIncidenciaIRS: 0,
      _empDesc: '',
      _abonoDesc: ''
    };
  }

  private _deleteUtlViatPatronal(utlViatPatronal: IUtlViatPatronal): Promise<void> {
    return this._utlViatPatronalService.deleteUtlViatPatronal(utlViatPatronal.rhUltViatPatronalID).then(() => {
      const index: number = this.utlViatPatronalList.findIndex((item: IUtlViatPatronal) => item.rhUltViatPatronalID === utlViatPatronal.rhUltViatPatronalID);
      if (index !== -1) {
        this.utlViatPatronalList.splice(index, 1);
        this._applyDataSourceUtlViatPatronal();
      }
    });
  }
}
