import moment from 'moment';
import {Subscription} from 'rxjs';
import type dxDataGrid from 'devextreme/ui/data_grid';
import CustomStore from 'devextreme/data/custom_store';
import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {copy, IPlToolbarItem, IPlToolbarMenuItem, isDefined, isDefinedNotNull, isString, isUndefined} from 'pl-comps-angular';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {ComunicaDocsModalComponent} from '../modals/comunicadocsmodal/comunicaDocs.modal.component';
import {ComunicaInexistenciaModalComponent} from '../modals/comunicaInexistencia/comunicaInexistencia.modal.component';
import {devExpressDataGridExpandDetailHandler} from '../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DocumentosService} from '../../../entities/docscomerciais/service/documentos.entity.service';
import {EComunicaInexistenciaModalModo, IEFaturaDocumento, IEFaturaEntityStateParams, IEFaturaMeses} from '../eFatura.module.interface';
import {
  EFaturaDocumentoComunicado,
  EFaturaEstadoDocumento,
  IJsonEFatura,
  IJsonEFaturaAnos,
  IJsonEFaturaConfiguracao,
  IJsonEFaturaErrosAvisos,
  IJsonEFaturaResumoPorIVA
} from '../jsonEFatura.module.interface';
import {EFaturaGenerateDocsModalComponent} from '../modals/efaturageneratedocs/eFaturaGenerateDocs.modal.component';
import {EFaturaConfigModalComponent} from '../modals/configuracao/eFaturaConfig.modal.component';
import {EFaturaService} from '../eFatura.module.service';
import {EMPTY_PASSWORD, EStatusCode} from '../../../../config/constants';
import {ESAFTType, ISAFTStateParams, MODULE_NAME_SAFT} from '../../saft/saft.module.interface';
import {ETipoComunicacaoEFatura, IJsonDocComercial} from '../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnInitialized
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IModuleMaintenanceInstance} from '../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloComponent} from '../../../components/module/module.component';
import {monthName} from '../../../../common/dates';
import {EFaturaLogsModalComponent} from '../modals/logs/eFaturaLogs.modal.component';

const TOOLBAR_GROUP_ID = 'efatura';
const SKIP_ERROR_WARNING_NUMBER = 29;

@Component({
  selector: 'e-fatura',
  templateUrl: './eFatura.module.component.html'
})
export class EFaturaComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly definitionByIVA: IDevExpressDataGrid;
  public readonly definitionByDoc: IDevExpressDataGrid;
  public readonly comunicaInexistenciaModalModo: typeof EComunicaInexistenciaModalModo;

  public invoice: IJsonEFatura;
  public configEFatura: IJsonEFaturaConfiguracao;

  private readonly _download: {url: string; filename: string};
  private readonly _mnuAnos: IPlToolbarItem<IJsonEFaturaAnos>;
  private readonly _mnuMeses: IPlToolbarItem<IEFaturaMeses>;
  private readonly _btnDownload: IPlToolbarItem;
  private readonly _btnGenerate: IPlToolbarItem;
  private readonly _btnComunicaDocs: IPlToolbarItem;
  private readonly _btnConfig: IPlToolbarMenuItem;
  private readonly _btnSaftGlobal: IPlToolbarMenuItem;
  private readonly _btnSaftAutofaturacao: IPlToolbarMenuItem;
  private readonly _btnPesquisar: IPlToolbarItem;
  private readonly _btnComunicaInexistencia: IPlToolbarItem;
  private readonly _btnAnularComunicacaoInexistencia: IPlToolbarItem;
  private readonly _btnLogs: IPlToolbarMenuItem;
  private readonly _stateParams: IEFaturaEntityStateParams;
  private readonly _subscriptionConfigOptions: Subscription;
  private _year: number;
  private _yearData: IPlToolbarItem<IJsonEFaturaAnos>;
  private _month: number;
  private _monthData: IPlToolbarItem<IEFaturaMeses>;
  private _filename: string;
  private _firstInit: boolean;
  private _listaDocsNaoComunicados: Array<IEFaturaDocumento>;
  private _comunicaEmTempoReal: boolean;
  private _dataGridInstanceIVA: dxDataGrid<IJsonEFaturaResumoPorIVA, IJsonEFaturaResumoPorIVA>;
  private _dataGridInstanceDoc: dxDataGrid<IEFaturaDocumento>;
  private _maintenanceSaftGlobal: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _efaturaService: EFaturaService,
    private readonly _documentosService: DocumentosService,
    private readonly _cgModalService: CGModalService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this.comunicaInexistenciaModalModo = EComunicaInexistenciaModalModo;
    this.definitionByIVA = {
      columns: [
        {dataField: 'nome', dataType: 'string', caption: 'efatura.table.name'},
        {dataField: 'taxa', dataType: 'number', caption: 'efatura.table.rate'},
        {dataField: 'valorIVA', dataType: 'double', caption: 'efatura.table.vatValue'},
        {dataField: 'valorLiquido', dataType: 'double', caption: 'efatura.table.valorTributavel'},
        {dataField: 'montanteTotal', dataType: 'double', caption: 'global.text.total'}
      ],
      dataSource: new CustomStore({
        load: () => this._getIVA()
      }),
      filterRow: {visible: false},
      hoverStateEnabled: true,
      remoteOperations: false
    };
    this.definitionByDoc = {
      columns: [
        {dataField: 'tipoSAFT', dataType: 'string', caption: 'efatura.table.tipoSAFT'},
        {dataField: 'nDocNormalizado', dataType: 'string', caption: 'efatura.table.docNumber'},
        {dataField: 'nif', dataType: 'string', caption: 'efatura.table.nif'},
        {dataField: 'nomeClifo', dataType: 'string', caption: 'efatura.table.nomeClifo'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'global.text.date', sortOrder: 'desc'},
        {dataField: 'valorIVA', dataType: 'double', caption: 'efatura.table.valorIVA'},
        {dataField: 'valorTributavel', dataType: 'double', caption: 'efatura.table.valorTributavel'},
        {dataField: 'valorParaSAFT', dataType: 'double', caption: 'efatura.table.valorParaSAFT'},
        {dataField: 'valorTotal', dataType: 'double', caption: 'efatura.table.valorTotal'},
        {dataField: 'estadoStr', dataType: 'string', caption: 'efatura.table.estado'},
        {dataField: 'comunicadoStr', dataType: 'string', caption: 'efatura.table.comunicado'},
        {dataField: 'erroNumero', dataType: 'string', caption: 'efatura.table.erros'},
        {dataField: 'tipoComunicacao', dataType: 'string', caption: 'efatura.table.tipoComunicacao'}
      ],
      dataSource: new CustomStore({
        load: () => this._getDocs()
      }),
      hoverStateEnabled: true,
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocumento'},
      remoteOperations: false
    };

    this.invoice = this._emptyInvoice();

    this._firstInit = true;
    this._stateParams = <IEFaturaEntityStateParams>this._transition.params();
    this._year = this._stateParams.year || -1;
    this._month = this._stateParams.month || -1;
    this._filename = this._stateParams.filename || '';

    this._download = {url: '', filename: ''};
    this._mnuAnos = {groupId: TOOLBAR_GROUP_ID, order: 0, id: 'toolbarBtnAnos', caption: String(this._year), type: 'dropdown', menu: null};
    this._mnuMeses = {groupId: TOOLBAR_GROUP_ID, order: this._mnuAnos.order + 1, id: 'toolbarBtnMeses', caption: 'efatura.btnMonthsJan', type: 'dropdown', menu: null};
    this._btnPesquisar = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'pesquisarBtn',
      order: this._mnuMeses.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-search fa-fw me-1"></i>',
      class: 'btn-primary',
      caption: 'efatura.btnPesquisar',
      click: () => this._getInvoice()
    };
    this._btnDownload = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'download',
      order: this._btnPesquisar.order + 1,
      type: 'download',
      iconLeft: '<i class="fa fa-download fa-fw me-1"></i>',
      class: 'btn-primary',
      caption: 'efatura.btnDownload',
      download: this._download,
      visible: false
    };
    this._btnGenerate = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'generateDoc',
      order: this._btnDownload.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-cogs me-1"></i>',
      class: 'btn-success btn-action-generate',
      caption: 'efatura.btnGenerateDoc',
      click: () => this._generateDoc(),
      visible: false
    };
    this._btnComunicaDocs = {
      id: 'comunicaDocs',
      order: this._btnGenerate.order + 1,
      type: 'button',
      class: 'btn-success',
      iconLeft: '<i class="fa fa-fw fa-upload me-1"></i>',
      caption: 'efatura.btnComunicaDocs',
      click: () => this._comunicaDocs(),
      visible: true
    };

    this._btnConfig = {
      caption: 'efatura.btnLoginWebserviceAT',
      iconLeft: '<i class="fa fa-fw fa-key me-1" aria-hidden="true"></i>',
      click: () => {
        this._showConfigModal();
      }
    };
    this._btnSaftGlobal = {
      caption: 'efatura.btnSaftGlobal',
      iconLeft: '<i class="fa fa-fw fa-upload me-1" aria-hidden="true"></i>',
      click: () => {
        return this._openModuleSaftGlobal();
      }
    };

    this._btnSaftAutofaturacao = {
      caption: 'efatura.btnSaftAutofaturacao',
      iconLeft: '<i class="fa fa-fw fa-upload me-1" aria-hidden="true"></i>',
      click: () => {
        return this._openModuleSaftAutofaturacao();
      }
    };

    this._btnLogs = {
      caption: 'efatura.btnLogs',
      iconLeft: '<i class="fa fa-fw fa-history me-1" aria-hidden="true"></i>',
      click: () => {
        this._openModalLogs();
      }
    };

    this.configEFatura = {
      atUsername: '',
      atPassword: EMPTY_PASSWORD,
      temFacturacaoElectronica: false,
      notificaDia10SeNaoEnviado: false,
      notificaDia10Dia: 2,
      notificaDia10Email: ''
    };

    this._btnComunicaInexistencia = {
      id: 'comunicaInexistencia',
      order: this.btnConfig.order + 1,
      type: 'button',
      class: 'btn-sm btn-warning',
      iconLeft: '<i class="fa fa-fw fa-send me-1"></i>',
      caption: 'efatura.inexistenciaFaturacaoModalTitle',
      click: () => {
        return this.showComunicaInexistenciaModal(EComunicaInexistenciaModalModo.COMUNICACAO);
      },
      visible: false
    };

    this._btnAnularComunicacaoInexistencia = {
      id: 'anularComunicacaoInexistencia',
      order: this.btnConfig.order + 1,
      type: 'button',
      class: 'btn-sm btn-danger',
      iconLeft: '<i class="fa fa-fw fa-ban me-1"></i>',
      caption: 'efatura.btnRemoverInexistencia',
      click: () => {
        return this.showComunicaInexistenciaModal(EComunicaInexistenciaModalModo.ANULACAO);
      },
      visible: false
    };

    this._listaDocsNaoComunicados = [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnConfig.type = 'dropdown';
    this.btnConfig.menu = [this._btnConfig];
    this._initToolbar(this.isMobile);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscriptionConfigOptions) {
      this._subscriptionConfigOptions.unsubscribe();
    }
    this._efaturaService.stopJob();
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    if (this.toolbar) {
      this._initToolbar(value);
    }
  }

  public onInitializedByIVA(event: IDevExpressDataGridEventOnInitialized<IJsonEFaturaResumoPorIVA, IJsonEFaturaResumoPorIVA>): void {
    this._dataGridInstanceIVA = event.component;
  }

  public onInitializedByDoc(event: IDevExpressDataGridEventOnInitialized<IEFaturaDocumento>): void {
    this._dataGridInstanceDoc = event.component;
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IEFaturaDocumento>): void {
    if (event.rowType !== 'data') {
      return;
    }
    switch (event.column.dataField) {
      case 'erroNumero':
        if (event.data.erroNumero) {
          const numero: number = event.data.erroNumero;
          if (numero === SKIP_ERROR_WARNING_NUMBER) {
            event.cellElement.textContent = '';
          }
          const index: number = this.invoice.avisos.findIndex((aviso: IJsonEFaturaErrosAvisos) => aviso.numero === numero);
          if (index > -1) {
            event.cellElement.innerHTML = `<span class="badge bg-warning">${numero}</span>`;
          } else {
            event.cellElement.innerHTML = `<span class="badge bg-danger">${numero}</span>`;
          }
        }
        break;
      case 'tipoComunicacao':
        if (event.data.tipoComunicacao === ETipoComunicacaoEFatura.SAFT) {
          event.cellElement.textContent = this._translateService.instant('efatura.tipoComunicacao.saft');
        } else if (event.data.tipoComunicacao === ETipoComunicacaoEFatura.WebService) {
          event.cellElement.textContent = this._translateService.instant('efatura.tipoComunicacao.webService');
        }
        break;
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IEFaturaDocumento>): void {
    devExpressDataGridExpandDetailHandler(event, () => this._onDetail(event.row.data));
  }

  public isInvoiceValid(): boolean {
    // If invoice isn't, for some reason, loaded properly
    if (isUndefined(this.invoice.avisos) || isUndefined(this.invoice.erros)) {
      return true;
    }
    return this.invoice && this.invoice.erros.length === 0 && (this.invoice.avisos.length === 0 || (this.invoice.avisos.length === 1 && this.invoice.avisos[0].numero === SKIP_ERROR_WARNING_NUMBER));
  }

  public getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc);
  }

  public getDoc(id: number): Promise<IJsonDocComercial> {
    return this._documentosService.getDoc(id).then((response: HttpResponse<IJsonDocComercial>) => response.body);
  }

  public showComunicaInexistenciaModal(modo: EComunicaInexistenciaModalModo): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ComunicaInexistenciaModalComponent, {size: 'md'});
    const componentInstance: ComunicaInexistenciaModalComponent = modalInstance.componentInstance;
    componentInstance.mes = this._month;
    componentInstance.ano = this._year;
    componentInstance.modo = modo;
    return modalInstance.result.then(() => {
      this.invoice.inexistencia.msg = '';
      this.invoice.inexistencia.showAnular = false;
      this.invoice.inexistencia.showComunicar = false;
      this._btnComunicaInexistencia.visible = this.invoice.inexistencia.showComunicar;
      this._btnAnularComunicacaoInexistencia.visible = this.invoice.inexistencia.showAnular;
    });
  }

  public readonly fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this.getPdf(doc);

  public readonly fnGetDoc: (id: number) => Promise<IJsonDocComercial> = (id: number) => this.getDoc(id);

  private _getIVA(): Array<IJsonEFaturaResumoPorIVA> {
    return isDefined(this.invoice.resumoPorIVA) ? this.invoice.resumoPorIVA : [];
  }

  private _getDocs(): Array<IEFaturaDocumento> {
    return isDefined(this.invoice.documentos) ? this.invoice.documentos : [];
  }

  private _onDetail(documento: IEFaturaDocumento): Promise<void> {
    return this.getDoc(documento.faccbId).then((docComercial: IJsonDocComercial) => {
      documento._thedoc = docComercial;
    });
  }

  private _emptyInvoice(): IJsonEFatura {
    return {
      inexistencia: {
        showComunicar: false,
        showAnular: false,
        msg: ''
      },
      documentos: [],
      erros: [],
      avisos: [],
      resumoPorTipoDocumento: [],
      resumoPorIVA: [],
      resumoFicheiro: {
        numeroFacturas: 0,
        totalCredito: 0,
        totalDebito: 0,
        total: 0,
        numeroFacturasPorComunicar: 0,
        totalCreditoPorComunicar: 0,
        totalDebitoPorComunicar: 0,
        totalPorComunicar: 0,
        numeroFacturasComunicados: 0,
        totalCreditoComunicados: 0,
        totalDebitoComunicados: 0,
        totalComunicados: 0
      }
    };
  }

  private _initToolbar(isMobile: boolean): void {
    this.toolbar.removeGroupId(TOOLBAR_GROUP_ID);
    this.toolbar.addButton(this._mnuAnos).addButton(this._mnuMeses).addButton(this._btnPesquisar);
    this.dropdownActions.menu = [];
    this.dropdownActions.visible = this.btnConfig.visible = true;
    if (isMobile) {
      this.dropdownActions.menu.push(this._btnDownload, this._btnGenerate, this._btnComunicaDocs, this._btnConfig);
      this._btnGenerate.class = this._btnComunicaDocs.class = this._btnDownload.class = undefined;
    } else {
      this.toolbar.addButton(this._btnDownload).addButton(this._btnGenerate).addButton(this._btnComunicaDocs);
      this._btnDownload.class = 'btn-primary';
      this._btnGenerate.class = this._btnComunicaDocs.class = 'btn-success';
    }
    this.dropdownActions.menu.push(this._btnSaftGlobal);
    this.dropdownActions.menu.push(this._btnSaftAutofaturacao);
    this.dropdownActions.menu.push(this._btnLogs);

    this.toolbar.addButton(this._btnComunicaInexistencia).addButton(this._btnAnularComunicacaoInexistencia);

    if (this._firstInit) {
      this._efaturaService.getConfiguracaoEFatura().then((response) => {
        this.configEFatura = response.body;
        this._comunicaEmTempoReal = this.configEFatura.temFacturacaoElectronica;
        this._btnComunicaDocs.visible = this._comunicaEmTempoReal;
      });

      // Config years dropdown menu
      this._efaturaService.getAnos().then((response) => {
        let anos: Array<IJsonEFaturaAnos> = response.body;

        // If no years are generated, use last and current year
        const year: number = moment().year();
        if (!anos.length) {
          anos = [{ano: year - 1}, {ano: year}];
        } else if (anos[anos.length - 1].ano !== year) {
          /* Else we check the difference between the last received year
           * and the current year and add the missing ones */
          const lastReceivedYear = anos[anos.length - 1].ano;
          for (let i = 1; i < year - lastReceivedYear + 1; i++) {
            anos.push({ano: lastReceivedYear + i});
          }
        }

        this._mnuAnos.menu = [];
        for (const item of anos) {
          this._mnuAnos.menu.push({
            caption: String(item.ano),
            data: item,
            click: (anoItem): Promise<void> => {
              return this._changeYear(anoItem);
            }
          });
        }

        // Using query params if any
        if (this._year >= this._mnuAnos.menu[0].data.ano) {
          this._yearData = {
            caption: String(this._year),
            click: (item): Promise<void> => {
              return this._changeYear(item);
            },
            data: {
              ano: this._year
            }
          };
          this._changeYear(this._yearData).then(() => undefined);
        } else {
          this._changeYear(this._mnuAnos.menu[this._mnuAnos.menu.length - 1]).then(() => undefined);
        }
        this._firstInit = false;
      });
    }
  }

  private async _changeYear(item: IPlToolbarItem<IJsonEFaturaAnos>, force: boolean = false): Promise<void> {
    force = Boolean(force);
    if (this._year === item.data.ano && !force && !this._firstInit) {
      return Promise.resolve();
    }

    // Setting year query param
    const params: IEFaturaEntityStateParams = {year: item.data.ano};
    if (this._month !== -1) {
      params.month = this._month;
    }
    this._stateService.go(this._uiRouterGlobals.current.name, params);

    this._mnuAnos.caption = item.caption;
    this._mnuMeses.menu = [];
    this._year = item.data.ano;
    this._yearData = item;

    // Config months dropdown menu
    const response = await this._efaturaService.getMeses(this._year);
    const data = response.body;
    const date = moment();
    const currentYear = date.year();
    let currentMonth = date.month() + 1;
    const currentDay = date.date();
    // Se não for ano atual, adiciona ao menu todos os meses
    if (this._year !== currentYear) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      for (let i = 1; i <= 12; i++) {
        this._mnuMeses.menu.push({
          caption: `${monthName(i - 1)} ${<string>this._translateService.instant('efatura.naoGeradoString')}`,
          data: {mes: i, fileName: '', edited: false},
          click: (mnuMonth): void => {
            this._changeMonth(mnuMonth);
          }
        });
      }
    }

    // Se for, adiciona até ao mês atual (máximo dia 20 do mês, inclusive)
    else {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      if (currentDay < 20 && currentMonth > 1) {
        currentMonth--;
      }

      for (let j = 1; j <= currentMonth; j++) {
        this._mnuMeses.menu.push({
          caption: `${monthName(j - 1)} ${<string>this._translateService.instant('efatura.naoGeradoString')}`,
          data: {mes: j, fileName: '', edited: false},
          click: (mnuMonth): void => {
            this._changeMonth(mnuMonth);
          }
        });
      }
    }
    // Tendo em conta os meses recebidos, transforma menu
    for (const mesItem of data) {
      let repeated = false;

      // Checking if there is already a file with the same year and month
      for (let i = 0; i < this._mnuMeses.menu.length; i++) {
        const menu = this._mnuMeses.menu[i];
        if (mesItem.mes === menu.data.mes && menu.data.edited) {
          repeated = true;

          // Changing caption from menu item
          this._mnuMeses.menu[i].caption = `${menu.caption} (${this._formatFileName(menu.data.fileName)}) ${<string>this._translateService.instant('efatura.geradoString')}`;

          // Pushing new item to menu
          this._mnuMeses.menu.splice(i + 1, 0, {
            caption: `${monthName(mesItem.mes - 1)} (${this._formatFileName(mesItem.fileName)}) ${<string>this._translateService.instant('efatura.geradoString')}`,
            data: {...mesItem, edited: false},
            click: (mnuMonth): void => {
              this._changeMonth(mnuMonth);
            }
          });
          break;
        }
      }

      if (!repeated) {
        this._mnuMeses.menu[mesItem.mes - 1] = {
          caption: `${monthName(mesItem.mes - 1)} ${<string>this._translateService.instant('efatura.geradoString')}`,
          data: {...mesItem, edited: true},
          click: (mnuMonth): void => {
            this._changeMonth(mnuMonth);
          }
        };
      }
    }
    // Using query params if any
    if (this._month > -1) {
      this._monthData = this._mnuMeses.menu.find((menu) => {
        if (this._filename && this._filename.length > 0 && isDefined(menu.data.fileName) && menu.data.fileName) {
          return menu.data.mes === this._month && menu.data.fileName === this._filename;
        }
        return menu.data.mes === this._month;
      });
      this._changeMonth(this._monthData || this._mnuMeses.menu[this._mnuMeses.menu.length - 1]);
    } else {
      this._changeMonth(this._mnuMeses.menu[this._mnuMeses.menu.length - 1]);
    }
    return Promise.resolve();
  }

  private _changeMonth(item: IPlToolbarItem<IEFaturaMeses>): void {
    // In case item comes undefined, example of this being whenever a user
    // selects a month available in a previous year but not in the current year
    if (!item) {
      item = copy(this._mnuMeses.menu[this._mnuMeses.menu.length - 1]);
    }
    if (isDefinedNotNull(item.data)) {
      this._mnuMeses.caption = item.caption;
      this._month = item.data.mes;
      this._monthData = item;
      this._filename = item.data.fileName;
    }

    // Setting query params
    this._stateService.go(this._uiRouterGlobals.current.name, {month: item.data.mes});

    // reset
    this._btnDownload.visible = false;
    this._btnGenerate.visible = false;
    this._btnComunicaInexistencia.visible = false;
    this._btnAnularComunicacaoInexistencia.visible = false;

    this.invoice = this._emptyInvoice();

    // Updating tables
    this._dataGridInstanceDoc.refresh();
    this._dataGridInstanceIVA.refresh();
    this._efaturaService.getFaturaXmlUrl(this._year, this._month, this._filename).subscribe((faturaXmlUrl) => {
      this._download.url = faturaXmlUrl;
    });
  }

  private _reset(): void {
    this._btnDownload.visible = false;
    this._btnGenerate.visible = false;
    this._btnComunicaInexistencia.visible = false;
    this._btnAnularComunicacaoInexistencia.visible = false;
    this._listaDocsNaoComunicados = [];

    this.invoice = this._emptyInvoice();
    this._dataGridInstanceDoc.refresh();
    this._dataGridInstanceIVA.refresh();
  }

  private _getInvoice(): Promise<void> {
    this._reset();
    this._btnPesquisar.disabled = true;

    if (this._year === -1 || this._month === -1 || this._firstInit) {
      this._btnPesquisar.disabled = false;
      return Promise.resolve();
    }

    return this._efaturaService
      .getFatura(this._year, this._month)
      .then((response) => {
        if (response.status !== EStatusCode.OK) {
          this.invoice = this._emptyInvoice();
          return;
        }
        this.invoice = this._transformInvoice(response.body);
        this._btnComunicaInexistencia.visible = this.invoice.inexistencia.showComunicar;
        this._btnAnularComunicacaoInexistencia.visible = this.invoice.inexistencia.showAnular;
        if (this.invoice.documentos.length > 0) {
          const show = isDefined(this._filename) && this._filename !== null;
          this._btnDownload.visible = !this._comunicaEmTempoReal && show;
          this._btnGenerate.visible = true;
          this._listaDocsNaoComunicados = this.invoice.documentos.filter((doc) => doc.comunicado === EFaturaDocumentoComunicado.NAO);
        }
      })
      .catch(() => {
        this.invoice = this._emptyInvoice();
      })
      .finally(() => {
        this._btnPesquisar.disabled = false;
        this._dataGridInstanceDoc.refresh();
        this._dataGridInstanceIVA.refresh();
      });
  }

  private async _generateDoc(): Promise<void> {
    let response: HttpResponse<File>;
    if (this.invoice.resumoFicheiro.numeroFacturasComunicados > 0) {
      const docsTodosComunicados = this.invoice.resumoFicheiro.numeroFacturasPorComunicar === 0;
      const modalInstance = this._cgModalService.showVanilla(EFaturaGenerateDocsModalComponent, {size: 'md'});
      const componentInstance: EFaturaGenerateDocsModalComponent = modalInstance.componentInstance;
      componentInstance.docsTodosComunicados = docsTodosComunicados;

      const soDocsPorComunicar = await modalInstance.result;
      response = await this._efaturaService.generateFatura(this._year, this._month, 1, soDocsPorComunicar);
    } else {
      response = await this._efaturaService.generateFatura(this._year, this._month, 1, true);
    }

    if (response.status === EStatusCode.OK) {
      // Updating menu's caption
      this._mnuMeses.caption = this._mnuMeses.caption.replace('não ', '');

      // Reload year/month
      await this._changeYear(this._yearData, true);

      // refresh invoice
      await this._getInvoice();
    }
  }

  private _transformInvoice(data: IJsonEFatura): IJsonEFatura {
    const invoice = data;

    if (invoice.documentos.length > 0) {
      invoice.documentos = invoice.documentos.map((documento) => {
        let docState = EFaturaEstadoDocumento.NORMAL;

        if (documento.anulado) {
          docState = EFaturaEstadoDocumento.ANULADO;
        }
        if (documento.posFacturado) {
          docState = EFaturaEstadoDocumento.FATURADO;
        }
        documento.estado = docState;
        documento.estadoStr = this._translateService.instant(docState);

        // Verificar se existe algum ficheiro não incluído (relativo ao botão gerar eFatura)
        if (!documento.jaComunicado) {
          documento.comunicado = EFaturaDocumentoComunicado.NAO;
        } else {
          documento.comunicado = EFaturaDocumentoComunicado.SIM;
        }
        documento.comunicadoStr = this._translateService.instant(documento.comunicado === EFaturaDocumentoComunicado.SIM ? 'global.btn.yes' : 'global.btn.no');

        // Se documento não tiver erro/aviso, retira-o
        if (documento.erroNumero === 0) {
          documento.erroNumero = null;
        }

        return documento;
      });
    }

    return invoice;
  }

  private _formatFileName(fileName: string): string {
    if (!isString(fileName)) {
      return fileName;
    }
    return (
      fileName
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        .substring(fileName.lastIndexOf('_') + 1, fileName.length - 4)
        .replace(/a/g, ' a ')
        .replace(/\./g, '-')
    );
  }

  private _showConfigModal(): void {
    const modalInstance = this._cgModalService.showVanilla(EFaturaConfigModalComponent, {size: 'lg'});
    modalInstance.result.then((result: IJsonEFaturaConfiguracao) => {
      this.configEFatura = result;
      this._btnComunicaDocs.visible = this.configEFatura.temFacturacaoElectronica;
    });
  }

  private async _comunicaDocs(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ComunicaDocsModalComponent);
    const componentInstance: ComunicaDocsModalComponent = modalInstance.componentInstance;
    componentInstance.docsList = this._listaDocsNaoComunicados;
    const result: Array<number> = await modalInstance.result;
    await this._efaturaService.comunicaDocs(result);
    await this._getInvoice();
  }

  private _openModuleSaftGlobal(): Promise<void> {
    if (!this._maintenanceSaftGlobal) {
      this._maintenanceSaftGlobal = this._moduleMaintenanceService.build(MODULE_NAME_SAFT);
    }
    const moduleParams: ISAFTStateParams = {
      ano: this._year,
      mes: this._month
    };
    return this._maintenanceSaftGlobal.maintenance({params: moduleParams});
  }

  private _openModuleSaftAutofaturacao(): Promise<void> {
    if (!this._maintenanceSaftGlobal) {
      this._maintenanceSaftGlobal = this._moduleMaintenanceService.build(MODULE_NAME_SAFT);
    }
    this._maintenanceSaftGlobal.stateMaintenance.data.pageTitle = this._translateService.instant('saft.title.autofaturacao');
    return this._maintenanceSaftGlobal.maintenance({params: {exportSaftType: ESAFTType.AUTOFATURACAO}});
  }

  private _openModalLogs(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(EFaturaLogsModalComponent, {size: 'xxl'});
    return modalInstance.result;
  }
}
