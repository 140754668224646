<div class="apuraiva">
  <pl-form>
    <pl-nav-wizard [definition]="definition" [beforeStepChange]="fnBeforeStepChange" [onFinalize]="fnFinalize" [model]="selectedStep" (modelChange)="selectedStep = $event">
      <pl-nav-wizard-step caption="apuraiva.steps.filter" icon="fa-filter" [validator]="fnValidatorStep1">
        <div *plNavWizardStepContent class="entity-detail-form">
          <pl-form [(formInstance)]="forms.step1" formInstanceName="filter" [properties]="{validators: {required: {value: true}}}">
            <div class="row">
              <div class="col-md-4">
                <pl-group>
                  <label [translate]="'apuraiva.fields.period'"></label>
                  <edit>
                    <pl-edit
                      type="autocomplete"
                      attrName="periodo"
                      [model]="model.nPeriodo"
                      (modelChange)="changedPeriodo($event)"
                      [properties]="{source: periodos, output: 'periodo', rowTemplate: fnGetRowPeriodo}">
                    </pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <label>
                    <span [translate]="'apuraiva.fields.diarios'"></span>
                    <pl-tooltip class="tooltip-info-label" [config]="{text: 'apuraiva.tooltip', placement: ['right', 'bottom']}">
                      <i *plTooltipContent class="fa fa-info-circle fa-fw text-info"></i>
                    </pl-tooltip>
                  </label>
                  <edit>
                    <entity-autocomplete
                      entity="diarios"
                      attrName="diario"
                      [model]="model"
                      (selectedKeyChange)="model.nDiario = $event"
                      (evtSelectedDescriptionChanged)="model.nomeDiario = $event"
                      [fieldsMap]="{nome: 'nomeDiario'}"
                      [properties]="{readonly: true}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
                <pl-group>
                  <label>
                    <span [translate]="'apuraiva.fields.descriptive'"></span>
                    <pl-tooltip class="tooltip-info-label" [config]="{text: 'apuraiva.tooltip', placement: ['right', 'bottom']}">
                      <i *plTooltipContent class="fa fa-info-circle fa-fw text-info"></i>
                    </pl-tooltip>
                  </label>
                  <edit>
                    <entity-autocomplete
                      entity="descritivos"
                      attrName="descritivo"
                      [model]="model"
                      (selectedKeyChange)="model.descritivo = $event"
                      (evtSelectedDescriptionChanged)="model.nomeDescritivo = $event"
                      [fieldsMap]="{nDescrit: 'descritivo', nome: 'nomeDescritivo'}"
                      [properties]="{readonly: true}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'docscontabilidade.fields.descricao'"></label>
                  <edit>
                    <pl-edit type="text" attrName="descricao" [(model)]="model.descricao" [properties]="{events: {keydown: keydownAvancar}}"></pl-edit>
                  </edit>
                </pl-group>
              </div>
            </div>
          </pl-form>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step caption="apuraiva.steps.apuramento" icon="fa-calendar-check-o" [validator]="fnValidatorStep2">
        <div *plNavWizardStepContent class="entity-detail-form">
          <pl-form [(formInstance)]="forms.step2" formInstanceName="apuramento">
            <pl-group>
              <pl-group [attrName]="'ivaDedutivel'">
                <label [translate]="'apuraiva.fields.ivaDedutivel'"></label>
                <edit>
                  <span class="form-control-align">{{ model.ivaDedutivel | cgcCurrency }}</span>
                </edit>
              </pl-group>

              <pl-group [attrName]="'ivaLiquidado'">
                <label [translate]="'apuraiva.fields.ivaLiquidado'"></label>
                <edit>
                  <span class="form-control-align">{{ model.ivaLiquidado | cgcCurrency }}</span>
                </edit>
              </pl-group>

              <pl-group [attrName]="'ivaApurado'">
                <label [translate]="'apuraiva.fields.ivaApurado'"></label>
                <edit>
                  <span class="form-control-align">{{ model.ivaApurado | cgcCurrency }}</span>
                </edit>
              </pl-group>

              <pl-group [attrName]="'aFavorDaEmpresa'">
                <label [translate]="'apuraiva.fields.aFavorDaEmpresa'"></label>
                <edit>
                  <span class="form-control-align" [translate]="model.aFavorDaEmpresa ? 'apuraiva.fields.aFavorDaEmpresa0' : 'apuraiva.fields.aFavorDaEmpresa1'"></span>
                </edit>
              </pl-group>
            </pl-group>

            <h4 class="page-header" [translate]="'apuraiva.titles.regularizations'"></h4>

            <pl-group>
              <pl-group [attrName]="'ivaRegFavEmpresa'">
                <label [translate]="'apuraiva.fields.ivaRegFavEmpresa'"></label>
                <edit>
                  <span class="form-control-align">{{ model.ivaRegFavEmpresa | cgcCurrency }}</span>
                </edit>
              </pl-group>

              <pl-group [attrName]="'ivaVariacoesProRata'">
                <label [translate]="'apuraiva.fields.ivaVariacoesProRata'"></label>
                <edit>
                  <span class="form-control-align">{{ model.ivaVariacoesProRata | cgcCurrency }}</span>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group [attrName]="'ivaRegFavEstado'">
                <label [translate]="'apuraiva.fields.ivaRegFavEstado'"></label>
                <edit>
                  <span class="form-control-align">{{ model.ivaRegFavEstado | cgcCurrency }}</span>
                </edit>
              </pl-group>

              <pl-group [attrName]="'ivaRegComunicadasPeloSIVA'">
                <label [translate]="'apuraiva.fields.ivaRegComunicadasPeloSIVA'"></label>
                <edit>
                  <span class="form-control-align">{{ model.ivaRegComunicadasPeloSIVA | cgcCurrency }}</span>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group [attrName]="'ivaRegCalculoProRata'">
                <label [translate]="'apuraiva.fields.ivaRegCalculoProRata'"></label>
                <edit>
                  <span class="form-control-align">{{ model.ivaRegCalculoProRata | cgcCurrency }}</span>
                </edit>
              </pl-group>

              <pl-group [attrName]="'ivaRegOutrasAnuais'">
                <label [translate]="'apuraiva.fields.ivaRegOutrasAnuais'"></label>
                <edit>
                  <span class="form-control-align">{{ model.ivaRegOutrasAnuais | cgcCurrency }}</span>
                </edit>
              </pl-group>
            </pl-group>

            <h4 class="page-header" [translate]="'apuraiva.titles.details'"></h4>

            <pl-tabs [theme]="'style3'">
              <pl-tab caption="apuraiva.titles.tabDestination">
                <div *plTabContent>
                  <h5 class="page-header" [translate]="'apuraiva.titles.information'"></h5>

                  <pl-group>
                    <pl-group [attrName]="'saldoAnterApuramento'">
                      <label [translate]="'apuraiva.fields.saldoAnterApuramento'"></label>
                      <edit>
                        <span class="form-control-align">{{ model.saldoAnterApuramento | cgcCurrency }}</span>
                      </edit>
                    </pl-group>

                    <pl-group [attrName]="'saldoAnterIVAAPagar'">
                      <label [translate]="'apuraiva.fields.saldoAnterIVAAPagar'"></label>
                      <edit>
                        <span class="form-control-align">{{ model.saldoAnterIVAAPagar | cgcCurrency }}</span>
                      </edit>
                    </pl-group>

                    <pl-group [attrName]="'saldoAnterIVAARecuperar'">
                      <label [translate]="'apuraiva.fields.saldoAnterIVAARecuperar'"></label>
                      <edit>
                        <span class="form-control-align">{{ model.saldoAnterIVAARecuperar | cgcCurrency }}</span>
                      </edit>
                    </pl-group>

                    <pl-group [attrName]="'saldoAnterIVAAReembolsar'">
                      <label [translate]="'apuraiva.fields.saldoAnterIVAAReembolsar'"></label>
                      <edit>
                        <span class="form-control-align">{{ model.saldoAnterIVAAReembolsar | cgcCurrency }}</span>
                      </edit>
                    </pl-group>
                  </pl-group>

                  <h5 class="page-header" [translate]="'apuraiva.titles.destination'"></h5>

                  <pl-group>
                    <pl-group [attrName]="'ivaAPagar'">
                      <label [translate]="'apuraiva.fields.ivaAPagar'"></label>
                      <edit>
                        <pl-edit type="number" [(model)]="model.ivaAPagar" attrName="ivaPagar" (modelChange)="changedValue()"></pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group [attrName]="'ivaARecuperar'">
                      <label [translate]="'apuraiva.fields.ivaARecuperar'"></label>
                      <edit>
                        <pl-edit type="number" [(model)]="model.ivaARecuperar" attrName="ivaRecuperar" (modelChange)="changedValue()"></pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group [attrName]="'ivaAReembolsar'">
                      <label [translate]="'apuraiva.fields.ivaAReembolsar'"></label>
                      <edit>
                        <pl-edit type="number" [(model)]="model.ivaAReembolsar" attrName="ivaReembolsar" (modelChange)="changedValue()"></pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group>
                      <edit>
                        <div class="text-danger" *ngIf="!!error">{{ getTranslatedError() }}</div>
                      </edit>
                    </pl-group>
                  </pl-group>
                </div>
              </pl-tab>

              <pl-tab caption="apuraiva.titles.tabDetails">
                <div *plTabContent>
                  <div class="row">
                    <div class="col-sm-12">
                      <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="apuraIvaTitlesTabDetails" [dataSource]="dataGridDefinition.dataSource"></dx-data-grid>
                    </div>
                  </div>
                </div>
              </pl-tab>
            </pl-tabs>
          </pl-form>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step caption="apuraiva.steps.fimperiodo" icon="fa-calendar-times-o">
        <div *plNavWizardStepContent class="entity-detail-form">
          <pl-form [(formInstance)]="forms.step3" formInstanceName="fimperiodo">
            <h4><strong [translate]="'apuraiva.titles.change'"></strong></h4>
            <pl-group>
              <pl-group [attrName]="'periodCurrent'">
                <label [translate]="'apuraiva.fields.periodCurrent'"></label>
                <edit>
                  <span class="form-control-align">{{ model.nPeriodo }}</span>
                </edit>
              </pl-group>

              <pl-group [attrName]="'periodNext'">
                <label [translate]="'apuraiva.fields.periodNext'"></label>
                <edit>
                  <pl-edit
                    type="autocomplete"
                    attrName="novoPeriodo"
                    [model]="model.proximoPeriodo"
                    (modelChange)="changedProximoPeriodo($event)"
                    [properties]="{source: periodos, output: 'periodo', rowTemplate: outputPeriodo}">
                  </pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group [attrName]="'diarios'">
              <label [translate]="'apuraiva.fields.diarios'"></label>
              <edit>
                <pl-multiselect
                  key="nDiario"
                  [model]="diarios"
                  [source]="diariosPossiveis"
                  [template]="outputDiarios"
                  [properties]="{
                    leftCaption: 'fimperiodoenc.titleDiariosDisponiveis',
                    rightCaption: 'fimperiodoenc.titleDiariosSelecionados'
                  }">
                </pl-multiselect>
              </edit>
            </pl-group>
          </pl-form>
        </div>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </pl-form>
</div>
