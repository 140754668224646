<div class="concelhos">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form
        [model]="model"
        [definition]="formDefinition"
        [template]="formTemplate"
        [properties]="{readonly: type === stateTypes.DETAIL, orientation: formOrientation}"
        [plPromise]="promise"></pl-form>

      <pl-form class="entity-detail-form" [properties]="{readonly: type === stateTypes.DETAIL, orientation: formOrientation}">
        <pl-group>
          <label [translate]="'concelhos.fields.nomeDistrito'"></label>
          <edit>
            <entity-autocomplete
              entity="distritos"
              attrName="codDistrito"
              [model]="model"
              (selectedKeyChange)="model.codDistrito = $event; loadListaConcelhosAT(true)"
              (evtSelectedDescriptionChanged)="model.nomeDistrito = $event"
              [properties]="{validators: {required: {value: true}}}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'concelhos.fields.codConcelhoAT'"></label>
          <edit>
            <pl-autocomplete
              attrName="codConcelhoAT"
              [source]="listConcelhosAT"
              [model]="model.nomeConcelhoAT"
              (evtSelected)="evtConcelhoATChanged($event)"
              [rowTemplate]="concelhoATTemplate"
              [output]="concelhoATTemplate">
            </pl-autocomplete>
          </edit>
        </pl-group>

        <fieldset *ngIf="type !== entityState.NEW" class="mt-2">
          <legend [translate]="'concelhos.cardderrama'"></legend>
          <pl-group>
            <pl-group>
              <label [translate]="'global.text.year'"></label>
              <edit>
                <pl-edit-select [model]="anoDerrama" [source]="anosDerrama" (modelChange)="anoDerramaChanged($event)" [properties]="{disallowClear: true}"></pl-edit-select>
              </edit>
            </pl-group>
            <pl-group>
              <pl-button klass="btn-primary btn-sm action-calc-derrama" [onClick]="loadDerrama" [disabled]="disableAnoDerrama">
                <i class="fa fa-fw fa-percent"></i>&nbsp;<span [translate]="'concelhos.cardderrama'"></span>
              </pl-button>
            </pl-group>
          </pl-group>
        </fieldset>
      </pl-form>
    </div>
  </entity-detail>
</div>
