import {Component, Injector, OnInit} from '@angular/core';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonPais} from '../../jsonPais.entity.interface';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {tipoMercado} from '../../paises.entity.interface';
import {isDefinedNotNull, isEmptyObject, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {DATA_SOURCE_PAISES_ISO_3166, isPaisIntracomunitario} from '../../../../datasources/paisesiso3166/paisesISO3166.datasource';
import {ENTITY_NAME_MOEDA, IMoedaEntityService} from '../../../../modules/portalcontabilidade/manutencao/moeda/moeda.entity.interface';
import {IJsonMoeda} from '../../../../modules/portalcontabilidade/manutencao/moeda/jsonMoeda.interface';
import {IPaisesISO3166} from '../../../../datasources/paisesiso3166/paisesISO3166.datasource.interface';
import {IApiRequestConfigWithBody} from '../../../../services/api/api.service.interface';
import {UntypedFormGroup} from '@angular/forms';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../config/constants';

@Component({
  selector: 'pagamentos-edit',
  templateUrl: './paises.entity.edit.component.html'
})
export class PaisesEditComponent extends ModuloEntityDetailComponent<IJsonPais> implements OnInit {
  public isDetailState: boolean;
  public isEditState: boolean;
  public fieldsDisabled: boolean;
  public formInstance: UntypedFormGroup;
  public readonly tipoMercadoSource = tipoMercado;

  private readonly _serviceMoedas: IMoedaEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._serviceMoedas = this._entityServiceBuilder.build<IJsonMoeda, IMoedaEntityService>(ENTITY_NAME_MOEDA);
    this.fieldsDisabled = true;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.isDetailState = this.type === EEntityStateDetailType.DETAIL;
    this.isEditState = this.type === EEntityStateDetailType.EDIT;
  }

  public save(config?: IApiRequestConfigWithBody<IJsonPais>): Promise<IJsonPais> {
    if (isEmptyObject(this.model) || !this.formInstance.valid) {
      this._plAlertService.error('error.invalidForm');
      return Promise.reject(FORM_INVALID_CANNOT_SUBMIT);
    }
    return super.save(config);
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    this.isDetailState = stateType === EEntityStateDetailType.DETAIL;
    this.isEditState = stateType === EEntityStateDetailType.EDIT;
    this.fieldsDisabled = stateType === EEntityStateDetailType.NEW;
    return super.onUpdate(stateType);
  }

  public codInternacionalChanged(codigo: string): void {
    this.fieldsDisabled = true;
    if (isDefinedNotNull(codigo)) {
      const pais = DATA_SOURCE_PAISES_ISO_3166.data.find((item) => item.codigo === codigo);
      if (pais) {
        this.fieldsDisabled = false;
        this._fillMoeda(pais).then((newModel) => {
          newModel.codInternacional = codigo;
          this.model = newModel;
        });
      }
    } else {
      this.model = {
        abrevFiscal: '',
        codPais: undefined,
        nomePais: '',
        abrevPais: '',
        nomePaisPropriaLingua: '',
        mercado: undefined,
        codMoeda: undefined,
        codLingua: undefined,
        codInternacional: '',
        nomeMoeda: ''
      };
    }
  }

  private _fillMoeda(pais: IPaisesISO3166): Promise<IJsonPais> {
    const newModel: IJsonPais = {
      abrevFiscal: pais.codigoAlpha2,
      codPais: Number(pais.codigo),
      nomePais: pais.designacaoPT,
      abrevPais: pais.codigoAlpha3,
      nomePaisPropriaLingua: pais.nomePaisPL,
      mercado: isPaisIntracomunitario(pais) ? 0 : 1,
      codMoeda: undefined,
      codLingua: undefined,
      codInternacional: undefined,
      nomeMoeda: undefined
    };

    return new Promise((resolve) => {
      this._serviceMoedas
        .query({pesquisa: `abreviaturaMoeda=${pais.abrevMoeda}`, reportExceptions: false})
        .then((response) => {
          if (response.body.list.length) {
            newModel.codMoeda = response.body.list[0].codMoeda;
            newModel.nomeMoeda = response.body.list[0].nomeMoeda;
          }
        })
        .finally(() => {
          if (isUndefinedOrNull(newModel.codMoeda)) {
            this._getMoedaDaEmpresa().then((moeda) => {
              newModel.codMoeda = moeda.codMoeda;
              newModel.nomeMoeda = moeda.nomeMoeda;
              resolve(newModel);
            });
          } else {
            resolve(newModel);
          }
        });
    });
  }

  private _getMoedaDaEmpresa(): Promise<IJsonMoeda> {
    return this._serviceMoedas.get({id: this._configService.configurations.empresa.codMoeda}).then((response) => response.body);
  }
}
