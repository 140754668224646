import {BehaviorSubject} from 'rxjs';
import {ERadix, isDefinedNotNull, isEmpty, isString, TOrientation} from 'pl-comps-angular';
import {DOCKER, ENV} from '../../environments/constants';
import {ETypeKind, IJsonConfigERP} from '../core/entities/configserp/jsonConfigERP.entity.interface';
import {ROLE} from '../core/services/role.const';
import {TUserSession} from '../core/services/account/jsonUserApi.interface';

export enum EGroupName {
  GLOBAL = 'global',
  ERP = 'erp',
  PCA = 'myaccounting',
  COLABORADOR = 'colaborador',
  GESTOR_EMENTAS = 'gestorementas',
  GESTOR_RH = 'gestorrh',
  GESTOR_SERVICO = 'gestorservico',
  CONTABILIDADE = 'contabilidade',
  RECURSOSHUMANOS = 'recursoshumanos',
  ADMINITRACAO = 'administracao',
  FINANCEIRO = 'financeiro'
}

export enum EGlobalEvent {
  USER_CHANGED = 'app.userChanged'
}

export enum EEnvironment {
  DEV = 'CG_ENV_DEV',
  PROD = 'CG_ENV_PROD',
  TEST = 'CG_ENV_TEST'
}

export enum EStatusCode {
  NoResponse = 0,
  OK = 200,
  NoContent = 204,
  NotModified = 304,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503
}

export const API_CENTRALGEST = 'https://www.centralgest.com';
export const API_CENTRALGEST_HELPER = `${API_CENTRALGEST}/helper/api`;
export const API_FAQS = 'https://www.centralgestcloud.com/faqs-feed.php?moduloerpcloud=';
export const API_PORTAL_PREFIX = '$P';
export const API_AUTHENTICATION_URL_PARAM_NAME = 'api_key';
export const API_FEATURE_FLAGS = 'https://unleash-qa.centralgest.com/api/frontend';
export const API_RUNTIME_PROPERTIES = '/config/properties.json';
export const APPLICATION_ICON_PREFIXES: ReadonlyArray<string> = Object.freeze(['fa', 'fa-fw', 'icofont']);
export const CONSTANT_COD_PAIS_NACIONAL = 351;
export const CONSTANT_NOME_PAIS_NACIONAL = 'Portugal';
export const CONSTANT_ABREV_FISCAL_PAIS_NACIONAL = 'PT';
export const CG_ACADEMY_URL = 'https://www.centralgest.com/servicos/centralgest-academy';
export const CG_COMPANY_URL = 'https://www.centralgest.com/';
export const CG_SUPPORT_URL = 'https://www.centralgestcloud.com/faqs';
export const CSS_CLASS_LAUNCH_MODE_HYBRID = 'cg-launch-mode-hybrid';
export const CSS_CLASS_LAUNCH_MODE_HYBRID_PARTIAL = 'cg-launch-mode-hybrid-partial';
export const CSS_CLASS_MODAL_FULLSCREEN = 'modal-fullscreen';
export const DATE_MONTHS_LENGTH = 12;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const DATAGRID_ALLOWED_PAGE_SIZES: ReadonlyArray<number> = Object.freeze([10, 25, 50]);
export const DEFAULT_CURRENCY_CODE = 'EUR';
export const DEFAULT_TIMEOUT = 50;
export const DEFAULT_TOOLBAR_ID = 'modulo';
export const DEFAULT_FORM_ORIENTATION: TOrientation = 'vertical';
export const DELPHI_DEFAULT_DATE_YEAR = 1899;
export const EMPTY_GUID = '{00000000-0000-0000-0000-000000000000}';
export const EMPTY_PASSWORD = '********';
export const FORM_INVALID_CANNOT_SUBMIT = 'Form is invalid, cannot submit.';
export const GLOBAL_EVENT_PREFIX = 'app';
export const GLOBAL_EVENT_HTTP_ERROR = `${GLOBAL_EVENT_PREFIX}.httpError`;
export const GLOBAL_EVENT_USER_CHANGED = `${GLOBAL_EVENT_PREFIX}.userChanged`;
export const GLOBAL_EVENT_LOGO_CHANGED = `${GLOBAL_EVENT_PREFIX}.logoChanged`;
export const HTTP_HEADER_EXCEPTION = 'exception';
export const HTTP_HEADER_X_AUTH_TOKEN = 'x-auth-token';
export const HTTP_HEADER_X_AUTH_NEMPRESA = 'x-auth-nempresa';
export const HTTP_HEADER_X_SET_AUTH_TOKEN = 'X-Set-Auth-Token';
export const HTTP_HEADER_X_AUTH_USER_FEEDBACK_TOKEN = 'x-auth-user-feedback-token';
export const LINK_CENTRALGEST_VIDEOS_CLOUD = 'https://www.centralgest.com/videos/cloud';
export const LINK_CENTRALGEST_FAQS_CLOUD = 'https://www.centralgestcloud.com/faqs';
export const LOCAL_STORAGE_DOMAIN = 'centralgest';
export const LOCAL_STORAGE_IDENTIFIER = 'erpcloud';
export const LOCAL_STORAGE_PREFIX = `${LOCAL_STORAGE_DOMAIN}.${LOCAL_STORAGE_IDENTIFIER}`;
export const LOCAL_STORAGE_APP = `${LOCAL_STORAGE_PREFIX}.app`;
export const LOCAL_STORAGE_APP_LAUNCH_MODE = `${LOCAL_STORAGE_APP}.launchMode`;
export const LOCAL_STORAGE_APP_API_KEY = `${LOCAL_STORAGE_APP}.apiKey`;
export const LOCAL_STORAGE_APP_THEME = `${LOCAL_STORAGE_APP}.theme`;
export const LOCAL_STORAGE_AMALIA = 'amalia';
export const LOCAL_STORAGE_AMALIA_WINDOW_WIDTH = `${LOCAL_STORAGE_AMALIA}.windowWidth`;
export const LOCAL_STORAGE_CONSENT = 'consent';
export const LOCAL_STORAGE_LOCALE = `${LOCAL_STORAGE_PREFIX}.locale`;
export const LOCAL_STORAGE_REFRESHED = `${LOCAL_STORAGE_PREFIX}.refreshed`;
export const LOCAL_STORAGE_SIDEBAR = 'sidebar';
export const LOCAL_STORAGE_SIDEBAR_TOGGLED = `${LOCAL_STORAGE_SIDEBAR}.toggled`;
export const LOCAL_STORAGE_USER_FEEDBACK_TOKEN = 'userFeedbackToken';
export const NAVBAR_TOP_OFFSET = 60;
export const NAVBAR_TOP_OFFSET_COMPENSATION = 5;
export const NAVBAR_TOP_OFFSET_WITH_COMPENSATION = NAVBAR_TOP_OFFSET + NAVBAR_TOP_OFFSET_COMPENSATION;
export const REGEX_INTERPOLATION = new RegExp('{{(.*?)}}', 'gi');
export const REGEX_API_PORTAL_ID = new RegExp(`\\${API_PORTAL_PREFIX}(\\d+)`, 'g');
export const TOOLBAR_GROUP_ID_PCA = 'pca';
export const TOOLBAR_ID_MAINTENANCE_MODAL = 'maintenancemodal';
export const UNDEFINED_ID = -1;

export const CONFIG_LAST_PORTAL: IJsonConfigERP = {
  name: 'portais.lastPortal',
  value: undefined,
  kind: ETypeKind.String,
  cguid: undefined,
  description: '',
  readOnly: false
};

export const DEFAULT_APP_LOCALE = 'pt-PT';
export const APP_LOCALE = new BehaviorSubject<string>(window?.localStorage?.getItem(LOCAL_STORAGE_LOCALE) || DEFAULT_APP_LOCALE);

export function isDev(): boolean {
  return <unknown>ENV === EEnvironment.DEV;
}

export function isProduction(): boolean {
  return <unknown>ENV === EEnvironment.PROD;
}

export function isTest(): boolean {
  return <unknown>ENV === EEnvironment.TEST;
}

export function isDebug(): boolean {
  return <unknown>ENV !== EEnvironment.PROD;
}

export function isDocker(): boolean {
  return DOCKER;
}

export function storeName(session: TUserSession, name: string): string {
  return `${session.userId}.${session.erp.nEmpresa}.${name}`;
}

export function storeNameGlobal(session: TUserSession): string {
  return storeName(session, EGroupName.GLOBAL);
}

export function storeNameErp(session: TUserSession): string {
  return storeName(session, EGroupName.ERP);
}

export function storeNamePca(session: TUserSession): string {
  return storeName(session, EGroupName.PCA);
}

export function storeNameColaborador(session: TUserSession): string {
  return storeName(session, EGroupName.COLABORADOR);
}

export function storeNameGestorEmentas(session: TUserSession): string {
  return storeName(session, EGroupName.GESTOR_EMENTAS);
}

export function storeNameRh(session: TUserSession): string {
  return storeName(session, EGroupName.GESTOR_RH);
}

export function storeNameGestorServico(session: TUserSession): string {
  return storeName(session, EGroupName.GESTOR_SERVICO);
}

export function storeNameContabilidade(session: TUserSession): string {
  return storeName(session, EGroupName.CONTABILIDADE);
}

export function generatePortalId(id: number): ROLE {
  return <ROLE>(API_PORTAL_PREFIX + String(id));
}

export function extractPortalId(id: string | ROLE): number {
  if (!isEmpty(id)) {
    const extractedId: string = id.replace(REGEX_API_PORTAL_ID, '$1');
    if (extractedId) {
      return parseInt(extractedId, ERadix.Decimal);
    }
  }
  return undefined;
}

export function interpolateTemplateToKeys(template: string): Array<string> {
  if (isEmpty(template)) {
    return [];
  }
  return Array.from(template.matchAll(REGEX_INTERPOLATION)).reduce((accumulator, matchArray: RegExpMatchArray) => {
    if (matchArray[1]) {
      accumulator.push(matchArray[1]);
    }
    return accumulator;
  }, []);
}

const SECURE_PASSWORD_MIN_LENGTH = 8;
const SECURE_PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!"#$%&/()=?+{}[\]\\«»´`ºª\-_.:,;]+$/g;

export function validatePasswordSecureMode(value: string): boolean {
  return isString(value) && value.length >= SECURE_PASSWORD_MIN_LENGTH && isDefinedNotNull(value.match(SECURE_PASSWORD_REGEX));
}
