import {deepFreeze} from 'pl-comps-angular';
import {ConfigService} from '../../services/config/config.service';
import {EGrupoDoc} from '../../datasources/grupodoc/grupoDoc.datasource.interface';
import {ICGConfigDocumentoComercialTipo} from '../../services/config/config.service.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonArtigo} from '../artigos/jsonArtigo.entity.interface';
import {IJsonDocComercial, IJsonDocComercialCab, IJsonDocComercialLinha, IJsonDocComercialPrestacaoItem, IJsonDocResumoIvaItem} from './jsonDocComercial.entity.interface';
import {Moment} from 'moment';
import {TDate} from '../../../common/dates';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {TTableLegend} from '../../components/tablelegend/tablelegend.component.interface';
import {TClifos} from '../clifos/clifos.entity.interface';
import {IAdiantamentosRegistoResult} from '../../modules/adiantamentos/adiantamentos.module.interface';
import {IConfigOptionsEvaluateInvisibleParams} from '../../components/configoptions/config.options.component.interface';
import {IDocsComerciaisEditConfigOptions, IDocsComerciaisListConfigOptions} from '../../services/config/options/config.options.service.interface';
import {IComercialDigitalGDocViewerDoc} from '../../components/arquivodigital/comercialdigital/docviewer/comercialDigital.docViewer.interface';
import {IJsonTxtar} from '../txtar/jsonTxtar.entity.interface';
import {IJsonTxtcl} from '../txtcl/jsonTxtcl.entity.interface';
import {EPeriodicidade} from '../../datasources/periodicidade/periodicidade.datasource.interface';

export enum EDocsComerciaisClifoStatus {
  None = 0,
  Existe = 1,
  ExisteAlternativo = 2,
  ExisteNif = 3,
  ExisteNifAlternativo = 4,
  Novo = 5,
  NovoAlternativo = 6,
  ExisteNifAlternativoEspecifico = 7,
  ExisteNaoValidaNif = 8,
  ExisteNaoActualizaNaoValidaNif = 9
}

export enum EDocsComerciaisListTab {
  Documentos = 'documentos',
  Editaveis = 'editaveis',
  PorProcessar = 'porProcessar',
  Processado = 'processado'
}

export enum EDocsComerciaisTableLegendColors {
  DocumentoComercialAnulado = 'documento-comercial-anulado'
}

export enum ESubTipoMovimentoSAFT {
  NaoAplicavel,
  EntregaEfectiva,
  DocumentoGlobal,
  EntregaEfectivaSobreDocumentoGlobal
}

export enum EFluxoMonetario {
  NaoDefinido,
  NaoTem,
  PagoNoActo,
  Credito,
  CompraPagaNoActo,
  CompraCredito
}

interface IDocsComerciaisService {
  getByDraft(nDocfaDraft: number, nNumerDraft: number, nDocumentoDraft: number): TServiceResponse<IJsonDocComercial>;

  sendEmail(faccbId: number, nvias: number, rptname: string, segundavia: boolean, email: string, forceoriginal: boolean): TServiceResponse<void>;

  getDocContabId(faccbId: number): TServiceResponse<string>;
}

export interface IDocsComerciaisCabEntityService extends IEntityService<IJsonDocComercialCab>, IDocsComerciaisService {}

export interface IDocsComerciaisEntityService extends IEntityService<IJsonDocComercial>, IDocsComerciaisService {}

export interface IDocsComerciaisEntity extends IEntityDefinition<IJsonDocComercial, IDocsComerciaisEntityService> {}

export interface IDocsComerciaisGenerateEntityData {
  name: string;
  grupo: EGrupoDoc;
  clifos: TClifos;
  sidebarTitle?: string;
  metadata?: Partial<IEntityDefinition['metadata']>;
  helperLinks?: IEntityDefinition['helperLinks'];
  resolve: (config: ConfigService) => Partial<IDocsComerciaisGroup>;
}

export interface IDocsComerciaisGroupProperties {
  autofaturacao: boolean;
}

export interface IDocsComerciaisGroup {
  grupo: EGrupoDoc;
  nDocFa: ICGConfigDocumentoComercialTipo;
  clifos: TClifos;
  properties?: IDocsComerciaisGroupProperties;
}

export interface IDocComerciaisListStateParams {
  tab?: EDocsComerciaisListTab;
}

export interface IDocComercialClifoInfo {
  nome: string;
  nContribuint: string;
  nConta: string;
}

export interface IDocComercialCab extends IJsonDocComercialCab {
  identificaCarga?: boolean;
  tipoMovimentoStock?: number;
  integraStocks?: boolean;
}

export interface IDocComercialLinha extends IJsonDocComercialLinha {
  dirty?: boolean;
  showTextoLivre?: boolean;
  uid?: string;
  textosLivresArtigo?: Array<IJsonTxtar>;
  textosLivresClifo?: Array<IJsonTxtcl>;
}

export interface IDocComercial extends IJsonDocComercial {
  cab?: IDocComercialCab;
  linhas?: Array<IDocComercialLinha>;
}

export interface IDocComercialEditStateParams {
  id?: number;
  doc?: IDocComercial;
  adiantamentos?: IAdiantamentosRegistoResult;
  grupo?: EGrupoDoc;
}

export interface IPesquisaDocsFilters {
  deConta: string;
  ateConta: string;
  dataDe: TDate;
  dataAte: TDate;
  docfalist: Array<number>;
}

export interface IDocComercialCabWithData extends IJsonDocComercialCab {
  _doc?: IJsonDocComercial;
  _fileNamePDF?: string;
  _urlPDF?: string;
  _digitalGDocViewerDoc?: IComercialDigitalGDocViewerDoc;
}

export interface IDocResumoIvaItemGrid extends IJsonDocResumoIvaItem {
  index: number;
  liquidoComArredAuto?: number;
  ivaComArredAuto?: number;
  arredLiquidoManualEditavel?: number;
  arredIVAManualEditavel?: number;
  incidenciaTotalEditavel?: number;
  ivaTotalEditavel?: number;
  totalLiquido?: number;
  totalIva?: number;
  total?: number;
}

export interface IDocResumoIvaTotais {
  total: number;
  totalIncidencia: number;
  totalIva: number;
}

export interface IDocComercialLinhaSerie {
  nArmazem: number;
  nArmazemSelected: boolean;
  nCCusto: string;
  nCCustoSelected: boolean;
  nrefprocesso: string;
  nrefprocessoSelected: boolean;
  nVendedor: number;
  nVendedorSelected: boolean;
  codIva: number;
  codIvaSelected: boolean;
  d1: number;
  d1Selected: boolean;
  taxaIva: number;
}

export interface IDocComercialArtigo extends IJsonArtigo {
  _timestamp?: Moment;
}

export interface IDocComercialStocks {
  nArmazem: number;
  qtd: number;
  qtdTotal: number;
  item: IDocComercialLinha;
  cache: Map<string, IDocComercialArtigo>;
}

export interface IJsonDocComercialPesqDocGlobais extends IJsonDocComercialCab {
  _selected?: boolean;
}

export interface IDocComercialDrag {
  dragging: boolean;
  dragOriginalElement: HTMLElement;
  dragOriginalIndex: number;
  dragOriginalNodeItem: IDocComercialLinha;
  dragCurrentIndex: number;
  dragCurrentNodeItem: IDocComercialLinha;
  dragPreviousIndex: number;
  dragPreviousClientY: number;
}

export interface IDocComercialDocCabFieldsVisibilityOptionsListView {
  showObservacoes: boolean;
  showDocExterno: boolean;
  showDataDocExterno: boolean;
  showDataVenc: boolean;
  showCCusto: boolean;
  showMoeda: boolean;
  showNRefProcesso: boolean;
  showNRequisicao: boolean;
  showNArmazem: boolean;
  showValorCIva: boolean;
  showLiquido: boolean;
  showCodUnidMov: boolean;
  showNVendedor: boolean;
  showD2: boolean;
  showD3: boolean;
  showDescEmValor: boolean;
  showPrestacoes: boolean;
}

export interface IDocComercialEvaluateConfigOptionInvisibleParams extends IConfigOptionsEvaluateInvisibleParams<boolean, IDocsComerciaisEditConfigOptions> {}

export interface IDocsComerciaisListEvaluateConfigOptionInvisibleParams extends IConfigOptionsEvaluateInvisibleParams<boolean, IDocsComerciaisListConfigOptions> {}

export interface IDocComercialPrestacoesModel {
  dateInicial: TDate;
  nPrestacoes: number;
  periodicidade: EPeriodicidade;
}

export interface IDocComercialPrestacaoItem extends IJsonDocComercialPrestacaoItem {
  nSeq: number;
}

export const ENTITY_NAME_DOCS_COMERCIAIS = 'docscomerciais';
export const ENTITY_NAME_DOCS_COMERCIAIS_COMPRAS = 'compras';
export const ENTITY_NAME_DOCS_COMERCIAIS_VENDAS = 'vendas';
export const ENTITY_NAME_DOCS_COMERCIAIS_GUIAS = 'guias';
export const ENTITY_NAME_DOCS_COMERCIAIS_GUIAS_FORNECEDORES = 'guiasfornecedores';
export const ENTITY_NAME_DOCS_COMERCIAIS_ENTRADAS_DIVERSAS = 'entradasdiversas';
export const ENTITY_NAME_DOCS_COMERCIAIS_SAIDAS_DIVERSAS = 'saidasdiversas';
export const ENTITY_NAME_DOCS_COMERCIAIS_PROPOSTAS_A_CLIENTES = 'propostasaclientes';
export const ENTITY_NAME_DOCS_COMERCIAIS_ENCOMENDAS_CLIENTES = 'encomendasclientes';
export const ENTITY_NAME_DOCS_COMERCIAIS_ENCOMENDAS_FORNECEDORES = 'encomendasfornecedores';
export const ENTITY_NAME_DOCS_COMERCIAIS_COMPRAS_AUTOFATURACAO = 'comprasautofaturacao';
export const ENTITY_NAME_DOCS_COMERCIAIS_CONSULTAS_A_FORNECEDORES = 'consultasafornecedores';

export const TABLE_LEGEND_DOCS_COMERCIAIS: TTableLegend = deepFreeze([{caption: 'docscomerciais.legend.anulado', badgeCSSClass: EDocsComerciaisTableLegendColors.DocumentoComercialAnulado}]);

export function docsComerciaisGrupoDocToEntityName(grupoDoc: EGrupoDoc): string {
  switch (grupoDoc) {
    case EGrupoDoc.Outros:
      return ENTITY_NAME_DOCS_COMERCIAIS;
    case EGrupoDoc.ComprasEfectivas:
      return ENTITY_NAME_DOCS_COMERCIAIS_COMPRAS;
    case EGrupoDoc.ConsultasAFornecedores:
      return ENTITY_NAME_DOCS_COMERCIAIS_CONSULTAS_A_FORNECEDORES;
    case EGrupoDoc.EncomendasClientes:
      return ENTITY_NAME_DOCS_COMERCIAIS_ENCOMENDAS_CLIENTES;
    case EGrupoDoc.EncomendasFornecedores:
      return ENTITY_NAME_DOCS_COMERCIAIS_ENCOMENDAS_FORNECEDORES;
    case EGrupoDoc.FolhasDeObra:
      return ENTITY_NAME_DOCS_COMERCIAIS_COMPRAS;
    case EGrupoDoc.GuiasTransporteRemessa:
      return ENTITY_NAME_DOCS_COMERCIAIS_GUIAS;
    case EGrupoDoc.PropostasAClientes:
      return ENTITY_NAME_DOCS_COMERCIAIS_PROPOSTAS_A_CLIENTES;
    case EGrupoDoc.ReservasClientes:
      return ENTITY_NAME_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.TrfArmazensEntradas:
      return ENTITY_NAME_DOCS_COMERCIAIS;
    case EGrupoDoc.TrfArmazensSaidas:
      return ENTITY_NAME_DOCS_COMERCIAIS;
    case EGrupoDoc.VendasEfectivas:
      return ENTITY_NAME_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.ConsignacoesClientes:
      return ENTITY_NAME_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.ConsignacoesFornecedores:
      return ENTITY_NAME_DOCS_COMERCIAIS_COMPRAS;
    case EGrupoDoc.RecibosClientes:
      return ENTITY_NAME_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.PagamentosFornecedores:
      return ENTITY_NAME_DOCS_COMERCIAIS_COMPRAS;
    case EGrupoDoc.None:
      return ENTITY_NAME_DOCS_COMERCIAIS;
    case EGrupoDoc.PlanoProducao:
      return ENTITY_NAME_DOCS_COMERCIAIS;
    case EGrupoDoc.PreEncomendasClientes:
      return ENTITY_NAME_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.CustosAdicionais:
      return ENTITY_NAME_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.NotaCredito:
      return ENTITY_NAME_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.AcertoCustoPadrao:
      return ENTITY_NAME_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.EntradasDiversas:
      return ENTITY_NAME_DOCS_COMERCIAIS_ENTRADAS_DIVERSAS;
    case EGrupoDoc.SaidasDiversas:
      return ENTITY_NAME_DOCS_COMERCIAIS_SAIDAS_DIVERSAS;
    case EGrupoDoc.GuiasTransporteFornecedores:
      return ENTITY_NAME_DOCS_COMERCIAIS_GUIAS_FORNECEDORES;
    default:
      return ENTITY_NAME_DOCS_COMERCIAIS;
  }
}
