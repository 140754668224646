import {merge, orderBy} from 'lodash-es';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {
  copy,
  ICGTableOnSelect,
  IPlNavWizardCallback,
  IPlNavWizardDefinition,
  IPlNavWizardEventBeforeChange,
  IPlNavWizardOptions,
  IPlNavWizardStep,
  IPlToolbarMenuItem,
  isArray,
  isFunction,
  isNumber,
  isUndefined,
  PlAlertService
} from 'pl-comps-angular';
import CustomStore from 'devextreme/data/custom_store';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ContabilidadeApuramentoResultadosService} from '../contabilidade.apuramentoResultados.module.service';
import {devExpressDataGridExpandDetailHandler} from '../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DocsContasInventarioModalComponent} from '../modals/docscontasinventario/docsContasInventario.modal.component';
import {EEntityStateDetailType} from '../../../../../../common/utils/entity.state.utils';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../../docscontabilidade/docsContabilidade.interface';
import {ETipoInventarioApuramento} from '../../../../../datasources/tipoinventario/tipoInventario.datasource.interface';
import {focusElement} from '../../../../../../common/utils/element.utils';
import {EApuramentoResultadosSteps, IApuramento, IApuramentoResultadosStateParams, IDocsApuraResultado} from '../contabilidade.apuramentoResultados.module.interface';
import {IDevExpressDataGridEventOnCellClick} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonDocContabilidadeCab} from '../../../docscontabilidade/jsonDocContabilidade.interface';
import {IJsonDocsContasInventario, IJsonSaldosContasInventario, IJsonVariaveisApuramento} from '../jsonApuramentoResultados.module.interface';
import {ModuloComponent} from '../../../../../components/module/module.component';
import moment from 'moment';
import {SaldosContasInventarioModalComponent} from '../modals/saldoscontasinventario/saldosContasInventario.modal.component';
import {IJsonDemFinSNCVarApuramento} from '../../../../demfinsnc/jsonDemFinSNC.module.interface';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DemFinSNCPreviewPDFModalComponent} from '../../../../demfinsnc/modals/previewpdf/demFinSNC.previewPDF.modal.component';

const timeOutFocus = 350;
const DEFAULT_LANGUAGE_PT = 0;

@Component({
  selector: 'contabilidade-apuramentoresultados',
  templateUrl: './contabilidade.apuramentoResultados.module.component.html'
})
export class ContabilidadeApuramentoResultadosComponent extends ModuloComponent implements OnInit {
  @Input() public apurado: boolean;
  @Input() public apuramento: IApuramento;

  public readonly steps: typeof EApuramentoResultadosSteps;
  public readonly definitionNavWizard: IPlNavWizardDefinition;
  public readonly definitionVariaveisApuramento: IDevExpressDataGrid;
  public readonly definitionVariaveisApuramentoDetail: IDevExpressDataGrid;
  public readonly definitionPreVisualDocumentos: IDevExpressDataGrid;
  public readonly callbackNavWizard: IPlNavWizardCallback;
  public stepOptions: IPlNavWizardOptions;
  public anoCursoIRC: number;
  public valores: Array<IJsonDocContabilidadeCab>;
  public selectedStep: IPlNavWizardStep;
  public generatedDocs: Array<IDocsApuraResultado>;

  private readonly _btnDelete: IPlToolbarMenuItem;
  private readonly _btnImprimirDemFinanceiras: IPlToolbarMenuItem;
  private _shouldGetValores: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _apuramentoResultadosService: ContabilidadeApuramentoResultadosService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this._btnImprimirDemFinanceiras = {
      id: 'imprimirDemFinanceiras',
      order: 1001,
      type: 'button',
      visible: false,
      class: 'btn btn-sm btn-success',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      caption: 'global.btn.processPDF',
      click: () => this._modalDemFinPreviewPDF(true)
    };
    this.steps = EApuramentoResultadosSteps;
    this.definitionNavWizard = {
      items: []
    };
    this.callbackNavWizard = {};
    this.anoCursoIRC = this._configService.configurations.empresa.anoEmCursoIRC;
    this.definitionVariaveisApuramento = {
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'apuramentoresultados.fields.codConta'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'apuramentoresultados.fields.nomeConta'},
        {dataField: 'total', dataType: 'double', caption: 'global.text.total'}
      ],
      dataSource: new CustomStore({
        key: 'nConta',
        load: () => this._getVariaveis()
      }),
      searchPanel: {visible: false},
      sorting: {mode: 'none'},
      masterDetail: {
        enabled: true,
        template: 'detail',
        autoExpandAll: false
      },
      export: {enabled: false},
      paging: {enabled: false},
      pager: {visible: false},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true
    };
    this.definitionVariaveisApuramentoDetail = {
      keyExpr: 'nConta',
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'apuramentoresultados.fields.nConta', allowEditing: false},
        {dataField: 'nomeConta', dataType: 'string', caption: 'apuramentoresultados.fields.nome', allowEditing: false},
        {dataField: 'valor', dataType: 'double', caption: 'apuramentoresultados.fields.valor'}
      ],
      allowColumnReordering: false,
      columnChooser: {enabled: false},
      editing: {
        allowUpdating: true,
        mode: 'cell',
        selectTextOnEditStart: true
      },
      export: {enabled: false},
      filterRow: {visible: false},
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false},
      headerFilter: {visible: false},
      paging: {enabled: false},
      pager: {visible: false},
      repaintChangesOnly: true,
      sorting: {mode: 'none'}
    };
    this.definitionPreVisualDocumentos = {
      allowColumnReordering: false,
      columns: [
        {dataField: 'periodo', dataType: 'string', caption: 'docscontabilidade.doc.linhas.periodo', groupIndex: 0},
        {dataField: 'nContaDebito', dataType: 'string', caption: 'docscontabilidade.doc.linhas.nContaDebito'},
        {dataField: 'nContaCredito', dataType: 'string', caption: 'docscontabilidade.doc.linhas.nContaCredito'},
        {dataField: 'valor', dataType: 'double', caption: 'docscontabilidade.doc.linhas.valor'},
        {dataField: 'valorME', dataType: 'double', caption: 'docscontabilidade.doc.linhas.valorME'},
        {dataField: 'descricao', dataType: 'string', caption: 'docscontabilidade.doc.linhas.descricao'}
      ],
      columnChooser: {enabled: false},
      sorting: {mode: 'none'},
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false},
      paging: {enabled: false},
      pager: {visible: false},
      filterRow: {visible: false},
      export: {enabled: false},
      headerFilter: {visible: false},
      hoverStateEnabled: true
    };
    this.valores = [];
    this._btnDelete = {
      id: 'btnDelete',
      order: 1002,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-trash"></i>',
      caption: 'apuramentoresultados.toolbar.delete',
      class: 'btn-danger',
      visible: false,
      click: () => this._delete()
    };
    this._shouldGetValores = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar
      .addButton(this._btnImprimirDemFinanceiras)
      .addButton(this._btnDelete)
      .addButton({
        id: 'dropdownAnalise',
        order: 1000,
        type: 'dropdown',
        caption: 'apuramentoresultados.toolbar.analise',
        class: 'btn-primary',
        menu: [
          {
            id: 'infoSaldosInv',
            caption: 'apuramentoresultados.toolbar.infoSaldosInv',
            click: () => this._openModalInfoSaldosInv()
          },
          {
            id: 'docsContasInventario',
            caption: 'apuramentoresultados.toolbar.docsContasInventario',
            click: () => this._openDocsContasInventario()
          },
          {
            id: 'docsSaldosErrados',
            caption: 'apuramentoresultados.toolbar.docsSaldosErrados',
            click: () => this._openDocsSaldosErrados()
          }
        ]
      });
    this.apuramento = merge(
      {
        tipoinventario: ETipoInventarioApuramento.NaotemInventarioPermanenteMensal,
        ndiario: undefined,
        nomeDiario: undefined,
        descritivo: undefined,
        nomeDescritivo: undefined,
        datadoc: moment(this.anoCursoIRC, 'YYYY').endOf('year').startOf('day'),
        descricao: '',
        variaveisapuramento: []
      },
      this.apuramento
    );
    if (!this.apuramento.ndiario) {
      this.apuramento.ndiario = undefined;
      this.apuramento.nomeDiario = undefined;
    }
    if (!this.apuramento.descritivo) {
      this.apuramento.descritivo = undefined;
      this.apuramento.nomeDescritivo = undefined;
    }
    this._evaluateToolbar();
    const params: IApuramentoResultadosStateParams = <IApuramentoResultadosStateParams>this._transition.params();
    if (params.selected) {
      if (isArray(params.docsGerados)) {
        this._doFinish(params.docsGerados);
      }
      setTimeout(() => {
        if (isFunction(this.callbackNavWizard.setStep)) {
          this.callbackNavWizard.setStep(params.selected, true);
          this.stepOptions = {disableNavigation: true};
        }
      });
    }
  }

  public onDataGridVariaveisApuramentoCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonVariaveisApuramento>): void {
    devExpressDataGridExpandDetailHandler(event);
  }

  public changeValorParentRow(item: IJsonVariaveisApuramento): void {
    item.total = 0;
    if (item.valoresInventario) {
      for (const itemValor of item.valoresInventario) {
        item.total += itemValor.valor;
      }
    }
  }

  public goToDoc(extPocCabID: string): Promise<void> {
    return this._cgStateService.redirectToState({
      stateOrName: ENTITY_NAME_DOCS_CONTABILIDADE,
      stateType: EEntityStateDetailType.EDIT,
      params: {
        id: extPocCabID,
        returnState: this._uiRouterGlobals.current.name,
        returnStateParams: {
          selected: this.selectedStep.stepId,
          docsGerados: this.generatedDocs
        }
      }
    });
  }

  public tipoInventarioChanged(value: ETipoInventarioApuramento): void {
    this.apuramento.tipoinventario = !value ? ETipoInventarioApuramento.NaotemInventarioPermanenteMensal : value;
  }

  public onSelect({item, columnIndex}: ICGTableOnSelect<IJsonVariaveisApuramento>): void {
    if (isNumber(columnIndex) && columnIndex !== -1) {
      item._detailOpen = !item._detailOpen;
    }
  }

  public readonly fnBeforeChangedStep = (event: IPlNavWizardEventBeforeChange): boolean | Promise<void> => this._beforeChangedStep(event);

  public readonly fnFinalize = (): Promise<void> => this._finalize();

  private _modalDemFinPreviewPDF(isModalImprimirDemonstracoesFinanceiras: boolean): Promise<void> {
    const varApuramento: IJsonDemFinSNCVarApuramento = {apResultN: [], apResultN1: []};
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(DemFinSNCPreviewPDFModalComponent, {size: 'xxl'});
    const componentInstance: DemFinSNCPreviewPDFModalComponent = modalInstance.componentInstance;
    componentInstance.ano = this.anoCursoIRC;
    componentInstance.processaFluxos = false;
    componentInstance.verComDados = false;
    componentInstance.processaIntercalares = false;
    componentInstance.periodoIntercalares = '';
    componentInstance.idioma = DEFAULT_LANGUAGE_PT;
    componentInstance.loadedID = undefined;
    componentInstance.varApuramento = varApuramento;
    componentInstance.selectedModalImprimirReports = isModalImprimirDemonstracoesFinanceiras;
    return modalInstance.result;
  }

  private _evaluateToolbar(): void {
    const title = !this.apurado ? 'apuramentoresultados.title_complex' : 'apuramentoresultados.title_complex_apurado';
    this.setCaption(this._translateService.instant(title, {year: this._configService.configurations.empresa.anoEmCursoIRC}));
    this._btnDelete.visible = this._btnImprimirDemFinanceiras.visible = this.apurado;
  }

  private _getVariaveis(): Promise<Array<IJsonVariaveisApuramento>> {
    return this._apuramentoResultadosService.variaveisApuramento(this.apuramento.tipoinventario).then((response) => {
      this.apuramento.variaveisapuramento = response.body;
      this.apuramento.variaveisapuramento = orderBy(this.apuramento.variaveisapuramento, ['nConta', 'asc']);
      return this.apuramento.variaveisapuramento;
    });
  }

  private _beforeChangedStep({nextStep, currentStep, previousStep, type}: IPlNavWizardEventBeforeChange): boolean | Promise<void> {
    let promise = null;
    if (currentStep.stepId === EApuramentoResultadosSteps.Variables) {
      setTimeout(() => {
        focusElement(this._element.querySelector<HTMLInputElement>('entity-autocomplete[data-attr-name="diario"] input'));
      }, timeOutFocus);
    }
    if (currentStep.stepId === EApuramentoResultadosSteps.Documents && previousStep?.stepId !== EApuramentoResultadosSteps.Documents && !nextStep && type !== 'previous') {
      if (this.apurado) {
        promise = this._promptReplace();
      }
      return Promise.resolve(promise).then(() => {
        this.apurado = false;
        this._evaluateToolbar();
        this._shouldGetValores = true;
        return this._getValores().then((valores: Array<IJsonDocContabilidadeCab>) => {
          this.valores = valores;
        });
      });
    }
    if (currentStep.stepId === EApuramentoResultadosSteps.Preview && previousStep?.stepId !== EApuramentoResultadosSteps.Preview && !nextStep && type !== 'previous') {
      return this._save();
    }
    if (nextStep?.stepId === EApuramentoResultadosSteps.Preview && !this.definitionNavWizard.items[1].complete) {
      nextStep.complete = false;
      return false;
    }
    return true;
  }

  private _getValores(): Promise<Array<IJsonDocContabilidadeCab>> {
    if (!this._shouldGetValores) {
      return Promise.resolve([]);
    }
    const params = copy<IApuramento>(this.apuramento);
    delete params.variaveisapuramento;
    return this._apuramentoResultadosService.valoresApuramento(params, this.apuramento.variaveisapuramento).then((response: HttpResponse<Array<IJsonDocContabilidadeCab>>) => response.body);
  }

  private _promptReplace(): Promise<void> {
    const message = this._translateService.instant('apuramentoresultados.promptAlreadyExistsMessage', {
      year: this._configService.configurations.empresa.anoEmCursoIRC
    });
    return this._cgModalService.showOkCancel('apuramentoresultados.promptAlreadyExistsTitle', message);
  }

  private _save(): Promise<void> {
    const params = copy<IApuramento>(this.apuramento);
    delete params.variaveisapuramento;
    return this._apuramentoResultadosService.novoApuramento(params, this.apuramento.variaveisapuramento).then((response: HttpResponse<Array<IJsonDocContabilidadeCab>>) => {
      this._plAlertService.success('apuramentoresultados.success');
      this.stepOptions = {disableNavigation: true};
      this._doFinish(response.body);
    });
  }

  private _doFinish(list: Array<IDocsApuraResultado | IJsonDocContabilidadeCab>): void {
    this.apurado = true;
    this._evaluateToolbar();
    this.generatedDocs = [];
    for (const item of list) {
      const exists = this.generatedDocs.find((doc) => {
        return doc.extPocCabID === item.extPocCabID;
      });
      if (isUndefined(exists)) {
        this.generatedDocs.push({
          periodo: item.periodo,
          nDiario: item.nDiario,
          nDocInterno: item.nDocInterno,
          extPocCabID: item.extPocCabID,
          caption: `${item.periodo}.${item.nDiario}.${item.nDocInterno.trim()}`
        });
      }
    }
  }

  private _finalize(): Promise<void> {
    return this._stateService.go(this._uiRouterGlobals.current, null, {reload: true, inherit: false}).then(() => undefined);
  }

  private _openModalInfoSaldosInv(): Promise<void> {
    return this._apuramentoResultadosService.getLoadInformacaoSaldosApuramento('').then((response: HttpResponse<IJsonSaldosContasInventario>) => {
      const modalInstance = this._cgModalService.showVanilla(SaldosContasInventarioModalComponent);
      const componentInstance: SaldosContasInventarioModalComponent = modalInstance.componentInstance;
      componentInstance.saldosContasInventario = response.body;
    });
  }

  private _openModalDocsContasInventario(onlyContaInventario: boolean, docsContasInventario: Array<IJsonDocsContasInventario>): void {
    const modalInstance = this._cgModalService.showVanilla(DocsContasInventarioModalComponent);
    const componentInstance: DocsContasInventarioModalComponent = modalInstance.componentInstance;
    componentInstance.docsContasInventario = docsContasInventario;
    componentInstance.onlyContaInventario = onlyContaInventario;
  }

  private _openDocsContasInventario(): Promise<void> {
    return this._apuramentoResultadosService.getLoadDocsContasInventario().then((response: HttpResponse<Array<IJsonDocsContasInventario>>) => {
      if (response.body.length < 1) {
        this._plAlertService.info('apuramentoresultados.modal.docscontasinventario.naoExistemDocumentosComMovimenta');
        return;
      }
      this._openModalDocsContasInventario(true, response.body);
    });
  }

  private _openDocsSaldosErrados(): Promise<void> {
    return this._apuramentoResultadosService.getLoadDocsSaldosErrados().then((response: HttpResponse<Array<IJsonDocsContasInventario>>) => {
      if (response.body.length < 1) {
        this._plAlertService.info('apuramentoresultados.modal.docscontasinventario.naoExistemDocumentosComSaldosDeC');
        return;
      }
      this._openModalDocsContasInventario(false, response.body);
    });
  }

  private _delete(): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.confirmation', 'apuramentoresultados.beforeDeleteModalMessage').then(() => {
      return this._apuramentoResultadosService.delete().then(() => {
        this._plAlertService.success('apuramentoresultados.deleted');
        this.apurado = false;
        this._evaluateToolbar();
      });
    });
  }
}
