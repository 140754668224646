import {Component, Injector} from '@angular/core';
import {isNumber, PlI18nService, PlTranslateService} from 'pl-comps-angular';
import {ENTITY_NAME_DGEMPS, IDGEMPSEntityService} from '../../../../../entities/dgemps/dgemps.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {FORM_INVALID_CANNOT_SUBMIT} from '../../../../../../config/constants';
import {GestaoDGEMPSMarcarAbstractModalComponent} from '../gestaodgemps.marcar.abstract.modal.component';
import {IGestaoDGEMPSCalendario} from '../../../gestaodgemps.interface';
import {IJsonDGEMP} from '../../../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IJsonPRHAbonos, IJsonPRHMarcarAbonos} from '../../../../../interfaces/jsonPRHAbonos.interface';
import {IJsonPRHEventosConfig} from '../../../../../entities/prheventosconfig/jsonPRHEventosConfig.entity.interface';
import moment, {Moment} from 'moment';

@Component({
  selector: 'gestao-dgemps-marcar-abonos-modal',
  templateUrl: './gestaodgemps.marcar.abonos.modal.component.html'
})
export class GestaoDGEMPSMarcarAbonosModalComponent extends GestaoDGEMPSMarcarAbstractModalComponent {
  public tipoAbono: IJsonPRHEventosConfig;
  public motivo: string;
  public quantidade: number;
  public valor: number;
  public dadosFixos: boolean;

  private readonly _serviceDGEMPS: IDGEMPSEntityService;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _plI18nService: PlI18nService,
    protected readonly _plTranslateService: PlTranslateService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector, _plI18nService, _plTranslateService);
    this._serviceDGEMPS = this._entityServiceBuilder.build<IJsonDGEMP, IDGEMPSEntityService>(ENTITY_NAME_DGEMPS);
  }

  public changedTipoAbono(value: number): void {
    this.tipoAbono = isNumber(value) ? this.configEvents.find((configEvent: IJsonPRHEventosConfig) => configEvent.codPRHEventosConfig === value) : undefined;
  }

  public close(): Promise<void> {
    if (!this.tipoAbono) {
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }
    this.disableClose();

    const calendarios: Array<IGestaoDGEMPSCalendario> = this.marcarData.calendarios.slice();

    const fnMarcarAbonos = (): Promise<void> => {
      const calendario: IGestaoDGEMPSCalendario = calendarios.shift();
      if (!calendario) {
        return Promise.resolve();
      }
      return this._marcarAbono(calendario).then(fnMarcarAbonos);
    };

    return fnMarcarAbonos()
      .then(() => {
        this.enableClose();
        super.close();
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }

  private _marcarAbono(calendario: IGestaoDGEMPSCalendario): Promise<unknown> {
    const abonos: IJsonPRHMarcarAbonos = {
      cab: undefined,
      linhas: [],
      motivo: this.motivo,
      codEmpLanca: this.codEmpLanca,
      codServico: this.codServico,
      proximoPapel: -1
    };

    const dataAbono: Moment = moment(this.marcarData.startDate);
    const dataAteRecFix = moment(this.marcarData.endDate);

    if (this.dadosFixos) {
      const linha: IJsonPRHAbonos = this._generateLinha(dataAbono.clone(), dataAteRecFix.clone());
      abonos.linhas.push(linha);
    } else {
      for (const dataAtual = dataAbono.clone(); dataAtual.diff(dataAteRecFix, 'days') <= 0; dataAtual.add(1, 'day')) {
        const linha: IJsonPRHAbonos = this._generateLinha(dataAtual.clone(), dataAteRecFix.clone());
        abonos.linhas.push(linha);
      }
    }

    return this._serviceDGEMPS.marcarAbonos(calendario.codEmp, abonos).then(() => {
      this._successfulCalendarios.add(calendario);
      for (let i = this.marcarData.calendarios.length - 1; i >= 0; i--) {
        if (this.marcarData.calendarios[i].codEmp === calendario.codEmp) {
          this.marcarData.calendarios.splice(i, 1);
        }
      }
    });
  }

  private _generateLinha(dataAtual: Moment, dataAteRecFix: Moment): IJsonPRHAbonos {
    return {
      idTarefaCab: undefined,
      posicao: undefined,
      codPRHAbonos: undefined,
      tipoProcessamento: this.tipoAbono.tipoProcessamento,
      ncodAbdes: this.tipoAbono.ncodAbdes,
      dataAbono: dataAtual.clone(),
      quantidade: this.quantidade,
      remunId: undefined,
      remfxId: undefined,
      dataAteRecFix: dataAteRecFix.clone(),
      dadosFixos: this.dadosFixos,
      valor: this.valor
    };
  }
}
