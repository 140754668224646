import {merge} from 'lodash-es';
import {race, Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import type {Options as PopperOptions} from '@popperjs/core';
import {Directive, HostListener, Injector, NgZone, OnDestroy} from '@angular/core';
import type {IPlEditComponentOptionsInputDropdown} from './edit.input.dropdown.interface';
import type {IPlPositioning} from '../../../positioning/positioning.interface';
import {PlEditInputTypeComponent} from './edit.input.type.component';
import {positioning} from '../../../positioning/positioning';

@Directive()
export abstract class PlEditInputDropdownComponent<T, S extends IPlEditComponentOptionsInputDropdown<T> = IPlEditComponentOptionsInputDropdown<T>>
  extends PlEditInputTypeComponent<T, S>
  implements OnDestroy
{
  protected readonly _ngZone: NgZone;
  protected readonly _positioning: IPlPositioning;
  protected readonly _dropdownOpened: Subject<void>;
  protected readonly _dropdownClosed: Subject<void>;

  private _dropdownOpen: boolean;
  private _stopClose: boolean;
  private _elementDropdown: HTMLElement;
  private _subscriptionZone: Subscription;

  protected constructor(protected readonly _injector: Injector) {
    super('', _injector);
    this._updatePopperOptions = this._updatePopperOptions.bind(this);
    this._ngZone = this._injector.get<NgZone>(NgZone);
    this._defaultOptions = Object.freeze<S>(
      merge({}, this._defaultOptions, {
        appendToBody: false
      })
    );
    this._positioning = positioning();
    this._dropdownOpened = new Subject<void>();
    this._dropdownClosed = new Subject<void>();
    this._stopClose = false;
    this._dropdownOpen = false;
  }

  public abstract showDropdown(): void;

  public abstract closeDropdown(): void;

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._clearSubscriptionZone();
    this._positioning.destroy();
    this._dropdownOpened.complete();
    this._dropdownClosed.complete();
    this._stopClose = false;
    this.closeDropdown();
    if (this._elementDropdown) {
      this._elementDropdown.remove();
    }
  }

  public updateComponent(properties: S): void {
    const appendToBody: boolean = this.options.appendToBody;
    super.updateComponent(properties);
    if (this.options.appendToBody !== appendToBody) {
      this._positioning.setOptions({appendToBody: this.options.appendToBody});
    }
  }

  public initDropdown(hostElement: HTMLElement, targetElement: HTMLElement): void {
    if (this.options.appendToBody !== true) {
      return;
    }
    this._elementDropdown = targetElement;
    this._ngZone.runOutsideAngular(() => {
      this._document.body.appendChild(targetElement);
      this._positioning.createPopper({
        hostElement: hostElement,
        targetElement: targetElement,
        placement: ['bottom-left', 'top-left'],
        appendToBody: true,
        updatePopperOptions: this._updatePopperOptions
      });
      this._clearSubscriptionZone();
      this._subscriptionZone = this._ngZone.onStable.pipe(takeUntil(race([this._dropdownClosed, this._destroyed]))).subscribe({
        next: () => {
          this._positioning.update();
        },
        error: (error: unknown) => {
          this._logger.error(error);
          this._positioning.destroy();
        },
        complete: () => {
          this._positioning.destroy();
        }
      });
    });
  }

  public toggleDropdown(): void {
    if (this.dropdownOpen || this.options.disabled || this.options.readonly) {
      this.closeDropdown();
    } else {
      this.inputFocus();
      this.showDropdown();
    }
  }

  public stopCloseDropdown(): void {
    this._stopClose = true;
  }

  public onWrapperMouseEnter(): void {
    this.isMouseIn = true;
  }

  public onWrapperMouseLeave(): void {
    this.isMouseIn = false;
  }

  @HostListener('document:click')
  public documentClick(): void {
    if (this.dropdownOpen && !this._stopClose) {
      this.closeDropdown();
    }
    this._stopClose = false;
  }

  public get dropdownOpen(): boolean {
    return this._dropdownOpen;
  }

  public set dropdownOpen(value: boolean) {
    if (value !== this._dropdownOpen) {
      this._dropdownOpen = value;
      if (!this._dropdownOpen) {
        this._dropdownClosed.next();
      } else {
        this._dropdownOpened.next();
      }
    }
  }

  protected _updatePopperOptions(options: Partial<PopperOptions>): Partial<PopperOptions> {
    return options;
  }

  private _clearSubscriptionZone(): void {
    if (this._subscriptionZone) {
      this._subscriptionZone.unsubscribe();
      this._subscriptionZone = undefined;
    }
  }
}
