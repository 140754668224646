<div class="fimperiodoenc">
  <entity-detail
    entityName="fimperiodoenc"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [model]="model" [definition]="definition" [template]="template" [properties]="{readonly: type === stateTypes.DETAIL, orientation: formOrientation}"></pl-form>
      <pl-form [properties]="{orientation: formOrientation}">
        <pl-multiselect
          [model]="model.diarios"
          [source]="model.diariosPossiveis"
          key="nDiario"
          [template]="diariosTemplate"
          [callback]="plMultiselect"
          [properties]="{
            disabled: type === stateTypes.DETAIL,
            leftCaption: 'fimperiodoenc.titleDiariosDisponiveis',
            rightCaption: 'fimperiodoenc.titleDiariosSelecionados'
          }"
          (evtChanged)="changedDiarios($event.model)">
        </pl-multiselect>
      </pl-form>
    </div>
  </entity-detail>
</div>
