import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ApiService} from '../../../../../services/api/api.service';
import {IJsonPreDefinidoContab, IJsonPreDefinidoContabLinha} from '../jsonPreDefinidosContab.entity.interface';
import {IPreDefinidoContab, IPreDefinidoContabLinha} from '../preDefinidosContab.entity.interface';
import {TServiceResponse} from '../../../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ContabilidadePredefinidosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/predefinidoscontabilidade`;
  }

  public sugerirCodigo(): TServiceResponse<number> {
    return this._apiService.get({url: `${this._path}/sugerircodigo`});
  }

  public sugerirCodigoMultiEmpresa(): TServiceResponse<number> {
    return this._apiService.get({url: `${this._path}/sugerircodigomultiempresa`});
  }

  public getFromDocContabilidade(extPocCabID: string): TServiceResponse<IPreDefinidoContab> {
    return this._apiService.get({url: `${this._path}/doccontabilidade/${extPocCabID}`}).then((response: HttpResponse<IJsonPreDefinidoContab>) => {
      const transformedResponse: HttpResponse<IPreDefinidoContab> = response.clone({
        body: {
          ...response.body,
          linhas: response.body.linhas.map<IPreDefinidoContabLinha>((linha: IJsonPreDefinidoContabLinha, index: number) => {
            return {
              ...linha,
              _index: index
            };
          })
        }
      });
      return transformedResponse;
    });
  }

  public validarFormula(linha: number, tipo: number, formula: string, predefinido: IJsonPreDefinidoContab): TServiceResponse<boolean> {
    return this._apiService.post({
      url: `${this._path}/validarformula`,
      body: predefinido,
      params: {
        linha: linha,
        tipo: tipo,
        formula: formula
      }
    });
  }
}
