import {Component, Injector, OnInit} from '@angular/core';
import {IJsonLote} from '../../jsonLote.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import moment from 'moment';
import {ILoteDetailStateParams} from '../../lotes.entity.interface';
import {IJsonArtigo} from '../../../artigos/jsonArtigo.entity.interface';
import {ENTITY_NAME_ARTIGOS, IArtigosEntityService} from '../../../artigos/artigos.entity.interface';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {isObject, isString, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {focusAndSelectElementWithDelay} from '../../../../../common/utils/element.utils';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {TDate} from '../../../../../common/dates';

@Component({
  selector: 'lotes-edit',
  templateUrl: './lotes.entity.edit.component.html'
})
export class LotesEditComponent extends ModuloEntityDetailComponent<IJsonLote> implements OnInit {
  public nArtigoReadonlyOnNew: boolean;

  private readonly _loteParams: ILoteDetailStateParams;
  private readonly _serviceArtigos: IArtigosEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);
    this._loteParams = <ILoteDetailStateParams>this._transition.params();
    this.nArtigoReadonlyOnNew = false;
    this._serviceArtigos = this._entityServiceBuilder.build<IJsonArtigo, IArtigosEntityService>(ENTITY_NAME_ARTIGOS);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.type === this.stateTypes.NEW) {
      this.nArtigoReadonlyOnNew = this._loteParams?.nArtigo !== '';
      this.model.dataFabrico = this._loteParams?.dataDoc ? this._loteParams.dataDoc : moment();
      this.getDataValidade();
    }
  }

  public async getDataValidade(): Promise<void> {
    if (this.nArtigoReadonlyOnNew) {
      let diasValidade: number = !this._loteParams?.diasValidade ? 0 : this._loteParams.diasValidade;
      if (!diasValidade) {
        diasValidade = (await this._serviceArtigos.get({id: this.params.nArtigo, reportExceptions: false})).body.diasValidade;
      }
      this.model.dataValidade = this.model.dataFabrico ? moment(this.model.dataFabrico).add(diasValidade, 'days') : moment().add(diasValidade, 'days');
    }
  }

  public dataFabricoChanged(newDate: TDate): void {
    this.model.dataFabrico = newDate;
    this.getDataValidade();
  }

  public nArtigoChanged(artigo: IJsonArtigo | string): void {
    if (!isObject(artigo) && isString(artigo)) {
      this._serviceArtigos
        .get({id: this.model.nArtigo, reportExceptions: false})
        .then((response: HttpResponse<IJsonArtigo>) => {
          if (response) {
            this._artigoValidate(response.body);
          }
        })
        .catch((reason: HttpErrorResponse) => {
          const cgException = this._cgExceptionService.get(reason);
          this._plAlertService.error(cgException.message);
          this._setFocusNArtigo();
        });
    } else {
      this._artigoValidate(<IJsonArtigo>artigo);
    }
  }

  private _artigoValidate(artigo: IJsonArtigo): void {
    if (!artigo.temLote) {
      this._plAlertService.error(this._plTranslateService.translate('lotes.errors.artigoNaoTemLote', {nArtigo: artigo.nArtigo}));
      this._setFocusNArtigo();
      return;
    }
    this.model.dataValidade = moment().add(artigo.diasValidade, 'days');
  }

  private _setFocusNArtigo(): void {
    focusAndSelectElementWithDelay(this._element.querySelector<HTMLInputElement>('.artigo-edit input[name="nArtigoLote"]'));
  }
}
