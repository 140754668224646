<div class="calculodiferimentos">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form>
        <div class="row">
          <div class="col-sm-12 col-xl-4">
            <pl-group>
              <label [translate]="'mapadiferimentosacrescimos.tipoOperacao'"></label>
              <edit>
                <pl-edit-select
                  [model]="tipoOperacaoDiferimentosSourceItem"
                  [source]="tipoOperacaoDiferimentosSource"
                  (modelChange)="tipoOperacaoDiferimentosChanged($event)"
                  [properties]="{select: {valueProp: 'value', labelProp: 'label'}, disallowClear: true}"></pl-edit-select>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-xl-4">
            <pl-group>
              <label [translate]="'mapadiferimentosacrescimos.tipodiferimento'"></label>
              <edit>
                <pl-edit-select
                  [model]="tipoDiferimentosSourceItem"
                  [source]="tipoDiferimentosSource"
                  (modelChange)="tipoDiferimentosChanged($event)"
                  [properties]="{select: {valueProp: 'value', labelProp: 'label'}, disallowClear: true}"></pl-edit-select>
              </edit>
            </pl-group>
          </div>

          <div class="col-sm-12 col-xl-4 align-self-end">
            <pl-group>
              <pl-button klass="btn-primary btn-sm action-pesquisar" id="pesquisar" [onClick]="searchAcrescimosDiferimentos">
                <i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
              </pl-button>
            </pl-group>
          </div>
        </div>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    [cgDxDataGridInstanceName]="'mapaAcrescimosDiferimentos'"
    [dataSource]="dataGridDefinition.dataSource"
    (onInitialized)="onInitialized($event)"
    (onCellClick)="onCellClick($event)">
    <div *dxTemplate="let detailInfo of 'masterDetailTemplate'">
      <split-viewer-doccontab-digital
        [(docContabilidade)]="detailInfo.data._docContabilidade"
        [contabDigitalDoc]="detailInfo.data._contabDigitalDoc"
        [initialSizeLeft]="70"
        [showToolbar]="true"
        [maintenanceMode]="true"
        [readonlyDigital]="true">
      </split-viewer-doccontab-digital>
    </div>
  </dx-data-grid>
</div>
