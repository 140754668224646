import {deepFreeze, TPlEditType} from 'pl-comps-angular';
import {TEntityEditType} from '../../../../../../components/entity/entity.form.definition.interface';
import {TPredefinidoFieldLinha, TPredefinidoVisibilidadeField} from '../../jsonPreDefinidosContab.entity.interface';
import {IPreDefinidoContabLinha} from '../../preDefinidosContab.entity.interface';
import {IPreDefinidoContabCabVisualProperty} from '../cabecalho/predefinidocontabcab.component.interface';

export type TPreDefinidoContabHeader = 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | 'G' | 'H' | 'I' | 'J' | 'L';

export type TPreDefinidoContabFormulaTableBodyDefinitionGroups = Map<keyof IPreDefinidoContabFormulaTableBodyDefinitionGroups, IPreDefinidoContabFormulaTableBodyDefinitionGroup>;

export type TPreDefinidoContabFormulaFnVisible = (model: IPreDefinidoContabLinha) => boolean;

export interface IPreDefinidoContabFormula {
  name: string;
  valueProperty: string;
  property: string;
  propertyValue: string;
  value: number;
}

export interface IPreDefinidoContabFormulaTableHeaderDefinition {
  lines: Array<IPreDefinidoContabFormulaTableHeaderDefinitionLine>;
}

export interface IPreDefinidoContabFormulaTableHeaderDefinitionLine {
  id?: number;
  index?: number;
  field?: string;
  colspan?: number;
  rowspan?: number;
  locked?: boolean;
  text?: string;
  visible?: boolean;
}

export interface IPreDefinidoContabFormulaTableBodyDefinition {
  id: number;
  index: number;
  name: TPredefinidoFieldLinha;
  header: TPreDefinidoContabHeader;
  originalHeader: TPreDefinidoContabHeader;
  groups: TPreDefinidoContabFormulaTableBodyDefinitionGroups;
  visual?: string;
  allowSimpleFormula?: boolean;
  allowComplexFormula?: boolean;
  visible?: boolean;
  selected?: boolean;

  click?(): void;
}

export interface IPreDefinidoContabFormulaTableBodyDefinitionGroups {
  base: IPreDefinidoContabFormulaTableBodyDefinitionGroup;
  baseTributavelProperties?: IPreDefinidoContabFormulaTableBodyDefinitionGroup;
  contaCorrenteProperties?: IPreDefinidoContabFormulaTableBodyDefinitionGroup;
}

export interface IPreDefinidoContabFormulaTableBodyDefinitionGroup {
  fields: Array<IPreDefinidoContabFormulaTableBodyDefinitionGroupField>;
  caption?: string;
  visible?: boolean | TPreDefinidoContabFormulaFnVisible;
}

export interface IPreDefinidoContabFormulaTableBodyDefinitionGroupField {
  name: string;
  type: string | TEntityEditType;
  caption: string;
  properties?: object;
  visible?: boolean | TPreDefinidoContabFormulaFnVisible;

  onInit?(model: IPreDefinidoContabLinha, fields: Array<IPreDefinidoContabFormulaTableBodyDefinitionGroupField>);

  onChange?(model: IPreDefinidoContabLinha, fields: Array<IPreDefinidoContabFormulaTableBodyDefinitionGroupField>, newValue: unknown);

  reset?(model: IPreDefinidoContabLinha, field: IPreDefinidoContabFormulaTableBodyDefinitionGroupField);
}

export interface IPreDefinidoContabFormulaVisualProperty {
  id: number;
  name: TPredefinidoVisibilidadeField;
  type: TPlEditType;
  caption: string;
  default: boolean;
  visible: boolean;
}

export interface IPreDefinidoContabFormulaVaiImputarLinha {
  value: number;
  name: string;
}

export const CONTABILIDADE_PREDEFINIDOS_DEFAULT_CAB_VISUAL_PROPERTIES: ReadonlyArray<IPreDefinidoContabCabVisualProperty> = deepFreeze([
  {name: 'readonly', type: 'boolean', caption: 'predefinidocontabcab.fields.readonly', default: false},
  {name: 'tabStop', type: 'boolean', caption: 'predefinidocontabcab.fields.tabStop', default: true},
  {name: 'visible', type: 'boolean', caption: 'predefinidocontabcab.fields.visible', default: true}
]);

export const CONTABILIDADE_PREDEFINIDOS_DEFAULT_LINHA_VISUAL_PROPERTIES: ReadonlyArray<IPreDefinidoContabFormulaVisualProperty> = deepFreeze([
  {id: 0, name: 'readonly', type: 'boolean', caption: 'predefinidocontabcab.fields.readonly', default: false, visible: true},
  {id: 1, name: 'tabStop', type: 'boolean', caption: 'predefinidocontabcab.fields.tabStop', default: true, visible: true},
  {id: 2, name: 'visible', type: 'boolean', caption: 'predefinidocontabcab.fields.visible', default: true, visible: true}
]);
