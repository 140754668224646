import {Component, Injector, Input, OnInit} from '@angular/core';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {IDevExpressDataGridEventOnRowClick} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {TabelasIRSDetailModalComponent} from '../modals/tabelasIrsDetail/tabelasIRS.detail.modal.component';
import {HttpResponse} from '@angular/common/http';
import {IJsonDate} from '../../../../../common/interfaces/json';
import {THttpQueryResponse} from '../../../../services/api/api.service.interface';
import {DATA_SOURCE_NAME_LOCAL_TRIBUTACAO_RENDIMENTO} from '../../../../datasources/localtributacaorendimento/localTributacaoRendimento.datasource.interface';
import {IJsonTirs, IJsonTIRSDT} from '../jsonTabelasIRS.module.interface';
import {TabelasIRSModuleService} from '../tabelasIRS.module.service';

@Component({
  selector: 'module-tabelas-irs',
  templateUrl: './tabelasIRS.module.component.html'
})
export class TabelasIRSModuleComponent extends ModuloComponent implements OnInit {
  @Input() public tabelasIRS: Array<IJsonTirs>;

  public dataGrid: IDevExpressDataGrid<IJsonTirs, IJsonTirs>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _serviceTabelasIRS: TabelasIRSModuleService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    // btns
    this.btnRefresh.visible = true;
    this.btnRefresh.click = async () => this._refresh();

    this.dataGrid = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nTabIrs', dataType: 'string', caption: 'tirs.fields.nTabIrs'},
        {dataField: 'localTributacaoRendi', dataType: 'number', caption: 'tirs.fields.localRendimento', lookup: {cgDataSource: DATA_SOURCE_NAME_LOCAL_TRIBUTACAO_RENDIMENTO}},
        {dataField: 'designaBreve', dataType: 'string', caption: 'tirs.fields.designaBreve'},
        {dataField: 'designaCompl', dataType: 'string', caption: 'tirs.fields.designaCompl'},
        {dataField: 'dataVigor', dataType: 'date', caption: 'tirs.fields.dtulTabela'}
      ],
      export: {filename: 'tirs.title_plural'},
      columnChooser: {enabled: false},
      remoteOperations: false,
      headerFilter: {visible: true}
    };
  }

  public async onRowClick(event: IDevExpressDataGridEventOnRowClick<IJsonTirs, IJsonTirs>): Promise<void> {
    const datasEscaloes: Array<IJsonDate> = await this._serviceTabelasIRS.getDistinctDatasEscaloes(event.data.nTabIrs).then((response: HttpResponse<Array<IJsonDate>>) => response.body);
    const taxasIRS: Array<IJsonTIRSDT> = await this._serviceTabelasIRS
      .getTableIRSEscaloesByDate(event.data.nTabIrs, event.data.dataVigor)
      .then((response: HttpResponse<Array<IJsonTIRSDT>>) => response.body);

    const modalInstance = this._cgModalService.showVanilla(TabelasIRSDetailModalComponent);
    const componentInstance: TabelasIRSDetailModalComponent = modalInstance.componentInstance;
    componentInstance.model = event.data;
    componentInstance.datasEscaloes = datasEscaloes;
    componentInstance.datagridEscaloesIRSSource = taxasIRS;
    await modalInstance.result;
  }

  private async _refresh(): Promise<void> {
    this.tabelasIRS = await this._serviceTabelasIRS.query().then((response: THttpQueryResponse<IJsonTirs>) => response.body.list);
  }
}
