import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonImportadorSaftDirectTaxaIva} from '../../importadorSaftDirect.module.interface';
import {IJsonIva} from '../../../../../entities/ivas/jsonIva.entity.interface';
import {ImportadorSaftDirectService} from '../../importadorSaftDirect.module.service';
import {IRestCmdImportadorSaftDirectCorrespTaxasAtribuirCodIva, IRestCmdImportadorSaftDirectCorrespTaxasAtribuirDigitosIva} from '../../importadorSaftDirectCommands.module.interface';
import {round} from '../../../../../../common/utils/utils';
import {PlI18nPlNumberService} from 'pl-comps-angular';

@Component({
  selector: 'importador-saft-direct-taxas-iva-modal',
  templateUrl: './importadorSaftDirect.taxasIva.modal.component.html'
})
export class ImportadorSaftDirectTaxasIvaModalComponent extends CGModalComponent<IJsonImportadorSaftDirectTaxaIva> implements OnInit {
  @Input() public model: IJsonImportadorSaftDirectTaxaIva;

  public filterByTax: string;

  private _needSaveDigitosIva: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _importadorSaftDirectService: ImportadorSaftDirectService,
    private readonly _plI18nPlNumberService: PlI18nPlNumberService
  ) {
    super(_injector);
    this._needSaveDigitosIva = false;
  }

  public ngOnInit(): void {
    this.filterByTax = `taxaActual=${this._plI18nPlNumberService.formatPlNumber(round(this.model.taxPercentage), {decimalsLimit: 2, decimalsSeparator: '.'})}`;
  }

  public async close(): Promise<void> {
    if (this._needSaveDigitosIva) {
      const responseDigitosIva: IRestCmdImportadorSaftDirectCorrespTaxasAtribuirDigitosIva = await this._importadorSaftDirectService.correspTaxasAtribuirDigitosIva(this.model);
      this.model = responseDigitosIva.taxaIva;
    }
    super.close(this.model);
  }

  public async codIvaChange(item: IJsonIva): Promise<void> {
    if (item && item.codIva !== this.model.codIva) {
      this.model.codIva = item.codIva;
      const responseAtribuirCodIva: IRestCmdImportadorSaftDirectCorrespTaxasAtribuirCodIva = await this._importadorSaftDirectService.correspTaxasAtribuirCodIva(this.model);
      this.model = responseAtribuirCodIva.taxaIva;
    }
  }

  public digitosIvaChange(digitosIva: string): void {
    if (digitosIva && digitosIva !== this.model.digitosIva) {
      this._needSaveDigitosIva = true;
      this.model.digitosIva = digitosIva;
    }
  }
}
