import {Component, Injector, Input, OnInit} from '@angular/core';
import {ModuloComponent} from '../../../components/module/module.component';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {ETipoOrdenacao} from '../jsonRendHabitPerm.module.interface';
import {dataSourceTipoEscolhaRendHabitPerm, IRendHabitPerm, ITipoEscolhaRendHabitPerm} from '../rendHabitPerm.module.interface';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {RendHabitPermModalComponent} from '../modals/rendHabitPerm.modal.component';
import {RendHabitPermService} from '../rendHabitPerm.module.service';
import {copy} from 'pl-comps-angular';
import {HttpResponse} from '@angular/common/http';
import moment from 'moment';

@Component({
  selector: 'rend-habit-perm',
  templateUrl: './rendHabitPerm.module.component.html'
})
export class RendHabitPermModuleComponent extends ModuloComponent implements OnInit {
  @Input() public rendHabitPermList: Array<IRendHabitPerm>;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public tipoEscolhaRendHabitPerm: Array<ITipoEscolhaRendHabitPerm>;
  public estadoRendHabitPerm: ITipoEscolhaRendHabitPerm;

  private _dataGridInstance: dxDataGrid;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _rendHabitPermService: RendHabitPermService
  ) {
    super(_injector);
    this.pesquisar = this.pesquisar.bind(this);
    this.tipoEscolhaRendHabitPerm = <Array<ITipoEscolhaRendHabitPerm>>copy(dataSourceTipoEscolhaRendHabitPerm(this._translateService));
    this.estadoRendHabitPerm = this.tipoEscolhaRendHabitPerm[0];
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      groupPanel: {visible: true},
      grouping: {autoExpandAll: false},
      columns: [
        {dataField: '_empDesc', dataType: 'string', caption: 'rendhabitperm.fields.codEmp'},
        {dataField: '_abonoDesc', dataType: 'string', caption: 'rendhabitperm.fields.codAbdes'},
        {dataField: 'anoProcessamento', dataType: 'number', caption: 'rendhabitperm.fields.anoProcessamento'},
        {dataField: 'valorRenda', dataType: 'double', caption: 'rendhabitperm.fields.valorRend'},
        {dataField: 'tipoProcessamentoStr', dataType: 'string', caption: 'rendhabitperm.fields.rhTipoProce'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnsRendHbitPerm'}
      ],
      dataSource: [],
      export: {filename: 'rendhabitperm.filename'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      keyExpr: 'rendHabitPermId',
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton({
      id: 'saveRendHabitPerm',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-plus-circle fa-fw"></i>',
      class: 'btn-primary',
      caption: 'global.btn.new',
      click: () => this._openRendHabitPermModal(this._emptyRendHabitPerm())
    });
    this._applyDataSourceRendHabitPerm();
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public pesquisar(): void {
    this._dataGridInstance.beginCustomLoading(undefined);

    this._rendHabitPermService
      .getRendHabitPerm(this.estadoRendHabitPerm.value, ETipoOrdenacao.CodEmp)
      .then((response: HttpResponse<Array<IRendHabitPerm>>) => {
        this.rendHabitPermList = response.body;
        this._applyDataSourceRendHabitPerm();
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
  }

  public readonly fnEditRendHabitPerm: (item: IRendHabitPerm) => () => Promise<void> = (item: IRendHabitPerm) => (): Promise<void> => this._openRendHabitPermModal(item);

  public readonly fnDeleteRendHabitPerm: (item: IRendHabitPerm) => () => Promise<void> = (item: IRendHabitPerm) => (): Promise<void> => this._deleteRendHabitPerm(item);

  private _applyDataSourceRendHabitPerm(): void {
    for (const rendHabitPerm of this.rendHabitPermList) {
      rendHabitPerm._empDesc = `${rendHabitPerm.codEmp} - ${rendHabitPerm.nome}`;
      rendHabitPerm._abonoDesc = `${rendHabitPerm.codAbdes} - ${rendHabitPerm.abdesNome}`;
    }
    this.dataGridDefinition.dataSource = this.rendHabitPermList;
  }

  private _openRendHabitPermModal(rendhabitPerm: IRendHabitPerm): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(RendHabitPermModalComponent, {size: 'xxl'});
    const componentInstance: RendHabitPermModalComponent = modalInstance.componentInstance;
    componentInstance.estado = this.estadoRendHabitPerm.value;
    componentInstance.tipoOrdenacao = ETipoOrdenacao.CodEmp;
    componentInstance.rendHabitPerm = rendhabitPerm;
    return modalInstance.result.then((result: Array<IRendHabitPerm>) => {
      this.rendHabitPermList = result;
      this._applyDataSourceRendHabitPerm();
    });
  }

  private _deleteRendHabitPerm(rendHabitPerm: IRendHabitPerm): Promise<void> {
    return this._rendHabitPermService.deleteRendHabitPerm(rendHabitPerm.rendHabitPermId).then(() => {
      const index: number = this.rendHabitPermList.findIndex((item: IRendHabitPerm) => item.rendHabitPermId === rendHabitPerm.rendHabitPermId);
      if (index !== -1) {
        this.rendHabitPermList.splice(index, 1);
        this._applyDataSourceRendHabitPerm();
      }
    });
  }

  private _emptyRendHabitPerm(): IRendHabitPerm {
    return {
      rendHabitPermId: '',
      codAbdes: '',
      abdesNome: '',
      codEmp: '',
      nome: '',
      valorRenda: 0,
      valorRendaMaxMuni: 0,
      anoProcessamento: moment().year(),
      mesInicioProce: moment().month() + 1,
      observacoes: '',
      suspende: false,
      dataSuspensao: moment(),
      obsSuspensao: '',
      tipoProcessamento: undefined,
      valorIncidendiaIRS: 0,
      tipoProcessamentoStr: '',
      _empDesc: '',
      _abonoDesc: ''
    };
  }
}
