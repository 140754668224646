import {Subject, Subscription} from 'rxjs';
import {DOCUMENT} from '@angular/common';
import {AfterViewInit, Component, ElementRef, Inject, Input, NgZone, OnDestroy, TrackByFunction} from '@angular/core';
import {EScreenSize, Logger, plAutoClose, PlDocumentService, PlDynamicVisualsActiveReference} from 'pl-comps-angular';
import {CGStateService} from '../state/cg.state.service';
import {INotification, INotificationsUI, notificationMarkAsVisitedDelay, notificationsTrackBy} from '../../services/notificationcenter/notificationcenter.service.interface';
import {INotificationCenterStateParams, MODULE_NAME_NOTIFICATION_CENTER} from '../../modules/notificationcenter/notificationCenter.module.interface';
import {NotificationCenterService} from '../../services/notificationcenter/notificationcenter.service';

@Component({
  selector: 'notification-center',
  templateUrl: './notificationcenter.component.html'
})
export class NotificationCenterComponent implements OnDestroy, AfterViewInit {
  @Input() public hostElement: HTMLElement;
  public readonly allNotificationsHref: string;
  public readonly notifications: INotificationsUI;
  public readonly notificationsTrackByFn: TrackByFunction<INotification>;
  public allNotificationsParams: INotificationCenterStateParams;
  public mobile: boolean;
  public noNotifications: boolean;

  private readonly _document: Document;
  private readonly _element: HTMLElement;
  private readonly _subjectDestroyed: Subject<void>;
  private readonly _subscriptionWindowWidth: Subscription;
  private readonly _subscriptionNotifications: Subscription;

  constructor(
    private readonly _ngZone: NgZone,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Inject(DOCUMENT) document: any,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _logger: Logger,
    private readonly _plDocumentService: PlDocumentService,
    private readonly _plDynamicVisualsActiveReference: PlDynamicVisualsActiveReference,
    private readonly _cgStateService: CGStateService,
    private readonly _notificationService: NotificationCenterService
  ) {
    this.allNotificationsHref = this._cgStateService.getRedirectState({stateOrName: MODULE_NAME_NOTIFICATION_CENTER})?.name;
    this.notifications = {
      notificationsNew: [],
      notificationsOld: []
    };
    this.notificationsTrackByFn = notificationsTrackBy;
    this.mobile = false;
    this.noNotifications = false;
    this._document = document;
    this._element = this._elementRef.nativeElement;
    this._subjectDestroyed = new Subject<void>();
    this._subscriptionWindowWidth = this._plDocumentService.windowWidth().subscribe((windowWidth: number) => {
      this.mobile = windowWidth < EScreenSize.Medium;
    });
    this._subscriptionNotifications = this._notificationService.notificationsUI(this.notifications).subscribe((notifications: INotificationsUI) => {
      this.notifications.notificationsNew = notifications.notificationsNew;
      this.notifications.notificationsOld = notifications.notificationsOld;
      this.noNotifications = !this.notifications.notificationsNew.length && !this.notifications.notificationsOld.length;
      this.allNotificationsParams = {
        newNotificationsIds: this.notifications?.notificationsNew.map((notification: INotification) => notification.id) ?? []
      };
      notificationMarkAsVisitedDelay(this._subjectDestroyed).subscribe(() => {
        this._notificationService.markNotificationsUIAsVisited(this.notifications).catch((reason: unknown) => {
          this._logger.error(reason);
        });
      });
    });
  }

  public ngOnDestroy(): void {
    this._subjectDestroyed.next();
    this._subjectDestroyed.complete();
    this._subscriptionWindowWidth.unsubscribe();
    this._subscriptionNotifications.unsubscribe();
  }

  public ngAfterViewInit(): void {
    plAutoClose(this._ngZone, this._document, 'outside', this.close.bind(this), [this._element]);
  }

  public selectedNotification(notification: INotification): void {
    this._notificationService.markNotificationAsRead(notification.id);
  }

  public close(): void {
    this._plDynamicVisualsActiveReference.close();
  }
}
