import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonPRHTipoCom} from './jsonrhtipocom.entity.interface';
import {ENTITY_NAME_PRHTIPOCOM} from './prhtipocom.entity.interface';
import {PRHTipoComEntityEditComponent} from './components/edit/prhtipocom.entity.edit.component';

export const ENTITY_PRHTIPOCOM: IEntityDefinition<IJsonPRHTipoCom> = {
  name: ENTITY_NAME_PRHTIPOCOM,
  roles: [ROLE.GESTORRH, ROLE.GESTORSERVICO],
  searchPlaceholder: 'entity.placeholder.search',
  metadata: {
    keyName: 'codPRHTipoCom',
    descriptionName: '{{codPRHTipoCom}} - {{nome}}',
    fields: [
      {
        name: 'codPRHTipoCom',
        type: 'cginteger',
        caption: 'prhtipocom.fields.codPRHTipoCom',
        placeholder: 'prhtipocom.fields.codPRHTipoComPlaceholder',
        validators: {required: true}
      },
      {
        name: 'nome',
        type: 'string',
        caption: 'prhtipocom.fields.nome',
        placeholder: 'prhtipocom.fields.nome',
        validators: {required: true}
      },
      {
        name: 'isSystem',
        type: 'boolean',
        caption: 'prhtipocom.fields.sistema'
      }
    ],
    order: 'codPRHTipoCom, nome',
    searchFields: 'codPRHTipoCom,nome'
  },
  autocomplete: {
    rowTemplate: '{{codPRHTipoCom}} - {{nome}}',
    output: '{{codPRHTipoCom}} - {{nome}}',
    searchFields: 'codPRHTipoCom,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.prhtipocom'
      }
    }
  },
  detail: {
    state: {
      component: PRHTipoComEntityEditComponent,
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.prhtipocom'
      }
    }
  }
};
