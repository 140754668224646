import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {FormGroupDirective} from '@angular/forms';
import {copy, IPlToolbarItem, PlAlertService} from 'pl-comps-angular';
import {CONFIG_SITE_MAIL_SMTP_PASSWORD, EConfigSiteEditType, EConfigSiteGroup, IConfigSiteValue} from '../configsSite.module.interface';
import {ConfigSiteService} from '../../../../services/configsite.service';
import {EMPTY_PASSWORD, FORM_INVALID_CANNOT_SUBMIT} from '../../../../../config/constants';
import {ModuloComponent} from '../../../../components/module/module.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';

const TESTE_EMAIL_ID = 'testEmail';
const DELETE_CONFIGS = 'deleteConfigs';
const trueValue = '1';
const falseValue = '0';
@Component({
  selector: 'configs-site',
  templateUrl: './configsSite.module.component.html'
})
export class ConfigsSiteModuleComponent extends ModuloComponent implements OnInit {
  @Input() public configsEmail: Array<IConfigSiteValue>;

  public form: FormGroupDirective;
  public booleanValue: boolean;
  private readonly _btnDeleteConfigs: IPlToolbarItem;
  private readonly _btnTestEmail: IPlToolbarItem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _configSiteService: ConfigSiteService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this.btnSave.visible = true;
    this.btnSave.click = () => this._save();
    this.booleanValue = false;
    this._btnDeleteConfigs = {
      id: DELETE_CONFIGS,
      type: 'button',
      order: this.btnSave.order + 2,
      iconLeft: '<i class="fa fa-fw fa-trash-o"></i>',
      class: 'btn-danger',
      caption: 'configsSite.deleteConfigs',
      click: () => {
        this.deleteEmailConfig();
      }
    };
    this._btnTestEmail = {
      id: TESTE_EMAIL_ID,
      type: 'button',
      order: this.btnSave.order + 1,
      iconLeft: '<i class="fa fa-fw fa-envelope-o"></i>',
      class: 'btn-success',
      caption: 'configsSite.testMail',
      click: () => this._testEmail()
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.configsEmail = this.configsEmail.filter((config: IConfigSiteValue) => config.group === EConfigSiteGroup.Email);
    this.toolbar.addButton(this._btnTestEmail).addButton(this._btnDeleteConfigs);
    this.booleanValue = this.configsEmail.find((config: IConfigSiteValue) => config.type === EConfigSiteEditType.Boolean).value !== falseValue;
  }

  public async deleteEmailConfig(): Promise<void> {
    const itemsToDelete: Array<IConfigSiteValue> = [];
    await this._cgModalService.showOkCancel('configsSite.modalConfirm.title', 'configsSite.modalConfirm.message', {size: 'md', btnOkText: 'global.btn.yes'});
    for (const item of this.configsEmail) {
      if (item.value) {
        if (item.type === EConfigSiteEditType.Boolean) {
          item.value = falseValue;
          this.booleanValue = false;
        } else {
          item.value = '';
        }
      }
      itemsToDelete.push(item);
    }
    await this._configSiteService.saveConfigs(itemsToDelete);
    this._plAlertService.success('configsSite.deleteSuccess');
  }

  private _save(): Promise<void> {
    if (!this.form.valid) {
      this._plAlertService.error('configsSite.error');
      return Promise.reject(new Error(FORM_INVALID_CANNOT_SUBMIT));
    }

    const booleanConfig = this.configsEmail.find((config: IConfigSiteValue) => config.type === EConfigSiteEditType.Boolean);
    booleanConfig.value = this.booleanValue ? trueValue : falseValue;

    const configsToSave: Array<IConfigSiteValue> = copy(this.configsEmail);
    // send password only if altered
    const passwordIndex: number = configsToSave.findIndex((config: IConfigSiteValue) => config.configId.toLowerCase() === CONFIG_SITE_MAIL_SMTP_PASSWORD && config.value === EMPTY_PASSWORD);
    if (passwordIndex !== -1) {
      configsToSave.splice(passwordIndex, 1);
    }

    return this._configSiteService.saveConfigs(configsToSave).then(() => {
      this._plAlertService.success('entity.state.saved');
    });
  }

  private _testEmail(): Promise<void> {
    return this._configSiteService.testeEmail().then((response: HttpResponse<string>) => {
      this._plAlertService.success(response.body);
    });
  }
}
