/**
 * CentralGest Amalia
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ArticleState = 'DELETED' | 'PUBLISHED' | 'DRAFT' | 'READY_TO_PUBLISHED';

export const ArticleState = {
    Deleted: 'DELETED' as ArticleState,
    Published: 'PUBLISHED' as ArticleState,
    Draft: 'DRAFT' as ArticleState,
    ReadyToPublished: 'READY_TO_PUBLISHED' as ArticleState
};

