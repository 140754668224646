import {ENTITY_NAME_RECIBOS, IRecibosEntity} from './recibos.entity.interface';
import {RecibosEditComponent} from './components/edit/recibos.entity.edit.component';
import {RecibosListComponent} from './components/list/recibos.entity.list.component';
import {ROLE} from '../../services/role.const';
import {IJsonAnosFaturacao} from '../anosfaturacao/jsonAnosFaturacao.entity.interface';
import {PortalClientAccountService} from '../../services/portalclientaccount/portalclientaccount.service';

export const ENTITY_RECIBOS: IRecibosEntity = {
  name: ENTITY_NAME_RECIBOS,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.FINANCEIRO],
  searchPlaceholder: 'recibos.pesquisa',
  icon: 'fa-chevron-circle-down',
  metadata: {
    keyName: 'extPocCabID',
    fields: [
      {name: 'nDocAsStr', width: '180px', caption: 'recibos.fields.numero'},
      {name: 'numero', width: '180px', caption: 'recibos.fields.numero', visible: false},
      {name: 'nConta', width: '100px', caption: 'recibos.fields.nconta'},
      {name: 'nome', caption: 'recibos.fields.nome'},
      {name: 'data', width: '100px', type: 'date', caption: 'global.text.date'},
      {name: 'valor', width: '100px', align: 'right', type: 'double', caption: 'global.text.total'},
      {name: 'abreviaturaMoeda', align: 'center', caption: 'recibos.fields.abreviaturaMoeda'},
      {name: 'stampUpdate', type: 'datetime', caption: 'recibos.fields.stampUpdate'},
      {name: 'data>', width: '100px', type: 'date', caption: 'recibos.fields.deData'}, // campo adicionado apenas com o propósito de filtrar a listagem
      {name: 'data<', width: '100px', type: 'date', caption: 'recibos.fields.ateData'}, // campo adicionado apenas com o propósito de filtrar a listagem
      {name: 'anulado', type: 'boolean', caption: 'recibos.fields.anulado', visible: false}
    ],
    listFields: 'nDocAsStr,numero,nDocExterno,nConta,nome,data,valor,abreviaturaMoeda,stampUpdate,anulado',
    searchFields: 'numero,nConta,nome,data,valor,abreviaturaMoeda,stampUpdate,data>,data<,anulado',
    order: 'stampUpdate desc'
  },
  actions: {
    new: true,
    detail: false,
    edit: true,
    delete: false,
    search: true,
    filter: true,
    duplicate: false
  },
  list: {
    state: {
      component: RecibosListComponent,
      resolve: [
        {
          provide: 'anosList',
          deps: [PortalClientAccountService],
          useFactory: (anosConatbilidade: PortalClientAccountService): Promise<Array<IJsonAnosFaturacao>> => {
            return anosConatbilidade.getAnosContabilidade().then((response) => {
              const anosList: Array<IJsonAnosFaturacao> = [];
              response.body.forEach((item) => {
                anosList.push({ano: item.ano});
              });
              return anosList;
            });
          }
        }
      ]
    }
  },
  detail: {
    state: {
      component: RecibosEditComponent,
      params: {
        nConta: {
          type: 'string',
          value: ''
        },
        nome: {
          type: 'string',
          value: ''
        }
      }
    }
  },
  service: function () {
    this.sendEmail = (extpoccabID, nconta, email, ncopias, ano: number) => {
      const params = ano === 0 ? {email: email, nconta: nconta, ncopias: ncopias} : {email: email, nconta: nconta, ncopias: ncopias, ano: ano};
      return this.get({id: `${extpoccabID}/email`, params: params});
    };
  }
};
