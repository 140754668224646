import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, Type, ViewChild, ViewRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Draft, produce} from 'immer';
import {merge} from 'lodash-es';
import moment, {Moment} from 'moment';
import {asyncScheduler, debounceTime, Subscription, timer} from 'rxjs';
import {
  ActionQueue,
  IEditInputEvents,
  IPlEditComponentOptionsInputDatepicker,
  IPlEditComponentOptionsInputText,
  IPlTableNavigationSelectors,
  IPlToolbarItem,
  IPlTooltipConfig,
  isBoolean,
  isDefined,
  isEmpty,
  isFunction,
  isNumber,
  isObject,
  isString,
  KEYCODES,
  Logger,
  PlAlertService,
  PlCompsService,
  PlDocumentService,
  PlTableNavigationDirective,
  timeout
} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {ContabilidadeDigitalServiceDocuments} from '../../../../../services/contabilidadedigital/contabilidadedigital.service.documents';
import {ContabilidadeDigitalService} from '../../../../../services/contabilidadedigital/contabilidadedigital.service';
import {DATA_SOURCE_DEBITO_CREDITO} from '../../../../../datasources/debitocredito/debitoCredito.datasource';
import {DEFAULT_TIMEOUT, EGroupName, EMPTY_GUID} from '../../../../../../config/constants';
import {
  DOC_CONTABILIDADE_CAMPO_ERRO_INFORMATIVO_TO_IGNORE,
  EDocContabilidadeException,
  EDocContabilidadeField,
  IDocContabilidadeCallback,
  IDocContabilidadeContabDigitalAttachment,
  IDocContabilidadeDefinition,
  IDocContabilidadeDefinitionTemplateContext,
  IDocContabilidadeEvaluatedField,
  IDocContabilidadeEventConta,
  IDocContabilidadeLastFocus,
  TDocContabilidadeFnAfterInitDocumento,
  TDocContabilidadeFnLoadPreDefinido,
  TDocContabilidadeFocusField,
  TDocContabilidadePredefinidoEvaluatedCabecalho,
  TDocContabilidadePredefinidoEvaluatedLinha
} from './docContabilidade.interface';
import {DocContabilidadeService} from './docContabilidade.service';
import {DocsContabilidadeEditCCustoModalComponent} from './modals/editccustomodal/docsContabilidade.edit.cCusto.modal.component';
import {DocsContabilidadeEditMoedaEstrangeiraModalComponent} from './modals/editmoedaestrangeiramodal/docsContabilidade.edit.moedaEstrangeira.modal.component';
import {DocsContabilidadeEditMovabModalComponent} from './modals/editmovimentosabertomodal/docsContabilidade.edit.movAb.modal.component';
import {DocsContabilidadeEditRegularizacaoCampo40ModalComponent} from './modals/editregularizacaocampo40modal/docsContabilidade.edit.regularizacaoCampo40.modal.component';
import {DocsContabilidadeEditRetencaoModalComponent} from './modals/editretencaomodal/docsContabilidade.edit.retencao.modal.component';
import {DocsContabilidadeService} from '../../service/docsContabilidade.service';
import {DocsContabilidadeViewDocModalComponent} from './modals/viewdoc/docsContabilidade.viewdoc.modal.component';
import {ECampoCalculadoME, IJsonDocContabilidadeLinha, IJsonRegularizacaoCampo40} from '../../jsonDocContabilidade.interface';
import {EClassificacaoControlo} from '../../../../../../common/enums/contabilidade.enums';
import {EConfigOptionsInstanceName, IDocContabilidadeConfigOptions, TConfigOptions} from '../../../../../services/config/options/config.options.service.interface';
import {EDebitoCredito} from '../../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {elementIndex} from '../../../../../../common/utils/element.utils';
import {emptyPredefinidoContabilidadeLine} from '../../../manutencao/predefinidos/preDefinidosContab.entity.interface';
import {ENTITY_NAME_CLIFOS} from '../../../../../entities/clifos/clifos.entity.interface';
import {ENTITY_NAME_NIFS} from '../../../../../entities/nifs/nifs.entity.interface';
import {EntityMaintenanceService} from '../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IArquivoDigitalDocViewerEvtChangedAttachment} from '../../../../../components/arquivodigital/common/docviewer/arquivodigital.docviewer.component.interface';
import {ICGConfigContabilidade} from '../../../../../services/config/config.service.interface';
import {ICGModalOptions, TCGModalResult} from '../../../../../components/cg/modal/cgmodal.interface';
import {IConfigOptionsInstance} from '../../../../../services/config/options/config.options.instance.interface';
import {IContabDigitalDocViewerRecolhaCallback} from '../../../../../components/arquivodigital/contabilidade/docviewerrecolha/contabilidadedigital.docviewer.recolha.component.interface';
import {IContabDigitalGDocViewerRecolhaDoc} from '../../../../../services/contabilidadedigital/contabilidadedigital.interface';
import {IContabilidadeExtratosGridParams, MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID} from '../../../estatistica/extratosgrid/contabilidade.extratosGrid.module.interface';
import {IDocContabilidade, IDocContabilidadeCCustoResult, IDocContabilidadeException, IDocContabilidadeLinha, IDocContabilidadeRetencao} from '../../docsContabilidade.interface';
import {IEntityAutocompleteCustomActionDefinition, IEntityAutocompleteOptions} from '../../../../../components/entity/entity.autocomplete.definition.interface';
import {IEntityAutocompleteFieldsMap} from '../../../../../components/cg/autocomplete/entity.autocomplete.component.interface';
import {IEntityMaintenanceInstance} from '../../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonContabDigitalConfigs, IJsonDocOCRCab} from '../../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {IJsonMoeda} from '../../../manutencao/moeda/jsonMoeda.interface';
import {IJsonNIFs} from '../../../../../entities/nifs/jsonNifs.entity.interface';
import {IJsonPOC} from '../../../../../entities/pocs/jsonPOC.entity.interface';
import {IJsonPreDefinidoContab, IJsonPreDefinidoContabLinha, TPredefinidoField, TPredefinidoFieldCabecalho} from '../../../manutencao/predefinidos/jsonPreDefinidosContab.entity.interface';
import {IModuleMaintenanceInstance} from '../../../../../components/entity/maintenance/module/module.maintenance.interface';
import {IMovimentosEmAberto} from '../../../../../interfaces/jsonMovimentosEmAberto.interface';
import {INVOICE_TYPE_STRUCT} from '../../../../../datasources/invoicetype/invoiceType.datasource.interface';
import {IRestCommandNovoByPredefinido} from '../../restDocsContabilidadeCommands.interface';
import {isLineDisabled} from '../../../../../../common/utils/utils';
import {MODULE_NAME_PCA_ESTATISTICA_MOVIMENTOS_EM_ABERTO} from '../../../estatistica/movimentosemaberto/contabilidade.movimentosEmAberto.module.interface';
import {ModuleMaintenanceService} from '../../../../../components/entity/maintenance/module/module.maintenance.service';
import {RegistarNifModalComponent} from '../../../../../entities/nifs/modals/registanif/registarNIF.modal.component';
import {TABLE_FLEX_NAVIGATION_OVERRIDES} from '../../../../../../common/data';
import {TDate} from '../../../../../../common/dates';

const TOOLBAR_GROUP_ID = 'doccontabilidade-component';
const FIELDS_ALLOWED_TOGGLE_VALOR_IVA_INCLUIDO: ReadonlyArray<EDocContabilidadeField> = Object.freeze([
  EDocContabilidadeField.CONTA_DEBITO,
  EDocContabilidadeField.CONTA_CREDITO,
  EDocContabilidadeField.VALOR
]);
const FIELDS_SALDAR_DOCUMENTO: ReadonlyArray<EDocContabilidadeField> = FIELDS_ALLOWED_TOGGLE_VALOR_IVA_INCLUIDO;
const SELECTOR_CABECALHO = '.doccontabilidade-cabecalho';
const SELECTOR_LINHAS = '.doccontabilidade-linhas .table-flex-docscontabilidade > .table-flex-body .table-flex-tr';
const LOADING_DELAY = 500;

@Component({
  selector: 'doccontabilidade',
  templateUrl: './docContabilidade.component.html'
})
export class DocContabilidadeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public model: IDocContabilidade;
  @Input() public editing: boolean;
  @Input() public predefinido: IJsonPreDefinidoContab;
  @Input() public simulation: boolean;
  @Input() public calculouDiferimento: boolean;
  @Input() public contabilidadeDigital: boolean;
  @Input() public contabilidadeDigitalAttachment: IDocContabilidadeContabDigitalAttachment;
  @Input() public callback: IDocContabilidadeCallback;
  @Input() public toolbarInstanceName: string;
  @Input() public onLoadPreDefinido: TDocContabilidadeFnLoadPreDefinido;
  @Input() public onLoadFocusField: TDocContabilidadeFocusField;
  @Input() public onAfterInitDocument: TDocContabilidadeFnAfterInitDocumento;
  @Input() public erroInformativo: string;
  @Input() public contabilidadeDigitalDocumentsService: ContabilidadeDigitalServiceDocuments;
  @Input() public digitalDocOCRCabID: string;
  @Input() public keepCabAfterClearPreDefinido: boolean;
  @Output() public readonly modelChange: EventEmitter<IDocContabilidade>;
  @Output() public readonly contabilidadeDigitalAttachmentChange: EventEmitter<IDocContabilidadeContabDigitalAttachment>;
  @Output() public readonly calculouDiferimentoChange: EventEmitter<boolean>;
  @Output() public readonly disableActionDiferimentos: EventEmitter<boolean>;
  @ViewChild('templateContentPerguntaCC') public readonly templateContentPerguntaCC: TemplateRef<HTMLElement>;

  public readonly configContabilidade: ICGConfigContabilidade;
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly btnConfigOptions: IPlToolbarItem;
  public readonly tooltipDataLancamento: IPlTooltipConfig;
  public readonly tooltipDataDoc: IPlTooltipConfig;
  public readonly tooltipMoeda: IPlTooltipConfig;
  public readonly fieldsMapPeriodo: IEntityAutocompleteFieldsMap;
  public readonly fieldsMapDiario: IEntityAutocompleteFieldsMap;
  public readonly fieldsMapNContribuinte: IEntityAutocompleteFieldsMap;
  public readonly fieldsMapDescritivo: IEntityAutocompleteFieldsMap;
  public readonly tableNavigationSelectorOverrides: Partial<IPlTableNavigationSelectors>;
  public readonly callbackDocViewer: IContabDigitalDocViewerRecolhaCallback;
  public readonly customActionsPeriodo: Array<IEntityAutocompleteCustomActionDefinition>;

  public definitionUI: Array<IDocContabilidadeDefinition>;
  public itemInfo: number;
  public locked: boolean;
  public hasPreDefinido: boolean;
  public docNotInitialized: boolean;
  public labelPerguntaCC: string;
  public disableAutoFocus: boolean;
  public activeNLanc: string;
  public contabilidadeDigitalDoc: IContabDigitalGDocViewerRecolhaDoc;
  public contabilidadeDigitalIgnorePredefinido: boolean;
  public hintMarcadoComoConsistente: string;
  public isMobile: boolean;
  public isDiferimento: boolean;
  public docViewerFooterCollapsed: boolean;
  public loadingAnularCalculoDiferimento: boolean;
  public promise: Promise<unknown>;
  public promiseUI: Promise<unknown>;

  public optionValorComIvaIncluido: boolean;
  public optionObtemDadosDocDigital: boolean;
  public optionShowFieldPredefinido: boolean;
  public optionShowFieldDataVencimento: boolean;
  public optionShowFieldMoeda: boolean;
  public optionShowFieldLinhaNif: boolean;
  public optionShowFieldLinhaValorTaxa: boolean;
  public optionShowFieldLinhaDataDoc: boolean;
  public optionShowFieldLinhaDescricao: boolean;
  public optionShowFieldLinhaCDecPerAnual: boolean;
  public optionShowFieldLinhaNomeConta: boolean;
  public optionSeletorObtemDadosDocDigital: boolean;
  public optionSeletorValorComIvaIncluido: boolean;
  public optionSkipDescricao: boolean;
  public optionRetainDescription: boolean;
  public optionShowFormMoeda: boolean;
  public optionSkipPromptSave: boolean;
  public optionDocViewerFooterCollapsed: boolean;

  public visiblePeriodo: boolean;
  public visibleDiario: boolean;
  public visibleDataLancamento: boolean;
  public visibleDataDoc: boolean;
  public visibleDataVencimento: boolean;
  public visibleNContribuinte: boolean;
  public visibleDescricao: boolean;
  public visibleDescritivo: boolean;
  public visibleNDocExterno: boolean;

  public readonlyPeriodo: boolean;
  public readonlyDiario: boolean;
  public readonlyDataLancamento: boolean;
  public readonlyDataDoc: boolean;
  public readonlyDataVencimento: boolean;
  public readonlyNContribuinte: boolean;
  public readonlyDescricao: boolean;
  public readonlyDescritivo: boolean;
  public readonlyNDocExterno: boolean;

  public tabStopPeriodo: boolean;
  public tabStopDiario: boolean;
  public tabStopDataLancamento: boolean;
  public tabStopDataDoc: boolean;
  public tabStopDataVencimento: boolean;
  public tabStopNContribuinte: boolean;
  public tabStopDescricao: boolean;
  public tabStopDescritivo: boolean;
  public tabStopNDocExterno: boolean;

  public propertiesPreDefinido: IEntityAutocompleteOptions;
  public propertiesPeriodo: IEntityAutocompleteOptions;
  public propertiesDiario: IEntityAutocompleteOptions;
  public propertiesDataLancamento: IPlEditComponentOptionsInputDatepicker;
  public propertiesDataDoc: IPlEditComponentOptionsInputDatepicker;
  public propertiesDataVencimento: IPlEditComponentOptionsInputDatepicker;
  public propertiesNContribuinte: IEntityAutocompleteOptions;
  public propertiesDescricao: IPlEditComponentOptionsInputText<string>;
  public propertiesDescritivo: IEntityAutocompleteOptions;
  public propertiesNDocExterno: IPlEditComponentOptionsInputText<string>;
  public propertiesMoeda: IEntityAutocompleteOptions;

  private readonly _element: HTMLElement;
  private readonly _actionQueue: ActionQueue;
  private readonly _nifs: IEntityService<IJsonNIFs>;
  private readonly _definitionNContaDebito: IDocContabilidadeDefinition;
  private readonly _definitionNContaCredito: IDocContabilidadeDefinition;
  private readonly _definitionNContribuinte: IDocContabilidadeDefinition;
  private readonly _definitionValor: IDocContabilidadeDefinition;
  private readonly _definitionCambio: IDocContabilidadeDefinition;
  private readonly _definitionValorME: IDocContabilidadeDefinition;
  private readonly _definitionValorTaxa: IDocContabilidadeDefinition;
  private readonly _definitionDataDoc: IDocContabilidadeDefinition;
  private readonly _definitionDescricao: IDocContabilidadeDefinition;
  private readonly _definitionCDecPer: IDocContabilidadeDefinition;
  private readonly _definitionCDecAnual: IDocContabilidadeDefinition;
  private readonly _definitionNomeConta: IDocContabilidadeDefinition;
  private readonly _definition: Array<IDocContabilidadeDefinition>;
  private readonly _maintenanceExtratosGrid: IModuleMaintenanceInstance;
  private readonly _maintenanceMovimentosEmAberto: IModuleMaintenanceInstance;
  private readonly _maintenanceClifos: IEntityMaintenanceInstance;
  private readonly _predefinidoEvaluatedCabecalho: Array<TDocContabilidadePredefinidoEvaluatedCabecalho>;
  private readonly _predefinidoEvaluatedLinhas: Map<number, Array<TDocContabilidadePredefinidoEvaluatedLinha>>;
  private readonly _handledCentroCustoLines: Set<string>;
  private readonly _subscriptionOnException: Subscription;
  private readonly _subscriptionContabDigitalConfigs: Subscription;
  private readonly _subscriptionIsMobile: Subscription;
  private readonly _configOptionsInstance: IConfigOptionsInstance<boolean, IDocContabilidadeConfigOptions>;

  private _subscriptionClearActiveNLanc: Subscription;
  private _subscriptionConfigOptions: Subscription;
  private _subscriptionPrepareFocus: Subscription;
  private _subscriptionLoading: Subscription;
  private _docContabilidadeOptions: TConfigOptions<boolean, IDocContabilidadeConfigOptions>;
  private _contabDigitalConfigs: IJsonContabDigitalConfigs;
  private _lastFocusElement: IDocContabilidadeLastFocus;
  private _tableNavigation: PlTableNavigationDirective;
  private _docOCRCab: IJsonDocOCRCab;
  private _nDocumento: string;
  private _itemInfoOriginal: number;
  private _restoreLastFocus: boolean;
  private _appliedPreDefinidoOptions: boolean;
  private _isDocEmpty: boolean;
  private _isMoedaPresent: boolean;
  private _contabilidadeDigitalChangedFolder: boolean;
  private _destroyed: boolean;
  private _fromClearPreDefinido: boolean;

  constructor(
    @Inject(ChangeDetectorRef) private readonly _viewRef: ViewRef,
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plCompsService: PlCompsService,
    private readonly _plDocumentService: PlDocumentService,
    private readonly _docsContabilidadeService: DocsContabilidadeService,
    private readonly _docContabilidadeService: DocContabilidadeService,
    private readonly _cgModalService: CGModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _logger: Logger,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _contabilidadeDigitalService: ContabilidadeDigitalService
  ) {
    this.editing = false;
    this.simulation = false;
    this.contabilidadeDigital = false;
    this.contabilidadeDigitalAttachment = {gDocId: '', gDocFolderId: '', docOCRCabID: ''};
    this.contabilidadeDigitalDocumentsService = this._contabilidadeDigitalService.documents;
    this.modelChange = new EventEmitter<IDocContabilidade>();
    this.contabilidadeDigitalAttachmentChange = new EventEmitter<IDocContabilidadeContabDigitalAttachment>();
    this.calculouDiferimentoChange = new EventEmitter<boolean>();
    this.disableActionDiferimentos = new EventEmitter<boolean>();
    this.configContabilidade = this._docContabilidadeService.getConfigurations();
    this.configOptionsInstanceName = EConfigOptionsInstanceName.DOC_CONTABILIDADE;
    this.configOptionsGroupName = EGroupName.CONTABILIDADE;
    this.btnConfigOptions = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'doccontabilidadeconfigs',
      tooltip: {
        disabled: true,
        text: 'docscontabilidade.erros.optionsNotAvailable',
        placement: 'bottom',
        tooltipClass: 'tooltip-min-width'
      }
    };
    this.tooltipDataLancamento = {text: 'docscontabilidade.doc.tooltipDataLancamento', tooltipClass: 'tooltip-min-width', placement: 'bottom'};
    this.tooltipDataDoc = {text: 'docscontabilidade.doc.tooltipDataDoc', tooltipClass: 'tooltip-min-width', placement: 'bottom'};
    this.tooltipMoeda = {text: 'docscontabilidade.erros.changeMoedaNotAvailable', tooltipClass: 'tooltip-min-width', disabled: true};
    this.fieldsMapPeriodo = {nome: 'nomePeriodo'};
    this.fieldsMapDiario = {nome: 'nomeDiario'};
    this.fieldsMapNContribuinte = {nConta: 'nContribuinte', nif: 'nContribuinte'};
    this.fieldsMapDescritivo = {nDescrit: 'codDescritivo', nome: 'nomeDescritivo'};
    this.disableAutoFocus = true;
    this.tableNavigationSelectorOverrides = TABLE_FLEX_NAVIGATION_OVERRIDES;
    this.callbackDocViewer = {};
    this.customActionsPeriodo = [
      {
        caption: 'docscontabilidade.text.mudarperiodocontab',
        icon: 'fa-fw fa-retweet',
        action: () => this._mudaPeriodoContabEmp()
      }
    ];
    this.hasPreDefinido = false;
    this.docNotInitialized = false;
    this.editing = false;
    this.contabilidadeDigitalIgnorePredefinido = false;

    this._element = this._elementRef.nativeElement;
    this._actionQueue = new ActionQueue(this._logger);
    this._nifs = this._entityServiceBuilder.build<IJsonNIFs>(ENTITY_NAME_NIFS);
    this._definitionNContaDebito = {
      defaultIndex: 0,
      index: 0,
      id: EDocContabilidadeField.CONTA_DEBITO,
      caption: 'docscontabilidade.doc.linhas.nContaDebito',
      preDefinidoIndex: 0,
      evaluatePreDefinidoIndex: 0,
      templateRef: undefined,
      show: true
    };
    this._definitionNContaCredito = {
      defaultIndex: 1,
      index: 1,
      id: EDocContabilidadeField.CONTA_CREDITO,
      caption: 'docscontabilidade.doc.linhas.nContaCredito',
      preDefinidoIndex: 1,
      evaluatePreDefinidoIndex: 0,
      templateRef: undefined,
      show: true
    };
    this._definitionNContribuinte = {
      defaultIndex: 2,
      index: 2,
      id: EDocContabilidadeField.NCONTRIBUINTE,
      caption: 'docscontabilidade.doc.linhas.poc.nif',
      preDefinidoIndex: 4,
      evaluatePreDefinidoIndex: 1,
      templateRef: undefined,
      show: true
    };
    this._definitionValor = {
      defaultIndex: 3,
      index: 3,
      id: EDocContabilidadeField.VALOR,
      caption: 'docscontabilidade.doc.linhas.valor',
      preDefinidoIndex: 2,
      evaluatePreDefinidoIndex: 2,
      templateRef: undefined,
      show: true
    };
    this._definitionCambio = {
      defaultIndex: 4,
      index: 4,
      id: EDocContabilidadeField.CAMBIO,
      caption: 'docscontabilidade.doc.linhas.cambio',
      preDefinidoIndex: 6,
      evaluatePreDefinidoIndex: -1,
      templateRef: undefined,
      show: true
    };
    this._definitionValorME = {
      defaultIndex: 5,
      index: 5,
      id: EDocContabilidadeField.VALOR_ME,
      caption: 'docscontabilidade.doc.linhas.valorME',
      preDefinidoIndex: 7,
      evaluatePreDefinidoIndex: -1,
      templateRef: undefined,
      show: true
    };
    this._definitionValorTaxa = {
      defaultIndex: 6,
      index: 6,
      id: EDocContabilidadeField.VALOR_TAXA,
      caption: 'docscontabilidade.doc.linhas.valorTaxa',
      preDefinidoIndex: 3,
      evaluatePreDefinidoIndex: 3,
      templateRef: undefined,
      show: true
    };
    this._definitionDataDoc = {
      defaultIndex: 7,
      index: 7,
      id: EDocContabilidadeField.DATA_DOC,
      caption: 'docscontabilidade.doc.linhas.dataDoc',
      preDefinidoIndex: 8,
      evaluatePreDefinidoIndex: 4,
      templateRef: undefined,
      show: false
    };
    this._definitionDescricao = {
      defaultIndex: 8,
      index: 8,
      id: EDocContabilidadeField.DESCRICAO,
      caption: 'docscontabilidade.doc.linhas.descricao',
      preDefinidoIndex: 5,
      evaluatePreDefinidoIndex: 5,
      templateRef: undefined,
      show: true
    };
    this._definitionCDecPer = {
      defaultIndex: 9,
      index: 9,
      id: EDocContabilidadeField.C_DEC_PER,
      caption: 'docscontabilidade.doc.linhas.cDecPer',
      preDefinidoIndex: 6,
      evaluatePreDefinidoIndex: -1,
      templateRef: undefined,
      show: true
    };
    this._definitionCDecAnual = {
      defaultIndex: 10,
      index: 10,
      id: EDocContabilidadeField.C_DEC_ANUAL,
      caption: 'docscontabilidade.doc.linhas.cDecAnual',
      preDefinidoIndex: 7,
      evaluatePreDefinidoIndex: -1,
      templateRef: undefined,
      show: true
    };
    this._definitionNomeConta = {
      defaultIndex: 11,
      index: 11,
      id: EDocContabilidadeField.NOME_CONTA,
      caption: 'docscontabilidade.doc.linhas.poc.nomePlaceholder',
      preDefinidoIndex: 11,
      evaluatePreDefinidoIndex: -1,
      templateRef: undefined,
      show: true,
      cssClass: 'form-control-align'
    };
    this._definition = [
      this._definitionNContaDebito,
      this._definitionNContaCredito,
      this._definitionNContribuinte,
      this._definitionValor,
      this._definitionCambio,
      this._definitionValorME,
      this._definitionValorTaxa,
      this._definitionDataDoc,
      this._definitionDescricao,
      this._definitionCDecPer,
      this._definitionCDecAnual,
      this._definitionNomeConta
    ];
    this.definitionUI = this._definition.slice();
    this.isDiferimento = false;
    this.loadingAnularCalculoDiferimento = false;
    this._maintenanceExtratosGrid = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID);
    this._maintenanceMovimentosEmAberto = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_MOVIMENTOS_EM_ABERTO);
    this._maintenanceClifos = this._entityMaintenanceService.build(ENTITY_NAME_CLIFOS);
    this._predefinidoEvaluatedCabecalho = [];
    this._predefinidoEvaluatedLinhas = new Map<number, Array<TDocContabilidadePredefinidoEvaluatedLinha>>();
    this._handledCentroCustoLines = new Set<string>();
    this._restoreLastFocus = false;
    this._appliedPreDefinidoOptions = false;
    this._isDocEmpty = true;
    this._isMoedaPresent = false;
    this._destroyed = false;
    this._configOptionsInstance = this._docContabilidadeService.configOptionsDocContabilidade;

    this._subscriptionOnException = this._docsContabilidadeService
      .onException()
      .pipe(debounceTime(DEFAULT_TIMEOUT))
      .subscribe((exception: IDocContabilidadeException) => {
        this._handleServiceException(exception);
      });

    this._subscriptionContabDigitalConfigs = this._contabilidadeDigitalService.configs.getConfigs().subscribe((contabDigitalConfigs: IJsonContabDigitalConfigs) => {
      this._contabDigitalConfigs = contabDigitalConfigs;
    });

    this._subscriptionIsMobile = this._plDocumentService.isMobile().subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
      this._evaluateDocViewerFooterCollapsed();
    });
  }

  public ngOnInit(): void {
    this.editing = Boolean(this.editing);
    this._initModel();
    let firstTime = true;
    let optionShowFieldLinhaDescricaoPreviousValue: boolean;
    this._subscriptionConfigOptions = this._configOptionsInstance.options().subscribe((value: TConfigOptions<boolean, IDocContabilidadeConfigOptions>) => {
      this._docContabilidadeOptions = value;
      this.optionValorComIvaIncluido = this._docContabilidadeOptions.get('valorComIvaIncluido').value;
      this.optionObtemDadosDocDigital = this._docContabilidadeOptions.get('obtemDadosDocDigital').value;
      this.optionShowFieldPredefinido = this._docContabilidadeOptions.get('showFieldPredefinido').value;
      this.optionShowFieldDataVencimento = this._docContabilidadeOptions.get('showFieldDataVencimento').value;
      this.optionShowFieldMoeda = this._docContabilidadeOptions.get('showFieldMoeda').value;
      this.optionShowFieldLinhaNif = this._docContabilidadeOptions.get('showFieldLinhaNif').value;
      this.optionShowFieldLinhaValorTaxa = this._docContabilidadeOptions.get('showFieldLinhaValorTaxa').value;
      this.optionShowFieldLinhaDataDoc = this._docContabilidadeOptions.get('showFieldLinhaDataDoc').value;
      this.optionShowFieldLinhaDescricao = this._docContabilidadeOptions.get('showFieldLinhaDescricao').value;
      this.optionShowFieldLinhaCDecPerAnual = this._docContabilidadeOptions.get('showFieldLinhaCDecPerAnual').value;
      this.optionShowFieldLinhaNomeConta = this._docContabilidadeOptions.get('showFieldLinhaNomeConta').value;
      this.optionSeletorObtemDadosDocDigital = this._docContabilidadeOptions.get('seletorObtemDadosDocDigital').value;
      this.optionSeletorValorComIvaIncluido = this._docContabilidadeOptions.get('seletorValorComIvaIncluido').value;
      this.optionSkipDescricao = this._docContabilidadeOptions.get('skipDescricao').value;
      this.optionRetainDescription = this._docContabilidadeOptions.get('retainDescription').value;
      this.optionShowFormMoeda = this._docContabilidadeOptions.get('showFormMoeda').value;
      this.optionSkipPromptSave = this._docContabilidadeOptions.get('skipPromptSave').value;
      this.optionDocViewerFooterCollapsed = this._docContabilidadeOptions.get('docViewerFooterCollapsed').value;
      if (
        this.optionShowFieldLinhaDescricao &&
        this.optionSkipDescricao &&
        isBoolean(optionShowFieldLinhaDescricaoPreviousValue) &&
        this.optionShowFieldLinhaDescricao !== optionShowFieldLinhaDescricaoPreviousValue
      ) {
        this._configOptionsInstance.setOption('skipDescricao', false);
      }
      optionShowFieldLinhaDescricaoPreviousValue = this.optionShowFieldLinhaDescricao;
      if (firstTime) {
        firstTime = false;
        this._setLoading(this._initDocumento());
      }
      // When pre-definido is set this method is called in method "_handlePreDefinido()"
      if (!this.hasPreDefinido) {
        this._evaluateColumns();
      }
      this._evaluateDocViewerFooterCollapsed();
    });
    if (this.editing) {
      this._evaluateContabilidadeDigitalDoc();
    }
  }

  public ngOnChanges({model, predefinido, editing, calculouDiferimento, contabilidadeDigital, contabilidadeDigitalAttachment, callback}: SimpleChanges): void {
    const changedModel: boolean = model && !model.isFirstChange() && (<IDocContabilidade>model.currentValue)?.nDocumento !== this._nDocumento;
    const changedContabilidadeDigital: boolean = contabilidadeDigital && !contabilidadeDigital.isFirstChange();
    const changedEditing: boolean = editing && !editing.isFirstChange();
    if (changedModel || changedContabilidadeDigital) {
      if (changedContabilidadeDigital) {
        if (contabilidadeDigital.currentValue !== true) {
          this._docOCRCab = undefined;
        }
        this.contabilidadeDigitalDoc = undefined;
      }
      this._initModel();
      this._initDocumento();
    }
    if (predefinido && !predefinido.isFirstChange()) {
      this.loadPreDefinido(predefinido.currentValue);
    } else if (changedEditing || (calculouDiferimento && !calculouDiferimento.isFirstChange())) {
      if (changedEditing) {
        this.editing = Boolean(editing.currentValue);
      }
      this._evaluateProperties();
    }
    if (contabilidadeDigitalAttachment) {
      this._changedContabilidadeDigitalAttachment(contabilidadeDigitalAttachment.currentValue);
    }
    if (callback) {
      const docContabilidadeCallback: IDocContabilidadeCallback = callback.currentValue;
      if (isObject(docContabilidadeCallback)) {
        docContabilidadeCallback.docViewer = this.callbackDocViewer;
        docContabilidadeCallback.addLine = () => {
          this.addLine();
        };
        docContabilidadeCallback.reset = (docContabilidade?: IDocContabilidade, focus?: boolean, focusField?: TDocContabilidadeFocusField) => this.reset(docContabilidade, focus, focusField);
        docContabilidadeCallback.resetAndInit = (docContabilidade?: IDocContabilidade, focus?: boolean, focusField?: TDocContabilidadeFocusField) =>
          this.resetAndInit(docContabilidade, focus, focusField);
        docContabilidadeCallback.removePreDefinido = () => {
          this._removePreDefinido();
        };
        docContabilidadeCallback.prepareFocus = (field?: TDocContabilidadeFocusField) => {
          this._prepareFocus(field);
        };
        docContabilidadeCallback.focusFieldCabecalho = (fieldName: TPredefinidoFieldCabecalho, exact?: boolean) => this._focusFieldCabecalho(fieldName, exact);
        docContabilidadeCallback.focusFieldLinha = (fieldName: EDocContabilidadeField, indexLinha: number) => this._focusFieldLinha(fieldName, indexLinha);
        docContabilidadeCallback.focusLastElement = (delay?: boolean) => {
          this._focusLastElement(delay);
        };
        docContabilidadeCallback.doMovimentosAbertoForLines = (lines: Array<string | IJsonDocContabilidadeLinha>) => this._doMovimentosAbertoForLines(lines);
        docContabilidadeCallback.doMovimentosAbertoForAllLines = () => this._doMovimentosAbertoForAllLines();
        docContabilidadeCallback.doPostValorChangeForLines = (lines: Array<string | IJsonDocContabilidadeLinha>) => this._doPostValorChangeForLines(lines);
        docContabilidadeCallback.doPostValorChangeForAllLines = () => this._doPostValorChangeForAllLines();
        docContabilidadeCallback.setErroInformativo = (value: string) => {
          this._setErroInformativo(value);
        };
        docContabilidadeCallback.clearErroInformativo = () => {
          this._clearErroInformativo();
        };
      }
    }
    if (calculouDiferimento) {
      this.calculouDiferimento = calculouDiferimento.currentValue;
    }
  }

  public ngOnDestroy(): void {
    this._destroyed = true;
    this._docContabilidadeService.clearBackup();
    this._subscriptionOnException.unsubscribe();
    this._subscriptionContabDigitalConfigs.unsubscribe();
    this._subscriptionIsMobile.unsubscribe();
    if (this._subscriptionConfigOptions) {
      this._subscriptionConfigOptions.unsubscribe();
    }
    if (this._subscriptionClearActiveNLanc) {
      this._subscriptionClearActiveNLanc.unsubscribe();
    }
    if (this._subscriptionLoading) {
      this._subscriptionLoading.unsubscribe();
    }
    if (this._appliedPreDefinidoOptions) {
      this._configOptionsInstance.reloadOptions();
    }
  }

  public onResizerValuesChanged(): void {
    if (!this._destroyed && isObject(this.callbackDocViewer.pdf) && isFunction(this.callbackDocViewer.pdf.updateSize)) {
      setTimeout(this.callbackDocViewer.pdf.updateSize);
    }
  }

  public loadPreDefinido(predefinido: IJsonPreDefinidoContab): Promise<void> {
    if (!isObject(predefinido) || !isNumber(predefinido.preDefinidosID)) {
      if (this.hasPreDefinido) {
        return this.clearPreDefinido();
      }
      return Promise.resolve();
    }
    this.predefinido = predefinido;
    this.model.isUsingPreDefinido = false;
    this.model.predefinido = undefined;
    const promise: Promise<void> = (async () => {
      try {
        let jsonDoc: IDocContabilidade;
        if (isFunction(this.onLoadPreDefinido)) {
          jsonDoc = await this.onLoadPreDefinido({
            predefinido: predefinido,
            docContabilidade: this.model,
            docOCRCab: this._docOCRCab
          });
          if (jsonDoc) {
            this._docsContabilidadeService.handleCommandResponse(jsonDoc, true, true, true);
          }
        } else {
          const docOCRCabID: string = this.contabilidadeDigital && this.optionObtemDadosDocDigital && this._docOCRCab ? this._docOCRCab.docOCRCabID : undefined;
          if (!this.simulation && (!isObject(this.predefinido) || !this.predefinido.linhas?.length)) {
            jsonDoc = await this._docsContabilidadeService.novoByPredefinidoID(predefinido.preDefinidosID, docOCRCabID, this.model);
          } else {
            const resultNovoByPredefinido: IRestCommandNovoByPredefinido = await this._docsContabilidadeService.novoByPredefinido(predefinido, docOCRCabID, this.model);
            resultNovoByPredefinido.jsonDoc.predefinido = resultNovoByPredefinido.preDefinido;
            jsonDoc = resultNovoByPredefinido.jsonDoc;
          }
        }
        jsonDoc.isUsingPreDefinido = isObject(jsonDoc) && isObject(jsonDoc.predefinido);
        this.hasPreDefinido = jsonDoc.isUsingPreDefinido;
        this._applyModel(jsonDoc);
        this._setHasErrorInitDoc(!this.model.periodo || !this.model.nDiario);
        this._handlePreDefinido();
      } catch (error: unknown) {
        this._logger.error(error);
        await this.clearPreDefinido();
      }
    })();
    this._setLoading(promise);
    return promise;
  }

  public addLine(): boolean {
    if (this.locked || (this.model.linhas.length > 0 && this._docContabilidadeService.isLineEmpty(this.model.linhas[this.model.linhas.length - 1]))) {
      return false;
    }
    const newLine: IDocContabilidadeLinha = this._docContabilidadeService.emptyLine(this.model);
    if (this.hasPreDefinido) {
      const predefinidoLine: IJsonPreDefinidoContabLinha = emptyPredefinidoContabilidadeLine();
      predefinidoLine.fake = true;
      this.predefinido.linhas.push(predefinidoLine);
      newLine.preDefinidoContabLinhaIndice = this.predefinido.linhas.length - 1;
      newLine._predefinidoFake = true;
    }
    this.model.linhas = [...this.model.linhas, newLine];
    if (this.hasPreDefinido) {
      this._evaluatePreDefinidoLinhas();
    }
    return true;
  }

  public removeLine(index: number): void {
    if (this.locked) {
      return;
    }
    this.promise = this._docsContabilidadeService.linhaDelete(index, this.model).then((response: IDocContabilidade) => {
      this._applyModel(response);
      if (!this.model.linhas.length) {
        this.addLine();
      }
    });
  }

  public evaluateVisible(field: TPredefinidoField, index?: number): boolean {
    return this._docContabilidadeService.evaluateVisible(this.model, field, index);
  }

  public evaluateReadOnly(field: TPredefinidoField, index?: number): boolean {
    return this._docContabilidadeService.evaluateReadOnly(this.model, field, index);
  }

  public evaluateTabStop(field: TPredefinidoField, index?: number): boolean {
    return this._docContabilidadeService.evaluateTabStop(this.model, field, index);
  }

  public onPeriodoChange(value: string): void {
    if (this.editing) {
      return;
    }
    if (!value || !this.model.nDiario) {
      this.model.periodo = value;
      this._setHasErrorInitDoc(true);
      this._applyModel();
      return;
    }
    this._setHasErrorInitDoc(false);
    if (!this.hasPreDefinido) {
      const docOCRCabID: string = !this._docOCRCab ? undefined : this._docOCRCab.docOCRCabID;
      this._setLoading(
        this._docsContabilidadeService
          .novoDocumento(value, this.model.nDiario, docOCRCabID, this.model)
          .then((response: IDocContabilidade) => {
            this._applyModel(response);
          })
          .catch((reason: HttpErrorResponse) => {
            this._logger.error(reason);
            this._setHasErrorInitDoc(true);
          })
      );
    } else {
      this.model.periodo = value;
      if (!isEmpty(this.digitalDocOCRCabID) && this.predefinido?.preDefinidosID === 0) {
        this.promise = this._docsContabilidadeService
          .novoByDocOCRCabID(this.digitalDocOCRCabID, this._docContabilidadeService.emptyDoc(), true, false, value)
          .then((docContabilidade: IDocContabilidade) => {
            docContabilidade.isUsingPreDefinido = isObject(docContabilidade) && isObject(docContabilidade.predefinido);
            this.hasPreDefinido = docContabilidade.isUsingPreDefinido;
            this._applyModel(docContabilidade);
            this._setHasErrorInitDoc(!this.model.periodo || !this.model.nDiario);
            this._handlePreDefinido();
          })
          .catch(async (reason: unknown) => {
            this._logger.error(reason);
            await this.clearPreDefinido();
          })
          .finally(() => {
            this.promise = undefined;
          });
      } else {
        this.loadPreDefinido(this.predefinido);
      }
    }
  }

  public onDiarioChange(value: number): void {
    if (this.editing) {
      return;
    }
    if (!value || !this.model.periodo) {
      this.model.nDiario = value;
      this._setHasErrorInitDoc(true);
      this._applyModel();
      return;
    }
    this._setHasErrorInitDoc(false);
    if (!this.hasPreDefinido) {
      const docOCRCabID: string = !this._docOCRCab ? undefined : this._docOCRCab.docOCRCabID;
      this._setLoading(
        this._docsContabilidadeService
          .novoDocumento(this.model.periodo, value, docOCRCabID, this.model)
          .then((response: IDocContabilidade) => {
            this._applyModel(response);
          })
          .catch((reason: HttpErrorResponse) => {
            this._logger.error(reason);
            this._setHasErrorInitDoc(true);
          })
      );
    } else {
      this.model.nDiario = value;
      this.loadPreDefinido(this.predefinido);
    }
  }

  public onNDocExternoChange(value: string): void {
    this._setLoading(
      this._docsContabilidadeService.documentoExternoChanged(value, this.model).then((response: IDocContabilidade) => {
        this._applyModel(response);
      })
    );
  }

  public onDescritivoChange(value: number): void {
    this._setLoading(
      this._docsContabilidadeService.descritivoChanged(value, this.model).then((response: IDocContabilidade) => {
        this._applyModel(response);
      })
    );
  }

  public onDescricaoChange(value: string): void {
    this._setLoading(
      this._docsContabilidadeService.descricaoChanged(value, this.model).then((response: IDocContabilidade) => {
        this._applyModel(response);
      })
    );
  }

  public onMoedaChange(value: IJsonMoeda): void {
    if (this.editing) {
      return;
    }
    if (value && isNumber(value.codMoeda)) {
      this._setLoading(
        this._docsContabilidadeService.moedaChanged(value.codMoeda, value.nomeMoeda, this.model).then((response: IDocContabilidade) => {
          this._applyModel(response);
        })
      );
    }
  }

  public onNContribuinteChange(value: IJsonPOC | IJsonNIFs | string): void {
    const nif: string = isObject(value) ? ((<IJsonPOC>value).nif ? (<IJsonPOC>value).nif : (<IJsonNIFs>value).nContribuinte) : <string>value;
    this._setLoading(
      this._registarNif(nif).then(([nContribuinte]: [string, boolean]) => {
        const previousNif: string = this.model.nContribuinte;
        this._docsContabilidadeService
          .contribuinteChanged(nContribuinte, this.model)
          .then((response: IDocContabilidade) => {
            this._applyModel(response);
          })
          .catch((reason: HttpErrorResponse) => {
            this._logger.error(reason);
            if (previousNif && !nif) {
              this._applyModel({...this.model, nContribuinte: previousNif});
            }
          });
      })
    );
  }

  public onDataLancamentoChange(value: TDate): void {
    const dataLancamento: Moment = moment(value);
    if (!moment(this.model.dataLancamento).isSame(dataLancamento, 'day')) {
      this._setLoading(
        this._docsContabilidadeService.dataLancamentoChanged(dataLancamento, this.model).then((response: IDocContabilidade) => {
          this._applyModel(response);
        })
      );
    }
  }

  public onDataVencimentoChange(value: TDate): void {
    const dataVencimento: Moment = moment(value);
    if (!moment(this.model.dataVencimento).isSame(dataVencimento, 'day')) {
      this._setLoading(
        this._docsContabilidadeService.dataVencimentoChanged(dataVencimento, this.model).then((response: IDocContabilidade) => {
          this._applyModel(response);
        })
      );
    }
  }

  public onDataDocChange(value: TDate): void {
    const dataDoc: Moment = moment(value);
    if (!moment(this.model.dataDoc).isSame(dataDoc, 'day')) {
      this._setLoading(
        this._docsContabilidadeService.dataDocChanged(dataDoc, this.model).then((response: IDocContabilidade) => {
          this._applyModel(response);
        })
      );
    }
  }

  public onLinhaNContaChange({value, oldValue, type, linha}: IDocContabilidadeEventConta): void {
    linha.dc = type;
    if (isEmpty(value) && isEmpty(oldValue)) {
      return;
    }
    /**
     * Tem de ir ao servidor nas seguintes condições:
     *   - Limpou a conta
     *   - Mudou a conta
     *   - Mudou a conta de tipo (débito/crédito)
     *   - Se a conta tiver CC, centro de custo ou IVA
     *   - Se estamos em modo pré-definidos (não genérico)
     *   - Se estamos em modo moeda estrangeira
     */
    if ((!value && oldValue) || !isObject(value) || (value && oldValue && value.nConta !== oldValue.nConta) || this._docContabilidadeService.isLineContaCalculatedOnline(value, this.model)) {
      this._setLoading(
        this._docsContabilidadeService
          .linhaContaChanged(isObject(value) ? value.nConta : <string>(<unknown>value), type, linha._index, this.model)
          .then((response: IDocContabilidade) => {
            this._applyModel(response);
            if (this.model.linhas.length) {
              linha = this._docContabilidadeService.getLinha(this.model, linha.nLanc);
            } else {
              this.addLine();
              linha = this.model.linhas[0];
            }
            this._setLinhaNContaValidationStatus(linha, type, false);
            let promise: Promise<void>;
            if (this._docContabilidadeService.doMovimentosAberto(this.model, linha, value?.nConta === oldValue?.nConta)) {
              promise = timeout(DEFAULT_TIMEOUT).then(() => this._doMovimentosAberto(linha));
            }
            let doneMovAberto = false;
            Promise.resolve(promise)
              .then(() => {
                doneMovAberto = true;
                this._handleModalAddLine(linha.nLanc);
              })
              .catch(() => {
                this._focusLastElement();
              })
              .finally(() => {
                if ((!linha.temMovAberto || !doneMovAberto) && this._isMoedaPresent && this.optionShowFieldMoeda && linha.nConta) {
                  this._doMoedaEstrangeira(linha).then(() => {
                    this._handleModalAddLine(linha.nLanc, true);
                  });
                }
              });
          })
          .catch(() => {
            if (!this.model.linhas.length) {
              this.addLine();
            }
          })
      );
    } else {
      linha.poc = value;
      linha.nConta = linha.poc.nConta;
      if (linha.dc === EDebitoCredito.Credito) {
        linha.nContaDebito = undefined;
        linha.nContaCredito = linha.nConta;
      } else {
        linha.nContaDebito = linha.nConta;
        linha.nContaCredito = undefined;
      }
    }
  }

  public onLinhaNContribuinteChange(value: IJsonPOC | string, linha: IDocContabilidadeLinha): void {
    const nif: string = isObject(value) ? (<IJsonPOC>value).nif : <string>value;
    this._setLoading(
      this._docsContabilidadeService
        .linhaContribuinteChanged(nif, linha._index, this.model)
        .then((response: IDocContabilidade) => {
          this._applyModel(response);
        })
        .finally(() => {
          this._clearItemInfo();
        })
    );
  }

  public onLinhaValorChange(value: number, linha: IDocContabilidadeLinha, valorComIvaIncluido: boolean = this.optionValorComIvaIncluido): Promise<void> {
    let promise: Promise<void>;
    /**
     * Tem de ir ao servidor nas seguintes condições:
     *   - Se a conta tiver CC, centro de custo, IVA
     *   - Se estamos em modo pré-definidos (não genérico)
     *   - Se estamos em modo moeda estrangeira
     */
    if (this._docContabilidadeService.isLineValueCalculatedOnline(linha.poc, this.model)) {
      promise = new Promise<void>((resolve, reject) => {
        this._docsContabilidadeService
          .linhaValorChanged(value, valorComIvaIncluido, linha._index, this.model)
          .then((response: IDocContabilidade) => {
            this._applyModel(response);
            if (!value) {
              resolve();
            } else {
              linha = this._docContabilidadeService.getLinha(this.model, linha.nLanc);
              if (linha) {
                this._postValorChange(linha, this.model, true).finally(() => {
                  this._handleModalAddLine(linha.nLanc);
                  resolve();
                });
              } else {
                resolve();
              }
            }
          })
          .catch((error: unknown) => {
            linha = this._docContabilidadeService.getLinha(this.model, linha.nLanc);
            if (linha) {
              linha.valor = isNumber(linha.valor) ? undefined : 0;
            }
            this._focusLastElement();
            reject(error);
          })
          .finally(() => {
            this._clearItemInfo();
          });
      });
    } else {
      linha.valor = value;
      this._docContabilidadeService.calculaTotais(this.model);
      this._applyModel();
      if (value) {
        promise = this._postValorChange(linha, this.model, true);
      }
    }
    if (promise) {
      this._setLoading(promise);
    } else {
      promise = Promise.resolve();
    }
    return promise;
  }

  public onLinhaCambioChange(value: number, linha: IDocContabilidadeLinha): void {
    this._setLoading(
      this._docsContabilidadeService
        .linhaCambioChanged(value, this.optionValorComIvaIncluido, linha._index, this.model)
        .then((response: IDocContabilidade) => {
          this._applyModel(response);
        })
        .finally(() => {
          this._clearItemInfo();
        })
    );
  }

  public onValorMEChange(value: number, linha: IDocContabilidadeLinha): void {
    this._setLoading(
      this._docsContabilidadeService
        .linhaValorMEChanged(value, this.optionValorComIvaIncluido, linha._index, this.model)
        .then((response: IDocContabilidade) => {
          this._applyModel(response);
        })
        .finally(() => {
          this._clearItemInfo();
        })
    );
  }

  public onLinhaValorTaxaChange(value: number, linha: IDocContabilidadeLinha): void {
    this._setLoading(
      new Promise<void>((resolve) => {
        const valorTaxaOriginal: number = linha._valorTaxaOriginal;
        this._docsContabilidadeService
          .linhaValorIvaChanged(value, linha._index, this.model)
          .then((response: IDocContabilidade) => {
            linha = this._docContabilidadeService.getLinha(response, linha.nLanc);
            linha._valorTaxaOriginal = valorTaxaOriginal;
            this._applyModel(response);
          })
          .finally(resolve);
      })
    );
  }

  public onLinhaDataDocChange(value: TDate, linha: IDocContabilidadeLinha): void {
    this._setLoading(
      this._docsContabilidadeService
        .linhaDataDocChanged(value, linha._index, this.model)
        .then((response: IDocContabilidade) => {
          this._applyModel(response);
        })
        .catch((reason: unknown) => {
          this._logger.error(reason);
          linha = this._docContabilidadeService.getLinha(this.model, linha.nLanc);
          if (linha) {
            linha.dataDoc = value;
            linha.changedDataDoc = true;
          }
          linha._invalidDataDoc = true;
        })
    );
  }

  public onLinhaLockCampoME(campoME: ECampoCalculadoME, linha: IDocContabilidadeLinha): void {
    linha = {...linha};
    this._docContabilidadeService.doLock(linha, campoME);
    this.model.linhas[linha._index] = linha;
    this.model.linhas = this.model.linhas.slice();
  }

  public onValorObterSaldoConta(linha: IDocContabilidadeLinha): void {
    this._setLoading(
      this._docsContabilidadeService.obterSaldoConta(linha._index, this.model).then((response: IDocContabilidade) => {
        linha = this._docContabilidadeService.getLinha(response, linha.nLanc);
        this._applyModel(response);
        this._focusFieldLinha(EDocContabilidadeField.VALOR, linha._index);
      })
    );
  }

  public async onRegularizacaoCampo40(linha: IDocContabilidadeLinha): Promise<void> {
    await this._doRegularizacaoCampo40Art78(linha);
  }

  public linhaInfoMouseEnter(linha: IDocContabilidadeLinha, event: MouseEvent): void {
    const inputElements: NodeListOf<HTMLInputElement> = (<HTMLElement>event.target).querySelectorAll<HTMLInputElement>('input');
    const input: HTMLInputElement = inputElements[0];
    this._itemInfoOriginal = input?.disabled ? this.itemInfo : undefined;
    this.itemInfo = input?.disabled ? linha._index : undefined;
    this._initLinhaImputada(linha);
  }

  public linhaInfoMouseLeave(): void {
    if (isNumber(this._itemInfoOriginal)) {
      this.itemInfo = this._itemInfoOriginal;
      this._itemInfoOriginal = undefined;
    }
  }

  public async reset(docContabilidade?: IDocContabilidade, focus: boolean = true, focusField?: TDocContabilidadeFocusField): Promise<void> {
    let shouldEvaluateProperties = false;

    if (this.calculouDiferimento) {
      this.anularCalculoDiferimento(false);
      shouldEvaluateProperties = true;
    }

    if (this.hasPreDefinido) {
      await this.loadPreDefinido(this.predefinido);
      return;
    }

    if (docContabilidade) {
      this._docsContabilidadeService.handleCommandResponse(docContabilidade, true, true, true);
    } else {
      docContabilidade = this._docContabilidadeService.emptyDocWithLines();
    }

    this._applyModel(docContabilidade);

    if (shouldEvaluateProperties) {
      this._evaluateProperties();
    }

    this.addLine();

    if (focus) {
      this._prepareFocus(focusField);
    }
  }

  public async resetAndInit(docContabilidade?: IDocContabilidade, focus?: boolean, focusField?: TDocContabilidadeFocusField): Promise<void> {
    await this.reset(docContabilidade, focus, focusField);
    await this._initDocumento();
  }

  public async clearPreDefinido(): Promise<void> {
    if (!this.hasPreDefinido || this.locked) {
      return;
    }
    const emptyDoc: IDocContabilidade = this._docContabilidadeService.emptyDoc();
    this.model = !this.keepCabAfterClearPreDefinido
      ? emptyDoc
      : merge(emptyDoc, {
          periodo: this.model.periodo,
          nomePeriodo: this.model.nomePeriodo,
          nDiario: this.model.nDiario,
          nomeDiario: this.model.nomeDiario,
          dataLancamento: this.model.dataLancamento,
          dataDoc: this.model.dataDoc,
          descricao: this.model.descricao,
          codDescritivo: this.model.codDescritivo,
          nomeDescritivo: this.model.nomeDescritivo,
          nDocExterno: this.model.nDocExterno,
          documentoExterno: this.model.documentoExterno
        });
    this._fromClearPreDefinido = true;
    this.predefinido = undefined;
    try {
      await this._initDocumento();
    } finally {
      this._fromClearPreDefinido = false;
    }
  }

  public trackByIndex(index: number, item: IDocContabilidadeLinha): number {
    return item._index;
  }

  public setObtemDadosDocDigital(value: boolean): Promise<void> {
    return this._changedConfig('obtemDadosDocDigital', value);
  }

  public setValorComIvaIncluido(value: boolean): Promise<void> {
    return this._changedConfig('valorComIvaIncluido', value);
  }

  public onLinhaFieldFocus(linha: IDocContabilidadeLinha, event: Event): void {
    if (this._subscriptionClearActiveNLanc) {
      this._subscriptionClearActiveNLanc.unsubscribe();
    }
    this.activeNLanc = linha.nLanc;
    this.itemInfo = linha._index;
    const fieldName: EDocContabilidadeField = <EDocContabilidadeField>(<HTMLInputElement>event.target).getAttribute('name');
    this._lastFocusElement = {
      fieldName: fieldName,
      linhaNLanc: linha.nLanc,
      indiceLinha: linha._index
    };
  }

  public onLinhaFieldBlur(linha: IDocContabilidadeLinha, event: Event): void {
    const fieldName: EDocContabilidadeField = <EDocContabilidadeField>(<HTMLInputElement>event.target).getAttribute('name');
    if (fieldName === EDocContabilidadeField.VALOR) {
      linha.saldoContaCalculado = false;
      delete linha.saldoConta;
    }
    this._restoreLastFocus = false;
    this._clearItemInfo();
    if (this._subscriptionClearActiveNLanc) {
      this._subscriptionClearActiveNLanc.unsubscribe();
    }
    this._subscriptionClearActiveNLanc = asyncScheduler.schedule(() => {
      this.activeNLanc = undefined;
    }, DEFAULT_TIMEOUT);
  }

  public onLinhaFieldKeyDown(event: KeyboardEvent, linhaIndex: number): void {
    const target: HTMLInputElement = <HTMLInputElement>event.target;
    const targetName: EDocContabilidadeField = <EDocContabilidadeField>target.getAttribute('name');
    switch (event.key) {
      case KEYCODES.DASH:
        if (FIELDS_ALLOWED_TOGGLE_VALOR_IVA_INCLUIDO.includes(targetName)) {
          this.setValorComIvaIncluido(!this.optionValorComIvaIncluido);
        }
        break;
      case KEYCODES.ENTER:
        this._onLinhaFieldLinhaKeydownEnter(event, linhaIndex);
        break;
      case KEYCODES.UP:
        if (event.defaultPrevented) {
          return;
        }
        const inputElements: NodeListOf<HTMLInputElement> = this._element.querySelectorAll<HTMLInputElement>('.doccontabilidade-cabecalho input');
        const toFocus: HTMLInputElement = inputElements.item(inputElements.length - 1);
        const autocompleteDropdown: HTMLElement = this._element.querySelector<HTMLElement>('.doccontabilidade-linhas .filter-active');
        if (toFocus && !autocompleteDropdown) {
          this._focusAndSelect(toFocus);
        }
        break;
      case KEYCODES.DOWN:
        if (event.defaultPrevented) {
          return;
        }
        if (this.model.linhas.length <= 1) {
          event.preventDefault();
          this.addLine();
          return;
        }
        const lineRow: HTMLElement = target.closest('.table-flex-tr');
        const lineIndex: number = elementIndex(lineRow);
        const isLastLine: boolean = lineIndex === this.model.linhas.length - 1;
        const lastNonEmptyLineIndex: number = this._getLastNonEmptyLineIndex();
        if (isLastLine || lineIndex === lastNonEmptyLineIndex) {
          const line: IDocContabilidadeLinha = this.model.linhas[lineIndex];
          const inputValor: HTMLInputElement = lineRow.querySelector<HTMLInputElement>('input[name="valor"]');
          const valor: number = this._plCompsService.parseNumber(inputValor.value);
          const saldarDocumento: boolean = FIELDS_SALDAR_DOCUMENTO.includes(targetName) && !valor;
          if (saldarDocumento || (isLastLine && !this._docContabilidadeService.isLineEmpty(line))) {
            event.preventDefault();
            this.disableAutoFocus = false;
            if (saldarDocumento && (line.nContaDebito || line.nContaCredito)) {
              this._saldarDocumento(line, this.model).then(() => {
                if (!isLastLine) {
                  this._focusFieldLinha(EDocContabilidadeField.CONTA_DEBITO, lineIndex + 1);
                } else {
                  this.addLine();
                }
              });
            } else {
              this._addLineIfLast(line);
            }
          }
        }
        break;
    }
  }

  public onFieldClosedModal(): void {
    this._focusLastElement();
  }

  public customActionExtratos(value: string): void {
    const params: IContabilidadeExtratosGridParams = {nConta: value};
    this._maintenanceExtratosGrid.maintenance({params: params});
  }

  public customActionMovab(value: string): void {
    this._maintenanceMovimentosEmAberto.maintenance({params: {deConta: value, ateConta: value}});
  }

  public customActionClifos(value: string): void {
    this._maintenanceClifos.maintenanceEdit(value);
  }

  public closedErroInformativo(): void {
    delete this.model.campoErroInformativo;
    this._clearErroInformativo();
  }

  public changedDocViewerFooterCollapsed(value: boolean): void {
    this._changedConfig('docViewerFooterCollapsed', value).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public changedContabilidadeDigitalAttachment({folder, attachment, doInitDocumento}: IArquivoDigitalDocViewerEvtChangedAttachment): void {
    this.contabilidadeDigitalIgnorePredefinido = false;
    if (!this.contabilidadeDigital || !folder || folder.isFolderFromClassificados || attachment?.cab.associadoADocContab) {
      this._docOCRCab = undefined;
      this._setContabilidadeDigitalAttachment('', '', '');
      return;
    }
    this._docOCRCab = attachment?.cab;
    const gDocId: string = this._docOCRCab ? this._docOCRCab.docID : '';
    const gDocFolderId: string = folder.folderID || '';
    if (this.contabilidadeDigitalAttachment.gDocId !== gDocId || this.contabilidadeDigitalAttachment.gDocFolderId !== gDocFolderId) {
      this._setContabilidadeDigitalAttachment(gDocId, gDocFolderId, this._docOCRCab?.docOCRCabID);
      if (doInitDocumento !== false) {
        const currentPromise: Promise<unknown> = this.promise;
        Promise.resolve(currentPromise).finally(() => {
          if (this.hasPreDefinido) {
            this._removePreDefinido();
          }
          this._setLoading(this._initDocumento());
        });
      }
    }
  }

  public contabilidadeDigitalAttachedVerbete(): void {
    if (!this.model.temDocDigital) {
      this.model.temDocDigital = true;
    }
  }

  public anularCalculoDiferimento(focusLastElement: boolean = true): void {
    if (this.calculouDiferimento || this.model.isDocPrincipalDiferimento) {
      this._cgModalService.showOkCancel('global.text.confirmation', 'docscontabilidade.text.confirmAnulaDiferimento').then(() => {
        let promise: Promise<unknown>;
        if (this.model.isDocPrincipalDiferimento) {
          this.loadingAnularCalculoDiferimento = true;
          promise = this._docsContabilidadeService
            .anularDiferimento(this.model)
            .then((docContabilidade: IDocContabilidade) => {
              this._applyModel(docContabilidade);
            })
            .finally(() => {
              this.loadingAnularCalculoDiferimento = false;
            });
          this._setLoading(promise);
        }
        Promise.resolve(promise).then(() => {
          this.calculouDiferimento = false;
          this.calculouDiferimentoChange.emit(this.calculouDiferimento);
          this._evaluateProperties();
          if (focusLastElement) {
            this._focusLastElement();
          }
        });
      });
    }
  }

  public async openDocImputacao(linha: IDocContabilidadeLinha): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DocsContabilidadeViewDocModalComponent, {size: 'xxl'});
    const componentInstance: DocsContabilidadeViewDocModalComponent = modalInstance.componentInstance;
    componentInstance.extPocCabID = linha._extPocCabImput;
    await modalInstance.result;
  }

  public readonly fnRemoveLine = (index: number) => (): Promise<void> => {
    if (this.locked) {
      return Promise.resolve();
    }
    const promise: Promise<void> = this._docsContabilidadeService.linhaDelete(index, this.model).then((response: IDocContabilidade) => {
      this._applyModel(response);
      if (!this.model.linhas.length) {
        this.addLine();
      }
    });
    this._setLoading(promise);
    return promise;
  };

  public readonly fnRemoveEmptyLine = (event: KeyboardEvent): void => {
    this._removeEmptyLine(event);
  };

  @ViewChild(PlTableNavigationDirective)
  public set tableNavigation(value: PlTableNavigationDirective) {
    this._tableNavigation = value;
  }

  @ViewChild('templateNContaDebito')
  public set templateNContaDebito(value: TemplateRef<IDocContabilidadeDefinitionTemplateContext>) {
    this._definitionNContaDebito.templateRef = value;
  }

  @ViewChild('templateNContaCredito')
  public set templateNContaCredito(value: TemplateRef<IDocContabilidadeDefinitionTemplateContext>) {
    this._definitionNContaCredito.templateRef = value;
  }

  @ViewChild('templateNContribuinte')
  public set templateNContribuinte(value: TemplateRef<IDocContabilidadeDefinitionTemplateContext>) {
    this._definitionNContribuinte.templateRef = value;
  }

  @ViewChild('templateValor')
  public set templateValor(value: TemplateRef<IDocContabilidadeDefinitionTemplateContext>) {
    this._definitionValor.templateRef = value;
  }

  @ViewChild('templateCambio')
  public set templateCambio(value: TemplateRef<IDocContabilidadeDefinitionTemplateContext>) {
    this._definitionCambio.templateRef = value;
  }

  @ViewChild('templateValorME')
  public set templateValorME(value: TemplateRef<IDocContabilidadeDefinitionTemplateContext>) {
    this._definitionValorME.templateRef = value;
  }

  @ViewChild('templateValorTaxa')
  public set templateValorTaxa(value: TemplateRef<IDocContabilidadeDefinitionTemplateContext>) {
    this._definitionValorTaxa.templateRef = value;
  }

  @ViewChild('templateDataDoc')
  public set templateDataDoc(value: TemplateRef<IDocContabilidadeDefinitionTemplateContext>) {
    this._definitionDataDoc.templateRef = value;
  }

  @ViewChild('templateDescricao')
  public set templateDescricao(value: TemplateRef<IDocContabilidadeDefinitionTemplateContext>) {
    this._definitionDescricao.templateRef = value;
  }

  @ViewChild('templateCDecPer')
  public set templateCDecPer(value: TemplateRef<IDocContabilidadeDefinitionTemplateContext>) {
    this._definitionCDecPer.templateRef = value;
  }

  @ViewChild('templateCDecAnual')
  public set templateCDecAnual(value: TemplateRef<IDocContabilidadeDefinitionTemplateContext>) {
    this._definitionCDecAnual.templateRef = value;
  }

  @ViewChild('templateNomeConta')
  public set templateNomeConta(value: TemplateRef<IDocContabilidadeDefinitionTemplateContext>) {
    this._definitionNomeConta.templateRef = value;
  }

  private _setLoading(promise: Promise<unknown>): void {
    const wasExecuting: boolean = this._actionQueue.executing();
    this._actionQueue.queueResult(promise);
    if (!wasExecuting) {
      this.promise = this._actionQueue.executionPromise();
      this.promiseUI = undefined;
      if (this._tableNavigation) {
        this._tableNavigation.plTableNavigation = this.promise;
      }
      if (!this._subscriptionLoading) {
        this._subscriptionLoading = timer(LOADING_DELAY).subscribe(() => {
          this._subscriptionLoading = undefined;
          if (this._actionQueue.executing()) {
            this.promiseUI = this.promise;
          }
        });
      }
    }
  }

  private _changedContabilidadeDigitalAttachment(value: IDocContabilidadeContabDigitalAttachment = this.contabilidadeDigitalAttachment): void {
    this.contabilidadeDigitalAttachment = merge<object, IDocContabilidadeContabDigitalAttachment, IDocContabilidadeContabDigitalAttachment>(
      {},
      {
        gDocId: undefined,
        gDocFolderId: undefined,
        docOCRCabID: undefined
      },
      value
    );
  }

  private _prepareFocus(field?: TDocContabilidadeFocusField): void {
    this.disableAutoFocus = true;

    if (this._subscriptionPrepareFocus) {
      this._subscriptionPrepareFocus.unsubscribe();
    }

    this._subscriptionPrepareFocus = asyncScheduler.schedule(() => {
      this._subscriptionPrepareFocus = undefined;

      this.disableAutoFocus = false;

      field = field || this.onLoadFocusField;

      if (field) {
        if (field === 'linhas') {
          this._focusFieldLinha(EDocContabilidadeField.CONTA_DEBITO, 0);
        } else {
          this._focusFieldCabecalho(field, true);
        }
      } else if (this.contabilidadeDigital) {
        if (this._docOCRCab && this.model.linhas.length) {
          const linha: IDocContabilidadeLinha = this.model.linhas[0];
          if (linha.nConta) {
            const fieldLinhaToFocus: EDocContabilidadeField =
              INVOICE_TYPE_STRUCT[this._docOCRCab.invoiceType].dc === EDebitoCredito.Credito ? EDocContabilidadeField.VALOR : EDocContabilidadeField.CONTA_DEBITO;
            this._focusFieldLinha(fieldLinhaToFocus, linha._index);
            return;
          }
        }
        if (this._contabDigitalConfigs.lastFolder) {
          this._focusFieldLinha(EDocContabilidadeField.CONTA_DEBITO, 0);
        }
      }
    });
  }

  private _applyModel(value: IDocContabilidade = this.model): void {
    const oldSize: number = this.model?.linhas ? this.model.linhas.length : 0;
    const changed: boolean = value !== this.model;
    if (changed) {
      this.model =
        this.keepCabAfterClearPreDefinido && this._fromClearPreDefinido
          ? merge(value, {
              periodo: this.model.periodo,
              nomePeriodo: this.model.nomePeriodo,
              nDiario: this.model.nDiario,
              nomeDiario: this.model.nomeDiario,
              dataLancamento: this.model.dataLancamento,
              dataDoc: this.model.dataDoc,
              descricao: this.model.descricao,
              codDescritivo: this.model.codDescritivo,
              nomeDescritivo: this.model.nomeDescritivo,
              nDocExterno: this.model.nDocExterno,
              documentoExterno: this.model.documentoExterno
            })
          : value;
      this._nDocumento = this.model.nDocumento;
    }
    if (this.hasPreDefinido) {
      if (this.model.predefinido !== this.predefinido) {
        this.predefinido = this.model.predefinido;
        this._evaluatePreDefinido();
      } else if (this.model?.linhas && this.model.linhas.length !== oldSize) {
        this._evaluatePreDefinidoLinhas();
      }
    }
    this._evaluateDoc();
    if (this.contabilidadeDigitalDoc) {
      this._evaluateContabilidadeDigitalDoc();
    }
    if (changed) {
      this.modelChange.emit(this.model);
    }
  }

  private _initModel(): void {
    if (!isObject(this.model) || !this.model.extPocCabID) {
      this._applyModel(this._docContabilidadeService.emptyDoc());
    } else {
      this._docsContabilidadeService.handleCommandResponse(this.model);
      this._evaluateDoc();
    }
  }

  private async _initDocumento(): Promise<void> {
    this.simulation = Boolean(this.simulation);
    this.hasPreDefinido = isObject(this.predefinido);

    if (!this.simulation) {
      this.btnConfigOptions.disabled = false;
      this.btnConfigOptions.tooltip.disabled = !this.btnConfigOptions.disabled;
    }

    if (this._isNew()) {
      await this._initNewDocument();
      return;
    }

    this._nDocumento = this.model.nDocumento;

    // Set predefinido from model if exists
    if (isObject(this.model.predefinido)) {
      this.predefinido = this.model.predefinido;
      this.hasPreDefinido = true;
    }

    await this._handlePreDefinido();
    this._triggerAfterInitDocument();
    this._evaluateContabilidadeDigitalDoc();

    this.disableAutoFocus = false;
  }

  private async _initNewDocument(): Promise<void> {
    if (this.hasPreDefinido) {
      try {
        await this.loadPreDefinido(this.predefinido);
      } finally {
        this._triggerAfterInitDocument();
      }
      return;
    }

    const usingDocOCRCab: boolean = this.optionObtemDadosDocDigital && Boolean(this._docOCRCab);
    try {
      const response: IDocContabilidade = await (!usingDocOCRCab
        ? this._docsContabilidadeService.initDocumento(this.model, this.contabilidadeDigital)
        : this._docsContabilidadeService.novoByDocOCRCabID(this._docOCRCab.docOCRCabID, this.model, this._contabilidadeDigitalChangedFolder, this.contabilidadeDigitalIgnorePredefinido));
      this._applyModel(response);

      this.hasPreDefinido = Boolean(this.model.isUsingPreDefinido);

      if (this.hasPreDefinido) {
        this.predefinido = this.model.predefinido;
      }

      this._setHasErrorInitDoc(!this.model.periodo || !this.model.nDiario);
      await this._handlePreDefinido();

      if (!this.hasPreDefinido && (!usingDocOCRCab || !this.model.linhas.length)) {
        this.addLine();
      }

      this._triggerAfterInitDocument();
    } catch (reason: unknown) {
      this._logger.error(reason);
      this._setHasErrorInitDoc(true);
    }
  }

  private _triggerAfterInitDocument(): void {
    if (isFunction(this.onAfterInitDocument)) {
      const docContabilidade: void | IDocContabilidade = this.onAfterInitDocument(this.model);
      if (isObject(docContabilidade)) {
        this._applyModel(<IDocContabilidade>docContabilidade);
      }
    }
  }

  private async _handlePreDefinido(): Promise<void> {
    this._definition.sort((a: IDocContabilidadeDefinition, b: IDocContabilidadeDefinition) => a.defaultIndex - b.defaultIndex);

    this.hasPreDefinido = isObject(this.predefinido) && !this.editing;

    this.btnConfigOptions.disabled = this.hasPreDefinido;
    this.btnConfigOptions.tooltip.disabled = !this.hasPreDefinido;

    if (this.hasPreDefinido) {
      this._appliedPreDefinidoOptions = true;
      this._configOptionsInstance.setOptions(
        produce(this._docContabilidadeOptions, (draft: Draft<TConfigOptions<boolean, IDocContabilidadeConfigOptions>>) => {
          draft.get('valorComIvaIncluido').value = false;
          draft.get('showFieldPredefinido').value = true;
          draft.get('showFieldDataVencimento').value = true;
          draft.get('showFieldLinhaNif').value = true;
          draft.get('showFieldLinhaValorTaxa').value = true;
          draft.get('showFieldLinhaValorTaxa').value = true;
          draft.get('showFieldLinhaDescricao').value = true;
          draft.get('showFieldLinhaNomeConta').value = false;
          draft.get('seletorValorComIvaIncluido').value = false;
          draft.get('skipDescricao').value = false;
          draft.get('retainDescription').value = false;
        }),
        false
      );
      if (this.predefinido.ordemColunas?.length) {
        for (let i = 0; i < this.predefinido.ordemColunas.length; i++) {
          const ordem: number = this.predefinido.ordemColunas[i];
          const fieldIndex: number = this._definition.findIndex((item: IDocContabilidadeDefinition) => item.preDefinidoIndex === i);
          if (fieldIndex === -1) {
            break;
          }
          this._definition[fieldIndex].index = ordem;
        }
        this._definition.sort((a: IDocContabilidadeDefinition, b: IDocContabilidadeDefinition) => a.index - b.index);
      }
    } else {
      this._appliedPreDefinidoOptions = false;
      await this._configOptionsInstance.reloadOptions();
    }

    this._evaluateProperties();
    this._evaluateColumns();

    if (this.hasPreDefinido) {
      try {
        await this._doPostValorChangeForAllLines();
      } catch (reason: unknown) {
        // If errors continue execution
        this._logger.error(reason);
      }
    }

    this._prepareFocus();
  }

  private _evaluateDoc(): void {
    this._isDocEmpty = this._docContabilidadeService.isDocEmpty(this.model);
    this._handledCentroCustoLines.clear();
    if (!this._isDocEmpty) {
      for (const linha of this.model.linhas) {
        if (linha.nLancOrigemImputacaoCCusto && linha.nLancOrigemImputacaoCCusto !== EMPTY_GUID) {
          this._handledCentroCustoLines.add(linha.nLancOrigemImputacaoCCusto);
        }
      }
    }
    const isMoedaPresent: boolean = this._docContabilidadeService.isMoedaPresent(this.model);
    if (this._isMoedaPresent !== isMoedaPresent) {
      this._isMoedaPresent = isMoedaPresent;
      this._evaluateColumns();
    }
    this.tooltipMoeda.disabled = this._isDocEmpty;
    this.hintMarcadoComoConsistente = this._docContabilidadeService.generateHintMarcadoComoConsistente(this.model);
    this._evaluateLocked();
  }

  private _evaluateContabilidadeDigitalDoc(): void {
    this.contabilidadeDigitalDoc = {
      extPocCabID: this.model.extPocCabID,
      periodo: this.model.periodo,
      nDiario: this.model.nDiario,
      nDocInterno: this.model.nDocInterno,
      dataDoc: this.model.dataDoc
    };
  }

  private _evaluatePreDefinido(): void {
    this._evaluatePreDefinidoCabecalho();
    this._evaluatePreDefinidoLinhas();
  }

  private _evaluatePreDefinidoCabecalho(): void {
    // Período
    this.visiblePeriodo = this.evaluateVisible('periodo');
    this.readonlyPeriodo = this.evaluateReadOnly('periodo');
    this.tabStopPeriodo = this.evaluateTabStop('periodo');

    // Diário
    this.visibleDiario = this.evaluateVisible('diario');
    this.readonlyDiario = this.evaluateReadOnly('diario');
    this.tabStopDiario = this.evaluateTabStop('diario');

    // Data lançamento
    this.visibleDataLancamento = this.evaluateVisible('dataLancamento');
    this.readonlyDataLancamento = this.evaluateReadOnly('dataLancamento');
    this.tabStopDataLancamento = this.evaluateTabStop('dataLancamento');

    // Data doc
    this.visibleDataDoc = this.evaluateVisible('dataDoc');
    this.readonlyDataDoc = this.evaluateReadOnly('dataDoc');
    this.tabStopDataDoc = this.evaluateTabStop('dataDoc');

    // Data vencimento
    this.visibleDataVencimento = this.evaluateVisible('dataVencimento');
    this.readonlyDataVencimento = this.evaluateReadOnly('dataVencimento');
    this.tabStopDataVencimento = this.evaluateTabStop('dataVencimento');

    // Nº contribuinte
    this.visibleNContribuinte = this.evaluateVisible('nContribuinte');
    this.readonlyNContribuinte = this.evaluateReadOnly('nContribuinte');
    this.tabStopNContribuinte = this.evaluateTabStop('nContribuinte');

    // Descrição
    this.visibleDescricao = this.evaluateVisible('descricao');
    this.readonlyDescricao = this.evaluateReadOnly('descricao');
    this.tabStopDescricao = this.evaluateTabStop('descricao');

    // Descritivo
    this.visibleDescritivo = this.evaluateVisible('descritivo');
    this.readonlyDescritivo = this.evaluateReadOnly('descritivo');
    this.tabStopDescritivo = this.evaluateTabStop('descritivo');

    // Nº doc externo
    this.visibleNDocExterno = this.evaluateVisible('nDocExterno');
    this.readonlyNDocExterno = this.evaluateReadOnly('nDocExterno');
    this.tabStopNDocExterno = this.evaluateTabStop('nDocExterno');

    // Push order must reflect component's view order
    this._predefinidoEvaluatedCabecalho.splice(0, this._predefinidoEvaluatedCabecalho.length);
    this._predefinidoEvaluatedCabecalho.push(['periodo', {visible: this.visiblePeriodo, readonly: this.readonlyPeriodo, tabStop: this.tabStopPeriodo}]);
    this._predefinidoEvaluatedCabecalho.push(['diario', {visible: this.visibleDiario, readonly: this.readonlyDiario, tabStop: this.tabStopDiario}]);
    this._predefinidoEvaluatedCabecalho.push([
      'dataLancamento',
      {
        visible: this.visibleDataLancamento,
        readonly: this.readonlyDataLancamento,
        tabStop: this.tabStopDataLancamento
      }
    ]);
    this._predefinidoEvaluatedCabecalho.push(['dataDoc', {visible: this.visibleDataDoc, readonly: this.readonlyDataDoc, tabStop: this.tabStopDataDoc}]);
    this._predefinidoEvaluatedCabecalho.push([
      'nContribuinte',
      {
        visible: this.visibleNContribuinte,
        readonly: this.readonlyNContribuinte,
        tabStop: this.tabStopNContribuinte
      }
    ]);
    this._predefinidoEvaluatedCabecalho.push([
      'dataVencimento',
      {
        visible: this.visibleDataVencimento,
        readonly: this.readonlyDataVencimento,
        tabStop: this.tabStopDataVencimento
      }
    ]);
    this._predefinidoEvaluatedCabecalho.push(['descricao', {visible: this.visibleDescricao, readonly: this.readonlyDescricao, tabStop: this.tabStopDescricao}]);
    this._predefinidoEvaluatedCabecalho.push(['descritivo', {visible: this.visibleDescritivo, readonly: this.readonlyDescritivo, tabStop: this.tabStopDescritivo}]);
    this._predefinidoEvaluatedCabecalho.push(['nDocExterno', {visible: this.visibleNDocExterno, readonly: this.readonlyNDocExterno, tabStop: this.tabStopNDocExterno}]);
  }

  private _evaluatePreDefinidoLinhas(): void {
    this._predefinidoEvaluatedLinhas.clear();
    if (!this.hasPreDefinido) {
      return;
    }
    for (const linha of this.model.linhas) {
      const preDefinidoContabLinhaIndice: number = linha.preDefinidoContabLinhaIndice || 0;
      if (this._predefinidoEvaluatedLinhas.has(preDefinidoContabLinhaIndice)) {
        continue;
      }
      const predefinidoEvaluatedLinha: Array<TDocContabilidadePredefinidoEvaluatedLinha> = [];
      this._predefinidoEvaluatedLinhas.set(preDefinidoContabLinhaIndice, predefinidoEvaluatedLinha);

      // Conta (evaluatePreDefinidoIndex: 0)
      predefinidoEvaluatedLinha.push([
        'conta',
        {
          visible: this.evaluateVisible('conta', preDefinidoContabLinhaIndice),
          readonly: this.evaluateReadOnly('conta', preDefinidoContabLinhaIndice),
          tabStop: this.evaluateTabStop('conta', preDefinidoContabLinhaIndice)
        }
      ]);

      // Nº contribuinte (evaluatePreDefinidoIndex: 1)
      predefinidoEvaluatedLinha.push([
        'nContribuinte',
        {
          visible: this.evaluateVisible('nContribuinte', preDefinidoContabLinhaIndice),
          readonly: this.evaluateReadOnly('nContribuinte', preDefinidoContabLinhaIndice),
          tabStop: this.evaluateTabStop('nContribuinte', preDefinidoContabLinhaIndice)
        }
      ]);

      // Valor (evaluatePreDefinidoIndex: 2)
      predefinidoEvaluatedLinha.push([
        'valor',
        {
          visible: this.evaluateVisible('valor', preDefinidoContabLinhaIndice),
          readonly: this.evaluateReadOnly('valor', preDefinidoContabLinhaIndice),
          tabStop: this.evaluateTabStop('valor', preDefinidoContabLinhaIndice)
        }
      ]);

      // Valor taxa (evaluatePreDefinidoIndex: 3)
      predefinidoEvaluatedLinha.push([
        'valorIva',
        {
          visible: this.evaluateVisible('valorIva', preDefinidoContabLinhaIndice),
          readonly: this.evaluateReadOnly('valorIva', preDefinidoContabLinhaIndice),
          tabStop: this.evaluateTabStop('valorIva', preDefinidoContabLinhaIndice)
        }
      ]);

      // Data documento (evaluatePreDefinidoIndex: 4)
      predefinidoEvaluatedLinha.push([
        'descricao',
        {
          visible: this.evaluateVisible('dataDoc', preDefinidoContabLinhaIndice),
          readonly: this.evaluateReadOnly('dataDoc', preDefinidoContabLinhaIndice),
          tabStop: this.evaluateTabStop('dataDoc', preDefinidoContabLinhaIndice)
        }
      ]);

      // Descrição (evaluatePreDefinidoIndex: 5)
      predefinidoEvaluatedLinha.push([
        'descricao',
        {
          visible: this.evaluateVisible('descricao', preDefinidoContabLinhaIndice),
          readonly: this.evaluateReadOnly('descricao', preDefinidoContabLinhaIndice),
          tabStop: this.evaluateTabStop('descricao', preDefinidoContabLinhaIndice)
        }
      ]);
    }
  }

  private _evaluateColumns(): void {
    this._definitionNContaDebito.show = true;
    this._definitionNContaCredito.show = true;
    this._definitionValor.show = true;
    this._definitionCambio.show = this._isMoedaPresent;
    this._definitionValorME.show = this._isMoedaPresent;
    this._definitionCDecPer.show = this.optionShowFieldLinhaCDecPerAnual;
    this._definitionCDecAnual.show = this.optionShowFieldLinhaCDecPerAnual;
    this._definitionNomeConta.show = this.optionShowFieldLinhaNomeConta;
    if (!this.hasPreDefinido) {
      this._definitionNContribuinte.show = this.optionShowFieldLinhaNif;
      this._definitionValorTaxa.show = this.optionShowFieldLinhaValorTaxa;
      this._definitionDataDoc.show = this.optionShowFieldLinhaDataDoc;
      this._definitionDescricao.show = this.optionShowFieldLinhaDescricao;
    } else {
      this._definitionNContribuinte.show = true;
      this._definitionValorTaxa.show = true;
      this._definitionDataDoc.show = true;
      this._definitionDescricao.show = true;
    }
    this.definitionUI = this._definition.filter((definition: IDocContabilidadeDefinition) => {
      if (!definition.show) {
        return false;
      }
      if (this.hasPreDefinido && definition.evaluatePreDefinidoIndex > -1) {
        for (const linha of this.model.linhas) {
          const preDefinidoContabLinhaIndice: number = linha.preDefinidoContabLinhaIndice || 0;
          if (!this._predefinidoEvaluatedLinhas.get(preDefinidoContabLinhaIndice)[definition.evaluatePreDefinidoIndex][1].visible) {
            return false;
          }
        }
      }
      return true;
    });
  }

  private _evaluateProperties(): void {
    this._evaluateLocked();
    this._evaluatePreDefinido();

    const events: IEditInputEvents<unknown> = {
      keydown: (value: unknown, event: KeyboardEvent) => {
        this._onFieldCabecalhoKeyDown(event);
      },
      blur: () => {
        this._onFieldCabecalhoBlur();
      }
    };

    // Pré definido
    this.propertiesPreDefinido = {events: events, disabled: this.locked};

    // Período
    this.propertiesPeriodo = {events: events, disabled: this.editing || this.locked, readonly: this.readonlyPeriodo};

    // Diário
    this.propertiesDiario = {events: events, disabled: this.editing || this.locked, readonly: this.readonlyDiario};

    // Data lançamento
    this.propertiesDataLancamento = {
      events: events,
      modelOptions: {updateOn: 'blur'},
      disabled: this.locked,
      readonly: this.readonlyDataLancamento
    };

    // Data doc
    this.propertiesDataDoc = {
      events: events,
      modelOptions: {updateOn: 'blur'},
      disabled: this.locked,
      readonly: this.readonlyDataDoc
    };

    // Data vencimento
    this.propertiesDataVencimento = {events: events, modelOptions: {updateOn: 'blur'}, disabled: this.locked, readonly: this.readonlyDataVencimento};

    // Nº contribuinte
    this.propertiesNContribuinte = {
      events: events,
      disabled: this.locked,
      readonly: this.readonlyNContribuinte,
      customActions: [
        {
          caption: 'docscontabilidade.text.registarNif',
          icon: 'fa-check-square',
          visible: (selectedKey: string) => Boolean(selectedKey),
          action: (selectedKey: string) => {
            return this._registarNif(selectedKey).then(([nContribuinte, shouldRegister]: [string, boolean]) => {
              if (!shouldRegister) {
                this._plAlertService.info(this._translateService.instant('docscontabilidade.erros.nifAlreadyRegistered', {nContribuinte: nContribuinte}));
              }
            });
          }
        }
      ]
    };

    // Descrição
    this.propertiesDescricao = {events: events, modelOptions: {updateOn: 'blur'}, disabled: this.locked, readonly: this.readonlyDescricao};

    // Descritivo
    this.propertiesDescritivo = {events: events, disabled: this.locked, readonly: this.readonlyDescritivo};

    // Nº doc externo
    this.propertiesNDocExterno = {events: events, modelOptions: {updateOn: 'blur'}, disabled: this.locked, readonly: this.readonlyNDocExterno};

    // Moeda
    this.propertiesMoeda = {events: events, disabled: this.editing || !this._isDocEmpty || this.locked};
  }

  private _evaluateDiferimento(): void {
    this.isDiferimento = !this.simulation && (this.calculouDiferimento || this.model.isDocPrincipalDiferimento || this.model.isDocDiferimento);
    if (this.isDiferimento && DOC_CONTABILIDADE_CAMPO_ERRO_INFORMATIVO_TO_IGNORE.includes(this.model.campoErroInformativo)) {
      this.model.campoErroInformativo = undefined;
    }
  }

  private _evaluateLocked(): void {
    this._evaluateDiferimento();
    this.locked = this.isDiferimento || (!this.isDiferimento && this.model.soDeLeitura);
  }

  private _removeEmptyLine(event: KeyboardEvent): void {
    if (this.model.linhas.length > 1) {
      const element: HTMLElement = (<HTMLElement>event.target).closest('.table-flex-tr');
      const index: number = elementIndex(element);
      if (this._docContabilidadeService.isLineEmpty(this.model.linhas[index]) && ((!this.simulation && !this.hasPreDefinido) || this.model.linhas[index]._predefinidoFake)) {
        const rows: Array<HTMLElement> = Array.from(element.closest('.table-flex-body').querySelectorAll<HTMLElement>('.table-flex-tr'));
        let enabledRows = 0;
        for (const row of rows) {
          if (!isLineDisabled(row)) {
            enabledRows++;
          }
          if (enabledRows > 1) {
            this.model.linhas.splice(index, 1);
            this.model.linhas = this.model.linhas.slice();
            return;
          }
        }
      }
    }
  }

  private _handleModal<T>(modal: Type<CGModalComponent<T>>, options?: ICGModalOptions<T>): TCGModalResult<T> {
    const modalInstance = this._cgModalService.showVanilla(modal, {ownerViewRef: this._viewRef, ...options});
    modalInstance.result
      .catch((reason: unknown) => {
        this._logger.error(reason);
      })
      .finally(() => {
        this._focusLastElement();
      });
    return modalInstance;
  }

  private _handleModalAddLine(nLanc: string, forceAddLineIfLast: boolean = false): void {
    const linha: IDocContabilidadeLinha = this._docContabilidadeService.getLinha(this.model, nLanc);
    if (forceAddLineIfLast || this._docContabilidadeService.isLineDisabled(linha)) {
      this._addLineIfLast(linha);
    }
  }

  private async _doRegularizacaoCampo40Art78(linha: IDocContabilidadeLinha): Promise<void> {
    if (!linha.isRegularizacaoCampo40Art78) {
      return;
    }
    const modalInstance = this._handleModal(DocsContabilidadeEditRegularizacaoCampo40ModalComponent);
    const componentInstance: DocsContabilidadeEditRegularizacaoCampo40ModalComponent = modalInstance.componentInstance;
    componentInstance.linha = linha;
    let shouldContinue = true;
    const regularizacaoCampo40: IJsonRegularizacaoCampo40 = await modalInstance.result.catch((reason: unknown) => {
      this._logger.error(reason);
      shouldContinue = false;
    });
    if (!shouldContinue) {
      return;
    }
    const promise = this._docsContabilidadeService.linhaAdicionarRegularizacaoCampo40(regularizacaoCampo40.linhasRegularizacaoCampo40, linha._index, this.model);
    this._setLoading(promise);
    const response: IDocContabilidade = await promise;
    this._applyModel(response);
  }

  private async _doCentroCusto(linha: IDocContabilidadeLinha): Promise<void> {
    if (!linha.poc.temCCusto) {
      return;
    }
    const modalInstance = this._handleModal(DocsContabilidadeEditCCustoModalComponent);
    const componentInstance: DocsContabilidadeEditCCustoModalComponent = modalInstance.componentInstance;
    componentInstance.linha = linha;
    componentInstance.doc = this.model;
    let shouldContinue = true;
    const ccustoResult: IDocContabilidadeCCustoResult = await modalInstance.result.catch((reason: unknown) => {
      this._logger.error(reason);
      shouldContinue = false;
    });
    if (!shouldContinue) {
      return;
    }
    this._applyModel(ccustoResult.docContabilidade);
    linha = this._docContabilidadeService.getLinha(this.model, linha.nLanc);
    if (linha.isRegularizacaoCampo40Art78) {
      await this._doRegularizacaoCampo40Art78(linha);
    }
  }

  private async _doRetencao(linha: IDocContabilidadeLinha): Promise<void> {
    if (!linha.poc.temRetencao || !linha.nContrib || !linha.entNIF.codRet) {
      return;
    }
    const modalInstance = this._handleModal(DocsContabilidadeEditRetencaoModalComponent, {size: 'lg'});
    const componentInstance: DocsContabilidadeEditRetencaoModalComponent = modalInstance.componentInstance;
    componentInstance.linha = linha;
    componentInstance.doc = this.model;
    let shouldContinue = true;
    const retencao: IDocContabilidadeRetencao = await modalInstance.result.catch((reason: unknown) => {
      this._logger.error(reason);
      shouldContinue = false;
    });
    if (!shouldContinue) {
      return;
    }
    const promise: Promise<undefined | IDocContabilidade> = this._docsContabilidadeService
      .linhaEfetuarRetencao(
        retencao.dispoRendimento,
        linha.entNIF.codRet,
        retencao.contaCorrenteTemRetencaoDeduzida,
        retencao.valorTributavel,
        retencao.valorRetido,
        linha._index,
        this.model,
        retencao.numeroGuiaPagamento
      )
      .catch((reason: unknown) => {
        this._logger.error(reason);
        shouldContinue = false;
        return undefined;
      });
    this._setLoading(promise);
    const response: undefined | IDocContabilidade = await promise;
    if (!shouldContinue) {
      return;
    }
    this._applyModel(response);
    linha = this._docContabilidadeService.getLinha(this.model, linha.nLanc);
    if (linha.poc.temCCusto) {
      await this._doCentroCusto(linha);
    } else if (linha.isRegularizacaoCampo40Art78) {
      await this._doRegularizacaoCampo40Art78(linha);
    }
  }

  private async _doMovimentosAberto(linha: IDocContabilidadeLinha): Promise<void> {
    const title = this._translateService.instant('docscontabilidade.contaTemLigacaoContasCorrentes', {nConta: linha.nConta});
    this.labelPerguntaCC = this._translateService.instant('docscontabilidade.prompt.perguntaCC', {sinal: this._translateService.instant(DATA_SOURCE_DEBITO_CREDITO.data[linha.dc].label)});
    await this._cgModalService.showOkCancel(title, 'docscontabilidade.desejaEfetuarPagamentos', {
      templateRefContent: this.templateContentPerguntaCC,
      templateRefContentContext: {$implicit: linha, linha: linha},
      ownerViewRef: this._viewRef
    });
    const nConta: string = linha.dc === EDebitoCredito.Credito ? linha.nContaCredito : linha.nContaDebito;
    await this._docsContabilidadeService.perguntaCC(nConta, linha.dc, !linha.naoPerguntaCC).catch((reason: unknown) => {
      this._logger.error(reason);
    });
    const modalInstance = this._handleModal(DocsContabilidadeEditMovabModalComponent);
    const componentInstance: DocsContabilidadeEditMovabModalComponent = modalInstance.componentInstance;
    componentInstance.linha = linha;
    componentInstance.doc = this.model;
    const movimentosEmAberto: Array<IMovimentosEmAberto> = await modalInstance.result;
    const promise: Promise<undefined | IDocContabilidade> = this._docsContabilidadeService.linhaImputaValores(movimentosEmAberto, linha._index, this.model).catch((reason: unknown) => {
      this._logger.error(reason);
      return undefined;
    });
    this._setLoading(promise);
    const response: undefined | IDocContabilidade = await promise;
    if (response) {
      this._applyModel(response);
    }
  }

  private async _doMoedaEstrangeira(linha: IDocContabilidadeLinha): Promise<void> {
    const modalInstance = this._handleModal(DocsContabilidadeEditMoedaEstrangeiraModalComponent);
    const componentInstance: DocsContabilidadeEditMoedaEstrangeiraModalComponent = modalInstance.componentInstance;
    componentInstance.doc = this.model;
    componentInstance.nLanc = linha.nLanc;
    componentInstance.valorComIvaIncluido = this.optionValorComIvaIncluido;
    const response: IDocContabilidade = await modalInstance.result;
    this._applyModel(response);
  }

  private _addLineIfLast(linha: IDocContabilidadeLinha): void {
    const lastLine: IDocContabilidadeLinha = this.model.linhas[this.model.linhas.length - 1];
    if (!this.model.linhas.length || this._docContabilidadeService.isLineDisabled(lastLine) || linha === lastLine || this._docContabilidadeService.isLastEnabledLine(this.model, linha)) {
      this.addLine();
    }
  }

  private _focusLastElement(delay: boolean = true): void {
    const focusLastElementFn = (): void => {
      let linha: IDocContabilidadeLinha = this._docContabilidadeService.getLinha(this.model, this._lastFocusElement.linhaNLanc);
      if (!linha && isNumber(this._lastFocusElement.indiceLinha)) {
        linha = this.model.linhas[this._lastFocusElement.indiceLinha];
      }
      if (linha) {
        this._focusFieldLinha(this._lastFocusElement.fieldName, linha._index);
        setTimeout(() => {
          this._selectActiveElement();
        });
      }
    };
    if (this._lastFocusElement) {
      if (delay) {
        Promise.resolve(this.promise).finally(() => {
          setTimeout(() => {
            focusLastElementFn();
          });
        });
      } else {
        focusLastElementFn();
      }
    }
  }

  private _isNew(): boolean {
    return !this.editing && (!this.model?.extPocCabID || this.hasPreDefinido || this.contabilidadeDigital);
  }

  private _setLinhaNContaValidationStatus(linha: IDocContabilidadeLinha, type: EDebitoCredito, isInvalid: boolean): void {
    if (type === EDebitoCredito.Credito) {
      linha._invalidNContaCredito = isInvalid;
      if (linha._invalidNContaDebito) {
        linha._invalidNContaDebito = false;
      }
    } else {
      linha._invalidNContaDebito = isInvalid;
      if (linha._invalidNContaCredito) {
        linha._invalidNContaCredito = false;
      }
    }
  }

  private _setHasErrorInitDoc(value: boolean): void {
    this.disableActionDiferimentos.emit(value);
    if (value) {
      this.docNotInitialized = true;
      this.model.linhas = [];
      this._clearItemInfo();
    } else {
      this.docNotInitialized = false;
    }
  }

  private _saldarDocumento(linha: IDocContabilidadeLinha, docContabilidade: IDocContabilidade): Promise<void> {
    const oldValue: number = linha.valor;
    this._docContabilidadeService.saldaDocumento(linha, docContabilidade);
    const newValue: number = linha.valor;
    linha.valor = oldValue;
    return this.onLinhaValorChange(newValue, linha, true);
  }

  private async _changedConfig(configOption?: keyof IDocContabilidadeConfigOptions, value?: boolean): Promise<void> {
    await this._configOptionsInstance.setOption(configOption, value);
  }

  private _onFieldCabecalhoKeyDown(event: KeyboardEvent): void {
    if (event.key !== KEYCODES.ENTER) {
      if (event.key === KEYCODES.ADD || event.key === KEYCODES.MULTIPLY) {
        event.preventDefault();
      }
      return;
    }
    if (!this._restoreLastFocus) {
      const target: HTMLInputElement = <HTMLInputElement>event.target;
      let targetName: TDocContabilidadeFocusField = <TDocContabilidadeFocusField>target.getAttribute('name');
      if (targetName === 'predefinido') {
        targetName = undefined;
      }
      if (this._focusFieldCabecalho(targetName)) {
        event.preventDefault();
        event.stopPropagation();
      }
    } else {
      timeout().then(() => {
        this._focusLastElement();
      });
    }
  }

  private _onFieldCabecalhoBlur(): void {
    this._restoreLastFocus = false;
  }

  private _onLinhaFieldLinhaKeydownEnter(event: KeyboardEvent, indexLinha: number = 0): void {
    if (isFunction(event.preventDefault)) {
      event.preventDefault();
    }
    if (isFunction(event.stopImmediatePropagation)) {
      event.stopImmediatePropagation();
    }
    const target: HTMLInputElement = <HTMLInputElement>event.target;
    target.blur();
    setTimeout(() => {
      let requestError = false;
      Promise.resolve(this.promise)
        .catch(() => {
          requestError = true;
        })
        .finally(() => {
          let targetName: EDocContabilidadeField = target ? <EDocContabilidadeField>target.getAttribute('name') : undefined;
          if (targetName === EDocContabilidadeField.CONTA_DEBITO || targetName === EDocContabilidadeField.CONTA_CREDITO) {
            const row: Element = target.closest('.table-flex-tr');
            const inputNContaDebito: HTMLInputElement = row.querySelector<HTMLInputElement>(`input[name="${EDocContabilidadeField.CONTA_DEBITO}"]`);
            const inputNContaCredito: HTMLInputElement = row.querySelector<HTMLInputElement>(`input[name="${EDocContabilidadeField.CONTA_CREDITO}"]`);
            if (isEmpty(inputNContaDebito.value) && isEmpty(inputNContaCredito.value)) {
              if (targetName === EDocContabilidadeField.CONTA_DEBITO) {
                if (!inputNContaCredito.disabled) {
                  this._focusAndSelect(inputNContaCredito);
                }
                // Blur was called earlier
                else {
                  this._focusAndSelect(inputNContaDebito);
                }
                return;
              }
              if (targetName === EDocContabilidadeField.CONTA_CREDITO) {
                if (!inputNContaDebito.disabled) {
                  this._focusAndSelect(inputNContaDebito);
                }
                // Blur was called earlier
                else {
                  this._focusAndSelect(inputNContaCredito);
                }
                return;
              }
            } else {
              targetName = EDocContabilidadeField.CONTA_CREDITO;
            }
          }
          let nextElement: HTMLInputElement = this._findNextFieldLinha(targetName, indexLinha);
          if (nextElement) {
            targetName = <EDocContabilidadeField>nextElement.getAttribute('name');
            // Skip if already defined in header or isn't an IVA account
            if (targetName === EDocContabilidadeField.NCONTRIBUINTE && (this.model.nContribuinte || !this.model.linhas[indexLinha].poc.temIVA)) {
              nextElement = this._findNextFieldLinha(targetName, indexLinha);
            }
            this._focusAndSelect(nextElement);
          } else if (!requestError) {
            if (this.addLine()) {
              this.disableAutoFocus = false;
            } else {
              this._focusAndSelect(<HTMLInputElement>event.target);
            }
          }
        });
    }, DEFAULT_TIMEOUT);
  }

  private _focusFieldCabecalho(fieldName: TDocContabilidadeFocusField, exact?: boolean): boolean {
    const focusField = (name: string): boolean => {
      const toFocus: HTMLInputElement = this._element.querySelector<HTMLInputElement>(`${SELECTOR_CABECALHO} input[name="${name}"]:not(:disabled)`);
      if (!toFocus) {
        return false;
      }
      this._focusAndSelect(toFocus);
      return true;
    };

    if (fieldName === 'predefinido') {
      if (focusField(fieldName)) {
        return true;
      }
      fieldName = undefined;
    }

    let fieldIndex: number = !fieldName
      ? -1
      : this._predefinidoEvaluatedCabecalho.findIndex((tabStop: TDocContabilidadePredefinidoEvaluatedCabecalho) => {
          return tabStop[0] === fieldName;
        });
    if (!fieldName || fieldIndex !== -1) {
      if (!exact) {
        fieldIndex += 1;
      }
      // Focus cabeçalho
      if (fieldIndex < this._predefinidoEvaluatedCabecalho.length) {
        for (let i = fieldIndex; i < this._predefinidoEvaluatedCabecalho.length; i++) {
          const fieldCabecalho: IDocContabilidadeEvaluatedField = this._predefinidoEvaluatedCabecalho[i][1];
          if (fieldCabecalho.visible && fieldCabecalho.tabStop && !fieldCabecalho.readonly && focusField(this._predefinidoEvaluatedCabecalho[i][0])) {
            return true;
          }
        }
      }
    }

    // If no element was focused, focus linhas
    return this._focusFieldLinha(undefined, 0);
  }

  private _focusFieldLinha(fieldName: EDocContabilidadeField, indexLinha: number): boolean {
    const toFocus: HTMLInputElement = this._findNextFieldLinha(fieldName, indexLinha, true);
    if (toFocus) {
      this._focusAndSelect(toFocus);
      return true;
    }
    return false;
  }

  private _findNextFieldLinha(fromField: EDocContabilidadeField, indexLinha: number, exact?: boolean): HTMLInputElement {
    if (indexLinha >= this.model.linhas.length) {
      return undefined;
    }
    const linha: IDocContabilidadeLinha = this.model.linhas[indexLinha];
    if (!this._docContabilidadeService.isLineDisabled(linha)) {
      let definitionIndex = fromField ? this._definition.findIndex((definition: IDocContabilidadeDefinition) => definition.id === fromField) : 0;
      if (fromField && !exact) {
        definitionIndex += 1;
      }
      if (definitionIndex > -1 && definitionIndex < this._definition.length) {
        for (let i = definitionIndex; i < this._definition.length; i++) {
          const definition: IDocContabilidadeDefinition = this._definition[i];
          let nextElement: HTMLInputElement;
          if (!this.hasPreDefinido || definition.evaluatePreDefinidoIndex === -1) {
            if (definition.show) {
              if (definition.id === EDocContabilidadeField.DESCRICAO && this.optionSkipDescricao) {
                continue;
              }
              nextElement = this._getLinhaInputElement(definition.id, indexLinha);
            }
          } else {
            const preDefinidoContabLinhaIndice: number = linha.preDefinidoContabLinhaIndice || 0;
            const predefinidoEvaluatedLinha: Array<TDocContabilidadePredefinidoEvaluatedLinha> = this._predefinidoEvaluatedLinhas.get(preDefinidoContabLinhaIndice);
            const fieldLinha: IDocContabilidadeEvaluatedField = predefinidoEvaluatedLinha[definition.evaluatePreDefinidoIndex][1];
            if (fieldLinha.visible && fieldLinha.tabStop && !fieldLinha.readonly) {
              nextElement = this._getLinhaInputElement(definition.id, indexLinha);
            }
          }
          if (nextElement) {
            return nextElement;
          }
        }
      }
    }
    return this._findNextFieldLinha(EDocContabilidadeField.CONTA_DEBITO, indexLinha + 1, true);
  }

  private _getLinhaInputElement(inputName: EDocContabilidadeField, indexLinha: number): HTMLInputElement {
    return this._element.querySelector<HTMLInputElement>(`${SELECTOR_LINHAS}:nth-child(${indexLinha + 1}) input[name="${inputName}"]:not(:disabled)`);
  }

  private _handleServiceException(exception: IDocContabilidadeException): void {
    const docContabilidade: IDocContabilidade = exception.docContabilidade;
    const linha: IDocContabilidadeLinha = exception.linhaNLanc ? this._docContabilidadeService.getLinha(docContabilidade, exception.linhaNLanc) : undefined;
    switch (exception.class) {
      case EDocContabilidadeException.INVALID_DATA_LANCAM:
        if (this.visibleDataLancamento) {
          setTimeout(() => {
            this._focusFieldCabecalho('dataLancamento', true);
          });
        }
        break;
      case EDocContabilidadeException.INVALID_NIF:
        if (this.visibleNContribuinte && !this.optionShowFieldLinhaNif) {
          setTimeout(() => {
            this._focusFieldCabecalho('nContribuinte', true);
          });
        }
        break;
      case EDocContabilidadeException.INVALID_POC:
        if (linha) {
          this._setLinhaNContaValidationStatus(linha, linha.dc, true);
          const fieldToFocus: EDocContabilidadeField = linha.dc === EDebitoCredito.Credito ? EDocContabilidadeField.CONTA_CREDITO : EDocContabilidadeField.CONTA_DEBITO;
          setTimeout(() => {
            this._focusFieldLinha(fieldToFocus, linha._index);
          });
        }
        break;
      case EDocContabilidadeException.INVALID_DATA_DOC:
        if (
          (this.optionShowFieldLinhaDataDoc && !this.hasPreDefinido) ||
          (this.hasPreDefinido && this._predefinidoEvaluatedLinhas.get(linha.preDefinidoContabLinhaIndice || 0)[this._definitionDataDoc.evaluatePreDefinidoIndex][1].visible)
        ) {
          setTimeout(() => {
            this._focusFieldLinha(EDocContabilidadeField.DATA_DOC, linha._index);
          });
        }
        break;
    }
    this._restoreLastFocus = isDefined(this._lastFocusElement);
  }

  private _clearItemInfo(): void {
    this.itemInfo = undefined;
  }

  private _focusAndSelect(element: HTMLInputElement): void {
    element.focus();
    element.select();
  }

  private _selectActiveElement(): void {
    const activeElement: HTMLInputElement = <HTMLInputElement>window.document.activeElement;
    if (activeElement && isFunction(activeElement.select)) {
      activeElement.select();
    }
  }

  private _postValorChange(docContabilidadeLinha: IDocContabilidadeLinha, docContabilidade: IDocContabilidade, force: boolean): Promise<void> {
    if (this._destroyed) {
      return Promise.resolve();
    }
    let promise: Promise<void>;
    if (docContabilidadeLinha.poc.temRetencao && docContabilidadeLinha.valor && docContabilidadeLinha.nContrib && docContabilidadeLinha.entNIF.codRet) {
      promise = this._doRetencao(docContabilidadeLinha);
    } else if (docContabilidadeLinha.poc.temCCusto && docContabilidadeLinha.valor && (force || !this._handledCentroCustoLines.has(docContabilidadeLinha.nLanc))) {
      promise = this._doCentroCusto(docContabilidadeLinha).then(() => {
        docContabilidade = this.model;
        docContabilidadeLinha = docContabilidade.linhas.find((linha: IDocContabilidadeLinha) => linha.nLanc === docContabilidadeLinha.nLanc);
        if (isNumber(docContabilidadeLinha.nGrupo)) {
          const linesToCheckDoCentroCusto: Array<string> = docContabilidade.linhas
            .slice(docContabilidadeLinha._index, docContabilidade.linhas.length)
            .filter((lineToFilter: IDocContabilidadeLinha) => lineToFilter.nGrupo === docContabilidadeLinha.nGrupo && lineToFilter.classificControlo > EClassificacaoControlo.BaseTributavel)
            .map<string>((lineToMap: IDocContabilidadeLinha) => lineToMap.nLanc)
            .reverse();
          return this._doCentroCustoForLines(linesToCheckDoCentroCusto, force);
        }
        return Promise.resolve();
      });
    } else if (docContabilidadeLinha.isRegularizacaoCampo40Art78) {
      promise = this._doRegularizacaoCampo40Art78(docContabilidadeLinha);
    } else {
      promise = Promise.resolve();
    }
    return promise;
  }

  private _doCentroCustoForLines(lines: Array<string>, force: boolean): Promise<void> {
    const currentLineToCheck: string = lines.pop();
    const lineToHandle: IDocContabilidadeLinha = this.model.linhas.find((modelLine: IDocContabilidadeLinha) => modelLine.nLanc === currentLineToCheck);
    const promise: Promise<void> = !lineToHandle
      ? Promise.resolve()
      : lineToHandle.poc.temCCusto &&
          lineToHandle.valor &&
          (force ||
            (lineToHandle.nLancDestinoImputacaoCCusto &&
              lineToHandle.nLancDestinoImputacaoCCusto !== EMPTY_GUID &&
              lineToHandle.nLancDestinoImputacaoCCusto === lineToHandle.nLanc &&
              !this._handledCentroCustoLines.has(lineToHandle.nLanc)))
        ? this._doCentroCusto(lineToHandle)
        : Promise.resolve();
    return promise.then(() => {
      if (lines.length) {
        return this._doCentroCustoForLines(lines, force);
      }
      return Promise.resolve();
    });
  }

  private _doMovimentosAbertoForLines(lines: Array<string | IJsonDocContabilidadeLinha>): Promise<void> {
    if (this._destroyed || !lines.length) {
      return Promise.resolve();
    }
    const line: string | IJsonDocContabilidadeLinha = lines.pop();
    const lineToCheck: string = isString(line) ? line : line.nLanc;
    const lineToHandle: IDocContabilidadeLinha = this.model.linhas.find((modelLine: IDocContabilidadeLinha) => modelLine.nLanc === lineToCheck);
    if (lineToHandle && this._docContabilidadeService.doMovimentosAberto(this.model, lineToHandle)) {
      return this._doMovimentosAberto(lineToHandle).then(() => {
        return this._doMovimentosAbertoForLines(lines);
      });
    }
    return Promise.resolve();
  }

  private _doMovimentosAbertoForAllLines(): Promise<void> {
    const lines: Array<string> = this.model.linhas.map<string>((lineToMap: IDocContabilidadeLinha) => lineToMap.nLanc).reverse();
    return this._doMovimentosAbertoForLines(lines);
  }

  private _doPostValorChangeForLines(lines: Array<string | IJsonDocContabilidadeLinha>): Promise<void> {
    if (this._destroyed || !lines.length) {
      return Promise.resolve();
    }
    const line: string | IJsonDocContabilidadeLinha = lines.pop();
    const lineToCheck: string = isString(line) ? line : line.nLanc;
    const lineToHandle: IDocContabilidadeLinha = this.model.linhas.find((modelLine: IDocContabilidadeLinha) => modelLine.nLanc === lineToCheck);
    if (lineToHandle && (lineToHandle.poc.temIVA || lineToHandle.poc.temCCusto || this.hasPreDefinido || this._isMoedaPresent)) {
      return this._postValorChange(lineToHandle, this.model, false).then(() => {
        return this._doPostValorChangeForLines(lines);
      });
    }
    return Promise.resolve();
  }

  private _doPostValorChangeForAllLines(): Promise<void> {
    const linesToCheckDoCentroCusto: Array<string> = this.model.linhas.map<string>((lineToMap: IDocContabilidadeLinha) => lineToMap.nLanc).reverse();
    return this._doPostValorChangeForLines(linesToCheckDoCentroCusto);
  }

  private _setErroInformativo(value: string): void {
    this.erroInformativo = value;
  }

  private _clearErroInformativo(): void {
    this._setErroInformativo(undefined);
  }

  private _registarNif(nif: string): Promise<[string, boolean]> {
    return new Promise<[string, boolean]>((resolve) => {
      let shouldRegister = false;
      let promise: Promise<unknown>;
      if (nif) {
        promise = this._docsContabilidadeService.registaNif(nif);
      }
      Promise.resolve(promise).finally(() => {
        if (nif) {
          promise = this._nifs.get({id: nif, reportExceptions: false});
        }
        Promise.resolve(promise)
          .catch((reason: HttpErrorResponse) => {
            this._logger.error(reason);
            shouldRegister = true;
          })
          .finally(() => {
            if (shouldRegister && !this._destroyed) {
              this._cgModalService
                .showOkCancel(this._translateService.instant('nifs.naoregistado'), this._translateService.instant('nifs.desejaregistar'), {ownerViewRef: this._viewRef})
                .then(() => {
                  const modalInstance = this._cgModalService.showVanilla(RegistarNifModalComponent, {ownerViewRef: this._viewRef});
                  const componentInstance: RegistarNifModalComponent = modalInstance.componentInstance;
                  componentInstance.nContribuinte = nif;
                  modalInstance.result
                    .then((response: IJsonNIFs) => {
                      resolve([response.nContribuinte, true]);
                    })
                    .catch((reason: unknown) => {
                      this._logger.error(reason);
                      resolve([nif, true]);
                    });
                })
                .catch((reason: unknown) => {
                  this._logger.error(reason);
                  resolve([nif, true]);
                });
            } else {
              resolve([nif, false]);
            }
          });
      });
    });
  }

  private _setContabilidadeDigitalAttachment(gDocId: string, gDocFolderId: string, docOCRCabID: string): void {
    this._contabilidadeDigitalChangedFolder = !this.contabilidadeDigitalAttachment.gDocFolderId || this.contabilidadeDigitalAttachment.gDocFolderId !== gDocFolderId;
    this.contabilidadeDigitalAttachment = {gDocId: gDocId, gDocFolderId: gDocFolderId, docOCRCabID: docOCRCabID};
    this.contabilidadeDigitalAttachmentChange.emit(this.contabilidadeDigitalAttachment);
  }

  private async _removePreDefinido(): Promise<void> {
    this.predefinido = undefined;
    this.model.predefinido = this.predefinido;
    this.model.isUsingPreDefinido = false;
    await this._handlePreDefinido();
  }

  private _evaluateDocViewerFooterCollapsed(): void {
    this.docViewerFooterCollapsed = !this.isMobile && this.optionDocViewerFooterCollapsed;
  }

  private _getLastNonEmptyLineIndex(): number {
    if (this.model.linhas.length) {
      for (let i = this.model.linhas.length - 1; i >= 0; i--) {
        const linha: IDocContabilidadeLinha = this.model.linhas[i];
        if (!this._docContabilidadeService.isLineEmpty(linha)) {
          return i;
        }
      }
    }
    return -1;
  }

  private async _mudaPeriodoContabEmp(): Promise<void> {
    const message: string = this._translateService.instant('docscontabilidade.text.confirmMudaPerio', {periodo: this.model.periodo});
    await this._cgModalService.showOkCancel('global.text.confirmation', message, {ownerViewRef: this._viewRef});
    await this._docsContabilidadeService.mudarPeriodoEmpresa(this.model.periodo);
    this._plAlertService.success('docscontabilidade.text.periodoempmudadosuccesso');
  }

  private _initLinhaImputada(linha: IDocContabilidadeLinha): void {
    if (linha._imputaDocPrincipal && (!linha._extPocCabImput || linha._extPocCabImput === EMPTY_GUID) && !linha._promiseLinhaImputada) {
      linha._promiseLinhaImputada = this._docsContabilidadeService.initLinhaImputada(linha);
      linha._promiseLinhaImputada.catch(() => {
        linha._promiseLinhaImputada = undefined;
      });
    }
  }
}
