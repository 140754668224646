import {Component, Injector, Input} from '@angular/core';
import {IJsonApuraIvaConfig} from '../../jsonApuraIva.module.interface';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {ContabilidadeApuraIvaService} from '../../../../../../services/contabilidade.apuraiva.service';

@Component({
  selector: 'apura-iva-config',
  templateUrl: './contabilidade.apuraIva.config.modal.component.html'
})
export class ContabilidadeApuraIvaConfigModalComponent extends CGModalComponent<IJsonApuraIvaConfig> {
  @Input() public model: IJsonApuraIvaConfig;

  constructor(
    protected readonly _injector: Injector,
    private readonly _apuraIvaService: ContabilidadeApuraIvaService
  ) {
    super(_injector);
    this.model = {
      nDiario: undefined,
      nDescritivo: undefined,
      nomeDiario: '',
      nomeDescritivo: ''
    };
  }

  public async close(): Promise<void> {
    await this._apuraIvaService.saveConfig(this.model);
    super.close(this.model);
  }
}
