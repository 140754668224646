<div class="rh-abdes-edit">
  <div class="d-flex" *ngIf="type === stateTypes.NEW">
    <button type="button" class="btn btn-md btn-primary me-1 mb-3" [ngbTooltip]="'abdes.messages.perfilSistema' | translate" (click)="lockUnlockClicked($event)">
      <i class="fa fa-lg fa-fw" [ngClass]="isPerfilSistema ? 'fa-lock' : 'fa-unlock'"></i>
    </button>
    <pl-alert type="info" class="flex-grow-1" [closeable]="false">
      <span *ngIf="isPerfilSistema">{{ abdescSystemLockedWarningMsg }}</span>
      <span *ngIf="!isPerfilSistema">{{ abdescSystemUnLockedWarningMsg }}</span>
    </pl-alert>
  </div>

  <entity-detail [entityName]="entityName" [(model)]="model" [type]="type" [maintenanceMode]="maintenanceMode" [formOrientation]="formOrientation" [callback]="callback" (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL, orientation: formOrientation}">
        <div class="d-flex">
          <pl-group>
            <label></label>
            <edit>
              <pl-edit type="boolean" attrName="abonoDescDesativado" [(model)]="model.abonoDescDesativado" [properties]="{label: 'abdes.fields.abonoDescDesativado'}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label></label>
            <actions>
              <button
                type="button"
                class="btn btn-md btn-primary me-1 mb-3"
                [ngbTooltip]="isPerfilSistema ? abdescSystemLockedWarningMsg : abdescSystemUnLockedWarningMsg"
                placement="right"
                (click)="type === stateTypes.NEW ? lockUnlockClicked($event) : undefined">
                <i class="fa fa-lg fa-fw" [ngClass]="isPerfilSistema ? 'fa-lock' : 'fa-unlock'"></i>
              </button>
            </actions>
          </pl-group>

          <pl-group class="flex-grow-1">
            <label [translate]="'abdes.fields.codAbDesc'"></label>
            <edit>
              <pl-edit-text attrName="codAbDesc" [model]="model.codAbDesc" (modelChange)="onCodAbDescChange($event)" [properties]="{readonly: type !== stateTypes.NEW}"></pl-edit-text>
            </edit>
          </pl-group>
        </div>

        <pl-group *ngIf="isAbono">
          <label>
            <span class="me-1" [translate]="'abdes.fields.grupoAbono'"></span>
            <pl-tooltip *ngIf="isPerfilSistema" class="num-depend-def-label" [config]="{text: 'abdes.messages.perfilSistemaTooltip', container: 'body'}">
              <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
            </pl-tooltip>
          </label>
          <edit>
            <entity-autocomplete
              entity="grabonos"
              attrName="grupoAbono"
              [model]="model"
              [selectedKey]="model.grupoAbono"
              (selectedKeyChange)="grupoAbonoChange($event)"
              (evtSelectedDescriptionChanged)="model.grupoAbonoNome = $event"
              [outputKey]="'codGrAbono'"
              [outputDescription]="'descricao'"
              [fieldsMap]="{codGrAbono: 'grupoAbono', descricao: 'grupoAbonoNome'}"
              [properties]="{disabled: isPerfilSistema}"
              plAutoFocus
              [plAutoFocusDisabled]="!isAbono">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="!isAbono">
          <label>
            <span class="me-1" [translate]="'abdes.fields.grupoDesconto'"></span>
            <pl-tooltip *ngIf="isPerfilSistema" class="num-depend-def-label" [config]="{text: 'abdes.messages.perfilSistemaTooltip', container: 'body'}">
              <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
            </pl-tooltip>
          </label>
          <edit>
            <entity-autocomplete
              entity="grdescontos"
              attrName="grupoDesconto"
              [model]="model"
              [selectedKey]="model.grupoDesconto"
              (selectedKeyChange)="grupoDescontoChange($event)"
              (evtSelectedDescriptionChanged)="model.grupoDescontoNome = $event"
              [outputKey]="'codGrDesconto'"
              [outputDescription]="'descricao'"
              [fieldsMap]="{codGrDesconto: 'grupoDesconto', descricao: 'grupoDescontoNome'}"
              [properties]="{disabled: isPerfilSistema}"
              plAutoFocus
              [plAutoFocusDisabled]="isAbono">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'abdes.fields.designacaoBreve'"></label>
            <edit>
              <pl-edit-text attrName="designacaoBreve" [(model)]="model.designacaoBreve"></pl-edit-text>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'abdes.fields.designacaoCompleta'"></label>
            <edit>
              <pl-edit-text attrName="designacaoCompleta" [(model)]="model.designacaoCompleta"></pl-edit-text>
            </edit>
          </pl-group>
        </pl-group>

        <fieldset class="mb-2">
          <legend [translate]="'abdes.fields.irs'"></legend>
          <pl-group>
            <label>
              <span class="me-1" [translate]="'abdes.fields.tipoRendimento'"></span>
              <pl-tooltip *ngIf="isPerfilSistema" class="num-depend-def-label" [config]="{text: 'abdes.messages.perfilSistemaTooltip', container: 'body'}">
                <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
              </pl-tooltip>
            </label>
            <edit>
              <entity-autocomplete
                entity="rendis"
                attrName="tipoRendimento"
                [model]="model"
                (selectedKeyChange)="model.tipoRendimento = $event"
                [fieldsMap]="{tipRendiment: 'tipoRendimento'}"
                output="key"
                outputKey="tipRendiment"
                [rowTemplate]="rendisRowTemplate"
                [filter]="rendisFilter"
                [properties]="{showMaintenanceEditAction: false, disabled: isPerfilSistema}"
                [maintenanceProperties]="{inheritFilter: true}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <pl-group>
              <label>
                <span class="me-1" [translate]="'abdes.fields.taxaRetencao'"></span>
                <pl-tooltip *ngIf="isPerfilSistema" class="num-depend-def-label" [config]="{text: 'abdes.messages.perfilSistemaTooltip', container: 'body'}">
                  <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
                </pl-tooltip>
              </label>
              <edit>
                <pl-edit attrName="procNatal" [type]="'tipoTaxaRetencao'" [model]="taxaRetencao" (modelChange)="taxaRetencaoChange($event)" [properties]="{disabled: isPerfilSistema}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <pl-group>
                <label>
                  <span class="me-1" [translate]="'abdes.virtualFields.imprimeDec119'"></span>
                  <pl-tooltip *ngIf="isPerfilSistema" class="num-depend-def-label" [config]="{text: 'abdes.messages.perfilSistemaTooltip', container: 'body'}">
                    <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
                  </pl-tooltip>
                </label>
                <edit>
                  <pl-edit-checkbox attrName="imprimeDec119" [(model)]="modelDec119.imprimeDec119" [properties]="{disabled: type === stateTypes.DETAIL || isPerfilSistema}"></pl-edit-checkbox>
                </edit>
              </pl-group>

              <pl-group>
                <label>
                  <span class="me-1" [translate]="'abdes.virtualFields.enviaAT'"></span>
                  <pl-tooltip *ngIf="isPerfilSistema" class="num-depend-def-label" [config]="{text: 'abdes.messages.perfilSistemaTooltip', container: 'body'}">
                    <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
                  </pl-tooltip>
                </label>
                <edit>
                  <pl-edit-checkbox attrName="enviaAT" [(model)]="modelDec119.enviaAT" [properties]="{disabled: type === stateTypes.DETAIL || isPerfilSistema}"></pl-edit-checkbox>
                </edit>
              </pl-group>
            </pl-group>
          </pl-group>
        </fieldset>

        <fieldset class="mb-2">
          <legend [translate]="'abdes.fields.segsocial'"></legend>
          <pl-group>
            <pl-group [ngClass]="{'input-warning': type !== stateTypes.DETAIL && codSSColShowWarning}">
              <label>
                <span class="me-1" [translate]="'abdes.fields.codSSCol'"></span>
                <pl-tooltip *ngIf="isPerfilSistema" class="num-depend-def-label" [config]="{text: 'abdes.messages.perfilSistemaTooltip', container: 'body'}">
                  <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
                </pl-tooltip>
              </label>
              <edit>
                <entity-autocomplete
                  entity="sscol"
                  attrName="codSSCol"
                  [(selectedKey)]="model.codSSCol"
                  (modelChange)="fnEvaluateCodSSColShowWarning()"
                  [properties]="{disabled: isPerfilSistema}"
                  output="key"></entity-autocomplete>
                <div class="input-warning-message">
                  <i class="fa fa-fw fa-exclamation-triangle me-1" aria-hidden="true"></i>
                  <span [translate]="'abdes.messages.abdesTemConfigSegSocialSemCruzamentos'" [translateParams]="{abdes: titleAbonoDesconto, id: model.codAbDesc}"> </span>
                </div>
              </edit>
            </pl-group>

            <pl-group>
              <label>
                <span class="me-1" [translate]="'abdes.fields.percInciSegSocial'"></span>
                <pl-tooltip *ngIf="isPerfilSistema" class="num-depend-def-label" [config]="{text: 'abdes.messages.perfilSistemaTooltip', container: 'body'}">
                  <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
                </pl-tooltip>
              </label>
              <edit>
                <pl-edit-number attrName="percInciSegSocial" [(model)]="model.percInciSegSocial" [properties]="{disabled: isPerfilSistema}"></pl-edit-number>
              </edit>
            </pl-group>
          </pl-group>
        </fieldset>

        <pl-group>
          <pl-group>
            <label [translate]="'abdes.fields.valorFixo'"></label>
            <edit>
              <pl-edit-number attrName="valorFixo" [(model)]="model.valorFixo"></pl-edit-number>
            </edit>
          </pl-group>

          <pl-group>
            <label>
              <span class="me-1" [translate]="'abdes.fields.valorLimIncidencia'"></span>
              <pl-tooltip
                *ngIf="isPerfilSistema || perfilSistemaAbdes?.bloqueiaLimitesIncidencia"
                class="num-depend-def-label"
                [config]="{text: 'abdes.messages.perfilSistemaTooltip', container: 'body'}">
                <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
              </pl-tooltip>
            </label>
            <edit>
              <pl-edit-number attrName="valorLimIncidencia" [(model)]="model.valorLimIncidencia" [properties]="{disabled: isPerfilSistema || perfilSistemaAbdes?.bloqueiaLimitesIncidencia}">
              </pl-edit-number>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <label>
            <span class="me-1" [translate]="'abdes.fields.tipoArredondamento'"></span>
            <pl-tooltip *ngIf="isPerfilSistema" class="num-depend-def-label" [config]="{text: 'abdes.messages.perfilSistemaTooltip', container: 'body'}">
              <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
            </pl-tooltip>
          </label>
          <edit>
            <pl-edit attrName="tipoArredondamento" type="tipoArrendodamentoABDES" [(model)]="model.tipoArredondamento" [properties]="{disabled: isPerfilSistema}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'abdes.fields.tipoValorUnitRecolha'"></label>
            <edit>
              <pl-edit attrName="tipoValorUnitRecolha" type="tipoValorUnitarioRecolher" [(model)]="model.tipoValorUnitRecolha"></pl-edit>
            </edit>
          </pl-group>

          <pl-group *ngIf="isAbono">
            <label [translate]="'abdes.fields.calcCustHora'"></label>
            <edit>
              <pl-edit-checkbox attrName="calcCustHora" [(model)]="model.calcCustHora" [properties]="{disabled: type === stateTypes.DETAIL}"></pl-edit-checkbox>
            </edit>
          </pl-group>

          <pl-group *ngIf="!isAbono">
            <label [translate]="'abdes.fields.entiSuporte'"></label>
            <edit>
              <pl-edit attrName="entiSuporte" type="tipoEntSuporteABDES" [(model)]="model.entiSuporte"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-tabs class="mt-3">
          <pl-tab id="integracao" [caption]="'abdes.tabIntegracaoCruzamento'">
            <div *plTabContent>
              <pl-group>
                <label [translate]="'abdes.fields.contaMovDeb'"></label>
                <edit>
                  <entity-autocomplete entity="pocs" attrName="contaMovDeb" [model]="model" [(selectedKey)]="model.contaMovDeb" [fieldsMap]="{nConta: 'contaMovDeb'}" output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'abdes.fields.contaMovCred'"></label>
                <edit>
                  <entity-autocomplete entity="pocs" attrName="contaMovCred" [model]="model" [(selectedKey)]="model.contaMovCred" [fieldsMap]="{nConta: 'contaMovCred'}" output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'abdes.fields.contaEncDEB'"></label>
                <edit>
                  <entity-autocomplete entity="pocs" attrName="contaEncDEB" [model]="model" [(selectedKey)]="model.contaEncDEB" [fieldsMap]="{nConta: 'contaEncDEB'}" output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'abdes.fields.contaEncCRE'"></label>
                <edit>
                  <entity-autocomplete entity="pocs" attrName="contaEncCRE" [model]="model" [(selectedKey)]="model.contaEncCRE" [fieldsMap]="{nConta: 'contaEncCRE'}" output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="abdesGrid" [dataSource]="dataGridDefinition.dataSource">
                <div *dxTemplate="let item of 'headerCellTemplateBtnsEdit'">
                  <pl-button *ngIf="type !== stateTypes.NEW" klass="btn-xs btn-primary float-end" [disabled]="type === stateTypes.DETAIL || !canEditCruzamentos" [onClick]="newCrabd">
                    <i class="fa fa-fw fa-plus-circle"></i>
                  </pl-button>
                </div>

                <div *dxTemplate="let item of 'cellTemplateBtnsEdit'">
                  <pl-button klass="btn-xs btn-warning float-end" [disabled]="type === stateTypes.DETAIL || !canEditCruzamentos" (evtClicked)="editCrabd(item.data)">
                    <i class="fa fa-fw fa-pencil"></i>
                  </pl-button>
                  <pl-button klass="btn-xs btn-danger float-end" [disabled]="type === stateTypes.DETAIL || !canEditCruzamentos" (evtClicked)="deleteCrabd(item.data)">
                    <i class="fa fa-fw fa-trash"></i>
                  </pl-button>
                </div>
              </dx-data-grid>
            </div>
          </pl-tab>

          <pl-tab id="outrosdados" [caption]="'abdes.tabOutrosDados'">
            <div *plTabContent>
              <pl-group>
                <label [translate]="'abdes.fields.nHorasDiaProvDesc'"></label>
                <edit>
                  <pl-edit-number attrName="nHorasDiaProvDesc" [(model)]="model.nHorasDiaProvDesc" [properties]="{disabled: !isNHorasDiaProvDescEnabled}"></pl-edit-number>
                </edit>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'abdes.fields.tipoDescontoProporcionalABDES'"></label>
                  <edit>
                    <pl-edit attrName="tipoDescontoProporcionalABDES" [type]="'tipoDescontoProporcionalABDES'" [(model)]="model.tipoDescontoProporcional"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group *ngIf="model.tipoDescontoProporcional === 2">
                  <label [translate]="'abdes.fields.nHorasDescontoProporcional'"></label>
                  <edit>
                    <pl-edit-number attrName="tipoDescontoProporcional" [(model)]="model.nHorasDescontoProporcional"></pl-edit-number>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'abdes.fields.mapaSeguro'"></label>
                  <edit>
                    <pl-edit type="boolean" attrName="mapaSeguro" [(model)]="model.mapaSeguro" [properties]="{trueValue: 1, falseValue: 0}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'abdes.fields.mapaSindic'"></label>
                  <edit>
                    <pl-edit type="boolean" attrName="mapaSindic" [(model)]="model.mapaSindic" [properties]="{trueValue: 1, falseValue: 0}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>
              <pl-group>
                <pl-group>
                  <label [translate]="'abdes.fields.coefHExtra'"></label>
                  <edit>
                    <pl-edit-number attrName="coefHExtra" [(model)]="model.coefHExtra"></pl-edit-number>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'abdes.fields.percLimValorHExtra'"></label>
                  <edit>
                    <pl-edit-number attrName="percLimValorHExtra" [(model)]="model.percLimValorHExtra"></pl-edit-number>
                  </edit>
                </pl-group>
              </pl-group>
              <pl-group>
                <pl-group>
                  <label [translate]="'abdes.fields.usaSPReducaoRemuneratoria'"></label>
                  <edit>
                    <pl-edit-checkbox attrName="usaSPReducaoRemuneratoria" [(model)]="model.usaSPReducaoRemuneratoria" [properties]="{disabled: type === stateTypes.DETAIL}"></pl-edit-checkbox>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'abdes.fields.perctDescSeguro'"></label>
                  <edit>
                    <pl-edit-number attrName="perctDescSeguro" [(model)]="model.perctDescSeguro"></pl-edit-number>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <label [translate]="'abdes.fields.codCGASit'"></label>
                <edit>
                  <entity-autocomplete entity="cgasi" attrName="codCGASit" [model]="model" [(selectedKey)]="model.codCGASit" [fieldsMap]="{codCGASit: 'codCGASit'}" output="key"></entity-autocomplete>
                </edit>
              </pl-group>
            </div>
          </pl-tab>
        </pl-tabs>
      </pl-form>
    </div>
  </entity-detail>
</div>
