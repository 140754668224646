<div class="reparticoesccustos">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [service]="service"
    [formInstance]="formGroup"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <reparticoesccusto
        [(model)]="model"
        [type]="tipoMascaraAnalitica"
        [new]="type === stateTypes.NEW"
        [stateType]="type"
        [readonly]="type === stateTypes.DETAIL"
        (evtChangedFormGroup)="changedFormGroup($event)">
      </reparticoesccusto>
    </div>
  </entity-detail>
</div>
