<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'efatura.logs.modalTitle'"></h4>
  </div>

  <div class="modal-body">
    <cg-card-panel #cardPanel="cgCardPanel">
      <div *cgCardPanelContent>
        <pl-form class="px-2 py-1">
          <div class="row">
            <div class="col-sm-12 col-md-6 col-xl-3">
              <pl-group>
                <label [translate]="'efatura.logs.dataComunicacao'"></label>
                <edit>
                  <pl-edit-datepicker [(model)]="modelFilters.dataDe"></pl-edit-datepicker>
                </edit>
              </pl-group>
            </div>
            <div class="col-sm-12 col-md-6 col-xl-3">
              <pl-group>
                <label [translate]="'efatura.logs.ate'"></label>
                <edit>
                  <pl-edit-datepicker [(model)]="modelFilters.dataAte"></pl-edit-datepicker>
                </edit>
              </pl-group>
            </div>
            <div class="col-sm-12 col-md-6 col-xl-3 align-self-end">
              <pl-group>
                <edit>
                  <pl-button klass="btn-sm btn-primary action-register-value" [onClick]="fnPesquisar"
                    ><i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
                  </pl-button>
                </edit>
              </pl-group>
            </div>
          </div>
        </pl-form>
      </div>
    </cg-card-panel>

    <dx-data-grid
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="dataGridDefinitionTable"
      (onInitialized)="onInitialized($event)"
      [dataSource]="dataGridDefinition.dataSource"></dx-data-grid>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</pl-form>
