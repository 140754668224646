import {Observable} from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import {isEmpty, isObject, isUndefined, PlAlertService} from 'pl-comps-angular';
import {ApiService} from '../../../services/api/api.service';
import {buildSessionUrlWithParams, round} from '../../../../common/utils/utils';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {ConfigService} from '../../../services/config/config.service';
import {DefinicaoEmailService} from '../../clifos/components/component/definicaoemails/definicaoEmail.service';
import {EClifoExtraValueTipo} from '../../clifoextravaluetipo/jsonClifoExtraValueTipo.entity.interface';
import {ENTITY_NAME_PAGAMENTOS, IPagamentoDistribuirValor, IPagamentosEntityService} from '../pagamentos.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {ICGExceptionErrorField} from '../../../components/exceptions/exceptions.service.interface';
import {IJsonClifoExtraValue} from '../../clifoextravalue/jsonClifoExtraValue.entity.interface';
import {IJsonPagamento, IJsonPagamentoEmail, IJsonPagamentoLinha, IJsonPagamentoMeioPagamento} from '../jsonPagamento.entity.interface';
import {IPagamentoImprimirModalParams} from '../modals/imprimirmodal/pagamento.imprimir.modal.component.interface';
import {IPagamentosSaveModalResult, TPagamentosSaveModalSaveFn} from '../modals/savemodal/pagamentos.save.modal.component.interface';
import {IPagamentosService} from './pagamentos.entity.service.interface';
import {PagamentoImprimirModalComponent} from '../modals/imprimirmodal/pagamento.imprimir.modal.component';
import {PagamentosDistribuiValorModalComponent} from '../modals/distribuivalormodal/pagamentos.distribuiValor.modal.component';
import {PagamentosSaveModalComponent} from '../modals/savemodal/pagamentos.save.modal.component';
import {THttpQueryResponse, TServiceQueryResponse, TServiceResponse} from '../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class PagamentosService implements IPagamentosService {
  private readonly _entityNamePagamentos: string;
  private readonly _servicePagamentos: IPagamentosEntityService;
  private readonly _path: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _configService: ConfigService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _apiService: ApiService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _definicaoEmailService: DefinicaoEmailService
  ) {
    this._entityNamePagamentos = ENTITY_NAME_PAGAMENTOS;
    this._servicePagamentos = this._entityServiceBuilder.build<IJsonPagamento, IPagamentosEntityService>(this._entityNamePagamentos);
    this._path = `${this._apiService.path.restapi}/${this._entityNamePagamentos}`;
  }

  public atribuiMontanteRetencao(pagamento: IJsonPagamento): void {
    pagamento.cab.temRetencao = false;

    for (const linha of pagamento.linhas) {
      if (linha.montanteAReter > 0) {
        pagamento.cab.temRetencao = true;
        linha.montanteRetido = linha.montanteAReter;
      } else {
        linha.montanteRetido = 0;
      }
    }
  }

  public calculaPercentagemDesconto(linha: IJsonPagamentoLinha, percDescSobreValorIVA: boolean): void {
    const valorDesconto = round(linha.valorDesconto, this._configService.configurations.contabilidade.decimais.valorDesconto);
    if (valorDesconto > linha.valorAPagar && linha.valorAPagar > 0) {
      this._plAlertService.error('pagamentos.erros.valorDescontoMaiorValor');
    }

    let valorRealIVA = 0;
    if (valorDesconto > 0) {
      valorRealIVA = linha.valorPorPagar > 0 && percDescSobreValorIVA ? (linha.valorAPagar * linha.valorIVA) / linha.valorPorPagar : 0;
      linha.percDesconto =
        linha.valorAPagar - valorRealIVA > 0 ? round((valorDesconto * 100) / (linha.valorAPagar - valorRealIVA), this._configService.configurations.contabilidade.decimais.desconto) : 0;
    } else {
      linha.percDesconto = 0;
    }
  }

  public calculaValorDesconto(linha: IJsonPagamentoLinha, percDescSobreValorIVA: boolean): void {
    if (!linha.percDesconto) {
      return;
    }

    const percDesconto = round(linha.percDesconto, this._configService.configurations.contabilidade.decimais.percDesconto);
    if (percDesconto > 100) {
      this._plAlertService.error('pagamentos.erros.percentagemDescontoMaior100');
    }

    let valorRealIVA = 0;
    if (percDesconto > 0) {
      valorRealIVA = linha.valorPorPagar > 0 && percDescSobreValorIVA ? (linha.valorAPagar * linha.valorIVA) / linha.valorPorPagar : 0;

      linha.valorDesconto =
        linha.valorAPagar - valorRealIVA > 0 ? round((percDesconto / 100) * (linha.valorAPagar - valorRealIVA), this._configService.configurations.contabilidade.decimais.desconto) : 0;
    } else {
      linha.valorDesconto = 0;
    }
  }

  public calculaValorRetencao(pagamentoLinha: IJsonPagamentoLinha): void {
    if (pagamentoLinha.valorAPagar === pagamentoLinha.valorPorPagar || pagamentoLinha.valorAPagar === 0 || pagamentoLinha.valorPorPagar === 0) {
      pagamentoLinha.montanteRetido = pagamentoLinha.montanteAReter;
    } else {
      const percentagem = (pagamentoLinha.valorAPagar * 100) / pagamentoLinha.valorPorPagar;
      pagamentoLinha.montanteRetido = round((pagamentoLinha.montanteAReter * percentagem) / 100, this._configService.configurations.contabilidade.decimais.desconto);
    }
  }

  public calculaTotais(pagamento: IJsonPagamento): void {
    pagamento.cab.totalDescontos = 0;
    pagamento.cab.totalPagar = 0;
    pagamento.cab.totalRetencao = 0;
    for (const linha of pagamento.linhas) {
      if (linha.valorAPagar) {
        if (linha.valorAPagar > 0) {
          pagamento.cab.totalRetencao = round(pagamento.cab.totalRetencao + linha.montanteRetido, this._configService.configurations.contabilidade.decimais.valor);
        } else {
          pagamento.cab.totalRetencao = round(pagamento.cab.totalRetencao - linha.montanteRetido, this._configService.configurations.contabilidade.decimais.valor);
        }
        // atribui total a pagar
        pagamento.cab.totalPagar = round(pagamento.cab.totalPagar + linha.valorAPagar, this._configService.configurations.contabilidade.decimais.valor);
        if (linha.percDesconto) {
          // atribui total desconto
          pagamento.cab.totalDescontos = round(pagamento.cab.totalDescontos + linha.valorDesconto, this._configService.configurations.contabilidade.decimais.valor);
        }
      }
    }
    pagamento.cab.totalRetencao = Math.abs(pagamento.cab.totalRetencao);
    pagamento.cab.total = round(pagamento.cab.totalPagar - pagamento.cab.totalDescontos - pagamento.cab.totalRetencao, this._configService.configurations.contabilidade.decimais.valor);
  }

  public loadLinhas(pagamento: IJsonPagamento): Promise<IJsonPagamento> {
    return new Promise<IJsonPagamento>((resolve, reject) => {
      this.post(1, 0, 0, pagamento)
        .then((response) => {
          pagamento.percDescSobreValorIVA = response.body.percDescSobreValorIVA || false;
          pagamento.cab = response.body.cab;
          pagamento.linhas = response.body.linhas;
          pagamento.linhas.forEach((item, index) => {
            pagamento.linhas[index] = {...item, aPagar: 0};
          });
          this.atribuiMontanteRetencao(pagamento);
          this.calculaTotais(pagamento);

          resolve(pagamento);
        })
        .catch((error: unknown) => {
          reject(error);
        });
    });
  }

  public validaDados(pagamento: IJsonPagamento): Array<ICGExceptionErrorField> {
    const erros: Array<ICGExceptionErrorField> = [];

    if (isUndefined(pagamento.cab) || !pagamento.cab.nConta) {
      erros.push({fieldname: 'nConta', message: 'O cliente não está preenchido.'});
    }
    if (isUndefined(pagamento.cab.data)) {
      erros.push({fieldname: 'data', message: 'Tem de definir uma data de pagamento'});
    }

    let valRecDefinido = false;
    for (let i = 0; i < pagamento.linhas.length; i++) {
      const linha = pagamento.linhas[i];
      if (Math.abs(linha.valorAPagar) > Math.abs(linha.valorPorPagar)) {
        erros.push({
          fieldname: 'error',
          message: `O valor a pagar na linha nº${i + 1} é superior ao valor do documento "${linha.numeroDocumento}"`
        });
      }
      if (Math.abs(linha.valorAPagar) !== 0) {
        if (linha.valorAPagar > 0 && linha.valorPorPagar < 0) {
          erros.push({
            fieldname: 'error',
            message: `O valor a pagar na linha nº${i + 1} do documento "${linha.numeroDocumento}" tem de ser negativo por se tratar de uma notaCaption de crédito.`
          });
        } else if (linha.valorAPagar < 0 && linha.valorPorPagar > 0) {
          erros.push({
            fieldname: 'error',
            message: `O valor a pagar na linha nº${i + 1} do documento "${linha.numeroDocumento}" tem de ser positivo.`
          });
        }
        valRecDefinido = true;
      }
      if (Math.abs(linha.percDesconto) !== 0) {
        if (linha.percDesconto < 0 || linha.percDesconto > 100) {
          erros.push({
            fieldname: 'error',
            message: `A percentagem de desconto na linha nº${i + 1} do documento "${linha.numeroDocumento}" tem de ter um valor entre 0 e 100.`
          });
        }
      }
      if (Math.abs(linha.valorDesconto) !== 0) {
        if (linha.valorDesconto > linha.valorAPagar) {
          erros.push({
            fieldname: 'error',
            message: `O valor de desconto na linha nº${i + 1} do documento "${linha.numeroDocumento}" é superior ao valor a pagar.`
          });
        }
      }
    }
    if (!valRecDefinido) {
      erros.push({fieldname: 'error', message: 'Não definiu nenhum valor a pagar.'});
    }

    return erros;
  }

  public async getPdf(params: IPagamentoImprimirModalParams): Promise<void> {
    if (!isObject(params) || isEmpty(params.extPocCabID) || isEmpty(params.nConta)) {
      this._plAlertService.error('pagamentos.erros.notselected');
      return Promise.resolve();
    }
    if (params.nConta) {
      const response: THttpQueryResponse<IJsonClifoExtraValue> = await this._definicaoEmailService.queryByNContaTipo(params.nConta, EClifoExtraValueTipo.EmailPagamentosFornecedor);
      const emails: string = response.body.list.map((clifoExtraValue: IJsonClifoExtraValue) => clifoExtraValue.valor).join(';');
      if (emails) {
        params = {...params, email: emails};
      }
    }
    const modalInstance = this._cgModalService.showVanilla(PagamentoImprimirModalComponent);
    const componentInstance: PagamentoImprimirModalComponent = modalInstance.componentInstance;
    componentInstance.params = params;
    componentInstance.pagamentosService = this;
    return modalInstance.result;
  }

  public getPdfUrl(extPocCabID: string, nomereport: string, nconta: string, nvias: number, ano: number): Observable<string> {
    const params = ano === 0 ? {nomereport: nomereport, nconta: nconta, nvias: nvias} : {nomereport: nomereport, nconta: nconta, nvias: nvias, ano: ano};
    return buildSessionUrlWithParams(`${this._apiService.path.restapi}/${this._entityNamePagamentos}/${extPocCabID}/pdf`, params);
  }

  public enviaPdfPorEmail(extPocCabID: string, nconta: string, email: string, ano: number): TServiceResponse<void> {
    return this._servicePagamentos.sendEmail(extPocCabID, nconta, email, ano);
  }

  public anular(extPocCabID: string): TServiceResponse<void> {
    return this._servicePagamentos.delete({url: `${extPocCabID}/anular`});
  }

  public saveModal(pagamento: IJsonPagamento, saveFn: TPagamentosSaveModalSaveFn, meioPagamentos: Array<IJsonPagamentoMeioPagamento>, abreviaturaMoeda: string): Promise<IPagamentosSaveModalResult> {
    const modalInstance = this._cgModalService.showVanilla(PagamentosSaveModalComponent, {size: 'lg'});
    const componentInstance: PagamentosSaveModalComponent = modalInstance.componentInstance;
    componentInstance.pagamento = pagamento;
    componentInstance.saveFn = saveFn;
    componentInstance.meioPagamentos = meioPagamentos;
    componentInstance.abreviaturaMoeda = abreviaturaMoeda;
    return modalInstance.result;
  }

  public distribuiValorModal(): Promise<IPagamentoDistribuirValor> {
    return this._cgModalService.show(PagamentosDistribuiValorModalComponent, {size: 'md'});
  }

  public post(loadlinhas: number, loteauto: number, optionsepa: number, pagamento: IJsonPagamento): TServiceResponse<IJsonPagamento> {
    return this._servicePagamentos.post({
      params: {
        loadlinhas: loadlinhas,
        loteauto: loteauto,
        optionsepa: optionsepa
      },
      body: pagamento
    });
  }

  public getEmailsToSend(trfbaidlist: string): TServiceResponse<IJsonPagamentoEmail> {
    return this._apiService.get<IJsonPagamentoEmail>({
      url: `${this._path}/emailstosend`,
      params: {
        trfbaids: trfbaidlist
      }
    });
  }

  public sendEMail(mailContent: IJsonPagamentoEmail): TServiceResponse<IJsonPagamentoEmail> {
    return this._apiService.post<IJsonPagamentoEmail>({
      url: `${this._path}/sendmail`,
      body: mailContent
    });
  }

  public getPagamentosNoFicheiroSEPA(
    extPocCabID: string,
    search: string = '',
    order: string = '',
    page: number = -1,
    perPage: number = -1,
    searchFields: string = ''
  ): TServiceQueryResponse<IJsonPagamento> {
    return this._apiService.get({
      url: `${this._path}/${extPocCabID}/pagamentosficheirosepa`,
      params: {
        pagina: page,
        porpagina: perPage,
        pesquisa: search,
        ordena: order,
        campospesq: searchFields
      }
    });
  }
}
