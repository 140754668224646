import {NgModule} from '@angular/core';
import {CGCIconComponent} from './component/icon.component';
import {CGCIconDirective} from './directive/icon.directive';

const DECLARATIONS = [CGCIconComponent, CGCIconDirective];

@NgModule({
  imports: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlIconModule {}
