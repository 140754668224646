import moment, {Moment} from 'moment';
import {firstValueFrom, Observable, Subject} from 'rxjs';
import {take} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {isArray, isBoolean, isEmpty, isNumber, isObject, Logger, PlAlertService} from 'pl-comps-angular';
import {ApiService} from '../../../../services/api/api.service';
import {buildSessionUrlWithParams, minDateCG} from '../../../../../common/utils/utils';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ConfigService} from '../../../../services/config/config.service';
import {DocContabilidadeService} from '../components/doccontabilidade/docContabilidade.service';
import {ECampoCalculadoME, EDocContabilidadeOrigem, IJsonContaAnalitica, IJsonDocContabilidade, IJsonDocContabilidadeLinha, IJsonRegularizacaoCampo40} from '../jsonDocContabilidade.interface';
import {EClassificacaoControlo, EClassificacaoControloRetencao} from '../../../../../common/enums/contabilidade.enums';
import {EDebitoCredito} from '../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {
  EDocContabilidadeCommandType,
  IDocContabilidade,
  IDocContabilidadeException,
  IDocContabilidadeLinha,
  TDocContabilidadeCommandRawResult,
  TDocContabilidadeCommandResult
} from '../docsContabilidade.interface';
import {
  EDocContabilidadeException,
  IDocContabilidadePropertyNameOriginalData,
  IDocContabilidadePropertyNameOriginalValidateData,
  IDocContabilidadeValidateAddValorTaxaParams
} from '../components/doccontabilidade/docContabilidade.interface';
import {EMPTY_GUID} from '../../../../../config/constants';
import {ICGConfigurations} from '../../../../services/config/config.service.interface';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {IJsonDocDigitalizado, IJsonDocOCR} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {IJsonMovimentosEmAberto} from '../../../../interfaces/jsonMovimentosEmAberto.interface';
import {IJsonPreDefinidoContab} from '../../manutencao/predefinidos/jsonPreDefinidosContab.entity.interface';
import {
  IRestCommand,
  IRestCommandCodMoedaChange,
  IRestCommandContribuinteChange,
  IRestCommandDataDocChange,
  IRestCommandDataLancamentoChange,
  IRestCommandDataVencimentoChange,
  IRestCommandDescricaoChange,
  IRestCommandFirstDoc,
  IRestCommandInitDocumento,
  IRestCommandLastDoc,
  IRestCommandLinha,
  IRestCommandLinhaAdicionarContasAnalitica,
  IRestCommandLinhaAdicionarRegularizacaoCampo40,
  IRestCommandLinhaCambioChange,
  IRestCommandLinhaContaChange,
  IRestCommandLinhaContribuinteChange,
  IRestCommandLinhaDataDocChange,
  IRestCommandLinhaEfetuarRetencao,
  IRestCommandLinhaGenerateContaAnalitica,
  IRestCommandLinhaImputaValores,
  IRestCommandLinhaObterMovAb,
  IRestCommandLinhaValorChange,
  IRestCommandLinhaValorIvaChange,
  IRestCommandLinhaValorMEChange,
  IRestCommandNDescritivoChange,
  IRestCommandNDocExternoChange,
  IRestCommandNextDoc,
  IRestCommandNovoByDocOCRCabID,
  IRestCommandNovoByPredefinido,
  IRestCommandNovoByPredefinidoID,
  IRestCommandNovoDocumento,
  IRestCommandPerguntaCC,
  IRestCommandPreviousDoc,
  IRestCommandRegistaNif,
  IRestCommandSimulaGravacao,
  TJsonCommandResponse
} from '../restDocsContabilidadeCommands.interface';
import {PesquisaDocsLinhaService} from '../../../../components/contabilidade/pesquisaDocsLinha/pesquisaDocsLinha.service';
import {TDate} from '../../../../../common/dates';
import {TServiceResponse} from '../../../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class DocsContabilidadeService implements OnDestroy {
  private readonly _path: string;
  private readonly _subjectExceptions: Subject<IDocContabilidadeException>;
  private readonly _cachePodeUltrapassarToleranciaIVA: Map<number, boolean>;
  private _observableExceptions: Observable<IDocContabilidadeException>;

  constructor(
    private readonly _logger: Logger,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _apiService: ApiService,
    private readonly _configService: ConfigService,
    private readonly _docContabilidadeService: DocContabilidadeService,
    private readonly _pesquisaDocsLinhaService: PesquisaDocsLinhaService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    this._path = `${this._apiService.path.restapi}/docscontabilidade`;
    this._subjectExceptions = new Subject<IDocContabilidadeException>();
    this._cachePodeUltrapassarToleranciaIVA = new Map<number, boolean>();
  }

  public ngOnDestroy(): void {
    this._subjectExceptions.complete();
  }

  public onException(): Observable<IDocContabilidadeException> {
    if (!this._observableExceptions) {
      this._observableExceptions = this._subjectExceptions.asObservable();
    }
    return this._observableExceptions;
  }

  public novoDocumento(periodo: string, nDiario: number, docOCRCabID: string, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const novoDocumentoCommand: IRestCommandNovoDocumento = {
      periodo: periodo,
      nDiario: nDiario,
      docOCRCabID: docOCRCabID,
      jsonDoc: doc
    };
    doc.linhas.length = 0;
    return this._newCommand(EDocContabilidadeCommandType.NovoDocumento, novoDocumentoCommand);
  }

  public novoByPredefinidoID(preDefinidoId: number, docOCRCabID: string, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const novoByPredefinidoIDCommand: IRestCommandNovoByPredefinidoID = {
      preDefinidoId: preDefinidoId,
      docOCRCabID: docOCRCabID,
      useDataDoc: moment(doc.dataDoc).isAfter(minDateCG(), 'date'),
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.NovoByPredefinidoID, novoByPredefinidoIDCommand);
  }

  public novoByPredefinido(
    preDefinido: IJsonPreDefinidoContab,
    docOCRCabID: string,
    doc: IDocContabilidade,
    simulation: boolean = false
  ): TDocContabilidadeCommandRawResult<IRestCommandNovoByPredefinido> {
    const novoByPredefinidoCommand: IRestCommandNovoByPredefinido = {
      preDefinido: preDefinido,
      docOCRCabID: docOCRCabID,
      useDataDoc: !simulation && moment(doc.dataDoc).isAfter(minDateCG(), 'date'),
      jsonDoc: doc
    };
    return this._newCommandRaw<IRestCommandNovoByPredefinido>(EDocContabilidadeCommandType.NovoByPredefinido, novoByPredefinidoCommand);
  }

  public documentoExternoChanged(nDocumentoExterno: string, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const documentoExternoChangedCommand: IRestCommandNDocExternoChange = {
      nDocExterno: nDocumentoExterno,
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.NDocExternoChange, documentoExternoChangedCommand);
  }

  public descricaoChanged(descricao: string, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const descricaoChangedCommand: IRestCommandDescricaoChange = {
      descricao: descricao,
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.DescricaoChange, descricaoChangedCommand);
  }

  public registaNif(nif: string): TDocContabilidadeCommandResult {
    const registaNifCommand: IRestCommandRegistaNif = {
      nif: nif,
      jsonDoc: undefined
    };
    return this._newCommand(EDocContabilidadeCommandType.RegistaNif, registaNifCommand);
  }

  public perguntaCC(nConta: string, dc: EDebitoCredito, value: boolean): TDocContabilidadeCommandResult {
    const perguntaCCCommand: IRestCommandPerguntaCC = {
      nConta: nConta,
      dc: dc,
      value: value,
      jsonDoc: undefined
    };
    return this._newCommand(EDocContabilidadeCommandType.PerguntaCC, perguntaCCCommand);
  }

  public contribuinteChanged(nContribuinte: string, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const contribuinteChangedCommand: IRestCommandContribuinteChange = {
      nContribuinte: nContribuinte,
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.ContribuinteChange, contribuinteChangedCommand);
  }

  public descritivoChanged(nDescritivo: number, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const nDescritivoChangedCommand: IRestCommandNDescritivoChange = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      NDescritivo: nDescritivo,
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.NDescritivoChange, nDescritivoChangedCommand);
  }

  public moedaChanged(codMoeda: number, nomeMoeda: string, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const codMoedaChangedCommand: IRestCommandCodMoedaChange = {
      codMoeda: codMoeda,
      nomeMoeda: nomeMoeda,
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.CodMoedaChange, codMoedaChangedCommand);
  }

  public dataLancamentoChanged(dataLancamento: Moment, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const dataDocChangedCommand: IRestCommandDataLancamentoChange = {
      dataLancamento: dataLancamento,
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.DataLancamentoChange, dataDocChangedCommand);
  }

  public dataVencimentoChanged(dataVencimento: Moment, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const dataVencimentoChangedCommand: IRestCommandDataVencimentoChange = {
      dataVencimento: dataVencimento,
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.DataVencimentoChange, dataVencimentoChangedCommand);
  }

  public dataDocChanged(dataDoc: Moment, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const dataDocChangedCommand: IRestCommandDataDocChange = {
      dataDoc: dataDoc,
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.DataDocChange, dataDocChangedCommand);
  }

  public initDocumento(doc: IDocContabilidade, contabilidadeDigital: boolean = false): TDocContabilidadeCommandResult {
    const initDocumentoCommand: IRestCommandInitDocumento = {
      contabilidadeDigital: contabilidadeDigital,
      jsonDoc: doc
    };
    doc.linhas.length = 0;
    return this._newCommand(EDocContabilidadeCommandType.InitDocumento, initDocumentoCommand);
  }

  public nextDoc(doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const nextDocCommand: IRestCommandNextDoc = {
      nDocInterno: doc.nDocInterno,
      periodo: doc.periodo,
      nDiario: doc.nDiario,
      nDocSeq: doc.nDocSeq,
      extPocCabID: doc.extPocCabID,
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.NextDoc, nextDocCommand);
  }

  public previousDoc(doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const previousDocCommand: IRestCommandPreviousDoc = {
      nDocInterno: doc.nDocInterno,
      periodo: doc.periodo,
      nDiario: doc.nDiario,
      nDocSeq: doc.nDocSeq,
      extPocCabID: doc.extPocCabID,
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.PreviousDoc, previousDocCommand);
  }

  public firstDoc(doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const firstDocCommand: IRestCommandFirstDoc = {
      nDocInterno: doc.nDocInterno,
      periodo: doc.periodo,
      nDiario: doc.nDiario,
      nDocSeq: doc.nDocSeq,
      extPocCabID: doc.extPocCabID,
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.FirstDoc, firstDocCommand);
  }

  public lastDoc(doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const lastDocCommand: IRestCommandLastDoc = {
      nDocInterno: doc.nDocInterno,
      periodo: doc.periodo,
      nDiario: doc.nDiario,
      nDocSeq: doc.nDocSeq,
      extPocCabID: doc.extPocCabID,
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.LastDoc, lastDocCommand);
  }

  public novoByDocOCRCabID(docOCRCabID: string, doc: IDocContabilidade, changedFolder: boolean, ignorePredefinido: boolean = false, periodo: string = ''): TDocContabilidadeCommandResult {
    const novoByDocOCRCabIDCommand: IRestCommandNovoByDocOCRCabID = {
      docOCRCabID: docOCRCabID,
      periodo: periodo,
      changedFolder: changedFolder,
      ignorePredefinido: ignorePredefinido,
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.NovoByDocOCRCabID, novoByDocOCRCabIDCommand);
  }

  public linhaContaChanged(nConta: string, dc: EDebitoCredito, indiceLinha: number, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const linhaContaChangedCommand: IRestCommandLinhaContaChange = {
      nConta: nConta,
      dc: dc,
      indiceLinha: indiceLinha,
      jsonDoc: doc
    };
    return this._newCommandLinha(EDocContabilidadeCommandType.LinhaContaChange, linhaContaChangedCommand);
  }

  public linhaValorChanged(valor: number, valorComIvaIncluido: boolean, indiceLinha: number, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const linhaValorChangedCommand: IRestCommandLinhaValorChange = {
      valor: valor,
      valorComIvaIncluido: valorComIvaIncluido,
      indiceLinha: indiceLinha,
      jsonDoc: doc
    };
    return this._newCommandLinha(EDocContabilidadeCommandType.LinhaValorChange, linhaValorChangedCommand);
  }

  public obterSaldoConta(indiceLinha: number, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const linhaObterSaldoContaCommand: IRestCommandLinha = {
      indiceLinha: indiceLinha,
      jsonDoc: doc
    };
    return this._newCommandLinha(EDocContabilidadeCommandType.LinhaObterSaldoConta, linhaObterSaldoContaCommand, false);
  }

  public linhaContribuinteChanged(nContribuinte: string, indiceLinha: number, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const linhaContribuinteChangedCommand: IRestCommandLinhaContribuinteChange = {
      nContribuinte: nContribuinte,
      indiceLinha: indiceLinha,
      jsonDoc: doc
    };
    return this._newCommandLinha(EDocContabilidadeCommandType.LinhaContribuinteChange, linhaContribuinteChangedCommand, false);
  }

  public linhaValorIvaChanged(valorIva: number, indiceLinha: number, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const linhaValorIvaChangedCommand: IRestCommandLinhaValorIvaChange = {
      valorIva: valorIva,
      indiceLinha: indiceLinha,
      jsonDoc: doc
    };
    return this._newCommandLinha(EDocContabilidadeCommandType.LinhaValorIvaChange, linhaValorIvaChangedCommand);
  }

  public linhaDataDocChanged(dataDoc: TDate, indiceLinha: number, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const linhaDataDocChangedCommand: IRestCommandLinhaDataDocChange = {
      dataDoc: dataDoc,
      indiceLinha: indiceLinha,
      jsonDoc: doc
    };
    return this._newCommandLinha(EDocContabilidadeCommandType.LinhaDataDocChange, linhaDataDocChangedCommand);
  }

  public linhaImputaValores(movImputList: Array<IJsonMovimentosEmAberto>, indiceLinha: number, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const linhaImputaValoresCommand: IRestCommandLinhaImputaValores = {
      movImputList: movImputList,
      indiceLinha: indiceLinha,
      jsonDoc: doc
    };
    return this._newCommandLinha(EDocContabilidadeCommandType.LinhaImputaValores, linhaImputaValoresCommand);
  }

  public linhaObterMovAb(movImputList: Array<IJsonMovimentosEmAberto>, indiceLinha: number, doc: IDocContabilidade): TDocContabilidadeCommandRawResult<IRestCommandLinhaObterMovAb> {
    const linhaObterMovAbCommand: IRestCommandLinhaObterMovAb = {
      indiceLinha: indiceLinha,
      movImputList: movImputList,
      jsonDoc: doc
    };
    return this._newCommandLinhaRaw<IRestCommandLinhaObterMovAb>(EDocContabilidadeCommandType.LinhaObterMovAb, linhaObterMovAbCommand);
  }

  public linhaAdicionarContasAnalitica(listaContasAnalitica: Array<IJsonContaAnalitica>, indiceLinha: number, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const linhaAdicionarContasAnaliticaCommand: IRestCommandLinhaAdicionarContasAnalitica = {
      listaContasAnalitica: listaContasAnalitica,
      indiceLinha: indiceLinha,
      jsonDoc: doc
    };
    return this._newCommandLinha(EDocContabilidadeCommandType.LinhaAdicionarContasAnalitica, linhaAdicionarContasAnaliticaCommand);
  }

  public linhaAdicionarRegularizacaoCampo40(listaRegularizacaoCampo40: Array<IJsonRegularizacaoCampo40>, indiceLinha: number, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const linhaAdicionarRegularizacaoCampo40Command: IRestCommandLinhaAdicionarRegularizacaoCampo40 = {
      listaRegularizacaoCampo40: listaRegularizacaoCampo40,
      indiceLinha: indiceLinha,
      jsonDoc: doc
    };
    return this._newCommandLinha(EDocContabilidadeCommandType.LinhaAdicionarRegularizacaoCampo40, linhaAdicionarRegularizacaoCampo40Command);
  }

  public linhaEfetuarRetencao(
    dispoRendimento: number,
    codRet: string,
    contaCorrenteTemRetencaoDeduzida: boolean,
    valorTributavel: number,
    valorRetido: number,
    indiceLinha: number,
    doc: IDocContabilidade,
    numeroGuiaPagamento?: string
  ): TDocContabilidadeCommandResult {
    const linhaEfetuarRetencaoCommand: IRestCommandLinhaEfetuarRetencao = {
      dispoRendimento: dispoRendimento,
      codRet: codRet,
      contaCorrenteTemRetencaoDeduzida: contaCorrenteTemRetencaoDeduzida,
      valorTributavel: valorTributavel,
      valorRetido: valorRetido,
      indiceLinha: indiceLinha,
      jsonDoc: doc,
      numeroGuiaPagamento: numeroGuiaPagamento
    };
    return this._newCommandLinha(EDocContabilidadeCommandType.LinhaEfetuarRetencao, linhaEfetuarRetencaoCommand);
  }

  public linhaGenerateContaAnalitica(
    indiceLinha: number,
    doc: IDocContabilidade,
    manalID: number,
    cCusto: string,
    zona: number,
    departamento: number,
    subDepartamento: string,
    familia: number,
    codContab: string,
    tipoArtigo: number,
    grandeFamilia: number,
    subFamilia: number,
    classe: number,
    categoria: number,
    vendedor: number,
    nProcesso: string,
    contaGerada: string
  ): Promise<IRestCommandLinhaGenerateContaAnalitica> {
    const linhaGenerateContaAnaliticaCommand: IRestCommandLinhaGenerateContaAnalitica = {
      indiceLinha: indiceLinha,
      jsonDoc: doc,
      manalID: manalID,
      cCusto: cCusto,
      zona: zona,
      departamento: departamento,
      subDepartamento: subDepartamento,
      familia: familia,
      codContab: codContab,
      tipoArtigo: tipoArtigo,
      grandeFamilia: grandeFamilia,
      subFamilia: subFamilia,
      classe: classe,
      categoria: categoria,
      vendedor: vendedor,
      nProcesso: nProcesso,
      contaGerada: contaGerada
    };

    return this.sendCommand<IRestCommandLinhaGenerateContaAnalitica>(doc.extPocCabID, EDocContabilidadeCommandType.LinhaGenerateContaAnalitica, linhaGenerateContaAnaliticaCommand).then(
      (response: HttpResponse<IRestCommandLinhaGenerateContaAnalitica>) => response.body
    );
  }

  public linhaDelete(indiceLinha: number, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const linhaDeleteCommand: IRestCommandLinha = {
      indiceLinha: indiceLinha,
      jsonDoc: doc
    };
    return this._newCommandLinha(EDocContabilidadeCommandType.LinhaDelete, linhaDeleteCommand);
  }

  public addLinha(doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const addLinhaCommand: IRestCommand = {
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.AddLinha, addLinhaCommand);
  }

  public simulaGravacao(origem: EDocContabilidadeOrigem, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const simulaGravacaoCommand: IRestCommandSimulaGravacao = {
      jsonDoc: doc,
      origem: origem
    };
    return this._newCommand(EDocContabilidadeCommandType.SimulaGravacao, simulaGravacaoCommand);
  }

  public linhaValorMEChanged(valorME: number, valorComIvaIncluido: boolean, indiceLinha: number, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const linhaValorMECommand: IRestCommandLinhaValorMEChange = {
      valorME: valorME,
      valorComIvaIncluido: valorComIvaIncluido,
      indiceLinha: indiceLinha,
      jsonDoc: doc
    };
    return this._newCommandLinha(EDocContabilidadeCommandType.LinhaValorMEChange, linhaValorMECommand);
  }

  public linhaCambioChanged(cambio: number, valorComIvaIncluido: boolean, indiceLinha: number, doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const linhaCambioCommand: IRestCommandLinhaCambioChange = {
      cambio: cambio,
      valorComIvaIncluido: valorComIvaIncluido,
      indiceLinha: indiceLinha,
      jsonDoc: doc
    };
    return this._newCommandLinha(EDocContabilidadeCommandType.LinhaCambioChange, linhaCambioCommand);
  }

  public anularDiferimento(doc: IDocContabilidade): TDocContabilidadeCommandResult {
    const anularDiferimentoCommand: IRestCommand = {
      jsonDoc: doc
    };
    return this._newCommand(EDocContabilidadeCommandType.AnularDiferimento, anularDiferimentoCommand);
  }

  public getPdfUrl(extPocCabID: string, periodo: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/${extPocCabID}/pdf`, {periodo: periodo});
  }

  public sendCommand<T = IRestCommand>(id: string, commandName: string, command: IRestCommand): TServiceResponse<T> {
    return this._apiService.patch<TJsonCommandResponse<T>, IRestCommand>({url: `${this._path}/${id}`, body: command, params: {command: commandName}});
  }

  public sendEmail(extPocCabID: string, email: string, periodo: string): TServiceResponse<IJsonDocContabilidade> {
    return this._apiService.get({url: `${this._path}/${extPocCabID}/email`, params: {email: email, periodo: periodo}});
  }

  public podeUltrapassarToleranciaIVA(nDiario: number): Promise<boolean> {
    const result: boolean = this._cachePodeUltrapassarToleranciaIVA.get(nDiario);
    if (isBoolean(result)) {
      return Promise.resolve(result);
    }
    return this._apiService.get({url: `${this._path}/podeultrapassartoleranciaiva`, params: {nDiario: nDiario}}).then((response: HttpResponse<boolean>) => {
      this._cachePodeUltrapassarToleranciaIVA.set(nDiario, response.body);
      return response.body;
    });
  }

  public patchDocumentoDeAtivos(doc: IDocContabilidade): TServiceResponse<boolean> {
    return this._apiService.patch<boolean, IDocContabilidade>({
      url: `${this._path}/documentodeativos`,
      body: doc
    });
  }

  public getDocsDigitaisFolder(extPocCabID: string, ano: number = -1): TServiceResponse<string> {
    return this._apiService.get<string>({
      url: `${this._path}/${extPocCabID}/digitaldocsfolder`,
      params: {ano: ano}
    });
  }

  public getDocsDigitais(extPocCabID: string, ano: number = -1): TServiceResponse<Array<IJsonDocOCR>> {
    return this._apiService.get<Array<IJsonDocOCR>>({
      url: `${this._path}/${extPocCabID}/digitaldocs`,
      params: {ano: ano}
    });
  }

  public anexarDocDigitalExistente(extPocCabID: string, gDocId: string): TServiceResponse<IJsonDocDigitalizado> {
    return this._apiService.post<IJsonDocDigitalizado>({url: `${this._path}/${extPocCabID}/anexardocdigitalexistente`, params: {gDocId: gDocId}});
  }

  public mudarPeriodoEmpresa(periodo: string): TServiceResponse<void> {
    return this._apiService.post<void, string>({url: `${this._path}/mudarperiodoempresa`, body: periodo});
  }

  public obterValorADiferirDoDocumento(doc: IDocContabilidade): TServiceResponse<number> {
    return this._apiService.post<number, IDocContabilidade>({url: `${this._path}/obtervalordiferimento`, body: doc});
  }

  public anexarNovoDocDigital(extPocCabID: string, gDocFolderId: string, file: File): TServiceResponse<IJsonDocDigitalizado> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this._apiService.post<IJsonDocDigitalizado, FormData>({
      url: `${this._path}/${extPocCabID}/anexardocdigitalnaoexistente`,
      body: formData,
      params: {gDocFolderId: gDocFolderId}
    });
  }

  public deleteDocContabilidade(docContabilidade: IJsonDocContabilidade): TServiceResponse<void> {
    const title: string = this._translateService.instant('entity.delete.title', {id: docContabilidade.nDocumento});
    return this._cgModalService.showOkCancel(title, 'entity.delete.message').then(() => {
      return this._apiService.delete<void>({url: `${this._path}/${docContabilidade.extPocCabID}`}).then((response: HttpResponse<void>) => {
        this._plAlertService.success('docscontabilidade.documentoEliminadoComSucesso');
        return response;
      });
    });
  }

  public validateAddValorTaxa({docContabilidadeLinha, value, alertTitle}: IDocContabilidadeValidateAddValorTaxaParams): Promise<boolean> {
    return Promise.all([this.podeUltrapassarToleranciaIVA(docContabilidadeLinha.nDiario), firstValueFrom(this._configService.configurationsAsObservable().pipe(take(1)))]).then(
      ([podeUltrapassarIVA, configurations]: [boolean, ICGConfigurations]) => {
        if (podeUltrapassarIVA) {
          return true;
        }
        const propertyData: IDocContabilidadePropertyNameOriginalData<number> = this._docContabilidadeService.initPropertyNameOriginal(docContabilidadeLinha, 'valorTaxa');
        const validateData: IDocContabilidadePropertyNameOriginalValidateData = propertyData.validate(value, configurations.contabilidade.diferencaIva);
        if (alertTitle && !validateData.valid) {
          this._plAlertService.error(
            this._translateService.instant('docscontabilidade.valorValidatorMessage', {
              type: String(this._translateService.instant(alertTitle)).toLowerCase(),
              value: configurations.contabilidade.diferencaIva,
              currency: configurations.contabilidade.moeda
            })
          );
        }
        return validateData.valid;
      }
    );
  }

  public async initLinhaImputada(linha: IDocContabilidadeLinha): Promise<void> {
    const response: HttpResponse<IJsonDocContabilidadeLinha> = await this._pesquisaDocsLinhaService.getLinhaImputada(linha.nLancImput);
    const linhaImputada: IJsonDocContabilidadeLinha = response.body;
    linha._extPocCabImput = linhaImputada.extPocCabID;
    linha._nDocImput = linhaImputada.periodo && linhaImputada.nDiario && linhaImputada.nDocInterno ? `${linhaImputada.periodo}.${linhaImputada.nDiario}.${linhaImputada.nDocInterno?.trim()}` : '';
  }

  public async getDocsPagamentoLinha(linha: IDocContabilidadeLinha): Promise<void> {
    const response: HttpResponse<Array<IDocContabilidadeLinha>> = await this._pesquisaDocsLinhaService.getDocsPagamentos(linha.nLanc);
    linha._docsPagamentoLinha = response.body;
    for (const item of linha._docsPagamentoLinha) {
      item._extPocCabImput = item.extPocCabID;
      item._nDocImput = `${item.periodo}.${item.nDiario}.${item.nDocInterno?.trim()}`;
    }
  }

  public handleCommandResponse(doc: IDocContabilidade, calculaTotais: boolean = true, calculaTotaisGeral: boolean = true, commandLinha: boolean = false): void {
    if (doc.nDocInterno) {
      doc.nDocInterno = doc.nDocInterno.trim();
    }

    // Correções entidades
    if (doc.nomeDescritivo && !isNumber(doc.codDescritivo)) {
      doc.codDescritivo = 0;
    }
    if (doc.nomeDiario && !isNumber(doc.nDiario)) {
      doc.nDiario = 0;
    }
    if (doc.nomeMoeda && !isNumber(doc.codMoeda)) {
      doc.codMoeda = 0;
    }
    if (doc.isUsingPreDefinido && isObject(doc.predefinido) && !isNumber(doc.predefinido.preDefinidosID)) {
      doc.predefinido.preDefinidosID = 0;
    }

    doc._hasLinhasImputadas = false;

    // Reindexar
    if (isArray(doc.linhas)) {
      for (let i = doc.linhas.length - 1; i >= 0; i--) {
        const linha: IDocContabilidadeLinha = doc.linhas[i];
        if (commandLinha && (!doc.isUsingPreDefinido || !doc.predefinido) && this._docContabilidadeService.isLineEmpty(linha)) {
          doc.linhas.splice(i, 1);
          continue;
        }
        linha._index = i;
        this._handleCommandLinhaResponse(doc, linha);
      }
    } else {
      doc.linhas = [];
    }
    doc._invalidDataLancamento = false;
    doc._invalidNif = false;
    if (calculaTotais) {
      this._docContabilidadeService.calculaTotais(doc, calculaTotaisGeral);
    }
  }

  protected _newCommand(commandName: EDocContabilidadeCommandType, command: IRestCommand, calculaTotais: boolean = true, calculaTotaisGeral: boolean = true): TDocContabilidadeCommandResult {
    return this._newCommandRaw<IRestCommand>(commandName, command, calculaTotais, calculaTotaisGeral).then((response: IRestCommand) => response.jsonDoc);
  }

  protected _newCommandLinha(commandName: EDocContabilidadeCommandType, command: IRestCommandLinha, calculaTotais: boolean = true, calculaTotaisGeral: boolean = true): TDocContabilidadeCommandResult {
    return this._newCommandLinhaRaw<IRestCommandLinha>(commandName, command, calculaTotais, calculaTotaisGeral).then((response: IRestCommandLinha) => response.jsonDoc);
  }

  protected _newCommandRaw<T extends IRestCommand>(
    commandName: EDocContabilidadeCommandType,
    command: T,
    calculaTotais: boolean = true,
    calculaTotaisGeral: boolean = true,
    commandLinha: boolean = false
  ): TDocContabilidadeCommandRawResult<T> {
    return new Promise<T>((resolve, reject) => {
      this.sendCommand<T>(command.jsonDoc?.extPocCabID || EMPTY_GUID, commandName, command)
        .then((response: HttpResponse<T>) => {
          this.handleCommandResponse(response.body.jsonDoc, calculaTotais, calculaTotaisGeral, commandLinha);
          resolve(response.body);
        })
        .catch((reason: HttpErrorResponse) => {
          this._logger.error(reason);
          this.handleCommandResponse(command.jsonDoc, calculaTotais, calculaTotaisGeral, false);
          const exception: ICGExceptionError = this._handleCommandResponseErrors(reason, command.jsonDoc);
          const indiceLinha: number = (<IRestCommandLinha>(<unknown>command)).indiceLinha;
          const docContabilidadeException: IDocContabilidadeException = {
            class: exception.class,
            message: exception.message,
            status: exception.status,
            fields: exception.fields,
            docContabilidade: command.jsonDoc,
            linhaNLanc: isNumber(indiceLinha) ? command.jsonDoc.linhas[indiceLinha]?.nLanc : undefined
          };
          reject(docContabilidadeException);
          this._subjectExceptions.next(docContabilidadeException);
        });
    });
  }

  protected _newCommandLinhaRaw<T extends IRestCommandLinha>(
    commandName: EDocContabilidadeCommandType,
    command: T,
    calculaTotais: boolean = true,
    calculaTotaisGeral: boolean = true
  ): TDocContabilidadeCommandRawResult<T> {
    return new Promise<T>((resolve, reject) => {
      this._newCommandRaw<T>(commandName, command, calculaTotais, calculaTotaisGeral, true)
        .then((response: T) => {
          resolve(response);
        })
        .catch((reason: IDocContabilidadeException) => {
          this._logger.error(reason);
          this._handleCommandLinhaResponseErrors(reason, command.jsonDoc.linhas[command.indiceLinha]);
          reject(reason);
        });
    });
  }

  protected _handleCommandLinhaResponse(doc: IDocContabilidade, linha: IDocContabilidadeLinha): void {
    if (!isNumber(linha.classificControlo)) {
      linha.classificControlo = EClassificacaoControlo.NA;
    }
    if (!isNumber(linha.classificControloRetencao)) {
      linha.classificControloRetencao = EClassificacaoControloRetencao.RetidoDisponivel;
    }
    if (!isNumber(linha.dc)) {
      linha.dc = EDebitoCredito.Debito;
    }
    if (!isNumber(linha.valor)) {
      linha.valor = 0;
    }
    if (!isNumber(linha.valorTaxa)) {
      linha.valorTaxa = 0;
    }
    if (!isNumber(linha.valorME)) {
      linha.valorME = 0;
    }
    if (!isNumber(linha.cambio)) {
      linha.cambio = 0;
    }
    if (!isNumber(linha.campoMeCalculado)) {
      linha.campoMeCalculado = ECampoCalculadoME.Valor;
    }
    if (!isBoolean(linha.naoPerguntaCC)) {
      linha.naoPerguntaCC = false;
    }

    linha._invalidNif = false;
    linha._invalidDataDoc = false;
    linha._isImputacao = this._docContabilidadeService.isLineImputacao(linha);
    linha._imputaDocPrincipal = linha.nLancImput !== EMPTY_GUID && linha.nLanc !== linha.nLancImput;
    linha._extPocCabImput = '';
    linha._nDocImput = '';
    linha._deletable = (linha._isImputacao || !this._docContabilidadeService.isLineDisabled(linha)) && isEmpty(linha.nLancDescontoRefCC);

    if (doc.isUsingPreDefinido) {
      if (!isNumber(linha.preDefinidoContabLinhaIndice)) {
        linha.preDefinidoContabLinhaIndice = 0;
      }
      if (doc.predefinido.linhas[linha.preDefinidoContabLinhaIndice]) {
        linha._predefinidoFake = doc.predefinido.linhas[linha.preDefinidoContabLinhaIndice].fake === true;
      }
    }

    // Correções entidades
    if (linha.nomeMoeda && !isNumber(linha.codMoeda)) {
      linha.codMoeda = 0;
    }

    if (linha._imputaDocPrincipal && !doc._hasLinhasImputadas) {
      doc._hasLinhasImputadas = true;
    }
  }

  protected _handleCommandResponseErrors(error: HttpErrorResponse, doc: IDocContabilidade): ICGExceptionError {
    const exception: ICGExceptionError = this._cgExceptionService.get(error);
    switch (exception.class) {
      case EDocContabilidadeException.INVALID_DATA_LANCAM:
        doc._invalidDataLancamento = true;
        break;
      case EDocContabilidadeException.INVALID_NIF:
        doc._invalidNif = true;
        break;
    }
    return exception;
  }

  protected _handleCommandLinhaResponseErrors(exception: ICGExceptionError, linha: IDocContabilidadeLinha): void {
    switch (exception.class) {
      case EDocContabilidadeException.INVALID_NIF:
        linha._invalidNif = true;
        break;
      case EDocContabilidadeException.INVALID_DATA_DOC:
        linha._invalidDataDoc = true;
        break;
    }
  }
}
