import {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {isDefinedNotNull, isEmpty, isFunction, isNumber, isObject} from 'pl-comps-angular';
import {EEntityMaintenanceEditMode, IEntityMaintenanceEditModalOptions} from '../../entity/entity.maintenance.interface';
import {EEntityStateDetailType, entityStateDetailQueryParam} from '../../../../../../common/utils/entity.state.utils';
import {EntityMaintenanceModalComponent} from '../entity.maintenance.modal.component';
import {EStatusCode} from '../../../../../../config/constants';
import {IApiRequestConfigWithBody} from '../../../../../services/api/api.service.interface';
import {ModuloEntityDetailComponent} from '../../../../module/entitydetail/module.entitydetail.component';

@Component({
  selector: 'entity-maintenance-detail-modal',
  templateUrl: './entity.maintenance.detail.modal.component.html'
})
export class EntityMaintenanceDetailModalComponent<T extends object, S extends ModuloEntityDetailComponent<T> = ModuloEntityDetailComponent<T>>
  extends EntityMaintenanceModalComponent<T, S>
  implements OnDestroy
{
  @Input() public type: EEntityStateDetailType;
  @Input() public id: string | number;
  @Input() public editOptions: IEntityMaintenanceEditModalOptions;
  @Input() public onMaintenanceSave: (config: IApiRequestConfigWithBody<T>) => Promise<T>;

  public toolbarTitle: string;

  private _subscriptionOnClose: Subscription;
  private _subscriptionOnNew: Subscription;
  private _subscriptionOnSave: Subscription;
  private _subscriptionOnDelete: Subscription;
  private _subscriptionOnDuplicate: Subscription;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this._onDuplicate = this._onDuplicate.bind(this);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._clearSubscription();
  }

  protected _transitionParams(): object {
    const superParams: object = super._transitionParams();
    const params: object = {...superParams, id: this.id};
    const type = entityStateDetailQueryParam(this.entityMaintenanceInstance.entity.name);
    params[type] = isDefinedNotNull(this.type) ? this.type : isEmpty(this.id) ? EEntityStateDetailType.NEW : EEntityStateDetailType.EDIT;
    return params;
  }

  protected _configToolbar(): void {
    super._configToolbar();
    const modeReadonly: boolean = Boolean(this.editOptions) && this.editOptions.mode === EEntityMaintenanceEditMode.ReadOnly;
    const modeEditOnly: boolean = Boolean(this.editOptions) && this.editOptions.mode === EEntityMaintenanceEditMode.EditOnly;
    this._componentRefInstance.btnSave.visible = !modeReadonly;
    this._componentRefInstance.btnDelete.visible = Boolean(this.entityMaintenanceInstance.entity.actions.delete) && this._componentRefInstance.type === EEntityStateDetailType.EDIT && !modeReadonly;
    this._componentRefInstance.btnNovo.visible =
      Boolean(this.entityMaintenanceInstance.entity.actions.new) && this._componentRefInstance.type === EEntityStateDetailType.EDIT && !modeEditOnly && !modeReadonly;
    if (this._componentRefInstance.btnNovo.visible && isNumber(this._componentRefInstance.btnDuplicate.order)) {
      this._componentRefInstance.btnNovo.order = this._componentRefInstance.btnDuplicate.order - 1;
      this._componentRefInstance.toolbar.sortItems();
    }
    this._componentRefInstance.btnDuplicate.visible =
      Boolean(this.entityMaintenanceInstance.entity.actions.duplicate) && this._componentRefInstance.type === EEntityStateDetailType.EDIT && !modeEditOnly && !modeReadonly;
    if (this._componentRefInstance.type !== EEntityStateDetailType.NEW && this._componentRefInstance.toolTitle.caption && !this._componentRefInstance.toolTitle.caption.includes('{{')) {
      this.toolbarTitle = this._componentRefInstance.toolTitle.caption;
    }
    if (this._componentRefInstance.btnEdit.visible && modeReadonly) {
      this._componentRefInstance.btnEdit.visible = false;
    }
    if (this.type === EEntityStateDetailType.NEW && isObject(this._componentRefInstance.params) && !isEmpty(this._componentRefInstance.params.id)) {
      this.entityMaintenanceInstance.entity.setId(this._componentRefInstance.model, this._componentRefInstance.params.id);
    }
    if (isFunction(this.onMaintenanceSave)) {
      this._componentRefInstance.save = (config: IApiRequestConfigWithBody<T>) => {
        config = {
          body: this._componentRefInstance.model,
          ...config
        };
        const promise: Promise<T> = this.onMaintenanceSave(config);
        this._onSave(promise);
        return promise;
      };
    }
  }

  protected _evaluateResolvedValue(key: string, result: unknown): void {
    if (
      key === 'model' &&
      result instanceof HttpErrorResponse &&
      result.status === EStatusCode.NotFound &&
      (this.type === EEntityStateDetailType.DETAIL || this.type === EEntityStateDetailType.EDIT)
    ) {
      this.dismiss(result);
    }
  }

  protected _observe(): void {
    this._clearSubscription();
    this._subscriptionOnClose = this._componentRefInstance.evtOnCloseMaintenance().subscribe((value: unknown) => {
      this.enableClose();
      this.close(<T>value);
    });
    this._subscriptionOnNew = this._componentRefInstance.evtOnNew().subscribe(() => {
      this._onNew();
    });
    this._subscriptionOnSave = this._componentRefInstance.evtOnSave().subscribe((value: Promise<T>) => {
      this._onSave(value);
    });
    this._subscriptionOnDelete = this._componentRefInstance.evtOnDelete().subscribe((value: Promise<void>) => {
      this._onDelete(value);
    });
    this._subscriptionOnDuplicate = this._componentRefInstance.evtOnDuplicate().subscribe(async (value: Promise<void>) => {
      await value;
      this._onDuplicate();
    });
  }

  protected _clearSubscription(): void {
    if (this._subscriptionOnClose) {
      this._subscriptionOnClose.unsubscribe();
    }
    if (this._subscriptionOnNew) {
      this._subscriptionOnNew.unsubscribe();
    }
    if (this._subscriptionOnSave) {
      this._subscriptionOnSave.unsubscribe();
    }
    if (this._subscriptionOnDelete) {
      this._subscriptionOnDelete.unsubscribe();
    }
    if (this._subscriptionOnDuplicate) {
      this._subscriptionOnDuplicate.unsubscribe();
    }
  }

  private _onNew(): void {
    this.type = EEntityStateDetailType.NEW;
    this.toolbarTitle = undefined;
    setTimeout(() => {
      this._configToolbar();
      this._changeDetectorRef.detectChanges();
    });
  }

  private async _onSave(savePromise: Promise<T>): Promise<void> {
    this.disableClose();
    try {
      const response: T = await savePromise;
      this.enableClose();
      this.close(response);
    } catch (error: unknown) {
      this.enableClose();
      this._logger.error(error);
    }
  }

  private async _onDelete(deletePromise: Promise<void>): Promise<void> {
    this.disableClose();
    try {
      await deletePromise;
      this.enableClose();
      this.close(undefined);
    } catch (error: unknown) {
      this.enableClose();
      this._logger.error(error);
    }
  }

  private _onDuplicate(): void {
    this.toolbarTitle = undefined;
    this._configToolbar();
    this._changeDetectorRef.detectChanges();
  }
}
