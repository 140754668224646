import {Component, Injector, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {IEntityServiceQueryRequestConfig} from '../../../services/entity/entity.service.interface';
import {TEntityListBeforeRequestFn} from '../../../components/entity/list/entity.list.component.interface';
import {EGrupoDoc} from '../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {EMovimentacoesTabID, IMovimentacoesParams} from '../movimentacoes.module.interface';
import {IPlDynamicVisualsSecondaryClickAction, IPlToolbarItem, isEmpty, isNumber, isObject, PlAlertService} from 'pl-comps-angular';
import {
  docsComerciaisGrupoDocToEntityName,
  EDocsComerciaisClifoStatus,
  EDocsComerciaisTableLegendColors,
  ENTITY_NAME_DOCS_COMERCIAIS_ENTRADAS_DIVERSAS,
  ENTITY_NAME_DOCS_COMERCIAIS_SAIDAS_DIVERSAS,
  IDocComercial,
  IDocComercialCabWithData,
  IDocComercialDocCabFieldsVisibilityOptionsListView,
  IDocComercialEditStateParams,
  IDocComercialLinha,
  IDocsComerciaisEntityService,
  TABLE_LEGEND_DOCS_COMERCIAIS
} from '../../../entities/docscomerciais/docsComerciais.entity.interface';
import {EEntityStateDetailType} from '../../../../common/utils/entity.state.utils';
import {EFACCBHASHEstado, IJsonDocComercial, IJsonDocComercialCab, IJsonDocContabImputacao} from '../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {DocumentosService} from '../../../entities/docscomerciais/service/documentos.entity.service';
import {ModuloComponent} from '../../../components/module/module.component';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnRowPrepared
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {HttpResponse} from '@angular/common/http';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DocumentoImputacoesDocContabModalComponent} from '../../../entities/docscomerciais/modals/imputacoesDocContab/documento.imputacoesDocContab.modal.component';
import {docsComerciaisGrupoDocToEntity} from '../../../entities/docscomerciais/docsComerciais.entity';
import {ROLE} from '../../../services/role.const';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {AuthService} from '../../../services/auth/auth.service';
import {EntityListComponent} from '../../../components/entity/list/entity.list.component';
import {EFaturaService} from '../../efatura/eFatura.module.service';
import {EFaturaConfigModalComponent} from '../../efatura/modals/configuracao/eFaturaConfig.modal.component';
import {IJsonEFaturaConfiguracao} from '../../efatura/jsonEFatura.module.interface';
import {IJsonRecibo, IJsonReciboCab} from '../../../entities/recibos/jsonRecibo.entity.interface';
import moment from 'moment';
import {ENTITY_NAME_RECIBOS} from '../../../entities/recibos/recibos.entity.interface';
import {DevolucoesModalComponent} from '../../../entities/docscomerciais/modals/devolucoes/devolucoes.modal.component';
import {IEntityMaintenanceInstance} from '../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityMaintenanceService} from '../../../components/entity/maintenance/entity/entity.maintenance.service';
import {IJsonRastreabilidadeFaturacao} from '../../../entities/docscomerciais/modals/rastreabilidade/jsonRastreabilidade.interface';
import {DocumentoRastreabilidadeModalComponent} from '../../../entities/docscomerciais/modals/rastreabilidade/documento.rastreabilidade.modal.component';
import {DocumentoRastreabilidadeService} from '../../../entities/docscomerciais/modals/rastreabilidade/documento.rastreabilidade.service';
import {IJsonEFaturaCirculacaoLogin} from '../../../services/efaturacirculacaologin/eFaturaCirculacaoLogin.service.interface';
import {EFaturaCirculacaoLoginModalComponent} from '../../efaturacirculacaologin/eFaturaCirculacaoLogin.modal.component';
import {EFaturaCirculacaoLoginService} from '../../../services/efaturacirculacaologin/eFaturaCirculacaoLogin.service';
import {IJsonDocfa, IJsonDocfaReportList} from '../../../entities/docfas/jsonDocFa.entity.interface';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../entities/docfas/docFas.entity.interface';
import {Subscription} from 'rxjs';
import {EConfigOptionsInstanceName, IDocsComerciaisListConfigOptions, TConfigOptions} from '../../../services/config/options/config.options.service.interface';
import {ConfigOptionsService} from '../../../services/config/options/config.options.service';
import {EGroupName} from '../../../../config/constants';
import {TTableLegend} from '../../../components/tablelegend/tablelegend.component.interface';
import {DevExpressDataGridUIService} from '../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';

const WS_ERROR_RETURN_CODE = -80;

@Component({
  selector: 'module-movimentacoes',
  templateUrl: './movimentacoes.module.component.html'
})
export class MovimentacoesModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  public readonly movimentacoesTabID: typeof EMovimentacoesTabID;
  public readonly configOptionsGroupName: EGroupName;
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;

  public tableLegend: TTableLegend;
  public activeTabId: string;
  public grupo: EGrupoDoc;
  public showGDocs: boolean;
  public hasContabilidade: boolean;
  public hasComercial: boolean;
  public movimentacoesVisibilityOptions: IDocComercialDocCabFieldsVisibilityOptionsListView;
  public configOptionsToolbarInstanceId: string;

  @ViewChild('entityListEntradasDiversas') private readonly _entityListEntradasDiversas: EntityListComponent;
  @ViewChild('entityListSaidasDiversas') private readonly _entityListSaidasDiversas: EntityListComponent;

  private readonly _btnNewEntradasDiversas: IPlToolbarItem;
  private readonly _btnNewSaidasDiversas: IPlToolbarItem;
  private readonly _params: IMovimentacoesParams;
  private readonly _serviceDocsComerciais: IDocsComerciaisEntityService;
  private readonly _maintenanceInstanceDocContab: IEntityMaintenanceInstance;
  private readonly _maintenanceInstanceEntradasDiversas: IEntityMaintenanceInstance;
  private readonly _maintenanceInstanceSaidasDiversas: IEntityMaintenanceInstance;
  private readonly _docfas: IDocFasEntityService;
  private _reportDefaultPdf: string;
  private _nViasPdf: number;
  private _subscriptionConfigOptions: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _documentosService: DocumentosService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgModalService: CGModalService,
    private readonly _authService: AuthService,
    private readonly _eFaturaService: EFaturaService,
    private readonly _plAlertService: PlAlertService,
    private readonly _documentoRastreabilidadeService: DocumentoRastreabilidadeService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _eFaturaCirculacaoLoginService: EFaturaCirculacaoLoginService,
    private readonly _configOptionsService: ConfigOptionsService,
    private readonly _renderer: Renderer2,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService
  ) {
    super(_injector);
    this.configOptionsInstanceName = EConfigOptionsInstanceName.MOVIMENTACOES;
    this.movimentacoesTabID = EMovimentacoesTabID;
    this.configOptionsGroupName = EGroupName.ERP;
    this._params = <IMovimentacoesParams>this._transition.params();
    this.grupo = EGrupoDoc.EntradasDiversas;
    if (this._params.grupo !== undefined) {
      this.activeTabId = this._params?.grupo === EGrupoDoc.EntradasDiversas ? this.movimentacoesTabID.Entradas : this.movimentacoesTabID.Saidas;
    } else {
      this.activeTabId = this.movimentacoesTabID.Entradas;
    }
    this._docfas = this._entityServiceBuilder.build<IJsonDocfa, IDocFasEntityService>(ENTITY_NAME_DOC_FAS);
    this._maintenanceInstanceDocContab = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._maintenanceInstanceEntradasDiversas = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_COMERCIAIS_ENTRADAS_DIVERSAS);
    this._maintenanceInstanceSaidasDiversas = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_COMERCIAIS_SAIDAS_DIVERSAS);
    this.showGDocs = this._configService.configurations.empresa.temContabilidadeDigital || this._configService.configurations.acessos.erpcloud.gdocs;
    this._serviceDocsComerciais = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>('docscomerciais');
    this._btnNewEntradasDiversas = {
      id: 'newEntradasDiversas',
      caption: 'movimentacoes.button.novaEntrada',
      class: 'btn-primary',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-plus-circle" aria-hidden="true"></i>',
      click: () =>
        this._cgStateService.redirectToState({
          stateOrName: docsComerciaisGrupoDocToEntityName(EGrupoDoc.EntradasDiversas),
          stateType: EEntityStateDetailType.NEW,
          params: {grupo: this.grupo}
        })
    };
    this._btnNewSaidasDiversas = {
      id: 'newSaidasDiversas',
      caption: 'movimentacoes.button.novaSaida',
      class: 'btn-primary',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-plus-circle" aria-hidden="true"></i>',
      click: () =>
        this._cgStateService.redirectToState({
          stateOrName: docsComerciaisGrupoDocToEntityName(EGrupoDoc.SaidasDiversas),
          stateType: EEntityStateDetailType.NEW,
          params: {grupo: this.grupo}
        })
    };

    this.movimentacoesVisibilityOptions = {
      showObservacoes: false,
      showDocExterno: false,
      showDataDocExterno: false,
      showDataVenc: false,
      showNRefProcesso: false,
      showCCusto: false,
      showMoeda: false,
      showNRequisicao: false,
      showNArmazem: false,
      showValorCIva: false,
      showLiquido: false,
      showCodUnidMov: false,
      showNVendedor: false,
      showD2: false,
      showD3: false,
      showDescEmValor: false,
      showPrestacoes: false
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this._params.grupo !== undefined) {
      this.grupo = this._params.grupo;
    }
    this.configOptionsToolbarInstanceId = this.instanceName;
    this._authService.hasAuthority(ROLE.ERP).then((hasAuthority: boolean) => {
      this.hasComercial = hasAuthority;
    });
    this._authService.hasAuthority(ROLE.CONTABILIDADE).then((hasAuthority: boolean) => {
      this.hasContabilidade = hasAuthority;
    });
    this.toolbar.addButton(this._btnNewEntradasDiversas);
    this.toolbar.addButton(this._btnNewSaidasDiversas);
    this.btnRefresh.visible = true;
    this.btnRefresh.click = () => this.refreshList();

    this._subscriptionConfigOptions = this._configOptionsService
      .getGroupOptions(this.configOptionsGroupName)
      .get(this.configOptionsInstanceName)
      .options()
      .subscribe((configOptions: TConfigOptions<boolean, IDocsComerciaisListConfigOptions>) => {
        this.movimentacoesVisibilityOptions.showNRefProcesso = configOptions.get('showNRefProcesso').value;
        this.movimentacoesVisibilityOptions.showCCusto = configOptions.get('showCCusto').value;
        this.movimentacoesVisibilityOptions.showNArmazem = configOptions.get('showNArmazem').value;
        this.movimentacoesVisibilityOptions.showNVendedor = configOptions.get('showNVendedor').value;
        this.movimentacoesVisibilityOptions.showValorCIva = configOptions.get('showValorCIva').value;
        this.movimentacoesVisibilityOptions.showCodUnidMov = configOptions.get('showCodUnidMov').value;
        this.movimentacoesVisibilityOptions.showLiquido = configOptions.get('showLiquido').value;
        this.movimentacoesVisibilityOptions.showD2 = configOptions.get('showD2').value;
        this.movimentacoesVisibilityOptions.showD3 = configOptions.get('showD3').value;
        this.movimentacoesVisibilityOptions.showDescEmValor = configOptions.get('showDescEmValor').value;
        this.movimentacoesVisibilityOptions.showPrestacoes = configOptions.get('showPrestacoes').value;
      });

    this.tableLegend = TABLE_LEGEND_DOCS_COMERCIAIS;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscriptionConfigOptions) {
      this._subscriptionConfigOptions.unsubscribe();
    }
  }

  public onDataGridContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IJsonDocComercialCab>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();
      if (isNumber(event.row.data.faccbId) || !isEmpty(event.row.data.faccbId)) {
        const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateContextMenuActions(event.row.data.faccbId);
        this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions);
      }
    }
  }

  public onDataGridRowPrepared({data, rowElement, rowType}: IDevExpressDataGridEventOnRowPrepared<IJsonDocComercialCab>): void {
    if (rowType === 'data') {
      let cssClass: string;
      if (data.anulado) {
        cssClass = EDocsComerciaisTableLegendColors.DocumentoComercialAnulado;
      }

      if (cssClass) {
        this._renderer.addClass(rowElement, cssClass);
      } else if (rowElement.style.backgroundColor) {
        this._renderer.removeClass(rowElement, cssClass);
      }
    }
  }

  public getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc, doc.cab.nDocumento === 0 || (doc.cab.hashEstado <= EFACCBHASHEstado.DocSemAssinatura && !doc.cab.terminado));
  }

  public onEntradasESaidasDiversasDocsCellClick(event: IDevExpressDataGridEventOnCellClick<IDocComercialCabWithData>): void {
    devExpressDataGridExpandDetailHandler(event, () => this._onDocDetail(event));
  }

  public getDoc(id: number): Promise<IJsonDocComercial> {
    return this._documentosService.getDoc(id).then((response: HttpResponse<IJsonDocComercial>) => response.body);
  }

  public getPdfActions(doc: IDocComercialCabWithData): Promise<void> {
    if (doc._doc) {
      return this._documentosService.getPdf(doc._doc, doc._doc.cab.nDocumento === 0 || !doc._doc.cab.terminado);
    }
    return this.getDoc(doc.faccbId).then((result: IJsonDocComercial) => {
      this.getPdf(result);
    });
  }

  public evaluateTabChosen(): void {
    if (this.activeTabId === this.movimentacoesTabID.Entradas) {
      this.grupo = EGrupoDoc.EntradasDiversas;
    } else {
      this.grupo = EGrupoDoc.SaidasDiversas;
    }
  }

  public anexarGDocComercial(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.anexarGDocComercial(doc);
  }

  public getEditarDocumentoActions(doc: IDocComercialCabWithData): Promise<void> {
    return this.getDoc(doc.faccbId).then((result: IJsonDocComercial) => {
      return this._getEditarDocumento(result);
    });
  }

  public transformarLinhasDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.transformarLinhasDocumento(doc).then((response) => {
      this.refreshList();
      return response;
    });
  }

  public refreshList(): Promise<void> {
    if (this.grupo === EGrupoDoc.EntradasDiversas && this._entityListEntradasDiversas) {
      return this._entityListEntradasDiversas.refresh();
    } else if (this.grupo === EGrupoDoc.SaidasDiversas && this._entityListSaidasDiversas) {
      return this._entityListSaidasDiversas.refresh();
    }
    return Promise.resolve();
  }

  public comunicarDocWebServiceATCab(doc: IJsonDocComercialCab): Promise<IJsonDocComercial> {
    return this._eFaturaService.getConfiguracaoEFatura().then(({body}: HttpResponse<IJsonEFaturaConfiguracao>) => {
      if (body.atUsername && body.atPassword) {
        return this._comunicarDocWebServiceAT(doc.faccbId);
      }
      return this._callEFaturacaoLoginCab(doc);
    });
  }

  public duplicarDocumentoEditavelActions(doc: IJsonDocComercialCab): Promise<void> {
    return this.getDoc(doc.faccbId).then((result: IJsonDocComercial) => {
      return this.duplicarDocumentoEditavel(result).then(() => this.refreshList());
    });
  }

  public duplicarDocumentoEditavel(doc: IJsonDocComercial): Promise<void> {
    const docComercial: IDocComercial = {
      cab: doc.cab,
      clifo: doc.clifo,
      moradaCarga: doc.moradaCarga,
      moradaDescarga: doc.moradaDescarga,
      transportador: doc.transportador,
      meiosPagamento: doc.meiosPagamento,
      linhas: []
    };

    docComercial.cab.nDocumento = 0;
    docComercial.cab.nDocumentoDraft = 0;
    docComercial.cab.nFactFornec = '';
    docComercial.cab.atDocCodeID = '';
    docComercial.cab.comunicadoAoEFatura = false;
    docComercial.cab.dataDoc = moment();
    docComercial.cab.dataDocExterno = moment(doc.cab.dataDoc).isSameOrAfter(doc.cab.dataDocExterno, 'day')
      ? moment()
      : moment(docComercial.cab.dataDoc).add(moment(doc.cab.dataDocExterno).diff(doc.cab.dataDoc, 'day'), 'days');
    docComercial.cab.dataVenc = moment(doc.cab.dataDoc).isSameOrAfter(doc.cab.dataVenc, 'day')
      ? moment()
      : moment(docComercial.cab.dataDoc).add(moment(doc.cab.dataVenc).diff(doc.cab.dataDoc, 'day'), 'days');
    docComercial.clifo.action = EDocsComerciaisClifoStatus.ExisteNaoActualizaNaoValidaNif;

    if (doc.cab.anulado && doc.cab.observacoes !== '') {
      const idx = doc.cab.observacoes.indexOf('!', 0);
      if (idx > 0) {
        doc.cab.observacoes = doc.cab.observacoes.replace(doc.cab.observacoes.substring(0, idx + 1), '').trim();
      }
    }

    for (const linha of doc.linhas) {
      const linhaDocComercial: IDocComercialLinha = {
        showTextoLivre: false,
        ...linha
      };
      docComercial.linhas.push(linhaDocComercial);
    }
    const stateParams: IDocComercialEditStateParams = {doc: docComercial, grupo: this.grupo};
    return this._cgStateService.redirectToState({stateOrName: docsComerciaisGrupoDocToEntity(this.grupo), stateType: EEntityStateDetailType.NEW, params: stateParams});
  }

  public encerrarDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.encerrarDocumento(doc, true).then((result) => {
      this.refreshList();
      return result;
    });
  }

  public comunicarDocWebServiceAT(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._eFaturaService.getConfiguracaoEFatura().then(({body}: HttpResponse<IJsonEFaturaConfiguracao>) => {
      if (body.atUsername && body.atPassword) {
        return this._comunicarDocWebServiceAT(doc.cab.faccbId);
      }
      return this._callEFaturacaoLogin(doc);
    });
  }

  public comunicarDocCirculacaoAT(doc: IJsonDocComercial, callEFaturacaoCirculacaoLogin: boolean): Promise<IJsonDocComercial> {
    if (!callEFaturacaoCirculacaoLogin) {
      return this._comunicarDocCirculacaoAT(doc);
    }
    return this._eFaturaCirculacaoLoginService.get().then(({body}: HttpResponse<IJsonEFaturaCirculacaoLogin>) => {
      if (body.username && body.password) {
        return this._comunicarDocCirculacaoAT(doc);
      }
      return this._callEFaturacaoCirculacaoLogin(doc);
    });
  }

  public inserirCodigoTransporteAT(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.inserirCodigoTransporteAT(doc);
  }

  public async anularDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    if (doc.cab.nDocumento === 0) {
      await this._cgModalService.showOkCancel('docscomerciais.auxiliares.anularDocumento', 'gestaoDocsComerciais.messages.anularAviso', {
        size: 'md',
        icon: 'warning',
        btnOkIcon: 'fa fa-fw fa-check-circle-o',
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no'
      });
    }
    return this._documentosService.anularDocumento(doc).then((result) => {
      this.refreshList();
      return result;
    });
  }

  public notaCreditoDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.notaCreditoDocumento(doc).then((response) => {
      this.refreshList();
      return response;
    });
  }

  public desmarcarDocTransformado(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.desmarcarDocTransformado(doc).then((response) => {
      this.refreshList();
      return response;
    });
  }

  public readonly fnEditarDocumento = (doc: IJsonDocComercial): Promise<void> => this._getEditarDocumento(doc);

  public readonly fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this.getPdf(doc);

  public readonly fnGetDocPdf = (doc: IDocComercialCabWithData) => (): Promise<void> => this.getPdfActions(doc);

  public readonly fnBeforeRequest: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequest(queryRequestConfig);

  public readonly fnAnexarDocumentoActions = (doc: IDocComercialCabWithData) => (): Promise<IJsonDocComercial> => this._getAnexarDocumentoActions(doc);

  public readonly fnTransformarLinhasDocumentoActions = (doc: IDocComercialCabWithData) => (): Promise<IJsonDocComercial> => this._getTransformarLinhasDocumentoActions(doc);

  public readonly fnEditarDocumentoActions = (doc: IDocComercialCabWithData) => (): Promise<void> => this.getEditarDocumentoActions(doc);

  public readonly fnReAbreDocumento = (doc: IDocComercialCabWithData) => (): Promise<void> => this._reAbreDocumento(doc);

  public readonly fnComunicarDocWebServiceATCab = (doc: IJsonDocComercialCab) => (): Promise<IJsonDocComercial> => this.comunicarDocWebServiceATCab(doc);

  public readonly fnEmitirRecibo = (doc: IJsonDocComercialCab) => (): Promise<void> => this._emitirRecibo(doc);

  public readonly fnDevolucoes = (doc: IDocComercial): Promise<void> => this._devolucoes(doc);

  public readonly fnDuplicarDocumentoEditavelActions = (doc: IDocComercialCabWithData) => (): Promise<void> => this.duplicarDocumentoEditavelActions(doc);

  public readonly fnEncerrarDocumentoActions = (doc: IDocComercialCabWithData) => (): Promise<IJsonDocComercial> => this._getEncerrarDocumentoActions(doc);

  public readonly fnMostrarDocContabActions = (doc: IDocComercialCabWithData) => (): Promise<void> => this._showDocContab(doc);

  public readonly fnRastreabilidadeDocumentoActions = (doc: IDocComercialCabWithData) => (): Promise<void> => this._getRastreabilidadeDocumentoActions(doc);

  public readonly fnAnularDocumentoActions = (doc: IDocComercialCabWithData) => (): Promise<void | IJsonDocComercial> => this._getAnularDocumentoActions(doc);

  public readonly fnAnexarGDocComercial = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.anexarGDocComercial(doc);

  public readonly fnComunicarDocWebServiceAT = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.comunicarDocWebServiceAT(doc);

  public readonly fnComunicarDocCirculacaoAT = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.comunicarDocCirculacaoAT(doc, true);

  public readonly fnInserirCodigoTransporteAT = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.inserirCodigoTransporteAT(doc);

  public readonly fnAnularDocumento = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.anularDocumento(doc);

  public readonly fnNotaCreditoDocumento = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.notaCreditoDocumento(doc);

  public readonly fnTransformarLinhasDocumento = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.transformarLinhasDocumento(doc);

  public readonly fnDesmarcarDocTransformado: (doc: IJsonDocComercial) => Promise<IJsonDocComercial> = (doc: IJsonDocComercial) => this.desmarcarDocTransformado(doc);

  public readonly fnGetDoc: (id: number) => Promise<IJsonDocComercial> = (id: number) => this.getDoc(id);

  public readonly fnDuplicarDocumentoEditavel = (doc: IJsonDocComercial): Promise<void> => this.duplicarDocumentoEditavel(doc);

  public readonly fnMeiosPagamentoComercial = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this._getMeiosPagamentoComercial(doc);

  public readonly fnEncerrarDocumento = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.encerrarDocumento(doc);

  public readonly fnReAbreDocumentoActions = (doc: IDocComercialCabWithData): Promise<void> => this._reAbreDocumento(doc);

  private _getMeiosPagamentoComercial(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.getMeiosPagamentoComercial(doc);
  }

  private _generateContextMenuActions(faccbId: string | number): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'entity.list.secondaryClick.menu.openAsModal',
        icon: 'fa-file-o',
        click: () => (this.grupo === EGrupoDoc.EntradasDiversas ? this._maintenanceInstanceEntradasDiversas.maintenanceEdit(faccbId) : this._maintenanceInstanceSaidasDiversas.maintenanceEdit(faccbId))
      },
      {
        caption: 'entity.list.secondaryClick.menu.openAsNewTabOrWindow',
        icon: 'fa-window-restore',
        click: () => {
          this._cgStateService.redirectToState({stateOrName: docsComerciaisGrupoDocToEntity(this.grupo), stateType: EEntityStateDetailType.EDIT, params: {id: faccbId, grupo: this.grupo}});
        }
      }
    ];
  }

  private _callEFaturacaoCirculacaoLogin(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._cgModalService.show(EFaturaCirculacaoLoginModalComponent, {size: 'sm', backdrop: 'static'}).then(() => {
      return this.comunicarDocCirculacaoAT(doc, false);
    });
  }

  private _comunicarDocCirculacaoAT(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.comunicarDocCirculacaoAT(doc.cab.faccbId).then((response: IJsonDocComercial) => {
      if (response.cab.returnCode === WS_ERROR_RETURN_CODE) {
        return this._callEFaturacaoCirculacaoLogin(response);
      }
      return response;
    });
  }

  private _callEFaturacaoLogin(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._cgModalService.show<IJsonEFaturaConfiguracao>(EFaturaConfigModalComponent, {size: 'lg', backdrop: 'static'}).then(() => {
      return this.comunicarDocWebServiceAT(doc);
    });
  }

  private _getRastreabilidadeDocumentoActions(docCab: IJsonDocComercialCab): Promise<void> {
    return this._documentoRastreabilidadeService.getRastreabilidade(docCab.nDocFa, docCab.nNumer, docCab.nDocumentoDraft).then((response: HttpResponse<Array<IJsonRastreabilidadeFaturacao>>) => {
      const modalRef = this._cgModalService.showVanilla(DocumentoRastreabilidadeModalComponent, {size: 'fullscreen'});
      const componentInstance: DocumentoRastreabilidadeModalComponent = modalRef.componentInstance;
      componentInstance.rastreabilidade = response.body;
      componentInstance.faccbId = docCab.faccbId;
      modalRef.result.then(() => {
        this.refreshList();
      });
    });
  }

  private _getEncerrarDocumentoActions(doc: IDocComercialCabWithData): Promise<IJsonDocComercial> {
    if (doc._doc) {
      return this.encerrarDocumento(doc._doc);
    }
    return this.getDoc(doc.faccbId).then((result: IJsonDocComercial) => {
      return this.encerrarDocumento(result);
    });
  }

  private _devolucoes(doc: IJsonDocComercial): Promise<void> {
    const modalRef = this._cgModalService.showVanilla(DevolucoesModalComponent, {size: 'xxl'});
    const componentInstance: DevolucoesModalComponent = modalRef.componentInstance;
    componentInstance.doc = {
      cab: <IJsonDocComercialCab>doc
    };
    return modalRef.result.then(() => {
      return this.refreshList();
    });
  }

  private _comunicarDocWebServiceAT(faccbId: number): Promise<IJsonDocComercial> {
    return this._documentosService.comunicarDocWebServiceAT(faccbId, false);
  }

  private _callEFaturacaoLoginCab(doc: IJsonDocComercialCab): Promise<IJsonDocComercial> {
    return this._cgModalService.show<IJsonEFaturaConfiguracao>(EFaturaConfigModalComponent, {size: 'lg', backdrop: 'static'}).then(() => {
      return this.comunicarDocWebServiceATCab(doc);
    });
  }

  private _emitirRecibo(doc: IJsonDocComercialCab): Promise<void> {
    const reciboCab: IJsonReciboCab = {
      extPocCabID: '',
      numero: '',
      nConta: doc.nConta,
      nome: doc.nome,
      nif: doc.nif,
      email: '',
      data: moment(),
      valor: 0,
      stampUpdate: moment(),
      totalDescontos: 0,
      totalReceber: 0,
      total: 0,
      origem: 0,
      totalRetencao: 0,
      temRetencao: false,
      descricao: '',
      valorEntregueCredito: 0,
      nDocAsStr: '',
      anulado: false
    };
    const reciboModel: Partial<IJsonRecibo> = {
      cab: reciboCab
    };
    return this._cgStateService.redirectToState({stateOrName: ENTITY_NAME_RECIBOS, stateType: EEntityStateDetailType.NEW, params: {model: reciboModel}});
  }

  private _getTransformarLinhasDocumentoActions(doc: IDocComercialCabWithData): Promise<IJsonDocComercial> {
    if (doc._doc) {
      return this.transformarLinhasDocumento(doc._doc);
    }
    return this.getDoc(doc.faccbId).then((result: IJsonDocComercial) => {
      return this.transformarLinhasDocumento(result);
    });
  }

  private _getEditarDocumento(doc: IJsonDocComercial): Promise<void> {
    const docComercial: IDocComercial = {
      cab: doc.cab,
      clifo: doc.clifo,
      moradaCarga: doc.moradaCarga,
      moradaDescarga: doc.moradaDescarga,
      transportador: doc.transportador,
      meiosPagamento: doc.meiosPagamento,
      linhas: []
    };
    for (const linha of doc.linhas) {
      const linhaDocComercial: IDocComercialLinha = {
        showTextoLivre: false,
        ...linha
      };
      docComercial.linhas.push(linhaDocComercial);
    }
    const stateParams: IDocComercialEditStateParams = {id: doc.cab.faccbId, doc: docComercial, grupo: this.grupo};
    return this._cgStateService.redirectToState({stateOrName: docsComerciaisGrupoDocToEntity(this.grupo), stateType: EEntityStateDetailType.DETAIL, params: stateParams});
  }

  private _getAnexarDocumentoActions(doc: IDocComercialCabWithData): Promise<IJsonDocComercial> {
    if (doc._doc) {
      return this.anexarGDocComercial(doc._doc);
    }
    return this.getDoc(doc.faccbId).then((result: IJsonDocComercial) => {
      return this.anexarGDocComercial(result);
    });
  }

  private _onDocDetail({data}: IDevExpressDataGridEventOnCellClick<IDocComercialCabWithData>): Promise<void> {
    return this._serviceDocsComerciais.get({id: data.faccbId}).then((response: HttpResponse<IJsonDocComercial>) => {
      data._doc = response.body;
      if (isEmpty(this._reportDefaultPdf)) {
        this._docfas.reports(data._doc.cab.nDocFa).then((responseDocFa: HttpResponse<IJsonDocfaReportList>) => {
          this._nViasPdf = responseDocFa.body.nviasDefault;
          this._reportDefaultPdf = responseDocFa.body.reportDefault;
        });
      }
      data._fileNamePDF = `${data._doc.cab.nDoc}_${moment(data._doc.cab.dataDoc).format('YYYYMMDD')}.pdf`;
      if (isEmpty(data._urlPDF) && isEmpty(data._doc.cab.ocrCabDocId)) {
        this._documentosService.getPdfUrl(data._doc.cab.faccbId, this._nViasPdf, this._reportDefaultPdf, false, false, true, false, false).subscribe((pdfUrl: string) => {
          data._urlPDF = pdfUrl;
        });
      }
      if (!isEmpty(data._doc.cab.ocrCabDocId)) {
        data._digitalGDocViewerDoc = {docID: data._doc.cab.ocrCabDocId, folderID: data._doc.cab.ocrCabFolderID, dataDoc: data._doc.cab.dataDoc};
      }
    });
  }

  private _beforeRequest(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    if (!queryRequestConfig.pesquisa) {
      queryRequestConfig.pesquisa = '';
    } else {
      queryRequestConfig.pesquisa = queryRequestConfig.pesquisa.includes('(') ? `${queryRequestConfig.pesquisa}&` : `(${queryRequestConfig.pesquisa})&`;
    }
    queryRequestConfig.pesquisa += `grupoDocfa=${this.grupo}`;
    return queryRequestConfig;
  }

  private async _getAnularDocumentoActions(doc: IDocComercialCabWithData): Promise<void> {
    if (!doc._doc) {
      doc._doc = await this.getDoc(doc.faccbId);
    }
    if (doc.nDocumento === 0) {
      await this._cgModalService.showOkCancel('docscomerciais.auxiliares.anularDocumento', 'gestaoDocsComerciais.messages.anularAviso', {
        size: 'md',
        icon: 'warning',
        btnOkIcon: 'fa fa-fw fa-check-circle-o',
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no'
      });
    }
    await this._documentosService.anularDocumento(doc._doc);
    this._plAlertService.success('docscomerciais.info.anularDocumento');
    await this.refreshList();
  }

  private async _showDocContab(doc: IJsonDocComercialCab): Promise<void> {
    const extPocCabID: string = await this._serviceDocsComerciais.getDocContabId(doc.faccbId).then((response: HttpResponse<string>) => response.body);
    await this._maintenanceInstanceDocContab.maintenanceEdit(extPocCabID);
  }

  private async _reAbreDocumento(doc: IDocComercialCabWithData | IJsonDocComercial): Promise<void> {
    const cab: IJsonDocComercialCab = <IJsonDocComercialCab>('cab' in doc ? doc.cab : doc);
    if (cab.integraContabilidade) {
      if (this.hasContabilidade) {
        const response: HttpResponse<Array<IJsonDocContabImputacao>> = await this._documentosService.getImputacoesDocContab(cab.faccbId);
        if (response.body.length) {
          const modalInstance: NgbModalRef = this._cgModalService.showVanilla(DocumentoImputacoesDocContabModalComponent, {size: 'md'});
          const componentInstance: DocumentoImputacoesDocContabModalComponent = modalInstance.componentInstance;
          componentInstance.list = response.body;
          componentInstance.faccbId = cab.faccbId;
          await modalInstance.result;
        }
      } else {
        await this._documentosService.apagaImputacoesDocContab(cab.faccbId);
      }
    }
    const docComercial: IJsonDocComercial = await this._documentosService.reAbreDocComercial(cab.faccbId).then((r) => r.body);
    const stateParams: IDocComercialEditStateParams = {id: cab.faccbId, doc: docComercial, grupo: this.grupo};
    await this._cgStateService.redirectToState({
      stateOrName: docsComerciaisGrupoDocToEntity(this.grupo),
      stateType: EEntityStateDetailType.EDIT,
      params: stateParams
    });
  }
}
