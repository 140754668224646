import {get, merge, set, uniq} from 'lodash-es';
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {copy, getPathValue, interpolate, isArray, isBoolean, isDefined, isEmpty, isFunction, isNumber, isObject, isString, Logger, PlEditRegistryService, TValueOrPromise} from 'pl-comps-angular';
import {CGModalService} from '../cg/modal/cgmodal.service';
import {COMPONENT_NAME_INPUT_ENTITY} from './component/input.entity.component.interface';
import {
  DATAGRID_COLUMN_NAME_ENTITY_LIST_BUTTONS,
  DATAGRID_TEMPLATE_ENTITY_LIST_HEADER_ACTIONS,
  DATAGRID_TEMPLATE_ENTITY_LIST_ITEM_ACTIONS,
  IEntity,
  IEntityDefinition,
  IEntityDeleteMessage,
  IEntityDeletePromptArgs,
  IEntityDevExpress,
  IEntityDevExpressDataGrid,
  IEntityMetadata,
  IEntityMetadataField,
  IEntityState,
  TEntityMetadataName
} from './entity.definition.interface';
import {DEV_EXPRESS_DATA_GRID_CONFIGURATION} from '../devexpress/datagrid/configuration/devexpress.datagrid.configuration';
import {DEV_EXPRESS_DEFAULT_DECIMALS_LIMIT} from '../devexpress/widget/devexpress.widget.utilities';
import {EEntityStateDetailType} from '../../../common/utils/entity.state.utils';
import {entityFieldEvents} from './utilities/entity.field.events';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridConfiguration} from '../devexpress/datagrid/configuration/devexpress.datagrid.configuration.interface';
import {IDevExpressFormatObject} from '../devexpress/widget/devexpress.widget.interface';
import {IEntityFormDefinitionField} from './entity.form.definition.interface';
import {interpolateTemplateToKeys} from '../../../config/constants';
import {TDevExpressDataGridColumnDataType} from '../devexpress/datagrid/devexpress.datagrid.types.interface';
import {IApiRequestConfig} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class EntityRegistryService {
  private readonly _entities: Map<string, IEntity>;

  constructor(
    private readonly _logger: Logger,
    private readonly _translateService: TranslateService,
    private readonly _plEditRegistryService: PlEditRegistryService,
    @Inject(DEV_EXPRESS_DATA_GRID_CONFIGURATION) private readonly _defaultDataGridConfiguration: IDevExpressDataGridConfiguration
  ) {
    this._entities = new Map<string, IEntity>();
  }

  // Regista os pré-estados para depois serem copiados no portal
  public register(entityDefinition: IEntityDefinition): void {
    const toRegister = <IEntity>entityDefinition;

    toRegister.getMetadata = (name: TEntityMetadataName, fields?: string) => this._getMetadata(toRegister, name, fields);
    toRegister.getFieldDef = (fieldName: string) => this._getFieldDef(toRegister, fieldName);
    toRegister.getId = (model: object) => this._getEntityId(toRegister, model);
    toRegister.setId = (model: object, value: string | number | object) => {
      this._setEntityId(toRegister, model, value);
    };
    toRegister.deleteId = (model: object) => {
      this._deleteEntityId(toRegister, model);
    };
    toRegister.getDescriptionName = (model: object) => this._getEntityDescriptionName(toRegister, model);
    toRegister.getCaption = (detailStateType: EEntityStateDetailType, id: string | number) => this._getEntityCaption(toRegister, detailStateType, id);
    toRegister.getSuccessMessageId = (model: object) => this._getEntitySuccessMessageId(toRegister, model);
    toRegister.deleteProperty = (model: object, propertyName: string) => {
      this._deleteEntityProperty(toRegister, model, propertyName);
    };

    if (!toRegister.pageTitle) {
      toRegister.pageTitle = `global.menu.${toRegister.name}`;
    }
    if (!toRegister.sidebarTitle) {
      toRegister.sidebarTitle = toRegister.pageTitle;
    }

    toRegister.metadata.keyName = toRegister.metadata.keyName || toRegister.metadata.fields[0].name;
    if (!toRegister.metadata.descriptionName) {
      toRegister.metadata.descriptionName = toRegister.metadata.keyName;
    }
    if (!toRegister.metadata.successMessageId) {
      toRegister.metadata.successMessageId = toRegister.metadata.descriptionName;
    }

    toRegister.actions = {
      new: true,
      detail: true,
      edit: true,
      delete: false,
      search: true,
      filter: true,
      duplicate: true,
      batchAdd: false,
      newMultiple: false,
      modernPagination: true,
      ...toRegister.actions
    };
    if (!toRegister.actions.new || !toRegister.actions.edit) {
      toRegister.actions.duplicate = false;
    }
    if (!toRegister.actions.new) {
      toRegister.actions.batchAdd = false;
      toRegister.actions.newMultiple = false;
    }

    if (!isObject(toRegister.list)) {
      toRegister.list = {roles: [], pluginsRoles: [], pageTitle: '', definition: undefined, template: undefined, state: undefined};
    }
    if (!toRegister.list.pageTitle) {
      toRegister.list.pageTitle = toRegister.list.state?.data?.pageTitle || toRegister.pageTitle;
    }

    if (!isBoolean(toRegister.asModule)) {
      toRegister.asModule = true;
    }
    if (toRegister.asModule) {
      this._registerStateDetail(toRegister);
      this._registerStateNew(toRegister);
      this._registerStateEdit(toRegister);
    }

    toRegister.devExpress = this._generateDevExpress(toRegister);
    toRegister.fieldEvents = (fieldName: string) => entityFieldEvents(toRegister, fieldName);

    if (!isFunction(toRegister.getDeleteMessage)) {
      toRegister.getDeleteMessage = (model: unknown, translateService: TranslateService): IEntityDeleteMessage => {
        const evaluatedId: string | number = toRegister.getId(model);
        const evaluatedDescriptionName: string | number = toRegister.getDescriptionName(model);
        const successMessageId: string | number = toRegister.getSuccessMessageId(model);
        const id: string | number = successMessageId ? successMessageId : evaluatedDescriptionName ? `${evaluatedId} - ${evaluatedDescriptionName}` : evaluatedId;
        const title: string = translateService.instant('entity.delete.title', {id: id});
        const message: string = translateService.instant('entity.delete.message');
        return {title: title, message: message};
      };
    }

    const deletePrompt = (args: IEntityDeletePromptArgs<object>): TValueOrPromise<void | IApiRequestConfig> => this._entityDeletePrompt(toRegister, args);
    toRegister.deletePrompt = (args: IEntityDeletePromptArgs<object>) => {
      if (isFunction(toRegister.deletePromptFactory)) {
        return toRegister.deletePromptFactory({...args, superDeletePrompt: deletePrompt});
      }
      return deletePrompt(args);
    };

    this._entities.set(toRegister.name, toRegister);

    if (!isEmpty(toRegister.autocomplete?.output)) {
      if (!toRegister.autocomplete.placeholder && toRegister.searchPlaceholder) {
        toRegister.autocomplete.placeholder = toRegister.searchPlaceholder;
      }
      if (!toRegister.autocomplete.searchFields && toRegister.metadata.searchFields) {
        toRegister.autocomplete.searchFields = toRegister.metadata.searchFields;
      }
      if (toRegister.autocomplete.cacheValues) {
        if (!isObject(toRegister.autocomplete.properties)) {
          toRegister.autocomplete.properties = {};
        }
        if (!isBoolean(toRegister.autocomplete.properties.cacheValues)) {
          toRegister.autocomplete.properties.cacheValues = toRegister.autocomplete.cacheValues;
        }
        if (!toRegister.autocomplete.properties.cacheValuesInstanceId) {
          toRegister.autocomplete.properties.cacheValuesInstanceId = toRegister.name;
        }
      }
      this._plEditRegistryService.map(COMPONENT_NAME_INPUT_ENTITY, toRegister.name, {entity: {name: toRegister.name}});
    }
  }

  public getEntity<T extends object = object>(name: string, clone: boolean = true): IEntity<T> {
    const entity = this._entities.get(name);
    return isDefined(entity) ? (clone !== false ? copy(entity) : entity) : undefined;
  }

  public getAll(clone: boolean = true): Array<IEntity> {
    const entities: Array<IEntity> = [];
    this._entities.forEach((entity: IEntity) => {
      entities.push(clone !== false ? copy(entity) : entity);
    });
    return entities;
  }

  private _buildFormDefinition(entityState: IEntityState, metadata: IEntityMetadata, type: EEntityStateDetailType): void {
    entityState.definition = {
      fields: [],
      events: {}
    };
    for (const metadataField of metadata.fields) {
      if (isBoolean(metadataField.visible) && !metadataField.visible) {
        continue;
      }
      const field: IEntityMetadataField = copy<IEntityMetadataField>(metadataField);

      const newField: IEntityFormDefinitionField = {
        name: field.name,
        type: field.type,
        formName: field.formName,
        caption: field.caption,
        value: field.value,
        events: field.events || undefined,
        properties: field.properties || {},
        validators: field.validators || {},
        visible: isBoolean(field.visible) || isFunction(field.visible) ? field.visible : true,
        disabled: field.disabled === true
      };

      if (newField.name === metadata.keyName || field.disabled === true) {
        newField.properties.disabled = type !== EEntityStateDetailType.NEW;
      }
      if (isObject(metadata.properties)) {
        newField.properties = merge({}, metadata.properties, newField.properties);
      }

      // Handle events
      const events = ['blur', 'change', 'click', 'focus'];
      for (const event of events) {
        if (isFunction(field[event]) && !field.events?.[event]) {
          if (!isObject(newField.events)) {
            newField.events = {};
          }
          newField.events[event] = field[event];
        }
      }

      // Handle validators
      for (const property in newField.validators) {
        if (!Object.prototype.hasOwnProperty.call(newField.validators, property)) {
          continue;
        }
        const validator = newField.validators[property];
        if (!isObject(validator)) {
          newField.validators[property] = {value: validator};
        }
      }

      // Handle private evaluated properties
      if (newField.properties && isFunction(newField.properties.visible)) {
        this._logger.warn(
          "EntityRegistryService field's private evaluated properties (such as `visible` and `disabled`)" +
            "should not be defined inside the field's properties object as it will be overwritten once the" +
            'evaluation is done. Define them in the root object instead (aside `properties` object for example)'
        );
      }

      if (isDefined(field.placeholder)) {
        newField.properties.placeholder = field.placeholder;
      }

      if (isObject(field.entity)) {
        newField.type = 'inputEntity';
        newField.properties.entity = field.entity;
        if (!field.entity.keyTarget) {
          newField.objectMode = false;
        }
      }

      entityState.definition.fields.push(newField);
    }
  }

  private _registerStateDetail(entity: IEntity): void {
    if (!isObject(entity.detail)) {
      // se não tiver detalhe
      entity.detail = {roles: [], pluginsRoles: [], pageTitle: '', definition: undefined, template: undefined, state: undefined};
    }
    this._registerStateGeneric(entity, entity.detail);
    if (!entity.detail.pageTitle) {
      entity.detail.pageTitle = entity.detail.state?.data?.pageTitle || `${entity.name}.title_detail`;
    }
    this._buildFormDefinition(entity.detail, entity.getMetadata('detail'), EEntityStateDetailType.DETAIL);
  }

  private _registerStateNew(entity: IEntity): void {
    if (!entity.actions?.new) {
      return;
    }
    if (!isObject(entity.new)) {
      entity.new = copy(entity.detail);
      entity.new.definition = undefined;
    }
    this._registerStateGeneric(entity, entity.new);
    if (!entity.new.pageTitle) {
      entity.new.pageTitle = entity.new.state?.data?.pageTitle || `${entity.name}.title_new`;
    }
    this._buildFormDefinition(entity.new, entity.getMetadata('new'), EEntityStateDetailType.NEW);
  }

  private _registerStateEdit(entity: IEntity): void {
    if (!entity.actions?.edit) {
      return;
    }
    if (!isObject(entity.edit)) {
      entity.edit = copy(entity.detail);
      entity.edit.definition = undefined;
    }
    this._registerStateGeneric(entity, entity.edit);
    if (!entity.edit.pageTitle) {
      entity.edit.pageTitle = entity.edit.state?.data?.pageTitle || `${entity.name}.title_detail`;
    }
    this._buildFormDefinition(entity.edit, entity.getMetadata('edit'), EEntityStateDetailType.EDIT);
  }

  private _registerStateGeneric(entity: IEntity, entityState: IEntityState): void {
    if (isArray(entity.roles)) {
      entityState.roles = uniq(!isArray(entityState.roles) ? entity.roles : entity.roles.concat(entityState.roles));
      if (entityState.state?.data) {
        entityState.state.data.roles = uniq(!isArray(entityState.state.data.roles) ? entity.roles : entity.roles.concat(entityState.state.data.roles));
      }
    }
    if (isArray(entity.pluginsRoles)) {
      entityState.pluginsRoles = uniq(!isArray(entityState.pluginsRoles) ? entity.pluginsRoles : entity.pluginsRoles.concat(entityState.pluginsRoles));
      if (entityState.state?.data) {
        entityState.state.data.pluginsRoles = uniq(!isArray(entityState.state.data.pluginsRoles) ? entity.pluginsRoles : entity.pluginsRoles.concat(entityState.state.data.pluginsRoles));
      }
    }
    if (isArray(entity.requiredRoles)) {
      entityState.requiredRoles = uniq(!isArray(entityState.requiredRoles) ? entity.requiredRoles : entity.requiredRoles.concat(entityState.requiredRoles));
      if (entityState.state?.data) {
        entityState.state.data.requiredRoles = uniq(!isArray(entityState.state.data.requiredRoles) ? entity.requiredRoles : entity.requiredRoles.concat(entityState.state.data.requiredRoles));
      }
    }
  }

  private _getMetadata(entity: IEntity, name: TEntityMetadataName, fields?: string): IEntityMetadata {
    const metadata: IEntityMetadata = copy(entity.metadata);
    let fieldsList: Array<string> = [];
    let hiddenFieldsList: Array<string> = [];
    let persistentFieldsList: Array<string> = [];
    if (fields) {
      fieldsList = fields.split(',');
    } else if (name === 'list' && metadata.listFields) {
      fieldsList = metadata.listFields.split(',');
    } else if (name === 'detail' && metadata.detailFields) {
      fieldsList = metadata.detailFields.split(',');
    } else if (name === 'new' && (metadata.newFields || metadata.detailFields)) {
      const newFields = metadata.newFields || metadata.detailFields;
      fieldsList = newFields.split(',');
    } else if (name === 'edit' && (metadata.editFields || metadata.detailFields)) {
      const editFields = metadata.editFields || metadata.detailFields;
      fieldsList = editFields.split(',');
    } else if (name === 'filter') {
      if (metadata.filterFields) {
        fieldsList = metadata.filterFields.split(',');
      } else if (metadata.searchFields) {
        fieldsList = metadata.searchFields.split(',');
      } else if (metadata.listFields) {
        fieldsList = metadata.listFields.split(',');
      } else {
        fieldsList = metadata.fields.map((field: IEntityMetadataField) => field.name);
      }
      if (metadata.filterHiddenFields) {
        hiddenFieldsList = metadata.filterHiddenFields.split(',');
      }
      if (metadata.filterPersistentFields) {
        persistentFieldsList = metadata.filterPersistentFields.split(',');
      }
    } else if (name === 'search') {
      if (metadata.searchFields) {
        fieldsList = metadata.searchFields.split(',');
      } else if (metadata.listFields) {
        fieldsList = metadata.listFields.split(',');
      } else {
        return metadata;
      }
    } else {
      return metadata;
    }

    metadata.fields = [];
    for (const item of fieldsList) {
      const fieldDefinition = item.trim();
      const field: IEntityMetadataField = entity.getFieldDef(fieldDefinition);
      if (field) {
        field.hidden = Boolean(hiddenFieldsList.length) && hiddenFieldsList.includes(field.name);
        field.persistent = Boolean(persistentFieldsList.length) && persistentFieldsList.includes(field.name);
        metadata.fields.push(field);
      }
    }
    return metadata;
  }

  private _getEntityId(entity: IEntity, model: object): string | number {
    let id: string | number;
    if (isObject(entity.evaluationMethods) && isFunction(entity.evaluationMethods.evaluateId)) {
      id = entity.evaluationMethods.evaluateId(model);
    } else if (isString(entity.metadata.keyName) && entity.metadata.keyName) {
      try {
        id = entity.metadata.keyName.includes('{{') ? interpolate(entity.metadata.keyName)(model) : get(model, entity.metadata.keyName);
      } catch (error: unknown) {
        id = '';
      }
    }
    return id;
  }

  private _setEntityId(entity: IEntity, model: object, value: string | number | object): void {
    if (!entity || !isObject(model)) {
      return;
    }
    if (entity.metadata.keyName.includes('{{')) {
      if (!isObject(value)) {
        throw new TypeError('Expected a object value when an entity has a complex keyName definition.');
      }
      const keyNameKeys: Array<string> = interpolateTemplateToKeys(entity.metadata.keyName);
      for (const key of keyNameKeys) {
        const keyValue: unknown = get(value, key);
        set(model, key, keyValue);
      }
    } else {
      if (isObject(value)) {
        value = this._getEntityId(entity, <object>value);
      }
      set(model, entity.metadata.keyName, value);
    }
  }

  private _deleteEntityId(entity: IEntity, model: object): void {
    if (!entity) {
      return;
    }
    this._deleteEntityProperty(entity, model, entity.metadata.keyName);
  }

  private _getEntityDescriptionName(entity: IEntity, model: unknown): string {
    if (!entity) {
      return '';
    }
    let descriptionName = '';
    if (isObject(entity.evaluationMethods) && isFunction(entity.evaluationMethods.evaluateDescriptionName)) {
      descriptionName = String(entity.evaluationMethods.evaluateDescriptionName(model));
      try {
        return descriptionName.includes('{{') ? interpolate(descriptionName)(model) : get(model, descriptionName, '');
      } catch (error: unknown) {
        return '';
      }
    } else if (isString(entity.metadata.descriptionName) && entity.metadata.descriptionName) {
      descriptionName = entity.metadata.descriptionName;
    }
    if (descriptionName) {
      try {
        descriptionName = descriptionName.includes('{{') ? interpolate(descriptionName)(model) : get(model, descriptionName, '');
      } catch (error: unknown) {
        // ignored
      }
    }
    return descriptionName;
  }

  private _getFieldDef(entity: IEntity, fieldName: string): IEntityMetadataField {
    if (!isEmpty(fieldName)) {
      fieldName = fieldName.toUpperCase();
      for (const field of entity.metadata.fields) {
        if (fieldName === field.name.toUpperCase()) {
          return field;
        }
      }
    }
    return undefined;
  }

  private _getEntityCaption(entity: IEntity, detailStateType: EEntityStateDetailType, id?: string | number): string {
    if (!entity) {
      return '';
    }
    let caption = `${entity.name}.title_${detailStateType}`;
    const translatedCaption: string = this._translateService.instant(`${entity.name}.title_${detailStateType}`, {id: id});
    caption = caption !== translatedCaption ? translatedCaption : this._translateService.instant(`${entity.name}.title_detail`, {id: id});
    return caption;
  }

  private _getEntitySuccessMessageId(entity: IEntity, model: object): string | number {
    if (!entity || !isObject(model)) {
      return '';
    }
    if (!entity.metadata.successMessageId) {
      return this._getEntityId(entity, model);
    } else if (isFunction(entity.metadata.successMessageId)) {
      return entity.metadata.successMessageId(model);
    }
    try {
      return entity.metadata.successMessageId.includes('{{') ? interpolate(entity.metadata.successMessageId)(model) : get(model, entity.metadata.successMessageId, '');
    } catch (error: unknown) {
      return '';
    }
  }

  private _deleteEntityProperty(entity: string | IEntity, model: object, propertyName: string): void {
    if (!entity || !isObject(model) || !propertyName) {
      return;
    }
    let root: object = model;
    const indexOfPropertyName: number = propertyName.lastIndexOf('.');
    if (indexOfPropertyName !== -1) {
      root = getPathValue(root, propertyName);
      propertyName = propertyName.slice(indexOfPropertyName + 1);
    }
    delete root[propertyName];
  }

  private _entityDeletePrompt(entity: IEntity, {model, injector}: IEntityDeletePromptArgs<object>): Promise<void> {
    const modalService: CGModalService = injector.get<CGModalService>(CGModalService);
    const translateService: TranslateService = injector.get<TranslateService>(TranslateService);
    const {title, message} = entity.getDeleteMessage(model, translateService);
    return modalService.showOkCancel(title, message, {size: 'md', type: 'delete', icon: 'warning'});
  }

  private _generateDevExpress(entity: IEntity): IEntityDevExpress {
    return {
      dataGrid: this._generateDevExpressDataGrid(entity)
    };
  }

  private _generateDevExpressDataGrid(entity: IEntity): IEntityDevExpressDataGrid {
    const dataGrid: IEntityDevExpressDataGrid = merge<object, IDevExpressDataGrid, IDevExpressDataGrid, IDevExpressDataGrid, IEntityDevExpressDataGrid>(
      {},
      this._defaultDataGridConfiguration.dataGrid,
      {
        export: {
          filename: `global.menu.${entity.name}`
        },
        searchPanel: {
          visible: Boolean(entity.actions.search)
        }
      },
      entity.devExpress?.dataGrid,
      {
        columns: [],
        remoteOperations: {
          filtering: true,
          grouping: false,
          groupPaging: false,
          paging: true,
          sorting: true,
          summary: false
        },
        getMetadataColumns: (name: TEntityMetadataName, fields?: string) => this._devExpressDataGridGetColumns(dataGrid, entity, name, fields)
      }
    );
    dataGrid.columns = [
      ...entity.metadata.fields.map<IDevExpressDataGridColumn>((field: IEntityMetadataField) => {
        const hasSearchFields = !isEmpty(entity.metadata.searchFields);
        const searchFields = hasSearchFields ? entity.metadata.searchFields.split(',') : undefined;
        const column: IDevExpressDataGridColumn = merge<object, IDevExpressDataGridColumn, IDevExpressDataGridColumn, IDevExpressDataGridColumn>(
          {},
          this._defaultDataGridConfiguration.columns,
          {
            dataField: field.name,
            dataType: <TDevExpressDataGridColumnDataType>field.type,
            caption: field.caption,
            alignment: field.align,
            visible: isBoolean(field.visible) ? field.visible : undefined,
            width: field.width
          },
          field.properties?.devExpress?.dataGrid
        );
        if (
          isNumber(field.properties?.decimalsLimit) &&
          field.properties?.decimalsLimit !== DEV_EXPRESS_DEFAULT_DECIMALS_LIMIT &&
          !isString(column.format) &&
          !isFunction(column.format) &&
          (!isObject(column.format) || !isNumber((<IDevExpressFormatObject>column.format).decimalsLimit))
        ) {
          if (column.dataType === 'number') {
            column.dataType = 'double';
          }
          if (!isObject(column.format)) {
            column.format = {};
          }
          (<IDevExpressFormatObject>column.format).decimalsLimit = field.properties.decimalsLimit;
        }
        if (hasSearchFields && !searchFields.includes(column.dataField || column.name) && !isBoolean(column.allowSearch)) {
          column.allowSearch = false;
        }
        return column;
      }),
      {
        type: 'buttons',
        name: DATAGRID_COLUMN_NAME_ENTITY_LIST_BUTTONS,
        headerCellTemplate: DATAGRID_TEMPLATE_ENTITY_LIST_HEADER_ACTIONS,
        cellTemplate: DATAGRID_TEMPLATE_ENTITY_LIST_ITEM_ACTIONS,
        allowEditing: false,
        allowExporting: false,
        allowFiltering: false,
        allowFixing: false,
        allowGrouping: false,
        allowHeaderFiltering: false,
        allowHiding: true,
        allowReordering: false,
        allowResizing: false,
        allowSearch: false,
        allowSorting: false,
        showInColumnChooser: false,
        visible: false
      }
    ];
    return dataGrid;
  }

  private _devExpressDataGridGetColumns(dataGrid: IEntityDevExpressDataGrid, entity: IEntity, name: TEntityMetadataName, fields?: string): Array<IDevExpressDataGridColumn> {
    const fieldsNames: Array<string> = this._getMetadata(entity, name, fields).fields.map<string>((metadataField: IEntityMetadataField) => metadataField.name);
    return copy(
      dataGrid.columns
        .filter((dataGridField: IDevExpressDataGridColumn) => {
          return (
            !isEmpty(dataGridField.type) ||
            (fieldsNames.includes(dataGridField.dataField) && (name !== 'filter' || dataGridField.allowFiltering !== false) && (name !== 'search' || dataGridField.allowSearch !== false))
          );
        })
        .sort((a: IDevExpressDataGridColumn, b: IDevExpressDataGridColumn) => {
          if (a.name === DATAGRID_COLUMN_NAME_ENTITY_LIST_BUTTONS) {
            return 1;
          }
          if (b) {
            return b.name !== DATAGRID_COLUMN_NAME_ENTITY_LIST_BUTTONS ? fieldsNames.indexOf(a.dataField) - fieldsNames.indexOf(b.dataField) : -1;
          }
          return 0;
        })
    );
  }
}
