<div class="modal-header">
  <button type="button" class="btn-close" (click)="close()"></button>
  <h4 class="modal-title" [translate]="'docfasNums.modal.comunicacao.title'"></h4>
</div>

<div class="modal-body">
  <pl-alert type="info" *ngIf="isAutoFaturacao && model.estado !== typeEstadoSerie.Ativa" [closeable]="false">
    <div class="d-flex" style="gap: 5px">
      <div><i class="fa fa-fw fa-info-circle" aria-hidden="true"></i></div>
      <div style="font-size: 12px">
        <div class="mb-2 d-flex" style="justify-content: space-between; align-items: center">
          <span [translate]="getAutoFaturacaoMessage1()"></span>
          <pl-tooltip [config]="{text: 'docfasNums.modal.comunicacao.atSeriesLinkTooltip', placement: 'top', container: 'body'}">
            <a style="color: #ffffff" target="_blank" href="https://processos.portaldasfinancas.gov.pt/series/pf/inicio?modo=false" *plTooltipContent><i class="fa fa-link" aria-hidden="true"></i></a>
          </pl-tooltip>
        </div>
        <div class="mb-2" [translate]="getAutoFaturacaoMessage2()"></div>
        <div [translate]="getAutoFaturacaoMessage3()"></div>
      </div>
    </div>
  </pl-alert>

  <div class="comunicacaoSeriesAt-modal-fields">
    <pl-group *ngIf="isAutoFaturacaoComAcordo">
      <label [translate]="'docfasNums.modal.comunicacao.fields.nifFornecedor'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.atAfNifTerceiro" [properties]="{disabled: true}"></pl-edit>
      </edit>
    </pl-group>
    <pl-group *ngIf="isAutoFaturacaoSemAcordo">
      <label [translate]="'docfasNums.modal.comunicacao.fields.enquadramento'"></label>
      <edit>
        <pl-tooltip [config]="{text: getEnquadramentoTooltip(), placement: 'top', container: 'body'}">
          <pl-edit-select
            *plTooltipContent
            [(model)]="model.enquadramento"
            [properties]="{select: {list: enquadramentoList}, disallowClear: true, disabled: model.estado === typeEstadoSerie.Ativa}"></pl-edit-select>
        </pl-tooltip>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'docfasNums.modal.comunicacao.fields.serie'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.serie" [properties]="{disabled: true}"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'docfasNums.modal.comunicacao.fields.tipoSerie'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.tipoSerie" [properties]="{disabled: true}"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'docfasNums.modal.comunicacao.fields.classeDoc'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.classeDoc" [properties]="{disabled: true}"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'docfasNums.modal.comunicacao.fields.tipoDoc'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.tipoDoc" [properties]="{disabled: true}"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'docfasNums.modal.comunicacao.fields.numInicialSeq'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.numInicialSeq" [properties]="{disabled: model.estado === typeEstadoSerie.Ativa}"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'docfasNums.modal.comunicacao.fields.dataInicioPrevUtiliz'"></label>
      <edit>
        <pl-edit type="date" [(model)]="model.dataInicioPrevUtiliz" [properties]="{disabled: model.estado === typeEstadoSerie.Ativa}"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'docfasNums.modal.comunicacao.fields.meioProcessamento'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.meioProcessamento" [properties]="{disabled: true}"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'docfasNums.modal.comunicacao.fields.numCertificado'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.numeroCertificado" [properties]="{disabled: true}"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'docfasNums.modal.comunicacao.fields.codValidacaoSerie'"></label>
      <edit>
        <pl-edit type="text" [(model)]="model.codValidacaoSerie" [properties]="{disabled: model.estado === typeEstadoSerie.Ativa}"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'docfasNums.modal.comunicacao.fields.estado'"></label>
      <edit>
        <pl-edit [ngClass]="{'comunicacaoSeriesAt-active': model.estado === 'Ativa'}" type="text" [(model)]="model.estado" [properties]="{disabled: true}"></pl-edit>
      </edit>
    </pl-group>
  </div>
</div>

<div class="modal-footer" plNavigation>
  <button *ngIf="model.estado === typeEstadoSerie.Ativa && !isRegisterExecuted" class="btn btn-warning btn-sm" type="button" [click]="fnFinalizarSerie" plPromise>
    <i class="fa fa-fw fa-lock"></i><span [translate]="'docfasNums.modal.comunicacao.btn.finalizar'"></span>
  </button>

  <ng-container *ngIf="model.estado === typeEstadoSerie.None">
    <ng-container *ngIf="!isAutoFaturacao">
      <div ngbDropdown container="body">
        <div class="btn-group">
          <button class="btn btn-success btn-sm" type="button" [click]="fnRegistarWebService" plPromise>
            <i class="fa fa-fw fa-globe"></i><span [translate]="'docfasNums.modal.comunicacao.btn.registarWebService'"></span>
          </button>
          <button class="btn btn-success btn-sm" type="button" ngbDropdownToggle></button>
        </div>

        <div ngbDropdownMenu>
          <button type="button" [click]="fnRegistarManualmente" plPromise ngbDropdownItem>
            <i class="fa fa-fw fa-edit"></i><span [translate]="'docfasNums.modal.comunicacao.btn.registarManual'"></span>
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isAutoFaturacao">
      <button class="btn btn-success btn-sm" type="button" [click]="fnRegistarManualmente" plPromise>
        <i class="fa fa-fw fa-edit"></i><span [translate]="'docfasNums.modal.comunicacao.btn.registarManual'"></span>
      </button>
    </ng-container>
  </ng-container>

  <button type="button" class="btn btn-light btn-sm" (click)="close()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
