<div class="com-categoria-edit">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [formInstance]="formInstance"
    [serviceMethodsOverride]="serviceMethodsOverride"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form (formInstanceChange)="formInstance = $event" [properties]="{readonly: type === entityStateDetailType.DETAIL}">
        <pl-group>
          <label [translate]="'comcategorias.fields.nome'"></label>
          <edit>
            <pl-edit type="text" attrName="nome" [(model)]="model.nome" [properties]="{validators: {required: {value: true}, maxlength: {value: 50}}}" plAutoFocus></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'comcategorias.fields.cor'"></label>
          <edit>
            <dx-color-box
              [(value)]="model.cor"
              [applyValueMode]="'instantly'"
              [acceptCustomValue]="false"
              [openOnFieldClick]="true"
              (onValueChanged)="evaluateColorValid()"
              [disabled]="type === entityStateDetailType.DETAIL"></dx-color-box>
            <div class="form-control-messages" *ngIf="colorInvalid">
              <div class="form-control-messages-error">
                <span [translate]="'gestaoComunicacoes.common.colorInvalid'"></span>
              </div>
            </div>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'comcategorias.fields.todasEmpresas'"></label>
          <edit>
            <pl-edit type="bool" attrName="todasEmpresas" [model]="model.todasEmpresas" (modelChange)="model.todasEmpresas = $event; todasEmpresasChanged($event)"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'comcategorias.fields.nEmpresa'"></label>
          <edit>
            <pl-autocomplete
              attrName="nEmpresa"
              [model]="model.nEmpresa"
              (modelChange)="changedEmpresa($event)"
              [source]="empresasSource"
              [rowTemplate]="rowTemplateEmpresa"
              [output]="'nEmpresa'"
              [allowInvalid]="false"
              [allowEmpty]="false"
              [properties]="{validators: {required: {value: !model.todasEmpresas}, maxlength: {value: 3}}, disabled: model.todasEmpresas}">
            </pl-autocomplete>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
