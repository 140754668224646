export enum ECaixaAtivaOperacao {
  None,
  ComercialCompras,
  ComercialVendas,
  FinanceiroRecibos,
  FinanceiroPagamentos,
  FinanceiroTransferencias,
  FinanceiroRendas,
  RHRemuneracao,
  RHTicketRefeicao
}

export interface IJsonMeioPagamento {
  nCaixa: number;
  nome: string;
  tipo: number;
  nConta: string;
  nomeConta: string;
  tipoMovimento: number;
  codMoeda: number;
  swift?: string;
  nib?: string;
  porDefeito?: string;
  caixaativaoperacaolst?: string;
}
