import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {copy, IPlToolbarItem, isDefinedNotNull, PlAlertService} from 'pl-comps-angular';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {ComunicacaoSeriesATModalComponent} from '../../../../modules/comunicacaoseriesat/comunicacao/comunicacaoseriesat/comunicacaoSeriesAt.modal.component';
import {DocFasNumsComunicacaoLoginModalComponent} from '../../../../modules/docfasnumscomunicacaologin/docFasNumsComunicacaoLogin.modal.component';
import {DocumentosService} from '../../service/documentos.entity.service';
import {EConfigOptionsInstanceName} from '../../../../services/config/options/config.options.service.interface';
import {EEntityStateDetailType, entityStateDetailQueryParam} from '../../../../../common/utils/entity.state.utils';
import {EFACCBHASHEstado} from '../../jsonDocComercial.entity.interface';
import {EGroupName} from '../../../../../config/constants';
import {EGrupoDoc} from '../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {ERROR_AUTH_SUBSTRING} from '../../../../services/docfasNumsComunicacaoLogin/docfasNumsComunicacaoLogin.service.interface';
import {ENTITY_NAME_DOCFAS_NUMS, ERROR_SERIE_NAO_COMUNICADA} from '../../../docfasnums/docfasNums.entity.interface';
import {ETipoAutoFaturacao} from '../../../docfas/jsonDocFa.entity.interface';
import {hasAuthority} from '../../../../../common/utils/roles.utils';
import {IApiRequestConfigWithBody} from '../../../../services/api/api.service.interface';
import {IDocComercial, IDocComercialCab, IDocComercialEditStateParams, IDocsComerciaisEntityService, IDocsComerciaisGroup} from '../../docsComerciais.entity.interface';
import {IDocComercialArquivoDigitalAttachment, IDocComercialCallback, IDropdownActionsDocComercial} from '../component/docsComerciais.component.interface';
import {IEntityEvaluationMethods} from '../../../../components/entity/entity.definition.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IJsonDocfaNum, IJsonDocfaNumATConsulta} from '../../../docfasnums/jsonDocfaNum.entity.interface';
import {IModuleActionDefinition} from '../../../../components/module/module.definition.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {ROLE} from '../../../../services/role.const';
import {TClifos} from '../../../clifos/clifos.entity.interface';
import {TDocsComerciaisSaveModalResultType} from '../../modals/save/docsComerciais.save.modal.component.interface';
import {MODULE_NAME_MOVIMENTACOES} from '../../../../modules/movimentacoes/movimentacoes.module.interface';
import {EComunicacaoSeriesATType} from '../../../../modules/comunicacaoseriesat/comunicacaoseriesat.interface';
import {ComunicacaoSeriesAtService} from '../../../../modules/comunicacaoseriesat/comunicacaoseriesat.service';

const BAR_BUTTON_PDF_PREVIEW_ORDER = 109;
const MENU_ALT_CAMPOS_SERIE_ORDER = 206;
const MENU_ITEM_ARRED_MANUAL_ORDER = 207;
const MENU_ITEM_RASTREABILIDADE_ORDER = 208;
const MENU_ITEM_GESTAO_ENCOMENDAS = 209;
const MENU_ITEM_ADIANT_REG_DOC_ATUAL = 210;
// const MENU_ITEM_ADIANT_REG_DOC_NEW = 211;
const MENU_ITEM_ADIANT_CONSULT = 212;
const MENU_ITEM_DEVOLUCOES = 213;
const ACTION_MEIOS_PAG_ORDER = 204;
const TOOLBAR_GROUP_NAME = 'docscomerciais-edit';

@Component({
  selector: 'docscomerciais-edit',
  templateUrl: './docsComerciais.entity.edit.component.html'
})
export class DocsComerciaisEditComponent extends ModuloEntityDetailComponent<IDocComercial, IDocsComerciaisEntityService> implements OnInit {
  @Input() public group: IDocsComerciaisGroup;
  @Input() public empresaTemContabilidadeDigital: boolean;
  @Input() public contabilidadeDigital: boolean;
  @Input() public cgStoreUrl: boolean;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly stateParams: IDocComercialEditStateParams;
  public readonly evaluationMethods: IEntityEvaluationMethods<IDocComercial>;
  public docComercialCallback: IDocComercialCallback;
  public clifoEntName: TClifos;
  public comercialAdvanced: boolean;
  public lastDoc: IDocComercial;
  public showLicenseNotActivatedCGOnError: boolean;

  private readonly _servicosDocfanums: IEntityService<IJsonDocfaNum>;
  private _storeModePublic: boolean;
  private _docComercialArquivoDigitalAttachment: IDocComercialArquivoDigitalAttachment;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _documentosService: DocumentosService,
    private readonly _plAlertService: PlAlertService,
    private readonly _comunicacaoSeriesAtService: ComunicacaoSeriesAtService
  ) {
    super(_injector);
    this.configOptionsInstanceName = this._documentosService.configOptionsInstanceName;
    this.configOptionsGroupName = this._documentosService.configOptionsGroupName;

    this.docComercialCallback = {};
    this.comercialAdvanced = hasAuthority(this.session, ROLE.ERPADVANCED);
    this.evaluationMethods = {
      evaluateId: (docComercial: IDocComercial) => this._evaluateId(docComercial)
    };

    this.stateParams = <IDocComercialEditStateParams>this._transition.params();
    this.showLicenseNotActivatedCGOnError = false;

    this._servicosDocfanums = this._entityServiceBuilder.build<IJsonDocfaNum, IEntityService<IJsonDocfaNum>>(ENTITY_NAME_DOCFAS_NUMS);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const isDocumentoTerminado = this.type !== EEntityStateDetailType.NEW && !this._docComercialEmEdicao(this.model?.cab);
    this._storeModePublic = this.configurations.licenca.storeModePublic;
    this.clifoEntName = this.group.clifos ? this.group.clifos : undefined;
    const btnSave = this.toolbar.find('save');
    this.contabilidadeDigital = false;
    if (this.type === EEntityStateDetailType.NEW && this.group.grupo === EGrupoDoc.ComprasEfectivas && (this.empresaTemContabilidadeDigital || this._storeModePublic)) {
      this.toolbar.addButton({
        groupId: TOOLBAR_GROUP_NAME,
        id: 'comercialDigital',
        type: 'button',
        order: 11,
        class: !this.contabilidadeDigital ? 'btn-info' : 'btn-info active',
        iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>&nbsp;',
        caption: 'docscomerciais.toolbar.arquivoDigital',
        visible: false,
        click: (btnContabilidadeDigital: IPlToolbarItem) => {
          if (!this.empresaTemContabilidadeDigital && this._storeModePublic) {
            this.showLicenseNotActivatedCGOnError = true;
            return;
          }
          this.contabilidadeDigital = !this.contabilidadeDigital;
          this._evaluateMaintenanceModeFullscreen();
          // this.callbackDocContabilidade.prepareFocus();
          btnContabilidadeDigital.class = !this.contabilidadeDigital ? 'btn-info' : 'btn-info active';
          // this._btnSaveWithoutAttach.visible = this.contabilidadeDigital;
          if (this.isMobile) {
            this.btnSave.type = !this.contabilidadeDigital ? 'button' : 'dropdown-split';
          }
        }
      });
    }
    if (isDefinedNotNull(btnSave)) {
      btnSave.caption = 'docscomerciais.toolbar.finalizarDocumento';
      btnSave.iconLeft = '<i class="fa fa-fw fa-check-circle-o"></i>';
      btnSave.class = 'btn-success';
      btnSave.visible = !isDocumentoTerminado;
    }

    const moduleActionOrActions: Array<IModuleActionDefinition> = [
      {
        caption: 'docscomerciais.auxiliares.copiaDocumentoLinhas', // this._translateService.instant('entity.maintenance.headerMaintenance'),
        tooltip: {
          text: 'docscomerciais.auxiliares.tooltips.copiaDocumentoLinhasTooltip',
          placement: 'right',
          container: 'body'
        },
        iconLeft: '<i class="fa fa-fw fa-arrow-circle-down"></i>',
        order: 203,
        action: () => this.docComercialCallback.copiarDocumento()
      },
      {
        caption: 'docscomerciais.auxiliares.importarLinhasTemplate', // this._translateService.instant('entity.maintenance.headerMaintenance'),
        tooltip: {
          text: 'docscomerciais.auxiliares.tooltips.importarLinhasTemplateTooltip',
          placement: 'right',
          container: 'body'
        },
        iconLeft: '<i class="fa fa-fw fa-cloud-download" aria-hidden="true"></i>',
        action: () => this.docComercialCallback.importadorLinhas()
      },
      {
        caption: 'docscomerciais.auxiliares.meiosPagamento',
        iconLeft: '<i class="fa fa-credit-card fa-fw"></i>',
        order: ACTION_MEIOS_PAG_ORDER,
        disabled: true,
        action: () => this.docComercialCallback.addMeioPagamento()
      },
      {
        caption: 'docscomerciais.auxiliares.calcular',
        order: 205,
        iconLeft: '<i class="fa fa-fw fa-calculator"></i>',
        action: () => this.docComercialCallback.calc()
      },
      {
        caption: 'docscomerciais.auxiliares.arredondamentoManual',
        order: MENU_ITEM_ARRED_MANUAL_ORDER,
        disabled: true,
        iconLeft: '<i class="fa fa-fw fa-arrows-v"></i>',
        action: () => this.docComercialCallback.arredondamentoManual()
      },
      {
        caption: 'docscomerciais.auxiliares.alterarCamposSerie',
        order: MENU_ALT_CAMPOS_SERIE_ORDER,
        disabled: true,
        iconLeft: '<i class="fa fa-fw fa-list"></i>',
        action: () => this.docComercialCallback.alterarCamposEmSerie()
      },
      {
        caption: 'docscomerciais.auxiliares.rastreabilidade',
        order: MENU_ITEM_RASTREABILIDADE_ORDER,
        disabled: true,
        iconLeft: '<i class="fa fa-fw fa-binoculars"></i>',
        action: () => this.docComercialCallback.showRastreabilidade()
      },
      {
        caption: 'docscomerciais.auxiliares.gestaoEncomendas',
        order: MENU_ITEM_GESTAO_ENCOMENDAS,
        disabled: !this.comercialAdvanced || this.group.grupo === EGrupoDoc.EncomendasClientes || this.group.grupo === EGrupoDoc.EncomendasFornecedores,
        iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
        action: () => this.docComercialCallback.encomendasPendentes()
      },
      {
        caption: 'documentoFaturacao.btn.notaCredito',
        order: MENU_ITEM_DEVOLUCOES,
        disabled: !this.model?.cab?.isDevolucao || this.model?.cab?.terminado,
        iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
        action: () => this.docComercialCallback.showModalDevolucoes()
      }
    ];
    if (this.clifoEntName && (this.group.grupo === EGrupoDoc.VendasEfectivas || this.group.grupo === EGrupoDoc.ComprasEfectivas)) {
      moduleActionOrActions.push(
        {
          caption: 'docscomerciais.actions.adiantRegDoc',
          order: MENU_ITEM_ADIANT_REG_DOC_ATUAL,
          disabled: this.model?.cab?.faccbId < 1 || this.model?.cab?.terminado || this.model?.cab?.anulado,
          iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
          action: () => this.docComercialCallback.adiantRegistaDoc()
        },
        {
          caption: 'docscomerciais.actions.adiantConsult',
          order: MENU_ITEM_ADIANT_CONSULT,
          disabled: false,
          iconLeft: '<i class="fa fa-fw fa-list-alt"></i>',
          action: () => this.docComercialCallback.regularizarAdiantamentosModal()
        }
      );
    }

    if (!isDocumentoTerminado) {
      this.setModuleActions(moduleActionOrActions);

      this.toolbar.addButton({
        id: 'doccomerciaisPdfPreview',
        type: 'button',
        order: BAR_BUTTON_PDF_PREVIEW_ORDER,
        class: 'btn-primary',
        iconLeft: '<i class="fa fa-fw fa-save"></i>',
        caption:
          this.model?.cab && this._docComercialEmEdicao(this.model.cab) && this.model.cab.hashEstado === EFACCBHASHEstado.DocAssinado
            ? 'docscomerciais.toolbar.guardar'
            : 'docscomerciais.toolbar.guardarRascunho',
        click: () => this.pdfPreview()
      });
    }

    if (this.type === EEntityStateDetailType.EDIT) {
      this.params = `NDocFaDraft=${this.model.cab.nDocFa}&NNumerDraft=${this.model.cab.nNumer}&NDocumentoDraft=${this.model.cab.nDocumentoDraft}`;
    }

    if (this.stateParams.grupo !== undefined) {
      this.btnBack.click = () => this._cgStateService.redirectToState({stateOrName: MODULE_NAME_MOVIMENTACOES, params: {grupo: this.stateParams.grupo}});
    }
  }

  public async save(): Promise<IDocComercial> {
    await this.docComercialCallback.perguntaSeFinalizaDoc();

    await this.docComercialCallback.syncAndCheckPrestacoes();

    const resultDoc: IDocComercial = await this.docComercialCallback.obrigatorioMeiosPagamento();

    const promise = new Promise<IDocComercial>((resolve, reject) => {
      this.model = resultDoc;

      const config: IApiRequestConfigWithBody<IDocComercial> = {
        body: copy(this.model),
        params: {
          terminadoc: 1,
          anexargdocid: this.contabilidadeDigital && this.type === EEntityStateDetailType.NEW ? this._docComercialArquivoDigitalAttachment.gDocId : '',
          anexargdocfolderid: this.contabilidadeDigital && this.type === EEntityStateDetailType.NEW ? this._docComercialArquivoDigitalAttachment.gDocFolderId : ''
        }
      };

      Promise.resolve(this.callback.save(config))
        .then((response: IDocComercial) => {
          this.docComercialCallback.afterEntitySave(response);

          // avaliar se tem impacto ter o callback depois da funcao *_setDefaultTipoDoc*
          this.callback.new();

          this.getpdf();
          resolve(response);
          return response;
        })
        .catch((error: unknown) => {
          if (error instanceof HttpErrorResponse) {
            const exception = this._cgExceptionService.get(error);
            if (exception.message.includes(ERROR_AUTH_SUBSTRING)) {
              return this._cgModalService
                .showVanilla(DocFasNumsComunicacaoLoginModalComponent, {size: 'sm'})
                .result.then(() => {
                  return this.save();
                })
                .catch(() => {
                  this._plAlertService.error(exception.message);
                });
            } else if (exception.message.includes(ERROR_SERIE_NAO_COMUNICADA) && this.model.cab.autoFaturacao && this.model.cab.autoFaturacao !== ETipoAutoFaturacao.None) {
              return this._callComunicarSerieAutofaturacaoAT('save', error, this.model.cab.nDocFa, this.model.cab.nNumer);
            }
            this._plAlertService.error(exception.message);
          } else {
            this._logger.error(error);
          }
          reject(error);
          return undefined;
        });
    });

    this._subjectOnSave.next(promise);
    return promise;
  }

  public async pdfPreview(): Promise<void> {
    await this.docComercialCallback.syncAndCheckPrestacoes();

    const resultDoc: IDocComercial = await this.docComercialCallback.obrigatorioMeiosPagamento();

    this.model = resultDoc;
    if (this.model.cab.faccbId === 0) {
      return this._documentosService
        .postDoc(this.model, false, false)
        .then((response: HttpResponse<IDocComercial>) => {
          if (this.type === EEntityStateDetailType.NEW) {
            if (this.model.meiosPagamento && this.model.meiosPagamento.length > 0) {
              response.body.meiosPagamento = [];
              response.body.meiosPagamento = copy(this.model.meiosPagamento);
            }
            response.body.cab.identificaCarga = this.model.cab.identificaCarga;
            const stateParams: IDocComercialEditStateParams = {id: response.body.cab.faccbId, doc: copy(response.body)};
            stateParams[entityStateDetailQueryParam(this.entity.name)] = EEntityStateDetailType.EDIT;
            this._stateService.go(this.states.detail.name, stateParams);
          }
          return this._documentosService.getPdf(response.body);
        })
        .catch((error: unknown) => {
          let erroMsg = '';
          if (error instanceof HttpErrorResponse) {
            const exception = this._cgExceptionService.get(error);
            if (exception) {
              erroMsg = exception.message;
            } else {
              erroMsg = error.message;
            }
          } else {
            this._logger.error(error);
          }
          this._plAlertService.error(erroMsg);
          return Promise.reject(new Error(erroMsg));
        });
    }
    return this._documentosService
      .patchDoc(this.model)
      .then((response: HttpResponse<IDocComercial>) => {
        if (this.type === EEntityStateDetailType.EDIT) {
          if (this.model.meiosPagamento && this.model.meiosPagamento.length > 0) {
            response.body.meiosPagamento = [];
            response.body.meiosPagamento = copy(this.model.meiosPagamento);
          }
          response.body.cab.identificaCarga = this.model.cab.identificaCarga;
        }
        return this._documentosService.getPdf(response.body);
      })
      .catch((error: unknown) => {
        let erroMsg = '';
        if (error instanceof HttpErrorResponse) {
          const exception = this._cgExceptionService.get(error);
          if (exception) {
            erroMsg = exception.message;
          } else {
            erroMsg = error.message;
          }
        } else {
          this._logger.error(error);
        }
        this._plAlertService.error(erroMsg);
        return Promise.reject(new Error(erroMsg));
      });
  }

  public getpdf(): Promise<void> | void {
    return this._documentosService.getPdf(this.lastDoc, this.lastDoc.cab.nDocumento === 0 || !this.lastDoc.cab.terminado);
  }

  public dropdownActionsEvaluate(event: IDropdownActionsDocComercial): void {
    this.dropdownActions.menu.find((item) => item.order === event.id).disabled = event.validator;
  }

  public comercialArquivoDigitalAttachmentChange(event: IDocComercialArquivoDigitalAttachment): void {
    this._docComercialArquivoDigitalAttachment = event;
  }

  public setActionEnabled(event: IDropdownActionsDocComercial): void {
    this.dropdownActions.menu.forEach((item) => {
      if (item.order === event.id) {
        item.disabled = !event.validator;
        item.visible = event.visible ? event.visible : event.validator;
      }
    });
  }

  protected _onConfigurationsChanged(): void {
    if (!this.configurations.empresa.verificacaoEfetuada && !this.hybridMode) {
      this._configService.checkConfiguracaoVerificada();
    }
  }

  private _callComunicarSerieAutofaturacaoAT(type: TDocsComerciaisSaveModalResultType, error: unknown, nDocfa: number, nNumer: number): Promise<void> {
    return this._servicosDocfanums.query({pesquisa: `nDocfa=${nDocfa}&nNumer=${nNumer}`}).then((result) => {
      if (result && result.body.list.length === 1) {
        const idDocfaNum = result.body.list[0].idDocfaNum;
        return this._comunicacaoSeriesAtService.getSerieComunicadaAT(EComunicacaoSeriesATType.DOCFANUM, idDocfaNum).then((response) => {
          const modalInstance = this._cgModalService.showVanilla(ComunicacaoSeriesATModalComponent, {size: 'md'});
          if (this.model.cab.autoFaturacao === ETipoAutoFaturacao.ParaFornecedoresComAcordo) {
            response.body.atAfNifTerceiro = response.body.atAfNifTerceiro ? response.body.atAfNifTerceiro : this.model.cab.nif;
            response.body.nConta = response.body.nConta ? response.body.nConta : this.model.cab.nConta;
          }
          const componentInstance: ComunicacaoSeriesATModalComponent = modalInstance.componentInstance;
          componentInstance.serieType = EComunicacaoSeriesATType.DOCFANUM;
          componentInstance.nSerie = idDocfaNum;
          componentInstance.model = response.body;
          componentInstance.autoFaturacao = this.model.cab.autoFaturacao;
          modalInstance.result.then((data: IJsonDocfaNumATConsulta) => {
            if (data.sucesso) {
              return this.save();
            }
            return undefined;
          });
        });
      }
      return undefined;
    });
  }

  private _evaluateId(docComercial: IDocComercial): number {
    return docComercial?.cab?.faccbId;
  }

  private _evaluateMaintenanceModeFullscreen(): void {
    if (this.maintenanceMode) {
      this.setMaintenanceModeFullscreen(this.empresaTemContabilidadeDigital && this.contabilidadeDigital);
    }
  }

  private _docComercialEmEdicao(cab: IDocComercialCab): boolean {
    return (
      cab &&
      (cab.hashEstado === EFACCBHASHEstado.Indefinido ||
        cab.hashEstado === EFACCBHASHEstado.Rascunho ||
        ((cab.hashEstado === EFACCBHASHEstado.DocSemAssinatura || cab.hashEstado === EFACCBHASHEstado.DocAssinado) && !cab.terminado))
    );
  }
}
