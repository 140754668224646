import {ModuloComponent} from '../../../../components/module/module.component';
import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ETipoDiferimento, ETipoOperacaoDiferimentos, IJsonMapaDiferimentosAcrescimosMonthGeral} from '../../jsonCalculoDiferimentos.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumn, IDevExpressDataGridSummaryTotalItems} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {Subscription} from 'rxjs';
import {DiferimentosService} from '../../diferimentos.module.service';
import {PcaContabilidadeService} from '../../../portalclientaccounts/contabilidade/pca.contabilidade.module.service';
import {EPcaContabilidadeServiceOptionsDefaultYear, IPcaContabilidadeServiceRefreshedEvent} from '../../../portalclientaccounts/contabilidade/pca.contabilidade.module.service.interface';
import {HttpResponse} from '@angular/common/http';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {EDateMonth, PlAlertService} from 'pl-comps-angular';
import {IJsonDocContabilidade} from '../../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {
  IMapaDiferimentosAcrescimos,
  IMapaDiferimentosAcrescimosDynamic,
  IMapaDiferimentosAcrescimosGeral,
  IMapaDiferimentosAcrescimosYear,
  ITipoDiferimentoSourceItem,
  ITipoOperacaoDiferimentoSourceItem,
  MODULE_NAME_INFO_GLOBAL_DIFERIMENTOS_ACRESCIMOS
} from '../../diferimentos.module.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {groupBy, orderBy, upperFirst} from 'lodash-es';
import {IDictionary} from '../../../../../common/interfaces/interfaces';
import {IJsonPerioMudaPrimeiroMesContab} from '../../../../entities/empresas/empresas.entity.interface';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';
import {DATA_SOURCE_MESES_ABREVIADO} from '../../../../datasources/mesesAbreviado/mesesAbreviado.datasource';
import dxDataGrid from 'devextreme/ui/data_grid';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';

const FIELD_ACRESCIMO_NAME = 'acrescimo';
const FIELD_DIFERIMENTO_NAME = 'diferimento';

@Component({
  selector: 'module-mapa-diferimentos-acrescimos',
  templateUrl: './mapaDiferimentosAcrescimos.module.component.html'
})
export class MapaDiferimentosAcrescimosModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public empresaMesContab: IJsonPerioMudaPrimeiroMesContab;
  @Input() public empresaTemContabilidadeDigital: boolean;

  public readonly tipoOperacaoDiferimentosSource: Array<ITipoOperacaoDiferimentoSourceItem>;
  public readonly tipoDiferimentosSource: Array<ITipoDiferimentoSourceItem>;

  public tipoOperacaoDiferimentosSourceItem: ITipoOperacaoDiferimentoSourceItem;
  public tipoDiferimentosSourceItem: ITipoDiferimentoSourceItem;
  public dataGridDefinition: IDevExpressDataGrid;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private readonly _maintenanceInfoGlobalDiferimentosAcrescimos: IModuleMaintenanceInstance;

  private _mapaAcrescimosDiferimentos: Array<IMapaDiferimentosAcrescimosGeral>;
  private _subscriptionOnRefresh: Subscription;
  private _cardPanel: CGCardPanelComponent;
  private _datagridInstance: dxDataGrid;
  private _selectedYear: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _calculoDiferimentosService: DiferimentosService,
    private readonly _pcaContabilidadeService: PcaContabilidadeService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._maintenanceInfoGlobalDiferimentosAcrescimos = this._moduleMaintenanceService.build(MODULE_NAME_INFO_GLOBAL_DIFERIMENTOS_ACRESCIMOS);
    this.tipoOperacaoDiferimentosSource = [
      {value: ETipoOperacaoDiferimentos.Acrescimo, label: this._translateService.instant('mapadiferimentosacrescimos.acrescimos')},
      {value: ETipoOperacaoDiferimentos.Diferimento, label: this._translateService.instant('mapadiferimentosacrescimos.diferimentos')},
      {value: ETipoOperacaoDiferimentos.Ambos, label: this._translateService.instant('mapadiferimentosacrescimos.acrescimosDiferimentos')}
    ];
    this.tipoDiferimentosSource = [
      {value: ETipoDiferimento.NaoDefinido, label: this._translateService.instant('tipoDiferimento.naoDefinido')},
      {value: ETipoDiferimento.Rendimento, label: this._translateService.instant('tipoDiferimento.rendimento')},
      {value: ETipoDiferimento.Gasto, label: this._translateService.instant('tipoDiferimento.gasto')}
    ];
    this._mapaAcrescimosDiferimentos = [];
    this.tipoOperacaoDiferimentosSourceItem = this.tipoOperacaoDiferimentosSource[2];
    this.tipoDiferimentosSourceItem = this.tipoDiferimentosSource[0];
    this.dataGridDefinition = {dataSource: [], columns: []};
    this.searchAcrescimosDiferimentos = this.searchAcrescimosDiferimentos.bind(this);
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    await this._pcaContabilidadeService.init(this.toolbar, {
      showDropdownPeriodos: false,
      showCaptionEncerrado: true,
      defaultYear: EPcaContabilidadeServiceOptionsDefaultYear.AnoCursoIRC
    });
    this._selectedYear = this._pcaContabilidadeService.ano.ano;
    this._subscriptionOnRefresh = this._pcaContabilidadeService.onRefresh().subscribe((event: IPcaContabilidadeServiceRefreshedEvent) => {
      this._onNotify(event);
    });
    this.toolbar.addButton({
      id: 'btn-info-global-diferimentos-acrescimos',
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-list"></i>',
      class: 'btn-info',
      caption: 'mapadiferimentosacrescimos.btn.infoglobaldiferimentosacrescimos',
      click: () => this._openInfoGlobalDiferimentosAcrescimos()
    });

    this._applyDataGridDefinition();

    if (this.maintenanceMode) {
      this.setMaintenanceModeFullscreen(true);
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionOnRefresh.unsubscribe();
  }

  public onInitialized(event: IDevExpressDataGridEventOnInitialized): void {
    this._datagridInstance = event.component;
  }

  public tipoOperacaoDiferimentosChanged(item: number): void {
    this.tipoOperacaoDiferimentosSourceItem = this.tipoOperacaoDiferimentosSource[item - 1];
  }
  public tipoDiferimentosChanged(item: number): void {
    this.tipoDiferimentosSourceItem = this.tipoDiferimentosSource[item];
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IMapaDiferimentosAcrescimos>): void {
    devExpressDataGridExpandDetailHandler(event, async () => {
      if (!event.data._docContabilidade) {
        const response: HttpResponse<IJsonDocContabilidade> = await this._serviceDocsContabilidade.get({id: event.data.extPocCabID});
        event.data._docContabilidade = response.body;
        if (this.empresaTemContabilidadeDigital) {
          event.data._contabDigitalDoc = {
            extPocCabID: response.body.extPocCabID,
            periodo: response.body.periodo,
            nDiario: response.body.nDiario,
            nDocInterno: response.body.nDocInterno,
            dataDoc: response.body.dataDoc
          };
        }
      }
    }).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public async searchAcrescimosDiferimentos(): Promise<void> {
    try {
      if (this._datagridInstance) {
        this._datagridInstance.beginCustomLoading(undefined);
      }

      const hasResponseData: boolean = await this._getMapaDiferimentosAcrescimos();
      if (!hasResponseData) {
        this._plAlertService.info('global.text.searchNoData');
        this.dataGridDefinition.dataSource = [];
        return;
      }
      this._cardPanel.collapse();
      this._applyDataGridDefinition();
    } finally {
      if (this._datagridInstance) {
        this._datagridInstance.endCustomLoading();
      }
    }
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _setColumnsMonths(title: string, field: string, years: Array<number>): IDevExpressDataGridColumn {
    const columnsMonths: IDevExpressDataGridColumn = {
      caption: title,
      allowFiltering: false,
      allowGrouping: false,
      allowReordering: false,
      allowFixing: false,
      showInColumnChooser: false,
      columns: [
        {dataField: `nConta${upperFirst(field)}`, dataType: 'string', caption: 'mapadiferimentosacrescimos.fields.nConta', width: 90},
        {dataField: `nomeConta${upperFirst(field)}`, dataType: 'string', caption: 'mapadiferimentosacrescimos.fields.nomeConta', width: 150},
        {dataField: `${field}ValorAnoAnterior`, dataType: 'double', caption: 'mapadiferimentosacrescimos.fields.valorAno1N', format: {suppressZeros: true}, cssClass: 'fw-bold'}
      ]
    };

    let mes: number = this.empresaMesContab?.primeiroMesContab !== -1 ? this.empresaMesContab?.primeiroMesContab : EDateMonth.January;
    const anoEmCursoIRC: number = this.configurations.empresa.anoEmCursoIRC;
    let ano: number = years[0] ?? anoEmCursoIRC;
    let columnsMonthsYear: IDevExpressDataGridColumn = {
      caption: `${ano}`,
      allowFiltering: false,
      allowGrouping: false,
      allowHeaderFiltering: false,
      allowSorting: false,
      allowReordering: false,
      allowFixing: false,
      showInColumnChooser: false,
      columns: []
    };
    for (let i = 0; i < EDateMonth.December; i++) {
      if (mes > EDateMonth.December) {
        columnsMonths.columns.push(columnsMonthsYear);
        mes = 1;
        ano = years[1] ?? anoEmCursoIRC + 1;
        columnsMonthsYear = {
          caption: `${ano}`,
          allowGrouping: false,
          allowReordering: false,
          allowFixing: false,
          showInColumnChooser: false,
          columns: []
        };
      }
      columnsMonthsYear.columns.push({
        dataField: `${field}${ano}month${mes}`,
        dataType: 'double',
        caption: DATA_SOURCE_MESES_ABREVIADO.data[mes - 1].name,
        format: {suppressZeros: true},
        allowFixing: false,
        allowReordering: false,
        allowGrouping: false
      });
      mes++;
    }
    columnsMonths.columns.push(columnsMonthsYear);

    columnsMonths.columns.push(
      {dataField: `${field}ValorAnoAtual`, dataType: 'double', caption: 'mapadiferimentosacrescimos.fields.valorAnoN', format: {suppressZeros: true}, cssClass: 'fw-bold'},
      {dataField: `${field}ValorAnoSeguinte`, dataType: 'double', caption: 'mapadiferimentosacrescimos.fields.valorAnoN1', format: {suppressZeros: true}, cssClass: 'fw-bold'}
    );
    return columnsMonths;
  }

  private _applyDataGridDefinition(): void {
    const yearList: Array<number> = [];
    let hasAcrescimo: boolean = this.tipoOperacaoDiferimentosSourceItem.value === ETipoOperacaoDiferimentos.Acrescimo;
    let hasDiferimento: boolean = this.tipoOperacaoDiferimentosSourceItem.value === ETipoOperacaoDiferimentos.Diferimento;
    if (this.tipoOperacaoDiferimentosSourceItem.value === ETipoOperacaoDiferimentos.Ambos) {
      hasAcrescimo = true;
      hasDiferimento = true;
    }

    const dynamicMap: Array<IMapaDiferimentosAcrescimosDynamic> = [];
    for (const item of this._mapaAcrescimosDiferimentos) {
      const dynamicMapItem: IMapaDiferimentosAcrescimosDynamic = this._fillDynamicMapItem(item);

      (<Array<IMapaDiferimentosAcrescimosYear>>dynamicMapItem.yearList) = [];
      const groupByYear: IDictionary<Array<IJsonMapaDiferimentosAcrescimosMonthGeral>> = groupBy<IJsonMapaDiferimentosAcrescimosMonthGeral>(item.valorMeses, 'ano');
      for (const key of Object.keys(groupByYear)) {
        (<Array<IMapaDiferimentosAcrescimosYear>>dynamicMapItem.yearList).push({year: Number(key), acrescimoList: groupByYear[key], diferimentoList: groupByYear[key]});
        if (!yearList.includes(Number(key))) {
          yearList.push(Number(key));
        }
      }

      dynamicMap.push(dynamicMapItem);
    }

    const dynamicSource: Array<IMapaDiferimentosAcrescimosDynamic> = [];
    for (const item of dynamicMap) {
      const dynamicSourceItem: IMapaDiferimentosAcrescimosDynamic = this._fillDynamicMapItem(item);
      for (const yearItem of <Array<IMapaDiferimentosAcrescimosYear>>item.yearList) {
        if (hasAcrescimo) {
          for (const acrescimo of yearItem.acrescimoList) {
            dynamicSourceItem[`${FIELD_ACRESCIMO_NAME}${yearItem.year}month${acrescimo.mes}`] = acrescimo.valorAcrescimo;
          }
        }
        if (hasDiferimento) {
          for (const diferimento of yearItem.diferimentoList) {
            dynamicSourceItem[`${FIELD_DIFERIMENTO_NAME}${yearItem.year}month${diferimento.mes}`] = diferimento.valorDiferimento;
          }
        }
      }

      dynamicSource.push(dynamicSourceItem);
    }

    const columns: Array<IDevExpressDataGridColumn> = [
      {
        caption: '',
        allowFiltering: false,
        allowGrouping: false,
        columns: [
          {dataField: 'nDocumento', dataType: 'string', caption: 'mapadiferimentosacrescimos.fields.nDocumento', width: 160, fixed: !this.isMobile},
          {dataField: 'descricaoDoc', dataType: 'string', caption: 'mapadiferimentosacrescimos.fields.descricaoDoc', width: 300, fixed: !this.isMobile},
          {dataField: 'nConta', dataType: 'string', caption: 'mapadiferimentosacrescimos.fields.nConta', width: 90},
          {dataField: 'nomeConta', dataType: 'string', caption: 'mapadiferimentosacrescimos.fields.nomeConta', width: 150},
          {dataField: 'nDocExterno', dataType: 'string', caption: 'mapadiferimentosacrescimos.fields.nDocExterno'},
          {dataField: 'dataDocExterno', dataType: 'date', caption: 'mapadiferimentosacrescimos.fields.dataDocExterno'},
          {dataField: 'nomeDescriti', dataType: 'string', caption: 'mapadiferimentosacrescimos.fields.nomeDescriti', width: 150},
          {dataField: 'dataInicio', dataType: 'date', caption: 'mapadiferimentosacrescimos.fields.dataInicio'},
          {dataField: 'dataFim', dataType: 'date', caption: 'mapadiferimentosacrescimos.fields.dataFim'},
          {dataField: 'dias', dataType: 'number', caption: 'mapadiferimentosacrescimos.fields.dias'},
          {dataField: 'valor', dataType: 'double', caption: 'mapadiferimentosacrescimos.fields.valor', format: {suppressZeros: true}, cssClass: 'fw-bold'}
        ]
      }
    ];

    const totalItems: Array<IDevExpressDataGridSummaryTotalItems> = [{column: 'valor', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}];
    if (hasAcrescimo) {
      columns.push(this._setColumnsMonths(this._translateService.instant('mapadiferimentosacrescimos.acrescimo'), FIELD_ACRESCIMO_NAME, yearList));

      totalItems.push({column: `${FIELD_ACRESCIMO_NAME}ValorAnoAnterior`, summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'});
      for (const year of yearList) {
        for (let i = EDateMonth.January; i <= EDateMonth.December; i++) {
          totalItems.push({column: `${FIELD_ACRESCIMO_NAME}${year}month${i}`, summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'});
        }
      }
      totalItems.push({column: `${FIELD_ACRESCIMO_NAME}ValorAnoAtual`, summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'});
      totalItems.push({column: `${FIELD_ACRESCIMO_NAME}ValorAnoSeguinte`, summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'});
    }
    if (hasDiferimento) {
      columns.push(this._setColumnsMonths(this._translateService.instant('mapadiferimentosacrescimos.diferimento'), FIELD_DIFERIMENTO_NAME, yearList));

      totalItems.push({column: `${FIELD_DIFERIMENTO_NAME}ValorAnoAnterior`, summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'});
      for (const year of yearList) {
        for (let i = EDateMonth.January; i <= EDateMonth.December; i++) {
          totalItems.push({column: `${FIELD_DIFERIMENTO_NAME}${year}month${i}`, summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'});
        }
      }
      totalItems.push({column: `${FIELD_DIFERIMENTO_NAME}ValorAnoAtual`, summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'});
      totalItems.push({column: `${FIELD_DIFERIMENTO_NAME}ValorAnoSeguinte`, summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'});
    }

    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: columns,
      dataSource: dynamicSource,
      export: {
        filename: this.tipoOperacaoDiferimentosSourceItem.label,
        enabledPdf: false
      },
      masterDetail: {enabled: true, template: 'masterDetailTemplate'},
      height: '60vh',
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      remoteOperations: false,
      scrolling: {columnRenderingMode: 'virtual', rowRenderingMode: 'virtual'},
      summary: {
        totalItems: totalItems
      }
    };
  }

  private _fillDynamicMapItem(item: IMapaDiferimentosAcrescimosDynamic | IMapaDiferimentosAcrescimosGeral): IMapaDiferimentosAcrescimosDynamic {
    return {
      extPocCabID: item.extPocCabID,
      nDocumento: item.nDocumento,
      descricaoDoc: item.descricaoDoc,
      nomeConta: item.nomeConta,
      nConta: item.nConta,
      dataInicio: item.dataInicio,
      dataFim: item.dataFim,
      dias: item.dias,
      valor: item.valor,
      dataDocExterno: item.dataDocExterno,
      nDocExterno: item.nDocExterno,
      nomeDescriti: item.nomeDescriti,
      nContaAcrescimo: item.nContaAcrescimo,
      nContaDiferimento: item.nContaDiferimento,
      nomeContaAcrescimo: item.nomeContaAcrescimo,
      nomeContaDiferimento: item.nomeContaDiferimento,
      diferimentoValorAnoAtual: item.diferimentoValorAnoAtual,
      acrescimoValorAnoAtual: item.acrescimoValorAnoAtual,
      diferimentoValorAnoAnterior: item.diferimentoValorAnoAnterior,
      diferimentoValorAnoSeguinte: item.diferimentoValorAnoSeguinte,
      acrescimoValorAnoAnterior: item.acrescimoValorAnoAnterior,
      acrescimoValorAnoSeguinte: item.acrescimoValorAnoSeguinte
    };
  }

  private _openInfoGlobalDiferimentosAcrescimos(): Promise<void> {
    return this._maintenanceInfoGlobalDiferimentosAcrescimos.maintenance();
  }

  private _onNotify({ano}: IPcaContabilidadeServiceRefreshedEvent): void {
    this._selectedYear = ano.ano;
    this._cardPanel.expand();
  }

  private async _getMapaDiferimentosAcrescimos(): Promise<boolean> {
    this._mapaAcrescimosDiferimentos = [];
    const response: HttpResponse<Array<IMapaDiferimentosAcrescimosGeral>> = await this._calculoDiferimentosService.getMapaDiferimentosAcrescimos(
      this.tipoDiferimentosSourceItem.value,
      this.tipoOperacaoDiferimentosSourceItem.value,
      this._selectedYear
    );
    this._mapaAcrescimosDiferimentos = orderBy(response.body, 'nDocumento', 'asc');
    return response.body.length > 0;
  }
}
