import {ROLE} from '../../services/role.const';
import {IJsonDiario} from './jsonDiario.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {DATA_SOURCE_NAME_FORMA_INCREMENTO_NDOC} from '../../datasources/formaincrementondoc/formaIncrementoNDoc.datasource.interface';
import {ENTITY_NAME_DIARIOS} from './diarios.entity.interface';
import {DiariosEntityEditComponent} from './components/edit/diarios.entity.edit.component';

export const ENTITY_DIARIOS: IEntityDefinition<IJsonDiario> = {
  name: ENTITY_NAME_DIARIOS,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.ATIVOS],
  searchPlaceholder: 'diarios.pesquisa',
  sidebarTitle: 'portals.sidebar.modules.diarios',
  metadata: {
    keyName: 'nDiario',
    fields: [
      {
        name: 'nDiario',
        type: 'cgsmallint',
        width: '120px',
        caption: 'diarios.fields.nDiario',
        placeholder: 'diarios.fields.nDiarioPlaceholder',
        validators: {required: true}
      },
      {name: 'nome', caption: 'diarios.fields.nome', placeholder: 'diarios.fields.nomePlaceholder', validators: {required: true, maxlength: 49}},
      {name: 'formaIncrementoNDoc', type: DATA_SOURCE_NAME_FORMA_INCREMENTO_NDOC, caption: 'diarios.fields.formaIncrementoNDoc', validators: {required: true}},
      {name: 'aberturaContab', type: 'boolean', caption: 'diarios.fields.aberturaContab', placeholder: 'diarios.fields.aberturaContabPlaceholder', validators: {}}
    ],
    order: 'nome',
    searchFields: 'nDiario,nome,formaIncrementoNDoc',
    listFields: 'nDiario,nome'
  },
  autocomplete: {
    rowTemplate: '{{nDiario}} - {{nome}}',
    output: '{{nDiario}} - {{nome}}',
    outputDescription: 'nome',
    searchFields: 'nDiario,nome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP, ROLE.CONTABILIDADE],
        pageTitle: 'global.menu.diarios'
      }
    }
  },
  detail: {
    state: {
      component: DiariosEntityEditComponent,
      data: {
        roles: [ROLE.ERP, ROLE.CONTABILIDADE],
        pageTitle: 'global.menu.diarios'
      }
    }
  }
};
