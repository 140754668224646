import type dxDataGrid from 'devextreme/ui/data_grid';
import {CGModalComponent} from '../../../../cg/modal/cgmodal.component';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {copy, IPlNavWizardCallback, IPlNavWizardDefinition, IPlNavWizardEventBeforeChange, IPlNavWizardEventStep, isEmpty, PlAlertService} from 'pl-comps-angular';
import {EGrupoDoc} from '../../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {IJsonCodPostal} from '../../../../../entities/codpostais/jsonCodPostal.entity.interface';
import {IDocComercialEditStateParams} from '../../../../../entities/docscomerciais/docsComerciais.entity.interface';
import moment from 'moment';
import {CONSTANT_COD_PAIS_NACIONAL, CONSTANT_NOME_PAIS_NACIONAL, EGroupName} from '../../../../../../config/constants';
import {EStepsNavGestaoEncomendas, IEncomendasDefaultData, schemaIJsonEncomendasCab} from '../../../../../modules/encomendas/encomendas.module.interface';
import {IJsonDocSatEncom, IJsonDocSatEncomLin, IJsonDocSatEncomLinLote} from '../../../../../modules/encomendas/jsonEncomenda.module.interface';
import {IJsonMorAl} from '../../../../../entities/morals/jsonMoral.entity.interface';
import {EncomendasService} from '../../../../../modules/encomendas/service/encomendas.service';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {IJsonDocComercial} from '../../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {EncomendasSimulacaoModalComponent} from '../simulacao/encomendas.simulacao.modal.component';
import {CGModalService} from '../../../../cg/modal/cgmodal.service';
import {DocumentosService} from '../../../../../entities/docscomerciais/service/documentos.entity.service';
import {IJsonDocfa} from '../../../../../entities/docfas/jsonDocFa.entity.interface';
import {CGStateService} from '../../../../state/cg.state.service';
import {ENTITY_DOCS_COMERCIAIS_COMPRAS, ENTITY_DOCS_COMERCIAIS_VENDAS} from '../../../../../entities/docscomerciais/docsComerciais.entity';
import {EEntityStateDetailType} from '../../../../../../common/utils/entity.state.utils';
import {CUSTO_MEDIO_PONDERADO, FIFO} from '../../../../../datasources/criteriodisponiveis/criterioDisponiveis.datasource.interface';
import {GrupoDocService} from '../../../../../services/grupodoc/grupo.doc.service';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../../../entities/docfas/docFas.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IDevExpressDataGrid} from '../../../../devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonArtigoLoteArmazemStock, IJsonLote} from '../../../../../entities/lotes/jsonLote.entity.interface';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {ENTITY_NAME_LOTES} from '../../../../../entities/lotes/lotes.entity.interface';
import {THttpQueryResponse} from '../../../../../services/api/api.service.interface';
import {
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnEditingStart,
  IDevExpressDataGridEventOnFocusedCellChanged,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {CGExceptionService} from '../../../../exceptions/exceptions.service';
import {StateService} from '@uirouter/core';
import {preencheAutomaticamenteLotesNaLinha, temLinhasComLotes} from '../../../../../modules/encomendas/encomendas.module.utils';
import {CGLocalStorageGroupService} from '../../../../../services/storage/localstoragegroup.service';
import {TClifos} from '../../../../../entities/clifos/clifos.entity.interface';
import {IEntityMaintenanceInstance} from '../../../../entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../entity/maintenance/entity/entity.maintenance.service';

const timeOutFocus = 350;

@Component({
  selector: 'encomendas-processamento-modal',
  templateUrl: './encomendas.processamento.modal.component.html'
})
export class EncomendasProcessamentoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public grupo: EGrupoDoc;
  @Input() public grupoClifos: TClifos;
  @Input() public nConta: string;
  @Input() public nomeConta: string;
  @Input() public linhas: Array<IJsonDocSatEncomLin>;

  public readonly navWizardDefinition: IPlNavWizardDefinition;
  public readonly navWizardCallback: IPlNavWizardCallback;
  public readonly docfasOutput: string;
  public readonly paisesOutput: string;
  public readonly clifosOutput: string;
  public readonly stepsNavGestaoEncomendas: typeof EStepsNavGestaoEncomendas;

  public proceGestEnc: IJsonDocSatEncom;
  public docComercial: IJsonDocComercial;
  public docfaFilter: string;
  public docfaNumFilter: string;
  public nContaMoralFilter: string;
  public clifoDescription: string;
  public showLotes: boolean;
  public showPreview: boolean;
  public showFinalizePreviewBtn: boolean;
  public showEditDocBtn: boolean;
  public showAddLoteBtn: boolean;
  public showSimularBtn: boolean;
  public disableNConta: boolean;
  public dataGridLinhasDefinition: IDevExpressDataGrid<IJsonDocSatEncomLin, number>;
  public dataGridLotesDefinition: IDevExpressDataGrid<IJsonLote, number>;
  public linhaEnc: IJsonDocSatEncomLin;

  private readonly _docFaService: IDocFasEntityService;
  private readonly _lotes: IEntityService<IJsonLote>;
  private readonly _nDecimaisQtd: number;

  private _maintenanceLotes: IEntityMaintenanceInstance;
  private _lotesLinha: Array<IJsonDocSatEncomLinLote>;
  private _dataGridLotesInstance: dxDataGrid;
  private _rowIndex: number;
  private _grupoDocfa: number;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _stateService: StateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _encomendasService: EncomendasService,
    private readonly _cgModalService: CGModalService,
    private readonly _documentosService: DocumentosService,
    private readonly _cgStateService: CGStateService,
    private readonly _grupoDoc: GrupoDocService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _cgLocalStorageGroupService: CGLocalStorageGroupService,
    private readonly _entityMaintenanceService: EntityMaintenanceService
  ) {
    super(_injector);
    this._docFaService = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
    this._lotes = this._entityServiceBuilder.build<IJsonLote>(ENTITY_NAME_LOTES);
    this._lotesLinha = [];
    this.docfasOutput = '{{nDocFa}} - {{nome}}';
    this.paisesOutput = '{{codPais}} - {{nomePais}}';
    this.clifosOutput = '{{nConta}} - {{nome}}';
    this.stepsNavGestaoEncomendas = EStepsNavGestaoEncomendas;
    this.navWizardDefinition = {
      items: []
    };
    this.navWizardCallback = {};
    this.proceGestEnc = {
      cab: {
        nDocfa: undefined,
        nNumer: undefined,
        nConta: '',
        dataDoc: moment(),
        temIdCarga: false,
        nomeDocfa: '',
        nomeConta: ''
      },
      carga: {dataInicTran: moment(), horaInicTran: moment(), ...this._emptyMoralInterface()},
      descarga: {...this._emptyMoralInterface()},
      linhas: []
    };
    this.showLotes = false;
    this.showPreview = false;
    this.showFinalizePreviewBtn = false;
    this.showEditDocBtn = false;
    this.showSimularBtn = true;
    this.disableNConta = false;
    this.nConta = '';
    this.nomeConta = '';
    this.beforeChangedStep = this.beforeChangedStep.bind(this);
    this.finalize = this.finalize.bind(this);

    this.dataGridLinhasDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'facliId', dataType: 'number', caption: 'encomendas.table.facliId', visible: false, allowEditing: false},
        {dataField: 'nDoc', dataType: 'string', caption: 'encomendas.table.nDoc', allowEditing: false},
        {dataField: 'dataDoc', dataType: 'date', caption: 'encomendas.table.dataDoc', allowEditing: false},
        {dataField: 'nArtigo', dataType: 'string', caption: 'encomendas.table.nArtigo', allowEditing: false},
        {dataField: 'nomeArtigo', dataType: 'string', caption: 'encomendas.table.nomeArtigo', allowEditing: false},
        {dataField: 'nArmaz', dataType: 'string', caption: 'encomendas.table.nArmaz', allowEditing: false},
        {dataField: 'nomeArmazem', dataType: 'string', caption: 'encomendas.table.nomeArmaz', visible: false, allowEditing: false},
        {dataField: 'prVendaQtd1', dataType: 'double', caption: 'encomendas.table.prVendaQtd1', allowEditing: false},
        {dataField: 'ligacaoQtd', dataType: 'number', caption: 'encomendas.table.ligacaoQtd', allowEditing: false}
      ],
      dataSource: [],
      keyExpr: 'facliId',
      selection: {mode: 'single', showCheckBoxesMode: 'none'},
      export: {enabled: false},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      columnChooser: {enabled: false},
      remoteOperations: false,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateBtnToolbar',
            locateInMenu: 'auto'
          }
        ]
      }
    };

    this._nDecimaisQtd = this._configService.configurations.gestaoComercial.decimais.stocks.quantidades;

    this.dataGridLotesDefinition = {
      allowColumnReordering: false,
      columnHidingEnabled: true,
      columnChooser: {mode: 'select'},
      columns: [
        {dataField: 'nLote', dataType: 'number', caption: 'lotes.fields.nLote', visible: false, allowEditing: false, showInColumnChooser: false},
        {dataField: 'nLoteEspecifico', dataType: 'string', caption: 'lotes.fields.nLoteEspecifico', allowEditing: false, showInColumnChooser: false},
        {dataField: 'dataFabrico', dataType: 'date', caption: 'lotes.fields.dataFabrico', visible: false, allowEditing: false},
        {dataField: 'dataValidade', dataType: 'date', caption: 'lotes.fields.dataValidade', visible: false, allowEditing: false},
        {dataField: 'qtdStock', dataType: 'double', caption: 'docscomerciais.doc.linhas.qtdStock', visible: true, format: {decimalsLimit: this._nDecimaisQtd}, allowEditing: false},
        {dataField: 'qtd', dataType: 'double', caption: 'docscomerciais.doc.linhas.qtd', format: {decimalsLimit: this._nDecimaisQtd}, showEditorAlways: true, showInColumnChooser: false}
      ],
      dataSource: [],
      keyExpr: 'nLote',
      toolbar: {visible: true},
      filterRow: {visible: false},
      editing: {allowUpdating: true, mode: 'cell', selectTextOnEditStart: true},
      export: {enabled: false},
      remoteOperations: false,
      keyboardNavigation: {
        enabled: true,
        enterKeyAction: 'moveFocus',
        enterKeyDirection: 'column'
      }
    };
  }

  public ngOnInit(): void {
    this.docfaFilter =
      this.grupo === EGrupoDoc.EncomendasClientes
        ? `grupoDocfa=${EGrupoDoc.VendasEfectivas}|grupoDocfa=${EGrupoDoc.GuiasTransporteRemessa}`
        : this.grupo === EGrupoDoc.EncomendasFornecedores
          ? `grupoDocfa=${EGrupoDoc.ComprasEfectivas}|grupoDocfa=${EGrupoDoc.GuiasTransporteFornecedores}`
          : undefined;
    this.docfaNumFilter = 'encerrado=0&visivelERPCloud=1';
    this.nContaMoralFilter = '';
    this.clifoDescription =
      this.grupoClifos === 'clientes'
        ? 'encomendas.modal.processamento.fields.cliente'
        : this.grupoClifos === 'fornecedores'
          ? 'encomendas.modal.processamento.fields.fornecedor'
          : 'encomendas.modal.processamento.fields.clifos';
    if (!isEmpty(this.nConta)) {
      this.proceGestEnc.cab.nConta = this.nConta;
      this.proceGestEnc.cab.nomeConta = this.nomeConta;
      this.disableNConta = true;
      this.nContaChanged(this.nConta);
      this.proceGestEnc.linhas = this.linhas;
      this._encomendasService.getConfiguracoesPorDefeito(this.linhas[0].nDocfa, this.nConta).then((response: HttpResponse<IJsonDocSatEncom>) => {
        if (response) {
          this._cgLocalStorageGroupService
            .getItem<IEncomendasDefaultData>(`${this.grupoClifos}.encomendas.processamento.defaultdata`, schemaIJsonEncomendasCab, EGroupName.ERP)
            .subscribe((value: IEncomendasDefaultData) => {
              const nDocfa = value ? value.nDocfa : response.body.cab.nDocfa;
              const nomeDocfa = value ? value.nomeDocfa : response.body.cab.nomeDocfa;
              const nNumer = value ? value.nNumer : response.body.cab.nNumer;

              this.proceGestEnc.cab = {...response.body.cab, nDocfa: nDocfa, nomeDocfa: nomeDocfa, nNumer: nNumer, nConta: this.proceGestEnc.cab.nConta, nomeConta: this.proceGestEnc.cab.nomeConta};

              this._docFaService.get({id: this.proceGestEnc.cab.nDocfa, reportExceptions: false}).then((responseAux: HttpResponse<IJsonDocfa>) => {
                if (responseAux) {
                  this.nDocFaChanged(responseAux.body);
                  this.showFinalizePreviewBtn = !this.showLotes && !this.proceGestEnc.cab.temIdCarga;
                  if (this.showLotes) {
                    const isCompra = this._grupoDoc.isCompra(responseAux.body.grupoDocfa);
                    const linhas: Array<IJsonDocSatEncomLin> = [];
                    for (const linha of this.linhas) {
                      if (linha.ligacaoQtd > 0 && linha.temLote && (linha.codValoriz === CUSTO_MEDIO_PONDERADO || (linha.codValoriz === FIFO && isCompra))) {
                        linhas.push(linha);
                      }
                    }
                    this.dataGridLinhasDefinition.dataSource = linhas;
                  }
                }
              });
            });

          this.proceGestEnc.carga = response.body.carga;
          this.proceGestEnc.descarga = response.body.descarga;
        }
      });
    }
    this._getLotesArtigoLinha(null);
  }

  public close(): void {
    super.close();
  }

  public nDocFaChanged(docFa: IJsonDocfa): void {
    this.proceGestEnc.cab.nDocfa = docFa.nDocFa;
    this.proceGestEnc.cab.nomeDocfa = docFa.nome;
    this.docfaNumFilter = `nDocFa=${docFa.nDocFa}&encerrado=0&visivelERPCloud=1`;
    this._grupoDocfa = docFa.grupoDocfa;
    const isCompra = this._grupoDoc.isCompra(docFa.grupoDocfa);
    this.showLotes = docFa.integraStocks && temLinhasComLotes(this.proceGestEnc.linhas, isCompra);
  }

  public nContaChanged(nConta: string): void {
    this.nContaMoralFilter = `nConta=${nConta}`;
  }

  public codPostaisChanged(postal: IJsonCodPostal, type: 'carga' | 'descarga'): void {
    if (type === 'carga') {
      this.proceGestEnc.carga.cPost = postal.codPostal;
      this.proceGestEnc.carga.localidade = postal.localidade;
    } else {
      this.proceGestEnc.descarga.cPost = postal.codPostal;
      this.proceGestEnc.descarga.localidade = postal.localidade;
    }
  }

  public stepChanged({currentStep}: IPlNavWizardEventStep): void {
    if (currentStep.stepId === EStepsNavGestaoEncomendas.Preview) {
      for (const item of this.navWizardDefinition.items) {
        if (item.stepId !== EStepsNavGestaoEncomendas.Preview) {
          item.disabled = true;
        }
      }
      currentStep.hidePrevious = true;
      currentStep.hideFinalize = true;
      this.showFinalizePreviewBtn = false;
    }

    this.showAddLoteBtn = currentStep.stepId === EStepsNavGestaoEncomendas.Lotes;
    this.showEditDocBtn = currentStep.stepId === EStepsNavGestaoEncomendas.Preview;
    this.showSimularBtn = currentStep.stepId === EStepsNavGestaoEncomendas.Identificacao || (this.showLotes && currentStep.stepId === EStepsNavGestaoEncomendas.Lotes);
    this.showFinalizePreviewBtn =
      (this.showLotes && currentStep.stepId === EStepsNavGestaoEncomendas.Lotes) ||
      (!this.showLotes && !this.proceGestEnc.cab.temIdCarga && currentStep.stepId === EStepsNavGestaoEncomendas.Identificacao) ||
      (!this.showLotes && currentStep.stepId === EStepsNavGestaoEncomendas.Descarga);
  }

  public beforeChangedStep({currentStep}: IPlNavWizardEventBeforeChange): boolean {
    if (this.showPreview && currentStep.stepId === EStepsNavGestaoEncomendas.Preview) {
      return true;
    }
    return true;
  }

  public editDoc(): Promise<void> {
    const stateParams: IDocComercialEditStateParams = {id: this.docComercial.cab.faccbId, doc: copy(this.docComercial)};
    return this._cgStateService
      .redirectToState({
        stateOrName: this.grupo === EGrupoDoc.EncomendasClientes ? ENTITY_DOCS_COMERCIAIS_VENDAS : ENTITY_DOCS_COMERCIAIS_COMPRAS,
        stateType: EEntityStateDetailType.EDIT,
        params: stateParams
      })
      .then(() => {
        super.close();
      });
  }

  public finalize(): Promise<void> {
    return this.processar()
      .then(() => {
        this.showPreview = true;
        setTimeout(() => {
          return this.navWizardCallback.setStep(EStepsNavGestaoEncomendas.Preview, true);
        }, timeOutFocus);
      })
      .catch((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          const exception = this._cgExceptionService.get(error);
          if (exception?.message) {
            this._plAlertService.error(exception.message);
          }
        }
      });
  }

  public processar(simular: boolean = false): Promise<void> {
    if (simular && this.showLotes && !this.showFinalizePreviewBtn) {
      this._plAlertService.error('encomendas.modal.processamento.messages.atribuirLotes');
      return this.navWizardCallback.setStep(EStepsNavGestaoEncomendas.Lotes, true);
    }
    return this._encomendasService.processar(this.proceGestEnc, simular).then((response: HttpResponse<IJsonDocComercial>) => {
      if (simular) {
        const modalInstance = this._cgModalService.showVanilla(EncomendasSimulacaoModalComponent);
        const componentInstance: EncomendasSimulacaoModalComponent = modalInstance.componentInstance;
        componentInstance.docComercialCab = response.body.cab;
        return Promise.resolve();
      }

      this._plAlertService.success('encomendas.modal.processamento.messages.proceSuccess');
      this.docComercial = response.body;
      return Promise.resolve();
    });
  }

  public preencheAutomaticamenteLotes(): Promise<void> {
    if (this._grupoDoc.isCompra(this._grupoDocfa)) {
      this._plAlertService.error('encomendas.modal.processamento.messages.notAutoSelLotesEntradaStock');
      return Promise.reject(new Error('encomendas.modal.processamento.messages.notAutoSelLotesEntradaStock'));
    }

    if (this.showLotes && this.proceGestEnc.linhas.length === 0) {
      this._plAlertService.error('encomendas.modal.processamento.messages.naoTemLinhas');
      return Promise.reject(new Error('encomendas.modal.processamento.messages.naoTemLinhas'));
    }

    const isCompra = this._grupoDoc.isCompra(this._grupoDocfa);
    if (!temLinhasComLotes(this.proceGestEnc.linhas, isCompra)) {
      this._plAlertService.error('encomendas.modal.processamento.messages.naoTemLinhasComLotes');
      return Promise.reject(new Error('encomendas.modal.processamento.messages.naoTemLinhasComLotes'));
    }

    for (const linha of this.proceGestEnc.linhas) {
      if (linha.ligacaoQtd > 0 && linha.temLote && linha.codValoriz === CUSTO_MEDIO_PONDERADO) {
        if (linha.lotes?.length > 0) {
          for (const lote of linha.lotes) {
            lote.qtd = 0;
          }
          preencheAutomaticamenteLotesNaLinha(linha, this._nDecimaisQtd);
        } else {
          this._getLotesArtigoLinha(linha, true);
        }
      }
    }
    return Promise.resolve();
  }

  public onGridLotesInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridLotesInstance = component;
    this._dataGridLotesInstance.columnOption('qtd', 'showEditorAlways', true);
    this._dataGridLotesInstance.columnOption('qtdStock', 'visible', true);
  }

  public onGridLotesContentReady(): void {
    this._dataGridLotesInstance.endCustomLoading();
    if (this._lotesLinha.length > 0) {
      this._dataGridLotesInstance.columnOption('qtd', 'showEditorAlways', true);
      this._dataGridLotesInstance.editCell(this._rowIndex ? this._rowIndex : 0, 'qtd');
    }
  }

  public onGridLotesFocusedCellChanged(event: IDevExpressDataGridEventOnFocusedCellChanged<IJsonDocSatEncomLinLote>): void {
    this._rowIndex = event.row.rowIndex ? event.row.rowIndex : 0;
  }

  public onGridLinhasCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonDocSatEncomLin, number>): void {
    if (event.rowType === 'data' && event.column.dataField === 'ligacaoQtd') {
      if (event.cellElement.classList.contains('bg-part-satif')) {
        event.cellElement.classList.remove('bg-part-satif');
      }
      if (event.cellElement.classList.contains('bg-total-satif')) {
        event.cellElement.classList.remove('bg-part-satif');
      }
      if (event.cellElement.classList.contains('bg-linha-text-livre')) {
        event.cellElement.classList.remove('bg-part-satif');
      }
      if (event.cellElement.classList.contains('bg-satisf-mais')) {
        event.cellElement.classList.remove('bg-part-satif');
      }

      if (event.data.ligacaoQtd !== 0 && event.data.ligacaoQtd === event.data.qtd1) {
        event.cellElement.classList.add('bg-total-satif');
      }
      if (event.data.ligacaoQtd !== 0 && event.data.ligacaoQtd > event.data.qtd1) {
        event.cellElement.classList.add('bg-satisf-mais');
      }
      if (event.data.ligacaoQtd !== 0 && event.data.ligacaoQtd < event.data.qtd1) {
        event.cellElement.classList.add('bg-part-satif');
      }
    }
  }

  public onGridLinhasEditorStart(event: IDevExpressDataGridEventOnEditingStart<IJsonDocSatEncomLin, number>): void {
    event.component.selectRows([event.key], false);
  }

  public onGridLinhasSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IJsonDocSatEncomLin>): void {
    for (const facliId of event.currentSelectedRowKeys) {
      const idx = this.proceGestEnc.linhas.findIndex((item: IJsonDocSatEncomLin) => item.facliId === facliId);
      this.linhaEnc = this.proceGestEnc.linhas[idx];
      if (this.linhaEnc.lotes?.length > 0) {
        this.dataGridLotesDefinition.dataSource = this.linhaEnc.lotes;
      } else {
        this._getLotesArtigoLinha(this.linhaEnc);
      }
    }
  }

  public closeModal(): void | Promise<void> {
    if (this.proceGestEnc?.cab?.nDocfa && this.proceGestEnc?.cab?.nNumer) {
      const encomendasDefaultData: IEncomendasDefaultData = {nDocfa: this.proceGestEnc.cab.nDocfa, nomeDocfa: this.proceGestEnc.cab.nomeDocfa, nNumer: this.proceGestEnc.cab.nNumer};
      this._cgLocalStorageGroupService.setItem(`${this.grupoClifos}.encomendas.processamento.defaultdata`, encomendasDefaultData, schemaIJsonEncomendasCab, EGroupName.ERP).subscribe();
    }
    if (this.showPreview) {
      this.close();
    } else {
      this.dismiss();
    }
  }

  public novoLote(): Promise<void> {
    if (!this._maintenanceLotes) {
      this._maintenanceLotes = this._entityMaintenanceService.build(ENTITY_NAME_LOTES);
    }
    return this._maintenanceLotes
      .maintenanceNew({params: {model: {nArtigo: this.linhaEnc?.nArtigo, nomeArtigo: this.linhaEnc?.nomeArtigo}, nArtigo: this.linhaEnc?.nArtigo, nomeArtigo: this.linhaEnc?.nomeArtigo}})
      .then(() => {
        this._getLotesArtigoLinha(this.linhaEnc);
      })
      .catch((reason: unknown) => {
        if (!reason) {
          this._getLotesArtigoLinha(this.linhaEnc);
        }
        this._logger.error(reason);
      });
  }

  public fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this._getPdf(doc);

  private _getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc, doc.cab.nDocumento === 0 || !doc.cab.terminado);
  }

  private _emptyMoralInterface(): IJsonMorAl {
    return {
      nConta: '',
      nome: '',
      rua: '',
      cPost: '',
      localidade: '',
      nipc: '',
      id: undefined,
      observacoes: '',
      morada: '',
      codPais: CONSTANT_COD_PAIS_NACIONAL,
      moralID: '',
      desativado: false,
      facIDGuidID: '',
      contactoNome: '',
      contactoTelefone: '',
      codigoPontoPickup: '',
      nomePais: CONSTANT_NOME_PAIS_NACIONAL,
      nIdAltern: undefined,
      nomeConta: ''
    };
  }

  private _getLotesArtigoLinha(linha: IJsonDocSatEncomLin, preencheAutomaticamente: boolean = false): void {
    if (!linha?.nArtigo) {
      this._lotesLinha = [];
      this.dataGridLotesDefinition.dataSource = this._lotesLinha;
      return;
    }
    const search = `nArtigo=${linha.nArtigo}&terminado=0`;
    this._lotes.query({pesquisa: search}).then((response: THttpQueryResponse<IJsonLote>) => {
      if (response) {
        const lotes: Array<IJsonLote> = response.body.list;

        if (lotes.length > 0) {
          if (this._lotesLinha.length > 0) {
            this._lotesLinha = [];
          }
          for (const item of lotes) {
            const stock = item?.stockArmazens?.find((stockArmaz: IJsonArtigoLoteArmazemStock) => stockArmaz?.nArmazem === linha.nArmaz);
            const qtdStock = stock ? stock.qtd : 0;
            this._lotesLinha.push({nLote: item.nLote, dataFabrico: item.dataFabrico, dataValidade: item.dataValidade, nLoteEspecifico: item.nLoteEspecifico, qtd: 0, qtdStock: qtdStock});
          }
          this.dataGridLotesDefinition.dataSource = this._lotesLinha;
          linha.lotes = this._lotesLinha;
          if (preencheAutomaticamente) {
            preencheAutomaticamenteLotesNaLinha(linha, this._nDecimaisQtd);
          }
        }
      }
    });
  }
}
