<div class="crss">
  <entity-detail entityName="crss" [(model)]="model" [type]="type" [maintenanceMode]="maintenanceMode" [formOrientation]="formOrientation" [callback]="callback" (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [model]="model" [definition]="formDefinition" [template]="formTemplate" [properties]="{readonly: type === stateTypes.DETAIL, orientation: formOrientation}"></pl-form>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL, orientation: formOrientation}">
        <span [translate]="'crss.listRegimes'"></span>

        <dx-data-grid [cgDxDataGrid]="definition" [dataSource]="definition.dataSource" (onInitialized)="onDataGridInitializedCRSS($event)">
          <div *dxTemplate="let item of 'actions'" class="text-center">
            <button type="button" class="btn btn-warning btn-xs" (click)="editarlinha(item.data)">
              <i class="fa fa-fw fa-pencil"></i>
            </button>
          </div>
        </dx-data-grid>
      </pl-form>
    </div>
  </entity-detail>
</div>
