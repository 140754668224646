<div class="faturacao-eletronica-configuracoes entity-detail-form" [plPromise]="mainLockPromise">
  <pl-tabs [theme]="'style3'" attrName="tab" [activeId]="tabActiveId" (activeIdChange)="changedTab($event)">
    <pl-tab [id]="tabs.Certificado" caption="faturacaoeletronicaconfiguracoes.tabs.certificado">
      <div class="faturacao-eletronica-configuracoes-certificado" *plTabContent>
        <config-options *ngIf="allowAmbientePreProducao" [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>

        <pl-alert type="success" [closeable]="false" *ngIf="fatEletronicaConfig.faturacaoEletronicaAtiva">
          <span *ngIf="fatEletronicaConfig.tipoCertificado === tiposCertificado.CertificadoPFX" [translate]="'faturacaoeletronicaconfiguracoes.text.tipoCertificadoNotSelected'"></span>
          <ng-container *ngIf="fatEletronicaConfig.faturacaoEletronicaAtiva && fatEletronicaConfig.tipoCertificado !== tiposCertificado.CertificadoPFX">
            <div class="d-flex" style="align-items: center; gap: 15px">
              <i class="fa fa-fw fa-check-circle" aria-hidden="true"></i>
              <span
                [translate]="'faturacaoeletronicaconfiguracoes.text.selectedTipoCertificado'"
                [translateParams]="{
                  plataforma:
                    (fatEletronicaConfig.tipoCertificado === tiposCertificado.SAFE
                      ? 'faturacaoeletronicaconfiguracoes.certificadoAvailable.safe'
                      : fatEletronicaConfig.tipoCertificado === tiposCertificado.SAFETestes
                        ? 'faturacaoeletronicaconfiguracoes.certificadoAvailable.safeTestes'
                        : fatEletronicaConfig.tipoCertificado === tiposCertificado.DigitalSign
                          ? 'faturacaoeletronicaconfiguracoes.certificadoAvailable.digitalSign'
                          : fatEletronicaConfig.tipoCertificado === tiposCertificado.DigitalSignTestes
                            ? 'faturacaoeletronicaconfiguracoes.certificadoAvailable.digitalSignTestes'
                            : ''
                    ) | translate
                }">
              </span>
              <button type="button" class="btn btn-xs btn-danger" [click]="fnDeactivateCertificate" plPromise>
                <i class="fa fa-fw fa-ban me-1" aria-hidden="true"></i><span [translate]="'faturacaoeletronicaconfiguracoes.btn.desativar'"></span>
              </button>
            </div>
          </ng-container>
        </pl-alert>

        <pl-alert type="error" [closeable]="false" *ngIf="!fatEletronicaConfig.faturacaoEletronicaAtiva">
          <ng-container><span [translate]="fatEletronicaConfig.faturacaoEletronicaEstadoStr"></span>&nbsp;</ng-container>
        </pl-alert>

        <pl-navpill class="faturacao-eletronica-navpill" [(activeId)]="certificadoPillActiveId" [plPromise]="navPillPromise" [destroyOnHide]="false">
          <pl-navpill-panel [id]="certificadoPills.DigitalSign">
            <ng-container *plNavPillPanelTitle>
              <ng-container
                *ngTemplateOutlet="
                  templateTipoCertificado;
                  context: {
                    caption: 'faturacaoeletronicaconfiguracoes.certificadoAvailable.digitalSignShort',
                    description: 'faturacaoeletronicaconfiguracoes.certificadoAvailable.digitalSignFullname',
                    tipo: tiposCertificado.DigitalSign
                  }
                "></ng-container>
            </ng-container>

            <div *plNavPillPanelContent>
              <h4>
                <span class="me-3" [translate]="'faturacaoeletronicaconfiguracoes.certificadoAvailable.digitalSignShort'"></span>
                <button
                  *ngIf="!fatEletronicaConfig.faturacaoEletronicaAtiva || fatEletronicaConfig.tipoCertificado !== tiposCertificado.DigitalSign"
                  type="button"
                  class="btn btn-xs btn-success"
                  [click]="fnActivateCertificate(tiposCertificado.DigitalSign)"
                  plPromise>
                  <i class="fa fa-check me-1" aria-hidden="true"></i><span [translate]="'faturacaoeletronicaconfiguracoes.btn.ativar'"></span>
                </button>
                <span
                  *ngIf="fatEletronicaConfig.faturacaoEletronicaAtiva && fatEletronicaConfig.tipoCertificado === tiposCertificado.DigitalSign"
                  class="cert-activo-tag"
                  [translate]="'global.text.active'"></span>
              </h4>

              <div class="entity-detail-form">
                <pl-form>
                  <pl-group>
                    <label [translate]="'faturacaoeletronicaconfiguracoes.digitalsign.nomeAutorizador'"></label>
                    <edit>
                      <pl-edit
                        type="text"
                        attrName="authorizername"
                        autocapitalize="off"
                        autocomplete="off"
                        [(model)]="faturacaoEletronicaDigitalSignConfig.nomeAutorizador"
                        [properties]="{disabled: hasDigitalSignUser, validators: {maxlength: {value: 50}}}"
                        plAutoFocus
                        [plAutoFocusDisabled]="isUpdatingDigitalSign">
                      </pl-edit>
                      <div *ngIf="faturacaoEletronicaDigitalSignConfig?.error" style="font-size: 12px" class="text-danger fw-bold mt-1">
                        <i class="fa fa-exclamation-triangle fa-fw" aria-hidden="true"></i><span>{{ faturacaoEletronicaDigitalSignConfig?.errorDescription }}</span>
                      </div>
                    </edit>
                  </pl-group>

                  <pl-group>
                    <label [translate]="'faturacaoeletronicaconfiguracoes.digitalsign.idAutorizador'"></label>
                    <edit>
                      <pl-edit
                        type="text"
                        attrName="authorizerid"
                        autocapitalize="off"
                        autocomplete="off"
                        [(model)]="faturacaoEletronicaDigitalSignConfig.idAutorizador"
                        [properties]="{disabled: hasDigitalSignUser, validators: {maxlength: {value: 100}}}"
                        plAutoFocus
                        [plAutoFocusDisabled]="!isUpdatingDigitalSign">
                      </pl-edit>
                    </edit>
                  </pl-group>

                  <pl-group>
                    <label [translate]="'faturacaoeletronicaconfiguracoes.digitalsign.secret'"></label>
                    <edit>
                      <pl-edit type="password" attrName="authorizersecret" [(model)]="authorizerSecretDigitalSign" [properties]="{disabled: hasDigitalSignUser, validators: {maxlength: {value: 80}}}">
                      </pl-edit>
                    </edit>
                  </pl-group>

                  <div class="d-flex" style="justify-content: flex-end; gap: 8px; align-items: center">
                    <span *ngIf="hasDigitalSignUser && !hasDigitalSignAuthError()">
                      <label style="margin: 0" [translate]="'faturacaoeletronicaconfiguracoes.text.dataValidade'"></label>:<span class="ms-1">{{
                        faturacaoEletronicaDigitalSignConfig?.accountExpiration | cgcDate
                      }}</span>
                    </span>
                    <button
                      *ngIf="!hasDigitalSignUser || hasDigitalSignAuthError()"
                      type="button"
                      class="btn btn-sm btn-primary"
                      [click]="authenticateDigitalSignAuthorizer(faturacaoEletronicaDigitalSignConfig.nomeAutorizador)"
                      plPromise>
                      <i class="fa fa-fw fa-key"></i>&nbsp;<span [translate]="'faturacaoeletronicaconfiguracoes.text.authenticate'"></span>
                    </button>
                    <button *ngIf="hasDigitalSignUser" type="button" class="btn btn-sm btn-danger" [click]="fnDeleteDigitalSignAuthData" plPromise>
                      <i class="fa fa-fw fa-trash-o"></i>&nbsp;<span [translate]="'faturacaoeletronicaconfiguracoes.text.apagar'"></span>
                    </button>
                  </div>
                </pl-form>

                <hr />

                <p><i class="fa fa-exclamation-triangle fa-fw text-warning" aria-hidden="true"></i><span [translate]="'faturacaoeletronicaconfiguracoes.text.savedInfoIsAnonimous'"></span></p>

                <div class="mt-2 config-info">
                  <div class="mb-1">
                    <span [translate]="'faturacaoeletronicaconfiguracoes.text.infoDigitalSignMessage'"></span>
                  </div>
                  <!-- NÃO REMOVER, VAI SER NECESSÁRIO QUANDO A FAQ ESTIVER CONCLUÍDA -->
                  <!-- <div>
                    <span [translate]="'faturacaoeletronicaconfiguracoes.text.faq'"></span>
                  </div> -->
                  <div class="mt-2">
                    <!-- NÃO REMOVER, VAI SER NECESSÁRIO QUANDO A FAQ ESTIVER CONCLUÍDA -->
                    <!-- <button type="button" class="btn btn-sm btn-light">
                      <i class="fa fa-question-circle"></i>&nbsp;<span [translate]="'faturacaoeletronicaconfiguracoes.faq.dropdowntitle'"></span>
                    </button> -->
                    <a class="btn btn-light btn-sm" href="https://gapi-dev.digitalsign.pt/login?/?lang=PT" target="_blank">
                      <i class="fa fa-file"></i>&nbsp;<span [translate]="'faturacaoeletronicaconfiguracoes.digitalsign.dropdown.gestao'"></span>
                    </a>
                    <a class="btn btn-light btn-sm" href="https://www.digitalsign.pt/pt/customer/login" target="_blank" style="margin-left: 1rem; margin-right: 1rem">
                      <i class="fa fa-users"></i>&nbsp;<span [translate]="'faturacaoeletronicaconfiguracoes.digitalsign.dropdown.cliente'"></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </pl-navpill-panel>
          <pl-navpill-panel [id]="certificadoPills.Safe">
            <ng-container *plNavPillPanelTitle>
              <ng-container
                *ngTemplateOutlet="
                  templateTipoCertificado;
                  context: {
                    caption: 'faturacaoeletronicaconfiguracoes.certificadoAvailable.safeShort',
                    description: 'faturacaoeletronicaconfiguracoes.certificadoAvailable.safeFullname',
                    tipo: tiposCertificado.SAFE
                  }
                "></ng-container>
            </ng-container>

            <div *plNavPillPanelContent>
              <h4>
                <span class="me-3" [translate]="'faturacaoeletronicaconfiguracoes.certificadoAvailable.safeShort'"></span>
                <button
                  *ngIf="!fatEletronicaConfig.faturacaoEletronicaAtiva || fatEletronicaConfig.tipoCertificado !== tiposCertificado.SAFE"
                  type="button"
                  class="btn btn-xs btn-success"
                  [click]="fnActivateCertificate(tiposCertificado.SAFE)"
                  plPromise>
                  <i class="fa fa-check me-1" aria-hidden="true"></i><span [translate]="'faturacaoeletronicaconfiguracoes.btn.ativar'"></span>
                </button>
                <span
                  *ngIf="fatEletronicaConfig.faturacaoEletronicaAtiva && fatEletronicaConfig.tipoCertificado === tiposCertificado.SAFE"
                  class="cert-activo-tag"
                  [translate]="'global.text.active'"></span>
              </h4>

              <p [translate]="'faturacaoeletronicaconfiguracoes.text.checkAcessoSAFEMsg'"></p>
              <div>
                <span [translate]="'faturacaoeletronicaconfiguracoes.text.checkAcessoSAFE'"></span>
                <pl-edit type="radiogroup" attrName="headerEstadoUso" [(model)]="checkSAFE" [properties]="radioGroupCheckSAFE"></pl-edit>
              </div>
              <dx-data-grid
                [cgDxDataGrid]="dataGridDefinitionSafeCertificados"
                cgDxDataGridInstanceName="faturacaoEletronicaSafeCertificado"
                [dataSource]="dataGridDefinitionSafeCertificados.dataSource"
                (onInitialized)="onDataGridInitializedSafeCertificados($event)"
                (onRowRemoved)="onRowRemovedSafeCertificados()">
                <div *dxTemplate="let item of 'templateDigitalSignAutenticacaoGov'">
                  <ng-container
                    *ngTemplateOutlet="
                      templateLink;
                      context: {
                        href: !ambientePreProducao ? 'https://www.autenticacao.gov.pt/area-privada/atributos-profissionais' : 'https://pprwww.autenticacao.gov.pt/area-privada/atributos-profissionais',
                        caption: 'faturacaoeletronicaconfiguracoes.safe.autenticacaoGov'
                      }
                    ">
                  </ng-container>
                </div>
                <div *dxTemplate="let item of 'templateToolbarBtnAddSAFECertificado'">
                  <pl-tooltip [config]="{text: 'faturacaoeletronicaconfiguracoes.addsafecertificadomodal.title', tooltipClass: 'tooltip-primary'}">
                    <button type="button" *plTooltipContent class="btn btn-primary btn-xs" [click]="fnAddSAFECertificate" plPromise [disabled]="!checkSAFE">
                      <i class="fa fa-fw fa-plus"></i>&nbsp;<span [translate]="'faturacaoeletronicaconfiguracoes.text.register'"></span>
                    </button>
                  </pl-tooltip>
                </div>
              </dx-data-grid>

              <hr />

              <p><i class="fa fa-exclamation-triangle fa-fw text-warning" aria-hidden="true"></i><span [translate]="'faturacaoeletronicaconfiguracoes.text.savedInfoIsAnonimous'"></span></p>

              <pl-alert type="notice" [closeable]="false" style="background-color: aliceblue; font-size: 12px">
                <div style="padding-bottom: 1rem">
                  <i class="fa fa-info-circle" aria-hidden="true"></i>
                  <span [translate]="'faturacaoeletronicaconfiguracoes.text.infoSAFEMessage'" style="padding-left: 1rem"></span>
                </div>
                <div style="padding-bottom: 1rem">
                  <span [translate]="'faturacaoeletronicaconfiguracoes.text.faq'"></span>
                </div>
                <div>
                  <button type="button" class="btn btn-sm btn-light" (click)="openInfoModal('faq')">
                    <i class="fa fa-question-circle"></i>&nbsp;<span [translate]="'faturacaoeletronicaconfiguracoes.faq.dropdowntitle'"></span>
                  </button>
                  <button type="button" class="btn btn-sm btn-light float-end" (click)="openInfoModal('guia')">
                    <i class="fa fa-fw fa-book" aria-hidden="true"></i><span [translate]="'faturacaoeletronicaconfiguracoes.guide.dropdowntitle'"></span>
                  </button>
                </div>
              </pl-alert>
            </div>
          </pl-navpill-panel>
        </pl-navpill>
      </div>
    </pl-tab>

    <pl-tab *ngIf="fatEletronicaConfig.acessoTiposDocumento && fatEletronicaConfig.faturacaoEletronicaAtiva" [id]="tabs.TiposDocumento" caption="faturacaoeletronicaconfiguracoes.tabs.tiposdocumento">
      <div *plTabContent>
        <pl-group>
          <label><span [translate]="'faturacaoeletronicaconfiguracoes.radioOptions.caption'"></span>:</label>
          <edit>
            <pl-edit
              type="radiogroup"
              attrName="modoFuncionamentoDocfas"
              [model]="fatEletronicaConfig.modoFuncionamentoDocfas"
              (modelChange)="fatEletronicaConfig.modoFuncionamentoDocfas = $event; onChangeModoFuncDocfas()"
              [properties]="radioGroupModoFuncDocfas">
            </pl-edit>
          </edit>
        </pl-group>

        <pl-alert [closeable]="false" type="info" *ngIf="fatEletronicaConfig.modoFuncionamentoDocfas === 0">
          <i class="fa fa-info-circle" aria-hidden="true"></i>
          <span [translate]="'faturacaoeletronicaconfiguracoes.text.allTiposDocs'" style="padding-left: 1rem"></span>
        </pl-alert>
        <div *ngIf="fatEletronicaConfig.modoFuncionamentoDocfas === 1">
          <div class="tiposdocumento-wrapper-dxgrid">
            <div class="tiposdocumento-table-dxgrid">
              <div class="text-nowrap"><h6 [translate]="'faturacaoeletronicaconfiguracoes.text.semFaturacaoEletronica'"></h6></div>
              <dx-data-grid
                [cgDxDataGrid]="dataGridDefinitionTiposDocumento"
                [cgDxDataGridColumns]="dataGridDefinitionColumnsTiposDocumentoSemFatEletronica"
                [dataSource]="dataGridDefinitionTiposDocumento.dataSource"
                [filterValue]="['status', '=', 'semFaturacaoEletronica']"
                (onInitialized)="onInitialized($event, true)"
                (onContentReady)="onContentReady(true)"
                (onCellClick)="onCellClick($event)"
                (onCellDblClick)="onCellDblClick($event)"
                (onCellPrepared)="onCellPrepared($event)">
                <dxo-row-dragging group="statusGroup" [data]="statuses.SemFaturacaoEletronica" [onAdd]="fnOnAddTipoDocumentoTable"></dxo-row-dragging>
              </dx-data-grid>
            </div>

            <div class="tiposdocumento-btn-actions-dxgrid">
              <button type="button" class="btn btn-primary btn-sm" [disabled]="emptyAtivosSemFaturacaoEletronicaTable" (click)="allRight()">
                <i class="fa fa-fw fa-angle-double-right"></i>
              </button>

              <button type="button" class="btn btn-primary btn-sm" [disabled]="!selectedSemFaturacaoEletronica" (click)="selectedRight()">
                <i class="fa fa-fw fa-angle-right"></i>
              </button>

              <button type="button" class="btn btn-primary btn-sm" [disabled]="!selectedComFaturacaoEletronica" (click)="selectedLeft()">
                <i class="fa fa-fw fa-angle-left"></i>
              </button>

              <button type="button" class="btn btn-primary btn-sm" [disabled]="emptyAtivosComFaturacaoEletronicaTable" (click)="allLeft()">
                <i class="fa fa-fw fa-angle-double-left"></i>
              </button>
            </div>

            <div class="tiposdocumento-table-dxgrid">
              <div class="text-nowrap"><h6 [translate]="'faturacaoeletronicaconfiguracoes.text.comFaturacaoEletronica'"></h6></div>
              <dx-data-grid
                [cgDxDataGrid]="dataGridDefinitionTiposDocumento"
                [cgDxDataGridColumns]="dataGridDefinitionColumnsTiposDocumentoComFatEletronica"
                [dataSource]="dataGridDefinitionTiposDocumento.dataSource"
                [filterValue]="['status', '=', 'comFaturacaoEletronica']"
                (onInitialized)="onInitialized($event, false)"
                (onContentReady)="onContentReady(false)"
                (onCellClick)="onCellClick($event)"
                (onCellDblClick)="onCellDblClick($event)"
                (onCellPrepared)="onCellPrepared($event)">
                <dxo-row-dragging group="statusGroup" [data]="statuses.ComFaturacaoEletronica" [onAdd]="fnOnAddTipoDocumentoTable"></dxo-row-dragging>
              </dx-data-grid>
            </div>
          </div>
          <cg-table-legend [legend]="tableLegend"></cg-table-legend>
        </div>
      </div>
    </pl-tab>

    <pl-tab *ngIf="fatEletronicaConfig.acessoClientes && fatEletronicaConfig.faturacaoEletronicaAtiva" [id]="tabs.Clientes" caption="faturacaoeletronicaconfiguracoes.tabs.clientes">
      <div *plTabContent>
        <pl-group>
          <label [translate]="'faturacaoeletronicaconfiguracoes.radioOptions.caption'"></label>
          <edit>
            <pl-edit
              type="radiogroup"
              attrName="modoFuncionamentoClientes"
              [model]="fatEletronicaConfig.modoFuncionamentoClientes"
              (modelChange)="fatEletronicaConfig.modoFuncionamentoClientes = $event; onChangeModoFuncClientes()"
              [properties]="radioGroupModoFuncClientes">
            </pl-edit>
          </edit>
        </pl-group>

        <pl-alert class="info" type="info" [closeable]="false" *ngIf="fatEletronicaConfig.modoFuncionamentoClientes === 0">
          <i class="fa fa-info-circle" aria-hidden="true"></i>
          <span [translate]="'faturacaoeletronicaconfiguracoes.text.allClientes'" style="padding-left: 1rem"></span>
        </pl-alert>
        <div *ngIf="fatEletronicaConfig.modoFuncionamentoClientes === 1">
          <div class="grid-configuracoes-clientes">
            <dx-data-grid
              [cgDxDataGrid]="dataGridDefinitionClientes"
              cgDxDataGridInstanceName="faturacaoEletronicaConfiguracoesClientes"
              [dataSource]="dataGridDefinitionClientes.dataSource"
              (onCellPrepared)="onCellPrepared($event)">
              <div *dxTemplate="let item of 'headerCellTemplateBtnClientes'">
                <button type="button" class="btn btn-primary btn-xs" (click)="addClient()">
                  <i class="fa fa-fw fa-plus"></i>
                </button>
              </div>

              <div *dxTemplate="let item of 'cellTemplateBtnClientes'">
                <button type="button" class="btn btn-warning btn-xs" (click)="editClient(item.data)">
                  <i class="fa fa-fw fa-pencil"></i>
                </button>

                <button type="button" class="btn btn-danger btn-xs" (click)="removeClient(item.data)">
                  <i class="fa fa-fw fa-times"></i>
                </button>
              </div>
            </dx-data-grid>
          </div>
          <cg-table-legend [legend]="tableLegend"></cg-table-legend>
        </div>
      </div>
    </pl-tab>
  </pl-tabs>
</div>

<ng-template #templateTipoCertificado let-caption="caption" let-tipo="tipo" let-description="description">
  <div class="tipo-certificado" [class.active]="fatEletronicaConfig.faturacaoEletronicaAtiva && fatEletronicaConfig.tipoCertificado === tipo">
    <div class="tipo-certificado-caption">
      <div>
        <span class="fw-bold mb-1" style="font-size: 1.1em" [translate]="caption"></span>
        <span *ngIf="tipo === tiposCertificado.DigitalSignTestes || tipo === tiposCertificado.SAFETestes" class="badge bg-secondary ms-1" style="vertical-align: text-top">Testes</span>
      </div>
      <div style="font-size: 11px" [translate]="description"></div>
    </div>
    <div
      class="certificado-state text-center"
      [attr.title]="
        (fatEletronicaConfig.tipoCertificado === tipo ? 'faturacaoeletronicaconfiguracoes.text.tipoCerficadoAtivo' : 'faturacaoeletronicaconfiguracoes.text.setTipoCerficadoAtivo') | translate
      ">
      <div><span class="certificado-state-circle"></span></div>
      <div class="certificado-state-active-text" [translate]="'global.text.active'"></div>
    </div>
  </div>
</ng-template>

<ng-template #templateLink let-caption="caption" let-href="href">
  <a class="faturacao-eletronica-link" [href]="href" target="_blank" rel="noopener noreferrer">
    <div class="dx-list-item-icon-container">
      <i class="dx-icon dx-icon-link dx-list-item-icon" aria-hidden="true"></i>
    </div>
    <span [translate]="caption"></span>
  </a>
</ng-template>
