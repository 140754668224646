import {EDocsComerciaisClifoStatus} from './docsComerciais.entity.interface';
import {EGrupoDoc} from '../../datasources/grupodoc/grupoDoc.datasource.interface';
import {IJsonClifo} from '../clifos/jsonClifo.entity.interface';
import {IJsonDocComercialMeioPagam} from './components/meiospagamento/jsonDocComercialMeioPagam.interface';
import {IJsonTransportador} from '../../interfaces/jsonTransportador.interface';
import {TDate} from '../../../common/dates';
import {IJsonMoradaFaturacao} from '../moradasfaturacao/jsonMoradaFaturacao.entity.interface';
import {IJsonTxtcl} from '../txtcl/jsonTxtcl.entity.interface';
import {IJsonTxtar} from '../txtar/jsonTxtar.entity.interface';

export enum EEstadoEncomenda {
  NaoAplicavel = 'eeNaoAplicavel',
  Pendente = 'eePendente',
  Satisfeita = 'eeSatisfeita'
}

export enum ETipoComunicacaoEFatura {
  None,
  WebService,
  SAFT
}

export enum EATEstado {
  None,
  Erro,
  ComunicadoOK,
  AnulacaoPendente,
  ComunicacaoAnuladoOK
}

export enum EFACCBHASHEstado {
  Indefinido,
  Rascunho,
  DocSemAssinatura,
  DocAssinado,
  DocOrigemExterna,
  DocRecuperacao,
  DocImportado
}

export enum EFACCBEmitidoDocDigital {
  NAN,
  NaoEmitido,
  Emitido,
  Enviado,
  Arquivado
}

export interface IJsonDocUltimoDocGlobalCab {
  faccbId: number;
  nDoc: string;
}

export interface IJsonDocComercialCab {
  faccbId?: number;
  anulado?: boolean;
  anuladoStr?: string;
  atDocCodeID?: string;
  codCondComerciais?: number;
  codCondComTabRegCab?: number;
  codEstado?: string;
  codRet?: string;
  codTabelaPrecos?: number;
  dataDoc?: TDate;
  dataDocExterno?: TDate;
  dataEntrega?: TDate;
  dataEstado?: TDate;
  dataInicTran?: TDate;
  dataRequis?: TDate;
  dataVenc?: TDate;
  docActions?: Array<IJsonDocComercialAction>;
  docJaImprimido?: boolean;
  docNome?: string;
  estado?: string;
  estadoEncomenda?: EEstadoEncomenda;
  estadoEncomendaStr?: string;
  estadoEnvio?: number;
  estadoEnvioStr?: string;
  fluxoMonetario?: number;
  grupoDocfa?: EGrupoDoc;
  horaEstado?: TDate;
  horaInicTran?: TDate;
  integraCCorrentes?: boolean;
  isDevolucao?: boolean;
  isDocTransporteSAFT?: boolean;
  modoEnvio?: string;
  nCCusto?: string;
  nCondPag?: number;
  nConta?: string;
  nDoc?: string;
  nDocFa?: number;
  nDocumento?: number;
  nDocumentoDraft?: number;
  nFactFornec?: string;
  nIdAltern?: number;
  nif?: string;
  nNumer?: number;
  nome?: string;
  nomeEstado?: string;
  nPaletes?: string;
  nRequisicao?: string;
  observacoes?: string;
  podeFazerDevolucao?: boolean;
  porPagar?: number;
  retencaoIncidArred?: number;
  retencaoIncidencia?: number;
  retencaoValor?: number;
  returnCode?: number;
  returnMessage?: string;
  stampInsert?: TDate;
  stampUpdate?: TDate;
  taxaRet?: number;
  terminado?: boolean;
  totalDesconto?: number;
  totalDocumento?: number;
  totalIliquido?: number;
  totalIva?: number;
  totalLiquido?: number;
  totalDescontoME?: number;
  totalDocumentoME?: number;
  totalIliquidoME?: number;
  totalIvaME?: number;
  totalLiquidoME?: number;
  tipoComunicacao?: ETipoComunicacaoEFatura;
  atEstado?: EATEstado;
  comunicadoAoEFatura?: boolean;
  hashEstado?: EFACCBHASHEstado;
  faturaWebServSujAComunicacao?: boolean;
  transformado?: boolean;
  nrefprocesso?: string;
  codMoeda?: number;
  nomeMoeda?: string;
  cambio?: number;
  abreviaturaMoeda?: string;
  ctRefExterna?: string;
  acordoQuadro?: string;
  compromisso?: string;
  saftTipoCodigo?: string;
  emitidoDocDigital?: EFACCBEmitidoDocDigital;
  integraContabilidade?: boolean;
  dataIniDiferimento?: TDate;
  dataFimDiferimento?: TDate;
  tipoDocSujeitoFaturaDigital?: boolean;
  faturacaoPublica?: boolean;
  nDocGlobalOrigemFaccbId?: number;
  nDocGlobalOrigemNDoc?: string;
  autoFaturacao?: number;
  nomeCondPag?: string;
  identificaCarga?: boolean;
  descricaoNDocfa?: string;
  docRegularizMotivo?: string;
  docRegularizOrigExt?: string;
  docRegularizData?: TDate;
  ocrCabDocId?: string;
  ocrCabFolderID?: string;
  faccbPrestacoesList?: Array<IJsonDocComercialPrestacaoItem>;
}

export interface IJsonDocComercialLinha {
  nSeq?: number;
  nArtigo?: string;
  nomeArtigo?: string;
  qtd1?: number;
  prVenda1?: number;
  codIva?: number;
  taxaIva?: number;
  valorIva?: number;
  prVendaIvaInc?: number;
  qtdPorUnidade1?: number;
  d1?: number;
  d2?: number;
  d3?: number;
  d4?: number;
  d5?: number;
  d6?: number;
  dataEntregaPrev?: TDate;
  textoLivre?: string;
  nCCusto?: string;
  valorIvaME?: number;
  prVendaIvaIncME?: number;
  nrefprocesso?: string;
  nArmazem?: number;
  nVendedor?: number;
  codUnidMov?: number;
  facliId?: number;
  unidMovAbrev?: string;
  liquido?: number;
  desconto?: number;
  codIvaDefined?: boolean;
  lotes?: Array<IJsonDocLinhaLote>;
  prVenda1Defined?: boolean;
  docRegularizMotivo?: string;
  docRegularizOrigExt?: string;
  docRegularizData?: TDate;
}

export interface IJsonDocComercialAction {
  action: EDocsComerciaisClifoStatus;
  activo: boolean;
  info: string;
}

export interface IJsonDocComercialClifo extends IJsonClifo, Partial<IJsonDocComercialAction> {}

export interface IJsonDocComercial {
  cab?: IJsonDocComercialCab;
  clifo?: IJsonDocComercialClifo;
  moradaCarga?: IJsonMoradaFaturacao;
  moradaDescarga?: IJsonMoradaFaturacao;
  transportador?: IJsonTransportador;
  meiosPagamento?: Array<IJsonDocComercialMeioPagam>;
  resumoIva?: Array<IJsonDocResumoIvaItem>;
  linhas?: Array<IJsonDocComercialLinha>;
}

export interface IJsonDocResumoIvaItem {
  codIva: number;
  taxaIva: number;
  valorIliquido: number;
  valorLiquido: number;
  valorIva: number;
  valorIvaIncluido: number;
  desconto: number;
  arredLiquido: number;
  arredIva: number;
  arredIvaIncluido: number;
  arredLiquidoManual: number;
  arredIvaManual: number;
  arredIvaIncluidoManu: number;
}

export interface IJsonDocLinhaLote {
  nLote: number;
  nLoteEspecifico: string;
  qtd: number;
  seq?: number;
  dataFabrico?: TDate;
  dataValidade?: TDate;
  qtdStock?: number;
}

export interface IJsonDocComercialTextosLivres {
  clifo: Array<IJsonTxtcl>;
  artigo: Array<IJsonTxtar>;
}

export interface IJsonDocContabImputacao {
  extPocCabID: string;
  nDocAsString: string;
}

export interface IJsonDocComercialPrestacaoItem {
  faccbId: number;
  dataPrestacao: TDate;
  valorPrestacao: number;
}
