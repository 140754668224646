import {Component, Injector, Input} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {AtivosAbateService} from '../../../../modules/ativos/ativosabate/ativosAbate.module.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonAtivosAbate} from '../../../../modules/ativos/ativosabate/jsonAtivosAbate.module.interface';
import {IJsonAtivos} from '../../interfaces/jsonAtivos.entity.interface';

@Component({
  selector: 'ativos-abate-modal',
  templateUrl: './ativosAbate.modal.component.html'
})
export class AtivosAbateModalComponent extends CGModalComponent<IJsonAtivos> {
  @Input() public ativo: IJsonAtivos;
  @Input() public abate: IJsonAtivosAbate;

  public readonly pocsOutput: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _ativosAbateService: AtivosAbateService
  ) {
    super(_injector);
    this.pocsOutput = '{{nConta}} - {{nome}}';
  }

  public close(): Promise<void> {
    this.abate.listaAtivos = [
      {
        codAtivo: this.ativo.codAtivo,
        dataAquisicao: this.ativo.aquisicao.dataAquisicao,
        designacao: this.ativo.designacao,
        refExterna: this.ativo.refExterna,
        valorAtivoBrutoContab: this.ativo.valorAquisicaoContab,
        valorDepreciacoesAcumuladasContab: this.ativo.valorDepreciacaoContab,
        codAtivoPai: this.ativo.codAtivoPai,
        isGrandeReparacao: this.ativo.isAtivoGrandeReparacao,
        hasGrandesReparacoes: this.ativo.hasGrandesReparacoes
      }
    ];
    this.disableClose();
    return this._ativosAbateService
      .postRegistaAbate(this.abate)
      .then((response: HttpResponse<IJsonAtivos>) => {
        this.enableClose();
        this._plAlertService.success('ativosabate.message.success');
        super.close(response.body);
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }
}
