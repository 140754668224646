import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {
  EImportadorSaftDirectTipoAnaliticaIS,
  IImportadorSaftDirectConfig,
  IImportadorSaftDirectConfigMascaraAnalitica,
  IImportadorSaftDirectTipoAnaliticaISItem,
  IJsonImportadorSaftDirectConfigMercados
} from '../../importadorSaftDirect.module.interface';
import {IRestCmdImportadorSaftDirectLoadConfigMercados} from '../../importadorSaftDirectCommands.module.interface';
import {ImportadorSaftDirectService} from '../../importadorSaftDirect.module.service';
import {isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';

@Component({
  selector: 'importador-saft-config-modal',
  templateUrl: './importadorSaftDirect.config.modal.component.html'
})
export class ImportadorSaftDirectConfigModalComponent extends CGModalComponent<IImportadorSaftDirectConfig> implements OnInit {
  @Input() public configMercados: IJsonImportadorSaftDirectConfigMercados;
  @Input() public configMascara: IImportadorSaftDirectConfigMascaraAnalitica;
  public tipoAnaliticaISItems: Array<IImportadorSaftDirectTipoAnaliticaISItem>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _importadorSaftDirectService: ImportadorSaftDirectService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.tipoAnaliticaISItems = [
      {value: EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumento, label: 'importadorSaftDirect.strings.porTipoDeDocumento'},
      {value: EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumentoReparticao, label: 'importadorSaftDirect.strings.porTipoDocumentoReparticao'},
      {value: EImportadorSaftDirectTipoAnaliticaIS.PorCliente, label: 'importadorSaftDirect.strings.porCliente'},
      {value: EImportadorSaftDirectTipoAnaliticaIS.PorClienteReparticao, label: 'importadorSaftDirect.strings.porClienteReparticao'},
      {value: EImportadorSaftDirectTipoAnaliticaIS.PorArtigo, label: 'importadorSaftDirect.strings.porArtigo'},
      {value: EImportadorSaftDirectTipoAnaliticaIS.PorArtigoReparticao, label: 'importadorSaftDirect.strings.porArtigoReparticao'}
    ];
  }

  public ngOnInit(): void {
    if (!this.configMercados) {
      this.loadConfigMercados();
    }
  }

  public isEnabledMascaraAC(): boolean {
    return (
      this.configMascara.usaAnalitica &&
      this.configMascara.tipoAnaliticaIS in
        [EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumento, EImportadorSaftDirectTipoAnaliticaIS.PorCliente, EImportadorSaftDirectTipoAnaliticaIS.PorArtigoReparticao]
    );
  }

  public async loadConfigMercados(): Promise<void> {
    const response: IRestCmdImportadorSaftDirectLoadConfigMercados = await this._importadorSaftDirectService.loadConfigMercados();
    this._loadConfigMercados(response.valorMercadoNacional, response.valorOutrosMercados, response.valorMercadoComunitario);
  }

  public async close(): Promise<void> {
    await this._saveConfigMercados();
    await this._saveConfigMascara();
    super.close({configMercados: this.configMercados, configMascara: this.configMascara});
  }

  private _loadConfigMercados(valorMercadoNacional: string, valorOutrosMercados: string, valorMercadoComunitario: string): void {
    this.configMercados.valorMercadoNacional = valorMercadoNacional;
    this.configMercados.valorOutrosMercados = valorOutrosMercados;
    this.configMercados.valorMercadoComunitario = valorMercadoComunitario;
  }

  private async _saveConfigMascara(): Promise<void> {
    this.configMascara.mascara = this.configMascara._mascaraObj.mascara;
    if (this.configMascara.usaAnalitica) {
      if (
        this.configMascara.tipoAnaliticaIS in [EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumento, EImportadorSaftDirectTipoAnaliticaIS.PorArtigo, EImportadorSaftDirectTipoAnaliticaIS.PorCliente]
      ) {
        if (isUndefinedOrNull(this.configMascara.mascara) || this.configMascara.mascara.length === 0) {
          this._plAlertService.error('importadorSaftDirect.messages.temIndicarMascaraAnalitica');
          return;
        }
      }
    } else {
      this.configMascara.mascara = '';
    }

    await this._importadorSaftDirectService.saveConfiguracoesMascaraAnalitica(this.configMascara.usaAnalitica, this.configMascara.mascara, this.configMascara.tipoAnaliticaIS);
  }

  private async _saveConfigMercados(): Promise<void> {
    await this._importadorSaftDirectService.saveConfigMercados(this.configMercados.valorMercadoNacional, this.configMercados.valorMercadoComunitario, this.configMercados.valorOutrosMercados);
  }
}
