import {deepFreeze, IPlToolbarItem} from 'pl-comps-angular';
import {IRestCmdImportadorSaftDirect} from './importadorSaftDirectCommands.module.interface';
import {Moment} from 'moment';
import {TDate} from '../../../../common/dates';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IJsonManal} from '../../../entities/mascarasanalitica/jsonMascaraAnalitica.entity.interface';
import {IRadioGroup} from '../../../../common/interfaces/interfaces';
import {TTableLegend} from '../../../components/tablelegend/tablelegend.component.interface';
import {EDataGridLegendCellColors} from '../../../components/devexpress/datagrid/configuration/devexpress.datagrid.configuration';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';

export enum EImportadorSaftDirectState {
  Inactive = 'sfsInactive',
  Scheduled = 'sfsScheduled',
  Started = 'sfsStarted',
  Ended = 'sfsEnded',
  Timeout = 'sfsTimeout',
  Error = 'sfsError'
}

export enum EImportadorSaftDirectCurrentOperation {
  Idle,
  ProcessFile,
  SaveConfiguracoesTiposDocumento,
  SaveConfiguracoesMercado,
  SaveConfiguracoesFormacaoConta,
  SaveConfiguracoesCorrespondenciaContas,
  SaveConfiguracoesCorrespondenciaTaxas,
  SaveConfiguracoesArtigos,
  SaveDocumentosAgruparDocumentos,
  SaveDocumentos,
  DeleteDocumentosGerados,
  ProcessarRegistos
}

export enum EImportadorSaftDirectTipoImportacao {
  FicheiroSaft,
  CentralGestCloud,
  CentralGestPos,
  CentralGestPosWinTouch
}

export enum EImportadorSaftDirectTypeHasDocsData {
  LoadingData,
  HasDocsData,
  NoDocsData
}

export enum EImportadorSaftDirectValoresImportar {
  ImportarVendas = 1,
  ImportarRecibos,
  ImportarCompras,
  ImportarPagamentos
}

export enum EImportadorSaftDirectWizStepIds {
  TipoImportacao = 'wizStepTipoImportacao',
  Upload = 'wizStepUpload',
  ProcessaFileCloud = 'wizStepProcessaFileCloud',
  ProcessaFilePOS = 'wizStepProcessaFilePOS',
  ProcessaFilePOSWinTouch = 'wizStepProcessaFilePOSWinTouch',
  DocsToImport = 'wizStepDocsToImport',
  TiposDocs = 'wizStepTiposDocs',
  ConfigContasCorrentes = 'wizStepConfigContasCorrentes',
  CorrespContasCorrentes = 'wizStepCorrespContasCorrentes',
  CorrespTaxasIva = 'wizStepCorrespTaxasIva',
  Artigos = 'wizStepArtigos',
  DataDocs = 'wizStepDataDocs',
  DocsContab = 'wizStepDocsContab'
}

export enum EImportadorSaftDirectTipoInfo {
  None,
  Aviso,
  Erro
}

export enum EImportadorSaftDirectTipoMercado {
  Nacional,
  Comunitario,
  Outro
}

export enum ETaxaPerc {
  Zero = 0,
  Four = 4,
  Five = 5,
  Six = 6,
  Nine = 9,
  Twelve = 12,
  Thirteen = 13,
  Sixteen = 16,
  TwentyTwo = 22,
  TwentyThree = 23
}

export enum EImportadorSaftDirectTipoProduto {
  Produtos,
  Servicos,
  Outros,
  ImpostoEspeciais,
  ImpostosParaFiscais
}

export enum EImportadorSaftDirectTipoLancamDatas {
  UltimoDiaMes,
  UltimaDataDocMes,
  DataDoDocumento
}

export enum EImportadorSaftDirectTipoFormacaoConta {
  CriarContasCorrentes,
  UsaContaClientesDiversos
}

export enum EImportadorSaftDirectCriterioFormacao {
  RadicalSequencia,
  RadicalMercadoSequencia,
  RadicalContribuinte,
  RadicalIdExterna,
  IgualIdExterna,
  RadicalIdExternaConvertCharsAscii
}

export enum EImportadorSaftDirectTipoAgrupamento {
  None,
  PorDiaApenasCF,
  PorDiaContribuinte,
  ResumoDiaGlobal,
  ResumoMensalGlobal,
  ResumoMensalContribuinte
}

export enum EImportadorSaftDirectTipoRetencao {
  None,
  Imediata,
  NoPagamento,
  ImediataValorCCJaDeduzido
}

export enum EImportadorSaftDirectNifErrorType {
  None,
  Invalido,
  Critical
}

export enum EImportadorSaftDirectInvoiceType {
  Fatura,
  FaturaSimplificada,
  FaturaRecibo,
  NotaDebito,
  NotaCredito,
  VendaDinheiro,
  TalaoVenda,
  TalaoDevolucao,
  AlienacaoAtivos,
  DevolucaoAtivos,
  Premio,
  Estorno,
  ImputacaoCoSeguradoras,
  ImputacaoCoSeguradoraLider,
  ResseguroAceite,
  Recibo
}

export enum EImportadorSaftDirectGeneratedDocContabErrorType {
  None,
  Warning,
  Critical
}

export enum EImportadorSaftDirectGeneratedDocContabTipo {
  Documento,
  Recibo
}

export enum EImportadorSaftDirectCorrespContasAtribuirFieldEnum {
  NCCUSTO,
  RepCC
}

export interface IImportadorSaftDirectWizStepData {
  valid: boolean;
  _index: number;
  _needLoadNext: boolean;
  _needSave: boolean;
}

export enum EImportadorSaftDirectTipoAnaliticaIS {
  PorTipoDocumento,
  PorCliente,
  PorArtigo,
  PorTipoDocumentoReparticao,
  PorClienteReparticao,
  PorArtigoReparticao
}

export interface IImportadorSaftDirectWizStepTipoImportacao extends IImportadorSaftDirectWizStepData {}

export interface IImportadorSaftDirectWizStepUploadData extends IImportadorSaftDirectWizStepData {
  showUploader: boolean;
  showProcessFileProgressBar: boolean;
  processFileIntervalId: number;
}

export interface IImportadorSaftDirectWizStepCentralGestCloud extends IImportadorSaftDirectWizStepData {}

export interface IImportadorSaftDirectWizStepImportDocsData extends IImportadorSaftDirectWizStepData {
  dataSource: Array<IJsonImportadorSaftDirectDocFaturacao>;
  paymentsSource: Array<IJsonImportadorSaftDirectDocRecibo>;
  summaryDocs: IJsonImportadorSaftDirectImportDocSummary;
  summaryRecibos: IJsonImportadorSaftDirectImportRecibosSummary;
  showDocsWithErrorOrWarningOnly: boolean;
  showRecibosWithErrorOrWarningOnly: boolean;
  docsHaveErrorsOrWarnings: boolean;
  paymentsHaveErrorsOrWarnings: boolean;
  showTabDocs: boolean;
  showTabPayments: boolean;
  dataGridInstanceDocs: dxDataGrid;
}

export interface IImportadorSaftDirectWizStepConfigTiposDocs extends IImportadorSaftDirectWizStepData {
  criaDiariosEDescritivos: boolean;
  datagridDefinition: IDevExpressDataGrid;
  dataSource: Array<IJsonImportadorSaftDirectTipoDocumento>;
  configMascaraAnalitica: IImportadorSaftDirectConfigMascaraAnalitica;
}

export interface IImportadorSaftDirectConfigMascaraAnalitica {
  usaAnalitica: boolean;
  mascara: string;
  tipoAnaliticaIS: EImportadorSaftDirectTipoAnaliticaIS;
  mascaraAutocompleteOutput: string;
  _mascaraObj?: Partial<IJsonManal>;
}

export interface IImportadorSaftDirectWizStepConfigFormacaoContasData extends IImportadorSaftDirectWizStepData, IJsonImportadorSaftDirectFacConfigFormacaoContas {
  tipoFormacaoContaGroupItems: Array<unknown>;
  criterioFormacaoGroupItems: Array<unknown>;
  nDigitosHelperText: string;
}

export interface IImportadorSaftDirectWizStepCorrespContasCorrentes extends IImportadorSaftDirectWizStepData {
  _cacheSource: Array<IJsonImportadorSaftDirectCliente>;
  datagridDefinition: IDevExpressDataGrid;
  dataSource: Array<IJsonImportadorSaftDirectCliente>;
  mostrarContasComErro: boolean;
  mostrarNIFInvalid: boolean;
  haveNifInvalid: boolean;
  pbText: string;
  showPb: boolean;
}

export interface IImportadorSaftDirectWizStepCorrespTaxasIva extends IImportadorSaftDirectWizStepData {
  datagridDefinition: IDevExpressDataGrid;
  dataSource: Array<IJsonImportadorSaftDirectTaxaIva>;
}

export interface IImportadorSaftDirectWizStepConfigArtigos extends IImportadorSaftDirectWizStepData {
  mostrarApenasComErro: boolean;
  datagridDefinition: IDevExpressDataGrid;
  dataSource: Array<IJsonImportadorSaftDirectArtigo>;
  toolbarMostrarApenasComErro: IPlToolbarItem;
  querySearch: string;
  showPb: boolean;
  _cacheSource: Array<IJsonImportadorSaftDirectArtigo>;
  _reloadFromServer?: boolean;
  _loadFirstTime: number;
}

export interface IImportadorSaftDirectWizStepDatasDocumentos extends IImportadorSaftDirectWizStepData {
  dataLancContab: EImportadorSaftDirectTipoLancamDatas;
  dataSource: Array<IJsonImportadorSaftDirectDataInfo>;
  datagridDefinition: IDevExpressDataGrid;
  dataLancContabGroupItems: Array<unknown>;
}

export interface IImportadorSaftDirectWizStepGeneratedDocs extends IImportadorSaftDirectWizStepData {
  intervalId: number;
  totalValorCG: number;
  totalValorIVACG: number;
  totalValorBaseCG: number;
  totalValor: number;
  totalValorIVA: number;
  totalValorBase: number;
  dataSource: Array<IJsonImportadorSaftDirectGeneratedDocContab>;
  _cacheSource: Array<IJsonImportadorSaftDirectGeneratedDocContab>;
  datagridDefinition: IDevExpressDataGrid;
  mostrarApenasComErroToolbarItem: IPlToolbarItem;
  mostrarApenasComErro: boolean;
  pbValue: number;
  pbMessage: string;
  showPb: boolean;
}

export interface IImportadorSaftDirectWizSteps {
  tipoImportacao: IImportadorSaftDirectWizStepTipoImportacao;
  upload: IImportadorSaftDirectWizStepUploadData;
  centralGestCloud: IImportadorSaftDirectWizStepCentralGestCloud;
  centralGestPOS: IImportadorSaftDirectWizStepCentralGestCloud;
  centralGestPOSWinTouch: IImportadorSaftDirectWizStepCentralGestCloud;
  importDocs: IImportadorSaftDirectWizStepImportDocsData;
  configTiposDocs?: IImportadorSaftDirectWizStepConfigTiposDocs;
  configFormacaoContas: IImportadorSaftDirectWizStepConfigFormacaoContasData;
  correspContasCorrentes: IImportadorSaftDirectWizStepCorrespContasCorrentes;
  correspTaxasIva: IImportadorSaftDirectWizStepCorrespTaxasIva;
  configArtigos: IImportadorSaftDirectWizStepConfigArtigos;
  datasDocumentos: IImportadorSaftDirectWizStepDatasDocumentos;
  genDocs: IImportadorSaftDirectWizStepGeneratedDocs;
}

export interface IJsonImportadorSaftDirectStatus {
  requestDate: Moment;
  userStarted: string;
  userStartedId: number;
  state: EImportadorSaftDirectState;
  description: string;
  position: number;
  max: number;
  plannedStartDate: Moment;
  currentOp: EImportadorSaftDirectCurrentOperation;
  objModel?: IJsonImportadorSaftDirectObjFullModel;
  filename: string;
}

export interface IJsonImportadorSaftDirectConfigProcess {
  userCloud: string;
  passwordCloud: string;
  userPOS: string;
  passwordPOS: string;
  userPOSWinTouch: string;
  passwordPOSWinTouch: string;
}

export interface IJsonImportadorSaftDirectDocFaturacao {
  jaImportado: boolean;
  invoiceNo: string;
  invoiceDate: Moment;
  invoiceTypeStr: string;
  totalBase: number;
  totalIVA: number;
  total: number;
  extPocCabID: string;
  info: string;
  tipoInfo: EImportadorSaftDirectTipoInfo;
  invoiceType: EImportadorSaftDirectInvoiceType;
  anulado: boolean;
}

export interface IJsonImportadorSaftDirectDocRecibo {
  jaImportado: boolean;
  paymentRefNo: string;
  transactionID: string;
  transactionDate: Moment;
  description: string;
  documentStatusPaymentStatus: number;
  systemEntryDate: Moment;
  valorIVA: number;
  documentTotalsNetTotal: number;
  documentTotalsGrossTotal: number;
  tipoInfo: number;
  extPocCabID: string;
  nomeCliente: string;
  dataStr: string;
  info: string;
  valorLinhasDebito: number;
  valorLinhasCredito: number;
  settlementAmount: number;
}

export interface IJsonImportadorSaftDirectTaxaIva {
  taxCountryRegion: string;
  taxType: string;
  taxPercentage: number;
  taxCode: string;
  codIva: number;
  taxaIva: number;
  motivoTaxa: string;
  digitosIva: string;
  taxExemptionCode: string;
  _errors?: IImportadorSaftDirectTaxaIvaErrors;
  _isValidRow?: boolean;
  _errorMsg?: string;
}

export interface IJsonImportadorSaftDirectArtigo {
  productCode: string;
  productDescription: string;
  productType: EImportadorSaftDirectTipoProduto;
  isNew: boolean;
  acresceDigIVA: boolean;
  retencao: boolean;
  acresceDigMercado: boolean;
  nConta: string;
  stateContasFormadas: string;
  fakeProp: boolean;
  nContaNC: string;
  productTypeStr: string;
  nCCusto?: string;
  nCodRepCC?: number;
}

export interface IJsonImportadorSaftDirectArtigoModal extends IJsonImportadorSaftDirectArtigo {
  nContaNome?: string;
  nContaNCNome?: string;
}

export interface IJsonImportadorSaftDirectCliente {
  isNew: boolean;
  customerID: string;
  customerTaxID: string;
  name: string;
  country: string;
  countryStr: string;
  mercado: number;
  mercadoStr: string;
  contaDestino: string;
  codRetencao: string;
  jaExiste: boolean;
  nomeContaCG: string;
  contribuinteCG: string;
  codPais: number;
  nifError: EImportadorSaftDirectNifErrorType;
  erro: string;
  nCCusto?: string;
  nCodRepCC?: number;
  _isValidRow?: boolean;
  _errorMsg?: string;
}

export interface IJsonImportadorSaftDirectImportDocSummary {
  totalDocs: number;
  totalBase: number;
  totalIva: number;
  total: number;
}

export interface IJsonImportadorSaftDirectImportRecibosSummary {
  totalPayments: number;
  totalCredit: number;
  totalDebit: number;
  total: number;
}

export interface IJsonImportadorSaftDirectTipoDocumento {
  importa: boolean;
  invoice: string;
  invoiceTypeStr: string;
  nDiario: number;
  descritivo: number;
  lancaCaixa: boolean;
  contaCaixa: string;
  lancaContaCorrente: boolean;
  nCCusto?: string;
  invoiceType: EImportadorSaftDirectInvoiceType;
  tipoRetencao: EImportadorSaftDirectTipoRetencao;
  tipoRetencaoStr: string;
  agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento;
  agrupamentoDocumentosStr: string;
  isNew: boolean;
  contaDescRec: string;
  nCodRepCC?: number;
  _errors: IImportadorSaftDirectTipoDocumentoErrors;
  _isValidRow?: boolean;
  _errorMsg?: string;
}

export interface IJsonImportadorSaftDirectConfigMercados {
  valorMercadoNacional: string;
  valorMercadoComunitario: string;
  valorOutrosMercados: string;
}

export interface IJsonImportadorSaftDirectFacConfigFormacaoContas {
  tipoFormacaoConta: EImportadorSaftDirectTipoFormacaoConta;
  criterioFormacao: EImportadorSaftDirectCriterioFormacao;
  radical: string;
  nDigitos: number;
  nContaDiversos: string;
  usaDigitosIdentExterna: boolean;
}

export interface IJsonImportadorSaftDirectDataInfo {
  mes: number;
  ano: number;
  dataUltimoDoc: TDate;
  dataLancamento: TDate;
  mesStr?: string;
}

export interface IJsonImportadorSaftDirectGeneratedDocContab {
  valor: number;
  extPocCabID: string;
  nDocumento: string;
  invoices: string;
  valorIVACG: number;
  valorIVA: number;
  valorBaseCG: number;
  valorCG: number;
  valorBase: number;
  valorArredBase: number;
  valorArredIVA: number;
  erro: string;
  select: boolean;
  errorType?: EImportadorSaftDirectGeneratedDocContabErrorType;
  tipo: EImportadorSaftDirectGeneratedDocContabTipo;
  tipoStr: string;
}

export interface IJsonImportadorSaftDirectListOption {
  id: number;
  text: string;
}

export type TImportadorSaftDirectCommandRawResult<T extends IRestCmdImportadorSaftDirect = IRestCmdImportadorSaftDirect> = Promise<T>;

export type TImportadorSaftDirectCommandResult = Promise<void>;

export enum EImportadorSaftDirectCommandType {
  ProcessFile = 'ProcessFile',
  ProcessFileCloud = 'ProcessFileCloud',
  ProcessFilePOS = 'ProcessFilePOS',
  ProcessFilePOSWinTouch = 'ProcessFileWinTouchPos',
  GetDocumentosImportar = 'GetDocumentosImportar',
  GetTiposDocumento = 'GetTiposDocumento',
  SaveConfigTiposDocumento = 'SaveConfigTiposDocumento',
  LoadConfigMercados = 'LoadConfigMercados',
  SaveConfigMercados = 'SaveConfigMercados',
  LoadConfigFormacaoConta = 'LoadConfigFormacaoConta',
  SaveConfigFormacaoConta = 'SaveConfigFormacaoConta',
  GetCorrespondenciaContas = 'GetCorrespondenciaContas',
  CorrespondenciaContasMercadoChange = 'CorrespondenciaContasMercadoChange',
  CorrespContasRemoverContaAssociada = 'CorrespContasRemoverContaAssociada',
  CorrespContasAtribuirConta = 'CorrespContasAtribuirConta',
  CorrespContasAtribuirRetencao = 'CorrespContasAtribuirRetencao',
  SaveConfigCorrespContas = 'SaveConfigCorrespContas',
  GetCorrespTaxas = 'GetCorrespTaxas',
  CorrespTaxasAtribuirCodIva = 'CorrespTaxasAtribuirCodIva',
  CorrespTaxasAtribuirDigitosIva = 'CorrespTaxasAtribuirDigitosIva',
  SaveConfigCorrespTaxas = 'SaveConfigCorrespTaxas',
  LoadConfigArtigos = 'LoadConfigArtigos',
  ConfigArtigosGetList = 'ConfigArtigosGetList',
  ConfigArtigosTodosPorTipo = 'ConfigArtigosTodosPorTipo',
  ConfigArtigos = 'ConfigArtigos',
  SaveConfigArtigos = 'SaveConfigArtigos',
  LoadDatasDocumentos = 'LoadDatasDocumentos',
  DatasDocumentosSetDataLanc = 'DatasDocumentosSetDataLanc',
  DatasDocumentosTipoLancamDatasChange = 'DatasDocumentosTipoLancamDatasChange',
  SaveDocumentosAgruparDocumentos = 'SaveDocumentosAgruparDocumentos',
  SaveDocumentos = 'SaveDocumentos',
  GetGeneratedDocsContab = 'GetGeneratedDocsContab',
  DeleteGeneratedDocsContab = 'DeleteGeneratedDocsContab',
  LoadState = 'LoadState',
  CancelJob = 'StopJob',
  AtribuirAutomaticamenteDiarioEDescritivoATiposDoc = 'AtribuirAutomaticamenteDiarioEDescritivoATiposDoc',
  VerContasFormadasArtigo = 'VerContasFormadasArtigo',
  SaveConfiguracoesMascaraAnalitica = 'SaveConfiguracoesMascaraAnalitica',
  LoadConfiguracoesMascaraAnalitica = 'LoadConfiguracoesMascaraAnalitica',
  CorrespContasAtribuirCCusto = 'CorrespContasAtribuirCCusto',
  CorrespContasAtribuirRepCC = 'CorrespContasAtribuirRepCC'
}

export type TJsonImportadorSaftDirectCommandResponse<T> = T & {
  commandName: string;
  jobState: EImportadorSaftDirectState;
};

export interface IJsonImportadorSaftDirectObjFullModel {
  docFaturacaoList: Array<IJsonImportadorSaftDirectDocFaturacao>;
  docFaturacaoSummary: IJsonImportadorSaftDirectImportDocSummary;
  docRecibosList: Array<IJsonImportadorSaftDirectDocRecibo>;
  docRecibosSummary: IJsonImportadorSaftDirectImportRecibosSummary;
  tiposDocumentoList: Array<IJsonImportadorSaftDirectTipoDocumento>;
  clientesList: Array<IJsonImportadorSaftDirectCliente>;
  taxasIvaList: Array<IJsonImportadorSaftDirectTaxaIva>;
  artigosList: Array<IJsonImportadorSaftDirectArtigo>;
  datasDocumentosList: Array<IJsonImportadorSaftDirectDataInfo>;
  genDocs: Array<IJsonImportadorSaftDirectGeneratedDocContab>;
  usaAnalitica: boolean;
  mascara: string;
  criaDiariosEDescritivos: boolean;
  valorMercadoNacional: string;
  valorMercadoComunitario: string;
  valorOutrosMercados: string;
  tipoFormacaoConta: number;
  criterioFormacao: number;
  radical: string;
  nDigitos: number;
  nContaDiversos: string;
  usaDigitosIdentExterna: boolean;
  tipoAnaliticaIS: EImportadorSaftDirectTipoAnaliticaIS;
  tipoLancamDatas: EImportadorSaftDirectTipoLancamDatas;
}

export interface IJsonImportadorSaftDirectContaFormada {
  nContaClassificada: string;
  digitosMercado: string;
  digitosIVA: string;
  nContaFormada: string;
  contaFormadaNome: string;
  estado: string;
}

export interface IImportadorSaftDirectTipoAnaliticaISItem {
  value: EImportadorSaftDirectTipoAnaliticaIS;
  label: string;
}

export interface IImportadorSaftDirectCorrespContasAtribuirModel {
  nCCusto: string;
  nCodRepCC: number;
}

export interface IImportadorSaftDirectProcessFileFilters {
  utilizadorCloud: string;
  passwordCloud: string;
  utilizadorPOS: string;
  passwordPOS: string;
  utilizadorPOSWinTouch: string;
  passwordPOSWinTouch: string;
  periodo: string;
  processaVendas: boolean;
  processaCompras: boolean;
  processaRecibos: boolean;
  processaPagamentos: boolean;
}

export interface IImportadorSaftDirectTipoDocumentoErrors {
  nCCusto?: boolean;
  nCodRepCC?: boolean;
  contaCaixa?: boolean;
  lancaCaixaOuCC?: boolean;
}

export interface IImportadorSaftDirectTaxaIvaErrors {
  codIva?: boolean;
  digitosIva?: boolean;
  taxaIva?: boolean;
}

export interface IAgrupamentoDocsItem {
  agrupamentoDocumentos: EImportadorSaftDirectTipoAgrupamento;
  agrupamentoDocumentosStr: string;
}

export interface ITipoRetencaoItem {
  tipoRetencao: EImportadorSaftDirectTipoRetencao;
  tipoRetencaoStr: string;
}

export const RADIO_GROUP_TIPOS_IMPORTACAO: IRadioGroup<EImportadorSaftDirectTipoImportacao> = deepFreeze<IRadioGroup<EImportadorSaftDirectTipoImportacao>>({
  groupItems: [
    {value: EImportadorSaftDirectTipoImportacao.FicheiroSaft, label: 'importadorSaftDirect.steps.escolhaTipoImportacao.tiposImportacao.ficheiroSaft'},
    {value: EImportadorSaftDirectTipoImportacao.CentralGestCloud, label: 'importadorSaftDirect.steps.escolhaTipoImportacao.tiposImportacao.centralGestCloud'},
    {value: EImportadorSaftDirectTipoImportacao.CentralGestPos, label: 'importadorSaftDirect.steps.escolhaTipoImportacao.tiposImportacao.centralGestPOS'},
    {value: EImportadorSaftDirectTipoImportacao.CentralGestPosWinTouch, label: 'importadorSaftDirect.steps.escolhaTipoImportacao.tiposImportacao.centralGestPOSWinTouch'}
  ]
});

export const RADIO_GROUP_TIPOS_IMPORTACAO_PT: IRadioGroup<EImportadorSaftDirectTipoImportacao> = deepFreeze<IRadioGroup<EImportadorSaftDirectTipoImportacao>>({
  groupItems: [{value: EImportadorSaftDirectTipoImportacao.FicheiroSaft, label: 'importadorSaftDirect.steps.escolhaTipoImportacao.tiposImportacao.ficheiroSaft'}]
});

export const MODULE_NAME_IMPORTADOR_SAFT_DIRECT = 'importadorSaftDirect';

export const TABLE_LEGEND_IMPORT_SAFT_DIRECT_LEGEND: TTableLegend = deepFreeze([
  {
    caption: 'importadorSaftDirect.steps.viewDocsToImport.legendError',
    badgeCSSClass: EDataGridLegendCellColors.RedLegendColor
  },
  {
    caption: 'importadorSaftDirect.steps.viewDocsToImport.legendWarning',
    badgeCSSClass: EDataGridLegendCellColors.OrangeLegendColor
  }
]);

export interface IImportadorSaftDirectConfig {
  configMercados: IJsonImportadorSaftDirectConfigMercados;
  configMascara: IImportadorSaftDirectConfigMascaraAnalitica;
}
