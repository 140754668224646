import moment, {Moment} from 'moment';
import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import type {MessageBody, MessageBodyIntent} from '@centralgest/amalia-api-angular';

const TODAY = moment().startOf('day');

@Component({
  selector: 'amalia-chat-message',
  templateUrl: './amalia.chatmessage.component.html'
})
export class AmaliaChatMessageComponent implements OnChanges {
  @Input() public agentName: string;
  @Input() public agentPicture: string;
  @Input() public messageBody: Array<MessageBody>;
  @Input() public timestamp: string;
  @Input() public sentByAgent: boolean;
  @Input() public timeRead: number;
  @Output() public readonly evtActionIntent: EventEmitter<MessageBodyIntent>;
  @Output() public readonly evtLinkClicked: EventEmitter<string>;

  public messageFaq: boolean;
  public timestampDate: Moment;
  public isToday: boolean;

  constructor() {
    this.evtActionIntent = new EventEmitter<MessageBodyIntent>();
    this.evtLinkClicked = new EventEmitter<string>();
    this.messageFaq = false;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.timestamp) {
      this.timestampDate = moment(changes.timestamp.currentValue);
      this.isToday = TODAY.isSame(this.timestampDate, 'date');
    }

    if (changes.messageBody) {
      const messageBody = changes.messageBody.currentValue;
      if (messageBody?.some((item) => item.format)) {
        this.messageFaq = true;
      }
    }
  }

  public actionIntent(intent: MessageBodyIntent): void {
    this.evtActionIntent.emit(intent);
  }

  public handleCustomLinkClick(text: string): void {
    this.evtLinkClicked.emit(text);
  }
}
