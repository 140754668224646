import {ClassProvider, forwardRef, Injectable} from '@angular/core';
import {DI_AMALIA_INTENT_HANDLERS} from '../../../../../../services/amalia/intent/amalia.intent.handler.di';
import {IAmaliaIntent, IAmaliaIntentHandler} from '../../../../../../services/amalia/intent/amalia.intent.handler.interface';
import {CGStateService} from '../../../../../../components/state/cg.state.service';
import {EPortal} from '../../../../../../../common/enums/portals.enums';
import {AMALIA_INTENT_NAME_DECLARACAO_IVA, IDeclaracaoIvaIntentParameters} from './contabilidade.declaracaoIva.amalia.interface';
import {MODULE_NAME_PCA_MANUTENCAO_DECLARACAOIVA} from '../../contabilidade.declaracaoIva.module.interface';

export const AMALIA_INTENT_HANDLER_DECLARACAO_IVA: ClassProvider = {
  provide: DI_AMALIA_INTENT_HANDLERS,
  multi: true,
  useClass: forwardRef(() => DeclaracaoIVAAmaliaIntentHandler)
};

@Injectable()
export class DeclaracaoIVAAmaliaIntentHandler implements IAmaliaIntentHandler<IDeclaracaoIvaIntentParameters> {
  constructor(private readonly _cgStateService: CGStateService) {}

  public intentName(): string {
    return AMALIA_INTENT_NAME_DECLARACAO_IVA;
  }

  public async intentHandler(intent: IAmaliaIntent<IDeclaracaoIvaIntentParameters>): Promise<void> {
    return this._cgStateService.redirectToState({
      transitionOptions: {reload: true},
      stateOrName: MODULE_NAME_PCA_MANUTENCAO_DECLARACAOIVA,
      portal: EPortal.CONTABILIDADE,
      params: {
        date: intent.parameters.date
      }
    });
  }
}
