<div class="proceSalarios">
  <pl-alert type="warning" *ngIf="temInconsistenciasTabelaIRS && inconsistenciasTabelaIRS.mostrarnotificacao" [autoClose]="false" (evtClosed)="inconsistenciasTabelaIRSNotifClose()">
    <i class="fa fa-lg fa-fw fa-exclamation-circle me-1"></i>
    <span [translate]="'dgempsfull.tabelaIRSColaboradoresPorValidar'"></span>
    <ul>
      <li *ngFor="let item of inconsistenciasTabelaIRS.empregadosinconsistentes">
        <span class="hyperlink hyperlink-text-color-white" (click)="openDGEMP(item.codEmp)">{{ item.nome }}</span>
      </li>
    </ul>
  </pl-alert>

  <pl-nav-wizard
    *ngIf="!isBlocked"
    [definition]="definitionNavWizard"
    [beforeStepChange]="fnBeforeStepChange"
    [onFinalize]="fnFinalize"
    [properties]="propertiesNavWizard"
    [callback]="plWizardCallback">
    <pl-nav-wizard-step icon="fa-home" caption="proceSalarios.tabIntroCaption" [stepId]="proceSalariosSteps.INTRO">
      <div *plNavWizardStepContent>
        <h4 [translate]="'proceSalarios.tabIntroTitle'"></h4>
        <p [translate]="'proceSalarios.tabIntroSubTitle'"></p>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-th-list" caption="proceSalarios.tabEmpresasCaption" [stepId]="proceSalariosSteps.EMPRESAS_SEL" [validator]="fnValidateStepEmpresasSel">
      <div *plNavWizardStepContent>
        <div class="item-group-dropdown" tabindex="-1" ngbDropdown container="body" [placement]="['right-bottom', 'bottom-right', 'right', 'auto']">
          <button type="button" class="btn btn-sm btn-info dropdown-toggle" ngbDropdownToggle>
            <span [translate]="'proceSalarios.btn.ordenar'" [translateParams]="{orderby: orderbyselected}" class="caret"></span>
          </button>

          <div class="dropdown-menu" ngbDropdownMenu>
            <button type="button" class="dropdown-item" [translate]="'proceSalarios.btn.dropdown.sortEmpresasByNEmpresa'" (click)="sortEmpresasByNEmpresa()"></button>
            <button type="button" class="dropdown-item" [translate]="'proceSalarios.btn.dropdown.sortEmpresasByName'" (click)="sortEmpresasByName()"></button>
          </div>
        </div>

        <pl-multiselect class="tabEmpresas" [model]="empresasSel" [source]="empresasSource" key="nEmpresa" [template]="msEmpresasTemplate" (evtChanged)="fnEmpresasChanged()"></pl-multiselect>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-cog" caption="proceSalarios.tabConfigCaption" [stepId]="proceSalariosSteps.PROC_CONFIG" [validator]="fnValidateStepProcConfig">
      <div *plNavWizardStepContent>
        <div class="row" *ngIf="empresasSel.length > 1">
          <div class="col-md-3">
            <div class="panel panel-warning">
              <div class="year-navi-container">
                <button type="button" class="btn btn-light btn-sm left" (click)="onDecYearClick()"><i class="fa fa-angle-left" aria-hidden="true"></i></button>
                <input class="form-control year-input" type="number" [ngModel]="modelMultiEmp.ano" (ngModelChange)="onMultiYearChange($event)" />
                <button type="button" class="btn btn-light btn-sm right" (click)="onIncYearClick()"><i class="fa fa-angle-right" aria-hidden="true"></i></button>
              </div>
            </div>
            <pl-table
              [definition]="multiDatasTableDef"
              [source]="fnMultiDatasTableSource"
              [callback]="multiDatasTableCallback"
              [properties]="multiDatasTableOptions"
              (evtSelect)="onSelectMultiDatasTable($event)">
            </pl-table>
          </div>
          <div class="col-md-9">
            <h5 class="main-title" [translate]="'proceSalarios.tipoProcessamentoGerarTitle'"></h5>

            <div class="data-box">
              <pl-edit type="checkbox" [(model)]="modelMultiEmp.tipoProceMensal" [properties]="{label: 'proceSalarios.tipoProceMensalCaption'}"></pl-edit>
              <pl-edit type="checkbox" [(model)]="modelMultiEmp.tipoProceFerias" [properties]="{label: 'proceSalarios.tipoProceFeriasCaption'}"></pl-edit>
              <pl-edit type="checkbox" [(model)]="modelMultiEmp.tipoProceNatal" [properties]="{label: 'proceSalarios.tipoProceNatalCaption'}"></pl-edit>
            </div>

            <pl-edit type="checkbox" [(model)]="modelMultiEmp.podeReprocessarSalarios" [properties]="{label: 'proceSalarios.podeReprocessarSalariosCaption'}"></pl-edit>

            <ng-container *ngIf="modelMultiEmp.podeReprocessarSalarios">
              <div class="alert alert-warning d-inline-block">
                <div><span [translate]="'proceSalarios.podeReprocessarSalariosHelpText1'"></span>:</div>
                <div [translate]="'proceSalarios.podeReprocessarSalariosHelpText2'"></div>
                <div [translate]="'proceSalarios.podeReprocessarSalariosHelpText3'"></div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="row" *ngIf="empresasSel.length === 1">
          <div class="col-md-12">
            <h4 class="main-title" [translate]="singleEmpresaCaption"></h4>
            <pl-tabs [callback]="dgEmpTabCallback" [activeId]="activeTab" (evtSelected)="changedTab($event.nextId)">
              <pl-tab caption="proceSalarios.procExistentesTitle" [id]="tabIdEmpregadosProcExistentes">
                <div *plTabContent>
                  <dx-data-grid
                    [cgDxDataGrid]="dataGridDefinitionProceExist"
                    cgDxDataGridInstanceName="proceExistTable"
                    [dataSource]="dataGridDefinitionProceExist.dataSource"
                    (onInitialized)="onInitializedProceExist($event)"
                    (onCellClick)="onCellClickProceExist($event)">
                    <div *dxTemplate="let item of 'toolbarTemplateProceExist'">
                      <div class="proce-salarios-existentes-toolbar">
                        <label [translate]="'global.text.year'"></label>
                        <pl-edit-select [model]="procExistingYear" [properties]="{select: {list: yearsSource}, disallowClear: true}" (modelChange)="onYearChange($event)"></pl-edit-select>

                        <label [translate]="'global.text.month'"></label>
                        <pl-edit-select [model]="procExistingMonth" [properties]="{select: {list: mesesSource}, disallowClear: true}" (modelChange)="onMonthChange($event)"></pl-edit-select>
                      </div>
                    </div>

                    <div *dxTemplate="let item of 'cellTemplateBtnProceExist'">
                      <pl-button type="button" klass="btn btn-danger btn-xs" [attrTitle]="'proceSalarios.deleteProcess' | translate" [onClick]="fnDeleteProcess(item.data)">
                        <i class="fa fa-fw fa-trash-o" aria-hidden="true"></i>
                      </pl-button>
                    </div>

                    <div *dxTemplate="let item of 'masterDetailProceExist'">
                      <div class="proceExist-details-container">
                        <dx-data-grid
                          [cgDxDataGrid]="dataGridDefinitionProceExistEmpregados"
                          [dataSource]="item.data.listEmpregados"
                          [(selectedRowKeys)]="item.data.selectedKeysProcExistente"
                          (onInitialized)="onInitializedProceExistEmpregados(item.data, $event)">
                          <div *dxTemplate="let itemDetail of 'toolbarTemplateProceExistEmpregados'">
                            <pl-toolbar [instanceId]="item.data.nProcessamento + '-toolbar'"></pl-toolbar>
                          </div>

                          <div *dxTemplate="let itemDetail of 'cellTemplateBtnProceExistEmpregados'">
                            <pl-button
                              *ngIf="!item.data.integradoContab"
                              type="button"
                              klass="btn btn-warning btn-xs me-1"
                              [attrTitle]="'proceSalarios.reprocEmpregadoTooltip' | translate"
                              (evtClicked)="reProcEmp(itemDetail.data)">
                              <i class="fa fa-fw fa-bolt" aria-hidden="true"></i>
                            </pl-button>
                            <pl-button
                              type="button"
                              klass="btn btn-primary btn-xs me-1"
                              [attrTitle]="'proceSalarios.consultarReciboEmpregado' | translate"
                              (evtClicked)="consultarRecibos(item.data.nProcessamento, itemDetail.data.codEmpregado)">
                              <i class="fa fa-fw fa-id-card-o" aria-hidden="true"></i>
                            </pl-button>
                            <pl-button type="button" klass="btn btn-warning btn-xs" [attrTitle]="'proceSalarios.reprocEncargosTooltip' | translate" (evtClicked)="reProcEncargos(itemDetail.data)">
                              <i class="fa fa-fw fa-id-badge" aria-hidden="true"></i>
                            </pl-button>
                          </div>
                        </dx-data-grid>
                      </div>
                    </div>
                  </dx-data-grid>
                </div>
              </pl-tab>

              <pl-tab caption="proceSalarios.novoProcessamentoTitle" [id]="tabIdEmpregadosNewProc">
                <div *plTabContent>
                  <pl-form [(formInstance)]="newProcForm" [properties]="{validators: {required: {value: true}}}">
                    <pl-group>
                      <pl-group>
                        <label [translate]="'proceSalarios.fields.tipoProcessamento'"></label>
                        <edit>
                          <entity-autocomplete
                            entity="rhtipoprocess"
                            [properties]="{allowInvalid: false, allowEmpty: false}"
                            [model]="modelSingleEmp"
                            (modelChange)="fnTipoProcessamentoChange($event)"
                            [fieldsMap]="{descricao: 'descricaoTipoProc'}"
                            [output]="tipoProcessamentoOutput">
                          </entity-autocomplete>
                        </edit>
                      </pl-group>
                      <pl-group>
                        <label [translate]="'proceSalarios.fields.dataProce'"></label>
                        <edit>
                          <pl-edit-datepicker [(model)]="modelSingleEmp.dataProcessamento" (modelChange)="onDataProcessamentoChange($event)"></pl-edit-datepicker>
                        </edit>
                      </pl-group>
                      <pl-group>
                        <label [translate]="'proceSalarios.fields.dADataProc'"></label>
                        <edit>
                          <pl-edit-datepicker [model]="modelSingleEmp.dataInicio" (modelChange)="onDataInicioChange($event)"></pl-edit-datepicker>
                        </edit>
                      </pl-group>
                      <pl-group>
                        <label [translate]="'proceSalarios.fields.ateDataProc'"></label>
                        <edit>
                          <pl-edit-datepicker [model]="modelSingleEmp.dataFim" (modelChange)="onDataFimChange($event)"></pl-edit-datepicker>
                        </edit>
                      </pl-group>
                    </pl-group>
                    <pl-group>
                      <pl-group>
                        <label [translate]="'proceSalarios.fields.calendar'"></label>
                        <edit>
                          <entity-autocomplete
                            entity="calendarios"
                            [model]="modelSingleEmp"
                            [fieldsMap]="{calenCabID: 'calendario', nome: 'calenCabDesc'}"
                            (selectedKeyChange)="fnCalendarioChange($event)"
                            [output]="calendarioOutput">
                          </entity-autocomplete>
                        </edit>
                      </pl-group>
                      <pl-group>
                        <label [translate]="'proceSalarios.fields.nDiasUteis'"></label>
                        <edit>
                          <pl-edit type="cginteger" [(model)]="modelSingleEmp.nDiasUteis"></pl-edit>
                        </edit>
                      </pl-group>
                      <pl-group>
                        <label [translate]="'proceSalarios.fields.nDiasTotalMes'"></label>
                        <edit>
                          <pl-edit type="cginteger" [(model)]="modelSingleEmp.nDiasTotalMes" [properties]="{disabled: true}"></pl-edit>
                        </edit>
                      </pl-group>
                      <pl-group>
                        <label [translate]="'proceSalarios.fields.descricao'"></label>
                        <edit>
                          <pl-edit type="text" [(model)]="modelSingleEmp.descricao"></pl-edit>
                        </edit>
                      </pl-group>
                    </pl-group>

                    <fieldset class="mt-3 col-md-6">
                      <legend [translate]="'proceSalarios.segSocialRelativaTitle'"></legend>
                      <pl-group>
                        <pl-group>
                          <label [translate]="'global.text.year'"></label>
                          <edit>
                            <pl-edit type="cginteger" [(model)]="modelSingleEmp.anoParaSegSocial"></pl-edit>
                          </edit>
                        </pl-group>
                        <pl-group>
                          <label [translate]="'global.text.month'"></label>
                          <edit>
                            <pl-edit type="cginteger" [(model)]="modelSingleEmp.mesParaSegSocial"></pl-edit>
                          </edit>
                        </pl-group>
                      </pl-group>
                    </fieldset>
                  </pl-form>
                </div>
              </pl-tab>

              <pl-tab caption="proceSalarios.empregadosTitle" [id]="tabIdEmpregadosList">
                <div *plTabContent>
                  <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDGEmp" cgDxDataGridInstanceName="dgEmp" [dataSource]="dataGridDefinitionDGEmp.dataSource" [(selectedRowKeys)]="selectedKeysDGEmp">
                  </dx-data-grid>
                </div>
              </pl-tab>
            </pl-tabs>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-bolt" caption="proceSalarios.tabProcCaption" [stepId]="proceSalariosSteps.PROC">
      <div *plNavWizardStepContent class="proc-container">
        <div class="progress">
          <div
            class="progress-bar bg-info progress-bar-striped progress-bar-animated"
            role="progressbar"
            [attr.aria-valuenow]="pbProcPos"
            [attr.aria-valuemin]="0"
            [attr.aria-valuemax]="100"
            [style.width.%]="pbProcPos"></div>
        </div>
        <div class="procLabel" [translate]="pbProcLabel"></div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-flag-checkered" caption="proceSalarios.tabResultsCaption" [stepId]="proceSalariosSteps.PROC_RESULTS">
      <div *plNavWizardStepContent>
        <ng-container *ngIf="resultsProcType === proceTypes.Multi">
          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinitionProcResults"
            cgDxDataGridInstanceName="procResults"
            [dataSource]="dataGridDefinitionProcResults.dataSource"
            (onInitialized)="onInitializedProcResults($event)"
            (onCellPrepared)="onCellPreparedProcResults($event)">
            <div *dxTemplate="let item of 'toolbarTemplateProcResults'">
              <pl-edit
                type="checkbox"
                attrName="mostrarLinhasConciliadasLabel"
                [model]="procResultsOnlyWithErrors"
                (modelChange)="changedProcResultsOnlyWithErrors($event)"
                [properties]="{label: 'proceSalarios.mostrarSoComErros'}">
              </pl-edit>
            </div>

            <div *dxTemplate="let item of 'masterDetailProcResults'">
              <dx-data-grid [cgDxDataGrid]="dataGridDefinitionprocResultsError" [dataSource]="item.data.errorList"></dx-data-grid>
            </div>
          </dx-data-grid>
        </ng-container>

        <ng-container *ngIf="resultsProcType === proceTypes.Single">
          <dx-data-grid
            [cgDxDataGrid]="dataGridDefinitionProcResultsSingle"
            cgDxDataGridInstanceName="procResultsSingle"
            [dataSource]="dataGridDefinitionProcResultsSingle.dataSource"
            (onInitialized)="onInitializedProcResultsSingle($event)">
            <div *dxTemplate="let item of 'cellTemplateBtnProcResultsSingle'">
              <pl-button
                type="button"
                klass="btn btn-primary btn-xs"
                [attrTitle]="'proceSalarios.consultarReciboEmpregado' | translate"
                (evtClicked)="consultarRecibos(item.data.nProcessamento, item.data.codEmp)">
                <i class="fa fa-fw fa-id-card-o" aria-hidden="true"></i>
              </pl-button>
            </div>
          </dx-data-grid>
        </ng-container>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
