import {TDate} from '../../../../common/dates';
import {IJsonDocContabilidade} from '../docscontabilidade/jsonDocContabilidade.interface';

export enum EATRecibosRendasSituacao {
  Default,
  Emitido,
  Anulado
}

export enum EATRecibosRendasJaLancado {
  None,
  Ignorado,
  IgnoradoPorNif,
  LigacaoDiretaNIFNDocExterno,
  AssociadoSemNDocExterno
}

export interface IJsonRecibosRendasTaxasIva {
  taxa: number;
  taxastring: string;
  valorBase: number;
  valorBasestring: string;
  valorIva: number;
  valorIvastring: string;
  valorRemanescente: number;
  valorRemanescentestring: string;
  motivoIsencao: string;
}

export interface IJsonRecibosRendasCodLabel {
  codigo: string;
  label: string;
}

export interface IJsonRecibosRendasDetalhesReciboRetencao extends IJsonRecibosRendasCodLabel {
  taxa: number;
}

export interface IJsonRecibosRendasDetalhesReciboLocador {
  nif: string;
  nome: string;
  quotaParte: string;
  nifConjuge: string;
  regime: IJsonRecibosRendasCodLabel;
  sujeitoPassivo: string;
}

export interface IJsonRecibosRendasDetalhesReciboLocatario {
  nif: string;
  nome: string;
  pais: IJsonRecibosRendasCodLabel;
  retencao: IJsonRecibosRendasDetalhesReciboRetencao;
}

export interface IJsonRecibosRendasDetalhesRecibo {
  iRecibo: number;
  numRecibo: number;
  numContrato: number;
  versaoContrato: number;
  nifEmitente: string;
  nomeEmitente: string;
  isNifEmitenteColetivo: boolean;
  emissaoData: TDate;
  dataInicio: TDate;
  dataFim: TDate;
  dataRecebimento: TDate;
  vRetencao: number;
  valor: number;
  vImportanciaRecb: number;
  retencao: IJsonRecibosRendasDetalhesReciboRetencao;
  estado: IJsonRecibosRendasCodLabel;
  tipoContrato: IJsonRecibosRendasCodLabel;
  tipoImportancia: IJsonRecibosRendasCodLabel;
  locadores: Array<IJsonRecibosRendasDetalhesReciboLocador>;
  locatarios: Array<IJsonRecibosRendasDetalhesReciboLocatario>;
}

export interface IJsonRecibosRendasImovel {
  codigoPostal: string;
  unidadeFuncional: string;
  morada: string;
  numeroMorada: string;
  localidade: string;
  andar: string;
  distrito: IJsonRecibosRendasCodLabel;
  concelho: IJsonRecibosRendasCodLabel;
  freguesia: IJsonRecibosRendasCodLabel;
  tipo: IJsonRecibosRendasCodLabel;
  artigo: string;
  fraccao: string;
  seccao: string;
  arvCol: string;
  parteComum: boolean;
  bemOmisso: boolean;
  parteArrendada: string;
  locadores: string;
  novo: boolean;
  editableMode: boolean;
  viewLoadingConcelhos: boolean;
  iewLoadingFreguesias: boolean;
  ordem: number;
  codFuncionalidadeAutorizacao: string;
  nifCondominioImovel: string;
  nifAdministradorImovel: string;
  alternateId: string;
  artigoStr: string;
  codTipoStr: string;
  codDistrito: string;
  codConcelho: string;
  codFreguesia: string;
  parteComumboolean: boolean;
  empty: boolean;
}

export interface IJsonRRErro {
  errorType: number;
  errorMessage: string;
}

export interface IJsonRecibosRendasItem {
  numeroContrato: number;
  numeroRecibo: number;
  referencia: string;
  nomeLocador: string;
  nomeLocatario: string;
  dataInicio: TDate;
  dataFim: TDate;
  valor: number;
  importancia: number;
  vRetencao: number;
  dataRecebimento: TDate;
  imovelAlternateId: string;
  estadoLabel: string;
  estado: IJsonRecibosRendasCodLabel;
  imovel: IJsonRecibosRendasImovel;
  nomeEmitente: string;
  nifEmitente: string;
  tipoImportancia: IJsonRecibosRendasCodLabel;
  detalhesRecibo: IJsonRecibosRendasDetalhesRecibo;
  taxasIva: Array<IJsonRecibosRendasTaxasIva>;
  valorIva: number;
  preDefinidoId: number;
  preDefinidoStr: string;
  erro: string;
  listaErros: Array<IJsonRRErro>;
  contaCorrente: string;
  podeGenNovaCC: boolean;
  tipoItemJaLancado: EATRecibosRendasJaLancado;
  isDeAnoEncerrado: boolean;
  documentoLancado: string;
  extPocCabID: string;
}

export interface IJsonRRImportDocApiList {
  list: Array<IJsonRecibosRendasItem>;
  listLancadosIgnorados: Array<IJsonRecibosRendasItem>;
}

export interface IJsonRRConfig {
  preDefinidoId: number;
  preDefinidoStr: string;
}

export interface IJsonRRDocContabilidade {
  doc: IJsonDocContabilidade;
  errors: Array<IJsonRRErro>;
}

export interface IJsonRRNIFConfig {
  nif: string;
  nome: string;
  usaSempreRecolha: boolean;
  preDefinido: number;
  contaCorrente: string;
  preDefinidoStr: string;
  contaCorrenteStr: string;
}

export interface IJsonRRNIFConfigSave {
  nifConfig: IJsonRRNIFConfig;
  docsList: Array<IJsonRecibosRendasItem>;
}
