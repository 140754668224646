<entity-detail
  [entityName]="entityName"
  [(model)]="model"
  [type]="type"
  [maintenanceMode]="maintenanceMode"
  [callback]="callback"
  [serviceMethodsOverride]="serviceMethodsOverride"
  (evtUpdate)="onUpdate($event)">
  <div *entityDetailContent>
    <pl-form [properties]="{readonly: isDetailState, orientation: formOrientation}" (formInstanceChange)="formInstance = $event">
      <pl-group>
        <pl-group>
          <label [translate]="'paises.fields.codISO'"></label>
          <edit>
            <pl-edit
              type="paisesISO3166"
              [model]="model.codInternacional"
              (modelChange)="codInternacionalChanged($event)"
              [properties]="{
                disabled: isEditState,
                disallowClear: true,
                validators: {required: true}
              }"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'paises.fields.abrevFiscal'"></label>
          <edit>
            <pl-edit type="text" [(model)]="model.abrevFiscal" [properties]="{disabled: true}"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'paises.fields.codPais'"></label>
          <edit>
            <pl-edit type="cgsmallint" [(model)]="model.codPais" [properties]="{disabled: fieldsDisabled || isEditState, validators: {required: true}}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'paises.fields.nomePais'"></label>
          <edit>
            <pl-edit type="text" [(model)]="model.nomePais" [properties]="{disabled: fieldsDisabled, validators: {required: true}}"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'paises.fields.abrevPais'"></label>
          <edit>
            <pl-edit type="text" [(model)]="model.abrevPais" [properties]="{disabled: fieldsDisabled, validators: {required: true}}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'paises.fields.nomePaisPropriaLingua'"></label>
          <edit>
            <pl-edit type="text" [(model)]="model.nomePaisPropriaLingua" [properties]="{disabled: fieldsDisabled}"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'paises.fields.codMoeda'"></label>
          <edit>
            <entity-autocomplete
              entity="moeda"
              [model]="model"
              [properties]="{disabled: fieldsDisabled, validators: {required: {value: true}}}"
              (selectedKeyChange)="model.codMoeda = $event"
              (evtSelectedDescriptionChanged)="model.nomeMoeda = $event"></entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'paises.fields.mercado'"></label>
          <edit>
            <pl-edit type="radiogroup" [(model)]="model.mercado" [properties]="{disabled: fieldsDisabled, groupItems: tipoMercadoSource, validators: {required: true}}"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>
    </pl-form>
  </div>
</entity-detail>
