import {Injectable, Injector} from '@angular/core';
import {Transition, UIRouterGlobals} from '@uirouter/core';
import {isString} from 'pl-comps-angular';
import {EntityService} from './entity.service';
import {IEntityService} from './entity.service.interface';
import {IEntity} from '../../components/entity/entity.definition.interface';

@Injectable({
  providedIn: 'root'
})
export class EntityServiceBuilder {
  private readonly _pool: Map<string, IEntityService>;

  constructor(
    private readonly _injector: Injector,
    private readonly _uiRouterGlobals: UIRouterGlobals
  ) {
    this._pool = new Map<string, IEntityService>();
  }

  public build<TEntityJson extends object, TEntityService extends IEntityService<TEntityJson> = IEntityService<TEntityJson>>(
    entityOrName: string | IEntity<TEntityJson, TEntityService>,
    poolName?: string
  ): TEntityService {
    const key: string = this._getKey(entityOrName, poolName);
    let instance: IEntityService = this._pool.get(key);
    if (!instance) {
      instance = new EntityService<TEntityJson>({entityOrName: entityOrName, injector: this._injector});
      this._pool.set(key, instance);
      const injector = Injector.create({
        parent: this._injector,
        providers: [{provide: Transition, useValue: this._uiRouterGlobals.transition || this._uiRouterGlobals.successfulTransitions.peekTail()}]
      });
      instance.invokeEntity(injector);
    }
    return <TEntityService>(<unknown>instance);
  }

  private _getKey(entityOrName: string | IEntity, poolName: string): string {
    poolName = poolName ? `${poolName}_` : '';
    poolName = isString(entityOrName) ? poolName + entityOrName : poolName + entityOrName.name;
    return poolName.toLowerCase();
  }
}
