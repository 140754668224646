import {deepFreeze} from 'pl-comps-angular';
import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_LOCAL_TRIBUTACAO_RENDIMENTO} from './localTributacaoRendimento.datasource.interface';
import {ERHLocalTributacaoRendimento} from '../../modules/portalrh/dgempsfull/dgempsFull.interface';

export const DATA_SOURCE_LOCAL_TRIBUTACAO_RENDIMENTO: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_LOCAL_TRIBUTACAO_RENDIMENTO,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{value}} - {{name}}',
    output: 'name'
  },
  data: [
    {value: ERHLocalTributacaoRendimento.RHLTRNone, name: 'dgempsfull.enumStr.rhLocalTributacaoRendimento.rhltrNone'},
    {value: ERHLocalTributacaoRendimento.RHLTRContinente, name: 'dgempsfull.enumStr.rhLocalTributacaoRendimento.rhltrContinente'},
    {value: ERHLocalTributacaoRendimento.RHLTRRegiaoAcores, name: 'dgempsfull.enumStr.rhLocalTributacaoRendimento.rhltrRegiaoAcores'},
    {value: ERHLocalTributacaoRendimento.RHLTRRegiaoMadeira, name: 'dgempsfull.enumStr.rhLocalTributacaoRendimento.rhltrRegiaoMadeira'}
  ]
});
