import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PlToolbarService, PlTranslateService} from 'pl-comps-angular';
import {
  generateGestaoComunicacoesDate,
  generateUtilizadorShow,
  IGestaoComunicacoesEmpresa,
  IGestaoComunicacoesTopico
} from '../../../modules/portalcontabilidade/gestaocomunicacoes/gestaoComunicacoes.module.interface';

@Component({
  selector: 'cg-message-empresa',
  templateUrl: './messageEmpresa.component.html'
})
export class CGMessageEmpresaComponent implements OnInit {
  @Input() public contabilistaView: boolean;
  @Input() public empresa: IGestaoComunicacoesEmpresa;
  @Input() public topico: IGestaoComunicacoesTopico;
  @Input() public lastMessage: boolean;
  @Input() public itemSelected: boolean;

  @Output() public readonly evtChangedEmpresa: EventEmitter<string>;
  @Output() public readonly evtChangedClienteTopico: EventEmitter<void>;

  public contabilistaSigla: string;

  constructor(
    private readonly _plToolbarService: PlToolbarService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    this.evtChangedEmpresa = new EventEmitter<string>();
    this.evtChangedClienteTopico = new EventEmitter<void>();
    this._plToolbarService.isRegistered('toolbar');
  }

  public ngOnInit(): void {
    if (!this.contabilistaView) {
      this.contabilistaSigla = this._getInitials(generateUtilizadorShow(this.topico, false)).toUpperCase();
      this.topico._dateShow = generateGestaoComunicacoesDate(this.topico.stampUltimaMensagem, true, this._plTranslateService);
    } else {
      this.empresa._dateShow = generateGestaoComunicacoesDate(this.empresa.ultimaMensagem, true, this._plTranslateService);
    }
  }

  public onClickEmpresa(nEmpresa: string): void {
    this.evtChangedEmpresa.emit(nEmpresa);
  }

  public onClickClienteTopico(): void {
    this.evtChangedClienteTopico.emit();
  }

  private _getInitials(name: string): string {
    const words: Array<string> = name.split(' ');
    if (words.length > 1) {
      return words[0].charAt(0) + words[words.length - 1].charAt(0);
    }
    return name.substring(0, 2);
  }
}
