<pl-form class="encomendas-processamento-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="closeModal()" [disabled]="closeDisabled"></button>
    <h4 class="modal-title" [translate]="'encomendas.modal.processamento.title'"></h4>
  </div>

  <div class="modal-body">
    <pl-nav-wizard
      #navWizard="navWizard"
      [definition]="navWizardDefinition"
      [callback]="navWizardCallback"
      [hideFooter]="true"
      (evtStepChange)="stepChanged($event)"
      [beforeStepChange]="beforeChangedStep"
      [onFinalize]="finalize">
      <pl-nav-wizard-step [stepId]="stepsNavGestaoEncomendas.Identificacao" caption="encomendas.modal.processamento.steps.ident" icon="fa-file-text-o">
        <div *plNavWizardStepContent>
          <pl-group>
            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.nDocFa'"></label>
              <edit>
                <entity-autocomplete
                  attrName="docfa"
                  entity="docfas"
                  [filter]="docfaFilter"
                  [model]="proceGestEnc.cab"
                  (modelChange)="nDocFaChanged($event)"
                  [output]="docfasOutput"
                  [fieldsMap]="{nDocFa: 'nDocfa', nome: 'nomeDocfa'}"
                  plAutoFocus>
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.nNumer'"></label>
              <edit>
                <entity-autocomplete attrName="numeracao" entity="docfasnums" [(selectedKey)]="proceGestEnc.cab.nNumer" [filter]="docfaNumFilter" [output]="'nNumer'" [outputKey]="'nNumer'">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="clifoDescription"></label>
              <edit>
                <entity-autocomplete
                  attrName="clifos"
                  [entity]="grupoClifos"
                  [model]="proceGestEnc.cab"
                  [selectedKey]="proceGestEnc.cab.nConta"
                  (selectedKeyChange)="proceGestEnc.cab.nConta = $event; nContaChanged($event)"
                  (evtSelectedDescriptionChanged)="proceGestEnc.cab.nomeConta = $event"
                  [fieldsMap]="{nome: 'nomeConta'}"
                  [properties]="{disabled: disableNConta}"
                  outputKey="nConta"
                  [output]="clifosOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.dataDoc'"></label>
              <edit>
                <pl-edit-datepicker attrName="dataDoc" [(model)]="proceGestEnc.cab.dataDoc"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </pl-group>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step [stepId]="stepsNavGestaoEncomendas.Carga" caption="encomendas.modal.processamento.steps.carga" icon="fas fa-cubes" *ngIf="proceGestEnc.cab.temIdCarga">
        <div *plNavWizardStepContent>
          <pl-group>
            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.dataInicTran'"></label>
              <edit>
                <pl-edit-datepicker attrName="iniTransp" [(model)]="proceGestEnc.carga.dataInicTran" plAutoFocus></pl-edit-datepicker>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.horaInicTran'"></label>
              <edit>
                <pl-edit-timepicker attrName="horaInicTran" [(model)]="proceGestEnc.carga.horaInicTran"></pl-edit-timepicker>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.morada'"></label>
              <edit>
                <pl-edit type="text" [(model)]="proceGestEnc.carga.rua"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.codPostal'"></label>
              <edit>
                <entity-autocomplete entity="codpostais" [model]="proceGestEnc.carga" (modelChange)="codPostaisChanged($event, 'carga')" [fieldsMap]="{codPostal: 'cPost'}" [outputKey]="'codPostal'">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.localidade'"></label>
              <edit>
                <pl-edit type="text" [(model)]="proceGestEnc.carga.localidade"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.codPais'"></label>
              <edit>
                <entity-autocomplete
                  entity="paises"
                  [model]="proceGestEnc.carga"
                  (selectedKeyChange)="proceGestEnc.carga.codPais = $event"
                  (evtSelectedDescriptionChanged)="proceGestEnc.carga.nomePais = $event"
                  [output]="paisesOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step [stepId]="stepsNavGestaoEncomendas.Descarga" caption="encomendas.modal.processamento.steps.descarga" icon="fas fa-truck" *ngIf="proceGestEnc.cab.temIdCarga">
        <div *plNavWizardStepContent>
          <pl-group class="cab-descarga-options-edit">
            <label [translate]="'docscomerciais.doc.moradaDescarga.moralId'"></label>
            <edit>
              <entity-autocomplete
                attrName="contaMoradaDescarga"
                entity="morals"
                [model]="proceGestEnc.descarga"
                (selectedKeyChange)="proceGestEnc.descarga.nConta = $event"
                (evtSelectedDescriptionChanged)="proceGestEnc.descarga.nome = $event"
                [filter]="nContaMoralFilter"
                [fieldsMap]="{nome: 'moralId'}"
                [outputKey]="'nConta'"
                output="nConta"
                outputDescription="nome"
                plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.morada'"></label>
              <edit>
                <pl-edit type="text" [(model)]="proceGestEnc.descarga.rua"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.codPostal'"></label>
              <edit>
                <entity-autocomplete
                  entity="codpostais"
                  [model]="proceGestEnc.descarga"
                  (modelChange)="codPostaisChanged($event, 'descarga')"
                  [fieldsMap]="{codPostal: 'cPost'}"
                  [outputKey]="'codPostal'">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.localidade'"></label>
              <edit>
                <pl-edit type="text" [(model)]="proceGestEnc.descarga.localidade"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'encomendas.modal.processamento.fields.codPais'"></label>
              <edit>
                <entity-autocomplete
                  entity="paises"
                  [model]="proceGestEnc.descarga"
                  (selectedKeyChange)="proceGestEnc.descarga.codPais = $event"
                  (evtSelectedDescriptionChanged)="proceGestEnc.descarga.nomePais = $event"
                  [output]="paisesOutput">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <label [translate]="'encomendas.modal.processamento.fields.observacoes'"></label>
            <edit>
              <pl-edit-textarea attrName="observacoes" [(model)]="proceGestEnc.descarga.observacoes" [properties]="{validators: {maxlength: {value: 50}}}"></pl-edit-textarea>
            </edit>
          </pl-group>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step [stepId]="stepsNavGestaoEncomendas.Lotes" caption="encomendas.modal.processamento.steps.lote" icon="fas fa-list-ul" *ngIf="showLotes">
        <div *plNavWizardStepContent>
          <div class="modal-body">
            <dx-data-grid
              [cgDxDataGrid]="dataGridLinhasDefinition"
              [dataSource]="dataGridLinhasDefinition.dataSource"
              cgDxDataGridInstanceName="gridProcessamentoLinhas"
              (onEditingStart)="onGridLinhasEditorStart($event)"
              (onCellPrepared)="onGridLinhasCellPrepared($event)"
              (onSelectionChanged)="onGridLinhasSelectionChanged($event)">
              <div *dxTemplate="let item of 'templateBtnToolbar'">
                <pl-button klass="btn-sm btn-warning action-preenche-auto-lotes" (evtClicked)="preencheAutomaticamenteLotes()">
                  <i class="fa fa-list-ul"></i>&nbsp;<span [translate]="'encomendas.modal.btns.preencherAuto'"></span>
                </pl-button>
              </div>
            </dx-data-grid>
            <hr />
            <dx-data-grid
              #gridLotes
              [cgDxDataGrid]="dataGridLotesDefinition"
              [dataSource]="dataGridLotesDefinition.dataSource"
              cgDxDataGridInstanceName="gridProcessamentoLotes"
              (onInitialized)="onGridLotesInitialized($event)"
              (onContentReady)="onGridLotesContentReady()"
              (onFocusedCellChanged)="onGridLotesFocusedCellChanged($event)">
            </dx-data-grid>
          </div>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step [stepId]="stepsNavGestaoEncomendas.Preview" caption="encomendas.modal.processamento.steps.preview" icon="fas fa-eye" *ngIf="showPreview">
        <div *plNavWizardStepContent>
          <documento-faturacao [doc]="docComercial" [actionPdf]="fnGetPdf"></documento-faturacao>
        </div>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-primary btn-sm me-auto" style="margin-right: auto" name="btnAddLote" *ngIf="showAddLoteBtn" (click)="novoLote()" [disabled]="!linhaEnc || closeDisabled">
      <i class="fa fa-plus-circle"></i>&nbsp;<span [translate]="'global.btn.new'"></span>
    </button>

    <pl-nav-wizard-actions [navWizardInstance]="navWizard"></pl-nav-wizard-actions>

    <pl-button klass="btn-sm btn-warning action-edit-doc" *ngIf="showEditDocBtn" (evtClicked)="editDoc()">
      <i class="fa fa-pencil"></i>&nbsp;<span [translate]="'encomendas.modal.btns.editDoc'"></span>
    </pl-button>

    <pl-button klass="btn-sm btn-success action-simular" *ngIf="showSimularBtn && !this.showPreview" (evtClicked)="processar(true)">
      <i class="fa fa-check-circle-o"></i>&nbsp;<span [translate]="'global.btn.simulate'"></span>
    </pl-button>

    <button type="button" class="btn btn-sm btn-light action-dismiss" (click)="closeModal()"><i class="fa fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</pl-form>
