import {Component, Injector, Input, OnInit} from '@angular/core';
import {copy, downloadStream, IPlToolbarItem, IPlToolbarMenuItem, isDefined, isEmpty, isObject, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {
  ECodigoAnexoI,
  ETipoAbrevPaisIntra,
  ETipoOperacaoAnexoI,
  IJsonDecIvaRecapitulativa,
  IJsonDecIvaRecapitulativaOpIntracomunitaria,
  IJsonDecIvaRecapitulativaQ06,
  IJsonPeriodoDecIva
} from '../../../../../interfaces/jsonDeclaracaoIva.interface';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {isEqual, isNull, merge} from 'lodash-es';
import {ContabilidadeDeclaracaoIvaService} from '../../../../../services/contabilidade.declaracaoiva.service';
import {HttpResponse} from '@angular/common/http';
import {DeclaracaoIvaPeriodicaPrevisualizaDeclaracaoModalComponent} from '../modals/previsualizadeclaracao/declaracaoIvaPeriodica.preVisualizaDeclaracao.modal.component';
import {IDataSourceItem} from '../../../../../components/datasource/datasources.interface';
import {IApiQueryResponse} from '../../../../../services/api/api.service.interface';
import {DATA_SOURCE_PERIODOS_IVA} from '../../../../../datasources/periodosiva/periodosIva.datasource';
import {ETypePeriodoIva, IMetodoSourceCodigoAnexoI, IMetodoSourcePaisDest, IMetodoSourceTipoOperacaoAnexoI} from '../contabilidade.declaracaoIva.module.interface';
import {IDevExpressDataGrid, IDevExpressDataGridDataChange} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowValidating,
  IDevExpressDataGridEventOnSaved
} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {ContabilidadeDeclaracaoivaConfigModalComponent} from '../modals/config/contabilidade.declaracaoIva.config.modal.component';

@Component({
  selector: 'module-contabilidade-declaracaoiva-recapitulativa',
  templateUrl: './contabilidade.declaracaoIva.recapitulativa.module.component.html'
})
export class ContabilidadeDeclaracaoIvaRecapitulativaModuleComponent extends ModuloComponent implements OnInit {
  @Input() public decIvaRecapitulativa: IJsonDecIvaRecapitulativa;

  public dataGridListQ04: IDevExpressDataGrid<IJsonDecIvaRecapitulativaOpIntracomunitaria, IJsonDecIvaRecapitulativaOpIntracomunitaria>;
  public dataGridListQ06: IDevExpressDataGrid<IJsonDecIvaRecapitulativaQ06, IJsonDecIvaRecapitulativaQ06>;
  public readonly mtdOutput: string;

  public metodoSourcePaisDest: Array<IMetodoSourcePaisDest>;
  public metodoSourceTipoOperacaoAnexoI: Array<IMetodoSourceTipoOperacaoAnexoI>;
  public metodoSourceCodigoAnexoI: Array<IMetodoSourceCodigoAnexoI>;

  /* eslint-disable @typescript-eslint/naming-convention */
  public DRIVA090510: number;
  public DRIVA090517: number;
  public DRIVA090518: number;
  public DRIVA090519: number;
  /* eslint-enable @typescript-eslint/naming-convention */
  public mensal: string;
  public trimensal: string;

  private readonly _mnuPeriodoIvaChoice: IPlToolbarItem;
  private readonly _mnuPeriodos: IPlToolbarItem<IJsonPeriodoDecIva>;
  private readonly _btnCriarFicheiro: IPlToolbarMenuItem;
  private readonly _btnGuardar: IPlToolbarMenuItem;
  private readonly _btnAnular: IPlToolbarMenuItem;
  private readonly _btnPreVisualizar: IPlToolbarMenuItem;
  private readonly _btnEntregarDrIva: IPlToolbarMenuItem;
  private _initialDecIvaRecapitulativa: IJsonDecIvaRecapitulativa;
  private _promise: Promise<void>;
  private _periodosIVA: Array<IJsonPeriodoDecIva>;
  private _periodo: string;
  private _periodoIva: ETypePeriodoIva;
  private _dataGridInstanceQ04: dxDataGrid<IJsonDecIvaRecapitulativaOpIntracomunitaria, IJsonDecIvaRecapitulativaOpIntracomunitaria>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _declaracaoIvaService: ContabilidadeDeclaracaoIvaService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._setMetodoSourcePaisDest();
    this._setMetodoSourceTipoOperacaoAnexoI();
    this._setMetodoSourceCodigoAnexoI();
    this._periodoIva = this.configurations.empresa.periodoIvaTrimestral ? ETypePeriodoIva.TRIMESTRAL : ETypePeriodoIva.MENSAL;

    this._mnuPeriodoIvaChoice = {
      id: 'periodoIvaChoice',
      order: 1,
      type: 'dropdown',
      class: 'btn-primary',
      menu: []
    };
    this._mnuPeriodos = {
      groupId: 'pdec',
      id: 'periodos',
      order: this._mnuPeriodoIvaChoice.order + 1,
      caption: 'Período',
      type: 'dropdown',
      class: 'btn-primary',
      menu: []
    };
    this._btnGuardar = {
      id: 'gravar',
      order: this._mnuPeriodos.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-save"></i>',
      class: 'btn-primary',
      caption: 'global.btn.save',
      promise: this.promise,
      tooltip: {
        text: 'declaracaoiva.tooltips.btnGuardarNotValid',
        placement: 'bottom',
        disabled: false
      },
      click: () => this._saveDecIvaPeriodica()
    };
    this._btnAnular = {
      id: 'anular',
      order: this._btnGuardar.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-trash"></i>',
      class: 'btn-danger',
      caption: 'global.btn.nullify',
      disabled: true,
      promise: this.promise,
      click: () => this._anularDeclaracao()
    };
    this._btnCriarFicheiro = {
      id: 'criarficheiro',
      order: this._btnAnular.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-download"></i>',
      class: 'btn-success',
      caption: 'global.btn.export',
      disabled: false,
      tooltip: {
        text: 'declaracaoiva.tooltips.btnGetXML',
        placement: 'bottom',
        disabled: false
      },
      promise: this.promise,
      click: () => this._criarFicheiro()
    };
    this._btnPreVisualizar = {
      id: 'preVisualizar',
      order: this._btnCriarFicheiro.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      class: 'btn-success',
      caption: 'global.btn.processPDF',
      disabled: true,
      promise: this.promise,
      click: () => {
        this._preVisualizarDeclaracao();
      }
    };

    this._btnEntregarDrIva = {
      id: 'entregarDeclaracao',
      order: 6,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-paper-plane-o" aria-hidden="true"></i>',
      class: 'btn-info',
      caption: 'declaracaoiva.btns.entregarDeclaracao',
      disabled: true,
      promise: this.promise,
      click: () => {
        this._entregarDeclaracao();
      }
    };

    this.dataGridListQ04 = {
      columnHidingEnabled: false,
      columns: [
        {
          dataField: 'paisDestino',
          dataType: 'string',
          caption: 'declaracaoiva.recapitulativa.quadros.quadro45.table.prefpaisdest',
          showEditorAlways: true,
          lookup: {
            dataSource: this.metodoSourcePaisDest,
            valueExpr: 'valueMetodo',
            displayExpr: (item: IMetodoSourcePaisDest) => item.nameMetodo,
            allowClearing: false
          }
        },
        {
          dataField: 'nContribuinte',
          dataType: 'string',
          caption: 'declaracaoiva.recapitulativa.quadros.quadro45.table.nif'
        },
        {
          dataField: 'valorOperacoes',
          dataType: 'double',
          caption: 'declaracaoiva.recapitulativa.quadros.quadro45.table.valor'
        },
        {
          dataField: 'tipoOperacao',
          dataType: 'string',
          caption: 'declaracaoiva.recapitulativa.quadros.quadro45.table.tipoop',
          showEditorAlways: true,
          lookup: {
            dataSource: this.metodoSourceTipoOperacaoAnexoI,
            valueExpr: 'valueMetodo',
            displayExpr: (item: IMetodoSourceTipoOperacaoAnexoI) => item.nameMetodo,
            allowClearing: false
          }
        }
      ],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        mode: 'cell',
        newRowPosition: 'last'
      },
      export: {filename: 'global.menu.declaracaoiva'},
      columnChooser: {enabled: false},
      remoteOperations: false
    };
    this.dataGridListQ06 = {
      columnHidingEnabled: false,
      columnAutoWidth: true,
      columns: [
        {
          dataField: 'paisDestino',
          dataType: 'string',
          caption: 'declaracaoiva.recapitulativa.quadros.quadro6.table.prefpaisdest',
          showEditorAlways: true,
          lookup: {
            dataSource: this.metodoSourcePaisDest,
            valueExpr: 'valueMetodo',
            displayExpr: (item: IMetodoSourcePaisDest) => item.nameMetodo,
            allowClearing: false
          }
        },
        {
          dataField: 'nContribuinte',
          dataType: 'string',
          caption: 'declaracaoiva.recapitulativa.quadros.quadro6.table.nif'
        },
        {
          dataField: 'nContribuinteOriginal',
          dataType: 'string',
          caption: 'declaracaoiva.recapitulativa.quadros.quadro6.table.nifOriginal'
        },
        {
          dataField: 'codigo',
          dataType: 'string',
          caption: 'declaracaoiva.recapitulativa.quadros.quadro6.table.codigo',
          showEditorAlways: true,
          lookup: {
            dataSource: this.metodoSourceCodigoAnexoI,
            valueExpr: 'valueMetodo',
            displayExpr: (item: IMetodoSourceCodigoAnexoI) => item.nameMetodo,
            allowClearing: false
          }
        }
      ],
      editing: {
        allowAdding: true,
        allowUpdating: true,
        allowDeleting: true,
        mode: 'cell',
        newRowPosition: 'last'
      },
      export: {filename: 'global.menu.declaracaoiva'},
      columnChooser: {enabled: false},
      remoteOperations: false
    };
    this._mnuPeriodos = {
      groupId: 'pdec',
      id: 'periodos',
      order: 1,
      caption: 'Período',
      type: 'dropdown',
      class: 'btn-primary',
      menu: []
    };
    this.mtdOutput = '{{nameMetodo}}';
    this.DRIVA090510 = 0;
    this.DRIVA090517 = 0;
    this.DRIVA090518 = 0;
    this.DRIVA090519 = 0;
    this.mensal = '';
    this.trimensal = '';
    this._generatePeriodosIvaChoice();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnGuardar);
    this.toolbar.addButton(this._btnCriarFicheiro);
    this.toolbar.addButton(this._btnAnular);
    this.toolbar.addButton(this._btnPreVisualizar);
    this.toolbar.addButton(this._btnEntregarDrIva);
    this.toolbar.addButton({
      id: 'config',
      order: this._btnEntregarDrIva.order + 1,
      type: 'button',
      class: 'btn-light',
      caption: 'global.btn.config',
      click: () => this._callConfig(),
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      visible: true
    });
    this.toolbar.addButton(this._mnuPeriodoIvaChoice);
    this._mnuPeriodoIvaChoice.caption = DATA_SOURCE_PERIODOS_IVA.data.find((item) => item.value === this._periodoIva).name;
    this._periodo = this.decIvaRecapitulativa.periodo;
    this._loadPeriodos(ETypePeriodoIva.DEFAULT).finally(() => {
      this.toolbar.addButton(this._mnuPeriodos);
      this._evaluateButtons();
    });
    this._applyDecIvaRecapitulativa(true);
  }

  public onInitializedQ04({component}: IDevExpressDataGridEventOnInitialized<IJsonDecIvaRecapitulativaOpIntracomunitaria, IJsonDecIvaRecapitulativaOpIntracomunitaria>): void {
    this._dataGridInstanceQ04 = component;
  }

  public onSavedQ04(event: IDevExpressDataGridEventOnSaved<IJsonDecIvaRecapitulativaOpIntracomunitaria, IJsonDecIvaRecapitulativaOpIntracomunitaria>): void {
    event.changes.forEach((item: IDevExpressDataGridDataChange<IJsonDecIvaRecapitulativaOpIntracomunitaria, IJsonDecIvaRecapitulativaOpIntracomunitaria>) => {
      if (item.type !== 'remove') {
        const paisData = this.metodoSourcePaisDest.find((pais: IMetodoSourcePaisDest) => item.data.paisDestino === pais.valueMetodo);
        const tipoOpData = this.metodoSourceTipoOperacaoAnexoI.find((tipoOp: IMetodoSourceTipoOperacaoAnexoI) => item.data.tipoOperacao === tipoOp.valueMetodo);
        this._lookupValueChangedQ04(item, paisData, tipoOpData);
      }
    });
    this._evaluateButtons();
  }

  public onRowValidatingQ04(event: IDevExpressDataGridEventOnRowValidating<IJsonDecIvaRecapitulativaOpIntracomunitaria, IJsonDecIvaRecapitulativaOpIntracomunitaria>): void {
    let isError = false;
    if (isUndefinedOrNull(event.newData?.paisDestino)) {
      if (isUndefinedOrNull(event.oldData?.paisDestino)) {
        isError = true;
      }
    }

    if (!event.newData?.nContribuinte) {
      if (!event.oldData?.nContribuinte || (isDefined(event.newData?.nContribuinte) && isEmpty(event.newData?.nContribuinte) && event.oldData?.nContribuinte)) {
        isError = true;
      }
    }

    if (!event.newData?.valorOperacoes) {
      if (!event.oldData?.valorOperacoes || (isNull(event.newData?.valorOperacoes) && event.oldData?.valorOperacoes)) {
        isError = true;
      }
    }

    if (isUndefinedOrNull(event.newData?.tipoOperacao)) {
      if (isUndefinedOrNull(event.oldData?.tipoOperacao)) {
        isError = true;
      }
    }

    if (isError) {
      event.isValid = false;
      event.errorText = this._translateService.instant('declaracaoiva.messages.errorTextDataGridRowNotOk');
    }
    this._evaluateButtons();
  }

  public onSavedQ06(event: IDevExpressDataGridEventOnSaved<IJsonDecIvaRecapitulativaQ06, IJsonDecIvaRecapitulativaQ06>): void {
    event.changes.forEach((item: IDevExpressDataGridDataChange<IJsonDecIvaRecapitulativaQ06, IJsonDecIvaRecapitulativaQ06>) => {
      if (item.type !== 'remove') {
        const paisData = this.metodoSourcePaisDest.find((pais: IMetodoSourcePaisDest) => item.data.paisDestino === pais.valueMetodo);
        const codigoData = this.metodoSourceCodigoAnexoI.find((codigo: IMetodoSourceCodigoAnexoI) => item.data.codigo === codigo.valueMetodo);
        this._lookupValueChangedQ06(item, paisData, codigoData);
      }
    });
    this._evaluateButtons();
  }

  public onRowValidatingQ06(event: IDevExpressDataGridEventOnRowValidating<IJsonDecIvaRecapitulativaQ06, IJsonDecIvaRecapitulativaQ06>): void {
    let isError = false;
    if (isUndefinedOrNull(event.newData?.paisDestino)) {
      if (isUndefinedOrNull(event.oldData?.paisDestino)) {
        isError = true;
      }
    }

    if (!event.newData?.nContribuinte) {
      if (!event.oldData?.nContribuinte || (isDefined(event.newData?.nContribuinte) && isEmpty(event.newData?.nContribuinte) && event.oldData?.nContribuinte)) {
        isError = true;
      }
    }

    if (!event.newData?.nContribuinteOriginal) {
      if (!event.oldData?.nContribuinteOriginal || (isDefined(event.newData?.nContribuinteOriginal) && isEmpty(event.newData?.nContribuinteOriginal) && event.oldData?.nContribuinteOriginal)) {
        isError = true;
      }
    }

    if (isUndefinedOrNull(event.newData?.codigo)) {
      if (isUndefinedOrNull(event.oldData?.codigo)) {
        isError = true;
      }
    }

    if (isError) {
      event.isValid = false;
      event.errorText = this._translateService.instant('declaracaoiva.messages.errorTextDataGridRowNotOk');
    }
    this._evaluateButtons();
  }

  public changedQ2Cx(newSelected: string, group: string): void {
    let modelCheckboxGroup: Array<string> = [];
    let removeNestedRadio: Array<string> = [];
    let removeNested: Array<string> = [];
    switch (group) {
      case '1':
        removeNested = [
          'emSubstituicaoDecHouveAltTrimestralParaMensal',
          'emSubstituicaoInexistenciaDeOperacoes',
          'emSubstituicaoOutrosMotivos',
          'q2c24',
          'q2c241',
          'q2c242',
          'q2c243',
          'q2c2411',
          'q2c2412'
        ];
        removeNestedRadio = ['emSubstituicaoValorMensalSubstituiTotalmenteValorTrimestral'];
        break;
      case '2':
        removeNestedRadio = ['emPrimeiraDecHouveAltTrimestralParaMensal'];
        break;
      case '2x':
        modelCheckboxGroup = ['emSubstituicaoDecHouveAltTrimestralParaMensal', 'emSubstituicaoInexistenciaDeOperacoes', 'emSubstituicaoOutrosMotivos'];
        removeNestedRadio = ['emSubstituicaoValorMensalSubstituiTotalmenteValorTrimestral'];
        break;
      case '24':
        modelCheckboxGroup = ['q2c24'];
        removeNested = ['q2c241', 'q2c242', 'q2c243', 'q2c2411', 'q2c2412'];
        break;
      case '24x':
        modelCheckboxGroup = ['q2c241', 'q2c242', 'q2c243'];
        removeNested = ['q2c2411', 'q2c2412'];
        break;
      case '241x':
        modelCheckboxGroup = ['q2c2411', 'q2c2412'];
        break;
      default:
        break;
    }
    modelCheckboxGroup.forEach((item: string) => {
      if (newSelected === item) {
        this.decIvaRecapitulativa[item] = !this.decIvaRecapitulativa[item];
      } else {
        this.decIvaRecapitulativa[item] = false;
      }
    });
    removeNested.forEach((item: string) => {
      this.decIvaRecapitulativa[item] = false;
    });
    removeNestedRadio.forEach((item: string) => {
      this.decIvaRecapitulativa[item] = undefined;
    });
    this._evaluateButtons();
  }

  public get promise(): Promise<void> {
    return this._promise;
  }

  public set promise(value: Promise<void>) {
    this._promise = value;
    this._promise.finally(() => {
      this._promise = undefined;
    });
  }

  private _applyDecIvaRecapitulativa(isInit: boolean = false): void {
    this.decIvaRecapitulativa = merge(
      {
        nifEmpresa: '',
        nifTOC: '',
        anoAQueRespeita: '',
        mesTrimestreAQueRespeita: '',
        mesIncluidoTrimestreMes1: '',
        mesIncluidoTrimestreMes2: '',
        tipoDeclaracaoPrimeiraDeclaracao: undefined,
        emPrimeiraDecHouveAltTrimestralParaMensal: 0,
        emSubstituicaoDecHouveAltTrimestralParaMensal: undefined,
        emSubstituicaoValorMensalSubstituiTotalmenteValorTrimestral: undefined,
        emSubstituicaoInexistenciaDeOperacoes: undefined,
        emSubstituicaoOutrosMotivos: undefined,
        isMensal: false,
        totalDasVendasDeMeiosDeTransporteNovosAParticularesDaUE: 0,
        decIvaRecapErro: '',
        existePaginaDeclaracaoIVA: false,
        dataDe: undefined,
        dataAte: undefined,
        listaOpIntracomunitarias: [],
        q2c24: false,
        q2c241: false,
        q2c2411: false,
        q2c2412: false,
        q2c242: false,
        q2c243: false,
        q06Lista: []
      },
      this.decIvaRecapitulativa
    );
    if (isInit) {
      this._initialDecIvaRecapitulativa = copy(this.decIvaRecapitulativa);
    }

    if (!this.decIvaRecapitulativa.emSubstituicaoDecHouveAltTrimestralParaMensal) {
      this.decIvaRecapitulativa.emSubstituicaoValorMensalSubstituiTotalmenteValorTrimestral = undefined;
    }

    this._evaluateMensalTrimensal();
    this._doCalcTotalsQuadro0405();
    this._evaluateButtons();
  }

  private _callConfig(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(ContabilidadeDeclaracaoivaConfigModalComponent);
    const componentInstance: ContabilidadeDeclaracaoivaConfigModalComponent = modalInstance.componentInstance;
    componentInstance.forDrIva = true;
    return modalInstance.result;
  }

  private _anularDeclaracao(): Promise<void> {
    this.promise = this._declaracaoIvaService.anularDeclaracaoRecap(this._periodo).then(() => {
      return this._declaracaoIvaService.loadDeclaracaoIVARecap(this._periodo, this._periodoIva).then((response: HttpResponse<IJsonDecIvaRecapitulativa>) => {
        this.decIvaRecapitulativa = response.body;
        this._loadPeriodos(this._periodoIva).finally(() => {
          for (let i = this._mnuPeriodos.menu.length - 1; i >= 0; i--) {
            if (this._mnuPeriodos.menu[i].data.periodo === this._periodo) {
              this._mnuPeriodos.caption = this._mnuPeriodos.menu[i].caption;
              this._periodo = this._mnuPeriodos.menu[i].data.periodo;
              break;
            }
          }
        });
        this._applyDecIvaRecapitulativa(true);
        this._plAlertService.success('declaracaoiva.messages.anuladasuccess');
      });
    });
    return this.promise;
  }

  private _criarFicheiro(): Promise<void> {
    return this._declaracaoIvaService.getRecapitulativaXml(this._periodo, this._periodoIva).then((response: HttpResponse<Blob>) => {
      downloadStream(response);
    });
  }

  private _decIvaRecapitulativaHasChanges(decIvaRecapitulativa: IJsonDecIvaRecapitulativa): boolean {
    return !isEqual(decIvaRecapitulativa, this._initialDecIvaRecapitulativa);
  }

  private _saveDecIvaPeriodica(): Promise<void> {
    this.decIvaRecapitulativa.mesTrimestreAQueRespeita = isEmpty(this.mensal) ? this.trimensal : this.mensal;
    this.decIvaRecapitulativa.isMensal = isEmpty(this.trimensal);
    const hasChanges: boolean = this._decIvaRecapitulativaHasChanges(this.decIvaRecapitulativa);
    if (!hasChanges && this.decIvaRecapitulativa.existePaginaDeclaracaoIVA) {
      this._plAlertService.success('declaracaoiva.messages.savedsucessfully');
      return Promise.resolve();
    }
    this.promise = this._declaracaoIvaService.postSaveDeclaracaoIvaRecap(this.decIvaRecapitulativa, this._periodo, this._periodoIva).then((response: HttpResponse<IJsonDecIvaRecapitulativa>) => {
      this._plAlertService.success('declaracaoiva.messages.savedsucessfully');
      this.decIvaRecapitulativa = response.body;
      this._loadPeriodos(this._periodoIva).finally(() => {
        for (let i = this._mnuPeriodos.menu.length - 1; i >= 0; i--) {
          if (this._mnuPeriodos.menu[i].data.periodo === this._periodo) {
            this._mnuPeriodos.caption = this._mnuPeriodos.menu[i].caption;
            this._periodo = this._mnuPeriodos.menu[i].data.periodo;
            break;
          }
        }
      });
      this._applyDecIvaRecapitulativa(true);
    });
    return this.promise;
  }

  private _loadPeriodos(periodoIva: ETypePeriodoIva = ETypePeriodoIva.DEFAULT): Promise<void> {
    this._periodosIVA = [];
    this._mnuPeriodos.menu = [];
    return this._declaracaoIvaService
      .getPeriodosIVA(true, periodoIva)
      .then((response: HttpResponse<IApiQueryResponse<IJsonPeriodoDecIva>>) => {
        this._periodosIVA = response.body.list;
      })
      .finally(() => {
        for (const periodoIVA of this._periodosIVA) {
          if (periodoIVA) {
            let strGravado = '';
            if (periodoIVA.comDecGravada) {
              strGravado = ` (${<string>this._translateService.instant('declaracaoiva.text.saved')})`;
            }
            this._mnuPeriodos.menu.push({
              id: periodoIVA.periodo,
              caption: `${periodoIVA.periodo} - ${periodoIVA.nome}${strGravado}`,
              data: periodoIVA,
              active: periodoIVA.periodo === this._periodo,
              click: (menu: IPlToolbarMenuItem<IJsonPeriodoDecIva>) => this._mudaPeriodo(menu)
            });

            if (periodoIVA.periodo === this._periodo) {
              this._mnuPeriodos.caption = `${periodoIVA.periodo} - ${periodoIVA.anoCivil} - ${periodoIVA.nome} ${strGravado}`;
            }
          }
        }
      });
  }

  private async _mudaPeriodo(menu: IPlToolbarMenuItem<IJsonPeriodoDecIva>): Promise<void> {
    if (!menu || menu.data.periodo === this._periodo) {
      this._mnuPeriodos.caption = menu.caption;
      return;
    }
    const index = this._mnuPeriodos.menu.findIndex((item: IPlToolbarMenuItem<IJsonPeriodoDecIva>) => item.id === this._periodo);
    if (index !== -1) {
      this._mnuPeriodos.menu[index].active = false;
    }
    this._mnuPeriodos.caption = menu.caption;
    this._periodo = menu.data.periodo;
    menu.active = true;
    await this._periodoChanged();
    this._evaluateButtons();
  }

  private _periodoChanged(): Promise<void> {
    this.promise = this._declaracaoIvaService.loadDeclaracaoIVARecap(this._periodo, this._periodoIva).then((response: HttpResponse<IJsonDecIvaRecapitulativa>) => {
      this.decIvaRecapitulativa = response.body;
      this._periodo = this.decIvaRecapitulativa.periodo;
      this._applyDecIvaRecapitulativa(true);
    });
    return this.promise;
  }

  private _evaluateButtons(): void {
    this._btnAnular.disabled = !this.decIvaRecapitulativa.existePaginaDeclaracaoIVA;
    this._btnCriarFicheiro.disabled = !this.decIvaRecapitulativa.existePaginaDeclaracaoIVA;
    this._btnCriarFicheiro.tooltip.disabled = this.decIvaRecapitulativa.existePaginaDeclaracaoIVA;
    this._btnPreVisualizar.disabled = !this.decIvaRecapitulativa.existePaginaDeclaracaoIVA;
    this._btnEntregarDrIva.disabled = !this.decIvaRecapitulativa.existePaginaDeclaracaoIVA;

    this._btnGuardar.disabled = !this._dataGridQ04HasData() || this._dataGridQ04HasEditData();
    this._btnGuardar.tooltip.disabled = !this._btnGuardar.disabled;
  }

  private _dataGridQ04HasData(): boolean {
    return this.decIvaRecapitulativa.listaOpIntracomunitarias.length > 0;
  }

  private _dataGridQ04HasEditData(): boolean {
    if (this._dataGridInstanceQ04) {
      return this._dataGridInstanceQ04.hasEditData();
    }
    return false;
  }

  private _preVisualizarDeclaracao(): void {
    const tiposAnexo: Array<IDataSourceItem<number>> = [{value: 3, name: this._translateService.instant('tiposanexo.decRecapitulativa')}];
    const modalInstance = this._cgModalService.showVanilla(DeclaracaoIvaPeriodicaPrevisualizaDeclaracaoModalComponent);
    const componentInstance: DeclaracaoIvaPeriodicaPrevisualizaDeclaracaoModalComponent = modalInstance.componentInstance;
    componentInstance.periodo = this._periodo;
    componentInstance.tiposAnexo = tiposAnexo;
    componentInstance.periodoIva = this._periodoIva;
  }

  private _entregarDeclaracao(): Promise<void> {
    this.promise = this._declaracaoIvaService.postEntregarDeclaracaoIvaRecap(this._periodo, this._periodoIva).then(() => {
      this._plAlertService.success('declaracaoiva.messages.entreguesuccess');
    });
    return this.promise;
  }

  private _doCalcTotalsQuadro0405(): void {
    this.DRIVA090519 = 0;
    this.DRIVA090510 = 0;
    this.DRIVA090517 = 0;
    this.DRIVA090518 = 0;

    for (const opIntracomunitaria of this.decIvaRecapitulativa.listaOpIntracomunitarias) {
      if (opIntracomunitaria.tipoOperacao === ETipoOperacaoAnexoI.TransmissoesIntraUETipo1) {
        this.DRIVA090510 += opIntracomunitaria.valorOperacoes;
      }
      if (opIntracomunitaria.tipoOperacao === ETipoOperacaoAnexoI.TransmissoesOpTriangularesTipo4) {
        this.DRIVA090517 += opIntracomunitaria.valorOperacoes;
      }
      if (opIntracomunitaria.tipoOperacao === ETipoOperacaoAnexoI.PrestacoesServicosArt6Tipo5) {
        this.DRIVA090518 += opIntracomunitaria.valorOperacoes;
      }
    }

    this.DRIVA090519 = this.DRIVA090510 + this.DRIVA090517 + this.DRIVA090518;
  }

  private _evaluateMensalTrimensal(): void {
    this.mensal = this.trimensal = '';
    if (this.decIvaRecapitulativa.mesTrimestreAQueRespeita.endsWith('T')) {
      this.trimensal = this.decIvaRecapitulativa.mesTrimestreAQueRespeita;
    } else {
      this.mensal = this.decIvaRecapitulativa.mesTrimestreAQueRespeita;
    }
  }

  private _generatePeriodosIvaChoice(): void {
    DATA_SOURCE_PERIODOS_IVA.data.forEach((item) => {
      this._mnuPeriodoIvaChoice.menu.push({caption: item.name, data: item, click: (menu: IPlToolbarMenuItem<IDataSourceItem<number>>) => this._mudaPeriodoIva(menu)});
    });
  }

  private async _mudaPeriodoIva(menu: IPlToolbarMenuItem<IDataSourceItem<number>>): Promise<void> {
    if (!menu || menu.data.value === this._periodoIva) {
      return;
    }
    this._mnuPeriodoIvaChoice.caption = menu.caption;
    this._periodoIva = menu.data.value;
    this._periodo = '';
    this.promise = this._periodoChanged();
    await Promise.resolve(this.promise);
    this.promise = this._loadPeriodos(this._periodoIva);
    await Promise.resolve(this.promise);
    this._evaluateButtons();
  }

  private _setMetodoSourcePaisDest(): void {
    this.metodoSourcePaisDest = [
      {valueMetodo: ETipoAbrevPaisIntra.Austria, nameMetodo: 'AT-Austria', nomePaisDest: 'Austria', abrevitura: 'AT'},
      {valueMetodo: ETipoAbrevPaisIntra.Belgica, nameMetodo: 'BE-Belgica', nomePaisDest: 'Belgica', abrevitura: 'BE'},
      {valueMetodo: ETipoAbrevPaisIntra.Bulgaria, nameMetodo: 'BG-Bulgaria', nomePaisDest: 'Bulgaria', abrevitura: 'BG'},
      {valueMetodo: ETipoAbrevPaisIntra.Chipre, nameMetodo: 'CY-Chipre', nomePaisDest: 'Chipre', abrevitura: 'CY'},
      {valueMetodo: ETipoAbrevPaisIntra.RepublicaCheca, nameMetodo: 'CZ-RepublicaCheca', nomePaisDest: 'Republica Checa', abrevitura: 'CZ'},
      {valueMetodo: ETipoAbrevPaisIntra.Alemanha, nameMetodo: 'DE-Alemanha', nomePaisDest: 'Alemanha', abrevitura: 'DE'},
      {valueMetodo: ETipoAbrevPaisIntra.Dinamarca, nameMetodo: 'DK-Dinamarca', nomePaisDest: 'Dinamarca', abrevitura: 'DK'},
      {valueMetodo: ETipoAbrevPaisIntra.Estonia, nameMetodo: 'EE-Estonia', nomePaisDest: 'Estonia', abrevitura: 'EE'},
      {valueMetodo: ETipoAbrevPaisIntra.Grecia, nameMetodo: 'EL-Grecia', nomePaisDest: 'Grecia', abrevitura: 'EL'},
      {valueMetodo: ETipoAbrevPaisIntra.Espanha, nameMetodo: 'ES-Espanha', nomePaisDest: 'Espanha', abrevitura: 'ES'},
      {valueMetodo: ETipoAbrevPaisIntra.Finlandia, nameMetodo: 'FI-Finlandia', nomePaisDest: 'Finlandia', abrevitura: 'FI'},
      {valueMetodo: ETipoAbrevPaisIntra.Franca, nameMetodo: 'FR-Franca', nomePaisDest: 'Franca', abrevitura: 'FR'},
      {valueMetodo: ETipoAbrevPaisIntra.IrlandaDoNorte, nameMetodo: 'XI-IrlandaDoNorte', nomePaisDest: 'Irlanda Do Norte', abrevitura: 'XI'},
      {valueMetodo: ETipoAbrevPaisIntra.Hungria, nameMetodo: 'HU-Hungria', nomePaisDest: 'Hungria', abrevitura: 'HU'},
      {valueMetodo: ETipoAbrevPaisIntra.Irlanda, nameMetodo: 'IE-Irlanda', nomePaisDest: 'Irlanda', abrevitura: 'IE'},
      {valueMetodo: ETipoAbrevPaisIntra.Italia, nameMetodo: 'IT-Italia', nomePaisDest: 'Italia', abrevitura: 'IT'},
      {valueMetodo: ETipoAbrevPaisIntra.Lituania, nameMetodo: 'LT-Lituania', nomePaisDest: 'Lituania', abrevitura: 'LT'},
      {valueMetodo: ETipoAbrevPaisIntra.Luxemburgo, nameMetodo: 'LU-Luxemburgo', nomePaisDest: 'Luxemburgo', abrevitura: 'LU'},
      {valueMetodo: ETipoAbrevPaisIntra.Letonia, nameMetodo: 'LV-Letonia', nomePaisDest: 'Letonia', abrevitura: 'LV'},
      {valueMetodo: ETipoAbrevPaisIntra.Malta, nameMetodo: 'MT-Malta', nomePaisDest: 'Malta', abrevitura: 'MT'},
      {valueMetodo: ETipoAbrevPaisIntra.Holanda, nameMetodo: 'NL-Holanda', nomePaisDest: 'Holanda', abrevitura: 'NL'},
      {valueMetodo: ETipoAbrevPaisIntra.Polonia, nameMetodo: 'PL-Polonia', nomePaisDest: 'Polonia', abrevitura: 'PL'},
      {valueMetodo: ETipoAbrevPaisIntra.Portugal, nameMetodo: 'PT-Portugal', nomePaisDest: 'Portugal', abrevitura: 'PT'},
      {valueMetodo: ETipoAbrevPaisIntra.Romenia, nameMetodo: 'RO-Romenia', nomePaisDest: 'Romenia', abrevitura: 'RO'},
      {valueMetodo: ETipoAbrevPaisIntra.Suecia, nameMetodo: 'SE-Suecia', nomePaisDest: 'Suecia', abrevitura: 'SE'},
      {valueMetodo: ETipoAbrevPaisIntra.Eslovenia, nameMetodo: 'SI-Eslovenia', nomePaisDest: 'Eslovenia', abrevitura: 'SI'},
      {valueMetodo: ETipoAbrevPaisIntra.Eslovaquia, nameMetodo: 'SK-Eslovaquia', nomePaisDest: 'Eslovaquia', abrevitura: 'SK'},
      {valueMetodo: ETipoAbrevPaisIntra.Croacia, nameMetodo: 'HR-Croacia', nomePaisDest: 'Croacia', abrevitura: 'HR'}
    ];
  }

  private _setMetodoSourceTipoOperacaoAnexoI(): void {
    this.metodoSourceTipoOperacaoAnexoI = [
      {valueMetodo: ETipoOperacaoAnexoI.TransmissoesIntraUETipo1, nameMetodo: '1 - Não compreendidas no tipo 4'},
      {valueMetodo: ETipoOperacaoAnexoI.TransmissoesOpTriangularesTipo4, nameMetodo: '4 - Operações triangulares'},
      {valueMetodo: ETipoOperacaoAnexoI.PrestacoesServicosArt6Tipo5, nameMetodo: '5 - Prestações de Serviços'}
    ];
  }

  private _setMetodoSourceCodigoAnexoI(): void {
    this.metodoSourceCodigoAnexoI = [
      {valueMetodo: ECodigoAnexoI.EnvioBensRegimeVendasConsignacao, nameMetodo: '1 - Envio dos bens sob o regime de vendas à consignação'},
      {valueMetodo: ECodigoAnexoI.RetornoBensCorrecoesInformacoesAnteriormenteDeclaradas, nameMetodo: '2 - Retorno dos bens e correções a informações anteriormente declaradas'},
      {valueMetodo: ECodigoAnexoI.AlteracaoDestinatarioDosBens, nameMetodo: '3 - Alteração do destinatário dos bens'}
    ];
  }

  private _lookupValueChangedQ04(
    item: IDevExpressDataGridDataChange<IJsonDecIvaRecapitulativaOpIntracomunitaria, IJsonDecIvaRecapitulativaOpIntracomunitaria>,
    pais?: IMetodoSourcePaisDest,
    tipoOp?: IMetodoSourceTipoOperacaoAnexoI
  ): void {
    if (isObject(pais) && isObject(item)) {
      item.data.paisDestinoStr = pais.nameMetodo;
      item.data.paisDestinoNome = pais.nomePaisDest;
      item.data.paisDestinoAbreviatura = pais.abrevitura;
    }

    if (isObject(tipoOp) && isObject(item)) {
      item.data.tipoOperacaoStr = tipoOp.nameMetodo;
    }
  }

  private _lookupValueChangedQ06(
    item: IDevExpressDataGridDataChange<IJsonDecIvaRecapitulativaQ06, IJsonDecIvaRecapitulativaQ06>,
    pais?: IMetodoSourcePaisDest,
    codigo?: IMetodoSourceCodigoAnexoI
  ): void {
    if (isObject(pais) && isObject(item)) {
      item.data.paisDestinoStr = pais.nameMetodo;
      item.data.paisDestinoNome = pais.nomePaisDest;
      item.data.paisDestinoAbreviatura = pais.abrevitura;
    }

    if (isObject(codigo) && isObject(item)) {
      item.data.codigoStr = codigo.nameMetodo;
    }
  }
}
