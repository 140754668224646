import {firstValueFrom, Observable} from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {copy, fromJson, PlAlertService, PlTranslateService, toJson} from 'pl-comps-angular';
import {ApiService} from '../../../services/api/api.service';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {CGLocalStorageGroupService} from '../../../services/storage/localstoragegroup.service';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {ConfigOptionsInstanceService} from '../../../services/config/options/config.options.instance.service';
import {ConfigService} from '../../../services/config/config.service';
import {DocsComerciaisModalService} from '../modals/docsComerciais.modal.service';
import {DocumentoMeiosPagamentoModalComponent} from '../modals/meiospagamento/documento.meiosPagamento.modal';
import {EConfigOptionsInstanceName, IConfigOptionsGroupErp, IDocsComerciaisEditConfigOptions} from '../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../config/constants';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {GDocComercialModalComponent} from '../modals/gdoccomercial/gdoc.comercial.modal.component';
import {IDocComercial, IDocsComerciaisEntityService} from '../docsComerciais.entity.interface';
import {EDocsComerciaisImportadorLinhasExtraField, IDocumentosService} from './documentos.entity.service.interface';
import {IJsonDocComercial, IJsonDocComercialTextosLivres, IJsonDocContabImputacao, IJsonDocUltimoDocGlobalCab} from '../jsonDocComercial.entity.interface';
import {ImpdocModalComponent} from '../modals/impdoc/impDoc.modal.component';
import {SCHEMA_STRING} from '../../../../common/schemas';
import {TServiceResponse} from '../../../services/api/api.service.interface';

const STORAGE_DOCSCOMMERCIAIS = 'docscomerciais';
const STORAGE_NAME_DOC = `${STORAGE_DOCSCOMMERCIAIS}.documento`;

@Injectable({
  providedIn: 'root'
})
export class DocumentosService extends ConfigOptionsInstanceService<boolean, IDocsComerciaisEditConfigOptions, IConfigOptionsGroupErp> implements IDocumentosService {
  public readonly apiService: ApiService;

  private readonly _entityName: string;
  private readonly _entityService: IDocsComerciaisEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _docscomerciaisModalService: DocsComerciaisModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _apiService: ApiService,
    private readonly _cgLocalStorageGroupService: CGLocalStorageGroupService,
    private readonly _configService: ConfigService
  ) {
    super(_injector, EGroupName.ERP, EConfigOptionsInstanceName.DOCS_COMERCIAIS_EDIT);
    this._entityName = 'docscomerciais';
    this._entityService = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>(this._entityName);
    this.apiService = this._apiService;
  }

  public getPdf(doc: IJsonDocComercial, preview: boolean = false): Promise<void> {
    if (!doc) {
      this._plAlertService.error('docscomerciais.erros.notselected');
      return Promise.reject(new Error(this._translateService.instant('docscomerciais.erros.notselected')));
    }
    const modalInstance = this._cgModalService.showVanilla(ImpdocModalComponent);
    const componentInstance: ImpdocModalComponent = modalInstance.componentInstance;
    componentInstance.doc = doc;
    componentInstance.documentosService = this;
    componentInstance.preview = preview;
    return modalInstance.result;
  }

  public getPdfUrl(
    faccbId: number,
    nvias: number,
    rptname: string,
    segundavia: boolean,
    preview: boolean,
    forceoriginal: boolean,
    assinar: boolean = false,
    geranovo: boolean = false
  ): Observable<string> {
    return buildSessionUrlWithParams(`${this._apiService.path.restapi}/${this._entityName}/${faccbId}/pdf`, {
      nvias: nvias,
      rptname: rptname,
      segundavia: segundavia,
      preview: preview,
      forceoriginal: forceoriginal,
      assinar: assinar,
      geranovo: geranovo
    });
  }

  public enviaPdfPorEmail(faccbId: number, nvias: number, rptname: string, segundavia: boolean, email: string, forceoriginal: boolean): TServiceResponse<void> {
    return this._entityService.sendEmail(faccbId, nvias, rptname, segundavia, email, forceoriginal);
  }

  public anexarGDocComercial(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    if (!doc?.cab) {
      const error: string = this._plTranslateService.translate('docscomerciais.erros.notselected');
      this._plAlertService.error(error);
      return Promise.reject(new Error(error));
    }
    return this._docscomerciaisModalService.anexarGDocComercial(doc);
  }

  public comunicarDocWebServiceAT(faccbId: number, assincrono: boolean): Promise<IJsonDocComercial> {
    return this._entityService.put({url: `${faccbId}/faturaswebserviceat`, params: {assincrono: assincrono}}).then((response) => response.body);
  }

  public comunicarDocCirculacaoAT(faccbId: number): Promise<IJsonDocComercial> {
    return this._entityService.put({url: `${faccbId}/at`}).then((response) => response.body);
  }

  public inserirCodigoTransporteAT(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._docscomerciaisModalService.comunicarAT(doc);
  }

  public anularDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    if (doc.cab.nDocumento > 0) {
      return this._docscomerciaisModalService.anularDocumento(doc);
    }
    const motivo = this._translateService.instant('docscomerciais.erros.anulacaoFisica');
    return this._entityService.put({url: `${doc.cab.faccbId}/anulardoc`, params: {anulacaofisica: 1, motivoanulacao: motivo}}).then((response) => response.body);
  }

  public notaCreditoDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    if (!doc?.cab?.nDocumento) {
      const error: string = this._plTranslateService.translate('docscomerciais.erros.notselected');
      this._plAlertService.error(error);
      return Promise.reject(new Error(error));
    }
    return this._docscomerciaisModalService.notaCreditoDocumento(doc);
  }

  public transformarLinhasDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    if (!doc?.cab) {
      const error: string = this._plTranslateService.translate('docscomerciais.erros.notselected');
      this._plAlertService.error(error);
      return Promise.reject(new Error(error));
    }
    if (!doc.cab.anulado && doc.cab.nDocumento > 0) {
      return this._docscomerciaisModalService.transformarLinhasDocumento(doc);
    }
    return Promise.resolve(doc);
  }

  public desmarcarDocTransformado(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    if (!doc?.cab) {
      const error: string = this._plTranslateService.translate('docscomerciais.erros.notselected');
      this._plAlertService.error(error);
      return Promise.reject(new Error(error));
    }
    if (doc.cab.transformado && !doc.cab.anulado) {
      return this._entityService.put({url: `${doc.cab.faccbId}/desmarcardoctransformado`}).then((response) => response.body);
    }
    return Promise.resolve(doc);
  }

  public duplicarDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    if (!doc?.cab) {
      const error: string = this._plTranslateService.translate('docscomerciais.erros.notselected');
      this._plAlertService.error(error);
      return Promise.reject(new Error(error));
    }
    if (!doc.cab.anulado && doc.cab.nDocumento > 0) {
      return this._docscomerciaisModalService.duplicarDocumento(doc);
    }
    return Promise.resolve(doc);
  }

  public getDoc(id: number): TServiceResponse<IJsonDocComercial> {
    return this._entityService.get({id: id});
  }

  public postDoc(
    doc: IJsonDocComercial,
    memoria: boolean = true,
    terminaDoc: boolean = false,
    novodocsemlinhas: boolean = false,
    anexarGDocId: string = '',
    anexarGDocFolderId: string = ''
  ): TServiceResponse<IJsonDocComercial> {
    return this._entityService.post<IJsonDocComercial>({
      params: {
        memoria: memoria ? 1 : 0,
        terminadoc: terminaDoc ? 1 : 0,
        novodocsemlinhas: novodocsemlinhas,
        anexargdocid: anexarGDocId,
        anexargdocfolderid: anexarGDocFolderId
      },
      body: doc
    });
  }

  public patchDoc(doc: IJsonDocComercial, reportException: boolean = true): TServiceResponse<IJsonDocComercial> {
    if (doc.cab.faccbId > 0 && ((doc.cab.nDocumento === 0 && doc.cab.nDocumentoDraft > 0) || !doc.cab.terminado)) {
      return this._entityService.put<IJsonDocComercial>({id: doc.cab.faccbId, params: {terminadoc: 0}, body: doc, reportExceptions: reportException});
    }
    return this._entityService.post<IJsonDocComercial>({params: {memoria: 1, terminadoc: 0}, body: doc, reportExceptions: reportException});
  }

  public getStoredDocument(grupo: string): Promise<IDocComercial> {
    const storageKey: string = this._getStorageNameDoc(grupo);
    return firstValueFrom(this._cgLocalStorageGroupService.getItem<string>(storageKey, SCHEMA_STRING, EGroupName.ERP)).then((json: string) => (json ? fromJson(json) : undefined));
  }

  public storeDocument(grupo: string, docComercial: IDocComercial): Promise<void> {
    const storageKey: string = this._getStorageNameDoc(grupo);
    return firstValueFrom(this._cgLocalStorageGroupService.setItem(storageKey, toJson(docComercial), SCHEMA_STRING, EGroupName.ERP));
  }

  public clearStoredDocument(grupo: string): Promise<void> {
    const storageKey: string = this._getStorageNameDoc(grupo);
    return firstValueFrom(this._cgLocalStorageGroupService.removeItem(storageKey, EGroupName.ERP));
  }

  public getGDoc(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    if (!doc) {
      this._plAlertService.error('docscomerciais.erros.notselected');
      return Promise.reject(new Error('docscomerciais.erros.notselected'));
    }
    const modalInstance = this._cgModalService.showVanilla(GDocComercialModalComponent);
    const componentInstance: GDocComercialModalComponent = modalInstance.componentInstance;
    componentInstance.doc = doc;
    componentInstance.service = this._entityService;
    return modalInstance.result;
  }

  public getMeiosPagamentoComercial(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    if (!doc) {
      this._plAlertService.error('docscomerciais.erros.notselected');
      return Promise.reject(new Error('docscomerciais.erros.notselected'));
    }
    const modalInstance = this._cgModalService.showVanilla(DocumentoMeiosPagamentoModalComponent, {size: 'lg'});
    const componentInstance: DocumentoMeiosPagamentoModalComponent = modalInstance.componentInstance;
    componentInstance.doc = copy(doc);
    componentInstance.codMoeda = this._configService.configurations.empresa.codMoeda;
    componentInstance.abreviaturaMoeda = this._configService.configurations.empresa.abreviaturaMoeda;
    componentInstance.emEdicao = false;
    return modalInstance.result;
  }

  public encerrarDocumento(doc: IJsonDocComercial, confirmModal: boolean): Promise<IJsonDocComercial> {
    if (!doc?.cab?.faccbId) {
      const error: string = this._plTranslateService.translate('docscomerciais.erros.notselected');
      this._plAlertService.error(error);
      return Promise.reject(new Error(error));
    }

    if (confirmModal) {
      return this._docscomerciaisModalService.encerrarDocumento(doc);
    }

    return this._entityService.put({url: `${doc.cab.faccbId}/encerrar`}).then((response) => response.body);
  }

  public getModeloImportacaoDocLinhas(extraFields: Array<EDocsComerciaisImportadorLinhasExtraField>): TServiceResponse<Blob> {
    return this._entityService.post<Blob, Array<EDocsComerciaisImportadorLinhasExtraField>>({
      url: 'import/modelo/xls',
      responseType: 'blob',
      body: extraFields
    });
  }

  public importModeloDocLinhasUrl(extraFields: Array<EDocsComerciaisImportadorLinhasExtraField>): Observable<string> {
    return buildSessionUrlWithParams(`${this._apiService.path.restapi}/${this._entityName}/import/execute/xls`, {extraFieldsCommaSep: extraFields.join(',')});
  }

  public obterUltimoDocumentoGlobalCriado(): TServiceResponse<IJsonDocUltimoDocGlobalCab> {
    return this._entityService.get<IJsonDocUltimoDocGlobalCab>({
      url: 'ultimodocumentoglobalcriado'
    });
  }

  public novoByDocOCRCabID(docOCRCabID: string): TServiceResponse<IJsonDocComercial> {
    return this._entityService.post<IJsonDocComercial>({
      url: 'novobydococrcabid',
      params: {
        dococrcabid: docOCRCabID
      }
    });
  }

  public obterTextosLivres(nClifo: string, nArtigo: string): TServiceResponse<IJsonDocComercialTextosLivres> {
    return this._entityService.get<IJsonDocComercialTextosLivres>({
      url: 'textoslivres',
      params: {nClifo: nClifo, nArtigo: nArtigo}
    });
  }

  public getImputacoesDocContab(faccbId: number): TServiceResponse<Array<IJsonDocContabImputacao>> {
    return this._entityService.get<Array<IJsonDocContabImputacao>>({
      url: `${faccbId}/imputacoes`
    });
  }

  public apagaImputacoesDocContab(faccbId: number): TServiceResponse<void> {
    return this._entityService.delete({
      url: `${faccbId}/imputacoes`
    });
  }

  public reAbreDocComercial(faccbId: number): TServiceResponse<IJsonDocComercial> {
    return this._entityService.put({
      url: `${faccbId}/reabre`
    });
  }

  public insertManualAtCodeToDocTransporteSaft(doc: IJsonDocComercial): TServiceResponse<IJsonDocComercial> {
    return this._entityService.put<IJsonDocComercial>({
      url: `${doc.cab.faccbId}/codigoat/${doc.cab.atDocCodeID}`
    });
  }

  private _getStorageNameDoc(suffix: string): string {
    return `${STORAGE_NAME_DOC}.${suffix}`;
  }
}
