<div class="modal-change-artigos-fields-serie">
  <div class="modal-header">
    <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'changeArtigosFieldsInSerie.modalTitle'"></h4>
  </div>

  <div class="sticky modal-body">
    <pl-nav-wizard #navWizard="navWizard" [hideFooter]="true" [callback]="navWizardCallback" [beforeStepChange]="fnBeforeChangedStep" [onFinalize]="fnOnFinalize">
      <pl-nav-wizard-step [stepId]="wizardSteps.PESQUISA" caption="changeArtigosFieldsInSerie.strings.pesquisa" icon="fa fa-search fa-fw" [hidePrevious]="true">
        <div *plNavWizardStepContent>
          <entity-list
            entityName="artigos"
            [maintenanceMode]="maintenanceMode"
            [serviceMethodsOverride]="serviceMethodsOverride"
            (evtDataGridColumnsPreparing)="onDataGridColumnsPreparing($event)"
            (evtDataGridOnInitialized)="onDataGridOnInitialized($event)"
            (evtDataGridCellPrepared)="onDataGridCellPrepared($event)"
            (evtDataGridOnSelectionChanged)="onDataGridOnSelectionChanged($event)"
            (evtDataGridOnEditingStart)="onDataGridOnEditingStart($event)"
            [beforeRequest]="fnBeforeRequest"
            [afterRequest]="fnAfterRequest"
            [filter]="filter"
            [dataGridProperties]="dataGridProperties">
          </entity-list>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step [stepId]="wizardSteps.ATUALIZACAO" caption="changeArtigosFieldsInSerie.strings.atualizacao" icon="fa fa-pencil fa-fw">
        <div *plNavWizardStepContent>
          <pl-split-view [initialSizeRight]="25" (evtSizeValuesChanged)="splitSizeValuesChanged()">
            <div *plSplitViewLeftSide>
              <h4 class="change-artigos-title"><i class="fa fa-fw fa-list me-1" aria-hidden="true"></i><span [translate]="'changeArtigosFieldsInSerie.strings.artigosSelecionados'"></span></h4>
              <dx-data-grid [cgDxDataGrid]="artigosSelDataGridDefinition" [dataSource]="artigosSelDataGridDefinition.dataSource" (onInitialized)="onArtigosSelInitialized($event)"></dx-data-grid>
            </div>
            <div *plSplitViewRightSide>
              <h4 class="change-artigos-title"><i class="fa fa-pencil-square-o" aria-hidden="true"></i>&nbsp;<span [translate]="'changeArtigosFieldsInSerie.strings.camposAAtualizar'"></span></h4>
              <div *ngFor="let item of changableFieldsUI | keyvalue">
                <div class="d-flex align-items-center mb-1" style="gap: 3px">
                  <pl-edit type="checkbox" [model]="changableFieldsUI[item.key].checked" (modelChange)="onModelChangeCheckbox($event, item.key)"></pl-edit>
                  <label><span [translate]="changableFieldsUI[item.key].uiLabel"></span>:</label>
                  <ng-container *ngIf="changableFieldsUI[item.key].uiType === 'entity'">
                    <entity-autocomplete
                      [entity]="changableFieldsUI[item.key].entityName"
                      [properties]="{disabled: !changableFieldsUI[item.key].checked, allowInvalid: false}"
                      inputClass="input-sm"
                      [(model)]="changableFieldsUI[item.key].entityModel">
                    </entity-autocomplete>
                  </ng-container>
                  <ng-container *ngIf="changableFieldsUI[item.key].uiType === 'booleanSelect'">
                    <pl-edit-switch [(model)]="changableFieldsUI[item.key].value" [properties]="{disabled: !changableFieldsUI[item.key].checked}"></pl-edit-switch>
                  </ng-container>
                </div>
                <div *ngIf="item.key === 'codIvaVenda' && changableFieldsUI[item.key].checked" class="d-flex align-items-center mb-1" style="gap: 3px">
                  <fieldset>
                    <legend [translate]="'changeArtigosFieldsInSerie.strings.calculoPreco'"></legend>
                    <pl-group>
                      <edit>
                        <pl-edit type="radiogroup" attrName="calculoPreco" [(model)]="calculoPrecoModel" [properties]="radioGroupCalculoPreco"></pl-edit>
                      </edit>
                    </pl-group>
                  </fieldset>
                </div>
              </div>
            </div>
          </pl-split-view>
        </div>
      </pl-nav-wizard-step>

      <pl-nav-wizard-step [stepId]="wizardSteps.RELATORIO" [hidePrevious]="true" caption="changeArtigosFieldsInSerie.strings.relatorio" icon="fa fa-newspaper-o fa-fw">
        <div *plNavWizardStepContent>
          <dx-data-grid [cgDxDataGrid]="artigosResumoDataGridDefinition" [dataSource]="artigosResumoDataGridDefinition.dataSource" (onInitialized)="onArtigosResumoInitialized($event)"></dx-data-grid>
        </div>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </div>

  <div class="modal-footer" plNavigation>
    <pl-nav-wizard-actions [navWizardInstance]="navWizard"></pl-nav-wizard-actions>

    <button type="button" class="btn btn-light btn-sm action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</div>
