<div class="declaracao-iva-periodica">
  <pl-alert *ngIf="decIvaPeriodica && !!decIvaPeriodica.decIvaPerioErro" type="warning" [closeable]="true">
    <div [innerHTML]="decIvaPeriodica.decIvaPerioErro | translate"></div>
  </pl-alert>

  <pl-form>
    <pl-tabs [theme]="'style3'" [activeId]="activeTab" [callback]="tabCallback" (evtSelected)="changedTab($event)" [plPromise]="promise">
      <pl-tab [id]="typeNavAnexos.Anexos">
        <div *plTabTitle ngbDropdown>
          <button type="button" class="btn btn-link p-0" ngbDropdownToggle [translate]="'declaracaoiva.tabs.anexos'"></button>
          <div ngbDropdownMenu class="dropdown-menu-anexos">
            <button type="button" ngbDropdownItem class="fw-bold"><span [translate]="'declaracaoiva.tabs.rosto'"></span></button>
            <button type="button" ngbDropdownItem class="fw-bold" *ngIf="hasAnexoRContinente">
              <div class="d-flex align-items-center">
                <span class="me-auto" [translate]="'declaracaoiva.tabs.anexoRContinente'" (click)="activeTab = typeNavAnexos.AnexoRContinente"></span>
                <div class="remove-anexo-icon" (click)="removeAnexo(typeNavAnexos.AnexoRContinente)">
                  <i class="fa fa-trash-o" aria-hidden="true" style=""></i>
                </div>
              </div>
            </button>
            <button type="button" ngbDropdownItem class="fw-bold" *ngIf="hasAnexoRAcores">
              <div class="d-flex align-items-center">
                <span class="me-auto" [translate]="'declaracaoiva.tabs.anexoRAcores'" (click)="activeTab = typeNavAnexos.AnexoRAcores"></span>
                <div class="remove-anexo-icon" (click)="removeAnexo(typeNavAnexos.AnexoRAcores)">
                  <i class="fa fa-trash-o" aria-hidden="true" style=""></i>
                </div>
              </div>
            </button>
            <button type="button" ngbDropdownItem class="fw-bold" *ngIf="hasAnexoRMadeira">
              <div class="d-flex align-items-center" style="">
                <span class="me-auto" [translate]="'declaracaoiva.tabs.anexoRMadeira'" (click)="activeTab = typeNavAnexos.AnexoRMadeira"></span>
                <div class="remove-anexo-icon" (click)="removeAnexo(typeNavAnexos.AnexoRMadeira)">
                  <i class="fa fa-trash-o" aria-hidden="true" style=""></i>
                </div>
              </div>
            </button>
            <button type="button" ngbDropdownItem class="fw-bold" *ngIf="hasAnexoCampo40">
              <div class="d-flex align-items-center" style="">
                <span class="me-auto" [translate]="'declaracaoiva.tabs.anexoCampo40'" (click)="activeTab = typeNavAnexos.AnexoCampo40"></span>
                <div class="remove-anexo-icon" (click)="removeAnexo(typeNavAnexos.AnexoCampo40)">
                  <i class="fa fa-trash-o" aria-hidden="true" style=""></i>
                </div>
              </div>
            </button>
            <button type="button" ngbDropdownItem class="fw-bold" *ngIf="hasAnexoCampo41">
              <div class="d-flex align-items-center" style="">
                <span class="me-auto" [translate]="'declaracaoiva.tabs.anexoCampo41'" (click)="activeTab = typeNavAnexos.AnexoCampo41"></span>
                <div class="remove-anexo-icon" (click)="removeAnexo(typeNavAnexos.AnexoCampo41)">
                  <i class="fa fa-trash-o" aria-hidden="true" style=""></i>
                </div>
              </div>
            </button>
            <div class="dropdown-divider"></div>
            <h6 class="dropdown-header" [translate]="'declaracaoiva.tabs.adicionarAnexo'"></h6>
            <div class="dropdown-divider"></div>
            <button
              type="button"
              ngbDropdownItem
              *ngIf="!hasAnexoRContinente"
              [translate]="'declaracaoiva.tabs.' + typeNavAnexos.AnexoRContinente"
              (click)="evaluateMenuAnexos(typeNavAnexos.AnexoRContinente, true); evaluateOperacoesDiferenteDaSede()"></button>
            <button
              type="button"
              ngbDropdownItem
              *ngIf="!hasAnexoRAcores"
              [translate]="'declaracaoiva.tabs.' + typeNavAnexos.AnexoRAcores"
              (click)="evaluateMenuAnexos(typeNavAnexos.AnexoRAcores, true); evaluateOperacoesDiferenteDaSede()"></button>
            <button
              type="button"
              ngbDropdownItem
              *ngIf="!hasAnexoRMadeira"
              [translate]="'declaracaoiva.tabs.' + typeNavAnexos.AnexoRMadeira"
              (click)="evaluateMenuAnexos(typeNavAnexos.AnexoRMadeira, true); evaluateOperacoesDiferenteDaSede()"></button>
            <button
              type="button"
              ngbDropdownItem
              *ngIf="!hasAnexoCampo40"
              [translate]="'declaracaoiva.tabs.' + typeNavAnexos.AnexoCampo40"
              (click)="evaluateMenuAnexos(typeNavAnexos.AnexoCampo40, true)"></button>
            <button
              type="button"
              ngbDropdownItem
              *ngIf="!hasAnexoCampo41"
              [translate]="'declaracaoiva.tabs.' + typeNavAnexos.AnexoCampo41"
              (click)="evaluateMenuAnexos(typeNavAnexos.AnexoCampo41, true)"></button>
          </div>
        </div>
      </pl-tab>

      <pl-tab [id]="typeNavAnexos.Rosto">
        <div *plTabTitle>
          <span class="fw-bold" [translate]="'declaracaoiva.tabs.rosto'"></span>
        </div>

        <div *plTabContent>
          <div class="card">
            <h5 class="card-header"><span class="number-title-box">0</span>&nbsp;<span class="nav-item-title" [translate]="'declaracaoiva.anexos.rosto.title'"></span></h5>
            <div class="card-body">
              <div class="inputs-currency-inline">
                <section class="mt-4">
                  <div class="box-title">
                    <div class="input-box-numbers-title">1</div>
                    <label [translate]="'declaracaoiva.anexos.rosto.quadro0.desc1'" class="input-box-numbers-label"></label>
                  </div>

                  <div class="ms-3 mt-2">
                    <pl-group>
                      <pl-group>
                        <label [translate]="'declaracaoiva.anexos.rosto.quadro0.nif'"></label>
                        <edit>
                          <pl-edit type="text" attrName="nif" [(model)]="decIvaPeriodica.nifEmpresa"></pl-edit>
                        </edit>
                      </pl-group>
                      <pl-group>
                        <label [translate]="'declaracaoiva.anexos.rosto.quadro0.locSede'"></label>
                        <edit>
                          <pl-edit type="areasRegionais" attrName="areasRegionais" [(model)]="decIvaPeriodica.dpIVArbLocSede" [properties]="{disabled: true}"></pl-edit>
                        </edit>
                      </pl-group>
                    </pl-group>
                  </div>
                </section>

                <section class="mt-4">
                  <div class="box-title">
                    <div class="input-box-numbers-title">2</div>
                    <label [translate]="'declaracaoiva.anexos.rosto.quadro0.desc2'" class="input-box-numbers-label"></label>
                  </div>

                  <div class="ms-3 mt-2">
                    <pl-group>
                      <label [translate]="'declaracaoiva.anexos.rosto.quadro0.prazodecl'"></label>
                      <edit>
                        <pl-autocomplete
                          [source]="metodoSourcePrazoDecl"
                          attrName="prazoDecl"
                          [model]="nomePrazoDecl"
                          (evtSelected)="metodoPrazoDeclChanged($event)"
                          [rowTemplate]="mtdOutput"
                          output="nameMetodo">
                        </pl-autocomplete>
                      </edit>
                    </pl-group>
                  </div>
                </section>

                <section class="mt-4">
                  <div class="box-title">
                    <div class="input-box-numbers-title">3</div>
                    <label [translate]="'declaracaoiva.anexos.rosto.quadro0.desc3'" class="input-box-numbers-label"></label>
                  </div>

                  <div class="ms-3 mt-2">
                    <pl-group>
                      <pl-group>
                        <edit>
                          <pl-edit type="checkbox" attrName="continente" [(model)]="continente" [properties]="{label: 'declaracaoiva.anexos.rosto.quadro0.continente'}"></pl-edit>
                        </edit>
                      </pl-group>
                      <pl-group>
                        <edit>
                          <pl-edit type="checkbox" attrName="acores" [(model)]="acores" [properties]="{label: 'declaracaoiva.anexos.rosto.quadro0.acores'}"></pl-edit>
                        </edit>
                      </pl-group>
                      <pl-group>
                        <edit>
                          <pl-edit type="checkbox" attrName="madeira" [(model)]="madeira" [properties]="{label: 'declaracaoiva.anexos.rosto.quadro0.madeira'}"></pl-edit>
                        </edit>
                      </pl-group>
                    </pl-group>
                  </div>
                </section>

                <section class="mt-4">
                  <div class="box-title">
                    <div class="input-box-numbers-title">4-A</div>
                    <label [translate]="'declaracaoiva.anexos.rosto.quadro0.desc4A'" class="input-box-numbers-label"></label>
                  </div>

                  <div class="ms-3 mt-2">
                    <pl-group>
                      <edit>
                        <pl-edit
                          type="checkbox"
                          attrName="transaccoesIntraComunitarias"
                          [(model)]="decIvaPeriodica.transaccoesIntraComunitarias"
                          [properties]="{label: 'declaracaoiva.anexos.rosto.quadro0.check4A'}"></pl-edit>
                      </edit>
                    </pl-group>
                  </div>
                </section>

                <section class="mt-4">
                  <div class="box-title">
                    <div class="input-box-numbers-title">5</div>
                    <label [translate]="'declaracaoiva.anexos.rosto.quadro0.desc5'" class="input-box-numbers-label"></label>
                  </div>

                  <div class="ms-3 mt-2">
                    <pl-group>
                      <edit>
                        <pl-edit
                          type="checkbox"
                          attrName="inexistenciaOperacoesNoPeriodo"
                          [(model)]="decIvaPeriodica.inexistenciaOperacoesNoPeriodo"
                          [properties]="{label: 'declaracaoiva.anexos.rosto.quadro0.check5'}"></pl-edit>
                      </edit>
                    </pl-group>
                  </div>
                </section>

                <section class="mt-4">
                  <div class="box-title">
                    <div class="input-box-numbers-title">20</div>
                    <label [translate]="'declaracaoiva.anexos.rosto.quadro0.desc20'" class="input-box-numbers-label"></label>
                  </div>

                  <div class="ms-3 mt-2">
                    <pl-group>
                      <label [translate]="'declaracaoiva.anexos.rosto.quadro0.nifcert'"></label>
                      <edit>
                        <pl-edit type="text" attrName="nif" [(model)]="decIvaPeriodica.nifTOC"></pl-edit>
                      </edit>
                    </pl-group>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </pl-tab>

      <pl-tab [id]="typeNavAnexos.AnexoRContinente" *ngIf="hasAnexoRContinente">
        <div *plTabTitle>
          <span [translate]="'declaracaoiva.tabs.anexoRContinente'"></span>&nbsp;<span *ngIf="typeNavAnexos.AnexoRContinente === anexoSede" [translate]="'declaracaoiva.tabs.sede'"></span>
        </div>

        <div *plTabContent>
          <ng-container *ngTemplateOutlet="templateAnexoR; context: {anexorLocal: decIvaPeriodica.continente, local: typeNavAnexos.AnexoRContinente}"></ng-container>
        </div>
      </pl-tab>

      <pl-tab [id]="typeNavAnexos.AnexoRAcores" *ngIf="hasAnexoRAcores">
        <div *plTabTitle><span [translate]="'declaracaoiva.tabs.anexoRAcores'"></span>&nbsp;<span *ngIf="typeNavAnexos.AnexoRAcores === anexoSede" [translate]="'declaracaoiva.tabs.sede'"></span></div>

        <div *plTabContent>
          <ng-container *ngTemplateOutlet="templateAnexoR; context: {anexorLocal: decIvaPeriodica.acores, local: typeNavAnexos.AnexoRAcores}"></ng-container>
        </div>
      </pl-tab>

      <pl-tab [id]="typeNavAnexos.AnexoRMadeira" *ngIf="hasAnexoRMadeira">
        <div *plTabTitle>
          <span [translate]="'declaracaoiva.tabs.anexoRMadeira'"></span>&nbsp;<span *ngIf="typeNavAnexos.AnexoRMadeira === anexoSede" [translate]="'declaracaoiva.tabs.sede'"></span>
        </div>

        <div *plTabContent>
          <ng-container *ngTemplateOutlet="templateAnexoR; context: {anexorLocal: decIvaPeriodica.madeira, local: typeNavAnexos.AnexoRMadeira}"></ng-container>
        </div>
      </pl-tab>

      <pl-tab [id]="typeNavAnexos.AnexoCampo40" *ngIf="hasAnexoCampo40">
        <div *plTabTitle>
          <span [translate]="'declaracaoiva.tabs.anexoCampo40'"></span>
        </div>

        <div *plTabContent>
          <div class="card">
            <h5 class="card-header"><span class="number-title-box">0</span>&nbsp;<span class="nav-item-title" [translate]="'declaracaoiva.anexos.anexoCampo40.title'"></span></h5>
            <div class="card-body">
              <div class="inputs-currency-inline">
                <section class="mt-4">
                  <div class="box-title">
                    <div class="input-box-numbers-title">1</div>
                    <label [translate]="'declaracaoiva.anexos.anexoCampo40.desc1'" class="input-box-numbers-label"></label>
                  </div>

                  <div class="ms-3 mt-2 text-nowrap">
                    <dx-data-grid [cgDxDataGrid]="dataGridDefinitionCampo40" cgDxDataGridInstanceName="anexoCampo40Regularizacoes" [dataSource]="dataGridDefinitionCampo40.dataSource"></dx-data-grid>
                  </div>
                </section>

                <hr />

                <section class="mt-4">
                  <div class="box-title">
                    <div class="input-box-numbers-title">2</div>
                    <label [translate]="'declaracaoiva.anexos.anexoCampo40.desc2'" class="input-box-numbers-label"></label>
                  </div>

                  <div class="ms-3 mt-2">
                    <div class="inputs-currency-inline">
                      <div class="row">
                        <div class="col"></div>
                        <div class="col-lg-3"><label [translate]="'declaracaoiva.anexos.anexoCampo40.baseincidenciareg'"></label></div>
                        <div class="col-lg-3"><label [translate]="'declaracaoiva.anexos.anexoCampo40.ivareg'"></label></div>
                      </div>

                      <div class="row">
                        <div class="col"><label [translate]="'declaracaoiva.anexos.anexoCampo40.field3e4'"></label></div>

                        <div class="col-lg-3">
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">3</div>
                            <pl-edit
                              type="currency"
                              attrName="anexoCampo40Campo3"
                              [model]="decIvaPeriodica.anexoCampo40.regCujoCreditoNaoSejaSupA750BaseIncidencia"
                              (modelChange)="decIvaPeriodica.anexoCampo40.regCujoCreditoNaoSejaSupA750BaseIncidencia = $event">
                            </pl-edit>
                          </div>
                        </div>

                        <div class="col-lg-3">
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">4</div>
                            <pl-edit
                              type="currency"
                              attrName="anexoCampo40Campo4"
                              [model]="decIvaPeriodica.anexoCampo40.regCujoCreditoNaoSejaSupA750IvaRegularizado"
                              (modelChange)="decIvaPeriodica.anexoCampo40.regCujoCreditoNaoSejaSupA750IvaRegularizado = $event; evaluateTotalAnexoCampo40()">
                            </pl-edit>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section class="mt-4">
                  <div class="box-title">
                    <div class="input-box-numbers-title">3</div>
                    <label [translate]="'declaracaoiva.anexos.anexoCampo40.desc3'" class="input-box-numbers-label"></label>
                  </div>

                  <div class="ms-3 mt-2">
                    <div class="inputs-currency-inline">
                      <div class="row">
                        <div class="col"><label [translate]="'declaracaoiva.anexos.anexoCampo40.field3Ae4A'"></label></div>

                        <div class="col-lg-3">
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">3A</div>
                            <pl-edit
                              type="currency"
                              attrName="anexoCampo40Campo3A"
                              [model]="decIvaPeriodica.anexoCampo40.regAbrangidasArtigos23A26BaseIncidencia"
                              (modelChange)="decIvaPeriodica.anexoCampo40.regAbrangidasArtigos23A26BaseIncidencia = $event">
                            </pl-edit>
                          </div>
                        </div>

                        <div class="col-lg-3">
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">4A</div>
                            <pl-edit
                              type="currency"
                              attrName="anexoCampo40Campo4A"
                              [model]="decIvaPeriodica.anexoCampo40.regAbrangidasArtigos23A26IvaRegularizado"
                              (modelChange)="decIvaPeriodica.anexoCampo40.regAbrangidasArtigos23A26IvaRegularizado = $event; evaluateTotalAnexoCampo40()">
                            </pl-edit>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col"><label [translate]="'declaracaoiva.anexos.anexoCampo40.field3Be4B'"></label></div>

                        <div class="col-lg-3">
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">3B</div>
                            <pl-edit
                              type="currency"
                              attrName="anexoCampo40Campo3B"
                              [model]="decIvaPeriodica.anexoCampo40.outrasRegularizacoesBaseIncidencia"
                              (modelChange)="decIvaPeriodica.anexoCampo40.outrasRegularizacoesBaseIncidencia = $event">
                            </pl-edit>
                          </div>
                        </div>

                        <div class="col-lg-3">
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">4B</div>
                            <pl-edit
                              type="currency"
                              attrName="anexoCampo40Campo4B"
                              [model]="decIvaPeriodica.anexoCampo40.outrasRegularizacoesIvaRegularizado"
                              (modelChange)="decIvaPeriodica.anexoCampo40.outrasRegularizacoesIvaRegularizado = $event; evaluateTotalAnexoCampo40()">
                            </pl-edit>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section class="mt-4">
                  <div class="box-title">
                    <div class="input-box-numbers-title">4</div>
                    <label [translate]="'declaracaoiva.anexos.anexoCampo40.desc4'" class="input-box-numbers-label"></label>
                  </div>

                  <div class="ms-3 mt-2">
                    <div class="inputs-currency-inline">
                      <div class="row">
                        <div class="col"></div>
                        <div class="col-lg-3"></div>

                        <div class="col-lg-3">
                          <label [translate]="'declaracaoiva.anexos.anexoCampo40.desc4'"></label>
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">4</div>
                            <pl-edit type="currency" attrName="anexoCampo40Campo4" [model]="decIvaPeriodica.anexoCampo40.valorTotalCampo40" [properties]="{disabled: true}"></pl-edit>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section class="mt-4">
                  <div class="box-title">
                    <div class="input-box-numbers-title">5</div>
                    <label [translate]="'declaracaoiva.anexos.anexoCampo40.desc5'" class="input-box-numbers-label"></label>
                  </div>

                  <div class="ms-3 mt-2">
                    <dx-data-grid [cgDxDataGrid]="dataGridDefinitionROC" [dataSource]="nifsROC" (onInitialized)="onDataGridInitializedROC($event)" (onInitNewRow)="onDataGridROCInitNewRow($event)">
                    </dx-data-grid>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </pl-tab>

      <pl-tab [id]="typeNavAnexos.AnexoCampo41" *ngIf="hasAnexoCampo41">
        <div *plTabTitle>
          <span [translate]="'declaracaoiva.tabs.anexoCampo41'"></span>
        </div>

        <div *plTabContent>
          <div class="card">
            <h5 class="card-header"><span class="number-title-box">0</span>&nbsp;<span class="nav-item-title" [translate]="'declaracaoiva.anexos.anexoCampo41.title'"></span></h5>
            <div class="card-body">
              <div class="inputs-currency-inline">
                <section class="mt-4">
                  <div class="box-title">
                    <div class="input-box-numbers-title">1</div>
                    <label [translate]="'declaracaoiva.anexos.anexoCampo41.desc1'" class="input-box-numbers-label"></label>
                  </div>

                  <div class="ms-3 mt-2 text-nowrap">
                    <dx-data-grid [cgDxDataGrid]="dataGridDefinitionCampo41" cgDxDataGridInstanceName="anexoCampo41Regularizacoes" [dataSource]="dataGridDefinitionCampo41.dataSource"></dx-data-grid>
                  </div>
                </section>

                <hr />

                <section class="mt-4">
                  <div class="box-title">
                    <div class="input-box-numbers-title">2</div>
                    <label [translate]="'declaracaoiva.anexos.anexoCampo41.desc2'" class="input-box-numbers-label"></label>
                  </div>

                  <div class="ms-3 mt-2">
                    <div class="inputs-currency-inline">
                      <div class="row">
                        <div class="col"></div>
                        <div class="col-lg-3"><label [translate]="'declaracaoiva.anexos.anexoCampo41.baseincidenciareg'"></label></div>
                        <div class="col-lg-3"><label [translate]="'declaracaoiva.anexos.anexoCampo41.ivareg'"></label></div>
                      </div>

                      <div class="row">
                        <div class="col"><label [translate]="'declaracaoiva.anexos.anexoCampo41.field3Ae4A'"></label></div>

                        <div class="col-lg-3">
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">3A</div>
                            <pl-edit
                              type="currency"
                              attrName="anexoCampo41Campo3A"
                              [model]="decIvaPeriodica.anexoCampo41.regAbrangidasPelosArt23a26BaseIncidencia"
                              (modelChange)="decIvaPeriodica.anexoCampo41.regAbrangidasPelosArt23a26BaseIncidencia = $event">
                            </pl-edit>
                          </div>
                        </div>

                        <div class="col-lg-3">
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">4A</div>
                            <pl-edit
                              type="currency"
                              attrName="anexoCampo41Campo4A"
                              [model]="decIvaPeriodica.anexoCampo41.regAbrangidasPelosArt23a26IvaRegularizado"
                              (modelChange)="decIvaPeriodica.anexoCampo41.regAbrangidasPelosArt23a26IvaRegularizado = $event; evaluateTotalAnexoCampo41()">
                            </pl-edit>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col"><label [translate]="'declaracaoiva.anexos.anexoCampo41.field3Be4B'"></label></div>

                        <div class="col-lg-3">
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">3B</div>
                            <pl-edit
                              type="currency"
                              attrName="anexoCampo41Campo3B"
                              [model]="decIvaPeriodica.anexoCampo41.outrasRegularizacoesBaseIncidencia"
                              (modelChange)="decIvaPeriodica.anexoCampo41.outrasRegularizacoesBaseIncidencia = $event">
                            </pl-edit>
                          </div>
                        </div>

                        <div class="col-lg-3">
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">4B</div>
                            <pl-edit
                              type="currency"
                              attrName="anexoCampo41Campo4B"
                              [model]="decIvaPeriodica.anexoCampo41.outrasRegularizacoesIvaRegularizado"
                              (modelChange)="decIvaPeriodica.anexoCampo41.outrasRegularizacoesIvaRegularizado = $event; evaluateTotalAnexoCampo41()">
                            </pl-edit>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col"><label [translate]="'declaracaoiva.anexos.anexoCampo41.field3Ce4C'"></label></div>

                        <div class="col-lg-3">
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">3C</div>
                            <pl-edit
                              type="currency"
                              attrName="anexoCampo41Campo3C"
                              [model]="decIvaPeriodica.anexoCampo41.artigo6BaseIncidencia"
                              (modelChange)="decIvaPeriodica.anexoCampo41.artigo6BaseIncidencia = $event">
                            </pl-edit>
                          </div>
                        </div>

                        <div class="col-lg-3">
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">4C</div>
                            <pl-edit
                              type="currency"
                              attrName="anexoCampo41Campo4C"
                              [model]="decIvaPeriodica.anexoCampo41.artigo6IvaRegularizado"
                              (modelChange)="decIvaPeriodica.anexoCampo41.artigo6IvaRegularizado = $event; evaluateTotalAnexoCampo41()">
                            </pl-edit>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col"><label [translate]="'declaracaoiva.anexos.anexoCampo41.field3De4D'"></label></div>

                        <div class="col-lg-3">
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">3D</div>
                            <pl-edit
                              type="currency"
                              attrName="anexoCampo41Campo3D"
                              [model]="decIvaPeriodica.anexoCampo41.decretoLei192017BaseIncidencia"
                              (modelChange)="decIvaPeriodica.anexoCampo41.decretoLei192017BaseIncidencia = $event">
                            </pl-edit>
                          </div>
                        </div>

                        <div class="col-lg-3">
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">4D</div>
                            <pl-edit
                              type="currency"
                              attrName="anexoCampo41Campo4D"
                              [model]="decIvaPeriodica.anexoCampo41.decretoLei192017IvaRegularizado"
                              (modelChange)="decIvaPeriodica.anexoCampo41.decretoLei192017IvaRegularizado = $event; evaluateTotalAnexoCampo41()">
                            </pl-edit>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section class="mt-4">
                  <div class="box-title">
                    <div class="input-box-numbers-title">3</div>
                    <label [translate]="'declaracaoiva.anexos.anexoCampo41.desc3'" class="input-box-numbers-label"></label>
                  </div>

                  <div class="ms-3 mt-2">
                    <div class="inputs-currency-inline">
                      <div class="row">
                        <div class="col"></div>
                        <div class="col-lg-3"></div>

                        <div class="col-lg-3">
                          <label [translate]="'declaracaoiva.anexos.anexoCampo41.desc3'"></label>
                          <div class="d-flex">
                            <div class="input-box-numbers-hundred">4</div>
                            <pl-edit type="currency" attrName="anexoCampo41Campo4" [model]="decIvaPeriodica.anexoCampo41.valorTotalCampo41" [properties]="{disabled: true}"></pl-edit>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </pl-tab>
    </pl-tabs>
  </pl-form>
</div>

<ng-template #templateAnexoR let-anexorLocal="anexorLocal" let-local="local">
  <div class="card">
    <h5 class="card-header"><span class="number-title-box">6</span>&nbsp;<span class="nav-item-title" [translate]="'declaracaoiva.anexos.anexoR.quadro6.title'"></span></h5>
    <div class="card-body">
      <div class="inputs-currency-inline">
        <section class="mt-4">
          <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.desc'"></label>

          <div class="ms-3 mt-2">
            <ul>
              <li [translate]="'declaracaoiva.anexos.anexoR.quadro6.point1'"></li>
              <li [translate]="'declaracaoiva.anexos.anexoR.quadro6.point2'"></li>
            </ul>
            <div class="d-flex">
              <div class="me-2">
                <pl-edit
                  type="radio"
                  attrName="houveOperacoesDestaNatureza"
                  [model]="anexorLocal.efectouOperacoesRevCharge"
                  (modelChange)="anexorLocal.efectouOperacoesRevCharge = $event"
                  [properties]="{value: true, label: 'declaracaoiva.anexos.comum.sim'}">
                </pl-edit>
              </div>
              <pl-edit
                type="radio"
                attrName="houveOperacoesDestaNatureza"
                [model]="anexorLocal.efectouOperacoesRevCharge"
                (modelChange)="anexorLocal.efectouOperacoesRevCharge = $event"
                [properties]="{value: false, label: 'declaracaoiva.anexos.comum.nao'}">
              </pl-edit>
            </div>
          </div>
        </section>

        <section class="mt-4">
          <div class="box-title">
            <div class="input-box-numbers-title">1</div>
            <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.desc1'" class="input-box-numbers-label"></label>
          </div>

          <div class="ms-3 mt-2">
            <div class="inputs-currency-inline">
              <div class="row">
                <div class="col"></div>
                <div class="col-lg-3"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.basetribut'"></label></div>
                <div class="col-lg-3"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.impfavorestado'"></label></div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field1e2'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers">1</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo1" [model]="anexorLocal.baseTributavel1" (modelChange)="anexorLocal.baseTributavel1 = $event; anexoRCalcTotBaseTrib(local)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          class="fa fa-info-circle text-info"
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          (click)="openExtratoCampo('1', false)"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">2</div>
                    <pl-edit
                      type="currency"
                      attrName="anexorQuadro6Campo2"
                      [model]="anexorLocal.impostoFavEstado2"
                      (modelChange)="anexorLocal.impostoFavEstado2 = $event; anexoRCalcTotImpFavEstado(local); anexoRCalcTotImpFavEstado(anexoSede)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          (click)="openExtratoCampo('2')"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field5e6'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers">5</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo5" [model]="anexorLocal.baseTributavel5" (modelChange)="anexorLocal.baseTributavel5 = $event; anexoRCalcTotBaseTrib(local)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          (click)="openExtratoCampo('5', false)"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">6</div>
                    <pl-edit
                      type="currency"
                      attrName="anexorQuadro6Campo6"
                      [model]="anexorLocal.impostoFavEstado6"
                      (modelChange)="anexorLocal.impostoFavEstado6 = $event; anexoRCalcTotImpFavEstado(local); anexoRCalcTotImpFavEstado(anexoSede)"
                      [properties]="{modelOptions: {debounce: 500}}">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          (click)="openExtratoCampo('6')"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field3e4'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers">3</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo3" [model]="anexorLocal.baseTributavel3" (modelChange)="anexorLocal.baseTributavel3 = $event; anexoRCalcTotBaseTrib(local)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          (click)="openExtratoCampo('3', false)"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">4</div>
                    <pl-edit
                      type="currency"
                      attrName="anexorQuadro6Campo4"
                      [model]="anexorLocal.impostoFavEstado4"
                      (modelChange)="anexorLocal.impostoFavEstado4 = $event; anexoRCalcTotImpFavEstado(local); anexoRCalcTotImpFavEstado(anexoSede)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          (click)="openExtratoCampo('4')"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.isentaounaotrib'"></label></div>
                <div class="col-lg-3"></div>
                <div class="col-lg-3"></div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field7'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers">7</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo7" [model]="anexorLocal.baseTributavel7" (modelChange)="anexorLocal.baseTributavel7 = $event; anexoRCalcTotBaseTrib(local)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          (click)="openExtratoCampo('7', false)"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3"></div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field8'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers">8</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo8" [model]="anexorLocal.baseTributavel8" (modelChange)="anexorLocal.baseTributavel8 = $event; anexoRCalcTotBaseTrib(local)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          (click)="openExtratoCampo('8', false)"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3"></div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field9'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers">9</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo9" [model]="anexorLocal.baseTributavel9" (modelChange)="anexorLocal.baseTributavel9 = $event; anexoRCalcTotBaseTrib(local)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          (click)="openExtratoCampo('9', false)"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3"></div>
              </div>
            </div>
          </div>
        </section>

        <section class="mt-4">
          <div class="box-title">
            <div class="input-box-numbers-title">2</div>
            <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.desc2'" class="input-box-numbers-label"></label>
          </div>

          <div class="ms-3 mt-2">
            <div class="inputs-currency-inline">
              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field12e13'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">12</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo12" [model]="anexorLocal.baseTributavel12" (modelChange)="anexorLocal.baseTributavel12 = $event; anexoRCalcCampo10(local)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          (click)="openExtratoCampo('12', false)"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">13</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo13" [model]="anexorLocal.impostoFavEstado13" (modelChange)="anexorLocal.impostoFavEstado13 = $event; anexoRCalcCampo11(local)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          (click)="openExtratoCampo('13')"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field14'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">14</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo14" [model]="anexorLocal.baseTributavel14" (modelChange)="anexorLocal.baseTributavel14 = $event; anexoRCalcCampo10(local)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          (click)="openExtratoCampo('14', false)"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3"></div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field15'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">15</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo15" [model]="anexorLocal.baseTributavel15" (modelChange)="anexorLocal.baseTributavel15 = $event; anexoRCalcCampo10(local)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          (click)="openExtratoCampo('15', false)"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3"></div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field12e13'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">10</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo10" [model]="anexorLocal.baseTributavel10" [properties]="{disabled: true}"></pl-edit>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">11</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo11" [model]="anexorLocal.impostoFavEstado11" [properties]="{disabled: true}"></pl-edit>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="mt-4">
          <div class="box-title">
            <div class="input-box-numbers-title">3</div>
            <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.desc3'" class="input-box-numbers-label"></label>
          </div>

          <div class="ms-3 mt-2">
            <div class="inputs-currency-inline">
              <div class="row">
                <div class="col"></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">16</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo16" [model]="anexorLocal.baseTributavel16" (modelChange)="anexorLocal.baseTributavel16 = $event; anexoRCalcTotBaseTrib(local)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          (click)="openExtratoCampo('16', false)"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">17</div>
                    <pl-edit
                      type="currency"
                      attrName="anexorQuadro6Campo17"
                      [model]="anexorLocal.impostoFavEstado17"
                      (modelChange)="anexorLocal.impostoFavEstado17 = $event; anexoRCalcTotImpFavEstado(local); anexoRCalcTotImpFavEstado(anexoSede)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          (click)="openExtratoCampo('17')"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="mt-4">
          <div class="box-title">
            <div class="input-box-numbers-title">4</div>
            <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.desc4'" class="input-box-numbers-label"></label>
          </div>

          <div class="ms-3 mt-2">
            <div class="inputs-currency-inline">
              <div class="row">
                <div class="col"></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">18</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo18" [model]="anexorLocal.baseTributavel18" (modelChange)="anexorLocal.baseTributavel18 = $event; anexoRCalcTotBaseTrib(local)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          (click)="openExtratoCampo('18', false)"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">19</div>
                    <pl-edit
                      type="currency"
                      attrName="anexorQuadro6Campo19"
                      [model]="anexorLocal.impostoFavEstado19"
                      (modelChange)="anexorLocal.impostoFavEstado19 = $event; anexoRCalcTotImpFavEstado(local); anexoRCalcTotImpFavEstado(anexoSede)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          (click)="openExtratoCampo('19')"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="mt-4">
          <div class="box-title">
            <div class="input-box-numbers-title">5</div>
            <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.desc5'" class="input-box-numbers-label"></label>
          </div>

          <div class="ms-3 mt-2">
            <div class="inputs-currency-inline">
              <div class="row">
                <div class="col"></div>
                <div class="col-lg-3"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.impostfavSujPassivo'"></label></div>
                <div class="col-lg-3"></div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field20'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">20</div>
                    <pl-edit
                      type="currency"
                      attrName="anexorQuadro6Campo20"
                      [model]="anexorLocal.impostoFavSujeitoPassivo20"
                      (modelChange)="anexorLocal.impostoFavSujeitoPassivo20 = $event; anexoRCalcTotImpFavSujPassivo(local); anexoRCalcTotImpFavSujPassivo(anexoSede)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          (click)="openExtratoCampo('20')"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3"></div>
              </div>

              <div class="col" [translate]="'declaracaoiva.anexos.anexoR.quadro6.inventarios'"></div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field21'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">21</div>
                    <pl-edit
                      type="currency"
                      attrName="anexorQuadro6Campo21"
                      [model]="anexorLocal.impostoFavSujeitoPassivo21"
                      (modelChange)="anexorLocal.impostoFavSujeitoPassivo21 = $event; anexoRCalcTotImpFavSujPassivo(local); anexoRCalcTotImpFavSujPassivo(anexoSede)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          (click)="openExtratoCampo('21')"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3"></div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field23'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">23</div>
                    <pl-edit
                      type="currency"
                      attrName="anexorQuadro6Campo23"
                      [model]="anexorLocal.impostoFavSujeitoPassivo23"
                      (modelChange)="anexorLocal.impostoFavSujeitoPassivo23 = $event; anexoRCalcTotImpFavSujPassivo(local); anexoRCalcTotImpFavSujPassivo(anexoSede)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          (click)="openExtratoCampo('23')"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3"></div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field22'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">22</div>
                    <pl-edit
                      type="currency"
                      attrName="anexorQuadro6Campo22"
                      [model]="anexorLocal.impostoFavSujeitoPassivo22"
                      (modelChange)="anexorLocal.impostoFavSujeitoPassivo22 = $event; anexoRCalcTotImpFavSujPassivo(local); anexoRCalcTotImpFavSujPassivo(anexoSede)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          (click)="openExtratoCampo('22')"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3"></div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field24'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">24</div>
                    <pl-edit
                      type="currency"
                      attrName="anexorQuadro6Campo24"
                      [model]="anexorLocal.impostoFavSujeitoPassivo24"
                      (modelChange)="anexorLocal.impostoFavSujeitoPassivo24 = $event; anexoRCalcTotImpFavSujPassivo(local); anexoRCalcTotImpFavSujPassivo(anexoSede)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <i
                          [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                          class="fa fa-info-circle text-info"
                          aria-hidden="true"
                          plDelegateEventDownwardsClick
                          (click)="openExtratoCampo('24')"></i>
                      </div>
                    </pl-edit>
                  </div>
                </div>

                <div class="col-lg-3"></div>
              </div>
            </div>
          </div>
        </section>

        <ng-container *ngIf="anexoSede === local">
          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">6</div>
              <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.desc6'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div class="inputs-currency-inline">
                <div class="row">
                  <div class="col"></div>

                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">40</div>
                      <pl-edit
                        type="currency"
                        attrName="anexorQuadro6Campo40"
                        [model]="anexorLocal.impostoFavSujeitoPassivo40"
                        (modelChange)="anexorLocal.impostoFavSujeitoPassivo40 = $event; anexoRCalcTotImpFavSujPassivo(local); anexoRCalcTotImpFavSujPassivo(anexoSede)">
                        <div *inputGroup="{klass: 'hollow-form'}">
                          <i
                            [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                            class="fa fa-info-circle text-info"
                            aria-hidden="true"
                            plDelegateEventDownwardsClick
                            (click)="openExtratoCampo('40')"></i>
                        </div>
                      </pl-edit>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">41</div>
                      <pl-edit
                        type="currency"
                        attrName="anexorQuadro6Campo41"
                        [model]="anexorLocal.impostoFavEstado41"
                        (modelChange)="anexorLocal.impostoFavEstado41 = $event; anexoRCalcTotImpFavEstado(local); anexoRCalcTotImpFavEstado(anexoSede)">
                        <div *inputGroup="{klass: 'hollow-form'}">
                          <i
                            [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                            class="fa fa-info-circle text-info"
                            aria-hidden="true"
                            plDelegateEventDownwardsClick
                            (click)="openExtratoCampo('41')"></i>
                        </div>
                      </pl-edit>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">7</div>
              <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.desc7'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div class="inputs-currency-inline">
                <div class="row">
                  <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field61'"></label></div>

                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">61</div>
                      <pl-edit
                        type="currency"
                        attrName="anexorQuadro6Campo61"
                        [model]="anexorLocal.impostoFavSujeitoPassivo61"
                        (modelChange)="anexorLocal.impostoFavSujeitoPassivo61 = $event; anexoRCalcTotImpFavSujPassivo(local); anexoRCalcTotImpFavSujPassivo(anexoSede)">
                        <div *inputGroup="{klass: 'hollow-form'}">
                          <i
                            [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                            class="fa fa-info-circle text-info"
                            aria-hidden="true"
                            plDelegateEventDownwardsClick
                            (click)="openExtratoCampo('61', false)"></i>
                        </div>
                      </pl-edit>
                    </div>
                  </div>

                  <div class="col-lg-3"></div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">8</div>
              <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.desc8'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div class="inputs-currency-inline">
                <div class="row">
                  <div class="col"></div>

                  <div class="col-lg-3">
                    <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.impdedutivel'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">65</div>
                      <pl-edit
                        type="currency"
                        attrName="anexorQuadro6Campo65"
                        [model]="anexorLocal.impostoFavSujeitoPassivo65"
                        (modelChange)="anexorLocal.impostoFavSujeitoPassivo65 = $event; anexoRCalcTotImpFavSujPassivo(local); anexoRCalcTotImpFavSujPassivo(anexoSede)">
                        <div *inputGroup="{klass: 'hollow-form'}">
                          <i
                            [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                            class="fa fa-info-circle text-info"
                            aria-hidden="true"
                            plDelegateEventDownwardsClick
                            (click)="openExtratoCampo('65', false)"></i>
                        </div>
                      </pl-edit>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.impliquidado'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">66</div>
                      <pl-edit
                        type="currency"
                        attrName="anexorQuadro6Campo66"
                        [model]="anexorLocal.impostoFavEstado66"
                        (modelChange)="anexorLocal.impostoFavEstado66 = $event; anexoRCalcTotImpFavEstado(local); anexoRCalcTotImpFavEstado(anexoSede)">
                        <div *inputGroup="{klass: 'hollow-form'}">
                          <i
                            [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                            class="fa fa-info-circle text-info"
                            aria-hidden="true"
                            plDelegateEventDownwardsClick
                            (click)="openExtratoCampo('66', false)"></i>
                        </div>
                      </pl-edit>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">9</div>
              <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.desc9'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div class="inputs-currency-inline">
                <div class="row">
                  <div class="col"></div>

                  <div class="col-lg-3">
                    <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.impdedutivel'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">67</div>
                      <pl-edit
                        type="currency"
                        attrName="anexorQuadro6Campo67"
                        [model]="anexorLocal.impostoFavSujeitoPassivo67"
                        (modelChange)="anexorLocal.impostoFavSujeitoPassivo67 = $event; anexoRCalcTotImpFavSujPassivo(local); anexoRCalcTotImpFavSujPassivo(anexoSede)">
                        <div *inputGroup="{klass: 'hollow-form'}">
                          <i
                            [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                            class="fa fa-info-circle text-info"
                            aria-hidden="true"
                            plDelegateEventDownwardsClick
                            (click)="openExtratoCampo('67', false)"></i>
                        </div>
                      </pl-edit>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.impliquidado'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">68</div>
                      <pl-edit
                        type="currency"
                        attrName="anexorQuadro6Campo68"
                        [model]="anexorLocal.impostoFavEstado68"
                        (modelChange)="anexorLocal.impostoFavEstado68 = $event; anexoRCalcTotImpFavEstado(local); anexoRCalcTotImpFavEstado(anexoSede)">
                        <div *inputGroup="{klass: 'hollow-form'}">
                          <i
                            [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                            class="fa fa-info-circle text-info"
                            aria-hidden="true"
                            plDelegateEventDownwardsClick
                            (click)="openExtratoCampo('68', false)"></i>
                        </div>
                      </pl-edit>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">10</div>
              <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.desc10'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div class="inputs-currency-inline">
                <div class="row">
                  <div class="col"></div>

                  <div class="col-lg-3">
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">81</div>
                      <pl-edit
                        type="currency"
                        attrName="anexorQuadro6Campo81"
                        [model]="anexorLocal.impostoFavSujeitoPassivo81"
                        (modelChange)="anexorLocal.impostoFavSujeitoPassivo81 = $event; anexoRCalcTotImpFavSujPassivo(local); anexoRCalcTotImpFavSujPassivo(anexoSede)">
                        <div *inputGroup="{klass: 'hollow-form'}">
                          <i
                            [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                            class="fa fa-info-circle text-info"
                            aria-hidden="true"
                            plDelegateEventDownwardsClick
                            (click)="openExtratoCampo('81', false)"></i>
                        </div>
                      </pl-edit>
                    </div>
                  </div>

                  <div class="col-lg-3"></div>
                </div>
              </div>
            </div>
          </section>

          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">11</div>
              <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.desc11'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div class="inputs-currency-inline">
                <div class="row">
                  <div class="col"></div>

                  <div class="col-lg-3">
                    <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field90'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">90</div>
                      <pl-edit type="currency" attrName="anexorQuadro6Campo90" [model]="anexorLocal.totalBaseTributavel" [properties]="{disabled: true}"></pl-edit>
                    </div>
                  </div>

                  <div class="col-lg-3"></div>
                </div>

                <div class="row">
                  <div class="col"></div>

                  <div class="col-lg-3">
                    <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field91'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">91</div>
                      <pl-edit type="currency" attrName="anexorQuadro6Campo90" [model]="anexorLocal.totalImpostoFavSujeitoPassivo" [properties]="{disabled: true}"></pl-edit>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field92'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">92</div>
                      <pl-edit type="currency" attrName="anexorQuadro6Campo92" [model]="anexorLocal.totalImpostoFavEstado" [properties]="{disabled: true}"></pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"></div>

                  <div class="col-lg-3">
                    <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field93'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">93</div>
                      <pl-edit type="currency" attrName="anexorQuadro6Campo93" [model]="anexorLocal.impostoEntregarEstado93" [properties]="{disabled: true}"></pl-edit>
                    </div>
                  </div>

                  <div class="col-lg-3"></div>
                </div>

                <div class="row">
                  <div class="col"></div>

                  <div class="col-lg-3">
                    <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field94'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">94</div>
                      <pl-edit type="currency" attrName="anexorQuadro6Campo94" [model]="anexorLocal.creditoDoImposto94" [properties]="{disabled: true}"></pl-edit>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field95'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">95</div>
                      <pl-edit
                        type="currency"
                        attrName="anexorQuadro6Campo95"
                        [model]="anexorLocal.valorReembolsoSolicitado95"
                        (modelChange)="valorReembolsoSolicitado95Changed($event, anexorLocal)"
                        [properties]="{modelOptions: {debounce: 500}, validators: {min: {value: 0}, max: {value: anexorLocal.creditoDoImposto94}}}">
                        <div *inputGroup="{klass: 'hollow-form'}">
                          <i
                            *ngIf="showTooltipValorReembolsoSolicitado"
                            [title]="'declaracaoiva.anexos.anexoR.extratodecperio' | translate"
                            class="fa fa-info-circle text-warning"
                            aria-hidden="true"></i>
                        </div>
                      </pl-edit>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col"></div>
                  <div class="col-lg-3"></div>

                  <div class="col-lg-3">
                    <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.field96'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">96</div>
                      <pl-edit type="currency" attrName="anexorQuadro6Campo96" [model]="anexorLocal.excessoAReportar96" [properties]="{disabled: true}"></pl-edit>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ng-container>

        <ng-container *ngIf="anexoSede !== local && (continente || acores || madeira)">
          <section class="mt-4">
            <div class="box-title">
              <div class="input-box-numbers-title">6</div>
              <label [translate]="'global.text.total'" class="input-box-numbers-label"></label>
            </div>

            <div class="ms-3 mt-2">
              <div class="inputs-currency-inline">
                <div class="row">
                  <div class="col"></div>

                  <div class="col-lg-3">
                    <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.desc6NotSedeAnexoTotalPassivo'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">63</div>
                      <pl-edit type="currency" attrName="anexorQuadro6Campo63" [model]="anexorLocal.totalImpostoFavSujeitoPassivo" [properties]="{disabled: true}"></pl-edit>
                    </div>
                  </div>

                  <div class="col-lg-3">
                    <label [translate]="'declaracaoiva.anexos.anexoR.quadro6.desc6NotSedeAnexoTotalImposto'"></label>
                    <div class="d-flex">
                      <div class="input-box-numbers-hundred">64</div>
                      <pl-edit type="currency" attrName="anexorQuadro6Campo64" [model]="anexorLocal.totalImpostoFavEstado" [properties]="{disabled: true}"></pl-edit>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="card">
    <h5 class="card-header"><span class="number-title-box">6-A</span>&nbsp;<span class="nav-item-title" [translate]="'declaracaoiva.anexos.anexoR.quadro6A.title'"></span></h5>
    <div class="card-body">
      <div class="inputs-currency-inline">
        <section class="mt-4">
          <div class="box-title">
            <div class="input-box-numbers-title">A</div>
            <label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.descA'" class="input-box-numbers-label"></label>
          </div>

          <div class="ms-3 mt-2">
            <div class="inputs-currency-inline">
              <div class="row">
                <div class="col">
                  <label><span [translate]="'declaracaoiva.anexos.anexoR.quadro6A.efetuadentresident'"></span>:</label>
                </div>
                <div class="col-lg-3"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.basetrib'"></label></div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.field97'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">{{ anexoSede === local ? '97' : '65' }}</div>
                    <pl-edit
                      type="currency"
                      attrName="anexorQuadro6ACampo97"
                      [model]="anexorLocal.baseTributavel97"
                      (modelChange)="anexorLocal.baseTributavel97 = $event; anexoRCalcTotQuadro6A(local)">
                    </pl-edit>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.field98'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-hundred">{{ anexoSede === local ? '98' : '66' }}</div>
                    <pl-edit type="currency" attrName="anexorQuadro6Campo98" [model]="anexorLocal.baseTributavel98" (modelChange)="anexorLocal.baseTributavel98 = $event; anexoRCalcTotQuadro6A(local)">
                    </pl-edit>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="mt-4">
          <div class="box-title">
            <div class="input-box-numbers-title">B</div>
            <label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.descB'" class="input-box-numbers-label"></label>
          </div>

          <div class="ms-3 mt-2">
            <div class="row">
              <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.field99'"></label></div>

              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-hundred">{{ anexoSede === local ? '99' : '67' }}</div>
                  <pl-edit type="currency" attrName="anexorQuadro6ACampo99" [model]="anexorLocal.baseTributavel99" (modelChange)="anexorLocal.baseTributavel99 = $event; anexoRCalcTotQuadro6A(local)">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.field100'"></label></div>

              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-thousand">{{ anexoSede === local ? '100' : '68' }}</div>
                  <pl-edit
                    type="currency"
                    attrName="anexorQuadro6Campo100"
                    [model]="anexorLocal.baseTributavel100"
                    (modelChange)="anexorLocal.baseTributavel100 = $event; anexoRCalcTotQuadro6A(local)">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.field101'"></label></div>

              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-thousand">{{ anexoSede === local ? '101' : '69' }}</div>
                  <pl-edit
                    type="currency"
                    attrName="anexorQuadro6Campo101"
                    [model]="anexorLocal.baseTributavel101"
                    (modelChange)="anexorLocal.baseTributavel101 = $event; anexoRCalcTotQuadro6A(local)">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.field102'"></label></div>

              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-thousand">{{ anexoSede === local ? '102' : '70' }}</div>
                  <pl-edit
                    type="currency"
                    attrName="anexorQuadro6Campo102"
                    [model]="anexorLocal.baseTributavel102"
                    (modelChange)="anexorLocal.baseTributavel102 = $event; anexoRCalcTotQuadro6A(local)">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.field105'"></label></div>

              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-thousand">{{ anexoSede === local ? '105' : '73' }}</div>
                  <pl-edit
                    type="currency"
                    attrName="anexorQuadro6Campo105"
                    [model]="anexorLocal.baseTributavel105"
                    (modelChange)="anexorLocal.baseTributavel105 = $event; anexoRCalcTotQuadro6A(local)">
                  </pl-edit>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.field107'"></label></div>

              <div class="col-lg-3">
                <div class="d-flex">
                  <div class="input-box-numbers-thousand">{{ anexoSede === local ? '107' : '75' }}</div>
                  <pl-edit
                    type="currency"
                    attrName="anexorQuadro6Campo107"
                    [model]="anexorLocal.baseTributavel107"
                    (modelChange)="anexorLocal.baseTributavel107 = $event; anexoRCalcTotQuadro6A(local)">
                  </pl-edit>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="mt-4">
          <div class="box-title">
            <div class="input-box-numbers-title">C</div>
            <label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.descC'" class="input-box-numbers-label"></label>
          </div>

          <div class="ms-3 mt-2">
            <div class="inputs-currency-inline">
              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.field103'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-thousand">{{ anexoSede === local ? '103' : '71' }}</div>
                    <pl-edit
                      type="currency"
                      attrName="anexorQuadro6ACampo103"
                      [model]="anexorLocal.baseTributavel103"
                      (modelChange)="anexorLocal.baseTributavel103 = $event; anexoRCalcTotQuadro6A(local)">
                    </pl-edit>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="mt-4">
          <div class="box-title">
            <div class="input-box-numbers-title">D</div>
            <label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.descD'" class="input-box-numbers-label"></label>
          </div>

          <div class="ms-3 mt-2">
            <div class="inputs-currency-inline">
              <div class="row">
                <div class="col"><label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.field104'"></label></div>

                <div class="col-lg-3">
                  <div class="d-flex">
                    <div class="input-box-numbers-thousand">{{ anexoSede === local ? '104' : '72' }}</div>
                    <pl-edit
                      type="currency"
                      attrName="anexorQuadro6ACampo104"
                      [model]="anexorLocal.baseTributavel104"
                      (modelChange)="anexorLocal.baseTributavel104 = $event; anexoRCalcTotQuadro6A(local)">
                    </pl-edit>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col"></div>

                <div class="col-lg-3">
                  <label [translate]="'declaracaoiva.anexos.anexoR.quadro6A.soma6A'"></label>
                  <div class="d-flex">
                    <div class="input-box-numbers-thousand">{{ anexoSede === local ? '106' : '74' }}</div>
                    <pl-edit type="currency" attrName="anexorQuadro6ACampo106" [model]="anexorLocal.totalQuadroA106" [properties]="{disabled: true}"></pl-edit>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</ng-template>
