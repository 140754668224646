import {IJsonDate} from '../../../../common/interfaces/json';
import {IJsonTIRSDT} from './jsonTabelasIRS.module.interface';

export interface ITIRSDT extends IJsonTIRSDT {
  _index?: number;
}

export interface IDataEscalao extends IJsonDate {
  label?: string;
}

export interface IDataIRS extends IJsonDate {
  label?: string;
}

export const MODULE_NAME_TABELAS_IRS = 'tabelasirs';
