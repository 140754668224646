<div class="rhpenhoras-list">
  <cg-card-panel #cardPanel="cgCardPanel" [collapsed]="true">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <pl-group>
          <label [translate]="'rhpenhoras.fields.penhoras'"></label>
          <edit>
            <pl-autocomplete attrName="estadoPenhora" [(model)]="estadoPenhora" [source]="tipoEscolhaPenhorasDataSource" [rowTemplate]="'label'" [output]="'label'"></pl-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <edit>
            <pl-button klass="btn-sm btn-primary" id="btnPesquisar" [onClick]="pesquisar">
              <i class="fa fa-search fa-fw"></i>&nbsp;
              <span [translate]="'global.btn.search'"></span>
            </pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [dataGridState]="dataGridState"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [filterCollapsed]="true"
    [beforeRequest]="fnBeforeRequest"
    [toolbarInstanceId]="instanceName"
    [properties]="listOptions"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
  </entity-list>
</div>
