<div class="modelo22-anexod-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 *ngIf="is04C1" class="modal-title" [translate]="'modelo22.anexoD.quadro04.desc4C1'"></h5>
    <h5 *ngIf="is04C2" class="modal-title" [translate]="'modelo22.anexoD.quadro04.desc4C2'"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <pl-group *ngIf="is04C2">
        <label [translate]="'modelo22.anexoD.quadro04.table04C2.fields.excessoPeriodoReportar'"></label>
        <edit>
          <pl-edit type="number" attrName="excessoPeriodoReportar" [(model)]="excessoPeriodoReportar"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is04C2">
        <label [translate]="'modelo22.anexoD.quadro04.table04C2.fields.reportePeriodosAnteriores'"></label>
        <edit>
          <pl-edit type="number" attrName="reportePeriodosAnteriores" [(model)]="reportePeriodosAnteriores"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'modelo22.anexoD.quadro04.table04C2.fields.periodo'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="periodo" [(model)]="periodo"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is04C1">
        <label [translate]="'modelo22.anexoD.quadro04.table04C1.fields.aumentoCapitaisProprios'"></label>
        <edit>
          <pl-edit type="number" attrName="aumentoCapitaisProprios" [(model)]="aumentoCapitaisProprios"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is04C1">
        <label [translate]="'modelo22.anexoD.quadro04.table04C1.fields.saidasElegiveisEmDinheiro'"></label>
        <edit>
          <pl-edit type="number" attrName="saidasElegiveisEmDinheiro" [(model)]="saidasElegiveisEmDinheiro"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is04C1">
        <label [translate]="'modelo22.anexoD.quadro04.table04C1.fields.aumentosLiquidosCapitaisProprios'"></label>
        <edit>
          <pl-edit type="number" attrName="aumentosLiquidosCapitaisProprios" [(model)]="aumentosLiquidosCapitaisProprios"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is04C1">
        <label [translate]="'modelo22.anexoD.quadro04.table04C1.fields.somatorioValoresApurados'"></label>
        <edit>
          <pl-edit type="number" attrName="somatorioValoresApurados" [(model)]="somatorioValoresApurados"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is04C1">
        <label [translate]="'modelo22.anexoD.quadro04.table04C1.fields.taxaEuribor'"></label>
        <edit>
          <pl-edit type="number" attrName="taxaEuribor" [(model)]="taxaEuribor"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is04C1">
        <label [translate]="'modelo22.anexoD.quadro04.table04C1.fields.beneficioFiscalPotencial'"></label>
        <edit>
          <pl-edit type="number" attrName="beneficioFiscalPotencial" [(model)]="beneficioFiscalPotencial"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is04C1">
        <label [translate]="'modelo22.anexoD.quadro04.table04C1.fields.beneficioFiscalPotencialMajorado'"></label>
        <edit>
          <pl-edit type="number" attrName="beneficioFiscalPotencialMajorado" [(model)]="beneficioFiscalPotencialMajorado"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is04C1">
        <label [translate]="'modelo22.anexoD.quadro04.table04C1.fields.trintaPercEBITDA'"></label>
        <edit>
          <pl-edit type="number" attrName="trintaPercEBITDA" [(model)]="trintaPercEBITDA"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is04C1">
        <label [translate]="'modelo22.anexoD.quadro04.table04C1.fields.beneficioFiscalPeriodo'"></label>
        <edit>
          <pl-edit type="number" attrName="beneficioFiscalPeriodo" [(model)]="beneficioFiscalPeriodo"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is04C2">
        <label [translate]="'modelo22.anexoD.quadro04.table04C2.fields.montante'"></label>
        <edit>
          <pl-edit type="number" attrName="montante" [(model)]="montante"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is04C2">
        <label [translate]="'modelo22.anexoD.quadro04.table04C2.fields.deducaoPeriodo'"></label>
        <edit>
          <pl-edit type="number" attrName="deducaoPeriodo" [(model)]="deducaoPeriodo"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is04C2">
        <label [translate]="'modelo22.anexoD.quadro04.table04C2.fields.saldoReportar'"></label>
        <edit>
          <pl-edit type="number" attrName="saldoReportar" [(model)]="saldoReportar"></pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"> <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span> </pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"> <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span> </pl-button>
  </div>
</div>
