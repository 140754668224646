import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EMonth, isUndefinedOrNull} from 'pl-comps-angular';
import {ApiService} from '../api/api.service';
import {ETipoDashboard, IDashboardsERP, parseDashboardsERP} from './dashboards.erp.service.interface';
import {IJsonDashboards} from '../../interfaces/jsonDashboards.interface';
import {Moment} from 'moment';
import {monthDateRange} from '../../../common/dates';
import {TServiceResponse} from '../api/api.service.interface';

const TOP_TWELVE = 12;
const TOP_FIVE = 5;

@Injectable({
  providedIn: 'root'
})
export class DashboardsERPService {
  constructor(private readonly _apiService: ApiService) {}

  public clientesEvolucao(tipo: ETipoDashboard, top: number = TOP_TWELVE, ano: number = 0, force: boolean = false): TServiceResponse<IDashboardsERP> {
    return this._requestClientes('evolucao', tipo, top, ano, force);
  }

  public clientesNumero(tipo: ETipoDashboard, top: number = TOP_TWELVE, ano: number = 0, force: boolean = false): TServiceResponse<IDashboardsERP> {
    return this._requestClientes('numero', tipo, top, ano, force);
  }

  public clientesNovos(tipo: ETipoDashboard, top: number = 1, ano: number = 0, force: boolean = false): TServiceResponse<IDashboardsERP> {
    return this._requestClientes('novos', tipo, top, ano, force);
  }

  public clientesTopVendas(top: number = TOP_FIVE, deData?: Moment, ateData?: Moment, force: boolean = false): TServiceResponse<IDashboardsERP> {
    if (isUndefinedOrNull(deData)) {
      deData = monthDateRange(EMonth.January).start;
    }
    if (isUndefinedOrNull(ateData)) {
      ateData = monthDateRange(EMonth.December).end;
    }
    return this._apiService
      .get<IJsonDashboards>({
        url: `${this._apiService.path.restapi}/dashboards/clientes/topvendas/${top}`,
        params: {top: top, deData: deData, ateData: ateData, force: force}
      })
      .then((response: HttpResponse<IJsonDashboards>) => {
        return this._transformValues(ETipoDashboard.ANO, response);
      });
  }

  public vendasTotais(tipo: ETipoDashboard, top: number = TOP_TWELVE, ano: number = 0, force: boolean = false): TServiceResponse<IDashboardsERP> {
    return this._requestVendas('totais', tipo, top, ano, force);
  }

  public vendasTotaisMes(tipo: ETipoDashboard, top: number = 1, ano: number = 0, force: boolean = false): TServiceResponse<IDashboardsERP> {
    return this._requestVendas('totais', tipo, top, ano, force);
  }

  public vendasTotaisAnoTop(top: number = TOP_FIVE, deData?: Moment, ateData?: Moment, force: boolean = false): TServiceResponse<IDashboardsERP> {
    if (isUndefinedOrNull(deData)) {
      deData = monthDateRange(EMonth.January).start;
    }
    if (isUndefinedOrNull(ateData)) {
      ateData = monthDateRange(EMonth.December).end;
    }
    return this._apiService
      .get<IJsonDashboards>({
        url: `${this._apiService.path.restapi}/dashboards/vendas/topartigos/${top}`,
        params: {top: top, deData: deData, ateData: ateData, force: force}
      })
      .then((response: HttpResponse<IJsonDashboards>) => {
        return this._transformValues(ETipoDashboard.ANO, response);
      });
  }

  public fornecedoresEvolucao(tipo: ETipoDashboard, top: number = TOP_TWELVE, ano: number = 0, force: boolean = false): TServiceResponse<IDashboardsERP> {
    return this._requestFornecedores('evolucao', tipo, top, ano, force);
  }

  public fornecedoresNumero(tipo: ETipoDashboard, top: number = TOP_TWELVE, ano: number = 0, force: boolean = false): TServiceResponse<IDashboardsERP> {
    return this._requestFornecedores('numero', tipo, top, ano, force);
  }

  public fornecedoresNovos(tipo: ETipoDashboard, top: number = 1, ano: number = 0, force: boolean = false): TServiceResponse<IDashboardsERP> {
    return this._requestFornecedores('novos', tipo, top, ano, force);
  }

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  public fornecedoresTopCompras(top: number = 5, deData?: Moment, ateData?: Moment, force: boolean = false): TServiceResponse<IDashboardsERP> {
    if (isUndefinedOrNull(deData)) {
      deData = monthDateRange(EMonth.January).start;
    }
    if (isUndefinedOrNull(ateData)) {
      ateData = monthDateRange(EMonth.December).end;
    }
    return this._apiService
      .get<IJsonDashboards>({
        url: `${this._apiService.path.restapi}/dashboards/fornecedores/topcompras/${top}`,
        params: {dedata: deData, atedata: ateData, force: force}
      })
      .then((response: HttpResponse<IJsonDashboards>) => {
        return this._transformValues(ETipoDashboard.ANO, response);
      });
  }

  public comprasTotais(tipo: ETipoDashboard, top: number = TOP_TWELVE, ano: number = 0, force: boolean = false): TServiceResponse<IDashboardsERP> {
    return this._requestCompras('totais', tipo, top, ano, force);
  }

  public comprasTotaisMes(tipo: ETipoDashboard, top: number = 1, ano: number = 0, force: boolean = false): TServiceResponse<IDashboardsERP> {
    return this._requestCompras('totais', tipo, top, ano, force);
  }

  public comprasTotaisAnoTop(top: number = TOP_FIVE, deData?: Moment, ateData?: Moment, force: boolean = false): TServiceResponse<IDashboardsERP> {
    if (isUndefinedOrNull(deData)) {
      deData = monthDateRange(EMonth.January).start;
    }
    if (isUndefinedOrNull(ateData)) {
      ateData = monthDateRange(EMonth.December).end;
    }
    return this._apiService
      .get<IJsonDashboards>({
        url: `${this._apiService.path.restapi}/dashboards/compras/topartigos/${top}`,
        params: {top: top, dedata: deData, atedata: ateData, force: force}
      })
      .then((response: HttpResponse<IJsonDashboards>) => {
        return this._transformValues(ETipoDashboard.ANO, response);
      });
  }

  private _requestClientes(endpoint: string, tipo: ETipoDashboard, top: number, ano: number, force: boolean): TServiceResponse<IDashboardsERP> {
    return this._request('clientes', endpoint, tipo, top, ano, force);
  }

  private _requestVendas(endpoint: string, tipo: ETipoDashboard, top: number, ano: number, force: boolean): TServiceResponse<IDashboardsERP> {
    return this._request('vendas', endpoint, tipo, top, ano, force);
  }

  private _requestFornecedores(endpoint: string, tipo: ETipoDashboard, top: number, ano: number, force: boolean): TServiceResponse<IDashboardsERP> {
    return this._request('fornecedores', endpoint, tipo, top, ano, force);
  }

  private _requestCompras(endpoint: string, tipo: ETipoDashboard, top: number, ano: number, force: boolean): TServiceResponse<IDashboardsERP> {
    return this._request('compras', endpoint, tipo, top, ano, force);
  }

  private _request(endpoint: string, subendpoint: string, tipo: ETipoDashboard, top: number, ano: number, force: boolean): TServiceResponse<IDashboardsERP> {
    return this._apiService
      .get<IJsonDashboards>({
        url: `${this._apiService.path.restapi}/dashboards/${endpoint}/${subendpoint}/${tipo}`,
        params: {top: top, ano: ano, force: force}
      })
      .then((response: HttpResponse<IJsonDashboards>) => this._transformValues(tipo, response));
  }

  private _transformValues(tipo: ETipoDashboard, response: HttpResponse<IJsonDashboards>): HttpResponse<IDashboardsERP> {
    if (tipo !== ETipoDashboard.MES) {
      return response;
    }
    return response.clone({body: parseDashboardsERP(response.body)});
  }
}
