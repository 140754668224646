import {Transition} from '@uirouter/core';
import {isObject} from 'pl-comps-angular';
import {ConfigService} from '../../services/config/config.service';
import {DocsComerciaisEditComponent} from './components/edit/docsComerciais.entity.edit.component';
import {DocsComerciaisListComponent} from './components/list/docsComerciais.entity.list.component';
import {EGrupoDoc} from '../../datasources/grupodoc/grupoDoc.datasource.interface';
import {
  ENTITY_NAME_DOCS_COMERCIAIS,
  ENTITY_NAME_DOCS_COMERCIAIS_COMPRAS,
  ENTITY_NAME_DOCS_COMERCIAIS_COMPRAS_AUTOFATURACAO,
  ENTITY_NAME_DOCS_COMERCIAIS_CONSULTAS_A_FORNECEDORES,
  ENTITY_NAME_DOCS_COMERCIAIS_ENCOMENDAS_CLIENTES,
  ENTITY_NAME_DOCS_COMERCIAIS_ENCOMENDAS_FORNECEDORES,
  ENTITY_NAME_DOCS_COMERCIAIS_ENTRADAS_DIVERSAS,
  ENTITY_NAME_DOCS_COMERCIAIS_GUIAS,
  ENTITY_NAME_DOCS_COMERCIAIS_GUIAS_FORNECEDORES,
  ENTITY_NAME_DOCS_COMERCIAIS_PROPOSTAS_A_CLIENTES,
  ENTITY_NAME_DOCS_COMERCIAIS_SAIDAS_DIVERSAS,
  ENTITY_NAME_DOCS_COMERCIAIS_VENDAS,
  IDocComercialEditStateParams,
  IDocsComerciaisEntity,
  IDocsComerciaisGenerateEntityData,
  IDocsComerciaisGroup
} from './docsComerciais.entity.interface';
import {ETipoComunicacaoEFatura} from './jsonDocComercial.entity.interface';
import {IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IModuleHelperLink} from '../../components/module/module.definition.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_MOEDA} from '../../modules/portalcontabilidade/manutencao/moeda/moeda.entity.interface';
import {RESOLVER_CONTABILIDADE_DIGITAL, RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL} from '../../services/contabilidadedigital/contabilidadedigital.router.interface';
import {RESOLVER_CG_STORE_URL} from '../../../config/uirouter/uirouter.resolvers';

export const ENTITY_DOCS_COMERCIAIS: IDocsComerciaisEntity = generateEntityDocsComerciais({
  name: ENTITY_NAME_DOCS_COMERCIAIS,
  grupo: EGrupoDoc.Outros,
  clifos: 'clifos',
  resolve: (config: ConfigService) => {
    return {
      nDocFa: config.configurations.documentosComerciais.tipos.outros,
      clifos: 'clifos'
    };
  }
});

export const ENTITY_DOCS_COMERCIAIS_COMPRAS: IDocsComerciaisEntity = generateEntityDocsComerciais({
  name: ENTITY_NAME_DOCS_COMERCIAIS_COMPRAS,
  grupo: EGrupoDoc.ComprasEfectivas,
  clifos: 'fornecedores',
  metadata: {
    listFields: 'nDoc,dataDoc,nDocFa,nNumer,nDocumento,nome,dataVenc,nif,porPagar,totalDocumentoME,abreviaturaMoeda,transformado,anulado,nFactFornec,dataDocExterno,observacoes',
    searchFields: 'dataDoc,nome,nif,porPagar,totalDocumentoME,abreviaturaMoeda,nDocFa,nFactFornec,dataDocExterno'
  },
  resolve: (config: ConfigService) => {
    return {
      nDocFa: config.configurations.documentosComerciais.tipos.comprasEfectivas,
      clifos: 'fornecedores'
    };
  }
});

export const ENTITY_DOCS_COMERCIAIS_VENDAS: IDocsComerciaisEntity = generateEntityDocsComerciais({
  name: ENTITY_NAME_DOCS_COMERCIAIS_VENDAS,
  grupo: EGrupoDoc.VendasEfectivas,
  clifos: 'clientes',
  metadata: {
    listFields: 'nDoc,dataDoc,nDocFa,nNumer,nDocumento,nome,dataVenc,nif,comunicadoAoEFatura,tipoComunicacao,porPagar,totalDocumentoME,abreviaturaMoeda,transformado,anulado,nFactFornec,observacoes',
    searchFields: 'nDoc,dataDoc,nome,nif,porPagar,totalDocumentoME,abreviaturaMoeda,nDocFa,nFactFornec'
  },
  resolve: (config: ConfigService) => {
    return {
      nDocFa: config.configurations.documentosComerciais.tipos.vendasEfectivas,
      clifos: 'clientes'
    };
  }
});

export const ENTITY_DOCS_COMERCIAIS_GUIAS: IDocsComerciaisEntity = generateEntityDocsComerciais({
  name: ENTITY_NAME_DOCS_COMERCIAIS_GUIAS,
  grupo: EGrupoDoc.GuiasTransporteRemessa,
  clifos: 'clientes',
  metadata: {
    listFields: 'nDoc,dataDoc,nDocFa,nNumer,nDocumento,nome,nif,estadoEnvioStr,modoEnvio,transformado,anulado,stampUpdate',
    searchFields: 'dataDoc,nome,nif'
  },
  resolve: (config: ConfigService) => {
    return {
      nDocFa: config.configurations.documentosComerciais.tipos.guiasTransporteRemessa,
      clifos: 'clientes'
    };
  }
});

export const ENTITY_DOCS_COMERCIAIS_GUIAS_FORNECEDORES: IDocsComerciaisEntity = generateEntityDocsComerciais({
  name: ENTITY_NAME_DOCS_COMERCIAIS_GUIAS_FORNECEDORES,
  grupo: EGrupoDoc.GuiasTransporteFornecedores,
  clifos: 'fornecedores',
  sidebarTitle: 'global.menu.guias',
  metadata: {
    listFields: 'nDoc,dataDoc,nDocFa,nNumer,nDocumento,nome,nif,estadoEnvioStr,modoEnvio,transformado,anulado,nFactFornec,dataDocExterno,stampUpdate',
    searchFields: 'dataDoc,nome,nif'
  },
  resolve: (config: ConfigService) => {
    return {
      nDocFa: config.configurations.documentosComerciais.tipos.guiasTransporteFornecedores,
      clifos: 'fornecedores'
    };
  }
});

export const ENTITY_DOCS_COMERCIAIS_ENTRADASDIVERSAS: IDocsComerciaisEntity = generateEntityDocsComerciais({
  name: ENTITY_NAME_DOCS_COMERCIAIS_ENTRADAS_DIVERSAS,
  grupo: EGrupoDoc.EntradasDiversas,
  clifos: 'fornecedores',
  metadata: {
    listFields: 'nDoc,dataDoc,totalDocumento,stampUpdate',
    searchFields: 'dataDoc'
  },
  resolve: (config: ConfigService) => {
    return {
      nDocFa: config.configurations.documentosComerciais.tipos.entradasDiversas,
      clifos: 'fornecedores'
    };
  }
});

export const ENTITY_DOCS_COMERCIAIS_SAIDASDIVERSAS: IDocsComerciaisEntity = generateEntityDocsComerciais({
  name: ENTITY_NAME_DOCS_COMERCIAIS_SAIDAS_DIVERSAS,
  grupo: EGrupoDoc.SaidasDiversas,
  clifos: 'clientes',
  metadata: {
    listFields: 'nDoc,dataDoc,totalDocumento,stampUpdate',
    searchFields: 'dataDoc'
  },
  resolve: (config: ConfigService) => {
    return {
      nDocFa: config.configurations.documentosComerciais.tipos.saidasDiversas,
      clifos: 'clientes'
    };
  }
});

export const ENTITY_DOCS_COMERCIAIS_PROPOSTASACLIENTES: IDocsComerciaisEntity = generateEntityDocsComerciais({
  name: ENTITY_NAME_DOCS_COMERCIAIS_PROPOSTAS_A_CLIENTES,
  grupo: EGrupoDoc.PropostasAClientes,
  clifos: 'clientes',
  resolve: (config: ConfigService) => {
    return {
      nDocFa: config.configurations.documentosComerciais.tipos.propostasaClientes,
      clifos: 'clientes'
    };
  }
});

export const ENTITY_DOCS_COMERCIAIS_ENCOMENDASCLIENTES: IDocsComerciaisEntity = generateEntityDocsComerciais({
  name: ENTITY_NAME_DOCS_COMERCIAIS_ENCOMENDAS_CLIENTES,
  grupo: EGrupoDoc.EncomendasClientes,
  clifos: 'clientes',
  sidebarTitle: 'global.menu.encomendas',
  metadata: {
    listFields: 'nDoc,dataDoc,nDocFa,nNumer,nDocumento,nome,nif,totalDocumento,abreviaturaMoeda,transformado,anulado,stampUpdate',
    searchFields: 'dataDoc,nome,nif'
  },
  resolve: (config: ConfigService) => {
    return {
      nDocFa: config.configurations.documentosComerciais.tipos.encomendasClientes,
      clifos: 'clientes'
    };
  }
});

export const ENTITY_DOCS_COMERCIAIS_ENCOMENDASFORNECEDORES: IDocsComerciaisEntity = generateEntityDocsComerciais({
  name: ENTITY_NAME_DOCS_COMERCIAIS_ENCOMENDAS_FORNECEDORES,
  grupo: EGrupoDoc.EncomendasFornecedores,
  clifos: 'fornecedores',
  sidebarTitle: 'global.menu.encomendas',
  metadata: {
    listFields: 'nDoc,dataDoc,nDocFa,nNumer,nDocumento,nome,nif,totalDocumento,abreviaturaMoeda,transformado,anulado,nFactFornec,dataDocExterno,stampUpdate',
    searchFields: 'dataDoc,nome,nif'
  },
  resolve: (config: ConfigService) => {
    return {
      nDocFa: config.configurations.documentosComerciais.tipos.encomendasFornecedores,
      clifos: 'fornecedores'
    };
  }
});

export const ENTITY_DOCS_COMERCIAIS_COMPRAS_AUTOFATURACAO: IDocsComerciaisEntity = generateEntityDocsComerciais({
  name: ENTITY_NAME_DOCS_COMERCIAIS_COMPRAS_AUTOFATURACAO,
  grupo: EGrupoDoc.ComprasEfectivas,
  clifos: 'fornecedores',
  metadata: {
    listFields: 'nDoc,dataDoc,nDocFa,nNumer,nDocumento,nome,nif,totalDocumento,abreviaturaMoeda,transformado,anulado,stampUpdate',
    searchFields: 'dataDoc,nome,nif'
  },
  resolve: (config: ConfigService) => {
    return {
      nDocFa: config.configurations.documentosComerciais.tipos.none,
      clifos: 'fornecedores',
      properties: {
        autofaturacao: true
      }
    };
  }
});

export const ENTITY_DOCS_COMERCIAIS_CONSULTASAFORNECEDORES: IDocsComerciaisEntity = generateEntityDocsComerciais({
  name: ENTITY_NAME_DOCS_COMERCIAIS_CONSULTAS_A_FORNECEDORES,
  grupo: EGrupoDoc.ConsultasAFornecedores,
  clifos: 'fornecedores',
  resolve: (config: ConfigService) => {
    return {
      nDocFa: config.configurations.documentosComerciais.tipos.consultasaFornecedores,
      clifos: 'fornecedores'
    };
  }
});

export function docsComerciaisGrupoDocToEntity(grupoDoc: EGrupoDoc): IDocsComerciaisEntity {
  switch (grupoDoc) {
    case EGrupoDoc.Outros:
      return ENTITY_DOCS_COMERCIAIS;
    case EGrupoDoc.ComprasEfectivas:
      return ENTITY_DOCS_COMERCIAIS_COMPRAS;
    case EGrupoDoc.ConsultasAFornecedores:
      return ENTITY_DOCS_COMERCIAIS_CONSULTASAFORNECEDORES;
    case EGrupoDoc.EncomendasClientes:
      return ENTITY_DOCS_COMERCIAIS_ENCOMENDASCLIENTES;
    case EGrupoDoc.EncomendasFornecedores:
      return ENTITY_DOCS_COMERCIAIS_ENCOMENDASFORNECEDORES;
    case EGrupoDoc.FolhasDeObra:
      return ENTITY_DOCS_COMERCIAIS_COMPRAS;
    case EGrupoDoc.GuiasTransporteRemessa:
      return ENTITY_DOCS_COMERCIAIS_GUIAS;
    case EGrupoDoc.PropostasAClientes:
      return ENTITY_DOCS_COMERCIAIS_PROPOSTASACLIENTES;
    case EGrupoDoc.ReservasClientes:
      return ENTITY_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.TrfArmazensEntradas:
      return ENTITY_DOCS_COMERCIAIS;
    case EGrupoDoc.TrfArmazensSaidas:
      return ENTITY_DOCS_COMERCIAIS;
    case EGrupoDoc.VendasEfectivas:
      return ENTITY_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.ConsignacoesClientes:
      return ENTITY_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.ConsignacoesFornecedores:
      return ENTITY_DOCS_COMERCIAIS_COMPRAS;
    case EGrupoDoc.RecibosClientes:
      return ENTITY_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.PagamentosFornecedores:
      return ENTITY_DOCS_COMERCIAIS_COMPRAS;
    case EGrupoDoc.None:
      return ENTITY_DOCS_COMERCIAIS;
    case EGrupoDoc.PlanoProducao:
      return ENTITY_DOCS_COMERCIAIS;
    case EGrupoDoc.PreEncomendasClientes:
      return ENTITY_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.CustosAdicionais:
      return ENTITY_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.NotaCredito:
      return ENTITY_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.AcertoCustoPadrao:
      return ENTITY_DOCS_COMERCIAIS_VENDAS;
    case EGrupoDoc.EntradasDiversas:
      return ENTITY_DOCS_COMERCIAIS_ENTRADASDIVERSAS;
    case EGrupoDoc.SaidasDiversas:
      return ENTITY_DOCS_COMERCIAIS_SAIDASDIVERSAS;
    case EGrupoDoc.GuiasTransporteFornecedores:
      return ENTITY_DOCS_COMERCIAIS_GUIAS_FORNECEDORES;
    default:
      return ENTITY_DOCS_COMERCIAIS;
  }
}

function generateEntityDocsComerciais(grupo: IDocsComerciaisGenerateEntityData): IDocsComerciaisEntity {
  const definition: IDocsComerciaisEntity = {
    name: grupo.name,
    url: 'docscomerciais',
    roles: [ROLE.ERP],
    searchPlaceholder: 'docscomerciais.pesquisa',
    sidebarTitle: grupo.sidebarTitle,
    metadata: {
      keyName: 'faccbId',
      descriptionName: 'cab.nDoc',
      fields: [
        {name: 'nDocFa', caption: 'docscomerciais.fields.nDocFa', visible: false},
        {name: 'nNumer', caption: 'docscomerciais.fields.nNumer', visible: false},
        {name: 'nDocumento', caption: 'docscomerciais.fields.nDocumento', visible: false},
        {name: 'nDoc', width: '100px', align: 'right', caption: 'docscomerciais.fields.nDocFilter', properties: {devExpress: {dataGrid: {caption: 'docscomerciais.fields.nDoc'}}}},
        {name: 'nConta', type: 'clifos', caption: 'docscomerciais.fields.nConta'},
        {name: 'nIdAltern', caption: 'docscomerciais.fields.nIdAltern', visible: false},
        {name: 'nome', caption: `docscomerciais.fields.nome.${grupo.clifos}`},
        {name: 'nif', caption: 'docscomerciais.fields.nif'},
        {name: 'telefone', caption: 'docscomerciais.clifo.telefone'},
        {name: 'rua', caption: 'docscomerciais.clifo.rua'},
        {name: 'codPostal', caption: 'docscomerciais.clifo.codPostal'},
        {name: 'localidade', caption: 'docscomerciais.clifo.localidade'},
        {name: 'email', caption: 'docscomerciais.clifo.email'},
        {name: 'dataDoc', type: 'date', caption: 'global.text.date'},
        {name: 'dataVenc', type: 'date', caption: 'docscomerciais.fields.dataVenc', visible: false},
        {name: 'estado', align: 'center', caption: 'docscomerciais.fields.estado', properties: {devExpress: {dataGrid: {cssClass: 'estado'}}}},
        {
          name: 'porPagar',
          type: 'double',
          caption: grupo.name === 'vendas' ? 'docscomerciais.fields.porReceber' : 'docscomerciais.fields.porPagar',
          properties: {devExpress: {dataGrid: {allowSearch: false, allowFiltering: false, allowHeaderFiltering: true}}}
        },
        {name: 'totalDocumento', type: 'double', align: 'right', caption: 'global.text.total'},
        {name: 'totalDocumentoME', type: 'double', align: 'right', caption: 'global.text.total'},
        {name: 'stampUpdate', type: 'date', caption: 'docscomerciais.fields.stampUpdate'},
        {name: 'codRet', type: 'text', caption: 'docscomerciais.fields.codRet'},
        {name: 'taxaRet', type: 'double', caption: 'docscomerciais.fields.taxaRet'},
        {name: 'retencaoIncidencia', type: 'double', caption: 'docscomerciais.fields.retencaoIncidencia'},
        {name: 'retencaoValor', type: 'double', caption: 'docscomerciais.fields.retencaoValor'},
        {name: 'retencaoIncidArred', type: 'double', caption: 'docscomerciais.fields.retencaoIncidArred'},
        {name: 'modoEnvio', type: 'text', caption: 'docscomerciais.fields.modoEnvio'},
        {name: 'estadoEnvio', type: 'integer', caption: 'docscomerciais.fields.estadoEnvio'},
        {name: 'estadoEnvioStr', type: 'text', caption: 'docscomerciais.fields.estadoEnvioStr'},
        {name: 'dataEstado', type: 'date', caption: 'docscomerciais.fields.dataEstado'},
        {name: 'horaEstado', type: 'time', caption: 'docscomerciais.fields.horaEstado'},
        {name: 'returnCode', caption: 'docscomerciais.fields.returnCode'},
        {name: 'returnMessage', type: 'text', caption: 'docscomerciais.fields.returnMessage'},
        {name: 'atDocCodeID', type: 'text', caption: 'docscomerciais.fields.atDocCodeID'},
        {name: 'nCCusto', caption: 'docscomerciais.fields.nCCusto'},
        {name: 'codMoeda', type: 'moedas', visible: false},
        {
          name: 'nomeMoeda',
          caption: 'docscomerciais.fields.nomeMoeda',
          entity: {name: ENTITY_NAME_MOEDA, keyTarget: 'codMoeda', outputTarget: 'nomeMoeda'}
        },
        {name: 'abreviaturaMoeda', type: 'text', align: 'center', caption: 'docscomerciais.fields.nomeMoeda'},
        {
          name: 'comunicadoAoEFatura',
          caption: 'docscomerciais.doc.cab.comunicadoAoEFatura',
          align: 'center',
          properties: {
            devExpress: {
              dataGrid: {
                allowSearch: false,
                allowSorting: false,
                allowFiltering: false,
                customizeText: (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => {
                  if (cellInfo.target === 'row') {
                    if (cellInfo.value) {
                      return 'Comunicado AT';
                    }
                    return 'Por comunicar';
                  }
                  return cellInfo.valueText;
                }
              }
            }
          }
        },
        {
          name: 'tipoComunicacao',
          caption: 'docscomerciais.doc.cab.tipoComunicacao',
          type: 'number',
          align: 'center',
          properties: {
            devExpress: {
              dataGrid: {
                allowSearch: false,
                allowSorting: false,
                allowFiltering: false,
                allowHeaderFiltering: true,
                customizeText: (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => {
                  switch (cellInfo.value) {
                    case ETipoComunicacaoEFatura.None:
                      return 'Nenhum';
                    case ETipoComunicacaoEFatura.SAFT:
                      return 'SAFT';
                    case ETipoComunicacaoEFatura.WebService:
                      return 'Web-Service';
                    default:
                      return cellInfo.valueText;
                  }
                }
              }
            }
          }
        },
        {name: 'transformado', type: 'boolean', caption: 'docscomerciais.fields.transformado', properties: {devExpress: {dataGrid: {visible: false, showInColumnChooser: true}}}},
        {name: 'anulado', type: 'boolean', caption: 'docscomerciais.fields.anulado', properties: {devExpress: {dataGrid: {visible: false, showInColumnChooser: true}}}},
        {name: 'nFactFornec', caption: 'docscomerciais.fields.nFactFornec', properties: {devExpress: {dataGrid: {visible: grupo.clifos === 'fornecedores', showInColumnChooser: true}}}},
        {
          name: 'dataDocExterno',
          type: 'date',
          caption: 'docscomerciais.fields.dataDocExterno',
          properties: {devExpress: {dataGrid: {visible: grupo.clifos === 'fornecedores', showInColumnChooser: true}}}
        },
        {name: 'observacoes', caption: 'docscomerciais.fields.observacoes', properties: {devExpress: {dataGrid: {visible: false, showInColumnChooser: true}}}}
      ],
      order: 'dataDoc desc',
      listFields: 'nDoc,dataDoc,nome,nif,porPagar,totalDocumentoME,abreviaturaMoeda,transformado,anulado',
      searchFields: 'dataDoc,nome,nif'
    },
    actions: {
      new: true,
      edit: true,
      detail: false,
      delete: false,
      search: true,
      filter: true
    },
    list: {
      state: {
        component: DocsComerciaisListComponent,
        resolve: [
          {
            provide: 'group',
            deps: [ConfigService],
            useFactory: (config: ConfigService): IDocsComerciaisGroup => {
              return {
                grupo: grupo.grupo,
                ...(<IDocsComerciaisGroup>grupo.resolve(config))
              };
            }
          }
        ]
      }
    },
    new: {
      state: {
        component: DocsComerciaisEditComponent,
        params: {
          doc: {
            type: 'any'
          },
          adiantamentos: {
            type: 'any'
          },
          grupo: {
            type: 'any',
            value: undefined
          }
        },
        resolve: [
          {
            provide: 'model',
            deps: [Transition],
            useFactory: (transition: Transition) => {
              const params: IDocComercialEditStateParams = <IDocComercialEditStateParams>transition.params();
              if (isObject(params.doc)) {
                return params.doc;
              }
              return undefined;
            }
          },
          RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL,
          RESOLVER_CONTABILIDADE_DIGITAL,
          RESOLVER_CG_STORE_URL
        ]
      }
    },
    detail: {
      state: {
        component: DocsComerciaisEditComponent,
        resolve: [
          {
            provide: 'group',
            deps: [ConfigService],
            useFactory: (config: ConfigService): IDocsComerciaisGroup => {
              return {
                grupo: grupo.grupo,
                ...(<IDocsComerciaisGroup>grupo.resolve(config))
              };
            }
          },
          RESOLVER_EMPRESA_TEM_CONTABILIDADE_DIGITAL,
          RESOLVER_CONTABILIDADE_DIGITAL,
          RESOLVER_CG_STORE_URL
        ],
        params: {
          grupo: {
            type: 'any',
            value: undefined
          }
        }
      }
    },
    service: function () {
      this.getByDraft = function (nDocFaDraft: number, nNumerDraft: number, nDocumentoDraft: number) {
        return this.get({
          id: 'bydraft',
          params: {
            nDocFaDraft: nDocFaDraft,
            nNumerDraft: nNumerDraft,
            nDocumentoDraft: nDocumentoDraft
          }
        });
      };

      this.sendEmail = function (faccbId: number, nvias: number, rptname: string, segundavia: boolean, email: string, forceoriginal: boolean) {
        return this.get({
          id: `${faccbId}/email`,
          params: {
            nvias: nvias,
            rptname: rptname,
            segundavia: segundavia,
            email: email,
            forceoriginal: forceoriginal
          }
        });
      };
      this.getDocContabId = function (faccbId: number) {
        return this.get({
          id: `${faccbId}/doccontabid`
        });
      };
    }
  };
  if (grupo.metadata) {
    definition.metadata = {...definition.metadata, ...grupo.metadata};
  }
  if (grupo.helperLinks) {
    definition.helperLinks = (<Array<IModuleHelperLink>>definition.helperLinks).concat(grupo.helperLinks);
  }
  if (grupo.grupo === EGrupoDoc.VendasEfectivas || grupo.grupo === EGrupoDoc.ComprasEfectivas) {
    if (!isObject(definition.list.state.params)) {
      definition.list.state.params = {};
    }
  }
  return definition;
}
