<div class="calculo-diferimentos-info-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'calculodiferimentos.modals.info.title'"></h5>
  </div>

  <div class="modal-body">
    <h6 class="fw-bold text-center">{{ infoDiferimentos.descricao }}</h6>
    <hr />
    <pl-tabs [theme]="'style3'">
      <pl-tab id="infoDiferimentosTotaisTab" caption="calculodiferimentos.modals.info.infomes">
        <div *plTabContent>
          <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTotais" cgDxDataGridInstanceName="infoDiferimentosTotais" [dataSource]="infoDiferimentos.totais" (onCellClick)="onCellClick($event)">
            <div *dxTemplate="let detailInfo of 'masterDetailTemplateTotais'">
              <documento-contabilidade [(doc)]="detailInfo.data._docContabilidade" [showToolbar]="true" [maintenanceMode]="true"></documento-contabilidade>
            </div>
          </dx-data-grid>
        </div>
      </pl-tab>

      <pl-tab id="infoDiferimentosDetalhesTab" caption="calculodiferimentos.modals.info.infodetalhada">
        <div *plTabContent>
          <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDetalhes" cgDxDataGridInstanceName="infoDiferimentosDetalhes" [dataSource]="infoDiferimentos.detalhes" (onCellClick)="onCellClick($event)">
            <div *dxTemplate="let detailInfo of 'masterDetailTemplateDetalhes'">
              <documento-contabilidade [(doc)]="detailInfo.data._docContabilidade" [showToolbar]="true" [maintenanceMode]="true"></documento-contabilidade>
            </div>
          </dx-data-grid>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
