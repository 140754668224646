import {JSONSchema} from '@ngx-pwa/local-storage';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonDocComercial} from '../docscomerciais/jsonDocComercial.entity.interface';
import {IJsonPagamento, IJsonPagamentoCab, IJsonPagamentoLinha} from './jsonPagamento.entity.interface';
import moment from 'moment';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {TTableLegend} from '../../components/tablelegend/tablelegend.component.interface';
import {deepFreeze} from 'pl-comps-angular';

export interface IPagamentosEntityService extends IEntityService<IJsonPagamento> {
  sendEmail(extpoccabID: string, nconta: string, email: string, ano: number): TServiceResponse<void>;
}

export interface IPagamentosEntity extends IEntityDefinition<IJsonPagamento, IPagamentosEntityService> {}

export interface IPagamentoCabWithData extends IJsonPagamentoCab {
  _pagamento?: IJsonPagamento;
}

export interface IPagamentoLinha extends IJsonPagamentoLinha {
  _thedoc?: IJsonDocComercial;
}

export interface IPagamento extends IJsonPagamento {
  linhas: Array<IPagamentoLinha>;
}

export enum EEstadoSEPA {
  None,
  Emitido,
  FicheiroGerado
}

export enum EPagamentoTipoDistribuicao {
  Automatico,
  Manual
}

export interface IPagamentoDistribuirValor {
  valor: number;
  tipoDistribuicao: EPagamentoTipoDistribuicao;
}

export const ENTITY_NAME_PAGAMENTOS = 'pagamentos';

export const NDECIMAIS_CAMBIO_PAGAMENTOS_DEFAULT = 5;

export const schemaIJsonPagamentoCab: JSONSchema = {
  type: 'object',
  properties: {
    extPocCabID: {
      type: 'string'
    },
    numero: {
      type: 'string'
    },
    nConta: {
      type: 'string'
    },
    descricao: {
      type: 'string'
    },
    nome: {
      type: 'string'
    },
    nif: {
      type: 'string'
    },
    email: {
      type: 'string'
    },
    data: {
      type: 'string'
    },
    valor: {
      type: 'number'
    },
    stampUpdate: {
      type: 'string'
    },
    totalDescontos: {
      type: 'number'
    },
    totalPagar: {
      type: 'number'
    },
    total: {
      type: 'number'
    },
    origem: {
      type: 'number'
    },
    totalRetencao: {
      type: 'number'
    },
    temRetencao: {
      type: 'boolean'
    },
    codMoeda: {
      type: 'number'
    },
    abreviaturaMoeda: {
      type: 'string'
    }
  }
};

export const schemaIJsonPagamentoLinha: JSONSchema = {
  type: 'object',
  properties: {
    nlanc: {
      type: 'string'
    },
    tipoDocumento: {
      type: 'string'
    },
    numeroDocumento: {
      type: 'string'
    },
    nDocExterno: {
      type: 'string'
    },
    dataVencimento: {
      type: 'string'
    },
    valor: {
      type: 'number'
    },
    valorPorPagar: {
      type: 'number'
    },
    percDesconto: {
      type: 'number'
    },
    valorDesconto: {
      type: 'number'
    },
    valorAPagar: {
      type: 'number'
    },
    valorIVA: {
      type: 'number'
    },
    montanteAReter: {
      type: 'number'
    },
    montanteRetido: {
      type: 'number'
    },
    codMoeda: {
      type: 'number'
    },
    abreviaturaMoeda: {
      type: 'string'
    },
    cambioOrigem: {
      type: 'number'
    },
    cambioPagamento: {
      type: 'number'
    }
  }
};

export const schemaIJsonPagamentoLinhas: JSONSchema = {
  type: 'array',
  items: schemaIJsonPagamentoLinha
};

export const schemaIJsonPagamentoMeioPagamento: JSONSchema = {
  type: 'object',
  properties: {
    nCaixa: {
      type: 'number'
    },
    nomeCaixa: {
      type: 'string'
    },
    valor: {
      type: 'number'
    }
  }
};

export const schemaIJsonPagamentoMeioPagamentos: JSONSchema = {
  type: 'array',
  items: schemaIJsonPagamentoMeioPagamento
};

export const schemaIJsonPagamento: JSONSchema = {
  type: 'object',
  properties: {
    cab: schemaIJsonPagamentoCab,
    linhas: schemaIJsonPagamentoLinhas,
    meiosPagamento: schemaIJsonPagamentoMeioPagamentos,
    percDescSobreValorIVA: {
      type: 'boolean'
    },
    totalCaixa: {
      type: 'number'
    },
    troco: {
      type: 'number'
    }
  }
};

export enum EPagamentosTableLegendColors {
  PagamentoAnulado = 'pagamento-anulado'
}

export const TABLE_LEGEND_PAGAMENTOS: TTableLegend = deepFreeze([{caption: 'pagamentos.fields.anulado', badgeCSSClass: EPagamentosTableLegendColors.PagamentoAnulado}]);

export function pagamentoDefault(): Required<IPagamento> {
  return {
    cab: {
      extPocCabID: '',
      numero: '',
      nConta: '',
      descricao: '',
      nome: '',
      nif: '',
      email: '',
      data: moment(),
      valor: 0,
      stampUpdate: moment(),
      totalDescontos: 0,
      totalPagar: 0,
      total: 0,
      origem: 0,
      totalRetencao: 0,
      temRetencao: false,
      codMoeda: 0,
      abreviaturaMoeda: '',
      nDocExterno: '',
      estadoSEPA: EEstadoSEPA.None,
      anulado: false
    },
    linhas: [],
    meiosPagamento: [],
    percDescSobreValorIVA: false,
    totalCaixa: 0,
    troco: 0,
    anoPagamento: 0
  };
}

export interface IPagamentosParams {
  nConta: string;
  nome: string;
}
