<div class="contabilidade-predefinidos-linhas entity-detail-form">
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <div class="contabilidade-predefinidos-linhas-actions" *ngIf="!previewOnly">
        <button type="button" class="btn btn-xs btn-success" (click)="addEmptyLine()" name="addEmptyLine">
          <i class="fa fa-fw fa-plus"></i><span [translate]="'predefinidocontablinhas.addLine'"></span>
        </button>

        <button type="button" class="btn btn-xs btn-primary" (click)="duplicateLine()" name="duplicateLine" [disabled]="!selectedLine">
          <i class="fa fa-fw fa-files-o"></i><span [translate]="'predefinidocontablinhas.duplicateLine'"></span>
        </button>

        <button type="button" class="btn btn-xs btn-primary" (click)="moveLineUp()" name="moveLineUp" [disabled]="!selectedLine || selectedLine === linhas[0]">
          <i class="fa fa-fw fa-angle-up"></i><span [translate]="'predefinidocontablinhas.moveLineUp'"></span>
        </button>

        <button type="button" class="btn btn-xs btn-primary" (click)="moveLineDown()" [disabled]="!selectedLine || selectedLine === linhas[linhas.length - 1]" name="moveLineDown">
          <i class="fa fa-fw fa-angle-down"></i><span [translate]="'predefinidocontablinhas.moveLineDown'"></span>
        </button>

        <button type="button" class="btn btn-xs btn-danger" (click)="removeLine()" [disabled]="!selectedLine" name="removeLine">
          <i class="fa fa-fw fa-times"></i><span [translate]="'predefinidocontablinhas.removeLine'"></span>
        </button>

        <button type="button" class="btn btn-xs btn-info" (click)="resetTableOrder()" *ngIf="changedTableOrder" name="resetTableOrder">
          <i class="fa fa-fw fa-sort"></i><span [translate]="'predefinidocontablinhas.reorder'"></span>
        </button>
      </div>

      <div class="table-responsive">
        <table class="table table-sm table-bordered">
          <thead>
            <ng-container *ngFor="let row of tableHeaderDefinition">
              <tr>
                <ng-container *ngFor="let line of row.lines">
                  <th class="table-header-definition-row" *ngIf="line.visible !== false" [attr.rowspan]="line.rowspan || 1" [attr.colspan]="line.colspan || 1" [translate]="line.text"></th>
                </ng-container>
              </tr>
            </ng-container>
          </thead>
          <tbody>
            <tr *ngFor="let line of linhas; index as i" [class.selected]="line._selected" (click)="selectLine(line)">
              <td>{{ i + 1 }}</td>
              <ng-container *ngFor="let cell of tableBodyDefinition">
                <td *ngIf="cell.visible !== false" [class.selected]="cell.selected" [innerHTML]="getCellValue(cell, line._index)" (click)="selectCell(cell)"></td>
              </ng-container>
            </tr>
            <tr *ngIf="!linhas || !linhas.length">
              <td colspan="7" [translate]="'predefinidocontablinhas.titles.emptyLines'"></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="col-12 col-sm-6" *ngIf="selectedLine && selectedCell">
      <pl-group>
        <edit>
          <h5><strong [translate]="titleProperties"></strong></h5>
        </edit>
      </pl-group>

      <ng-container *ngFor="let group of tableBodyDefinition[selectedCell.index].groups | keyvalue">
        <div *ngIf="isFieldVisible(group.value)" class="mt-2">
          <pl-group *ngIf="group.value.caption">
            <edit>
              <h5><strong [translate]="group.value.caption"></strong></h5>
            </edit>
          </pl-group>

          <ng-container *ngFor="let field of group.value.fields">
            <pl-group *ngIf="isFieldVisible(field)" [properties]="{readonly: previewOnly}">
              <label [translate]="field.caption"></label>
              <edit>
                <pl-edit
                  [type]="field.type"
                  [attrName]="field.name"
                  [model]="getGroupFieldModel(group.key)[field.name]"
                  (modelChange)="fieldChanged(field, group.value.fields, getGroupFieldModel(group.key), $event)"
                  [properties]="field.properties"
                  plAutoFocus
                  [plAutoFocusDisabled]="field.name !== fieldToFocus">
                </pl-edit>
              </edit>
            </pl-group>
          </ng-container>
        </div>
      </ng-container>

      <pl-group class="mt-2">
        <edit>
          <h5><strong [translate]="'predefinidocontablinhas.titles.visualProperties'"></strong></h5>
        </edit>
      </pl-group>

      <ng-container *ngFor="let visualProperty of visualProperties">
        <pl-group *ngIf="visualProperty.visible !== false" [properties]="{readonly: previewOnly}">
          <label [translate]="visualProperty.caption"></label>
          <edit>
            <pl-edit
              [type]="visualProperty.type"
              [attrName]="visualProperty.name"
              [model]="linhas[selectedLine._index][selectedCell.name]['visibilidadeProperties'][visualProperty.name]"
              (modelChange)="fieldChangedVisual(visualProperty, linhas[selectedLine._index][selectedCell.name]['visibilidadeProperties'], $event)">
            </pl-edit>
          </edit>
        </pl-group>
      </ng-container>
    </div>
  </div>
</div>
