import {isEqual, merge} from 'lodash-es';
import {AfterViewInit, Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {HookMatchCriteria, StateDeclaration, StateObject, Transition, TransitionService} from '@uirouter/core';
import {copy, downloadStream, IPlNavPillCallback, IPlTabsCallback, IPlTabsEventSelected, IPlToolbarItem, IPlToolbarMenuItem, isArray, isFunction, isObject, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ENavAnexosMod22} from '../modelo22.module.interface';
import {
  IJsonModelo22,
  IJsonModelo22AnexoA,
  IJsonModelo22AnexoC,
  IJsonModelo22AnexoD,
  IJsonModelo22AnexoE,
  IJsonModelo22AnexoF,
  IJsonModelo22AnexoG,
  IJsonModelo22CompDerrama,
  IJsonModelo22ContasDef,
  IJsonModelo22PrejuizosFiscais,
  IJsonModelo22Rosto,
  IJsonModelo22SubmeterResponse
} from '../jsonModelo22.module.interface';
import {EStatusCode, isTest} from '../../../../../config/constants';
import {Modelo22DerramaModalComponent} from './anexos/modal/modelo22.derrama.modal.component';
import {Modelo22Service} from '../modelo22.module.service';
import {ModuloComponent} from '../../../../components/module/module.component';
import {STATE_NAME_DISCONNECTED} from '../../../../states/account/disconnected/disconnected.state.interface';
import {STATE_NAME_LOGIN} from '../../../../states/account/login/login.state.interface';
import {Modelo22ConfigwsModalComponent} from '../modals/modelo22.configWS.modal.component';
import moment from 'moment';
import {maxDateCG, maxDateCGD, minDateCG, minDateCGD} from '../../../../../common/utils/utils';
import {TDate} from '../../../../../common/dates';
import {Modelo22ContasDefAllModalComponent} from './anexos/rosto/modal/contasdefall/modelo22.contasDefAll.modal.component';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {MODULE_NAME_PCA_ESTATISTICA_BALANCETES} from '../../estatistica/balancetes/contabilidade.balancetes.module.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID} from '../../estatistica/extratosgrid/contabilidade.extratosGrid.module.interface';
import {MODULE_NAME_TRIBUT_AUTONOMAS} from '../../../tributacaoautonoma/tributAutonomas.module.interface';
import {Modelo22PrejuizosFiscaisModalComponent} from './anexos/rosto/modal/prejuizosfiscais/modelo22.prejuizosFiscais.modal.component';
import {MODULE_NAME_DOCUMENTOS_FISCAIS} from '../../manutencao/documentosFiscais/documentosFiscais.module.interface';
import {MODULE_NAME_RETENCOES} from '../../retencoes/retencoes.module.interface';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {ETiposValidar, IJsonModeloErrors} from '../../modelo30/modelo30.module.interface';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';

const TOOLBAR_GROUP_RESPONSIVE = 'module-btns-responsive';

@Component({
  selector: 'module-modelo-22',
  templateUrl: './modelo22.module.component.html'
})
export class Modelo22ModuleComponent extends ModuloComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() public modelo22: IJsonModelo22;
  @Input() public anosAnteriores: Array<number>;
  @Input() public showAlertDerrama: boolean;

  public readonly tabCallback: IPlTabsCallback;
  public readonly plNavPillCallback: IPlNavPillCallback;
  public readonly eTiposValidar: typeof ETiposValidar;
  public readonly typeNavAnexos: typeof ENavAnexosMod22;

  public activeTab: ENavAnexosMod22;
  public errorMessages: IJsonModeloErrors;
  public hasAnexoA: boolean;
  public hasAnexoC: boolean;
  public hasAnexoD: boolean;
  public hasAnexoE: boolean;
  public hasAnexoF: boolean;
  public hasAnexoG: boolean;

  private readonly _btnCriarFicheiro: IPlToolbarMenuItem;
  private readonly _btnLimpaValores: IPlToolbarMenuItem;
  private readonly _btnValidaAt: IPlToolbarItem;
  private readonly _btnAtalhos: IPlToolbarItem;
  private readonly _btnSumbmitAt: IPlToolbarMenuItem;

  private readonly _maintenanceInstanceBalancetes: IModuleMaintenanceInstance;
  private readonly _maintenanceInstanceExtratosGrid: IModuleMaintenanceInstance;
  private readonly _maintenanceInstanceTributAutonomas: IModuleMaintenanceInstance;
  private readonly _maintenanceInstanceDocsFiscais: IModuleMaintenanceInstance;
  private readonly _maintenanceInstanceRetencoes: IModuleMaintenanceInstance;

  private _changedAnyValue: boolean;
  private _initialModelo22: IJsonModelo22;
  private _promise: Promise<void>;
  private _anoAtivo: number;
  private _mnuAnos: IPlToolbarItem;
  private _selectedAno: IPlToolbarMenuItem;
  private _deRegisterOnStartFn: Function;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _transitionService: TransitionService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);
    this._maintenanceInstanceBalancetes = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_BALANCETES);
    this._maintenanceInstanceExtratosGrid = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID);
    this._maintenanceInstanceTributAutonomas = this._moduleMaintenanceService.build(MODULE_NAME_TRIBUT_AUTONOMAS);
    this._maintenanceInstanceDocsFiscais = this._moduleMaintenanceService.build(MODULE_NAME_DOCUMENTOS_FISCAIS);
    this._maintenanceInstanceRetencoes = this._moduleMaintenanceService.build(MODULE_NAME_RETENCOES);
    this._cleanErrors();
    this.tabCallback = {};
    this.plNavPillCallback = {};
    this.eTiposValidar = ETiposValidar;
    this.typeNavAnexos = ENavAnexosMod22;
    this._mnuAnos = {order: 1};
    this.btnSave.visible = true;
    this.btnSave.click = () => this._saveModelo22();
    this.btnSave.disabled = true;
    this.btnSave.order = this._mnuAnos.order + 1;
    this._btnCriarFicheiro = {
      id: 'criarficheiro',
      order: this.btnSave.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file-text-o"></i>',
      class: 'btn-primary',
      caption: 'modelo22.btn.criarficheiro',
      disabled: false,
      tooltip: {
        disabled: true,
        placement: 'bottom-right',
        container: 'body',
        text: 'modelo22.messages.temDeGuardar'
      },
      promise: this.promise,
      click: () => this._criarFicheiro()
    };
    this._btnSumbmitAt = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'submeterNaAT',
      type: 'button',
      caption: 'global.btn.submeterAt',
      iconLeft: '<i class="fa fa-fw fa-upload"></i>',
      click: () => this._submeterNaAT(),
      disabled: false
    };
    const btnConfigWS: IPlToolbarMenuItem = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'configWS',
      type: 'button',
      caption: 'global.btn.configWS',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      click: () => this._callConfigWS()
    };
    this._btnValidaAt = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'validarNaAT',
      order: this._btnCriarFicheiro.order + 1,
      type: 'dropdown-split',
      class: 'btn-primary',
      caption: 'global.btn.validarAt',
      iconLeft: '<i class="fa fa-fw fa-globe"></i>',
      click: () => this._validarNaAT(),
      disabled: false,
      menu: [this._btnSumbmitAt, btnConfigWS]
    };
    this._btnLimpaValores = {
      id: 'limpaValores',
      order: this._btnValidaAt.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-trash"></i>',
      class: 'btn-secondary',
      caption: 'modelo22.btn.limpaValores',
      promise: this.promise,
      tooltip: {text: 'modelo22.messages.cleanValuesTooltip', placement: 'bottom'},
      click: () => this._deleteModelo22()
    };
    const btnBalancetes: IPlToolbarMenuItem = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'balancetes',
      type: 'button',
      caption: 'modelo22.btn.balancetes',
      click: () => this._openMaintenanceBalancetes()
    };
    const btnExtratos: IPlToolbarMenuItem = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'extratos',
      type: 'button',
      caption: 'modelo22.btn.extratos',
      click: () => this._openMaintenanceExtratosGrid()
    };
    const btnCalcDerrama: IPlToolbarMenuItem = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'calcDerrama',
      type: 'button',
      caption: 'modelo22.btn.calcDerrama',
      click: () => this.openAdicionalDerramaMunicipal()
    };
    const btnTributAutonomas: IPlToolbarMenuItem = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'tributAutonomas',
      type: 'button',
      caption: 'modelo22.btn.tributAutonomas',
      click: () => this._openMaintenanceTributAutonomas()
    };
    const btnPrejuizosFiscais: IPlToolbarMenuItem = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'prejFiscais',
      type: 'button',
      caption: 'modelo22.btn.prejFiscais',
      click: () => this._openPrejuizosFiscais()
    };
    const btnRetencoes: IPlToolbarMenuItem = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'retencoes',
      type: 'button',
      caption: 'modelo22.btn.retencoes',
      click: () => this._openRetencoes()
    };
    const btnDocumentosFiscais: IPlToolbarMenuItem = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'documentosFiscais',
      type: 'button',
      caption: 'modelo22.btn.documentosFiscais',
      click: () => this._openDocumentosFiscais()
    };
    const btnContasDefAll: IPlToolbarMenuItem = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'contasDefAll',
      type: 'button',
      caption: 'modelo22.btn.contasdefall',
      click: () => this._openContasDefAll()
    };
    this._btnAtalhos = {
      groupId: TOOLBAR_GROUP_RESPONSIVE,
      id: 'atalhos',
      order: this._btnValidaAt.order + 1,
      type: 'dropdown',
      class: 'btn-light',
      caption: 'global.btn.shortcuts',
      iconLeft: '<i class="fa fa-fw fa-cog"></i>',
      disabled: false,
      menu: [btnBalancetes, btnExtratos, btnCalcDerrama, btnTributAutonomas, btnPrejuizosFiscais, btnRetencoes, btnDocumentosFiscais, btnContasDefAll]
    };
    this._changedAnyValue = false;
    this.anosAnteriores = [];
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._anoAtivo = this.modelo22.anoDaDeclaracao;
    const anos: Array<IPlToolbarMenuItem> = [];
    let addedCurrentYear = false;
    for (const ano of this.anosAnteriores) {
      if (ano === this.modelo22.anoDaDeclaracao) {
        addedCurrentYear = true;
      }
      const itemAno: IPlToolbarMenuItem = {
        caption: String(ano),
        active: ano === this._anoAtivo,
        click: (menuItem: IPlToolbarMenuItem) => this._anoChanged(menuItem)
      };
      if (itemAno.active) {
        this._selectedAno = itemAno;
      }
      anos.push(itemAno);
    }
    if (!addedCurrentYear) {
      anos.unshift({
        caption: String(this.modelo22.anoDaDeclaracao),
        active: this.modelo22.anoDaDeclaracao === this._anoAtivo,
        click: (menuItem: IPlToolbarMenuItem) => this._anoChanged(menuItem)
      });
      const itemAno: IPlToolbarMenuItem = anos[0];
      if (itemAno.active) {
        this._selectedAno = itemAno;
      }
    }
    this._mnuAnos = {...this._mnuAnos, id: 'anos', caption: this._getAnoCaption(), type: 'dropdown', menu: anos};

    this.toolbar.addButton(this._mnuAnos).addButton(this._btnCriarFicheiro).addButton(this._btnValidaAt).addButton(this._btnLimpaValores).addButton(this._btnAtalhos);

    this._applyModelo22(true);
    this.activeTab = ENavAnexosMod22.Rosto;
    setTimeout(() => {
      this._registerOnStart();
    });
  }

  public ngAfterViewInit(): void {
    this._applyModelo22(true, true);
    this._evaluateButtons(true);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._deRegisterOnStart();
  }

  public changedTab(event: IPlTabsEventSelected): void {
    if (event.nextId === ENavAnexosMod22.Anexos) {
      event.preventDefault();
    } else {
      this.activeTab = <ENavAnexosMod22>event.nextId;
    }
  }

  public changedModelo22(value: IJsonModelo22): void {
    this.modelo22 = value;

    const normalizedModelo22: IJsonModelo22 = this._normalizeModelo22(this.modelo22);
    this._changedAnyValue = JSON.stringify(normalizedModelo22) !== JSON.stringify(this._initialModelo22);
    this._setAnexosRosto();
    this._clearCGDatesDefault();
    this._evaluateButtons();
  }

  public openAdicionalDerramaMunicipal(): Promise<void> {
    this.promise = this._modelo22Service.loadCalculoDerrama(this.modelo22.anoDaDeclaracao).then((response: HttpResponse<IJsonModelo22CompDerrama>) => {
      const modalInstance = this._cgModalService.showVanilla(Modelo22DerramaModalComponent);
      const componentInstance: Modelo22DerramaModalComponent = modalInstance.componentInstance;
      componentInstance.derrama = copy(response.body);
      componentInstance.modelo22 = copy(this.modelo22);
      modalInstance.result.then((result: IJsonModelo22) => {
        const rostoHasAnexoA = this.modelo22.rosto.temAnexoA;
        const rostoHasAnexoB = this.modelo22.rosto.temAnexoB;
        const rostoHasAnexoC = this.modelo22.rosto.temAnexoC;
        const rostoHasAnexoD = this.modelo22.rosto.temAnexoD;
        const rostoHasAnexoE = this.modelo22.rosto.temAnexoE;
        const rostoHasAnexoF = this.modelo22.rosto.temAnexoF;
        const rostoHasAnexoG = this.modelo22.rosto.temAnexoG;
        this.modelo22.rosto = merge(this.modelo22.rosto, result.rosto);
        this.modelo22.anexoA = merge(this.modelo22.anexoA, result.anexoA);
        this.modelo22.anexoC = merge(this.modelo22.anexoC, result.anexoC);
        this.modelo22.anexoD = merge(this.modelo22.anexoD, result.anexoD);
        this.modelo22.anexoE = merge(this.modelo22.anexoE, result.anexoE);
        this.modelo22.anexoF = merge(this.modelo22.anexoF, result.anexoF);
        this.modelo22.anexoG = merge(this.modelo22.anexoG, result.anexoG);
        this.modelo22.rosto.temAnexoA = rostoHasAnexoA;
        this.modelo22.rosto.temAnexoB = rostoHasAnexoB;
        this.modelo22.rosto.temAnexoC = rostoHasAnexoC;
        this.modelo22.rosto.temAnexoD = rostoHasAnexoD;
        this.modelo22.rosto.temAnexoE = rostoHasAnexoE;
        this.modelo22.rosto.temAnexoF = rostoHasAnexoF;
        this.modelo22.rosto.temAnexoG = rostoHasAnexoG;
        this._setListIndexes();

        this._initialModelo22 = this._normalizeModelo22(merge(this._initialModelo22, result));
        this._evaluateButtons();
        this.showAlertDerrama = false;
      });
    });
    return this.promise;
  }

  public removeAnexo(anexo: string): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.warning', this._translateService.instant('modelo22.messages.avisoAnexos', {anexo: anexo})).then(() => {
      this.evaluateMenuAnexos(anexo, false);
    });
  }

  public evaluateMenuAnexos(anexo: string, value: boolean): void {
    switch (anexo) {
      case 'A':
        this.hasAnexoA = value;
        this.modelo22.rosto.temAnexoA = !value ? undefined : value;
        if (!value) {
          this.modelo22.anexoA = this._initialModelo22.anexoA;
        }
        this.activeTab = value ? ENavAnexosMod22.AnexoA : ENavAnexosMod22.Rosto;
        break;
      case 'C':
        this.hasAnexoC = value;
        this.modelo22.rosto.temAnexoC = !value ? undefined : value;
        if (!value) {
          this.modelo22.anexoC = this._initialModelo22.anexoC;
        }
        this.activeTab = value ? ENavAnexosMod22.AnexoC : ENavAnexosMod22.Rosto;
        break;
      case 'D':
        this.hasAnexoD = value;
        this.modelo22.rosto.temAnexoD = !value ? undefined : value;
        if (!value) {
          this.modelo22.anexoD = this._initialModelo22.anexoD;
        }
        this.activeTab = value ? ENavAnexosMod22.AnexoD : ENavAnexosMod22.Rosto;
        break;
      case 'E':
        this.hasAnexoE = value;
        this.modelo22.rosto.temAnexoE = !value ? undefined : value;
        if (!value) {
          this.modelo22.anexoE = this._initialModelo22.anexoE;
        }
        this.activeTab = value ? ENavAnexosMod22.AnexoE : ENavAnexosMod22.Rosto;
        break;
      case 'F':
        this.hasAnexoF = value;
        this.modelo22.rosto.temAnexoF = !value ? undefined : value;
        if (!value) {
          this.modelo22.anexoF = this._initialModelo22.anexoF;
        }
        this.activeTab = value ? ENavAnexosMod22.AnexoF : ENavAnexosMod22.Rosto;
        break;
      case 'G':
        this.hasAnexoG = value;
        this.modelo22.rosto.temAnexoG = !value ? undefined : value;
        if (!value) {
          this.modelo22.anexoG = this._initialModelo22.anexoG;
        }
        this.activeTab = value ? ENavAnexosMod22.AnexoG : ENavAnexosMod22.Rosto;
        break;
      default: {
        this.activeTab = ENavAnexosMod22.Rosto;
      }
    }
    this._evaluateButtons();
  }

  public get promise(): Promise<void> {
    return this._promise;
  }

  public set promise(value: Promise<void>) {
    this._promise = value;
    this._promise.finally(() => {
      this._promise = undefined;
    });
  }

  private _openContasDefAll(): Promise<void> {
    return this._modelo22Service.getCamposContasDefAll().then((response: HttpResponse<Array<IJsonModelo22ContasDef>>) => {
      const modalInstance = this._cgModalService.showVanilla(Modelo22ContasDefAllModalComponent);
      const componentInstance: Modelo22ContasDefAllModalComponent = modalInstance.componentInstance;
      componentInstance.contasDefAll = response.body;
    });
  }

  private _applyModelo22(isInit: boolean = false, isSave: boolean = false): void {
    this.modelo22 = merge(
      {
        anoDaDeclaracao: undefined,
        existeGravacaoRosto: false,
        existeGravacaoAnexoA: false,
        existeGravacaoAnexoC: false,
        existeGravacaoAnexoD: false,
        existeGravacaoAnexoE: false,
        existeGravacaoAnexoF: false,
        existeGravacaoAnexoG: false,
        rosto: this._emptyModelo22Rosto(),
        anexoA: this._emptyModelo22AnexoA(),
        anexoC: this._emptyModelo22AnexoC(),
        anexoD: this._emptyModelo22AnexoD(),
        anexoE: this._emptyModelo22AnexoE(),
        anexoF: this._emptyModelo22AnexoF(),
        anexoG: this._emptyModelo22AnexoG(),
        listaDeErros: []
      },
      this.modelo22
    );

    this._setListIndexes();

    if (isInit || isSave) {
      this._initialModelo22 = this._normalizeModelo22(this.modelo22);
      this._changedAnyValue = false;
    }
    this.hasAnexoA = isInit ? this.modelo22.rosto.temAnexoA || this.modelo22.existeGravacaoAnexoA : this.modelo22.rosto.temAnexoA;
    this.hasAnexoC = isInit ? this.modelo22.rosto.temAnexoC || this.modelo22.existeGravacaoAnexoC : this.modelo22.rosto.temAnexoC;
    this.hasAnexoD = isInit ? this.modelo22.rosto.temAnexoD || this.modelo22.existeGravacaoAnexoD : this.modelo22.rosto.temAnexoD;
    this.hasAnexoE = isInit ? this.modelo22.rosto.temAnexoE || this.modelo22.existeGravacaoAnexoE : this.modelo22.rosto.temAnexoE;
    this.hasAnexoF = isInit ? this.modelo22.rosto.temAnexoF || this.modelo22.existeGravacaoAnexoF : this.modelo22.rosto.temAnexoF;
    this.hasAnexoG = isInit ? this.modelo22.rosto.temAnexoG || this.modelo22.existeGravacaoAnexoG : this.modelo22.rosto.temAnexoG;
    this._evaluateButtons();
  }

  private _criarFicheiro(): Promise<void> {
    return this._modelo22Service.criarFicheiro(this.modelo22.rosto.quadro01.campo2).then((response: HttpResponse<Blob>) => {
      downloadStream(response);
    });
  }

  private _saveModelo22(): Promise<void> {
    this._setAnexosRosto();
    const hasChanges: boolean = this._modelo22HasChanges(this.modelo22);
    if (!hasChanges) {
      this._applyModelo22(false, true);
      this._plAlertService.success('modelo22.messages.dadosguardados');
      return Promise.resolve();
    }
    this.promise = this._modelo22Service.saveModelo22(this.modelo22).then((response: HttpResponse<IJsonModelo22>) => {
      if (response.status === EStatusCode.OK) {
        this._plAlertService.success('modelo22.messages.dadosguardados');
      }
      this._applyModelo22(false, true);
    });
    return this.promise;
  }

  private _deleteModelo22(): Promise<void> {
    return this._cgModalService.showOkCancel('global.text.warning', 'modelo22.messages.cleanValuesInfo').then(() => {
      this.promise = this._modelo22Service.deleteModelo22(this._anoAtivo).then((response: HttpResponse<IJsonModelo22>) => {
        this._plAlertService.success('modelo22.messages.registosdel');
        this.modelo22 = response.body;
        this._applyModelo22(true);
        this.activeTab = ENavAnexosMod22.Rosto;
      });
      return this.promise;
    });
  }

  private _modelo22ByYear(): Promise<void> {
    this.promise = this._modelo22Service.getModelo22ByYear(this._anoAtivo).then((response: HttpResponse<IJsonModelo22>) => {
      this.modelo22 = response.body;
      this._applyModelo22(true);
      this.activeTab = ENavAnexosMod22.Rosto;
    });
    return this.promise;
  }

  private _evaluateButtons(firstTime: boolean = false): void {
    if (firstTime) {
      this.btnSave.disabled = true;
      this._btnCriarFicheiro.disabled = false;
      this._btnCriarFicheiro.tooltip.disabled = true;
      this._btnValidaAt.disabled = this._btnSumbmitAt.disabled = false;
    } else {
      this.btnSave.disabled = !this._changedAnyValue;
      this._btnCriarFicheiro.disabled = this._changedAnyValue;
      this._btnCriarFicheiro.tooltip.disabled = !this._changedAnyValue;
      this._btnValidaAt.disabled = this._btnSumbmitAt.disabled = !this.btnSave.disabled;
    }
  }

  private _anoChanged(menuItem: IPlToolbarMenuItem): Promise<void> {
    if (menuItem.caption !== String(this._anoAtivo)) {
      if (this._selectedAno) {
        this._selectedAno.active = false;
      }
      this._selectedAno = menuItem;
      this._selectedAno.active = true;
      this._anoAtivo = Number(menuItem.caption);
      this._mnuAnos.caption = this._getAnoCaption();

      this.promise = this._modelo22ByYear();
      return this.promise;
    }
    return Promise.resolve();
  }

  private _getAnoCaption(): string {
    return this._translateService.instant('toolbar.year', {value: this._anoAtivo});
  }

  private _setListIndexes(): void {
    function initIndex(value: unknown): void {
      if (isObject(value)) {
        for (const key of Object.keys(value)) {
          const objValue: unknown = value[key];
          if (isArray(value)) {
            let isArrayValueAnObject = false;
            for (let i = 0; i < value.length; i++) {
              const arrayValue: unknown = value[i];
              if (!isArrayValueAnObject && isObject(arrayValue)) {
                isArrayValueAnObject = true;
              }
              if (isArrayValueAnObject) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (<any>arrayValue)._index = i + 1;
              }
            }
          } else if (isObject(objValue)) {
            initIndex(objValue);
          }
        }
      }
    }

    initIndex(this.modelo22);
  }

  private _setAnexosRosto(): void {
    this.modelo22.rosto.temAnexoA = this.hasAnexoA;
    this.modelo22.rosto.temAnexoC = this.hasAnexoC;
    this.modelo22.rosto.temAnexoD = this.hasAnexoD;
    this.modelo22.rosto.temAnexoE = this.hasAnexoE;
    this.modelo22.rosto.temAnexoF = this.hasAnexoF;
    this.modelo22.rosto.temAnexoG = this.hasAnexoG;
  }

  private _modelo22HasChanges(modelo22: IJsonModelo22): boolean {
    const normalizedModelo22 = this._normalizeModelo22(modelo22);
    return !isEqual(normalizedModelo22, this._initialModelo22);
  }

  private _normalizeModelo22(modelo22: IJsonModelo22): IJsonModelo22 {
    function normalizeValue(value: unknown): unknown {
      if (value === 0) {
        return undefined;
      } else if (value === false) {
        return undefined;
      } else if (isObject(value)) {
        for (const key of Object.keys(value)) {
          const objValue: unknown = value[key];
          value[key] = normalizeValue(objValue);
        }
      } else if (isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const arrayValue: unknown = value[i];
          value[i] = normalizeValue(arrayValue);
        }
      }
      return value;
    }

    const normalizedModelo22: IJsonModelo22 = copy(modelo22);
    normalizeValue(normalizedModelo22);
    return normalizedModelo22;
  }

  private async _validarNaAT(): Promise<void> {
    const response = await this._modelo22Service.validarDeclaracaoAT(this.modelo22.rosto.quadro01.campo2).catch((reason: HttpErrorResponse) => {
      const exception: ICGExceptionError = this._cgExceptionService.get(reason);
      if (exception) {
        this.errorMessages = {
          tipoValidacao: ETiposValidar.AT,
          mensagemTipoHeader: this._translateService.instant('modelo30.servicoAT.validarAtFailed'),
          erros: [{mensagemOriginal: exception.message}]
        };
      }
    });
    if (response) {
      this._cleanErrors();
      this._plAlertService.success('modelo22.messages.servicoAT.validarAtSuccess');
    }
  }

  private async _submeterNaAT(): Promise<void> {
    const submitResponse: IJsonModelo22SubmeterResponse = await this._modelo22Service.submeterDeclaracaoAT(this.modelo22.rosto.quadro01.campo2).then((response) => response.body);
    if (submitResponse.isOk && !submitResponse.temApenasAvisos) {
      this._plAlertService.success('modelo22.messages.servicoAT.submeterAtSuccess');
    } else {
      const messageString = `<p>${<string>this._translateService.instant('modelo22.messages.modalSubmeterAT.message')}</p><p>${submitResponse.message}</p>`;
      this._cgModalService.showOkCancel(this._translateService.instant('modelo22.messages.modalSubmeterAT.title'), messageString).then(async () => {
        const forceSubmitResponse = await this._modelo22Service.submeterDeclaracaoAT(this.modelo22.rosto.quadro01.campo2, true);
        if (forceSubmitResponse) {
          this._plAlertService.success('modelo22.messages.servicoAT.submeterAtSuccess');
        }
      });
    }
  }

  private async _callConfigWS(): Promise<void> {
    return this._cgModalService.show(Modelo22ConfigwsModalComponent);
  }

  private _clearCGDatesDefault(): void {
    this.modelo22.rosto.quadro04.campo1_Data = this._validateDate(this.modelo22.rosto.quadro04.campo1_Data);
    this.modelo22.rosto.quadro04.campo2_11 = this._validateDate(this.modelo22.rosto.quadro04.campo2_11);
    this.modelo22.rosto.quadro04.campo2_6 = this._validateDate(this.modelo22.rosto.quadro04.campo2_6);
    this.modelo22.rosto.quadro04.campo2_8 = this._validateDate(this.modelo22.rosto.quadro04.campo2_8);

    this.modelo22.rosto.quadro05.campo3 = this._validateDate(this.modelo22.rosto.quadro05.campo3);
    this.modelo22.rosto.quadro05.campo5 = this._validateDate(this.modelo22.rosto.quadro05.campo5);
    this.modelo22.rosto.quadro05.campo6 = this._validateDate(this.modelo22.rosto.quadro05.campo6);

    this.modelo22.rosto.quadro10B.campo4 = this._validateDate(this.modelo22.rosto.quadro10B.campo4);

    this.modelo22.rosto.quadro11.campo418 = this._validateDate(this.modelo22.rosto.quadro11.campo418);
    this.modelo22.rosto.quadro11.campo457 = this._validateDate(this.modelo22.rosto.quadro11.campo457);

    this.modelo22.rosto.quadro11A.campo465 = this._validateDate(this.modelo22.rosto.quadro11A.campo465);

    this.modelo22.rosto.quadro13.campo13e3 = this._validateDate(this.modelo22.rosto.quadro13.campo13e3);

    this.modelo22.anexoD.quadro06.campo601 = this._validateDate(this.modelo22.anexoD.quadro06.campo601);

    this.modelo22.anexoE.quadro04 = this._validateDate(this.modelo22.anexoE.quadro04);

    this.modelo22.anexoG.quadro06.campo01 = this._validateDate(this.modelo22.anexoG.quadro06.campo01);
  }

  private _validateDate(date: TDate): TDate {
    if (
      moment(date).isSameOrBefore(minDateCG(), 'date') ||
      moment(date).isSameOrBefore(minDateCGD(), 'date') ||
      moment(date).isSameOrAfter(maxDateCG(), 'date') ||
      moment(date).isSameOrAfter(maxDateCGD(), 'date')
    ) {
      return undefined;
    }
    return date;
  }

  private _openMaintenanceBalancetes(): Promise<void> {
    return this._maintenanceInstanceBalancetes.maintenance({params: {fromModelo22: true}, modalOptions: {size: 'xxl'}});
  }

  private _openMaintenanceExtratosGrid(): Promise<void> {
    return this._maintenanceInstanceExtratosGrid.maintenance({modalOptions: {size: 'xxl'}});
  }

  private _openMaintenanceTributAutonomas(): Promise<void> {
    return this._maintenanceInstanceTributAutonomas.maintenance({modalOptions: {size: 'xxl'}});
  }

  private _openPrejuizosFiscais(): Promise<void> {
    return this._modelo22Service.prejuizosFiscais().then((response: HttpResponse<Array<IJsonModelo22PrejuizosFiscais>>) => {
      const modalInstance = this._cgModalService.showVanilla(Modelo22PrejuizosFiscaisModalComponent);
      const componentInstance: Modelo22PrejuizosFiscaisModalComponent = modalInstance.componentInstance;
      componentInstance.modelo22 = copy(this.modelo22);
      componentInstance.prejuizosFiscais = response.body;
      return modalInstance.result.then((result: IJsonModelo22) => {
        this.changedModelo22(result);
      });
    });
  }

  private _openRetencoes(): Promise<void> {
    return this._maintenanceInstanceRetencoes.maintenance({modalOptions: {size: 'xxl'}});
  }

  private _openDocumentosFiscais(): Promise<void> {
    return this._maintenanceInstanceDocsFiscais.maintenance({modalOptions: {size: 'xxl'}});
  }

  private _emptyModelo22Rosto(): IJsonModelo22Rosto {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      numeroDeRegistos: undefined,
      anoDaDeclaracao: undefined,
      regimeTributacaoIsencaoDefinitiva: undefined,
      regimeTributacaoIsencaoTemporaria: undefined,
      regimeTributacaoSimplificado: undefined,
      tipoSujeitoPassivoResidenteQueExerce: undefined,
      tipoSujeitoPassivoResidenteQueNaoExerce: undefined,
      tipoSujeitoPassivoNaoResidenteComEstabelecimento: undefined,
      tipoSujeitoPassivoNaoResidenteSemEstabelecimento: undefined,
      empresaClassificadaComoPME: undefined,
      optouPelasTaxasDoRegimeGeral: undefined,
      temAnexoA: undefined,
      temAnexoB: undefined,
      temAnexoC: undefined,
      temAnexoD: undefined,
      temAnexoE: undefined,
      temAnexoF: undefined,
      temAnexoG: undefined,
      taxaPrimeiroEscalao: 0,
      taxaSegundoEscalao: 0,
      limitePrimeiroEscalao: 0,
      regimeGeral: 0,
      regimeGeralAtePrimeiroEscalao: 0,
      regimeGeralMaiorPrimeiroEscalao: 0,
      regimeReducao: 0,
      regimeSimplificado: 0,
      quadro01: {
        campo1_De: undefined,
        campo1_Ate: undefined,
        campo2: undefined
      },
      quadro02: {
        campo1: '',
        campo2: undefined,
        cgM22IRCServFinanSede: '',
        cgM22IRCServFinanDirecao: ''
      },
      quadro03: {
        campo2: undefined,
        campo3: undefined,
        campo3A: undefined,
        campo3A5: false,
        campo3A6: false,
        campo3A7: false,
        campo3B_1: undefined,
        campo3C_1: undefined,
        campo4_10: undefined,
        campo4_11: undefined,
        campo4_12: undefined,
        campo4_13: undefined,
        campo4_1a8: [],
        campo4_9: undefined,
        campo4A: undefined,
        cgM22IRCNomeSujPass: ''
      },
      quadro04: {
        campo1: undefined,
        campo1_Data: undefined,
        campo2_11: undefined,
        campo2_1a5e7: [],
        campo2_6: undefined,
        campo2_8: undefined,
        campo2_9e10: undefined,
        campo3_1: undefined,
        campo3_2: undefined,
        campo3_3: undefined,
        campo3_4: undefined,
        campo3_5: undefined,
        campo3_6: undefined,
        campo3_7: undefined
      },
      quadro05: {
        campo1: undefined,
        campo2: undefined,
        campo3: undefined,
        campo4: undefined,
        campo5: undefined,
        campo6: undefined,
        campo78: undefined
      },
      quadro07: {
        campo701: undefined,
        campo702: undefined,
        campo703: undefined,
        campo704: undefined,
        campo705: undefined,
        campo706: undefined,
        campo707: undefined,
        campo708: undefined,
        campo709: undefined,
        campo710: undefined,
        campo711: undefined,
        campo712: undefined,
        campo713: undefined,
        campo714: undefined,
        campo715: undefined,
        campo716: undefined,
        campo717: undefined,
        campo718: undefined,
        campo719: undefined,
        campo720: undefined,
        campo721: undefined,
        campo722: undefined,
        campo723: undefined,
        campo724: undefined,
        campo725: undefined,
        campo726: undefined,
        campo727: undefined,
        campo728: undefined,
        campo729: undefined,
        campo730: undefined,
        campo731: undefined,
        campo732: undefined,
        campo733: undefined,
        campo734: undefined,
        campo735: undefined,
        campo736: undefined,
        campo737: undefined,
        campo738: undefined,
        campo739: undefined,
        campo740: undefined,
        campo741: undefined,
        campo742: undefined,
        campo743: undefined,
        campo744: undefined,
        campo745: undefined,
        campo746: undefined,
        campo747: undefined,
        campo748: undefined,
        campo749: undefined,
        campo750: undefined,
        campo751: undefined,
        campo752: undefined,
        campo753: undefined,
        campo754: undefined,
        campo755: undefined,
        campo756: undefined,
        campo757: undefined,
        campo758: undefined,
        campo759: undefined,
        campo760: undefined,
        campo761: undefined,
        campo762: undefined,
        campo763: undefined,
        campo764: undefined,
        campo765: undefined,
        campo766: undefined,
        campo767: undefined,
        campo768: undefined,
        campo769: undefined,
        campo770: undefined,
        campo771: undefined,
        campo772: undefined,
        campo773: undefined,
        campo774: undefined,
        campo775: undefined,
        campo776: undefined,
        campo777: undefined,
        campo778: undefined,
        campo779: undefined,
        campo780: undefined,
        campo781: undefined,
        campo782: undefined,
        campo783: undefined,
        campo784: undefined,
        campo785: undefined,
        campo786: undefined,
        campo787: undefined,
        campo788: undefined,
        campo789: undefined,
        campo790: undefined,
        campo791: undefined,
        campo792: undefined,
        campo793: undefined,
        campo794: undefined,
        campo795: undefined,
        campo796: undefined,
        campo797: undefined,
        campo798: undefined,
        campo799: undefined,
        campo800: undefined,
        campo801: undefined,
        campo802: undefined,
        campo803: undefined,
        campo804: undefined,
        campo805: undefined,
        campo806: undefined
      },
      quadro07Desativado: {
        campo201: undefined,
        campo202: undefined,
        campo203: undefined,
        campo204: undefined,
        campo205: undefined,
        campo206: undefined,
        campo207: undefined,
        campo208: undefined,
        campo209: undefined,
        campo210: undefined,
        campo211: undefined,
        campo212: undefined,
        campo213: undefined,
        campo214: undefined,
        campo215: undefined,
        campo216: undefined,
        campo217: undefined,
        campo218: undefined,
        campo220: undefined,
        campo222: undefined,
        campo223: undefined,
        campo224: undefined,
        campo251: undefined,
        campo252: undefined,
        campo253: undefined,
        campo254: undefined,
        campo225: undefined,
        campo226: undefined,
        campo227: undefined,
        campo228: undefined,
        campo229: undefined,
        campo230: undefined,
        campo255: undefined,
        campo256: undefined,
        campo257: undefined,
        campo258: undefined,
        campo259: undefined,
        campo270: undefined,
        campo271: undefined,
        campo231: undefined,
        campo232: undefined,
        campo233: undefined,
        campo234: undefined,
        campo235: undefined,
        campo236: undefined,
        campo237: undefined,
        campo238: undefined,
        campo239: undefined,
        campo240: undefined,
        campo272: undefined,
        campo273: undefined,
        campo274: undefined,
        campo275: undefined,
        campo276: undefined,
        campo277: undefined
      },
      quadro08: {
        campo242: undefined,
        campo245: undefined,
        campo269: undefined,
        campo246: undefined,
        campo248: undefined,
        campo249: undefined,
        campo260: undefined,
        campo262: undefined,
        campo263: undefined,
        campo264: undefined,
        campo265: undefined,
        campo266: undefined,
        campo267: undefined,
        campo247: undefined,
        campo268: undefined,
        campo270: undefined
      },
      quadro09: {
        campo396List: [],
        campo398List: [],
        campo309List: [],
        campo320List: [],
        campo331List: [],
        campo397AList: [],
        campo397BList: [],
        campo309BList: [],
        campo300: undefined,
        campo301: undefined,
        campo302: undefined,
        campo303: undefined,
        campo309: undefined,
        campo310: undefined,
        campo311: undefined,
        campo312: undefined,
        campo313: undefined,
        campo314: undefined,
        campo320: undefined,
        campo321: undefined,
        campo322: undefined,
        campo323: undefined,
        campo324: undefined,
        campo325: undefined,
        campo331: undefined,
        campo332: undefined,
        campo333: undefined,
        campo336: undefined,
        campo346: undefined,
        campo376: undefined,
        campo380: undefined,
        campo381: undefined,
        campo382: undefined,
        campo383: undefined,
        campo384: undefined,
        campo385: undefined,
        campo386: undefined,
        campo387: undefined,
        campo388: undefined,
        campo389: undefined,
        campo390: undefined,
        campo391: undefined,
        campo392: undefined,
        campo393: undefined,
        campo394: undefined,
        campo395: undefined,
        campo397: undefined,
        campo398: undefined,
        campo398NIF: undefined,
        campo399: undefined,
        campo400: undefined,
        campo401: undefined,
        campo407: undefined,
        campo408: undefined,
        campo409: undefined,
        campo500: undefined
      },
      quadro10: {
        campo347A: undefined,
        campo347B: undefined,
        campo348: undefined,
        campo349: undefined,
        campo350: undefined,
        campo351: undefined,
        campo353: undefined,
        campo355: undefined,
        campo356: undefined,
        campo357: undefined,
        campo358: undefined,
        campo359: undefined,
        campo360: undefined,
        campo361: undefined,
        campo362: undefined,
        campo363: undefined,
        campo364: undefined,
        campo365: undefined,
        campo366: undefined,
        campo367: undefined,
        campo368: undefined,
        campo369: undefined,
        campo370: undefined,
        campo371: undefined,
        campo372: undefined,
        campo373: undefined,
        campo374: undefined,
        campo375: undefined,
        campo378: undefined,
        campo379: undefined,
        campo470: undefined,
        taxaDerrama: 0,
        consideraPrejuizoFiscal: undefined,
        consideraPrejuizoFiscalChanged: false
      },
      quadro10A: {
        campo366A: undefined,
        campo366B: undefined
      },
      quadro10B: {
        campo1a3: undefined,
        campo377: undefined,
        campo377A: undefined,
        campo377B: undefined,
        campo4: undefined,
        campo430: undefined,
        campo431: undefined
      },
      quadro11: {
        campo410: undefined,
        campo411: undefined,
        campo416: undefined,
        campo418: undefined,
        campo423: undefined,
        campo429: undefined,
        campo455: undefined,
        campo456: undefined,
        campo457: undefined
      },
      quadro11A: {
        campo460: undefined,
        campo461: undefined,
        campo462: undefined,
        campo463: undefined,
        campo464: undefined,
        campo465: undefined
      },
      quadro11B: {
        campo1: undefined,
        campo10: undefined,
        campo2: undefined,
        campo22: undefined,
        campo3: undefined,
        campo4: undefined,
        campo5: undefined,
        campo67: '',
        campo8: undefined,
        campo9: undefined
      },
      quadro12: {
        list: []
      },
      quadro13: {
        campo414: undefined,
        campo415: undefined,
        campo417: undefined,
        campo420: undefined,
        campo421: undefined,
        campo422: undefined,
        campo424: undefined,
        campo425: undefined,
        campo426: undefined,
        campo427: undefined,
        campo428: undefined,
        campo432: undefined,
        campo433: undefined,
        campo434: undefined,
        campo435: undefined,
        campo436: undefined,
        campo437: undefined,
        campo438: undefined,
        campo439: undefined,
        campo456: undefined,
        campoIniciouAtividade: undefined,
        campo13e3: undefined
      },
      quadro13A: {
        campo440: undefined,
        campo441: undefined,
        campo442: undefined,
        campo443: undefined,
        campo444: undefined,
        campo445: undefined,
        campo446: undefined,
        campo447: undefined,
        campo448: undefined,
        campo449: undefined,
        campo450: undefined,
        campo451: undefined,
        campo452: undefined,
        campo453: undefined,
        campo454: undefined,
        campo457: undefined
      },
      quadro14: {
        list: [],
        totalCampo3: undefined,
        totalCampo6: undefined,
        totalCampo7: undefined,
        totalCampo8: undefined,
        totalCampo10: undefined,
        totalComCDTCampo3: undefined,
        totalComCDTCampo6: undefined,
        totalComCDTCampo7: undefined,
        totalComCDTCampo8: undefined,
        totalComCDTCampo10: undefined,
        totalSemCDTCampo3: undefined,
        totalSemCDTCampo6: undefined,
        totalSemCDTCampo7: undefined,
        totalSemCDTCampo8: undefined,
        totalSemCDTCampo10: undefined
      },
      listaDeErros: []
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  private _emptyModelo22AnexoA(): IJsonModelo22AnexoA {
    return {
      quadro01: undefined,
      quadro02: undefined,
      quadro03: {
        campo01: undefined,
        campo02: undefined,
        campo03: undefined,
        campo04: undefined,
        campo05: undefined,
        campo06: undefined,
        campo07: undefined,
        campo08: undefined,
        campo09: undefined,
        campo10: undefined
      },
      quadro04: {
        listaA: [],
        listaB: [],
        listaC: [],
        listaD: []
      },
      numeroDeRegistos: undefined,
      anoDaDeclaracao: undefined,
      listaDeErros: []
    };
  }

  private _emptyModelo22AnexoC(): IJsonModelo22AnexoC {
    return {
      quadro01: undefined,
      quadro02: undefined,
      quadro04: {
        campo6: undefined,
        campo6A: undefined,
        campo8B: undefined,
        campo9B: undefined,
        campo8C: undefined,
        campo10B: undefined,
        campo9C: undefined,
        campo10C: undefined,
        campo8A: undefined,
        campo9A: undefined,
        campo10A: undefined,
        campo7B: undefined,
        campo7C: undefined,
        campo7A: undefined
      },
      quadro05: {
        campo21: undefined,
        campo12: undefined,
        campo13: undefined,
        campo11: undefined,
        campo14: undefined,
        campo15: undefined
      },
      quadro06: {
        campo18B: undefined,
        campo19B: undefined,
        campo18A: undefined,
        campo16: undefined,
        campo19A: undefined,
        campo20B: undefined,
        campo20A: undefined,
        campo17B: undefined,
        campo17A: undefined
      },
      quadro06A: {
        campo22: undefined,
        campo22A: undefined,
        campo22B: undefined,
        campo23A: undefined,
        campo23B: undefined,
        campo24A: undefined,
        campo24B: undefined
      },
      quadro07: {
        campo1: undefined,
        campo2A: undefined,
        campo2B: undefined,
        campo3A: undefined,
        campo3B: undefined,
        campo4A: undefined,
        campo4B: undefined,
        campo5A: undefined,
        campo5B: undefined
      },
      numeroDeRegistos: undefined,
      anoDaDeclaracao: undefined,
      listaDeErros: []
    };
  }

  private _emptyModelo22AnexoD(): IJsonModelo22AnexoD {
    /* eslint-disable @typescript-eslint/naming-convention */
    return {
      quadro01: undefined,
      quadro02: undefined,
      quadro03: {
        campo301: undefined,
        campo302: undefined,
        campo303: undefined,
        campo304: undefined,
        campo305: undefined,
        campo306: undefined,
        campo307: undefined,
        campo308: undefined,
        campo309: undefined,
        campo310: undefined,
        campo311: undefined,
        campo312: undefined,
        campo313: undefined,
        campo314: undefined,
        campo315: undefined,
        campo316: undefined,
        campo317: undefined,
        campo031AList: [],
        campo031BList: [],
        campo032AList: [],
        campo032BList: []
      },
      quadro04: {
        campo401: undefined,
        campo402: undefined,
        campo403: undefined,
        campo404: undefined,
        campo405: undefined,
        campo406: undefined,
        campo407: undefined,
        campo408: undefined,
        campo409: undefined,
        campo410: undefined,
        campo411: undefined,
        campo412: undefined,
        campo413: undefined,
        campo414: undefined,
        campo415: undefined,
        campo416: undefined,
        campo417: undefined,
        campo418: undefined,
        campo419: undefined,
        campo420: undefined,
        campo421: undefined,
        campo422: undefined,
        campo423: undefined,
        campo424: undefined,
        campo425: undefined,
        campo426: undefined,
        campo427: undefined,
        campo428: undefined,
        campo429: undefined,
        campo430: undefined,
        campo431: undefined,
        campo432: undefined,
        campo433: undefined,
        campo04AList: [],
        campo04B12: undefined,
        campo04B3: undefined,
        campo04B4: undefined,
        campo04B5: undefined,
        campo04C1List: [],
        campo04C2List: [],
        campo041List: [],
        campo434: undefined,
        campo435: undefined,
        campo436: undefined,
        campo437: undefined,
        campo438: undefined,
        campo439: undefined,
        campo440: undefined,
        campo441: undefined
      },
      quadro05: {
        campo501: undefined,
        campo502: undefined
      },
      quadro06: {
        campo601: undefined,
        campo602: undefined,
        campo603: undefined,
        campo603List: [],
        campo604List: [],
        campo605List: [],
        campo606: undefined,
        campo607: undefined,
        campo608: undefined,
        campo609: undefined,
        campo610: undefined,
        campo611: undefined,
        campo612: undefined,
        campo613: undefined,
        campo614: undefined,
        campo615: undefined,
        campo616: undefined,
        campo617: undefined,
        campo618: undefined
      },
      quadro07: {
        campo071List: [],
        campo071AList: [],
        campo705: undefined,
        campo706: undefined,
        campo707: undefined,
        campo708: undefined,
        campo073List: [],
        campo073AList: [],
        campo074List: [],
        campo074AList: [],
        campo076List: [],
        campo076AList: [],
        campo079List: [],
        campo079A1: undefined,
        campo079A2: undefined,
        campo079A3: undefined,
        campo079A4: undefined,
        campo079A5: undefined,
        campo079A6: undefined,
        campo079A7: undefined,
        campo079A8: undefined,
        campo079A9: undefined,
        campo0710A: undefined,
        campo0710B: undefined,
        campo0710List: [],
        campo0710AList: [],
        campo717: undefined,
        campo718: undefined,
        campo719: undefined,
        campo720: undefined,
        campo721: undefined,
        campo726: undefined,
        campo727: undefined,
        campo728: undefined,
        campo077List: [],
        campo078List: [],
        campo078_12: undefined,
        campo078A1List: [],
        campo078A2List: [],
        campo078A3List: []
      },
      quadro08: {
        lista: []
      },
      quadro09: {
        campo901_1: undefined,
        campo901_2: undefined,
        campo902_1: undefined,
        campo902_2: undefined,
        campo903: undefined,
        campo904: undefined,
        campo904A: undefined,
        campo904B: undefined,
        campo904C: undefined,
        campo904D: undefined,
        campo904E: undefined,
        campo904F: undefined,
        campo904G: undefined,
        campo904H: undefined,
        campo904I: undefined,
        campo904J: undefined,
        campo904K: undefined,
        campo904L: undefined,
        campo905: undefined,
        campo906: undefined,
        campo907List: []
      },
      quadro10: {
        campo1001: undefined,
        campo1002: undefined,
        campo1003: undefined,
        campo1004: undefined,
        campo1005: undefined,
        campo1006: undefined,
        campo1007: undefined,
        campo1008: undefined,
        campo1009: undefined,
        campo1010: undefined,
        campo1011: undefined,
        campo1012: undefined,
        campo1013: undefined,
        campo1014: undefined,
        campo1015: undefined,
        campo1016: undefined
      },
      quadro11: {
        campo1111: undefined,
        campo1112: undefined,
        campo1113: undefined,
        campo1114: undefined
      },
      quadro11A: {
        campo3List: []
      },
      quadro12: {
        campo122: undefined,
        lista121: [],
        lista122: []
      },
      numeroDeRegistos: undefined,
      anoDaDeclaracao: undefined,
      taxaBF: 0,
      listaDeErros: []
    };
    /* eslint-enable @typescript-eslint/naming-convention */
  }

  private _emptyModelo22AnexoE(): IJsonModelo22AnexoE {
    return {
      quadro01: undefined,
      quadro02: undefined,
      quadro03: {
        campo01: undefined,
        campo02: undefined,
        campo03: undefined,
        campo04: undefined,
        campo05: undefined,
        campo0621List: [],
        campo07: undefined,
        campo08: undefined,
        campo09: undefined,
        campo10: undefined,
        campo11: undefined,
        campo12: undefined,
        campo13: undefined,
        campo14: undefined,
        campo15: undefined,
        campo16: undefined,
        campo17: undefined,
        campo18: undefined,
        campo19: undefined,
        campo20: undefined,
        campo22: undefined,
        campo23: undefined,
        campo24: undefined,
        campo25: undefined,
        campo26: undefined,
        campo27: undefined,
        campo28: undefined,
        campo29: undefined,
        campo30: undefined,
        campo31: undefined,
        campo32: undefined,
        campo33: undefined,
        campo34: undefined,
        campo40: undefined,
        campo41: undefined,
        campo42: undefined,
        campo35: undefined,
        campo36: undefined,
        campo37: undefined,
        campo38: undefined,
        campo39: undefined,
        campo46: undefined,
        campo06: undefined
      },
      quadro04: undefined,
      numeroDeRegistos: undefined,
      anoDaDeclaracao: undefined,
      listaDeErros: []
    };
  }

  private _emptyModelo22AnexoF(): IJsonModelo22AnexoF {
    return {
      quadro01: undefined,
      quadro02: undefined,
      quadro03: {
        campo01: undefined,
        campo02: undefined,
        campo03: undefined,
        campo04: undefined,
        campo05: undefined,
        campo06: undefined,
        campo07: undefined,
        campo08: undefined,
        campo09: undefined,
        campo10: undefined,
        campo11: undefined,
        campo12: undefined,
        campo13: undefined,
        campo14: undefined,
        campo15: undefined,
        campo16: undefined,
        campo17: undefined,
        campo18: undefined,
        campo19: undefined
      },
      quadro04: {
        campo01: undefined,
        campo02: undefined,
        campo03: undefined,
        campo04: undefined,
        campo05: undefined
      },
      quadro05: {
        campo01: undefined,
        campo02: undefined,
        campo03: undefined,
        campo04: undefined
      },
      quadro06: {
        lista6A: [],
        campoA09: undefined,
        campoA10: undefined,
        lista6B: [],
        campoB06: undefined
      },
      numeroDeRegistos: undefined,
      anoDaDeclaracao: undefined,
      listaDeErros: []
    };
  }

  private _emptyModelo22AnexoG(): IJsonModelo22AnexoG {
    return {
      quadro01: undefined,
      quadro02: undefined,
      quadro03: {
        campo01: undefined,
        campo02: undefined,
        campo03: undefined,
        lista032: [],
        campo10: undefined
      },
      quadro04: {
        lista: [],
        campo11: undefined
      },
      quadro05: {
        campo01: undefined,
        campo02: undefined,
        campo03: undefined,
        campo04: undefined
      },
      quadro06: {
        campo01: undefined,
        campo0203: undefined
      },
      numeroDeRegistos: undefined,
      anoDaDeclaracao: undefined,
      listaDeErros: []
    };
  }

  private _cleanErrors(): void {
    this.errorMessages = {
      tipoValidacao: ETiposValidar.Default,
      erros: []
    };
  }

  private _navigationSafeGuard(): Promise<void> {
    if (!isTest()) {
      const hasChanges: boolean = this._modelo22HasChanges(this.modelo22);
      if (hasChanges) {
        return this._cgModalService.showOkCancel('modelo22.prompt.exitTitle', 'modelo22.prompt.exitMessage');
      }
    }
    return Promise.resolve();
  }

  private _registerOnStart(): void {
    this._deRegisterOnStart();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_LOGIN && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFn = this._transitionService.onStart(criteria, () => this._navigationSafeGuard());
  }

  private _deRegisterOnStart(): void {
    if (isFunction(this._deRegisterOnStartFn)) {
      this._deRegisterOnStartFn();
      this._deRegisterOnStartFn = undefined;
    }
  }
}
