import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonLote} from './jsonLote.entity.interface';
import {ENTITY_NAME_LOTES, ILoteDetailStateParams} from './lotes.entity.interface';
import {ROLE} from '../../services/role.const';
import moment from 'moment';
import {isObject, Transition} from '@uirouter/angular';
import {LotesEditComponent} from './components/edit/lotes.entity.edit.component';
import {LotesListComponent} from './components/list/lotes.entity.list.component';

export const ENTITY_LOTES: IEntityDefinition<IJsonLote> = {
  name: ENTITY_NAME_LOTES,
  roles: [ROLE.ERP],
  pluginsRoles: [ROLE.ERPADVANCED],
  searchPlaceholder: 'lotes.pesquisa',
  metadata: {
    keyName: 'nLote',
    descriptionName: 'nLoteEspecifico',
    fields: [
      {name: 'nLote', type: 'number', caption: 'lotes.fields.nLote', visible: false, properties: {devExpress: {dataGrid: {visible: false, showInColumnChooser: false}}}},
      {
        name: 'nArtigo',
        caption: 'lotes.fields.nArtigo',
        placeholder: 'lotes.fields.nArtigo',
        entity: {name: 'artigos', keyTarget: 'nArtigo', outputTarget: 'nomeArtigo', filter: 'temLote=1'},
        validators: {required: true},
        properties: {devExpress: {dataGrid: {groupIndex: 0}}}
      },
      {name: 'nomeArtigo', type: 'string', caption: 'lotes.fields.nomeArtigo', visible: false, properties: {devExpress: {dataGrid: {allowFiltering: false, allowSearch: false, visible: true}}}},
      {name: 'nLoteEspecifico', type: 'string', caption: 'lotes.fields.nLoteEspecifico', validators: {required: true}},
      {name: 'loteFornecedor', type: 'string', caption: 'lotes.fields.loteFornecedor', visible: false},
      {
        name: 'nConta',
        caption: 'lotes.fields.nConta',
        placeholder: 'lotes.fields.nConta',
        entity: {name: 'fornecedores', keyTarget: 'nConta', outputTarget: 'nomeConta'},
        visible: false,
        properties: {devExpress: {dataGrid: {allowFiltering: false, allowSearch: false, visible: false}}}
      },
      {name: 'nomeConta', type: 'string', caption: 'lotes.fields.nomeConta', visible: false, properties: {devExpress: {dataGrid: {allowFiltering: false, allowSearch: false, visible: false}}}},
      {name: 'dataFabrico', type: 'date', caption: 'lotes.fields.dataFabrico', value: moment()},
      {name: 'dataValidade', type: 'date', caption: 'lotes.fields.dataValidade', value: moment()},
      {
        name: 'qtdTotal',
        type: 'double',
        caption: 'lotes.fields.qtdTotal',
        properties: {decimalsLimit: 3, devExpress: {dataGrid: {allowFiltering: false, allowSearch: false, visible: false}}}
      },
      {name: 'terminado', type: 'boolean', caption: 'lotes.fields.terminado'}
    ],
    order: 'nArtigo,nLoteEspecifico,dataFabrico',
    searchFields: 'nLote,nArtigo,nLoteEspecifico'
  },
  autocomplete: {
    rowTemplate: '{{nLote}} - {{nLoteEspecifico}}',
    output: 'nLoteEspecifico',
    searchFields: 'nLote,nArtigo,nLoteEspecifico'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      component: LotesListComponent
    }
  },
  detail: {
    state: {
      component: LotesEditComponent
    }
  },
  edit: {
    state: {
      component: LotesEditComponent
    }
  },
  new: {
    state: {
      component: LotesEditComponent,
      resolve: [
        {
          provide: 'model',
          deps: [Transition],
          useFactory: (transition: Transition): IJsonLote => {
            const params: ILoteDetailStateParams = <ILoteDetailStateParams>transition.params();
            if (isObject(params.model)) {
              return params.model;
            }
            return undefined;
          }
        }
      ],
      params: {
        defaultData: {
          type: 'any',
          value: null
        },
        nArtigo: {
          type: 'string',
          value: ''
        },
        nomeArtigo: {
          type: 'string',
          value: ''
        },
        dataDoc: {
          type: 'string',
          value: ''
        },
        diasValidade: {
          type: 'int',
          value: 0
        }
      }
    }
  }
};
