import {Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {copy, IPlDynamicVisualsSecondaryClickAction, IPlToolbarItem, isEmpty, isObject, PlAlertService} from 'pl-comps-angular';
import {EModeloTributAutonoma, IJsonTributAutonoAnaliseHeader, IJsonTributAutonomaAnalise} from '../../jsonTributAutonomas.module.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {TributAutonomasModuleService} from '../../tributAutonomas.module.service';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {ITributAutonomasParams, MODULE_NAME_MANUT_TRIBUT_AUTONOMAS, RADIO_GROUP_TRIBUTACAO_APLICADA} from '../../tributAutonomas.module.interface';
import {IDevExpressDataGridEventOnContextMenuPreparing} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {ETipoContaContabilidade} from '../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {HttpResponse} from '@angular/common/http';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';
import {ENTITY_NAME_POCS} from '../../../../entities/pocs/pocs.entity.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {DevExpressDataGridUIService} from '../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {TributAutonomasPrintModalComponent} from '../modals/print/tributAutonomas.print.modal.component';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID} from '../../../portalcontabilidade/estatistica/extratosgrid/contabilidade.extratosGrid.module.interface';

const NUMBER_4 = 4;
const PERIODO_DEZEMBRO_NORMAL = '121';
const BTN_PROCESS_PDF = 'processPDF';

@Component({
  selector: 'tribut-autonomas-module',
  templateUrl: './tributAutonomas.module.component.html'
})
export class TributAutonomasModuleComponent extends ModuloComponent implements OnInit {
  @Input() public header: IJsonTributAutonoAnaliseHeader;

  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonTributAutonomaAnalise, string>;
  public readonly filterMovimento: string;
  public readonly periodosFilterDe: string;
  public readonly typeModeloTributAutonoma: typeof EModeloTributAutonoma;
  public readonly propertiesModeloTributAutonoma: IRadioGroup<EModeloTributAutonoma>;

  public periodosFilterAte: string;
  public gridTitle: string;

  private readonly _params: ITributAutonomasParams;
  private readonly _maintenanceInstancePocs: IEntityMaintenanceInstance;
  private readonly _maintenanceInstanceManutTributAutonoma: IModuleMaintenanceInstance<boolean>;
  private readonly _btnPrintTributAutonoAnalise: IPlToolbarItem;
  private readonly _maintenanceInstanceExtratosGrid: IModuleMaintenanceInstance;

  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _tributAutonomasService: TributAutonomasModuleService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._maintenanceInstanceExtratosGrid = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID);
    this._maintenanceInstanceManutTributAutonoma = this._moduleMaintenanceService.build<boolean>(MODULE_NAME_MANUT_TRIBUT_AUTONOMAS);
    this._maintenanceInstancePocs = this._entityMaintenanceService.build(ENTITY_NAME_POCS);
    this.propertiesModeloTributAutonoma = copy(RADIO_GROUP_TRIBUTACAO_APLICADA);
    this.propertiesModeloTributAutonoma.disabled = true;
    this.typeModeloTributAutonoma = EModeloTributAutonoma;
    this.periodosFilterDe = `periodo=%${this.configurations.empresa.anoEmCursoIRC}%|periodo=%${this.configurations.empresa.anoEmCursoIRC + 1}%`;
    this.periodosFilterAte = `loadOnlyAno=${this.configurations.empresa.anoEmCursoIRC}`;
    this.filterMovimento = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'descricao', dataType: 'string', caption: 'tributAutonomas.fields.descricao', groupIndex: 0},
        {dataField: 'nConta', dataType: 'string', caption: 'tributAutonomas.fields.nConta'},
        {dataField: 'nome', dataType: 'string', caption: 'tributAutonomas.fields.nome'},
        {dataField: 'taxaTotal', dataType: 'double', caption: 'tributAutonomas.fields.taxa'},
        {dataField: 'saldoGeral', dataType: 'double', caption: 'tributAutonomas.fields.saldoGeral'},
        {dataField: 'valorTributAutonoma', dataType: 'double', caption: 'tributAutonomas.fields.valorTributAutonoma'},
        {
          dataField: 'vf_actions',
          fixed: true,
          fixedPosition: 'right',
          caption: '',
          cellTemplate: 'cellTemplateActions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false,
          showInColumnChooser: false
        }
      ],
      dataSource: [],
      export: {filename: 'global.menu.tributautonomasanalise', enabledPdf: false},
      filterPanel: {visible: true, filterEnabled: false},
      filterValue: ['saldoGeral', '>', 0],
      height: '60vh',
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateGridTitle'
          },
          {
            location: 'after',
            name: 'exportButton'
          },
          'columnChooserButton',
          'revertButton'
        ]
      },
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      scrolling: {rowRenderingMode: 'virtual'},
      keyExpr: 'nConta',
      remoteOperations: false,
      summary: {
        groupItems: [
          {column: 'saldoGeral', displayFormat: '{0}', showInGroupFooter: true, skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorTributAutonoma', displayFormat: '{0}', showInGroupFooter: true, skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ],
        totalItems: [
          {column: 'saldoGeral', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'},
          {column: 'valorTributAutonoma', summaryType: 'sum', displayFormat: '{0}', valueFormat: 'double'}
        ]
      }
    };
    this.header = {
      contasDe: '6',
      contasAte: '69999',
      prejuizoFiscal: false,
      periodoDe: '',
      nomePeriodoDe: '',
      periodoAte: '',
      nomePeriodoAte: '',
      modeloTributAutonoma: EModeloTributAutonoma.IRC
    };
    this._btnPrintTributAutonoAnalise = {
      id: BTN_PROCESS_PDF,
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>',
      class: 'btn-success',
      caption: 'global.btn.processPDF',
      disabled: true,
      click: () => this._printTributAutonoAnalise()
    };
    this._params = <ITributAutonomasParams>this._transition.params();
    this.search = this.search.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!isEmpty(this._params?.nConta)) {
      this.header.contasDe = this._params.nConta;
    }
    this.toolbar.addButton(this._btnPrintTributAutonoAnalise).addButton({
      id: 'manutTributAutono',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-file"></i>',
      class: 'btn-info',
      caption: 'tributAutonomas.btn.manutTributAutonoma',
      click: () => this._manutTributAutono()
    });
  }

  public async search(): Promise<void> {
    const response: HttpResponse<Array<IJsonTributAutonomaAnalise>> = await this._tributAutonomasService.searchClassificacaoContasAnalise(this.header);
    this.dataGridDefinition.dataSource = response.body;
    this._btnPrintTributAutonoAnalise.disabled = response.body?.length < 1;

    this.gridTitle = this._translateService.instant('tributAutonomas.dxToolbar.title', {ano: this.header.periodoDe.substring(0, NUMBER_4)});
    if (response.body.length > 0) {
      this._cardPanel.collapse();
      setTimeout(() => {
        this.toolbar.focusItem(BTN_PROCESS_PDF);
      });
    } else {
      this._cardPanel.focusFirstElement();
      this._plAlertService.info('global.text.searchNoData');
    }
  }

  public onContextMenuPreparing(event: IDevExpressDataGridEventOnContextMenuPreparing<IJsonTributAutonomaAnalise, string>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.event.preventDefault();
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(event.row.data);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.event.target, actions).then(() => undefined);
    }
  }

  public openPOC(nConta: string): Promise<void> {
    return this._maintenanceInstancePocs.maintenanceEdit(nConta).then(() => undefined);
  }

  public periodoDeChanged(periodo: string): void {
    const year = periodo.substring(0, NUMBER_4);
    if (year !== this.header.periodoDe) {
      this.header.periodoAte = `${year}${PERIODO_DEZEMBRO_NORMAL}`;
      this.header.nomePeriodoAte = this._translateService.instant('tributAutonomas.header.perioDezembroCaption');
      this.periodosFilterAte = `loadOnlyAno=${year}`;
      this.header = copy(this.header);
    }

    this.header.periodoDe = periodo;
  }

  public openMaintenanceInstanceExtratosGrid(nconta: string): void {
    this._maintenanceInstanceExtratosGrid.maintenance({params: {nConta: nconta, dePeriodo: '', atePeriodo: ''}});
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _generateSecondaryClickActions(item: IJsonTributAutonomaAnalise): Array<IPlDynamicVisualsSecondaryClickAction> {
    return [
      {
        caption: 'tributAutonomas.actions.poc',
        click: () => this.openPOC(item.nConta)
      },
      {
        caption: 'tributAutonomas.actions.extratoConta',
        click: () => {
          this.openMaintenanceInstanceExtratosGrid(item.nConta);
        }
      }
    ];
  }

  private _printTributAutonoAnalise(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(TributAutonomasPrintModalComponent);
    const componentInstance: TributAutonomasPrintModalComponent = modalInstance.componentInstance;
    componentInstance.header = this.header;
    return modalInstance.result;
  }

  private async _manutTributAutono(): Promise<void> {
    let needRefresh = false;
    try {
      needRefresh = await this._maintenanceInstanceManutTributAutonoma.maintenance({closeOnSaved: true});
    } finally {
      const response: HttpResponse<IJsonTributAutonoAnaliseHeader> = await this._tributAutonomasService.loadDefaultAnaliseHeader();
      needRefresh = !needRefresh && this.header.modeloTributAutonoma !== response.body.modeloTributAutonoma ? true : needRefresh;
      this.header.modeloTributAutonoma = response.body.modeloTributAutonoma;
      if (needRefresh) {
        await this.search();
      }
    }
  }
}
