import {Injector} from '@angular/core';
import {DataSourcesRegistryService} from '../core/components/datasource/datasources.registry.service';
import {IDataSource} from '../core/components/datasource/input/datasource.input.component.interface';

import {DATA_SOURCE_AREAS_REGIONAIS_CONTROLO_IVA} from '../core/datasources/areasregionaiscontroloiva/areasRegionaisControloIVA.datasource';
import {DATA_SOURCE_AREAS_REGIONAIS} from '../core/datasources/areasregionais/areasRegionais.datasource';
import {DATA_SOURCE_ATIVOS_BASE_CALCULO} from '../core/datasources/ativosbasecalculo/ativosBaseCalculo.datasource';
import {DATA_SOURCE_ATIVOS_ESTADOS} from '../core/datasources/ativosestado/ativosEstado.datasource';
import {DATA_SOURCE_CALC_PR_VENDA} from '../core/datasources/calcprvenda/calcPrVenda.datasource';
import {DATA_SOURCE_CATEGORIA_PRODUTO} from '../core/datasources/categoriaproduto/categoriaProduto.datasource';
import {DATA_SOURCE_CONTINENTES} from '../core/datasources/continentes/continentes.datasource';
import {DATA_SOURCE_CRITERIOS_DISPONIVEIS} from '../core/datasources/criteriodisponiveis/criterioDisponiveis.datasource';
import {DATA_SOURCE_DEBITO_CREDITO} from '../core/datasources/debitocredito/debitoCredito.datasource';
import {DATA_SOURCE_DMRF_RUBRICA} from '../core/datasources/dmrfrubrica/dmrfRubrica.datasource';
import {DATA_SOURCE_DMRF_ZONA} from '../core/datasources/dmrfzona/dmrfZona.datasource';
import {DATA_SOURCE_ENTIDADE_HABITUAL} from '../core/datasources/entidadehabitual/entidadeHabitual.datasource';
import {DATA_SOURCE_ESTADO_CONTA} from '../core/datasources/estadoconta/estadoConta.datasource';
import {DATA_SOURCE_ESTADO_EMP} from '../core/datasources/estadoemp/estadoEmp.datasource';
import {DATA_SOURCE_ESTADO_REGISTO} from '../core/datasources/estadoregisto/estadoRegisto.datasource';
import {DATA_SOURCE_ESTADOS_VALIDACAO} from '../core/datasources/estadosvalidacao/estadosValidacao.datasource';
import {DATA_SOURCE_FATURACAO_PUBLICA_ESTADO} from '../core/datasources/faturacaopublicaestado/faturacaoPublicaEstado.datasource';
import {DATA_SOURCE_FLUXO_MONETARIO} from '../core/datasources/fluxomonetario/fluxoMonetario.datasource';
import {DATA_SOURCE_FORMA_INCREMENTO_NDOC} from '../core/datasources/formaincrementondoc/formaIncrementoNDoc.datasource';
import {DATA_SOURCE_GRUPO_ARTIGO} from '../core/datasources/grupoartigo/grupoArtigo.datasource';
import {DATA_SOURCE_GRUPO_DOC} from '../core/datasources/grupodoc/grupoDoc.datasource';
import {DATA_SOURCE_GTO_MOSTRAR_FILTER} from '../core/datasources/gto/mostrarfilter/mostrarFilter.datasource';
import {DATA_SOURCE_GTO_TIPO_TAREFA} from '../core/datasources/gto/tipotarefa/tipoTarefa.datasource';
import {DATA_SOURCE_INVERSAO_SUJ_PASSIVO} from '../core/datasources/inversaosujpassivo/inversaoSujPassivo.datasource';
import {DATA_SOURCE_INVOICE_TYPE} from '../core/datasources/invoicetype/invoiceType.datasource';
import {DATA_SOURCE_LOCAL_OBTENCAO_RENDIMENTOS} from '../core/datasources/localobtencaorendimentos/localObtencaoRendimentos.datasource';
import {DATA_SOURCE_MAPAS_OFICIAIS} from '../core/datasources/mapasoficiais/mapasOficiais.datasource';
import {DATA_SOURCE_MERCADOS} from '../core/datasources/mercados/mercados.datasource';
import {DATA_SOURCE_MESES} from '../core/datasources/meses/meses.datasource';
import {DATA_SOURCE_METODO_CALC_SALARIO} from '../core/datasources/dhemp/metodocalcsalario/metodoCalcSalario.datasource';
import {DATA_SOURCE_MODELO22_DIST_MUNICI} from '../core/datasources/modelo22/distmuni/modelo22.distMuni.datasource';
import {DATA_SOURCE_MODELO22_FACTO_IMPEDIMENTO} from '../core/datasources/modelo22/factoimpedimento/modelo22.factoImpedimento.datasource';
import {DATA_SOURCE_MODELO22_PAISES} from '../core/datasources/modelo22/paises/modelo22.paises.datasource';
import {DATA_SOURCE_MODELO22_TIPO_DONATIVO} from '../core/datasources/modelo22/tipodonativo/modelo22.tipoDonativo.datasource';
import {DATA_SOURCE_MODELO22_TIPO_IDENT_MATRICIAL} from '../core/datasources/modelo22/tipoindentmatricial/modelo22.tipoIndentMatricial.datasource';
import {DATA_SOURCE_MODELO22_TIPO_REND} from '../core/datasources/modelo22/tiporend/modelo22.tipoRend.datasource';
import {DATA_SOURCE_MOEDA_NORMAS_ISO} from '../core/datasources/moedanormasiso/moedaNormasIso.datasource';
import {DATA_SOURCE_MOTIVO_CONTRATO} from '../core/datasources/motivocontrato/motivoContrato.datasource';
import {DATA_SOURCE_NATUREZA} from '../core/datasources/natureza/natureza.datasource';
import {DATA_SOURCE_NIVEL_QUALIFICACAO} from '../core/datasources/nivelqualificacao/nivelQualificacao.datasource';
import {DATA_SOURCE_ORIGEM_COND_PAGAMENTO} from '../core/datasources/origemcondpagamento/origemCondPagamento.datasource';
import {DATA_SOURCE_ORIGEM_CONTAS_RETENCAO} from '../core/datasources/origemcontasretencao/origemContasRetencao.datasource';
import {DATA_SOURCE_ORIGEM_DATA_VENCIMENTO} from '../core/datasources/origemdatavencimento/origemDataVencimento.datasource';
import {DATA_SOURCE_PAISES_ISO_3166} from '../core/datasources/paisesiso3166/paisesISO3166.datasource';
import {DATA_SOURCE_PERIOCIDADE_CONTRATO_TIPO} from '../core/datasources/periocidadecontratotipo/periocidadeContratoTipo.datasource';
import {DATA_SOURCE_PERIODOS_IVA} from '../core/datasources/periodosiva/periodosIva.datasource';
import {DATA_SOURCE_REG_IRC} from '../core/datasources/regirc/regIRC.datasource';
import {DATA_SOURCE_REG_IVA} from '../core/datasources/regiva/regIVA.datasource';
import {DATA_SOURCE_REGIME_TRABALHO} from '../core/datasources/dhemp/regimetrabalho/regimeTrabalho.datasource';
import {DATA_SOURCE_REGIONAL_TAXA} from '../core/datasources/arearegionaltaxa/areaRegionalTaxa.datasource';
import {DATA_SOURCE_RESIDENCIA_FISCAL} from '../core/datasources/dhemp/residenciafiscal/residenciaFiscal.datasource';
import {DATA_SOURCE_SEXOS} from '../core/datasources/sexos/sexos.datasource';
import {DATA_SOURCE_SINAL_TAXA_RETENCAO} from '../core/datasources/sinaltaxaretencao/sinalTaxaRetencao.datasource';
import {DATA_SOURCE_TESOURARIA_PERIODICIDADE} from '../core/datasources/tesourariaperiodicidade/tesourariaPeriodicidade.datasource';
import {DATA_SOURCE_TIPO_ABONODESC} from '../core/datasources/tipoabonodesc/tipoAbonoDesc.datasource';
import {DATA_SOURCE_TIPO_BEM} from '../core/datasources/tipobem/tipoBem.datasource';
import {DATA_SOURCE_TIPO_CALC_DUODECIMOS} from '../core/datasources/dhemp/tipocalcduodecimos/tipoCalcDuodecimos.datasource';
import {DATA_SOURCE_TIPO_CLASSE_CONTRATO} from '../core/datasources/tipoclassecontrato/tipoClasseContrato.datasource';
import {DATA_SOURCE_TIPO_CONTA} from '../core/datasources/tipoconta/tipoConta.datasource';
import {DATA_SOURCE_TIPO_CONTRATO_TRAB} from '../core/datasources/tipocontratotrab/tipoContratoTrab.datasource';
import {DATA_SOURCE_TIPO_CONTRATO} from '../core/datasources/tipocontrato/tipoContrato.datasource';
import {DATA_SOURCE_TIPO_DEDUCAO_TAXA} from '../core/datasources/tipodeducaotaxa/tipoDeducaoTaxa.datasource';
import {DATA_SOURCE_TIPO_DEDUCAO} from '../core/datasources/tipodeducao/tipoDeducao.datasource';
import {DATA_SOURCE_TIPO_DESCRITIVO} from '../core/datasources/tipodescritivo/tipoDescritivo.datasource';
import {DATA_SOURCE_TIPO_EXCECAO_CRABD} from '../core/datasources/tipoexcecaocrabd/tipoExcecaoCRABD.datasource';
import {DATA_SOURCE_TIPO_FAC_PUBLICA_MODELO} from '../core/datasources/tipofacpublicamodelo/tipoFacPublicaModelo.datasource';
import {DATA_SOURCE_TIPO_INVENTARIO} from '../core/datasources/tipoinventario/tipoInventario.datasource';
import {DATA_SOURCE_TIPO_LIQUIDACAO} from '../core/datasources/tipoliquidacao/tipoLiquidacao.datasource';
import {DATA_SOURCE_TIPO_OPERACAO} from '../core/datasources/tipooperacao/tipoOperacao.datasource';
import {DATA_SOURCE_TIPO_ORD_DOCUMENTOS} from '../core/datasources/tiposordenacaodocumentos/tiposOrdenacaoDocs.datasource';
import {DATA_SOURCE_TIPO_ORD_VISUALIZADOR} from '../core/datasources/tiposordenacaovisualizador/tiposOrdenacaoVisualizador.datasource';
import {DATA_SOURCE_TIPO_PASSWORD_RECIBO} from '../core/datasources/tipopasswordrecibo/tipoPasswordRecibo.datasource';
import {DATA_SOURCE_TIPO_PERFIL_BALANCETES} from '../core/datasources/tipoperfilbalancetes/tipoPerfilBalancetes.datasource';
import {DATA_SOURCE_TIPO_REGIME} from '../core/datasources/tiporegime/tipoRegime.datasource';
import {DATA_SOURCE_TIPO_REGULARIZACAO_CAMPO40} from '../core/datasources/tiporegularizacaocampo40/tipoRegularizacaoCampo40.datasource';
import {DATA_SOURCE_TIPO_REGULARIZACAO_CAMPO41} from '../core/datasources/tiporegularizacaocampo41/tipoRegularizacaoCampo41.datasource';
import {DATA_SOURCE_TIPO_RESUMO_MENSAL} from '../core/datasources/tiporesumomensal/tipoResumoMensal.datasource';
import {DATA_SOURCE_TIPO_TAXA_IVA} from '../core/datasources/tipotaxaiva/tipoTaxaIva.datasource';
import {DATA_SOURCE_TIPO_TAXA_RETENCAO} from '../core/datasources/tipotaxaretencao/tipoTaxaRetencao.datasource';
import {DATA_SOURCE_TIPO_TAXA} from '../core/datasources/tipotaxa/tipoTaxa.datasource';
import {DATA_SOURCE_TIPO_TEXTO_SOLIC} from '../core/datasources/tipotextosolic/tipoTextoSolic.datasource';
import {DATA_SOURCE_TIPO_TRANSMISSAO_ATIVA} from '../core/datasources/tipotransmissaoactiva/tipoTransmissaoActiva.datasource';
import {DATA_SOURCE_TIPO_TRANSMISSAO_PASSIVA} from '../core/datasources/tipotransmissaopassiva/tipoTransmissaoPassiva.datasource';
import {DATA_SOURCE_TIPO_UNIDADES} from '../core/datasources/tipounidades/tipoUnidades.datasource';
import {DATA_SOURCE_TIPO_VALIDACAO_NIF} from '../core/datasources/tipovalidacaonif/tipoValidacaoNif.datasource';
import {DATA_SOURCE_TIPO_VALOR_PENHORA} from '../core/datasources/tipovalorpenhora/tipoValorPenhora.datasource';
import {DATA_SOURCE_TIPOS_ANALITICA} from '../core/datasources/tipoanalitica/tipoAnalitica.datasource';
import {DATA_SOURCE_TIPOS_ANEXO} from '../core/datasources/tiposanexo/tiposAnexo.datasource';
import {DATA_SOURCE_TIPOS_ARREDONDAMENTO_ABDES} from '../core/datasources/tipoarredondamentoabdes/tipoArredondamentoABDES.datasource';
import {DATA_SOURCE_TIPOS_AUSENCIAS} from '../core/datasources/tipoausencias/tipoAusencias.datasource';
import {DATA_SOURCE_TIPOS_CATEGORIA_ABONOS} from '../core/datasources/tipocategoria/tipoCategoria.datasource';
import {DATA_SOURCE_TIPOS_CATEGORIA_DESCONTOS} from '../core/datasources/tipocategoriadesc/tipoCategoriaDesc.datasource';
import {DATA_SOURCE_TIPOS_COMUNICACAO_AT} from '../core/datasources/tipocomunicacaoat/tiposComunicacaoAt.datasource';
import {DATA_SOURCE_TIPOS_CONTA_DIAS_SUB_ALIMEN} from '../core/datasources/tipodiascontasubalimen/tipoContaDiasSubAlimen.datasource';
import {DATA_SOURCE_TIPOS_CONTABILIDADE} from '../core/datasources/tipocontabilidade/tipoContabilidade.datasource';
import {DATA_SOURCE_TIPOS_CREDITO} from '../core/datasources/tiposcredito/tiposCredito.datasource';
import {DATA_SOURCE_TIPOS_DESCONTO_PROPORCIONAL_ABDES} from '../core/datasources/tipodescontoproporcionalabdes/tipoDescontoProporcionalABDES.datasource';
import {DATA_SOURCE_TIPOS_EMPRESA} from '../core/datasources/tiposempresa/tiposEmpresa.datasource';
import {DATA_SOURCE_TIPOS_ENT_SUPORTE_ABDES} from '../core/datasources/tipoentsuporteabdes/tipoEntSuporteABDES.datasource';
import {DATA_SOURCE_TIPOS_IVA} from '../core/datasources/tiposiva/tiposIVA.datasource';
import {DATA_SOURCE_TIPOS_MOVIMENTO} from '../core/datasources/tiposmovimento/tiposMovimento.datasource';
import {DATA_SOURCE_TIPOS_NIF} from '../core/datasources/tiposnif/tiposNIF.datasource';
import {DATA_SOURCE_TIPOS_PERIOCIDADE} from '../core/datasources/tipoperiocidade/tipoPeriocidade.datasource';
import {DATA_SOURCE_TIPOS_POC} from '../core/datasources/tipospoc/tiposPoc.datasource';
import {DATA_SOURCE_TIPOS_REFEICOES} from '../core/datasources/tiposrefeicoes/tiposRefeicoes.datasource';
import {DATA_SOURCE_TIPOS_REMUNERADO} from '../core/datasources/tiporemunerado/tipoRemunerado.datasource';
import {DATA_SOURCE_TIPOS_RET_SERV_NIF} from '../core/datasources/tiposretservnif/tiposRetServNIF.datasource';
import {DATA_SOURCE_TIPOS_VALOR_UNITARIO_RECOLHER} from '../core/datasources/tipovalorunitariorecolher/tipoValorUnitarioRecolher.datasource';
import {DATA_SOURCE_TIPOS_VALORIZACAO} from '../core/datasources/tipovalorizacao/tipoValorizacao.datasource';
import {DATA_SOURCE_PROC_AUTO_TIPO} from '../core/datasources/procautotipo/procAutoTipo.datasource';
import {DATA_SOURCE_POC_ALT_TIPO_VALIDACAO} from '../core/datasources/pocalttipovalidacao/pocAltTipoValidacao.datasource';
import {DATA_SOURCE_TIPOS_DECLARACAO} from '../core/datasources/tiposdeclaracao/tiposDeclaracao.datasource';
import {DATA_SOURCE_DIVIS_TIPO} from '../core/datasources/divistipo/divistipo.datasource';
import {DATA_SOURCE_MESES_ABREVIADO} from '../core/datasources/mesesAbreviado/mesesAbreviado.datasource';
import {DATA_SOURCE_TIPO_ABONO_AGRUPADO} from '../core/datasources/tipoabonoagrupado/tipoabonoagrupado.datasource';
import {DATA_SOURCE_TIPO_DESCONTO_AGRUPADO} from '../core/datasources/tipodescontoagrupado/tipodescontoagrupado.datasource';
import {DATA_SOURCE_TIPO_COM_CATEGORIA} from '../core/datasources/tipocomcategoria/tipoComCategoria.datasource';
import {DATA_SOURCE_TIPOS_CONTA_DIAS_FERIAS_NORM} from '../core/datasources/tipocontadiasferiasnorm/tipoContaDiasFeriasNorm.datasource';
import {DATA_SOURCE_PERIODICIDADE} from '../core/datasources/periodicidade/periodicidade.datasource';
import {DATA_SOURCE_LOCAL_TRIBUTACAO_RENDIMENTO} from '../core/datasources/localtributacaorendimento/localTributacaoRendimento.datasource';

const DATA_SOURCES: Array<IDataSource<unknown, unknown>> = [
  DATA_SOURCE_AREAS_REGIONAIS,
  DATA_SOURCE_AREAS_REGIONAIS_CONTROLO_IVA,
  DATA_SOURCE_ATIVOS_BASE_CALCULO,
  DATA_SOURCE_ATIVOS_ESTADOS,
  DATA_SOURCE_CALC_PR_VENDA,
  DATA_SOURCE_CATEGORIA_PRODUTO,
  DATA_SOURCE_CONTINENTES,
  DATA_SOURCE_CRITERIOS_DISPONIVEIS,
  DATA_SOURCE_DEBITO_CREDITO,
  DATA_SOURCE_DIVIS_TIPO,
  DATA_SOURCE_DMRF_RUBRICA,
  DATA_SOURCE_DMRF_ZONA,
  DATA_SOURCE_ENTIDADE_HABITUAL,
  DATA_SOURCE_ESTADO_CONTA,
  DATA_SOURCE_ESTADO_EMP,
  DATA_SOURCE_ESTADO_REGISTO,
  DATA_SOURCE_ESTADOS_VALIDACAO,
  DATA_SOURCE_FATURACAO_PUBLICA_ESTADO,
  DATA_SOURCE_FLUXO_MONETARIO,
  DATA_SOURCE_FORMA_INCREMENTO_NDOC,
  DATA_SOURCE_GRUPO_ARTIGO,
  DATA_SOURCE_GRUPO_DOC,
  DATA_SOURCE_GTO_MOSTRAR_FILTER,
  DATA_SOURCE_GTO_TIPO_TAREFA,
  DATA_SOURCE_INVERSAO_SUJ_PASSIVO,
  DATA_SOURCE_INVOICE_TYPE,
  DATA_SOURCE_LOCAL_OBTENCAO_RENDIMENTOS,
  DATA_SOURCE_LOCAL_TRIBUTACAO_RENDIMENTO,
  DATA_SOURCE_MAPAS_OFICIAIS,
  DATA_SOURCE_MERCADOS,
  DATA_SOURCE_MESES,
  DATA_SOURCE_MESES_ABREVIADO,
  DATA_SOURCE_METODO_CALC_SALARIO,
  DATA_SOURCE_MODELO22_DIST_MUNICI,
  DATA_SOURCE_MODELO22_FACTO_IMPEDIMENTO,
  DATA_SOURCE_MODELO22_PAISES,
  DATA_SOURCE_MODELO22_TIPO_DONATIVO,
  DATA_SOURCE_MODELO22_TIPO_IDENT_MATRICIAL,
  DATA_SOURCE_MODELO22_TIPO_REND,
  DATA_SOURCE_MOEDA_NORMAS_ISO,
  DATA_SOURCE_MOTIVO_CONTRATO,
  DATA_SOURCE_NATUREZA,
  DATA_SOURCE_NIVEL_QUALIFICACAO,
  DATA_SOURCE_ORIGEM_COND_PAGAMENTO,
  DATA_SOURCE_ORIGEM_CONTAS_RETENCAO,
  DATA_SOURCE_ORIGEM_DATA_VENCIMENTO,
  DATA_SOURCE_PAISES_ISO_3166,
  DATA_SOURCE_PERIOCIDADE_CONTRATO_TIPO,
  DATA_SOURCE_PERIODICIDADE,
  DATA_SOURCE_PERIODOS_IVA,
  DATA_SOURCE_POC_ALT_TIPO_VALIDACAO,
  DATA_SOURCE_PROC_AUTO_TIPO,
  DATA_SOURCE_REG_IRC,
  DATA_SOURCE_REG_IVA,
  DATA_SOURCE_REGIME_TRABALHO,
  DATA_SOURCE_REGIONAL_TAXA,
  DATA_SOURCE_RESIDENCIA_FISCAL,
  DATA_SOURCE_SEXOS,
  DATA_SOURCE_SINAL_TAXA_RETENCAO,
  DATA_SOURCE_TESOURARIA_PERIODICIDADE,
  DATA_SOURCE_TIPO_ABONO_AGRUPADO,
  DATA_SOURCE_TIPO_ABONODESC,
  DATA_SOURCE_TIPO_BEM,
  DATA_SOURCE_TIPO_CALC_DUODECIMOS,
  DATA_SOURCE_TIPO_CLASSE_CONTRATO,
  DATA_SOURCE_TIPO_COM_CATEGORIA,
  DATA_SOURCE_TIPO_CONTA,
  DATA_SOURCE_TIPO_CONTRATO,
  DATA_SOURCE_TIPO_CONTRATO_TRAB,
  DATA_SOURCE_TIPO_DEDUCAO,
  DATA_SOURCE_TIPO_DEDUCAO_TAXA,
  DATA_SOURCE_TIPO_DESCRITIVO,
  DATA_SOURCE_TIPO_DESCONTO_AGRUPADO,
  DATA_SOURCE_TIPO_EXCECAO_CRABD,
  DATA_SOURCE_TIPO_FAC_PUBLICA_MODELO,
  DATA_SOURCE_TIPO_INVENTARIO,
  DATA_SOURCE_TIPO_LIQUIDACAO,
  DATA_SOURCE_TIPO_OPERACAO,
  DATA_SOURCE_TIPO_ORD_DOCUMENTOS,
  DATA_SOURCE_TIPO_ORD_VISUALIZADOR,
  DATA_SOURCE_TIPO_PASSWORD_RECIBO,
  DATA_SOURCE_TIPO_PERFIL_BALANCETES,
  DATA_SOURCE_TIPO_REGIME,
  DATA_SOURCE_TIPO_REGULARIZACAO_CAMPO40,
  DATA_SOURCE_TIPO_REGULARIZACAO_CAMPO41,
  DATA_SOURCE_TIPO_RESUMO_MENSAL,
  DATA_SOURCE_TIPO_TAXA,
  DATA_SOURCE_TIPO_TAXA_IVA,
  DATA_SOURCE_TIPO_TAXA_RETENCAO,
  DATA_SOURCE_TIPO_TEXTO_SOLIC,
  DATA_SOURCE_TIPO_TRANSMISSAO_ATIVA,
  DATA_SOURCE_TIPO_TRANSMISSAO_PASSIVA,
  DATA_SOURCE_TIPO_UNIDADES,
  DATA_SOURCE_TIPO_VALIDACAO_NIF,
  DATA_SOURCE_TIPO_VALOR_PENHORA,
  DATA_SOURCE_TIPOS_ANALITICA,
  DATA_SOURCE_TIPOS_ANEXO,
  DATA_SOURCE_TIPOS_ARREDONDAMENTO_ABDES,
  DATA_SOURCE_TIPOS_AUSENCIAS,
  DATA_SOURCE_TIPOS_CATEGORIA_ABONOS,
  DATA_SOURCE_TIPOS_CATEGORIA_DESCONTOS,
  DATA_SOURCE_TIPOS_COMUNICACAO_AT,
  DATA_SOURCE_TIPOS_CONTA_DIAS_FERIAS_NORM,
  DATA_SOURCE_TIPOS_CONTA_DIAS_SUB_ALIMEN,
  DATA_SOURCE_TIPOS_CONTABILIDADE,
  DATA_SOURCE_TIPOS_CREDITO,
  DATA_SOURCE_TIPOS_DECLARACAO,
  DATA_SOURCE_TIPOS_DESCONTO_PROPORCIONAL_ABDES,
  DATA_SOURCE_TIPOS_EMPRESA,
  DATA_SOURCE_TIPOS_ENT_SUPORTE_ABDES,
  DATA_SOURCE_TIPOS_IVA,
  DATA_SOURCE_TIPOS_MOVIMENTO,
  DATA_SOURCE_TIPOS_NIF,
  DATA_SOURCE_TIPOS_PERIOCIDADE,
  DATA_SOURCE_TIPOS_PERIOCIDADE,
  DATA_SOURCE_TIPOS_POC,
  DATA_SOURCE_TIPOS_REFEICOES,
  DATA_SOURCE_TIPOS_REMUNERADO,
  DATA_SOURCE_TIPOS_RET_SERV_NIF,
  DATA_SOURCE_TIPOS_RET_SERV_NIF,
  DATA_SOURCE_TIPOS_VALOR_UNITARIO_RECOLHER,
  DATA_SOURCE_TIPOS_VALORIZACAO
];

export function configDataSources(injector: Injector): void {
  const dataSourcesRegistryService: DataSourcesRegistryService = injector.get<DataSourcesRegistryService>(DataSourcesRegistryService);
  for (const dataSource of DATA_SOURCES) {
    dataSourcesRegistryService.register(dataSource);
  }
}
