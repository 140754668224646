<div class="conttrabbaselegal">
  <entity-detail
    entityName="conttrabbaselegal"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [formOrientation]="formOrientation"
    [callback]="callback"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{disabled: type === stateTypes.DETAIL, orientation: formOrientation}">
        <pl-group>
          <label [translate]="'conttrabbaselegal.fields.contTrabBaseLegal'"></label>
          <edit>
            <pl-edit type="cgsmallint" attrName="contTrabBaseLegal" [(model)]="model.contTrabBaseLegal" [properties]="{readonly: type === stateTypes.EDIT, validators: {required: {value: true}}}">
            </pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'conttrabbaselegal.fields.descricao'"></label>
          <edit>
            <pl-edit type="text" attrName="descricao" [(model)]="model.descricao" [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'conttrabbaselegal.fields.tipoContrato'"></label>
          <edit>
            <pl-edit type="tipoContrato" attrName="tipoContrato" [model]="model.tipoContrato" (modelChange)="model.tipoContrato = $event; checkTipoContrato()"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.duracaoMinima'"></label>
            <edit>
              <pl-edit type="number" attrName="duracaoMinima" [(model)]="model.duracaoMinima" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.duracaoMaxima'"></label>
            <edit>
              <pl-edit type="number" attrName="duracaoMaxima" [(model)]="model.duracaoMaxima" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.duracaoMaxPrimEmpreg'"></label>
            <edit>
              <pl-edit type="number" attrName="duracaoMaxPrimEmpreg" [(model)]="model.duracaoMaxPrimEmpreg" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.dilacaoNovoContrato'"></label>
            <edit>
              <pl-edit type="number" attrName="dilacaoNovoContrato" [(model)]="model.dilacaoNovoContrato" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.numRenovNormal'"></label>
            <edit>
              <pl-edit type="number" attrName="numRenovNormal" [(model)]="model.numRenovNormal" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.numRenovExcepcionais'"></label>
            <edit>
              <pl-edit type="number" attrName="numRenovExcepcionais" [(model)]="model.numRenovExcepcionais" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.passagemSemTermo'"></label>
            <edit>
              <pl-edit type="number" attrName="passagemSemTermo" [(model)]="model.passagemSemTermo" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.contTBLNovaSeq'"></label>
            <edit>
              <pl-edit type="number" attrName="contTBLNovaSeq" [(model)]="model.contTBLNovaSeq" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.periodoExpNormalInd'"></label>
            <edit>
              <pl-edit type="number" attrName="periodoExpNormalInd" [(model)]="model.periodoExpNormalInd" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.periodoExpTecnicoInd'"></label>
            <edit>
              <pl-edit type="number" attrName="periodoExpTecnicoInd" [(model)]="model.periodoExpTecnicoInd" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.periodoExpSuperioInd'"></label>
            <edit>
              <pl-edit type="number" attrName="periodoExpSuperioInd" [(model)]="model.periodoExpSuperioInd" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.periodoExpTermoCerto'"></label>
            <edit>
              <pl-edit type="number" attrName="periodoExpTermoCerto" [(model)]="model.periodoExpTermoCerto" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.preAvisoDenunNormal'"></label>
            <edit>
              <pl-edit type="number" attrName="preAvisoDenunNormal" [(model)]="model.preAvisoDenunNormal" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.preAvisoDenunTecnico'"></label>
            <edit>
              <pl-edit type="number" attrName="preAvisoDenunTecnico" [(model)]="model.preAvisoDenunTecnico" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.preAvisoDenunSuperio'"></label>
            <edit>
              <pl-edit type="number" attrName="preAvisoDenunSuperio" [(model)]="model.preAvisoDenunSuperio" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.preAvisoDenunTermoC'"></label>
            <edit>
              <pl-edit type="number" attrName="preAvisoDenunTermoC" [(model)]="model.preAvisoDenunTermoC" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.feriasPorMesCompl'"></label>
            <edit>
              <pl-edit type="number" attrName="feriasPorMesCompl" [(model)]="model.feriasPorMesCompl" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.feriasMaximo'"></label>
            <edit>
              <pl-edit type="number" attrName="feriasMaximo" [(model)]="model.feriasMaximo" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.aviDesvincTrab'"></label>
            <edit>
              <pl-edit type="number" attrName="aviDesvincTrab" [(model)]="model.aviDesvincTrab" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.aviCaducidadeTrab'"></label>
            <edit>
              <pl-edit type="number" attrName="aviCaducidadeTrab" [(model)]="model.aviCaducidadeTrab" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.aviCaducidadeEmpresa'"></label>
            <edit>
              <pl-edit type="number" attrName="aviCaducidadeEmpresa" [(model)]="model.aviCaducidadeEmpresa" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'conttrabbaselegal.fields.compCaducidadeEmpre'"></label>
            <edit>
              <pl-edit type="number" attrName="compCaducidadeEmpre" [(model)]="model.compCaducidadeEmpre" [properties]="{disabled: !haveTipoContrato}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
