import {IDocContabilidadeCallback, TDocContabilidadeFocusField} from '../docscontabilidade/components/doccontabilidade/docContabilidade.interface';
import {IJsonDocContabilidade} from '../docscontabilidade/jsonDocContabilidade.interface';
import {IJsonRecibosRendasItem} from './jsonATRecibosRendas.interface';
import {IJsonPreDefinidoContab} from '../manutencao/predefinidos/jsonPreDefinidosContab.entity.interface';
import {TDate} from '../../../../common/dates';
import {TTableLegend} from '../../../components/tablelegend/tablelegend.component.interface';
import {deepFreeze} from 'pl-comps-angular';
import {EDataGridLegendCellColors} from '../../../components/devexpress/datagrid/configuration/devexpress.datagrid.configuration';

export interface IRRImportDocApiList {
  list: Array<IRRImportDoc>;
  listLancadosIgnorados: Array<IRRImportDoc>;
}

export interface IRRImportDoc extends IJsonRecibosRendasItem {
  errorText?: string;
  docModel?: IJsonDocContabilidade;
  preDefinidoContab?: IJsonPreDefinidoContab;
  docLoadFocusField?: TDocContabilidadeFocusField;
  docCallback?: IDocContabilidadeCallback;
  nifLocatario?: string;
  reciboId?: number;
  dataEmissao?: TDate;
  taxaIVAStr?: string;
  reciboPeriodo?: string;
}

export interface IATRecibosRendasFilter {
  fromDate: TDate;
  toDate: TDate;
}

export interface IATRecibosRendasDateFilters {
  dataDe: TDate;
  dataAte: TDate;
}

export interface IATRecibosRendasResultFindInSource {
  index: number;
  item: IRRImportDoc | null;
}

export const MODULE_NAME_AT_RECIBOS_RENDAS = 'atRecibosRendas';

export const TABLE_LEGEND_DOCUMENTOS_JA_LANACADOS: TTableLegend = deepFreeze([
  {
    caption: 'atRecibosRendas.tabJaLancadosCaptionContainer.legends.documentoIgnorado',
    badgeCSSClass: EDataGridLegendCellColors.YellowLegendColor
  },
  {
    caption: 'atRecibosRendas.tabJaLancadosCaptionContainer.legends.documentoFornecedorIgnorado',
    badgeCSSClass: EDataGridLegendCellColors.BlueLegendColor
  }
]);
