<div class="mapa-pagamentos-pagamento-modal">
  <div class="modal-header">
    <h5 class="modal-title" [translate]="'mapapagam.texts.visualizacaoDocumento'"></h5>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <pl-group *ngIf="viewMode === viewModeType.DOCUMENTO">
      <label [translate]="'mapapagam.texts.documento'"></label>
      <edit>
        <pl-edit-select [model]="docSelected" (modelChange)="onDocumentChange($event)" [properties]="{select: {list: documentosSource}, disallowClear: true}"></pl-edit-select>
      </edit>
    </pl-group>

    <ng-container *ngIf="!documentoEmitido">
      <div class="alert alert-warning">
        <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;
        <span [translate]="'mapapagam.texts.naoPossivelVisualizarDocNaoEmitido'" [translateParams]="{estado: documentoEstado}"></span>
      </div>
    </ng-container>

    <ng-container *ngIf="!documentoEmitido && documentoIsIUC">
      <div class="alert alert-info">
        <div><i class="fa fa-info-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'mapapagam.texts.podeEmitirDocNosDocsFiscais'"></span></div>
        <button class="btn btn-sm btn-light mt-2" type="button" (click)="showDocumentosFiscais()"><span [translate]="'mapapagam.texts.documentosFiscais'"></span></button>
      </div>
    </ng-container>

    <ng-container *ngIf="documentoEmitido">
      <cg-pdf-viewer [filename]="pdfFilename" [pdfSrc]="pdfSrc"></cg-pdf-viewer>
    </ng-container>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <button *ngIf="isExternalDocument" type="button" class="btn btn-sm btn-light me-auto" (click)="forceReload()">
      <i class="fa fa-fw fa-refresh"></i>&nbsp;<span [translate]="'mapapagam.texts.recarregar'"></span>
    </button>
    <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</div>
