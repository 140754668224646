import type {TranslateService} from '@ngx-translate/core';
import {API_PORTAL_PREFIX} from '../../../config/constants';
import {APPLICATION_ICON_PREFIXES} from '../../../config/constants';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IJsonPortal, IJsonPortalSortItem} from './jsonPortal.entity.interface';
import {ROLE} from '../../services/role.const';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface IPortalEntityService extends IEntityService<IJsonPortal> {
  sortPortals(sortItems: Array<IJsonPortalSortItem>): TServiceResponse<void>;
}

export interface IPortalEntity extends IEntityDefinition<IJsonPortal, IPortalEntityService> {}

export interface IPortalModule {
  name: string; // Module or entity name
  title?: string; // Deprecated field
  pageTitle?: string;
  sidebarTitle?: string;
  icon?: string;
  roles?: Array<ROLE>;
  visible?: boolean;
  items?: Array<IPortalModule>;
}

export const ENTITY_NAME_PORTALS = 'portals';

export const PORTALS_IGNORED_ROLES: ReadonlyArray<ROLE> = Object.freeze([ROLE.ANY, ROLE.API, ROLE.REGEX_ROLE]);

export function sortPortals<T extends Array<{sortOrder: number}>>(list: T): T {
  return list.sort((a, b) => {
    if (!a.sortOrder && !b.sortOrder) {
      return 0;
    }
    if (!a.sortOrder) {
      return 1;
    }
    if (!b.sortOrder) {
      return -1;
    }
    return a.sortOrder - b.sortOrder;
  });
}

export function trimPortalModuleIcons(icon: string): string {
  if (!icon) {
    return icon;
  }
  const icons: Array<string> = icon.split(' ');
  for (let i = icons.length - 1; i >= 0; i--) {
    const value: string = icons[i];
    if (APPLICATION_ICON_PREFIXES.includes(value)) {
      icons.splice(i, 1);
    }
  }
  return icons.join(' ');
}

export function evaluatePortalTitle(portal: IJsonPortal, translateService: TranslateService): string {
  const textKey = `portals.items.${portal.url}`;
  let text: string = translateService.instant(textKey);
  if (text === textKey) {
    text = portal.name || portal.description || String(portal.id);
  }
  return text;
}

export function portalIgnoreRole(role: ROLE): boolean {
  return PORTALS_IGNORED_ROLES.includes(role) || role.startsWith(API_PORTAL_PREFIX);
}
