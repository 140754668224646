import {Injectable} from '@angular/core';
import {IApiQueryRequestConfig, TServiceQueryResponse, TServiceResponse} from '../../../services/api/api.service.interface';
import {ApiService} from '../../../services/api/api.service';
import {MODULE_NAME_TABELAS_IRS} from './tabelasIRS.module.interface';
import {IJsonIrsDt, IJsonTirs, IJsonTIRSDT} from './jsonTabelasIRS.module.interface';
import {TDate} from '../../../../common/dates';
import {IJsonDate} from '../../../../common/interfaces/json';

@Injectable({
  providedIn: 'root'
})
export class TabelasIRSModuleService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/${MODULE_NAME_TABELAS_IRS}`;
  }

  public query(config?: IApiQueryRequestConfig): TServiceQueryResponse<IJsonTirs> {
    return this._apiService.query<IJsonTirs>({
      url: this._path,
      ...config
    });
  }

  public get(nTabIRS: string): TServiceResponse<Array<IJsonTirs>> {
    return this._apiService.get<Array<IJsonTirs>>({
      url: `${this._path}/${nTabIRS}`
    });
  }

  public getDistinctDatasEscaloes(nTabIRS: string): TServiceResponse<Array<IJsonDate>> {
    return this._apiService.get<Array<IJsonDate>>({
      url: `${this._path}/${nTabIRS}/distinctdatasescaloes`
    });
  }

  public getTableIRSEscaloesByDate(nTabIRS: string, date: TDate): TServiceResponse<Array<IJsonTIRSDT>> {
    return this._apiService.get<Array<IJsonTIRSDT>>({
      url: `${this._path}/${nTabIRS}/escaloespordata`,
      params: {
        date: date
      }
    });
  }

  public getIRSDT(nTabIrsDt: string, tabIRSDT: TDate, valorLiq: string): TServiceResponse<Array<IJsonIrsDt>> {
    return this._apiService.get<Array<IJsonIrsDt>>({
      url: `${this._path}/irsdt/${nTabIrsDt}`,
      params: {
        tabirsdt: tabIRSDT,
        valorliq: valorLiq
      }
    });
  }
}
