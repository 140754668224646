import {ClassProvider, forwardRef, Injectable} from '@angular/core';
import {DI_AMALIA_INTENT_HANDLERS} from '../../../../../services/amalia/intent/amalia.intent.handler.di';
import {IAmaliaIntent, IAmaliaIntentHandler} from '../../../../../services/amalia/intent/amalia.intent.handler.interface';
import {CGStateService} from '../../../../../components/state/cg.state.service';
import {EPortal} from '../../../../../../common/enums/portals.enums';
import {IContabilidadeMovimentosEmAbertoStateParams, MODULE_NAME_PCA_ESTATISTICA_MOVIMENTOS_EM_ABERTO} from '../contabilidade.movimentosEmAberto.module.interface';
import {AMALIA_INTENT_NAME_MOVIMENTOS_ABERTO} from './movimentoemaberto.amalia.interface';

export const AMALIA_INTENT_HANDLER_MOVIMENTOS_ABERTO: ClassProvider = {
  provide: DI_AMALIA_INTENT_HANDLERS,
  multi: true,
  useClass: forwardRef(() => MovimentosEmAbertoAmaliaIntentHandler)
};

@Injectable()
export class MovimentosEmAbertoAmaliaIntentHandler implements IAmaliaIntentHandler<IContabilidadeMovimentosEmAbertoStateParams> {
  constructor(private readonly _cgStateService: CGStateService) {}

  public intentName(): string {
    return AMALIA_INTENT_NAME_MOVIMENTOS_ABERTO;
  }

  public async intentHandler(intent: IAmaliaIntent<IContabilidadeMovimentosEmAbertoStateParams>): Promise<void> {
    return this._cgStateService.redirectToState({
      stateOrName: MODULE_NAME_PCA_ESTATISTICA_MOVIMENTOS_EM_ABERTO,
      portal: EPortal.CONTABILIDADE,
      exactMatch: true,
      transitionOptions: {reload: true},
      params: {
        query: intent.parameters?.query,
        nConta: intent.parameters?.nConta
      }
    });
  }
}
