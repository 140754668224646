import moment from 'moment';
import {HttpResponse} from '@angular/common/http';
import {Transition} from '@uirouter/core';
import {isObject, TValueOrPromise} from 'pl-comps-angular';
import {AtivosEditComponent} from './components/edit/ativos.entity.edit.component';
import {AtivosListComponent} from './components/list/ativos.entity.list.component';
import {AtivosNewComponent} from './components/new/ativos.entity.new.component';
import {AtivosService} from './services/ativos.service';
import {CGModalService} from '../../components/cg/modal/cgmodal.service';
import {DATA_SOURCE_NAME_ATIVOS_BASE_CALCULO} from '../../datasources/ativosbasecalculo/ativosBaseCalculo.datasource.interface';
import {DATA_SOURCE_NAME_ATIVOS_ESTADOS} from '../../datasources/ativosestado/ativosEstado.datasource.interface';
import {EAtivosCommandType, ENTITY_NAME_ATIVOS, IAtivosEntityDefinition, IAtivosNewStateParams} from './interfaces/ativos.entity.interface';
import {EAtivosOrigem, IJsonAtivos, IJsonAtivosTotals} from './interfaces/jsonAtivos.entity.interface';
import {ENTITY_NAME_MAPAS_AMORTIZACAO} from '../mapasAmortizacao/mapasAmortizacao.entity.interface';
import {IApiRequestConfig, TServiceResponse} from '../../services/api/api.service.interface';
import {IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityDeletePromptFactoryArgs} from '../../components/entity/entity.definition.interface';
import {IRestAtivosCommand, TJsonAtivosCommandResponse} from './interfaces/restAtivosCommands.interface';
import {minDateCG} from '../../../common/utils/utils';
import {ROLE} from '../../services/role.const';

export const ENTITY_ATIVOS: IAtivosEntityDefinition = {
  name: ENTITY_NAME_ATIVOS,
  roles: [ROLE.ATIVOS, ROLE.CONTABILIDADE],
  icon: 'fa-file-text',
  searchPlaceholder: 'ativos.pesquisa',
  metadata: {
    keyName: 'codAtivo',
    fields: [
      {name: 'codAtivo', type: 'cginteger', caption: 'ativos.fields.codAtivo'},
      {name: 'designacao', caption: 'ativos.fields.designacao', validators: {maxlength: 150}},
      {name: 'refExterna', caption: 'ativos.fields.refExterna'},
      {
        name: 'estado',
        type: DATA_SOURCE_NAME_ATIVOS_ESTADOS,
        caption: 'ativos.fields.estado',
        properties: {
          devExpress: {
            dataGrid: {
              lookup: {
                cgDataSource: DATA_SOURCE_NAME_ATIVOS_ESTADOS
              }
            }
          }
        }
      },
      {
        name: 'dataEstado',
        type: 'date',
        caption: 'ativos.fields.dataEstado',
        properties: {
          devExpress: {
            dataGrid: {
              customizeText: (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string => {
                if (cellInfo.target === 'row' && moment(cellInfo.value).isSameOrBefore(minDateCG(), 'date')) {
                  return '';
                }
                return cellInfo.valueText;
              }
            }
          }
        }
      },
      {name: 'codAtivoPai', type: 'cginteger', caption: 'ativos.fields.codAtivoPai'},
      {name: 'ignoraImpostosDif', type: 'boolean', caption: 'ativos.fields.ignoraImpostosDif'},
      {name: 'origem', type: 'cgsmallint', caption: 'ativos.fields.origem'},
      {name: 'depreciacoesEstado', type: 'cgsmallint', caption: 'ativos.fields.depreciacoesEstado'},
      {name: 'depreciacoesLastAno', type: 'cgsmallint', caption: 'ativos.fields.depreciacoesLastAno'},
      {name: 'depreciacoesLastMes', type: 'cgsmallint', caption: 'ativos.fields.depreciacoesLastMes'},
      {
        name: 'aquisicao',
        entity: {name: 'ativosAquisicao', keyTarget: 'aquisicao', outputTarget: 'aquisicao'}
      },
      {
        name: 'valorAquisicaoContab',
        caption: 'ativos.fields.header.valorAquisicao',
        tdClass: 'text-end',
        type: 'double',
        properties: {decimalsLimit: 2}
      },
      {
        name: 'valorDepreciacaoContab',
        caption: 'ativos.fields.header.depAcumulada',
        tdClass: 'text-end',
        type: 'double',
        properties: {decimalsLimit: 2}
      },
      {
        name: 'valorLiquidoContab',
        caption: 'ativos.fields.header.valorLiquido',
        tdClass: 'text-end',
        type: 'double',
        properties: {decimalsLimit: 2}
      },
      {name: 'dataAquisicao', caption: 'ativosAquisicao.fields.dataAquisicao', type: 'date'},
      {name: 'contabTaxaAmortiza', caption: 'ativos.fields.header.contabTaxaAmortiza', type: 'number'},
      {name: 'fiscalTaxaAmortiza', caption: 'ativos.fields.header.fiscalTaxaAmortiza', type: 'number'},
      {name: 'contabBaseCalculo', caption: 'ativos.fields.header.contabBaseCalculo', type: DATA_SOURCE_NAME_ATIVOS_BASE_CALCULO},
      {name: 'fiscalBaseCalculo', caption: 'ativos.fields.header.fiscalBaseCalculo', type: DATA_SOURCE_NAME_ATIVOS_BASE_CALCULO},
      {
        name: 'nMapaAmortizacao',
        caption: 'ativosAquisicao.fields.nMapaAmortizacao',
        entity: {name: ENTITY_NAME_MAPAS_AMORTIZACAO, keyTarget: 'nMapaAmortizacao', fieldsMap: {nImmap: 'nMapaAmortizacao'}}
      },
      {
        name: 'codAmortizacao',
        caption: 'ativosAquisicao.fields.codAmortizacao',
        entity: {name: 'amortizacao', keyTarget: 'codAmortizacao', fieldsMap: {nImtab: 'codAmortizacao'}}
      }
    ],
    order: 'codAtivo desc',
    searchFields: 'codAtivo,designacao,estado,nMapaAmortizacao,codAmortizacao,dataAquisicao,contabTaxaAmortiza,fiscalTaxaAmortiza,contabBaseCalculo,fiscalBaseCalculo',
    listFields: 'codAtivo,designacao,estado,dataEstado,valorAquisicaoContab,valorDepreciacaoContab,valorLiquidoContab'
  },
  autocomplete: {
    rowTemplate: '{{codAtivo}} - {{designacao}}',
    output: 'designacao',
    searchFields: 'codAtivo,designacao'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      component: AtivosListComponent,
      resolve: [
        {
          provide: 'ativosTotals',
          deps: [AtivosService],
          useFactory: (ativosService: AtivosService): Promise<Array<IJsonAtivosTotals>> => {
            return ativosService.getAtivosTotals().then((response: HttpResponse<Array<IJsonAtivosTotals>>) => response.body);
          }
        }
      ]
    }
  },
  detail: {
    state: {
      component: AtivosEditComponent
    }
  },
  new: {
    state: {
      component: AtivosNewComponent,
      resolve: [
        {
          provide: 'model',
          deps: [AtivosService, Transition],
          useFactory: (ativosService: AtivosService, transition: Transition): TValueOrPromise<IJsonAtivos> => {
            const params: IAtivosNewStateParams = <IAtivosNewStateParams>transition.params();
            if (isObject(params)) {
              if (isObject(params.model)) {
                if (!params.model.codAtivo) {
                  params.model.codAtivo = 0;
                }
                return params.model;
              }
              if (isObject(params.defaultData)) {
                return params.defaultData;
              }
            }
            return ativosService.commandDefaultData(ativosService.emptyAtivo());
          }
        }
      ],
      params: {
        defaultData: {
          type: 'any',
          value: null
        },
        contabValorAquisicao: {
          type: 'string',
          value: '0'
        },
        docExtPocCabId: {
          type: 'string',
          value: ''
        },
        valorInvEmCursoDisponivelAnoN: {
          type: 'string',
          value: ''
        },
        valorInvEmCursoDisponivelAnoN1: {
          type: 'string',
          value: ''
        },
        fromInvestimentosEmCurso: {
          type: 'bool',
          value: false
        }
      }
    }
  },
  service: function () {
    this.sendAtivosCommand = <T>(id: number, commandName: EAtivosCommandType, command: IRestAtivosCommand): TServiceResponse<TJsonAtivosCommandResponse<T>> => {
      return this.patch<TJsonAtivosCommandResponse<T>, IRestAtivosCommand>({id: id, body: command, params: {command: commandName}});
    };
  },
  deletePromptFactory: async function (args: IEntityDeletePromptFactoryArgs<IJsonAtivos>): Promise<void | IApiRequestConfig> {
    const {model, injector, superDeletePrompt} = args;
    const modalService: CGModalService = injector.get<CGModalService>(CGModalService);
    if (model.origem === EAtivosOrigem.Contabilidade) {
      const ativosService: AtivosService = injector.get<AtivosService>(AtivosService);
      const response: HttpResponse<boolean> = await ativosService.checkHaveMoreAtivosFromContab(model.codAtivo);
      if (response.body) {
        return modalService.showOkCancel('global.text.warning', 'ativos.messages.existemmaisativoscontab');
      }
    } else if (model.origem === EAtivosOrigem.DivisaoAtivo) {
      return modalService.showOkCancel('global.text.warning', 'ativos.messages.existemmaisativosdivididos');
    }
    return superDeletePrompt(args);
  }
};
