<div class="contabilidade-digital-documentos-digitais-component entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel" [collapsed]="true" *ngIf="temContabilidadeDigital || !modoCGOn">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="search" plPromise>
        <pl-group>
          <pl-group>
            <label [translate]="'arquivodigital.gestaodocsdigitalizados.pesqavancada.nifDe'"></label>
            <edit>
              <entity-autocomplete
                entity="clifos"
                [model]="docsDigitalizadosHeader"
                (selectedKeyChange)="docsDigitalizadosHeader.nifDe = $event; docsDigitalizadosHeader.nifAte = $event"
                [fieldsMap]="{nContribuint: 'nifDe'}"
                output="nContribuint"
                outputKey="nContribuint">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="clifos"
                [model]="docsDigitalizadosHeader"
                [selectedKey]="docsDigitalizadosHeader.nifAte"
                (selectedKeyChange)="docsDigitalizadosHeader.nifAte = $event"
                [fieldsMap]="{nContribuint: 'nifAte'}"
                output="nContribuint"
                outputKey="nContribuint">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'arquivodigital.gestaodocsdigitalizados.pesqavancada.docExternoDe'"></label>
            <edit>
              <pl-edit-text attrName="docExternoDe" [(model)]="docsDigitalizadosHeader.docExternoDe"></pl-edit-text>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit-text attrName="docExternoAte" [(model)]="docsDigitalizadosHeader.docExternoAte"></pl-edit-text>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'arquivodigital.gestaodocsdigitalizados.pesqavancada.dataDocExternoDe'"></label>
            <edit>
              <pl-edit-datepicker attrName="dataDocExternoDe" [(model)]="docsDigitalizadosHeader.dataDocExternoDe"></pl-edit-datepicker>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit-datepicker attrName="dataDocExternoAte" [(model)]="docsDigitalizadosHeader.dataDocExternoAte"></pl-edit-datepicker>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'arquivodigital.gestaodocsdigitalizados.pesqavancada.dataRececaoDe'"></label>
            <edit>
              <pl-edit-datepicker attrName="dataRececaoDe" [(model)]="docsDigitalizadosHeader.dataRececaoDe"></pl-edit-datepicker>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit-datepicker attrName="dataRececaoAte" [(model)]="docsDigitalizadosHeader.dataRececaoAte"></pl-edit-datepicker>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group *ngIf="mode !== gestaoDocDigiMode.Conciliacao">
            <edit>
              <pl-edit-checkbox
                attrName="mostrarDocClassificados"
                [(model)]="docsDigitalizadosHeader.mostrarDocClassificados"
                [properties]="{disabled: docsDigitalizadosHeader.mostraApenasLancRobot}"
                label="arquivodigital.gestaodocsdigitalizados.pesqavancada.mostrarDocClassificados">
              </pl-edit-checkbox>
            </edit>
          </pl-group>

          <pl-group *ngIf="!clientConnectMode && modoLancarDocsAutoDisponivel && mode !== gestaoDocDigiMode.Conciliacao">
            <edit>
              <pl-edit-checkbox
                attrName="mostrarDocClassificados"
                [model]="docsDigitalizadosHeader.mostraApenasLancRobot"
                (modelChange)="docsDigitalizadosHeader.mostraApenasLancRobot = $event; docsDigitalizadosHeader.mostrarDocClassificados = false"
                label="arquivodigital.gestaodocsdigitalizados.pesqavancada.mostraApenasLancRobot">
              </pl-edit-checkbox>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group class="align-self-end">
          <edit>
            <pl-button klass="btn btn-sm btn-primary" type="submit" id="pesquisar"><i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <div class="docsdigitalizados-by-folder">
    <div class="row mb-3 mt-2 d-lg-block d-xl-none" *ngIf="!disabled">
      <div class="col-sm-12">
        <div class="btn-toolbar toolbar-actions" role="toolbar" aria-label="toolbar-actions" *ngIf="!disabled">
          <ng-container *ngTemplateOutlet="templateToolbarBtns"></ng-container>
        </div>
      </div>
    </div>

    <dx-data-grid
      class="cg-data-grid-toolbar-flex"
      [cgDxDataGrid]="dataGridDefinition"
      cgDxDataGridInstanceName="gridContabDigital"
      [dataSource]="docsDigitalizadosMain"
      (onInitialized)="onInitialized($event)"
      (onContentReady)="onContentReady()"
      (onSelectionChanged)="onSelectionChanged($event)"
      (onCellPrepared)="onCellPrepared($event)"
      (onEditorPreparing)="onEditorPreparing($event)"
      (onCellClick)="onCellClick($event)"
      (onRowExpanding)="onRowExpanding($event)"
      (onRowUpdated)="onDataGridRowUpdated($event)"
      (onContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
      (onFocusedCellChanged)="onFocusedCellChanged($event)"
      (onKeyDown)="onKeyDown($event)">
      <dxi-column
        dataField="folder"
        [allowEditing]="false"
        [showInColumnChooser]="false"
        [groupIndex]="0"
        [calculateGroupValue]="fnCalculateFolderGroupValue"
        groupCellTemplate="groupCellTemplateFolder">
      </dxi-column>

      <dxi-column cellTemplate="cellTemplateIcons" type="detailExpand" [minWidth]="60" [allowExporting]="false" [allowGrouping]="false" [showInColumnChooser]="false" [allowEditing]="false">
      </dxi-column>

      <dxi-column
        dataField="fileName"
        dataType="string"
        [allowEditing]="false"
        [allowGrouping]="false"
        [allowResizing]="true"
        [width]="200"
        [visible]="false"
        [caption]="'arquivodigital.gestaodocsdigitalizados.table.fields.ficheiro' | translate">
      </dxi-column>

      <dxi-column
        dataField="nif"
        dataType="string"
        editCellTemplate="editCellTemplateNif"
        [allowGrouping]="false"
        [allowEditing]="!clientConnectMode && selectedLine?.status !== gestaoDocsDigitalizadosStatus.AEsperaDeOCR"
        [caption]="'arquivodigital.gestaodocsdigitalizados.table.fields.nif' | translate"
        [hidingPriority]="!clientConnectMode ? 16 : 15">
      </dxi-column>

      <dxi-column
        dataField="entidade"
        dataType="string"
        width="200"
        [allowEditing]="false"
        [allowGrouping]="false"
        [caption]="'arquivodigital.gestaodocsdigitalizados.table.fields.entidade' | translate"
        [hidingPriority]="clientConnectMode ? 16 : 15">
      </dxi-column>

      <dxi-column
        dataField="temNIFEmpresa"
        dataType="boolean"
        [allowEditing]="!disabled && selectedLine && !selectedLine._classified && selectedLine?.status !== gestaoDocsDigitalizadosStatus.AEsperaDeOCR"
        [allowGrouping]="false"
        [width]="65"
        [caption]="'arquivodigital.gestaodocsdigitalizados.table.fields.temNIFEmpresamin' | translate"
        [hidingPriority]="14">
      </dxi-column>

      <dxi-column
        dataField="fiscalmenteRelevante"
        dataType="boolean"
        [allowEditing]="!disabled && selectedLine && !selectedLine._classified && selectedLine?.status !== gestaoDocsDigitalizadosStatus.AEsperaDeOCR"
        [allowGrouping]="false"
        [width]="65"
        [visible]="false"
        [showInColumnChooser]="mode !== gestaoDocDigiMode.Comercial"
        [caption]="'arquivodigital.gestaodocsdigitalizados.table.fields.fiscalmenteRelevantemin' | translate"
        [hidingPriority]="13">
      </dxi-column>

      <dxi-column
        dataField="invoiceTypeStr"
        dataType="string"
        editCellTemplate="editCellTemplateInvoiceType"
        [allowGrouping]="false"
        [allowEditing]="!clientConnectMode && selectedLine?.status !== gestaoDocsDigitalizadosStatus.AEsperaDeOCR"
        [width]="120"
        [caption]="'arquivodigital.gestaodocsdigitalizados.table.fields.invoiceTypeStrmin' | translate"
        [hidingPriority]="12">
      </dxi-column>

      <dxi-column
        dataField="dataDoc"
        dataType="date"
        [allowEditing]="!disabled && selectedLine && !selectedLine._classified && selectedLine?.status !== gestaoDocsDigitalizadosStatus.AEsperaDeOCR"
        [allowGrouping]="false"
        [caption]="'global.text.date' | translate"
        [hidingPriority]="11">
      </dxi-column>

      <dxi-column
        dataField="nDocumento"
        dataType="string"
        [allowEditing]="!disabled && selectedLine && !selectedLine._classified && selectedLine?.status !== gestaoDocsDigitalizadosStatus.AEsperaDeOCR"
        [allowGrouping]="false"
        [width]="120"
        [caption]="'arquivodigital.gestaodocsdigitalizados.table.fields.nDocumento' | translate"
        [hidingPriority]="10">
      </dxi-column>

      <dxi-column
        dataField="totalBase"
        dataType="number"
        [allowEditing]="!disabled && selectedLine && !selectedLine._classified && selectedLine?.status !== gestaoDocsDigitalizadosStatus.AEsperaDeOCR"
        [allowGrouping]="false"
        [visible]="false"
        [showInColumnChooser]="mode !== gestaoDocDigiMode.Conciliacao"
        [caption]="'arquivodigital.gestaodocsdigitalizados.table.fields.totalBase' | translate"
        format="#,##0.##00"
        alignment="right"
        [hidingPriority]="9">
      </dxi-column>

      <dxi-column
        dataField="totalIVA"
        dataType="number"
        [allowEditing]="!disabled && selectedLine && !selectedLine._classified && selectedLine?.status !== gestaoDocsDigitalizadosStatus.AEsperaDeOCR"
        [allowGrouping]="false"
        [visible]="false"
        [showInColumnChooser]="mode !== gestaoDocDigiMode.Conciliacao"
        [caption]="'arquivodigital.gestaodocsdigitalizados.table.fields.totalIVA' | translate"
        format="#,##0.##00"
        alignment="right"
        [hidingPriority]="8">
      </dxi-column>

      <dxi-column
        dataField="total"
        dataType="number"
        [allowEditing]="!disabled && selectedLine && !selectedLine._classified && selectedLine?.status !== gestaoDocsDigitalizadosStatus.AEsperaDeOCR"
        [allowGrouping]="false"
        [caption]="'global.text.total' | translate"
        [visible]="mode !== gestaoDocDigiMode.Conciliacao"
        [showInColumnChooser]="mode !== gestaoDocDigiMode.Conciliacao"
        format="#,##0.##00"
        alignment="right"
        [hidingPriority]="7">
      </dxi-column>

      <dxi-column
        dataField="preDefinidosID"
        dataType="number"
        cellTemplate="cellTemplatePreDefinido"
        editCellTemplate="editCellTemplatePreDefinido"
        [allowGrouping]="false"
        [allowEditing]="!clientConnectMode && selectedLine?.status !== gestaoDocsDigitalizadosStatus.AEsperaDeOCR"
        [showEditorAlways]="!clientConnectMode"
        [visible]="mode !== gestaoDocDigiMode.Conciliacao && mode !== gestaoDocDigiMode.Comercial"
        [showInColumnChooser]="mode !== gestaoDocDigiMode.Conciliacao && mode !== gestaoDocDigiMode.Comercial"
        [caption]="'arquivodigital.gestaodocsdigitalizados.table.fields.preDefenidosID' | translate"
        [hidingPriority]="6">
      </dxi-column>

      <dxi-column
        dataField="dataRececao"
        dataType="date"
        [allowEditing]="false"
        [allowGrouping]="false"
        [visible]="false"
        [caption]="'arquivodigital.gestaodocsdigitalizados.table.fields.dataRececao' | translate"
        [hidingPriority]="5">
      </dxi-column>

      <dxi-column
        dataField="classificadoPor"
        dataType="string"
        [allowEditing]="false"
        [allowGrouping]="false"
        [visible]="false"
        [caption]="'arquivodigital.gestaodocsdigitalizados.table.fields.classficadoPormin' | translate"
        [hidingPriority]="4">
      </dxi-column>

      <dxi-column
        dataField="nDocInterno"
        dataType="string"
        [allowEditing]="false"
        [allowGrouping]="false"
        [visible]="false"
        [showInColumnChooser]="mode !== gestaoDocDigiMode.Comercial"
        [caption]="'arquivodigital.gestaodocsdigitalizados.table.fields.ndocContabilidademin' | translate"
        [hidingPriority]="3">
      </dxi-column>

      <dxi-column
        dataField="nDocumentoSAFT"
        dataType="string"
        [allowEditing]="false"
        [allowGrouping]="false"
        [visible]="false"
        [showInColumnChooser]="mode !== gestaoDocDigiMode.Comercial"
        [caption]="'arquivodigital.gestaodocsdigitalizados.table.fields.ndocumentoSAFTmin' | translate"
        [hidingPriority]="2">
      </dxi-column>

      <div *dxTemplate="let cellInfo of 'templateToolbarActions'">
        <div class="btn-toolbar toolbar-actions d-none d-lg-none d-xl-block" role="toolbar" aria-label="toolbar-actions" *ngIf="!disabled">
          <ng-container *ngTemplateOutlet="templateToolbarBtns"></ng-container>
        </div>
      </div>

      <div *dxTemplate="let cellInfo of 'groupCellTemplateFolder'">
        <div>{{ cellInfo.value.split(dataGridSortDelimiter)[1] }}</div>
      </div>

      <div *dxTemplate="let cellInfo of 'cellTemplateIcons'">
        <div (click)="openDoc(cellInfo.data)" class="cursor-pointer d-inline-flex">
          <div class="dx-datagrid-group-closed" [plAnimationRotate]="cellInfo.data._detailExpanded" *ngIf="!clientConnectMode"></div>

          <i class="fa fa-exclamation text-warning" aria-hidden="true" *ngIf="cellInfo.data._changed" [title]="'arquivodigital.gestaodocsdigitalizados.table.havechanges' | translate"></i>

          <i class="fa fa-times-circle text-danger" aria-hidden="true" *ngIf="cellInfo.data.erro" [title]="cellInfo.data.erro"></i>

          <div class="img-efatura" *ngIf="!cellInfo.data._changed && cellInfo.data.validadoEFatura">
            <img
              class="img-fluid icon-efatura"
              src="../../assets/images/logo-efatura.svg"
              alt="icon-efatura"
              [title]="'arquivodigital.gestaodocsdigitalizados.messages.docvalidadoefatura' | translate" />
          </div>

          <i
            class="fa fa-fw fa-qrcode"
            aria-hidden="true"
            *ngIf="!cellInfo.data._changed && !cellInfo.data.validadoEFatura && cellInfo.data.temQRCode"
            [title]="'arquivodigital.gestaodocsdigitalizados.messages.docQR' | translate"></i>
        </div>
      </div>

      <div *dxTemplate="let cellInfo of 'editCellTemplateNif'">
        <entity-autocomplete
          entity="clifos"
          attrName="nif"
          *ngIf="!cellInfo.data._classified && !clientConnectMode"
          [selectedKey]="cellInfo.value"
          (selectedKeyChange)="nifChanged(cellInfo, $event)"
          [rowTemplate]="rowTemplateClifos"
          output="nContribuint"
          outputKey="nContribuint"
          [helperMode]="true"
          [inputClass]="['dx-texteditor-input', cellInfo.data._legendNif || '']"
          [properties]="propertiesInput">
        </entity-autocomplete>
        <div *ngIf="cellInfo.data._classified || clientConnectMode">{{ cellInfo.value }}</div>
      </div>

      <div *dxTemplate="let cellInfo of 'editCellTemplateInvoiceType'">
        <dx-select-box
          *ngIf="!cellInfo.data._classified"
          displayExpr="name"
          valueExpr="value"
          [dataSource]="invoiceTypeSource"
          [value]="cellInfo.data.invoiceType"
          (valueChange)="invoiceTypeChanged($event, cellInfo)"
          [class]="cellInfo.data._legendInvoiceType"
          [useItemTextAsTitle]="true"
          [dropDownOptions]="{width: 150}"
          [searchEnabled]="true"></dx-select-box>
        <div *ngIf="cellInfo.data._classified">{{ cellInfo.value }}</div>
      </div>

      <div *dxTemplate="let cellInfo of 'cellTemplatePreDefinido'">{{ cellInfo.data.preDefinidosID }} - {{ cellInfo.data.preDefinidoNome }}</div>

      <div *dxTemplate="let cellInfo of 'editCellTemplatePreDefinido'">
        <entity-autocomplete
          entity="predefinidoscontabilidade"
          attrName="preDefinidosID"
          *ngIf="!cellInfo.data._classified"
          [model]="cellInfo.data"
          (modelChange)="preDefinidosChanged(cellInfo, $event)"
          [fieldsMap]="{descricao: 'preDefinidoNome'}"
          [properties]="propertiesInputPreDefinido"
          [customActions]="customActionPreDefinido">
        </entity-autocomplete>
        <div *ngIf="cellInfo.data._classified && cellInfo.data?.preDefinidosID">{{ cellInfo.data.preDefinidosID }} - {{ cellInfo.data.preDefinidoNome }}</div>
      </div>

      <div *dxTemplate="let cellInfo of 'masterDetailTemplateDoc'">
        <div *ngIf="mode === gestaoDocDigiMode.Conciliacao">
          <div *ngIf="cellInfo.data.tipoDocsIdentContab !== tipoDocsIdentsContab.Nenhum">
            <pl-button
              klass="btn-sm btn-success me-1"
              *ngIf="cellInfo.data.tipoDocsIdentContab === tipoDocsIdentsContab.IdentificacaoDireta"
              [onClick]="fnAssociarDocs(cellInfo.data, cellInfo.data._docContabilidade)">
              <i class="fa fa-gg-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'arquivodigital.gestaodocsdigitalizados.btn.associar'"></span>
            </pl-button>

            <documento-contabilidade [(doc)]="cellInfo.data._docContabilidade" [showToolbar]="false"></documento-contabilidade>
          </div>

          <dx-data-grid
            *ngIf="cellInfo.data.tipoDocsIdentContab === tipoDocsIdentsContab.Nenhum"
            [cgDxDataGrid]="dataGridDefinitionDocsSemelhantes"
            [dataSource]="cellInfo.data._docsSemelhantes"
            (onCellClick)="onCellClickDocsSemelhantes($event)">
            <div *dxTemplate="let toolbarItem of 'toolbarTemplateDocsSemelhantes'">
              <span [translate]="'arquivodigital.gestaodocsdigitalizados.table.fields.docSemelhante.docspossiveis'"></span>
            </div>

            <div *dxTemplate="let itemDetail of 'detailTemplateSemelhanteDocContabilidade'">
              <pl-button klass="btn-sm btn-success me-1" [onClick]="fnAssociarDocs(cellInfo.data, itemDetail.data._docContabilidade)">
                <i class="fa fa-gg-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'arquivodigital.gestaodocsdigitalizados.btn.associar'"></span>
              </pl-button>

              <documento-contabilidade [(doc)]="itemDetail.data._docContabilidade" [showToolbar]="false"></documento-contabilidade>
            </div>
          </dx-data-grid>
        </div>

        <ng-container *ngIf="mode !== gestaoDocDigiMode.Conciliacao" [ngSwitch]="cellInfo.data._classified">
          <ng-container *ngSwitchCase="false">
            <div class="contabilidade-digital-documentos-digitais-grid-row-detail" *ngIf="cellInfo.data._docContabilidade">
              <pl-toolbar [instanceId]="cellInfo.data._docContabilidadeToolbarInstanceId"></pl-toolbar>
              <hr />
              <doccontabilidade
                [(model)]="cellInfo.data._docContabilidade"
                [toolbarInstanceName]="cellInfo.data._docContabilidadeToolbarInstanceId"
                [digitalDocOCRCabID]="cellInfo.data.docOCRCabID"
                [keepCabAfterClearPreDefinido]="true"
                plLifeCycle
                (evtInit)="docContabilidadeInit($event, cellInfo.data)"
                (evtDestroy)="docContabilidadeDestroy(cellInfo.data)">
              </doccontabilidade>
            </div>
            <!--            <div class="contabilidade-digital-documentos-digitais-grid-row-detail" *ngIf="cellInfo.data._docComercial">-->
            <div class="contabilidade-digital-documentos-digitais-grid-row-detail" *ngIf="mode === gestaoDocDigiMode.Comercial">
              <pl-toolbar [instanceId]="cellInfo.data._docComercialToolbarInstanceId"></pl-toolbar>
              <hr />
              <doccomercial
                [group]="group"
                [model]="cellInfo.data._docComercial"
                [type]="stateTypeDetail"
                [entityName]="'docscomerciais'"
                [toolbarInstanceName]="cellInfo.data._docComercialToolbarInstanceId"
                (evtModelChanged)="cellInfo.data._docComercial = $event">
              </doccomercial>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="true">
            <documento-contabilidade *ngIf="mode !== gestaoDocDigiMode.Comercial" [(doc)]="cellInfo.data._docContabilidade" [showToolbar]="true" [maintenanceMode]="true"></documento-contabilidade>

            <documento-faturacao *ngIf="mode === gestaoDocDigiMode.Comercial" [doc]="cellInfo.data._docComercial"></documento-faturacao>
          </ng-container>
        </ng-container>
      </div>
    </dx-data-grid>
  </div>

  <cg-table-legend *ngIf="!disabled" [legend]="arquivoDigitalLegend"></cg-table-legend>
</div>

<ng-template #templateToolbarBtns>
  <div class="btn-group" role="group">
    <dx-button [hint]="'arquivodigital.gestaodocsdigitalizados.btn.refresh' | translate" [disabled]="actionsDisabled" (click)="fnRefreshDocsDigitalizado()" icon="fa fa-fw fa-refresh action-refresh">
    </dx-button>

    <dx-button [hint]="'global.btn.save' | translate" [disabled]="actionsDisabled" (click)="fnSaveDocsDigitalizados()" icon="fa fa-fw fa-save action-save"></dx-button>

    <dx-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.adicionardoc' | translate"
      [disabled]="actionsDisabled"
      (click)="callAction(typeAction.AdicionarDoc)"
      icon="fa fa-fw pl-icon-adicionar-docs action-adicionar-doc">
    </dx-button>

    <dx-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.mudardocpasta' | translate"
      [disabled]="!selectedLines.length || actionsDisabled || selectedLine.associadoADocContab"
      (click)="callAction(typeAction.MudarDocPasta)"
      icon="fa fa-fw pl-icon-mover-pasta action-mover-pasta">
    </dx-button>

    <dx-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.robo' | translate"
      [disabled]="!selectedLines.length || actionsDisabled"
      [visible]="modoLancarDocsAutoDisponivel && mode !== gestaoDocDigiMode.Conciliacao"
      (click)="callAction(typeAction.Robo)"
      icon="fa-fw pl-icon-robot action-robo">
      <div *dxTemplate="let button of 'content'">
        <div [ngClass]="button.icon">
          <span class="path1"></span>
          <span class="path2"></span>
          <span class="path3"></span>
          <span class="path4"></span>
          <span class="path5"></span>
          <span class="path6"></span>
          <span class="path7"></span>
          <span class="path8"></span>
          <span class="path9"></span>
          <span class="path10"></span>
          <span class="path11"></span>
          <span class="path12"></span>
          <span class="path13"></span>
          <span class="path14"></span>
          <span class="path15"></span>
          <span class="path16"></span>
          <span class="path17"></span>
          <span class="path18"></span>
        </div>
      </div>
    </dx-button>

    <dx-drop-down-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.associardocsauto' | translate"
      [visible]="mode === gestaoDocDigiMode.Conciliacao"
      [items]="associarDocsAutoOptions"
      [dropDownOptions]="{width: 525}"
      keyExpr="typeAction"
      displayExpr="caption"
      (onItemClick)="callAction($event.itemData.typeAction)"
      icon="fa fa-fw fa-gg-circle action-ass-doc-auto">
    </dx-drop-down-button>

    <dx-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.reaplicarocr' | translate"
      [disabled]="!selectedLine || selectedLine.associadoADocContab || selectedLines.length > 1 || actionsDisabled"
      (click)="callAction(typeAction.ReaplicarOCR)"
      icon="fa fa-fw pl-icon-ocr-doc action-ocr-doc">
    </dx-button>

    <dx-button
      class="action-obter-dados-efatura"
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.obterDadosEFatura' | translate"
      [disabled]="!selectedLines.length || actionsDisabled"
      (click)="callAction(typeAction.ObterDadosEFatura)"
      icon="fa fa-fw fa-save action-save">
      <div *dxTemplate="let button of 'content'">
        <img class="img-fluid icon-efatura" src="../../assets/images/logo-efatura.svg" alt="icon-efatura" />
      </div>
    </dx-button>

    <dx-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.atribuirTipoDocumento' | translate"
      [disabled]="!selectedLines.length || actionsDisabled"
      (click)="callAction(typeAction.AtribuirTipoDocumento)"
      icon="fa fa-fw pl-icon-atribuir-tipo-doc action-atribuir-tipo-doc">
    </dx-button>

    <dx-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.unirDocs' | translate"
      [disabled]="selectedLines.length < 2 || actionsDisabled"
      (click)="callAction(typeAction.UnirDocs)"
      icon="fa fa-fw pl-icon-unir-docs action-unir-docs">
    </dx-button>

    <dx-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.separarDoc' | translate"
      [disabled]="!selectedLine || selectedLines.length > 1 || actionsDisabled"
      (click)="callAction(typeAction.SepararDoc)"
      icon="fa fa-fw pl-icon-separar-docs action-separar-docs">
    </dx-button>

    <dx-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.copiarDoc' | translate"
      [disabled]="!selectedLine || selectedLines.length > 1 || actionsDisabled"
      (click)="callAction(typeAction.CopiarDoc)"
      icon="fa fa-fw pl-icon-copiar-docs action-copiar-docs">
    </dx-button>

    <dx-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.removePages' | translate"
      [disabled]="!selectedLine || selectedLines.length > 1 || actionsDisabled"
      (click)="callAction(typeAction.RemovePages)"
      icon="fa fa-fw pl-icon-remover-doc1 action-remover-pagina-doc">
    </dx-button>

    <dx-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.apagar' | translate"
      [disabled]="!selectedLines.length || actionsDisabled"
      (click)="callAction(typeAction.Apagar)"
      [visible]="!selectedLine || !selectedLine._classified"
      icon="fa fa-fw pl-icon-remover-doc action-remover-doc">
    </dx-button>

    <dx-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.removeassociation' | translate"
      [disabled]="!selectedLines.length || actionsDisabled"
      (click)="callAction(typeAction.Apagar)"
      [visible]="selectedLine && !!selectedLine._classified"
      icon="fa fa-fw fa-chain-broken action-remover-associacao">
    </dx-button>

    <dx-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.verDoc' | translate"
      [disabled]="!selectedLine || !selectedLine.associadoADocContab || actionsDisabled"
      (click)="openDoc(selectedLine)"
      [visible]="mode !== gestaoDocDigiMode.Conciliacao"
      icon="fa fa-fw pl-icon-ver-doc-contabilidade action-ver-doc">
    </dx-button>

    <dx-button
      *ngIf="mode !== gestaoDocDigiMode.Comercial"
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.novaContaCC' | translate"
      [disabled]="!selectedLine || selectedLine.entidade !== '' || selectedLines.length > 1 || actionsDisabled || selectedLine.nif === ''"
      (click)="callAction(typeAction.NovaContaCC)"
      icon="fa fa-fw pl-icon-nova-conta-corrente action-nova-conta-corrente">
    </dx-button>

    <dx-button
      *ngIf="mode !== gestaoDocDigiMode.Comercial"
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.predefinidos' | translate"
      [disabled]="actionsDisabled"
      (click)="callAction(typeAction.PreDefinidos)"
      [visible]="mode !== gestaoDocDigiMode.Conciliacao"
      icon="fa fa-fw fa-cogs action-pre-definidos">
    </dx-button>

    <dx-button
      *ngIf="mode !== gestaoDocDigiMode.Comercial"
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.extratoConta' | translate"
      [disabled]="!selectedLine || selectedLines.length > 1 || actionsDisabled || !selectedLine.nContaEntidade"
      (click)="callAction(typeAction.ExtratoConta)"
      icon="fa fa-fw fa-file-text-o action-extrato-conta">
    </dx-button>

    <dx-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.docsContabSemContabDig' | translate"
      [disabled]="actionsDisabled"
      (click)="callAction(typeAction.DocsContabSemContabDig)"
      icon="fa fa-fw fa-files-o action-pre-docs-contab-sem-contab-dig">
    </dx-button>

    <dx-button
      [hint]="'arquivodigital.gestaodocsdigitalizados.actions.exportarArquivo' | translate"
      [disabled]="actionsDisabled"
      (click)="callAction(typeAction.ExportarArquivo)"
      icon="fa fa-fw fa-download action-download">
    </dx-button>
  </div>
</ng-template>
