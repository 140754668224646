<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'extratosDT.print.title'"></h5>
</div>

<div class="modal-body">
  <pl-form [submit]="loadPdf" plPromise>
    <pl-group class="mb-2">
      <pl-group>
        <label [translate]="'global.text.listing'"></label>
        <edit>
          <pl-edit [type]="reportType" [model]="reportModel" (modelChange)="onReportChange($event)" [properties]="{entity: {outputKey: 'name', outputDescription: 'title'}}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group class="align-self-end">
        <edit>
          <pl-button type="submit" klass="btn btn-sm btn-primary"><i class="fa fa-fw fa-search" aria-hidden="true"></i>&nbsp;<span [translate]="'global.btn.preview'"></span></pl-button>
        </edit>
      </pl-group>
    </pl-group>
  </pl-form>
  <hr />
  <ng-container>
    <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
  </ng-container>
</div>

<div class="modal-footer" plNavigation>
  <button type="button" class="btn btn-light" (click)="dismiss()"><i class="fa fa-fw fa-close"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
</div>
