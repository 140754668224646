<div [ngClass]="entityClassName">
  <entity-list
    [entityName]="entityName"
    [toolbarInstanceId]="instanceName"
    [maintenanceMode]="maintenanceMode"
    [dataGridState]="dataGridState"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [filterCollapsed]="false"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [properties]="listOptions"
    [onFiltered]="fnOnFiltered"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
    <div *entityListItemActions="let item">
      <pl-tooltip *ngIf="item.data.perfilSistema" [config]="{text: 'abdes.fields.perfilSistema'}">
        <i *plTooltipContent class="fa fa-fw fa-lock mr-1" aria-hidden="true"></i>
      </pl-tooltip>
    </div>
  </entity-list>
</div>
