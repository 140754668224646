import {ENTITY_NAME_PAGAMENTOS, IPagamentosEntity} from './pagamentos.entity.interface';
import {PagamentosEditComponent} from './components/edit/pagamentos.entity.edit.component';
import {PagamentosListComponent} from './components/list/pagamentos.entity.list.component';
import {ROLE} from '../../services/role.const';
import {RESOLVER_CG_STORE_URL_BACKOFFICE} from '../../../config/uirouter/uirouter.resolvers';
import {IJsonAnosFaturacao} from '../anosfaturacao/jsonAnosFaturacao.entity.interface';
import {PortalClientAccountService} from '../../services/portalclientaccount/portalclientaccount.service';

export const ENTITY_PAGAMENTOS: IPagamentosEntity = {
  name: ENTITY_NAME_PAGAMENTOS,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE, ROLE.FINANCEIRO],
  searchPlaceholder: 'pagamentos.pesquisa',
  icon: 'fa-chevron-circle-up',
  metadata: {
    keyName: 'extPocCabID',
    fields: [
      {name: 'numero', width: '160px', caption: 'pagamentos.fields.numero'},
      {name: 'nConta', width: '100px', caption: 'pagamentos.fields.nconta'},
      {name: 'nome', caption: 'pagamentos.fields.nome'},
      {name: 'data', width: '100px', type: 'date', caption: 'global.text.date'},
      {name: 'valor', width: '75px', align: 'right', type: 'double', caption: 'global.text.total'},
      {name: 'abreviaturaMoeda', align: 'center', caption: 'pagamentos.fields.abreviaturaMoeda'},
      {name: 'stampUpdate', type: 'datetime', caption: 'pagamentos.fields.stampUpdate'},
      {name: 'anulado', type: 'boolean', caption: 'pagamentos.fields.anulado', visible: false}
    ],
    order: 'stampUpdate desc'
  },
  actions: {
    new: true,
    detail: false,
    edit: false,
    delete: false,
    search: true,
    filter: true
  },
  list: {
    state: {
      component: PagamentosListComponent,
      resolve: [
        RESOLVER_CG_STORE_URL_BACKOFFICE,
        {
          provide: 'anosList',
          deps: [PortalClientAccountService],
          useFactory: (anosConatbilidade: PortalClientAccountService): Promise<Array<IJsonAnosFaturacao>> => {
            return anosConatbilidade.getAnosContabilidade().then((response) => {
              const anosList: Array<IJsonAnosFaturacao> = [];
              response.body.forEach((item) => {
                anosList.push({ano: item.ano});
              });
              return anosList;
            });
          }
        }
      ]
    }
  },
  detail: {
    state: {
      component: PagamentosEditComponent,
      params: {
        nConta: {
          type: 'string',
          value: ''
        },
        nome: {
          type: 'string',
          value: ''
        }
      }
    }
  },
  service: function () {
    this.sendEmail = (extpoccabID, nconta, email, ano) => {
      const params = ano === 0 ? {email: email, nconta: nconta} : {email: email, nconta: nconta, ano: ano};
      return this.get({id: `${extpoccabID}/email`, params: params});
    };
  }
};
