<div class="modal-header">
  <h4 class="modal-title" [translate]="'importadorSaftDirect.strings.modalTitleEdit'"></h4>
</div>

<div class="modal-body">
  <pl-form>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h6 class="card-title" [translate]="'importadorSaftDirect.strings.correspContasContaSaftLabel'"></h6>
          </div>
          <div class="card-body">
            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.customerID'"></label>
              <edit>
                <pl-edit type="text" [model]="model.customerID" [properties]="{disabled: true}"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.customerTaxID'"></label>
              <edit>
                <pl-edit type="text" [model]="model.customerTaxID" [properties]="{disabled: true}"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.name'"></label>
              <edit>
                <pl-edit type="text" [model]="model.name" [properties]="{disabled: true}"></pl-edit>
              </edit>
            </pl-group>
            <div class="row">
              <div class="col-md-6">
                <pl-group>
                  <label [translate]="'importadorSaftDirect.fields.country'"></label>
                  <edit>
                    <pl-edit type="text" [model]="model.country" [properties]="{disabled: true}"></pl-edit>
                  </edit>
                </pl-group>
              </div>
              <div class="col-md-6">
                <pl-group>
                  <label [translate]="'importadorSaftDirect.fields.mercado'"></label>
                  <edit>
                    <pl-edit-select
                      [model]="model"
                      [source]="mercadoSource"
                      (modelChange)="changedMercado($event)"
                      [properties]="{disallowClear: true, select: {labelProp: 'mercadoStr', valueProp: 'mercado'}}"></pl-edit-select>
                  </edit>
                </pl-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-header">
            <h6 class="card-title" [translate]="'importadorSaftDirect.strings.correspContasContaCGLabel'"></h6>
          </div>
          <div class="card-body">
            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.contaDestino'"></label>
              <edit>
                <entity-autocomplete
                  entity="clifos"
                  [model]="model"
                  [fieldsMap]="{nConta: 'contaDestino', nome: 'nomeContaCG'}"
                  (modelChange)="changedContaDestino($event)"
                  [properties]="{allowInvalid: false}"
                  output="nConta"
                  outputDescription="nome"></entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.codRetencao'"></label>
              <edit>
                <entity-autocomplete entity="taxasretencao" [(selectedKey)]="model.codRetencao" output="key"></entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group *ngIf="showCCusto">
              <label [translate]="'importadorSaftDirect.fields.nCCusto'"></label>
              <edit>
                <entity-autocomplete entity="ccustos" [(selectedKey)]="model.nCCusto" output="key" [filter]="'encerrado=0'" [properties]="{allowInvalid: false}"></entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group *ngIf="showRepCC">
              <label [translate]="'importadorSaftDirect.fields.nCodRepCC'"></label>
              <edit>
                <entity-autocomplete entity="reparticoesccustosfaturacao" [(selectedKey)]="model.nCodRepCC" output="key" [properties]="{allowInvalid: false}"></entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'importadorSaftDirect.fields.nomeContaCG'"></label>
              <edit>
                <pl-edit type="text" [model]="model.nomeContaCG" [properties]="{disabled: true}"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <edit>
                <pl-edit type="boolean" [(model)]="model.jaExiste" [properties]="{disabled: true, label: 'importadorSaftDirect.fields.jaExiste'}"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>
      </div>
    </div>
  </pl-form>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-primary btn-sm action-close" [onClick]="close" plAutoFocus>
    <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalSave'"></span>
  </pl-button>

  <pl-button klass="btn-sm btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalCancel'"></span></pl-button>
</div>
