<div class="gestao-comunicacoes-topico-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'gestaoComunicacoes.modal.topico.newMessage'"></h4>
  </div>

  <div class="modal-body" [plPromise]="promiseLoading">
    <pl-form [(ngForm)]="form" [properties]="{validators: {required: {value: true}}}">
      <pl-group>
        <label [translate]="'gestaoComunicacoes.modal.topico.assunto'"></label>
        <edit>
          <pl-edit-text attrName="assunto" [model]="model.assunto" (modelChange)="model.assunto = $event; evaluateAssunto()" plAutoFocus></pl-edit-text>
        </edit>
      </pl-group>

      <pl-group *ngIf="datagridView">
        <label [translate]="'gestaoComunicacoes.modal.topico.estado'"></label>
        <edit>
          <pl-autocomplete
            attrName="estado"
            [model]="model.estadoNome"
            (modelChange)="changedEstado($event)"
            [source]="estadosSource"
            [rowTemplate]="'nome'"
            [output]="'nome'"
            [allowInvalid]="false"
            [allowEmpty]="false">
          </pl-autocomplete>
        </edit>
      </pl-group>

      <pl-group *ngIf="contabilistaView">
        <label [translate]="'gestaoComunicacoes.modal.topico.categoria'"></label>
        <edit>
          <entity-autocomplete
            entity="comcategorias"
            [model]="model"
            (selectedKeyChange)="model.comCategoriaId = $event"
            (evtSelectedDescriptionChanged)="model.categoriaNome = $event"
            [fieldsMap]="{comCategoriaId: 'comCategoriaId', nome: 'categoriaNome'}"
            [filter]="searchCategoriasParam"
            [selectOnFocus]="false"
            [properties]="{allowInvalid: false, maintenanceProperties: {inheritFilter: true}}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'gestaoComunicacoes.modal.topico.user'"></label>
        <edit>
          <pl-autocomplete
            attrName="utilizador"
            [model]="utilizador.nome"
            (modelChange)="changedUtilizador($event)"
            [source]="utilizadoresSource"
            [rowTemplate]="utilizadorTemplate"
            [output]="'nome'"
            [allowInvalid]="false"
            [allowEmpty]="false"
            (keydown)="onKeyDown()">
          </pl-autocomplete>
        </edit>
      </pl-group>

      <cg-gestao-comunicacoes-message-input
        #inputMessage
        [showAttachFile]="true"
        [showAttachFileMessage]="true"
        [marginTop]="true"
        (evtSubmitFile)="evtSubmitFile($event)"
        (evtSubmitMessage)="evtSubmitMessage($event)"
        (keydown)="evtKeyDownEnter($event)"></cg-gestao-comunicacoes-message-input>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button
      #btnSend="cgcButton"
      klass="btn-primary btn-sm action-submit"
      (evtClicked)="close()"
      [disabled]="closeDisabled || form?.invalid || !assuntoValid"
      (keydown)="evtKeyDownEnterBtnSend($event)"
      [promise]="promise"
      plAutoFocus
      ><i class="fa fa-fw fa-paper-plane"></i>&nbsp;<span [translate]="'global.btn.send'"></span>
    </pl-button>
    <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
