import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {TranslateService} from '@ngx-translate/core';
import {PlAlertService} from 'pl-comps-angular';
import {AtivosService} from '../../services/ativos.service';
import {HttpResponse} from '@angular/common/http';
import {IJsonAtivos} from '../../interfaces/jsonAtivos.entity.interface';

@Component({
  selector: 'modal-ativos-grande-reparacao',
  templateUrl: './ativos.grandeReparacao.modal.component.html'
})
export class AtivosGrandeReparacaoModalComponent extends CGModalComponent<IJsonAtivos> implements OnInit {
  @Input() public codAtivo: number;
  @Input() public codAtivoPai: number;
  @Input() public nomeAtivoPai: string;

  public title: string;
  public associa: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _ativosService: AtivosService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this.associa = !this.codAtivoPai || this.codAtivoPai === 0;
    this.title = this.associa ? this._translateService.instant('ativos.modal.grMarcar') : this._translateService.instant('ativos.modal.grDesmarcar');
  }

  public close(): Promise<void> {
    if (this.associa && !this.codAtivoPai) {
      this._plAlertService.error('ativos.messages.grcodativoempty');
      return Promise.resolve();
    }

    return this._ativosService.ativosGrandesReparacoes(this.codAtivo, this.codAtivoPai, this.associa).then((response: HttpResponse<IJsonAtivos>) => {
      this._plAlertService.success('ativos.messages.realizadacomsucesso');
      super.close(response.body);
    });
  }
}
