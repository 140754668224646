import moment from 'moment';
import {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {IPlToolbarItemTemplateContext, IPlToolbarMenuItem, isEmpty, KEYCODES, PlAlertService} from 'pl-comps-angular';
import {AuthService} from '../../../../services/auth/auth.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ConfigOptionsService} from '../../../../services/config/options/config.options.service';
import {DevolucoesModalComponent} from '../../modals/devolucoes/devolucoes.modal.component';
import {DocumentoRastreabilidadeModalComponent} from '../../modals/rastreabilidade/documento.rastreabilidade.modal.component';
import {DocumentoRastreabilidadeService} from '../../modals/rastreabilidade/documento.rastreabilidade.service';
import {DocumentosService} from '../../service/documentos.entity.service';
import {EConfigOptionsInstanceName, IDocsComerciaisListConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {
  EDocsComerciaisClifoStatus,
  EDocsComerciaisListTab,
  EDocsComerciaisTableLegendColors,
  ENTITY_NAME_DOCS_COMERCIAIS,
  ENTITY_NAME_DOCS_COMERCIAIS_VENDAS,
  IDocComercial,
  IDocComercialCabWithData,
  IDocComercialDocCabFieldsVisibilityOptionsListView,
  IDocComercialEditStateParams,
  IDocComercialLinha,
  IDocsComerciaisCabEntityService,
  IDocsComerciaisEntityService,
  IDocsComerciaisGroup,
  IDocsComerciaisListEvaluateConfigOptionInvisibleParams,
  TABLE_LEGEND_DOCS_COMERCIAIS
} from '../../docsComerciais.entity.interface';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {EFACCBHASHEstado, IJsonDocComercial, IJsonDocComercialCab, IJsonDocContabImputacao} from '../../jsonDocComercial.entity.interface';
import {EFaturaCirculacaoLoginModalComponent} from '../../../../modules/efaturacirculacaologin/eFaturaCirculacaoLogin.modal.component';
import {EFaturaCirculacaoLoginService} from '../../../../services/efaturacirculacaologin/eFaturaCirculacaoLogin.service';
import {EFaturaConfigModalComponent} from '../../../../modules/efatura/modals/configuracao/eFaturaConfig.modal.component';
import {EGroupName} from '../../../../../config/constants';
import {EGrupoDoc, EGrupoDocType, grupoDocToDocType} from '../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {ENTITY_NAME_PAGAMENTOS} from '../../../pagamentos/pagamentos.entity.interface';
import {ENTITY_NAME_RECIBOS} from '../../../recibos/recibos.entity.interface';
import {EntityListComponent} from '../../../../components/entity/list/entity.list.component';
import {GestaoDocsComerciaisModalComponent} from '../../modals/gestaodocscomerciais/gestaoDocsComerciais.modal.component';
import {IDevExpressDataGridEventOnCellPrepared, IDevExpressDataGridEventOnRowPrepared} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../docfas/docFas.entity.interface';
import {IEntityServiceQueryRequestConfig} from '../../../../services/entity/entity.service.interface';
import {IJsonDocfa, IJsonDocfaReportList} from '../../../docfas/jsonDocFa.entity.interface';
import {IJsonEFaturaCirculacaoLogin} from '../../../../services/efaturacirculacaologin/eFaturaCirculacaoLogin.service.interface';
import {IJsonRastreabilidadeFaturacao} from '../../modals/rastreabilidade/jsonRastreabilidade.interface';
import {IJsonRecibo, IJsonReciboCab} from '../../../recibos/jsonRecibo.entity.interface';
import {IKeyBindingsHelperAction} from '../../../../components/keybindings/helper/keybindings.helper.component.interface';
import {IModuleEntityListOnDataGridCellClick} from '../../../../components/module/entitylist/module.entitylist.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {ROLE} from '../../../../services/role.const';
import {TEntityListBeforeRequestFn} from '../../../../components/entity/list/entity.list.component.interface';
import {TrdocsCabsModalComponent} from '../../../../components/docscomerciais/trDocs/modals/cabs/trdocs.cabs.modal.component';
import {TTableLegend} from '../../../../components/tablelegend/tablelegend.component.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {MODULE_NAME_DOCS_COMERCIAIS_CONFIG_NOME_FILE_EXPORT} from '../../../../modules/docscomerciaisconfignomefileexport/docsComerciaisConfigNomeFileExport.module.interface';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DocumentoImputacoesDocContabModalComponent} from '../../modals/imputacoesDocContab/documento.imputacoesDocContab.modal.component';
import {docsComerciaisGrupoDocToEntity} from '../../docsComerciais.entity';
import {MODULE_NAME_FATURACAO_PUBLICA} from '../../../../modules/faturacaopublica/faturacaoPublica.module.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../../../modules/portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EFaturaService} from '../../../../modules/efatura/eFatura.module.service';
import {IJsonEFaturaConfiguracao} from '../../../../modules/efatura/jsonEFatura.module.interface';

const WS_ERROR_RETURN_CODE = -80;

@Component({
  selector: 'docscomerciais-list',
  templateUrl: './docsComerciais.entity.list.component.html'
})
export class DocsComerciaisListComponent extends ModuloEntityListComponent<IJsonDocComercialCab, IDocsComerciaisCabEntityService> implements OnInit, OnDestroy {
  @Input() public group: IDocsComerciaisGroup;

  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly tabs: typeof EDocsComerciaisListTab;
  public readonly groupDoc: typeof EGrupoDoc;
  public readonly roleComercialAdvanced: ROLE;
  public readonly keyboardShortcuts: Array<IKeyBindingsHelperAction>;
  public readonly docsComerciaisListTab: typeof EDocsComerciaisListTab;

  public tabEditaveisVisible: boolean;
  public toolbarInstanceIdDocumentos: string;
  public toolbarInstanceIdDocumentosEditaveis: string;
  public toolbarInstanceIdPorProcessar: string;
  public toolbarInstanceIdProcessados: string;
  public activeTabId: EDocsComerciaisListTab;
  public showGDocs: boolean;
  public tableLegend: TTableLegend;
  public hasComercialAdvanced: boolean;
  public hasComercial: boolean;
  public hasContabilidade: boolean;
  public docCabVisibilityOptions: IDocComercialDocCabFieldsVisibilityOptionsListView;
  public configOptionsToolbarInstanceId: string;

  @ViewChild('entityListDocumentos') private readonly _entityListDocumentos: EntityListComponent;
  @ViewChild('entityListDocumentosEditaveis') private readonly _entityListDocumentosEditaveis: EntityListComponent;
  private readonly _docfas: IDocFasEntityService;
  private readonly _btnLoginWebServiceAT: IPlToolbarMenuItem;
  private readonly _btnTransformacaoDocumentos: IPlToolbarMenuItem;
  private readonly _btnConfigNameFileExport: IPlToolbarMenuItem;
  private readonly _btnFaturacaoPublica: IPlToolbarMenuItem;
  private readonly _maintenanceInstanceDocContab: IEntityMaintenanceInstance;
  private readonly _docsComerciaisService: IDocsComerciaisEntityService;
  private _subscriptionConfigOptions: Subscription;
  private _templateKeyboardShortcuts: TemplateRef<IPlToolbarItemTemplateContext>;
  private _reportDefaultPdf: string;
  private _nViasPdf: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _documentosService: DocumentosService,
    private readonly _documentoRastreabilidadeService: DocumentoRastreabilidadeService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _renderer: Renderer2,
    private readonly _authService: AuthService,
    private readonly _configOptionsService: ConfigOptionsService,
    private readonly _eFaturaService: EFaturaService,
    private readonly _eFaturaCirculacaoLoginService: EFaturaCirculacaoLoginService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService
  ) {
    super(_injector);

    this.configOptionsInstanceName = EConfigOptionsInstanceName.DOCS_COMERCIAIS_LIST;
    this.configOptionsGroupName = EGroupName.ERP;
    this.configOptionsInstanceName = EConfigOptionsInstanceName.DOCS_COMERCIAIS_LIST;
    this.roleComercialAdvanced = ROLE.ERPADVANCED;
    this.tabs = EDocsComerciaisListTab;
    this.groupDoc = EGrupoDoc;
    this.docsComerciaisListTab = EDocsComerciaisListTab;
    this.showGDocs = this._configService.configurations.empresa.temContabilidadeDigital || this._configService.configurations.acessos.erpcloud.gdocs;

    this._maintenanceInstanceDocContab = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._docsComerciaisService = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>(ENTITY_NAME_DOCS_COMERCIAIS);
    this._docfas = this._entityServiceBuilder.build<IJsonDocfa, IDocFasEntityService>(ENTITY_NAME_DOC_FAS);

    this._btnLoginWebServiceAT = {
      caption: 'guias.toolbar.btnConfigAt',
      iconLeft: '<i class="fa fa-fw fa-key" aria-hidden="true"></i>',
      click: () => this._configLoginAt()
    };

    this._btnTransformacaoDocumentos = {
      caption: 'docscomerciais.toolbar.transformacaoDocumentos',
      iconLeft: '<i class="fa fa-fw fa-stack-overflow" aria-hidden="true"></i>',
      click: () => this._showTrDocsWizard()
    };

    this._btnConfigNameFileExport = {
      caption: 'docscomerciais.toolbar.configNameFileExport',
      iconLeft: '<i class="fa fa-fw fa-cog" aria-hidden="true"></i>',
      click: () => this._showNameFileExport(),
      visible: false
    };

    this._btnFaturacaoPublica = {
      caption: 'docscomerciais.toolbar.faturacaoPublica',
      iconLeft: '<i class="fa fa-share-square fa-fw" aria-hidden="true"></i>',
      click: () => this._showFaturacaoPublica()
    };

    this.keyboardShortcuts = [
      {description: 'encomendas.keyboardShortcuts.processarTotalmente', key: KEYCODES.F9},
      {description: 'encomendas.keyboardShortcuts.processarLinhaSemFaturar', key: KEYCODES.F7}
    ];

    this.docCabVisibilityOptions = {
      showNRefProcesso: false,
      showDataVenc: false,
      showMoeda: false,
      showNRequisicao: false,
      showDataDocExterno: false,
      showDocExterno: false,
      showObservacoes: false,
      showCCusto: false,
      showNArmazem: false,
      showValorCIva: false,
      showLiquido: false,
      showCodUnidMov: false,
      showNVendedor: false,
      showD2: false,
      showD3: false,
      showDescEmValor: false,
      showPrestacoes: false
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.configOptionsToolbarInstanceId = this.instanceName;
    this._authService.hasAuthority(ROLE.CONTABILIDADE).then((hasAuthority: boolean) => {
      this.hasContabilidade = hasAuthority;
    });

    this._authService.hasAuthority(ROLE.ERP).then((hasAuthority: boolean) => {
      this.hasComercial = hasAuthority;
    });
    if (this.group.grupo === EGrupoDoc.GuiasTransporteRemessa || this.group.grupo === EGrupoDoc.GuiasTransporteFornecedores) {
      this.btnConfig.visible = true;
      this.btnConfig.type = 'dropdown';
      this.btnConfig.menu = [this._btnLoginWebServiceAT];
    }

    this.tabEditaveisVisible =
      this.group.grupo === EGrupoDoc.VendasEfectivas ||
      this.group.grupo === EGrupoDoc.ComprasEfectivas ||
      this.group.grupo === EGrupoDoc.GuiasTransporteRemessa ||
      this.group.grupo === EGrupoDoc.GuiasTransporteFornecedores;

    this.tableLegend = TABLE_LEGEND_DOCS_COMERCIAIS;
    this._authService.hasAuthority(ROLE.ERPADVANCED).then((hasAuthority: boolean) => {
      this.hasComercialAdvanced = hasAuthority;
      this._canShowAccoes().then((showAccoes) => {
        if (showAccoes) {
          this.dropdownActions.visible = true;
          this.dropdownActions.menu.push(this._btnTransformacaoDocumentos, this._btnConfigNameFileExport);
          if (this.group.grupo === EGrupoDoc.VendasEfectivas) {
            this.dropdownActions.menu.push(this._btnFaturacaoPublica);
          }
        }
      });
    });
    this._subscriptionConfigOptions = this._configOptionsService
      .getGroupOptions(this.configOptionsGroupName)
      .get(this.configOptionsInstanceName)
      .options()
      .subscribe((configOptions: TConfigOptions<boolean, IDocsComerciaisListConfigOptions>) => {
        this.docCabVisibilityOptions.showObservacoes = configOptions.get('showObservacoes').value;
        this.docCabVisibilityOptions.showDocExterno = configOptions.get('showDocExterno').value;
        this.docCabVisibilityOptions.showDataDocExterno = configOptions.get('showDataDocExterno').value;
        this.docCabVisibilityOptions.showDataVenc = configOptions.get('showDataVenc').value;
        this.docCabVisibilityOptions.showMoeda = configOptions.get('showMoeda').value;
        this.docCabVisibilityOptions.showNRefProcesso = configOptions.get('showNRefProcesso').value;
        this.docCabVisibilityOptions.showNRequisicao = configOptions.get('showNRequisicao').value;
        this.docCabVisibilityOptions.showCCusto = configOptions.get('showCCusto').value;
        this.docCabVisibilityOptions.showNArmazem = configOptions.get('showNArmazem').value;
        this.docCabVisibilityOptions.showNVendedor = configOptions.get('showNVendedor').value;
        this.docCabVisibilityOptions.showValorCIva = configOptions.get('showValorCIva').value;
        this.docCabVisibilityOptions.showCodUnidMov = configOptions.get('showCodUnidMov').value;
        this.docCabVisibilityOptions.showNRequisicao = configOptions.get('showNRequisicao').value;
        this.docCabVisibilityOptions.showLiquido = configOptions.get('showLiquido').value;
        this.docCabVisibilityOptions.showD2 = configOptions.get('showD2').value;
        this.docCabVisibilityOptions.showD3 = configOptions.get('showD3').value;
        this.docCabVisibilityOptions.showDescEmValor = configOptions.get('showDescEmValor').value;
        this.docCabVisibilityOptions.showPrestacoes = configOptions.get('showPrestacoes').value;
      });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._subscriptionConfigOptions) {
      this._subscriptionConfigOptions.unsubscribe();
    }
  }

  public setInstanceName(value: string): void {
    this.toolbarInstanceIdDocumentos = `${value}-${EDocsComerciaisListTab.Documentos}`;
    this.toolbarInstanceIdDocumentosEditaveis = `${value}-${EDocsComerciaisListTab.Editaveis}`;
    this.toolbarInstanceIdPorProcessar = `${value}-${EDocsComerciaisListTab.PorProcessar}`;
    this.toolbarInstanceIdProcessados = `${value}-${EDocsComerciaisListTab.Processado}`;
    super.setInstanceName(this.toolbarInstanceIdDocumentos);
  }

  public refreshList(): Promise<void> {
    if (this._entityListDocumentos) {
      return this._entityListDocumentos.refresh();
    }
    if (this._entityListDocumentosEditaveis) {
      return this._entityListDocumentosEditaveis.refresh();
    }
    return Promise.resolve();
  }

  public async onDetail(event: IModuleEntityListOnDataGridCellClick<IDocComercialCabWithData>): Promise<void> {
    const {data} = event;
    data._doc = await this.service.get<IJsonDocComercial>({id: data.faccbId}).then((response: HttpResponse<IJsonDocComercial>) => response.body);
    if (isEmpty(this._reportDefaultPdf)) {
      await this._docfas.reports(data._doc.cab.nDocFa).then((responseDocFa: HttpResponse<IJsonDocfaReportList>) => {
        this._nViasPdf = responseDocFa.body.nviasDefault;
        this._reportDefaultPdf = responseDocFa.body.reportDefault;
      });
    }
    data._fileNamePDF = `${data._doc.cab.nDoc}_${moment(data._doc.cab.dataDoc).format('YYYYMMDD')}.pdf`;
    if (isEmpty(data._urlPDF) && isEmpty(data._doc.cab.ocrCabDocId)) {
      this._documentosService.getPdfUrl(data._doc.cab.faccbId, this._nViasPdf, this._reportDefaultPdf, false, false, true, false, false).subscribe((pdfUrl: string) => {
        data._urlPDF = pdfUrl;
      });
    }
    if (!isEmpty(data._doc.cab.ocrCabDocId)) {
      data._digitalGDocViewerDoc = {docID: data._doc.cab.ocrCabDocId, folderID: data._doc.cab.ocrCabFolderID, dataDoc: data._doc.cab.dataDoc};
    }
    return super.onDetail(event);
  }

  public changedTab(id: string): void {
    this.activeTabId = <EDocsComerciaisListTab>id;
    const toolbarInstanceId: string =
      this.activeTabId === EDocsComerciaisListTab.Documentos
        ? this.toolbarInstanceIdDocumentos
        : this.activeTabId === EDocsComerciaisListTab.PorProcessar
          ? this.toolbarInstanceIdPorProcessar
          : this.activeTabId === EDocsComerciaisListTab.Processado
            ? this.toolbarInstanceIdProcessados
            : this.toolbarInstanceIdDocumentosEditaveis;
    this.setToolbarInstanceId(toolbarInstanceId);
    this.setPortalToolbarInstanceId(toolbarInstanceId);

    if (toolbarInstanceId === this.toolbarInstanceIdDocumentosEditaveis) {
      if (this.tabEditaveisVisible) {
        this.toolbar.addButton({
          id: 'btn-gestao-docs-editaveis-toolbar',
          type: 'button',
          class: 'btn-info',
          iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
          caption: 'docscomerciais.btn.gestaoRascunhos',
          order: 131,
          click: () => this._gestaoAvencas()
        });
      }
    }
    const btnNovo = this.toolbar.find('novo');
    if (btnNovo) {
      btnNovo.visible = toolbarInstanceId === this.toolbarInstanceIdDocumentos || toolbarInstanceId === this.toolbarInstanceIdDocumentosEditaveis;
    }
    this.configOptionsToolbarInstanceId = toolbarInstanceId;
  }

  public onDataGridCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonDocComercial>): void {
    if (event.rowType === 'data' && event.column.dataField === 'estado') {
      if (event.data.cab.estado === 'Pago') {
        event.cellElement.classList.add('pago');
      }
      if (event.data.cab.estado === 'Em aberto') {
        event.cellElement.classList.add('em-aberto');
      }
    }
  }

  public onDataGridRowPrepared({data, rowElement, rowType}: IDevExpressDataGridEventOnRowPrepared<IJsonDocComercialCab>): void {
    if (rowType === 'data') {
      let cssClass: string;
      if (data.anulado) {
        cssClass = EDocsComerciaisTableLegendColors.DocumentoComercialAnulado;
      }

      if (cssClass) {
        this._renderer.addClass(rowElement, cssClass);
      } else if (rowElement.style.backgroundColor) {
        this._renderer.removeClass(rowElement, cssClass);
      }
    }
  }

  public getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc, doc.cab.nDocumento === 0 || (doc.cab.hashEstado <= EFACCBHASHEstado.DocSemAssinatura && !doc.cab.terminado));
  }

  public getPdfActions(doc: IDocComercialCabWithData): Promise<void> {
    if (doc._doc) {
      return this._documentosService.getPdf(doc._doc, doc._doc.cab.nDocumento === 0 || !doc._doc.cab.terminado);
    }
    return this.getDoc(doc.faccbId).then((result: IJsonDocComercial) => {
      this.getPdf(result);
    });
  }

  public getDoc(id: number): Promise<IJsonDocComercial> {
    return this._documentosService.getDoc(id).then((response: HttpResponse<IJsonDocComercial>) => response.body);
  }

  public anexarGDocComercial(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.anexarGDocComercial(doc);
  }

  public comunicarDocWebServiceAT(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._eFaturaService.getConfiguracaoEFatura().then(({body}: HttpResponse<IJsonEFaturaConfiguracao>) => {
      if (body.atPassword && body.atPassword) {
        return this._comunicarDocWebServiceAT(doc.cab.faccbId);
      }
      return this._callEFaturacaoLogin(doc);
    });
  }

  public comunicarDocWebServiceATCab(doc: IJsonDocComercialCab): Promise<IJsonDocComercial> {
    return this._eFaturaService.getConfiguracaoEFatura().then(({body}: HttpResponse<IJsonEFaturaConfiguracao>) => {
      if (body.atPassword && body.atPassword) {
        return this._comunicarDocWebServiceAT(doc.faccbId);
      }
      return this._callEFaturacaoLoginCab(doc);
    });
  }

  public comunicarDocCirculacaoAT(doc: IJsonDocComercial, callEFaturacaoCirculacaoLogin: boolean): Promise<IJsonDocComercial> {
    if (!callEFaturacaoCirculacaoLogin) {
      return this._comunicarDocCirculacaoAT(doc);
    }
    return this._eFaturaCirculacaoLoginService.get().then(({body}: HttpResponse<IJsonEFaturaCirculacaoLogin>) => {
      if (body.username && body.password) {
        return this._comunicarDocCirculacaoAT(doc);
      }
      return this._callEFaturacaoCirculacaoLogin(doc);
    });
  }

  public inserirCodigoTransporteAT(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.inserirCodigoTransporteAT(doc);
  }

  public async anularDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    if (doc.cab.nDocumento === 0) {
      await this._cgModalService.showOkCancel('docscomerciais.auxiliares.anularDocumento', 'gestaoDocsComerciais.messages.anularAviso', {
        size: 'md',
        icon: 'warning',
        btnOkIcon: 'fa fa-fw fa-check-circle-o',
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no'
      });
    }
    return this._documentosService.anularDocumento(doc).then((result) => {
      this.refreshList();
      return result;
    });
  }

  public notaCreditoDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.notaCreditoDocumento(doc).then((response) => {
      this.refreshList();
      return response;
    });
  }

  public transformarLinhasDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.transformarLinhasDocumento(doc).then((response) => {
      this.refreshList();
      return response;
    });
  }

  public desmarcarDocTransformado(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.desmarcarDocTransformado(doc).then((response) => {
      this.refreshList();
      return response;
    });
  }

  public duplicarDocumentoEditavel(doc: IJsonDocComercial): Promise<void> {
    const docComercial: IDocComercial = {
      cab: doc.cab,
      clifo: doc.clifo,
      moradaCarga: doc.moradaCarga,
      moradaDescarga: doc.moradaDescarga,
      transportador: doc.transportador,
      meiosPagamento: doc.meiosPagamento,
      linhas: []
    };

    docComercial.cab.nDocumento = 0;
    docComercial.cab.nDocumentoDraft = 0;
    docComercial.cab.nFactFornec = '';
    docComercial.cab.atDocCodeID = '';
    docComercial.cab.comunicadoAoEFatura = false;
    docComercial.cab.dataDoc = moment();
    docComercial.cab.dataDocExterno = moment(doc.cab.dataDoc).isSameOrAfter(doc.cab.dataDocExterno, 'day')
      ? moment()
      : moment(docComercial.cab.dataDoc).add(moment(doc.cab.dataDocExterno).diff(doc.cab.dataDoc, 'day'), 'days');
    docComercial.cab.dataVenc = moment(doc.cab.dataDoc).isSameOrAfter(doc.cab.dataVenc, 'day')
      ? moment()
      : moment(docComercial.cab.dataDoc).add(moment(doc.cab.dataVenc).diff(doc.cab.dataDoc, 'day'), 'days');
    docComercial.clifo.action = EDocsComerciaisClifoStatus.ExisteNaoActualizaNaoValidaNif;

    if (doc.cab.anulado && doc.cab.observacoes !== '') {
      const idx = doc.cab.observacoes.indexOf('!', 0);
      if (idx > 0) {
        doc.cab.observacoes = doc.cab.observacoes.replace(doc.cab.observacoes.substring(0, idx + 1), '').trim();
      }
    }

    for (const linha of doc.linhas) {
      const linhaDocComercial: IDocComercialLinha = {
        showTextoLivre: false,
        ...linha
      };
      docComercial.linhas.push(linhaDocComercial);
    }
    const stateParams: IDocComercialEditStateParams = {doc: docComercial};
    return this._stateService.go(this.states.new.name, stateParams).then(() => undefined);
  }

  public duplicarDocumentoEditavelActions(doc: IJsonDocComercialCab): Promise<void> {
    return this.getDoc(doc.faccbId).then((result: IJsonDocComercial) => {
      return this.duplicarDocumentoEditavel(result).then(() => this.refreshList());
    });
  }

  public getEditarDocumentoActions(doc: IDocComercialCabWithData): Promise<void> {
    return this.getDoc(doc.faccbId).then((result: IJsonDocComercial) => {
      return this._getEditarDocumento(result);
    });
  }

  public encerrarDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.encerrarDocumento(doc, true).then((result) => {
      this.refreshList();
      return result;
    });
  }

  public readonly fnBeforeRequest: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequest(queryRequestConfig);

  public readonly fnBeforeRequestEditaveis: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequestEditaveis(queryRequestConfig);

  public readonly fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this.getPdf(doc);

  public readonly fnGetDocPdf = (doc: IDocComercialCabWithData) => (): Promise<void> => this.getPdfActions(doc);

  public readonly fnGetDoc: (id: number) => Promise<IJsonDocComercial> = (id: number) => this.getDoc(id);

  public readonly fnAnexarGDocComercial = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.anexarGDocComercial(doc);

  public readonly fnComunicarDocWebServiceAT = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.comunicarDocWebServiceAT(doc);

  public readonly fnComunicarDocCirculacaoAT = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.comunicarDocCirculacaoAT(doc, true);

  public readonly fnInserirCodigoTransporteAT = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.inserirCodigoTransporteAT(doc);

  public readonly fnAnularDocumento = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.anularDocumento(doc);

  public readonly fnNotaCreditoDocumento = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.notaCreditoDocumento(doc);

  public readonly fnTransformarLinhasDocumento = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.transformarLinhasDocumento(doc);

  public readonly fnDesmarcarDocTransformado: (doc: IJsonDocComercial) => Promise<IJsonDocComercial> = (doc: IJsonDocComercial) => this.desmarcarDocTransformado(doc);

  public readonly fnDuplicarDocumentoEditavel = (doc: IJsonDocComercial): Promise<void> => this.duplicarDocumentoEditavel(doc);

  public readonly fnEmitirRecibo = (doc: IJsonDocComercialCab) => (): Promise<void> => this._emitirRecibo(doc);

  public readonly fnDuplicarDocumentoEditavelActions = (doc: IDocComercialCabWithData) => (): Promise<void> => this.duplicarDocumentoEditavelActions(doc);

  public readonly fnComunicarDocWebServiceATCab = (doc: IJsonDocComercialCab) => (): Promise<IJsonDocComercial> => this.comunicarDocWebServiceATCab(doc);

  public readonly fnMeiosPagamentoComercial = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this._getMeiosPagamentoComercial(doc);

  public readonly fnEditarDocumento = (doc: IJsonDocComercial): Promise<void> => this._getEditarDocumento(doc);

  public readonly fnEditarDocumentoActions = (doc: IDocComercialCabWithData) => (): Promise<void> => this.getEditarDocumentoActions(doc);

  public readonly fnAnexarDocumentoActions = (doc: IDocComercialCabWithData) => (): Promise<IJsonDocComercial> => this._getAnexarDocumentoActions(doc);

  public readonly fnTransformarLinhasDocumentoActions = (doc: IDocComercialCabWithData) => (): Promise<IJsonDocComercial> => this._getTransformarLinhasDocumentoActions(doc);

  public readonly fnNotaCreditoDocumentoActions = (doc: IDocComercialCabWithData) => (): Promise<IJsonDocComercial> => this._getNotaCreditoDocumentoActions(doc);

  public readonly fnMostrarDocContabActions = (doc: IDocComercialCabWithData) => (): Promise<void> => this._showDocContab(doc);

  public readonly fnRastreabilidadeDocumentoActions = (doc: IDocComercialCabWithData) => (): Promise<void> => this._getRastreabilidadeDocumentoActions(doc);

  public readonly fnAnularDocumentoActions = (doc: IDocComercialCabWithData) => (): Promise<void | IJsonDocComercial> => this._getAnularDocumentoActions(doc);

  public readonly fnEncerrarDocumentoActions = (doc: IDocComercialCabWithData) => (): Promise<IJsonDocComercial> => this._getEncerrarDocumentoActions(doc);

  public readonly fnEncerrarDocumento = (doc: IJsonDocComercial): Promise<IJsonDocComercial> => this.encerrarDocumento(doc);

  public readonly fnDevolucoes = (doc: IDocComercial): Promise<void> => this._devolucoes(doc);

  public readonly fnReAbreDocumento = (doc: IDocComercialCabWithData) => (): Promise<void> => this._reAbreDocumento(doc);

  public readonly fnReAbreDocumentoActions = (doc: IDocComercialCabWithData): Promise<void> => this._reAbreDocumento(doc);

  public readonly fnEvaluateConfigOptionInvisible = (params: IDocsComerciaisListEvaluateConfigOptionInvisibleParams): boolean => this._evaluateConfigOptionInvisible(params);

  @ViewChild('templateKeyboardShortcuts')
  public set templateKeyboardShortcuts(value: TemplateRef<IPlToolbarItemTemplateContext>) {
    this._templateKeyboardShortcuts = value;
    this.setModuleKeyboardShortcuts(this._templateKeyboardShortcuts);
  }

  private _beforeRequest(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    if (!queryRequestConfig.pesquisa) {
      queryRequestConfig.pesquisa = '';
    } else {
      queryRequestConfig.pesquisa = queryRequestConfig.pesquisa.includes('(') ? `${queryRequestConfig.pesquisa}&` : `(${queryRequestConfig.pesquisa})&`;
    }
    queryRequestConfig.pesquisa += `grupoDocfa=${this.group.grupo}`;
    if (this.group.grupo === EGrupoDoc.ComprasEfectivas) {
      queryRequestConfig.pesquisa += this.group.properties?.autofaturacao ? '&autofaturacao>0' : '&autofaturacao=0';
    }

    if (this.tabEditaveisVisible) {
      queryRequestConfig.pesquisa +=
        this.group.grupo === EGrupoDoc.ComprasEfectivas && !this.group.properties?.autofaturacao
          ? `&hashEstado=${EFACCBHASHEstado.DocSemAssinatura}&status>=1`
          : `&hashEstado>${EFACCBHASHEstado.Rascunho}`;
    }

    return queryRequestConfig;
  }

  private _beforeRequestEditaveis(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    if (!queryRequestConfig.pesquisa) {
      queryRequestConfig.pesquisa = '';
    } else {
      queryRequestConfig.pesquisa = queryRequestConfig.pesquisa.includes('(') ? `${queryRequestConfig.pesquisa}&` : `(${queryRequestConfig.pesquisa})&`;
    }
    queryRequestConfig.pesquisa += `grupoDocfa=${this.group.grupo}`;
    if (this.group.grupo === EGrupoDoc.ComprasEfectivas) {
      queryRequestConfig.pesquisa += this.group.properties?.autofaturacao ? '&autofaturacao>0' : '&autofaturacao=0';
    }
    queryRequestConfig.pesquisa += `&hashEstado<${EFACCBHASHEstado.DocAssinado}&status=0`;
    return queryRequestConfig;
  }

  private _configLoginAt(): Promise<void> {
    return this._cgModalService.show(EFaturaCirculacaoLoginModalComponent, {size: 'sm'}).then(() => undefined);
  }

  private _showTrDocsWizard(): Promise<void> {
    const modalRef = this._cgModalService.showVanilla(TrdocsCabsModalComponent, {size: 'xxl', backdrop: 'static'});
    const componentInstance: TrdocsCabsModalComponent = modalRef.componentInstance;
    componentInstance.grupoDocfa = this.group.grupo;
    componentInstance.docType = grupoDocToDocType(this.group.grupo);
    return modalRef.result;
  }

  private _showFaturacaoPublica(): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_FATURACAO_PUBLICA, params: {stateOrName: ENTITY_NAME_DOCS_COMERCIAIS_VENDAS}});
  }

  private _showNameFileExport(): Promise<void> {
    const maintenanceDocsComerciaisConfigNomeFileExport: IModuleMaintenanceInstance = this._moduleMaintenanceService.build(MODULE_NAME_DOCS_COMERCIAIS_CONFIG_NOME_FILE_EXPORT);
    return maintenanceDocsComerciaisConfigNomeFileExport.maintenance({params: {group: this.group}});
  }

  private _callEFaturacaoLogin(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._cgModalService.show<IJsonEFaturaConfiguracao>(EFaturaConfigModalComponent, {size: 'lg', backdrop: 'static'}).then(() => {
      return this.comunicarDocWebServiceAT(doc);
    });
  }

  private _callEFaturacaoLoginCab(doc: IJsonDocComercialCab): Promise<IJsonDocComercial> {
    return this._cgModalService.show<IJsonEFaturaConfiguracao>(EFaturaConfigModalComponent, {size: 'lg', backdrop: 'static'}).then(() => {
      return this.comunicarDocWebServiceATCab(doc);
    });
  }

  private _comunicarDocWebServiceAT(faccbId: number): Promise<IJsonDocComercial> {
    return this._documentosService.comunicarDocWebServiceAT(faccbId, false);
  }

  private _callEFaturacaoCirculacaoLogin(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._cgModalService.show(EFaturaCirculacaoLoginModalComponent, {size: 'sm', backdrop: 'static'}).then(() => {
      return this.comunicarDocCirculacaoAT(doc, false);
    });
  }

  private _comunicarDocCirculacaoAT(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.comunicarDocCirculacaoAT(doc.cab.faccbId).then((response: IJsonDocComercial) => {
      if (response.cab.returnCode === WS_ERROR_RETURN_CODE) {
        return this._callEFaturacaoCirculacaoLogin(response);
      }
      return response;
    });
  }

  private _emitirRecibo(doc: IJsonDocComercialCab): Promise<void> {
    const reciboCab: IJsonReciboCab = {
      extPocCabID: '',
      numero: '',
      nConta: doc.nConta,
      nome: doc.nome,
      nif: doc.nif,
      email: '',
      data: moment(),
      valor: 0,
      stampUpdate: moment(),
      totalDescontos: 0,
      totalReceber: 0,
      total: 0,
      origem: 0,
      totalRetencao: 0,
      temRetencao: false,
      descricao: '',
      valorEntregueCredito: 0,
      nDocAsStr: '',
      anulado: false
    };
    const reciboModel: Partial<IJsonRecibo> = {
      cab: reciboCab
    };

    if (this.group.grupo !== this.groupDoc.ComprasEfectivas) {
      return this._cgStateService.redirectToState({stateOrName: ENTITY_NAME_RECIBOS, stateType: EEntityStateDetailType.NEW, params: {model: reciboModel}});
    }
    return this._cgStateService.redirectToState({stateOrName: ENTITY_NAME_PAGAMENTOS, stateType: EEntityStateDetailType.NEW, params: {nConta: doc.nConta, nome: doc.nome}});
  }

  private _getMeiosPagamentoComercial(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._documentosService.getMeiosPagamentoComercial(doc);
  }

  private _getEditarDocumento(doc: IJsonDocComercial): Promise<void> {
    const docComercial: IDocComercial = {
      cab: doc.cab,
      clifo: doc.clifo,
      moradaCarga: doc.moradaCarga,
      moradaDescarga: doc.moradaDescarga,
      transportador: doc.transportador,
      meiosPagamento: doc.meiosPagamento,
      linhas: []
    };
    for (const linha of doc.linhas) {
      const linhaDocComercial: IDocComercialLinha = {
        showTextoLivre: false,
        ...linha
      };
      docComercial.linhas.push(linhaDocComercial);
    }
    const stateParams: IDocComercialEditStateParams = {id: doc.cab.faccbId, doc: docComercial};
    return this._stateService.go(this.states.detail.name, stateParams).then(() => undefined);
  }

  private _getAnexarDocumentoActions(doc: IDocComercialCabWithData): Promise<IJsonDocComercial> {
    if (doc._doc) {
      return this.anexarGDocComercial(doc._doc);
    }
    return this.getDoc(doc.faccbId).then((result: IJsonDocComercial) => {
      return this.anexarGDocComercial(result);
    });
  }

  private _getTransformarLinhasDocumentoActions(doc: IDocComercialCabWithData): Promise<IJsonDocComercial> {
    if (doc._doc) {
      return this.transformarLinhasDocumento(doc._doc);
    }
    return this.getDoc(doc.faccbId).then((result: IJsonDocComercial) => {
      return this.transformarLinhasDocumento(result);
    });
  }

  private _getNotaCreditoDocumentoActions(doc: IDocComercialCabWithData): Promise<IJsonDocComercial> {
    if (doc._doc) {
      return this.notaCreditoDocumento(doc._doc);
    }
    return this.getDoc(doc.faccbId).then((result: IJsonDocComercial) => {
      return this.notaCreditoDocumento(result);
    });
  }

  private _devolucoes(doc: IJsonDocComercial): Promise<void> {
    const modalRef = this._cgModalService.showVanilla(DevolucoesModalComponent, {size: 'xxl'});
    const componentInstance: DevolucoesModalComponent = modalRef.componentInstance;
    componentInstance.doc = {
      cab: <IJsonDocComercialCab>doc
    };
    return modalRef.result.then(() => {
      return this.refreshList();
    });
  }

  private async _reAbreDocumento(doc: IDocComercialCabWithData | IJsonDocComercial): Promise<void> {
    const cab: IJsonDocComercialCab = <IJsonDocComercialCab>('cab' in doc ? doc.cab : doc);
    if (cab.integraContabilidade) {
      if (this.hasContabilidade) {
        const response: HttpResponse<Array<IJsonDocContabImputacao>> = await this._documentosService.getImputacoesDocContab(cab.faccbId);
        if (response.body.length) {
          const modalInstance: NgbModalRef = this._cgModalService.showVanilla(DocumentoImputacoesDocContabModalComponent, {size: 'md'});
          const componentInstance: DocumentoImputacoesDocContabModalComponent = modalInstance.componentInstance;
          componentInstance.list = response.body;
          componentInstance.faccbId = cab.faccbId;
          await modalInstance.result;
        }
      } else {
        await this._documentosService.apagaImputacoesDocContab(cab.faccbId);
      }
    }
    const docComercial: IJsonDocComercial = await this._documentosService.reAbreDocComercial(cab.faccbId).then((r) => r.body);
    const stateParams: IDocComercialEditStateParams = {id: cab.faccbId, doc: docComercial};
    await this._cgStateService.redirectToState({
      stateOrName: docsComerciaisGrupoDocToEntity(this.group.grupo),
      stateType: EEntityStateDetailType.EDIT,
      params: stateParams
    });
  }

  private async _showDocContab(doc: IJsonDocComercialCab): Promise<void> {
    const extPocCabID: string = await this._docsComerciaisService.getDocContabId(doc.faccbId).then((response: HttpResponse<string>) => response.body);
    await this._maintenanceInstanceDocContab.maintenanceEdit(extPocCabID);
  }

  private _getRastreabilidadeDocumentoActions(docCab: IJsonDocComercialCab): Promise<void> {
    return this._documentoRastreabilidadeService.getRastreabilidade(docCab.nDocFa, docCab.nNumer, docCab.nDocumentoDraft).then((response: HttpResponse<Array<IJsonRastreabilidadeFaturacao>>) => {
      const modalRef = this._cgModalService.showVanilla(DocumentoRastreabilidadeModalComponent, {size: 'fullscreen'});
      const componentInstance: DocumentoRastreabilidadeModalComponent = modalRef.componentInstance;
      componentInstance.rastreabilidade = response.body;
      componentInstance.faccbId = docCab.faccbId;
      modalRef.result.then(() => {
        this.refreshList();
      });
    });
  }

  private async _getAnularDocumentoActions(doc: IDocComercialCabWithData): Promise<void> {
    if (!doc._doc) {
      doc._doc = await this.getDoc(doc.faccbId);
    }
    if (doc.nDocumento === 0) {
      await this._cgModalService.showOkCancel('docscomerciais.auxiliares.anularDocumento', 'gestaoDocsComerciais.messages.anularAviso', {
        size: 'md',
        icon: 'warning',
        btnOkIcon: 'fa fa-fw fa-check-circle-o',
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no'
      });
    }
    await this._documentosService.anularDocumento(doc._doc);
    this._plAlertService.success('docscomerciais.info.anularDocumento');
    await this.refreshList();
  }

  private _getEncerrarDocumentoActions(doc: IDocComercialCabWithData): Promise<IJsonDocComercial> {
    if (doc._doc) {
      return this.encerrarDocumento(doc._doc);
    }
    return this.getDoc(doc.faccbId).then((result: IJsonDocComercial) => {
      return this.encerrarDocumento(result);
    });
  }

  private _gestaoAvencas(): Promise<void> {
    const modalRef = this._cgModalService.showVanilla(GestaoDocsComerciaisModalComponent, {size: 'xxl'});
    const componentInstance: GestaoDocsComerciaisModalComponent = modalRef.componentInstance;
    componentInstance.group = this.group;
    componentInstance.service = this.service;
    return modalRef.result.then(() => {
      this.refreshList();
    });
  }

  private async _canShowAccoes(): Promise<boolean> {
    const docType = grupoDocToDocType(this.group.grupo);
    return Promise.resolve(docType === EGrupoDocType.Venda || docType === EGrupoDocType.Compra);
  }

  private _evaluateConfigOptionInvisible({key}: IDocsComerciaisListEvaluateConfigOptionInvisibleParams): boolean {
    return key === 'showPrestacoes' && this.group.grupo !== EGrupoDoc.VendasEfectivas && this.group.grupo !== EGrupoDoc.ComprasEfectivas;
  }
}
