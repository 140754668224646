import {TDate} from '../../../common/dates';
import {ETipoImportacaoSAFTContab} from './saftContab.module.interface';

export enum ESAFTContabProgressOperation {
  Idle,
  SetMode,
  LoadConfiguracoesExcel,
  ProcessFile,
  SaveConfiguracoesDiarios,
  SaveCorrespondenciaContas,
  ImportSaft
}

export enum ESAFTContabProgressState {
  Inactive = 'scsInactive',
  Scheduled = 'scsScheduled',
  Started = 'scsStarted',
  Ended = 'scsEnded',
  Timeout = 'scsTimeout',
  Error = 'scsError'
}

export interface IJsonSAFTContabStatus {
  requestDate: TDate;
  userStarted: string;
  userStartedId: number;
  state: ESAFTContabProgressState;
  description: string;
  position: number;
  plannedStartDate: TDate;
  currentOp: ESAFTContabProgressOperation;
  filename: string;
  max: number;
  objModel: IJsonSAFTContabObjFullModel;
}

export interface IJsonSAFTContabDiario {
  nDiario: string;
  nomeDiario: string;
  nDiarioDestino: string;
  nomeDiarioDestino: string;
}

export interface IJsonSAFTContabConvertConta {
  nContaOrigem: string;
  nomeContaOrigem: string;
  nContaDestino: string;
  nomeContaDestino: string;
  erro: string;
}

export interface IJsonSAFTContabPlanoContas {
  nContaOrigem: string;
  nomeContaOrigem: string;
  nContaDestino: string;
  nomeContaDestino: string;
  criaConta: boolean;
  codControlIvaCredito: string;
  codControlIvaDebito: string;
  erro: string;
  convertConta: boolean;
  contaExistente: boolean;
  contasSemConversao: boolean;
}

export interface IJsonSAFTContabContaSemConversao {
  erro: string;
  criaConta: boolean;
  codControlIvaCredito: string;
  codControlIvaDebito: string;
  nomeConta: string;
  nConta: string;
}

export interface IJsonSAFTContabContaCorrente {
  erro: string;
  nif: string;
  nConta: string;
  nome: string;
}

export interface IJsonSAFTContabBalanceteSaldado {
  balanceteSaldado: boolean;
  totalDebito: number;
  totalCredito: number;
}

export interface IJsonSAFTContabListOption {
  id: number;
  text: string;
}

export interface IJsonSAFTContabGeneratedDocContab {
  valor: number;
  extPocCabID: string;
  nDocumento: string;
  invoices: string;
  valorIVACG: number;
  valorIVA: number;
  valorBaseCG: number;
  valorCG: number;
  valorBase: number;
  valorArredBase: number;
  valorArredIVA: number;
  erro: string;
  select: boolean;
  tipo: number;
  tipoStr: string;
  anulado: boolean;
}

export interface IJsonSAFTContabConta {
  nconta: string;
  nome: string;
  tipo: string;
  level: number;
}

export interface IJsonSAFTContabContaBase {
  valorIVADoc: number;
  taxaCalculada: number;
  nContasBase: number;
  valorIvaCalculado: number;
  documento: string;
  valorBaseDoc: number;
  nContasIVA: number;
  valorBase: number;
  taxaSobreTotalIVA: number;
  docList: Array<IJsonSAFTContabContaBaseDoc>;
}

export interface IJsonSAFTContabContaBaseDoc {
  contaDebito: string;
  contaCredito: string;
  descricao: string;
  valor: number;
}

export interface IJsonSAFTContabTreeDetail {
  treeCG: Array<IJsonSAFTContabConta>;
  contasBase: Array<IJsonSAFTContabContaBase>;
  treeSaft: Array<IJsonSAFTContabConta>;
}

export interface IJsonSAFTContabObjFullModel {
  tipoImportacao: ETipoImportacaoSAFTContab;
  associacaoDiarioList: Array<IJsonSAFTContabDiario>;
  planoContasList: Array<IJsonSAFTContabPlanoContas>;
}
