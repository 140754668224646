<div class="rhsaltotrf entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="fnPesquisar" plPromise>
        <pl-group>
          <pl-group>
            <label [translate]="'rhsaltotrf.fields.codEmpDe'"></label>
            <edit>
              <entity-autocomplete
                entity="dgempsfull"
                attrName="codEmpDe"
                [model]="rhSalToTrfHeader"
                (selectedKeyChange)="rhSalToTrfHeader.codEmpDe = $event"
                [fieldsMap]="{codEmp: 'codEmpDe'}"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="dgempsfull"
                attrName="codEmpAte"
                [model]="rhSalToTrfHeader"
                (selectedKeyChange)="rhSalToTrfHeader.codEmpAte = $event"
                [fieldsMap]="{codEmp: 'codEmpAte'}"
                output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'rhsaltotrf.fields.dataProcesDE'"></label>
            <edit>
              <pl-edit type="date" [(model)]="rhSalToTrfHeader.dataProcesDe"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit type="date" [(model)]="rhSalToTrfHeader.dataProcesAte">
                <div *inputGroup="{klass: 'hollow-form'}">
                  <pl-tooltip *ngIf="!promiseProcessamento" [config]="tooltipExcepcoesProcessamento">
                    <i *plTooltipContent class="fa fa-ban text-danger" aria-hidden="true" (click)="openExclusionsProcessamento()"></i>
                  </pl-tooltip>
                  <i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true" *ngIf="promiseProcessamento"></i>
                </div>
              </pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <edit>
            <button type="submit" class="btn btn-sm btn-primary" name="pesquisar" id="btnPesquisar"><i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span></button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <div class="mt-4">
    <pl-tabs [(activeId)]="activeTabId" (activeIdChange)="activeTabIdChange()" [destroyOnHide]="false">
      <pl-tab [id]="tabs.RhTransBancariaVencimentos">
        <div *plTabTitle><i class="fa fa-fw fa-file-text-o" aria-hidden="true"></i>&nbsp;<span [translate]="'rhsaltotrf.tabs.vencimentos'"></span></div>
        <div *plTabContent>
          <ng-container *ngTemplateOutlet="templateDataGrid; context: {list: rhSalToTrfList}"></ng-container>

          <div class="d-flex mt-3 list-ativos-totals ms-auto w-25">
            <div class="card">
              <div class="card-header">
                <span class="fa fa-tag float-start"></span>
                <span class="total-listagens float-end" [translate]="'rhsaltotrf.table.resumo'"></span>
              </div>
              <div class="card-body">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTotals" [dataSource]="rhSalToTrfTotals"></dx-data-grid>
              </div>
            </div>
          </div>
        </div>
      </pl-tab>

      <pl-tab [id]="tabs.RhTransBancariaTicketsRefeicao">
        <div *plTabTitle><i class="fa fa-fw fa-files-o" aria-hidden="true"></i>&nbsp;<span [translate]="'rhsaltotrf.tabs.cartaoTicketRefeicao'"></span></div>
        <div *plTabContent>
          <ng-container *ngTemplateOutlet="templateDataGrid; context: {list: rhSalToTrfList}"></ng-container>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>
</div>

<ng-template #templateDataGrid let-list="list">
  <dx-data-grid
    class="mt-3"
    [cgDxDataGrid]="dataGrid"
    [dataSource]="list"
    [(selectedRowKeys)]="selectedRowKeys"
    (onInitialized)="onInitialized($event)"
    (onCellPrepared)="onCellPrepared($event)"
    (onCellClick)="onCellClick($event)"
    (onSelectionChanged)="onSelectionChanged($event)">
  </dx-data-grid>
</ng-template>
