<div class="gestao-comunicacoes-config-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4
      class="modal-title"
      [translate]="'gestaoComunicacoes.modal.empresaConfig.title'"
      [translateParams]="{nEmpresa: empresaSelectedDados.nEmpresa, nomeEmpresa: empresaSelectedDados.nomeEmpresa}"></h4>
  </div>

  <div class="modal-body">
    <pl-tabs [theme]="'style3'">
      <pl-tab [id]="'estados'" [caption]="'gestaoComunicacoes.modal.empresaConfig.empresaConfigEstados.title'">
        <div *plTabContent>
          <div class="row">
            <div class="col-sm-12">
              <pl-alert type="info" [closeable]="false">
                <i class="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaoComunicacoes.modal.empresaConfig.empresaConfigEstados.mensagens.notaOrdenarEstados'"></span>
              </pl-alert>
              <hr />
            </div>
          </div>

          <div class="d-flex justify-content-between align-content-center align-items-center">
            <h5 class="info mb-2" [translate]="'gestaoComunicacoes.modal.empresaConfig.empresaConfigEstados.estados'"></h5>
            <pl-button klass="btn btn-primary btn-sm" (evtClicked)="addEstado()"><i class="fa fa-fw fa-plus"></i>&nbsp;<span [translate]="'global.btn.new'"></span></pl-button>
          </div>

          <div class="cg-ui-tree">
            <div class="row">
              <div class="col-sm-12">
                <div class="angular-ui-tree">
                  <pl-ui-tree-drag [(uiTree)]="estados" [disabled]="!editing" [templateContent]="templateNodeContentEstados" [maxLevels]="1"></pl-ui-tree-drag>
                </div>
              </div>
            </div>
          </div>
        </div>
      </pl-tab>
      <pl-tab [id]="'utilizadores'" [caption]="'gestaoComunicacoes.modal.config.selectUtilizador.title'">
        <div *plTabContent>
          <div [class.inherited-model]="inherited">
            <pl-multiselect
              [model]="configUtilizadores.utilizadoresSelecionados"
              [source]="configUtilizadores.utilizadoresPossiveis"
              (modelChange)="utilizadoresModelChange($event)"
              key="_id"
              [template]="templateUtilizador"
              [properties]="{leftCaption: 'gestaoComunicacoes.modal.config.selectUtilizador.leftCaption', rightCaption: 'gestaoComunicacoes.modal.config.selectUtilizador.rightCaption'}">
            </pl-multiselect>
          </div>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-primary btn-sm action-submit" (evtClicked)="close()" [disabled]="closeDisabled"
      ><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
    </pl-button>
    <pl-button klass="btn-light btn-sm action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>

<ng-template #templateNodeContentEstados let-node="node" let-meta="meta" let-self="self">
  <ng-container *ngTemplateOutlet="templateEstados; context: {allowEditing: editing && !meta._empresaSistema, node: node, meta: meta, self: self}"></ng-container>
</ng-template>

<ng-template #templateEstados let-node="node" let-meta="meta" let-self="self" let-allowEditing="allowEditing">
  <div class="d-flex flex-wrap flex-grow-1 gap-2">
    <div class="d-flex flex-column flex-grow-1">
      <div class="tree-node-page-title">
        <span class="tree-node-title-value">{{ meta.nome }}</span>
      </div>
    </div>

    <div class="d-flex flex-wrap align-items-center gap-1">
      <ng-container *ngIf="allowEditing">
        <button *ngIf="editing" type="button" class="btn btn-xs btn-primary" [title]="'portals.btn.edit' | translate" (click)="editNode(meta)">
          <span class="fa fa-pencil"></span>
        </button>

        <button *ngIf="editing" type="button" class="btn btn-xs btn-danger" [title]="'portals.btn.remove' | translate" (click)="removeNode(meta)">
          <span class="fa fa-remove"></span>
        </button>
      </ng-container>
    </div>
  </div>
</ng-template>
