import {AfterViewInit, Component} from '@angular/core';
import {StateService, Transition, UIRouterGlobals} from '@uirouter/core';
import {Logger} from 'pl-comps-angular';
import {ILoadmoduleStateParams} from './loadmodule.state.interface';
import {STATE_NAME_PORTAL} from '../../services/portals/portals.service.interface';

@Component({
  selector: 'load-module-state',
  templateUrl: './loadmodule.state.component.html'
})
export class LoadModuleStateComponent implements AfterViewInit {
  constructor(
    private readonly _logger: Logger,
    private readonly _stateService: StateService,
    private readonly _transition: Transition,
    private readonly _uiRouterGlobals: UIRouterGlobals
  ) {}

  public ngAfterViewInit(): void {
    this._redirect().catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  private async _redirect(): Promise<void> {
    if (this._uiRouterGlobals.transition) {
      await this._uiRouterGlobals.transition.promise.catch((reason: unknown) => {
        this._logger.error(reason);
      });
    }
    const params: ILoadmoduleStateParams = <ILoadmoduleStateParams>this._transition.params();
    const moduleName: string = params.moduleName;
    if (moduleName) {
      await this._stateService.go(moduleName, params.moduleParams);
    } else if (!params.loaded) {
      await this._stateService.go(STATE_NAME_PORTAL);
      await this._stateService.go(this._transition.to(), {loaded: true});
    }
  }
}
