import {Injectable, Type} from '@angular/core';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {DocsComerciaisModalComponent} from './docsComerciais.modal.component';
import {DocumentoAnularModalComponent} from './anular/documento.anular.modal.component';
import {DocumentoComunicarATModalComponent} from './autoridadetributaria/documento.comunicarAT.modal.component';
import {DocumentoDuplicarModalComponent} from './duplicardocumento/documento.duplicarDocumento.modal.component';
import {DocumentoFacturacaoEncerrarModalComponent} from '../../../components/documento/facturacao/documentofaturacaoencerrarModal/documento.facturacao.encerrar.modal.component';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {GDocComercialModalComponent} from './gdoccomercial/gdoc.comercial.modal.component';
import {ICGModalOptions} from '../../../components/cg/modal/cgmodal.interface';
import {IDocsComerciaisEntityService} from '../docsComerciais.entity.interface';
import {IJsonDocComercial} from '../jsonDocComercial.entity.interface';
import {TrDocsLinhasModalComponent} from '../../../components/docscomerciais/trDocs/modals/linhas/trdocs.linhas.modal.component';
import {DevolucoesModalComponent} from './devolucoes/devolucoes.modal.component';

@Injectable({
  providedIn: 'root'
})
export class DocsComerciaisModalService {
  private readonly _serviceDocsComerciais: IDocsComerciaisEntityService;

  constructor(
    private readonly _cgModalService: CGModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this._serviceDocsComerciais = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>('docscomerciais');
  }

  public comunicarAT(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._show(DocumentoComunicarATModalComponent, doc);
  }

  public anularDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._show(DocumentoAnularModalComponent, doc);
  }

  public duplicarDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._show(DocumentoDuplicarModalComponent, doc);
  }

  public notaCreditoDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._show(DevolucoesModalComponent, doc, {size: 'xxl'});
  }

  public transformarLinhasDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._show(TrDocsLinhasModalComponent, doc, {size: 'xl'});
  }

  public anexarGDocComercial(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._show(GDocComercialModalComponent, doc, {size: 'xl'});
  }

  public encerrarDocumento(doc: IJsonDocComercial): Promise<IJsonDocComercial> {
    return this._show(DocumentoFacturacaoEncerrarModalComponent, doc);
  }

  private _show(component: Type<DocsComerciaisModalComponent>, doc: IJsonDocComercial, options?: ICGModalOptions<IJsonDocComercial>): Promise<unknown> {
    const modalInstance = this._cgModalService.showVanilla(component, {size: 'md', ...options});
    const componentInstance: DocsComerciaisModalComponent = modalInstance.componentInstance;
    componentInstance.doc = doc;
    componentInstance.service = this._serviceDocsComerciais;
    return modalInstance.result;
  }
}
