<div class="rhtransferenciasbancarias entity-detail-form">
  <pl-tabs [(activeId)]="activeTab">
    <pl-tab id="tickets">
      <div *plTabTitle><i class="fa fa-fw fa-ticket" aria-hidden="true"></i>&nbsp;<span [translate]="'rhtransferenciasbancarias.tab.listabancos'"></span></div>
      <div *plTabContent>
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="transferenciasBancariasTickets"
          [dataSource]="dataGridDefinition.dataSource"
          (onInitialized)="onInitialized($event)">
          <div *dxTemplate="let item of 'templateToolbar'">
            <pl-edit
              type="boolean"
              [properties]="{label: 'rhtransferenciasbancarias.options.ticketFormatSEPA'}"
              [model]="model.formatoSEPATicketRefeicao"
              (modelChange)="formatoTicketRefeicaoChanged($event)">
            </pl-edit>
          </div>
          <div *dxTemplate="let item of 'cellTemplateBtn'">
            <button type="button" class="btn btn-warning btn-xs" [click]="fnEditarLinha(item.data, item.rowIndex)" plPromise>
              <i class="fa fa-fw fa-pencil"></i>
            </button>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab id="exclusoes">
      <div *plTabTitle><i class="fa fa-fw fa-exchange" aria-hidden="true"></i>&nbsp;<span [translate]="'rhtransferenciasbancarias.tab.exclusaoabonos'"></span></div>
      <div *plTabContent>
        <pl-form>
          <pl-group>
            <pl-group>
              <label [translate]="'rhtransferenciasbancarias.tab.codigo'"></label>
              <edit>
                <pl-edit type="tipoAbonoDesc" [model]="codigoTipo" (modelChange)="codigoTipoChanged($event)"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label>&nbsp;</label>
              <edit>
                <div [ngSwitch]="codigoTipo">
                  <entity-autocomplete
                    *ngSwitchCase="tipoAbonoDesconto.Abono"
                    entity="abonos"
                    attrName="codABDESC"
                    [model]="abonoDesconto"
                    (selectedKeyChange)="abonoDesconto.codAbDesc = $event"
                    (evtSelectedDescriptionChanged)="abonoDesconto.designacaoBreve = $event"
                    [properties]="{validators: {required: {value: false}}}">
                  </entity-autocomplete>

                  <entity-autocomplete
                    *ngSwitchCase="tipoAbonoDesconto.Desconto"
                    entity="descontos"
                    attrName="codABDESC"
                    [model]="abonoDesconto"
                    (selectedKeyChange)="abonoDesconto.codAbDesc = $event"
                    (evtSelectedDescriptionChanged)="abonoDesconto.designacaoBreve = $event"
                    [properties]="{validators: {required: {value: false}}}">
                  </entity-autocomplete>
                </div>
              </edit>

              <actions>
                <button type="button" class="btn btn-sm btn-primary" (click)="adicionarAbonoDesconto()" [disabled]="!abonoDesconto.codAbDesc || !abonoDesconto.codAbDesc">
                  <i class="fa fa-fw fa-plus"></i>
                  <span [translate]="'global.btn.add'"></span>
                </button>
              </actions>
            </pl-group>
          </pl-group>
        </pl-form>

        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinitionExcluded"
          cgDxDataGridInstanceName="transferenciasBancariasExcluded"
          [dataSource]="dataGridDefinitionExcluded.dataSource"
          (onInitialized)="onInitialized($event)">
          <div *dxTemplate="let item of 'cellTemplateBtnExcluded'">
            <button type="button" class="btn btn-danger btn-xs" (click)="eliminarAbonosDescontos(item.rowIndex)">
              <i class="fa fa-fw fa-trash-o"></i>
            </button>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab id="transf">
      <div *plTabTitle><i class="fa fa-fw fa-truck" aria-hidden="true"></i>&nbsp;<span [translate]="'rhtransferenciasbancarias.tab.transfBancarias.title'"></span></div>
      <div *plTabContent>
        <!-- Ficheiro PS2 -->
        <fieldset class="mb-3">
          <legend [translate]="'rhtransferenciasbancarias.tab.transfBancarias.formatoPS2.title'"></legend>
          <pl-group>
            <edit>
              <pl-edit
                type="boolean"
                [model]="model.ficheiroDeTransferenciasNoFormatoPS2"
                (modelChange)="formatoPSChanged($event)"
                [properties]="{label: 'rhtransferenciasbancarias.tab.transfBancarias.formatoPS2.ficheiroFormatoPS2'}">
              </pl-edit>
            </edit>
          </pl-group>
        </fieldset>

        <!-- Banco CGD -->
        <fieldset class="mb-3">
          <legend [translate]="'rhtransferenciasbancarias.tab.transfBancarias.cgd.title'"></legend>
          <pl-group>
            <pl-group>
              <edit>
                <pl-edit
                  type="boolean"
                  [model]="model.exportacaoTXTSeparacaoNibs"
                  (modelChange)="separacaoNibsChanged($event)"
                  [properties]="{label: 'rhtransferenciasbancarias.tab.transfBancarias.cgd.separacaoNibs'}">
                </pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <edit>
                <pl-edit
                  type="boolean"
                  [model]="model.formatoTXTCGDSEPA"
                  (modelChange)="formatoTXTCGDSEPAChanged($event)"
                  [properties]="{label: 'rhtransferenciasbancarias.tab.transfBancarias.cgd.exportacaoTXTCGDSEPA'}">
                </pl-edit>
              </edit>
            </pl-group>
          </pl-group>
        </fieldset>

        <!-- Direção Geral do Tesouro -->
        <fieldset>
          <legend [translate]="'rhtransferenciasbancarias.tab.transfBancarias.dgt.title'"></legend>
          <pl-group>
            <edit>
              <pl-edit
                type="boolean"
                [model]="model.ficheiroDeTransferenciaDGTNoFormatoSEPA"
                (modelChange)="dgtFormatoSepaChanged($event)"
                [properties]="{label: 'rhtransferenciasbancarias.tab.transfBancarias.dgt.dgtFormatoSEPA'}">
              </pl-edit>
            </edit>
          </pl-group>
        </fieldset>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
