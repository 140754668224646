import {ContratosService} from '../../../contratos/contratos.entity.service';
import {Component, Injector, OnInit} from '@angular/core';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IContratoCabWithData, IContratoEditStateParams, IJsonContrato, IJsonContratoCab} from '../../../contratos/jsonContrato.entity.interface';
import {IModuleEntityListOnDataGridCellClick} from '../../../../components/module/entitylist/module.entitylist.interface';
import {HttpResponse} from '@angular/common/http';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import moment from 'moment';
import {MODULE_NAME_FATURACAO_CONTRATOS} from '../../../../modules/faturacaocontratos/faturacaoContratos.module.interface';
import {AvencasSelecionarTipoModalComponent} from '../../modal/selecionartipo/avencas.selecionar.tipo.modal.component';
import {GestaoAvencasModalComponent} from '../../../../modules/faturacaocontratos/modals/gestaoavencasfaturadas/gestaoAvencas.modal.component';
import {ENTITY_NAME_CLASSES_CONTRATOS_AVENCAS} from '../../../classescontratos/classesContratos.entity.interface';

import {ENTITY_NAME_CONTRATOS_TIPO_AVENCAS} from '../../../contratostipoavencas/contratosTipoAvencas.entity.interface';
import {ENTITY_NAME_AVENCAS, IContratosEntityService} from '../../../contratos/contrato.entity.interface';

const BAR_BUTTON_FATURACAO_AVENCAS = 201;

@Component({
  selector: 'avencas-list',
  templateUrl: './avencas.entity.list.component.html'
})
export class AvencasListComponent extends ModuloEntityListComponent<IJsonContratoCab> implements OnInit {
  public dataGridProperties: IDevExpressDataGrid;
  public activeTabId: string;
  public ativosCount: number;
  public expiradosCount: number;
  public terminadosCount: number;

  public readonly tabAtivosId: string = 'ativosTab';
  public readonly tabExpiradosId: string = 'expiradosTab';
  public readonly tabTerminadosId: string = 'terminadosTab';

  private _savedSearchValue: string;
  private readonly _contratosEntityService: IContratosEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _contratosService: ContratosService
  ) {
    super(_injector);
    this._contratosEntityService = this._entityServiceBuilder.build<IJsonContrato, IContratosEntityService>(ENTITY_NAME_AVENCAS);
    this.activeTabId = this.tabAtivosId;
    this.ativosCount = 0;
    this.expiradosCount = 0;
    this.terminadosCount = 0;
    this.dataGridProperties = {allowColumnResizing: true};
    this._contratosService.isAvenca = true;
    this._savedSearchValue = '';
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.toolbar.addButton({
      id: 'btnFaturacaoAvencas',
      type: 'button',
      order: BAR_BUTTON_FATURACAO_AVENCAS,
      class: 'btn-warning',
      iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
      caption: 'contratosservicos.modulos.faturacao',
      click: () => this._callModuloFaturacaoAvencas()
    });

    this.setModuleActions([
      {
        caption: 'contratosservicos.modulos.classes',
        iconLeft: '<i class="fa fa-fw fa-list-alt"></i>',
        order: 203,
        action: () => this._callModuloClassesContratos()
      },
      {
        caption: 'contratosservicos.modulos.tipos',
        iconLeft: '<i class="fa fa-fw fa-indent"></i>',
        order: 204,
        action: () => this._callModuloTiposAvencas()
      },
      {
        caption: 'faturacaoContratos.btn.gestaoAvencas',
        iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
        order: 205,
        action: () => this._gestaoAvencas()
      },
      {
        caption: 'contratosservicos.modal.faturacaoAuto.title',
        iconLeft: '<i class="fa fa-fw fa-cog"></i>',
        order: 206,
        action: () => this._callModuloSelTiposAvencas()
      }
    ]);

    this._refreshContadores().then(() => undefined);
  }

  public onDetail(event: IModuleEntityListOnDataGridCellClick<IContratoCabWithData>): Promise<void> {
    const {data} = event;
    return this.service.get<IJsonContrato>({id: data.refCtrCab}).then((response: HttpResponse<IJsonContrato>) => {
      data._contrato = response.body;
      return super.onDetail(event);
    });
  }

  public onSearchValueChange(searchValue: string): Promise<void> {
    this._savedSearchValue = searchValue;
    this.searchValue = searchValue ? `${searchValue}&${this._getAvencasFilter()}` : this._getAvencasFilter();
    return this.refreshList();
  }

  public async changedTab(id: string): Promise<void> {
    this.activeTabId = id;
    this.searchValue = this._savedSearchValue ? `${this._savedSearchValue}&${this._getAvencasFilter()}` : this._getAvencasFilter();
    return this.refreshList();
  }

  public readonly fnEditAvenca = (avenca: IJsonContrato): Promise<void> => this._editAvenca(avenca);

  public readonly fnReabrirAvenca = (contrato: IJsonContrato): Promise<IJsonContrato> => this._reabrirAvenca(contrato);

  public readonly fnTerminarAvenca = (contrato: IJsonContrato): Promise<IJsonContrato> => this._terminarAvenca(contrato);

  public readonly fnApagarAvenca = (contrato: IJsonContrato): Promise<void> => this._apagarAvenca(contrato);

  private _editAvenca(avenca: IJsonContrato): Promise<void> {
    const paramAvenca: IJsonContrato = {
      ...avenca
    };
    const stateParams: IContratoEditStateParams = {id: paramAvenca.cab.refCtrCab, contrato: paramAvenca};
    return this._stateService.go(this.states.detail.name, stateParams).then(() => undefined);
  }

  private _reabrirAvenca(contrato: IJsonContrato): Promise<IJsonContrato> {
    const message = this._translateService.instant('contratosservicos.message.temCertezaReabrir', {avenca: contrato.cab.nContratoSemVersaoAsString});
    return this._cgModalService.showOkCancel('contratosservicos.info.reabrir', message, {size: 'md'}).then(() =>
      this._contratosService.reabrirContrato(contrato).then(async (result) => {
        await this.refreshList();
        await this._refreshContadores();
        return result;
      })
    );
  }

  private _terminarAvenca(contrato: IJsonContrato): Promise<IJsonContrato> {
    const message = this._translateService.instant('contratosservicos.message.temCertezaTerminar', {avenca: contrato.cab.nContratoSemVersaoAsString});
    return this._cgModalService.showOkCancel('contratosservicos.info.terminar', message, {size: 'md'}).then(() =>
      this._contratosService.terminarContrato(contrato).then(async (result) => {
        await this.refreshList();
        await this._refreshContadores();
        return result;
      })
    );
  }

  private _apagarAvenca(contrato: IJsonContrato): Promise<void> {
    const message = this._translateService.instant('contratosservicos.message.temCertezaApagar', {avenca: contrato.cab.nContratoSemVersaoAsString});
    return this._cgModalService.showOkCancel('contratosservicos.info.apagar', message, {size: 'md'}).then(() =>
      this._contratosService.apagarContrato(contrato.cab.refCtrCab).then(async (result) => {
        await this.refreshList();
        await this._refreshContadores();
        return result;
      })
    );
  }

  private _callModuloFaturacaoAvencas(): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_FATURACAO_CONTRATOS, params: {moduleNameRetroceder: this.entity.name}});
  }

  private _callModuloClassesContratos(): Promise<void> {
    const maintenanceClassesContratos = this._entityMaintenanceService.build(ENTITY_NAME_CLASSES_CONTRATOS_AVENCAS);
    return maintenanceClassesContratos.maintenanceSelectAndEdit().then(() => undefined);
  }

  private _callModuloTiposAvencas(): Promise<void> {
    const maintenanceTiposAvencas = this._entityMaintenanceService.build(ENTITY_NAME_CONTRATOS_TIPO_AVENCAS);
    return maintenanceTiposAvencas.maintenanceSelectAndEdit().then(() => undefined);
  }

  private _callModuloSelTiposAvencas(): Promise<void> {
    const instance = this._cgModalService.showVanilla(AvencasSelecionarTipoModalComponent, {size: 'md'});
    return instance.result.then((response: string) => {
      if (response) {
        const maintenanceTiposAvencas = this._entityMaintenanceService.build(ENTITY_NAME_CONTRATOS_TIPO_AVENCAS);
        return maintenanceTiposAvencas.maintenanceEdit(response).then(() => undefined);
      }
      return undefined;
    });
  }

  private _gestaoAvencas(): Promise<void> {
    return new Promise<void>((resolve) => {
      this._cgModalService.showVanilla(GestaoAvencasModalComponent, {size: 'xxl'});
      resolve();
    });
  }

  private _getAvencasFilter(): string {
    const dataFim = moment().subtract(1, 'days');
    if (this.activeTabId === this.tabAtivosId) {
      return `terminado=0&dataFim>=${dataFim.toJSON()}`;
    } else if (this.activeTabId === this.tabExpiradosId) {
      return `terminado=0&dataFim<${dataFim.toJSON()}`;
    }
    return `terminado=1`;
  }

  private _refreshContadores(): Promise<void> {
    return this._contratosEntityService.getContadores().then((response) => {
      this.ativosCount = response.body.ativos;
      this.expiradosCount = response.body.expirados;
      this.terminadosCount = response.body.terminados;
    });
  }
}
