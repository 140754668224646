import {ROLE} from '../../services/role.const';
import {MODULE_NAME_MOVIMENTACOES} from './movimentacoes.module.interface';
import {MovimentacoesModuleComponent} from './components/movimentacoes.module.component';
import {IModuleDefinition} from '../../components/module/module.definition.interface';

export const MODULE_MOVIMENTACOES: IModuleDefinition = {
  name: MODULE_NAME_MOVIMENTACOES,
  state: {
    url: '/movimentacoes',
    component: MovimentacoesModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.movimentacoes'
    },
    params: {
      grupo: {
        type: 'any',
        value: undefined
      }
    }
  }
};
