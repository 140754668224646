<div class="categorias">
  <entity-detail
    entityName="categorias"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL, orientation: formOrientation}">
        <pl-group>
          <label [translate]="'categorias.fields.nCateg'"></label>
          <edit>
            <pl-edit
              type="string"
              attrName="nCateg"
              [(model)]="model.nCateg"
              [properties]="{disabled: type === stateTypes.EDIT, validators: {required: {value: true}, maxlength: {value: 4}}}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'categorias.fields.designaBreve'"></label>
          <edit>
            <pl-edit type="string" attrName="designaBreve" [(model)]="model.designaBreve" [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'qpirt.fields.codIRCT'"></label>
          <edit>
            <entity-autocomplete
              entity="qpirt"
              attrName="codIRCT"
              [model]="model"
              [fieldsMap]="{codirct: 'codIrct', nome: 'nomeIrct'}"
              (modelChange)="onCodIrctChange($event)"
              (selectedKeyChange)="model.codIrct = $event"
              (evtSelectedDescriptionChanged)="model.nomeIrct = $event"
              [output]="qpirtOutput">
            </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'categorias.fields.codCategoriaMT'"></label>
          <edit>
            <entity-autocomplete
              entity="qpcategorias"
              attrName="codCategoriaMT"
              [model]="model"
              [selectedKey]="modelQPCategorias.codCategoria"
              (selectedKeyChange)="model.codCategoriaMT = $event"
              (evtSelectedDescriptionChanged)="model.nomeCategoria = $event"
              [fieldsMap]="{codCategoria: 'codCategoriaMT', nome: 'nomeCategoria'}"
              [output]="qpcategoriasOutput"
              [filter]="modelQPCategorias.codIrct"
              [properties]="{disabled: modelQPCategorias.codIrct === ''}">
            </entity-autocomplete>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
