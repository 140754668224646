<div class="gestaocomercial gestaocomercial-entity-edit" [plPromise]="promiseCalc">
  <config-options
    *ngIf="isShowConfigOptions && !!toolbarInstanceName"
    [evaluateInvisibleFn]="fnEvaluateConfigOptionInvisible"
    [instanceName]="configOptionsInstanceName"
    [groupName]="configOptionsGroupName"
    [toolbarInstanceName]="toolbarInstanceName"></config-options>

  <pl-split-view [initialSizeLeft]="60" [hideRightSide]="!arquivoDigital" (evtSizeValuesChanged)="onResizerValuesChanged()">
    <div *plSplitViewLeftSide>
      <pl-alert *ngIf="docAssinadoNaoEncerrado" theme="pl-alert-sm" type="warning" [closeable]="false">
        <i class="fa fa-exclamation-triangle me-1" aria-hidden="true"></i><span [translate]="'docscomerciais.auxiliares.docFinalizadoApenasEditarCamposNaoComunicEFatura'"></span>
      </pl-alert>

      <pl-form [properties]="{orientation: 'horizontal'}">
        <pl-group class="docfa-edit">
          <label><span [translate]="'docscomerciais.doc.cab.nDocFa'"></span>:</label>
          <edit>
            <entity-autocomplete
              attrName="docfa"
              entity="docfas"
              [model]="docFa"
              (modelChange)="onDocFaChanged($event)"
              [filter]="docFilter"
              [output]="'{{nDocFa}} - {{nome}} ({{descricao}})'"
              [outputKey]="'nDocFa'"
              [properties]="{readonly: type === stateTypes.EDIT}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group class="docfanum-edit" *ngIf="!isTipoDocStocks && optionShowNNumer">
          <label><span [translate]="'docscomerciais.doc.cab.nNumer'"></span>:</label>
          <edit>
            <entity-autocomplete
              attrName="nnumer"
              entity="docfasnums"
              [filter]="docfaNumFilter"
              [model]="model.cab.nNumer"
              (modelChange)="onNNumerChanged($event)"
              [validateFn]="fnOnNNumerValidate"
              [(selectedKey)]="model.cab.nNumer"
              [output]="'nNumer'"
              [outputKey]="'nNumer'"
              [properties]="{readonly: type === stateTypes.EDIT}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group class="docfanum-edit" *ngIf="!isTipoDocStocks && model.cab.nDocumento && model.cab.nDocumento !== 0">
          <label><span [translate]="'docscomerciais.doc.cab.nDocumento'"></span>:</label>
          <edit>
            <pl-edit
              type="integer"
              [(model)]="model.cab.nDocumento"
              (modelChange)="model.cab.nDocumento = $event; modelChanged()"
              [properties]="{disabled: true, validators: {maxlength: {value: 11}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group class="doc-num-edit" *ngIf="!isTipoDocStocks && model.cab.nDocumento && model.cab.nDocumento === 0 && model.cab.nDocumentoDraft && model.cab.nDocumentoDraft > 0">
          <label><span [translate]="'docscomerciais.doc.cab.nDocumentoDraft'"></span>:</label>
          <edit>
            <pl-edit
              type="integer"
              [(model)]="model.cab.nDocumentoDraft"
              (modelChange)="(model.cab.nDocumentoDraft); modelChanged()"
              [properties]="{disabled: true, validators: {maxlength: {value: 11}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group class="clifo-edit" *ngIf="!isTipoDocStocks">
          <label
            ><span>{{ clifoDescription }}</span
            >:</label
          >
          <edit>
            <entity-autocomplete
              attrName="clifo"
              [entity]="clifoEntName"
              [model]="model.clifo"
              (modelChange)="onClifoNifSelected($event)"
              [validateFn]="fnOnClifoNifValidate"
              [filter]="clifoFilter"
              output="nContribuint"
              [properties]="{readonly: docAssinadoNaoEncerrado || isDocumentoTerminado}"
              plAutoFocus
              [plAutoFocusDisabled]="isTipoDocStocks">
              <actions>
                <div *inputGroup="{klass: 'hollow-form'}" ngbDropdown style="padding: 0">
                  <button *ngIf="model.clifo.textofaturacao" type="button" class="btn btn-sm btn-warning" style="border-radius: 0; height: 28px; font-weight: 800" (click)="showTxtFaturacaoModal()">
                    <span [translate]="'docscomerciais.actions.txtFaturacao'"></span>
                  </button>
                  <button
                    *ngIf="clifoSaldoEmAberto !== undefined"
                    type="button"
                    class="btn btn-sm btn-outline-success"
                    [ngClass]="{'btn-outline-danger': clifoSaldoEmAberto < 0}"
                    ngbDropdownToggle
                    style="border-radius: 0; height: 28px; font-weight: 100">
                    <span><span [translate]="'docscomerciais.auxiliares.saldoEmAberto'"></span>:&nbsp;</span>
                    <span class="gestao-comercial-valor-mov-ab">{{ clifoSaldoEmAberto | cgcCurrency }}</span>
                  </button>
                  <div ngbDropdownMenu>
                    <button type="button" class="btn" ngbDropdownItem (click)="openExtratoConta()" [translate]="'docscomerciais.actions.extratoConta'"></button>
                    <button type="button" class="btn" ngbDropdownItem (click)="openMovAberto()" [translate]="'docscomerciais.actions.movimentosEmAberto'"></button>
                  </div>
                </div>
              </actions>
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group class="clifo-nome-edit" *ngIf="!isTipoDocStocks">
          <label><span [translate]="'docscomerciais.doc.clifo.nome'"></span>:</label>
          <edit>
            <pl-edit
              type="text"
              attrName="nomeClifo"
              [model]="model.clifo.nome"
              (modelChange)="model.clifo.nome = $event; modelChanged()"
              [properties]="{validators: {maxlength: {value: 90}}, readonly: isClifoUpdate() || docAssinadoNaoEncerrado || isDocumentoTerminado}">
              <div *inputGroup="{klass: 'btn-primary'}">
                <div plDelegateEventDownwardsClick (click)="mostraEditCliente = !mostraEditCliente">
                  <span>Editar</span>&nbsp;<i class="fa fa-fw fa-angle-right" [plAnimationRotate]="mostraEditCliente"></i>
                </div>
              </div>
            </pl-edit>
          </edit>
        </pl-group>

        <pl-group *ngIf="!isTipoDocStocks && !docAssinadoNaoEncerrado">
          <label>&nbsp;</label>
          <edit>
            <span class="clifo-info" *ngIf="getClifoUpdateDescrition() || model.clifo.nContribuint === cgDefaultAtNif">
              {{ getClifoUpdateDescrition() }}
              <span class="hyperlink" *ngIf="isClifoNew() && model.cab.fluxoMonetario !== 3 && model.cab.fluxoMonetario !== 5" (click)="toggleContaCorrente($event)">...mudar</span>
            </span>
          </edit>
        </pl-group>

        <pl-group *ngIf="!mostraEditCliente && !isTipoDocStocks" class="clifo-morada-info">
          <label></label>
          <edit class="clifo-info">
            <p>
              <pl-inline-edit
                type="text"
                class="clifo-rua-info"
                [model]="model.clifo.rua"
                (modelChange)="onClifoRuaChanged($event)"
                [properties]="{readonly: docAssinadoNaoEncerrado || isDocumentoTerminado, validators: {maxlength: {value: 90}}, placeholder: 'rua'}">
              </pl-inline-edit>
            </p>

            <p class="clifo-cp-local">
              <pl-inline-edit
                type="text"
                class="inline"
                [model]="model.clifo.codPostal"
                (modelChange)="onClifoCodPostalChanged($event)"
                [onValidate]="fnCodPostalValidate"
                [properties]="{readonly: docAssinadoNaoEncerrado || isDocumentoTerminado, modelOptions: {updateOn: 'blur'}, validators: {maxlength: {value: 20}}, placeholder: 'código postal'}">
              </pl-inline-edit>

              <pl-inline-edit
                type="text"
                class="inline"
                [model]="model.clifo.localidade"
                (modelChange)="onClifoLocalidadeChanged($event)"
                [properties]="{readonly: docAssinadoNaoEncerrado || isDocumentoTerminado, validators: {maxlength: {value: 50}}, placeholder: 'localidade'}">
              </pl-inline-edit>
            </p>

            <p *ngIf="nContaNifSituacaoMsg && nContaNifSituacaoMsg.length">
              Situação: <span [class.text-danger]="nContaNifSituacaoMsg !== 'ACTIVA'">{{ nContaNifSituacaoMsg }}</span>
            </p>
          </edit>
        </pl-group>

        <ng-container *ngIf="mostraEditCliente">
          <pl-group class="clifo-morada-edit">
            <label><span [translate]="'docscomerciais.doc.clifo.rua'"></span>:</label>
            <edit>
              <pl-edit
                type="text"
                [model]="model.clifo.rua"
                (modelChange)="model.clifo.rua = $event; modelChanged()"
                attrName="rua"
                [properties]="{readonly: isDocumentoTerminado, validators: {maxlength: {value: 90}}}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label><span [translate]="'docscomerciais.doc.clifo.codPostal'"></span>:</label>
            <edit>
              <entity-autocomplete
                entity="codpostais"
                [model]="model.clifo.codPostal"
                (modelChange)="onCodPostalChange($event)"
                (selectedKeyChange)="model.clifo.codPostal = $event; modelChanged()"
                outputKey="codPostal"
                [properties]="{readonly: isDocumentoTerminado, validators: {maxlength: {value: 20}}}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label><span [translate]="'docscomerciais.doc.clifo.localidade'"></span>:</label>
            <edit>
              <pl-edit
                type="text"
                [model]="model.clifo.localidade"
                (modelChange)="model.clifo.localidade = $event; modelChanged()"
                [properties]="{readonly: isDocumentoTerminado, validators: {maxlength: {value: 50}}}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label><span [translate]="'docscomerciais.doc.clifo.telefone'"></span>:</label>
            <edit>
              <pl-edit
                type="text"
                [model]="model.clifo.telefone"
                (modelChange)="model.clifo.telefone = $event; modelChanged()"
                [properties]="{readonly: isDocumentoTerminado, validators: {maxlength: {value: 20}}}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label><span [translate]="'docscomerciais.doc.clifo.email'"></span>:</label>
            <edit>
              <pl-edit
                type="text"
                [model]="model.clifo.email"
                (modelChange)="model.clifo.email = $event; modelChanged()"
                [properties]="{readonly: isDocumentoTerminado, validators: {maxlength: {value: 100}}}"></pl-edit>
            </edit>
          </pl-group>
        </ng-container>

        <pl-group class="cab-options-edit" *ngIf="!isFornecedores || temAutoFaturacao || optionShowDataDoc">
          <label><span [translate]="'docscomerciais.doc.cab.dataDoc'"></span>:</label>
          <edit>
            <pl-edit-datepicker
              attrName="cabdata"
              [model]="model.cab.dataDoc"
              (modelChange)="onDataDocChange($event)"
              [properties]="{readonly: docAssinadoNaoEncerrado || isDocumentoTerminado, events: {keydown: fnKeyDownDataDoc}}"
              plAutoFocus
              [plAutoFocusDisabled]="!isTipoDocStocks">
              <ng-container *ngIf="isComprasEfectivas">
                <div *inputGroup="{klass: 'hollow-form'}">
                  <pl-tooltip [config]="{text: 'docscomerciais.auxiliares.tooltips.comprasDataDoc', placement: 'bottom', container: 'body'}">
                    <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
                  </pl-tooltip>
                </div>
              </ng-container>
            </pl-edit-datepicker>
            <div class="error-form" *ngIf="dataDocAlertErrorsMsg && dataDocAlertErrorsMsg.length">{{ dataDocAlertErrorsMsg }}</div>
          </edit>
        </pl-group>

        <pl-group *ngIf="!isTipoDocStocks && (optionShowDocExterno || isComprasFornecedor)">
          <pl-group class="cab-options-edit">
            <label><span [translate]="'docscomerciais.doc.cab.dataDocExterno'"></span>:</label>
            <edit>
              <pl-edit-datepicker
                attrName="dataDocExterno"
                [model]="model.cab.dataDocExterno"
                (modelChange)="onDataDocExternoChange($event)"
                [properties]="{readonly: isDocumentoTerminado, events: {keydown: fnKeyDownDataDocExterno}, validators: {required: {value: isComprasEfectivas}}}">
                <ng-container *ngIf="isComprasEfectivas">
                  <div *inputGroup="{klass: 'hollow-form'}">
                    <pl-tooltip [config]="{text: 'docscomerciais.auxiliares.tooltips.comprasDataDocExterno', placement: 'bottom', container: 'body'}">
                      <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
                    </pl-tooltip>
                  </div>
                </ng-container>
              </pl-edit-datepicker>
            </edit>
          </pl-group>
          <pl-group class="cab-options-edit">
            <label><span [translate]="'docscomerciais.doc.cab.nFactFornec'"></span>:</label>
            <edit>
              <pl-edit
                type="text"
                attrName="nFactFornec"
                [model]="model.cab.nFactFornec"
                (modelChange)="model.cab.nFactFornec = $event; modelChanged()"
                [properties]="{readonly: isDocumentoTerminado, placeholder: 'Ex: FT 1 1/100', events: {keydown: fnKeyDownNFactFornec}, validators: {maxlength: {value: 25}}}">
                <ng-container *ngIf="isComprasEfectivas">
                  <div *inputGroup="{klass: 'hollow-form'}">
                    <pl-tooltip [config]="{text: 'docscomerciais.auxiliares.tooltips.comprasNDocExterno', placement: 'bottom', container: 'body'}">
                      <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
                    </pl-tooltip>
                  </div>
                </ng-container>
              </pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="!isTipoDocStocks && optionShowDataVenc">
          <pl-group class="cab-options-edit">
            <label><span [translate]="'docscomerciais.doc.cab.nCondPagam'"></span>:</label>
            <edit>
              <entity-autocomplete
                attrName="nCondPag"
                entity="condicoespagamento"
                [model]="model.cab"
                (selectedKeyChange)="model.cab.nCondPag = $event; onCondicaoPagamentoChanged($event)"
                (evtSelectedDescriptionChanged)="model.cab.nomeCondPag = $event"
                [fieldsMap]="{nCondPag: 'nCondPag', descricao: 'nomeCondPag'}"
                [properties]="{readonly: isDocumentoTerminado, events: {keydown: fnKeyDownCondPagamento}}"
                outputKey="nCondPag"
                [output]="condPagamentOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group class="cab-options-edit">
            <label><span [translate]="'docscomerciais.doc.cab.dataVenc'"></span>:</label>
            <edit>
              <pl-edit-datepicker
                attrName="dataVenc"
                [model]="model.cab.dataVenc"
                (modelChange)="model.cab.dataVenc = $event; modelChanged()"
                [properties]="{readonly: isDocumentoTerminado, events: {keydown: fnKeyDownDataVenc}}"></pl-edit-datepicker>
            </edit>
            <actions *ngIf="optionShowDiferimentos && showBtnDiferimentos">
              <pl-button attrName="btnDiferimentos" klass="btn-secondary" (evtClicked)="openDiferimentos()">
                <i class="fa fa-calculator fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.btn.diferimentos'"></span>
              </pl-button>
            </actions>
          </pl-group>
        </pl-group>

        <pl-group class="cab-options-edit" *ngIf="isEntregaEfetivaSobreDocGlobal">
          <label><span [translate]="'docscomerciais.doc.cab.nDocGlobalOrigem'"></span>:</label>

          <edit>
            <pl-edit-text attrName="nDocGlobalOrigem" [model]="model.cab.nDocGlobalOrigemNDoc" [properties]="{disallowClear: true}">
              <ng-container *ngIf="model.cab.nDocGlobalOrigemFaccbId > 0">
                <div *inputGroup="{klass: 'btn-danger'}" (click)="clearDocumentoGlobal()" data-capture-click>
                  <pl-tooltip [config]="{text: 'docscomerciais.auxiliares.tooltips.limparCampo', container: 'body'}">
                    <i *plTooltipContent class="fa fa-fw fa-ban"></i>
                  </pl-tooltip>
                </div>
              </ng-container>

              <div *inputGroup="{klass: 'btn-light btn-obter-ultimo-doc-global'}" (click)="obterUltimoDocumentoGlobalCriado()" data-capture-click>
                <pl-tooltip [config]="{text: 'docscomerciais.auxiliares.tooltips.obterUltimoDocumentoGlobalCriado', container: 'body'}">
                  <i *plTooltipContent class="fa fa-fw fa-history"></i>
                </pl-tooltip>
              </div>

              <div *inputGroup="{klass: 'btn-primary btn-pesquisa-docs-globais'}" (click)="showPesqDocsGlobaisModal()" data-capture-click>
                <pl-tooltip [config]="{text: 'docscomerciais.auxiliares.tooltips.pesquisaDocsGlobais', container: 'body'}">
                  <i *plTooltipContent class="fa fa-fw fa-search"></i>
                </pl-tooltip>
              </div>
            </pl-edit-text>
          </edit>
        </pl-group>

        <pl-group class="moeda-edit" *ngIf="!isTipoDocStocks && (optionShowMoeda || isShowMoedaCambio)">
          <pl-group>
            <label><span [translate]="'docscomerciais.doc.cab.codMoeda'"></span>:</label>
            <edit>
              <entity-autocomplete
                attrName="moeda"
                entity="moeda"
                [model]="moeda"
                (modelChange)="onCodMoedaChanged($event)"
                [output]="'{{codMoeda}} - {{nomeMoeda}}'"
                [outputKey]="'codMoeda'"
                [outputDescription]="'nomeMoeda'"
                [properties]="{readonly: docAssinadoNaoEncerrado || isDocumentoTerminado, events: {keydown: fnKeyDownMoeda}}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group *ngIf="isShowMoedaCambio">
            <label><span [translate]="'docscomerciais.doc.cab.cambio'"></span>:</label>
            <edit>
              <pl-edit
                type="number"
                attrName="cambio"
                [model]="model.cab.cambio"
                (modelChange)="onCambioChanged($event)"
                [inputClass]="'text-end'"
                [properties]="{readonly: docAssinadoNaoEncerrado || isDocumentoTerminado, events: {keydown: fnKeyDownCambio}, modelOptions: {updateOn: 'blur'}, decimalsLimit: 5}">
                <div *inputGroup="{klass: 'btn-light'}" (click)="atualizarCambio()" data-capture-click>
                  <pl-tooltip [config]="{text: 'docscomerciais.auxiliares.tooltips.atualizarCambio', container: 'body'}">
                    <i *plTooltipContent class="fa fa-fw fa-refresh"></i>
                  </pl-tooltip>
                </div>
              </pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group class="cab-options-edit" *ngIf="!isTipoDocStocks && optionShowCCusto">
          <label><span [translate]="'docscomerciais.doc.cab.nCCusto'"></span>:</label>
          <edit>
            <entity-autocomplete
              attrName="nCCusto"
              entity="ccustos"
              [model]="model.cab.nCCusto"
              [selectedKey]="model.cab.nCCusto"
              (selectedKeyChange)="model.cab.nCCusto = $event; modelChanged()"
              output="nCCusto"
              [properties]="{readonly: isDocumentoTerminado, events: {keydown: fnKeyDownNCCusto}}"
              [filter]="'encerrado=0'">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group class="cab-options-edit" *ngIf="!isTipoDocStocks && optionShowNRefProcesso">
          <label><span [translate]="'docscomerciais.doc.cab.nRefProcesso'"></span>:</label>
          <edit>
            <entity-autocomplete
              attrName="nRefProcesso"
              entity="processos"
              [model]="model.cab.nrefprocesso"
              [selectedKey]="model.cab.nrefprocesso"
              (selectedKeyChange)="model.cab.nrefprocesso = $event; modelChanged()"
              output="nRefProcesso"
              [properties]="{readonly: isDocumentoTerminado, events: {keydown: fnKeyDownNRefProcesso}}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group class="cab-options-edit" *ngIf="!isTipoDocStocks && optionShowNRequisicao">
          <label><span [translate]="'docscomerciais.doc.cab.nRequisicao'"></span>:</label>
          <edit>
            <pl-edit
              type="text"
              attrName="nRequisicao"
              [model]="model.cab.nRequisicao"
              (modelChange)="model.cab.nRequisicao = $event; modelChanged()"
              [properties]="{readonly: isDocumentoTerminado, events: {keydown: fnKeyDownNRequisicao}, validators: {maxlength: {value: 15}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group class="cab-options-edit" *ngIf="!isTipoDocStocks && optionShowObservacoes">
          <label><span [translate]="'docscomerciais.doc.cab.observacoes'"></span>:</label>
          <edit>
            <pl-edit
              type="text"
              attrName="observacoes"
              [model]="model.cab.observacoes"
              (modelChange)="model.cab.observacoes = $event; modelChanged()"
              [properties]="{readonly: isDocumentoTerminado, events: {keydown: fnKeyDownObservacoes}, validators: {maxlength: {value: 100}}}"></pl-edit>
          </edit>
        </pl-group>
      </pl-form>

      <div class="gestaocomercial-widget-container">
        <div class="gestaocomercial-widget" style="width: 100%">
          <pl-tabs [theme]="'style3'" [(activeId)]="activeMainTab" (evtSelected)="changedTab($event.nextId)">
            <pl-tab [id]="mainTabLinhasId">
              <div *plTabTitle><i class="fa fa-list fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.linhas'"></span></div>
              <div *plTabContent>
                <div class="gestaocomercial-widget-body">
                  <div class="gestaocomercial-linhas-wrapper">
                    <div class="gestaocomercial-linhas" [ngClass]="{'gestaocomercial-linhas-fixed': !docAssinadoNaoEncerrado && !isDocumentoTerminado}">
                      <div class="table-responsive">
                        <table class="table-artigos cg-dx-table-theme" [plTableNavigation] [onCheckLine]="fnCheckLine" [onLastCell]="fnAddLine">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th [translate]="'docscomerciais.doc.linhas.nArtigo'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.nome'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.qtd'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.nArmazem'" *ngIf="isTipoDocStocks || optionShowNArmazem"></th>
                              <th [translate]="'docscomerciais.doc.linhas.preco'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.codIva'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.taxaIva'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.valorIva'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.valorComIva'" *ngIf="optionShowValorCIva && !isTipoDocStocks"></th>
                              <th [translate]="'docscomerciais.doc.linhas.liquido'" *ngIf="optionShowLiquido && !isTipoDocStocks"></th>
                              <th [translate]="'docscomerciais.doc.linhas.codUnidMov'" *ngIf="isTipoDocStocks || optionShowCodUnidMov"></th>
                              <th [translate]="'docscomerciais.doc.linhas.nVendedor'" *ngIf="isTipoDocStocks || optionShowNVendedor"></th>
                              <th [translate]="'docscomerciais.doc.linhas.nCCusto'" *ngIf="isTipoDocStocks || optionShowCCusto"></th>
                              <th [translate]="'docscomerciais.doc.linhas.nRefProcesso'" *ngIf="isTipoDocStocks || optionShowNRefProcesso"></th>
                              <th [translate]="'docscomerciais.doc.linhas.desconto'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.d2'" *ngIf="optionShowD2 && !isTipoDocStocks"></th>
                              <th [translate]="'docscomerciais.doc.linhas.d3'" *ngIf="optionShowD3 && !isTipoDocStocks"></th>
                              <th [translate]="'docscomerciais.doc.linhas.dataEntregaPrev'" *ngIf="showDataEntregaPrev && !isTipoDocStocks"></th>
                              <th [translate]="'docscomerciais.doc.linhas.descEmValor'" *ngIf="optionShowDescEmValor && !isTipoDocStocks"></th>
                              <th class="actions-buttons-header" *ngIf="!docAssinadoNaoEncerrado">
                                <button type="button" class="btn btn-primary btn-xs" (click)="addLine(true)">
                                  <i class="fa fa-fw fa-plus"></i>
                                </button>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              *ngFor="let item of paginatedLinhas; trackBy: trackByFn; index as $index; first as isFirst"
                              [draggable]="type !== stateTypes.DETAIL && !!item.nSeq"
                              (dragstart)="dragStart($event, item, $index)"
                              (dragover)="dragOver($event, item, $index)"
                              (dragend)="dragEnd()"
                              class="doccomercial-linha"
                              [ngClass]="{'doccomercial-linha-texto-show': item.showTextoLivre, 'doccomercial-linha-devolucao-show': item.docRegularizMotivo}"
                              [attr.data-row-id]="paginationLinhas.offset + $index"
                              [attr.data-uid]="item.uid">
                              <td style="min-width: 50px; max-width: 50px; vertical-align: middle">
                                <i *ngIf="!!item.nSeq" class="fa fa-fw fa-bars cursor-grab" aria-hidden="true"></i>&nbsp;{{ item.nSeq }}
                              </td>

                              <td class="doccomercial-linha-artigo-td" style="min-width: 210px; width: 15%">
                                <entity-autocomplete
                                  entity="artigos"
                                  attrName="artigo"
                                  [filter]="'artDesactivado=false&artBloqueado=false'"
                                  [output]="'nArtigo'"
                                  [outputKey]="'nArtigo'"
                                  outputDescription="nome"
                                  [model]="item.nArtigo"
                                  [selectedKey]="item.nArtigo"
                                  (selectedKeyChange)="item.nArtigo = $event; modelChanged()"
                                  [maintenanceProperties]="{detailModalOptions: {params: {dataDoc: (model.cab.dataDoc | cgJsonSerialize)}}}"
                                  [properties]="getFieldProperties('nArtigo', item)"
                                  (evtSelectedDescriptionChanged)="item.nomeArtigo = $event"
                                  (modelChange)="artigoChanged($event, item)"
                                  plAutoFocus
                                  [plAutoFocusDisabled]="initting || isFirst">
                                  <div *inputGroup="{klass: 'hollow-form'}">
                                    <pl-tooltip [config]="{text: 'global.btn.advancedSearch', placement: 'right', container: 'body'}">
                                      <i *plTooltipContent class="fa fa-search-plus fa-fw" (click)="openArtigosAdvancedSearch()" aria-hidden="true"></i>
                                    </pl-tooltip>
                                  </div>
                                </entity-autocomplete>
                                <div class="doccomercial-linha-artigo-nao-existe-msg text-danger">
                                  <i class="fa fa-fw fa-exclamation-triangle" aria-hidden="true"></i>&nbsp;<span [translate]="'gestaoDocsComerciais.messages.artigoNaoExiste'"></span>
                                </div>

                                <div class="doccomercial-linha-devolucao-motivo text-info" *ngIf="item.docRegularizMotivo">
                                  <i class="fa fa-fw fa-info-circle" aria-hidden="true"></i>&nbsp;<span>Motivo: {{ item.docRegularizMotivo }}</span>
                                </div>
                              </td>
                              <!--NARTIGO-->

                              <td style="min-width: 300px; width: 30%">
                                <pl-edit
                                  type="text"
                                  attrName="nomeArtigo"
                                  [model]="item.nomeArtigo"
                                  (modelChange)="item.nomeArtigo = $event; calcStock(item)"
                                  [properties]="getFieldProperties('nomeArtigo', item)">
                                  <div *inputGroup="{klass: item.textoLivre?.length ? 'btn-primary' : 'btn-light', disabled: false}">
                                    <pl-tooltip [config]="{text: 'docscomerciais.auxiliares.tooltips.addTextoLivre', placement: 'right', container: 'body'}">
                                      <div *plTooltipContent (click)="toggleTextoLivre(item, paginationLinhas.offset + $index, $event)" data-capture-click>
                                        <i class="fa fa-fw fa-angle-right" [plAnimationRotate]="item.showTextoLivre"></i>
                                      </div>
                                    </pl-tooltip>
                                  </div>
                                </pl-edit>

                                <!-- TEXTO LIVRE -->
                                <pl-animation-collapse [collapsed]="!item.showTextoLivre" [animationDuration]="0">
                                  <div *ngIf="item.textosLivresClifo?.length || item.textosLivresArtigo?.length" class="d-flex mt-1 mb-1" style="gap: 5px">
                                    <div *ngIf="item.textosLivresClifo?.length" ngbDropdown [container]="'body'">
                                      <button type="button" class="btn btn-xs btn-light" ngbDropdownToggle>
                                        <span style="font-size: 11px" [translate]="getTextoLivreNContaBtnCaption()"></span>
                                      </button>
                                      <div class="dropdown-menu" ngbDropdownMenu>
                                        <button style="font-size: 12px" type="button" *ngFor="let txtcl of item.textosLivresClifo" ngbDropdownItem (click)="item.textoLivre = txtcl.note">
                                          <div style="font-weight: bold; color: #2196f3">{{ txtcl.titulo }}</div>
                                          <div style="font-style: italic; font-size: 11px; color: #8b8b8b">{{ txtcl.note.length > 100 ? txtcl.note.substring(0, 100) + '...' : txtcl.note }}</div>
                                        </button>
                                      </div>
                                    </div>
                                    <div *ngIf="item.textosLivresArtigo?.length" ngbDropdown [container]="'body'">
                                      <button type="button" class="btn btn-xs btn-light" ngbDropdownToggle>
                                        <span style="font-size: 11px" [translate]="'docscomerciais.doc.linhas.nArtigo'"></span>
                                      </button>
                                      <div class="dropdown-menu" ngbDropdownMenu>
                                        <button style="font-size: 12px" type="button" *ngFor="let txtar of item.textosLivresArtigo" ngbDropdownItem (click)="item.textoLivre = txtar.note">
                                          <div style="font-weight: bold; color: #2196f3">{{ txtar.titulo }}</div>
                                          <div style="font-style: italic; font-size: 11px; color: #8b8b8b">{{ txtar.note.length > 100 ? txtar.note.substring(0, 100) + '...' : txtar.note }}</div>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <textarea
                                    class="mt-1 form-control"
                                    maxlength="2048"
                                    rows="2"
                                    [attr.name]="'descricao' + (paginationLinhas.offset + $index)"
                                    [(ngModel)]="item.textoLivre"
                                    (keydown)="keydownTextoLivre($event)">
                                  </textarea>
                                </pl-animation-collapse>
                              </td>

                              <!--NOME ARTIGO-->
                              <td style="min-width: 180px; width: 11%">
                                <pl-edit
                                  type="number"
                                  attrName="quantidade"
                                  [model]="item.qtd1"
                                  (modelChange)="onQdt1Changed($event, item)"
                                  [inputClass]="'text-end'"
                                  [properties]="getFieldProperties('qtd1', item)">
                                  <div *inputGroup="{klass: comercialAdvanced && artigoLinhaTemLote(item) === true && !docAssinadoNaoEncerrado ? 'btn-light' : 'btn-light disabled'}">
                                    <pl-tooltip [config]="{text: 'selecaoLotes.tooltips.lote', placement: 'right', disabled: item.lotes.length === 0, container: 'body'}">
                                      <i class="fa fa-list-ul" plDelegateEventDownwardsClick *plTooltipContent (click)="callSelecaoLotes(item)" plPromise></i>
                                    </pl-tooltip>
                                  </div>
                                </pl-edit>
                              </td>
                              <!--QTD-->

                              <td *ngIf="isTipoDocStocks || optionShowNArmazem" style="min-width: 150px">
                                <entity-autocomplete
                                  entity="armazens"
                                  attrName="armazem"
                                  [output]="'nArmazem'"
                                  [outputKey]="'nArmazem'"
                                  [selectedKey]="item.nArmazem"
                                  (selectedKeyChange)="item.nArmazem = $event; modelChanged()"
                                  [properties]="getFieldProperties('nArmazem', item)"
                                  (modelChange)="armazemChanged($event, item)">
                                </entity-autocomplete>
                              </td>
                              <!--NARMAZEM-->

                              <td style="min-width: 200px; width: 12%">
                                <pl-edit
                                  type="number"
                                  attrName="precoVenda"
                                  [model]="item.prVenda1"
                                  (modelChange)="onPrVenda1Changed($event, item)"
                                  [inputClass]="'text-end'"
                                  [properties]="getFieldProperties('prVenda1', item)">
                                  <div *inputGroup="{klass: 'hollow-form'}">
                                    <pl-tooltip *ngIf="item.nArtigo" [config]="{text: 'docscomerciais.modals.precoUnitario.modalTitle', placement: 'bottom', container: 'body'}">
                                      <i *plTooltipContent class="fa fa-calculator fa-fw" (click)="openPrecoUnitarioModal(item)" aria-hidden="true"></i>
                                    </pl-tooltip>
                                  </div>
                                </pl-edit>
                              </td>
                              <!--PRECO-->

                              <td style="min-width: 150px; width: 6%">
                                <entity-autocomplete
                                  entity="ivas"
                                  [selectedKey]="item.codIva"
                                  (selectedKeyChange)="item.codIva = $event; modelChanged()"
                                  outputKey="codIva"
                                  output="codIva"
                                  [properties]="getFieldProperties('codIva', item)"
                                  (modelChange)="codIvaChanged($event, item)">
                                </entity-autocomplete>
                              </td>
                              <!--CODIVA-->

                              <td style="min-width: 80px; width: 6%">
                                <pl-edit type="text" [inputClass]="'text-end'" [model]="item.taxaIva" [properties]="{disabled: true}"></pl-edit>
                              </td>
                              <!--TAXAIVA-->

                              <td style="min-width: 150px; width: 10%">
                                <pl-edit type="number" [model]="item.valorIvaME" [inputClass]="'text-end'" [properties]="propertiesEditDecimalsValoresUnitariosLimitDisabled"></pl-edit>
                              </td>
                              <!--VALORIVA-->

                              <td *ngIf="optionShowValorCIva && !isTipoDocStocks" style="min-width: 150px">
                                <pl-edit type="number" [model]="item.prVendaIvaIncME" [inputClass]="'text-end'" [properties]="propertiesEditDecimalsValoresUnitariosLimitDisabled"></pl-edit>
                              </td>
                              <!--VALORCOMIVA-->

                              <td *ngIf="optionShowLiquido && !isTipoDocStocks" style="min-width: 150px">
                                <pl-edit type="number" [model]="item.liquido" [inputClass]="'text-end'" [properties]="propertiesEditDecimalsValoresUnitariosLimitDisabled"></pl-edit>
                              </td>
                              <!--LIQUIDO-->

                              <td *ngIf="isTipoDocStocks || optionShowCodUnidMov" style="min-width: 150px">
                                <entity-autocomplete
                                  entity="unidadesmovimento"
                                  attrName="codUnidMov"
                                  [selectedKey]="item.codUnidMov"
                                  (selectedKeyChange)="item.codUnidMov = $event; modelChanged()"
                                  [output]="'codUni'"
                                  [outputKey]="'codUni'"
                                  [properties]="getFieldProperties('codUnidMov', item)"
                                  [getDataConfig]="{params: {nArtigo: item.nArtigo}}">
                                </entity-autocomplete>
                              </td>
                              <!--CODUNIDMOV-->

                              <td *ngIf="isTipoDocStocks || optionShowNVendedor" style="min-width: 150px">
                                <entity-autocomplete
                                  entity="vendedores"
                                  attrName="vendedor"
                                  [selectedKey]="item.nVendedor"
                                  (selectedKeyChange)="item.nVendedor = $event; modelChanged()"
                                  [output]="'nVendedor'"
                                  [outputKey]="'nVendedor'"
                                  inputClass="input-sm"
                                  [properties]="getFieldProperties('nVendedor', item)">
                                </entity-autocomplete>
                              </td>
                              <!--NVENDEDOR-->

                              <td *ngIf="isTipoDocStocks || optionShowCCusto" style="min-width: 200px">
                                <entity-autocomplete
                                  entity="ccustos"
                                  [selectedKey]="item.nCCusto"
                                  (selectedKeyChange)="item.nCCusto = $event; modelChanged()"
                                  output="key"
                                  [properties]="getFieldProperties('nCCusto', item)"
                                  inputClass="input-sm"
                                  [filter]="'encerrado=0'">
                                </entity-autocomplete>
                              </td>
                              <!--NCCUSTO-->

                              <td *ngIf="isTipoDocStocks || optionShowNRefProcesso" style="min-width: 200px">
                                <entity-autocomplete
                                  entity="processos"
                                  [model]="item.nrefprocesso"
                                  [(selectedKey)]="item.nrefprocesso"
                                  (evtSelectedDescriptionChanged)="item.nrefprocesso = $event; modelChanged()"
                                  [outputKey]="'nrefprocesso'"
                                  [output]="'nrefprocesso'"
                                  [outputDescription]="'nRefProcesso'"
                                  [fieldsMap]="{nRefProcesso: 'nrefprocesso'}"
                                  [properties]="getFieldProperties('nrefprocesso', item)"
                                  inputClass="input-sm">
                                </entity-autocomplete>
                              </td>
                              <!--NREFPROCESSO-->

                              <td style="min-width: 200px; width: 4%">
                                <pl-edit
                                  type="number"
                                  attrName="d1"
                                  [model]="item.d1"
                                  (modelChange)="onD1Changed($event, item)"
                                  [inputClass]="'text-end'"
                                  [properties]="getFieldProperties('d1', item)">
                                </pl-edit>
                              </td>
                              <td style="min-width: 200px; width: 4%" *ngIf="optionShowD2 && !isTipoDocStocks">
                                <pl-edit
                                  type="number"
                                  attrName="d2"
                                  [model]="item.d2"
                                  (modelChange)="onD2Changed($event, item)"
                                  [inputClass]="'text-end'"
                                  [properties]="getFieldProperties('d2', item)">
                                </pl-edit>
                              </td>
                              <td style="min-width: 200px; width: 4%" *ngIf="optionShowD3 && !isTipoDocStocks">
                                <pl-edit
                                  type="number"
                                  attrName="d3"
                                  [model]="item.d3"
                                  (modelChange)="onD3Changed($event, item)"
                                  [inputClass]="'text-end'"
                                  [properties]="getFieldProperties('d3', item)">
                                </pl-edit>
                              </td>

                              <td style="min-width: 140px; width: 4%" *ngIf="showDataEntregaPrev && !isTipoDocStocks">
                                <pl-edit-datepicker attrName="dataEntregaPrev" [model]="item.dataEntregaPrev" (modelChange)="item.dataEntregaPrev = $event; modelChanged()"></pl-edit-datepicker>
                              </td>
                              <td style="min-width: 200px; width: 4%" *ngIf="optionShowDescEmValor && !isTipoDocStocks">
                                <pl-edit
                                  type="number"
                                  attrName="desconto"
                                  [model]="item.desconto"
                                  (modelChange)="onDescontoChanged($event, item)"
                                  [inputClass]="'text-end'"
                                  [properties]="getFieldProperties('desconto', item)">
                                </pl-edit>
                              </td>
                              <!--DESCONTO-->

                              <td *ngIf="!docAssinadoNaoEncerrado && !isDocumentoTerminado" class="cg-dx-table-actions actions-buttons" style="min-width: 10px; width: 4%">
                                <button type="button" class="btn btn-danger btn-xs" (click)="remove(paginationLinhas.offset + $index)">
                                  <i class="fa fa-fw fa-trash-o"></i>
                                </button>
                                <button *ngIf="item.docRegularizMotivo" type="button" class="btn btn-secondary btn-xs mt-1" (click)="editarDevolucao(item.facliId)">
                                  <i class="fa fa-fw fa-pencil"></i>
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <pl-pagination
                      *ngIf="model.linhas.length > paginationMinimumPerPage"
                      [page]="paginationLinhas.page"
                      [perPage]="paginationLinhas.perPage"
                      [total]="model.linhas.length"
                      [lines]="paginationLinhas.allowedPageSizes"
                      (pageChange)="paginationLinhas.page = $event; paginateLinhas()"
                      (perPageChange)="paginationLinhas.perPage = $event; paginateLinhas()">
                    </pl-pagination>
                  </div>
                </div>
                <div class="gestaocomercial-widget-container" style="margin-left: 0; margin-right: 0">
                  <div class="d-flex" style="justify-content: space-between; align-items: stretch">
                    <div>
                      <div class="gestaocomercial-widget" style="height: 100%" *ngIf="stocks.item?.nArtigo && showInfoStockArtigo">
                        <h4 class="page-header-cg">Stock</h4>
                        <div class="gestaocomercial-widget-body resumo">
                          <dl>
                            <dt>Artigo:</dt>
                            <dd [attr.title]="(stocks.item.nArtigo + ' ' + stocks.item.nomeArtigo).trim()">
                              <span>{{ stocks.item.nArtigo }} {{ stocks.item.nomeArtigo }}</span>
                            </dd>
                            <dt>Armaz.:</dt>
                            <dd>{{ stocks.nArmazem }}</dd>
                          </dl>

                          <hr />

                          <dl>
                            <dt>Qtd.:</dt>
                            <dd>{{ stocks.qtd | cgcNumber }}</dd>
                            <dt>Qtd Total:</dt>
                            <dd>{{ stocks.qtdTotal | cgcNumber }}</dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="gestaocomercial-widget" style="height: 100%; margin-right: 10px" *ngIf="model.cab.codRet">
                        <h4 class="page-header-cg text-right">Retenção</h4>
                        <div class="gestaocomercial-widget-body resumo">
                          <dl>
                            <dt><span [translate]="'docscomerciais.doc.cab.taxaRet'"></span>:</dt>
                            <dd>{{ model.cab.taxaRet }}%</dd>
                            <dt><span [translate]="'docscomerciais.doc.cab.retencaoIncidencia'"></span>:</dt>
                            <dd>{{ model.cab.retencaoIncidencia | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>
                            <dt><span [translate]="'docscomerciais.doc.cab.retencaoValor'"></span>:</dt>
                            <dd>{{ model.cab.retencaoValor | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>
                          </dl>
                        </div>
                      </div>
                      <div class="gestaocomercial-widget" style="height: 100%">
                        <h4 class="page-header-cg text-right" [translate]="'documentoFaturacao.summary'"></h4>
                        <div class="gestaocomercial-widget-body resumo">
                          <dl>
                            <dt><span [translate]="'docscomerciais.doc.cab.totalIliquido'"></span>:</dt>
                            <dd>{{ model.cab.totalIliquido | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>

                            <dt><span [translate]="'docscomerciais.doc.cab.totalDesconto'"></span>:</dt>
                            <dd>{{ model.cab.totalDesconto | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>

                            <dt><span [translate]="'docscomerciais.doc.cab.totalLiquido'"></span>:</dt>
                            <dd>{{ model.cab.totalLiquido | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>

                            <dt><span [translate]="'docscomerciais.doc.cab.totalIva'"></span>:</dt>
                            <dd>{{ model.cab.totalIva | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>
                          </dl>

                          <hr />

                          <dl class="total-doc">
                            <dt><span [translate]="'global.text.total'"></span>:</dt>
                            <dd>{{ model.cab.totalDocumento | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>
                            <dt *ngIf="codMoedaEmpresa !== model.cab.codMoeda"></dt>
                            <dd *ngIf="codMoedaEmpresa !== model.cab.codMoeda">{{ model.cab.totalDocumentoME | cgcCurrency: 2 : abreviaturaMoedaCliente : 'code' }}</dd>
                          </dl>
                          <ng-container *ngIf="model.cab.codRet">
                            <hr />
                            <dl class="retencao-doc">
                              <dt>Retenção:</dt>
                              <dd>{{ model.cab.retencaoValor | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>
                              <dt>Total Líquido:</dt>
                              <dd>{{ model.cab.totalDocumento - model.cab.retencaoValor | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>
                            </dl>
                          </ng-container>
                          <span style="font-size: 0.7rem" *ngIf="arredManualString !== ''" [translate]="arredManualString"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </pl-tab>
            <pl-tab [id]="mainTabCargaId" [disabled]="!mainTabCargaEnabled">
              <div *plTabTitle><i class="fa fa-truck fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.transporte'"></span></div>
              <div *plTabContent>
                <div class="gestaocomercial-widget-body">
                  <pl-form (evtFieldValueChanged)="storeModel()">
                    <div class="row">
                      <div class="col-sm-12 col-lg-6">
                        <div class="card mb-3">
                          <div class="card-header"><i class="fa fa-address-card fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.tabs.carga'"></span></div>
                          <div class="card-body">
                            <pl-group>
                              <pl-group>
                                <label [translate]="'docscomerciais.doc.cab.dataInicTran'"></label>
                                <edit>
                                  <pl-edit-datepicker
                                    attrName="dataInicTran"
                                    [model]="model.cab.dataInicTran"
                                    [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}"
                                    (modelChange)="model.cab.dataInicTran = $event; modelChanged()"></pl-edit-datepicker>
                                </edit>
                              </pl-group>

                              <pl-group>
                                <label [translate]="'docscomerciais.doc.cab.horaInicTran'"></label>
                                <edit>
                                  <pl-edit-timepicker
                                    [model]="model.cab.horaInicTran"
                                    [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}"
                                    (modelChange)="model.cab.horaInicTran = $event; modelChanged()"></pl-edit-timepicker>
                                </edit>
                              </pl-group>
                            </pl-group>

                            <pl-group>
                              <label [translate]="'docscomerciais.doc.moradaDescarga.moralId'"></label>
                              <edit *ngIf="moradasInvertidas">
                                <pl-edit-select attrName="cargaEmpresa" [source]="moradasEmpresaSource" (modelChange)="moradaEmpresaChanged($event)" [properties]="propertiesSelectCargaEmpresa">
                                </pl-edit-select>
                              </edit>
                              <edit *ngIf="!moradasInvertidas">
                                <entity-autocomplete
                                  attrName="contaMoradaCarga"
                                  entity="morals"
                                  [model]="model.moradaDescarga"
                                  (modelChange)="onMoralChanged($event)"
                                  output="nome"
                                  [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}"
                                  [filter]="nContaMoralFilter"
                                  [validateFn]="fnMoralValidate">
                                </entity-autocomplete>
                              </edit>
                            </pl-group>

                            <pl-group>
                              <label [translate]="'docscomerciais.doc.moradaCarga.rua'"></label>
                              <pl-group>
                                <edit>
                                  <pl-edit
                                    type="text"
                                    [model]="model.moradaCarga.rua"
                                    [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}"
                                    (modelChange)="model.moradaCarga.rua = $event; modelChanged()"></pl-edit>
                                  <pl-edit
                                    type="text"
                                    [model]="model.moradaCarga.morada"
                                    [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}"
                                    (modelChange)="model.moradaCarga.morada = $event; modelChanged()"></pl-edit>
                                </edit>
                              </pl-group>
                            </pl-group>

                            <pl-group>
                              <pl-group>
                                <label [translate]="'docscomerciais.doc.moradaCarga.codPostal'"></label>
                                <edit>
                                  <entity-autocomplete
                                    entity="codpostais"
                                    attrName="codPostalCarga"
                                    [selectedKey]="model.moradaCarga.codPostal"
                                    (selectedKeyChange)="model.moradaCarga.codPostal = $event; modelChanged()"
                                    (evtSelectedDescriptionChanged)="model.moradaCarga.localidade = $event"
                                    [outputKey]="'codPostal'"
                                    [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}"
                                    [outputDescription]="'localidade'">
                                  </entity-autocomplete>
                                </edit>
                              </pl-group>

                              <pl-group>
                                <label [translate]="'docscomerciais.doc.moradaCarga.localidade'"></label>
                                <edit>
                                  <pl-edit
                                    type="text"
                                    [model]="model.moradaCarga.localidade"
                                    [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}"
                                    (modelChange)="model.moradaCarga.localidade = $event; modelChanged()"></pl-edit>
                                </edit>
                              </pl-group>
                            </pl-group>

                            <pl-group>
                              <pl-group>
                                <label [translate]="'docscomerciais.doc.moradaCarga.codPais'"></label>
                                <edit>
                                  <entity-autocomplete
                                    entity="paises"
                                    [model]="model.moradaCarga"
                                    (selectedKeyChange)="model.moradaCarga.codPais = $event; modelChanged()"
                                    (evtSelectedDescriptionChanged)="model.moradaCarga.nomePais = $event"
                                    [output]="paisOutput"
                                    [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}">
                                  </entity-autocomplete>
                                </edit>
                              </pl-group>
                            </pl-group>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12 col-lg-6">
                        <div class="card mb-3">
                          <div class="card-header"><i class="fa fa-address-card-o fa-flip-horizontal fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.tabs.descarga'"></span></div>
                          <div class="card-body" style="padding-bottom: 72px">
                            <pl-group>
                              <label [translate]="'docscomerciais.doc.moradaDescarga.moralId'"></label>
                              <edit *ngIf="!moradasInvertidas">
                                <pl-edit-select attrName="descargaEmpresa" [source]="moradasEmpresaSource" (modelChange)="moradaEmpresaChanged($event)" [properties]="propertiesSelectCargaEmpresa">
                                </pl-edit-select>
                              </edit>
                              <edit *ngIf="moradasInvertidas">
                                <entity-autocomplete
                                  attrName="contaMoradaDescarga"
                                  entity="morals"
                                  [model]="model.moradaDescarga"
                                  (modelChange)="onMoralChanged($event)"
                                  output="nome"
                                  [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}"
                                  [filter]="nContaMoralFilter"
                                  [validateFn]="fnMoralValidate">
                                </entity-autocomplete>

                                <div class="form-control-danger" *ngIf="showMessageMoralError && moradasInvertidas">
                                  <i class="fa fa-times-circle" aria-hidden="true"></i>
                                  <span class="btn" [translate]="'docscomerciais.erros.moralInvalidSelected'"></span>
                                </div>
                              </edit>
                            </pl-group>

                            <pl-group>
                              <label [translate]="'docscomerciais.doc.moradaDescarga.rua'"></label>
                              <edit>
                                <pl-edit
                                  type="text"
                                  [model]="model.moradaDescarga.rua"
                                  [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}"
                                  (modelChange)="model.moradaDescarga.rua = $event; modelChanged()"></pl-edit>
                                <pl-edit
                                  type="text"
                                  [model]="model.moradaDescarga.morada"
                                  [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}"
                                  (modelChange)="model.moradaDescarga.morada = $event; modelChanged()"></pl-edit>
                              </edit>
                            </pl-group>

                            <pl-group>
                              <pl-group>
                                <label [translate]="'docscomerciais.doc.moradaDescarga.codPostal'"></label>
                                <edit>
                                  <entity-autocomplete
                                    entity="codpostais"
                                    attrName="codPostalDescarga"
                                    [selectedKey]="model.moradaDescarga.codPostal"
                                    (selectedKeyChange)="model.moradaDescarga.codPostal = $event; modelChanged()"
                                    (evtSelectedDescriptionChanged)="model.moradaDescarga.localidade = $event"
                                    [outputKey]="'codPostal'"
                                    [outputDescription]="'localidade'"
                                    [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}">
                                  </entity-autocomplete>
                                </edit>
                              </pl-group>

                              <pl-group>
                                <label [translate]="'docscomerciais.doc.moradaDescarga.localidade'"></label>
                                <edit>
                                  <pl-edit
                                    type="text"
                                    [(model)]="model.moradaDescarga.localidade"
                                    [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}"
                                    (modelChange)="model.moradaDescarga.localidade = $event; modelChanged()"></pl-edit>
                                </edit>
                              </pl-group>
                            </pl-group>

                            <pl-group>
                              <pl-group>
                                <label [translate]="'docscomerciais.doc.moradaDescarga.codPais'"></label>
                                <edit>
                                  <entity-autocomplete
                                    entity="paises"
                                    [model]="model.moradaDescarga"
                                    (selectedKeyChange)="model.moradaDescarga.codPais = $event; modelChanged()"
                                    (evtSelectedDescriptionChanged)="model.moradaDescarga.nomePais = $event"
                                    [output]="paisOutput"
                                    [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}">
                                  </entity-autocomplete>
                                </edit>
                              </pl-group>

                              <pl-group>
                                <label [translate]="'docscomerciais.doc.moradaDescarga.observacoes'"></label>
                                <edit>
                                  <pl-edit
                                    type="text"
                                    [model]="model.moradaDescarga.observacoes"
                                    [properties]="{readonly: isDocumentoTerminado || isATDocCodeComunicado}"
                                    (modelChange)="model.moradaDescarga.observacoes = $event; modelChanged()"></pl-edit>
                                </edit>
                              </pl-group>
                            </pl-group>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12 col-lg-6">
                        <div class="card mb-3">
                          <div class="card-header"><i class="fa fa-truck fa-flip-horizontal fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.tabs.transportadora'"></span></div>
                          <div class="card-body">
                            <pl-group>
                              <label [translate]="'docscomerciais.doc.transportador.matricula'"></label>
                              <edit>
                                <entity-autocomplete
                                  entity="cargveic"
                                  attrName="matricula"
                                  [output]="'matricula'"
                                  [outputKey]="'matricula'"
                                  [selectedKey]="model.transportador.matricula"
                                  (selectedKeyChange)="model.transportador.matricula = $event; modelChanged()"
                                  [properties]="{readonly: isDocumentoTerminado}">
                                </entity-autocomplete>
                              </edit>
                            </pl-group>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12 col-lg-6" *ngIf="model.cab.isDocTransporteSAFT">
                        <div class="card mb-3">
                          <div class="card-header">
                            <i class="fa fa-fw fa-paper-plane fa-flip-horizontal"></i>&nbsp;<span [translate]="'docscomerciais.comunicacaoDocTranspAtTelefone.title'"></span>
                            <span class="badge bg-secondary ms-2"> {{ model.cab.estadoEnvioStr }}</span>
                          </div>
                          <div class="card-body">
                            <pl-group>
                              <label>
                                <span [translate]="'docscomerciais.doc.cab.atDocCodeID'"></span>
                                <pl-tooltip class="tooltip-info-label" [config]="{text: 'docscomerciais.auxiliares.tooltips.codTranspApenasSeRegistadoAt', placement: ['right']}">
                                  <i *plTooltipContent class="fa fa-info-circle fa-fw text-info" aria-hidden="true"></i>
                                </pl-tooltip>
                              </label>
                              <edit>
                                <pl-edit type="text" [model]="model.cab.atDocCodeID" (modelChange)="onAtDocCodeIDChanged($event)" [properties]="{disabled: !flagAtDocCodeID}">
                                  <ng-container
                                    *inputGroup="{
                                      klass:
                                        flagAtDocCodeID && model.cab.nDocumento > 0 && model.cab.atDocCodeID
                                          ? 'btn-success'
                                          : flagAtDocCodeID && model.cab.nDocumento > 0 && !model.cab.atDocCodeID
                                            ? 'btn-success disabled'
                                            : 'hollow-form'
                                    }">
                                    <div *ngIf="flagAtDocCodeID && model.cab.nDocumento > 0">
                                      <button type="button" class="btn btn-success btn-sm p-0" [disabled]="!model.cab.atDocCodeID" (click)="insertManualAtCodeToDocTransporteSaft()">
                                        <i class="fa fa-fw fa-refresh"></i>&nbsp;<span [translate]="'docscomerciais.btn.markAsCompleted'"></span>
                                      </button>
                                    </div>
                                  </ng-container>
                                </pl-edit>
                              </edit>
                            </pl-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </pl-form>
                </div>
              </div>
            </pl-tab>
            <pl-tab [id]="mainTabPrestacoesId" *ngIf="optionShowPrestacoes && (isComprasEfectivas || isVendasEfectivas)">
              <div *plTabTitle>
                <i class="fa fa-calendar fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.prestacoes'"></span>&nbsp;<span
                  class="badge"
                  [translate]="prestacoesTableSource.length.toString()"></span>
              </div>
              <div *plTabContent>
                <div class="gestaocomercial-widget-body">
                  <div class="d-flex mb-2" style="gap: 15px; flex-wrap: wrap">
                    <pl-group>
                      <label [translate]="'docscomerciais.prestacoes.dateInicial'"></label>
                      <edit>
                        <pl-edit-datepicker [(model)]="prestacoesModel.dateInicial"></pl-edit-datepicker>
                      </edit>
                    </pl-group>

                    <pl-group>
                      <label [translate]="'docscomerciais.prestacoes.nPrestacoes'"></label>
                      <edit>
                        <pl-edit type="cginteger" [(model)]="prestacoesModel.nPrestacoes" [properties]="{validators: {min: {value: 0}, max: {value: 999}}}"></pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group>
                      <label [translate]="'docscomerciais.prestacoes.periodicidade'"></label>
                      <edit>
                        <pl-edit [(model)]="prestacoesModel.periodicidade" [type]="dataSourceNamePeriodicidade"></pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group>
                      <label>&nbsp;</label>
                      <edit>
                        <pl-button klass="btn-primary btn-sm" (evtClicked)="processarPrestacoes()"><i class="fa fa-bolt fa-fw"></i>&nbsp;<span [translate]="'global.btn.process'"></span></pl-button>
                      </edit>
                    </pl-group>

                    <pl-group *ngIf="prestacoesTableSource.length">
                      <label>&nbsp;</label>
                      <edit>
                        <pl-button klass="btn-danger btn-sm" (evtClicked)="apagarPrestacoes()"><i class="fa fa-ban fa-fw"></i>&nbsp;<span [translate]="'global.btn.delete'"></span></pl-button>
                      </edit>
                    </pl-group>
                  </div>

                  <dx-data-grid [cgDxDataGrid]="dataGridPrestacoesDefinition" [dataSource]="dataGridPrestacoesDefinition.dataSource" (onInitialized)="onDataGridPrestacoesInitialized($event)">
                  </dx-data-grid>
                </div>
              </div>
            </pl-tab>
            <pl-tab [id]="mainTabFaturacaoPublicaId" *ngIf="docFa && docFa.faturacaoPublica && model.clifo.facPublicaModelo !== 0">
              <div *plTabTitle><i class="fa fa-file-powerpoint-o fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.faturacaoPublica'"></span></div>
              <div *plTabContent>
                <div class="gestaocomercial-widget-body">
                  <pl-group class="cab-faturacao-publica-options-edit">
                    <pl-group class="cab-faturacao-publica-options-edit">
                      <label [translate]="'docscomerciais.doc.cab.ctRefExterna'"></label>
                      <edit>
                        <pl-edit
                          attrName="ctRefExterna"
                          type="text"
                          [model]="model.cab.ctRefExterna"
                          [properties]="{readonly: isDocumentoTerminado}"
                          (modelChange)="model.cab.ctRefExterna = $event; modelChanged()"></pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group>
                      <label [translate]="'docscomerciais.doc.cab.acordoQuadro'"></label>
                      <edit>
                        <pl-edit type="text" [model]="model.cab.acordoQuadro" [properties]="{readonly: isDocumentoTerminado}" (modelChange)="model.cab.acordoQuadro = $event; modelChanged()"></pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group>
                      <label [translate]="'docscomerciais.doc.cab.compromisso'"></label>
                      <edit>
                        <pl-edit
                          type="text"
                          [model]="model.cab.compromisso"
                          (modelChange)="model.cab.compromisso = $event; modelChanged()"
                          [properties]="{readonly: isDocumentoTerminado, events: {keydown: fnKeyDownCompromisso}}"></pl-edit>
                      </edit>
                    </pl-group>
                  </pl-group>
                </div>
              </div>
            </pl-tab>
            <pl-tab [id]="mainTabAnexosId">
              <div *plTabTitle><i class="fa fa-paperclip fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.anexos'"></span></div>

              <div *plTabContent>
                <div style="padding: 0 12px">
                  <gdocs-viewer
                    [folderId]="gDocFolderId"
                    [readOnly]="isDocumentoTerminado"
                    [showTreeViewer]="false"
                    [treeCollapsed]="false"
                    [params]="gDocParams"
                    [tipoDocEntity]="gDocTipoDocEntity"
                    (evtAddingFile)="onGDocsViewerAddingFile($event)"
                    (uploadFileEvent)="onGDocsViewerUploadFile()"
                    [callback]="callbackGDocs"></gdocs-viewer>
                </div>
              </div>
            </pl-tab>
          </pl-tabs>
        </div>
      </div>
    </div>

    <div *plSplitViewRightSide class="doccontabilidade-contabilidade-digital-docviewer">
      <comercialdigital-docviewer
        [doc]="comercialDigitalDoc"
        [obtemDadosDocDigital]="optionObtemDadosDocDigital"
        [callback]="callbackDocViewer"
        (evtChangedAttachment)="changedComercialDigitalAttachment($event)">
      </comercialdigital-docviewer>
    </div>
  </pl-split-view>
</div>

<ng-template #templateContentPerguntaFinalizaDoc>
  <div class="mt-2">
    <pl-edit-switch
      [model]="naoPerguntaSeFinalizaDoc"
      (modelChange)="changedPerguntaSeFinalizaDoc($event)"
      [properties]="{label: 'docscomerciais.modals.info.naoMostraSaveMsgOkCancel'}"></pl-edit-switch>
  </div>
</ng-template>

<ng-template #templateContentDevolucaoComLigacao>
  <div class="row">
    <div class="col-md-6">
      <div class="d-flex" style="gap: 10px">
        <input type="radio" id="devolucaoAdicionar" name="devolucaoEditar" [checked]="!devolucaoEditar" (change)="devolucaoEditarChanged(false)" />
        <label><i class="fa fa-add fa-fw me-1"></i><span [translate]="'devolucao.caption.adicionar'"></span></label>
      </div>
    </div>
    <div class="col-md-6">
      <div class="d-flex" style="gap: 10px">
        <input type="radio" id="devolucaoEditar" name="devolucaoEditar" [checked]="devolucaoEditar" (change)="devolucaoEditarChanged(true)" />
        <label><i class="fa fa-add fa-fw me-1"></i><span [translate]="'docscomerciais.actions.editarDocumento'"></span></label>
      </div>
    </div>
  </div>
</ng-template>
