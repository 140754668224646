import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {buildSessionUrlWithParams} from '../../../common/utils/utils';
import {IJsonEFatura, IJsonEFaturaAnos, IJsonEFaturaConfiguracao, IJsonEFaturaLogItem, IJsonEFaturaMeses} from './jsonEFatura.module.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';
import {IAtApiCommonBase} from '../../../common/interfaces/at.interfaces';
import {TDate} from '../../../common/dates';

@Injectable({
  providedIn: 'root'
})
export class EFaturaService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/eFatura`;
  }

  public getAnos(): TServiceResponse<Array<IJsonEFaturaAnos>> {
    return this._apiService.get<Array<IJsonEFaturaAnos>>({url: this._path});
  }

  public getMeses(ano: number): TServiceResponse<Array<IJsonEFaturaMeses>> {
    return this._apiService.get<Array<IJsonEFaturaMeses>>({url: `${this._path}/${ano}`});
  }

  public getFatura(year: number, month: number): TServiceResponse<IJsonEFatura> {
    return this._apiService.get<IJsonEFatura>({url: `${this._path}/${year}/${month}`});
  }

  // 0 - Gestão Comercial + Contabilidade; 1 - Gestão Comercial; 2 - Contabilidade
  public generateFatura(year: number, month: number, tipoPesquisa: number = 1, sodocsporcomunicar: boolean = false): TServiceResponse<File> {
    return this._apiService.post<File>({
      url: `${this._path}/${year}/${month}`,
      body: null,
      params: {tipoPesquisa: tipoPesquisa, sodocsporcomunicar: sodocsporcomunicar},
      responseType: 'text'
    });
  }

  public getFaturaJson(year: number, month: number, filename: string): TServiceResponse<IJsonEFatura> {
    return this._apiService.get<IJsonEFatura>({url: `${this._path}/${year}/${month}/json`, params: {filename: filename}});
  }

  public getFaturaXml(year: number, month: number, filename: string): TServiceResponse<string> {
    return this._apiService.get<string>({url: `${this._path}/${year}/${month}/xml`, params: {filename: filename}});
  }

  public getFaturaXmlUrl(year: number, month: number, filename: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/${year}/${month}/xml`, {
      filename: filename
    });
  }

  public comunicaDocs(docsFaccabIDs: Array<number>): TServiceResponse<void> {
    return this._apiService.post<void, Array<number>>({url: `${this._path}/comunicadocs`, body: docsFaccabIDs});
  }

  public comunicacaoInexistencia(ano: number, mes: number): TServiceResponse<IAtApiCommonBase> {
    return this._apiService.post({url: `${this._path}/comunicacao-inexistencia/${ano}/${mes}`, reportExceptions: false});
  }

  public anularComunicacaoInexistencia(ano: number, mes: number): TServiceResponse<IAtApiCommonBase> {
    return this._apiService.post({url: `${this._path}/anular-comunicacao-inexistencia/${ano}/${mes}`, reportExceptions: false});
  }

  public stopJob(): TServiceResponse<void> {
    return this._apiService.post({url: `${this._path}/stop`});
  }

  public getConfiguracaoEFatura(): TServiceResponse<IJsonEFaturaConfiguracao> {
    return this._apiService.get<IJsonEFaturaConfiguracao>({url: `${this._path}/configuracao`});
  }

  public saveConfiguracaoEFatura(config: IJsonEFaturaConfiguracao): TServiceResponse<void> {
    return this._apiService.post<void, IJsonEFaturaConfiguracao>({url: `${this._path}/configuracao`, body: config});
  }

  public getLogs(dataDe: TDate, dataAte: TDate): TServiceResponse<Array<IJsonEFaturaLogItem>> {
    return this._apiService.get<Array<IJsonEFaturaLogItem>>({
      url: `${this._path}/logs`,
      params: {
        dataDe: dataDe,
        dataAte: dataAte
      }
    });
  }
}
