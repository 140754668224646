import {JSONSchema} from '@ngx-pwa/local-storage';
import {EDebitoCredito} from '../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {
  EPreDefinidoContabOrigemDataDoc,
  EPreDefinidoContabOrigemDataLancamento,
  EPreDefinidoContabOrigemDataVenc,
  EPreDefinidoContabTipoConta,
  EPreDefinidoContabTipoValorBaseTributavelLinha,
  EPreDefinidoContabTipoValorCabDescricao,
  EPreDefinidoContabTipoValorCabDiario,
  EPreDefinidoContabTipoValorCabPeriodo,
  EPreDefinidoContabTipoValorDescricao,
  EPreDefinidoContabTipoValorDescritivo,
  EPreDefinidoContabTipoValorLinha,
  EPreDefinidoContabTipoValorMoeda,
  EPreDefinidoContabTipoValorPerguntaBTIntroduzido,
  IJsonPreDefinidoContab,
  IJsonPreDefinidoContabItemVisibilidadeProperties,
  IJsonPreDefinidoContabLinha,
  IJsonPreDefinidoContabLinhaDescricao,
  IJsonPreDefinidoContabLinhaValor
} from './jsonPreDefinidosContab.entity.interface';
import {IEntityDetailStateParams} from '../../../../components/entity/entity.definition.interface';
import {SCHEMA_BOOLEAN, SCHEMA_INTEGER, SCHEMA_NUMBER, SCHEMA_STRING} from '../../../../../common/schemas';

export enum EContabilidadePredefinidosOperation {
  NEW,
  NEW_BASE_DOC,
  NEW_BASE_PREDEFINIDO,
  ADVANCED,
  EDIT,
  DELETE
}

export enum EContabilidadePredefinidosStep {
  Basic = 'basic',
  Header = 'header',
  Lines = 'lines',
  Preview = 'preview',
  Companies = 'companies'
}

export type TPreDefinidoContabFormulaField = 'conta' | 'valor' | 'valorIva' | 'nContribuinte' | 'descricao' | 'nDocExterno' | 'descritivo' | 'dataDoc' | 'dataVencimento' | 'nomeConta';

export interface IPreDefinidoDetailStateParams extends Partial<IEntityDetailStateParams> {
  readonly operation?: EContabilidadePredefinidosOperation;
  readonly docContabilidade?: string;
}

export interface IPreDefinidoContab extends IJsonPreDefinidoContab {
  linhas: Array<IPreDefinidoContabLinha>;
}

export interface IPreDefinidoContabLinha extends IJsonPreDefinidoContabLinha {
  valor: IPreDefinidoContabLinhaValor;
  descricao: IPreDefinidoContabLinhaDescricao;
  _index?: number;
  _id?: number;
  _selected?: boolean;
}

export interface IPreDefinidoContabLinhaValor extends IJsonPreDefinidoContabLinhaValor {
  visibleValorFormula?: string;
}

export interface IPreDefinidoContabLinhaDescricao extends IJsonPreDefinidoContabLinhaDescricao {
  visibleValorFormula?: string;
}

export interface IPreDefinidosOperation {
  value: EContabilidadePredefinidosOperation;
  label: string;
}

export const ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS = 'predefinidoscontabilidade';

export const CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES: Readonly<IJsonPreDefinidoContabItemVisibilidadeProperties> = Object.freeze<IJsonPreDefinidoContabItemVisibilidadeProperties>({
  visible: true,
  readonly: false,
  tabStop: true,
  tabOrder: -1
});

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const CONTABILIDADE_PREDEFINIDOS_DEFAULT_ORDEM_COLUNAS: ReadonlyArray<number> = Object.freeze<Array<number>>([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);

const schemaJsonPreDefinidoContabItemVisibilidadeProperties: JSONSchema = {
  type: 'object',
  properties: {
    visible: SCHEMA_BOOLEAN,
    readonly: SCHEMA_BOOLEAN,
    tabStop: SCHEMA_BOOLEAN,
    tabOrder: SCHEMA_INTEGER
  }
};

const schemaJsonPredefinidoContabCabPeriodo: JSONSchema = {
  type: 'object',
  properties: {
    tipoValorPeriodo: {
      type: 'number'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabDiario: JSONSchema = {
  type: 'object',
  properties: {
    tipoValorDiario: SCHEMA_INTEGER,
    valorFixo: SCHEMA_INTEGER,
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabItemDefault: JSONSchema = {
  type: 'object',
  properties: {
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabDataDoc: JSONSchema = {
  type: 'object',
  properties: {
    origem: {
      type: 'number'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabDataVencimento: JSONSchema = {
  type: 'object',
  properties: {
    origem: {
      type: 'number'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabDataLancamento: JSONSchema = {
  type: 'object',
  properties: {
    origem: {
      type: 'string'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabNDocExterno: JSONSchema = {
  type: 'object',
  properties: {
    incrementaAutomaticamente: {
      type: 'boolean'
    },
    valorFixo: {
      type: 'string'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabDescricao: JSONSchema = {
  type: 'object',
  properties: {
    tipoValorDescricao: {
      type: 'number'
    },
    valorFixo: {
      type: 'string'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabDescritivo: JSONSchema = {
  type: 'object',
  properties: {
    tipoValorDescritivo: {
      type: 'number'
    },
    valorFixo: {
      type: 'number'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabMoeda: JSONSchema = {
  type: 'object',
  properties: {
    tipoValorMoeda: {
      type: 'number'
    },
    valorFixo: {
      type: 'number'
    },
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabCabecalho: JSONSchema = {
  type: 'object',
  properties: {
    periodo: schemaJsonPredefinidoContabCabPeriodo,
    diario: schemaJsonPreDefinidoContabCabDiario,
    nDocInterno: schemaJsonPreDefinidoContabItemDefault,
    dataDoc: schemaJsonPreDefinidoContabCabDataDoc,
    dataVencimento: schemaJsonPreDefinidoContabCabDataVencimento,
    dataLancamento: schemaJsonPreDefinidoContabCabDataLancamento,
    nContribuinte: schemaJsonPreDefinidoContabItemDefault,
    nDocExterno: schemaJsonPreDefinidoContabCabNDocExterno,
    descricao: schemaJsonPreDefinidoContabCabDescricao,
    descritivo: schemaJsonPreDefinidoContabCabDescritivo,
    moeda: schemaJsonPreDefinidoContabCabMoeda
  }
};

const schemaJsonPreDefinidoContabEmpresasSelecionadas: JSONSchema = {
  type: 'array',
  items: SCHEMA_STRING
};

const schemaJsonPreDefinidoContabOrdemColunas: JSONSchema = {
  type: 'array',
  items: SCHEMA_INTEGER
};

const schemaJsonPreDefinidoContabLinhaContaBaseTribProperties: JSONSchema = {
  type: 'object',
  properties: {
    codRepCC: {
      type: 'number'
    }
  }
};

const schemaJsonPreDefinidoContabLinhaContaCorrenteProperties: JSONSchema = {
  type: 'object',
  properties: {
    temImputacao: {
      type: 'boolean'
    },
    vaiImputarALinha: {
      type: 'number'
    }
  }
};

const schemaJsonPreDefinidoContabLinhaConta: JSONSchema = {
  type: 'object',
  properties: {
    tipoConta: {
      type: 'number'
    },
    valorFixo: {
      type: 'string'
    },
    dC: {
      type: 'number'
    },
    radical: {
      type: 'string'
    },
    baseTributavelProperties: schemaJsonPreDefinidoContabLinhaContaBaseTribProperties,
    contaCorrenteProperties: schemaJsonPreDefinidoContabLinhaContaCorrenteProperties,
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabLinhaValor: JSONSchema = {
  type: 'object',
  properties: {
    tipoValor: {
      type: 'number'
    },
    valorFixo: {
      type: 'number'
    },
    valorFormula: {
      type: 'string'
    },
    visibleValorFormula: {
      type: 'string'
    },
    baseTributavelProperties: schemaJsonPreDefinidoContabLinhaContaBaseTribProperties,
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

const schemaJsonPreDefinidoContabLinhaDescricao: JSONSchema = {
  type: 'object',
  properties: {
    tipoValorDescricao: {
      type: 'number'
    },
    valorFixo: {
      type: 'number'
    },
    valorFormula: {
      type: 'string'
    },
    visibleValorFormula: {
      type: 'string'
    },
    baseTributavelProperties: schemaJsonPreDefinidoContabLinhaContaBaseTribProperties,
    visibilidadeProperties: schemaJsonPreDefinidoContabItemVisibilidadeProperties
  }
};

export const schemaJsonPreDefinidoContabLinha: JSONSchema = {
  type: 'object',
  properties: {
    _index: {
      type: 'number'
    },
    conta: schemaJsonPreDefinidoContabLinhaConta,
    valor: schemaJsonPreDefinidoContabLinhaValor,
    valorIva: schemaJsonPreDefinidoContabItemDefault,
    descricao: schemaJsonPreDefinidoContabLinhaDescricao,
    nContribuinte: schemaJsonPreDefinidoContabItemDefault,
    nDocExterno: schemaJsonPreDefinidoContabCabNDocExterno,
    descritivo: schemaJsonPreDefinidoContabCabDescritivo,
    dataDoc: schemaJsonPreDefinidoContabCabDataDoc,
    dataVencimento: schemaJsonPreDefinidoContabCabDataVencimento,
    nomeConta: schemaJsonPreDefinidoContabItemDefault
  }
};

const schemaJsonPreDefinidoContabLinhas: JSONSchema = {
  type: 'array',
  items: schemaJsonPreDefinidoContabLinha
};

export const schemaJsonPreDefinidoContab: JSONSchema = {
  type: 'object',
  properties: {
    preDefinidosID: SCHEMA_NUMBER,
    descricao: SCHEMA_STRING,
    revision: SCHEMA_NUMBER,
    cgBanking: SCHEMA_BOOLEAN,
    cabecalho: schemaJsonPreDefinidoContabCabecalho,
    linhas: schemaJsonPreDefinidoContabLinhas,
    empresasSelecionadas: schemaJsonPreDefinidoContabEmpresasSelecionadas,
    ordemColunas: schemaJsonPreDefinidoContabOrdemColunas
  }
};

export function emptyPredefinidoContabilidade(): IJsonPreDefinidoContab {
  return {
    preDefinidosID: undefined,
    descricao: '',
    revision: undefined,
    cgBanking: false,
    isGeneric: false,
    cabecalho: {
      periodo: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES},
        tipoValorPeriodo: EPreDefinidoContabTipoValorCabPeriodo.TabelaEmpresa
      },
      diario: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES},
        tipoValorDiario: EPreDefinidoContabTipoValorCabDiario.NaoDefinido,
        valorFixo: undefined
      },
      nDocInterno: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, readonly: true, tabStop: false}
      },
      dataLancamento: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
        origem: EPreDefinidoContabOrigemDataLancamento.UltimoDiaPeriodo
      },
      dataDoc: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES},
        origem: EPreDefinidoContabOrigemDataDoc.NaoDefinido
      },
      dataVencimento: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
        origem: EPreDefinidoContabOrigemDataVenc.NaoDefinido
      },
      nContribuinte: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false}
      },
      nDocExterno: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES},
        incrementaAutomaticamente: false,
        valorFixo: undefined
      },
      descricao: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
        tipoValorDescricao: EPreDefinidoContabTipoValorCabDescricao.NaoDefinido,
        valorFixo: undefined
      },
      descritivo: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
        tipoValorDescritivo: EPreDefinidoContabTipoValorDescritivo.ObtidoPorUltimoDocumento,
        valorFixo: undefined
      },
      moeda: {
        visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
        tipoValorMoeda: EPreDefinidoContabTipoValorMoeda.NaoDefinido,
        valorFixo: undefined
      }
    },
    linhas: [],
    empresasSelecionadas: [],
    ordemColunas: CONTABILIDADE_PREDEFINIDOS_DEFAULT_ORDEM_COLUNAS.slice()
  };
}

export function emptyPredefinidoContabilidadeLine(): IJsonPreDefinidoContabLinha {
  return {
    conta: {
      visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES},
      tipoConta: EPreDefinidoContabTipoConta.NaoDefinido,
      valorFixo: undefined,
      dC: EDebitoCredito.Debito,
      radical: '',
      baseTributavelProperties: {codRepCC: 0},
      contaCorrenteProperties: {temImputacao: false, vaiImputarALinha: 0}
    },
    valor: {
      visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES},
      tipoValor: EPreDefinidoContabTipoValorLinha.Pergunta,
      valorFixo: undefined,
      valorFormula: undefined,
      colocaValorEFaturaDocDigital: true,
      baseTributavelProperties: {
        tipoValorPerguntaIntroduzido: EPreDefinidoContabTipoValorPerguntaBTIntroduzido.None,
        tipoValorBaseTributavel: EPreDefinidoContabTipoValorBaseTributavelLinha.BaseMaisIva,
        importEFaturaContaUnicaSemDeducao: false,
        importEFaturaSuportaTaxaIsenta: false,
        importEFaturaValorImpSelo: false,
        importFRVSuportaTaxaIsenta: false,
        importFRVValorImpSelo: false
      }
    },
    valorIva: {
      visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, readonly: true, tabStop: false}
    },
    descricao: {
      visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
      tipoValorDescricao: EPreDefinidoContabTipoValorDescricao.NaoDefinido,
      valorFixo: undefined,
      valorFormula: undefined
    },
    nContribuinte: {
      visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false}
    },
    nDocExterno: {
      visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
      incrementaAutomaticamente: false,
      valorFixo: undefined
    },
    descritivo: {
      visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
      tipoValorDescritivo: EPreDefinidoContabTipoValorDescritivo.ObtidoPorUltimoDocumento,
      valorFixo: undefined
    },
    dataDoc: {
      visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
      origem: EPreDefinidoContabOrigemDataDoc.NaoDefinido
    },
    dataVencimento: {
      visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false},
      origem: EPreDefinidoContabOrigemDataVenc.NaoDefinido
    },
    nomeConta: {
      visibilidadeProperties: {...CONTABILIDADE_PREDEFINIDOS_DEFAULT_VISIBILIDADE_PROPERTIES, visible: false, readonly: true, tabStop: false}
    },
    fake: false
  };
}
