<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'invec.newModalTitle'"></h5>
</div>

<pl-form (evtSubmitted)="close()">
  <div class="modal-body">
    <div class="mb-2"><label [translate]="'global.text.date'"></label>: {{ formattedData }}</div>
    <pl-group>
      <label [translate]="'invec.fields.nome'"></label>
      <edit>
        <pl-edit type="text" [(model)]="nome" [properties]="{validators: {required: {value: true}}}"></pl-edit>
      </edit>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn-sm btn-primary action-save" plAutoFocus><i class="fa fa-fw fa-floppy-o"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-sm btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</pl-form>
