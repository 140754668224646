import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {HookMatchCriteria, TransitionService} from '@uirouter/core';
import {copy, IPlNavWizardDefinition, IPlNavWizardEventStep, IPlValidator, IPlValidatorValidateParams, isEmpty, isFunction, PlAlertService, PlGlobalEventsService, toInteger} from 'pl-comps-angular';
import {AuthManagerService} from '../../../services/auth/auth.manager.service';
import {AuthService} from '../../../services/auth/auth.service';
import {CG_DEFAULT_STORE_NIF} from '../../../services/config/config.service.interface';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {ConfigLoginWsSSModalComponent} from '../../segsocial/modals/loginWsSS.modal.component';
import {ConfigService} from '../../../services/config/config.service';
import {EMPRESAS_STORE_WELCOME_REQUIRED_FIELDS, IEmpresasStoreWelcomeRequiredFields, MODULE_NAME_EMPRESAS} from '../empresas.module.interface';
import {EmpresaService} from '../../../services/empresa/empresa.service';
import {EmpresasPerioContabFirstMonthModalComponent} from '../modals/periocontabfirstmonth/empresas.perioContabFirstMonth.modal.component';
import {EmpresasService} from '../../../services/empresas/empresas.service';
import {ENTITY_NAME_EMPRESAS_ERP, IJsonPerioMudaPrimeiroMesContab} from '../../../entities/empresas/empresas.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {ETipoAnalitica} from '../../../datasources/tipoanalitica/tipoAnalitica.datasource.interface';
import {GLOBAL_EVENT_LOGO_CHANGED} from '../../../../config/constants';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IJsonEmpresaErp} from '../../../interfaces/jsonEmpresa.interface';
import {IJsonErpUser} from '../../../services/account/jsonUserApi.interface';
import {IModuleMaintenanceInstance} from '../../../components/entity/maintenance/module/module.maintenance.interface';
import {MODULE_NAME_ANALITICA_CONFIG} from '../../analiticaconfig/analiticaConfig.module.interface';
import {ModuleMaintenanceService} from '../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloComponent} from '../../../components/module/module.component';
import {ROLE} from '../../../services/role.const';
import {SimpleLoginModalComponent} from '../../simplelogin/modals/simpleLogin.modal.component';
import {StoreWelcomeModalComponent} from '../modals/storewelcome/storeWelcome.modal.component';
import {UploadLogoModalComponent} from '../modals/uploadlogo/uploadLogo.modal.component';
import {ILoginWsSS} from '../../segsocial/loginWsSS.module.interface';
import {IJsonQpcae} from '../../../entities/qpcae/jsonQpcae.entity.interface';
import {ENTITY_NAME_QPCAE} from '../../../entities/qpcae/qpcae.entity.interface';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';

const YEAR_LENGTH = 4;

@Component({
  selector: 'empresas',
  templateUrl: './empresas.module.component.html'
})
export class EmpresasComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public empresa: IJsonEmpresaErp;

  public readonly definition: IPlNavWizardDefinition;
  public readonly storeCompanyRequiredFields: IEmpresasStoreWelcomeRequiredFields;
  public readonly storeCompanyRequiredFieldsKeys: Array<keyof IEmpresasStoreWelcomeRequiredFields>;
  public readonly validatorNif: IPlValidator<string>;
  public readonly caeTemplate: string;

  public hasApiComercial: boolean;
  public hasApiContabilidade: boolean;
  public hasApiRH: boolean;
  public errorCount: number;
  public errorStoreCompanyRequiredFields: boolean;
  public promiseConfiguracaoAtModal: Promise<unknown>;
  public caeSource: Array<IJsonQpcae>;
  public caeList: Array<string>;

  private readonly _maintenanceAnaliticaConfig: IModuleMaintenanceInstance;
  private readonly _empresaEntityService: IEntityService<IJsonEmpresaErp>;
  private readonly _caeService: IEntityService<IJsonQpcae>;
  private _erp: IJsonErpUser;
  private _form: UntypedFormGroup;
  private _deRegisterOnStart: Function;
  private _promise: Promise<void>;
  private _storeCompanyNeedsConfiguration: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _transitionService: TransitionService,
    private readonly _authService: AuthService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _config: ConfigService,
    private readonly _authManagerService: AuthManagerService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _empresasService: EmpresasService,
    private readonly _empresaService: EmpresaService,
    private readonly _plGlobalEventsService: PlGlobalEventsService
  ) {
    super(_injector);
    this.definition = {
      items: [],
      force: true
    };
    this.storeCompanyRequiredFields = copy<IEmpresasStoreWelcomeRequiredFields>(EMPRESAS_STORE_WELCOME_REQUIRED_FIELDS);
    this.storeCompanyRequiredFieldsKeys = <Array<keyof IEmpresasStoreWelcomeRequiredFields>>Object.keys(this.storeCompanyRequiredFields);
    this.hasApiComercial = false;
    this.hasApiContabilidade = false;
    this.hasApiRH = false;
    this.errorCount = 0;
    this.errorStoreCompanyRequiredFields = false;
    this.caeSource = [];
    this.caeList = [];
    this.caeTemplate = '{{codcae}} - {{nome}}';
    this.validatorNif = {
      message: 'empresas.storewelcome.errors.nif',
      validate: ({formControlValue}: IPlValidatorValidateParams<string>) => {
        if (this._storeCompanyNeedsConfiguration) {
          return !isEmpty(formControlValue) && formControlValue !== CG_DEFAULT_STORE_NIF;
        }
        return true;
      }
    };
    this._empresaEntityService = this._entityServiceBuilder.build(ENTITY_NAME_EMPRESAS_ERP);
    this._maintenanceAnaliticaConfig = this._moduleMaintenanceService.build(MODULE_NAME_ANALITICA_CONFIG);
    this._authService.hasAuthority(ROLE.ERP).then((value: boolean) => {
      this.hasApiComercial = value;
    });
    this._authService.hasAuthority(ROLE.CONTABILIDADE).then((value: boolean) => {
      this.hasApiContabilidade = value;
    });
    this._authService.hasAuthority(ROLE.RH).then((value: boolean) => {
      this.hasApiRH = value;
    });
    this._storeCompanyNeedsConfiguration = false;
    this._promise = this._empresaService.checkStoreCompanyNeedsConfiguration().then((needsConfiguration: boolean) => {
      if (needsConfiguration) {
        this._storeCompanyNeedsConfiguration = true;
        this._registerStateExitHook();
        this._cgModalService.show(StoreWelcomeModalComponent).then(() => {
          if (this._form) {
            const formControlNif = this._form.controls.nifEmpresa;
            if (formControlNif) {
              formControlNif.updateValueAndValidity();
            }
          }
        });
      }
    });
    this._caeService = this._entityServiceBuilder.build<IJsonQpcae>(ENTITY_NAME_QPCAE);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.empresa = {
      nempresa: '',
      nome: '',
      nomeAlternativo: '',
      morada: '',
      codpostal: '',
      localidade: '',
      nif: '',
      actividade: '',
      cae: '',
      conservatoria: '',
      registo: '',
      codMoeda: undefined,
      codPais: undefined,
      nomePais: '',
      nomeMoeda: '',
      telefone: '',
      email: '',
      web: '',
      actividadePrincipal: '',
      codTipoEmpresa: undefined,
      tipoEmpresa: '',
      alvara: '',
      capitalSocial: undefined,
      codRepFinancas: undefined,
      repFinancas: '',
      codTipoContab: undefined,
      tipoContab: '',
      codRegimeIRC: undefined,
      regimeIRC: '',
      codRegimeIva: undefined,
      regimeIva: '',
      codPeriodoIva: undefined,
      periodoIva: '',
      anoCursoIRC: undefined,
      periodoContabilistico: '',
      textoRelatorio: '',
      demonstracao: false,
      tipoAnalitica: undefined,
      nEmpresaGabinete: '',
      empresaGabinete: false,
      capPublico: undefined,
      capPrivado: undefined,
      capEstrangeiro: undefined,
      capPublicoPerc: undefined,
      capPrivadoPerc: undefined,
      capEstrangeiroPerc: undefined,
      concelhoEmp: 0,
      nomeConcelhoEmp: '',
      caeAdicionais: '',
      ...this.empresa
    };
    this.btnSave.visible = true;
    this.btnSave.click = () => this._saveDadosEmpresa();
    this._erp = this.session.erp;
    this._caeService.query().then((response: THttpQueryResponse<IJsonQpcae>) => {
      this.caeSource = response.body.list;
    });

    if (this.empresa.cae) {
      this.caeList.push(this.empresa.cae);
      if (this.empresa.caeAdicionais) {
        this.empresa.caeAdicionais.split(',').forEach((cae: string) => {
          this.caeList.push(cae);
        });
      }
    }
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._unRegisterStateExitHook();
  }

  public tipoAnaliticaChanged(value: number): Promise<void> {
    this.empresa.tipoAnalitica = value;
    return new Promise<void>((resolve, reject) => {
      if (this.empresa.tipoAnalitica === ETipoAnalitica.Usa) {
        this._cgModalService
          .showOkCancel('empresas.modal.confirmTitle', 'empresas.modal.confirmMessage')
          .then(() => this._maintenanceAnaliticaConfig.maintenance().finally(resolve))
          .catch(reject);
      } else {
        resolve();
      }
    });
  }

  public changedFormInstance(value: UntypedFormGroup): void {
    this._form = value;
  }

  public getLocalidadeFromCodPostal(localidade: string): void {
    this.empresa.localidade = localidade;
  }

  public configuracaoAtModal(): Promise<unknown> {
    if (this.empresa.demonstracao) {
      return Promise.resolve();
    }
    this.promiseConfiguracaoAtModal = this._cgModalService.show(SimpleLoginModalComponent, {size: 'sm'});
    return this.promiseConfiguracaoAtModal;
  }

  public configuracaoSegSocialModal(): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(ConfigLoginWsSSModalComponent, {size: 'md'});
    const componentInstance: ConfigLoginWsSSModalComponent = modalInstance.componentInstance;
    componentInstance.nEmpresa = this.empresa.nempresa;
    return modalInstance.result.then((loginWsModel: ILoginWsSS) => {
      this.empresa.nContribSegSocial = loginWsModel.nissEmpresa;
    });
  }

  public openPerioMudaPrimeiroMesContab(): Promise<void> {
    return this._empresasService.getPerioMudaPrimeiroMesContab().then((response: HttpResponse<IJsonPerioMudaPrimeiroMesContab>) => {
      const modalInstance = this._cgModalService.showVanilla(EmpresasPerioContabFirstMonthModalComponent);
      const componentInstance: EmpresasPerioContabFirstMonthModalComponent = modalInstance.componentInstance;
      componentInstance.perioMudaPrimeiroMesContab = response.body;
    });
  }

  public stepChanged(params: IPlNavWizardEventStep): void {
    params.previousStep.setComplete();
  }

  public readonly fnMaintenanceAnaliticaConfig = (): Promise<void> => this._customActionAnaliticaConfig();

  public readonly fnChangeLogo: () => Promise<void> = () => this._changeLogo();

  public readonly fnDeleteLogo: () => Promise<void> = () => this._deleteLogo();

  public readonly fnFinalize: () => Promise<void> = () => this._finalize();

  private _registerStateExitHook(): void {
    this._unRegisterStateExitHook();
    const hookMatchCriteria: HookMatchCriteria = {exiting: MODULE_NAME_EMPRESAS};
    this._deRegisterOnStart = this._transitionService.onStart(hookMatchCriteria, () => {
      return new Promise<boolean>((resolve) => {
        Promise.resolve(this._promise).finally(() => {
          this._empresaService
            .checkStoreCompanyNeedsConfiguration()
            .then((needsConfiguration: boolean) => {
              this._storeCompanyNeedsConfiguration = needsConfiguration;
              if (this._storeCompanyNeedsConfiguration) {
                this.errorCount++;
              }
              resolve(!this._storeCompanyNeedsConfiguration);
            })
            .catch((reason: unknown) => {
              this._logger.error(reason);
              resolve(true);
            });
        });
      });
    });
  }

  private _unRegisterStateExitHook(): void {
    if (isFunction(this._deRegisterOnStart)) {
      this._deRegisterOnStart();
      this._deRegisterOnStart = undefined;
    }
  }

  private _saveDadosEmpresa(): Promise<void> {
    this.errorCount = 0;
    this.errorStoreCompanyRequiredFields = false;
    this.storeCompanyRequiredFields.nif.invalid = false;
    const anoContab = this.empresa.periodoContabilistico.slice(0, YEAR_LENGTH);
    if (toInteger(anoContab) < this.empresa.anoCursoIRC) {
      this._plAlertService.error('empresas.messages.error.savePeriodoContabilistico');
      return Promise.reject(new Error(this._translateService.instant('empresas.messages.error.savePeriodoContabilistico')));
    }

    // O logótipo deve ser guardado só quando são guardados os outros dados
    return new Promise<void>((resolve, reject) => {
      Promise.resolve(this._promise).finally(() => {
        const nif = this.empresa.nif.trim();
        if (this._storeCompanyNeedsConfiguration && (!nif || nif === CG_DEFAULT_STORE_NIF)) {
          this.errorCount++;
          this.errorStoreCompanyRequiredFields = true;
          this.storeCompanyRequiredFields.nif.invalid = true;
          reject(new Error('Invalid nif.'));
          return;
        }
        if (this.caeList.length > 0) {
          this.empresa.cae = this.caeList[0];
          this.empresa.caeAdicionais = this.caeList.slice(1).join(',');
        }
        this._promise = this._empresaEntityService
          .put({id: this.empresa.nempresa, body: this.empresa})
          .then(() => {
            if (this.empresa.nome !== this._erp.nomeEmpresa) {
              this._erp.nomeEmpresa = this.empresa.nome;
              this._authManagerService.mudarNomeEmpresa(this._erp).then(() => {
                this._plAlertService.success('empresas.messages.success.save');
                this._config.refresh().finally(() => {
                  this._refreshAllErpsUser(this._erp.nEmpresa, this.empresa.nome).finally(resolve);
                });
              });
            } else {
              this._plAlertService.success('empresas.messages.success.save');
              this._config.refresh().finally(resolve);
            }
          })
          .catch(reject);
      });
    });
  }

  private _refreshAllErpsUser(nEmpresa: string, nomeEmpresa: string): Promise<void> {
    return this._authService.identity().then((user) => {
      user.erp.nomeEmpresa = nomeEmpresa;
      const erp = user.erps.find((x) => x.nEmpresa === nEmpresa);
      erp.nomeEmpresa = nomeEmpresa;
    });
  }

  private _customActionAnaliticaConfig(): Promise<void> {
    return this._maintenanceAnaliticaConfig.maintenance();
  }

  private async _changeLogo(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(UploadLogoModalComponent, {size: 'md'});
    const componentInstance: UploadLogoModalComponent = modalInstance.componentInstance;
    componentInstance.nEmpresa = this.empresa.nempresa;
    await modalInstance.result;
  }

  private async _deleteLogo(): Promise<void> {
    await this._empresasService.deleteLogotipo(this.empresa.nempresa);
    this._plAlertService.success('empresas.messages.success.deleteLogoTipo');
    this._plGlobalEventsService.broadcast(GLOBAL_EVENT_LOGO_CHANGED);
  }

  private _finalize(): Promise<void> {
    return this._saveDadosEmpresa();
  }
}
