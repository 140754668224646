<pl-tabs [theme]="'style3'" [justified]="isMobile">
  <pl-tab id="ficha" caption="clifos.tab.ficha">
    <div *plTabContent>
      <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>

      <entity-detail
        [entityName]="entityName"
        [(model)]="model"
        [type]="type"
        [maintenanceMode]="maintenanceMode"
        [callback]="callback"
        [serviceMethodsOverride]="serviceMethodsOverride"
        (evtUpdate)="onUpdate($event)">
        <div *entityDetailContent>
          <pl-form [properties]="{readonly: type === stateTypes.DETAIL}" (formInstanceChange)="formInstance = $event">
            <clifos-ficha
              [model]="model"
              [grupo]="grupo"
              [type]="type"
              [maintenanceMode]="maintenanceMode"
              [serviceMethodsOverride]="serviceMethodsOverride"
              [optionShowVendedor]="optionShowVendedor"
              [optionShowZona]="optionShowZona"
              [optionShowSector]="optionShowSector"
              [callback]="clifosFichaCallback"
              (evtNifValidStateChange)="onNifValidStateChange($event)">
            </clifos-ficha>
          </pl-form>
        </div>
      </entity-detail>
    </div>
  </pl-tab>

  <pl-tab id="documentos" caption="global.menu.docscomerciais" *ngIf="showExtraTabs">
    <div *plTabContent>
      <entity-list
        entityName="docscomerciais"
        [maintenanceMode]="maintenanceMode"
        filter="nConta={{ model.nConta }}&nIdAltern={{ model.nIdAltern }}"
        [toolbarInstanceId]="toolbarInstanceDocumentos"
        [serviceMethodsOverride]="serviceMethodsOverride"
        (evtDataGridCellClick)="onDocsComerciaisDataGridCellClick($event)">
        <div *entityListItemDetail="let item">
          <documento-faturacao
            [doc]="item.data._doc"
            [entityNameClifos]="'fornecedores'"
            [actionPdf]="fnGetPdf"
            [actionGetDoc]="fnGetDoc"
            [actionAnularDoc]="fnAnularDocumento"
            [actionNotaCreditoDoc]="fnNotaCreditoDocumento"
            [actionTransformarLinhasDoc]="fnTransformarLinhasDocumento"
            [actionDesmarcarDocTransformado]="fnDesmarcarDocTransformado"
            [actionMeiosPagamentoComercial]="fnMeiosPagamentoComercial"
            [actionEncerrarDocumento]="fnEncerrarDocumento"
            (evtRefresh)="refresh()">
          </documento-faturacao>
        </div>
      </entity-list>
    </div>
  </pl-tab>

  <pl-tab id="pagamentos" caption="global.menu.pagamentos" *ngIf="showExtraTabs">
    <div *plTabContent>
      <entity-list
        entityName="pagamentos"
        [maintenanceMode]="maintenanceMode"
        filter="nConta={{ model.nConta }}"
        [fields]="pagamentosFields"
        [toolbarInstanceId]="toolbarInstancePagamentos"
        [serviceMethodsOverride]="serviceMethodsOverride"
        (evtDataGridCellClick)="onPagamentosDataGridCellClick($event)"
        #entityListPagamentos>
        <div *entityListItemDetail="let item">
          <pagamento [pagamento]="item.data._pagamento" (evtRefresh)="refreshPagamentosList()"></pagamento>
        </div>
      </entity-list>
    </div>
  </pl-tab>

  <pl-tab id="movimentos" caption="global.text.movimentosPendentes" *ngIf="showExtraTabs">
    <div *plTabContent>
      <movimentos-pendentes-fornecedores [nConta]="model.nConta" [fields]="fieldsMovimentosPendentes"></movimentos-pendentes-fornecedores>
    </div>
  </pl-tab>

  <pl-tab id="artigos" caption="clifos.tab.artigos" *ngIf="type !== stateTypes.NEW && !disableTabArtigos">
    <div *plTabContent>
      <entity-list
        entityName="arclis"
        [maintenanceMode]="maintenanceMode"
        filter="nConta={{ model.nConta }}"
        [serviceMethodsOverride]="serviceMethodsOverride"
        (evtDataGridOnInitialized)="onDataGridEventOnInitializedArCli($event)"
        (evtDataGridCellClick)="onDataGridCellClickArCli($event)">
        <div *entityListDataGridToolbarItem="let toolbarItem; item: {name: 'addArcli'}">
          <button type="button" id="arclis-save-2" class="btn btn-primary btn-sm ng-star-inserted" (click)="novoArCli()">
            <i class="fa fa-plus-circle"></i>&nbsp;<span [translate]="'arclis.btn.newArtigo'"></span>
          </button>
        </div>
        <div *entityListItemActions="let itemAction" class="text-center">
          <pl-button klass="btn-warning btn-xs action-edit-line me-1" [attr.title]="'arclis.list.actions.editar' | translate" [onClick]="fnEditarArcli(itemAction.data)">
            <i class="fa fa-fw fa-pencil-square-o"></i>
          </pl-button>
        </div>
      </entity-list>
    </div>
  </pl-tab>

  <pl-tab id="encomendas" caption="clifos.tab.encomendas" *ngIf="type !== stateTypes.NEW && !disableTabArtigos">
    <div *plTabContent>
      <cg-blocked-plugin [pluginRoles]="roleComercialAdvanced"></cg-blocked-plugin>
      <encomendas
        *ngIf="hasComercialAdvanced"
        [nConta]="model.nConta"
        [nomeConta]="model.nome"
        [grupoClifos]="'fornecedores'"
        [grupo]="grupoEncomenda"
        [toolbarInstanceName]="instanceName"></encomendas>
    </div>
  </pl-tab>

  <pl-tab id="extratos" caption="clifos.tab.extratos" *ngIf="type !== stateTypes.NEW">
    <div *plTabContent>
      <extratosdt
        [empresaTemContabilidadeDigital]="empresaTemContabilidadeDigital"
        [empresaAnoEmCursoIRC]="empresaAnoEmCursoIRC"
        [params]="{nConta: this.model.nConta, nomeConta: this.model.nome}"
        [callback]="callbackExtratos"
        [hideNConta]="true"
        [hideProcessBtn]="false">
      </extratosdt>
    </div>
  </pl-tab>

  <pl-tab id="anexos" caption="clifos.tab.anexos" *ngIf="type !== stateTypes.NEW">
    <div *plTabContent>
      <clifos-gdoc [nConta]="model.nConta"></clifos-gdoc>
    </div>
  </pl-tab>
</pl-tabs>
