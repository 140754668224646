import {Subscription} from 'rxjs';
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isBoolean, isString} from 'pl-comps-angular';
import {AuthService} from '../../../services/auth/auth.service';
import {ConfigService} from '../../../services/config/config.service';
import {ConfigSiteService} from '../../../services/configsite.service';
import {IJsonUserRole, TUserSession} from '../../../services/account/jsonUserApi.interface';
import {ICGConfigurations} from '../../../services/config/config.service.interface';
import {ROLE} from '../../../services/role.const';
import {IJsonPortal} from '../../../entities/portal/jsonPortal.entity.interface';
import {API_PORTAL_PREFIX} from '../../../../config/constants';

const IGNORED_ROLES: ReadonlyArray<ROLE> = Object.freeze([ROLE.ANY, ROLE.API, ROLE.REGEX_ROLE]);

@Component({
  selector: 'cg-blocked-plugin',
  templateUrl: './blockedplugin.component.html',
  exportAs: 'cgBlockedPlugin'
})
export class CGBlockedPluginComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public moduleMode: boolean;
  @Input() public pluginRoles: ROLE | Array<ROLE> | ReadonlyArray<ROLE>;
  @Input() public requiredRoles: ROLE | Array<ROLE> | ReadonlyArray<ROLE>;

  private readonly _pluginRoles: Set<ROLE>;
  private readonly _requiredRoles: Set<ROLE>;
  private readonly _subscriptionIdentity: Subscription;
  private readonly _subscriptionConfig: Subscription;
  private _initialized: boolean;
  private _portals: Array<IJsonPortal>;
  private _errors: Array<string>;
  private _rolesAccess: Array<IJsonUserRole>;
  private _rolesNotAccess: Array<IJsonUserRole>;
  private _licencaModoCGOn: boolean;
  private _storeUrl: string;

  constructor(
    private readonly _authService: AuthService,
    private readonly _configService: ConfigService,
    private readonly _configSiteService: ConfigSiteService,
    private readonly _translateService: TranslateService
  ) {
    this.moduleMode = false;
    this._pluginRoles = new Set<ROLE>();
    this._requiredRoles = new Set<ROLE>();
    this._initialized = false;
    this._portals = [];
    this._errors = [];
    this._rolesAccess = [];
    this._rolesNotAccess = [];

    this._subscriptionIdentity = this._authService.identityAsObservable().subscribe((session: TUserSession) => {
      this._rolesAccess = session?.roles.slice() || [];
      this._rolesNotAccess = session?.erp.rolesNotAcess.slice() || [];
      if (this._initialized) {
        this._evaluateErrors();
      }
    });

    this._subscriptionConfig = this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      this._licencaModoCGOn = configurations.licenca.modoCGOn;
      if (this._initialized) {
        this._evaluateErrors();
      }
    });

    this._configSiteService.cgStoreUrlBackOffice().then((cgStoreUrlBackOffice: string) => {
      this._storeUrl = cgStoreUrlBackOffice;
      if (this._initialized) {
        this._evaluateErrors();
      }
    });
  }

  public ngOnInit(): void {
    this._changedModuleMode();
    this._changedPluginRoles();
    this._changedRequiredRoles();
    this._authService.userPortals().then((portals: Array<IJsonPortal>) => {
      this._portals = portals;
      this._evaluateErrors();
      this._initialized = true;
    });
  }

  public ngOnChanges({moduleMode, pluginRoles, requiredRoles}: SimpleChanges): void {
    if (moduleMode && !moduleMode.isFirstChange()) {
      this._changedModuleMode();
    }
    const changedPluginRoles: boolean = pluginRoles && !pluginRoles.isFirstChange();
    const changedRequiredRoles: boolean = requiredRoles && !requiredRoles.isFirstChange();
    if (changedPluginRoles || changedRequiredRoles) {
      if (changedPluginRoles) {
        this._changedPluginRoles();
      }
      if (changedRequiredRoles) {
        this._changedRequiredRoles();
      }
      this._evaluateErrors();
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionIdentity.unsubscribe();
    this._subscriptionConfig.unsubscribe();
  }

  public get errors(): ReadonlyArray<string> {
    return this._errors;
  }

  public get valid(): boolean {
    return !this.errors.length;
  }

  private _changedModuleMode(): void {
    this.moduleMode = isBoolean(this.moduleMode) ? this.moduleMode : false;
  }

  private _changedPluginRoles(): void {
    this._changedRolesInput(this.pluginRoles, this._pluginRoles);
  }

  private _changedRequiredRoles(): void {
    this._changedRolesInput(this.requiredRoles, this._requiredRoles);
  }

  private _changedRolesInput(inputValue: ROLE | Array<ROLE> | ReadonlyArray<ROLE>, roles: Set<ROLE>): void {
    roles.clear();

    if (!inputValue?.length) {
      return;
    }

    if (isString(inputValue)) {
      inputValue = [inputValue];
    }

    for (const role of inputValue) {
      if (IGNORED_ROLES.includes(role)) {
        continue;
      }
      roles.add(role);
    }
  }

  private _evaluateErrors(): void {
    this._errors = [];

    const rolesAccess: Array<ROLE> = this._rolesAccess.map((role: IJsonUserRole) => role.role);
    const rolesNotAccess: Array<ROLE> = this._rolesNotAccess.map((role: IJsonUserRole) => role.role);

    if (this._pluginRoles.size) {
      const captionRoles: Array<string> = Array.from(this._pluginRoles)
        .filter((role: ROLE) => !IGNORED_ROLES.includes(role) && (!rolesAccess.includes(role) || rolesNotAccess.includes(role)))
        .map((role: ROLE) => this._translateService.instant(`portals.items.${role.toLowerCase()}`));

      if (captionRoles.length >= 1) {
        let portals: string;
        if (captionRoles.length === 1) {
          portals = captionRoles[0];
        } else {
          const or: string = this._translateService.instant('global.text.or');
          portals = `${captionRoles.slice(0, captionRoles.length - 1).join(', ')} ${or} ${captionRoles[captionRoles.length - 1]}`;
        }

        const error =
          this._licencaModoCGOn && this._storeUrl ? 'blockedPlugin.text.noLicenseStoreMode' : captionRoles.length === 1 ? 'blockedPlugin.text.noLicenseSingle' : 'blockedPlugin.text.noLicenseMulti';

        this._errors.push(this._translateService.instant(error, {portals: portals, href: this._storeUrl}));
      }
    }

    if (this._requiredRoles.size) {
      const captionRoles: Array<string> = Array.from(
        new Set(
          Array.from(this._requiredRoles)
            .map((role: ROLE) => (!role.startsWith(API_PORTAL_PREFIX) ? role : this._portals.find((portal: IJsonPortal) => portal.url.toUpperCase() === role.toUpperCase())?.name))
            .filter((role: ROLE) => role && !IGNORED_ROLES.includes(role) && (!rolesAccess.includes(role) || rolesNotAccess.includes(role)))
        )
      ).map((role: ROLE) => this._translateService.instant(`portals.items.${role.toLowerCase()}`));

      if (captionRoles.length === 1) {
        this._errors.push(this._translateService.instant('blockedPlugin.text.requiredRolesSingle', {portals: captionRoles[0]}));
      } else if (captionRoles.length > 1) {
        const and: string = this._translateService.instant('global.text.and');
        const portals = `${captionRoles.slice(0, captionRoles.length - 1).join(', ')} ${and} ${captionRoles[captionRoles.length - 1]}`;
        this._errors.push(this._translateService.instant('blockedPlugin.text.requiredRolesMulti', {portals: portals}));
      }
    }
  }
}
