<div class="rhtipoprocess">
  <entity-detail
    entityName="rhtipoprocess"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [formOrientation]="formOrientation"
    [callback]="callback"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL, orientation: formOrientation, validators: {required: {value: true}}}">
        <pl-group>
          <label [translate]="'rhtipoprocess.fields.tipoProcessamento'"></label>
          <edit>
            <pl-edit type="integer" attrName="tipoProcessamento" [(model)]="model.tipoProcessamento" [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'rhtipoprocess.fields.descricao'"></label>
          <edit>
            <pl-edit type="text" attrName="descricao" [(model)]="model.descricao" [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'rhtipoprocess.fields.dadosVencimento'"></label>
            <edit>
              <pl-edit type="boolean" attrName="dadosVencimento" [(model)]="model.dadosVencimento"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'rhtipoprocess.fields.diuturnidades'"></label>
            <edit>
              <pl-edit type="boolean" attrName="diuturnidades" [(model)]="model.diuturnidades"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'rhtipoprocess.fields.dadosManuais'"></label>
            <edit>
              <pl-edit type="boolean" attrName="dadosManuais" [(model)]="model.dadosManuais"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'rhtipoprocess.fields.subsidioNatal'"></label>
            <edit>
              <pl-edit type="boolean" attrName="subsidioNatal" [(model)]="model.subsidioNatal"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'rhtipoprocess.fields.dadosFixos'"></label>
            <edit>
              <pl-edit type="boolean" attrName="dadosFixos" [(model)]="model.dadosFixos"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'rhtipoprocess.fields.subsidioFerias'"></label>
            <edit>
              <pl-edit type="boolean" attrName="subsidioFerias" [(model)]="model.subsidioFerias"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'rhtipoprocess.fields.subsidioRefeicao'"></label>
            <edit>
              <pl-edit type="boolean" attrName="subsidioRefeicao" [(model)]="model.subsidioRefeicao"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'rhtipoprocess.fields.valorCaducidadeCTR'"></label>
            <edit>
              <pl-edit type="boolean" attrName="valorCaducidadeCTR" [(model)]="model.valorCaducidadeCTR"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'rhtipoprocess.fields.tipoPeriodicidade'"></label>
            <edit>
              <pl-edit type="tiposPeriocidade" attrName="tipoPeriodicidade" [(model)]="model.tipoPeriodicidade" [properties]="{validators: {required: {value: true}}}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'rhtipoprocess.fields.desativado'"></label>
            <edit>
              <pl-edit type="boolean" attrName="desativado" [(model)]="model.desativado"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="showFunc">
          <label [translate]="'rhtipoprocess.fields.func'"></label>
          <edit>
            <entity-autocomplete
              entity="funcoes"
              attrName="func"
              [model]="model"
              (selectedKeyChange)="model.funcao = $event"
              (evtSelectedDescriptionChanged)="model.nomeFuncao = $event"
              [fieldsMap]="{nFuncao: 'funcao', designaBreve: 'nomeFuncao'}">
            </entity-autocomplete>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
