import {ETipoEscolhaRendHabitPerm, IJsonRendHabitPerm} from './jsonRendHabitPerm.module.interface';
import {TranslateService} from '@ngx-translate/core';

export interface IRendHabitPerm extends IJsonRendHabitPerm {
  _empDesc: string;
  _abonoDesc: string;
}

export interface ITipoEscolhaRendHabitPerm {
  value: ETipoEscolhaRendHabitPerm;
  label: string;
}

export function dataSourceTipoEscolhaRendHabitPerm(translate: TranslateService): ReadonlyArray<ITipoEscolhaRendHabitPerm> {
  return [
    {value: ETipoEscolhaRendHabitPerm.Ativas, label: translate.instant('utlviatpatronal.tipoUtlViatPatronal.ativas')},
    {value: ETipoEscolhaRendHabitPerm.Inativas, label: translate.instant('utlviatpatronal.tipoUtlViatPatronal.inativas')},
    {value: ETipoEscolhaRendHabitPerm.Todas, label: translate.instant('utlviatpatronal.tipoUtlViatPatronal.todas')}
  ];
}

export const MODULE_NAME_HABITACAO_PERMANENTE = 'rendhabitperm';
