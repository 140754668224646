<div class="solic">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
  </entity-detail>
</div>
