import {Component, Injector, Input, OnInit} from '@angular/core';
import {IPlFormDefinition, IPlFormTemplate, isEmptyObject, isObject} from 'pl-comps-angular';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {ETipoContabilidade} from '../../../../datasources/tipocontabilidade/tipoContabilidade.datasource.interface';
import {ETipoEmpresa, IJsonEmpresaErpBasic} from '../../../../interfaces/jsonEmpresa.interface';
import {IJsonEntidadeExterna} from '../../jsonEntidadeExterna.entity.interface';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';

@Component({
  selector: 'entidadeexterna-edit',
  templateUrl: './entidadeExterna.entity.edit.component.html'
})
export class EntidadeExternaEditComponent extends ModuloEntityDetailComponent<IJsonEntidadeExterna> implements OnInit {
  @Input() public empresasGabinete: Array<IJsonEmpresaErpBasic>;

  public formDefinition: IPlFormDefinition;
  public formTemplate: IPlFormTemplate;
  public entityState: typeof EEntityStateDetailType;
  public empresaGabineteView: string;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.entityState = EEntityStateDetailType;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.formTemplate = <IPlFormTemplate>this.states.detail.data.formTemplate;
    this.formDefinition = this.entity[this.type].definition;
    if (isEmptyObject(this.model)) {
      this.model = {
        entidadeExternaID: '',
        codigo: '',
        nome: '',
        morada: '',
        morada2: '',
        nContribuinte: '',
        contacto: '',
        email: '',
        tipoEmpresa: ETipoEmpresa.None,
        tipoContabilidade: ETipoContabilidade.None,
        nEmpresaGabinete: '',
        periodoIVA: undefined,
        regimeIRC: undefined
      };
    }
    if (this.type !== EEntityStateDetailType.NEW) {
      const index = this.empresasGabinete.findIndex((value: IJsonEmpresaErpBasic) => value.nempresa === this.model.nEmpresaGabinete);
      if (index !== -1) {
        this.empresaGabineteView = this.empresasGabinete[index].nome;
      }
    }
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    return super.onUpdate(stateType).then(() => {
      this.formDefinition = this.entity[stateType].definition;
      if (stateType !== EEntityStateDetailType.NEW) {
        const index = this.empresasGabinete.findIndex((value: IJsonEmpresaErpBasic) => value.nempresa === this.model.nEmpresaGabinete);
        if (index !== -1) {
          this.empresaGabineteView = this.empresasGabinete[index].nome;
        }
      }
    });
  }

  public evtEmpresasGabineteChanged(value: {inputValue: string; item: IJsonEmpresaErpBasic}): void {
    if (isObject(value.item)) {
      this.empresaGabineteView = value.item.nome;
      this.model.nEmpresaGabinete = value.item.nempresa;
    }
  }
}
