<div class="modal-empresas-periocontabfirstmonth entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'empresas.modal.configanocivildiftitle'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <pl-group>
        <label [translate]="'empresas.fields.primeiroMesContab'"></label>
        <edit>
          <pl-edit type="meses" [model]="perioMudaPrimeiroMesContab.primeiroMesContab" (modelChange)="mesChanged($event)"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label></label>
        <edit>
          <pl-edit type="checkbox" [(model)]="perioMudaPrimeiroMesContab.seMesCivilDifPerioComecaAnoSeg" [properties]="{label: 'empresas.fields.seMesCivilDifPerioComecaAnoSeg'}"></pl-edit>
        </edit>
      </pl-group>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-sm btn-primary action-dismiss" [disabled]="closeDisabled" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn-sm btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()">
      <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span>
    </pl-button>
  </div>
</div>
