import {AfterViewChecked, Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {IJsonGestaoComunicacoesNewMessages} from '../../../jsonGestaoComunicacoesTopico.module.interface';
import {IJsonGestaoComunicacoesMensagem} from '../../../jsonGestaoComunicacoesMensagem.module.interface';
import {GESTAO_COMUNICACAO_COLOR_PERCENT, IGestaoComunicacoesEstadoDados, IGestaoComunicacoesTopico, lightenColor} from '../../../gestaoComunicacoes.module.interface';
import {GestaoComunicacoesTopicoService} from '../../../services/gestaoComunicacoesTopico.module.service';
import moment from 'moment';
import {HttpResponse} from '@angular/common/http';
import {CGGestaoComunincacaoMessageContainerComponent} from '../../../../../../components/gestaocomunicacoes/messagesContainer/messagesContainer.component';
import {CGGestaoComunincacaoMessageInputComponent} from '../../../../../../components/gestaocomunicacoes/messageInput/messageInput.component';
import {PlTranslateService} from 'pl-comps-angular';

const INTERVAL_TIMEOUT = 5000; // 5 seconds

@Component({
  selector: 'gestao-comunicacoes-topico-detail-modal',
  templateUrl: './gestaoComunicacoes.topico.detail.modal.component.html'
})
export class GestaoComunicacoesTopicoDetailModalComponent extends CGModalComponent<void> implements OnInit, OnDestroy, AfterViewChecked {
  @Input() public topico: IGestaoComunicacoesTopico;
  @Input() public estadoDados: IGestaoComunicacoesEstadoDados;
  @Input() public mensagens: Array<IJsonGestaoComunicacoesMensagem>;
  @Input() public contabilista: boolean;

  @ViewChild('containerMessages') public containerMessages: CGGestaoComunincacaoMessageContainerComponent;
  @ViewChild('inputMessage') public inputMessage: CGGestaoComunincacaoMessageInputComponent;
  public message: string;
  public categoryBackgroundColor: string;

  private _selectedFile: File;
  private _loadedFirstTime: boolean;
  private _intervalId: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _gestaoComunicacoesTopicoService: GestaoComunicacoesTopicoService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this._selectedFile = undefined;
    this._loadedFirstTime = false;
  }

  public async ngOnInit(): Promise<void> {
    this.topico._moveToTop = false;
    this.categoryBackgroundColor = lightenColor(this.topico.categoriaCor, GESTAO_COMUNICACAO_COLOR_PERCENT);
    await this._getMessagesGroupAndScroll(this.topico.comTopicoId, false);

    // start get new messages interval
    this._intervalId = setInterval(async () => {
      const nNewMessages: number = await this._gestaoComunicacoesTopicoService
        .getHasNewMessages(this.topico.comTopicoId, true)
        .then((response: HttpResponse<IJsonGestaoComunicacoesNewMessages>) => response.body.nMessages);
      if (nNewMessages > 0) {
        await this._getMessagesGroupAndScroll(this.topico.comTopicoId, true);
      }
    }, INTERVAL_TIMEOUT);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this._intervalId) {
      clearInterval(this._intervalId);
    }
  }

  public ngAfterViewChecked(): void {
    if (!this._loadedFirstTime) {
      this.containerMessages.scrollToBottom();
      this.inputMessage.focusMessageInput();
      this._loadedFirstTime = true;
    }
  }

  public close(): void {
    super.close();
  }

  public async evtSubmitFile(file: File): Promise<void> {
    await this._addFile(file);
  }

  public async evtSubmitMessage(message: string): Promise<void> {
    this.message = message;
    await this._onClickSendMessage();
  }

  private async _getMessagesGroupAndScroll(topicoId: string, fetchTopicoMessages: boolean): Promise<void> {
    if (fetchTopicoMessages) {
      this.mensagens = await this._gestaoComunicacoesTopicoService.getTopicoMessages(topicoId, true).then((response: HttpResponse<Array<IJsonGestaoComunicacoesMensagem>>) => response.body.reverse());
    }
    if (this.containerMessages) {
      this.containerMessages.groupMessages();
      if (this.inputMessage) {
        this.inputMessage.focusMessageInput();
      }
    }
  }

  private async _addFile(file: File): Promise<void> {
    this._selectedFile = file;
    const documentoCreated: IJsonGestaoComunicacoesMensagem = await this._gestaoComunicacoesTopicoService
      .createTopicoMessageFile(this.topico.comTopicoId, this._selectedFile, true)
      .then((response: HttpResponse<IJsonGestaoComunicacoesMensagem>) => response.body);

    const newMessage: IJsonGestaoComunicacoesMensagem = {
      comentarioId: undefined,
      comTopicoId: this.topico.comTopicoId,
      docId: documentoCreated.docId,
      fileName: this._selectedFile.name,
      isMessage: false,
      isMyMessage: true,
      isRead: true,
      message: undefined,
      nUtilizador: documentoCreated.nUtilizador,
      stampCreated: moment(),
      tamanho: this._selectedFile.size,
      mimeType: documentoCreated.mimeType,
      folder: documentoCreated.folder
    };
    this.containerMessages.readAndAddNewMessage(newMessage);
    this.inputMessage.focusMessageInput();
    this._selectedFile = undefined;

    this.topico.ultimaMensagem = this._plTranslateService.translate('gestaoComunicacoes.common.ficheiroEnviado');
    this.topico.stampUltimaMensagem = moment();
    this.topico._moveToTop = true;
  }

  private async _onClickSendMessage(): Promise<void> {
    const messageObj: IJsonGestaoComunicacoesMensagem = {
      comTopicoId: this.topico.comTopicoId,
      message: this.message,
      comentarioId: undefined,
      nUtilizador: undefined,
      stampCreated: moment(),
      isMyMessage: true,
      isMessage: true,
      isRead: true,
      tamanho: undefined,
      fileName: undefined,
      docId: undefined,
      mimeType: undefined,
      folder: undefined
    };
    await this._gestaoComunicacoesTopicoService.createTopicoMessage(this.topico.comTopicoId, messageObj, true);
    this.containerMessages.readAndAddNewMessage(messageObj);
    this.inputMessage.focusMessageInput();
    this.message = '';

    this.topico.ultimaMensagem = messageObj.message;
    this.topico.stampUltimaMensagem = moment();
    this.topico._moveToTop = true;
  }
}
