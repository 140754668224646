<div class="cargveic">
  <entity-detail
    entityName="cargveic"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL, orientation: formOrientation}">
        <div class="d-flex gap-4">
          <div style="max-width: 150px">
            <pl-group>
              <label [translate]="'cargveic.fields.matricula'"></label>
              <edit>
                <pl-edit-text
                  attrName="matricula"
                  [(model)]="model.matricula"
                  [properties]="{disabled: type !== stateTypes.NEW, validators: {required: {value: true}, maxlength: {value: 30}}}"
                  plAutoFocus></pl-edit-text>
              </edit>
            </pl-group>
          </div>
          <div>
            <pl-group>
              <label [translate]="'cargveic.fields.nomeViatura'"></label>
              <edit>
                <pl-edit type="text" attrName="nomeViatura" [(model)]="model.nomeViatura" [properties]="{validators: {maxlength: {value: 20}}}"></pl-edit>
              </edit>
            </pl-group>
          </div>
          <div>
            <pl-group>
              <label>&nbsp;</label>
              <edit>
                <pl-edit type="checkbox" attrName="desativado" [(model)]="model.desativado" [properties]="{disabled: type === stateTypes.DETAIL, label: 'cargveic.fields.desativado'}"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>
      </pl-form>
    </div>
  </entity-detail>
</div>
