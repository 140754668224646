import {merge} from 'lodash-es';
import {Subscription} from 'rxjs';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {isArray, isFunction, isString, isUndefinedOrNull, paginate, PlAlertService, PlDocumentService, PlTranslateService} from 'pl-comps-angular';
import {CGExceptionService} from '../../exceptions/exceptions.service';
import {CGModalService} from '../../cg/modal/cgmodal.service';
import {ConfigService} from '../../../services/config/config.service';
import {docComercialDefault} from '../../../entities/docscomerciais/service/documentos.entity.service.interface';
import {DocumentoRastreabilidadeModalComponent} from '../../../entities/docscomerciais/modals/rastreabilidade/documento.rastreabilidade.modal.component';
import {DocumentoRastreabilidadeService} from '../../../entities/docscomerciais/modals/rastreabilidade/documento.rastreabilidade.service';
import {EConfigOptionsInstanceName} from '../../../services/config/options/config.options.service.interface';
import {EGroupName, EStatusCode} from '../../../../config/constants';
import {EGrupoDoc} from '../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {ENTITY_NAME_ARTIGOS} from '../../../entities/artigos/artigos.entity.interface';
import {EntityMaintenanceService} from '../../entity/maintenance/entity/entity.maintenance.service';
import {
  IDocumentoFaturacao,
  TDocumentoFacturacaoPdfUrlFn,
  TDocumentoFaturacaoActionFn,
  TDocumentoFaturacaoActionIdResultFn,
  TDocumentoFaturacaoActionResponseOrResultFn,
  TDocumentoFaturacaoActionResultFn
} from './documento.facturacao.component.interface';
import {IEntityMaintenanceInstance} from '../../entity/maintenance/entity/entity.maintenance.interface';
import {
  EFACCBHASHEstado,
  IJsonDocComercial,
  IJsonDocComercialCab,
  IJsonDocComercialClifo,
  IJsonDocComercialLinha,
  IJsonDocResumoIvaItem
} from '../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {IJsonRastreabilidadeFaturacao} from '../../../entities/docscomerciais/modals/rastreabilidade/jsonRastreabilidade.interface';
import {SelecaoloteslinhaModalComponent} from '../../../entities/docscomerciais/modals/selecaolotes/linha/selecaoLotes.linha.modal.component';
import {ConfigSiteService} from '../../../services/configsite.service';
import {CG_DEFAULT_AT_NIF, ICGConfigurations} from '../../../services/config/config.service.interface';
import {ROLE} from '../../../services/role.const';
import {AuthService} from '../../../services/auth/auth.service';
import {AdiantamentosRegistarModalComponent} from '../../../modules/adiantamentos/modals/registar/adiantamentos.registar.modal.component';
import {
  IAdiantamentosParams,
  IAdiantamentosRegistoResult,
  MODULE_NAME_ADIANTAMENTOS_CLIENTES,
  MODULE_NAME_ADIANTAMENTOS_FORNECEDORES
} from '../../../modules/adiantamentos/adiantamentos.module.interface';
import {ModuleMaintenanceService} from '../../entity/maintenance/module/module.maintenance.service';
import {IJsonAdiantamentoConfig} from '../../../modules/adiantamentos/jsonAdiantamentos.module.interface';
import {AdiantamentosService} from '../../../modules/adiantamentos/adiantamentos.module.service';
import {ENTITY_NAME_CLIENTES, TClifos} from '../../../entities/clifos/clifos.entity.interface';
import {ENTITY_DOCS_COMERCIAIS_COMPRAS, ENTITY_DOCS_COMERCIAIS_VENDAS} from '../../../entities/docscomerciais/docsComerciais.entity';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IJsonDocfa} from '../../../entities/docfas/jsonDocFa.entity.interface';
import {ENTITY_NAME_DOC_FAS} from '../../../entities/docfas/docFas.entity.interface';
import {
  EDocsComerciaisClifoStatus,
  IDocComercialDocCabFieldsVisibilityOptionsListView,
  IDocComercialPrestacaoItem,
  IDocsComerciaisEntity,
  IDocsComerciaisEntityService
} from '../../../entities/docscomerciais/docsComerciais.entity.interface';
import {EEntityStateDetailType} from '../../../../common/utils/entity.state.utils';
import {CGStateService} from '../../state/cg.state.service';
import {IJsonDocfaNum} from '../../../entities/docfasnums/jsonDocfaNum.entity.interface';
import {GrupoDocService} from '../../../services/grupodoc/grupo.doc.service';
import {Pagination} from '../../../../common/pagination/pagination';
import {IJsonMoeda} from '../../../modules/portalcontabilidade/manutencao/moeda/jsonMoeda.interface';
import {EDocTipoEntity, IJsonGDFolder} from '../../../entities/gdoc/JsonGDoc.entity.interface';
import {EFolderDoc, ENTITY_NAME_GDOC, IGDocEntityService} from '../../../entities/gdoc/gdoc.entity.interface';
import {IGDocsCallback} from '../../gdocs/gdocs.component.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE} from '../../../modules/portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {IDevExpressDataGrid} from '../../devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';

const MINIMUM_PER_PAGE = 25;
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const DEFAULT_ALLOWED_PAGE_SIZES = Object.freeze([MINIMUM_PER_PAGE, 50, 75, 100]);
const DEFAULT_PER_PAGE = DEFAULT_ALLOWED_PAGE_SIZES[0];

@Component({
  selector: 'documento-faturacao',
  templateUrl: './documento.facturacao.component.html'
})
export class DocumentoFaturacaoComponent implements OnChanges, OnDestroy, OnInit {
  @Input() public doc: IDocumentoFaturacao;
  @Input() public entityNameClifos: TClifos;
  @Input() public grupoDoc: EGrupoDoc;
  @Input() public showGDocAnexos: boolean;
  @Input() public actionPdf: TDocumentoFaturacaoActionFn;
  @Input() public actionPdfUrl: string | TDocumentoFacturacaoPdfUrlFn;
  @Input() public actionAnexarGDocComercial: TDocumentoFaturacaoActionResultFn;
  @Input() public actionComunicarDocWebServiceAT: TDocumentoFaturacaoActionResultFn;
  @Input() public actionComunicarDocCirculacaoAT: TDocumentoFaturacaoActionResultFn;
  @Input() public actionInserirCodTranspAT: TDocumentoFaturacaoActionResultFn;
  @Input() public actionAnularDoc: TDocumentoFaturacaoActionResultFn;
  @Input() public actionNotaCreditoDoc: TDocumentoFaturacaoActionResponseOrResultFn;
  @Input() public actionGetDoc: TDocumentoFaturacaoActionIdResultFn;
  @Input() public actionTransformarLinhasDoc: TDocumentoFaturacaoActionResultFn;
  @Input() public actionDesmarcarDocTransformado: TDocumentoFaturacaoActionResultFn;
  @Input() public actionDuplicarDoc: TDocumentoFaturacaoActionResultFn;
  @Input() public actionDuplicarParaNovoDoc: TDocumentoFaturacaoActionFn;
  @Input() public actionCopiarLinhas: TDocumentoFaturacaoActionResultFn;
  @Input() public actionMeiosPagamentoComercial: TDocumentoFaturacaoActionResultFn;
  @Input() public actionEditarDocumento: TDocumentoFaturacaoActionFn;
  @Input() public actionEncerrarDocumento: TDocumentoFaturacaoActionResultFn;
  @Input() public actionReAbreDocumento: TDocumentoFaturacaoActionFn;
  @Input() public docCabFieldsVisibilityOptions: IDocComercialDocCabFieldsVisibilityOptionsListView;
  @Output() public readonly docChange: EventEmitter<IJsonDocComercial>;
  @Output() public readonly evtRefresh: EventEmitter<void>;

  public readonly gruposDocFa: typeof EGrupoDoc;
  public readonly showGDocs: boolean;
  public readonly codMoedaEmpresa: number;
  public readonly abreviaturaMoedaEmpresa: string;
  public readonly paginationLines: Array<number>;
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly cgDefaultAtNif: string;
  public readonly mainTabCargaId: string = 'mainTabCarga';
  public readonly mainTabFaturacaoPublicaId: string = 'mainTabFaturacaoPublica';
  public readonly mainTabLinhasId: string = 'mainTabFaturacaoLinhas';
  public readonly mainTabAnexosId: string = 'mainTabAnexos';
  public readonly mainTabPrestacoesId: string = 'mainTabPrestacoes';
  public readonly paginationLinhas: Pagination;
  public readonly paginationMinimumPerPage: number;
  public readonly gDocTipoDocEntity: EDocTipoEntity;
  public readonly callbackGDocs: IGDocsCallback;
  public readonly faccbHASHEstado: typeof EFACCBHASHEstado;
  public readonly dataGridPrestacoesDefinition: IDevExpressDataGrid<IDocComercialPrestacaoItem, number>;
  public readonly prestacoesTableSource: Array<IDocComercialPrestacaoItem>;

  public mobile: boolean;
  public abreviaturaMoedaCliente: string;
  public hintBtnAnular: string;
  public paginatedLines: IJsonDocComercial['linhas'];
  public pagination: boolean;
  public paginationPage: number;
  public paginationPerPage: number;
  public paginationTotal: number;
  public arredManualString: string;
  public comercialAdvanced: boolean;
  public licencaStoreModePublic: boolean;
  public portalName: string;
  public cgStoreUrlBackOffice: string;
  public showDataEntregaPrev: boolean;
  public showTransformarLinhasDoc: boolean;
  public clifoDescription: string;
  public isEntradaDiversa: boolean;
  public isSaidaDiversa: boolean;
  public isTipoDocStocks: boolean;
  public nContaNifSituacaoMsg: string;
  public activeMainTab: string;
  public mainTabCargaEnabled: boolean;
  public docFa: Partial<IJsonDocfa>;
  public moeda: IJsonMoeda;
  public gDocFolderId: string;
  public podeAnularDoc: boolean;
  public hasComercial: boolean;
  public hasContabilidade: boolean;
  public isComprasEfectivas: boolean;
  public isVendasEfectivas: boolean;

  private readonly _defaultDoc: IDocumentoFaturacao;
  private readonly _entityMaintenanceInstanceArtigos: IEntityMaintenanceInstance;
  private readonly _subscriptionIsMobile: Subscription;
  private readonly _docsComerciaisService: IDocsComerciaisEntityService;
  private readonly _maintenanceInstanceDocContab: IEntityMaintenanceInstance;
  private _gDocService: IGDocEntityService;

  private _adiantamentoConfig: IJsonAdiantamentoConfig;

  constructor(
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plDocumentService: PlDocumentService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _configService: ConfigService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _documentoRastreabilidadeService: DocumentoRastreabilidadeService,
    private readonly _cgModalService: CGModalService,
    private readonly _authService: AuthService,
    private readonly _configSiteService: ConfigSiteService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _adiantamentosService: AdiantamentosService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgStateService: CGStateService,
    private readonly _grupoDoc: GrupoDocService
  ) {
    this._docsComerciaisService = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>('docscomerciais');
    this._maintenanceInstanceDocContab = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    this.mainTabCargaEnabled = false;
    this.faccbHASHEstado = EFACCBHASHEstado;
    this.paginationMinimumPerPage = MINIMUM_PER_PAGE;
    this.paginationLinhas = new Pagination({allowedPageSizes: DEFAULT_ALLOWED_PAGE_SIZES, perPage: DEFAULT_PER_PAGE});
    this.cgDefaultAtNif = CG_DEFAULT_AT_NIF;
    this.configOptionsInstanceName = EConfigOptionsInstanceName.DOCS_COMERCIAIS_EDIT;
    this.configOptionsGroupName = EGroupName.ERP;
    this.docChange = new EventEmitter<IDocumentoFaturacao>();
    this.evtRefresh = new EventEmitter<void>();
    this.gruposDocFa = EGrupoDoc;
    this.showGDocs = this._configService.configurations.empresa.temContabilidadeDigital || this._configService.configurations.acessos.erpcloud.gdocs;
    this.codMoedaEmpresa = this._configService.configurations.empresa.codMoeda;
    this.abreviaturaMoedaEmpresa = this._configService.configurations.empresa.abreviaturaMoeda;
    this.activeMainTab = this.mainTabLinhasId;

    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    this.paginationLines = [50, 100, 200, 500];
    this.paginatedLines = [];
    this.pagination = false;
    this.paginationPerPage = this.paginationLines[0];
    this._defaultDoc = docComercialDefault();
    this._entityMaintenanceInstanceArtigos = this._entityMaintenanceService.build(ENTITY_NAME_ARTIGOS);
    this._subscriptionIsMobile = this._plDocumentService.isMobile().subscribe((isMobile: boolean) => {
      this.mobile = isMobile;
    });

    this.comercialAdvanced = false;
    this.licencaStoreModePublic = false;
    this.cgStoreUrlBackOffice = '';

    this.portalName = this._translateService.instant('portals.items.erp')?.toLowerCase();

    this._authService.hasAuthority(ROLE.ERPADVANCED).then((response) => {
      this.comercialAdvanced = response;
    });

    this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      this.licencaStoreModePublic = configurations.licenca.modoCGOn;
    });

    this._configSiteService.cgStoreUrlBackOffice().then((url) => {
      this.cgStoreUrlBackOffice = url;
    });

    this.gDocTipoDocEntity = EDocTipoEntity.GestaoComercial;
    this.showGDocAnexos = true;
    this.callbackGDocs = {};

    this._authService.hasAuthority(ROLE.ERP).then((response) => {
      this.hasComercial = response;
    });

    this._authService.hasAuthority(ROLE.CONTABILIDADE).then((response) => {
      this.hasContabilidade = response;
    });

    this.prestacoesTableSource = [];
    this.dataGridPrestacoesDefinition = {
      columnHidingEnabled: false,
      columns: [
        {
          dataField: 'nSeq',
          dataType: 'number',
          caption: 'docscomerciais.prestacoes.fields.nSeq',
          allowEditing: false
        },
        {
          dataField: 'dataPrestacao',
          dataType: 'date',
          caption: 'docscomerciais.prestacoes.fields.data',
          allowEditing: false
        },
        {
          dataField: 'valorPrestacao',
          dataType: 'number',
          caption: 'docscomerciais.prestacoes.fields.valor',
          allowEditing: false
        }
      ],
      dataSource: new CustomStore({
        key: 'nSeq',
        load: () => this.prestacoesTableSource
      }),
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    if (!this.docCabFieldsVisibilityOptions) {
      this.docCabFieldsVisibilityOptions = {
        showNRefProcesso: false,
        showDataVenc: false,
        showMoeda: false,
        showNRequisicao: false,
        showDataDocExterno: false,
        showDocExterno: false,
        showObservacoes: true,
        showCCusto: false,
        showNArmazem: false,
        showValorCIva: false,
        showLiquido: false,
        showCodUnidMov: false,
        showNVendedor: false,
        showD2: false,
        showD3: false,
        showDescEmValor: false,
        showPrestacoes: false
      };
    }

    if (this.showGDocAnexos) {
      this._gDocService = this._entityServiceBuilder.build<IJsonGDFolder, IGDocEntityService>(ENTITY_NAME_GDOC);
    }

    this.showDataEntregaPrev = this.doc.cab.grupoDocfa === EGrupoDoc.EncomendasClientes || this.doc.cab.grupoDocfa === EGrupoDoc.EncomendasFornecedores;
    this.showTransformarLinhasDoc = true;
    this.clifoDescription = this._plTranslateService.translate('docscomerciais.doc.clifo.nContribuint');
    this.isEntradaDiversa = this._grupoDoc.isEntradaDiversa(this.grupoDoc);
    this.isSaidaDiversa = this._grupoDoc.isSaidaDiversa(this.grupoDoc);
    this.isTipoDocStocks = this.isEntradaDiversa || this.isSaidaDiversa;
    this.activeMainTab = this.mainTabLinhasId;
    this.showDataEntregaPrev = this.grupoDoc === EGrupoDoc.EncomendasClientes || this.grupoDoc === EGrupoDoc.EncomendasFornecedores;
    this.doc.identificaCarga = false;
    this._initCabecalhoOnChange(this.doc, this.doc.cab);
    this.podeAnularDoc = !this.doc.cab.anulado; // && (!this.doc.cab.porPagar || round(this.doc.cab.porPagar, 2) === round(this.doc.cab.totalDocumentoME, 2));
    this.mainTabCargaEnabled = this.doc.identificaCarga;
    this.abreviaturaMoedaCliente = this.doc.cab.abreviaturaMoeda;
    this.doc.clifo.action = this._evaluateClifoAction(this.doc.clifo);
    this.isComprasEfectivas = this.grupoDoc === EGrupoDoc.ComprasEfectivas;
    this.isVendasEfectivas = this.grupoDoc === EGrupoDoc.VendasEfectivas;
  }

  public ngOnChanges({doc}: SimpleChanges): void {
    if (doc) {
      this._changedDoc(doc.currentValue);
      this._initCabecalhoOnChange(this.doc, this.doc.cab);
      this.mainTabCargaEnabled = this.doc.identificaCarga;
      this.abreviaturaMoedaCliente = this.doc.cab.abreviaturaMoeda;
      this.arredManualString = this._setArredManualInfo(this.doc);
      this.doc._downloadUrl = `${this.doc.cab.docNome}-${this.doc.cab.nDoc}.pdf`;
      this.hintBtnAnular = this.doc.cab.comunicadoAoEFatura ? this._translateService.instant('docscomerciais.info.comunicadoAoEFatura') : '';
      this._setDocPdfUrl(this.doc);
      this.paginationPage = 1;
      this.paginationTotal = isArray(this.doc.linhas) ? this.doc.linhas.length : 0;
      this.paginate();
      this.doc.cab.faccbPrestacoesList?.forEach((item, index) => {
        this.prestacoesTableSource.push({...item, nSeq: index + 1});
      });
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionIsMobile.unsubscribe();
  }

  public paginate(): void {
    if (isArray(this.doc.linhas)) {
      if (this.doc.linhas.length > this.paginationLines[0]) {
        this.pagination = true;
        this.paginatedLines = paginate(this.doc.linhas, this.paginationPage, this.paginationPerPage);
      } else {
        this.pagination = false;
        this.paginatedLines = this.doc.linhas;
      }
    } else {
      this.pagination = false;
      this.paginatedLines = [];
    }
  }

  public pdf(doc: IDocumentoFaturacao, refresh: boolean): void {
    if (isFunction(this.actionPdf)) {
      doc = doc || this.doc;
      Promise.resolve(this.actionPdf(doc)).then(() => {
        if (doc.cab.isDocTransporteSAFT && (doc.cab.nDocumento === 0 || doc.cab.atDocCodeID === '')) {
          if (isFunction(this.actionGetDoc)) {
            this.actionGetDoc(doc.cab.faccbId).then((response: IDocumentoFaturacao) => {
              if (refresh && response) {
                this._setDoc(response);
              }
            });
          }
        }
      });
    }
  }

  public editarDocumento(): Promise<void> | void {
    if (!isFunction(this.actionEditarDocumento)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionEditarDocumento(this.doc)).catch((error: unknown) => {
      this._handleError(error, 'docscomerciais.erros.editarDocumento');
    });
  }

  public anexarGDocComercial(): Promise<void> | void {
    if (!isFunction(this.actionAnexarGDocComercial)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionAnexarGDocComercial(this.doc)).then((response) => {
      if (response) {
        this._setDoc(response);
        this.callbackGDocs.refreshSelectedFolder(this.gDocFolderId);
      }
    });
  }

  public showMeiosPagamento(): Promise<void> | void {
    if (!isFunction(this.actionMeiosPagamentoComercial)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionMeiosPagamentoComercial(this.doc))
      .then((response) => {
        if (response) {
          this._setDoc(response);
        }
      })
      .catch((error: unknown) => {
        this._handleError(error, 'docscomerciais.erros.meiosPagam');
      });
  }

  public comunicarDocWebServiceAT(): Promise<void> | void {
    if (!isFunction(this.actionComunicarDocWebServiceAT)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionComunicarDocWebServiceAT(this.doc))
      .then((response) => {
        this._setDoc(response);
        this._plAlertService.success('docscomerciais.info.comunicacaoFaturaAT');
      })
      .catch((error: unknown) => {
        this._handleError(error, 'docscomerciais.erros.comunicacaoAT');
      });
  }

  public comunicarDocCirculacaoAT(): Promise<void> | void {
    if (!isFunction(this.actionComunicarDocCirculacaoAT)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionComunicarDocCirculacaoAT(this.doc))
      .then((response) => {
        this._setDoc(response);
        if ((isUndefinedOrNull(this.doc.cab.returnCode) && this.doc.cab.returnMessage === 'OK' && this.doc.cab.atDocCodeID !== ' ') || this.doc.cab.returnCode === 0) {
          this._plAlertService.success('docscomerciais.info.comunicacaoAT');
        } else {
          this._plAlertService.error(this.doc.cab.returnMessage);
        }
      })
      .catch((error: unknown) => {
        this._handleError(error, 'docscomerciais.erros.comunicacaoAT');
      });
  }

  public inserirCodigoTransporteAT(): Promise<void> {
    if (!isFunction(this.actionInserirCodTranspAT)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionInserirCodTranspAT(this.doc))
      .then((response) => {
        this._setDoc(response);
        this._refreshList();
        this._plAlertService.success('docscomerciais.info.inserirCodComunicacaoAT');
      })
      .catch((error: unknown) => {
        if (error) {
          this._handleError(error, 'docscomerciais.erros.inserirCodComunicacaoAT');
        }
      });
  }

  public anularDocumento(): Promise<void> {
    if (!isFunction(this.actionAnularDoc)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionAnularDoc(this.doc)).then((response) => {
      if (response) {
        this._setDoc(response);
        this._refreshList();
        this._plAlertService.success('docscomerciais.info.anularDocumento');
      }
    });
  }

  public duplicarDocumento(): Promise<void> {
    if (!isFunction(this.actionDuplicarDoc)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionDuplicarDoc(this.doc))
      .then((response) => {
        this._setDoc(response);
        this._refreshList();
        this._plAlertService.success('docscomerciais.info.duplicarDocumento');
      })
      .catch((error: unknown) => {
        this._handleError(error, 'docscomerciais.erros.duplicarDocumento');
      });
  }

  public copiarLinhas(): Promise<void> {
    if (!isFunction(this.actionCopiarLinhas)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionCopiarLinhas(this.doc))
      .then((response) => {
        this._setDoc(response);
        this._refreshList();
        this._plAlertService.success('docscomerciais.info.copiarLinhas');
      })
      .catch((error: unknown) => {
        this._handleError(error, 'docscomerciais.erros.copiarLinhas');
      });
  }

  public duplicarDocumentoEditavel(): Promise<void> {
    if (!isFunction(this.actionDuplicarParaNovoDoc)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionDuplicarParaNovoDoc(this.doc)).catch((error: unknown) => {
      this._handleError(error, 'docscomerciais.erros.duplicarDocumento');
    });
  }

  public notaCreditaDocumento(): Promise<void> {
    if (!isFunction(this.actionNotaCreditoDoc)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionNotaCreditoDoc(this.doc)).then((response: IJsonDocComercial | HttpResponse<IJsonDocComercial>) => {
      let msgSucesso = false;
      let msgErro = '';
      let docComercial: IJsonDocComercial;
      if (response instanceof HttpResponse) {
        if (response.status !== EStatusCode.OK) {
          switch (response.status) {
            case EStatusCode.InternalServerError:
              const exception = this._cgExceptionService.get(response);
              msgErro = exception ? exception.message : this._translateService.instant('docscomerciais.erros.notaCreditoErro');
              break;
            default:
              this._refreshList();
              msgErro = '';
              break;
          }
        } else if (response.body) {
          docComercial = response.body;
          msgSucesso = true;
        } else {
          msgSucesso = true;
        }
      } else {
        docComercial = response;
        msgSucesso = true;
      }
      if (msgSucesso) {
        this._refreshList();
        if (docComercial) {
          this.pdf(docComercial, false);
        }
        this._plAlertService.success('docscomerciais.info.notaCreditoSucesso');
      } else if (msgErro) {
        this._plAlertService.error(msgErro);
      }
      return Promise.resolve();
    });
  }

  public reAbrirDocumento(): Promise<void> {
    if (!isFunction(this.actionReAbreDocumento)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionReAbreDocumento(this.doc));
  }

  public transformarLinhasDocumento(): Promise<void> {
    if (!isFunction(this.actionTransformarLinhasDoc)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionTransformarLinhasDoc(this.doc)).then((response) => {
      if (response) {
        this._setDoc(response);
        this.pdf(response, false);
      }
      this._refreshList();
    });
  }

  public desmarcarDocTransformado(): Promise<void> {
    if (!isFunction(this.actionDesmarcarDocTransformado)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionDesmarcarDocTransformado(this.doc))
      .then((response) => {
        this._setDoc(response);
        this._refreshList();
        this._plAlertService.success('docscomerciais.info.desmarcadoDocTransformado');
      })
      .catch((error: unknown) => {
        this._handleError(error, 'docscomerciais.erros.desmarcadoDocTransformado');
      });
  }

  public encerrarDocumento(): Promise<void> {
    if (!isFunction(this.actionEncerrarDocumento)) {
      return Promise.resolve();
    }
    return Promise.resolve(this.actionEncerrarDocumento(this.doc))
      .then((response) => {
        this._setDoc(response);
        this._refreshList();
      })
      .catch((error: unknown) => {
        if (isUndefinedOrNull(error)) {
          return;
        }
        this._handleError(error, 'docscomerciais.erros.encerrarDocumento');
      });
  }

  public showRastreabilidade(): Promise<void> {
    return this._documentoRastreabilidadeService
      .getRastreabilidade(this.doc.cab.nDocFa, this.doc.cab.nNumer, this.doc.cab.nDocumentoDraft)
      .then((response: HttpResponse<Array<IJsonRastreabilidadeFaturacao>>) => {
        const modalRef = this._cgModalService.showVanilla(DocumentoRastreabilidadeModalComponent, {size: 'fullscreen'});
        const componentInstance: DocumentoRastreabilidadeModalComponent = modalRef.componentInstance;
        componentInstance.rastreabilidade = response.body;
        componentInstance.faccbId = this.doc.cab.faccbId;
      });
  }

  public async showDocContab(): Promise<void> {
    const extPocCabID: string = await this._docsComerciaisService.getDocContabId(this.doc.cab.faccbId).then((response: HttpResponse<string>) => response.body);
    await this._maintenanceInstanceDocContab.maintenanceEdit(extPocCabID);
  }

  public openArtigo(item: IJsonDocComercialLinha): Promise<void> {
    return this._entityMaintenanceInstanceArtigos.maintenanceEdit(item.nArtigo).then(() => undefined);
  }

  public openLotes(item: IJsonDocComercialLinha): Promise<void> {
    if (!this.comercialAdvanced) {
      const errorMsg = !this.licencaStoreModePublic
        ? this._plTranslateService.translate('blockedPlugin.text.portalNoLicense', {portal: this.portalName})
        : this._plTranslateService.translate('blockedPlugin.text.portalNoLicenseStoreMode', {portal: this.portalName, href: this.cgStoreUrlBackOffice});
      return Promise.reject(new Error(errorMsg));
    }

    const instance = this._cgModalService.showVanilla(SelecaoloteslinhaModalComponent, {size: 'md'});
    instance.componentInstance.doc = this.doc;
    instance.componentInstance.linha = item;
    instance.componentInstance.detail = true;
    return instance.result.then(() => undefined);
  }

  public getClifoUpdateDescrition(): string {
    if (!this.doc.clifo) {
      return '';
    }
    if (this.doc.clifo.action === EDocsComerciaisClifoStatus.Novo) {
      return 'Vai ser criada uma nova Conta Corrente';
    }
    if (this.doc.clifo.action === EDocsComerciaisClifoStatus.NovoAlternativo) {
      return 'Vai ser criada uma nova Conta Ocasional';
    }
    if (this.doc.clifo.action === EDocsComerciaisClifoStatus.ExisteAlternativo || this.doc.clifo.nIdAltern > 0) {
      return 'Conta Ocasional';
    }
    if (this.doc.clifo.action === EDocsComerciaisClifoStatus.Existe) {
      return 'Conta Corrente';
    }
    if (this.doc.clifo.action === EDocsComerciaisClifoStatus.ExisteNifAlternativo || this.doc.clifo.nIdAltern > 0) {
      return 'Conta Ocasional';
    }
    if (this.doc.clifo.action === EDocsComerciaisClifoStatus.ExisteNif) {
      return 'Conta Corrente';
    }
    if (
      this.doc.clifo.action === EDocsComerciaisClifoStatus.ExisteNifAlternativoEspecifico ||
      this.doc.clifo.action === EDocsComerciaisClifoStatus.ExisteNaoValidaNif ||
      this.doc.clifo.action === EDocsComerciaisClifoStatus.ExisteNaoActualizaNaoValidaNif
    ) {
      return this.doc.clifo.nIdAltern > 0 ? 'Conta Ocasional' : 'Conta Corrente';
    }
    return '';
  }

  public trackByFn(index: number): number {
    return index;
  }

  public changedTab(tabId: string): void {
    if (tabId === this.mainTabAnexosId && this.showGDocAnexos) {
      this._getGDocFolder(this._generateNDoc(this.doc.cab), EFolderDoc.DefaultFolderGestCom);
    }
  }

  public async adiantRegistaDoc(doc: IDocumentoFaturacao): Promise<void> {
    if (!this._adiantamentoConfig) {
      await this._adiantamentosService.adiantamentosConfig().then((response: HttpResponse<IJsonAdiantamentoConfig>) => {
        this._adiantamentoConfig = response.body;
      });
    }

    const docDefeito =
      this.entityNameClifos === ENTITY_NAME_CLIENTES
        ? (this._configService.configurations.documentosComerciais.tipos.vendasEfectivas.documentoPorDefeito ?? undefined)
        : (this._configService.configurations.documentosComerciais.tipos.comprasEfectivas.documentoPorDefeito ?? undefined);

    let nomeDocFa = '';
    let nNumer: number;
    if (docDefeito) {
      const docFaService: IEntityService<IJsonDocfa> = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
      await docFaService.get({id: docDefeito}).then((responseDocFa: HttpResponse<IJsonDocfa>) => {
        nomeDocFa = responseDocFa.body.nome;
        nNumer = this._getNNumerDefeitoDocfa(responseDocFa.body.docfanumList);
      });
    }

    const modalInstance = this._cgModalService.showVanilla(AdiantamentosRegistarModalComponent);
    const componentInstance: AdiantamentosRegistarModalComponent = modalInstance.componentInstance;
    componentInstance.adiantRegisto = {
      nDocFa: docDefeito ?? undefined,
      nomeDocFa: nomeDocFa,
      nNumer: nNumer ?? undefined,
      nConta: doc?.clifo?.nConta,
      nomeNConta: doc?.clifo?.nome,
      codIva: this.entityNameClifos === ENTITY_NAME_CLIENTES ? this._adiantamentoConfig.codIvaVenda : this._adiantamentoConfig.codIvaCompra,
      nomeCodIva: this.entityNameClifos === ENTITY_NAME_CLIENTES ? this._adiantamentoConfig.nomeCodIvaVenda : this._adiantamentoConfig.nomeCodIvaCompra,
      taxaIva: this.entityNameClifos === ENTITY_NAME_CLIENTES ? this._adiantamentoConfig.taxaIvaVenda : this._adiantamentoConfig.taxaIvaCompra,
      valorSemIva: 0,
      valorComIva: 0,
      grupoDocFa: doc?.cab?.grupoDocfa,
      nDoc: doc?.cab?.nDoc,
      faccbId: doc?.cab?.faccbId
    };
    componentInstance.clifoEntName = this.entityNameClifos;
    componentInstance.atualDoc = this.grupoDoc === EGrupoDoc.VendasEfectivas || this.grupoDoc === EGrupoDoc.ComprasEfectivas;
    componentInstance.adiantamentoConfig = this._adiantamentoConfig;
    return modalInstance.result.then((result: IAdiantamentosRegistoResult) => {
      const docsComerciaisEntity: IDocsComerciaisEntity = this.entityNameClifos === ENTITY_NAME_CLIENTES ? ENTITY_DOCS_COMERCIAIS_VENDAS : ENTITY_DOCS_COMERCIAIS_COMPRAS;
      if (result.faccbID > 0) {
        return this._cgStateService.redirectToState({stateOrName: docsComerciaisEntity.name, stateType: EEntityStateDetailType.EDIT, params: {id: result.faccbID}});
      }
      return this._cgStateService.redirectToState({stateOrName: docsComerciaisEntity.name, stateType: EEntityStateDetailType.NEW, params: {adiantamentos: result}});
    });
  }

  public async regularizarAdiantamentosModal(doc: IDocumentoFaturacao): Promise<void> {
    const moduleNameAdiantamentos = this.entityNameClifos === ENTITY_NAME_CLIENTES ? MODULE_NAME_ADIANTAMENTOS_CLIENTES : MODULE_NAME_ADIANTAMENTOS_FORNECEDORES;
    const maintenanceInstanceAdiantamentos = this._moduleMaintenanceService.build<number>(moduleNameAdiantamentos);
    const params: IAdiantamentosParams = {clifo: doc.cab.nConta, nomeClifo: doc.cab.nome, faccbId: doc.cab.faccbId, nnumer: doc.cab.nNumer, ndocfa: doc.cab.nDocFa};

    return maintenanceInstanceAdiantamentos.maintenance({params: params, closeOnSaved: true}).then((faccbId: number) => {
      const docsComerciaisEntity: IDocsComerciaisEntity = this.entityNameClifos === ENTITY_NAME_CLIENTES ? ENTITY_DOCS_COMERCIAIS_VENDAS : ENTITY_DOCS_COMERCIAIS_COMPRAS;
      return this._cgStateService.redirectToState({stateOrName: docsComerciaisEntity.name, stateType: EEntityStateDetailType.EDIT, params: {id: faccbId}});
    });
  }

  private _changedDoc(value: IDocumentoFaturacao = this.doc): void {
    this.doc = merge<object, IDocumentoFaturacao, IDocumentoFaturacao>({}, this._defaultDoc, value);
  }

  private _refreshList(): void {
    this.evtRefresh.emit();
  }

  private _setDocPdfUrl(doc: IDocumentoFaturacao): void {
    if (isFunction(this.actionPdfUrl)) {
      Promise.resolve(this.actionPdfUrl(doc.cab.faccbId, doc)).then((pdfUrl: string) => {
        doc._pdfUrl = pdfUrl;
      });
    } else if (isString(this.actionPdfUrl)) {
      doc._pdfUrl = this.actionPdfUrl;
    }
  }

  private _setDoc(value: IJsonDocComercial): void {
    this.doc = value;
    this.docChange.emit(this.doc);
  }

  private _handleError(error: unknown, defaultMessage: string): void {
    if (error instanceof HttpErrorResponse) {
      const exception = this._cgExceptionService.get(error);
      if (exception?.message) {
        this._plAlertService.error(exception.message);
        return;
      }
    }
    this._plAlertService.error(defaultMessage);
  }

  private _setArredManualInfo(doc: IJsonDocComercial): string {
    let arredManualString = '';
    let arredManualTotalIva = 0;
    let arredManualTotalLiquido = 0;
    if (doc.resumoIva.length > 0) {
      this.doc.resumoIva.forEach((resumoIvaItem: IJsonDocResumoIvaItem) => {
        arredManualTotalLiquido += resumoIvaItem.arredLiquidoManual;
        arredManualTotalIva += resumoIvaItem.arredIvaManual;
      });
      if (arredManualTotalIva > 0 && arredManualTotalLiquido > 0) {
        arredManualString = this._translateService.instant('arredondamentoManual.messages.totalIvaLiquido', {totalIva: arredManualTotalIva, totalLiquido: arredManualTotalLiquido});
      } else if (arredManualTotalIva > 0) {
        arredManualString = this._translateService.instant('arredondamentoManual.messages.totalIva', {totalIva: arredManualTotalIva});
      } else if (arredManualTotalLiquido) {
        arredManualString = this._translateService.instant('arredondamentoManual.messages.totalLiquido', {totalLiquido: arredManualTotalLiquido});
      }
    }
    return arredManualString;
  }

  private _getNNumerDefeitoDocfa(docfanumList: Array<IJsonDocfaNum>): number {
    for (const docfanum of docfanumList) {
      if (!docfanum.encerrado && docfanum.visivelERPCloud) {
        return docfanum.nNumer;
      }
    }
    return 1;
  }

  private _getGDocFolder(name: string, parentfolderId: string): Promise<unknown> {
    this.gDocFolderId = undefined;
    return this._gDocService
      .getFolderId(parentfolderId, name)
      .then((response) => {
        if (response?.body) {
          this.gDocFolderId = response.body.folderId;
        }
      })
      .catch((reason) => {
        const exception = this._cgExceptionService.get(reason);
        if (exception) {
          this._plAlertService.error(exception.message);
        }
      });
  }

  private _generateNDoc(cab: IJsonDocComercialCab): string {
    return !cab ? '' : `${cab.nDocFa}.${cab.nNumer}.${cab.nDocumentoDraft}`;
  }

  private _evaluateClifoAction(clifo: IJsonDocComercialClifo): EDocsComerciaisClifoStatus {
    return clifo.nConta
      ? clifo.action === EDocsComerciaisClifoStatus.None
        ? clifo.nIdAltern > 0
          ? EDocsComerciaisClifoStatus.ExisteNifAlternativo
          : (clifo.action = EDocsComerciaisClifoStatus.Existe)
        : clifo.action
      : clifo.action === EDocsComerciaisClifoStatus.None
        ? EDocsComerciaisClifoStatus.ExisteNifAlternativo
        : clifo.action;
  }

  private _initCabecalhoOnChange(doc: IDocumentoFaturacao, cab: IJsonDocComercialCab): void {
    doc.nDocFaOutput = `${cab.nDocFa} - ${cab.docNome}`;
    doc.nDocFaOutput = `${cab.nDocFa} - ${cab.docNome} (${cab.descricaoNDocfa})`;
    doc.identificaCarga = cab.identificaCarga;
    doc.moedaOutput = `${cab.codMoeda} - ${cab.nomeMoeda}`;
    doc.nCondPagOutput = `${cab.nCondPag} - ${cab.nomeCondPag}`;
    doc.nCCustoOutput = cab.nCCusto;
  }
}
