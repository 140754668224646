import {Component, Injector, OnInit} from '@angular/core';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonDiario} from '../../jsonDiario.entity.interface';
import {DATA_SOURCE_FORMA_INCREMENTO_NDOC} from '../../../../datasources/formaincrementondoc/formaIncrementoNDoc.datasource';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';

@Component({
  selector: 'diarios-edit',
  templateUrl: './diarios.entity.edit.component.html'
})
export class DiariosEntityEditComponent extends ModuloEntityDetailComponent<IJsonDiario> implements OnInit {
  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.type === EEntityStateDetailType.NEW) {
      this.model = {
        nDiario: undefined,
        nome: undefined,
        formaIncrementoNDoc: DATA_SOURCE_FORMA_INCREMENTO_NDOC.data[0].value
      };
    }
  }
}
