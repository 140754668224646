<div class="rhrecolhadados-list">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="pesquisar" plPromise>
        <pl-group>
          <pl-group *ngIf="isRemun">
            <label [translate]="'rhrecolhadados.fields.ano'"></label>
            <edit>
              <pl-autocomplete [source]="anos" [(model)]="anoEmCurso"></pl-autocomplete>
            </edit>
          </pl-group>

          <pl-group *ngIf="isRemun">
            <label [translate]="'rhrecolhadados.fields.mes'"></label>
            <edit>
              <pl-edit type="meses" [(model)]="mesEmCurso"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'rhrecolhadados.fields.inactivo'"></label>
            <edit>
              <pl-edit type="estadoemp" [(model)]="estadoEmp"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <edit>
            <button type="submit" class="btn btn-sm btn-primary" name="pesquisar" id="btnPesquisar"><i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span></button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [toolbarInstanceId]="instanceName"
    [onFiltered]="fnOnFiltered"
    [properties]="listOptions"
    [beforeRequest]="fnBeforeRequest"
    (filterChange)="filter = $event"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
  </entity-list>
</div>
