import type dxDataGrid from 'devextreme/ui/data_grid';
import {TemplateRef} from '@angular/core';
import {TNgClassSupportedTypes} from 'pl-comps-angular';
import {ECampoCalculadoME, IJsonDocContabilidade, IJsonDocContabilidadeLinha} from '../../jsonDocContabilidade.interface';
import {EDebitoCredito} from '../../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {IContabDigitalDocViewerRecolhaCallback} from '../../../../../components/arquivodigital/contabilidade/docviewerrecolha/contabilidadedigital.docviewer.recolha.component.interface';
import {IDevExpressValidationCallbackData} from '../../../../../components/devexpress/validator/devexpress.validator.rules.interface';
import {IDocContabilidade, IDocContabilidadeLinha} from '../../docsContabilidade.interface';
import {IJsonDocOCRCab} from '../../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {IJsonPOC} from '../../../../../entities/pocs/jsonPOC.entity.interface';
import {IJsonPreDefinidoContab, TPredefinidoFieldCabecalho, TPredefinidoFieldLinha} from '../../../manutencao/predefinidos/jsonPreDefinidosContab.entity.interface';
import {IMovimentosEmAberto} from '../../../../../interfaces/jsonMovimentosEmAberto.interface';

export enum EDocContabilidadeField {
  CONTA_DEBITO = 'nContaDebito',
  CONTA_CREDITO = 'nContaCredito',
  NCONTRIBUINTE = 'nContribuinte',
  VALOR = 'valor',
  CAMBIO = 'cambio',
  VALOR_ME = 'valorME',
  VALOR_TAXA = 'valorTaxa',
  DATA_DOC = 'dataDoc',
  DESCRICAO = 'descricao',
  C_DEC_PER = 'cDecPer',
  C_DEC_ANUAL = 'cDecAnual',
  NOME_CONTA = 'nomeConta'
}

export enum EDocContabilidadeException {
  INVALID_DATA_LANCAM = 'EObjContabilidadeDataLancamInvalida',
  INVALID_NIF = 'EObjContabilidadeNifInvalido',
  INVALID_POC = 'EEPOCNotFound',
  INVALID_DATA_DOC = 'EObjContabDataDocInconsistente'
}

export type TDocContabilidadeFnLoadPreDefinido = (params: IDocContabilidadeLoadPreDefinidoParams) => IJsonDocContabilidade | Promise<IJsonDocContabilidade>;

export type TDocContabilidadeFnAfterInitDocumento = (docContabilidade: IDocContabilidade) => void | IDocContabilidade;

export type TDocContabilidadePredefinidoEvaluatedCabecalho = [TPredefinidoFieldCabecalho, IDocContabilidadeEvaluatedField];

export type TDocContabilidadePredefinidoEvaluatedLinha = [TPredefinidoFieldLinha, IDocContabilidadeEvaluatedField];

export type TDocContabilidadeFocusField = 'predefinido' | 'linhas' | TPredefinidoFieldCabecalho;

export type TDocContabilidadeFnCallbackGetDoc = () => IJsonDocContabilidade;

export type TDocContabilidadeFnCallbackOnSave = (attachDigitalDocs: boolean) => unknown;

export type TDocContabilidadeFnCallbackContabilidadeDigital = boolean | (() => boolean);

export type TMOVABValorValidationCallbackData = IDevExpressValidationCallbackData<'custom', dxDataGrid, IMovimentosEmAberto, string>;

export interface IDocContabilidadeDefinition {
  defaultIndex: number;
  index: number;
  id: EDocContabilidadeField;
  caption: string;
  preDefinidoIndex: number;
  evaluatePreDefinidoIndex: number;
  templateRef: TemplateRef<IDocContabilidadeDefinitionTemplateContext>;
  show: boolean;
  cssClass?: TNgClassSupportedTypes;
}

export interface IDocContabilidadeDefinitionTemplateContext {
  readonly model: IDocContabilidade;
  readonly linha: IDocContabilidadeLinha;
}

export interface IDocContabilidadeCallback {
  docViewer?: IContabDigitalDocViewerRecolhaCallback;

  addLine?(): void;

  reset?(docContabilidade?: IDocContabilidade, focus?: boolean, focusField?: TDocContabilidadeFocusField): Promise<void>;

  resetAndInit?(docContabilidade?: IDocContabilidade, focus?: boolean, focusField?: TDocContabilidadeFocusField): Promise<void>;

  removePreDefinido?(): void;

  prepareFocus?(field?: TDocContabilidadeFocusField): void;

  focusFieldCabecalho?(fieldName: TPredefinidoFieldCabecalho, exact?: boolean): boolean;

  focusFieldLinha?(fieldName: EDocContabilidadeField, indexLinha: number): boolean;

  focusLastElement?(delay?: boolean): void;

  doMovimentosAbertoForLines?(lines: Array<string | IJsonDocContabilidadeLinha>): Promise<void>;

  doMovimentosAbertoForAllLines?(): Promise<void>;

  doPostValorChangeForLines?(lines: Array<string | IJsonDocContabilidadeLinha>): Promise<void>;

  doPostValorChangeForAllLines?(): Promise<void>;

  setErroInformativo?(value: string): void;

  clearErroInformativo?(): void;
}

export interface IDocContabilidadeEvent {
  linha: IDocContabilidadeLinha;
}

export interface IDocContabilidadeEventValue<T> extends IDocContabilidadeEvent {
  value: T;
  oldValue?: T;
}

export interface IDocContabilidadeEventFocus extends IDocContabilidadeEvent {
  event: FocusEvent;
}

export interface IDocContabilidadeEventKeyboard extends IDocContabilidadeEvent {
  event: KeyboardEvent;
}

export interface IDocContabilidadeEventConta extends IDocContabilidadeEventValue<IJsonPOC> {
  type: EDebitoCredito;
}

export interface IDocContabilidadeEventLock extends IDocContabilidadeEvent {
  campoME: ECampoCalculadoME;
}

export interface IDocContabilidadeEvaluatedField {
  visible: boolean;
  readonly: boolean;
  tabStop: boolean;
}

export interface IDocContabilidadeLastFocus {
  fieldName: EDocContabilidadeField;
  linhaNLanc: string;
  indiceLinha: number;
}

export interface IDocContabilidadeServiceSavePromptDefinition {
  identifier: string;
  callbackGetDoc: TDocContabilidadeFnCallbackGetDoc;
  callbackOnSave: TDocContabilidadeFnCallbackOnSave;
  simulation: boolean;
  contabilidadeDigital: TDocContabilidadeFnCallbackContabilidadeDigital;
  whenNode?: Node;
}

export interface IDocContabilidadeServiceSavePromptInstance {
  identifier: string;
  open: boolean;
  promise: Promise<void>;
  whenNode?: Node;

  callback(event: KeyboardEvent): void;
}

export interface IDocContabilidadeLoadPreDefinidoParams {
  predefinido: IJsonPreDefinidoContab;
  docContabilidade: IJsonDocContabilidade;
  docOCRCab: IJsonDocOCRCab;
}

export interface IDocContabilidadeContabDigitalAttachment {
  gDocId: string;
  gDocFolderId: string;
  docOCRCabID: string;
}

export interface IDocContabilidadePropertyNameOriginalData<T = unknown> {
  propertyNameOriginal: string;
  valorExistente: T;
  valorOriginal: T;

  validate(value: number, diferencaIva: number): IDocContabilidadePropertyNameOriginalValidateData;
}

export interface IDocContabilidadePropertyNameOriginalValidateData {
  minValue: number;
  maxValue: number;
  valid: boolean;
}

export interface IDocContabilidadeValidateAddValorTaxaParams {
  docContabilidadeLinha: IDocContabilidadeLinha;
  value: number;
  alertTitle?: string;
}

export const DOC_CONTABILIDADE_CAMPO_ERRO_INFORMATIVO_TO_IGNORE: ReadonlyArray<string> = Object.freeze([
  'Documento com acréscimo/diferimento associado. Para alterar este documento tem de anular o acréscimo/diferimento associado.'
]);
