import {firstValueFrom, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import dxDataGrid, {Column} from 'devextreme/ui/data_grid';
import {Properties as DxNumberBoxProperties} from 'devextreme/ui/number_box';
import {Component, EventEmitter, HostListener, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {
  copy,
  ERadix,
  getNodePath,
  getScreenWidth,
  IPlDynamicVisualsSecondaryClickAction,
  IPlEditComponentOptionsInput,
  IPlLifeCycleEvent,
  IPlToolbarInstance,
  isBoolean,
  isDefinedNotNull,
  isEmpty,
  isNumber,
  isObject,
  isString,
  KEYCODES,
  Logger,
  PlAlertService,
  PlToolbarService,
  PlTranslateService,
  timeout,
  toInteger
} from 'pl-comps-angular';
import {AuthService} from '../../../../services/auth/auth.service';
import {CGExceptionService} from '../../../exceptions/exceptions.service';
import {CGModalService} from '../../../cg/modal/cgmodal.service';
import {ConfigService} from '../../../../services/config/config.service';
import {ConfigSiteService} from '../../../../services/configsite.service';
import {
  CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_DOCS_CLASSIFICADOS,
  CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR
} from '../../contabilidade/docviewerrecolha/contabilidadedigital.docviewer.recolha.component.interface';
import {ContabilidadeDigitalAtribuirTipoDocumentoModalComponent} from '../../modals/atribuirtipodocumento/contabilidadedigital.atribuirtipodocumento.modal.component';
import {ContabilidadeDigitalCopiarDocModalComponent} from '../../modals/copiardoc/contabilidadedigital.copiardoc.modal.component';
import {ContabilidadeDigitalNovaContaCCModalComponent} from '../../modals/novacontacc/contabilidadedigital.novacontacc.modal.component';
import {ContabilidadeDigitalPreDefinidosConfigModalComponent} from '../../contabilidade/predefinidoconfig/contabilidadedigital.predefinidosconfig.modal.component';
import {ContabilidadeDigitalRemovePagesModalComponent} from '../../modals/removepages/contabilidadedigital.removepages.modal.component';
import {ContabilidadeDigitalSepararDocModalComponent} from '../../modals/separardoc/contabilidadedigital.separardoc.modal.component';
import {ContabilidadeDigitalUIService} from '../../../../services/contabilidadedigital/contabilidadedigital.ui.service';
import {ContabilidadeDigitalUnirDocsModalComponent} from '../../modals/unirdocs/contabilidadedigital.unirdocs.modal.component';
import {DATA_SOURCE_INVOICE_TYPE} from '../../../../datasources/invoicetype/invoiceType.datasource';
import {DevExpressDataGridUIService} from '../../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {DocContabilidadeService} from '../../../../modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/docContabilidade.service';
import {DocsContabilidadeService} from '../../../../modules/portalcontabilidade/docscontabilidade/service/docsContabilidade.service';
import {DocsDigitaisAdicionarDocumentosModalComponent} from '../../../../modules/contabilidadedigital/documentosdigitais/modals/adicionardocumentos/docsDigitais.adicionarDocumentos.modal.component';
import {DocsDigitaisDocsContabSemDocDigitalModalComponent} from '../../../../modules/contabilidadedigital/documentosdigitais/modals/docscontabsemdocdigital/docsDigitais.docsContabSemDocDigital.modal.component';
import {DocsDigitaisTreeDocsNaoClassificadosModalComponent} from '../../../../modules/contabilidadedigital/documentosdigitais/modals/treedocsnaoclassificados/docsDigitais.treeDocsNaoClassificados.modal.component';
import {
  EArquivoDigitalGestaoDocsDigitalizadosTypeAction,
  IAssociarDocsAutoDropDownOption,
  IContabDigitalAssociaDocSemelhante,
  IContabDigitalGestaoDocsDigitalizadosCallback,
  IContabDigitalPreDefinidoConfig,
  IDocDigitalizado,
  IDocDigitalizadoCellEditing,
  IDocsDigitalizadosHeader,
  MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS
} from '../../../../modules/contabilidadedigital/documentosdigitais/contabDigital.documentosDigitais.module.interface';
import {EContabilidadeDigitalActivateLicenseType} from '../../modals/activatelicense/contabilidadedigital.activatelicensemodal.component.interface';
import {EInvoiceType} from '../../../../datasources/invoicetype/invoiceType.datasource.interface';
import {
  ENTITY_NAME_DOCS_CONTABILIDADE,
  IDocContabilidade,
  IDocsContabilidadeEntityService,
  IDocsContabilidadePostParams
} from '../../../../modules/portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {EObjScanDocTextInterpreterInvoiceType} from '../../../../../common/interfaces/objscandoctextinterpreter.interface';
import {EXCEPTION_VERIFICA_EFATURAS_CALL_LOGIN} from '../../../../../common/exceptions';
import {ICGConfigurations} from '../../../../services/config/config.service.interface';
import {
  EGestaoDocumentosDigitalizadosMode,
  IArquivoDigitalGestaoDocumentosDigitalizadosEvtCalledAction,
  tableLegendDocumentosJaLanacados
} from './arquivoDigital.gestaoDocumentosDigitalizados.component.interface';
import {IContabilidadeDigitalTreeViewerFolder} from '../treeviewer/contabilidadedigital.treeviewer.component.interface';
import {IDevExpressDataGrid, IDevExpressDataGridCellTemplateData, IDevExpressDataGridEditCellTemplateData} from '../../../devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnEditorPreparing,
  IDevExpressDataGridEventOnFocusedCellChanged,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnKeyDown,
  IDevExpressDataGridEventOnRowExpanding,
  IDevExpressDataGridEventOnRowUpdated,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {
  IDevExpressDataGridExcelDataGridCell,
  IDevExpressDataGridExportExcelCustomizeCellOptions,
  IDevExpressDataGridExportPdfCustomizeCellOptions,
  IDevExpressDataGridPdfDataGridCell
} from '../../../devexpress/datagrid/export/devexpress.datagrid.export.interface';
import {IJsonClifo} from '../../../../entities/clifos/jsonClifo.entity.interface';
import {
  EDocOCRCabStatus,
  ETipoDocumentosIdentificadosContab,
  IJsonContabDigitalAssociaDoc,
  IJsonContabDigitalAssociaDocSemelhante,
  IJsonContabDigitalConfigs,
  IJsonContabDigitalLancadoRobo,
  IJsonContabDigitalPreDefinido,
  IJsonDocDigitalizado,
  IJsonDocOCRCab
} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {IJsonDocContabilidade} from '../../../../modules/portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IJsonPreDefinidoContab} from '../../../../modules/portalcontabilidade/manutencao/predefinidos/jsonPreDefinidosContab.entity.interface';
import {maxDateCG, minDateCG} from '../../../../../common/utils/utils';
import {ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS} from '../../../../modules/portalcontabilidade/manutencao/predefinidos/preDefinidosContab.entity.interface';
import {IModuleMaintenanceInstance} from '../../../entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../entity/maintenance/module/module.maintenance.service';
import moment, {Moment} from 'moment';
import {SimpleLoginModalComponent} from '../../../../modules/simplelogin/modals/simpleLogin.modal.component';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';
import {CGCardPanelComponent} from '../../../cg/cardpanel/cardpanel.component';
import {MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID} from '../../../../modules/portalcontabilidade/estatistica/extratosgrid/contabilidade.extratosGrid.module.interface';
import {IEntityAutocompleteCustomActionDefinition} from '../../../entity/entity.autocomplete.definition.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {IDocComercial, IDocsComerciaisGroup} from '../../../../entities/docscomerciais/docsComerciais.entity.interface';
import {EGrupoDoc} from '../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {DocumentosService} from '../../../../entities/docscomerciais/service/documentos.entity.service';
import {IDataSourceItem} from '../../../datasource/datasources.interface';
import {TTableLegend} from '../../../tablelegend/tablelegend.component.interface';
import {EntityMaintenanceService} from '../../../entity/maintenance/entity/entity.maintenance.service';
import {IEntityMaintenanceInstance} from '../../../entity/maintenance/entity/entity.maintenance.interface';

const ERROR_BACKGROUND = 'error-background';
const WARNING_BACKGROUND = 'warning-background';
const WAITING_OCR_BACKGROUND = 'waiting-ocr-background';
const DOCUMENTOS_CLASSIFICADOS = 'Documentos classificados';
const MIN_DATE: Moment = minDateCG();
const ANSI_FIRST_LETTER = 97;
const DATA_GRID_SORT_DELIMITER = ';';
const DATA_GRID_EXPORT_FILENAME = 'DocumentosDigitais';
const TOOLBAR_NAME_CONTAB = 'toolbar-contabilidade-documentos-digitalizados';
const TOOLBAR_NAME_COMERCIAL = 'toolbar-comercial-documentos-digitalizados';
const TOOLBAR_GROUP_ID = 'arquivo-digital-documentos-digitalizados';
const TOOLBAR_BTN_SAVE_ATTACH = 'saveAttach';
const SAVE_PROMPT_IDENTIFIER = 'docs-digitais-prompt-id';
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const ALLOWED_PAGE_SIZES: ReadonlyArray<number> = Object.freeze([25, 50, 100]);
const TOOLBAR_STICKY_TOP = 60;
const TABLET_WIDTH = 820;

@Component({
  selector: 'arquivo-digital-documentos-digitalizados',
  templateUrl: './arquivoDigital.gestaoDocumentosDigitalizados.component.html'
})
export class ArquivoDigitalGestaoDocumentosDigitalizadosComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public mode: EGestaoDocumentosDigitalizadosMode;
  @Input() public clientConnectMode: boolean;
  @Input() public nif: string;
  @Input() public nDocExterno: string;
  @Input() public showDocsClassificados: boolean;
  @Input() public callback: IContabDigitalGestaoDocsDigitalizadosCallback;
  @Output() public readonly evtChangedSelectedLine: EventEmitter<IDocDigitalizado>;
  @Output() public readonly evtChangedMultiSelectedLine: EventEmitter<Array<IDocDigitalizado>>;
  @Output() public readonly evtCalledAction: EventEmitter<IArquivoDigitalGestaoDocumentosDigitalizadosEvtCalledAction>;

  public readonly dataGridSortDelimiter: string;
  public readonly rowTemplateClifos: string;
  public readonly propertiesInput: IPlEditComponentOptionsInput<unknown>;
  public readonly propertiesInputPreDefinido: IPlEditComponentOptionsInput<unknown>;
  public readonly customActionPreDefinido: IEntityAutocompleteCustomActionDefinition;
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly dataGridDefinitionDocsSemelhantes: IDevExpressDataGrid;
  public readonly tipoDocsIdentsContab: typeof ETipoDocumentosIdentificadosContab;
  public readonly gestaoDocsDigitalizadosStatus: typeof EDocOCRCabStatus;
  public readonly gestaoDocDigiMode: typeof EGestaoDocumentosDigitalizadosMode;
  public readonly typeAction: typeof EArquivoDigitalGestaoDocsDigitalizadosTypeAction;

  public group: IDocsComerciaisGroup;
  public stateTypeDetail: EEntityStateDetailType;
  public selectedLine: IDocDigitalizado;
  public selectedLines: Array<IDocDigitalizado>;
  public docsDigitalizadosHeader: IDocsDigitalizadosHeader;
  public docsDigitalizadosMain: Array<IDocDigitalizado>;
  public associarDocsAutoOptions: Array<IAssociarDocsAutoDropDownOption>;
  public fromDuplicatedDocs: boolean;
  public temContabilidadeDigital: boolean;
  public modoCGOn: boolean;
  public disabled: boolean;
  public actionsDisabled: boolean;
  public modoLancarDocsAutoDisponivel: boolean;
  public isTablet: boolean;
  public arquivoDigitalLegend: TTableLegend;

  public clifosList: Array<IJsonClifo>;
  public preDefinidosList: Array<IJsonPreDefinidoContab>;
  public invoiceTypeSource: Array<IDataSourceItem<EInvoiceType>>;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private readonly _groupsIndexes: Map<string, string>;
  private readonly _groupsKeysIndexes: Map<string, string>;
  private readonly _registeredToolbarInstances: Map<string, IPlToolbarInstance>;
  private readonly _subscriptionConfigurations: Subscription;
  private readonly _subscriptionContabDigitalConfigs: Subscription;
  private readonly _maintenancePreDefinidos: IEntityMaintenanceInstance;
  private readonly _maintenanceInstanceExtratosGrid: IModuleMaintenanceInstance;
  private readonly _serviceDocsComerciais: DocumentosService;

  private _dataGridInstance: dxDataGrid<IDocDigitalizado, string>;
  private _initialDocsDigitalizadosMain: Array<IDocDigitalizado>;
  private _contabDigitalCGOnAllowAddFiles: boolean;
  private _expandingDoc: boolean;
  private _cgStoreUrl: string;
  private _expandedRowKey: string;
  private _cellEditingDataField: IDocDigitalizadoCellEditing;
  private _cardPanel: CGCardPanelComponent;
  private _controlKeyHolding: boolean;
  private _shiftKeyHolding: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _logger: Logger,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _authService: AuthService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _configService: ConfigService,
    private readonly _configSiteService: ConfigSiteService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _contabilidadeDigitalUIService: ContabilidadeDigitalUIService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _translateService: TranslateService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _docsContabilidadeService: DocsContabilidadeService,
    private readonly _docContabilidadeService: DocContabilidadeService,
    private readonly _plToolbarService: PlToolbarService
  ) {
    this.search = this.search.bind(this);
    this.isTablet = getScreenWidth() <= TABLET_WIDTH;
    this.clifosList = [];
    this.preDefinidosList = [];
    this.tipoDocsIdentsContab = ETipoDocumentosIdentificadosContab;
    this.gestaoDocsDigitalizadosStatus = EDocOCRCabStatus;
    this.gestaoDocDigiMode = EGestaoDocumentosDigitalizadosMode;
    this.evtChangedSelectedLine = new EventEmitter<IDocDigitalizado>();
    this.evtChangedMultiSelectedLine = new EventEmitter<Array<IDocDigitalizado>>();
    this.evtCalledAction = new EventEmitter<IArquivoDigitalGestaoDocumentosDigitalizadosEvtCalledAction>();
    this._registeredToolbarInstances = new Map<string, IPlToolbarInstance>();
    this._maintenancePreDefinidos = this._entityMaintenanceService.build(ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS);
    this._maintenanceInstanceExtratosGrid = this._moduleMaintenanceService.build(MODULE_NAME_PCA_ESTATISTICA_EXTRATOS_GRID);
    this.invoiceTypeSource = [];
    for (const item of DATA_SOURCE_INVOICE_TYPE.data) {
      this.invoiceTypeSource.push({value: item.value, name: this._translateService.instant(item.name)});
    }
    this.clientConnectMode = false;
    this.mode = EGestaoDocumentosDigitalizadosMode.Contabilidade;
    this.showDocsClassificados = false;
    this.dataGridSortDelimiter = DATA_GRID_SORT_DELIMITER;
    this.rowTemplateClifos = '{{nContribuint}}, {{nConta}} - {{nome}}';
    this.propertiesInput = {raw: true, validate: false};
    this.propertiesInputPreDefinido = {disallowClear: true, validate: false};
    this.customActionPreDefinido = {
      caption: 'docscontabilidade.text.maintenancePreDefinidos',
      action: () => {
        this._openMaintenancePreDefinidos();
      }
    };
    this.selectedLines = [];
    this.docsDigitalizadosHeader = {
      nifAte: 'ZZZZ',
      docExternoDe: '',
      docExternoAte: 'ZZZZ',
      dataDocExternoDe: minDateCG(),
      dataDocExternoAte: maxDateCG(),
      dataRececaoDe: moment().subtract(1, 'year').startOf('date'),
      dataRececaoAte: maxDateCG(),
      mostrarDocClassificados: false,
      mostraApenasLancRobot: false,
      nifDe: ''
    };
    this._expandingDoc = false;
    this.docsDigitalizadosMain = [];
    this.typeAction = EArquivoDigitalGestaoDocsDigitalizadosTypeAction;
    this.temContabilidadeDigital = false;
    this.modoCGOn = false;
    this.disabled = false;
    this.actionsDisabled = false;
    this._initialDocsDigitalizadosMain = [];
    this._contabDigitalCGOnAllowAddFiles = false;
    this._cgStoreUrl = '';
    this._expandedRowKey = '';
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._serviceDocsComerciais = this._injector.get<DocumentosService>(DocumentosService);
    this._groupsIndexes = new Map<string, string>();
    this._groupsKeysIndexes = new Map<string, string>();
    this.dataGridDefinitionDocsSemelhantes = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nDocumento', dataType: 'string', caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.docSemelhante.nDocumento'},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.docSemelhante.nDocExterno'},
        {dataField: 'nContrib', dataType: 'string', caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.docSemelhante.nContrib'},
        {dataField: 'descricao', dataType: 'string', caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.docSemelhante.descricao'},
        {dataField: 'dataDocExt', dataType: 'date', caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.docSemelhante.dataDocExt'}
      ],
      dataSource: [],
      filterRow: {visible: false},
      headerFilter: {visible: false},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateSemelhanteDocContabilidade'},
      keyExpr: 'extPocCabID',
      remoteOperations: false,
      toolbar: {items: [{location: 'before', template: 'toolbarTemplateDocsSemelhantes', locateInMenu: 'auto'}]}
    };
    this.dataGridDefinition = {
      columnAutoWidth: true,
      allowColumnResizing: true,
      sorting: {mode: 'single'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      columnHidingEnabled: false,
      columnResizingMode: this.isTablet ? 'nextColumn' : 'widget',
      editing: {mode: 'cell', startEditAction: 'click', selectTextOnEditStart: true, allowUpdating: true, refreshMode: 'repaint'},
      export: {
        enabled: true,
        filename: this._dataGridExportGenerateFilename(),
        propertiesExcel: {
          customizeCell: ({gridCell, excelCell}: IDevExpressDataGridExportExcelCustomizeCellOptions) => {
            this._dataGridExportCustomizeCell(gridCell, excelCell);
          }
        },
        propertiesPdf: {
          customizeCell: ({gridCell, pdfCell}: IDevExpressDataGridExportPdfCustomizeCellOptions) => {
            this._dataGridExportCustomizeCell(gridCell, pdfCell);
          }
        }
      },
      groupPanel: {visible: false, allowColumnDragging: false},
      grouping: {expandMode: 'rowClick', autoExpandAll: false},
      hoverStateEnabled: true,
      masterDetail: {enabled: true, template: 'masterDetailTemplateDoc'},
      pager: {visible: true, allowedPageSizes: ALLOWED_PAGE_SIZES.slice()},
      paging: {enabled: true, pageSize: 50},
      selection: {mode: 'multiple', selectAllMode: 'page', showCheckBoxesMode: 'none'},
      searchPanel: {visible: true},
      showBorders: true,
      keyboardNavigation: {editOnKeyPress: true, enterKeyAction: 'startEdit', enterKeyDirection: 'column'},
      keyExpr: 'docID',
      remoteOperations: false,
      repaintChangesOnly: true,
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbarActions',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'searchPanel'
        ]
      }
    };
    this.associarDocsAutoOptions = [
      {typeAction: EArquivoDigitalGestaoDocsDigitalizadosTypeAction.AssociarDocsAuto, caption: this._translateService.instant('arquivodigital.gestaodocsdigitalizados.actions.associardocsauto')},
      {
        typeAction: EArquivoDigitalGestaoDocsDigitalizadosTypeAction.AssociarDocsAutoInclAnulados,
        caption: this._translateService.instant('arquivodigital.gestaodocsdigitalizados.actions.associardocsincluidoanuladosauto')
      }
    ];
    this._configSiteService.cgStoreUrlBackOffice().then((value: string) => {
      this._cgStoreUrl = value;
    });
    this._subscriptionContabDigitalConfigs = this._contabilidadeDigitalUIService.configs.getConfigs().subscribe((contabDigitalConfigs: IJsonContabDigitalConfigs) => {
      this.modoLancarDocsAutoDisponivel = contabDigitalConfigs.modoLancarDocsAutoDisponivel;
    });
    this._subscriptionConfigurations = this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      this.temContabilidadeDigital = configurations.empresa.temContabilidadeDigital;
      this.modoCGOn = configurations.licenca.modoCGOn;
      this._contabDigitalCGOnAllowAddFiles = configurations.empresa.cgon.allowAddFiles;
      this._evaluateDisabled();
    });
    this._changedClientConnectMode();
    this.loadDocsDigitalizados = this.loadDocsDigitalizados.bind(this);
  }

  public ngOnInit(): void {
    this.fromDuplicatedDocs = false;
    if (!isEmpty(this.nif) && !isEmpty(this.nDocExterno)) {
      this.docsDigitalizadosHeader.nifDe = this.nif;
      this.docsDigitalizadosHeader.nifAte = this.nif;
      this.docsDigitalizadosHeader.docExternoDe = this.nDocExterno;
      this.docsDigitalizadosHeader.docExternoAte = this.nDocExterno;
      this.fromDuplicatedDocs = true;
      this.docsDigitalizadosHeader.mostrarDocClassificados = this.showDocsClassificados;
    }
    if (this.mode === EGestaoDocumentosDigitalizadosMode.Comercial) {
      this.stateTypeDetail = EEntityStateDetailType.NEW;
      this.group = {
        nDocFa: this._configService.configurations.documentosComerciais.tipos.comprasEfectivas,
        clifos: 'fornecedores',
        grupo: EGrupoDoc.ComprasEfectivas
      };
    }
    this.arquivoDigitalLegend = tableLegendDocumentosJaLanacados(this.mode !== EGestaoDocumentosDigitalizadosMode.Conciliacao);
    this.loadDocsDigitalizados();
  }

  public ngOnChanges({callback, clientConnectMode}: SimpleChanges): void {
    if (callback) {
      const cb: IContabDigitalGestaoDocsDigitalizadosCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.save = () => this._saveDocsDigitalizados();
        cb.hasLineChanges = () => this._hasLineChanges();
      }
    }
    if (clientConnectMode) {
      this._changedClientConnectMode(clientConnectMode.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigurations.unsubscribe();
    this._subscriptionContabDigitalConfigs.unsubscribe();
    for (const toolbarInstanceId of this._registeredToolbarInstances.keys()) {
      this._plToolbarService.unRegisterInstance(toolbarInstanceId);
    }
  }

  public changedValueLine(linha: IDocDigitalizado): void {
    const index: number = this._initialDocsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === linha.docID);
    if (index !== -1) {
      const initialLine: IDocDigitalizado = this._initialDocsDigitalizadosMain[index];
      linha._changed =
        initialLine.nif !== linha.nif ||
        initialLine.temNIFEmpresa !== linha.temNIFEmpresa ||
        initialLine.fiscalmenteRelevante !== linha.fiscalmenteRelevante ||
        initialLine.dataDoc !== linha.dataDoc ||
        initialLine.totalBase !== linha.totalBase ||
        initialLine.totalIVA !== linha.totalIVA ||
        initialLine.total !== linha.total ||
        initialLine.invoiceType !== linha.invoiceType ||
        initialLine.nDocumento !== linha.nDocumento ||
        initialLine.preDefinidosID !== linha.preDefinidosID;
    }
  }

  public async callAction(action: EArquivoDigitalGestaoDocsDigitalizadosTypeAction, adicionarDocFolderID?: string): Promise<void> {
    this.actionsDisabled = true;
    try {
      const validLicense: boolean = await this._contabilidadeDigitalUIService.checkConsumeLicense(EContabilidadeDigitalActivateLicenseType.ContabilidadeDigital);
      if (validLicense) {
        const empresaTemPastasCriadas: boolean = await firstValueFrom(this._contabilidadeDigitalUIService.configs.empresaTemPastasCriadas().pipe(take(1)));
        if (empresaTemPastasCriadas !== true) {
          await this._contabilidadeDigitalUIService.checkFoldersCreated();
        }

        switch (action) {
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.AdicionarDoc:
            if (!this.modoCGOn || this._contabDigitalCGOnAllowAddFiles) {
              const modalInstance: NgbModalRef = this._cgModalService.showVanilla(DocsDigitaisTreeDocsNaoClassificadosModalComponent, {size: 'lg'});
              const componentInstance: DocsDigitaisTreeDocsNaoClassificadosModalComponent = modalInstance.componentInstance;
              componentInstance.folderID = adicionarDocFolderID;
              componentInstance.anoEmCursoIRC = true;
              await modalInstance.result
                .then(({folderID}: IContabilidadeDigitalTreeViewerFolder) => {
                  const modalRef: NgbModalRef = this._cgModalService.showVanilla(DocsDigitaisAdicionarDocumentosModalComponent, {size: 'md'});
                  const componentInstanceAdicionarDocumentosModal: DocsDigitaisAdicionarDocumentosModalComponent = modalRef.componentInstance;
                  componentInstanceAdicionarDocumentosModal.folderId = folderID;
                  modalRef.result.then((uploadedFiles: Array<IDocDigitalizado>) => {
                    if (!uploadedFiles.length) {
                      return;
                    }
                    this._contabilidadeDigitalUIService.configs.saveLastFolder(folderID);
                    this._plAlertService.success('arquivodigital.gestaodocsdigitalizados.messages.modal.adicionardocumentos.success');
                    this._evaluateDocsDigitalizados(uploadedFiles);
                    for (const uploadedFile of uploadedFiles) {
                      this.docsDigitalizadosMain.push(uploadedFile);
                      this._initialDocsDigitalizadosMain.push(uploadedFile);
                    }
                    this._sortDocsDigitalizadosMain();
                    this._dataGridInstance.refresh(true);
                  });
                })
                .catch((reason: unknown) => {
                  this._logger.error(reason);
                });
            } else {
              this._plAlertService.error(this._plTranslateService.translate('arquivodigital.gestaodocsdigitalizados.errors.addFilesDisallowed', {href: this._cgStoreUrl}));
            }
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.MudarDocPasta:
            const modalInstance: NgbModalRef = this._cgModalService.showVanilla(DocsDigitaisTreeDocsNaoClassificadosModalComponent);
            const componentInstance: DocsDigitaisTreeDocsNaoClassificadosModalComponent = modalInstance.componentInstance;
            componentInstance.changeFolderFile = true;
            await modalInstance.result
              .then((folder: IContabilidadeDigitalTreeViewerFolder) => {
                this._contabilidadeDigitalUIService.documents.moveDocsFolder(folder.folderID, this.selectedLines).then((response: HttpResponse<Array<IJsonDocDigitalizado>>) => {
                  this._moveDocFolder(response.body);
                });
              })
              .catch((reason: unknown) => {
                this._logger.error(reason);
              });
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.Robo:
            this._dataGridInstance.beginCustomLoading(undefined);
            await this._contabilidadeDigitalUIService.documents
              .lancarPorRobo(this.selectedLines)
              .then((response: HttpResponse<IJsonContabDigitalLancadoRobo>) => {
                return this._lancarPorRobo(response.body);
              })
              .finally(() => {
                this._dataGridInstance.endCustomLoading();
              });
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.ReaplicarOCR:
            await this._contabilidadeDigitalUIService.documents
              .refazerOCR(this.selectedLine.docOCRCabID)
              .then((response: HttpResponse<IDocDigitalizado>) => {
                this._refazerOCR(response.body);
              })
              .catch((reason: unknown) => {
                this._logger.error(reason);
              });
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.ObterDadosEFatura:
            const docsIDEFatura: Array<string> = this.selectedLines.map((doc: IDocDigitalizado) => doc.docID);
            await this._contabilidadeDigitalUIService.documents
              .obterDadosEFatura(docsIDEFatura)
              .then((response: HttpResponse<Array<IDocDigitalizado>>) => {
                this._obterDadosEFatura(response.body);
              })
              .catch((reason: HttpErrorResponse) => {
                const cgException = this._cgExceptionService.get(reason);
                if (cgException.class !== EXCEPTION_VERIFICA_EFATURAS_CALL_LOGIN) {
                  this._plAlertService.error(cgException.message);
                  return Promise.resolve();
                }
                return this._cgModalService
                  .show(SimpleLoginModalComponent, {size: 'sm'})
                  .then(() => {
                    this._plAlertService.success('simpleLogin.messages.successLogin');
                    return this._contabilidadeDigitalUIService.documents
                      .obterDadosEFatura(docsIDEFatura)
                      .then((response: HttpResponse<Array<IDocDigitalizado>>) => {
                        this._obterDadosEFatura(response.body);
                      })
                      .catch((reason1: HttpErrorResponse) => {
                        this._logger.error(reason1);
                      });
                  })
                  .catch((reason1: unknown) => {
                    this._logger.error(reason1);
                  });
              });
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.AtribuirTipoDocumento:
            const docsID: Array<string> = this.selectedLines.map((doc: IDocDigitalizado) => doc.docID);
            const instanceAtribuirTipoDocumento = this._cgModalService.showVanilla(ContabilidadeDigitalAtribuirTipoDocumentoModalComponent);
            const componentInstanceAtribuirTipoDocumento: ContabilidadeDigitalAtribuirTipoDocumentoModalComponent = instanceAtribuirTipoDocumento.componentInstance;
            componentInstanceAtribuirTipoDocumento.docsID = docsID;
            await instanceAtribuirTipoDocumento.result
              .then((result: EObjScanDocTextInterpreterInvoiceType) => {
                this._setTypeDoc(result);
              })
              .catch((reason: unknown) => {
                this._logger.error(reason);
              });
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.UnirDocs:
            const docsUnir: Array<IDocDigitalizado> = this.selectedLines.filter((selectedLine: IDocDigitalizado) => selectedLine.docID !== this.selectedLine.docID);
            const instanceUnirDocs = this._cgModalService.showVanilla(ContabilidadeDigitalUnirDocsModalComponent);
            const componentInstanceUnirDocs: ContabilidadeDigitalUnirDocsModalComponent = instanceUnirDocs.componentInstance;
            componentInstanceUnirDocs.docPrincipal = this.selectedLine;
            componentInstanceUnirDocs.docsAUnir = docsUnir;
            await instanceUnirDocs.result
              .then((response: IJsonDocDigitalizado) => {
                this._unirDocs(response);
              })
              .catch((reason: unknown) => {
                this._logger.error(reason);
              });
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.SepararDoc:
            const instanceSepararDoc = this._cgModalService.showVanilla(ContabilidadeDigitalSepararDocModalComponent);
            const componentInstanceSepararDoc: ContabilidadeDigitalSepararDocModalComponent = instanceSepararDoc.componentInstance;
            componentInstanceSepararDoc.docID = this.selectedLine.docID;
            await instanceSepararDoc.result
              .then((result: Array<IJsonDocDigitalizado>) => {
                this._separarDoc(result);
              })
              .catch((reason: unknown) => {
                this._logger.error(reason);
              });
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.CopiarDoc:
            const instanceCopiarDoc = this._cgModalService.showVanilla(ContabilidadeDigitalCopiarDocModalComponent);
            const componentInstanceCopiarDoc: ContabilidadeDigitalCopiarDocModalComponent = instanceCopiarDoc.componentInstance;
            componentInstanceCopiarDoc.doc = this.selectedLine;
            await instanceCopiarDoc.result
              .then((result: IJsonDocOCRCab) => {
                this._copyDocument(<IDocDigitalizado>result);
              })
              .catch((reason: unknown) => {
                this._logger.error(reason);
              });
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.RemovePages:
            const instanceRemovePages = this._cgModalService.showVanilla(ContabilidadeDigitalRemovePagesModalComponent);
            const componentInstanceRemovePages: ContabilidadeDigitalRemovePagesModalComponent = instanceRemovePages.componentInstance;
            componentInstanceRemovePages.docId = this.selectedLine.docID;
            await instanceRemovePages.result.catch((reason: unknown) => {
              this._logger.error(reason);
            });
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.Apagar:
            const lines: Array<IDocDigitalizado> = this.selectedLines;
            const title: string = this.selectedLine._classified
              ? 'arquivodigital.gestaodocsdigitalizados.messages.deleteAttachment.titleRemoveAssociation'
              : 'arquivodigital.gestaodocsdigitalizados.messages.deleteAttachment.titleDelete';
            const message: string = this.selectedLine._classified
              ? 'arquivodigital.gestaodocsdigitalizados.messages.deleteAttachment.messageRemoveAssociation'
              : lines.length === 1
                ? 'arquivodigital.gestaodocsdigitalizados.messages.confirmDelete'
                : 'arquivodigital.gestaodocsdigitalizados.messages.confirmDeletePlural';
            try {
              await this._cgModalService.showOkCancel(title, message);
              if (this.selectedLine._classified) {
                const docsIDAssociacao: Array<string> = this.selectedLines.map((docDigitalizadoAssociation: IDocDigitalizado) => docDigitalizadoAssociation.docID);
                const response: HttpResponse<Array<IJsonDocDigitalizado>> = await this._contabilidadeDigitalUIService.documents.moverParaDocsNaoClassificados(docsIDAssociacao);
                this._plAlertService.success('arquivodigital.gestaodocsdigitalizados.alerts.associacaoremoved');
                this._removedAssociationDocs(response.body);
              } else {
                await this._contabilidadeDigitalUIService.documents.deleteDocsDigitalizados(this.selectedLines);
                this._plAlertService.success('arquivodigital.gestaodocsdigitalizados.alerts.deletesuccess');
                this._deleteDocs(this.selectedLines);
              }
            } catch (reason: unknown) {
              this._logger.error(reason);
            }
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.NovaContaCC:
            const instanceNovaContaCC = this._cgModalService.showVanilla(ContabilidadeDigitalNovaContaCCModalComponent);
            const componentInstanceNovaContaCC: ContabilidadeDigitalNovaContaCCModalComponent = instanceNovaContaCC.componentInstance;
            componentInstanceNovaContaCC.doc = this.selectedLine;
            await instanceNovaContaCC.result
              .then((result: string) => {
                this._newCCAccount(this.selectedLine, result);
              })
              .catch((reason: unknown) => {
                this._logger.error(reason);
              });
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.PreDefinidos:
            const validSelectedLine = this.selectedLine && this.selectedLines?.length === 1;
            let contabDigitalPreDefinidoResponse: HttpResponse<IJsonContabDigitalPreDefinido>;
            if (validSelectedLine) {
              contabDigitalPreDefinidoResponse = await this._contabilidadeDigitalUIService.documents.defaultConfigPreDefinidos(this.selectedLine.nif);
            }
            const instancePreDefinidos = this._cgModalService.showVanilla(ContabilidadeDigitalPreDefinidosConfigModalComponent);
            const componentInstancePreDefinidos: ContabilidadeDigitalPreDefinidosConfigModalComponent = instancePreDefinidos.componentInstance;
            componentInstancePreDefinidos.nif = !validSelectedLine ? '' : this.selectedLine.nif;
            componentInstancePreDefinidos.nomeFornecedor = !validSelectedLine ? '' : this.selectedLine.entidade;
            componentInstancePreDefinidos.preDefinido = contabDigitalPreDefinidoResponse?.body;
            componentInstancePreDefinidos.docs = copy(this.docsDigitalizadosMain);
            try {
              const result: IContabDigitalPreDefinidoConfig = await instancePreDefinidos.result;
              this._preDefinido(result.docsPreDefinidoReload, result.docs);
            } catch (reason: unknown) {
              this._logger.error(reason);
            }
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.DocsContabSemContabDig:
            await this._cgModalService.show(DocsDigitaisDocsContabSemDocDigitalModalComponent);
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.ExtratoConta:
            await this._maintenanceInstanceExtratosGrid.maintenance({params: {nConta: this.selectedLine.nContaEntidade}});
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.AssociarDocsAuto:
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.AssociarDocsAutoInclAnulados:
            this._dataGridInstance
              .getDataSource()
              .store()
              .load()
              .then((response: Array<IDocDigitalizado>) => {
                const docsIdentDireta: Array<IDocDigitalizado> = response.filter((item: IDocDigitalizado) => item.tipoDocsIdentContab === ETipoDocumentosIdentificadosContab.IdentificacaoDireta);
                return this._associarDocsContab(docsIdentDireta, undefined, action === EArquivoDigitalGestaoDocsDigitalizadosTypeAction.AssociarDocsAutoInclAnulados);
              });
            break;
          case EArquivoDigitalGestaoDocsDigitalizadosTypeAction.PossiveisDocsRepetidos:
            const moduleDocsDigitais: IModuleMaintenanceInstance = this._moduleMaintenanceService.build(MODULE_NAME_CONTABILIDADE_DIGITAL_DOCUMENTOS_DIGITAIS);
            await moduleDocsDigitais.maintenance({params: {nif: this.selectedLine.nif, nDocExterno: this.selectedLine.nDocumento, showDocsClassificados: true}});
            break;
          default:
            break;
        }
        this.evtCalledAction.emit({action: action, selectedLine: this.selectedLine, selectedLines: this.selectedLines});
      }
    } finally {
      this.actionsDisabled = false;
    }
  }

  public openDoc(line: IDocDigitalizado): void {
    if (this.clientConnectMode) {
      return;
    }

    let promise: Promise<void>;

    if (this.mode === EGestaoDocumentosDigitalizadosMode.Comercial) {
      if (line._classified) {
        if (!line._detailExpanded && !line._docComercial) {
          promise = this._serviceDocsComerciais.getDoc(line.faccbId).then((response: HttpResponse<IDocComercial>) => {
            line._docComercial = response.body;
          });
        }
      } else if (!line._detailExpanded && !this.clientConnectMode) {
        promise = this._suggestDocDigitalizadoDetail(line);
      }
    } else if (this.mode === EGestaoDocumentosDigitalizadosMode.Contabilidade || this.mode === EGestaoDocumentosDigitalizadosMode.Conciliacao) {
      if (line._classified) {
        if (!line._detailExpanded && !line._docContabilidade) {
          promise = this._serviceDocsContabilidade.get({id: line.extPocCabID}).then((response: HttpResponse<IJsonDocContabilidade>) => {
            line._docContabilidade = response.body;
          });
        }
      } else if (!line._detailExpanded && !this.clientConnectMode && this.mode !== EGestaoDocumentosDigitalizadosMode.Conciliacao) {
        promise = this._suggestDocDigitalizadoDetail(line);
      } else if (this.mode === EGestaoDocumentosDigitalizadosMode.Conciliacao && !line._detailExpanded) {
        if (!line._docContabilidade && line.tipoDocsIdentContab !== ETipoDocumentosIdentificadosContab.Nenhum) {
          promise = this._serviceDocsContabilidade.get({id: line.extPocCabIDDocConciliacao}).then((response: HttpResponse<IJsonDocContabilidade>) => {
            line._docContabilidade = response.body;
          });
        } else if (!line._docsSemelhantes) {
          promise = this._contabilidadeDigitalUIService.documents.getDocsSemelhantes(line.docOCRCabID).then((response: HttpResponse<Array<IJsonContabDigitalAssociaDocSemelhante>>) => {
            line._docsSemelhantes = response.body;
          });
        }
      }
    }

    this._dataGridInstance.beginCustomLoading(undefined);
    Promise.resolve(promise)
      .then(async () => {
        line._detailExpanded = !line._detailExpanded;
        if (line._detailExpanded) {
          this._expandingDoc = true;
          try {
            await this._dataGridInstance.navigateToRow(line.docID);
          } finally {
            await this._dataGridInstance.expandRow(line.docID);
          }
        } else {
          await this._dataGridInstance.collapseRow(line.docID);
        }
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
  }

  public preDefinidosChanged(cellInfo: IDevExpressDataGridEditCellTemplateData<IDocDigitalizado>, value: IJsonPreDefinidoContab): void {
    cellInfo.data.preDefinidosID = value?.preDefinidosID;
    cellInfo.data.preDefinidoNome = value?.descricao;
    this._onRowUpdated(cellInfo.data, cellInfo.component);
  }

  public nifChanged(cellInfo: IDevExpressDataGridCellTemplateData<IDocDigitalizado>, value: string): void {
    cellInfo.value = cellInfo.data.nif = value;
    this._onRowUpdated(cellInfo.data, cellInfo.component);
  }

  public invoiceTypeChanged(invoiceTypeDT: EInvoiceType, cellInfo: IDevExpressDataGridCellTemplateData<IDocDigitalizado>): void {
    cellInfo.value = cellInfo.data.invoiceTypeStr = this._translateService.instant(DATA_SOURCE_INVOICE_TYPE.data[invoiceTypeDT].name);
    cellInfo.data.invoiceType = invoiceTypeDT;
    this._onRowUpdated(cellInfo.data, cellInfo.component);
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized<IDocDigitalizado, string>): void {
    this._dataGridInstance = component;

    // When rendering table for the first time we must start loading manually
    this._dataGridInstance.beginCustomLoading(undefined);
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
  }

  public onCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IDocDigitalizado>): void {
    if (event.rowType === 'data') {
      if (this.mode === EGestaoDocumentosDigitalizadosMode.Conciliacao) {
        if (event.data.tipoDocsIdentContab === ETipoDocumentosIdentificadosContab.IdentificacaoDireta) {
          event.cellElement.classList.add('text-success');
        }
        if (event.data.tipoDocsIdentContab === ETipoDocumentosIdentificadosContab.JaComAnexo) {
          event.cellElement.classList.add('text-primary');
        }
      }

      switch (event.column.dataField) {
        case 'nif':
          event.cellElement.classList.add(event.data._legendNif);
          break;
        case 'temNIFEmpresa':
          event.cellElement.classList.add(event.data._legendTemNifEmp);
          break;
        case 'fiscalmenteRelevante':
          event.cellElement.classList.add(event.data._legendFiscalmenteRelevante);
          break;
        case 'invoiceTypeStr':
          event.cellElement.classList.add(event.data._legendInvoiceType);
          break;
        case 'dataDoc':
          event.cellElement.classList.add(event.data._legendDataDoc);
          break;
        case 'nDocumento':
          event.cellElement.classList.add(event.data._legendNDocumento);
          break;
        case 'totalBase':
          event.cellElement.classList.add(event.data._legendTotalBase);
          break;
        case 'totalIVA':
          event.cellElement.classList.add(event.data._legendTotalIva);
          break;
        case 'total':
          event.cellElement.classList.add(event.data._legendTotal);
          break;
      }
      if (!isEmpty(event.data._legendWaitingOCR)) {
        event.cellElement.classList.add(event.data._legendWaitingOCR);
      }
    }
  }

  public async onCellClick({row, event}: IDevExpressDataGridEventOnCellClick<IDocDigitalizado, string>): Promise<void> {
    if (!isObject(row) || row.rowType !== 'data') {
      return;
    }

    this._controlKeyHolding = event.ctrlKey;
    this._shiftKeyHolding = event.shiftKey;

    let selectedRows = false;
    let preserve = false;
    if (!this.clientConnectMode) {
      if (event.shiftKey) {
        if (this.selectedLine && this.selectedLine.docID !== row.key) {
          const selectedLineIndex: number = this._dataGridInstance.getRowIndexByKey(this.selectedLine.docID);
          const min: number = selectedLineIndex < row.rowIndex ? selectedLineIndex : row.rowIndex;
          const max: number = selectedLineIndex > row.rowIndex ? selectedLineIndex : row.rowIndex;

          const indexToSelect: Array<number> = [];
          for (let i = min; i <= max; i++) {
            indexToSelect.push(i);
          }

          selectedRows = true;
          await this._dataGridInstance.selectRowsByIndexes(indexToSelect);
        }
      } else if (event.ctrlKey) {
        event.stopImmediatePropagation();
        preserve = true;
      }
    }

    if (!selectedRows) {
      await this._dataGridInstance.selectRows([row.key], preserve);
    }

    const index: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === row.key);
    if (index > -1) {
      this.selectedLine = this.docsDigitalizadosMain[index];
      this.evtChangedSelectedLine.emit(this.selectedLine);
    }
  }

  public onSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IDocDigitalizado>): void {
    this.selectedLines = [];
    for (const selectedItem of event.selectedRowsData) {
      const index: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === selectedItem.docID);
      if (index > -1) {
        this.selectedLines.push(this.docsDigitalizadosMain[index]);
      }
    }
    this.evtChangedMultiSelectedLine.emit(this.selectedLines);
  }

  public onRowExpanding(event: IDevExpressDataGridEventOnRowExpanding<IDocDigitalizado, string>): void {
    if (!this._expandingDoc) {
      if (!isEmpty(this._expandedRowKey)) {
        event.component.collapseRow(this._expandedRowKey);
        event.component.deselectAll().then(() => {
          this.selectedLine = undefined;
          this.selectedLines = [];
          this.evtChangedSelectedLine.emit(this.selectedLine);
          this.evtChangedMultiSelectedLine.emit(this.selectedLines);
        });
      }
      this._expandedRowKey = event.key;
    }
    this._expandingDoc = false;
  }

  public onDataGridRowUpdated(event: IDevExpressDataGridEventOnRowUpdated<IDocDigitalizado, string>): void {
    this._onRowUpdated(event.data, event.component, false);
  }

  public onDataGridContextMenuPreparing({target, row, event, component}: IDevExpressDataGridEventOnContextMenuPreparing<IDocDigitalizado, string>): void {
    if (this.clientConnectMode || target !== 'content' || !row || (row.rowType !== 'data' && row.rowType !== 'group') || !isObject(row.data)) {
      return;
    }

    event.preventDefault();

    const isGroupType: boolean = row.rowType === 'group';
    if (!row.isSelected && !isGroupType) {
      component.selectRows([row.data.docID], false).then(() => {
        const index: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === row.data.docID);
        if (index !== -1) {
          this.selectedLine = this.docsDigitalizadosMain[index];
          this.evtChangedSelectedLine.emit(this.selectedLine);
        }
      });
    }

    const serializedHostElement: string = getNodePath(<HTMLElement>event.target);
    if (serializedHostElement) {
      setTimeout(() => {
        const hostElement: HTMLElement = component.element().querySelector(serializedHostElement);
        if (hostElement) {
          let adicionarDocFolderID: string;
          if (isGroupType) {
            const key: string = row.data.docID;
            adicionarDocFolderID = this._groupsKeysIndexes.get(key.substring(key.indexOf(';') + 1, key.length));
            if (adicionarDocFolderID.includes(CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_DOCS_CLASSIFICADOS)) {
              adicionarDocFolderID = undefined;
            }
          }
          const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateSecondaryClickActions(isGroupType, adicionarDocFolderID);
          this._devExpressDataGridUIService.openContextMenu(hostElement, actions);
        }
      });
    }
  }

  public onEditorPreparing(event: IDevExpressDataGridEventOnEditorPreparing<IDocDigitalizado>): void {
    if (event.editorName === 'dxNumberBox') {
      (<DxNumberBoxProperties>event.editorOptions).step = 0;
    }
  }

  public onFocusedCellChanged(event: IDevExpressDataGridEventOnFocusedCellChanged<IDocDigitalizado, string>): void {
    if (!this._controlKeyHolding && !this._shiftKeyHolding) {
      if (this._cellEditingDataField && this.selectedLine?.docID !== event.row.data.docID && this.selectedLines.length < 2) {
        const index: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === event.row.data.docID);
        if (index > -1) {
          this._dataGridInstance.selectRows([event.row.data.docID], false);
          this.selectedLine = this.docsDigitalizadosMain[index];
          this.evtChangedSelectedLine.emit(this.selectedLine);
        }
      }

      this._cellEditingDataField = {datafield: event.column.dataField, rowIndex: event.rowIndex};
    }
  }

  public onKeyDown({event, component}: IDevExpressDataGridEventOnKeyDown<IDocDigitalizado, string>): void {
    if (!event) {
      return;
    }

    if (event.key === KEYCODES.CONTROL) {
      this._controlKeyHolding = true;
    }

    if (event.key === KEYCODES.SHIFT) {
      this._shiftKeyHolding = true;
    }

    if (event.key === KEYCODES.UP || event.key === KEYCODES.DOWN) {
      event.preventDefault();
      if (this.selectedLine) {
        const currentIndex: number = this._dataGridInstance.getRowIndexByKey(this.selectedLine.docID);
        const nextIndex: number = event.key === KEYCODES.UP ? currentIndex - 1 : currentIndex + 1;
        const nextKey: string = this._dataGridInstance.getKeyByRowIndex(nextIndex);
        if (nextKey.includes('{', 0)) {
          this._dataGridInstance.selectRows([nextKey], false);
          const index: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === nextKey);
          if (index > -1) {
            this.selectedLine = this.docsDigitalizadosMain[index];
            this.evtChangedSelectedLine.emit(this.selectedLine);
            if (this._cellEditingDataField) {
              component.editCell(nextIndex, this._cellEditingDataField.datafield);
            }
          }
        }
      }
    }
  }

  public onCellClickDocsSemelhantes(event: IDevExpressDataGridEventOnCellClick<IContabDigitalAssociaDocSemelhante>): Promise<void> {
    if (event.rowType === 'data') {
      return devExpressDataGridExpandDetailHandler(event, () => {
        this._serviceDocsContabilidade.get({id: event.data.extPocCabID}).then((response: HttpResponse<IJsonDocContabilidade>) => {
          event.data._docContabilidade = response.body;
        });
      }).catch((reason: unknown) => {
        this._logger.error(reason);
      });
    }
    return Promise.resolve();
  }

  public docContabilidadeInit({element}: IPlLifeCycleEvent, docDigitalizado: IDocDigitalizado): void {
    this._docContabilidadeService.listenForSaveEvent({
      identifier: `${SAVE_PROMPT_IDENTIFIER}-${docDigitalizado.docID}`,
      callbackGetDoc: () => docDigitalizado._docContabilidade,
      callbackOnSave: () => {
        const promise: Promise<void> = this._saveTableDoc(docDigitalizado);
        const toolbarInstance: IPlToolbarInstance = this._registeredToolbarInstances.get(docDigitalizado._docContabilidadeToolbarInstanceId);
        if (toolbarInstance) {
          const btnSave = toolbarInstance.find(TOOLBAR_BTN_SAVE_ATTACH);
          if (btnSave) {
            btnSave.promise = promise;
          }
        }
      },
      simulation: false,
      contabilidadeDigital: true,
      whenNode: element
    });
  }

  public docContabilidadeDestroy(docDigitalizado: IDocDigitalizado): void {
    this._docContabilidadeService.clearForSaveEventListener(`${SAVE_PROMPT_IDENTIFIER}-${docDigitalizado.docID}`);
  }

  public async search(): Promise<void> {
    try {
      await this.loadDocsDigitalizados();
      if (this.docsDigitalizadosMain?.length) {
        this._cardPanel.collapse();
      } else {
        this._cardPanel.focusFirstElement();
        this._plAlertService.info('global.text.searchNoData');
      }
    } catch (error) {
      this._cardPanel.focusFirstElement();
    }
  }

  public async loadDocsDigitalizados(): Promise<void> {
    if (!this._checkInputFields()) {
      return;
    }
    if (this.docsDigitalizadosMain.length) {
      this.selectedLine = undefined;
      this.selectedLines = [];
      this.evtChangedSelectedLine.emit(this.selectedLine);
      this.evtChangedMultiSelectedLine.emit(this.selectedLines);
      if (this._dataGridInstance) {
        this._dataGridInstance.deselectAll();
      }
    }
    this.docsDigitalizadosMain = [];
    if (this._dataGridInstance) {
      this._dataGridInstance.collapseAll(-1);
      this._dataGridInstance.beginCustomLoading(undefined);
    }
    try {
      const response: HttpResponse<Array<IJsonDocDigitalizado>> = await this._contabilidadeDigitalUIService.getDocsDigitalizados(
        this.docsDigitalizadosHeader,
        this.mode === EGestaoDocumentosDigitalizadosMode.Conciliacao
      );
      if (!response.body.length) {
        this._plAlertService.info('global.text.searchNoData');
        return;
      }
      this._cardPanel.collapse();
      this._evaluateDocsDigitalizados(response.body);
      this.docsDigitalizadosMain = response.body;
      for (const docDigitalizado of this.docsDigitalizadosMain) {
        if (!docDigitalizado.preDefinidosID) {
          docDigitalizado.preDefinidosID = undefined;
          docDigitalizado.preDefinidoNome = undefined;
        }
      }
      this._evaluateGroupsSortOrder();
    } finally {
      this._initialDocsDigitalizadosMain = copy(this.docsDigitalizadosMain);
      if (this._dataGridInstance) {
        this._dataGridInstance.endCustomLoading();
        this._dataGridInstance.refresh(true);
      }
    }
  }

  public readonly fnRefreshDocsDigitalizado: () => Promise<void> = () => this._refreshDocsDigitalizado();

  public readonly fnSaveDocsDigitalizados: () => Promise<void> = () => this._saveDocsDigitalizados();

  public readonly fnCalculateFolderGroupValue: (line: IDocDigitalizado) => string = (line: IDocDigitalizado) => this._calculateFolderGroupValue(line);

  public readonly fnAssociarDocs: (line: IDocDigitalizado, docContabilidade: IDocContabilidade) => () => Promise<void> =
    (line: IDocDigitalizado, docContabilidade: IDocContabilidade) => (): Promise<void> =>
      this._associarDocsContab([line], docContabilidade);

  @HostListener('window:scroll', [])
  public onWindowScroll(): void {
    const toolbar: HTMLElement = document.querySelector('div.dx-datagrid-header-panel');
    if (toolbar) {
      const header: HTMLElement = document.querySelector('.dx-datagrid-headers.dx-datagrid-nowrap');
      header.style.top = `${TOOLBAR_STICKY_TOP + toolbar.offsetHeight}px`;
    }
  }

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private async _refreshDocsDigitalizado(): Promise<void> {
    if (this._hasLineChanges()) {
      await this._cgModalService.showOkCancel('arquivodigital.gestaodocsdigitalizados.messages.updateWithChangesTitle', 'arquivodigital.gestaodocsdigitalizados.messages.updateWithChangesMessage');
    }
    await this.loadDocsDigitalizados();
  }

  private _changedClientConnectMode(value: boolean = this.clientConnectMode): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.clientConnectMode = val;
    this._evaluateDisabled();
  }

  private _evaluateDisabled(): void {
    this.disabled = this.clientConnectMode || (!this.temContabilidadeDigital && this.modoCGOn);
  }

  private _saveDocsDigitalizados(): Promise<void> {
    const columns: Array<Column> = this._dataGridInstance.getVisibleColumns();
    const visibleColumnTotalBase = columns.findIndex((column: Column) => column.dataField === 'totalBase') !== -1;
    const visibleColumnTotalIva = columns.findIndex((column: Column) => column.dataField === 'totalIVA') !== -1;
    const marcaTotaisComoOk = !visibleColumnTotalBase && !visibleColumnTotalIva;
    const docsDigitalizados: Array<IDocDigitalizado> = [];
    for (const doc of this.docsDigitalizadosMain) {
      if (doc._changed) {
        docsDigitalizados.push(doc);
      }
    }
    if (!docsDigitalizados.length) {
      this._plAlertService.error('arquivodigital.gestaodocsdigitalizados.messages.naotemalteracoes');
      return Promise.resolve();
    }
    this._dataGridInstance.beginCustomLoading(undefined);
    return this._contabilidadeDigitalUIService.documents
      .saveDocsDigitalizados(marcaTotaisComoOk, docsDigitalizados)
      .then((response: HttpResponse<Array<IDocDigitalizado>>) => {
        for (const docDigit of response.body) {
          this._generateLegendDocScanned(docDigit);
          const doc: IDocDigitalizado = docsDigitalizados.find((value: IDocDigitalizado) => value.docOCRCabID === docDigit.docOCRCabID);
          if (doc) {
            docDigit._changed = false;
            docDigit._classified = doc._classified;
            docDigit._docContabilidade = doc._docContabilidade;
            docDigit._detailExpanded = doc._detailExpanded;
            const indexMain: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === docDigit.docID);
            const indexInitial: number = this._initialDocsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === docDigit.docID);
            if (indexMain !== -1) {
              this.docsDigitalizadosMain[indexMain] = docDigit;
              this._initialDocsDigitalizadosMain[indexInitial] = copy(docDigit);
            }
          }
        }
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
        this._dataGridInstance.refresh(true);
      });
  }

  private _generateLegendDocScanned(docDigitalizado: IDocDigitalizado): void {
    if (!docDigitalizado.associadoADocContab && !this.disabled) {
      if (docDigitalizado.status !== EDocOCRCabStatus.AEsperaDeOCR) {
        if (docDigitalizado.nif === '') {
          docDigitalizado._legendNif = ERROR_BACKGROUND;
        }
        if (!docDigitalizado.temNIFEmpresa) {
          docDigitalizado._legendTemNifEmp = ERROR_BACKGROUND;
        }
        if (docDigitalizado.nDocumento === '') {
          docDigitalizado._legendNDocumento = ERROR_BACKGROUND;
        }
        if (moment(docDigitalizado.dataDoc).isSame(MIN_DATE, 'day') || docDigitalizado.isDataSuperiorAoMes) {
          docDigitalizado._legendDataDoc = ERROR_BACKGROUND;
        }
        if (!docDigitalizado.totaisOK) {
          docDigitalizado._legendTotalBase = WARNING_BACKGROUND;
          docDigitalizado._legendTotalIva = WARNING_BACKGROUND;
          docDigitalizado._legendTotal = docDigitalizado.total === 0 ? ERROR_BACKGROUND : WARNING_BACKGROUND;
        }
        if (docDigitalizado.invoiceType === EInvoiceType.None) {
          docDigitalizado._legendInvoiceType = WARNING_BACKGROUND;
        }
      } else {
        docDigitalizado._legendWaitingOCR = WAITING_OCR_BACKGROUND;
      }
    }
  }

  private _generateSecondaryClickActions(isByFolder: boolean, adicionarDocFolderID?: string): Array<IPlDynamicVisualsSecondaryClickAction> {
    const actions: Array<IPlDynamicVisualsSecondaryClickAction> = [
      {
        caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.actions.adicionardoc',
        disabled: this.actionsDisabled,
        click: () => this.callAction(this.typeAction.AdicionarDoc, adicionarDocFolderID)
      }
    ];
    if (!isByFolder) {
      actions.push(
        {
          caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.actions.mudardocpasta',
          disabled: !this.selectedLines.length || this.actionsDisabled || this.selectedLine.associadoADocContab,
          click: () => this.callAction(this.typeAction.MudarDocPasta)
        },
        {
          caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.actions.reaplicarocr',
          disabled: !this.selectedLine || this.selectedLine.associadoADocContab || this.selectedLines.length > 1 || this.actionsDisabled,
          click: () => this.callAction(this.typeAction.ReaplicarOCR)
        },
        {
          caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.actions.obterDadosEFatura',
          disabled: !this.selectedLines.length || this.actionsDisabled,
          click: () => this.callAction(this.typeAction.ObterDadosEFatura)
        },
        {
          caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.actions.atribuirTipoDocumento',
          disabled: !this.selectedLines.length || this.actionsDisabled,
          click: () => this.callAction(this.typeAction.AtribuirTipoDocumento)
        },
        {
          caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.actions.unirDocs',
          disabled: this.selectedLines.length < 2 || this.actionsDisabled,
          click: () => this.callAction(this.typeAction.UnirDocs)
        },
        {
          caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.actions.separarDoc',
          disabled: !this.selectedLine || this.selectedLines.length > 1 || this.actionsDisabled,
          click: () => this.callAction(this.typeAction.SepararDoc)
        },
        {
          caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.actions.copiarDoc',
          disabled: !this.selectedLine || this.selectedLines.length > 1 || this.actionsDisabled,
          click: () => this.callAction(this.typeAction.CopiarDoc)
        },
        {
          caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.actions.removePages',
          disabled: !this.selectedLine || this.selectedLines.length > 1 || this.actionsDisabled,
          click: () => this.callAction(this.typeAction.RemovePages)
        },
        {
          caption: !this.selectedLine?._classified ? 'arquivodigital.gestaodocsdigitalizados.table.fields.actions.apagar' : 'arquivodigital.gestaodocsdigitalizados.actions.removeassociation',
          disabled: !this.selectedLines.length || this.actionsDisabled,
          click: () => this.callAction(this.typeAction.Apagar)
        }
      );
      if (this.mode !== EGestaoDocumentosDigitalizadosMode.Conciliacao) {
        actions.push(
          {divider: true},
          {
            caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.actions.verDoc',
            disabled: !this.selectedLine?.associadoADocContab || this.actionsDisabled,
            click: () => {
              this.openDoc(this.selectedLine);
            }
          }
        );
      }
      actions.push(
        {divider: true},
        {
          caption: 'arquivodigital.gestaodocsdigitalizados.actions.possiveisDocsRepetidos',
          disabled: !this.selectedLine || isEmpty(this.selectedLine.idDocOCRCabRepetidoNIFDoc) || this.selectedLines.length > 1 || this.actionsDisabled,
          click: () => {
            this.callAction(this.typeAction.PossiveisDocsRepetidos);
          }
        },
        {divider: true},
        {
          caption: 'arquivodigital.gestaodocsdigitalizados.actions.extratoConta',
          disabled: !this.selectedLine || isEmpty(this.selectedLine.nContaEntidade) || this.selectedLines.length > 1 || this.actionsDisabled,
          click: () => {
            this.callAction(this.typeAction.ExtratoConta);
          }
        },
        {divider: true},
        {
          caption: 'arquivodigital.gestaodocsdigitalizados.table.fields.actions.novaContaCC',
          disabled: !this.selectedLine || this.selectedLine.entidade !== '' || this.selectedLines.length > 1 || this.actionsDisabled,
          click: () => this.callAction(this.typeAction.NovaContaCC)
        }
      );
      if (this.mode !== EGestaoDocumentosDigitalizadosMode.Conciliacao) {
        actions.push(
          {divider: true},
          {
            caption: 'arquivodigital.gestaodocsdigitalizados.actions.predefinidos',
            disabled: !this.selectedLine || this.selectedLines.length > 1 || this.actionsDisabled,
            click: () => this.callAction(this.typeAction.PreDefinidos)
          }
        );
      }
    }
    return actions;
  }

  private _evaluateDocsDigitalizados(docsDigitalizados: Array<IDocDigitalizado>): void {
    for (const docsDigitalizado of docsDigitalizados) {
      docsDigitalizado._classified = docsDigitalizado.folder.split(CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR)[0] === DOCUMENTOS_CLASSIFICADOS;
      this._generateLegendDocScanned(docsDigitalizado);
    }
  }

  private _removedAssociationDocs(docsDigitalizadosRemovedAssociation: Array<IDocDigitalizado>): void {
    for (const docDigitalizado of this.selectedLines) {
      const indexMain: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === docDigitalizado.docID);
      const indexInitial: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === docDigitalizado.docID);
      if (indexMain !== -1) {
        this.docsDigitalizadosMain.splice(indexMain, 1);
        this._initialDocsDigitalizadosMain.splice(indexInitial, 1);
      }
    }
    this._evaluateDocsDigitalizados(docsDigitalizadosRemovedAssociation);
    for (const docDigitalRemovedAssociation of docsDigitalizadosRemovedAssociation) {
      this.docsDigitalizadosMain.push(docDigitalRemovedAssociation);
      this._initialDocsDigitalizadosMain.push(docDigitalRemovedAssociation);
    }
    this._sortDocsDigitalizadosMain();
    this._dataGridInstance.deselectAll().then(() => {
      this.selectedLine = undefined;
      this.selectedLines = [];
      this.evtChangedSelectedLine.emit(this.selectedLine);
      this.evtChangedMultiSelectedLine.emit(this.selectedLines);
      this._dataGridInstance.refresh(true);
    });
  }

  private _deleteDocs(docsDigitalizadosDeleted: Array<IDocDigitalizado>): void {
    this.selectedLine = undefined;
    this.selectedLines = [];
    this.evtChangedSelectedLine.emit(this.selectedLine);
    this.evtChangedMultiSelectedLine.emit(this.selectedLines);

    for (const docDigitalizadoDeleted of docsDigitalizadosDeleted) {
      const indexMain: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === docDigitalizadoDeleted.docID);
      const indexInitial: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === docDigitalizadoDeleted.docID);
      if (indexMain !== -1) {
        this.docsDigitalizadosMain.splice(indexMain, 1);
        this._initialDocsDigitalizadosMain.splice(indexInitial, 1);
      }
    }

    this._dataGridInstance.deselectAll().then(() => this._dataGridInstance.refresh(true));
  }

  private _moveDocFolder(docsDigitalizadosMoved: Array<IDocDigitalizado>): void {
    for (const movedDocDigitalizado of docsDigitalizadosMoved) {
      const indexMain: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === movedDocDigitalizado.docID);
      if (indexMain !== -1) {
        movedDocDigitalizado._changed = this.docsDigitalizadosMain[indexMain]._changed;
        movedDocDigitalizado._classified = this.docsDigitalizadosMain[indexMain]._classified;
        movedDocDigitalizado._legendDataDoc = this.docsDigitalizadosMain[indexMain]._legendDataDoc;
        movedDocDigitalizado._legendFiscalmenteRelevante = this.docsDigitalizadosMain[indexMain]._legendFiscalmenteRelevante;
        movedDocDigitalizado._legendInvoiceType = this.docsDigitalizadosMain[indexMain]._legendInvoiceType;
        movedDocDigitalizado._legendNDocumento = this.docsDigitalizadosMain[indexMain]._legendNDocumento;
        movedDocDigitalizado._legendNif = this.docsDigitalizadosMain[indexMain]._legendNif;
        movedDocDigitalizado._legendTemNifEmp = this.docsDigitalizadosMain[indexMain]._legendTemNifEmp;
        movedDocDigitalizado._legendTotal = this.docsDigitalizadosMain[indexMain]._legendTotal;
        movedDocDigitalizado._legendTotalBase = this.docsDigitalizadosMain[indexMain]._legendTotalBase;
        movedDocDigitalizado._legendWaitingOCR = this.docsDigitalizadosMain[indexMain]._legendWaitingOCR;
        movedDocDigitalizado._legendTotalIva = this.docsDigitalizadosMain[indexMain]._legendTotalIva;
        movedDocDigitalizado._legendTotalIva = this.docsDigitalizadosMain[indexMain]._legendTotalIva;
        this.docsDigitalizadosMain[indexMain] = movedDocDigitalizado;

        const indexInitial: number = this._initialDocsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === movedDocDigitalizado.docID);
        this._initialDocsDigitalizadosMain[indexInitial].folderID = movedDocDigitalizado.folderID;
        this._initialDocsDigitalizadosMain[indexInitial].folder = movedDocDigitalizado.folder;
        this._initialDocsDigitalizadosMain[indexInitial].fileName = movedDocDigitalizado.fileName;
        this._initialDocsDigitalizadosMain[indexInitial].classificadoPor = movedDocDigitalizado.classificadoPor;

        this._evaluateGroupsSortOrder();
        this._dataGridInstance.refresh(true);
      }
    }
  }

  private _refazerOCR(docDigitalizado: IDocDigitalizado): void {
    this._updateDocLineOnAction(docDigitalizado);
    this._dataGridInstance.refresh(true);
  }

  private _unirDocs(docDigitalizadoUnido: IDocDigitalizado): void {
    for (const docByMulti of this.selectedLines) {
      const indexMain: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === docByMulti.docID);
      const indexInitial: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === docByMulti.docID);
      if (indexMain !== -1) {
        if (docByMulti.docID !== docDigitalizadoUnido.docID) {
          this.docsDigitalizadosMain.splice(indexMain, 1);
          this._initialDocsDigitalizadosMain.splice(indexInitial, 1);
        }
      }
    }
    this._updateDocLineOnAction(docDigitalizadoUnido);
    this._dataGridInstance.deselectAll().then(() => {
      this.selectedLine = undefined;
      this.selectedLines = [];
      this.evtChangedSelectedLine.emit(this.selectedLine);
      this.evtChangedMultiSelectedLine.emit(this.selectedLines);
      this._dataGridInstance.refresh(true);
    });
  }

  private _obterDadosEFatura(docsDigitalizados: Array<IDocDigitalizado>): void {
    for (const docDigitalizado of docsDigitalizados) {
      const index: number = this.selectedLines.findIndex((value: IDocDigitalizado) => value.docID === docDigitalizado.docID);
      if (index !== -1) {
        this._updateDocLineOnAction(docDigitalizado);
      }
    }
    this._dataGridInstance.refresh(true);
  }

  private _preDefinido(docsResponse: Array<IDocDigitalizado>, docsDigitalizados: Array<IDocDigitalizado>): void {
    for (const doc of docsDigitalizados) {
      const index = docsResponse.findIndex((value: IDocDigitalizado) => value.docOCRCabID === doc.docOCRCabID);
      const indexMain = this.docsDigitalizadosMain.findIndex((value: IDocDigitalizado) => value.docOCRCabID === doc.docOCRCabID);
      if (index !== -1 && indexMain !== -1) {
        doc.preDefinidosID = docsResponse[index].preDefinidosID > 0 ? docsResponse[index].preDefinidosID : undefined;
        doc.preDefinidoNome = docsResponse[index].preDefinidosID > 0 ? docsResponse[index].preDefinidoNome : '';
        this.docsDigitalizadosMain[indexMain].preDefinidosID = doc.preDefinidosID;
        this.docsDigitalizadosMain[indexMain].preDefinidoNome = doc.preDefinidoNome;

        this.changedValueLine(this.docsDigitalizadosMain[indexMain]);
      }
    }
    this._dataGridInstance.refresh(true);
  }

  private _separarDoc(docsDigitalizadosSeparados: Array<IDocDigitalizado>): void {
    for (const docDigitalizadoSep of docsDigitalizadosSeparados) {
      docDigitalizadoSep._classified = this.selectedLine._classified;
      this._generateLegendDocScanned(docDigitalizadoSep);
      this.docsDigitalizadosMain.push(docDigitalizadoSep);
      this._initialDocsDigitalizadosMain.push(docDigitalizadoSep);
    }
    this._dataGridInstance.refresh(true);
  }

  private _setTypeDoc(invoiceType: number): void {
    for (const docDigitalizado of this.selectedLines) {
      const indexMain: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === docDigitalizado.docID);
      const indexInitial: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === docDigitalizado.docID);
      if (indexMain !== -1) {
        this.docsDigitalizadosMain[indexMain].invoiceType = this._initialDocsDigitalizadosMain[indexInitial].invoiceType = invoiceType;
        this.docsDigitalizadosMain[indexMain].invoiceTypeStr = this._initialDocsDigitalizadosMain[indexInitial].invoiceTypeStr = this._translateService.instant(
          DATA_SOURCE_INVOICE_TYPE.data[invoiceType].name
        );
        this.changedValueLine(this.docsDigitalizadosMain[indexMain]);
        this._dataGridInstance.refresh(true);
      }
    }
    this.evtChangedMultiSelectedLine.emit(this.selectedLines);
    this.evtChangedSelectedLine.emit(this.selectedLine);
  }

  private _newCCAccount(doc: IDocDigitalizado, entidade: string): void {
    const indexMain: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === doc.docID);
    let indexInitial: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === doc.docID);
    if (indexMain !== -1) {
      this.docsDigitalizadosMain[indexMain].entidade = entidade;
      this._initialDocsDigitalizadosMain[indexInitial].entidade = entidade;
    }
    for (const linha of this.docsDigitalizadosMain) {
      if (linha !== doc && linha.nif === doc.nif) {
        linha.entidade = doc.entidade;
        indexInitial = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === doc.docID);
        if (indexInitial !== -1) {
          this._initialDocsDigitalizadosMain[indexInitial].entidade = entidade;
        }
      }
    }
    this._dataGridInstance.refresh(true);
  }

  private _copyDocument(docDigitalizadoCopied: IDocDigitalizado): void {
    docDigitalizadoCopied._classified = this.selectedLine._classified;
    this._generateLegendDocScanned(docDigitalizadoCopied);

    this.docsDigitalizadosMain.push(docDigitalizadoCopied);
    this._initialDocsDigitalizadosMain.push(docDigitalizadoCopied);
    this._dataGridInstance.refresh(true);
  }

  private _hasLineChanges(): boolean {
    const index = this.docsDigitalizadosMain.findIndex((value: IDocDigitalizado) => value._changed);
    return index !== -1;
  }

  private _updateDocLineOnAction(newLineDoc: IDocDigitalizado): void {
    newLineDoc._changed = false;
    newLineDoc._classified = newLineDoc.folder.split(CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR)[0] === DOCUMENTOS_CLASSIFICADOS;
    this._generateLegendDocScanned(newLineDoc);
    const indexMain: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === newLineDoc.docID);
    const indexInitial: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === newLineDoc.docID);
    if (indexMain !== -1) {
      this.docsDigitalizadosMain[indexMain] = newLineDoc;
      this._initialDocsDigitalizadosMain[indexInitial] = newLineDoc;
    }
    this.changedValueLine(this.docsDigitalizadosMain[indexMain]);
  }

  private async _onRowUpdated(data: IDocDigitalizado, component: dxDataGrid, saveEditData: boolean = true): Promise<void> {
    if (saveEditData) {
      await component.saveEditData();
    }
    const index: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === data.docID);
    if (index === -1) {
      return;
    }
    this.docsDigitalizadosMain[index] = data;
    const linha: IDocDigitalizado = this.docsDigitalizadosMain[index];
    const previousChanged: boolean = linha._changed;
    this.changedValueLine(linha);
    if (linha._changed !== previousChanged) {
      component.refresh(true);
    }
  }

  private _sortDocsDigitalizadosMain(): void {
    this._contabilidadeDigitalUIService.documents.sortDocsDigitalizados(this.docsDigitalizadosMain);
    this._evaluateGroupsSortOrder();
  }

  private _evaluateGroupsSortOrder(): void {
    this._groupsIndexes.clear();
    const folders: Array<string> = [];
    for (const docDigitalizado of this.docsDigitalizadosMain) {
      if (!folders.includes(docDigitalizado.folder)) {
        folders.push(docDigitalizado.folder);
        this._groupsKeysIndexes.set(docDigitalizado.folder, docDigitalizado.folderID);
      }
    }
    this._groupsIndexes.clear();
    for (let index = 0; index < folders.length; index++) {
      const digits = index.toString(ERadix.Decimal).split('');
      for (let i = digits.length - 1; i < folders.length; i++) {
        digits.unshift('0');
      }
      const sortValue: string = digits.map((number: string) => String.fromCharCode(ANSI_FIRST_LETTER + toInteger(number))).join('');
      this._groupsIndexes.set(folders[index], sortValue);
    }
  }

  private _calculateFolderGroupValue(line: IDocDigitalizado): string {
    const sortValue: string = this._groupsIndexes.get(line.folder);
    return sortValue + DATA_GRID_SORT_DELIMITER + line.folder;
  }

  private async _dataGridExportGenerateFilename(): Promise<string> {
    const session: TUserSession = await this._authService.identity();
    return `${DATA_GRID_EXPORT_FILENAME}_${session.erp.nEmpresa}_${moment().format('YYYYMMDD')}`;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _dataGridExportCustomizeCell(gridCell: IDevExpressDataGridExcelDataGridCell | IDevExpressDataGridPdfDataGridCell, documentCell: any): void {
    if (gridCell.rowType === 'group' && isString(gridCell.value)) {
      const folderGroupValues: Array<string> = gridCell.value.split(DATA_GRID_SORT_DELIMITER);
      if (folderGroupValues.length >= 2) {
        const folder: string = folderGroupValues[1];
        // PDF
        if (Object.prototype.hasOwnProperty.call(documentCell, 'content')) {
          documentCell.content = folder;
        }
        // Excel
        else {
          documentCell.value = folder;
        }
      }
    }
  }

  private async _suggestDocDigitalizadoDetail(docDigitalizado: IDocDigitalizado): Promise<void> {
    if (this.mode !== EGestaoDocumentosDigitalizadosMode.Comercial) {
      const docContabilidade: IJsonDocContabilidade = await this._docsContabilidadeService.novoByDocOCRCabID(docDigitalizado.docOCRCabID, this._docContabilidadeService.emptyDoc(), true);
      // Since previously expanded rows's views aren't destroyed we have to force refresh jsons
      delete docDigitalizado._docContabilidade;
      await timeout();
      // Init models
      docDigitalizado._docContabilidade = docContabilidade;
    } else if (this.mode === EGestaoDocumentosDigitalizadosMode.Comercial) {
      const response: HttpResponse<IDocComercial> = await this._serviceDocsComerciais.novoByDocOCRCabID(docDigitalizado.docOCRCabID);
      // Since previously expanded rows's views aren't destroyed we have to force refresh jsons
      delete docDigitalizado._docComercial;
      await timeout();
      // Init models
      docDigitalizado._docComercial = response.body;
    }
    this._initDocDigitalizadoDetailToolbar(docDigitalizado);
  }

  private _initDocDigitalizadoDetailToolbar(docDigitalizado: IDocDigitalizado): void {
    let toolbarInstanceName = '';
    if (this.mode !== EGestaoDocumentosDigitalizadosMode.Comercial) {
      if (!docDigitalizado._docContabilidadeToolbarInstanceId) {
        toolbarInstanceName = docDigitalizado._docContabilidadeToolbarInstanceId = `${TOOLBAR_NAME_CONTAB}-${docDigitalizado.docID}`;
      }
    } else if (!docDigitalizado._docComercialToolbarInstanceId) {
      toolbarInstanceName = docDigitalizado._docComercialToolbarInstanceId = `${TOOLBAR_NAME_COMERCIAL}-${docDigitalizado.docID}`;
    }

    const toolbarInstance: IPlToolbarInstance = this._plToolbarService.getInstance(toolbarInstanceName);
    toolbarInstance.setItems([
      {
        groupId: TOOLBAR_GROUP_ID,
        id: TOOLBAR_BTN_SAVE_ATTACH,
        order: 110,
        type: 'button',
        iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>',
        caption: 'entity.action.save',
        class: 'btn-primary',
        click: () => this._saveTableDoc(docDigitalizado)
      }
    ]);
    this._registeredToolbarInstances.set(toolbarInstanceName, toolbarInstance);
  }

  private async _saveTableDoc(docDigitalizado: IDocDigitalizado): Promise<void> {
    await this._saveDoc(docDigitalizado);
    const indexMain: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === docDigitalizado.docID);
    const indexInitial: number = this._initialDocsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === docDigitalizado.docID);
    let nextRow: IDocDigitalizado;
    if (indexMain !== -1 && indexInitial !== -1) {
      if (this.docsDigitalizadosHeader.mostrarDocClassificados) {
        nextRow = this.docsDigitalizadosMain[indexMain + 1] || this.docsDigitalizadosMain[indexMain + 1];
        this._dataGridInstance.beginCustomLoading(undefined);
        const response: HttpResponse<IJsonDocDigitalizado> = await this._contabilidadeDigitalUIService.getDocDigitalizado(docDigitalizado.docOCRCabID).finally(() => {
          this._dataGridInstance.endCustomLoading();
        });
        const updatedDocDigitalizado: IDocDigitalizado = response.body;
        updatedDocDigitalizado._classified = updatedDocDigitalizado.folder.split(CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR)[0] === DOCUMENTOS_CLASSIFICADOS;
        this._generateLegendDocScanned(updatedDocDigitalizado);
        this.docsDigitalizadosMain[indexMain] = updatedDocDigitalizado;
        this._initialDocsDigitalizadosMain[indexInitial] = updatedDocDigitalizado;
      } else {
        this.docsDigitalizadosMain.splice(indexMain, 1);
        this._initialDocsDigitalizadosMain.splice(indexInitial, 1);
        nextRow = this.docsDigitalizadosMain[indexMain];
        this._evaluateGroupsSortOrder();
      }
    }
    this._evaluateGroupsSortOrder();
    this._dataGridInstance.repaint();
    if (nextRow) {
      await this._selectNextRowAfterAction(nextRow, true);
    }
  }

  private _saveDoc(docDigitalizado: IDocDigitalizado): Promise<IJsonDocContabilidade> | Promise<IDocComercial> {
    if (docDigitalizado._docComercial) {
      const docComercial: IDocComercial = docDigitalizado._docComercial;
      return this._serviceDocsComerciais.postDoc(docComercial, false, true, false, docDigitalizado.docID, docDigitalizado.folderID).then((response: HttpResponse<IDocComercial>) => response.body);
    }

    const docContabilidade: IJsonDocContabilidade = docDigitalizado._docContabilidade;
    const saldado: boolean = this._docContabilidadeService.isDocumentoSaldado(docContabilidade);
    if (!saldado) {
      const message: string = this._translateService.instant('docscontabilidade.erros.notSaldado');
      this._plAlertService.error(message);
      return Promise.reject(new Error(message));
    }
    const params: IDocsContabilidadePostParams = {anexarGDocId: docDigitalizado.docID, anexarGDocFolderId: docDigitalizado.folderID};
    return this._serviceDocsContabilidade.post({body: docContabilidade, params: params}).then((response: HttpResponse<IJsonDocContabilidade>) => response.body);
  }

  private _lancarPorRobo(contabDigitalLancadoRobo: IJsonContabDigitalLancadoRobo): Promise<void> {
    for (const error of contabDigitalLancadoRobo.errorList) {
      this._plAlertService.warning(error);
    }

    for (const docDigitalizado of contabDigitalLancadoRobo.docsDigitalizados) {
      const indexMain: number = this.docsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === docDigitalizado.docID);
      const indexInitial: number = this._initialDocsDigitalizadosMain.findIndex((item: IDocDigitalizado) => item.docID === docDigitalizado.docID);
      if (indexMain !== -1 && indexInitial !== -1) {
        this.docsDigitalizadosMain[indexMain] = docDigitalizado;
        this._initialDocsDigitalizadosMain[indexInitial] = docDigitalizado;
        this.docsDigitalizadosMain[indexMain]._classified = this.docsDigitalizadosMain[indexMain].folder.split(CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR)[0] === DOCUMENTOS_CLASSIFICADOS;
        this._initialDocsDigitalizadosMain[indexInitial]._classified =
          this.docsDigitalizadosMain[indexMain].folder.split(CONTAB_DIGITAL_DOC_VIEWER_RECOLHA_LOCATION_SEPARATOR)[0] === DOCUMENTOS_CLASSIFICADOS;
        this._generateLegendDocScanned(this.docsDigitalizadosMain[indexMain]);

        if (!this.docsDigitalizadosHeader.mostrarDocClassificados && this.docsDigitalizadosMain[indexMain]._classified) {
          this.docsDigitalizadosMain.splice(indexMain, 1);
          this._initialDocsDigitalizadosMain.splice(indexInitial, 1);

          this._selectNextRowAfterAction(this.docsDigitalizadosMain[indexMain]);
        }
      }
      const message: string = this._plTranslateService.translate('arquivodigital.gestaodocsdigitalizados.messages.lancrobotdoc', {
        filename: docDigitalizado.fileName,
        ndocumentointerno: docDigitalizado.nDocInterno
      });
      this._plAlertService.info(message);
    }
    this._evaluateGroupsSortOrder();
    return this._dataGridInstance.refresh(true);
  }

  private async _selectNextRowAfterAction(nextRow: IDocDigitalizado, openDoc: boolean = false): Promise<void> {
    nextRow = !nextRow ? undefined : nextRow;
    if (nextRow) {
      await this._dataGridInstance.selectRows([nextRow.docID], false);
    }

    this.selectedLine = nextRow;
    this.evtChangedSelectedLine.emit(this.selectedLine);

    if (!nextRow) {
      this.selectedLines = [];
      this.evtChangedMultiSelectedLine.emit(this.selectedLines);
    }

    if (openDoc && isDefinedNotNull(nextRow)) {
      this.openDoc(nextRow);
    }
  }

  private async _openMaintenancePreDefinidos(): Promise<void> {
    if (Boolean(this.selectedLine) && isNumber(this.selectedLine.preDefinidosID) && this.selectedLine.preDefinidosID > 0) {
      await this._maintenancePreDefinidos.maintenanceEdit(this.selectedLine.preDefinidosID);
    }
  }

  private _associarDocsContab(docsDigitalizados: Array<IDocDigitalizado>, docContabilidade?: IDocContabilidade, incluiAnulados: boolean = false): Promise<void> {
    const list: Array<IJsonContabDigitalAssociaDoc> = [];
    for (const docDigitalizado of docsDigitalizados) {
      list.push({
        docID: docDigitalizado.docID,
        folderID: docDigitalizado.folderID,
        extPocCabIDDocConciliacao: docDigitalizado.extPocCabIDDocConciliacao,
        periodo: docContabilidade?.periodo,
        nDocInterno: docContabilidade?.nDocInterno,
        nDiario: docContabilidade?.nDiario,
        tipoDocumentosIdentificadosContab: docDigitalizado.tipoDocsIdentContab,
        fiscalmenteRelevante: docDigitalizado.fiscalmenteRelevante
      });
    }
    return this._contabilidadeDigitalUIService.documents.associarDocumentos(list, incluiAnulados).then((response: HttpResponse<Array<IJsonContabDigitalAssociaDoc>>) => {
      for (const item of response.body) {
        const index = docsDigitalizados.findIndex((doc: IDocDigitalizado) => doc.docID === item.docID);
        if (index !== -1) {
          docsDigitalizados.splice(index, 1);
        }
      }
      this._deleteDocs(docsDigitalizados);
      this._plAlertService.success('arquivodigital.gestaodocsdigitalizados.messages.docsassociados');
    });
  }

  private _checkInputFields(): boolean {
    if (this.docsDigitalizadosHeader.nifDe > this.docsDigitalizadosHeader.nifAte) {
      this._plAlertService.error('arquivodigital.errors.nifFinalInferiorInicial');
      return false;
    }
    if (this.docsDigitalizadosHeader.dataDocExternoDe > this.docsDigitalizadosHeader.dataDocExternoAte) {
      this._plAlertService.error('arquivodigital.errors.dataDocExtFinalInferiorInicial');
      return false;
    }
    if (this.docsDigitalizadosHeader.dataRececaoDe > this.docsDigitalizadosHeader.dataRececaoAte) {
      this._plAlertService.error('arquivodigital.errors.dataRecFinalInferiorInicial');
      return false;
    }
    if (this.docsDigitalizadosHeader.docExternoDe > this.docsDigitalizadosHeader.docExternoAte) {
      this._plAlertService.error('arquivodigital.errors.docExtFinalInferiorInicial');
      return false;
    }
    return true;
  }
}
