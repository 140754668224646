import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonRHSalToTrf, IJsonRHSalToTrfDadosTransf} from '../../jsonRHSalToTrf.module.interface';
import {TDate} from '../../../../../../common/dates';
import {isEmpty, PlAlertService} from 'pl-comps-angular';
import {RHSalToTrfService} from '../../rhSalToTrf.module.service';
import {HttpResponse} from '@angular/common/http';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import moment from 'moment';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IJsonMeioPagamento} from '../../../../../entities/meiospagamento/jsonMeioPagamento.entity.interface';

@Component({
  selector: 'modal-rhsaltotrf-dadostransf',
  templateUrl: './rhSalToTrf.dadosTransf.modal.component.html'
})
export class RHSalToTrfDadosTransfModalComponent extends CGModalComponent<void> {
  @Input() public cartaoRef: boolean;
  @Input() public rhSalToTrfSelList: Array<IJsonRHSalToTrf>;
  @Input() public dataTransf: TDate;

  public readonly definition: IDevExpressDataGrid<IJsonRHSalToTrf, IJsonRHSalToTrf>;

  public promise: Promise<void>;
  public nCaixa: string;
  public dadosTransf: IJsonRHSalToTrfDadosTransf;
  public showDadosTransf: boolean;
  public meiosPagamentoList: Array<IJsonMeioPagamento>;

  private _dataGridInstance: dxDataGrid<IJsonRHSalToTrf, IJsonRHSalToTrf>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _rhSalToTrfService: RHSalToTrfService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.rhSalToTrfSelList = [];
    this.cartaoRef = false;
    this.showDadosTransf = false;
    this.dataTransf = moment();

    this.definition = {
      columns: [
        {dataField: 'codEmpregado', caption: 'rhsaltotrf.table.emp', dataType: 'string', format: {suppressZeros: true}},
        {dataField: 'nomeEmpregado', caption: 'rhsaltotrf.table.nome', dataType: 'string', format: {suppressZeros: true}},
        {dataField: 'valor', caption: 'rhsaltotrf.table.valor', dataType: 'number', format: {suppressZeros: true}},
        {dataField: 'dataLancamento', caption: 'rhsaltotrf.table.datalanc', dataType: 'date', format: {suppressZeros: true}},
        {dataField: 'iban', caption: 'rhsaltotrf.table.iban', dataType: 'string', format: {suppressZeros: true}},
        {dataField: 'swift', caption: 'rhsaltotrf.table.swift', dataType: 'string', format: {suppressZeros: true}},
        {
          dataField: 'actions',
          caption: '',
          cellTemplate: 'actions',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowFixing: false,
          allowGrouping: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSearch: false,
          allowSorting: false,
          showInColumnChooser: false,
          width: 150,
          visible: this.cartaoRef
        }
      ],
      dataSource: new CustomStore({
        load: () => this._getTransferencias()
      }),
      height: '50vh',
      pager: {visible: false},
      paging: {enabled: false},
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'},
      showBorders: true
    };
    this.dadosTransf = {
      bancoDesc: '',
      bancoEmi: '',
      agenciaEmi: '',
      ibanEmi: '',
      swiftEmi: '',
      moedaEmi: '',
      formato: ''
    };
    this.meiosPagamentoList = [];
    this._getMeiosPagamento();
  }

  public onInitialized(event: IDevExpressDataGridEventOnInitialized<IJsonRHSalToTrf, IJsonRHSalToTrf>): void {
    this._dataGridInstance = event.component;
  }

  public async close(): Promise<void> {
    if (isEmpty(this.nCaixa)) {
      this._plAlertService.error('rhsaltotrf.messages.nCaixaRequired');
      return;
    }
    await this._rhSalToTrfService.gravarDadosTransf(this.nCaixa, this.dataTransf, this.cartaoRef, this.rhSalToTrfSelList);
    this._plAlertService.success('rhsaltotrf.messages.listatransfsuccess');
    super.close();
  }

  public meiosPagamentoChanged(value: {item: IJsonMeioPagamento}): Promise<void> {
    this.nCaixa = value.item.nCaixa.toString();
    this.promise = this._rhSalToTrfService
      .getDadosNCaixaPagadora(this.nCaixa, this.cartaoRef)
      .then((response: HttpResponse<IJsonRHSalToTrfDadosTransf>) => {
        this.dadosTransf = response.body;
        this.showDadosTransf = Boolean(this.dadosTransf.ibanEmi);
      })
      .catch(() => {
        this.showDadosTransf = false;
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  public corrigeCartao(item: IJsonRHSalToTrf): Promise<void> {
    this.promise = this._rhSalToTrfService
      .corrigeCartao(this.dadosTransf.bancoEmi, item)
      .then((response: HttpResponse<IJsonRHSalToTrf>) => {
        const index: number = this.rhSalToTrfSelList.findIndex((value: IJsonRHSalToTrf) => value.codEmpregado === response.body.codEmpregado);
        if (index !== -1) {
          this.rhSalToTrfSelList[index] = response.body;
          this._dataGridInstance.refresh();
        }
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  public removeTransf(item: IJsonRHSalToTrf): Promise<void> {
    this.promise = this._cgModalService
      .showOkCancel('global.text.confirmation', 'rhsaltotrf.messages.temcertezaremtransf')
      .then(() => {
        const index: number = this.rhSalToTrfSelList.findIndex((value: IJsonRHSalToTrf) => value.codEmpregado === item.codEmpregado);
        if (index !== -1) {
          this.rhSalToTrfSelList.splice(index, 1);
          this._dataGridInstance.refresh();
        }
      })
      .finally(() => {
        this.promise = undefined;
      });
    return this.promise;
  }

  private _getTransferencias(): Array<IJsonRHSalToTrf> {
    return this.rhSalToTrfSelList;
  }

  private _getMeiosPagamento(): void {
    this._rhSalToTrfService.getMeiosPagamento().then((response: HttpResponse<Array<IJsonMeioPagamento>>) => {
      this.meiosPagamentoList = response.body;
    });
  }
}
