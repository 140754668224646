import {Component, Injector, Input, OnInit, Renderer2} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {DIGITS_INFO_TWO_DECIMALS} from '../../../../../common/data';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnRowPrepared} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonRecibo, IJsonReciboCab} from '../../jsonRecibo.entity.interface';
import {ERecibosTableLegendColors, IReciboCabWithData, IRecibosCabEntityService, TABLE_LEGEND_RECIBOS, TServiceHttpQueryResponseRecibos} from '../../recibos.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {TEntityListAfterRequestFn, TEntityListBeforeRequestFn} from '../../../../components/entity/list/entity.list.component.interface';
import {IPlToolbarItem, IPlToolbarMenuItem} from 'pl-comps-angular';
import {DocFasNumsComunicacaoLoginModalComponent} from '../../../../modules/docfasnumscomunicacaologin/docFasNumsComunicacaoLogin.modal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityServiceQueryRequestConfig} from '../../../../services/entity/entity.service.interface';
import {IJsonAnosFaturacao} from '../../../anosfaturacao/jsonAnosFaturacao.entity.interface';
import {OPERATOR_AND} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {currentYear} from '../../../../../common/dates';
import {anosFaturacaoToToolbarItem} from '../../../docscomerciais/utils/docscomerciais.utils';
import {merge} from 'lodash-es';
import {TTableLegend} from '../../../../components/tablelegend/tablelegend.component.interface';

@Component({
  selector: 'recibos-list',
  templateUrl: './recibos.entity.list.component.html'
})
export class RecibosListComponent extends ModuloEntityListComponent<IJsonReciboCab, IRecibosCabEntityService> implements OnInit {
  @Input() public anosList: Array<IJsonAnosFaturacao>;

  public readonly digitsInfoFloat: string;
  public sum: number;
  public tableLegend: TTableLegend;

  private readonly _btnLoginWebServiceAT: IPlToolbarMenuItem;
  private readonly _menuAnos: IPlToolbarItem<number>;
  private _selectedAno: IPlToolbarMenuItem<number>;
  private _anoEmCurso: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _renderer: Renderer2
  ) {
    super(_injector);
    this.digitsInfoFloat = DIGITS_INFO_TWO_DECIMALS;
    this.sum = 0;

    this._btnLoginWebServiceAT = {
      order: 1,
      caption: 'docfas.loginAT',
      iconLeft: '<i class="fa fa-fw fa-key" aria-hidden="true"></i>',
      click: () => this._configLoginAt()
    };

    this._menuAnos = {
      id: 'anos',
      order: this.btnRefresh.order,
      caption: 'recibos.text.ano',
      type: 'dropdown',
      class: 'btn-primary',
      menu: []
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnConfig.visible = true;
    this.btnConfig.type = 'dropdown';
    this.btnConfig.menu = [this._btnLoginWebServiceAT];
    this.tableLegend = TABLE_LEGEND_RECIBOS;
    this._anoEmCurso = currentYear();
    this._loadMenuAnos();
  }

  public readonly fnBeforeRequest: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequest(queryRequestConfig);
  public readonly fnAfterRequest: TEntityListAfterRequestFn<IJsonReciboCab> = (response: TServiceHttpQueryResponseRecibos<IJsonReciboCab>) => {
    this._afterRequest(response);
  };

  public onDetail({data}: IDevExpressDataGridEventOnCellClick<IReciboCabWithData>): Promise<void> {
    const params =
      this._selectedAno?.data !== this.configurations.empresa.anoEmCursoIRC ? {nconta: data.nConta, ano: this._selectedAno.data, anulado: data.anulado} : {nconta: data.nConta, anulado: data.anulado};
    return this.service.get<IJsonRecibo>({id: data.extPocCabID, params: params}).then((result: HttpResponse<IJsonRecibo>) => {
      data._recibo = result.body;
      if (this._selectedAno?.data !== this.configurations.empresa.anoEmCursoIRC) {
        data._recibo.anoRecibo = this._selectedAno?.data;
      }
    });
  }

  public onDataGridColumnsPreparing(fields: Array<IDevExpressDataGridColumn>): void {
    for (const field of fields) {
      if (field.dataField === 'nDocAsStr') {
        field.filterOperations = ['=', 'contains'];
        break;
      }
    }
  }

  public onDataGridRowPrepared({data, rowElement, rowType}: IDevExpressDataGridEventOnRowPrepared<IJsonReciboCab>): void {
    if (rowType === 'data') {
      let cssClass: string;
      if (data.anulado) {
        cssClass = ERecibosTableLegendColors.ReciboAnulado;
      }

      if (cssClass) {
        this._renderer.addClass(rowElement, cssClass);
      } else if (rowElement.style.backgroundColor) {
        this._renderer.removeClass(rowElement, cssClass);
      }
    }
  }

  private _beforeRequest(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    if (this._selectedAno) {
      if (queryRequestConfig.pesquisa) {
        queryRequestConfig.pesquisa += OPERATOR_AND;
      }
      queryRequestConfig.pesquisa += `ano=${this._selectedAno.data}`;
    }
    return queryRequestConfig;
  }

  private _afterRequest(response: TServiceHttpQueryResponseRecibos<IJsonReciboCab>): void {
    this.sum = response.body.sum;
  }

  private _configLoginAt(): Promise<void> {
    const instance = this._cgModalService.showVanilla(DocFasNumsComunicacaoLoginModalComponent, {size: 'sm'});
    return instance.result;
  }

  private _loadMenuAnos(): void {
    const anos: Array<IPlToolbarMenuItem<number>> = anosFaturacaoToToolbarItem(this.anosList);

    anos.map((itemAno) => {
      if (itemAno.active) {
        this._selectedAno = itemAno;
      }
      return merge(itemAno, {
        click: (menuItem: IPlToolbarMenuItem<number>) => {
          this._anoChanged(menuItem);
        }
      });
    });

    this._menuAnos.caption = this._getAnoCaption();
    this._menuAnos.menu = anos;
    this.toolbar.addButton(this._menuAnos);

    this._anoChanged(this._selectedAno);
  }

  private _anoChanged(menuItem: IPlToolbarMenuItem<number>): Promise<void> {
    if (menuItem.data !== this._anoEmCurso) {
      if (this._selectedAno) {
        this._selectedAno.active = false;
      }
      this._selectedAno = menuItem;
      this._selectedAno.active = true;
      this._anoEmCurso = menuItem.data;
      this._menuAnos.caption = this._getAnoCaption();
    }
    return this.refreshList();
  }

  private _getAnoCaption(): string {
    return this._translateService.instant('toolbar.year', {value: this._anoEmCurso});
  }
}
