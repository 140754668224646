import {merge} from 'lodash-es';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {IPlTooltipConfig, PlAlertService} from 'pl-comps-angular';
import {TranslateService} from '@ngx-translate/core';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ConfigService} from '../../../../services/config/config.service';
import {EDocContabilidadeOrigem} from '../../../../modules/portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {ICGConfigDecimais} from '../../../../services/config/config.service.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonRecibo} from '../../jsonRecibo.entity.interface';
import {IReciboLinha, NDECIMAIS_CAMBIO_DEFAULT, reciboDefault} from '../../recibos.entity.interface';
import {RecibosService} from '../../service/recibos.entity.service';

@Component({
  selector: 'recibo',
  templateUrl: './recibo.component.html'
})
export class ReciboComponent implements OnInit, OnChanges {
  @Input() public recibo: IJsonRecibo;
  @Output() public readonly evtRefreshList: EventEmitter<void>;

  public readonly dataGridDefinition: IDevExpressDataGrid<IReciboLinha>;
  public readonly typeDocContabilidadeOrigem: typeof EDocContabilidadeOrigem;

  public config: ICGConfigDecimais;
  public configTooltip: IPlTooltipConfig;
  public pdfTooltip: IPlTooltipConfig;

  private readonly _defaultRecibo: IJsonRecibo;

  constructor(
    private readonly _recibosService: RecibosService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _configService: ConfigService
  ) {
    this.evtRefreshList = new EventEmitter<void>();
    this.config = this._configService.configurations.contabilidade.decimais;
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'tipoDocumento', dataType: 'string', caption: 'recibos.recibo.linhas.tipoDocumento'},
        {dataField: 'numeroDocumento', dataType: 'string', caption: 'recibos.recibo.linhas.numeroDocumento'},
        {dataField: 'dataVencimento', dataType: 'date', caption: 'recibos.recibo.linhas.dataVencimento'},
        {dataField: 'valor', dataType: 'double', caption: 'recibos.recibo.linhas.valor', format: {decimalsLimit: this.config.valor}},
        {dataField: 'valorDesconto', dataType: 'double', caption: 'recibos.recibo.linhas.valorDesconto', format: {decimalsLimit: this.config.desconto}},
        {dataField: 'valorRecebido', dataType: 'double', caption: 'recibos.recibo.linhas.valorRecebido', format: {decimalsLimit: this.config.valor}},
        {dataField: 'cambioPagamento', dataType: 'double', caption: 'recibos.recibo.linhas.cambio', format: {decimalsLimit: NDECIMAIS_CAMBIO_DEFAULT}}
      ],
      filterRow: {visible: false},
      headerFilter: {visible: false},
      height: '50vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      rowAlternationEnabled: true,
      remoteOperations: false,
      scrolling: {rowRenderingMode: 'virtual'},
      toolbar: {visible: false}
    };
    this.typeDocContabilidadeOrigem = EDocContabilidadeOrigem;
    this._defaultRecibo = reciboDefault();
  }

  public ngOnInit(): void {
    this.configTooltip = {
      text: this.recibo.cab.origem === EDocContabilidadeOrigem.ERPCloud ? 'recibos.info.anularRecibo' : 'recibos.info.naoefetuadoCG',
      placement: 'top-right',
      container: 'body'
    };

    this.pdfTooltip = {
      text: this.recibo.cab.comErro ? 'recibos.erros.naoEmitidoCloud' : 'global.btn.processPDF',
      placement: 'top-right',
      container: 'body'
    };
  }

  public ngOnChanges({recibo}: SimpleChanges): void {
    if (recibo) {
      this._changedRecibo(recibo.currentValue);
    }
  }

  public anularRecibo(): Promise<void> {
    return this._cgModalService.showOkCancel('recibos.info.anularRecibo', 'recibos.info.temCertezaAnularRecibo').then(() => this._anulaReciboInternal());
  }

  public pdf(): Promise<void> {
    return this._recibosService.getPdf({
      extPocCabID: this.recibo.cab.extPocCabID,
      nConta: this.recibo.cab.nConta,
      nomeConta: this.recibo.cab.nome,
      email: this.recibo.cab.email,
      ano: (this.recibo.anoRecibo ?? 0) > 0 ? this.recibo.anoRecibo : 0,
      anulado: this.recibo.cab.anulado
    });
  }

  private _changedRecibo(value: IJsonRecibo = this.recibo): void {
    this.recibo = merge<object, IJsonRecibo, IJsonRecibo>({}, this._defaultRecibo, value);
  }

  private _refreshList(): void {
    this.evtRefreshList.emit();
  }

  private _anulaReciboInternal(): Promise<void> {
    return this._recibosService
      .anular(this.recibo)
      .then(() => {
        this._plAlertService.success(this._translateService.instant('recibos.info.anular'));
        this._refreshList();
      })
      .catch((error: HttpErrorResponse) => {
        const exception = this._cgExceptionService.get(error);
        this._plAlertService.error(exception?.message || 'recibos.erros.anular');
      });
  }
}
