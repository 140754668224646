<div class="generic-importer">
  <pl-nav-wizard instanceId="generic-importer" [definition]="navWizardDefinition" [callback]="navWizardCallback" [onFinalize]="fnFinalize">
    <pl-nav-wizard-step [stepId]="wizardStepIdDownloadModel" caption="components.genericImporter.steps.downloadModel.title" icon="fa-download">
      <div *plNavWizardStepContent>
        <p [translate]="'components.genericImporter.steps.downloadModel.content.1'" [translateParams]="{type: textType}"></p>
        <p [translate]="'components.genericImporter.steps.downloadModel.content.2'" [translateParams]="{type: textType}"></p>
        <p [translate]="'components.genericImporter.steps.downloadModel.content.3'"></p>
        <button type="button" class="btn btn-sm btn-info" [click]="fnDownloadModel" plPromise plAutoFocus>
          <i class="fa fa-download" aria-hidden="true"></i>&nbsp;<span [translate]="'components.genericImporter.actions.downloadModel'"></span>
        </button>
        <p class="text-danger">
          <strong [translate]="'components.genericImporter.steps.downloadModel.content.4'"></strong>
        </p>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      [stepId]="wizardStepIdUploadModel"
      caption="components.genericImporter.steps.uploadModel.title"
      icon="fa-upload"
      [valid]="uploadedFilledModel"
      [validator]="fnValidatorStepUploadModel"
      [hideNext]="uploadingFilledModel">
      <div *plNavWizardStepContent>
        <p [translate]="'components.genericImporter.steps.uploadModel.content.1'" [translateParams]="{type: textType}"></p>
        <p [translate]="'components.genericImporter.steps.uploadModel.content.2'"></p>
        <pl-upload
          class="generic-importer-uploader mt-3"
          [url]="uploadUrl"
          acceptedFiles=".xlsx"
          [hideActions]="true"
          [maxFiles]="1"
          [maxFileSize]="1"
          [uploadMultiple]="false"
          [params]="uploadParams"
          responseType="json"
          [callback]="uploadCallback"
          (evtAcceptedFile)="uploadingFilledModel = true"
          (evtUploadedFile)="onUploadedFilledModel($event)"
          (evtUploadErrored)="uploadingFilledModel = false">
        </pl-upload>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      [stepId]="wizardStepIdAnalyzeErrors"
      caption="components.genericImporter.steps.analyzeErrors.title"
      icon="fa-exclamation-circle"
      [visible]="visibleStepAnalyzeErrors"
      [validator]="fnValidatorStepAnalyzeErrors"
      [hideNext]="true">
      <div *plNavWizardStepContent>
        <p><strong [translate]="'components.genericImporter.steps.analyzeErrors.content.1.1'"></strong>:</p>
        <p>
          <i class="fa fa-fw fa-check me-1 text-success" aria-hidden="true"></i>
          <span [translate]="'components.genericImporter.steps.analyzeErrors.content.1.2' | translate: {successCount: successCount}"></span>
        </p>
        <p class="text-danger" *ngIf="errorCount > 0">
          <i class="fa fa-fw fa-exclamation-triangle me-1" aria-hidden="true"></i>
          <span [translate]="'components.genericImporter.steps.analyzeErrors.content.1.3' | translate: {errorCount: errorCount}"></span>
        </p>
        <ng-container *ngIf="errorsFile && errorsFile.size > 0">
          <p [translate]="'components.genericImporter.steps.analyzeErrors.content.2'"></p>
          <button type="button" class="btn btn-sm btn-info" (click)="downloadErrors()">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'components.genericImporter.actions.downloadErrors'"></span>
          </button>
          <button type="button" class="btn btn-sm btn-primary" [disabled]="!downloadedErrors" (click)="retry()">
            <i class="fa fa-undo" aria-hidden="true"></i>&nbsp;<span [translate]="'components.genericImporter.actions.retry'"></span>
          </button>
        </ng-container>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step [stepId]="wizardStepIdSuccess" caption="components.genericImporter.steps.success.title" icon="fa-check">
      <div *plNavWizardStepContent>
        <p [translate]="'components.genericImporter.steps.success.content.1'" [translateParams]="{type: textType}"></p>
        <p [translate]="'components.genericImporter.steps.success.content.2'" [translateParams]="{type: textType}"></p>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
