import {Component, Injector, Input} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonImportadorSaftDirectContaFormada} from '../../importadorSaftDirect.module.interface';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';

@Component({
  selector: 'importador-saft-direct-ver-conta-formada-modal',
  templateUrl: './importadorSaftDirect.verContaFormada.modal.component.html'
})
export class ImportadorSaftDirectVerContaFormadaModalComponent extends CGModalComponent<void> {
  @Input() public modalTitle: string;
  @Input() public source: Array<IJsonImportadorSaftDirectContaFormada>;

  public readonly datagridDefinition: IDevExpressDataGrid;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.datagridDefinition = {
      columns: [
        {dataField: 'nContaClassificada', caption: 'importadorSaftDirect.fields.nContaClassificada'},
        {dataField: 'digitosMercado', caption: 'importadorSaftDirect.fields.contaFormadaDigitosMercado'},
        {dataField: 'digitosIVA', caption: 'importadorSaftDirect.fields.contaFormadaDigitosIVA'},
        {dataField: 'nContaFormada', caption: 'importadorSaftDirect.fields.nContaFormada'},
        {dataField: 'contaFormadaNome', caption: 'importadorSaftDirect.fields.contaFormadaNome'},
        {dataField: 'estado', caption: 'global.text.error'}
      ]
    };
  }
}
