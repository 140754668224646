<div [ngClass]="entityClassName">
  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [dataGridState]="dataGridState"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [filterCollapsed]="false"
    [beforeRequest]="fnBeforeRequest"
    [toolbarInstanceId]="instanceName"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [properties]="listOptions"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridOnEditorPreparing)="onDataGridEditorPreparing($event)"
    (evtDataGridColumnsPreparing)="onDataGridColumnsPreparing($event)"
    (evtDataGridOnContentReady)="onDataGridOnContentReady($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
    <div *entityListItemDetail="let item">
      <pl-split-view [initialSizeLeft]="70" [hideRightSide]="!item.data._docdigital" (evtSizeValuesChanged)="onResizerValuesChanged()">
        <div *plSplitViewLeftSide>
          <documento-contabilidade
            [(doc)]="item.data._thedoc"
            [onActionEdited]="fnOnActionEdited"
            [onActionDeleted]="fnOnActionDeleted"
            [onActionAnulouDiferimentos]="fnOnActionAnulouDiferimentos"
            [maintenanceMode]="maintenanceMode"
            (evtLoading)="onChangedLoading(item.component, $event)">
          </documento-contabilidade>
        </div>

        <div *plSplitViewRightSide>
          <div [ngClass]="entityClassName + '-contabilidade-digital-docviewer'">
            <contabilidadedigital-docviewer-recolha
              [readonly]="contabilidadeDigitalReadonly"
              [doc]="item.data._docdigital"
              [year]="selectedYear"
              [hideToolbarOnEmptyDoc]="false"
              [nDocumento]="item.data._thedoc.temDocDigital ? item.data._thedoc.nDocumento : ''"
              [callback]="callbackDocViewer"
              (evtChangedAttachment)="changedDocAttachment(item.data, $event)"
              (evtAttachedAttachment)="item.data._thedoc.temDocDigital = true"
              (evtDeletedAttachment)="item.data._thedoc.temDocDigital = false">
            </contabilidadedigital-docviewer-recolha>
          </div>
        </div>
      </pl-split-view>
    </div>
  </entity-list>
</div>
