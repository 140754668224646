import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'cgJsonSerialize',
  standalone: false
})
export class CGJsonSerializePipe implements PipeTransform {
  public transform(value: unknown): string {
    let serialized: string = JSON.stringify(value);
    if (serialized.startsWith('"')) {
      serialized = serialized.slice(1);
    }
    if (serialized.endsWith('"')) {
      serialized = serialized.slice(0, -1);
    }
    return serialized;
  }
}
