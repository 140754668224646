<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'fct.strings.pedidosReembolsos'"></h4>
</div>

<div class="modal-body fct-pedido-reembolso-modal-body" [plPromise]="promise">
  <div class="d-flex flex-wrap">
    <div class="fct-filter-group me-4 mb-2">
      <label [translate]="'fct.strings.niss'"></label>
      <pl-group style="max-width: 150px">
        <edit>
          <pl-edit type="text" [model]="filters.nissTrabalhador" (modelChange)="filters.nissTrabalhador = $event; checkFilters()"></pl-edit>
        </edit>
      </pl-group>
    </div>

    <div class="fct-filter-group me-4">
      <label [translate]="'fct.strings.dataCessInicial'"></label>
      <pl-group style="max-width: 150px">
        <edit>
          <pl-edit-datepicker [model]="filters.dataCessInicial" (modelChange)="filters.dataCessInicial = $event; checkFilters()"></pl-edit-datepicker>
        </edit>
      </pl-group>
    </div>

    <div class="fct-filter-group me-4">
      <label [translate]="'fct.strings.a'"></label>
      <pl-group style="max-width: 150px">
        <edit>
          <pl-edit-datepicker [model]="filters.dataCessFinal" (modelChange)="filters.dataCessFinal = $event; checkFilters()"></pl-edit-datepicker>
        </edit>
      </pl-group>
    </div>

    <div class="fct-filter-group me-4">
      <label [translate]="'fct.strings.estadoContrato'"></label>
      <pl-group style="max-width: 240px">
        <edit>
          <pl-edit-select
            [model]="filters.estadoContrato"
            (modelChange)="filters.estadoContrato = $event; checkFilters()"
            [properties]="{select: {list: estadosContratoSource}, disallowClear: true}"></pl-edit-select>
        </edit>
      </pl-group>
    </div>

    <div class="fct-filter-group">
      <pl-button type="button" klass="btn-sm btn-primary" [onClick]="fnSearchTrabalhadores">
        <i class="fa fa-search"></i>&nbsp;<span [translate]="'fct.strings.pesquisarTrabalhadores'"></span>
      </pl-button>
    </div>
  </div>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="fctDadosTodosTrabalhadoresComFCGridInstanceName"
    [dataSource]="source"
    (onInitialized)="onInitialized($event)"
    (onCellClick)="onCellClick($event)">
    <div *dxTemplate="let cell of 'templateToolbarEmpresas'">
      <pl-tooltip [config]="{text: 'fct.tooltips.selecionarTodos', container: 'body'}">
        <button *plTooltipContent type="button" class="cg-dx-toolbar-btn" (click)="fnSelectAll()">
          <i class="fa fa-fw fa-check-square-o" aria-hidden="true"></i>
        </button>
      </pl-tooltip>

      <pl-tooltip [config]="{text: 'fct.tooltips.deselecionarTodos', container: 'body'}">
        <button *plTooltipContent type="button" class="cg-dx-toolbar-btn" (click)="fnUnselectAll()">
          <i class="fa fa-fw fa-square-o" aria-hidden="true"></i>
        </button>
      </pl-tooltip>

      <pl-tooltip [config]="{text: 'fct.tooltips.expandAllHaveEmployees', container: 'body'}">
        <button *plTooltipContent type="button" class="cg-dx-toolbar-btn" (click)="fnExpandAll()">
          <i class="fa fa-fw fa-plus-square-o" aria-hidden="true"></i>
        </button>
      </pl-tooltip>

      <pl-tooltip [config]="{text: 'fct.tooltips.collapseAll', container: 'body'}">
        <button *plTooltipContent type="button" class="cg-dx-toolbar-btn" (click)="fnCollapseAll()">
          <i class="fa fa-fw fa-minus-square-o" aria-hidden="true"></i>
        </button>
      </pl-tooltip>
    </div>

    <div *dxTemplate="let item of 'actions'" class="text-center">
      <pl-tooltip [config]="{text: 'fct.messages.configurarCredentials', container: 'body'}">
        <button *plTooltipContent type="button" class="btn btn-warning btn-xs" [click]="fnConfigCredentials(item.data)" plPromise>
          <i class="fa fa-cog" aria-hidden="true"></i>
        </button>
      </pl-tooltip>

      <pl-tooltip *ngIf="item.data.pedidosReembolso?.length" [config]="{text: 'fct.messages.visualizarPedidosEfetuados', container: 'body'}">
        <button *plTooltipContent type="button" class="btn btn-success btn-xs ms-2" [click]="fnViewPedidosEfetuados(item.data)" plPromise>
          <i class="fa fa-eye" aria-hidden="true"></i>
        </button>
      </pl-tooltip>
    </div>

    <div *dxTemplate="let item of 'detail'">
      <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDetails" [dataSource]="item.data.trabalhadores" (onInitialized)="onDataGridDetailInitialized($event, item.data)">
        <div *dxTemplate="let cell of 'templateToolbarEfetuarPedido'">
          <pl-tooltip [config]="{text: 'fct.tooltips.efetuarPedidosDestaEmpresa', container: 'body'}">
            <button *plTooltipContent type="button" class="cg-dx-toolbar-btn" [click]="fnEfetuarPedido(item.data)" plPromise>
              <i class="fa fa-fw fa-paper-plane" aria-hidden="true"></i>
            </button>
          </pl-tooltip>
        </div>
      </dx-data-grid>
    </div>
  </dx-data-grid>
</div>

<div class="modal-footer" plNavigation>
  <pl-button klass="btn btn-sm btn-primary action-submit" [onClick]="fnEfetuarPedidos" [disabled]="efetuarPedidosBtnDisabled">
    <i class="fa fa-paper-plane" aria-hidden="true"></i>&nbsp;<span [translate]="'fct.strings.efetuarPedidos'"></span>
  </pl-button>

  <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></button>
</div>
