import {Component, Injector, Input, OnInit} from '@angular/core';
import {PlAlertService} from 'pl-comps-angular';
import {AtivosNotaCreditoService} from '../../../../modules/ativos/ativosnotacredito/ativosNotaCredito.module.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {IJsonAtivos} from '../../interfaces/jsonAtivos.entity.interface';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonNotaCredito, IJsonNotaCreditoNovoValor, IJsonNotaCreditoPesquisaContab} from '../../../../modules/ativos/ativosnotacredito/jsonAtivosNotaCredito.module.interface';
import {AtivosNotaCreditoContabModalComponent} from '../../../../modules/ativos/ativosnotacredito/modals/contab/ativosNotaCredito.contab.modal.component';

@Component({
  selector: 'modal-ativos-nota-credito',
  templateUrl: './ativosNotaCredito.modal.component.html'
})
export class AtivosNotaCreditoModalComponent extends CGModalComponent<IJsonAtivos> implements OnInit {
  @Input() public ativo: IJsonAtivos;
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public fornecedorDesc: string;
  public valorAquisicao: number;
  public valorNotaCredito: number;
  public novoValorAquisicao: number;
  public notaCredito: IJsonNotaCredito;

  private _extPocCabId: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _ativosNotaCreditoService: AtivosNotaCreditoService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nContaDebito', dataType: 'string', caption: 'ativosnotacredito.table.nContaDebito'},
        {dataField: 'nContaCredito', dataType: 'string', caption: 'ativosnotacredito.table.nContaCredito', width: 200},
        {dataField: 'valor', dataType: 'double', caption: 'ativosnotacredito.table.valor'},
        {dataField: 'descricao', dataType: 'string', caption: 'ativosnotacredito.table.descricao', width: 200}
      ],
      dataSource: [],
      export: {filename: 'ativosnotacredito.title'},
      remoteOperations: false
    };
    if (!this.notaCredito) {
      this.notaCredito = {
        valorAquisicaoNovo: 0,
        valorNotaCredito: 0,
        extPocCabId: '',
        fornecedor: '',
        notaCreditoContab: [],
        listaAtivos: []
      };
    }
    this._extPocCabId = '';
  }

  public ngOnInit(): void {
    this.fornecedorDesc = `${this.ativo.aquisicao.nContaFornecedor} - ${this.ativo.aquisicao.nomeFornecedor}`;
    this.valorAquisicao = this.ativo.aquisicao.contabValorAquisicao;
    this.valorNotaCredito = 0;
    this.novoValorAquisicao = 0;
  }

  public async close(): Promise<void> {
    await this._cgModalService.showOkCancel('global.text.confirmation', 'ativosnotacredito.message.temcerteza');
    const notaCreditoNovoValor: IJsonNotaCreditoNovoValor = {
      ativo: this.ativo,
      extPocCabId: this._extPocCabId,
      valorAquisicaoNovo: this.novoValorAquisicao,
      valorNotaCredito: this.valorNotaCredito
    };
    const ativo: IJsonAtivos = (await this._ativosNotaCreditoService.postAssociaNotaDeCreditoDeReducaoDeValorAquisicao(notaCreditoNovoValor)).body;
    this._plAlertService.success('ativosnotacredito.message.success');
    super.close(ativo);
  }

  public async openMaintenanceDocsContabilidade(): Promise<void> {
    const listNotaCreditoPesquisaContab: Array<IJsonNotaCreditoPesquisaContab> = (await this._ativosNotaCreditoService.getPesquisaNotaCreditoContab()).body;
    const modalInstance = this._cgModalService.showVanilla(AtivosNotaCreditoContabModalComponent);
    const componentInstance: AtivosNotaCreditoContabModalComponent = modalInstance.componentInstance;
    componentInstance.codAtivo = this.ativo.codAtivo;
    componentInstance.notaCreditoList = listNotaCreditoPesquisaContab;

    const resAddDoc: IJsonNotaCredito = await modalInstance.result;
    this.valorNotaCredito = resAddDoc.valorNotaCredito;
    this.novoValorAquisicao = resAddDoc.valorAquisicaoNovo;
    this._extPocCabId = resAddDoc.extPocCabId;
    this.dataGridDefinition.dataSource = resAddDoc.notaCreditoContab;
  }
}
