export enum EScreenSize {
  ExtraSmall = 0,
  Small = 576,
  Medium = 768,
  Large = 992,
  ExtraLarge = 1200,
  ExtraExtraLarge = 1400
}

export enum EMonth {
  January = 0,
  February = 1,
  March = 2,
  April = 3,
  May = 4,
  June = 5,
  July = 6,
  August = 7,
  September = 8,
  October = 9,
  November = 10,
  December = 11
}

export enum EDateMonth {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12
}

export enum EWeekDay {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum EDelphiNumberTypes {
  MinByte = 0,
  MaxByte = 255,
  MinShortInt = -127,
  MaxShortInt = 127,
  MinWord = 127,
  MaxWord = 65535,
  MinSmallInt = -32768,
  MaxSmallInt = 32767,
  MinLongWord = 0,
  MaxLongWord = 4294967295,
  MinCardinal = 0,
  MaxCardinal = 4294967295,
  MinLongInt = -2147483648,
  MaxLongInt = 2147483647,
  MinInteger = -2147483648,
  MaxInteger = 2147483647,
  MinInt64 = -9223372036854775808,
  MaxInt64 = 922337203685477 // 5807, javascript can't handle delphi's precision
}
/* eslint-enable @typescript-eslint/no-duplicate-enum-values */

export enum EMouseEventButton {
  Main, // usually the left button
  Auxiliary, // usually the wheel button or the middle button (if present)
  Secondary, // usually the right button
  Fourth, // typically the browser Back button
  Fifth // typically the browser Forward button
}

export type THyperlinkTarget = '_self' | '_blank' | '_parent' | '_top';

/* eslint-disable @typescript-eslint/naming-convention */

export interface IKEYCODE {
  BACKSPACE: string;
  TAB: string;
  ENTER: string;
  SHIFT: string;
  CONTROL: string;
  ALT: string;
  BREAK: string;
  PAUSE: string;
  CAPS_LOCK: string;
  ESC: string;
  SPACEBAR: string;
  PAGE_UP: string;
  PAGE_DOWN: string;
  END: string;
  HOME: string;
  LEFT: string;
  UP: string;
  RIGHT: string;
  DOWN: string;
  INSERT: string;
  DELETE: string;
  _0: string;
  _1: string;
  _2: string;
  _3: string;
  _4: string;
  _5: string;
  _6: string;
  _7: string;
  _8: string;
  _9: string;
  A: string;
  B: string;
  C: string;
  D: string;
  E: string;
  F: string;
  G: string;
  H: string;
  I: string;
  J: string;
  K: string;
  L: string;
  M: string;
  N: string;
  O: string;
  P: string;
  Q: string;
  R: string;
  S: string;
  T: string;
  U: string;
  V: string;
  W: string;
  X: string;
  Y: string;
  Z: string;
  a: string;
  b: string;
  c: string;
  d: string;
  e: string;
  f: string;
  g: string;
  h: string;
  i: string;
  j: string;
  k: string;
  l: string;
  m: string;
  n: string;
  o: string;
  p: string;
  q: string;
  r: string;
  s: string;
  t: string;
  u: string;
  v: string;
  w: string;
  x: string;
  y: string;
  z: string;
  LEFT_WINDOW_KEY: string;
  RIGHT_WINDOW_KEY: string;
  SELECT_KEY: string;
  MULTIPLY: string;
  ADD: string;
  SUBTRACT: string;
  DECIMAL_POINT: string;
  DIVIDE: string;
  F1: string;
  F2: string;
  F3: string;
  F4: string;
  F5: string;
  F6: string;
  F7: string;
  F8: string;
  F9: string;
  F10: string;
  F11: string;
  F12: string;
  F13: string;
  F14: string;
  F15: string;
  NUM_LOCK: string;
  SCROLL_LOCK: string;
  SEMI_COLON: string;
  EQUAL_SIGN: string;
  COMMA: string;
  DASH: string;
  PERIOD: string;
  FORWARD_SLASH: string;
  DEAD: string;
  GRAVE_ACCENT: string;
  ACCENT: string;
  BACK_SLASH: string;
  SINGLE_QUOTE: string;
  UNIDENTIFIED: string;
}

/* eslint-enable @typescript-eslint/naming-convention */

export const WINDOW_TIMEOUT = 50;

export const DEFAULT_PER_PAGE = 10;

export const MAX_MONTHS = 12;

export const DATE_MINIMUM_YEAR = 100;

export const DATE_MAXIMUM_YEAR = 9999;

/* eslint-disable @typescript-eslint/naming-convention */

export const KEYCODES: IKEYCODE = Object.freeze({
  BACKSPACE: 'Backspace',
  TAB: 'Tab',
  ENTER: 'Enter',
  SHIFT: 'Shift',
  CONTROL: 'Control',
  ALT: 'Alt',
  BREAK: 'Break',
  PAUSE: 'Pause',
  CAPS_LOCK: 'CapsLock',
  ESC: 'Escape',
  SPACEBAR: ' ',
  PAGE_UP: 'PageUp',
  PAGE_DOWN: 'PageDown',
  END: 'End',
  HOME: 'Home',
  LEFT: 'ArrowLeft',
  UP: 'ArrowUp',
  RIGHT: 'ArrowRight',
  DOWN: 'ArrowDown',
  INSERT: 'Insert',
  DELETE: 'Delete',
  _0: '0',
  _1: '1',
  _2: '2',
  _3: '3',
  _4: '4',
  _5: '5',
  _6: '6',
  _7: '7',
  _8: '8',
  _9: '9',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
  I: 'I',
  J: 'J',
  K: 'K',
  L: 'L',
  M: 'M',
  N: 'N',
  O: 'O',
  P: 'P',
  Q: 'Q',
  R: 'R',
  S: 'S',
  T: 'T',
  U: 'U',
  V: 'V',
  W: 'W',
  X: 'X',
  Y: 'Y',
  Z: 'Z',
  a: 'a',
  b: 'b',
  c: 'c',
  d: 'd',
  e: 'e',
  f: 'f',
  g: 'g',
  h: 'h',
  i: 'i',
  j: 'j',
  k: 'k',
  l: 'l',
  m: 'm',
  n: 'n',
  o: 'o',
  p: 'p',
  q: 'q',
  r: 'r',
  s: 's',
  t: 't',
  u: 'u',
  v: 'v',
  w: 'w',
  x: 'x',
  y: 'y',
  z: 'z',
  LEFT_WINDOW_KEY: 'Meta',
  RIGHT_WINDOW_KEY: 'Meta',
  SELECT_KEY: 'Select',
  MULTIPLY: '*',
  ADD: '+',
  SUBTRACT: '-',
  DECIMAL_POINT: '.',
  DIVIDE: '/',
  F1: 'F1',
  F2: 'F2',
  F3: 'F3',
  F4: 'F4',
  F5: 'F5',
  F6: 'F6',
  F7: 'F7',
  F8: 'F8',
  F9: 'F9',
  F10: 'F10',
  F11: 'F11',
  F12: 'F12',
  F13: 'F13',
  F14: 'F14',
  F15: 'F15',
  NUM_LOCK: 'NumLock',
  SCROLL_LOCK: 'ScrollLock',
  SEMI_COLON: ';',
  EQUAL_SIGN: '=',
  COMMA: ',',
  DASH: '-',
  PERIOD: '.',
  FORWARD_SLASH: '/',
  DEAD: 'Dead',
  GRAVE_ACCENT: '`',
  ACCENT: '´',
  BACK_SLASH: '\\',
  SINGLE_QUOTE: "'",
  UNIDENTIFIED: 'Unidentified'
});

/* eslint-enable @typescript-eslint/naming-convention */

export function getScreenWidth(): number {
  return window?.innerWidth;
}

export function getScreenHeight(): number {
  return window?.innerHeight;
}

export function getScreenSize(): EScreenSize {
  const screenWidth = Math.trunc(getScreenWidth());
  if (screenWidth >= EScreenSize.ExtraExtraLarge) {
    return EScreenSize.ExtraExtraLarge;
  }
  if (screenWidth >= EScreenSize.ExtraLarge) {
    return EScreenSize.ExtraLarge;
  }
  if (screenWidth >= EScreenSize.Large) {
    return EScreenSize.Large;
  }
  if (screenWidth >= EScreenSize.Medium) {
    return EScreenSize.Medium;
  }
  if (screenWidth >= EScreenSize.Small) {
    return EScreenSize.Small;
  }
  return EScreenSize.ExtraSmall;
}

export function isMobile(): boolean {
  return getScreenWidth() < EScreenSize.Medium;
}
