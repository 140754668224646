import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import moment from 'moment';
import {HttpResponse} from '@angular/common/http';
import dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridEventOnInitialized} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {TDate} from '../../../../../../common/dates';
import {IDataEscalao} from '../../tabelasIRS.module.interface';
import {TabelasIRSModuleService} from '../../tabelasIRS.module.service';
import {IJsonTirs, IJsonTIRSDT} from '../../jsonTabelasIRS.module.interface';

@Component({
  selector: 'modal-modelo30-vertificar-lancamentos',
  templateUrl: './tabelasIRS.detail.modal.component.html'
})
export class TabelasIRSDetailModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public model: IJsonTirs;
  @Input() public datagridEscaloesIRSSource: Array<IJsonTIRSDT>;
  @Input() public datasEscaloes: Array<IDataEscalao>;

  public datagridEscaloesIRS: IDevExpressDataGrid<IJsonTIRSDT, IJsonTIRSDT>;
  public currentDataTaxaIrs: IDataEscalao;

  private _isTabelaNova: boolean;
  private _dataGridInstancedatagridEscaloesIRS: dxDataGrid<IJsonTIRSDT, IJsonTIRSDT>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _serviceTabelasIRS: TabelasIRSModuleService
  ) {
    super(_injector);
    this.currentDataTaxaIrs = undefined;
  }

  public ngOnInit(): void {
    this._isTabelaNova = this.model.nTabIrs?.toUpperCase().startsWith('A') || this.model.nTabIrs?.toUpperCase().startsWith('C') || this.model.nTabIrs?.toUpperCase().startsWith('M');

    this.datagridEscaloesIRS = {
      columns: [
        {dataField: 'valorILIQ', dataType: 'double', caption: 'tirs.table.ateRemunMes'},
        {dataField: 'percDesc0DEP', dataType: 'double', caption: '0', visible: !this._isTabelaNova},
        {dataField: 'percDesc1DEP', dataType: 'double', caption: '1', visible: !this._isTabelaNova},
        {dataField: 'percDesc2DEP', dataType: 'double', caption: '2', visible: !this._isTabelaNova},
        {dataField: 'percDesc3DEP', dataType: 'double', caption: '3', visible: !this._isTabelaNova},
        {dataField: 'percDesc4DEP', dataType: 'double', caption: '4', visible: !this._isTabelaNova},
        {dataField: 'percDesc5DEP', dataType: 'double', caption: 'tirs.table.maiorigual5', visible: !this._isTabelaNova},
        {dataField: 'valorAbater5DEP', dataType: 'double', caption: 'tirs.table.vabater5', visible: !this._isTabelaNova},
        {dataField: 'valorAbater4DEP', dataType: 'double', caption: 'tirs.table.vabater4', visible: !this._isTabelaNova},
        {dataField: 'valorAbater3DEP', dataType: 'double', caption: 'tirs.table.vabater3', visible: !this._isTabelaNova},
        {dataField: 'valorAbater2DEP', dataType: 'double', caption: 'tirs.table.vabater2', visible: !this._isTabelaNova},
        {dataField: 'valorAbater1DEP', dataType: 'double', caption: 'tirs.table.vabater1', visible: !this._isTabelaNova},
        {dataField: 'valorAbater0DEP', dataType: 'double', caption: 'tirs.table.vabater0', visible: !this._isTabelaNova},
        {dataField: 'taxaMarginalMaxima', dataType: 'double', caption: 'tirs.table.taxaMarginalMaxima', visible: this._isTabelaNova},
        {dataField: 'parcelaAbaterFormulaInfo', dataType: 'string', caption: 'tirs.table.parcelaAbaterFormulaInfo', visible: this._isTabelaNova},
        {dataField: 'parcelaAbaterDepende', dataType: 'number', caption: 'tirs.table.parcelaAbaterDepende', visible: this._isTabelaNova},
        {dataField: 'txEfetivaMensalEscal', dataType: 'string', caption: 'tirs.table.txEfetivaMensalEscal', visible: this._isTabelaNova},
        {dataField: 'nTabIRS', dataType: 'string', visible: false},
        {dataField: 'tabIRSDT', dataType: 'date', visible: false}
      ],
      paging: {enabled: false},
      pager: {visible: false},
      searchPanel: {visible: false},
      filterRow: {visible: false},
      export: {enabled: false},
      columnChooser: {enabled: false},
      allowColumnReordering: false,
      grouping: {allowCollapsing: false, contextMenuEnabled: false},
      groupPanel: {visible: false, allowColumnDragging: false},
      columnFixing: {enabled: false},
      hoverStateEnabled: true,
      columnHidingEnabled: false
    };

    this.currentDataTaxaIrs = {
      value: moment(this.model.dataVigor),
      label: moment(this.model.dataVigor).format(this.format.momentDate)
    };
    this._buildDataEscaloesAndSelectCurrent();
  }

  public close(): void {
    super.close();
  }

  public onDataGridInitializedEscaloesIRS({component}: IDevExpressDataGridEventOnInitialized<IJsonTIRSDT, IJsonTIRSDT>): void {
    this._dataGridInstancedatagridEscaloesIRS = component;
  }

  public async dateDropDownChanged(date: TDate): Promise<void> {
    this.model.dataVigor = date;
    this._dataGridInstancedatagridEscaloesIRS.beginCustomLoading(undefined);
    try {
      const response: HttpResponse<Array<IJsonTIRSDT>> = await this._serviceTabelasIRS.getTableIRSEscaloesByDate(this.model.nTabIrs, this.model.dataVigor);
      this.datagridEscaloesIRSSource = response.body;
    } finally {
      this._dataGridInstancedatagridEscaloesIRS.endCustomLoading();
    }
  }

  private _buildDataEscaloesAndSelectCurrent(): void {
    for (const item of this.datasEscaloes) {
      item.label = moment(item.value).format(this.format.momentDate);
    }
    this.currentDataTaxaIrs = this.datasEscaloes.find((item) => moment(item.value).isSame(this.model.dataVigor, 'date'));
  }
}
