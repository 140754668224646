<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()" aria-hidden="true" [disabled]="closeDisabled"></button>
  <h4 class="modal-title" [translate]="'recibos.saveModal.title'"></h4>
</div>

<div class="modal-body recibos-modal" [plPromise]="promise">
  <div [plAnimationShake]="alertErrorMsg.length" *ngIf="alertErrorMsg.length">
    <pl-alert (evtClosed)="clearErros()">
      <div *ngFor="let errorMsg of alertErrorMsg" [translate]="errorMsg"></div>
    </pl-alert>
  </div>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="recibosSaveModalPaymentMean"
    [dataSource]="recibo.meiosPagamento"
    (onSaved)="onSaved()"
    (onInitialized)="onInitialized($event)"
    (onKeyDown)="onKeyDown($event)">
    <div *dxTemplate="let item of 'cellTemplateBtns'">
      <dx-button
        class="dx-button-icon-fontawesome"
        [hint]="'recibos.saveModal.payInFull' | translate"
        (click)="receberTudoLinha(item.data)"
        icon="fa fa-fw fa-check"
        [stylingMode]="'text'"
        [disabled]="closeDisabled"></dx-button>

      <dx-button
        class="dx-button-icon-fontawesome"
        [hint]="'recibos.saveModal.clearValue' | translate"
        (click)="limpaLinha(item.data)"
        icon="fa fa-fw fa-eraser"
        [stylingMode]="'text'"
        [disabled]="closeDisabled"></dx-button>
    </div>
  </dx-data-grid>

  <div class="mt-3 d-flex align-items-start gap-3">
    <div class="align-items-center recibos-modal-descr">
      <div class="fw-semibold" [translate]="'recibos.fields.descricao'"></div>
      <div class="recibos-modal-textarea">
        <pl-edit-textarea
          #descricao
          [(model)]="recibo.cab.descricao"
          [attrName]="'descricao'"
          (keydown)="onKeydownEnter($event)"
          class="recibos-modal-edit-text"
          [properties]="{validators: {maxlength: {value: maxLenghtDescricao}}}"></pl-edit-textarea>
      </div>
    </div>

    <div class="resumo">
      <dl class="page-invoice-amount">
        <dt><span [translate]="'pagamentos.saveModal.document'"></span>:</dt>
        <dd>{{ recibo.cab.total | cgcCurrency: valorDecimais : abreviaturaMoeda : 'code' }}</dd>

        <dt><span [translate]="'recibos.recibo.totalCaixa'"></span>:</dt>
        <dd>{{ recibo.totalCaixa | cgcCurrency: valorDecimais : abreviaturaMoeda : 'code' }}</dd>

        <ng-container *ngIf="recibo.troco > 0">
          <dt><span [translate]="'recibos.saveModal.entregueAMais'"></span>:</dt>
          <dd>{{ recibo.troco | cgcCurrency: valorDecimais : abreviaturaMoeda : 'code' }}</dd>
        </ng-container>
      </dl>
    </div>
  </div>
  <div ngbDropdown class="py-2" [placement]="'bottom-left'">
    <button type="button" class="btn btn-info dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" ngbDropdownToggle>
      <i><span [translate]="'recibos.saveModal.dropdownTitle'"></span></i>
    </button>

    <div ngbDropdownMenu class="dropdown-select">
      <ng-container *ngFor="let desc of descricoes">
        <button ngbDropdownItem type="button" class="dropdown-item" [translate]="'recibos.saveModal.dropdownItems.' + desc" (click)="applyVariable('@' + desc)"></button>
      </ng-container>
    </div>
  </div>

  <div
    *ngIf="!canSave"
    class="mt-2 text-danger"
    [translate]="'recibos.erros.invalidValorAPagar'"
    [translateParams]="{
      valorEntregue: recibo.totalCaixa | cgcCurrency: valorDecimais : abreviaturaMoeda : 'code',
      valorDocumento: recibo.cab.total | cgcCurrency: valorDecimais : abreviaturaMoeda : 'code'
    }"></div>
</div>

<div class="modal-footer" plNavigation>
  <button [id]="saveButtonId" type="button" class="btn btn-sm btn-primary action-save" [click]="fnSave('save')" plPromise [disabled]="closeDisabled || !canSave">
    <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'recibos.saveModal.save'"></span>
  </button>

  <button type="button" class="btn btn-sm btn-warning action-save-pdf" [click]="fnSave('pdf')" plPromise [disabled]="closeDisabled || !canSave">
    <i class="fa fa-fw fa-file-pdf-o"></i>&nbsp;<span [translate]="'recibos.saveModal.saveCheck'"></span>
  </button>

  <button type="button" class="btn btn-sm btn-light action-dismiss" [disabled]="closeDisabled" (click)="dismiss()">
    <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
  </button>
</div>
