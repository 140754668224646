import {Component, Injector, Input, OnInit} from '@angular/core';
import {IJsonReport} from '../../../../entities/reports/jsonReport.interface';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {ETipoExport} from '../../../listagens/docscomerciasestatisticas/docsComerciaisEstatisticas.module.interface';
import {EReport} from '../../../../entities/reports/reports.interface';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonExtractoArtigoFilter} from '../../jsonExtractoArtigo.module.interface';
import {ExtractosArtigosService} from '../../extractosArtigos.module.service';

@Component({
  selector: 'extratos-artigos-print-modal',
  templateUrl: './extratosArtigos.print.modal.component.html'
})
export class ExtratosArtigosPrintModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public filters: IJsonExtractoArtigoFilter;

  public reportModel: IJsonReport;
  public pdfUrl: string;
  public reportType: string;
  public tipoExport: ETipoExport;
  public readonly tiposExport: typeof ETipoExport;

  constructor(
    protected readonly _injector: Injector,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _extractosArtigosService: ExtractosArtigosService
  ) {
    super(_injector);
    this.tipoExport = ETipoExport.PDF;
    this.tiposExport = ETipoExport;
  }

  public ngOnInit(): void {
    this.reportType = EReport.ExtratosArtigos;
    this._loadDefaultReport();
  }

  public loadPdf(): void {
    this._extractosArtigosService
      .getExtractosArtigosPdf(
        this.filters.nArtigoDe,
        this.filters.nArtigoAte,
        this.filters.nArmazemDe,
        this.filters.nArmazemAte,
        this.filters.dateDe,
        this.filters.dateAte,
        this.filters.calcMovAntesDepoisData,
        this.reportModel.name
      )
      .subscribe((url: string) => {
        this.pdfUrl = url;
      });
  }

  public onReportChange(report: IJsonReport): void {
    this.reportModel = report;
  }

  private _loadDefaultReport(): void {
    this._reportsRegistryService
      .get(EReport.ExtratosArtigos)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          this.reportModel = reports[0];
          this.loadPdf();
        }
      });
  }
}
