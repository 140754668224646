<div class="contabilidade-predefinidos-cabecalho" [ngSwitch]="previewOnly">
  <div class="contabilidade-predefinidos-cabecalho-preview" *ngSwitchCase="true">
    <dx-data-grid [cgDxDataGrid]="dataGridPreview" cgDxDataGridInstanceName="contabilidadePredefinidosCabecalhoPreview" [dataSource]="dataGridSourcePreview">
      <div *dxTemplate="let item of 'cellTemplateFields'">
        <ul class="d-flex align-items-center p-0 contabilidade-predefinidos-cabecalho-preview-fields">
          <li
            *ngFor="let field of item.data.fields; let i = index; let last = last"
            class="d-flex align-items-center contabilidade-predefinidos-cabecalho-preview-field contabilidade-predefinidos-cabecalho-preview-field-{{ field.name }}">
            <div *ngIf="field.modelValue || field.modelValue === 0" class="contabilidade-predefinidos-cabecalho-preview-field-caption"><span [translate]="field.caption"></span>:&nbsp;</div>
            <ng-container [ngSwitch]="field.type">
              <div *ngSwitchDefault>
                <ng-container *ngIf="field.modelValue || field.modelValue === 0">{{ field.modelValue }}</ng-container>
              </div>
              <dx-check-box *ngSwitchCase="'boolean'" [value]="field.modelValue" [readOnly]="true"></dx-check-box>
            </ng-container>
            <div *ngIf="!last && item.data.fields[i + 1]?.modelValue && item.data.fields[i + 1]?.modelValue !== 0" class="contabilidade-predefinidos-cabecalho-preview-field-separator px-2">|</div>
          </li>
        </ul>
      </div>
    </dx-data-grid>
  </div>

  <ng-container *ngSwitchDefault>
    <pl-navpill (evtSelected)="changedPanel($event)">
      <pl-navpill-panel *ngFor="let panel of panels; index as i" [id]="panel.name" [caption]="panel.caption" [captionClass]="getPanelClass(panel, i)">
        <div *plNavPillPanelContent>
          <div class="row">
            <div class="col-12">
              <h5 class="mb-4" [translate]="panel.caption"></h5>
            </div>
          </div>

          <div class="row">
            <div class="col-12 entity-detail-form">
              <pl-group *ngIf="panel.fields.length">
                <edit>
                  <h6 class="mb-2" [translate]="'predefinidocontabcab.titles.fieldProperties'"></h6>
                </edit>
              </pl-group>

              <ng-container *ngFor="let field of panel.fields">
                <pl-group *ngIf="isFieldVisible(field)">
                  <label [translate]="field.caption"></label>
                  <edit>
                    <pl-edit
                      [type]="field.type"
                      [attrName]="field.name"
                      [model]="cabecalho[panel.name][field.name]"
                      (modelChange)="cabecalho[panel.name][field.name] = $event; fieldChanged(field)"
                      [properties]="field.properties">
                    </pl-edit>
                  </edit>
                </pl-group>
              </ng-container>

              <hr *ngIf="panel.fields.length" />

              <pl-group>
                <edit>
                  <h6 class="mb-2" [translate]="'predefinidocontabcab.titles.visualProperties'"></h6>
                </edit>
              </pl-group>

              <pl-group *ngFor="let field of visualProperties">
                <label [translate]="field.caption"></label>
                <edit>
                  <pl-edit
                    [type]="field.type"
                    [attrName]="field.name"
                    [model]="cabecalho[panel.name].visibilidadeProperties[field.name]"
                    (modelChange)="fieldValueChanged(panel.name, field.name, $event)">
                  </pl-edit>
                </edit>
              </pl-group>
            </div>
          </div>
        </div>
      </pl-navpill-panel>
    </pl-navpill>

    <hr />

    <div>
      <h5><span [translate]="'predefinidoscontabilidade.titles.legend'"></span>:</h5>
      <ul class="list-legend contabilidade-predefinidos-cabecalho">
        <li class="contabilidade-predefinidos-cabecalho-panel complete"><span> - </span><span [translate]="'predefinidoscontabilidade.titles.verified'"></span></li>
        <li class="contabilidade-predefinidos-cabecalho-panel"><span> - </span><span [translate]="'predefinidoscontabilidade.titles.notVerified'"></span></li>
      </ul>
    </div>
  </ng-container>
</div>
