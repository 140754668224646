import {Component, Injector, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isNumber} from 'pl-comps-angular';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {EMPTY_PASSWORD} from '../../../../config/constants';
import {ESimpleLoginType, ISimpleLoginCredentials} from '../simpleLogin.module.interface';
import {SimpleLoginService} from '../simpleLogin.module.service';
import {TServiceResponse} from '../../../services/api/api.service.interface';

@Component({
  selector: 'simple-login-modal',
  templateUrl: './simpleLogin.modal.component.html'
})
export class SimpleLoginModalComponent extends CGModalComponent<ISimpleLoginCredentials> implements OnInit {
  @Input() public loginType: ESimpleLoginType;

  public model: ISimpleLoginCredentials;
  public userNameLabel: string;
  public logoImageSource: string;
  public promise: TServiceResponse<ISimpleLoginCredentials>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _simpleLoginService: SimpleLoginService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);
    this.model = {username: '', password: EMPTY_PASSWORD};
  }

  public ngOnInit(): void {
    if (!isNumber(this.loginType)) {
      this.loginType = ESimpleLoginType.AT;
    }
    this._evaluateLogo();
    switch (this.loginType) {
      case ESimpleLoginType.AT:
        this.userNameLabel = 'simpleLogin.at.userNameFieldLabel';
        this.promise = this._simpleLoginService.getAtCredentials();
        break;
      case ESimpleLoginType.SSD:
        this.userNameLabel = 'simpleLogin.ssd.userNameFieldLabel';
        this.promise = this._simpleLoginService.getSDDCredentials();
        break;
      case ESimpleLoginType.VIACTT:
        this.userNameLabel = 'simpleLogin.viactt.userNameFieldLabel';
        this.promise = this._simpleLoginService.getViaCTTCredentials();
        break;
      case ESimpleLoginType.EFATURA:
        this.userNameLabel = 'simpleLogin.at.userNameFieldLabel';
        this.promise = this._simpleLoginService.getEFaturaCredentials();
        break;
    }
    this.promise.then((response) => {
      this.model = response.body;
    });
  }

  public close(): Promise<void> {
    if (this.isSaveBtnDisabled()) {
      return Promise.reject(new Error(this._translateService.instant('simpleLogin.messages.credentialsEmpty')));
    }
    switch (this.loginType) {
      case ESimpleLoginType.AT:
        this.promise = this._simpleLoginService.saveAtCredentials(this.model);
        break;
      case ESimpleLoginType.SSD:
        this.promise = this._simpleLoginService.saveSDDCredentials(this.model);
        break;
      case ESimpleLoginType.VIACTT:
        this.promise = this._simpleLoginService.saveViaCTTCredentials(this.model);
        break;
      case ESimpleLoginType.EFATURA:
        this.promise = this._simpleLoginService.saveEFaturaCredentials(this.model);
        break;
    }
    return this.promise.then((response) => {
      this.model.username = response.body.username;
      this.model.password = response.body.password;
      super.close(this.model);
    });
  }

  public isSaveBtnDisabled(): boolean {
    return !this.model.username || !this.model.password || this.model.password === EMPTY_PASSWORD;
  }

  protected _onChangedTheme(): void {
    if (isNumber(this.loginType)) {
      this._evaluateLogo();
    }
  }

  private _evaluateLogo(): void {
    switch (this.loginType) {
      case ESimpleLoginType.AT:
        this.logoImageSource = this.darkTheme ? 'assets/images/logo-at-light.webp' : 'assets/images/logo-at.webp';
        break;
      case ESimpleLoginType.SSD:
        this.logoImageSource = this.darkTheme ? 'assets/images/logo-ssd-light.svg' : 'assets/images/logo-ssd.svg';
        break;
      case ESimpleLoginType.VIACTT:
        this.logoImageSource = this.darkTheme ? 'assets/images/logo-viactt-light.webp' : 'assets/images/logo-viactt.webp';
        break;
      case ESimpleLoginType.EFATURA:
        this.logoImageSource = this.darkTheme ? 'assets/images/logo-efatura-light.webp' : 'assets/images/logo-efatura.webp';
        break;
    }
  }
}
