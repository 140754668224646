/**
 * CentralGest Amalia
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type Channel = 'CHAT' | 'WEB';

export const Channel = {
    Chat: 'CHAT' as Channel,
    Web: 'WEB' as Channel
};

