import {IJsonEFaturaDocumento, IJsonEFaturaMeses} from './jsonEFatura.module.interface';
import {IDocumentoFaturacao} from '../../components/documento/facturacao/documento.facturacao.component.interface';
import {TDate} from '../../../common/dates';

export enum EComunicaInexistenciaModalModo {
  COMUNICACAO,
  ANULACAO
}

export interface IEFaturaEntityStateParams {
  year?: number;
  month?: number;
  filename?: string;
}

export interface IEFaturaComunicaDocumento extends IJsonEFaturaDocumento {
  selected?: boolean;
  estadoText?: string;
  comunicadoText?: string;
}

export interface IEFaturaDocumento extends IJsonEFaturaDocumento {
  _thedoc?: IDocumentoFaturacao;
}

export interface IEFaturaMeses extends IJsonEFaturaMeses {
  edited?: boolean;
}
export interface IEFaturaLogsFilters {
  dataDe: TDate;
  dataAte: TDate;
}

export const MODULE_NAME_EFATURA = 'efatura';
