import {IEntityDetailStateParams} from '../../components/entity/entity.definition.interface';
import {IJsonLote} from './jsonLote.entity.interface';
import {TDate} from '../../../common/dates';

export interface ILoteDetailStateParams extends IEntityDetailStateParams<IJsonLote> {
  defaultData?: IJsonLote;
  nArtigo?: string;
  nomeArtigo?: string;
  dataDoc?: TDate;
  diasValidade?: number;
}

export const ENTITY_NAME_LOTES = 'lotes';
