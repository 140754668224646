import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModuloComponent} from '../../../../components/module/module.component';
import {EDateMonth, IPlToolbarItem, IPlToolbarMenuItem, isArray, isDefinedNotNull, isFunction, isNumber, isObject, isUndefinedOrNull, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {
  IDevExpressDataGrid,
  IDevExpressDataGridCellTemplateData,
  IDevExpressDataGridColumn,
  IDevExpressDataGriHeaderCellTemplateData,
  TDevExpressDataGridColumnSetCellValueFn
} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnContextMenuPreparing, IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {MapaPagamentosService} from '../mapaPagamentos.module.service';
import {
  EJobMapaPagamState,
  EJsonMapaPagamMessageCode,
  EMapaPagamDataGridProps,
  EMapaPagamEntity,
  EMapaPagamImpostoEnum,
  EMapaPagamManualDeleteMethod,
  EMapaPagamPdfViewerMode,
  EMapaPagamResend,
  EMapaPagamTipoPeriodoIva,
  EMapaPagamTipoRegimeIva,
  IJsonMapaPagamEmpresaItem,
  IJsonMapaPagamEmpresasEnvio,
  IJsonMapaPagamEmpresasEnvioResumoItem,
  IJsonMapaPagamentoConfig,
  IJsonMapaPagamLoadResults,
  IJsonMapaPagamState,
  IMapaPagamDataGridColumnCheckboxesModel,
  IMapaPagamDataGridRecord,
  IMapaPagamEmpresaDataGridPagamentoItem,
  IMapaPagamentoEmpresaPaymentsSelected,
  IMapaPagamentoLastRequestFilters,
  IMapaPagamentoModalResult,
  IMapaPagamentoResponsavel,
  IMapaPagamFilters,
  IMapaPagamFilterSelectItem,
  IMapaPagamJaEnviadoItem,
  TMapaPagamJobStateIdleCallback
} from '../mapaPagamentos.module.interface';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {MapaPagamentosPagamentoModalComponent} from '../modals/pagamento/mapaPagamentos.pagamento.modal.component';
import {TServiceResponse} from '../../../../services/api/api.service.interface';
import {MapaPagamentosDocumentosModalComponent} from '../modals/documentos/mapaPagamentos.documentos.modal.component';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {ICGExceptionError} from '../../../../components/exceptions/exceptions.service.interface';
import {CGExceptionService} from '../../../../components/exceptions/exceptions.service';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {MapaPagamentosProcessModalComponent} from '../modals/process/mapaPagamentos.process.modal.component';
import {MapaPagamentosEnviadosPromptModalComponent} from '../modals/enviados/mapaPagamentos.enviados.prompt.modal.component';
import {MapaPagamentosLoginModalComponent} from '../modals/login/mapaPagamentos.login.modal.component';
import {MapaPagamentosResumoEnvioModalComponent} from '../modals/resumoenvio/mapaPagamentos.resumoEnvio.modal.component';
import moment from 'moment';
import {MapaPagamentosPagamentoDetalhesModalComponent} from '../modals/pagamentodetalhes/mapaPagamentos.pagamento.detalhes.modal.component';
import {cloneDeep} from 'lodash-es';
import {EMPTY_GUID} from '../../../../../config/constants';
import {MapaPagamentosConfigModalComponent} from '../modals/config/mapaPagamentos.config.modal.component';
import {IDevExpressDataGridState} from '../../../../components/devexpress/datagrid/state/devexpress.datagrid.state.interface';
import {ConfigLoginWsSSModalComponent} from '../../../segsocial/modals/loginWsSS.modal.component';
import {DATA_SOURCE_MESES} from '../../../../datasources/meses/meses.datasource';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {MapaPagamentosApagarPromptModalComponent} from '../modals/apagar/mapaPagamentos.apagar.prompt.modal.component';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';
import {
  IDevExpressDataGridExcelDataGridCell,
  IDevExpressDataGridExportExcelCustomizeCellOptions,
  IDevExpressDataGridPdfDataGridCell
} from '../../../../components/devexpress/datagrid/export/devexpress.datagrid.export.interface';
import {round} from '../../../../../common/utils/utils';

const TOOLBAR_GROUP_ID = 'mapaPagamentos';
const TOOLBAR_INSTANCE_ID_MAPA_PAGAMENTOS = 'MapaPagamentosGridToolbar';
const DYNAMIC_BAND_NAME = 'GRID_DYNAMIC_BAND';
const PAG_ESTADO_BAND_NAME = 'GRID_PAG_ESTADO_BAND';
const TIMEOUT_DELAY = 800;
const HOUR_12 = 12;
const HOUR_14 = 14;
const DYNAMIC_COL_PREFIX = 'DYNCOL_';
const EMAPAPAGAMIMPOSTOENUMCOLUMN = ['', 'iva', 'ircMod22', 'imi', 'iuc', 'dmr', 'retencaoNaFonte', 'pagAntIRC', 'dmrSS'];
const FIXED_COL_WIDTH = '180px';
const TAB_ID_POR_EMPRESA = 'porEmpresa';
const TAB_ID_MULTI_EMPRESA = 'multiEmpresa';
const NUMBER_3 = 3;

@Component({
  selector: 'module-mapa-pagamentos',
  templateUrl: './mapaPagamentos.module.component.html'
})
export class MapaPagamentosModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public licencaStoreModePublic: boolean;
  @Input() public userConfig: IJsonMapaPagamentoConfig;
  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly impostoManualEnum: number;
  public readonly tabIdPorEmpresa: string;
  public readonly tabIdMultiEmpresa: string;

  public toolbarInstanceIdMapaPagam: string;
  public showFilterPanel: boolean;
  public filters: IMapaPagamFilters;
  public regimeIvaSource: Array<IMapaPagamFilterSelectItem>;
  public periodoIvaSource: Array<IMapaPagamFilterSelectItem>;
  public showAlert: boolean;
  public usersTemplate: string;
  public responsaveis: Array<IMapaPagamentoResponsavel>;
  public sourceAnos: Array<number>;
  public mapaPagamEntity: typeof EMapaPagamEntity;
  public headerSelectAll: boolean;
  public columnCheckboxesModel: IMapaPagamDataGridColumnCheckboxesModel;
  public activeTab: string;

  private readonly _mnuAnos: IPlToolbarItem;
  private readonly _mnuMes: IPlToolbarItem;
  private readonly _btnLoadPagamentos: IPlToolbarItem;
  private readonly _btnEnviar: IPlToolbarItem;
  private readonly _btnCriarPagamentoManual: IPlToolbarItem;
  private readonly _btnEditPagamentoManual: IPlToolbarItem;
  private readonly _meses: Array<IPlToolbarMenuItem>;
  private _dataGridInstance: dxDataGrid;
  private _timeoutId: number;
  private _processModalRef: NgbModalRef;
  private _mainDataSource: Array<IMapaPagamDataGridRecord | IJsonMapaPagamEmpresaItem>;
  private _isEmpresasLoad: boolean;
  private _empresasList: Array<IJsonMapaPagamEmpresaItem>;
  private _totalPagamentosSel: number;
  private readonly _lastRequestFilters: IMapaPagamentoLastRequestFilters;
  private _empresaPaymentSelTracking: IMapaPagamentoEmpresaPaymentsSelected;
  private _selectedAno: IPlToolbarMenuItem;
  private _selectedMes: IPlToolbarMenuItem;
  private _dataGridFilteredColumns: Array<string>;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    protected readonly _mapaPagamentosService: MapaPagamentosService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);
    this._meses = [];
    this.tabIdPorEmpresa = TAB_ID_POR_EMPRESA;
    this.tabIdMultiEmpresa = TAB_ID_MULTI_EMPRESA;
    this.activeTab = this.tabIdMultiEmpresa;
    this.mapaPagamEntity = EMapaPagamEntity;
    this.impostoManualEnum = EMapaPagamImpostoEnum.Manual;
    this._timeoutId = -1;
    this._dataGridFilteredColumns = [];
    this.columnCheckboxesModel = {};
    this.columnCheckboxesModel[EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IVA]] = false;
    this.columnCheckboxesModel[EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IRCMod22]] = false;
    this.columnCheckboxesModel[EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IMI]] = false;
    this.columnCheckboxesModel[EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IUC]] = false;
    this.columnCheckboxesModel[EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.DMR]] = false;
    this.columnCheckboxesModel[EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.RetencaoNaFonte]] = false;
    this.columnCheckboxesModel[EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.PagAntIRC]] = false;
    this.columnCheckboxesModel[EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.DMRSS]] = false;
    this._empresaPaymentSelTracking = {};
    this.filters = {
      responsavel: undefined,
      periodoIva: -1,
      regimeIva: EMapaPagamTipoRegimeIva.ALL,
      ano: moment().year(),
      mes: moment().month() + 1
    };
    this.sourceAnos = [];
    for (let i = moment().subtract(NUMBER_3, 'year').year(); i <= moment().year(); i++) {
      this.sourceAnos.push(i);
    }
    this._meses = DATA_SOURCE_MESES.data.map<IPlToolbarMenuItem>(({value, name}: IDataSourceItem<EDateMonth>) => {
      const itemMes: IPlToolbarMenuItem = {
        id: String(value),
        caption: this._translateService.instant(name),
        active: value === this.filters.mes,
        click: (menuItem: IPlToolbarMenuItem) => {
          this._changedMes(menuItem);
        }
      };
      if (itemMes.active) {
        this._selectedMes = itemMes;
      }
      return itemMes;
    });
    const anos: Array<IPlToolbarMenuItem> = this.sourceAnos.map<IPlToolbarMenuItem>((value: number) => {
      const itemAno: IPlToolbarMenuItem = {
        caption: String(value),
        active: value === this.filters.ano,
        click: (menuItem: IPlToolbarMenuItem) => {
          this._changedAno(menuItem);
        }
      };
      if (itemAno.active) {
        this._selectedAno = itemAno;
      }
      return itemAno;
    });
    this._btnLoadPagamentos = {
      order: 1,
      id: 'loadPagamentos',
      caption: 'mapapagam.texts.carregarPagamentos',
      class: 'btn-success',
      iconLeft: '<i class="fa fa-fw fa-refresh" aria-hidden="true"></i>',
      type: 'button',
      tooltip: {
        text: 'mapapagam.tooltips.obterDadosPortaisATeSSD',
        placement: 'bottom'
      },
      click: () => this._loadPagamentos()
    };
    this._mnuAnos = {
      order: this._btnLoadPagamentos.order + 1,
      id: 'toolbarAnos',
      caption: this._getCaptionAno(),
      type: 'dropdown',
      menu: anos
    };
    this._mnuMes = {
      order: this._mnuAnos.order + 1,
      id: 'toolbarMesses',
      caption: this._getCaptionMes(),
      type: 'dropdown',
      menu: this._meses
    };
    this._btnEnviar = {
      groupId: TOOLBAR_GROUP_ID,
      order: this._mnuMes.order + 1,
      id: 'enviarPagamentos',
      caption: 'mapapagam.texts.enviarPagamento',
      class: 'btn-info',
      iconLeft: '<i class="fa fa-fw fa-paper-plane-o" aria-hidden="true"></i>',
      type: 'button',
      disabled: true,
      tooltip: {
        text: 'mapapagam.tooltips.enviarPagamentosSelecionadosGrelha',
        placement: 'bottom'
      },
      click: () => this._enviarPagamentos('ONLY_SELECTED')
    };
    this._btnCriarPagamentoManual = {
      id: 'criarPagamentoManual',
      caption: 'mapapagam.texts.criarPagamentoManual',
      iconLeft: '<i class="fa fa-plus fa-fw" aria-hidden="true"></i>',
      disabled: true,
      click: () => this._criarPagamento()
    };
    this._btnEditPagamentoManual = {
      id: 'editarPagamentoManual',
      caption: 'mapapagam.texts.editarPagamentoManual',
      iconLeft: '<i class="fa fa-pencil fa-fw" aria-hidden="true"></i>',
      disabled: true,
      click: () => this._editarPagamento()
    };
    const nowHour = moment().hour();
    this.showAlert = nowHour >= HOUR_12 && nowHour < HOUR_14;
    this.dataGridDefinition = {
      dataSource: [],
      keyExpr: 'nEmpresa',
      selection: {
        mode: 'multiple',
        selectAllMode: 'allPages',
        showCheckBoxesMode: 'always'
      },
      editing: {
        allowUpdating: true,
        mode: 'cell'
      },
      paging: {
        pageSize: 25
      },
      columns: [
        {
          caption: 'mapapagam.texts.empresas',
          fixed: true,
          fixedPosition: 'left',
          columns: [
            {
              dataField: 'errorMessage',
              caption: 'mapapagam.fields.status',
              width: '100px',
              alignment: 'center',
              cellTemplate: 'statusTmpl',
              allowHiding: false,
              allowSearch: false,
              allowReordering: false,
              allowSorting: false,
              allowFiltering: false,
              allowResizing: false,
              allowHeaderFiltering: false,
              allowGrouping: false,
              allowFixing: false,
              allowEditing: false,
              allowExporting: true
            },
            {dataField: 'nEmpresa', caption: 'mapapagam.fields.nEmpresa', sortOrder: 'asc', allowEditing: false, visible: true},
            {dataField: 'nomeEmpresa', caption: 'mapapagam.fields.nomeEmpresa', width: '250px', allowEditing: false},
            {dataField: 'nif', caption: 'mapapagam.fields.nif', allowEditing: false, visible: false},
            {dataField: 'regimeIvaString', caption: 'mapapagam.fields.regimeIva', allowEditing: false, visible: false},
            {dataField: 'periodoIvaString', caption: 'mapapagam.fields.periodoIva', allowEditing: false, visible: false},
            {
              dataField: 'email',
              caption: 'mapapagam.fields.email',
              dataType: 'string',
              showEditorAlways: true,
              width: '180px',
              setCellValue: this._fnEmailSetCellValue
            }
          ]
        },
        {
          caption: 'mapapagam.texts.pagEstado',
          name: PAG_ESTADO_BAND_NAME,
          visible: false,
          columns: [
            {
              headerCellTemplate: 'headerSelAllCellTemplate',
              cellTemplate: 'selPagamentoCellTemplate',
              width: '60px',
              alignment: 'center',
              allowExporting: true,
              allowHiding: false,
              allowSearch: false,
              allowFixing: false,
              allowReordering: false,
              allowResizing: false,
              showInColumnChooser: false,
              allowHeaderFiltering: false
            },
            {
              dataField: EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.DMR],
              caption: 'mapapagam.fields.dmr',
              cellTemplate: 'pagamentoTemplate',
              headerCellTemplate: 'headerSelColCellTemplate',
              width: FIXED_COL_WIDTH,
              allowEditing: false,
              alignment: 'center',
              allowHeaderFiltering: false,
              visible: false
            },
            {
              dataField: EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.DMRSS],
              caption: 'mapapagam.fields.dmrSS',
              cellTemplate: 'pagamentoTemplate',
              headerCellTemplate: 'headerSelColCellTemplate',
              width: FIXED_COL_WIDTH,
              allowEditing: false,
              alignment: 'center',
              allowHeaderFiltering: false,
              visible: false
            },
            {
              dataField: EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IVA],
              caption: 'mapapagam.fields.iva',
              cellTemplate: 'pagamentoTemplate',
              headerCellTemplate: 'headerSelColCellTemplate',
              width: FIXED_COL_WIDTH,
              allowEditing: false,
              alignment: 'center',
              allowHeaderFiltering: false,
              visible: false
            },
            {
              dataField: EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.RetencaoNaFonte],
              caption: 'mapapagam.fields.retencaoNaFonte',
              cellTemplate: 'pagamentoTemplate',
              headerCellTemplate: 'headerSelColCellTemplate',
              width: FIXED_COL_WIDTH,
              allowEditing: false,
              alignment: 'center',
              allowHeaderFiltering: false,
              visible: false
            },
            {
              dataField: EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IRCMod22],
              caption: 'mapapagam.fields.ircMod22',
              cellTemplate: 'pagamentoTemplate',
              headerCellTemplate: 'headerSelColCellTemplate',
              width: FIXED_COL_WIDTH,
              allowEditing: false,
              alignment: 'center',
              allowHeaderFiltering: false,
              visible: false
            },
            {
              dataField: EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IMI],
              caption: 'mapapagam.fields.imi',
              cellTemplate: 'pagamentoTemplate',
              headerCellTemplate: 'headerSelColCellTemplate',
              width: FIXED_COL_WIDTH,
              allowEditing: false,
              alignment: 'center',
              allowHeaderFiltering: false,
              visible: false
            },
            {
              dataField: EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IUC],
              caption: 'mapapagam.fields.iuc',
              cellTemplate: 'pagamentoTemplate',
              headerCellTemplate: 'headerSelColCellTemplate',
              width: FIXED_COL_WIDTH,
              allowEditing: false,
              alignment: 'center',
              allowHeaderFiltering: false,
              visible: false
            },
            {
              dataField: EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.PagAntIRC],
              caption: 'mapapagam.fields.pagAntIRC',
              cellTemplate: 'pagamentoTemplate',
              headerCellTemplate: 'headerSelColCellTemplate',
              width: FIXED_COL_WIDTH,
              allowEditing: false,
              alignment: 'center',
              allowHeaderFiltering: false,
              visible: false
            }
          ]
        },
        {
          dataField: 'vf_actions',
          fixed: true,
          fixedPosition: 'right',
          caption: ' ',
          width: '80px',
          alignment: 'center',
          cellTemplate: 'actions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false
        },
        {
          caption: 'mapapagam.texts.pagamentosManuais',
          name: DYNAMIC_BAND_NAME,
          visible: false,
          columns: []
        }
      ],
      scrolling: {rowRenderingMode: 'standard'},
      height: this.showAlert ? '72vh' : '78vh',
      toolbar: {
        items: [
          {
            location: 'after',
            template: 'toolbarTemplateFiltros',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      },
      stateStoring: {
        beforeStore: this._onBeforeStore,
        generateStateInstanceIdFn: (userId: number, nEmpresa: string, cgDxDataGridInstanceName: string) => {
          return `${userId}.000.${cgDxDataGridInstanceName}`;
        }
      },
      export: {
        filename: 'global.menu.mapaPagamentos',
        enabled: true,
        propertiesExcel: {
          customizeCell: ({gridCell, excelCell}: IDevExpressDataGridExportExcelCustomizeCellOptions) => {
            this._dataGridExportCustomizeCell(gridCell, excelCell, false);
          }
        },
        enabledPdf: false
      }
    };
    this.regimeIvaSource = [
      {value: EMapaPagamTipoRegimeIva.ALL, name: 'global.text.all'},
      {value: EMapaPagamTipoRegimeIva.Normal, name: 'mapapagam.texts.regimeIva.normal'},
      {value: EMapaPagamTipoRegimeIva.Isencao, name: 'mapapagam.texts.regimeIva.isencao'},
      {value: EMapaPagamTipoRegimeIva.PequenosRetalhistas, name: 'mapapagam.texts.regimeIva.pequenosRetalhistas'},
      {value: EMapaPagamTipoRegimeIva.RegimeNormalBens2Mao, name: 'mapapagam.texts.regimeIva.regimeNormalBens2Mao'},
      {value: EMapaPagamTipoRegimeIva.RegimeNormalRevendaCombustiveis, name: 'mapapagam.texts.regimeIva.regimeNormalRevendaCombustíveis'},
      {value: EMapaPagamTipoRegimeIva.RegimeIvaCaixa, name: 'mapapagam.texts.regimeIva.regimeNormalIvaCaixa'}
    ];
    this.periodoIvaSource = [
      {value: EMapaPagamTipoPeriodoIva.ALL, name: 'global.text.all'},
      {value: EMapaPagamTipoPeriodoIva.Mensal, name: 'mapapagam.texts.periodoIva.mensal'},
      {value: EMapaPagamTipoPeriodoIva.Trimestral, name: 'mapapagam.texts.periodoIva.trimestral'}
    ];
    this._empresasList = [];
    this._isEmpresasLoad = false;
    this.responsaveis = [];
    this.headerSelectAll = false;
    this._totalPagamentosSel = 0;
    this._lastRequestFilters = {
      ano: this.filters.ano,
      mes: this.filters.mes
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.usersTemplate = !this.licencaStoreModePublic ? '{{nResponsavel}} - {{nomeResponsavel}}' : '{{nomeResponsavel}}';

    // toolbar configs
    this.btnConfig.visible = this.dropdownActions.visible = true;
    this.dropdownActions.order = this._btnEnviar.order + 1;
    this.btnConfig.order = this.dropdownActions.order + 1;
    this.btnConfig.click = () => this._showConfigModal();
    this.toolbar.addButton(this._btnLoadPagamentos).addButton(this._mnuAnos).addButton(this._mnuMes);
    this._buildToolbarResponsive(this.isMobile);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._clearProcessChecker();
    this._mapaPagamentosService.stopMS();
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    if (this.toolbar) {
      this._buildToolbarResponsive(value);
    }
  }

  public setInstanceName(value: string): void {
    super.setInstanceName(value);
    if (this.toolbarInstanceIdMapaPagam) {
      this._plToolbarService.unRegisterInstance(this.toolbarInstanceIdMapaPagam);
    }
    this.toolbarInstanceIdMapaPagam = `${this.instanceName}-${TOOLBAR_INSTANCE_ID_MAPA_PAGAMENTOS}`;
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
    if (this._isEmpresasLoad) {
      setTimeout(() => this._dataGridInstance.selectAll());
      this._isEmpresasLoad = false;
    }
  }

  public previewDocUnico(empresaRow: IMapaPagamDataGridRecord): Promise<void> {
    if (!empresaRow.havePagamentos) {
      return undefined;
    }

    const pagamentosSel: Array<string> = [];
    let havePagamSelected = false;
    for (const prop in empresaRow) {
      if (isObject(empresaRow[prop]) && this._isPagamentoObject(empresaRow[prop])) {
        if (empresaRow[prop][EMapaPagamDataGridProps.SELECTED] === true) {
          havePagamSelected = true;
          pagamentosSel.push(empresaRow[prop][EMapaPagamDataGridProps.PAGAMENTO][EMapaPagamDataGridProps.PAGAMENTO_GUID]);
        }
      }
    }

    if (!havePagamSelected) {
      this._plAlertService.error(this._translateService.instant('mapapagam.messages.empresaNaoTemPagamentosSelecionados', {empresa: empresaRow.nEmpresa}));
      return undefined;
    }

    const modalInstance = this._cgModalService.showVanilla(MapaPagamentosDocumentosModalComponent, {size: 'xl', backdrop: 'static', keyboard: false});
    const componentInstance: MapaPagamentosDocumentosModalComponent = modalInstance.componentInstance;
    componentInstance.viewMode = EMapaPagamPdfViewerMode.DOCUMENTO_UNICO;
    componentInstance.nEmpresa = empresaRow.nEmpresa;
    componentInstance.pagamentos = pagamentosSel;
    componentInstance.ano = this.filters.ano;
    componentInstance.mes = this.filters.mes;
    return modalInstance.result;
  }

  public editarPagamentoManual(data: IMapaPagamEmpresaDataGridPagamentoItem, empresaRow: IMapaPagamDataGridRecord): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(MapaPagamentosPagamentoModalComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    const componentInstance: MapaPagamentosPagamentoModalComponent = modalInstance.componentInstance;
    componentInstance.storeModePublic = this.licencaStoreModePublic;
    componentInstance.sourceResponsaveis = this.responsaveis;
    componentInstance.nEmpresa = empresaRow.nEmpresa;
    componentInstance.nomeEmpresa = empresaRow.nomeEmpresa;
    componentInstance.mes = data.pagamento.mes;
    componentInstance.ano = data.pagamento.ano;
    componentInstance.pagamento = data.pagamento;
    return modalInstance.result.then((modalResult: IMapaPagamentoModalResult) => {
      if (modalResult.isDelete) {
        const colName = this._generateColName(data.pagamento.nome);
        this._dataGridInstance.deleteColumn(colName);
        this._mainDataSource.forEach((item) => {
          if (isDefinedNotNull(item[colName])) {
            delete item[colName];
          }
        });
      } else {
        const newSource = this._createColumnsAndTransformSource(modalResult.results);
        newSource.forEach((item) => {
          this._categorizeMessages(item);
          const index = this._mainDataSource.findIndex((row) => row.nEmpresa === item.nEmpresa);
          if (index > -1) {
            this._mainDataSource[index] = item;
          }
        });

        if (modalResult.oldPagName !== modalResult.newPagName) {
          const oldColumnName = this._generateColName(modalResult.oldPagName);
          this._mainDataSource.forEach((item) => {
            if (isDefinedNotNull(item[oldColumnName])) {
              item[this._generateColName(modalResult.newPagName)] = {...item[oldColumnName]};
              delete item[oldColumnName];
            }
          });
        }

        this.dataGridDefinition.dataSource = this._mainDataSource;
      }
    });
  }

  public verATDocumentoPagamento(data: IMapaPagamEmpresaDataGridPagamentoItem, empresaRow: IMapaPagamDataGridRecord): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(MapaPagamentosDocumentosModalComponent, {size: 'xl', backdrop: 'static', keyboard: false});
    const componentInstance: MapaPagamentosDocumentosModalComponent = modalInstance.componentInstance;
    componentInstance.nEmpresa = empresaRow.nEmpresa;
    componentInstance.pagamentoGUID = data.pagamento.pagamentoGUID;
    componentInstance.pagamentoNome = data.pagamento.nome;
    componentInstance.impostoEnum = data.pagamento.impostoEnum;
    componentInstance.documentos = data.pagamento.documentos;
    componentInstance.ano = this.filters.ano;
    componentInstance.mes = this.filters.mes;
    return modalInstance.result;
  }

  public marcarComoPago(data: IMapaPagamEmpresaDataGridPagamentoItem, empresaRow: IMapaPagamDataGridRecord): TServiceResponse<void> {
    return this._cgModalService
      .showOkCancel('global.text.confirmation', 'mapapagam.messages.marcarComoPagoPrompt', {
        size: 'md',
        btnOkText: 'global.btn.yes',
        backdrop: 'static',
        keyboard: false
      })
      .then(() => {
        return this._mapaPagamentosService.marcarPagamentoManualPago(empresaRow.nEmpresa, data.pagamento.pagManTaskId).then(() => {
          const rowIndex = this._dataGridInstance.getRowIndexByKey(empresaRow.nEmpresa);
          data.pagamento.pago = true;
          this._dataGridInstance.repaintRows([rowIndex]);
          return undefined;
        });
      });
  }

  public enviarPagamento(data: IMapaPagamEmpresaDataGridPagamentoItem, empresaRow: IMapaPagamDataGridRecord): Promise<void> {
    if (!this._validateEmails(empresaRow.email)) {
      this._plAlertService.error(this._plTranslateService.translate('mapapagam.messages.empresaNaoTemEmailInvalido', {empresa: empresaRow.nEmpresa}));
      return undefined;
    }

    const model: IJsonMapaPagamEmpresasEnvio = {
      ano: this.filters.ano,
      mes: this.filters.mes,
      empresas: [
        {
          nEmpresa: empresaRow.nEmpresa,
          email: empresaRow.email,
          listOfPagamentos: [data.pagamento.pagamentoGUID]
        }
      ]
    };

    this._dataGridInstance.selectRows([empresaRow.nEmpresa], true);
    return this._processEnviarPagamentos(model);
  }

  public verDetalhesPagamento(data: IMapaPagamEmpresaDataGridPagamentoItem): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(MapaPagamentosPagamentoDetalhesModalComponent, {size: 'lg', backdrop: 'static'});
    const componentInstance: MapaPagamentosPagamentoDetalhesModalComponent = modalInstance.componentInstance;
    componentInstance.pagamento = {...data.pagamento};
    return modalInstance.result;
  }

  public apagarPagamento(data: IMapaPagamEmpresaDataGridPagamentoItem, empresaRow: IMapaPagamDataGridRecord): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(MapaPagamentosApagarPromptModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
    const componentInstance: MapaPagamentosApagarPromptModalComponent = modalInstance.componentInstance;
    componentInstance.nEmpresa = empresaRow.nEmpresa;
    componentInstance.ano = data.pagamento.ano;
    componentInstance.mes = data.pagamento.mes;
    componentInstance.pagamentoGUID = data.pagamento.pagamentoGUID;
    return modalInstance.result.then((deleteMethod: EMapaPagamManualDeleteMethod) => {
      const colName = this._generateColName(data.pagamento.nome);
      let canDeleteCol = true;
      this._mainDataSource.forEach((item) => {
        if (isDefinedNotNull(item[colName])) {
          if (deleteMethod === EMapaPagamManualDeleteMethod.Everywhere || item.nEmpresa === empresaRow.nEmpresa) {
            delete item[colName];
          } else if (item[colName].havePayments) {
            canDeleteCol = false;
          }
        }
      });

      if (canDeleteCol) {
        this._dataGridInstance.deleteColumn(colName);
      }
    });
  }

  public getLidoTooltip(cellValue: IMapaPagamEmpresaDataGridPagamentoItem): string {
    return this._plTranslateService.translate('mapapagam.tooltips.emailEnviadoELido', {
      enviadoDataHora: moment(cellValue.pagamento.enviadoDataHora).format(this.format.momentDatetime),
      lidoDataHora: moment(cellValue.pagamento.lidoDataHora).format(this.format.momentDatetime)
    });
  }

  public getEnviadoTooltip(cellValue: IMapaPagamEmpresaDataGridPagamentoItem): string {
    if (cellValue.pagamento.enviado) {
      return this._plTranslateService.translate('mapapagam.tooltips.emailEnviado', {enviadoDataHora: moment(cellValue.pagamento.enviadoDataHora).format(this.format.momentDatetime)});
    }
    return this._plTranslateService.translate('mapapagam.tooltips.emailNaoEnviado');
  }

  public addContextMenuItems(event: IDevExpressDataGridEventOnContextMenuPreparing<IMapaPagamDataGridRecord>): void {
    if (event.target === 'content' && event.row?.rowType === 'data' && isObject(event.row.data)) {
      event.items = [
        {
          icon: 'fa fa-cog',
          text: this._translateService.instant('mapapagam.texts.configurarCredentials'),
          items: [
            {
              text: this._translateService.instant('mapapagam.texts.portalAT'),
              onItemClick: () => this.configCredentials(EMapaPagamEntity.AT, event.row.data)
            },
            {
              text: this._translateService.instant('mapapagam.texts.portalSSD'),
              onItemClick: () => this.configCredentials(EMapaPagamEntity.SSD, event.row.data)
            }
          ]
        }
      ];
    }
  }

  public gridFiltersApply(): void {
    let filteredSource = cloneDeep(this._mainDataSource);
    this._dataGridFilteredColumns.forEach((colName) => {
      this._dataGridInstance.columnOption(colName, 'visible', true);
    });
    this._dataGridFilteredColumns = [];

    if (this.filters.regimeIva !== EMapaPagamTipoRegimeIva.ALL) {
      filteredSource = filteredSource.filter((item) => {
        return item.regimeIva === this.filters.regimeIva;
      });
    }

    if (this.filters.periodoIva !== EMapaPagamTipoPeriodoIva.ALL) {
      filteredSource = filteredSource.filter((item) => {
        return item.periodoIva === this.filters.periodoIva;
      });
    }

    if (this.filters.responsavel?.nResponsavel > 0) {
      filteredSource = filteredSource.filter((item) => {
        let isRowMatched = false;

        for (const prop in item) {
          if (isObject(item[prop]) && this._isPagamentoObject(item[prop])) {
            if (item[prop][EMapaPagamDataGridProps.PAGAMENTO][EMapaPagamDataGridProps.NRESPONSAVEL] === this.filters.responsavel.nResponsavel) {
              isRowMatched = true;
            } else {
              delete item[prop];
              this._dataGridFilteredColumns.push(prop);
              this._dataGridInstance.columnOption(prop, 'visible', false);
            }
          }
        }
        return isRowMatched;
      });
    }

    this.dataGridDefinition.dataSource = filteredSource;
  }

  public gridFiltersReset(): void {
    this.filters = {
      responsavel: undefined,
      periodoIva: -1,
      regimeIva: EMapaPagamTipoRegimeIva.ALL,
      ano: moment().year(),
      mes: moment().month()
    };
    this.dataGridDefinition.dataSource = this._mainDataSource;
    this._dataGridFilteredColumns.forEach((colName) => {
      this._dataGridInstance.columnOption(colName, 'visible', true);
    });
    this._dataGridFilteredColumns = [];
  }

  public configCredentials(entity: EMapaPagamEntity, row: IMapaPagamDataGridRecord): Promise<void> {
    if (entity === EMapaPagamEntity.AT) {
      const modalInstance = this._cgModalService.showVanilla(MapaPagamentosLoginModalComponent, {size: 'sm', backdrop: 'static'});
      const componentInstance: MapaPagamentosLoginModalComponent = modalInstance.componentInstance;
      componentInstance.nEmpresa = row.nEmpresa;
      componentInstance.entity = entity;
      return modalInstance.result.then(() => {
        this._removeErrorMessages(row, [EJsonMapaPagamMessageCode.EmptyCredentialsAT, EJsonMapaPagamMessageCode.LoginAT]);
      });
    }

    const modalInstance = this._cgModalService.showVanilla(ConfigLoginWsSSModalComponent, {size: 'md'});
    const componentInstance: ConfigLoginWsSSModalComponent = modalInstance.componentInstance;
    componentInstance.nEmpresa = row.nEmpresa;
    return modalInstance.result.then(() => {
      this._removeErrorMessages(row, [EJsonMapaPagamMessageCode.EmptyCredentialsSSD, EJsonMapaPagamMessageCode.LoginSSD]);
    });
  }

  public pagamentoRowCheckClick(value: boolean, item: IDevExpressDataGridCellTemplateData<IMapaPagamEmpresaDataGridPagamentoItem>): void {
    let canProcessCheck = false;
    for (const prop in item.data) {
      if (isObject(item.data[prop]) && this._isPagamentoObject(item.data[prop])) {
        const pagItem: IMapaPagamEmpresaDataGridPagamentoItem = item.data[prop];
        if (isDefinedNotNull(pagItem.pagamento) && !pagItem.pagamento.pago && pagItem.pagamento.documentos.length) {
          if (this.setPaymentSelected(pagItem, <IMapaPagamDataGridRecord>(<unknown>item.data), value)) {
            canProcessCheck = true;
          }
        }
      }
    }
    if (canProcessCheck) {
      item.data.selectRowCheck = value;
    }
  }

  public pagamentoColCheckClick(value: boolean, item: IDevExpressDataGriHeaderCellTemplateData<IMapaPagamEmpresaDataGridPagamentoItem>): void {
    let canProcessCheck = false;
    this._mainDataSource.forEach((row) => {
      for (const prop in row) {
        if (prop === item.column.dataField) {
          const pagItem: IMapaPagamEmpresaDataGridPagamentoItem = row[prop];
          if (pagItem.havePayments) {
            if (this.setPaymentSelected(pagItem, <IMapaPagamDataGridRecord>row, value)) {
              canProcessCheck = true;
            }
          }
        }
      }
    });
    if (canProcessCheck) {
      this.columnCheckboxesModel[item.column.dataField] = value;
    }
  }

  public headerSelectAllChange(value: boolean): void {
    let canProcessCheck = false;
    this._mainDataSource.forEach((empresa) => {
      for (const prop in empresa) {
        if (isObject(empresa[prop]) && this._isPagamentoObject(empresa[prop])) {
          const pagItem: IMapaPagamEmpresaDataGridPagamentoItem = empresa[prop];
          if (pagItem.havePayments && !pagItem.pagamento.pago) {
            if (this.setPaymentSelected(pagItem, <IMapaPagamDataGridRecord>empresa, value)) {
              canProcessCheck = true;
              empresa.selectRowCheck = value;
              this.columnCheckboxesModel[prop] = value;
            }
          }
        }
      }
    });

    if (canProcessCheck) {
      this.headerSelectAll = value;
    }
  }

  public setPaymentSelected(item: IMapaPagamEmpresaDataGridPagamentoItem, empresaRow: IMapaPagamDataGridRecord, value: boolean): boolean {
    if (item.pagamento.pago || !item.pagamento.documentos.length) {
      return false;
    }
    item.selected = value;
    if (value) {
      empresaRow.totalPagamentosSel++;
      this._totalPagamentosSel++;
      if (!isArray(this._empresaPaymentSelTracking[empresaRow.nEmpresa])) {
        this._empresaPaymentSelTracking[empresaRow.nEmpresa] = [];
      }
      this._empresaPaymentSelTracking[empresaRow.nEmpresa].push(item);
    } else {
      empresaRow.totalPagamentosSel--;
      this._totalPagamentosSel--;
      this._empresaPaymentSelTracking[empresaRow.nEmpresa] = this._empresaPaymentSelTracking[empresaRow.nEmpresa].filter((filter) => filter.pagamento.pagamentoGUID !== item.pagamento.pagamentoGUID);
    }
    this._btnEnviar.disabled = this._totalPagamentosSel <= 0;

    if (this._empresaPaymentSelTracking[empresaRow.nEmpresa].length) {
      this._dataGridInstance.selectRows([empresaRow.nEmpresa], true);
    } else {
      this._dataGridInstance.deselectRows([empresaRow.nEmpresa]);
    }
    return true;
  }

  public readonly fnToggleFilterPanel = (): void => {
    this._toggleFilterPanel();
  };

  public readonly fnPesquisar = (): Promise<void> => this._pesquisar();

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._appService.sendBeacon(this._mapaPagamentosService.stopMSUrl());
  }

  private _loadPagamentos(): Promise<void> {
    const selEmpresas: Array<string> = this._dataGridInstance.getSelectedRowKeys();

    if (selEmpresas.length === 0) {
      this._plAlertService.error('mapapagam.messages.temSeleccionarEmpresa');
      return undefined;
    }

    return new Promise((resolve, reject) => {
      this._mapaPagamentosService
        .loadEmpresasComPagamentos(this.filters.ano, this.filters.mes, selEmpresas)
        .then(() => {
          const clearNonInList = this._lastRequestFilters.ano !== this.filters.ano || this._lastRequestFilters.mes !== this.filters.mes;
          this._lastRequestFilters.ano = this.filters.ano;
          this._lastRequestFilters.mes = this.filters.mes;
          this._startProcessChecker((isSuccess) => {
            if (isSuccess) {
              if (this._dataGridInstance) {
                this._dataGridInstance.beginCustomLoading(undefined);
              }
              this._mapaPagamentosService
                .getEmpresasComPagamentos()
                .then((response) => {
                  this._renderNewSource(response.body, true, clearNonInList);
                  resolve();
                })
                .catch(reject)
                .finally(() => {
                  if (this._dataGridInstance) {
                    this._dataGridInstance.endCustomLoading();
                    this._dataGridInstance.deselectAll();
                  }
                });
            } else {
              resolve();
            }
          });
        })
        .catch(reject);
    });
  }

  private _enviarPagamentos(nomePagamento: string | 'ONLY_SELECTED'): Promise<void> {
    const empresasSel: Array<IMapaPagamDataGridRecord> = this._dataGridInstance.getSelectedRowsData();

    if (empresasSel.length === 0) {
      this._plAlertService.error('mapapagam.messages.temSeleccionarEmpresa');
      return undefined;
    }

    const envioModel: IJsonMapaPagamEmpresasEnvio = {
      ano: this.filters.ano,
      mes: this.filters.mes,
      empresas: []
    };

    const enviadosList: Array<IMapaPagamJaEnviadoItem> = [];
    let havePagamentosEnviados = false;

    for (const empresa of empresasSel) {
      if (!empresa.havePagamentos) {
        this._plAlertService.error(this._translateService.instant('mapapagam.messages.empresaNaoTemPagamentosCarregados', {empresa: empresa.nEmpresa}));
        return undefined;
      }

      const enviadoItem: IMapaPagamJaEnviadoItem = {
        nEmpresa: empresa.nEmpresa,
        nomeEmpresa: empresa.nomeEmpresa,
        pagamentos: []
      };

      let havePagamSelected = false;
      const pagamentosSel = [];
      for (const prop in empresa) {
        if (isObject(empresa[prop]) && this._isPagamentoObject(empresa[prop])) {
          if (nomePagamento !== 'ONLY_SELECTED') {
            this.setPaymentSelected(<IMapaPagamEmpresaDataGridPagamentoItem>empresa[prop], empresa, false);
            if (this._generateColName(nomePagamento) === prop && empresa[prop][EMapaPagamDataGridProps.MONTANTETOTAL] > 0) {
              this.setPaymentSelected(<IMapaPagamEmpresaDataGridPagamentoItem>empresa[prop], empresa, true);
            }
          }
          if (empresa[prop][EMapaPagamDataGridProps.SELECTED] === true) {
            havePagamSelected = true;
            pagamentosSel.push(empresa[prop][EMapaPagamDataGridProps.PAGAMENTO][EMapaPagamDataGridProps.PAGAMENTO_GUID]);
            if (empresa[prop][EMapaPagamDataGridProps.PAGAMENTO][EMapaPagamDataGridProps.ENVIADO] === true) {
              havePagamentosEnviados = true;
              enviadoItem.pagamentos.push(empresa[prop][EMapaPagamDataGridProps.PAGAMENTO]);
            }
          }
        }
      }
      if (!havePagamSelected) {
        this._plAlertService.error(this._translateService.instant('mapapagam.messages.empresaNaoTemPagamentosSelecionados', {empresa: empresa.nEmpresa}));
        return undefined;
      }
      if (empresa.email.length === 0) {
        this._plAlertService.error(this._translateService.instant('mapapagam.messages.empresaNaoTemEmailPreenchido', {empresa: empresa.nEmpresa}));
        return undefined;
      }

      if (!this._validateEmails(empresa.email)) {
        this._plAlertService.error(this._plTranslateService.translate('mapapagam.messages.empresaNaoTemEmailInvalido', {empresa: empresa.nEmpresa}));
        return undefined;
      }

      enviadosList.push(enviadoItem);

      envioModel.empresas.push({
        nEmpresa: empresa.nEmpresa,
        email: empresa.email,
        listOfPagamentos: pagamentosSel
      });
    }

    let promise = Promise.resolve();
    if (havePagamentosEnviados) {
      const modalInstance = this._cgModalService.showVanilla(MapaPagamentosEnviadosPromptModalComponent, {size: 'md', backdrop: 'static', keyboard: false});
      const componentInstance: MapaPagamentosEnviadosPromptModalComponent = modalInstance.componentInstance;
      componentInstance.listJaEnviados = enviadosList;
      promise = modalInstance.result;
    }

    return promise.then(() => {
      return this._processEnviarPagamentos(envioModel);
    });
  }

  private _processEnviarPagamentos(envioModel: IJsonMapaPagamEmpresasEnvio, skipErrors: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      this._showProcessModal();
      this._setDescriptionProcessModal(this._plTranslateService.translate('mapapagam.texts.aPreparar'));

      this._mapaPagamentosService
        .enviarPagamentos(envioModel, skipErrors)
        .then(() => {
          this._startProcessChecker((isSuccess) => {
            if (isSuccess) {
              this._loadResumoEnvio(envioModel).finally(resolve);
            } else {
              resolve();
            }
          });
        })
        .catch(reject);
    });
  }

  private _loadResumoEnvio(envioModel: IJsonMapaPagamEmpresasEnvio): Promise<void> {
    return this._mapaPagamentosService.obterResumoEnvio().then((response) => {
      const haveErrors = response.body.resumo.find((item) => {
        return item.success === false;
      });
      if (isDefinedNotNull(haveErrors)) {
        this._showResumoEnvioErros(response.body.resumo, envioModel);
      } else {
        this._plAlertService.success('mapapagam.messages.documentoUnicoEnviadoSucesso');
        this._loadPagamentos();
      }
    });
  }

  private _showResumoEnvioErros(list: Array<IJsonMapaPagamEmpresasEnvioResumoItem>, envioModel: IJsonMapaPagamEmpresasEnvio): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(MapaPagamentosResumoEnvioModalComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    const componentInstance: MapaPagamentosResumoEnvioModalComponent = modalInstance.componentInstance;
    componentInstance.list = list;
    return modalInstance.result.then((response: EMapaPagamResend) => {
      const newEnvioModel = {...envioModel};

      const listaEmpresasComErros = list
        .filter((item) => {
          if (!item.success) {
            return true;
          }
          const pag = item.pagamentos.find((pagItem) => {
            return pagItem.errorMessage.length > 0;
          });
          return isDefinedNotNull(pag);
        })
        .map((item) => {
          return item.nEmpresa;
        });

      newEnvioModel.empresas = newEnvioModel.empresas.filter((empresa) => {
        return listaEmpresasComErros.includes(empresa.nEmpresa);
      });

      if (response === EMapaPagamResend.RETRY) {
        return this._processEnviarPagamentos(newEnvioModel);
      }
      return this._processEnviarPagamentos(newEnvioModel, true);
    });
  }

  private _pesquisar(): Promise<void> {
    if (!this.userConfig.isConfigured) {
      this._showConfigModal(false);
      return Promise.resolve();
    }
    this._showProcessModal();
    this._setDescriptionProcessModal(this._translateService.instant('mapapagam.messages.carregarEmpresas'));

    if (this._dataGridInstance) {
      this._dataGridInstance.beginCustomLoading(undefined);
    }

    this._totalPagamentosSel = 0;
    this._btnEnviar.disabled = true;
    return this._mapaPagamentosService
      .loadEmpresas({regimeIva: this.filters.regimeIva, periodoIva: this.filters.periodoIva})
      .then((response) => {
        this._cardPanel.collapse();
        this._mainDataSource = this._empresasList = response.body.empresas;
        this._mainDataSource.forEach((item) => {
          this._categorizeMessages(item);
        });
        this.dataGridDefinition.dataSource = this._mainDataSource;
        this._dataGridInstance.deselectAll();
        this._btnCriarPagamentoManual.disabled = false;
        this._btnEditPagamentoManual.disabled = false;
        this._loadResponsaveis();
        this._configPagamentosColumns();
        this._isEmpresasLoad = true;
      })
      .finally(() => {
        this._closeProcessModal();
        if (this._dataGridInstance) {
          this._dataGridInstance.endCustomLoading();
        }
      });
  }

  private _createColumnsAndTransformSource(data: IJsonMapaPagamLoadResults): Array<IMapaPagamDataGridRecord> {
    this._dataGridInstance.deleteColumn(DYNAMIC_BAND_NAME);
    const source: Array<IMapaPagamDataGridRecord> = [];
    data.empresas.forEach((empresaItem) => {
      const sourceItem: IMapaPagamDataGridRecord = {
        nEmpresa: empresaItem.nEmpresa,
        nomeEmpresa: empresaItem.nomeEmpresa,
        nif: empresaItem.nif,
        email: empresaItem.email,
        messageList: empresaItem.messageList,
        havePagamentos: false,
        haveDocWithAttachment: false,
        totalPagamentosSel: 0
      };

      empresaItem.pagamentos.forEach((pagamentoItem) => {
        const cellValue: IMapaPagamEmpresaDataGridPagamentoItem = {
          selected: false,
          pagamento: pagamentoItem,
          montanteTotal: 0,
          _sortValue: 0,
          havePayments: pagamentoItem.documentos.length > 0
        };

        cellValue.pagamento.haveDocWithAttachment = false;

        if (cellValue.pagamento.documentos.length) {
          sourceItem.havePagamentos = true;
          let total = 0;
          let totalPaid = 0;
          cellValue.pagamento.documentos.forEach((documento) => {
            total += documento.montante;
            if (documento.pago) {
              totalPaid += documento.montante;
            }
            if (documento.gDocId !== EMPTY_GUID) {
              cellValue.pagamento.haveDocWithAttachment = true;
            }
          });

          if (cellValue.pagamento.impostoEnum === EMapaPagamImpostoEnum.Manual && cellValue.pagamento.pago) {
            totalPaid = total;
          }

          if (total > 0 || totalPaid > 0) {
            const diff = total - totalPaid;
            if (diff > 0) {
              cellValue.pagamento.pago = false;
              cellValue.montanteTotal = diff;
              cellValue._sortValue = diff * -1;
            } else {
              cellValue.pagamento.pago = true;
              cellValue.montanteTotal = totalPaid;
              cellValue._sortValue = totalPaid;
            }
          }
        }

        const columnName = pagamentoItem.impostoEnum === EMapaPagamImpostoEnum.Manual ? this._generateColName(pagamentoItem.nome) : EMAPAPAGAMIMPOSTOENUMCOLUMN[pagamentoItem.impostoEnum];
        if (pagamentoItem.impostoEnum === EMapaPagamImpostoEnum.DMRSS) {
          pagamentoItem.pagoUnknown = true;
        }
        sourceItem[columnName] = cellValue;
      });

      data.columns.forEach((colName) => {
        const columnName = this._generateColName(colName);
        if (isUndefinedOrNull(sourceItem[columnName])) {
          const cellValue: IMapaPagamEmpresaDataGridPagamentoItem = {
            selected: false,
            pagamento: undefined,
            montanteTotal: 0,
            _sortValue: 0,
            havePayments: false
          };

          cellValue.pagamento = empresaItem.pagamentos.find((pagamentoItem) => {
            return pagamentoItem.nome.toLowerCase() === columnName.toLowerCase();
          });

          if (isDefinedNotNull(cellValue.pagamento)) {
            sourceItem.havePagamentos = true;
            cellValue.havePayments = cellValue.pagamento.documentos.length > 0;
            cellValue.pagamento.haveDocWithAttachment = false;
            let total = 0;
            let totalPaid = 0;
            cellValue.pagamento.documentos.forEach((documento) => {
              total += documento.montante;
              if (documento.pago) {
                totalPaid += documento.montante;
              }
              if (documento.gDocId !== EMPTY_GUID) {
                cellValue.pagamento.haveDocWithAttachment = true;
              }
            });

            const diff = total - totalPaid;

            if (diff > 0) {
              cellValue.pagamento.pago = false;
              cellValue.montanteTotal = diff;
              cellValue._sortValue = diff * -1;
            } else {
              cellValue.pagamento.pago = true;
              cellValue.montanteTotal = totalPaid;
              cellValue._sortValue = totalPaid;
            }
          }
          sourceItem[columnName] = cellValue;
        }
      });

      source.push(sourceItem);
    });

    if (data.columns.length) {
      const columns: Array<IDevExpressDataGridColumn> = [];
      const columnsForCreate: Array<string> = data.columns.sort((a, b) => (a > b ? 1 : -1));

      columnsForCreate.forEach((columnName) => {
        const dateField = this._generateColName(columnName);
        this.columnCheckboxesModel[dateField] = false;

        columns.push({
          caption: columnName,
          dataField: dateField,
          cellTemplate: 'pagamentoTemplate',
          headerCellTemplate: 'headerSelColCellTemplate',
          alignment: 'center',
          allowEditing: false,
          width: FIXED_COL_WIDTH,
          allowHeaderFiltering: false,
          calculateSortValue: function (rowData: IMapaPagamEmpresaDataGridPagamentoItem) {
            if (isDefinedNotNull(rowData[this.dataField]) && isDefinedNotNull(rowData[this.dataField].pagamento)) {
              return rowData[this.dataField]._sortValue;
            }
            return Number.MAX_SAFE_INTEGER;
          }
        });
      });

      this._dataGridInstance.addColumn({
        caption: this._plTranslateService.translate('mapapagam.texts.pagamentos'),
        name: DYNAMIC_BAND_NAME,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        columns: <any>columns,
        alignment: 'center'
      });
    }

    return source;
  }

  private _generateColName(name: string): string {
    name = name.replace(/\W/g, '').toUpperCase();
    return `${DYNAMIC_COL_PREFIX}${name}`;
  }

  private _clearProcessChecker(): void {
    if (isNumber(this._timeoutId)) {
      window.clearTimeout(this._timeoutId);
      this._timeoutId = undefined;
    }
  }

  private _processCheckerRequest(fn: TMapaPagamJobStateIdleCallback, timeout = 0): void {
    this._timeoutId = window.setTimeout(() => {
      this._mapaPagamentosService
        .getStatus()
        .then((response: HttpResponse<IJsonMapaPagamState>) => {
          if (response.body.state === EJobMapaPagamState.ERROR) {
            this._clearProcessChecker();
            this._closeProcessModal();
            this._plAlertService.error(response.body.description);
            if (isFunction(fn)) {
              fn(false);
            }
          } else if (response.body.state === EJobMapaPagamState.WORKING) {
            this._setDescriptionProcessModal(`${response.body.progress}/${response.body.max} : ${response.body.description}`);
            this._processCheckerRequest(fn, TIMEOUT_DELAY);
          } else if (response.body.state === EJobMapaPagamState.IDLE) {
            this._clearProcessChecker();
            this._closeProcessModal();
            if (isFunction(fn)) {
              fn(true);
            }
          }
        })
        .catch((reason) => {
          this._clearProcessChecker();
          this._closeProcessModal();
          if (reason instanceof HttpErrorResponse) {
            const exception: ICGExceptionError = this._cgExceptionService.get(reason);
            this._plAlertService.error(exception.message);
          } else {
            this._logger.error(reason);
          }
          if (isFunction(fn)) {
            fn(false);
          }
        });
    }, timeout);
  }

  private _startProcessChecker(fn: TMapaPagamJobStateIdleCallback, timeout: number = 0): void {
    this._clearProcessChecker();
    this._showProcessModal();
    this._processCheckerRequest(fn, timeout);
  }

  private _closeProcessModal(): void {
    if (this._processModalRef) {
      this._processModalRef.close();
      this._processModalRef = undefined;
    }
  }

  private _showProcessModal(): void {
    if (isUndefinedOrNull(this._processModalRef)) {
      this._processModalRef = this._cgModalService.showVanilla(MapaPagamentosProcessModalComponent, {
        size: 'md',
        backdrop: 'static',
        keyboard: false
      });
    }
  }

  private _setDescriptionProcessModal(message: string): void {
    const componentInstance: MapaPagamentosProcessModalComponent = this._processModalRef.componentInstance;
    componentInstance.message = message;
  }

  private _validateEmail(email: string): boolean {
    const expression =
      /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+/0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)*$/;
    return expression.test(email);
  }

  private _validateEmails(emails: string): boolean {
    const commaSepArray = emails.split(',');
    const semicolonSepArray = emails.split(';');
    if (commaSepArray.length === 0 && semicolonSepArray.length === 0) {
      return this._validateEmail(emails);
    }

    const arrayOfEmails = commaSepArray.length >= semicolonSepArray.length ? commaSepArray : semicolonSepArray;
    for (const email of arrayOfEmails) {
      if (!this._validateEmail(email)) {
        return false;
      }
    }
    return true;
  }

  private _removeErrorMessages(row: IMapaPagamDataGridRecord, removeErrorsArray: Array<EJsonMapaPagamMessageCode>): void {
    row.messageList = row.messageList.filter((item) => {
      return !removeErrorsArray.includes(item.code);
    });
  }

  private _categorizeMessages(item: IMapaPagamDataGridRecord | IJsonMapaPagamEmpresaItem, fromLoadPagamento: boolean = false): void {
    item.messagesAT = [];
    item.messagesSSD = [];
    const cssAT = ['mapa-pagamentos-entity-span'];
    const cssSSD = ['mapa-pagamentos-entity-span'];
    item.messageList.forEach((message) => {
      if ([EJsonMapaPagamMessageCode.LoginAT, EJsonMapaPagamMessageCode.EmptyCredentialsAT, EJsonMapaPagamMessageCode.ServiceApiAT].includes(message.code)) {
        item.messagesAT.push(message);
        cssAT.push('mapa-pagamentos-entity-span-danger');
      } else if ([EJsonMapaPagamMessageCode.LoginSSD, EJsonMapaPagamMessageCode.EmptyCredentialsSSD, EJsonMapaPagamMessageCode.ServiceApiSSD].includes(message.code)) {
        item.messagesSSD.push(message);
        cssSSD.push('mapa-pagamentos-entity-span-danger');
      }
    });

    if (fromLoadPagamento) {
      if (
        item.messagesAT.length === 0 &&
        this.userConfig.selectedPagamentos.length &&
        !(this.userConfig.selectedPagamentos.length === 1 && this.userConfig.selectedPagamentos.includes(EMapaPagamImpostoEnum.DMRSS))
      ) {
        cssAT.push('mapa-pagamentos-entity-span-success');
      }

      if (item.messagesSSD.length === 0 && this.userConfig.selectedPagamentos.includes(EMapaPagamImpostoEnum.DMRSS)) {
        cssSSD.push('mapa-pagamentos-entity-span-success');
      }
    }

    item.stateCssClassAT = cssAT.join(' ');
    item.stateCssClassSSD = cssSSD.join(' ');

    item.stateTooltipAT = item.messagesAT.map((messsageItem) => messsageItem.message).join('<br/>');
    item.stateTooltipSSD = item.messagesSSD.map((messsageItem) => messsageItem.message).join('<br/>');
  }

  private _toggleFilterPanel(): void {
    this.showFilterPanel = !this.showFilterPanel;
  }

  private _isPagamentoObject(obj: unknown): boolean {
    return isObject(obj) && Object.prototype.hasOwnProperty.call(obj, '_sortValue');
  }

  private _criarPagamento(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(MapaPagamentosPagamentoModalComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    const componentInstance: MapaPagamentosPagamentoModalComponent = modalInstance.componentInstance;
    componentInstance.storeModePublic = this.licencaStoreModePublic;
    componentInstance.sourceResponsaveis = this.responsaveis;
    componentInstance.nEmpresa = this.session.erp.nEmpresa;
    componentInstance.nomeEmpresa = this.session.erp.nomeEmpresa;
    componentInstance.empresasList = this._empresasList
      .map((item) => {
        return {nEmpresa: item.nEmpresa, nomeEmpresa: item.nomeEmpresa};
      })
      .sort((a, b) => {
        return a.nEmpresa.localeCompare(b.nEmpresa);
      });
    return modalInstance.result.then((modalResult: IMapaPagamentoModalResult) => {
      this._renderNewSource(modalResult.results, false);
    });
  }

  private _editarPagamento(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(MapaPagamentosPagamentoModalComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    const componentInstance: MapaPagamentosPagamentoModalComponent = modalInstance.componentInstance;
    componentInstance.storeModePublic = this.licencaStoreModePublic;
    componentInstance.sourceResponsaveis = this.responsaveis;
    componentInstance.onEditPagMan = true;
    componentInstance.ano = this.filters.ano;
    componentInstance.empresasList = this._empresasList
      .map((item) => {
        return {nEmpresa: item.nEmpresa, nomeEmpresa: item.nomeEmpresa};
      })
      .sort((a, b) => {
        return a.nEmpresa.localeCompare(b.nEmpresa);
      });
    return modalInstance.result;
  }

  private _renderNewSource(source: IJsonMapaPagamLoadResults, fromLoadPagamento: boolean = true, clearNonInList: boolean = false): void {
    const newSource = this._createColumnsAndTransformSource(source);
    const currentMainSource = [...this._mainDataSource];
    currentMainSource.forEach((gridItem, index) => {
      const item = newSource.find((sourceItem) => sourceItem.nEmpresa === gridItem.nEmpresa);
      if (isDefinedNotNull(item)) {
        this._categorizeMessages(item, fromLoadPagamento);
        this._mainDataSource[index] = item;
      } else if (clearNonInList) {
        for (const prop in gridItem) {
          if (isObject(gridItem[prop]) && this._isPagamentoObject(gridItem[prop])) {
            delete gridItem[prop];
          }
        }
      }
    });
    this.dataGridDefinition.dataSource = this._mainDataSource;
  }

  private _loadResponsaveis(): void {
    this._mapaPagamentosService.getResponsaveis().then((response) => {
      this.responsaveis = response;
    });
  }

  private _emailSetCellValue(rowData: IMapaPagamDataGridRecord | IJsonMapaPagamEmpresaItem, value: string, currentRowData: IMapaPagamDataGridRecord | IJsonMapaPagamEmpresaItem): Promise<unknown> {
    if (value.length) {
      return this._mapaPagamentosService.saveEmails(currentRowData.nEmpresa, value).then(() => {
        rowData.email = value;
      });
    }
    return Promise.resolve();
  }

  private async _showConfigModal(canCancel: boolean = true): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(MapaPagamentosConfigModalComponent, {size: 'lg', backdrop: 'static', keyboard: false});
    const componentInstance: MapaPagamentosConfigModalComponent = modalInstance.componentInstance;
    componentInstance.config = this.userConfig;
    componentInstance.allowCancel = canCancel;
    const partialModel: Partial<IJsonMapaPagamentoConfig> = await modalInstance.result;
    this.userConfig.selectedEmpresas = partialModel.selectedEmpresas;
    this.userConfig.selectedPagamentos = partialModel.selectedPagamentos;
    this.userConfig.isConfigured = true;
    this._dataGridInstance.option('state', null);
    await this._pesquisar();
  }

  private readonly _fnEmailSetCellValue: TDevExpressDataGridColumnSetCellValueFn<IMapaPagamDataGridRecord | IJsonMapaPagamEmpresaItem> = (
    rowData: IMapaPagamDataGridRecord | IJsonMapaPagamEmpresaItem,
    value: string,
    currentRowData: IMapaPagamDataGridRecord | IJsonMapaPagamEmpresaItem
  ) => this._emailSetCellValue(rowData, value, currentRowData);

  private _configPagamentosColumns(): void {
    this._dataGridInstance.columnOption(PAG_ESTADO_BAND_NAME, 'visible', true);
    this._dataGridInstance.columnOption(EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IVA], 'visible', this._isColVisible(EMapaPagamImpostoEnum.IVA));
    this._dataGridInstance.columnOption(EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IRCMod22], 'visible', this._isColVisible(EMapaPagamImpostoEnum.IRCMod22));
    this._dataGridInstance.columnOption(EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IMI], 'visible', this._isColVisible(EMapaPagamImpostoEnum.IMI));
    this._dataGridInstance.columnOption(EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IUC], 'visible', this._isColVisible(EMapaPagamImpostoEnum.IUC));
    this._dataGridInstance.columnOption(EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.DMR], 'visible', this._isColVisible(EMapaPagamImpostoEnum.DMR));
    this._dataGridInstance.columnOption(EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.RetencaoNaFonte], 'visible', this._isColVisible(EMapaPagamImpostoEnum.RetencaoNaFonte));
    this._dataGridInstance.columnOption(EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.PagAntIRC], 'visible', this._isColVisible(EMapaPagamImpostoEnum.PagAntIRC));
    this._dataGridInstance.columnOption(EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.DMRSS], 'visible', this._isColVisible(EMapaPagamImpostoEnum.DMRSS));
  }

  private _isColVisible(imposto: EMapaPagamImpostoEnum): boolean {
    if (this.userConfig.selectedPagamentos.length === 0) {
      return true;
    }
    return this.userConfig.selectedPagamentos.includes(imposto);
  }

  private _onBeforeStore(state: IDevExpressDataGridState): IDevExpressDataGridState {
    if (isDefinedNotNull(state) && isArray(state.columns)) {
      state.columns = state.columns.filter((column) => {
        if (isUndefinedOrNull(column.dataField)) {
          return isUndefinedOrNull(column.name) || (isDefinedNotNull(column.name) && !column.name.includes(DYNAMIC_BAND_NAME));
        }
        return !column.dataField.includes(DYNAMIC_COL_PREFIX);
      });
    }
    return state;
  }

  private _getCaptionAno(): string {
    return this._translateService.instant('toolbar.year', {value: this.filters.ano});
  }

  private _getCaptionMes(): string {
    return this._translateService.instant('toolbar.month', {value: this._meses[this.filters.mes - 1].caption});
  }

  private _changedMes(menuItem: IPlToolbarMenuItem): void {
    if (menuItem.id !== String(this.filters.mes)) {
      if (this._selectedMes) {
        this._selectedMes.active = false;
      }
      this._selectedMes = menuItem;
      this._selectedMes.active = true;
      this.filters.mes = Number(this._selectedMes.id);
      this._mnuMes.caption = this._getCaptionMes();
    }
  }

  private _changedAno(menuItem: IPlToolbarMenuItem): void {
    if (menuItem.caption !== String(this.filters.ano)) {
      if (this._selectedAno) {
        this._selectedAno.active = false;
      }
      this._selectedAno = menuItem;
      this._selectedAno.active = true;
      this.filters.ano = Number(this._selectedAno.caption);
      this._mnuAnos.caption = this._getCaptionAno();
    }
  }

  private _buildToolbarResponsive(isMobile: boolean): void {
    this.toolbar.removeGroupId(TOOLBAR_GROUP_ID, false).removeButton(this.btnConfig);
    this.dropdownActions.menu = [this._btnCriarPagamentoManual, this._btnEditPagamentoManual];
    if (!isMobile) {
      this._btnEnviar.class = 'btn-info';
      this.btnConfig.class = 'btn-light';
      this.toolbar.addButton(this._btnEnviar).addButton(this.btnConfig);
    } else {
      this._btnEnviar.class = this.btnConfig.class = undefined;
      this.dropdownActions.menu.push(this._btnEnviar);
      this.dropdownActions.menu.push(this.btnConfig);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private _dataGridExportCustomizeCell(gridCell: IDevExpressDataGridExcelDataGridCell | IDevExpressDataGridPdfDataGridCell, cell: any, isPDF: boolean): void {
    if (gridCell.rowType === 'data') {
      let evaluateCell = false;
      switch (gridCell.column.dataField) {
        case EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.DMR]:
        case EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.DMRSS]:
        case EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IVA]:
        case EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.RetencaoNaFonte]:
        case EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IRCMod22]:
        case EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IMI]:
        case EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.IUC]:
        case EMAPAPAGAMIMPOSTOENUMCOLUMN[EMapaPagamImpostoEnum.PagAntIRC]:
          evaluateCell = true;
          break;
      }

      if (!evaluateCell && gridCell?.column?.dataField?.startsWith(DYNAMIC_COL_PREFIX)) {
        evaluateCell = true;
      }

      if (evaluateCell) {
        if (isPDF) {
          cell.text = '';
        } else {
          cell.value = '';
        }

        if (gridCell?.data?.havePagamentos && isNumber(gridCell?.data[gridCell?.column?.dataField]?.montanteTotal) && gridCell?.data[gridCell?.column?.dataField]?.montanteTotal !== 0) {
          let value = gridCell.data[gridCell.column.dataField]?.montanteTotal;
          if (gridCell.data[gridCell.column.dataField]?.pagamento?.impostoEnum === this.impostoManualEnum && gridCell.data[gridCell.column.dataField]?.montanteTotal === 0) {
            value *= -1;
          }
          value = round(value, 2);
          if (isPDF) {
            cell.text = String(value);
            cell.textColor = gridCell.data[gridCell.column.dataField]?.pagamento?.pago ? '#198754' : gridCell.data[gridCell.column.dataField]?.pagamento?.pagoUnknown ? '#333333' : '#DC3545';
          } else {
            cell.value = value;
            cell.font = {
              ...cell.font,
              color: {
                argb: gridCell.data[gridCell.column.dataField]?.pagamento?.pago ? '32198754' : gridCell.data[gridCell.column.dataField]?.pagamento?.pagoUnknown ? '32333333' : '32dc3545'
              }
            };
          }
        }
      }
    }
  }
}
