<div [ngClass]="entityClassName">
  <pl-tabs [theme]="'style3'" [activeId]="activeTabId" (activeIdChange)="changedTab($event)" [destroyOnHide]="false">
    <pl-tab [id]="tabAtivosId">
      <div *plTabTitle>
        <div class="d-flex align-items-center">
          <i class="fa fa-fw fa-check-circle me-1"></i>
          <span [translate]="'contratosservicos.tabAtivos'"></span>
          <span class="badge ms-2">{{ ativosCount }}</span>
        </div>
      </div>
    </pl-tab>
    <pl-tab [id]="tabExpiradosId">
      <div *plTabTitle>
        <div class="d-flex align-items-center">
          <i class="fa fa-fw fa-calendar-times-o me-1"></i>
          <span [translate]="'contratosservicos.tabExpirados'"></span>
          <span class="badge ms-2">{{ expiradosCount }}</span>
        </div>
      </div>
    </pl-tab>
    <pl-tab [id]="tabTerminadosId">
      <div *plTabTitle>
        <div class="d-flex align-items-center">
          <i class="fa fa-fw fa-minus-circle me-1"></i>
          <span [translate]="'contratosservicos.tabTerminados'"></span>
          <span class="badge ms-2">{{ terminadosCount }}</span>
        </div>
      </div>
    </pl-tab>
  </pl-tabs>

  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [dataGridProperties]="dataGridProperties"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [toolbarInstanceId]="instanceName"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [properties]="listOptions"
    (evtOnRefresh)="onRefreshList()"
    (searchValueChange)="onSearchValueChange($event)"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
    <div *entityListItemDetail="let item">
      <contratos-detail
        [contrato]="item.data._contrato"
        [actionEditarContrato]="fnEditAvenca"
        [actionReabrirContrato]="fnReabrirAvenca"
        [actionTerminarContrato]="fnTerminarAvenca"
        [actionApagarContrato]="fnApagarAvenca"
        (evtRefresh)="refreshList()">
      </contratos-detail>
    </div>
  </entity-list>
</div>
