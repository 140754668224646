<div [ngClass]="entityClassName">
  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [dataGridState]="dataGridState"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [beforeRequest]="fnBeforeRequest"
    [afterRequest]="fnAfterRequest"
    [toolbarInstanceId]="instanceName"
    [properties]="listOptions"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()"
    (evtDataGridColumnsPreparing)="onDataGridColumnsPreparing($event)"
    (evtDataGridRowPrepared)="onDataGridRowPrepared($event)">
    <div *entityListItemDetail="let item">
      <recibo [recibo]="item.data._recibo" (evtRefreshList)="refreshList()"></recibo>
    </div>
  </entity-list>
  <br />

  <div class="bancos-extrato-module-legend">
    <cg-table-legend [legend]="tableLegend"></cg-table-legend>
  </div>
</div>
