import {asyncScheduler, fromEvent, lastValueFrom, Subscription, timer} from 'rxjs';
import {Component, ElementRef, Inject, OnDestroy, ViewChild} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {Message, MessageBodyIntent} from '@centralgest/amalia-api-angular';
import {PlDocumentService} from 'pl-comps-angular';
import {AmaliaWindowService} from '../../../services/amalia/window/amalia.window.service';
import {IAmaliaAgentContext} from '../../../services/amalia/window/amalia.window.service.interface';

const TIMEOUT_STOP_TRANSCRIPT = 2000;

@Component({
  selector: 'amalia-window',
  templateUrl: './amalia.window.component.html'
})
export class AmaliaWindowComponent implements OnDestroy {
  public readonly amaliaMessageFrom: typeof Message.FromEnum;
  public readonly showPremadeActions: boolean;
  public readonly premadeActions: Array<string>;
  public agentContext: IAmaliaAgentContext;
  public messages: Array<Message>;
  public textMessage: string;
  public windowWidth: number;
  public isMobile: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public recognition: any;
  public recognizing: boolean;
  public loading: boolean;
  public addingMessage: boolean;
  public windowViewCompact: boolean;

  private readonly _document: Document;
  private readonly _subscriptionAgentContext: Subscription;
  private readonly _subscriptionMessages: Subscription;
  private readonly _subscriptionWindowWidth: Subscription;
  private readonly _subscriptionIsMobile: Subscription;
  private _subscriptionsRecognition: Array<Subscription>;
  private _subscriptionStopTranscript: Subscription;
  private _subscriptionResizerMouseMove: Subscription;
  private _subscriptionResizerMouseUp: Subscription;
  private _ignoreTranscriptOnEnd: boolean;
  private _elementChat: HTMLElement;
  private _scrollChatToBottomPending: boolean;
  private _inputElementTextMessage: HTMLInputElement;

  constructor(
    @Inject(DOCUMENT) document: unknown,
    private readonly _translateService: TranslateService,
    private readonly _plDocumentService: PlDocumentService,
    private readonly _amaliaWindowService: AmaliaWindowService
  ) {
    this.amaliaMessageFrom = Message.FromEnum;
    this.showPremadeActions = false;
    this.premadeActions = ['balanceteMesAnterior', 'possoUsarTelemovel', 'currentTime', 'configurarProRata', 'comoAtribuirPaisCliente'].map((action: string) =>
      this._translateService.instant(`amalia.actions.premade.${action}`)
    );
    this.isMobile = false;
    this.loading = true;
    this.addingMessage = false;
    this.windowViewCompact = false;
    this.messages = [];
    this._scrollChatToBottomPending = false;

    this._document = <Document>document;

    this._onResizerMouseMove = this._onResizerMouseMove.bind(this);
    this._onResizerMouseUp = this._onResizerMouseUp.bind(this);

    this._subscriptionAgentContext = this._amaliaWindowService.agentContext().subscribe((agentContext: IAmaliaAgentContext) => {
      this.agentContext = agentContext;
    });

    this._subscriptionMessages = this._amaliaWindowService.messages().subscribe((messages: Array<Message>) => {
      this.loading = false;
      this.messages = messages;
      if (this._elementChat) {
        this._scrollChatToBottom();
      } else {
        this._scrollChatToBottomPending = true;
      }
    });

    this._subscriptionWindowWidth = this._amaliaWindowService.windowWidth().subscribe((windowWidth: number) => {
      this.windowWidth = windowWidth;
    });

    this._subscriptionIsMobile = this._plDocumentService.isMobile().subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
    });

    this._setupTranscript();
  }

  public ngOnDestroy(): void {
    this._subscriptionAgentContext.unsubscribe();
    this._subscriptionMessages.unsubscribe();
    this._subscriptionWindowWidth.unsubscribe();
    this._subscriptionIsMobile.unsubscribe();
    if (this._subscriptionStopTranscript) {
      this._subscriptionStopTranscript.unsubscribe();
    }
    if (this._subscriptionsRecognition) {
      for (const subscription of this._subscriptionsRecognition) {
        subscription.unsubscribe();
      }
    }
    if (this._subscriptionResizerMouseMove) {
      this._subscriptionResizerMouseMove.unsubscribe();
    }
    if (this._subscriptionResizerMouseUp) {
      this._subscriptionResizerMouseUp.unsubscribe();
    }
  }

  public changeWindowView(): void {
    this.windowViewCompact = !this.windowViewCompact;
    // implement expand and compress window
  }

  public closeWindow(): void {
    this._amaliaWindowService.closeWindow();
  }

  public async addTextMessage(focusInputTextMessage: boolean): Promise<void> {
    const textMessage = this.textMessage;
    this.textMessage = '';
    await this.addMessage(textMessage);
    if (focusInputTextMessage && this._inputElementTextMessage) {
      this._inputElementTextMessage.focus();
    }
  }

  public async addMessage(messageText: string): Promise<void> {
    if (!messageText) {
      return;
    }
    this.addingMessage = true;
    try {
      await lastValueFrom(this._amaliaWindowService.addMessage(messageText));
    } finally {
      this.addingMessage = false;
    }
  }

  public toggleTranscript(): void {
    if (!this.recognition) {
      return;
    }
    if (this.recognizing) {
      this.recognition.stop();
      return;
    }
    this.recognizing = true;
    this.recognition.lang = 'pt-PT';
    this.recognition.start();
    this._ignoreTranscriptOnEnd = false;
  }

  public async actionIntent(intent: MessageBodyIntent): Promise<void> {
    await this._amaliaWindowService.handleIntent(intent);
  }

  public async clickedLink(text: string): Promise<void> {
    this.textMessage = text;
    await this.addTextMessage(false);
  }

  public onResizerMouseDown(event: MouseEvent): void {
    this._plDocumentService.setDragging(true);
    this._subscriptionResizerMouseMove = fromEvent(event.view.document, 'mousemove', {passive: true}).subscribe(this._onResizerMouseMove);
    this._subscriptionResizerMouseUp = fromEvent(event.view.document, 'mouseup', {passive: true}).subscribe(this._onResizerMouseUp);
  }

  @ViewChild('elementChat')
  public set elementChat(value: ElementRef<HTMLElement>) {
    this._elementChat = value?.nativeElement;
    if (this._elementChat && this._scrollChatToBottomPending) {
      this._scrollChatToBottom();
    }
  }

  @ViewChild('inputElementTextMessage')
  public set inputElementTextMessage(value: ElementRef<HTMLInputElement>) {
    this._inputElementTextMessage = value?.nativeElement;
  }

  private _setupTranscript(): void {
    const window: Window = this._document?.defaultView;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/naming-convention
    const speechRecognition = (<{SpeechRecognition: any}>(<unknown>window)).SpeechRecognition || (<{webkitSpeechRecognition: any}>(<unknown>window)).webkitSpeechRecognition;
    if (!speechRecognition) {
      return;
    }

    this.recognition = new speechRecognition();
    this.recognition.continuous = true;
    this.recognition.interimResults = false;

    this._subscriptionsRecognition = [];

    this._subscriptionsRecognition.push(
      fromEvent(this.recognition, 'error').subscribe((event: {error: string}) => {
        this._subscriptionStopTranscript.unsubscribe();
        if (event.error === 'not-allowed') {
          this._ignoreTranscriptOnEnd = true;
        }
      })
    );

    this._subscriptionsRecognition.push(
      fromEvent(this.recognition, 'end').subscribe(() => {
        this.recognizing = false;
        if (!this._ignoreTranscriptOnEnd && this.textMessage) {
          this.addTextMessage(true);
        }
      })
    );

    this._subscriptionsRecognition.push(
      fromEvent(this.recognition, 'result').subscribe((event: {results: SpeechRecognitionResultList; resultIndex: number}) => {
        if (!event.results) {
          this.recognition.onend = null;
          this.recognition.stop();
          return;
        }

        for (let i = event.resultIndex; i < event.results.length; ++i) {
          const transcript = event.results[i][0].transcript;
          if (!transcript) {
            continue;
          }
          if (event.results[i].isFinal) {
            if (!this.textMessage) {
              this.textMessage = '';
            } else if (!this.textMessage.endsWith(' ')) {
              this.textMessage += ' ';
            }
            this.textMessage += transcript;
          }
        }

        if (this._subscriptionStopTranscript) {
          this._subscriptionStopTranscript.unsubscribe();
        }
        this._subscriptionStopTranscript = timer(TIMEOUT_STOP_TRANSCRIPT).subscribe(() => {
          if (this.recognizing && this.recognition) {
            this.recognition.stop();
          }
        });
      })
    );
  }

  private _onResizerMouseMove(event: MouseEvent): void {
    const window: Window = this._document?.defaultView;
    if (!window) {
      return;
    }
    const cursorX: number = event.clientX;
    const viewportWidth: number = window.innerWidth;
    const newWidth: number = viewportWidth - cursorX;
    this._amaliaWindowService.setWindowWidth(newWidth);
  }

  private _onResizerMouseUp(): void {
    this._plDocumentService.setDragging(false);
    if (this._subscriptionResizerMouseMove) {
      this._subscriptionResizerMouseMove.unsubscribe();
    }
    if (this._subscriptionResizerMouseUp) {
      this._subscriptionResizerMouseUp.unsubscribe();
    }
  }

  private _scrollChatToBottom(): void {
    if (!this._elementChat) {
      return;
    }
    asyncScheduler.schedule(() => {
      this._elementChat.scroll({top: this._elementChat.scrollHeight, behavior: 'smooth'});
    });
  }
}
