<div class="modelo22-derrama-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo22.modal.derrama.title'"></h5>
  </div>

  <div class="modal-body">
    <label class="d-flex" [translate]="'modelo22.modal.derrama.ano'" [translateParams]="{ano: derrama.ano}"></label>
    <label class="d-flex" [translate]="'modelo22.modal.derrama.concelho'" [translateParams]="{cod: derrama.codConcelho, name: derrama.nomeConcelho}"></label>

    <pl-form class="py-2">
      <pl-group *ngIf="hasPostosTrabalhoCriados">
        <label [translate]="'modelo22.modal.derrama.postosTrabalhoCriados'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="postosTrabalhoCriados" [(model)]="derrama.postosTrabalhoCriados" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="hasVolumeNegocios">
        <label [translate]="'modelo22.modal.derrama.volumeNegocios'"></label>
        <edit>
          <pl-edit type="number" attrName="volumeNegocios" [(model)]="derrama.volumeNegocios"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="hasInvestimentoNoAno">
        <label [translate]="'modelo22.modal.derrama.investimentoNoAno'"></label>
        <edit>
          <pl-edit type="number" attrName="investimentoNoAno" [(model)]="derrama.investimentoNoAno"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="hasTemSedeNoConcelho">
        <edit>
          <pl-edit type="checkbox" attrName="temSedeNoConcelho" [(model)]="derrama.temSedeNoConcelho" [properties]="{label: 'modelo22.modal.derrama.temSedeNoConcelho'}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="hasAnoFixacaoDaSede">
        <label [translate]="'modelo22.modal.derrama.anoFixacaoDaSede'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="volumeNegocios" [(model)]="derrama.anoFixacaoDaSede" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="hasMicroPequenaMediaEmpresa">
        <pl-group>
          <edit>
            <pl-edit type="checkbox" attrName="microEmpresa" [(model)]="derrama.microEmpresa" [properties]="{label: 'modelo22.modal.derrama.microEmpresa'}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <edit>
            <pl-edit type="checkbox" attrName="pequenaEmpresa" [(model)]="derrama.pequenaEmpresa" [properties]="{label: 'modelo22.modal.derrama.pequenaEmpresa'}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <edit>
            <pl-edit type="checkbox" attrName="mediaEmpresa" [(model)]="derrama.mediaEmpresa" [properties]="{label: 'modelo22.modal.derrama.mediaEmpresa'}"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>

      <pl-group *ngIf="hasAcrescimoTrabalhadores">
        <label [translate]="'modelo22.modal.derrama.acrescimoTrabalhadores'"></label>
        <edit>
          <pl-edit type="number" attrName="acrescimoTrabalhadores" [(model)]="derrama.acrescimoTrabalhadores" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="hasPostosTrabalhoCriados2019">
        <label [translate]="'modelo22.modal.derrama.postosTrabalhoCriados2019'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="postosTrabalhoCriados2019" [(model)]="derrama.postosTrabalhoCriados2019" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="hasPostosTrabalhoCriados2020">
        <label [translate]="'modelo22.modal.derrama.postosTrabalhoCriados2020'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="postosTrabalhoCriados2020" [(model)]="derrama.postosTrabalhoCriados2020" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="hasPostosTrabalhoCriados2021">
        <label [translate]="'modelo22.modal.derrama.postosTrabalhoCriados2021'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="postosTrabalhoCriados2021" [(model)]="derrama.postosTrabalhoCriados2021" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="hasPostosTrabalhoCriados2022">
        <label [translate]="'modelo22.modal.derrama.postosTrabalhoCriados2022'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="postosTrabalhoCriados2022" [(model)]="derrama.postosTrabalhoCriados2022" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="hasPostosTrabalhoCriados2023">
        <label [translate]="'modelo22.modal.derrama.postosTrabalhoCriados2023'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="postosTrabalhoCriados2023" [(model)]="derrama.postosTrabalhoCriados2023" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="hasPostosTrabalhoCriados2024">
        <label [translate]="'modelo22.modal.derrama.postosTrabalhoCriados2024'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="postosTrabalhoCriados2024" [(model)]="derrama.postosTrabalhoCriados2024" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group *ngIf="isManualSelection">
        <dx-data-grid
          cgDxDataGridInstanceName="modelo22DerramaModalDatagrid"
          [cgDxDataGrid]="definitionCondicoes"
          [dataSource]="dadosDerrama.condicoesTaxaList"
          [(selectedRowKeys)]="selectedRowKeys"
          (onCellClick)="onCellClick($event)">
        </dx-data-grid>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
