<div class="importador-saft-direct-config-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <div class="modal-header-cab">
      <h5 class="modal-title" [translate]="'importadorSaftDirect.config.title'"></h5>
    </div>
  </div>

  <div class="modal-body">
    <pl-tabs>
      <pl-tab caption="importadorSaftDirect.tabs.mercado">
        <div *plTabContent>
          <p class="mb-2" [translate]="'importadorSaftDirect.config.helpText'"></p>
          <pl-form>
            <pl-group>
              <label [translate]="'importadorSaftDirect.config.valorMercadoNacionalLabel'"></label>
              <edit>
                <pl-edit type="integer" [(model)]="configMercados.valorMercadoNacional" [properties]="{validators: {maxlength: {value: 1}}}"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'importadorSaftDirect.config.valorOutrosMercadosLabel'"></label>
              <edit>
                <pl-edit type="integer" [(model)]="configMercados.valorMercadoComunitario" [properties]="{validators: {maxlength: {value: 1}}}"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'importadorSaftDirect.config.valorMercadoComunitarioLabel'"></label>
              <edit>
                <pl-edit type="integer" [(model)]="configMercados.valorOutrosMercados" [properties]="{validators: {maxlength: {value: 1}}}"></pl-edit>
              </edit>
            </pl-group>
          </pl-form>
        </div>
      </pl-tab>
      <pl-tab caption="importadorSaftDirect.tabs.analitica">
        <div *plTabContent>
          <pl-form>
            <pl-group>
              <edit>
                <pl-edit class="ps-1" type="boolean" [(model)]="configMascara.usaAnalitica" [properties]="{label: 'importadorSaftDirect.steps.cfgTiposDocs.usaAnalitica'}"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <div class="ps-1 config-mascara-analitica-tipos-radio-group">
                <pl-group>
                  <label [translate]="'importadorSaftDirect.configMascaraAnalitica.comoPretendeAtribuirAnalitica'"></label>
                  <edit>
                    <pl-edit type="radiogroup" [(model)]="configMascara.tipoAnaliticaIS" [properties]="{groupItems: tipoAnaliticaISItems, disabled: !configMascara.usaAnalitica}"></pl-edit>
                  </edit>
                </pl-group>
              </div>
            </pl-group>
            <pl-group>
              <label [translate]="'importadorSaftDirect.steps.cfgTiposDocs.mascaraLabel'"></label>
              <edit>
                <entity-autocomplete
                  entity="mascarasanalitica"
                  [(model)]="configMascara._mascaraObj"
                  [output]="'mascara'"
                  [outputKey]="'mascara'"
                  [properties]="{validators: {maxlength: {value: 100}, required: {value: true}}, disabled: !isEnabledMascaraAC()}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-form>
        </div>
      </pl-tab>
    </pl-tabs>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button" plPromise>
    <pl-button klass="btn-primary" [onClick]="close"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
    <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-ban"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
