import {JSONSchema} from '@ngx-pwa/local-storage';
import {deepFreeze} from 'pl-comps-angular';
import {IConfigOptionsInstance} from './config.options.instance.interface';
import {SCHEMA_BOOLEAN} from '../../../../common/schemas';

export enum EConfigOptionsInstanceName {
  DOCS_COMERCIAIS_EDIT = 'docsComerciaisEdit',
  DOC_CONTABILIDADE = 'docContabilidade',
  IMPORTADOR_EFATURA = 'importadorEfatura',
  CONCILIACAO_BANCARIA = 'conciliacaoBancaria',
  DOCS_COMERCIAIS_ESTATISTICAS = 'docsComerciaisEstatisticas',
  DOCS_COMERCIAIS_ESTATISTICAS_DOC = 'docsComerciaisEstatisticasDoc',
  CONTAB_DIGITAL_DOCUMENTOS_DIGITAIS = 'contabDigitalDocumentosDigitais',
  MOVIMENTOS_PENDENTES = 'movimentosPendentes',
  FAMILIAS = 'familias',
  ARTIGOS = 'artigos',
  ARTIGOS_LIST = 'artigosList',
  EXTRATOSDT = 'extratosDT',
  RETENCOES = 'retencoes',
  BANCOS_EXTRATO = 'bancosExtrato',
  FATURACAO_CONTRATOS = 'faturacaoContratos',
  CONTRATOS = 'contratos',
  CONTRATOS_LIST = 'contratosList',
  CONTRATOS_PRINT = 'contratosPrint',
  FATURACAO_ELETRONICA_CONFIGURACOES = 'faturacaoEletronicaConfiguracoes',
  FICHA_CLIENTES = 'fichaClientes',
  ADMIN_PORTAIS = 'adminPortais',
  AVISOSCOBRANCA = 'avisosCobranca',
  INVENTARIOS = 'inventarios',
  CLIENTES_LIST = 'clientesList',
  FORNECEDORES_LIST = 'fornecedoresList',
  LOTES_LIST = 'lotesList',
  ENCOMENDAS = 'encomendas',
  EXTRATOS_CONTABILIDADE = 'extratosDeContabilidade',
  MOVIMENTOS_ABERTOS = 'movimentosAbertos',
  IDADE_SALDOS = 'idadeSaldos',
  DOCS_COMERCIAIS_LIST = 'docsComerciaisList',
  MOVIMENTACOES = 'movimentacoes'
}

export type TConfigOptions<T, S extends IConfigOptions<T> = IConfigOptions<T>> = ReadonlyMap<keyof S, IConfigOption<T>>;

export interface IConfigOptions<T> {
  readonly [key: string]: IConfigOption<T>;
}

export interface IConfigOption<T> {
  readonly caption: string;
  readonly value: T;
  readonly dirty?: boolean;
  readonly invisible?: boolean;
  readonly persist?: boolean;
}

export interface IConfigOptionBoolean extends IConfigOption<boolean> {}

export interface IConfigOptionsGroup<T, S extends IConfigOptions<T> = IConfigOptions<T>> {
  readonly [instanceName: string]: IConfigOptionsInstance<T, S>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IConfigOptionsGroupErp extends IConfigOptionsGroup<boolean, any> {
  readonly docsComerciaisEdit: IConfigOptionsInstance<boolean, IDocsComerciaisEditConfigOptions>;
  readonly docsComerciaisEstatisticas: IConfigOptionsInstance<boolean, IDocsComerciaisEstatisticasConfigOptions>;
  readonly docsComerciaisEstatisticasDoc: IConfigOptionsInstance<boolean, IDocsComerciaisEstatisticasDocConfigOptions>;
  readonly movimentosPendentes: IConfigOptionsInstance<boolean, IMovimentosPendentesConfigOptions>;
  readonly familias: IConfigOptionsInstance<boolean, IFamiliasConfigOptions>;
  readonly artigos: IConfigOptionsInstance<boolean, IArtigosConfigOptions>;
  readonly faturacaoContratos: IConfigOptionsInstance<boolean, IFaturacaoContratosConfigOptions>;
  readonly contratos: IConfigOptionsInstance<boolean, IContratosConfigOptions>;
  readonly contratosPrint: IConfigOptionsInstance<boolean, IContratosPrintConfigOptions>;
  readonly contratosList: IConfigOptionsInstance<boolean, IContratosListConfigOptions>;
  readonly faturacaoEletronicaConfiguracoes: IConfigOptionsInstance<boolean, IFaturacaoEletronicaConfiguracoesConfigOptions>;
  readonly fichaclientes: IConfigOptionsInstance<boolean, IFichaClientesConfigOptions>;
  readonly clientesList: IConfigOptionsInstance<boolean, IClientesListConfigOptions>;
  readonly fonecedoresList: IConfigOptionsInstance<boolean, IFornecedoresListConfigOptions>;
  readonly avisosCobranca: IConfigOptionsInstance<boolean, IAvisosCobrancaConfigOptions>;
  readonly encomendas: IConfigOptionsInstance<boolean, IEncomendasConfigOptions>;
  readonly movimentosAbertos: IConfigOptionsInstance<boolean, IMovimentosAbertosConfigOptions>;
  readonly idadeSaldos: IConfigOptionsInstance<boolean, IIdadeSaldosConfigOptions>;
  readonly docsComerciaisList: IConfigOptionsInstance<boolean, IDocsComerciaisListConfigOptions>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IConfigOptionsGroupPca extends IConfigOptionsGroup<boolean, any> {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IConfigOptionsGroupColaborador extends IConfigOptionsGroup<boolean, any> {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IConfigOptionsGroupGestorEmentas extends IConfigOptionsGroup<boolean, any> {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IConfigOptionsGroupGestorRh extends IConfigOptionsGroup<boolean, any> {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IConfigOptionsGroupGestorServico extends IConfigOptionsGroup<boolean, any> {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IConfigOptionsGroupContabilidade extends IConfigOptionsGroup<boolean, any> {
  readonly docContabilidade: IConfigOptionsInstance<boolean, IDocContabilidadeConfigOptions>;
  readonly conciliacaoBancaria: IConfigOptionsInstance<boolean, IConciliacaoBancariaConfigOptions>;
  readonly contabDigitalDocumentosDigitais: IConfigOptionsInstance<boolean, IContabDigitalDocumentosDigitaisConfigOptions>;
  readonly extratosDT: IConfigOptionsInstance<boolean, IExtratosDTConfigOptions>;
  readonly retencoes: IConfigOptionsInstance<boolean, IRetencoesConfigOptions>;
  readonly bancosExtrato: IConfigOptionsInstance<boolean, IBancosExtratoConfigOptions>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IConfigOptionsGroupAtivos extends IConfigOptionsGroup<boolean, any> {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IConfigOptionsGroupAdministracao extends IConfigOptionsGroup<boolean, any> {}

export interface IDocsComerciaisEditConfigOptions extends IConfigOptions<boolean> {
  readonly showObservacoes: IConfigOptionBoolean;
  readonly showDocExterno: IConfigOptionBoolean;
  readonly showDataDoc: IConfigOptionBoolean;
  readonly showDataVenc: IConfigOptionBoolean;
  readonly showDiferimentos: IConfigOptionBoolean;
  readonly showCCusto: IConfigOptionBoolean;
  readonly showMoeda: IConfigOptionBoolean;
  readonly showNRefProcesso: IConfigOptionBoolean;
  readonly showNArmazem: IConfigOptionBoolean;
  readonly showNVendedor: IConfigOptionBoolean;
  readonly showValorCIva: IConfigOptionBoolean;
  readonly showCodUnidMov: IConfigOptionBoolean;
  readonly showNNumer: IConfigOptionBoolean;
  readonly showNRequisicao: IConfigOptionBoolean;
  readonly showLiquido: IConfigOptionBoolean;
  readonly showD2: IConfigOptionBoolean;
  readonly showD3: IConfigOptionBoolean;
  readonly showDescEmValor: IConfigOptionBoolean;
  readonly showPerguntaSeFinalizaDoc: IConfigOptionBoolean;
  readonly obtemDadosDocDigital: IConfigOptionBoolean;
  readonly showPrestacoes: IConfigOptionBoolean;
}

export interface IDocsComerciaisEstatisticasDocConfigOptions extends IConfigOptions<boolean> {
  readonly showFilterConta: IConfigOptionBoolean;
  readonly showFilterVendedor: IConfigOptionBoolean;
  readonly showFilterZona: IConfigOptionBoolean;
  readonly showFilterCentroCusto: IConfigOptionBoolean;
  readonly showFilterProcesso: IConfigOptionBoolean;
  readonly showFilterDocfaList: IConfigOptionBoolean;
  readonly showFilterNArtigo: IConfigOptionBoolean;
  readonly showFilterTipoArtigo: IConfigOptionBoolean;
  readonly showFilterGrandeFamilia: IConfigOptionBoolean;
  readonly showFilterFamilia: IConfigOptionBoolean;
  readonly showFilterSubFamilia: IConfigOptionBoolean;
  readonly showFilterGrupoConta: IConfigOptionBoolean;
  readonly showFilterClasse: IConfigOptionBoolean;
}

export interface IDocsComerciaisEstatisticasConfigOptions extends IDocsComerciaisEstatisticasDocConfigOptions {
  readonly showFilterTipoArtigo: IConfigOptionBoolean;
  readonly showFilterGrFamilia: IConfigOptionBoolean;
  readonly showFilterFamilia: IConfigOptionBoolean;
  readonly showFilterSubFamilia: IConfigOptionBoolean;
  readonly showFilterClasseArtigo: IConfigOptionBoolean;
  readonly showFilterCategoriaArtigo: IConfigOptionBoolean;
  readonly showFilterSoComMovimStock: IConfigOptionBoolean;
  readonly showFilterGrupoConta: IConfigOptionBoolean;
  readonly showFilterClasse: IConfigOptionBoolean;
}

export interface IFichaClientesConfigOptions extends IConfigOptions<boolean> {
  readonly showZona: IConfigOptionBoolean;
  readonly showVendedor: IConfigOptionBoolean;
  readonly showSector: IConfigOptionBoolean;
}

export interface IClientesListConfigOptions extends IConfigOptions<boolean> {
  readonly showDesativados: IConfigOptionBoolean;
}

export interface IFornecedoresListConfigOptions extends IConfigOptions<boolean> {
  readonly showDesativados: IConfigOptionBoolean;
}

export interface IExtratosDTConfigOptions extends IConfigOptions<boolean> {
  readonly showMoeda: IConfigOptionBoolean;
  readonly showDebitoEmDivisa: IConfigOptionBoolean;
  readonly showCreditoEmDivisa: IConfigOptionBoolean;
  readonly showPorPagarEmDivisa: IConfigOptionBoolean;
  readonly showCCusto: IConfigOptionBoolean;
  readonly showCambioSegMoeda: IConfigOptionBoolean;
  readonly showDebitoSegMoeda: IConfigOptionBoolean;
  readonly showCreditoSegMoeda: IConfigOptionBoolean;
  readonly showPorPagar: IConfigOptionBoolean;
  readonly showDataVencimento: IConfigOptionBoolean;
}

export interface IMovimentosPendentesConfigOptions extends IConfigOptions<boolean> {
  readonly showNDocExterno: IConfigOptionBoolean;
}

export interface IDocContabilidadeConfigOptions extends IConfigOptions<boolean> {
  readonly valorComIvaIncluido: IConfigOptionBoolean;
  readonly obtemDadosDocDigital: IConfigOptionBoolean;
  readonly showFieldPredefinido: IConfigOptionBoolean;
  readonly showFieldDataVencimento: IConfigOptionBoolean;
  readonly showFieldMoeda: IConfigOptionBoolean;
  readonly showFieldLinhaNif: IConfigOptionBoolean;
  readonly showFieldLinhaValorTaxa: IConfigOptionBoolean;
  readonly showFieldLinhaDataDoc: IConfigOptionBoolean;
  readonly showFieldLinhaDescricao: IConfigOptionBoolean;
  readonly showFieldLinhaCDecPerAnual: IConfigOptionBoolean;
  readonly showFieldLinhaNomeConta: IConfigOptionBoolean;
  readonly seletorObtemDadosDocDigital: IConfigOptionBoolean;
  readonly seletorValorComIvaIncluido: IConfigOptionBoolean;
  readonly skipDescricao: IConfigOptionBoolean;
  readonly retainDescription: IConfigOptionBoolean;
  readonly showFormMoeda: IConfigOptionBoolean;
  readonly skipPromptSave: IConfigOptionBoolean;
  readonly docViewerFooterCollapsed: IConfigOptionBoolean;
}

export interface IConciliacaoBancariaConfigOptions extends IConfigOptions<boolean> {
  showColumnDescricao: IConfigOptionBoolean;
  showColumnNDocExt: IConfigOptionBoolean;
  showColumnTipo: IConfigOptionBoolean;
  showColumnSaldo: IConfigOptionBoolean;
  showColumnStampUpdate: IConfigOptionBoolean;
  showColumnDataLanc: IConfigOptionBoolean;
  showColumnData: IConfigOptionBoolean;
  showColumnValorSemDC: IConfigOptionBoolean;
  showColumnBancoValorSemDC: IConfigOptionBoolean;
  showColumnDC: IConfigOptionBoolean;
  showColumnBancoDC: IConfigOptionBoolean;
}

export interface IContabDigitalDocumentosDigitaisConfigOptions extends IConfigOptions<boolean> {
  readonly docViewerFooterCollapsed: IConfigOptionBoolean;
}

export interface IRetencoesConfigOptions extends IConfigOptions<boolean> {
  readonly showColumnIsContaPocOrigemFornecedor: IConfigOptionBoolean;
  readonly showColumnNContribuinte: IConfigOptionBoolean;
  readonly showColumnConta: IConfigOptionBoolean;
  readonly showColumnCodRetencao: IConfigOptionBoolean;
  readonly showColumnTaxaRetUsada: IConfigOptionBoolean;
  readonly showColumnTipoRendimento: IConfigOptionBoolean;
  readonly showColumnPeriodo: IConfigOptionBoolean;
  readonly showColumnNDiario: IConfigOptionBoolean;
  readonly showColumnNDocInterno: IConfigOptionBoolean;
  readonly showColumnDescricaoDC: IConfigOptionBoolean;
  readonly showColumnDataDoc: IConfigOptionBoolean;
  readonly showColumnNDocExterno: IConfigOptionBoolean;
  readonly showColumnValorOrigem: IConfigOptionBoolean;
  readonly showColumnValorDestino: IConfigOptionBoolean;
  readonly showColumnValorDestDispo: IConfigOptionBoolean;
}

export interface IBancosExtratoConfigOptions extends IConfigOptions<boolean> {
  readonly autoSuggestDoc: IConfigOptionBoolean;
  readonly lancarDocsEmSerie: IConfigOptionBoolean;
  readonly docViewerFooterCollapsed: IConfigOptionBoolean;
}

export interface IAvisosCobrancaConfigOptions extends IConfigOptions<boolean> {
  readonly showVendedor: IConfigOptionBoolean;
  readonly showCondPagamento: IConfigOptionBoolean;
}

export interface IEncomendasConfigOptions extends IConfigOptions<boolean> {
  readonly showArtigos: IConfigOptionBoolean;
  readonly showArmazens: IConfigOptionBoolean;
  readonly showDocFas: IConfigOptionBoolean;
  readonly showTextoLivre: IConfigOptionBoolean;
}

export interface IFamiliasConfigOptions extends IConfigOptions<boolean> {
  readonly showContasVendaCompra: IConfigOptionBoolean;
}

export interface IArtigosConfigOptions extends IConfigOptions<boolean> {
  readonly showPrecoBaseCusto: IConfigOptionBoolean;
  readonly showCategoria: IConfigOptionBoolean;
  readonly showPropriedade: IConfigOptionBoolean;
}

export interface IArtigosListConfigOptions extends IConfigOptions<boolean> {
  readonly showApenasBloqueado: IConfigOptionBoolean;
  readonly showApenasDesativado: IConfigOptionBoolean;
}

export interface IFaturacaoContratosConfigOptions extends IConfigOptions<boolean> {
  readonly showDataInicTransp: IConfigOptionBoolean;
  readonly showHoraInicTransp: IConfigOptionBoolean;
  readonly showZonaDeAte: IConfigOptionBoolean;
  readonly showErrosContratos: IConfigOptionBoolean;
  readonly showContasDeAte: IConfigOptionBoolean;
  readonly showDataVenc: IConfigOptionBoolean;
}

export interface IContratosConfigOptions extends IConfigOptions<boolean> {
  readonly showGrupoConta: IConfigOptionBoolean;
  readonly showMoeda: IConfigOptionBoolean;
  readonly showCondComerciais: IConfigOptionBoolean;
  readonly showRefExterna: IConfigOptionBoolean;
  readonly showVendedor: IConfigOptionBoolean;
  readonly showNCCusto: IConfigOptionBoolean;
  readonly showD1: IConfigOptionBoolean;
  readonly showArmazem: IConfigOptionBoolean;
  readonly showUnidadeMovimento: IConfigOptionBoolean;
}

export interface IContratosListConfigOptions extends IConfigOptions<boolean> {
  readonly showNaoAtivosOnly: IConfigOptionBoolean;
}

export interface IContratosPrintConfigOptions extends IConfigOptions<boolean> {
  readonly showClasses: IConfigOptionBoolean;
}

export interface IFaturacaoEletronicaConfiguracoesConfigOptions extends IConfigOptions<boolean> {
  readonly ambientePreProducao: IConfigOptionBoolean;
}

export interface IAdminPortaisConfigOptions extends IConfigOptions<boolean> {
  readonly showModuleName: IConfigOptionBoolean;
}

export interface IComercialInventariosConfigOptions extends IConfigOptions<boolean> {
  readonly showTipoArtigo: IConfigOptionBoolean;
  readonly showGrandeFamilia: IConfigOptionBoolean;
  readonly showSubFamilia: IConfigOptionBoolean;
  readonly showClasse: IConfigOptionBoolean;
  readonly showCategoria: IConfigOptionBoolean;
  readonly showValorimetria: IConfigOptionBoolean;
}

export interface ILotesListConfigOptions extends IConfigOptions<boolean> {
  readonly showSoTerminados: IConfigOptionBoolean;
}

export interface IExtratosDeContabilidadeConfigOptions extends IConfigOptions<boolean> {
  readonly showDiario: IConfigOptionBoolean;
}

export interface IMovimentosAbertosConfigOptions extends IConfigOptions<boolean> {
  readonly showGrupoConta: IConfigOptionBoolean;
}

export interface IIdadeSaldosConfigOptions extends IConfigOptions<boolean> {
  readonly showGrupoContaIdadeSaldos: IConfigOptionBoolean;
}

export interface IDocsComerciaisListConfigOptions extends IConfigOptions<boolean> {
  readonly showObservacoes: IConfigOptionBoolean;
  readonly showDataDocExterno: IConfigOptionBoolean;
  readonly showDataDoc: IConfigOptionBoolean;
  readonly showDataVenc: IConfigOptionBoolean;
  readonly showCCusto: IConfigOptionBoolean;
  readonly showMoeda: IConfigOptionBoolean;
  readonly showNRefProcesso: IConfigOptionBoolean;
  readonly showNArmazem: IConfigOptionBoolean;
  readonly showNVendedor: IConfigOptionBoolean;
  readonly showValorCIva: IConfigOptionBoolean;
  readonly showCodUnidMov: IConfigOptionBoolean;
  readonly showNRequisicao: IConfigOptionBoolean;
  readonly showLiquido: IConfigOptionBoolean;
  readonly showD2: IConfigOptionBoolean;
  readonly showD3: IConfigOptionBoolean;
  readonly showDescEmValor: IConfigOptionBoolean;
  readonly showPrestacoes: IConfigOptionBoolean;
}

export const schemaDocsComerciaisEditConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: [
    'showObservacoes',
    'showDocExterno',
    'showDataDoc',
    'showDataVenc',
    'showDiferimentos',
    'showCCusto',
    'showMoeda',
    'showNRefProcesso',
    'showNArmazem',
    'showNVendedor',
    'showValorCIva',
    'showCodUnidMov',
    'showNNumer',
    'showNRequisicao',
    'showLiquido',
    'showD2',
    'showD3',
    'showDescEmValor',
    'showPerguntaSeFinalizaDoc',
    'obtemDadosDocDigital',
    'showPrestacoes'
  ],
  properties: {
    showObservacoes: SCHEMA_BOOLEAN,
    showDocExterno: SCHEMA_BOOLEAN,
    showDataDoc: SCHEMA_BOOLEAN,
    showDataVenc: SCHEMA_BOOLEAN,
    showDiferimentos: SCHEMA_BOOLEAN,
    showCCusto: SCHEMA_BOOLEAN,
    showMoeda: SCHEMA_BOOLEAN,
    showNRefProcesso: SCHEMA_BOOLEAN,
    showNArmazem: SCHEMA_BOOLEAN,
    showNVendedor: SCHEMA_BOOLEAN,
    showValorCIva: SCHEMA_BOOLEAN,
    showCodUnidMov: SCHEMA_BOOLEAN,
    showNNumer: SCHEMA_BOOLEAN,
    showNRequisicao: SCHEMA_BOOLEAN,
    showLiquido: SCHEMA_BOOLEAN,
    showD2: SCHEMA_BOOLEAN,
    showD3: SCHEMA_BOOLEAN,
    showDescEmValor: SCHEMA_BOOLEAN,
    showPerguntaSeFinalizaDoc: SCHEMA_BOOLEAN,
    obtemDadosDocDigital: SCHEMA_BOOLEAN,
    showPrestacoes: SCHEMA_BOOLEAN
  }
});

export const schemaDocsComerciaisEstatisticasConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: [
    'showFilterTipoArtigo',
    'showFilterGrFamilia',
    'showFilterFamilia',
    'showFilterSubFamilia',
    'showFilterClasseArtigo',
    'showFilterCategoriaArtigo',
    'showFilterSoComMovimStock',
    'showFilterConta',
    'showFilterVendedor',
    'showFilterZona',
    'showFilterCentroCusto',
    'showFilterProcesso',
    'showFilterDocfaList',
    'showFilterGrupoConta',
    'showFilterClasse'
  ],
  properties: {
    showFilterTipoArtigo: SCHEMA_BOOLEAN,
    showFilterGrFamilia: SCHEMA_BOOLEAN,
    showFilterFamilia: SCHEMA_BOOLEAN,
    showFilterSubFamilia: SCHEMA_BOOLEAN,
    showFilterClasseArtigo: SCHEMA_BOOLEAN,
    showFilterCategoriaArtigo: SCHEMA_BOOLEAN,
    showFilterSoComMovimStock: SCHEMA_BOOLEAN,
    showFilterContaVendedor: SCHEMA_BOOLEAN,
    showFilterVendedor: SCHEMA_BOOLEAN,
    showFilterZona: SCHEMA_BOOLEAN,
    showFilterCentroCusto: SCHEMA_BOOLEAN,
    showFilterProcesso: SCHEMA_BOOLEAN,
    showFilterDocfaList: SCHEMA_BOOLEAN,
    showFilterGrupoConta: SCHEMA_BOOLEAN,
    showFilterClasse: SCHEMA_BOOLEAN
  }
});

export const schemaDocsComerciaisEstatisticasDocConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: [
    'showFilterConta',
    'showFilterVendedor',
    'showFilterZona',
    'showFilterCentroCusto',
    'showFilterProcesso',
    'showFilterDocfaList',
    'showFilterNArtigo',
    'showFilterTipoArtigo',
    'showFilterGrandeFamilia',
    'showFilterFamilia',
    'showFilterSubFamilia',
    'showFilterGrupoConta',
    'showFilterClasse'
  ],
  properties: {
    showFilterConta: SCHEMA_BOOLEAN,
    showFilterVendedor: SCHEMA_BOOLEAN,
    showFilterZona: SCHEMA_BOOLEAN,
    showFilterCentroCusto: SCHEMA_BOOLEAN,
    showFilterProcesso: SCHEMA_BOOLEAN,
    showFilterDocfaList: SCHEMA_BOOLEAN,
    showFilterNArtigo: SCHEMA_BOOLEAN,
    showFilterTipoArtigo: SCHEMA_BOOLEAN,
    showFilterGrandeFamilia: SCHEMA_BOOLEAN,
    showFilterFamilia: SCHEMA_BOOLEAN,
    showFilterSubFamilia: SCHEMA_BOOLEAN,
    showFilterGrupoConta: SCHEMA_BOOLEAN,
    showFilterClasse: SCHEMA_BOOLEAN
  }
});

export const schemaMovimentosPendentesEditConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showNDocExterno'],
  properties: {
    showNDocExterno: SCHEMA_BOOLEAN
  }
});

export const schemaDocContabilidadeConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  properties: {
    valorComIvaIncluido: SCHEMA_BOOLEAN,
    obtemDadosDocDigital: SCHEMA_BOOLEAN,
    showFieldPredefinido: SCHEMA_BOOLEAN,
    showFieldDataVencimento: SCHEMA_BOOLEAN,
    showFieldMoeda: SCHEMA_BOOLEAN,
    showFieldLinhaNif: SCHEMA_BOOLEAN,
    showFieldLinhaValorTaxa: SCHEMA_BOOLEAN,
    showFieldLinhaDataDoc: SCHEMA_BOOLEAN,
    showFieldLinhaDescricao: SCHEMA_BOOLEAN,
    showFieldLinhaCDecPerAnual: SCHEMA_BOOLEAN,
    showFieldLinhaNomeConta: SCHEMA_BOOLEAN,
    seletorObtemDadosDocDigital: SCHEMA_BOOLEAN,
    seletorValorComIvaIncluido: SCHEMA_BOOLEAN,
    skipDescricao: SCHEMA_BOOLEAN,
    retainDescription: SCHEMA_BOOLEAN,
    showFormMoeda: SCHEMA_BOOLEAN,
    skipPromptSave: SCHEMA_BOOLEAN,
    docViewerFooterCollapsed: SCHEMA_BOOLEAN
  }
});

export const schemaConciliacaoBancariaConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: [
    'showColumnDescricao',
    'showColumnNDocExt',
    'showColumnTipo',
    'showColumnSaldo',
    'showColumnStampUpdate',
    'showColumnDataLanc',
    'showColumnData',
    'showColumnValorSemDC',
    'showColumnBancoValorSemDC',
    'showColumnDC',
    'showColumnBancoDC'
  ],
  properties: {
    showColumnDescricao: SCHEMA_BOOLEAN,
    showColumnNDocExt: SCHEMA_BOOLEAN,
    showColumnTipo: SCHEMA_BOOLEAN,
    showColumnSaldo: SCHEMA_BOOLEAN,
    showColumnStampUpdate: SCHEMA_BOOLEAN,
    showColumnDataLanc: SCHEMA_BOOLEAN,
    showColumnData: SCHEMA_BOOLEAN,
    showColumnValorSemDC: SCHEMA_BOOLEAN,
    showColumnBancoValorSemDC: SCHEMA_BOOLEAN,
    showColumnDC: SCHEMA_BOOLEAN,
    showColumnBancoDC: SCHEMA_BOOLEAN
  }
});

export const schemaExtratosDTConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: [
    'showMoeda',
    'showDebitoEmDivisa',
    'showCreditoEmDivisa',
    'showPorPagarEmDivisa',
    'showCCusto',
    'showCambioSegMoeda',
    'showDebitoSegMoeda',
    'showCreditoSegMoeda',
    'showPorPagar',
    'showDataVencimento'
  ],
  properties: {
    showMoeda: SCHEMA_BOOLEAN,
    showDebitoEmDivisa: SCHEMA_BOOLEAN,
    showCreditoEmDivisa: SCHEMA_BOOLEAN,
    showPorPagarEmDivisa: SCHEMA_BOOLEAN,
    showCCusto: SCHEMA_BOOLEAN,
    showCambioSegMoeda: SCHEMA_BOOLEAN,
    showDebitoSegMoeda: SCHEMA_BOOLEAN,
    showCreditoSegMoeda: SCHEMA_BOOLEAN,
    showPorPagar: SCHEMA_BOOLEAN,
    showDataVencimento: SCHEMA_BOOLEAN
  }
});

export const schemaContabDigitalDocumentosDigitaisConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  properties: {
    docViewerFooterCollapsed: SCHEMA_BOOLEAN
  }
});

export const schemaRetencoesConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: [
    'showColumnIsContaPocOrigemFornecedor',
    'showColumnNContribuinte',
    'showColumnConta',
    'showColumnCodRetencao',
    'showColumnTaxaRetUsada',
    'showColumnTipoRendimento',
    'showColumnPeriodo',
    'showColumnNDiario',
    'showColumnNDocInterno',
    'showColumnDescricaoDC',
    'showColumnDataDoc',
    'showColumnNDocExterno',
    'showColumnValorOrigem',
    'showColumnValorDestino',
    'showColumnValorDestDispo'
  ],
  properties: {
    showColumnIsContaPocOrigemFornecedor: SCHEMA_BOOLEAN,
    showColumnNContribuinte: SCHEMA_BOOLEAN,
    showColumnConta: SCHEMA_BOOLEAN,
    showColumnCodRetencao: SCHEMA_BOOLEAN,
    showColumnTaxaRetUsada: SCHEMA_BOOLEAN,
    showColumnTipoRendimento: SCHEMA_BOOLEAN,
    showColumnPeriodo: SCHEMA_BOOLEAN,
    showColumnNDiario: SCHEMA_BOOLEAN,
    showColumnNDocInterno: SCHEMA_BOOLEAN,
    showColumnDescricaoDC: SCHEMA_BOOLEAN,
    showColumnDataDoc: SCHEMA_BOOLEAN,
    showColumnNDocExterno: SCHEMA_BOOLEAN,
    showColumnValorOrigem: SCHEMA_BOOLEAN,
    showColumnValorDestino: SCHEMA_BOOLEAN,
    showColumnValorDestDispo: SCHEMA_BOOLEAN
  }
});

export const schemaBancosExtratoConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  properties: {
    autoSuggestDoc: SCHEMA_BOOLEAN,
    lancarDocsEmSerie: SCHEMA_BOOLEAN,
    docViewerFooterCollapsed: SCHEMA_BOOLEAN
  }
});

export const schemaFamiliasConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showContasVendaCompra'],
  properties: {
    showContasVendaCompra: SCHEMA_BOOLEAN
  }
});

export const schemaArtigosConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showPrecoBaseCusto', 'showCategoria', 'showPropriedade'],
  properties: {
    showPrecoBaseCusto: SCHEMA_BOOLEAN,
    showCategoria: SCHEMA_BOOLEAN,
    showPropriedade: SCHEMA_BOOLEAN
  }
});

export const schemaArtigosListConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showApenasBloqueado', 'showApenasDesativado'],
  properties: {
    showApenasBloqueado: SCHEMA_BOOLEAN,
    showApenasDesativado: SCHEMA_BOOLEAN
  }
});

export const schemaFichaClientesConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showZona', 'showVendedor', 'showSector'],
  properties: {
    showZona: SCHEMA_BOOLEAN,
    showVendedor: SCHEMA_BOOLEAN,
    showSector: SCHEMA_BOOLEAN
  }
});

export const schemaClientesListConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showDesativados'],
  properties: {
    showDesativados: SCHEMA_BOOLEAN
  }
});

export const schemaFornecedoresListConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showDesativados'],
  properties: {
    showDesativados: SCHEMA_BOOLEAN
  }
});

export const schemaFaturacaoContratosConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showDataInicTransp', 'showHoraInicTransp', 'showZonaDeAte', 'showErrosContratos', 'showContasDeAte', 'showDataVenc'],
  properties: {
    showDataInicTransp: SCHEMA_BOOLEAN,
    showHoraInicTransp: SCHEMA_BOOLEAN,
    showZonaDeAte: SCHEMA_BOOLEAN,
    showErrosContratos: SCHEMA_BOOLEAN,
    showContasDeAte: SCHEMA_BOOLEAN,
    showDataVenc: SCHEMA_BOOLEAN
  }
});

export const schemaContratosConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showGrupoConta', 'showMoeda', 'showCondComerciais', 'showRefExterna', 'showVendedor', 'showNCCusto', 'showD1', 'showArmazem', 'showUnidadeMovimento'],
  properties: {
    showGrupoConta: SCHEMA_BOOLEAN,
    showMoeda: SCHEMA_BOOLEAN,
    showCondComerciais: SCHEMA_BOOLEAN,
    showRefExterna: SCHEMA_BOOLEAN,
    showVendedor: SCHEMA_BOOLEAN,
    showNCCusto: SCHEMA_BOOLEAN,
    showD1: SCHEMA_BOOLEAN,
    showArmazem: SCHEMA_BOOLEAN,
    showUnidadeMovimento: SCHEMA_BOOLEAN
  }
});

export const schemaAvisosCobrancaConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showVendedor', 'showCondPagamento'],
  properties: {
    showVendedor: SCHEMA_BOOLEAN,
    showCondPagamento: SCHEMA_BOOLEAN
  }
});

export const schemaEncomendasConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showArtigos', 'showArmazens', 'showDocFas', 'showTextoLivre'],
  properties: {
    showArtigos: SCHEMA_BOOLEAN,
    showArmazens: SCHEMA_BOOLEAN,
    showDocFas: SCHEMA_BOOLEAN,
    showTextoLivre: SCHEMA_BOOLEAN
  }
});

export const schemaContratosPrintConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showClasses'],
  properties: {
    showClasses: SCHEMA_BOOLEAN
  }
});

export const schemaFaturacaoEletronicaConfiguracoesConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  properties: {
    ambientePreProducao: SCHEMA_BOOLEAN
  }
});

export const schemaContratosListConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showNaoAtivosOnly'],
  properties: {
    showNaoAtivosOnly: SCHEMA_BOOLEAN
  }
});

export const schemaAdminPortaisConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showModuleName'],
  properties: {
    showModuleName: SCHEMA_BOOLEAN
  }
});

export const schemaComercialInventariosConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showTipoArtigo', 'showGrandeFamilia', 'showSubFamilia', 'showClasse', 'showCategoria', 'showValorimetria'],
  properties: {
    showTipoArtigo: SCHEMA_BOOLEAN,
    showGrandeFamilia: SCHEMA_BOOLEAN,
    showSubFamilia: SCHEMA_BOOLEAN,
    showClasse: SCHEMA_BOOLEAN,
    showCategoria: SCHEMA_BOOLEAN,
    showValorimetria: SCHEMA_BOOLEAN
  }
});

export const schemaLotesListConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showSoTerminados'],
  properties: {
    showSoTerminados: SCHEMA_BOOLEAN
  }
});

export const schemaExtratosDeContabilidadeConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showDiario'],
  properties: {
    showDiario: SCHEMA_BOOLEAN
  }
});

export const schemaMovimentosAbertosConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showGrupoConta'],
  properties: {
    showGrupoConta: SCHEMA_BOOLEAN
  }
});

export const schemaIdadeSaldosConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showGrupoConta'],
  properties: {
    showGrupoConta: SCHEMA_BOOLEAN
  }
});

export const schemaDocsComerciaisListConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: [
    'showObservacoes',
    'showDocExterno',
    'showDataDocExterno',
    'showDataVenc',
    'showCCusto',
    'showMoeda',
    'showNRefProcesso',
    'showNArmazem',
    'showNVendedor',
    'showValorCIva',
    'showCodUnidMov',
    'showNRequisicao',
    'showLiquido',
    'showD2',
    'showD3',
    'showDescEmValor',
    'showPrestacoes'
  ],
  properties: {
    showObservacoes: SCHEMA_BOOLEAN,
    showDocExterno: SCHEMA_BOOLEAN,
    showDataDocExterno: SCHEMA_BOOLEAN,
    showDataVenc: SCHEMA_BOOLEAN,
    showCCusto: SCHEMA_BOOLEAN,
    showMoeda: SCHEMA_BOOLEAN,
    showNRefProcesso: SCHEMA_BOOLEAN,
    showNArmazem: SCHEMA_BOOLEAN,
    showNVendedor: SCHEMA_BOOLEAN,
    showValorCIva: SCHEMA_BOOLEAN,
    showCodUnidMov: SCHEMA_BOOLEAN,
    showNRequisicao: SCHEMA_BOOLEAN,
    showLiquido: SCHEMA_BOOLEAN,
    showD2: SCHEMA_BOOLEAN,
    showD3: SCHEMA_BOOLEAN,
    showDescEmValor: SCHEMA_BOOLEAN,
    showPrestacoes: SCHEMA_BOOLEAN
  }
});

export const schemaMovimentacoesListConfigOptions: JSONSchema = deepFreeze<JSONSchema>({
  type: 'object',
  required: ['showCCusto', 'showNRefProcesso', 'showNArmazem', 'showNVendedor', 'showValorCIva', 'showCodUnidMov', 'showLiquido', 'showD2', 'showD3', 'showDescEmValor', 'showPrestacoes'],
  properties: {
    showCCusto: SCHEMA_BOOLEAN,
    showNRefProcesso: SCHEMA_BOOLEAN,
    showNArmazem: SCHEMA_BOOLEAN,
    showNVendedor: SCHEMA_BOOLEAN,
    showValorCIva: SCHEMA_BOOLEAN,
    showCodUnidMov: SCHEMA_BOOLEAN,
    showLiquido: SCHEMA_BOOLEAN,
    showD2: SCHEMA_BOOLEAN,
    showD3: SCHEMA_BOOLEAN,
    showDescEmValor: SCHEMA_BOOLEAN,
    showPrestacoes: SCHEMA_BOOLEAN
  }
});
