<div class="transferencias-armazens-newline-modal entity-detail-form">
  <pl-form>
    <div class="modal-header">
      <button type="button" class="btn-close" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'transferenciasarmazens.modal.newlinetitle'"></h5>
    </div>

    <div class="modal-body">
      <pl-group>
        <label [translate]="'transferenciasarmazens.modal.fields.codartigo'"></label>
        <edit>
          <entity-autocomplete
            entity="artigos"
            attrName="origemNArtigo"
            [model]="transfArmazem"
            (modelChange)="origemNArtigoChanged($event)"
            [fieldsMap]="{nArtigo: 'origemNArtigo', nome: 'nomeOrigemArtigo'}"
            plAutoFocus>
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group *ngIf="temLote">
        <label [translate]="'transferenciasarmazens.modal.fields.lotes'"></label>
        <edit>
          <entity-autocomplete
            entity="lotes"
            attrName="origemNLoteEspecifico"
            [model]="transfArmazem"
            (modelChange)="origemNLoteEspecificoChanged($event)"
            output="description"
            [fieldsMap]="{nLoteEspecifico: 'origemNLoteEspecifico'}"
            [filter]="filterLotes">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'transferenciasarmazens.modal.fields.armazemorigem'"></label>
        <edit>
          <entity-autocomplete
            entity="armazens"
            attrName="origemNArmazem"
            [model]="transfArmazem"
            (modelChange)="origemNArmazemChanged($event)"
            [fieldsMap]="{nArmazem: 'origemNArmazem', nome: 'nomeOrigemArmazem'}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'transferenciasarmazens.modal.fields.armazemdestino'"></label>
        <edit>
          <entity-autocomplete
            entity="armazens"
            attrName="destinoNArmazem"
            [model]="transfArmazem"
            (modelChange)="destinoNArmazemChanged($event)"
            [fieldsMap]="{nArmazem: 'destinoNArmazem', nome: 'nomeDestinoArmazem'}">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'transferenciasarmazens.modal.fields.qtd'"></label>
        <edit>
          <pl-edit
            type="number"
            attrName="origemQtd"
            [model]="transfArmazem.origemQtd"
            (modelChange)="transfArmazem.origemQtd = $event; transfArmazem.destinoQtd = transfArmazem.origemQtd"
            [properties]="{validators: {min: {value: 0}}}">
          </pl-edit>
        </edit>
        <actions>
          (<span><span [translate]="'transferenciasarmazens.modal.fields.stock'"></span>:&nbsp;</span> <span>{{ availableStock }}</span
          >)
        </actions>
      </pl-group>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button *ngIf="!isEditing" klass="btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close" data-focus>
        <i class="fa fa-fw fa-plus"></i>&nbsp;<span [translate]="'global.btn.add'"></span>
      </pl-button>

      <pl-button *ngIf="isEditing" klass="btn-sm btn-primary action-submit" id="editLine" [onClick]="close" data-focus>
        <i class="fa fa-fw fa-pencil-square-o"></i>&nbsp;<span [translate]="'global.btn.change'"></span>
      </pl-button>

      <pl-button klass="btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
    </div>
  </pl-form>
</div>
