import {EGrupoDoc} from '../../datasources/grupodoc/grupoDoc.datasource.interface';

export const MODULE_NAME_MOVIMENTACOES = 'movimentacoes';

export enum EMovimentacoesTabID {
  Entradas = 'entradas',
  Saidas = 'saidas'
}

export interface IMovimentacoesParams {
  grupo?: EGrupoDoc;
}
