import {HttpResponse} from '@angular/common/http';
import {Component, Injector, Input, OnInit, Renderer2, TemplateRef, ViewChild} from '@angular/core';
import {IPlToolbarItem, IPlToolbarMenuItem} from 'pl-comps-angular';
import {hasAuthority} from '../../../../../common/utils/roles.utils';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnRowPrepared} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {ROLE} from '../../../../services/role.const';
import {MODULE_NAME_GESTAO_FICHEIROS_SEPA} from '../../../../modules/gestaoficheirossepa/gestaoFicheirosSepa.module.interface';
import {IJsonPagamento, IJsonPagamentoCab} from '../../jsonPagamento.entity.interface';
import {EPagamentosTableLegendColors, IPagamentoCabWithData, TABLE_LEGEND_PAGAMENTOS} from '../../pagamentos.entity.interface';
import {IJsonAnosFaturacao} from '../../../anosfaturacao/jsonAnosFaturacao.entity.interface';
import {TEntityListBeforeRequestFn} from '../../../../components/entity/list/entity.list.component.interface';
import {IEntityServiceQueryRequestConfig} from '../../../../services/entity/entity.service.interface';
import {OPERATOR_AND} from '../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {anosFaturacaoToToolbarItem} from '../../../docscomerciais/utils/docscomerciais.utils';
import {merge} from 'lodash-es';
import {currentYear} from '../../../../../common/dates';
import {TTableLegend} from '../../../../components/tablelegend/tablelegend.component.interface';

@Component({
  selector: 'pagamentos-list',
  templateUrl: './pagamentos.entity.list.component.html'
})
export class PagamentosListComponent extends ModuloEntityListComponent<IJsonPagamentoCab> implements OnInit {
  @Input() public cgStoreUrlBackOffice: string;
  @Input() public anosList: Array<IJsonAnosFaturacao>;

  public comercialAdvanced: boolean;
  public licencaStoreModePublic: boolean;
  public portalName: string;
  public tableLegend: TTableLegend;

  private readonly _menuAnos: IPlToolbarItem<number>;
  private _selectedAno: IPlToolbarMenuItem<number>;
  private _anoEmCurso: number;

  private readonly _btnGestaoSepa: IPlToolbarItem;
  private readonly _maintenanceGestaoFicheirosSepa: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    private readonly _renderer: Renderer2
  ) {
    super(_injector);
    this._maintenanceGestaoFicheirosSepa = this._moduleMaintenanceService.build(MODULE_NAME_GESTAO_FICHEIROS_SEPA);
    this.licencaStoreModePublic = this.configurations.licenca.modoCGOn;
    this.portalName = this._translateService.instant('portals.items.erp')?.toLowerCase();

    this.comercialAdvanced = false;
    this._btnGestaoSepa = {
      id: 'btn-gestao-sepa',
      caption: 'pagamentos.list.gestaoFicheirosSepa',
      type: 'button',
      class: 'btn-info',
      disabled: true,
      order: 131,
      iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
      click: () => this._showGestaoFicheirosSEPA(),
      tooltip: {
        disabled: false
      }
    };

    this._menuAnos = {
      id: 'anos',
      order: this.btnRefresh.order,
      caption: 'pagamentos.text.ano',
      type: 'dropdown',
      class: 'btn-primary',
      menu: []
    };

    this.comercialAdvanced = hasAuthority(this.session, ROLE.ERPADVANCED);
    this._btnGestaoSepa.disabled = !this.comercialAdvanced;
    this._btnGestaoSepa.tooltip.disabled = this.comercialAdvanced;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnGestaoSepa);
    this._anoEmCurso = currentYear();
    this._loadMenuAnos();
    this.tableLegend = TABLE_LEGEND_PAGAMENTOS;
  }

  public onDetail({data}: IDevExpressDataGridEventOnCellClick<IPagamentoCabWithData>): Promise<void> {
    const params =
      this._selectedAno?.data !== this.configurations.empresa.anoEmCursoIRC ? {nconta: data.nConta, ano: this._selectedAno.data, anulado: data.anulado} : {nconta: data.nConta, anulado: data.anulado};
    return this.service.get<IJsonPagamento>({id: data.extPocCabID, params: params}).then((response: HttpResponse<IJsonPagamento>) => {
      data._pagamento = response.body;
      if (this._selectedAno?.data !== this.configurations.empresa.anoEmCursoIRC) {
        data._pagamento.anoPagamento = this._selectedAno?.data;
      }
    });
  }

  public onDataGridRowPrepared({data, rowElement, rowType}: IDevExpressDataGridEventOnRowPrepared<IJsonPagamentoCab>): void {
    if (rowType === 'data') {
      let cssClass: string;
      if (data.anulado) {
        cssClass = EPagamentosTableLegendColors.PagamentoAnulado;
      }

      if (cssClass) {
        this._renderer.addClass(rowElement, cssClass);
      } else if (rowElement.style.backgroundColor) {
        this._renderer.removeClass(rowElement, cssClass);
      }
    }
  }

  public readonly fnBeforeRequest: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequest(queryRequestConfig);

  @ViewChild('templateTooltipGestaoSepa')
  public set templateTooltipGestaoSepa(value: TemplateRef<void>) {
    this._btnGestaoSepa.tooltip.templateRef = value;
  }

  private _showGestaoFicheirosSEPA(): Promise<void> {
    return this._maintenanceGestaoFicheirosSepa.maintenance();
  }

  private _beforeRequest(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    if (this._selectedAno) {
      if (queryRequestConfig.pesquisa) {
        queryRequestConfig.pesquisa += OPERATOR_AND;
      }
      queryRequestConfig.pesquisa += `ano=${this._selectedAno.data}`;
    }
    return queryRequestConfig;
  }

  private _loadMenuAnos(): void {
    const anos: Array<IPlToolbarMenuItem<number>> = anosFaturacaoToToolbarItem(this.anosList);

    anos.map((itemAno) => {
      if (itemAno.active) {
        this._selectedAno = itemAno;
      }
      return merge(itemAno, {
        click: (menuItem: IPlToolbarMenuItem<number>) => {
          this._anoChanged(menuItem);
        }
      });
    });
    this._menuAnos.caption = this._getAnoCaption();
    this._menuAnos.menu = anos;
    this.toolbar.addButton(this._menuAnos);

    this._anoChanged(this._selectedAno);
  }

  private _anoChanged(menuItem: IPlToolbarMenuItem<number>): Promise<void> {
    if (menuItem.data !== this._anoEmCurso) {
      if (this._selectedAno) {
        this._selectedAno.active = false;
      }
      this._selectedAno = menuItem;
      this._selectedAno.active = true;
      this._anoEmCurso = menuItem.data;
      this._menuAnos.caption = this._getAnoCaption();
    }
    return this.refreshList();
  }

  private _getAnoCaption(): string {
    return this._translateService.instant('toolbar.year', {value: this._anoEmCurso});
  }
}
