import {Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {CGModalService} from '../../../../../components/cg/modal/cgmodal.service';
import {PortalClientAccountService} from '../../../../../services/portalclientaccount/portalclientaccount.service';
import {IPCARHMapasFeriasFaltasFilters} from '../../pcaRHMapas.module.service.interface';
import moment from 'moment';
import {MAX_SMALL_INT} from '../../../../../../common/utils/utils';
import {CGCardPanelComponent} from '../../../../../components/cg/cardpanel/cardpanel.component';
import {isDefinedNotNull, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {IJsonReport} from '../../../../../entities/reports/jsonReport.interface';
import {EReport} from '../../../../../entities/reports/reports.interface';
import {ReportsRegistryService} from '../../../../../components/reports/reports.registry.service';
import {DEFAULT_RH_MAPAS_FERIAS_FALTRAS_REPORT, EGestaoDGEMPSType, RH_EVENTS_REPORTS_GROUPS} from '../../../../../components/gestaodgemps/gestaodgemps.interface';

@Component({
  selector: 'mapa-faltas',
  templateUrl: './mapaFaltas.module.component.html'
})
export class MapaFaltasComponent extends ModuloComponent implements OnInit {
  public pdfUrl: string;
  public filters: IPCARHMapasFeriasFaltasFilters;
  public reportModel: IJsonReport;
  public reportSource: Array<IJsonReport>;
  private _cardPanel: CGCardPanelComponent;
  private _firstReportLoaded: boolean;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _entityServiceBuilder: EntityServiceBuilder,
    protected readonly _cgModalService: CGModalService,
    private readonly _portalClientAccountsFactory: PortalClientAccountService,
    private readonly _plAlertService: PlAlertService,
    private readonly _reportsRegistryService: ReportsRegistryService
  ) {
    super(_injector);
    this.reportSource = [];
    this.filters = {
      dataDe: moment().startOf('year'),
      dataAte: moment().endOf('year'),
      codEmpDe: 0,
      codEmpAte: MAX_SMALL_INT,
      empShowInactive: false
    };
    this._firstReportLoaded = false;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._loadDefaultReport();
  }

  public reportChanged(reportName: string): void {
    this.reportModel = this.reportSource.find((o) => o.name === reportName);
    if (this._firstReportLoaded) {
      this._reloadReport();
    }
  }

  public readonly fnReloadReport = (): void => {
    this._reloadReport();
  };

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _reloadReport(): void {
    if (!moment(this.filters.dataDe).isSame(this.filters.dataAte, 'year')) {
      this._plAlertService.error('pca.mensagens.datasAfiltrarTemEstarDentroMesmoAno');
      return;
    }
    this.pdfUrl = undefined;
    this._portalClientAccountsFactory
      .getMapaFaltasUrl(this.reportModel.name, this.filters.dataDe, this.filters.dataAte, this.filters.codEmpDe, this.filters.codEmpAte, this.filters.empShowInactive)
      .subscribe((url: string) => {
        this._firstReportLoaded = true;
        this.pdfUrl = url;
        this._cardPanel.collapse();
      });
  }

  private _loadDefaultReport(): Promise<void> {
    return this._reportsRegistryService
      .get(EReport.ImpEventosRH)
      .query()
      .then((reports: Array<IJsonReport>) => {
        if (reports.length) {
          reports = reports.filter((report) => RH_EVENTS_REPORTS_GROUPS[EGestaoDGEMPSType.Faltas].includes(report.name.toUpperCase()));
          let defaultReport: IJsonReport = null;
          const source: Array<IJsonReport> = [];
          for (const report of reports) {
            source.push(report);
            if (isUndefinedOrNull(defaultReport) && report.name.toUpperCase() === DEFAULT_RH_MAPAS_FERIAS_FALTRAS_REPORT) {
              defaultReport = {name: report.name, title: report.title};
            }
          }

          this.reportSource = source;
          this.reportModel = isDefinedNotNull(defaultReport)
            ? defaultReport
            : source.length
              ? {
                  name: source[0].name,
                  title: source[0].title
                }
              : undefined;
        }
      });
  }
}
