import {Component, Injector, Input, OnInit} from '@angular/core';
import {IJsonModelo22AnexoD} from '../../../../jsonModelo22.module.interface';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {isNumber, PlAlertService} from 'pl-comps-angular';

@Component({
  selector: 'modal-modelo22-anexod-quadro04C',
  templateUrl: './modelo22.anexoD.quadro04C.modal.component.html'
})
export class Modelo22AnexoDQuadro04CModalComponent extends CGModalComponent<IJsonModelo22AnexoD> implements OnInit {
  @Input() public newLineFor: string;
  @Input() public anexoD: IJsonModelo22AnexoD;

  public promise: Promise<void>;

  public periodo: number;
  public aumentoCapitaisProprios: number;
  public saidasElegiveisEmDinheiro: number;
  public aumentosLiquidosCapitaisProprios: number;
  public somatorioValoresApurados: number;
  public taxaEuribor: number;
  public beneficioFiscalPotencial: number;
  public beneficioFiscalPotencialMajorado: number;
  public trintaPercEBITDA: number;
  public beneficioFiscalPeriodo: number;

  public excessoPeriodoReportar: number;
  public reportePeriodosAnteriores: number;
  public montante: number;
  public deducaoPeriodo: number;
  public saldoReportar: number;

  public is04C1: boolean;
  public is04C2: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.periodo = 0;
    this.aumentoCapitaisProprios = 0;
    this.saidasElegiveisEmDinheiro = 0;
    this.aumentosLiquidosCapitaisProprios = 0;
    this.somatorioValoresApurados = 0;
    this.taxaEuribor = 0;
    this.beneficioFiscalPotencial = 0;
    this.beneficioFiscalPotencialMajorado = 0;
    this.trintaPercEBITDA = 0;
    this.beneficioFiscalPeriodo = 0;
  }

  public ngOnInit(): void {
    this.is04C1 = this.newLineFor === '04C1';
    this.is04C2 = this.newLineFor === '04C2';
  }

  public close(): Promise<void> {
    if (this.is04C1) {
      if (!isNumber(this.periodo) || this.periodo === 0) {
        this._plAlertService.error('modelo22.messages.periodoVazio');
        return Promise.resolve();
      }
      this.anexoD.quadro04.campo04C1List.push({
        periodo: this.periodo,
        aumentoCapitaisProprios: this.aumentoCapitaisProprios,
        saidasElegiveisEmDinheiro: this.saidasElegiveisEmDinheiro,
        aumentosLiquidosCapitaisProprios: this.aumentosLiquidosCapitaisProprios,
        somatorioValoresApurados: this.somatorioValoresApurados,
        taxaEuribor: this.taxaEuribor,
        beneficioFiscalPotencial: this.beneficioFiscalPotencial,
        beneficioFiscalPotencialMajorado: this.beneficioFiscalPotencialMajorado,
        trintaPercEBITDA: this.trintaPercEBITDA,
        beneficioFiscalPeriodo: this.beneficioFiscalPeriodo
      });

      super.close(this.anexoD);
    }
    if (this.is04C2) {
      if (!isNumber(this.periodo) || this.periodo === 0) {
        this._plAlertService.error('modelo22.messages.periodoVazio');
        return Promise.resolve();
      }
      this.anexoD.quadro04.campo04C2List.push({
        excessoPeriodoReportar: this.excessoPeriodoReportar,
        reportePeriodosAnteriores: this.reportePeriodosAnteriores,
        periodo: this.periodo,
        montante: this.montante,
        deducaoPeriodo: this.deducaoPeriodo,
        saldoReportar: this.saldoReportar
      });

      super.close(this.anexoD);
    }
    return Promise.resolve();
  }
}
