import {ContabilidadeSvatComponent} from './components/contabilidade.svat.module.component';
import {ROLE} from '../../../../services/role.const';
import {IModuleDefinition} from '../../../../components/module/module.definition.interface';
import {MODULE_NAME_PCA_MANUTENCAO_SVAT} from './contabilidade.svat.module.interface';

export const MODULE_PCA_MANUTENCAO_SVAT: IModuleDefinition = {
  name: MODULE_NAME_PCA_MANUTENCAO_SVAT,
  state: {
    url: `/${MODULE_NAME_PCA_MANUTENCAO_SVAT}`,
    component: ContabilidadeSvatComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.svat'
    },
    params: {
      year: {
        type: 'int',
        value: null
      }
    }
  }
};
