import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_ATIVOS_NOTA_CREDITO} from './ativosNotaCredito.module.interface';
import {AtivosNotaCreditoModuleComponent} from './components/ativosNotaCredito.module.component';

export const MODULE_ATIVOS_NOTA_CREDITO: IModuleDefinition = {
  name: MODULE_NAME_ATIVOS_NOTA_CREDITO,
  state: {
    url: `/${MODULE_NAME_ATIVOS_NOTA_CREDITO}`,
    component: AtivosNotaCreditoModuleComponent,
    data: {
      roles: [ROLE.ATIVOS],
      icon: 'fa-pencil',
      pageTitle: 'portals.sidebar.ativos.ativosNotaCredito'
    }
  }
};
