import {Injectable} from '@angular/core';
import {ApiService} from './api/api.service';
import {EDocumentosFiscaisMainMenu, EDocumentosFiscaisTab} from '../modules/portalcontabilidade/manutencao/documentosFiscais/documentosFiscais.module.interface';
import {
  IAtApiCommonBase,
  IAtCertidaoListResponse,
  IAtDpIvaApiListResponse,
  IAtDrIvaApiListResponse,
  IAtIESApiListResponse,
  IAtIESApiPagamentoResponse,
  IAtImoveisNotasCobrancaListResponse,
  IAtIRCMod22ApiListResponse,
  IAtIRCPagamentosAntecipadosApiListResponse,
  IAtIRSMod10ApiListResponse,
  IAtIUCApiListResponse,
  IAtIUCListaParaEmitirResponse,
  IAtPatrimnioPredialListResponse,
  IAtPenhorasListResponse,
  IAtRecibosRendasApiListResponse,
  IAtRecibosRendasContratoLocatario,
  IAtRecibosRendasReciboDetails,
  IAtRecibosVerdesApiListResponse,
  IAtRetencoesIRCIRSApiListResponse,
  IAtVeiculoDetailsResponse,
  IAtVeiculosListResponse,
  IAtViaCTTListResponse,
  ISSSituacaoContributivaListResponse
} from '../../common/interfaces/at.interfaces';
import {TDate} from '../../common/dates';
import {TServiceResponse} from './api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class AtService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/atservices`;
  }

  public getDpIvaList(ano: number): TServiceResponse<IAtDpIvaApiListResponse> {
    return this._apiService.get<IAtDpIvaApiListResponse>({url: `${this._path}/dpiva/list/${ano}`, reportExceptions: false});
  }

  public getDpIvaComprovativo(decId: string): TServiceResponse<unknown> {
    return this._apiService.get<unknown>({url: this.getDpIvaComprovativoURL(decId)});
  }

  public getDpIvaComprovativoURL(decId: string): string {
    return `${this._path}/dpiva/comprovativo/${decId}`;
  }

  public getDpIvaDocPagamento(decId: string, isReplacement: boolean, ivaPagoInCent: number): TServiceResponse<unknown> {
    return this._apiService.get<unknown>({url: this.getDpIvaDocPagamentoURL(decId, isReplacement, ivaPagoInCent)});
  }

  public getDpIvaDocPagamentoURL(decId: string, isReplacement: boolean, ivaPagoInCent: number): string {
    return `${this._path}/dpiva/doc-pagamento/${decId}?isReplacement=${isReplacement}&ivaPago=${ivaPagoInCent}`;
  }

  public getDrIvaList(ano: number): TServiceResponse<IAtDrIvaApiListResponse> {
    return this._apiService.get<IAtDrIvaApiListResponse>({url: `${this._path}/driva/list/${ano}`, reportExceptions: false});
  }

  public getDrIvaComprovativo(decId: string): TServiceResponse<unknown> {
    return this._apiService.get<unknown>({url: this.getDrIvaComprovativoURL(decId)});
  }

  public getDrIvaComprovativoURL(decId: string): string {
    return `${this._path}/driva/comprovativo/${decId}`;
  }

  public getRecibosVerdesList(fromDate: TDate, endDate: TDate, nifPrestador: string = ''): TServiceResponse<IAtRecibosVerdesApiListResponse> {
    return this._apiService.get<IAtRecibosVerdesApiListResponse>({
      url: `${this._path}/recibos-verdes/list`,
      params: {dataInicio: fromDate, dataFim: endDate, nifPrestador: nifPrestador},
      reportExceptions: false
    });
  }

  public getReciboVerdeComprovativo(nifPrestadorServicos: string, numDocumento: number, isEco: boolean, userId: number = 0): TServiceResponse<unknown> {
    return this._apiService.get<unknown>({url: this.getReciboVerdeComprovativoURL(nifPrestadorServicos, numDocumento, isEco, userId)});
  }

  public getReciboVerdeComprovativoURL(nifPrestadorServicos: string, numDocumento: number, isEco: boolean, userId: number = 0): string {
    return `${this._path}/recibos-verdes/${nifPrestadorServicos}/${userId}/${numDocumento}?isEco=${isEco}`;
  }

  public getRecibosRendasList(fromDate: TDate, endDate: TDate): TServiceResponse<IAtRecibosRendasApiListResponse> {
    return this._apiService.get<IAtRecibosRendasApiListResponse>({
      url: `${this._path}/recibos-rendas/list`,
      params: {dataInicio: fromDate, dataFim: endDate},
      reportExceptions: false
    });
  }

  public getRecibosRendasEmitenteList(fromDate: TDate, endDate: TDate): TServiceResponse<IAtRecibosRendasApiListResponse> {
    return this._apiService.get<IAtRecibosRendasApiListResponse>({
      url: `${this._path}/recibos-rendas-emitente/list`,
      params: {dataInicio: fromDate, dataFim: endDate},
      reportExceptions: false
    });
  }

  public getReciboRendaComprovativo(numeroContrato: number, numeroRecibo: number): TServiceResponse<unknown> {
    return this._apiService.get<unknown>({url: this.getReciboRendaComprovativoURL(numeroContrato, numeroRecibo)});
  }

  public getReciboRendaComprovativoURL(numeroContrato: number, numeroRecibo: number): string {
    return `${this._path}/recibos-rendas/${numeroContrato}/${numeroRecibo}`;
  }

  public getIESList(): TServiceResponse<IAtIESApiListResponse> {
    return this._apiService.get<IAtIESApiListResponse>({url: `${this._path}/ies/list`, reportExceptions: false});
  }

  public getIESComprovativo(declId: string, ano: number, situacaoVigente: string): TServiceResponse<unknown> {
    return this._apiService.get<unknown>({url: this.getIESComprovativoURL(declId, ano, situacaoVigente)});
  }

  public getIESDadosPagamento(idPagamento: number): TServiceResponse<IAtIESApiPagamentoResponse> {
    return this._apiService.get<IAtIESApiPagamentoResponse>({url: `${this._path}/ies/pagamento/${idPagamento}`});
  }

  public getIESComprovativoURL(declId: string, ano: number, situacaoVigente: string): string {
    return `${this._path}/ies/comprovativo/${declId}/${ano}?situacaoVigente=${situacaoVigente}`;
  }

  public getIRCPagamentosAntecipadosList(ano: number): TServiceResponse<IAtIRCPagamentosAntecipadosApiListResponse> {
    return this._apiService.get<IAtIRCPagamentosAntecipadosApiListResponse>({url: `${this._path}/irc/pagamentos/list/${ano}`, reportExceptions: false});
  }

  public getIRCMod22List(ano: number): TServiceResponse<IAtIRCMod22ApiListResponse> {
    return this._apiService.get<IAtIRCMod22ApiListResponse>({url: `${this._path}/irc/mod22/list/${ano}`, reportExceptions: false});
  }

  public getIRCPagamentosAntecipadosComprovativo(nguia: string): TServiceResponse<unknown> {
    return this._apiService.get<unknown>({url: this.getIRCPagamentosAntecipadosComprovativoURL(nguia)});
  }

  public getIRCPagamentosAntecipadosComprovativoURL(nguia: string): string {
    return `${this._path}/irc/pagamentos/comprovativo/${nguia}`;
  }

  public getIRCMod22Comprovativo(declId: string): TServiceResponse<unknown> {
    return this._apiService.get<unknown>({url: this.getIRCMod22ComprovativoURL(declId)});
  }

  public getIRCMod22ComprovativoURL(declId: string): string {
    return `${this._path}/irc/mod22/comprovativo/${declId}`;
  }

  public getIRCMod22DocPagamento(declId: string): TServiceResponse<unknown> {
    return this._apiService.get<unknown>({url: this.getIRCMod22DocPagamentoURL(declId)});
  }

  public getIRCMod22DocPagamentoURL(declId: string): string {
    return `${this._path}/irc/mod22/doc-pagamento/${declId}`;
  }

  public getReciboRendaContratoLocatario(numeroContrato: number): TServiceResponse<IAtRecibosRendasContratoLocatario> {
    return this._apiService.get<IAtRecibosRendasContratoLocatario>({url: `${this._path}/recibos-rendas/detalheContrato/${numeroContrato}`});
  }

  public getReciboRendaReciboDetails(numeroContrato: number, numeroRecibo: number): TServiceResponse<IAtRecibosRendasReciboDetails> {
    return this._apiService.get<IAtRecibosRendasReciboDetails>({url: `${this._path}/recibos-rendas/detalheRecibo/${numeroContrato}/${numeroRecibo}`});
  }

  public getIRSMod10List(): TServiceResponse<IAtIRSMod10ApiListResponse> {
    return this._apiService.get<IAtIRSMod10ApiListResponse>({url: `${this._path}/irs/mod10/list`, reportExceptions: false});
  }

  public getIRSMod10Comprovativo(comprovativoId: string): TServiceResponse<unknown> {
    return this._apiService.get<unknown>({url: this.getIRSMod10ComprovativoURL(comprovativoId)});
  }

  public getIRSMod10ComprovativoURL(comprovativoId: string): string {
    return `${this._path}/irs/mod10/comprovativo/${comprovativoId}`;
  }

  public getRetencoesIRCIRSList(ano: number): TServiceResponse<IAtRetencoesIRCIRSApiListResponse> {
    return this._apiService.get<IAtRetencoesIRCIRSApiListResponse>({url: `${this._path}/retencoes-irc-irs/list/${ano}`, reportExceptions: false});
  }

  public getRetencoesIRCIRSComprovativo(id: string): TServiceResponse<unknown> {
    return this._apiService.get<unknown>({url: this.getRetencoesIRCIRSComprovativoURL(id)});
  }

  public getRetencoesIRCIRSComprovativoURL(id: string): string {
    return `${this._path}/retencoes-irc-irs/comprovativo/${id}`;
  }

  public getIUCList(ano: number): TServiceResponse<IAtIUCApiListResponse> {
    return this._apiService.get<IAtIUCApiListResponse>({url: `${this._path}/iuc/list/${ano}`, reportExceptions: false});
  }

  public getIUCComprovativoURL(ano: number, matricula: string, numDocumento: number): string {
    return `${this._path}/iuc/obter/comprovativo/${ano}/${matricula}/${numDocumento}`;
  }

  public getIUCGuiaPagamentoURL(ano: number, numDocumento: number): string {
    return `${this._path}/iuc/obter/pagamento/${ano}/${numDocumento}`;
  }

  public getIUCListaParaEmitir(group: number): TServiceResponse<IAtIUCListaParaEmitirResponse> {
    return this._apiService.get<IAtIUCListaParaEmitirResponse>({url: `${this._path}/iuc/lista/emitir/${group}`, reportExceptions: false});
  }

  public postIUCEmitir(group: number, matricula: string): TServiceResponse<IAtApiCommonBase> {
    return this._apiService.post<IAtApiCommonBase>({url: `${this._path}/iuc/emitir/${group}/${matricula}`});
  }

  public getVeiculosList(): TServiceResponse<IAtVeiculosListResponse> {
    return this._apiService.get<IAtVeiculosListResponse>({url: `${this._path}/veiculos/list`, reportExceptions: false});
  }

  public getVeiculoDetails(id: number): TServiceResponse<IAtVeiculoDetailsResponse> {
    return this._apiService.get<IAtVeiculoDetailsResponse>({url: `${this._path}/veiculos/details/${id}`});
  }

  public getCertidaoList(startDate: TDate, endDate: TDate, tipoCertidao: string, estado: string): TServiceResponse<IAtCertidaoListResponse> {
    return this._apiService.get<IAtCertidaoListResponse>({
      url: `${this._path}/certidao/list`,
      params: {
        startDate: startDate,
        endDate: endDate,
        tipoCertidao: tipoCertidao,
        estado: estado
      },
      reportExceptions: false
    });
  }

  public getCertidaoDocumentoURL(id: number, tipoCertidao: string): string {
    return `${this._path}/certidao/documento/${id}?tipoCertidao=${tipoCertidao}`;
  }

  public getCertidaoPedidoURL(ano: number, tipoCertidao: string): string {
    return `${this._path}/certidao/pedido?tipoCertidao=${tipoCertidao}&ano=${ano}`;
  }

  public getImoveisPPList(): TServiceResponse<IAtPatrimnioPredialListResponse> {
    return this._apiService.get<IAtPatrimnioPredialListResponse>({url: `${this._path}/imoveis/patrimniopredial/list`, reportExceptions: false});
  }

  public getImoveisNCList(ano: number): TServiceResponse<IAtImoveisNotasCobrancaListResponse> {
    return this._apiService.get<IAtImoveisNotasCobrancaListResponse>({url: `${this._path}/imoveis/notascobranca/list`, params: {ano: ano}, reportExceptions: false});
  }

  public getImoveisDocumentoURL(id: number): string {
    return `${this._path}/imoveis/documento/${id}`;
  }

  public getImoveisGuiaNCURL(ano: number, id: number): string {
    return `${this._path}/imoveis/guianotacobranca/${ano}/${id}`;
  }

  public getSSSituacaoContributivaList(): TServiceResponse<ISSSituacaoContributivaListResponse> {
    return this._apiService.get<ISSSituacaoContributivaListResponse>({url: `${this._path}/situacaocontributiva/list`, reportExceptions: false});
  }

  public getSSSituacaoContributivaDocURL(id: string): string {
    return `${this._path}/situacaocontributiva/documento/${id}`;
  }

  public getPenhorasList(): TServiceResponse<IAtPenhorasListResponse> {
    return this._apiService.get<IAtPenhorasListResponse>({url: `${this._path}/penhoras/list`, reportExceptions: false});
  }

  public getViaCTTList(): TServiceResponse<IAtViaCTTListResponse> {
    return this._apiService.get<IAtViaCTTListResponse>({url: `${this._path}/viactt/list`, reportExceptions: false});
  }

  public getViaCTTDocURL(accountId: number, billerId: number, userId: string, idDocumento: string): string {
    return `${this._path}/viactt/documento?accountId=${accountId}&billerId=${billerId}&userId=${userId}&idDocumento=${idDocumento}`;
  }

  public associarDocumentosContabDigital<T extends object>(type: EDocumentosFiscaisMainMenu, documentos: Array<T>, folderID: string, activeTab: EDocumentosFiscaisTab): TServiceResponse<void> {
    let path: string;
    switch (type) {
      case EDocumentosFiscaisMainMenu.IVA:
        path = 'dpiva/doc-pagamento';
        break;
      case EDocumentosFiscaisMainMenu.IRC:
        switch (activeTab) {
          case EDocumentosFiscaisTab.None:
            break;
          case EDocumentosFiscaisTab.DpIva:
            break;
          case EDocumentosFiscaisTab.DrIva:
            break;
          case EDocumentosFiscaisTab.RecibosRV:
            break;
          case EDocumentosFiscaisTab.RecibosRR:
            break;
          case EDocumentosFiscaisTab.IucDoc:
            break;
          case EDocumentosFiscaisTab.IucEmitir:
            break;
          case EDocumentosFiscaisTab.CertidoesConsulta:
            break;
          case EDocumentosFiscaisTab.CertidoesEmitir:
            break;
          case EDocumentosFiscaisTab.ImoveisPP:
            break;
          case EDocumentosFiscaisTab.ImoveisNC:
            break;
          case EDocumentosFiscaisTab.IrcPagAnt:
            path = 'irc/pagamentos/comprovativo';
            break;
          case EDocumentosFiscaisTab.IrcMod22:
            path = 'irc/mod22/doc-pagamento';
            break;
        }
        break;
      case EDocumentosFiscaisMainMenu.RECIBOS:
        switch (activeTab) {
          case EDocumentosFiscaisTab.None:
            break;
          case EDocumentosFiscaisTab.DpIva:
            break;
          case EDocumentosFiscaisTab.DrIva:
            break;
          case EDocumentosFiscaisTab.IucDoc:
            break;
          case EDocumentosFiscaisTab.IucEmitir:
            break;
          case EDocumentosFiscaisTab.CertidoesConsulta:
            break;
          case EDocumentosFiscaisTab.CertidoesEmitir:
            break;
          case EDocumentosFiscaisTab.ImoveisPP:
            break;
          case EDocumentosFiscaisTab.ImoveisNC:
            break;
          case EDocumentosFiscaisTab.IrcPagAnt:
            break;
          case EDocumentosFiscaisTab.IrcMod22:
            break;
          case EDocumentosFiscaisTab.RecibosRV:
            path = 'recibos-verdes';
            break;
          case EDocumentosFiscaisTab.RecibosRR:
            path = 'recibos-rendas';
            break;
        }
        break;
      case EDocumentosFiscaisMainMenu.IES:
        break;
      case EDocumentosFiscaisMainMenu.IRS:
        break;
      case EDocumentosFiscaisMainMenu.RETENCOES:
        path = 'retencoes-irc-irs';
        break;
      case EDocumentosFiscaisMainMenu.IUC:
        path = 'iuc/obter/pagamento';
        break;
      case EDocumentosFiscaisMainMenu.VEICULOS:
        break;
      case EDocumentosFiscaisMainMenu.CERTIDOES:
        break;
      case EDocumentosFiscaisMainMenu.IMOVEIS:
        path = 'imoveis/notascobranca';
        break;
      case EDocumentosFiscaisMainMenu.PENHORAS:
        break;
      case EDocumentosFiscaisMainMenu.SS_SITUACAO_CONTRIB:
        break;
      case EDocumentosFiscaisMainMenu.VIACTT_CAIXA_CORREIO:
        break;
      default:
        throw new Error('Not implemented');
    }
    return this._apiService.post<void, Array<T>>({url: `${this._path}/${path}`, body: documentos, params: {folderID: folderID}});
  }
}
