import {merge} from 'lodash-es';
import {Component, Injector, OnInit} from '@angular/core';
import {
  ICGCTableData,
  ICGTableOnSelect,
  IPlTableOptions,
  IPlToolbarItem,
  IPlToolbarItemDefinition,
  IPlToolbarMenuItemDefinition,
  isArray,
  isDefined,
  isNumber,
  PlAlertService,
  PlTranslateService
} from 'pl-comps-angular';
import {EmpresasService} from '../../../../../services/empresas/empresas.service';
import {ModuloComponent} from '../../../../../components/module/module.component';
import {SvatDataBuilder} from '../contabilidade.svat.builder';
import {SvatService} from '../contabilidade.svat.module.service';
import {IJsonEmpresaAno} from '../../../../../interfaces/jsonEmpresa.interface';
import {ISVATIntentParameters} from '../amalia/contabilidade.svat.amalia.interface';
import {HttpResponse} from '@angular/common/http';
import {IJsonDfSVAT} from '../jsonDfSVAT.module.interface';

/* eslint-disable @typescript-eslint/no-explicit-any */

@Component({
  selector: 'contabilidade-svat',
  templateUrl: './contabilidade.svat.module.component.html'
})
export class ContabilidadeSvatComponent extends ModuloComponent implements OnInit {
  public builder: SvatDataBuilder;
  public fieldAnoNCaption: string;
  public fieldAnoN1Caption: string;
  public tableCommonOptions: IPlTableOptions;
  public viewData: Array<any>;
  public anoEmCurso: number;
  public paramsAi: ISVATIntentParameters;

  private _dropdownAnos: IPlToolbarItemDefinition<IJsonEmpresaAno>;
  private _btnProcessar: IPlToolbarItemDefinition;
  private _btnCallModal: IPlToolbarItemDefinition;

  constructor(
    protected readonly _injector: Injector,
    private readonly _empresasService: EmpresasService,
    private readonly _svatService: SvatService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this.anoEmCurso = this._configService.configurations.empresa.anoEmCursoIRC;
    this.viewData = [];
    this.tableCommonOptions = {perPage: 20, suppressEmptyLines: true, hidePagination: true};
    this.updateAnoCaptions();
    this.builder = new SvatDataBuilder(this);
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.paramsAi = <ISVATIntentParameters>this._transition.params();
    this._dropdownAnos = {
      id: 'dropdownAnos',
      order: 2,
      type: 'dropdown',
      iconLeft: 'Ano: ',
      caption: String(this.anoEmCurso),
      menu: []
    };
    this._btnProcessar = {
      id: 'processar',
      order: 3,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-bolt"></i>',
      class: 'btn-success',
      caption: 'global.btn.process',
      click: () => this._processar()
    };
    this._btnCallModal = {
      id: 'callmodal',
      order: 4,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-print"></i>',
      class: 'btn-light',
      caption: 'global.btn.print',
      click: () => this.callPrintModal()
    };

    this.toolbar.addButton(this._btnProcessar);
    this.toolbar.addButton(this._btnCallModal);
    this.toolbar.addButton(this._dropdownAnos);
    await this._loadAnosDisponiveis();

    if (this.paramsAi.year) {
      await this._loadAi();
    }
  }

  public updateAnoCaptions(): void {
    this.fieldAnoNCaption = `31/12/${this.anoEmCurso}`;
    this.fieldAnoN1Caption = `31/12/${this.anoEmCurso - 1}`;
  }

  public async callPrintModal(): Promise<void> {
    await this._svatService.openPrintModal(this.anoEmCurso);
  }

  public responseToView(response: any): void {
    this.viewData = this.builder.build(response);
  }

  public balanceteClick(item: any, index: number): void {
    if (item?.subGroup?.details?.[index]) {
      const detail = item.subGroup.details[index];
      const modalSource: ICGCTableData = {list: [], total: 0, footer: []};
      const source = detail[1].tableSource.list || detail[1].tableSource;

      let anoNTotal = 0;
      let anoN1Total = 0;
      for (const sourceItem of source) {
        anoNTotal += sourceItem.anoN;
        anoN1Total += sourceItem.anoN1;
        modalSource.list.push(sourceItem);
      }

      modalSource.footer.push({
        nConta: modalSource.list.length,
        nomeConta: '&nbsp;',
        anoN: anoNTotal,
        anoN1: anoN1Total
      });

      this._svatService.openBalanceteModal({
        rubricaTitle: item.descricao,
        tableDef: detail[1].tableDef,
        tableSource: modalSource,
        tableOptions: merge({}, this.tableCommonOptions, {perPage: modalSource.list.length})
      });
    }
  }

  public trackMainTabs(index: number, mainTab: any): string {
    return mainTab ? mainTab.id : undefined;
  }

  public trackTabId(index: number, tab: any): string {
    return tab ? tab.id : undefined;
  }

  public getTableCommomOptions(source: any): IPlTableOptions {
    if (isDefined(source)) {
      const len = isArray(source) ? source.length : source.list.length;
      return merge({}, this.tableCommonOptions, {perPage: len});
    }
    return this.tableCommonOptions;
  }

  public getTableInstanceId(): string {
    return this.builder.rand();
  }

  public onSelect({item, columnIndex}: ICGTableOnSelect<unknown>): void {
    if (isNumber(columnIndex) && columnIndex !== -1) {
      item._detailOpen = !item._detailOpen;
    }
  }

  private async _loadAnosDisponiveis(): Promise<void> {
    this._dropdownAnos.menu = [];
    const years: Array<IJsonEmpresaAno> = (await this._empresasService.getAnos(this.session.erp.nEmpresa)).body.list;
    if (years.length) {
      for (const item of years) {
        this._dropdownAnos.menu.push({
          caption: String(item.ano),
          data: item,
          click: (menuItem) => {
            this._changeYear(menuItem);
          }
        });
      }
    }
  }

  private _changeYear(item: IPlToolbarItem<IJsonEmpresaAno>): void {
    this._dropdownAnos.caption = item.caption;
    this.anoEmCurso = item.data.ano;
    this.updateAnoCaptions();
  }

  private async _processar(): Promise<void> {
    const response: HttpResponse<IJsonDfSVAT> = await this._svatService.getDfSvat(this.anoEmCurso);
    this.builder.setup();
    this.responseToView(response);
  }

  private async _loadAi(): Promise<void> {
    const year: number = this.paramsAi.year;
    const yearFound: IPlToolbarMenuItemDefinition<IJsonEmpresaAno> = this._dropdownAnos.menu.find((item: IPlToolbarMenuItemDefinition<IJsonEmpresaAno>) => item.data.ano === year);
    if (!yearFound) {
      this._plAlertService.info(this._plTranslateService.translate('svat.messages.yearDoesntExist', {year: year}));
      return;
    }
    this._changeYear(yearFound);
    await this._processar();
  }
}
