<div class="contabilidade-predefinidos-preview" *ngIf="predefinido">
  <div class="card">
    <div class="card-header">
      <div class="card-title contabilidade-predefinidos-preview-header p-0">
        <div class="d-flex align-items-center gap-2">
          <div class="contabilidade-predefinidos-preview-identifier">
            <strong><span [translate]="'predefinidoscontabilidade.fields.preDefinidosID'"></span>:</strong>&nbsp;<span>{{ predefinido.preDefinidosID }}</span>
          </div>

          <div class="contabilidade-predefinidos-preview-description">
            <strong><span [translate]="'predefinidoscontabilidade.fields.description'"></span>:</strong>&nbsp;<span>{{ predefinido.descricao }}</span>
          </div>

          <div class="contabilidade-predefinidos-preview-header-actions d-flex align-items-center gap-2">
            <button type="button" class="btn btn-sm btn-warning action-edit" [click]="editarPreDefinido" plPromise><i cgcIcon="fa-edit"></i>&nbsp;<span [translate]="'global.btn.edit'"></span></button>

            <button type="button" class="btn btn-sm btn-danger action-delete" [click]="apagarPreDefinido" plPromise><i cgcIcon="fa-trash"></i>&nbsp;<span [translate]="'entity.action.delete'"></span></button>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body pt-0">
      <div class="card-body-cabecalho mb-3">
        <predefinidocontabcab [predefinido]="predefinido" [previewOnly]="true"></predefinidocontabcab>
      </div>

      <div class="card-body-linhas">
        <predefinidocontablinhas [predefinido]="predefinido" [previewOnly]="true"></predefinidocontablinhas>
      </div>
    </div>
  </div>
</div>
