<div class="modelo10-config-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h5 class="modal-title" [translate]="'tabelasIRS.title'" [translateParams]="{nTabIRS: model.nTabIrs}"></h5>
  </div>

  <div class="modal-body">
    <pl-form [properties]="{disabled: true, orientation: 'vertical', validators: {required: {value: true}}}">
      <pl-group>
        <label [translate]="'tirs.fields.nTabIrs'"></label>
        <edit>
          <pl-edit type="text" attrName="nTabIrs" [(model)]="model.nTabIrs" [properties]="{validators: {maxlength: {value: 4}}}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'tirs.fields.designaBreve'"></label>
        <edit>
          <pl-edit type="text" attrName="designaBreve" [(model)]="model.designaBreve"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'tirs.fields.designaCompl'"></label>
        <edit>
          <pl-edit type="text" attrName="designaCompl" [(model)]="model.designaCompl"></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <pl-group>
          <label [translate]="'tirs.fields.dtulTabela'"></label>
          <edit>
            <pl-edit type="date" attrName="dataVigor" [(model)]="model.dataVigor"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'tirs.fields.datasTaxasIrs'"></label>
          <edit>
            <pl-edit-select
              attrName="currentDataTaxaIrs"
              [source]="datasEscaloes"
              [model]="currentDataTaxaIrs"
              (modelChange)="dateDropDownChanged($event)"
              [properties]="{disabled: false, disallowClear: true, select: {valueProp: 'value', labelProp: 'label'}}">
            </pl-edit-select>
          </edit>
        </pl-group>
      </pl-group>

      <div class="tirs-table text-nowrap mt-3">
        <h6 [translate]="'tirs.table.title'"></h6>
        <dx-data-grid [cgDxDataGrid]="datagridEscaloesIRS" cgDxDataGridInstanceName="escaloesIRS" [dataSource]="datagridEscaloesIRSSource" (onInitialized)="onDataGridInitializedEscaloesIRS($event)">
        </dx-data-grid>
      </div>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
