import {Component, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {IJsonGestaoComunicacoesMensagem} from '../../../modules/portalcontabilidade/gestaocomunicacoes/jsonGestaoComunicacoesMensagem.module.interface';
import {downloadStream, EMouseEventButton, IPlDynamicVisualsSecondaryClickAction, isEmpty, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {GestaoComunicacoesTopicoService} from '../../../modules/portalcontabilidade/gestaocomunicacoes/services/gestaoComunicacoesTopico.module.service';
import moment from 'moment';
import {convertFileNameToFileData, EGestaoComunicacoesFileType, IGestaoComunicacoesFileData} from '../../../modules/portalcontabilidade/gestaocomunicacoes/gestaoComunicacoes.module.interface';
import {DevExpressDataGridUIService} from '../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {NgbDropdown, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {CGModalService} from '../../cg/modal/cgmodal.service';
import {EContabilidadeDigitalActivateLicenseType} from '../../arquivodigital/modals/activatelicense/contabilidadedigital.activatelicensemodal.component.interface';
import {firstValueFrom, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {DocsDigitaisTreeDocsNaoClassificadosModalComponent} from '../../../modules/contabilidadedigital/documentosdigitais/modals/treedocsnaoclassificados/docsDigitais.treeDocsNaoClassificados.modal.component';
import {ContabilidadeDigitalUIService} from '../../../services/contabilidadedigital/contabilidadedigital.ui.service';
import {ICGConfigurations} from '../../../services/config/config.service.interface';
import {ConfigService} from '../../../services/config/config.service';
import {IContabilidadeDigitalTreeViewerFolder} from '../../arquivodigital/common/treeviewer/contabilidadedigital.treeviewer.component.interface';
import {CGPdfModalComponent} from '../../cg/modal/pdf/cg.pdf.modal.component';
import {HttpResponse} from '@angular/common/http';

const TIME_FORMAT = 'HH:mm';

@Component({
  selector: 'cg-message-topico',
  templateUrl: './messageTopico.component.html'
})
export class CGMessageTopicoComponent implements OnInit, OnDestroy {
  @Input() public mensagemObj: IJsonGestaoComunicacoesMensagem;
  @Input() public contabilista: boolean;

  public readonly eFileTypes: typeof EGestaoComunicacoesFileType;

  public dateShow: string;
  public fileFormated: IGestaoComunicacoesFileData;
  public modoCGOn: boolean;
  public temContabilidadeDigital: boolean;
  public clientConnectMode: boolean;
  public documentoAdicionadoContabilidade: boolean;
  public validMimeTypeAdicionarContabilidade: boolean;

  private readonly _subscriptionConfigurations: Subscription;
  private readonly _cgStoreUrl: string;

  private _dropdown: NgbDropdown;
  private _contabDigitalCGOnAllowAddFiles: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _gestaoComunicacoesTopicoService: GestaoComunicacoesTopicoService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _cgModalService: CGModalService,
    private readonly _contabilidadeDigitalUIService: ContabilidadeDigitalUIService,
    private readonly _configService: ConfigService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    this.eFileTypes = EGestaoComunicacoesFileType;
    this.clientConnectMode = false;
    this._cgStoreUrl = '';

    this._subscriptionConfigurations = this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      this.temContabilidadeDigital = configurations.empresa.temContabilidadeDigital;
      this.modoCGOn = configurations.licenca.modoCGOn;
      this._contabDigitalCGOnAllowAddFiles = configurations.empresa.cgon.allowAddFiles;
    });
  }

  public ngOnInit(): void {
    this.dateShow = moment(this.mensagemObj.stampCreated).format(TIME_FORMAT);
    if (!this.mensagemObj.isMessage) {
      this.fileFormated = convertFileNameToFileData(this.mensagemObj.fileName, this.mensagemObj.tamanho, this.mensagemObj.mimeType);
      this._generateIsValidMimeTypeAdicionarContabilidade();
    } else {
      this.mensagemObj.message = this.mensagemObj.message.replace(/\n/g, '<br>');
    }
    this._evaluateDocumentoAdicionado();
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigurations.unsubscribe();
  }

  public async onClickDownloadFile(): Promise<void> {
    await this._gestaoComunicacoesTopicoService.downloadFile(this.mensagemObj.comTopicoId, this.mensagemObj.docId).then((response: HttpResponse<Blob>) => {
      downloadStream(response, this.mensagemObj.fileName);
    });
  }

  public async onRightClick(event: MouseEvent): Promise<void> {
    if (this._dropdown.isOpen()) {
      this._dropdown.close();
    }
    event.preventDefault();
    if (event.button === EMouseEventButton.Secondary) {
      await this._openDropdownActions(event);
    }
  }

  public async onClick(event: MouseEvent): Promise<void> {
    event.preventDefault();
    if (event.button === EMouseEventButton.Main) {
      if (!this.mensagemObj.isMessage) {
        if (this.fileFormated.extension === EGestaoComunicacoesFileType.PDF) {
          const modalInstance = this._cgModalService.showVanilla(CGPdfModalComponent, {size: 'lg'});
          const componentInstance: CGPdfModalComponent = modalInstance.componentInstance;
          componentInstance.title = this.mensagemObj.fileName;
          componentInstance.pdfUrl = await this._gestaoComunicacoesTopicoService.getUrl(this.mensagemObj.comTopicoId, this.mensagemObj.docId);
          await modalInstance.result;
        } else {
          await this._openDropdownActions(event);
        }
      }
    }
  }

  public async addDocumentToContabilidade(): Promise<void> {
    const validLicense: boolean = await this._contabilidadeDigitalUIService.checkConsumeLicense(EContabilidadeDigitalActivateLicenseType.ContabilidadeDigital);
    if (validLicense) {
      const empresaTemPastasCriadas: boolean = await firstValueFrom(this._contabilidadeDigitalUIService.configs.empresaTemPastasCriadas().pipe(take(1)));
      if (empresaTemPastasCriadas !== true) {
        await this._contabilidadeDigitalUIService.checkFoldersCreated();
      }
      if (!this.modoCGOn || this._contabDigitalCGOnAllowAddFiles) {
        const modalInstance: NgbModalRef = this._cgModalService.showVanilla(DocsDigitaisTreeDocsNaoClassificadosModalComponent, {size: 'lg'});
        const componentInstance: DocsDigitaisTreeDocsNaoClassificadosModalComponent = modalInstance.componentInstance;
        componentInstance.anoEmCursoIRC = true;

        const {folderID}: IContabilidadeDigitalTreeViewerFolder = await modalInstance.result;
        this.mensagemObj.folder = (await this._gestaoComunicacoesTopicoService.moveToDigital(this.mensagemObj.comTopicoId, this.mensagemObj.docId, folderID)).body.folder;
        this._evaluateDocumentoAdicionado();
        this._generateIsValidMimeTypeAdicionarContabilidade();

        this._plAlertService.success('arquivodigital.gestaodocsdigitalizados.messages.modal.adicionardocumentos.success');
      } else {
        this._plAlertService.error(this._plTranslateService.translate('arquivodigital.gestaodocsdigitalizados.errors.addFilesDisallowed', {href: this._cgStoreUrl}));
      }
    }
  }

  @ViewChild('dropdown')
  public set dropdown(value: NgbDropdown) {
    this._dropdown = value;
  }

  private _generateContextMenuActions(): Array<IPlDynamicVisualsSecondaryClickAction> {
    const list: Array<IPlDynamicVisualsSecondaryClickAction> = [
      {
        caption: 'gestaoComunicacoes.messageFile.downloadFile',
        icon: 'fa fa-fw fa-download',
        click: () => this.onClickDownloadFile()
      }
    ];

    if (this.contabilista && this._evaluateValidMimeTypeAdicionarContabilidade(this.fileFormated.extension)) {
      list.push({
        caption: 'gestaoComunicacoes.messageFile.addToContab',
        icon: 'fa fa-fw fa-plus',
        disabled: this.documentoAdicionadoContabilidade,
        click: async () => {
          await this.addDocumentToContabilidade();
        }
      });
    }
    return list;
  }

  private _evaluateDocumentoAdicionado(): void {
    this.documentoAdicionadoContabilidade = !isEmpty(this.mensagemObj.folder);
  }

  private async _openDropdownActions(event: MouseEvent): Promise<void> {
    const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateContextMenuActions();
    await this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.target, actions, {cssClass: 'message-file-dropdown-menu'});
  }

  private _generateIsValidMimeTypeAdicionarContabilidade(): void {
    this.validMimeTypeAdicionarContabilidade = this._evaluateValidMimeTypeAdicionarContabilidade(this.fileFormated.extension);
  }

  private _evaluateValidMimeTypeAdicionarContabilidade(extension: EGestaoComunicacoesFileType): boolean {
    return (
      extension === EGestaoComunicacoesFileType.PDF || extension === EGestaoComunicacoesFileType.JPEG || extension === EGestaoComunicacoesFileType.JPG || extension === EGestaoComunicacoesFileType.PNG
    );
  }
}
