import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {UntypedFormGroup} from '@angular/forms';
import {IPlEditAutocompleteEvtSelected, isObject, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_PERFIL_CATEGORIA_ABONO, ENTITY_NAME_PERFIL_CATEGORIA_DESCONTO} from '../../../../modules/perfilcategoriaabdes/perfilCategoriaAbDes.module.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {ENTITY_NAME_ABDES, ENTITY_NAME_ABONOS, ENTITY_NAME_DESCONTOS, ETipoAbonoDesconto, ETipoCategoriaABDES, IABDES, IABDESEntityService, ICRABDPerfil} from '../../abdes.entity.interface';
import {ETipoCategoriaDesc} from '../../../../datasources/tipocategoriadesc/tipoCategoriaDesc.datasource.interface';
import {ETipoCategoria} from '../../../../datasources/tipocategoria/tipoCategoria.datasource.interface';
import {IEntityService} from '../../../../services/entity/entity.service.interface';
import {IAbDesCrabd, IJsonABDES, IJsonPerfilSistemaAbdes} from '../../jsonABDES.entity.interface';
import {IJsonPerfilCategoriaAbono, IJsonPerfilCategoriaDesconto} from '../../../../modules/perfilcategoriaabdes/jsonPerfilCategoriaAbDes.module.interface';
import {IJsonCRABD} from '../../../crabd/jsonCrAbD.entity.interface';
import {ETipoExcecaoCRABD} from '../../../../datasources/tipoexcecaocrabd/tipoExcecaoCRABD.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_ABONO_AGRUPADO, ETipoAbonoAgrupado} from '../../../../datasources/tipoabonoagrupado/tipoabonoagrupado.datasource.interface';
import {DATA_SOURCE_NAME_TIPO_DESCONTO_AGRUPADO, ETipoDescontoAgrupado} from '../../../../datasources/tipodescontoagrupado/tipodescontoagrupado.datasource.interface';
import {ETipoEntSuporteABDES} from '../../../../datasources/tipoentsuporteabdes/tipoEntSuporteABDES.datasource.interface';
import {IEntityAutocompleteEventSelected} from '../../../../components/cg/autocomplete/entity.autocomplete.component.interface';
import {IJsonGrAbono} from '../../../grabonos/jsonGrAbonos.entity.interface';
import {IJsonGrDesconto} from '../../../grdescontos/jsonGrDescontos.entity.interface';
import {ENTITY_NAME_GR_ABONOS} from '../../../grabonos/grAbonos.entity.interface';
import {ENTITY_NAME_GR_DESCONTOS} from '../../../grdescontos/grDescontos.entity.interface';

@Component({
  selector: 'generate-abdes',
  templateUrl: './generateAbdes.modal.component.html'
})
export class GenerateAbdesModalComponent extends CGModalComponent<IAbDesCrabd> implements OnInit {
  @Input() public abono: boolean;
  @Input() public tipoConfigAbDescPreConfigurado: boolean;

  public readonly perfilSistemaAbdesTemplate: string;

  public abdesGeneratedModel: IAbDesCrabd;
  public formPerfil: UntypedFormGroup;
  public categoria: ETipoCategoria | ETipoCategoriaDesc;
  public tipo: ETipoAbonoAgrupado | ETipoDescontoAgrupado;
  public perfilAbDes: IJsonPerfilCategoriaAbono | IJsonPerfilCategoriaDesconto;
  public listaPerfilSistemaAbdes: Array<IJsonPerfilSistemaAbdes>;
  public selectedPerfilSistemaAbdes: IJsonPerfilSistemaAbdes;
  public selectedAbdes: IABDES;
  public ausencia: number;
  public nome: string;
  public descricao: string;
  public titleAbnDesc: string;
  public percAumentHorExtraVisible: boolean;
  public valorLimiteIncVisible: boolean;
  public valorUnit: number;
  public percAumentHorExtra: number;
  public valorUnitVisible: boolean;
  public valorLimiteInc: number;
  public ausenciaVisible: boolean;
  public disableBtnGerar: boolean;
  public isPerfilSistema: boolean;
  public tipoAbonoAgrupadoType: string;
  public codAbDescEntity: string;

  private readonly _perfilAbonoService: IEntityService<IJsonPerfilCategoriaAbono>;
  private readonly _perfilDescontoService: IEntityService<IJsonPerfilCategoriaDesconto>;
  private readonly _perfilABDESService: IABDESEntityService;
  private readonly _grAbonosService: IEntityService<IJsonGrAbono>;
  private readonly _grDescontosService: IEntityService<IJsonGrDesconto>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this._perfilAbonoService = this._entityServiceBuilder.build(ENTITY_NAME_PERFIL_CATEGORIA_ABONO);
    this._perfilDescontoService = this._entityServiceBuilder.build(ENTITY_NAME_PERFIL_CATEGORIA_DESCONTO);
    this._perfilABDESService = this._entityServiceBuilder.build<IJsonABDES, IABDESEntityService>(ENTITY_NAME_ABDES);
    this._grAbonosService = this._entityServiceBuilder.build(ENTITY_NAME_GR_ABONOS);
    this._grDescontosService = this._entityServiceBuilder.build(ENTITY_NAME_GR_DESCONTOS);
    this.perfilSistemaAbdesTemplate = '{{designaBreve}}';
    this.perfilAbDes = {
      nCategoriaAbDes: 0,
      tipoArredondamento: 0,
      tipoRecolha: 0,
      unidadeDefeito: 0,
      nomeUnidadeDefeito: '',
      tipoRendimento: '',
      codRem: '',
      nomeCodRem: '',
      nContaDebitoEmp: '',
      nContaCreditoEmp: '',
      nContaDebitoPatronato: '',
      nContaCreditoPatronato: '',
      cruzamentos: [],
      grupo: 0,
      nomeGrupo: '',
      nomeTipoRendimento: '',
      nHorasDiaProvDesc: 0,
      contribuiCalculoCustoPrecoH: false
    };
    this.ausencia = 0;
    this.valorUnit = 0;
    this.valorLimiteInc = 0;
    this.percAumentHorExtra = 0;
    this.disableBtnGerar = true;
    this.listaPerfilSistemaAbdes = [];
    this.selectedPerfilSistemaAbdes = undefined;
    this.selectedAbdes = undefined;
    this.tipoConfigAbDescPreConfigurado = true;
    this.percAumentHorExtraVisible = false;
    this.valorLimiteIncVisible = false;
    this.valorUnitVisible = false;
    this.ausenciaVisible = false;
    this.isPerfilSistema = false;
  }

  public ngOnInit(): void {
    this.nome = '';
    this.descricao = '';
    this.titleAbnDesc = this.abono ? this._plTranslateService.translate('generateAbdes.abono') : this._plTranslateService.translate('generateAbdes.desconto');
    this.tipoAbonoAgrupadoType = this.abono ? DATA_SOURCE_NAME_TIPO_ABONO_AGRUPADO : DATA_SOURCE_NAME_TIPO_DESCONTO_AGRUPADO;
    this.codAbDescEntity = this.abono ? ENTITY_NAME_ABONOS : ENTITY_NAME_DESCONTOS;

    if (this.tipoConfigAbDescPreConfigurado) {
      this._tipoChange(0);
    }
  }

  public readonly fnTipoChange = (value: ETipoAbonoAgrupado | ETipoDescontoAgrupado): Promise<void> => this._tipoChange(value);

  public readonly fnPerfilSistemaAbdesChanged = (value: IPlEditAutocompleteEvtSelected<IJsonPerfilSistemaAbdes>): Promise<void> => this._perfilSistemaAbdesChanged(value);

  public readonly fnCodAbDescChanged = (value: IEntityAutocompleteEventSelected): Promise<void> => this._codAbDescChanged(value);

  public readonly fnGenerateABDES = (): Promise<void> => this._generateABDES();

  private async _tipoChange(value: ETipoAbonoAgrupado | ETipoDescontoAgrupado): Promise<void> {
    this.tipo = value;
    this.listaPerfilSistemaAbdes = await this._perfilABDESService.listaPerfilSistemaByTipo(this.abono, this.tipo).then((response: HttpResponse<Array<IJsonPerfilSistemaAbdes>>) => response.body);
    if (this.tipo === ETipoAbonoAgrupado.ND || this.tipo === ETipoDescontoAgrupado.NA) {
      this.listaPerfilSistemaAbdes.push(this._emptyPerfilSistemaAbdes());
    }
    return this._perfilSistemaAbdesChanged({inputValue: '', item: undefined});
  }

  private _perfilSistemaAbdesChanged(value: IPlEditAutocompleteEvtSelected<IJsonPerfilSistemaAbdes>): Promise<void> {
    this._resetFormFields();

    if (value?.item && isObject(value.item)) {
      this.selectedPerfilSistemaAbdes = value.item;
      this.valorLimiteInc = this.selectedPerfilSistemaAbdes.valorLimiteInc;
      this.isPerfilSistema = this.selectedPerfilSistemaAbdes.perfilSistema && this.selectedPerfilSistemaAbdes.perfilSistema > 0;
      if (this.abono) {
        if (this.selectedPerfilSistemaAbdes.categoriaAbono === ETipoCategoria.HORA_EXTRA) {
          this.percAumentHorExtraVisible = true;
        } else if (this.selectedPerfilSistemaAbdes.categoriaAbono === ETipoCategoria.AJUDA_CUSTO) {
          this.valorLimiteIncVisible = true;
          this.valorUnitVisible = true;
          this.valorLimiteInc = this.selectedPerfilSistemaAbdes.valorLimiteInc;
        } else if (
          this.selectedPerfilSistemaAbdes.categoriaAbono === ETipoCategoria.SUB_REFEICAO ||
          this.selectedPerfilSistemaAbdes.categoriaAbono === ETipoCategoria.TicketValesRefeicao ||
          this.selectedPerfilSistemaAbdes.categoriaAbono === ETipoCategoria.DespesasTeletrabalho
        ) {
          this.valorLimiteIncVisible = true;
          this.valorLimiteInc = this.selectedPerfilSistemaAbdes.valorLimiteInc;
        }
      } else if (this.selectedPerfilSistemaAbdes.categoriaDesconto === ETipoCategoriaDesc.Falta) {
        this.ausenciaVisible = true;
      }
    } else {
      this.selectedPerfilSistemaAbdes = undefined;
    }
    return Promise.resolve();
  }

  private async _generateABDESBySelectedPerfilSistemaAbdes(): Promise<void> {
    if (this.tipoConfigAbDescPreConfigurado && !this.selectedPerfilSistemaAbdes) {
      const myPerfilAbDesc = this._plTranslateService.translate('generateAbdes.fields.perfilAbDesc', {abonoDesconto: this.titleAbnDesc});
      this._plAlertService.error(this._plTranslateService.translate('generateAbdes.perfilNotSelected', {perfilAbDesc: myPerfilAbDesc}));
    }

    if (this.abono) {
      const categoriaAbono = this.selectedPerfilSistemaAbdes.categoriaAbono;
      if (categoriaAbono) {
        const perfilAbono: HttpResponse<IJsonPerfilCategoriaAbono> = await this._perfilAbonoService.get({id: categoriaAbono});
        this.perfilAbDes = <IJsonPerfilCategoriaAbono & IJsonPerfilCategoriaDesconto>perfilAbono.body;
      }
    } else {
      const categoriaDesconto = this.selectedPerfilSistemaAbdes.categoriaDesconto;
      if (categoriaDesconto) {
        const perfilDesconto: HttpResponse<IJsonPerfilCategoriaDesconto> = await this._perfilDescontoService.get({id: `${categoriaDesconto}/${this.ausencia}`});
        this.perfilAbDes = <IJsonPerfilCategoriaAbono & IJsonPerfilCategoriaDesconto>perfilDesconto.body;
      }
    }

    if (this.isPerfilSistema) {
      this.perfilAbDes.tipoRendimento = this.selectedPerfilSistemaAbdes.tipRendimento;
      this.perfilAbDes.nomeTipoRendimento = this.selectedPerfilSistemaAbdes.nomeTipoRendimento;

      this.perfilAbDes.codRem = this.selectedPerfilSistemaAbdes.codSSCol;
      this.perfilAbDes.nomeCodRem = this.selectedPerfilSistemaAbdes.nomeCodRem;

      this.perfilAbDes.tipoArredondamento = this.selectedPerfilSistemaAbdes.tipArredond;

      if (this.abono) {
        for (const cruzamento of this.perfilAbDes.cruzamentos) {
          if (cruzamento.tipoCategoria === ETipoCategoriaABDES.IRS) {
            cruzamento.nCodDesconto = this.selectedPerfilSistemaAbdes.crabdIRS ? this.selectedPerfilSistemaAbdes.nCodDescontoIrs : '';
            cruzamento.entSuporteEmpregado = this.selectedPerfilSistemaAbdes.crabdIRS ? this.selectedPerfilSistemaAbdes.crabdIRS : false;
          }

          if (cruzamento.tipoCategoria === ETipoCategoriaABDES.SegSocial) {
            cruzamento.nCodDesconto = this.selectedPerfilSistemaAbdes.crabdSSEmpregado || this.selectedPerfilSistemaAbdes.crabdSSPatronato ? this.selectedPerfilSistemaAbdes.nCodDescontoSS : '';
            cruzamento.entSuporteEmpregado =
              this.selectedPerfilSistemaAbdes.crabdSSEmpregado || this.selectedPerfilSistemaAbdes.crabdSSPatronato ? this.selectedPerfilSistemaAbdes.crabdSSEmpregado : false;
            cruzamento.entSuportePatronato =
              this.selectedPerfilSistemaAbdes.crabdSSEmpregado || this.selectedPerfilSistemaAbdes.crabdSSPatronato ? this.selectedPerfilSistemaAbdes.crabdSSPatronato : false;
          }
        }
      }
    }

    const abdesc: IJsonABDES = {
      codSSCol: this.perfilAbDes.codRem,
      nCodABDESC: '0',
      codAbDesc: '0',
      abonoDesc: this.abono ? ETipoAbonoDesconto.Abono : ETipoAbonoDesconto.Desconto,
      tipoRendimento: this.perfilAbDes.tipoRendimento,
      grupoAbono: this.abono ? this.perfilAbDes.grupo : 0,
      grupoDesconto: !this.abono ? this.perfilAbDes.grupo : 0,
      contaEncCRE: this.perfilAbDes.nContaCreditoPatronato,
      contaEncDEB: this.perfilAbDes.nContaDebitoPatronato,
      contaMovCred: this.perfilAbDes.nContaCreditoEmp,
      contaMovDeb: this.perfilAbDes.nContaDebitoEmp,
      grupoAbonoNome: this.abono ? this.perfilAbDes.nomeGrupo : '',
      tipoValorUnitRecolha: this.perfilAbDes.tipoRecolha,
      calcCustHora: this.abono ? (<IJsonPerfilCategoriaAbono>this.perfilAbDes).contribuiCalculoCustoPrecoH : false,
      codCGASit: '',
      procNatal: this.isPerfilSistema ? this.selectedPerfilSistemaAbdes.procNatal : 0,
      procFerias: this.isPerfilSistema ? this.selectedPerfilSistemaAbdes.procFerias : 0,
      mapaSindic: 0,
      mapaSeguro: 0,
      horaDiaValor: 1,
      nHorasDiaProvDesc: 0,
      grupoDescontoNome: !this.abono ? this.perfilAbDes.nomeGrupo : '',
      valorLimIncidencia: this.valorLimiteInc,
      naoImpDecIRS114: this.isPerfilSistema ? this.selectedPerfilSistemaAbdes.naoImpDecIRS114 : 0,
      tipoArredondamento: this.perfilAbDes.tipoArredondamento,
      valorFixo: this.valorUnit,
      designacaoCompleta: this.descricao,
      designacaoBreve: this.nome,
      abonoDescDesativado: false,
      codRubrOrcAbon: '',
      codRubrOrcEnc: '',
      coefHExtra: this.percAumentHorExtra,
      colMapaCX: 0,
      colQuadroP: 0,
      diaHoraExtra: 0,
      entiSuporte: ETipoEntSuporteABDES.Empregado,
      nHorasDescontoProporcional: 0,
      percInciSegSocial: this.isPerfilSistema ? this.selectedPerfilSistemaAbdes.percInciSegSocial : 0,
      percLimValorHExtra: 0,
      perctDescSeguro: 0,
      tipoDescontoProporcional: 0,
      tipoHoraExtr: 0,
      usaSPReducaoRemuneratoria: false,
      perfilSistema: this.isPerfilSistema ? this.selectedPerfilSistemaAbdes.perfilSistema : 0
    };

    this.abdesGeneratedModel = {
      abdesc: abdesc,
      cruzamentos: [],
      perfilSistemaAbdes: this.selectedPerfilSistemaAbdes
    };

    return Promise.resolve();
  }

  private async _generateABDESBySelectedAbdes(): Promise<void> {
    if (!this.tipoConfigAbDescPreConfigurado && !this.selectedAbdes) {
      this._plAlertService.error(this._plTranslateService.translate('generateAbdes.codigoAbonoDescontoNotSelected', {abonoDesconto: this.titleAbnDesc}));
    }

    if (this.abono) {
      const categoriaAbono = this.selectedAbdes.categoriaAbono;
      const perfilAbono: HttpResponse<IJsonPerfilCategoriaAbono> = await this._perfilAbonoService.get({id: categoriaAbono});
      this.perfilAbDes = perfilAbono.body;
    } else {
      const categoriaDesconto = this.selectedAbdes.categoriaDesconto;
      const perfilDesconto: HttpResponse<IJsonPerfilCategoriaDesconto> = await this._perfilDescontoService.get({id: `${categoriaDesconto}/${this.ausencia}`});
      this.perfilAbDes = perfilDesconto.body;
    }

    let myPerfilSistemaAbdes: IJsonPerfilSistemaAbdes;
    if (this.selectedAbdes.perfilSistema && this.selectedAbdes.perfilSistema > 0) {
      await this._perfilABDESService.getPerfilSistema(this.selectedAbdes.perfilSistema).then((response: HttpResponse<IJsonPerfilSistemaAbdes>) => {
        myPerfilSistemaAbdes = response.body;
      });
    }

    this.abdesGeneratedModel = {
      abdesc: {
        ...this.selectedAbdes,
        designacaoBreve: this.nome,
        designacaoCompleta: this.descricao,
        valorLimIncidencia: this.valorLimiteInc,
        valorFixo: this.valorUnit,
        coefHExtra: this.percAumentHorExtra
      },
      cruzamentos: [],
      perfilSistemaAbdes: myPerfilSistemaAbdes
    };

    return Promise.resolve();
  }

  private async _generateABDES(): Promise<void> {
    if (!this.formPerfil.valid) {
      this._plAlertService.error('generateAbdes.formPerfilInvalid');
    }

    if (this.tipoConfigAbDescPreConfigurado) {
      await this._generateABDESBySelectedPerfilSistemaAbdes();
    } else {
      await this._generateABDESBySelectedAbdes();
    }

    if (this.abono) {
      return this._perfilAbonoService.get<string>({id: `${-1}/generatecodabono`}).then((response: HttpResponse<string>) => {
        this.abdesGeneratedModel.abdesc.calcCustHora = (<IJsonPerfilCategoriaAbono>this.perfilAbDes).contribuiCalculoCustoPrecoH;
        this.abdesGeneratedModel.abdesc.codAbDesc = response.body;
        this.abdesGeneratedModel.abdesc.nCodABDESC = String(ETipoAbonoDesconto.Abono) + response.body;
        this.abdesGeneratedModel.cruzamentos = this._crabdPerfilToCrabd(this.perfilAbDes.cruzamentos, this.abdesGeneratedModel.abdesc.nCodABDESC, this.abdesGeneratedModel.abdesc.codAbDesc);
        super.close(this.abdesGeneratedModel);
      });
    }
    return this._perfilDescontoService.get<string>({id: `${-1}/generatecoddesconto`}).then((response: HttpResponse<string>) => {
      this.abdesGeneratedModel.abdesc.nHorasDiaProvDesc = (<IJsonPerfilCategoriaDesconto>this.perfilAbDes).nHorasDiaProvDesc;
      this.abdesGeneratedModel.abdesc.codAbDesc = response.body;
      this.abdesGeneratedModel.abdesc.nCodABDESC = String(ETipoAbonoDesconto.Desconto) + response.body;
      this.abdesGeneratedModel.cruzamentos = this._crabdPerfilToCrabd(this.perfilAbDes.cruzamentos, this.abdesGeneratedModel.abdesc.nCodABDESC, this.abdesGeneratedModel.abdesc.codAbDesc);
      super.close(this.abdesGeneratedModel);
    });
  }

  private async _codAbDescChanged(value: IEntityAutocompleteEventSelected): Promise<void> {
    this._resetFormFields();

    if (value?.item && isObject(value.item)) {
      this.selectedAbdes = <IABDES>value.item;
      if (this.abono) {
        await this._grAbonosService.get({id: this.selectedAbdes.grupoAbono}).then((response) => {
          this.selectedAbdes.categoriaAbono = response.body.categoria;

          switch (this.selectedAbdes.categoriaAbono) {
            case ETipoCategoria.HORA_EXTRA: {
              this.percAumentHorExtraVisible = true;
              this.percAumentHorExtra = this.selectedAbdes.coefHExtra;
              break;
            }
            case ETipoCategoria.AJUDA_CUSTO: {
              this.valorLimiteIncVisible = true;
              this.valorUnitVisible = true;
              this.valorLimiteInc = this.selectedAbdes.valorLimIncidencia;
              break;
            }
            case ETipoCategoria.SUB_REFEICAO || ETipoCategoria.TicketValesRefeicao || ETipoCategoria.DespesasTeletrabalho: {
              this.valorLimiteIncVisible = true;
              this.valorLimiteInc = this.selectedAbdes.valorLimIncidencia;
              break;
            }
            default:
              break;
          }
        });
      } else {
        await this._grDescontosService.get({id: this.selectedAbdes.grupoDesconto}).then((response) => {
          this.selectedAbdes.categoriaDesconto = response.body.categoria;
          if (this.selectedAbdes.categoriaDesconto === ETipoCategoriaDesc.Falta) {
            this.ausenciaVisible = true;
          }
        });
      }
    } else {
      this.selectedAbdes = undefined;
      this._plAlertService.error(this._plTranslateService.translate('generateAbdes.codigoAbonoDescontoNotSelected', {abonoDesconto: this.titleAbnDesc}));
    }
    return Promise.resolve();
  }

  private _crabdPerfilToCrabd(crabdPerfilList: Array<ICRABDPerfil>, nCodOrigem: string, codOrigem: string): Array<IJsonCRABD> {
    const crabdList: Array<IJsonCRABD> = [];
    const tipoAbdescDestino = this.abono ? ETipoAbonoDesconto.Desconto : ETipoAbonoDesconto.Abono;
    for (const crabdPerfil of crabdPerfilList) {
      crabdList.push({
        tipoAbDescOrigem: this.categoria,
        codOrigem: codOrigem,
        nCodOrigem: nCodOrigem,
        tipoAbDescDestino: tipoAbdescDestino,
        codDestino: crabdPerfil.nCodDesconto,
        nCodDestino: crabdPerfil.nCodDesconto,
        entSuporteEmpregado: crabdPerfil.entSuporteEmpregado,
        entSuportePatronato: crabdPerfil.entSuportePatronato,
        incidenciaEmpregado: crabdPerfil.entSuporteEmpregadoIncidencia,
        incidenciaPatronato: crabdPerfil.entSuportePatronatoIncidencia,
        tipoExcecao: ETipoExcecaoCRABD.SemExcecoes,
        categoriaAbDescDestino: crabdPerfil.tipoCategoria,
        siglaCategoriaAbDescDestino: '',
        siglaTipoAbdesDestino: '',
        nomeExcecao: '',
        nomeABDESDestino: crabdPerfil.descricao,
        nomeCategoria: crabdPerfil.nomeCategoria
      });
    }
    return crabdList;
  }

  private _emptyPerfilSistemaAbdes(): IJsonPerfilSistemaAbdes {
    return {
      perfilSistema: 0,
      abonoDESC: this.abono ? ETipoAbonoDesconto.Abono : ETipoAbonoDesconto.Abono,
      nCodAbDesc: '',
      designaBreve: this._plTranslateService.translate('generateAbdes.fields.semPerfil'),
      grupoAbono: 0,
      grupoDesconto: 0,
      tipRendimento: '',
      tipArredond: undefined,
      procNatal: 0,
      procFerias: 0,
      naoImpDecIRS114: undefined,
      codSSCol: '',
      percInciSegSocial: 0,
      valorLimiteInc: 0,
      calcCustHora: 0,
      crabdIRS: false,
      crabdSSEmpregado: false,
      crabdSSPatronato: false,
      nCodDescontoIrs: '',
      nCodDescontoSS: '',
      categoriaAbono: undefined,
      categoriaDesconto: undefined,
      bloqueiaLimitesIncidencia: false,
      nomeCodRem: '',
      nomeTipoRendimento: ''
    };
  }

  private _resetFormFields(): void {
    this.percAumentHorExtraVisible = false;
    this.valorLimiteIncVisible = false;
    this.valorUnitVisible = false;
    this.ausenciaVisible = false;
    this.isPerfilSistema = false;

    this.nome = '';
    this.descricao = '';
    this.ausencia = 0;
    this.valorUnit = 0;
    this.valorLimiteInc = 0;
    this.percAumentHorExtra = 0;
  }
}
