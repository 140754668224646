<pl-form>
  <div class="calculo-diferimentos-config-modal">
    <div class="modal-header">
      <button type="button" class="btn-close" [disabled]="closeDisabled" (click)="dismiss()"></button>
      <h5 class="modal-title" [translate]="'calculodiferimentos.modals.config.title'"></h5>
    </div>

    <div class="modal-body">
      <div class="d-flex gap-1">
        <fieldset>
          <legend [translate]="'calculodiferimentos.modals.config.cardrendimentos'"></legend>

          <div>
            <dx-data-grid
              [cgDxDataGrid]="dataGridDefinitionRadicaisRendimentos"
              cgDxDataGridInstanceName="radicaisRendimentos"
              [dataSource]="this.custDif.rendimentosRadicais"
              (onInitialized)="onInitializedRendimentos($event)">
              <div *dxTemplate="let cellInfo of 'editCellAcrescimos'">
                <entity-autocomplete
                  entity="pocs"
                  attrName="contaDep"
                  [selectedKey]="cellInfo.data.nContaAcrescimos"
                  (selectedKeyChange)="changedCellContaAcrescimos(cellInfo, $event)"
                  [properties]="{allowInvalid: false}"
                  [filter]="filterMovimento"
                  output="key">
                </entity-autocomplete>
              </div>
              <div *dxTemplate="let cellInfo of 'editCellDiferimentos'">
                <entity-autocomplete
                  entity="pocs"
                  attrName="contaDep"
                  [selectedKey]="cellInfo.data.nContaDiferimentos"
                  (selectedKeyChange)="changedCellContaDiferimentos(cellInfo, $event)"
                  [properties]="{allowInvalid: false}"
                  [filter]="filterMovimento"
                  output="key">
                </entity-autocomplete>
              </div>
            </dx-data-grid>
            <hr />
            <pl-group>
              <label [translate]="'calculodiferimentos.modals.config.fields.contaacresc'"></label>
              <edit>
                <entity-autocomplete
                  entity="pocs"
                  attrName="rendimentosNContaAcrescimos"
                  [model]="custDif"
                  (selectedKeyChange)="custDif.rendimentosNContaAcrescimos = $event"
                  (evtSelectedDescriptionChanged)="rendimentosInputchanged(); custDif.nomeRendimentosNContaAcresc = $event"
                  [properties]="{validators: {required: {value: true}}}"
                  [filter]="rendiContaAcresFilter"
                  [output]="pocsOutput"
                  [fieldsMap]="{nConta: 'rendimentosNContaAcrescimos', nome: 'nomeRendimentosNContaAcresc'}">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'calculodiferimentos.modals.config.fields.contadif'"></label>
              <edit>
                <entity-autocomplete
                  entity="pocs"
                  attrName="rendimentosNContaDiferimentos"
                  [model]="custDif"
                  (selectedKeyChange)="custDif.rendimentosNContaDiferimentos = $event"
                  (evtSelectedDescriptionChanged)="rendimentosInputchanged(); custDif.nomeRendimentosNContaDif = $event"
                  [properties]="{validators: {required: {value: true}}}"
                  [filter]="rendiContaDiferFilter"
                  [output]="pocsOutput"
                  [fieldsMap]="{nConta: 'rendimentosNContaDiferimentos', nome: 'nomeRendimentosNContaDif'}">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'calculodiferimentos.modals.config.fields.calculo'"></label>
              <edit>
                <pl-autocomplete
                  attrName="tipodiferimentoRendimentos"
                  [source]="sourceMetodos"
                  [model]="nomeTipodiferimentoRendimentos"
                  (modelChange)="setTipoDiferimento($event, false)"
                  [allowEmpty]="false"
                  [allowInvalid]="false"
                  rowTemplate="label"
                  output="label">
                </pl-autocomplete>
              </edit>
            </pl-group>
          </div>
        </fieldset>

        <fieldset>
          <legend [translate]="'calculodiferimentos.modals.config.cardgastos'"></legend>

          <div>
            <dx-data-grid
              [cgDxDataGrid]="dataGridDefinitionRadicaisGastos"
              cgDxDataGridInstanceName="radicaisGastos"
              [dataSource]="this.custDif.gastosRadicais"
              (onInitialized)="onInitializedGastos($event)">
              <div *dxTemplate="let cellInfo of 'editCellAcrescimos'">
                <entity-autocomplete
                  entity="pocs"
                  attrName="contaDep"
                  [selectedKey]="cellInfo.data.nContaAcrescimos"
                  (selectedKeyChange)="changedCellContaAcrescimos(cellInfo, $event)"
                  [properties]="{allowInvalid: false}"
                  [filter]="filterMovimento"
                  output="key">
                </entity-autocomplete>
              </div>
              <div *dxTemplate="let cellInfo of 'editCellDiferimentos'">
                <entity-autocomplete
                  entity="pocs"
                  attrName="contaDep"
                  [selectedKey]="cellInfo.data.nContaDiferimentos"
                  (selectedKeyChange)="changedCellContaDiferimentos(cellInfo, $event)"
                  [properties]="{allowInvalid: false}"
                  [filter]="filterMovimento"
                  output="key">
                </entity-autocomplete>
              </div>
            </dx-data-grid>
            <hr />
            <pl-group>
              <label [translate]="'calculodiferimentos.modals.config.fields.contaacresc'"></label>
              <edit>
                <entity-autocomplete
                  entity="pocs"
                  attrName="gastosNContaAcrescimos"
                  [model]="custDif"
                  (selectedKeyChange)="custDif.gastosNContaAcrescimos = $event"
                  (evtSelectedDescriptionChanged)="gastosInputchanged(); custDif.nomeGastosNContaAcresc = $event"
                  [filter]="gastContaAcresFilter"
                  [output]="pocsOutput"
                  [properties]="{validators: {required: {value: true}}}"
                  [fieldsMap]="{nConta: 'gastosNContaAcrescimos', nome: 'nomeGastosNContaAcresc'}">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'calculodiferimentos.modals.config.fields.contadif'"></label>
              <edit>
                <entity-autocomplete
                  entity="pocs"
                  attrName="gastosNContaDiferimentos"
                  [model]="custDif"
                  (selectedKeyChange)="custDif.gastosNContaDiferimentos = $event"
                  (evtSelectedDescriptionChanged)="gastosInputchanged(); custDif.nomeGastosNContaDif = $event"
                  [filter]="gastContaDiferFilter"
                  [output]="pocsOutput"
                  [properties]="{validators: {required: {value: true}}}"
                  [fieldsMap]="{nConta: 'gastosNContaDiferimentos', nome: 'nomeGastosNContaDif'}">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'calculodiferimentos.modals.config.fields.calculo'"></label>
              <edit>
                <pl-autocomplete
                  attrName="tipodiferimentoGastos"
                  [source]="sourceMetodos"
                  [model]="nomeTipodiferimentoGastos"
                  (modelChange)="setTipoDiferimento($event, true)"
                  [allowEmpty]="false"
                  [allowInvalid]="false"
                  rowTemplate="label"
                  output="label">
                </pl-autocomplete>
              </edit>
            </pl-group>
          </div>
        </fieldset>
      </div>

      <div class="mt-3">
        <pl-group>
          <label [translate]="'calculodiferimentos.modals.config.fields.nDiario'"></label>
          <edit>
            <entity-autocomplete
              entity="diarios"
              attrName="nDiario"
              [model]="custDif"
              (selectedKeyChange)="custDif.nDiario = $event"
              (evtSelectedDescriptionChanged)="custDif.nomeNDiario = $event"
              [properties]="{validators: {required: {value: true}}}"
              [fieldsMap]="{nome: 'nomeNDiario'}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'calculodiferimentos.modals.config.fields.descritivoAnulacaoInicial'"></label>
          <edit>
            <entity-autocomplete
              entity="descritivos"
              attrName="descritivoAnulacaoInicial"
              [model]="custDif"
              (selectedKeyChange)="custDif.descritivoAnulacaoInicial = $event"
              (evtSelectedDescriptionChanged)="custDif.nomeDescritivoAnulacaoInicial = $event"
              [properties]="{validators: {required: {value: true}}}"
              [fieldsMap]="{nDescrit: 'descritivoAnulacaoInicial', nome: 'nomeDescritivoAnulacaoInicial'}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'calculodiferimentos.modals.config.fields.descritivo'"></label>
          <edit>
            <entity-autocomplete
              entity="descritivos"
              attrName="descritivo"
              [model]="custDif"
              (selectedKeyChange)="custDif.descritivo = $event"
              (evtSelectedDescriptionChanged)="custDif.nomeDescritivo = $event"
              [properties]="{validators: {required: {value: true}}}"
              [fieldsMap]="{nDescrit: 'descritivo', nome: 'nomeDescritivo'}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'calculodiferimentos.modals.config.fields.descritivoAcrescimos'"></label>
          <edit>
            <entity-autocomplete
              entity="descritivos"
              attrName="descritivoAcrescimos"
              [model]="custDif"
              (selectedKeyChange)="custDif.descritivoAcrescimos = $event"
              (evtSelectedDescriptionChanged)="custDif.nomeDescritivoAcrescimos = $event"
              [properties]="{validators: {required: {value: true}}}"
              [fieldsMap]="{nDescrit: 'descritivoAcrescimos', nome: 'nomeDescritivoAcrescimos'}">
            </entity-autocomplete>
          </edit>
        </pl-group>
      </div>
    </div>

    <div class="modal-footer" plNavigation selector="pl-button">
      <pl-button klass="btn-sm btn-primary action-close" [disabled]="closeDisabled" (evtClicked)="close()">
        <i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span>
      </pl-button>

      <pl-button klass="btn-sm btn-light action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()">
        <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
      </pl-button>
    </div>
  </div>
</pl-form>
