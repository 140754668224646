import {Component, Injector} from '@angular/core';
import {ModuloComponent} from '../../../components/module/module.component';

@Component({
  selector: 'module-extratos-artigos',
  templateUrl: './extractosArtigos.module.component.html'
})
export class ExtractosartigosModuleComponent extends ModuloComponent {
  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }
}
