import {ROLE} from '../../services/role.const';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonPais} from './jsonPais.entity.interface';
import {DATA_SOURCE_NAME_PAISES_ISO_3166} from '../../datasources/paisesiso3166/paisesISO3166.datasource.interface';
import {ENTITY_NAME_PAISES, tipoMercado} from './paises.entity.interface';
import {ENTITY_NAME_MOEDA} from '../../modules/portalcontabilidade/manutencao/moeda/moeda.entity.interface';
import {PaisesEditComponent} from './components/edit/paises.entity.edit.component';

const ENTITY_FIELDS_PAISES = 'codInternacional,abrevFiscal,codPais,nomePais,abrevPais,nomePaisPropriaLingua,codMoeda,codLingua,mercado';

export const ENTITY_PAISES: IEntityDefinition<IJsonPais> = {
  name: ENTITY_NAME_PAISES,
  roles: [ROLE.ERP, ROLE.CONTABILIDADE],
  searchPlaceholder: 'paises.pesquisa',
  metadata: {
    keyName: 'codPais',
    fields: [
      {name: 'codPais', type: 'cgsmallint', width: '120px', caption: 'paises.fields.codPais', validators: {required: true}},
      {name: 'nomePais', caption: 'paises.fields.nomePais', validators: {required: true}},
      {name: 'abrevPais', caption: 'paises.fields.abrevPais', validators: {required: true}},
      {name: 'nomePaisPropriaLingua', caption: 'paises.fields.nomePaisPropriaLingua'},
      {
        name: 'codMoeda',
        caption: 'paises.fields.codMoeda',
        validators: {required: true},
        entity: {name: ENTITY_NAME_MOEDA, keyTarget: 'codMoeda', outputTarget: 'nomeMoeda'}
      },
      {name: 'codLingua', caption: 'paises.fields.codLingua', visible: false},
      {name: 'mercado', type: 'radiogroup', caption: 'paises.fields.mercado', properties: {groupItems: tipoMercado}, validators: {required: true}},
      {
        name: 'codInternacional',
        caption: 'paises.fields.codISO',
        validators: {required: true},
        type: DATA_SOURCE_NAME_PAISES_ISO_3166
      },
      {name: 'abrevFiscal', caption: 'paises.fields.abrevFiscal', properties: {disabled: true}}
    ],
    order: 'nomePais',
    searchFields: 'codPais,nomePais',
    listFields: 'codPais,nomePais,abrevPais',
    newFields: ENTITY_FIELDS_PAISES,
    editFields: ENTITY_FIELDS_PAISES,
    detailFields: ENTITY_FIELDS_PAISES
  },
  autocomplete: {
    rowTemplate: '{{codPais}} - {{nomePais}}',
    output: 'nomePais',
    searchFields: 'codPais,nomePais'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    duplicate: false,
    search: true,
    delete: true,
    filter: true
  },
  list: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.paises'
      }
    }
  },
  detail: {
    state: {
      component: PaisesEditComponent
    }
  },
  new: {
    state: {
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.paises'
      }
    }
  }
};
