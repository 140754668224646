import type {Subscription} from 'rxjs';
import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import type {IPlLocale, IPlLocaleDashboard} from '../common/locale/locales.interface';
import {EPlDashboardType, IPlDashboard, IPlDashboardTableKey} from './dashboard.component.interface';
import {EScreenSize, getScreenWidth} from '../common/constants';
import {isArray, isBoolean, isEmpty, isNumber, isObject} from '../common/utilities/utilities';
import {Logger} from '../logger/logger';
import {PlDocumentService} from '../common/document/document.service';
import {PlI18nService} from '../i18n/i18n.service';
import {PlLocaleService} from '../common/locale/locale.service';
import type {TValueOrPromise} from '../common/utilities/utilities.interface';

interface ITableData {
  [index: string]: string | number;
}

@Component({
  selector: 'pl-dashboard',
  templateUrl: './dashboard.component.html',
  standalone: false
})
export class PlDashboardComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public dashboard: TValueOrPromise<IPlDashboard<any>>;
  @Input() public shortFormatNumber: boolean;
  @Input() public showTotalTable: boolean;

  public locale: IPlLocaleDashboard;
  public readonly types: typeof EPlDashboardType;
  public type: EPlDashboardType;
  public resolvedDashboard: IPlDashboard<any>;
  public resolvedTableData: Array<ITableData>;
  public resolvedTileData: string | number;
  public tableDataKeys: Array<IPlDashboardTableKey>;
  public windowWidth: number;
  public totalTable: string;
  public promise: Promise<void>;

  private readonly _subscriptionLocale: Subscription;
  private readonly _subscriptionWindowResize: Subscription;

  constructor(
    private readonly _logger: Logger,
    private readonly _plDocumentService: PlDocumentService,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _plI18nService: PlI18nService
  ) {
    this.shortFormatNumber = false;
    this.showTotalTable = false;
    this.types = EPlDashboardType;
    this.resolvedTableData = [];
    this.resolvedTileData = 0;
    this.tableDataKeys = [];
    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.locale = locale.plDashboard;
    });
    this._onResize();
    this._subscriptionWindowResize = this._plDocumentService.windowResize().subscribe(() => {
      this._onResize();
    });
  }

  public ngOnInit(): void {
    this._handleChanges();
    this._load();
  }

  public ngOnChanges({dashboard, shortFormatNumber, showTotalTable}: SimpleChanges): void {
    if (shortFormatNumber && !shortFormatNumber.isFirstChange()) {
      this._changedShortFormatNumber(shortFormatNumber.currentValue);
    }
    if (showTotalTable && !showTotalTable.isFirstChange()) {
      this._changedShowTotalTable(showTotalTable.currentValue);
    }
    if (dashboard && !dashboard.isFirstChange()) {
      this._load();
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionLocale.unsubscribe();
    this._subscriptionWindowResize.unsubscribe();
  }

  public getTableTooltip(value: string | number): string {
    if (!value && value !== 0) {
      return '';
    }
    value = String(value);
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if ((this.windowWidth < EScreenSize.ExtraExtraLarge && value.length > 50) || (this.windowWidth >= EScreenSize.ExtraExtraLarge && value.length > 90)) {
      return value;
    }
    return '';
  }

  public getTableValue(tableKey: IPlDashboardTableKey, value: {[index: string]: string | number}): string {
    if (tableKey.formatRight) {
      return this._formatNumber(<number>value[tableKey.keyName]);
    }
    return String(value[tableKey.keyName]);
  }

  private _handleChanges(): void {
    this._changedShortFormatNumber();
    this._changedShowTotalTable();
  }

  private _changedShortFormatNumber(value: boolean = this.shortFormatNumber): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.shortFormatNumber = val;
  }

  private _changedShowTotalTable(value: boolean = this.showTotalTable): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = false;
    }
    this.showTotalTable = val;
  }

  private _onResize(): void {
    this.windowWidth = getScreenWidth();
  }

  private _load(): void {
    this.resolvedDashboard = undefined;
    this.resolvedTableData = undefined;
    this.resolvedTileData = undefined;

    this.promise = Promise.resolve(this.dashboard).then(async (dashboard: IPlDashboard<any>) => {
      this.resolvedDashboard = dashboard;

      this.type = isObject(this.resolvedDashboard) && isNumber(this.resolvedDashboard.type) ? this.resolvedDashboard.type : undefined;

      let promise: Promise<void>;

      switch (this.type) {
        case EPlDashboardType.Table:
          promise = Promise.resolve(this.resolvedDashboard.data).then((tableData: Array<ITableData>) => {
            this._loadTable(tableData);
          });
          break;
        case EPlDashboardType.Tile:
          promise = Promise.resolve(this.resolvedDashboard.data).then((tileData: string | number) => {
            this._loadTile(tileData);
          });
          break;
        default:
          throw new Error('Invalid dashboard type');
      }

      try {
        await promise;
      } catch (reason) {
        this._logger.error(reason);
      }
    });
  }

  private _loadTable(tableData: Array<ITableData>): void {
    this.resolvedTableData = isArray(tableData) ? tableData : [];
    this.tableDataKeys = this.resolvedTableData.length
      ? Object.keys(this.resolvedTableData[0]).map<IPlDashboardTableKey>((keyName: string) => {
          return {
            keyName: keyName,
            formatRight: isNumber(this.resolvedTableData[0][keyName])
          };
        })
      : [];

    // Evaluate total
    let total = 0;
    for (const item of this.resolvedTableData) {
      for (const key of this.tableDataKeys) {
        if (key.formatRight) {
          total += <number>item[key.keyName];
        }
      }
    }
    this.totalTable = this._formatNumber(total);
  }

  private _loadTile(tileData: string | number): void {
    this.resolvedTileData = isNumber(tileData) ? tileData : !isEmpty(tileData) ? tileData : 0;
  }

  private _formatNumber(value: number): string {
    return this._plI18nService.formatNumber(value, '1.2-2');
  }
}
