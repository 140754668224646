import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {
  copy,
  IPlNavWizardCallback,
  IPlNavWizardDefinition,
  IPlNavWizardEventBeforeChange,
  IPlNavWizardEventStep,
  IPlNavWizardOptions,
  isUndefined,
  PlAlertService,
  PlTranslateService
} from 'pl-comps-angular';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {StateService} from '@uirouter/core';
import {IJsonDocComercial, IJsonDocComercialCab, IJsonDocComercialLinha} from '../../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {EStepsNavTrDocs} from '../../../../../modules/trdocs/trDocs.module.interface';
import {IJsonDocLinhaATransformar, IJsonTrDoc, IJsonTrDocDadosIdentificacaoCarga, IJsonTrDocLinhaLoteSel, ITrDocConfig} from '../../../../../modules/trdocs/jsonTrDoc.module.interface';
import {EGrupoDoc} from '../../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {TClifos} from '../../../../../entities/clifos/clifos.entity.interface';
import {IDevExpressDataGrid} from '../../../../devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonArtigoLoteArmazemStock, IJsonLote} from '../../../../../entities/lotes/jsonLote.entity.interface';
import {DocumentosService} from '../../../../../entities/docscomerciais/service/documentos.entity.service';
import {CGStateService} from '../../../../state/cg.state.service';
import {GrupoDocService} from '../../../../../services/grupodoc/grupo.doc.service';
import {CGExceptionService} from '../../../../exceptions/exceptions.service';
import {IDocComercialEditStateParams} from '../../../../../entities/docscomerciais/docsComerciais.entity.interface';
import {EEntityStateDetailType} from '../../../../../../common/utils/entity.state.utils';
import {
  IDevExpressDataGridEventOnFocusedCellChanged,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowPrepared,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonDocfa} from '../../../../../entities/docfas/jsonDocFa.entity.interface';
import {DocsComerciaisModalComponent} from '../../../../../entities/docscomerciais/modals/docsComerciais.modal.component';
import moment from 'moment';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {THttpQueryResponse} from '../../../../../services/api/api.service.interface';
import {ENTITY_NAME_LOTES} from '../../../../../entities/lotes/lotes.entity.interface';
import {ENTITY_NAME_TRDOC_CONFIG} from '../../../../../entities/trdocconfig/trDocConfig.entity.interface';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../../../entities/docfas/docFas.entity.interface';
import {TrDocsService} from '../../../../../modules/trdocs/service/trDocs.service';
import {IJsonArtigo} from '../../../../../entities/artigos/jsonArtigo.entity.interface';
import {ENTITY_NAME_ARTIGOS, IArtigosEntityService} from '../../../../../entities/artigos/artigos.entity.interface';
import {CUSTO_MEDIO_PONDERADO, FIFO} from '../../../../../datasources/criteriodisponiveis/criterioDisponiveis.datasource.interface';
import {round} from '../../../../../../common/utils/utils';
import {IJsonCodPostal} from '../../../../../entities/codpostais/jsonCodPostal.entity.interface';
import {IDocumentoFaturacao} from '../../../../documento/facturacao/documento.facturacao.component.interface';
import {IJsonDocfaNum} from '../../../../../entities/docfasnums/jsonDocfaNum.entity.interface';
import {CONSTANT_COD_PAIS_NACIONAL, CONSTANT_NOME_PAIS_NACIONAL} from '../../../../../../config/constants';
import {TTableLegend} from '../../../../tablelegend/tablelegend.component.interface';
import {ETrDocsTableLegendColors, TABLE_LEGEND_TRDOCS_LINHA_TRANSFORMADA} from '../../trdocs.component.interface';
import {docsComerciaisGrupoDocToEntity} from '../../../../../entities/docscomerciais/docsComerciais.entity';

const timeOutFocus = 350;

@Component({
  selector: 'trdocs-linhas-modal',
  templateUrl: './trdocs.linhas.modal.component.html'
})
export class TrDocsLinhasModalComponent extends DocsComerciaisModalComponent implements OnInit {
  @Input() public grupoClifos: TClifos;

  public readonly navWizardDefinition: IPlNavWizardDefinition;
  public readonly navWizardCallback: IPlNavWizardCallback;
  public readonly docfasOutput: string;
  public readonly stepsNavTrDocs: typeof EStepsNavTrDocs;
  public readonly paisesOutput: string = '{{codPais}} - {{nomePais}}';
  public readonly variablesTxtContratoSource: Array<string>;

  public proceTrDoc: IJsonTrDoc;
  public docComercial: IJsonDocComercial;
  public docFa: IJsonDocfa;
  public docfaFilter: string;
  public docfaNumFilter: string;
  public clifoDescription: string;
  public showLotes: boolean;
  public showResumo: boolean;
  public showEditDocBtn: boolean;
  public disableNConta: boolean;
  public dataGridLinhasDefinition: IDevExpressDataGrid<IJsonDocComercialLinha, number>;
  public dataGridLinhasLotesDefinition: IDevExpressDataGrid<IJsonDocComercialLinha, number>;
  public dataGridLotesDefinition: IDevExpressDataGrid<IJsonLote, number>;
  public trdocPretendeContinuar: boolean;
  public clifo: string;
  public propertiesNavWizard: IPlNavWizardOptions;
  public nContaMoralFilter: string;
  public showIdentificaCarga: boolean;
  public showObservacoes: boolean;
  public linhasATransformar: Array<IJsonDocLinhaATransformar>;
  public tableLegend: TTableLegend;
  public temLinhasTransformadas: boolean;

  private readonly _docFaService: IDocFasEntityService;
  private readonly _trDocConfigService: IEntityService<ITrDocConfig>;
  private readonly _lotesService: IEntityService<IJsonLote>;
  private readonly _artigosService: IEntityService<IJsonArtigo>;
  private readonly _nDecimaisQtd: number;
  private readonly _grupoDocfa: number;

  private _dataGridInstance: dxDataGrid;
  private _linhasComLote: Array<IJsonDocComercialLinha>;
  private _dataGridLinhasLotesInstance: dxDataGrid;
  private _lotesLinha: Array<IJsonTrDocLinhaLoteSel>;
  private _dataGridLotesInstance: dxDataGrid;
  private _rowIndex: number;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _stateService: StateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _trdocsService: TrDocsService,
    private readonly _documentosService: DocumentosService,
    private readonly _cgStateService: CGStateService,
    private readonly _grupoDoc: GrupoDocService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);

    this._trDocConfigService = this._entityServiceBuilder.build<ITrDocConfig>(ENTITY_NAME_TRDOC_CONFIG);
    this._docFaService = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
    this._lotesService = this._entityServiceBuilder.build<IJsonLote>(ENTITY_NAME_LOTES);
    this._artigosService = this._entityServiceBuilder.build<IJsonArtigo, IArtigosEntityService>(ENTITY_NAME_ARTIGOS);

    this.variablesTxtContratoSource = ['@NomeDoc', '@NDoc', '@DtDoc'];

    this._linhasComLote = [];
    this._lotesLinha = [];

    this.docfasOutput = '{{nDocFa}} - {{nome}}';
    this.grupoClifos = 'clifos';

    this.stepsNavTrDocs = EStepsNavTrDocs;
    this.navWizardDefinition = {
      items: []
    };
    this.navWizardCallback = {};
    this.proceTrDoc = {
      config: {
        dataDoc: moment(),
        saveConfig: false,
        dataDocOrigem: false,
        dataVencCondVenda: false,
        nRefProcessoOrigem: false,
        dataDocExternoOrigem: false,
        nArmazemOrigem: false
      },
      documentos: []
    };
    this.showIdentificaCarga = false;
    this.showLotes = false;
    this.showResumo = false;
    this.showEditDocBtn = false;
    this.disableNConta = false;
    this.showObservacoes = false;

    this.linhasATransformar = [];
    this.temLinhasTransformadas = false;

    this.finalize = this.finalize.bind(this);

    this._nDecimaisQtd = this._configService.configurations.gestaoComercial.decimais.stocks.quantidades;

    this.dataGridLinhasDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'facliId', dataType: 'number', caption: 'docscomerciais.doc.linhas.facliId', visible: false, allowEditing: false},
        {dataField: 'nSeq', dataType: 'number', caption: 'docscomerciais.doc.linhas.nSeq', allowEditing: false},
        {dataField: 'nArtigo', dataType: 'string', caption: 'docscomerciais.doc.linhas.nArtigo', allowEditing: false, cellTemplate: 'cellTemplateLinhaTransformada'},
        {dataField: 'nomeArtigo', dataType: 'string', caption: 'docscomerciais.doc.linhas.nome', allowEditing: false},
        {dataField: 'nArmazem', dataType: 'string', caption: 'docscomerciais.doc.linhas.nArmazem', allowEditing: false},
        {dataField: 'prVenda1', dataType: 'double', caption: 'docscomerciais.doc.linhas.preco', allowEditing: false},
        {dataField: 'qtd1', dataType: 'number', caption: 'docscomerciais.doc.linhas.qtd', allowEditing: false},
        {dataField: 'taxaIva', dataType: 'number', caption: 'docscomerciais.doc.linhas.taxaIva', allowEditing: false},
        {dataField: 'valorIva', dataType: 'number', caption: 'docscomerciais.doc.linhas.valorIva', allowEditing: false},
        {dataField: 'prVendaIvaInc', dataType: 'number', caption: 'docscomerciais.doc.linhas.valorComIva', allowEditing: false}
      ],
      dataSource: [],
      keyExpr: 'facliId',
      selection: {mode: 'multiple', showCheckBoxesMode: 'always'},
      filterRow: {visible: false},
      headerFilter: {visible: false}
    };

    this.dataGridLinhasLotesDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'facliId', dataType: 'number', caption: 'docscomerciais.doc.linhas.facliId', visible: false, allowEditing: false},
        {dataField: 'nSeq', dataType: 'number', caption: 'docscomerciais.doc.linhas.nSeq', allowEditing: false},
        {dataField: 'nArtigo', dataType: 'string', caption: 'docscomerciais.doc.linhas.nArtigo', allowEditing: false},
        {dataField: 'nomeArtigo', dataType: 'string', caption: 'docscomerciais.doc.linhas.nome', allowEditing: false},
        {dataField: 'nArmazem', dataType: 'string', caption: 'docscomerciais.doc.linhas.nArmazem', allowEditing: false},
        {dataField: 'prVenda1', dataType: 'double', caption: 'docscomerciais.doc.linhas.preco', allowEditing: false},
        {dataField: 'qtd1', dataType: 'number', caption: 'docscomerciais.doc.linhas.qtd', allowEditing: false},
        {dataField: 'taxaIva', dataType: 'number', caption: 'docscomerciais.doc.linhas.taxaIva', allowEditing: false},
        {dataField: 'valorIva', dataType: 'number', caption: 'docscomerciais.doc.linhas.valorIva', allowEditing: false},
        {dataField: 'prVendaIvaInc', dataType: 'number', caption: 'docscomerciais.doc.linhas.valorComIva', allowEditing: false}
      ],
      dataSource: [],
      keyExpr: 'facliId',
      filterRow: {visible: false},
      headerFilter: {visible: false},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateBtnToolbar',
            locateInMenu: 'auto'
          }
        ]
      }
    };

    this.dataGridLotesDefinition = {
      allowColumnReordering: false,
      columnHidingEnabled: true,
      columnChooser: {mode: 'select'},
      columns: [
        {dataField: 'nLote', dataType: 'number', caption: 'lotes.fields.nLote', visible: false, allowEditing: false, showInColumnChooser: false},
        {dataField: 'nLoteEspecifico', dataType: 'string', caption: 'lotes.fields.nLoteEspecifico', allowEditing: false, showInColumnChooser: false},
        {dataField: 'dataFabrico', dataType: 'date', caption: 'lotes.fields.dataFabrico', visible: false, allowEditing: false},
        {dataField: 'dataValidade', dataType: 'date', caption: 'lotes.fields.dataValidade', visible: false, allowEditing: false},
        {dataField: 'qtdStock', dataType: 'double', caption: 'docscomerciais.doc.linhas.qtdStock', visible: true, format: {decimalsLimit: this._nDecimaisQtd}, allowEditing: false},
        {dataField: 'qtd', dataType: 'double', caption: 'docscomerciais.doc.linhas.qtd', format: {decimalsLimit: this._nDecimaisQtd}, showEditorAlways: true, showInColumnChooser: false}
      ],
      dataSource: [],
      keyExpr: 'nLote',
      toolbar: {visible: true},
      filterRow: {visible: false},
      editing: {allowUpdating: true, mode: 'cell', selectTextOnEditStart: true},
      export: {enabled: false},
      remoteOperations: false,
      keyboardNavigation: {
        enabled: true,
        enterKeyAction: 'moveFocus',
        enterKeyDirection: 'column'
      }
    };

    this.propertiesNavWizard = {
      disableNextStep: false
    };
  }

  public ngOnInit(): void {
    const linhasATransformarTmp = copy(<Array<IJsonDocLinhaATransformar>>this.doc.linhas);

    for (const item of this.linhasATransformar) {
      item.transformada = false;
    }

    this._trdocsService.getLinhasTransformadasDoDocumento(this.doc.cab.faccbId).then((response) => {
      if (response.body) {
        for (const item of response.body) {
          const linha = linhasATransformarTmp.find((value) => value.facliId === item.facliId);
          if (linha) {
            linha.transformada = true;
            if (!this.temLinhasTransformadas) {
              this.temLinhasTransformadas = true;
            }
          }
        }
      }
      this.linhasATransformar = copy(linhasATransformarTmp);
    });

    this.docfaFilter =
      this.doc.cab.grupoDocfa === EGrupoDoc.EncomendasClientes
        ? `grupoDocfa=${EGrupoDoc.VendasEfectivas}|grupoDocfa=${EGrupoDoc.GuiasTransporteRemessa}`
        : this.doc.cab.grupoDocfa === EGrupoDoc.EncomendasFornecedores
          ? `grupoDocfa=${EGrupoDoc.ComprasEfectivas}|grupoDocfa=${EGrupoDoc.GuiasTransporteFornecedores}`
          : undefined;

    this.docfaNumFilter = 'encerrado=0&visivelERPCloud=1';

    this.clifoDescription =
      this.grupoClifos === 'clientes'
        ? 'encomendas.modal.processamento.fields.cliente'
        : this.grupoClifos === 'fornecedores'
          ? 'encomendas.modal.processamento.fields.fornecedor'
          : 'encomendas.modal.processamento.fields.clifos';

    this.trdocPretendeContinuar = !this.doc.cab.transformado;

    const dadosIdCarga = this._getDadosIdentificacaoCarga(this.doc);

    this.proceTrDoc.documentos.push({faccbId: this.doc.cab.faccbId, linhas: [], carga: dadosIdCarga?.carga, descarga: dadosIdCarga?.descarga});

    this._trDocConfigService.get({id: 0}).then((response) => {
      if (response) {
        this.proceTrDoc.config.nDocfaDestino = response.body.nDocfaDestino;
        this.proceTrDoc.config.nNumerDestino = response.body.nNumerDestino;
        this.proceTrDoc.config.dataDoc = this.doc.cab.dataDoc;
        this.proceTrDoc.config.dataDocOrigem = false;
        this.proceTrDoc.config.nFactFornec = this.doc.cab.nFactFornec;
        this.proceTrDoc.config.nFactFornecOrigem = this.doc.cab.nFactFornec !== '';
        this.proceTrDoc.config.dataDocExterno = this.doc.cab.dataDocExterno;
        this.proceTrDoc.config.dataDocExternoOrigem = false;
        this.proceTrDoc.config.dataVenc = this.doc.cab.dataVenc;
        this.proceTrDoc.config.encerraDocumentoOrigem = false;
        this.proceTrDoc.config.encerraDocumentoDestino = false;
        this.proceTrDoc.config.referenciaTextoBase = response.body.referenciaTextoBase;
        const nDocfaDestino = this.proceTrDoc.config.nDocfaDestino !== 0 ? this.proceTrDoc.config.nDocfaDestino : this.doc.cab.nDocFa;
        this._setDefaultTipoDoc(nDocfaDestino);
        this.docfaNumFilter = `nDocFa=${this.proceTrDoc.config.nDocfaDestino}&encerrado=0&visivelERPCloud=1`;
      }
    });

    this.clifo = `${this.doc.cab.nConta} - ${this.doc.cab.nome}`;
    this.nContaMoralFilter = `nConta=${this.doc.cab.nConta}`;

    this.tableLegend = TABLE_LEGEND_TRDOCS_LINHA_TRANSFORMADA;

    this._setDisableNextStepWizard(this.trdocPretendeContinuar);
  }

  public close(): void {
    super.close();
  }

  public onChangeTrdocPretendeContinuar(value: boolean): void {
    this.trdocPretendeContinuar = value;
    this._setDisableNextStepWizard(this.trdocPretendeContinuar);
    if (this.trdocPretendeContinuar) {
      this._dataGridInstance.selectAll();
    } else {
      this._dataGridInstance.deselectAll();
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
    if (!this.doc.cab.transformado || (this.doc.cab.transformado && this.trdocPretendeContinuar)) {
      this._dataGridInstance.selectAll();
      if (!this.doc.cab.transformado && this.temLinhasTransformadas) {
        const linhasTransformadas: Array<number> = [];
        for (const linha of this.linhasATransformar) {
          if (linha.transformada) {
            linhasTransformadas.push(linha.facliId);
          }
        }
        if (linhasTransformadas.length > 0) {
          this._dataGridInstance.deselectRows(linhasTransformadas);
        }
      }
    }
  }

  public editDoc(): Promise<void> {
    const stateParams: IDocComercialEditStateParams = {id: this.docComercial.cab.faccbId, doc: copy(this.docComercial)};
    const nameModulo = docsComerciaisGrupoDocToEntity(this.docComercial.cab.grupoDocfa);
    return this._cgStateService
      .redirectToState({
        stateOrName: nameModulo,
        stateType: EEntityStateDetailType.EDIT,
        params: stateParams
      })
      .then(() => {
        super.close();
      });
  }

  public finalize(): Promise<void> {
    if (this._dataGridInstance.getSelectedRowsData().length === 0) {
      this._plAlertService.error('trdoc.modal.linhas.messages.naoTemLinhasSelecionadas');
      return this.navWizardCallback.setStep(EStepsNavTrDocs.Linhas, true);
    }

    if (this.showLotes) {
      for (const linhaComLote of this._linhasComLote) {
        if (linhaComLote.lotes?.length === 0) {
          this._plAlertService.error('trdoc.modal.linhas.messages.atribuirLotes');
          return this.navWizardCallback.setStep(EStepsNavTrDocs.Lotes, true);
        }

        let qtdLotes = 0;
        for (const lote of linhaComLote.lotes) {
          qtdLotes += lote.qtd;
        }
        if (qtdLotes !== linhaComLote.qtd1) {
          this._plAlertService.error(
            this._plTranslateService.translate('trdoc.modal.linhas.messages.qtdLotesDifQtdLinha', {qtd: qtdLotes, nArtigo: linhaComLote.nArtigo, nSeq: linhaComLote.nSeq, qtd1: linhaComLote.qtd1})
          );
          return this.navWizardCallback.setStep(EStepsNavTrDocs.Lotes, true);
        }
      }
    }

    this.proceTrDoc.config.nFactFornecOrigem = this.proceTrDoc.config.nFactFornec !== '' && this.proceTrDoc.config.nFactFornec === this.doc.cab.nFactFornec;

    this.proceTrDoc.documentos[0].linhas = [];
    for (const item of this._dataGridInstance.getSelectedRowsData()) {
      this.proceTrDoc.documentos[0].linhas.push({facliId: item.facliId, qtd: item.qtd1, lotes: item.lotes});
    }

    return this._trdocsService
      .processar(this.proceTrDoc, this.trdocPretendeContinuar)
      .then((response: HttpResponse<Array<IJsonDocComercialCab>>) => {
        if (response && response.body.length === 1) {
          this.service.get({id: response.body[0].faccbId}).then((responseAux) => {
            this.docComercial = responseAux.body;
            this.showResumo = true;
            setTimeout(() => {
              return this.navWizardCallback.setStep(EStepsNavTrDocs.Resumo, true);
            }, timeOutFocus);
            this._plAlertService.success('trdoc.modal.linhas.messages.proceSuccess');
          });
        }
        return Promise.resolve();
      })
      .catch((error: unknown) => {
        if (error instanceof HttpErrorResponse) {
          const exception = this._cgExceptionService.get(error);
          if (exception?.message) {
            this._plAlertService.error(exception.message);
            return Promise.reject(new Error(exception.message));
          }
        }
        return Promise.reject(new Error('trdoc.modal.linhas.messages.proceFalhou'));
      });
  }

  public onDocFaChanged(docFa: IJsonDocfa, validaLotes: boolean = true): void {
    if (!docFa || isUndefined(docFa.nDocFa) || !this.proceTrDoc) {
      return;
    }

    this.proceTrDoc.config.nDocfaDestino = docFa.nDocFa;
    this.docfaNumFilter = `nDocFa=${docFa.nDocFa}&encerrado=0&visivelERPCloud=1`;
    // this.docFa = docFa;

    if (docFa.identificaCarga) {
      const doc = {
        cab: {nDocFa: docFa.nDocFa, nNumer: this._getNNumerDefeitoDocfa(docFa.docfanumList), nConta: this.doc.cab.nConta, nif: this.doc.cab.nif, dataDoc: moment()},
        clifo: {...this.doc.clifo},
        linhas: []
      };
      this.service.post({params: {memoria: 1, terminadoc: 0}, body: doc}).then((response) => {
        if (response.body) {
          const dadosIdCarga = this._getDadosIdentificacaoCarga(response.body, true);
          this.proceTrDoc.documentos[0].carga = dadosIdCarga.carga;
          this.proceTrDoc.documentos[0].descarga = dadosIdCarga.descarga;
        } else {
          this.proceTrDoc.documentos[0].carga = undefined;
          this.proceTrDoc.documentos[0].descarga = undefined;
        }
      });
    }

    this._validaSeLinhasTemLotes(this._dataGridInstance.getSelectedRowsData(), docFa.integraStocks, this._grupoDoc.isCompra(docFa.grupoDocfa), validaLotes);
  }

  public onGridLinhasLotesInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridLinhasLotesInstance = component;
  }

  public onGridLinhasLotesContentReady(): void {
    this._dataGridLinhasLotesInstance.endCustomLoading();
  }

  public onGridLinhasLotesSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IJsonTrDocLinhaLoteSel, number>): void {
    for (const facliId of event.currentSelectedRowKeys) {
      this._getLotesDaLinha(facliId);
    }
  }

  public preencheAutomaticamenteLotes(): Promise<void> {
    if (this._grupoDoc.isCompra(this._grupoDocfa)) {
      this._plAlertService.error('encomendas.modal.processamento.messages.notAutoSelLotesEntradaStock');
      return Promise.reject(new Error('encomendas.modal.processamento.messages.notAutoSelLotesEntradaStock'));
    }

    if (this.showLotes && this.proceTrDoc.documentos.length === 0 && this.proceTrDoc.documentos[0].linhas.length === 0) {
      this._plAlertService.error('encomendas.modal.processamento.messages.naoTemLinhas');
      return Promise.reject(new Error('encomendas.modal.processamento.messages.naoTemLinhas'));
    }

    if (this._linhasComLote.length === 0) {
      this._plAlertService.error('encomendas.modal.processamento.messages.naoTemLinhasComLotes');
      return Promise.reject(new Error('encomendas.modal.processamento.messages.naoTemLinhasComLotes'));
    }

    for (const linha of this._linhasComLote) {
      if (linha.qtd1 > 0 && linha.lotes?.length > 0) {
        for (const lote of linha.lotes) {
          lote.qtd = 0;
        }
        this._preencheAutomaticamenteLotesNaLinha(linha, this._nDecimaisQtd);
      } else {
        this._getLotesArtigoLinha(linha, true);
      }
    }

    if (this._linhasComLote?.length > 0 && this._linhasComLote[0].lotes.length > 0) {
      this._getLotesDaLinha(this._linhasComLote[0].facliId);
    }

    return Promise.resolve();
  }

  public onGridLotesInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridLotesInstance = component;
    this._dataGridLotesInstance.columnOption('qtd', 'showEditorAlways', true);
    this._dataGridLotesInstance.columnOption('qtdStock', 'visible', true);
  }

  public onGridLotesContentReady(): void {
    this._dataGridLotesInstance.endCustomLoading();
    if (this._lotesLinha.length > 0) {
      this._dataGridLotesInstance.columnOption('qtd', 'showEditorAlways', true);
      this._dataGridLotesInstance.editCell(this._rowIndex ? this._rowIndex : 0, 'qtd');
    }
  }

  public onGridLotesFocusedCellChanged(event: IDevExpressDataGridEventOnFocusedCellChanged<IJsonTrDocLinhaLoteSel>): void {
    this._rowIndex = event.row.rowIndex ? event.row.rowIndex : 0;
  }

  public closeModal(): void | Promise<void> {
    if (this.showResumo) {
      this.close();
    } else {
      this.dismiss();
    }
  }

  public stepChanged({currentStep}: IPlNavWizardEventStep): void {
    if (currentStep.stepId === EStepsNavTrDocs.Resumo) {
      for (const item of this.navWizardDefinition.items) {
        if (item.stepId !== EStepsNavTrDocs.Resumo) {
          item.disabled = true;
        }
      }
      currentStep.hidePrevious = true;
      currentStep.hideFinalize = true;
    }
    this.showEditDocBtn = currentStep.stepId === EStepsNavTrDocs.Resumo && !this.proceTrDoc.config.encerraDocumentoDestino;
  }

  public codPostaisChanged(postal: IJsonCodPostal, type: 'carga' | 'descarga'): void {
    if (type === 'carga') {
      this.proceTrDoc.documentos[0].carga.cPost = postal.codPostal;
      this.proceTrDoc.documentos[0].carga.localidade = postal.localidade;
    } else {
      this.proceTrDoc.documentos[0].descarga.cPost = postal.codPostal;
      this.proceTrDoc.documentos[0].descarga.localidade = postal.localidade;
    }
  }

  public onDataGridRowPrepared({data, rowElement, rowType}: IDevExpressDataGridEventOnRowPrepared<IJsonDocLinhaATransformar>): void {
    if (rowType === 'data') {
      let cssClass: string;
      if (data.transformada) {
        cssClass = ETrDocsTableLegendColors.LinhaTransformada;
      }

      if (cssClass) {
        this._renderer.addClass(rowElement, cssClass);
      } else if (rowElement.style.backgroundColor) {
        this._renderer.removeClass(rowElement, cssClass);
      }
    }
  }

  public applyVariable(variable: string): void {
    if (this.proceTrDoc.config.referenciaTextoBase) {
      this.proceTrDoc.config.referenciaTextoBase += variable;
    } else {
      this.proceTrDoc.config.referenciaTextoBase = variable;
    }
  }

  public fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this._getPdf(doc);

  public fnBeforeChangedStep = (event: IPlNavWizardEventBeforeChange): boolean => this._beforeChangedStep(event);

  private _beforeChangedStep({currentStep}: IPlNavWizardEventBeforeChange): boolean {
    if (currentStep.stepId === EStepsNavTrDocs.Linhas) {
      if (this._dataGridInstance.getSelectedRowsData().length === 0) {
        this._plAlertService.error('trdoc.modal.linhas.messages.naoTemLinhasSelecionadas');
        return false;
      }
      this._validaSeLinhasTemLotes(this._dataGridInstance.getSelectedRowsData(), this.docFa.integraStocks, this._grupoDoc.isCompra(this.docFa.grupoDocfa), true);
    }

    if (this.showResumo && currentStep.stepId === EStepsNavTrDocs.Resumo) {
      return false;
    }

    return true;
  }

  private _getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc, doc.cab.nDocumento === 0 || !doc.cab.terminado);
  }

  private _getLotesArtigoLinha(linha: IJsonDocComercialLinha, preencheAutomaticamente: boolean = false): void {
    if (!linha?.facliId) {
      this._lotesLinha = [];
      this.dataGridLotesDefinition.dataSource = this._lotesLinha;
      return;
    }

    const search = `nArtigo=${linha.nArtigo}&terminado=0`;
    this._lotesService.query({pesquisa: search}).then((response: THttpQueryResponse<IJsonLote>) => {
      if (response) {
        const lotes: Array<IJsonLote> = response.body.list;

        if (lotes.length > 0) {
          if (this._lotesLinha.length > 0) {
            this._lotesLinha = [];
          }

          for (const item of lotes) {
            const stock = item?.stockArmazens?.find((stockArmaz: IJsonArtigoLoteArmazemStock) => stockArmaz?.nArmazem === linha.nArmazem);
            const qtdStock = stock ? stock.qtd : 0;
            this._lotesLinha.push({nLote: item.nLote, dataFabrico: item.dataFabrico, dataValidade: item.dataValidade, nLoteEspecifico: item.nLoteEspecifico, qtd: 0, qtdStock: qtdStock, seq: 0});
          }

          linha.lotes = this._lotesLinha;

          this.dataGridLotesDefinition.dataSource = this._lotesLinha;

          if (preencheAutomaticamente) {
            this._preencheAutomaticamenteLotesNaLinha(linha, this._nDecimaisQtd);
          }
        }
      }
    });
  }

  private _setDefaultTipoDoc(nDocfaDestino: number): void {
    if (nDocfaDestino && nDocfaDestino > 0) {
      this._docFaService
        .get({id: nDocfaDestino})
        .then((response: HttpResponse<IJsonDocfa>) => {
          if (response) {
            this.docFa = copy(response.body);
            this.showIdentificaCarga = response.body.identificaCarga;
            this.onDocFaChanged(response.body, false);
          }
        })
        .catch(() => {
          (<Partial<IJsonDocfa>>this.docFa) = {};
        });
    }
  }

  private _validaSeLinhasTemLotes(linhas: Array<IJsonDocComercialLinha>, integraStocks: boolean, isCompra: boolean, validaLotes: boolean): void {
    if (validaLotes && integraStocks) {
      this._temLinhasComLotes(linhas, isCompra).then((response) => {
        if (response) {
          this.showLotes = response.length > 0;
          this.dataGridLinhasLotesDefinition.dataSource = response;
        } else {
          this.showLotes = false;
          this.dataGridLinhasLotesDefinition.dataSource = [];
        }
      });
    } else {
      this._linhasComLote = [];
      this.dataGridLinhasLotesDefinition.dataSource = [];
      this._lotesLinha = [];
      this.dataGridLotesDefinition.dataSource = [];
      this.showLotes = false;
    }
  }

  private async _temLinhasComLotes(linhas: Array<IJsonDocComercialLinha>, isCompra: boolean): Promise<Array<IJsonDocComercialLinha>> {
    this._linhasComLote = [];
    this.dataGridLinhasLotesDefinition.dataSource = [];
    this._lotesLinha = [];
    this.dataGridLotesDefinition.dataSource = [];

    const artigos: Array<HttpResponse<IJsonArtigo>> = await Promise.all(linhas.map((linha) => this._artigosService.get({id: linha.nArtigo})));

    for (let i = 0; i < linhas.length; i++) {
      const linha = linhas[i];
      const artigo = artigos[i].body;
      if (artigo.temLote && (artigo.codvaloriz === CUSTO_MEDIO_PONDERADO || (artigo.codvaloriz === FIFO && isCompra))) {
        this._linhasComLote.push(linha);
      }
    }

    return this._linhasComLote;
  }

  private _getLotesDaLinha(facliId: number): void {
    const linha = this._linhasComLote.find((item: IJsonDocComercialLinha) => item.facliId === facliId);
    if (linha?.lotes?.length > 0) {
      this.dataGridLotesDefinition.dataSource = linha.lotes;
    } else {
      this._getLotesArtigoLinha(linha);
    }
  }

  private _preencheAutomaticamenteLotesNaLinha(linha: IJsonDocComercialLinha, nDecimais: number): void {
    const qtdADistribuir = linha.qtd1;

    let qtdFACDistribuida = 0;
    let qtdLista = 0;

    for (const lote of linha.lotes) {
      const oldSelected = linha.qtd1;

      //quantidade que é suporto colocar na lista (caso normal)
      let tempQtd = round(lote.qtd + qtdADistribuir - qtdFACDistribuida, nDecimais);

      //verificar se a quantidade que vou colocar na linha ultrapassa a quantidade disponivel
      if (tempQtd > lote.qtdStock) {
        tempQtd = lote.qtdStock;
      }

      //se já distribui tudo... vou ver se há quantidades distribuidas a mais
      if (qtdFACDistribuida >= qtdADistribuir) {
        tempQtd = qtdFACDistribuida >= qtdADistribuir ? round(linha.qtd1 - qtdFACDistribuida, nDecimais) : lote.qtd;

        //caso em que existe mais quantidade seleccionada do que qtd fac, reduz para a qtd fac
        if (tempQtd > lote.qtdStock) {
          tempQtd = lote.qtdStock;
        }

        //caso em que já existe mais quantidade distribuida do que era suposto distribuir
        if (qtdFACDistribuida + tempQtd >= linha.qtd1) {
          tempQtd = round(linha.qtd1 - qtdFACDistribuida, nDecimais);
        }
      }

      //já existe mais quantidade satisfeita na lista do que era suporto distribuir..
      //reduz para a quantidade em falta
      if (qtdLista >= linha.qtd1) {
        tempQtd = round(linha.qtd1 - qtdLista, nDecimais);
        //caso em que existe mais quantidade seleccionada do que qtd fac, reduz para a qtd fac
        if (tempQtd > lote.qtd) {
          tempQtd = lote.qtdStock;
        }

        //não pode ser negativa
        if (tempQtd < 0) {
          tempQtd = 0;
        }
      }

      lote.qtd = tempQtd; //coloca a nova quantidade na linha
      qtdLista = round(qtdLista + lote.qtd, nDecimais); //soma quantidade total seleccionada na lista
      qtdFACDistribuida = round(tempQtd - oldSelected, nDecimais); //soma quantidade distribuida
    }
  }

  private _setDisableNextStepWizard(enable: boolean): void {
    this.propertiesNavWizard = {...this.propertiesNavWizard, disableNext: !enable};
  }

  private _getDadosIdentificacaoCarga(documento: IDocumentoFaturacao, identificaCarga: boolean = false): IJsonTrDocDadosIdentificacaoCarga {
    const doc = documento;
    if (doc.identificaCarga || identificaCarga) {
      const now = moment();
      const trDocDadosIdCarga = {
        carga: {
          nConta: '',
          nome: '',
          rua: doc.moradaCarga.rua ? doc.moradaCarga.rua : '',
          cPost: doc.moradaCarga.codPostal ? doc.moradaCarga.codPostal : '',
          localidade: doc.moradaCarga.localidade ? doc.moradaCarga.localidade : '',
          nipc: '',
          id: 0,
          observacoes: '',
          morada: doc.moradaCarga.morada ? doc.moradaCarga.morada : '',
          codPais: doc.moradaCarga.codPais ? doc.moradaCarga.codPais : CONSTANT_COD_PAIS_NACIONAL,
          moralID: '',
          desativado: false,
          contactoNome: '',
          contactoTelefone: '',
          nomeConta: '',
          nomePais: doc.moradaCarga.nomePais ? doc.moradaCarga.nomePais : CONSTANT_NOME_PAIS_NACIONAL,
          nIdAltern: 0,
          horaInicTran: now,
          dataInicTran: now
        },
        descarga: {
          nConta: '',
          nome: doc.moradaDescarga.nome ? doc.moradaDescarga.nome : '',
          rua: doc.moradaDescarga.rua ? doc.moradaDescarga.rua : '',
          cPost: doc.moradaDescarga.codPostal ? doc.moradaDescarga.codPostal : '',
          localidade: doc.moradaDescarga.localidade ? doc.moradaDescarga.localidade : '',
          nipc: '',
          id: 0,
          observacoes: doc.moradaDescarga.observacoes ? doc.moradaDescarga.observacoes : '',
          morada: doc.moradaDescarga.morada ? doc.moradaDescarga.morada : '',
          codPais: doc.moradaDescarga.codPais ? doc.moradaDescarga.codPais : CONSTANT_COD_PAIS_NACIONAL,
          moralID: doc.moradaDescarga.moralId ? doc.moradaDescarga.moralId : '',
          desativado: false,
          contactoNome: '',
          contactoTelefone: '',
          facIDGuidID: '',
          nomePais: doc.moradaDescarga.nomePais ? doc.moradaDescarga.nomePais : CONSTANT_NOME_PAIS_NACIONAL,
          nIdAltern: 0
        }
      };

      return trDocDadosIdCarga;
    }
    return undefined;
  }

  private _getNNumerDefeitoDocfa(docfanumList: Array<IJsonDocfaNum>): number {
    for (const docfanum of docfanumList) {
      if (!docfanum.encerrado && docfanum.visivelERPCloud) {
        return docfanum.nNumer;
      }
    }
    return 1;
  }
}
