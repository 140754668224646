<div class="pocs">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateType.DETAIL, orientation: formOrientation}">
        <pl-group>
          <label [translate]="'pocs.fields.nConta'"> </label>
          <edit>
            <pl-edit
              type="text"
              [(model)]="model.nConta"
              attrName="nConta"
              [properties]="{disabled: type === stateType.EDIT, validators: {required: {value: true}, maxlength: {value: 75}}}"
              (modelChange)="onNContaChange($event)"
              plAutoFocus
              [plAutoFocusDisabled]="type !== stateType.NEW">
            </pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'pocs.fields.nome'"></label>
          <edit>
            <pl-edit
              type="text"
              [(model)]="model.nome"
              attrName="nome"
              [properties]="{validators: {required: {value: true}, maxlength: {value: 100}}}"
              plAutoFocus
              [plAutoFocusDisabled]="type !== stateType.DETAIL">
            </pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'pocs.fields.tipo'"></label>
          <edit>
            <pl-edit type="radiogroup" attrName="tipoConta" [(model)]="model.tipo" [properties]="{groupItems: tipoPoc, validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <edit>
            <div class="pocs-boolean-inputs">
              <div class="pocs-boolean-input">
                <label [translate]="'pocs.fields.bloqueada'"></label>
                <pl-edit type="boolean" attrName="bloqueada" [(model)]="model.bloqueada" [properties]="{readonly: type === stateType.DETAIL}"></pl-edit>
              </div>

              <div class="pocs-boolean-input" *ngIf="model.tipo === eTipoContaContab.Movimento">
                <label [translate]="'pocs.fields.cc'"></label>
                <pl-edit type="boolean" attrName="cc" [model]="model.cc" (modelChange)="model.cc = $event" [properties]="{readonly: type === stateType.DETAIL}"></pl-edit>
              </div>

              <div class="pocs-boolean-input">
                <label [translate]="'pocs.fields.temRetencao'"></label>
                <pl-edit type="boolean" attrName="temRetencao" [(model)]="model.temRetencao" [properties]="{readonly: type === stateType.DETAIL}"></pl-edit>
              </div>

              <ng-container *ngIf="model.tipo === eTipoContaContab.Movimento">
                <div class="pocs-boolean-input" *ngIf="(type === stateType.NEW && !usaAnaliticaConfig) || type !== stateType.NEW">
                  <label [translate]="'pocs.fields.temCCusto'"></label>
                  <pl-edit type="boolean" attrName="temCCusto" [(model)]="model.temCCusto" [properties]="{readonly: type === stateType.DETAIL || (type === stateType.EDIT && !!usaAnaliticaConfig)}">
                  </pl-edit>
                </div>

                <div class="pocs-boolean-input">
                  <label [translate]="'pocs.fields.temIVA'"></label>
                  <pl-edit type="boolean" attrName="temIVA" [model]="model.temIVA" (modelChange)="onTemControloIVAChange($event)" [properties]="{readonly: type === stateType.DETAIL}"></pl-edit>
                </div>

                <div class="pocs-boolean-input">
                  <label [translate]="'pocs.fields.registaRetencao'"></label>
                  <pl-edit type="boolean" attrName="registaRetencao" [(model)]="model.registaRetencao" [properties]="{readonly: type === stateType.DETAIL}"></pl-edit>
                </div>
              </ng-container>
            </div>
          </edit>
        </pl-group>

        <pl-group *ngIf="model.tipo === eTipoContaContab.Movimento">
          <label [translate]="'pocs.fields.nif'"></label>
          <edit>
            <entity-autocomplete
              entity="nifs"
              attrName="nifs"
              [model]="model"
              (modelChange)="onNContribuinteChange($event)"
              [fieldsMap]="{nContribuinte: 'nif'}"
              [properties]="{validators: {max: {value: 9}}}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="model.tipo === eTipoContaContab.Movimento">
          <pl-group>
            <label [translate]="'pocs.fields.codControloIVADebito'"></label>
            <edit>
              <entity-autocomplete
                entity="controloIVA"
                attrName="controloIVADebito"
                [model]="model"
                (selectedKeyChange)="model.codControloIVADebito = $event"
                (evtSelectedDescriptionChanged)="model.codControloIVADebitoNome = $event"
                [fieldsMap]="{codControloIVA: 'codControloIVADebito', descAbreviada: 'codControloIVADebitoNome'}"
                [output]="codControloIVADebitoOutput"
                [properties]="{disabled: !model.temIVA}">
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'pocs.fields.codControloIVACredito'"></label>
            <edit>
              <entity-autocomplete
                entity="controloIVA"
                attrName="controloIVACredito"
                [model]="model"
                (selectedKeyChange)="model.codControloIVACredito = $event"
                (evtSelectedDescriptionChanged)="model.codControloIVACreditoNome = $event"
                [fieldsMap]="{codControloIVA: 'codControloIVACredito', descAbreviada: 'codControloIVACreditoNome'}"
                [output]="codControloIVACreditoOutput"
                [properties]="{disabled: !model.temIVA}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="model.tipo === eTipoContaContab.Movimento && !model.temIVA">
          <label [translate]="'pocs.fields.dpq06NCampo'"></label>
          <edit>
            <entity-autocomplete
              entity="qivaps"
              attrName="qivaps"
              [model]="model"
              (selectedKeyChange)="model.dpq06NCampo = $event"
              (evtSelectedDescriptionChanged)="model.dpq06NCampoNome = $event"
              [fieldsMap]="{nCampo: 'dpq06NCampo', nomeCampo: 'dpq06NCampoNome'}"
              [output]="dpq06NCampoOutput"
              [properties]="{visible: !model.temIVA}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="model.tipo === eTipoContaContab.Movimento && !model.temIVA">
          <label [translate]="'pocs.fields.daq08NCampoNome'"></label>
          <edit>
            <entity-autocomplete
              entity="qivaas"
              attrName="qivaas0305"
              [model]="model"
              [(selectedKey)]="model.daq08NCampo"
              (evtSelectedDescriptionChanged)="model.daq08NCampoNome = $event"
              [fieldsMap]="{nCampo: 'daq08NCampo', nome: 'daq08NCampoNome'}"
              [output]="daq08NCampoOutput"
              [properties]="{visible: !model.temIVA}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group *ngIf="model.tipo === eTipoContaContab.Movimento && !model.temIVA">
          <label [translate]="'pocs.fields.daq09NCampo'"></label>
          <edit>
            <entity-autocomplete
              entity="qivaas"
              attrName="qivaas0405"
              [model]="model"
              [(selectedKey)]="model.daq09NCampo"
              (evtSelectedDescriptionChanged)="model.daq09NCampoNome = $event"
              [fieldsMap]="{nCampo: 'daq09NCampo', nome: 'daq09NCampoNome'}"
              [output]="daq09NCampoOutput"
              [properties]="{disabled: model.temIVA}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'pocs.fields.codTaxonomia'"></label>
          <edit>
            <div class="row">
              <div class="col-sm-1" style="padding-right: 5px">
                <div class="form-control-align">{{ model.taxonomyReference }}</div>
              </div>

              <div class="col-sm-1" style="padding-left: 5px; padding-right: 5px">
                <div class="form-control-align">{{ model.taxonomyCode }}</div>
              </div>

              <div class="col-sm-10" style="padding-left: 5px">
                <div class="form-control-align">{{ model.taxonomyDescription }}</div>
              </div>
            </div>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
