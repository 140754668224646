import {Component, Injector, OnInit} from '@angular/core';
import {dataSourceTipoEscolhaPenhoras, ITipoEscolhaPenhoras} from '../../rhPenhoras.entity.interface';
import {TEntityListBeforeRequestFn} from '../../../../components/entity/list/entity.list.component.interface';
import {IJsonRHPenhoras} from '../../jsonRHPenhoras.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IEntityServiceQueryRequestConfig} from '../../../../services/entity/entity.service.interface';
import {copy} from 'pl-comps-angular';

@Component({
  selector: 'rhpenhoras-list',
  templateUrl: './rhPenhoras.entity.list.component.html'
})
export class RhPenhorasListComponent extends ModuloEntityListComponent<IJsonRHPenhoras> implements OnInit {
  public estadoPenhora: ITipoEscolhaPenhoras;
  public tipoEscolhaPenhorasDataSource: Array<ITipoEscolhaPenhoras>;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.tipoEscolhaPenhorasDataSource = <Array<ITipoEscolhaPenhoras>>copy(dataSourceTipoEscolhaPenhoras(this._translateService));
    this.estadoPenhora = this.tipoEscolhaPenhorasDataSource[0];
    this.pesquisar = this.pesquisar.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public pesquisar(): Promise<void> {
    return this.refreshList();
  }

  public readonly fnBeforeRequest: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequest(queryRequestConfig);

  private _beforeRequest(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    if (!queryRequestConfig.pesquisa) {
      queryRequestConfig.pesquisa = '';
    } else {
      queryRequestConfig.pesquisa += '&';
    }
    queryRequestConfig.pesquisa += `tipoescolha=${this.estadoPenhora.value}`;
    return queryRequestConfig;
  }
}
