import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonSector} from './jsonSector.entity.interface';
import {ENTITY_NAME_SECTORES} from './sectores.entity.interface';

export const ENTITY_SECTORES: IEntityDefinition<IJsonSector> = {
  name: ENTITY_NAME_SECTORES,
  roles: [ROLE.ERP],
  searchPlaceholder: 'sectores.title_plural',
  pageTitle: 'global.menu.sectores',
  metadata: {
    keyName: 'sectorId',
    fields: [
      {name: 'sectorId', caption: 'sectores.fields.sector', validators: {required: true}},
      {name: 'nome', caption: 'sectores.fields.nome', validators: {required: true}},
      {name: 'idExterno', caption: 'sectores.fields.idExterno', validators: {maxlength: 35}}
    ],
    order: 'sectorId',
    searchFields: 'sectorId,nome,idExterno'
  },
  autocomplete: {
    rowTemplate: '{{sectorId}} - {{nome}}',
    output: 'nome',
    searchFields: 'sectorId,nome,idExterno'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    delete: true,
    search: true,
    filter: true
  }
};
