import {Component, Injector, ViewChild} from '@angular/core';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IJsonEFaturaConfiguracao, IJsonEFaturaLogItem} from '../../jsonEFatura.module.interface';
import {EFaturaService} from '../../eFatura.module.service';
import {IEFaturaLogsFilters} from '../../eFatura.module.interface';
import moment from 'moment';
import {CGCardPanelComponent} from '../../../../components/cg/cardpanel/cardpanel.component';
import {IDevExpressDataGrid} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import CustomStore from 'devextreme/data/custom_store';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';

@Component({
  selector: 'efatura-logs-modal',
  templateUrl: './eFaturaLogs.modal.component.html'
})
export class EFaturaLogsModalComponent extends CGModalComponent<IJsonEFaturaConfiguracao> {
  public modelFilters: IEFaturaLogsFilters;
  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonEFaturaLogItem>;
  private _dataGridInstance: dxDataGrid;
  private _tableSource: Array<IJsonEFaturaLogItem>;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    protected readonly _injector: Injector,
    private readonly _efaturaService: EFaturaService
  ) {
    super(_injector);
    this._tableSource = [];
    this.modelFilters = {
      dataDe: moment(),
      dataAte: moment()
    };

    this.dataGridDefinition = {
      keyExpr: 'eFaturaLOGid',
      columns: [
        {dataField: 'operacaoStr', caption: 'efatura.logs.fields.operacaoStr'},
        {dataField: 'dataCriacaoReg', caption: 'efatura.logs.fields.data'},
        {dataField: 'horaCriacaoReg', caption: 'efatura.logs.fields.hora'},
        {dataField: 'nDocfa', caption: 'efatura.logs.fields.nDocfa'},
        {dataField: 'nNumer', caption: 'efatura.logs.fields.nNumer'},
        {dataField: 'nDocumento', caption: 'efatura.logs.fields.nDocumento'},
        {dataField: 'nDocfaAsString', caption: 'efatura.logs.fields.nDocfaAsString'},
        {dataField: 'grupoPOS', caption: 'efatura.logs.fields.grupoPOS'},
        {dataField: 'nPEDIDODraft', caption: 'efatura.logs.fields.nPEDIDODraft'},
        {dataField: 'tipoLogAsString', caption: 'efatura.logs.fields.tipoLogAsString'},
        {dataField: 'returnCode', caption: 'efatura.logs.fields.returnCode'},
        {dataField: 'descricao', caption: 'efatura.logs.fields.returnCode'}
      ],
      dataSource: new CustomStore({
        load: () => this._tableSource
      }),
      remoteOperations: false
    };
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public readonly fnPesquisar = (): Promise<void> => this._pesquisar();

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _pesquisar(): Promise<void> {
    return this._efaturaService.getLogs(this.modelFilters.dataDe, this.modelFilters.dataAte).then((response) => {
      this._tableSource = response.body;
      if (this._tableSource.length > 0) {
        this._cardPanel.collapse();
      }
      this._dataGridInstance.refresh();
    });
  }
}
