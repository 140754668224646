<pl-form [submit]="close" [plPromise]="promise">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'efaturalogin.title'"></h4>
  </div>

  <div class="modal-body">
    <img alt="Portal" class="d-block simple-login-logo" [src]="'../../../assets/images/logo-at.webp'" />
    <pl-group>
      <label class="mb-2" [translate]="'efaturalogin.fields.meioComunicacaoEFaturaAT'"></label>
      <edit>
        <pl-edit
          type="radiogroup"
          attrName="tipoConta"
          [model]="tipoComunicacao"
          (modelChange)="tipoComunicacaoChanged($event)"
          [properties]="{groupItems: tiposComunicacao, validators: {required: {value: true}}}"></pl-edit>
      </edit>
    </pl-group>

    <ng-container *ngIf="tipoComunicacao === tipoComunicacaoAt.EmTempoReal">
      <div class="d-flex mt-3 mb-3" style="gap: 15px">
        <pl-group style="flex: 50%">
          <label [translate]="'efaturalogin.fields.username'"></label>
          <edit>
            <div class="d-flex" style="gap: 5px; align-items: center">
              <pl-edit style="width: 120px" type="text" [(model)]="modelUsernameNif" plAutoFocus [properties]="{disabled: true}"></pl-edit>
              <span>/</span>
              <pl-edit style="width: 60px" type="text" [model]="modelUsernameId" (modelChange)="modelUsernameIdChanged($event)" plAutoFocus [properties]="{disabled: !isAdmin}"></pl-edit>
            </div>
          </edit>
        </pl-group>

        <pl-group style="flex: 50%">
          <label [translate]="'efaturalogin.fields.password'"></label>
          <edit>
            <pl-edit type="password" [(model)]="model.atPassword" [properties]="{disabled: !isAdmin}"></pl-edit>
          </edit>
        </pl-group>
      </div>

      <pl-edit type="checkbox" [(model)]="model.notificaDia10SeNaoEnviado" [properties]="{label: 'efaturalogin.fields.notificadoAoDia'}"></pl-edit>

      <div class="d-flex mt-3" style="gap: 15px">
        <pl-group style="flex: 50%">
          <label [translate]="'efaturalogin.fields.nDiaDoMes'"></label>
          <edit>
            <pl-edit type="cginteger" [(model)]="model.notificaDia10Dia" [properties]="{disabled: !isAdmin || !model.notificaDia10SeNaoEnviado}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group style="flex: 50%">
          <label [translate]="'efaturalogin.fields.email'"></label>
          <edit>
            <pl-edit type="text" [(model)]="model.notificaDia10Email" [properties]="{disabled: !isAdmin || !model.notificaDia10SeNaoEnviado}"></pl-edit>
          </edit>
        </pl-group>
      </div>
    </ng-container>
  </div>

  <div class="modal-footer" plNavigation>
    <span *ngIf="!isAdmin" [translate]="'efaturalogin.messages.noPermissions'"></span>

    <button type="submit" class="btn btn-sm btn-primary" [disabled]="!isAdmin"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'efaturalogin.saveTitle'"></span></button>
    <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'users.actions.cancel'"></span></button>
  </div>
</pl-form>
