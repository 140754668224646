import {HttpErrorResponse} from '@angular/common/http';
import type {TranslateService} from '@ngx-translate/core';
import type {ICGExceptionError} from '../../core/components/exceptions/exceptions.service.interface';
import type {CGExceptionService} from '../../core/components/exceptions/exceptions.service';

export function catchReasonToMessage({
  reason,
  exceptionService,
  translateService,
  defaultMessage
}: {
  reason: unknown;
  exceptionService: CGExceptionService;
  translateService: TranslateService;
  defaultMessage?: string;
}): string {
  if (defaultMessage) {
    defaultMessage = translateService.instant(defaultMessage);
  } else if (reason instanceof HttpErrorResponse) {
    const exception: ICGExceptionError = exceptionService.get(reason);
    defaultMessage = exception.message;
  } else if (reason instanceof Error) {
    defaultMessage = reason.message;
  } else {
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    defaultMessage = String(reason);
  }
  return defaultMessage;
}
