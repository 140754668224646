<div class="fct-container">
  <pl-tabs>
    <pl-tab caption="fct.tabEmpresas">
      <div *plTabContent [plPromise]="empresasPromise">
        <pl-toolbar [instanceId]="toolbarEmpresasId"></pl-toolbar>

        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="fctEmpresas"
          [dataSource]="dataGridDefinition.dataSource"
          (onCellPrepared)="onMainCellPrepared($event)"
          (onInitialized)="onInitialized($event)"
          (onContextMenuPreparing)="addContextMenuItems($event)"
          (onContentReady)="onContentReady()">
          <div *dxTemplate="let item of 'emitirTipoDocumento'" class="text-center">
            <pl-edit-select
              [(model)]="item.data.emitirDocumento"
              [properties]="{
                disabled: item.data.valorEmAtrasoCent === 0,
                select: {list: this.emitirDocumentoSource},
                disallowClear: true
              }"></pl-edit-select>
          </div>

          <div *dxTemplate="let item of 'messageCellTemplate'">
            <pl-tooltip *ngIf="item.data.mensagens.length" [config]="{text: getTooltipMessage(item.data), container: 'body'}">
              <div style="max-width: 400px; text-overflow: ellipsis; overflow: hidden" *plTooltipContent [innerHTML]="getMessageCellContent(item.data)"></div>
            </pl-tooltip>
          </div>

          <div *dxTemplate="let item of 'actions'" class="text-center">
            <pl-tooltip *ngIf="item.data.loginStatus === loginStatus.REQUIRE" [config]="{text: 'fct.messages.configurarCredentials', container: 'body'}">
              <button *plTooltipContent type="button" class="btn btn-warning btn-xs" [click]="fnConfigCredentials(item.data)" plPromise>
                <i class="fa fa-cog" aria-hidden="true"></i>
              </button>
            </pl-tooltip>

            <button *ngIf="item.data._canViewDetails" type="button" class="btn btn-secondary btn-xs" [click]="fnObterDetalhes(item.data)" plPromise>
              <i class="fa fa-file-text-o" aria-hidden="true"></i>&nbsp;<span [translate]="'fct.strings.detalhes'"></span>
            </button>

            <pl-tooltip *ngIf="item.data._canSendEmail" [config]="{text: 'fct.messages.enviarEmailTooltip', container: 'body'}">
              <button *plTooltipContent type="button" class="btn btn-secondary btn-xs" [click]="fnEnviarEmail(item.data)" plPromise>
                <i class="fa fa-file-text-o" aria-hidden="true"></i>&nbsp;<span [translate]="'fct.strings.enviarEmail'"></span>
              </button>
            </pl-tooltip>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>

    <pl-tab caption="fct.tabDocumentos">
      <div *plTabContent>
        <div class="d-flex">
          <div class="fct-filter-group me-4">
            <label [translate]="'fct.strings.de'"></label>
            <pl-group style="max-width: 150px">
              <edit>
                <pl-edit-datepicker [(model)]="searchFilters.startDate" [properties]="{events: {keydown: keydownStartDate}}" plAutoFocus></pl-edit-datepicker>
              </edit>
            </pl-group>
          </div>

          <div class="fct-filter-group me-4">
            <label [translate]="'global.text.to'"></label>
            <pl-group style="max-width: 150px">
              <edit>
                <pl-edit-datepicker [attrName]="'endDate'" [(model)]="searchFilters.endDate" [properties]="{events: {keydown: keydownEndDate}}"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </div>

          <div class="fct-filter-group me-4">
            <label [translate]="'fct.strings.tipoSearchDoc'"></label>
            <pl-group style="max-width: 240px">
              <edit style="min-width: 205px">
                <pl-edit-select [attrName]="'tipoDocumento'" [(model)]="searchFilters.tipoDocumento" [properties]="{select: {list: searchFiltersTiposDoc}, disallowClear: true}"></pl-edit-select>
              </edit>
            </pl-group>
          </div>

          <div class="fct-filter-group me-4">
            <label [translate]="'fct.strings.empresa'"></label>
            <pl-group style="max-width: 250px">
              <edit>
                <pl-autocomplete
                  [source]="searchFiltersNEmpresa"
                  [model]="searchFilters"
                  (modelChange)="searchFiltersNEmpresaChanged($event)"
                  [rowTemplate]="searchFiltersNEmpresaTemplate"
                  [properties]="{allowInvalid: false, allowEmpty: false, disallowClear: true, validators: {required: {value: true}}}"
                  [output]="'nome'">
                </pl-autocomplete>
              </edit>
            </pl-group>
          </div>

          <div class="fct-filter-group">
            <pl-button type="button" klass="btn-primary" (evtClicked)="searchDocs()"><i class="fa fa-search"></i>&nbsp;<span [translate]="'fct.strings.pesquisar'"></span></pl-button>
          </div>
        </div>

        <hr />

        <dx-data-grid
          [cgDxDataGrid]="dataGridDocsDefinition"
          cgDxDataGridInstanceName="fctDocumentos"
          [dataSource]="dataGridDocsDefinition.dataSource"
          (onInitialized)="onDocsInitialized($event)"
          (onCellPrepared)="onCellPrepared($event)"
          (onContentReady)="onDocsContentReady()">
          <div *dxTemplate="let item of 'actions'" class="text-center">
            <button type="button" class="btn btn-secondary btn-xs" [click]="fnObterDocumento(item.data)" plPromise>
              <i class="fa fa-file-pdf-o" aria-hidden="true"></i>&nbsp;<span [translate]="'fct.tableDefinition.documento'"></span>
            </button>
          </div>
        </dx-data-grid>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
