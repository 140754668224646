import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {AuthService} from '../../services/auth/auth.service';
import {CGModalComponent} from '../../components/cg/modal/cgmodal.component';
import {EFaturaCirculacaoLoginService} from '../../services/efaturacirculacaologin/eFaturaCirculacaoLogin.service';
import {EMPTY_PASSWORD} from '../../../config/constants';
import {IJsonEFaturaCirculacaoLogin} from '../../services/efaturacirculacaologin/eFaturaCirculacaoLogin.service.interface';
import {ROLE} from '../../services/role.const';

@Component({
  selector: 'efaturacirculacaologin-modal',
  templateUrl: './eFaturaCirculacaoLogin.modal.component.html'
})
export class EFaturaCirculacaoLoginModalComponent extends CGModalComponent<IJsonEFaturaCirculacaoLogin> implements OnInit {
  @Input() public username: string;

  public readonly model: IJsonEFaturaCirculacaoLogin;
  public logoAt: string;
  public isAdmin: boolean;
  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _authService: AuthService,
    private readonly _eFaturaCirculacaoLoginService: EFaturaCirculacaoLoginService
  ) {
    super(_injector);
    this.model = {username: '', password: EMPTY_PASSWORD};
    this._authService.hasAnyAuthority([ROLE.ADMIN, ROLE.ADMINBASIC]).then((hasAuthority: boolean) => {
      this.isAdmin = hasAuthority;
    });
  }

  public ngOnInit(): void {
    if (this.username) {
      this.model.username = this.username;
    } else {
      this.promise = this._eFaturaCirculacaoLoginService.get().then((response: HttpResponse<IJsonEFaturaCirculacaoLogin>) => {
        this.model.username = response.body.username ? response.body.username : `${this._configService.configurations.empresa.ncontribuemp}/UserId`;
        this.model.password = response.body.password ? response.body.password : '';
      });
    }
  }

  public async close(): Promise<void> {
    if (!this.model.username) {
      this._plAlertService.error('efaturacirculacaologin.messages.errorUsernameEmpty');
      return;
    }
    if (!this.model.password) {
      this._plAlertService.error('efaturacirculacaologin.messages.errorPasswordEmpty');
      return;
    }
    const response: HttpResponse<IJsonEFaturaCirculacaoLogin> = await this._eFaturaCirculacaoLoginService.set(this.model);
    this.model.username = response.body.username ? response.body.username : this.username;
    this.model.password = response.body.password;
    super.close(this.model);
  }

  protected _onChangedTheme(): void {
    this.logoAt = this.darkTheme ? 'logo-at-light.webp' : 'logo-at.webp';
  }
}
