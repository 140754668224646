<div class="cg-efatura">
  <div class="row" *ngIf="invoice.inexistencia.msg.length">
    <div class="col-sm-12">
      <div style="max-width: 1200px">
        <div class="alert alert-light">
          <div style="display: flex; align-items: center; gap: 15px">
            <i class="fa fa-info-circle text-primary" aria-hidden="true"></i>
            <span [translate]="invoice.inexistencia.msg"></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-lg-6">
      <h5 [ngSwitch]="isInvoiceValid()">
        <span *ngSwitchCase="true" [translate]="'efatura.summary'"></span>
        <span *ngSwitchCase="false" [translate]="'efatura.summaryErros'"></span>
      </h5>

      <div class="efatura-box">
        <div *ngIf="isInvoiceValid()">
          <table style="width: 100%">
            <thead>
              <tr>
                <th></th>
                <th [translate]="configEFatura.temFacturacaoElectronica ? 'efatura.resumoFicheiro.comunicados' : 'efatura.resumoFicheiro.exportado'"></th>
                <th [translate]="configEFatura.temFacturacaoElectronica ? 'efatura.resumoFicheiro.porComunicar' : 'efatura.resumoFicheiro.porExportar'"></th>
                <th [translate]="'global.text.all'"></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><span [translate]="'efatura.invoiceTotal'"></span>:</td>
                <td>{{ invoice.resumoFicheiro.numeroFacturasComunicados }}</td>
                <td>{{ invoice.resumoFicheiro.numeroFacturasPorComunicar }}</td>
                <td>{{ invoice.resumoFicheiro.numeroFacturas }}</td>
              </tr>
              <tr>
                <td><span [translate]="'efatura.totalCredit'"></span>:</td>
                <td>{{ invoice.resumoFicheiro.totalCreditoComunicados | cgcCurrency: 2 }}</td>
                <td>{{ invoice.resumoFicheiro.totalCreditoPorComunicar | cgcCurrency: 2 }}</td>
                <td>{{ invoice.resumoFicheiro.totalCredito | cgcCurrency: 2 }}</td>
              </tr>
              <tr>
                <td><span [translate]="'efatura.totalDebit'"></span>:</td>
                <td>{{ invoice.resumoFicheiro.totalDebitoComunicados | cgcCurrency: 2 }}</td>
                <td>{{ invoice.resumoFicheiro.totalDebitoPorComunicar | cgcCurrency: 2 }}</td>
                <td>{{ invoice.resumoFicheiro.totalDebito | cgcCurrency: 2 }}</td>
              </tr>
              <tr>
                <td><span [translate]="'global.text.total'"></span>:</td>
                <td>{{ invoice.resumoFicheiro.totalComunicados | cgcCurrency: 2 }}</td>
                <td>{{ invoice.resumoFicheiro.totalPorComunicar | cgcCurrency: 2 }}</td>
                <td>{{ invoice.resumoFicheiro.total | cgcCurrency: 2 }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="!isInvoiceValid()">
          <div class="cg-avisos" *ngIf="invoice.avisos.length">
            <div *ngFor="let aviso of invoice.avisos">
              <span class="badge bg-warning">{{ aviso.numero }}</span
              >&nbsp;{{ aviso.descricao }}
            </div>
          </div>

          <div class="cg-erros" *ngIf="invoice.erros.length">
            <div *ngFor="let erro of invoice.erros">
              <span class="badge bg-danger">{{ erro.numero }}</span
              >&nbsp;{{ erro.descricao }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 col-lg-6 mt-3 mt-lg-0">
      <h5 [translate]="'efatura.summaryByType'"></h5>
      <dx-data-grid [cgDxDataGrid]="definitionByIVA" [dataSource]="definitionByIVA.dataSource" cgDxDataGridInstanceName="summaryByType" (onInitialized)="onInitializedByIVA($event)">
        <dxo-load-panel [enabled]="false"></dxo-load-panel>
      </dx-data-grid>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="col-sm-12">
      <dx-data-grid
        [cgDxDataGrid]="definitionByDoc"
        [dataSource]="definitionByDoc.dataSource"
        cgDxDataGridInstanceName="summaryByDoc"
        (onInitialized)="onInitializedByDoc($event)"
        (onCellPrepared)="onCellPrepared($event)"
        (onCellClick)="onCellClick($event)">
        <dxo-load-panel [enabled]="false"></dxo-load-panel>

        <div *dxTemplate="let item of 'detailTemplateDocumento'">
          <documento-faturacao [doc]="item.data._thedoc" [actionPdf]="fnGetPdf" [actionGetDoc]="fnGetDoc"></documento-faturacao>
        </div>
      </dx-data-grid>
    </div>
  </div>
</div>
