import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {ISaftFornecedorAf, ISaftStatus} from './saft.module.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class SaftService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${_apiService.path.restapi}/saft`;
  }

  public downloadFile(): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({url: `${this._path}/download`, responseType: 'blob'});
  }

  public getStatus(): TServiceResponse<ISaftStatus> {
    return this._apiService.get<ISaftStatus>({url: `${this._path}/status`});
  }

  public getFornecedoresAf(): TServiceResponse<Array<ISaftFornecedorAf>> {
    return this._apiService.get<Array<ISaftFornecedorAf>>({url: `${this._path}/fornecedoresAf`});
  }

  public processar(ano: number, mes: number, exportaContabilidade: boolean, exportaFaturacao: boolean, exportaRecibos: boolean): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/processar`,
      params: {ano: ano, mes: mes, exportaRecibos: exportaRecibos, exportaContabilidade: exportaContabilidade, exportaFaturacao: exportaFaturacao}
    });
  }

  public processarPeriodo(ano: number, mesDe: number, mesAte: number, exportaContabilidade: boolean, exportaFaturacao: boolean, exportaRecibos: boolean): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/processarPeriodo`,
      params: {ano: ano, mesDe: mesDe, mesAte: mesAte, exportaRecibos: exportaRecibos, exportaContabilidade: exportaContabilidade, exportaFaturacao: exportaFaturacao}
    });
  }

  public processarAutofaturacao(ano: number, mes: number, nConta: string): TServiceResponse<void> {
    return this._apiService.post({
      url: `${this._path}/processarAutofaturacao`,
      params: {ano: ano, mes: mes, nConta: nConta}
    });
  }

  public cancelProcess(): TServiceResponse<void> {
    return this._apiService.post({url: this.cancelProcessUrl()});
  }

  public cancelProcessUrl(): string {
    return `${this._path}/cancel`;
  }
}
