import {HttpResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import CustomStore from 'devextreme/data/custom_store';
import type dxDataGrid from 'devextreme/ui/data_grid';
import moment from 'moment';
import {isEmpty, isNumber, isObject, isString, Logger, PlAlertService, toInteger} from 'pl-comps-angular';
import {TDate} from '../../../../../../../common/dates';
import {
  IDevExpressDataGrid,
  IDevExpressDataGridColumnCustomizeTextCellInfo,
  TDevExpressDataGridColumnCustomizeTextFn
} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnCellClick, IDevExpressDataGridEventOnInitialized} from '../../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {AuthService} from '../../../../../../services/auth/auth.service';
import {EntityServiceBuilder} from '../../../../../../services/entity/entity.service.builder';
import {ROLE} from '../../../../../../services/role.const';
import {EDebitoCredito} from '../../../../../../datasources/debitocredito/debitoCredito.datasource.interface';
import {ETipoContaContabilidade} from '../../../../../../datasources/tipospoc/tiposPoc.datasource.interface';
import {IJsonDocComercial} from '../../../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {DocumentosService} from '../../../../../../entities/docscomerciais/service/documentos.entity.service';
import {IJsonPOC} from '../../../../../../entities/pocs/jsonPOC.entity.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocContabilidade, IDocsContabilidadeEntityService} from '../../../../docscontabilidade/docsContabilidade.interface';
import {IJsonDocContabilidade} from '../../../../docscontabilidade/jsonDocContabilidade.interface';
import {ContabilidadeEstatisticaService} from '../../../contabilidadeEstatistica.module.service';
import {
  EExtratosDTTipo,
  IExtratosDT,
  IExtratosDTCallback,
  IExtratosDTClifo,
  IExtratosDTOutputParams,
  IExtratosDTStateParams,
  MODULE_NAME_EXTRATOS_DT,
  MODULE_NAME_EXTRATOS_DT_CLIENTES
} from '../../extratosDT.module.interface';
import {IJsonExtratosDT, TServiceHttpQueryResponseExtratosDT} from '../../jsonExtratosDT.module.interface';
import {ENTITY_NAME_CLIENTES, ENTITY_NAME_FORNECEDORES} from '../../../../../../entities/clifos/clifos.entity.interface';
import {ENTITY_NAME_POCS, IPOCSEntityService} from '../../../../../../entities/pocs/pocs.entity.interface';
import {CGCardPanelComponent} from '../../../../../../components/cg/cardpanel/cardpanel.component';
import {ENTITY_NAME_RECIBOS} from '../../../../../../entities/recibos/recibos.entity.interface';
import {IJsonRecibo} from '../../../../../../entities/recibos/jsonRecibo.entity.interface';
import {IApiQueryResponse} from '../../../../../../services/api/api.service.interface';
import {TranslateService} from '@ngx-translate/core';
import {IExcelExportBaseProps, IPdfExportDataGridProps} from '../../../../../../components/devexpress/datagrid/export/devexpress.datagrid.export.interface';
import {TUserSession} from '../../../../../../services/account/jsonUserApi.interface';
import {IDevExpressDataGridState} from '../../../../../../components/devexpress/datagrid/state/devexpress.datagrid.state.interface';
import {hasAuthority} from '../../../../../../../common/utils/roles.utils';
import {Subscription} from 'rxjs';

const DATA_FIELD_POR_PAGAR = 'porPagar';
const LENGTH_YEAR = 4;
const FONT_SIZE_11 = 11;
const FONT_SIZE_12 = 12;
const FONT_SIZE_16 = 16;
const NUMBER_4 = 4;
const NUMBER_5 = 5;
const NUMBER_6 = 6;
const NUMBER_7 = 7;
const NUMBER_10 = 10;
const NUMBER_15 = 15;
const NUMBER_20 = 20;
const NUMBER_25 = 25;
const NUMBER_30 = 30;

@Component({
  selector: 'extratosdt',
  templateUrl: './extratosDT.component.html'
})
export class ExtratosDTComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public moduleType: EExtratosDTTipo;
  @Input() public moduleName: string;
  @Input() public empresaTemContabilidadeDigital: boolean;
  @Input() public empresaAnoEmCursoIRC: number;
  @Input() public params: IExtratosDTStateParams;
  @Input() public hideNConta: boolean;
  @Input() public hideProcessBtn: boolean;
  @Input() public callback: IExtratosDTCallback;
  @Input() public keyDownAction: (event: KeyboardEvent) => void;
  @Input() public readonlyConta: boolean;
  @Output() public readonly evtPesquisar: EventEmitter<IExtratosDTOutputParams>;

  public readonly moduleTypes: typeof EExtratosDTTipo;
  public readonly entityNameClientes: string;
  public readonly entityNameFornecedores: string;

  public filter: string;
  public dataGridDefinition: IDevExpressDataGrid;
  public conta: Partial<IJsonPOC>;
  public clifoNConta: string;
  public deData: TDate;
  public ateData: TDate;
  public form: FormGroupDirective;
  public btnPesquisarDisabled: boolean;
  public gridDefinitionRecibos: IDevExpressDataGrid;
  public hasContabilidade: boolean;
  public model: IExtratosDTClifo;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private readonly _servicePocs: IPOCSEntityService;

  private _subscriptionSession: Subscription;
  private _hasErp: boolean;
  private _firstTime: boolean;
  private _dataGridInstance: dxDataGrid;
  private _cardPanel: CGCardPanelComponent;

  constructor(
    private readonly _logger: Logger,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _contabilidadeEstatisticaService: ContabilidadeEstatisticaService,
    private readonly _docsComerciaisService: DocumentosService,
    private readonly _plAlertService: PlAlertService,
    private readonly _authService: AuthService,
    private readonly _translateService: TranslateService
  ) {
    this.moduleTypes = EExtratosDTTipo;
    this.entityNameClientes = ENTITY_NAME_CLIENTES;
    this.entityNameFornecedores = ENTITY_NAME_FORNECEDORES;
    this.filter = `tipo=${ETipoContaContabilidade.Movimento}`;
    this.callback = {};
    this.hideNConta = false;
    this.hideProcessBtn = true;
    this.btnPesquisarDisabled = true;
    this.readonlyConta = false;
    this.evtPesquisar = new EventEmitter<IExtratosDTOutputParams>();
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._servicePocs = this._entityServiceBuilder.build(ENTITY_NAME_POCS);
    this._exportDataGridToExcel = this._exportDataGridToExcel.bind(this);
    this._exportDataGridToPDF = this._exportDataGridToPDF.bind(this);
  }

  public ngOnInit(): void {
    if (!isNumber(this.moduleType)) {
      this.moduleType = EExtratosDTTipo.Geral;
    }

    this.conta = {
      nConta: this.params.nConta || '',
      nome: this.params.nomeConta || '',
      cc: Boolean(this.params.temCC)
    };
    this._firstTime = isEmpty(this.conta.nConta);

    this.dataGridDefinition = {
      columns: [
        {dataField: 'periodo', dataType: 'string', caption: 'extratosDT.fields.periodo', visible: this.moduleName === MODULE_NAME_EXTRATOS_DT},
        {dataField: 'diario', dataType: 'string', caption: 'extratosDT.fields.diario', visible: this.moduleName === MODULE_NAME_EXTRATOS_DT},
        {dataField: 'nDocAsStr', dataType: 'string', caption: 'extratosDT.fields.nDocInterno'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'extratosDT.fields.dataDoc'},
        {dataField: 'dataVencimento', dataType: 'date', caption: 'extratosDT.fields.dataVencimento', visible: false},
        {dataField: 'nDocExterno', dataType: 'string', caption: 'extratosDT.fields.nDocExterno', width: 200, visible: this.moduleName !== MODULE_NAME_EXTRATOS_DT_CLIENTES},
        {dataField: 'dataDocExterno', dataType: 'date', caption: 'extratosDT.fields.dataDocExterno', width: 95, visible: this.moduleName !== MODULE_NAME_EXTRATOS_DT_CLIENTES},
        {dataField: 'descricao', dataType: 'string', caption: 'extratosDT.fields.descricao', width: 200},
        {dataField: 'valorDebito', dataType: 'double', caption: 'extratosDT.fields.valorDebito', customizeText: this._fnCustomizeText},
        {dataField: 'valorCredito', dataType: 'double', caption: 'extratosDT.fields.valorCredito', customizeText: this._fnCustomizeText},
        {
          dataField: DATA_FIELD_POR_PAGAR,
          dataType: 'double',
          caption: 'extratosDT.fields.porPagar',
          visible: this.conta.cc,
          showInColumnChooser: this.conta.cc
        },
        {dataField: 'saldo', dataType: 'double', caption: 'global.text.saldo'},
        {dataField: 'abreviaturaMoeda', dataType: 'string', caption: 'extratosDT.fields.abreviaturaMoeda', visible: false},
        {dataField: 'cambio', dataType: 'double', caption: 'extratosDT.fields.cambio', visible: false},
        {dataField: 'debitoME', dataType: 'double', caption: 'extratosDT.fields.debitoME', visible: false},
        {dataField: 'creditoME', dataType: 'double', caption: 'extratosDT.fields.creditoME', visible: false},
        {
          dataField: 'temDocDigital',
          dataType: 'boolean',
          caption: 'docscontabilidade.fields.temDocDigital',
          width: 100,
          visible: this.empresaTemContabilidadeDigital,
          showInColumnChooser: this.empresaTemContabilidadeDigital,
          allowExporting: false
        }
      ],
      dataSource: new CustomStore({
        key: ['extPocCabID', 'lancImput'],
        load: () => this._extratos()
      }),
      allowColumnReordering: false,
      columnHidingEnabled: false,
      export: {filename: 'global.menu.extratosDT', propertiesExcel: {customizeWorksheet: this._exportDataGridToExcel}, propertiesPdf: {customizePdf: this._exportDataGridToPDF}},
      scrolling: {mode: 'standard', rowRenderingMode: 'virtual'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      height: '70vh',
      masterDetail: {enabled: false, template: 'detailTemplateDocComercialContab'},
      remoteOperations: false,
      searchPanel: {visible: false},
      sorting: {mode: 'none'},
      paging: {enabled: false}
    };

    this._subscriptionSession = this._authService.identityAsObservable().subscribe((session: TUserSession) => {
      this.hasContabilidade = hasAuthority(session, ROLE.CONTABILIDADE);
      this._hasErp = hasAuthority(session, ROLE.ERP);

      this._evaluateMasterDetailVisibility();
    });

    this.gridDefinitionRecibos = {
      columns: [
        {dataField: 'cab.nDocAsStr', dataType: 'string', caption: 'recibos.fields.numero', allowEditing: false, allowHiding: false},
        {dataField: 'cab.total', dataType: 'double', caption: 'global.text.total', allowEditing: false, allowHiding: false},
        {dataField: 'cab.abreviaturaMoeda', dataType: 'double', caption: 'recibos.fields.abreviaturaMoeda', allowEditing: false, allowHiding: false}
      ],
      masterDetail: {enabled: true, template: 'templateDetailRecibo'}
    };

    if (isNumber(this.empresaAnoEmCursoIRC)) {
      this.deData = moment(this.empresaAnoEmCursoIRC, 'Y').startOf('year');
      this.ateData = this.deData.clone().add(1, 'year').endOf('year');
    }

    if (this.moduleName && this.moduleName !== MODULE_NAME_EXTRATOS_DT) {
      this._contabilidadeEstatisticaService.getRadicalConta(this.moduleName).then((response: HttpResponse<string>) => {
        this.filter = !isEmpty(response.body) ? `${this.filter}&temCC=-1&nConta=%${response.body}%` : this.filter;
      });
    }
    if (this.conta.nConta) {
      this.changedClifo(this.conta.nConta);
    }
  }

  public ngOnChanges({callback, params}: SimpleChanges): void {
    if (callback) {
      const cb: IExtratosDTCallback = callback.currentValue;
      if (isObject(cb)) {
        cb.refresh = () => this._refresh();
      }
    }
    if (params) {
      this.params = params.currentValue;
      if (this.params.nConta && this._dataGridInstance) {
        this._dataGridInstance.refresh();
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionSession.unsubscribe();
  }

  public changedConta(conta: string | IJsonPOC): void {
    this.evtPesquisar.emit({ateDate: undefined, deData: undefined, nConta: '', semResgistos: true});
    const contaIsString = isString(conta);
    if (!conta || contaIsString) {
      this.conta.nConta = contaIsString ? conta : undefined;
      this.conta.nome = undefined;
      this.conta.cc = false;
    } else {
      this.conta = conta;
    }
    const cc = Boolean(this.conta?.cc);
    if (this._dataGridInstance && this._dataGridInstance.columnOption(DATA_FIELD_POR_PAGAR, 'visible') !== cc) {
      this._dataGridInstance.columnOption(DATA_FIELD_POR_PAGAR, 'visible', cc);
      this._dataGridInstance.columnOption(DATA_FIELD_POR_PAGAR, 'showInColumnChooser', cc);
    }
  }

  public changedPeriodo(): void {
    this.evtPesquisar.emit({ateDate: undefined, deData: undefined, nConta: '', semResgistos: true});
  }

  public stateStoringChange(event: IDevExpressDataGridState): void {
    const col = event.columns.find((c) => c.dataField === DATA_FIELD_POR_PAGAR);
    if (col) {
      col.visible = false;
    }
  }

  public changedClifo(nConta: string): void {
    this.evtPesquisar.emit({ateDate: undefined, deData: undefined, nConta: '', semResgistos: true});
    this.clifoNConta = nConta;
    if (this.clifoNConta) {
      this._servicePocs
        .get({id: this.clifoNConta, reportExceptions: false})
        .then((response: HttpResponse<IJsonPOC>) => {
          this.changedConta(response.body);
        })
        .catch(() => {
          this.changedConta(undefined);
        });
    } else {
      this.changedConta(this.clifoNConta);
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IExtratosDT>): void {
    if (this.hasContabilidade || this._hasErp) {
      devExpressDataGridExpandDetailHandler(event, () => this._onDetail(event.data)).catch((reason: unknown) => {
        this._logger.error(reason);
      });
    }
  }

  public readonly fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this._getPdf(doc);

  public readonly fnPesquisar = (): Promise<void> => this._refresh();

  @ViewChild('cardPanel')
  public set cardPanel(value: CGCardPanelComponent) {
    this._cardPanel = value;
  }

  private _refresh(): Promise<void> {
    if (!this.form.valid) {
      this._plAlertService.error('extratosDT.msg.contaInvalida');
      return Promise.resolve();
    }
    return this._dataGridInstance.refresh();
  }

  private _extratos(): Promise<Array<IJsonExtratosDT>> {
    if (this._firstTime) {
      this._firstTime = false;
      return Promise.resolve(undefined);
    }
    return this._contabilidadeEstatisticaService.getExtratosDT(this.conta.nConta, this.deData, this.ateData).then((response: TServiceHttpQueryResponseExtratosDT) => {
      let myLastSaldo = response.body.saldoAntesData;
      for (const extrato of response.body.list) {
        if (extrato.dc === EDebitoCredito.Debito) {
          extrato.saldo = myLastSaldo + extrato.valor;
        } else {
          extrato.saldo = myLastSaldo - extrato.valor;
        }
        myLastSaldo = extrato.saldo;
      }

      if (response.body.list.length === 0) {
        this._cardPanel.focusFirstElement();
        this._plAlertService.info('global.text.searchNoData');
      } else {
        this._cardPanel.collapse();
      }

      this.evtPesquisar.emit({
        nConta: this.conta.nConta,
        deData: this.deData,
        ateDate: this.ateData,
        semResgistos: response.body.list.length === 0
      });
      return response.body.list;
    });
  }

  private async _onDetail(extrato: IExtratosDT): Promise<void> {
    const promises: Array<Promise<void>> = [];
    if (!extrato._docContabilidade && extrato.extPocCabID) {
      const ano: number = toInteger(extrato.periodo.substring(0, LENGTH_YEAR));
      promises.push(
        this._serviceDocsContabilidade.get({id: extrato.extPocCabID, params: {ano: ano}}).then((response: HttpResponse<IJsonDocContabilidade>) => {
          extrato._docContabilidade = response.body;
          extrato._docContabDigital =
            this.empresaTemContabilidadeDigital && extrato.temDocDigital
              ? {
                  extPocCabID: extrato.extPocCabID,
                  periodo: extrato.periodo,
                  nDiario: extrato.diario,
                  nDocInterno: extrato.nDocInterno,
                  dataDoc: extrato.dataDoc
                }
              : undefined;
        })
      );
    }
    if (this._hasErp && !extrato._docComercial && extrato.faccbId > 0) {
      promises.push(
        this._docsComerciaisService.getDoc(extrato.faccbId).then((response: HttpResponse<IJsonDocComercial>) => {
          extrato._docComercial = response.body;
        })
      );
    }
    if (extrato.lancImput) {
      promises.push(
        this._entityServiceBuilder
          .build<IJsonRecibo>(ENTITY_NAME_RECIBOS)
          .query({url: `${extrato.extPocCabID}/reciboextrato?lancinput=${extrato.lancImput}`})
          .then((response: HttpResponse<IApiQueryResponse<IJsonRecibo>>) => {
            if (response) {
              extrato._recibos = response.body.list;
            }
          })
      );
    }
    return Promise.all(promises).then(() => undefined);
  }

  private _getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._docsComerciaisService.getPdf(doc, doc.cab.nDocumento === 0 || !doc.cab.terminado);
  }

  private async _exportDataGridToExcel(options: IExcelExportBaseProps): Promise<void> {
    options.keepColumnWidths = true;
    options.topLeftCell = {row: NUMBER_4, column: 1};

    const userSession: TUserSession = await this._authService.identity();

    const worksheet = options.worksheet;

    const headerRow1 = worksheet.getRow(1);
    headerRow1.getCell(1).value = `${userSession.erp.nEmpresa} - ${userSession.erp.nomeEmpresa}`;
    headerRow1.getCell(1).alignment = {horizontal: 'center'};
    headerRow1.getCell(1).font = {size: FONT_SIZE_16, bold: true};
    worksheet.mergeCells(1, 1, 1, NUMBER_7);

    const headerRow2 = worksheet.getRow(2);
    const accountCaption: string = this._translateService.instant('extratosDT.export.account');
    headerRow2.getCell(1).value = `${accountCaption}: ${this.conta.nConta} - ${this.conta.nome}`;
    headerRow2.getCell(1).alignment = {horizontal: 'left'};
    headerRow2.getCell(1).font = {size: FONT_SIZE_12, bold: true};

    const dateCaption: string = this._translateService.instant('global.text.date');
    const toCaption: string = this._translateService.instant('global.text.to');
    const deData: string = moment(this.deData).format('DD-MM-YYYY');
    const ateData: string = moment(this.ateData).format('DD-MM-YYYY');
    headerRow2.getCell(NUMBER_6).value = `${dateCaption}: ${deData} ${toCaption} ${ateData}`;
    headerRow2.getCell(NUMBER_6).alignment = {horizontal: 'left'};
    headerRow2.getCell(NUMBER_6).font = {size: FONT_SIZE_11, bold: true};
    worksheet.mergeCells(2, 1, 2, NUMBER_5);
    worksheet.mergeCells(2, NUMBER_6, 2, NUMBER_7);
  }

  private async _exportDataGridToPDF(options: IPdfExportDataGridProps): Promise<void> {
    options.topLeft = {x: 1, y: NUMBER_25};
    const userSession: TUserSession = await this._authService.identity();

    const pageWidth = options.jsPDFDocument.internal.pageSize.getWidth();
    const header = `${userSession.erp.nEmpresa} - ${userSession.erp.nomeEmpresa}`;
    const headerWidth = options.jsPDFDocument.getTextDimensions(header).w;

    options.jsPDFDocument.setFontSize(FONT_SIZE_16);
    options.jsPDFDocument.text(header, (pageWidth - headerWidth) / 2, NUMBER_20);

    const accountCaption: string = this._translateService.instant('extratosDT.export.account');
    const conta = `${accountCaption}: ${this.conta.nConta} - ${this.conta.nome}`;

    options.jsPDFDocument.setFontSize(FONT_SIZE_12);
    options.jsPDFDocument.text(conta, NUMBER_15, NUMBER_30);

    const dateCaption: string = this._translateService.instant('global.text.date');
    const toCaption: string = this._translateService.instant('global.text.to');
    const deData: string = moment(this.deData).format('DD-MM-YYYY');
    const ateData: string = moment(this.ateData).format('DD-MM-YYYY');
    const date = `${dateCaption}: ${deData} ${toCaption} ${ateData}`;
    const dateWidth = options.jsPDFDocument.getTextDimensions(date).w;
    options.jsPDFDocument.setFontSize(FONT_SIZE_11);
    options.jsPDFDocument.text(date, pageWidth - dateWidth - NUMBER_10, NUMBER_30);
  }

  private _customizeText(cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string {
    if (cellInfo.target === 'row' && cellInfo.value === 0) {
      return '';
    }
    return cellInfo.valueText;
  }

  private _evaluateMasterDetailVisibility(): void {
    if (!this._dataGridInstance) {
      this.dataGridDefinition.masterDetail.enabled = this.hasContabilidade || this._hasErp;
    } else {
      this._dataGridInstance.option('masterDetail.enabled', this.hasContabilidade || this._hasErp);
    }
  }

  private readonly _fnCustomizeText: TDevExpressDataGridColumnCustomizeTextFn = (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo) => this._customizeText(cellInfo);
}
