import {IJsonRHSalToTrf} from './jsonRHSalToTrf.module.interface';

export enum ERhTransSalTab {
  RhTransBancariaVencimentos,
  RhTransBancariaTicketsRefeicao
}

export interface IRHSalToTrf extends IJsonRHSalToTrf {
  valid: boolean;
}

export interface IRHSalToTrfTotals {
  caption: string;
  totalLinhas: number;
  totalValor: number;
}

export const MODULE_NAME_RH_TRANS_SAL = 'rhtranssal';
