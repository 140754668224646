import {IEntityDefinition} from '../../../components/entity/entity.definition.interface';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {IJsonAtivos} from './jsonAtivos.entity.interface';
import {IRestAtivosCommand} from './restAtivosCommands.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';

export enum EAtivosCommandType {
  DefaultData = 'DefaultData',
  ValoresContabilisticosEFiscais = 'ValoresContabilisticosEFiscais',
  ContasAndIVAFromNContaAtivo = 'ContasAndIVAFromNContaAtivo',
  ValidaTaxasCodAmortizacao = 'ValidaTaxasCodAmortizacao',
  DataPorBaseParaCalculo = 'DataPorBaseParaCalculo',
  Classificadores = 'Classificadores',
  Planning = 'Planning'
}

export type TAtivosCommandResult = Promise<IJsonAtivos>;

export interface IAtivosNewStateParams {
  defaultData: IJsonAtivos;
  contabValorAquisicao: string;
  docExtPocCabId: string;
  model?: IJsonAtivos;
  valorInvEmCursoDisponivelAnoN?: string;
  valorInvEmCursoDisponivelAnoN1?: string;
  fromInvestimentosEmCurso?: boolean;
}

export interface IAtivosEntityService extends IEntityService<IJsonAtivos> {
  sendAtivosCommand<T = IRestAtivosCommand>(id: number, commandName: EAtivosCommandType, command: IRestAtivosCommand): TServiceResponse<T>;
}

export interface IAtivosEntityDefinition extends IEntityDefinition<IJsonAtivos, IAtivosEntityService> {}

export const ENTITY_NAME_ATIVOS = 'ativos';
