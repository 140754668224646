import {TServiceResponse} from '../../services/api/api.service.interface';

export enum EGenericImporterType {
  Artigos,
  Clientes,
  Fornecedores,
  OutrosDevedoresCredores,
  Dgemps,
  CCClientes,
  CCFornecedores,
  ImpSaftContab
}

export interface IGenericImporterExecutor {
  getModeloImportacao(type: EGenericImporterType): TServiceResponse<Blob>;

  getExecuteImportacaoUrl(): string;

  executeImportacao(type: EGenericImporterType): TServiceResponse<IJsonModelosImportacaoExecuteResult>;
}

export interface IJsonModelosImportacaoExecuteResult {
  successCount: number;
  errorCount: number;
  fileName: string;
  fileWithErrors: Array<number>;
}
