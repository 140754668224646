import {NgModule} from '@angular/core';
import {PlSidebarComponent} from './sidebar.component';
import {PlSidebarTitleComponent} from './components/sidebar.title.component';

export * from './sidebar.interface';
export * from './sidebar.component';
export * from './components/sidebar.title.component';

const DECLARATIONS = [PlSidebarComponent, PlSidebarTitleComponent];

@NgModule({
  imports: DECLARATIONS,
  exports: DECLARATIONS
})
export class PlSidebarModule {}
