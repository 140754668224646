<div class="mapa-pagamentos-detalhes-modal">
  <div class="modal-header">
    <h5 class="modal-title" [translate]="'mapapagam.texts.detalhesPagamento'"></h5>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12" *ngIf="documentosSource.length > 1">
        <pl-group>
          <label [translate]="'mapapagam.texts.documento'"></label>
          <edit>
            <pl-edit-select [model]="docSelectedKey" (modelChange)="onDocumentChange($event)" [properties]="{select: {list: documentosSource}, disallowClear: true}"></pl-edit-select>
          </edit>
        </pl-group>
      </div>
      <div class="col-md-12" *ngIf="documentosSource.length === 0 || docSelected?.detalhes.length === 0">
        <div class="alert alert-info"><i class="fa fa-fw fa-info-circle" aria-hidden="true"></i>&nbsp;<span [translate]="'mapapagam.texts.semDetalhesPagamento'"></span></div>
      </div>
      <div class="col-md-12">
        <div class="d-flex mapa-pagamentos-detalhes-flex">
          <div *ngFor="let item of docSelected?.detalhes">
            <pl-group>
              <label [translate]="item.text"></label>
              <edit>
                <pl-edit type="text" [model]="item.value" [properties]="{disabled: true}"></pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <button type="button" class="btn btn-sm btn-light" (click)="dismiss()"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.close'"></span></button>
  </div>
</div>
