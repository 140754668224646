/**
 * CentralGest Amalia
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type IntentType = 'INTENT' | 'WELCOME' | 'FALLBACK';

export const IntentType = {
    Intent: 'INTENT' as IntentType,
    Welcome: 'WELCOME' as IntentType,
    Fallback: 'FALLBACK' as IntentType
};

