<div class="doccontabilidade entity-detail-form">
  <config-options
    [instanceName]="configOptionsInstanceName"
    [groupName]="configOptionsGroupName"
    [disabled]="hasPreDefinido"
    [toolbarInstanceName]="toolbarInstanceName"
    [toolbarButton]="btnConfigOptions"
    [sortKeys]="false">
  </config-options>

  <pl-split-view [initialSizeLeft]="60" [hideRightSide]="!contabilidadeDigital" (evtSizeValuesChanged)="onResizerValuesChanged()">
    <div *plSplitViewLeftSide>
      <pl-form formInstanceName="doccontabilidade" [properties]="{orientation: 'horizontal'}">
        <div class="doccontabilidade-cabecalho">
          <pl-group *ngIf="!editing && !simulation && optionShowFieldPredefinido">
            <label [translate]="'docscontabilidade.fields.preDefinido'"></label>
            <edit>
              <entity-autocomplete entity="predefinidoscontabilidade" attrName="predefinido" [model]="predefinido" (modelChange)="loadPreDefinido($event)" [properties]="propertiesPreDefinido">
              </entity-autocomplete>
            </edit>
            <actions *ngIf="hasPreDefinido">
              <button
                type="button"
                class="btn btn-sm btn-danger"
                [translate]="!predefinido.isGeneric ? 'docscontabilidade.clearPreDefinido' : 'docscontabilidade.clearSuggestion'"
                [attr.title]="(!predefinido.isGeneric ? 'docscontabilidade.clearPreDefinido' : 'docscontabilidade.clearSuggestionTitle') | translate"
                [disabled]="locked"
                (click)="contabilidadeDigitalIgnorePredefinido = true; clearPreDefinido()"></button>
            </actions>
          </pl-group>

          <pl-group>
            <pl-group *ngIf="visiblePeriodo">
              <label class="doccontabilidade-periodo">
                <div *ngIf="hintMarcadoComoConsistente">
                  <i class="fa fa-exclamation-triangle doccontabilidade-warning" aria-hidden="true" [attr.title]="hintMarcadoComoConsistente"></i>
                </div>
                <div class="doccontabilidade-periodo-label" [translate]="'docscontabilidade.doc.cab.periodo'"></div>
              </label>
              <edit>
                <entity-autocomplete
                  entity="periodos"
                  attrName="periodo"
                  [model]="model"
                  (selectedKeyChange)="onPeriodoChange($event)"
                  [fieldsMap]="fieldsMapPeriodo"
                  [properties]="propertiesPeriodo"
                  [customActions]="customActionsPeriodo">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group *ngIf="visibleDiario">
              <label [translate]="'docscontabilidade.doc.cab.nDiario'"></label>
              <edit>
                <entity-autocomplete entity="diarios" attrName="diario" [model]="model" (selectedKeyChange)="onDiarioChange($event)" [fieldsMap]="fieldsMapDiario" [properties]="propertiesDiario">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group>
            <pl-group [hasError]="model._invalidDataLancamento" *ngIf="visibleDataLancamento" class="text-nowrap">
              <label [translate]="'docscontabilidade.doc.cab.dataLancamento'"></label>
              <edit>
                <pl-edit-datepicker attrName="dataLancamento" [model]="model.dataLancamento" (modelChange)="onDataLancamentoChange($event)" [properties]="propertiesDataLancamento">
                  <div *inputGroup="{klass: 'hollow-form'}">
                    <pl-tooltip [config]="tooltipDataLancamento">
                      <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
                    </pl-tooltip>
                  </div>
                </pl-edit-datepicker>
              </edit>
            </pl-group>

            <pl-group *ngIf="visibleDataDoc" class="text-nowrap">
              <label [translate]="'docscontabilidade.doc.cab.dataDoc'"></label>
              <edit>
                <pl-edit-datepicker attrName="dataDoc" [model]="model.dataDoc" (modelChange)="onDataDocChange($event)" [properties]="propertiesDataDoc">
                  <div *inputGroup="{klass: 'hollow-form'}">
                    <pl-tooltip [config]="tooltipDataDoc">
                      <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
                    </pl-tooltip>
                  </div>
                </pl-edit-datepicker>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group *ngIf="visibleNContribuinte || (optionShowFieldDataVencimento && visibleDataVencimento)">
            <pl-group [hasError]="model._invalidNif && !optionShowFieldLinhaNif" *ngIf="visibleNContribuinte">
              <label [translate]="'docscontabilidade.doc.cab.nContribuinte'"></label>
              <edit>
                <div>
                  <entity-autocomplete
                    entity="pocs"
                    attrName="nContribuinte"
                    [model]="model"
                    (modelChange)="onNContribuinteChange($event)"
                    [filter]="'nif<>'"
                    [fieldsMap]="fieldsMapNContribuinte"
                    [properties]="propertiesNContribuinte"
                    [maintenanceProperties]="{maintenanceEntity: 'nifs'}"
                    output="nif"
                    outputKey="nif">
                  </entity-autocomplete>
                </div>
              </edit>
            </pl-group>

            <pl-group *ngIf="optionShowFieldDataVencimento && visibleDataVencimento">
              <label [translate]="'docscontabilidade.doc.cab.dataVencimento'"></label>
              <edit>
                <pl-edit-datepicker attrName="dataVencimento" [model]="model.dataVencimento" (modelChange)="onDataVencimentoChange($event)" [properties]="propertiesDataVencimento">
                </pl-edit-datepicker>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group *ngIf="visibleDescricao">
            <label [translate]="'docscontabilidade.doc.cab.descricao'"></label>
            <edit>
              <pl-edit-text attrName="descricao" [model]="model.descricao" (modelChange)="onDescricaoChange($event)" [properties]="propertiesDescricao"></pl-edit-text>
            </edit>
          </pl-group>

          <pl-group>
            <pl-group *ngIf="visibleDescritivo" class="text-nowrap">
              <label [translate]="'docscontabilidade.doc.cab.codDescritivo'"></label>
              <edit>
                <entity-autocomplete
                  entity="descritivos"
                  attrName="descritivo"
                  [model]="model"
                  (selectedKeyChange)="onDescritivoChange($event)"
                  [fieldsMap]="fieldsMapDescritivo"
                  [properties]="propertiesDescritivo">
                </entity-autocomplete>
              </edit>
            </pl-group>

            <pl-group *ngIf="visibleNDocExterno" class="text-nowrap">
              <label [translate]="'docscontabilidade.doc.cab.documentoExterno'"></label>
              <edit>
                <pl-edit-text attrName="nDocExterno" [model]="model.documentoExterno" (modelChange)="onNDocExternoChange($event)" [properties]="propertiesNDocExterno"></pl-edit-text>
              </edit>
            </pl-group>
          </pl-group>

          <pl-group *ngIf="optionShowFieldMoeda">
            <label [translate]="'docscontabilidade.doc.cab.moeda'"></label>
            <edit>
              <div class="doccontabilidade-moeda">
                <pl-tooltip [config]="tooltipMoeda">
                  <entity-autocomplete *plTooltipContent entity="moeda" attrName="moeda" [model]="model" (modelChange)="onMoedaChange($event)" [properties]="propertiesMoeda"></entity-autocomplete>
                </pl-tooltip>
              </div>
            </edit>
          </pl-group>

          <div class="row" *ngIf="model.campoErroInformativo || erroInformativo">
            <div class="col-md-8 offset-md-2">
              <pl-alert class="pl-alert-sm" type="danger" theme="light" [plAnimationShake]="model.campoErroInformativo || erroInformativo" (evtClosed)="closedErroInformativo()">
                <div *ngIf="model.campoErroInformativo">{{ model.campoErroInformativo }}</div>
                <div *ngIf="erroInformativo" [translate]="erroInformativo"></div>
              </pl-alert>
            </div>
          </div>

          <div class="row" *ngIf="isDiferimento && !loadingAnularCalculoDiferimento">
            <div class="col-md-8 offset-md-2">
              <pl-alert class="pl-alert-sm" type="info" theme="light" [closeable]="false">
                <div *ngIf="calculouDiferimento || model.isDocPrincipalDiferimento">
                  <span [translate]="'docscontabilidade.text.calculouDiferimentos1'"></span>
                  <button type="button" class="btn btn-link p-0" [translate]="'docscontabilidade.text.calculouDiferimentos2'" (click)="anularCalculoDiferimento()"></button>
                  .
                </div>
                <div *ngIf="calculouDiferimento">
                  <span [translate]="'docscontabilidade.text.calculouDiferimentos3'"></span>
                </div>
                <div *ngIf="!calculouDiferimento && !model.isDocPrincipalDiferimento" [translate]="'docscontabilidade.text.calculouDiferimentosLocked'"></div>
              </pl-alert>
            </div>
          </div>
          <hr />
        </div>

        <div class="doccontabilidade-linhas">
          <pl-group *ngIf="optionSeletorValorComIvaIncluido || (contabilidadeDigital && optionSeletorObtemDadosDocDigital)">
            <pl-group *ngIf="contabilidadeDigital && optionSeletorObtemDadosDocDigital">
              <label>&nbsp;</label>
              <edit>
                <pl-edit-switch [model]="optionObtemDadosDocDigital" (modelChange)="setObtemDadosDocDigital($event)" [properties]="{label: 'docscontabilidade.configs.obtemDadosDocDigital'}">
                </pl-edit-switch>
              </edit>
            </pl-group>

            <pl-group *ngIf="optionSeletorValorComIvaIncluido">
              <label>&nbsp;</label>
              <edit>
                <pl-edit-switch [model]="optionValorComIvaIncluido" (modelChange)="setValorComIvaIncluido($event)" [properties]="{label: 'docscontabilidade.configs.valorComIvaIncluido'}">
                </pl-edit-switch>
              </edit>
            </pl-group>
          </pl-group>

          <div
            class="table-flex table-flex-hover table-flex-sm table-flex-docscontabilidade"
            [plTableNavigation]
            [onRemoveLine]="fnRemoveEmptyLine"
            [selectorOverrides]="tableNavigationSelectorOverrides"
            [plPromise]="promiseUI">
            <div class="table-flex-head">
              <div class="table-flex-tr">
                <div class="table-flex-th" *ngFor="let field of definitionUI">
                  <div class="table-flex-th-content" [translate]="field.caption"></div>
                </div>

                <div class="table-flex-th table-flex-appendix header-add-line">
                  <button type="button" class="btn btn-primary btn-xs action-add-line" [disabled]="locked" (click)="addLine()">
                    <i class="fa fa-fw fa-plus"></i>
                  </button>
                </div>

                <div class="table-flex-th table-flex-appendix header-add-line" *ngIf="model._hasLinhasImputadas"></div>
              </div>
            </div>

            <div class="table-flex-body" [ngSwitch]="docNotInitialized">
              <div class="table-flex-tr" *ngSwitchCase="true">
                <div class="table-flex-td" [innerHTML]="'docscontabilidade.erros.initDoc' | translate"></div>
              </div>

              <ng-container *ngSwitchCase="false">
                <div
                  class="table-flex-tr doccontabilidade-linha"
                  *ngFor="let linha of model.linhas; index as $index; trackBy: trackByIndex"
                  [class.active]="activeNLanc && linha.nLanc === activeNLanc"
                  [class.doccontabilidade-linha-is-imputacao]="linha._isImputacao"
                  (mouseenter)="linhaInfoMouseEnter(linha, $event)"
                  (mouseleave)="linhaInfoMouseLeave()">
                  <div class="table-flex-td" *ngFor="let definitionItem of definitionUI" [ngClass]="definitionItem.cssClass">
                    <div class="table-flex-td-content">
                      <ng-container *ngTemplateOutlet="definitionItem.templateRef; context: {model: model, linha: linha}"></ng-container>
                    </div>
                  </div>

                  <div class="table-flex-td table-flex-appendix" *ngIf="linha._nDocImput">
                    <button type="button" class="btn btn-primary btn-xs" [ngbTooltip]="'docscontabilidade.viewDocImputado' | translate" (click)="openDocImputacao(linha)">
                      <i class="fa fa-fw fa-file-text-o"></i>
                    </button>
                  </div>

                  <div class="table-flex-td table-flex-appendix">
                    <button
                      type="button"
                      class="btn btn-danger btn-xs action-remove-line"
                      *ngIf="linha._deletable && (!hasPreDefinido || predefinido.isGeneric || linha._predefinidoFake)"
                      [disabled]="locked"
                      [click]="fnRemoveLine($index)"
                      plPromise>
                      <i class="fa fa-fw fa-trash-o"></i>
                    </button>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div *ngIf="model.linhas">
          <doccontabilidade-totais [doccontabilidade]="model" [selectedAccount]="model.linhas[itemInfo]" [configDecimais]="configContabilidade.decimais"></doccontabilidade-totais>
        </div>
      </pl-form>
    </div>

    <div *plSplitViewRightSide class="doccontabilidade-contabilidade-digital-docviewer" [class.docviewer-footer-collapsed]="docViewerFooterCollapsed">
      <contabilidadedigital-docviewer-recolha
        [documentsService]="contabilidadeDigitalDocumentsService"
        [doc]="contabilidadeDigitalDoc"
        [obtemDadosDocDigital]="optionObtemDadosDocDigital"
        [showFooter]="true"
        [showFooterCollapse]="!isMobile"
        [footerCollapsed]="docViewerFooterCollapsed"
        [nDocumento]="editing && model.temDocDigital ? model.nDocumento : ''"
        [callback]="callbackDocViewer"
        (footerCollapsedChange)="changedDocViewerFooterCollapsed($event)"
        (evtChangedAttachment)="changedContabilidadeDigitalAttachment($event)"
        (evtAttachedVerbete)="contabilidadeDigitalAttachedVerbete()">
      </contabilidadedigital-docviewer-recolha>
    </div>
  </pl-split-view>

  <ng-template #templateNContaDebito let-model="model" let-linha="linha">
    <doccontabilidade-ncontadebito
      [model]="linha.nContaDebito"
      [linha]="linha"
      [docContabilidade]="model"
      [predefinido]="predefinido"
      [locked]="locked"
      [disableAutoFocus]="disableAutoFocus"
      (evtModelChanged)="onLinhaNContaChange($event)"
      (evtFocus)="onLinhaFieldFocus($event.linha, $event.event)"
      (evtBlur)="onLinhaFieldBlur($event.linha, $event.event)"
      (evtKeydown)="onLinhaFieldKeyDown($event.event, $event.linha._index)"
      (evtActionExtratos)="customActionExtratos($event)"
      (evtActionMovab)="customActionMovab($event)"
      (evtActionClifos)="customActionClifos($event)">
    </doccontabilidade-ncontadebito>
  </ng-template>

  <ng-template #templateNContaCredito let-model="model" let-linha="linha">
    <doccontabilidade-ncontacredito
      [model]="linha.nContaCredito"
      [linha]="linha"
      [docContabilidade]="model"
      [predefinido]="predefinido"
      [locked]="locked"
      (evtModelChanged)="onLinhaNContaChange($event)"
      (evtFocus)="onLinhaFieldFocus($event.linha, $event.event)"
      (evtBlur)="onLinhaFieldBlur($event.linha, $event.event)"
      (evtKeydown)="onLinhaFieldKeyDown($event.event, $event.linha._index)"
      (evtActionExtratos)="customActionExtratos($event)"
      (evtActionMovab)="customActionMovab($event)"
      (evtActionClifos)="customActionClifos($event)">
    </doccontabilidade-ncontacredito>
  </ng-template>

  <ng-template #templateNContribuinte let-model="model" let-linha="linha">
    <doccontabilidade-ncontribuinte
      [model]="linha.nContribuinte"
      [linha]="linha"
      [docContabilidade]="model"
      [predefinido]="predefinido"
      [locked]="locked"
      (evtModelChanged)="onLinhaNContribuinteChange($event.value, $event.linha)"
      (evtFocus)="onLinhaFieldFocus($event.linha, $event.event)"
      (evtBlur)="onLinhaFieldBlur($event.linha, $event.event)"
      (evtKeydown)="onLinhaFieldKeyDown($event.event, $event.linha._index)">
    </doccontabilidade-ncontribuinte>
  </ng-template>

  <ng-template #templateValor let-model="model" let-linha="linha">
    <doccontabilidade-valor
      [model]="linha.valor"
      [linha]="linha"
      [docContabilidade]="model"
      [predefinido]="predefinido"
      [locked]="locked"
      (evtModelChanged)="onLinhaValorChange($event.value, $event.linha)"
      (evtValorTaxaChanged)="onLinhaValorTaxaChange($event.value, $event.linha)"
      (evtLockCampoME)="onLinhaLockCampoME($event.campoME, $event.linha)"
      (evtFocus)="onLinhaFieldFocus($event.linha, $event.event)"
      (evtBlur)="onLinhaFieldBlur($event.linha, $event.event)"
      (evtKeydown)="onLinhaFieldKeyDown($event.event, $event.linha._index)"
      (evtClosedModal)="onFieldClosedModal()"
      (evtObterSaldoConta)="onValorObterSaldoConta($event.linha)"
      (evtRegularizacaoCampo40)="onRegularizacaoCampo40($event.linha)">
    </doccontabilidade-valor>
  </ng-template>

  <ng-template #templateCambio let-model="model" let-linha="linha">
    <doccontabilidade-cambio
      [model]="linha.cambio"
      [linha]="linha"
      [docContabilidade]="model"
      [predefinido]="predefinido"
      [locked]="locked"
      (evtModelChanged)="onLinhaCambioChange($event.value, $event.linha)"
      (evtLockCampoME)="onLinhaLockCampoME($event.campoME, $event.linha)"
      (evtFocus)="onLinhaFieldFocus($event.linha, $event.event)"
      (evtBlur)="onLinhaFieldBlur($event.linha, $event.event)"
      (evtKeydown)="onLinhaFieldKeyDown($event.event, $event.linha._index)">
    </doccontabilidade-cambio>
  </ng-template>

  <ng-template #templateValorME let-model="model" let-linha="linha">
    <doccontabilidade-valorme
      [model]="linha.valorME"
      [linha]="linha"
      [docContabilidade]="model"
      [predefinido]="predefinido"
      [locked]="locked"
      (evtModelChanged)="onValorMEChange($event.value, $event.linha)"
      (evtLockCampoME)="onLinhaLockCampoME($event.campoME, $event.linha)"
      (evtFocus)="onLinhaFieldFocus($event.linha, $event.event)"
      (evtBlur)="onLinhaFieldBlur($event.linha, $event.event)"
      (evtKeydown)="onLinhaFieldKeyDown($event.event, $event.linha._index)">
    </doccontabilidade-valorme>
  </ng-template>

  <ng-template #templateValorTaxa let-model="model" let-linha="linha">
    <doccontabilidade-valortaxa
      [model]="linha.valorTaxa"
      [linha]="linha"
      [docContabilidade]="model"
      [predefinido]="predefinido"
      [locked]="locked"
      (evtModelChanged)="onLinhaValorTaxaChange($event.value, $event.linha)"
      (evtClosedModal)="onFieldClosedModal()">
    </doccontabilidade-valortaxa>
  </ng-template>

  <ng-template #templateDataDoc let-model="model" let-linha="linha">
    <doccontabilidade-data-doc
      [model]="linha.dataDoc"
      [linha]="linha"
      [docContabilidade]="model"
      [predefinido]="predefinido"
      [locked]="locked"
      (evtModelChanged)="onLinhaDataDocChange($event.value, $event.linha)"
      (evtFocus)="onLinhaFieldFocus($event.linha, $event.event)"
      (evtBlur)="onLinhaFieldBlur($event.linha, $event.event)"
      (evtKeydown)="onLinhaFieldKeyDown($event.event, $event.linha._index)">
    </doccontabilidade-data-doc>
  </ng-template>

  <ng-template #templateDescricao let-model="model" let-linha="linha">
    <doccontabilidade-descricao
      [model]="linha.descricao"
      [linha]="linha"
      [docContabilidade]="model"
      [predefinido]="predefinido"
      [locked]="locked"
      (evtModelChanged)="linha.descricao = $event.value"
      (evtFocus)="onLinhaFieldFocus($event.linha, $event.event)"
      (evtBlur)="onLinhaFieldBlur($event.linha, $event.event)"
      (evtKeydown)="onLinhaFieldKeyDown($event.event, $event.linha._index)">
    </doccontabilidade-descricao>
  </ng-template>

  <ng-template #templateCDecPer let-model="model" let-linha="linha">
    <doccontabilidade-cdecper [model]="linha.cDecPer" [linha]="linha" [docContabilidade]="model" [predefinido]="predefinido" [locked]="locked" (evtModelChanged)="linha.cDecPer = $event.value">
    </doccontabilidade-cdecper>
  </ng-template>

  <ng-template #templateCDecAnual let-model="model" let-linha="linha">
    <doccontabilidade-cdecanual [model]="linha.cDecAnual" [linha]="linha" [docContabilidade]="model" [predefinido]="predefinido" [locked]="locked" (evtModelChanged)="linha.cDecAnual = $event.value">
    </doccontabilidade-cdecanual>
  </ng-template>

  <ng-template #templateNomeConta let-linha="linha">
    <div class="truncate doccontabilidade-field-nome-conta" [attr.title]="linha?.poc?.nome">{{ linha?.poc?.nome }}</div>
  </ng-template>

  <ng-template #templateContentPerguntaCC let-linha="linha">
    <div class="mt-2">
      <pl-edit-switch [(model)]="linha.naoPerguntaCC" [properties]="{label: labelPerguntaCC}"></pl-edit-switch>
    </div>
  </ng-template>
</div>
