import moment from 'moment';
import {firstValueFrom, Subscription} from 'rxjs';
import {Component, Input, OnDestroy} from '@angular/core';
import {PlGlobalEventsService, timeout, TNgClassSupportedTypes} from 'pl-comps-angular';
import {AppService} from '../../../services/app/app.service';
import {AuthService} from '../../../services/auth/auth.service';
import {EAppTheme, IAppTheme, LOGO_DARK, LOGO_LIGHT} from '../../../../common/themes/themes.interface';
import {EmpresasService} from '../../../services/empresas/empresas.service';
import {GLOBAL_EVENT_LOGO_CHANGED} from '../../../../config/constants';
import {TUserSession} from '../../../services/account/jsonUserApi.interface';

@Component({
  selector: 'cg-company-logo',
  templateUrl: './cg.company.logo.component.html'
})
export class CGCompanyLogoComponent implements OnDestroy {
  @Input() public cssClass: TNgClassSupportedTypes;

  public defaultLogo: string;
  public logoSrc: string;

  private readonly _subscriptionSession: Subscription;
  private readonly _subscriptionTheme: Subscription;
  private _session: TUserSession;
  private _bustCache: boolean;

  constructor(
    private readonly _appService: AppService,
    private readonly _authService: AuthService,
    private readonly _empresasService: EmpresasService,
    private readonly _globalEventsService: PlGlobalEventsService
  ) {
    this._logoChanged = this._logoChanged.bind(this);

    this.defaultLogo = LOGO_LIGHT;
    this._bustCache = false;

    this._subscriptionSession = this._authService.identityAsObservable().subscribe((session: TUserSession) => {
      this._session = session;
      if (this._session?.erp) {
        this._evaluateLogo();
      }
    });

    this._subscriptionTheme = this._appService.theme().subscribe((theme: IAppTheme) => {
      this._evaluateDefaultLogo(theme);
    });

    this._globalEventsService.on(GLOBAL_EVENT_LOGO_CHANGED, this._logoChanged);
  }

  public ngOnDestroy(): void {
    this._subscriptionSession.unsubscribe();
    this._subscriptionTheme.unsubscribe();
    this._globalEventsService.off(GLOBAL_EVENT_LOGO_CHANGED, this._logoChanged);
  }

  private _evaluateDefaultLogo({theme, prefersColorSchemeDark}: IAppTheme): void {
    if (theme === EAppTheme.Auto) {
      theme = prefersColorSchemeDark ? EAppTheme.Dark : EAppTheme.Light;
    }
    switch (theme) {
      case EAppTheme.Light:
        this.defaultLogo = LOGO_LIGHT;
        break;
      case EAppTheme.Dark:
        this.defaultLogo = LOGO_DARK;
        break;
    }
  }

  private _evaluateLogo(): void {
    this.logoSrc = '';
    timeout().finally(async () => {
      this.logoSrc = await firstValueFrom(this._empresasService.getLogotipoUrl(this._session.erp.nEmpresa));
      if (this._bustCache) {
        this.logoSrc += `?cacheBust=${moment().valueOf()}`;
        this._bustCache = false;
      }
    });
  }

  private _logoChanged(): void {
    this._bustCache = true;
    this._evaluateLogo();
  }
}
