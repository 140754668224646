import {Injectable} from '@angular/core';
import {TDate} from '../../../common/dates';
import {ApiService} from '../../services/api/api.service';
import {
  EATAutoFaturacaoEnquadramento,
  IJsonDocfaNumATConsulta,
  IJsonDocfaNumATRegisto,
  IJsonDocfaNumATResultado,
  IJsonDocfanumPorComunicarAT
} from '../../entities/docfasnums/jsonDocfaNum.entity.interface';
import {IApiQueryRequestConfig, THttpQueryResponse, TServiceQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';
import {ENTITY_NAME_DOCFAS_NUMS} from '../../entities/docfasnums/docfasNums.entity.interface';
import {ENTITY_NAME_RECIBOS_SERIE} from '../../entities/recibosSerie/recibosSerie.entity.interface';
import {EComunicacaoSeriesATType} from './comunicacaoseriesat.interface';
import {IJsonRecibosSerieATRegisto, IJsonRecibosSeriePorComunicarAT} from '../../entities/recibosSerie/jsonRecibosSerie.entity.interface';

@Injectable({
  providedIn: 'root'
})
export class ComunicacaoSeriesAtService {
  private readonly _pathDocFasNum: string;
  private readonly _pathRecibos: string;

  constructor(private readonly _apiService: ApiService) {
    this._pathDocFasNum = `${this._apiService.path.restapi}/${ENTITY_NAME_DOCFAS_NUMS}`;
    this._pathRecibos = `${this._apiService.path.restapi}/${ENTITY_NAME_RECIBOS_SERIE}`;
  }

  public getSerieComunicadaAT(serieType: EComunicacaoSeriesATType, nSerie: number): TServiceResponse<IJsonDocfaNumATConsulta> {
    return this._apiService.get<IJsonDocfaNumATConsulta>({
      url: `${this._getPath(serieType)}/${nSerie}/serieat`
    });
  }

  public consultaSerieNaAT(serieType: EComunicacaoSeriesATType, nSerie: number): TServiceResponse<IJsonDocfaNumATConsulta> {
    return this._apiService.get<IJsonDocfaNumATConsulta>({
      url: `${this._getPath(serieType)}/${nSerie}/consultaserieat`
    });
  }

  public registaSerieATPorWebService(
    serieType: EComunicacaoSeriesATType,
    nSerie: number,
    atNumInicialSeq: number,
    atDataInicioPrev: TDate,
    atcud: string
  ): TServiceResponse<IJsonDocfaNumATRegisto | IJsonRecibosSerieATRegisto> {
    return this._registaSerieAT(serieType, nSerie, atNumInicialSeq, atDataInicioPrev, atcud, true);
  }

  public registaSerieATManual(
    serieType: EComunicacaoSeriesATType,
    nSerie: number,
    atNumInicialSeq: number,
    atDataInicioPrev: TDate,
    atcud: string,
    nconta: string,
    enquadramentoterceiro: EATAutoFaturacaoEnquadramento
  ): TServiceResponse<IJsonDocfaNumATRegisto | IJsonRecibosSerieATRegisto> {
    return this._registaSerieAT(serieType, nSerie, atNumInicialSeq, atDataInicioPrev, atcud, false, nconta, enquadramentoterceiro);
  }

  public finalizaSerieAT(serieType: EComunicacaoSeriesATType, nSerie: number, ultimoDocEmitido: number, justificacao: string): TServiceResponse<IJsonDocfaNumATResultado> {
    return this._apiService.put<IJsonDocfaNumATResultado>({
      url: `${this._getPath(serieType)}/${nSerie}/finalizaserieat`,
      params: {
        ultimodocemitido: ultimoDocEmitido,
        justificacao: justificacao
      }
    });
  }

  public getSeriesPorComunicarAT(
    serieType: EComunicacaoSeriesATType,
    search?: string,
    order?: string,
    page?: string | number,
    perpage?: string | number,
    searchFields?: string
  ): TServiceQueryResponse<IJsonDocfanumPorComunicarAT | IJsonRecibosSeriePorComunicarAT> {
    const request: IApiQueryRequestConfig = {
      url: `${this._getPath(serieType)}/seriesporcomunicarat`,
      params: {
        pagina: Number.isNaN(Number(page)) ? undefined : Number(page),
        porpagina: Number.isNaN(Number(perpage)) ? undefined : Number(perpage),
        pesquisa: search,
        ordena: order,
        campospesq: searchFields
      }
    };

    return new Promise<THttpQueryResponse<IJsonDocfanumPorComunicarAT | IJsonRecibosSeriePorComunicarAT>>((resolve, reject) => {
      this._apiService
        .query<IJsonDocfanumPorComunicarAT | IJsonRecibosSeriePorComunicarAT>(request)
        .then((response: THttpQueryResponse<IJsonDocfanumPorComunicarAT | IJsonRecibosSeriePorComunicarAT>) => {
          resolve(response);
        })
        .catch(reject);
    });
  }

  public comunicarSeriesAT(
    serieType: EComunicacaoSeriesATType,
    seriesPorComunicarAT: Array<IJsonDocfanumPorComunicarAT | IJsonRecibosSeriePorComunicarAT>
  ): TServiceResponse<Array<IJsonDocfanumPorComunicarAT | IJsonRecibosSeriePorComunicarAT>> {
    return this._apiService.put<Array<IJsonDocfanumPorComunicarAT | IJsonRecibosSeriePorComunicarAT>, Array<IJsonDocfanumPorComunicarAT | IJsonRecibosSeriePorComunicarAT>>({
      url: `${this._getPath(serieType)}/comunicarseriesat`,
      body: seriesPorComunicarAT
    });
  }

  private _registaSerieAT(
    serieType: EComunicacaoSeriesATType,
    nSerie: number,
    atNumInicialSeq: number,
    atDataInicioPrev: TDate,
    atcud: string,
    porWebService: boolean,
    nconta: string = '',
    enquadramentoterceiro: EATAutoFaturacaoEnquadramento = EATAutoFaturacaoEnquadramento.None
  ): TServiceResponse<IJsonDocfaNumATRegisto | IJsonRecibosSerieATRegisto> {
    return this._apiService.put<IJsonDocfaNumATRegisto | IJsonRecibosSerieATRegisto>({
      url: `${this._getPath(serieType)}/${nSerie}/registaserieat`,
      params: {
        atnuminicialseq: atNumInicialSeq,
        atdatainicioprev: atDataInicioPrev,
        atcud: atcud,
        porwebservice: porWebService,
        nconta: nconta,
        enquadramentoterceiro: enquadramentoterceiro
      }
    });
  }

  private _getPath(serieType): string {
    return serieType === EComunicacaoSeriesATType.DOCFANUM ? this._pathDocFasNum : this._pathRecibos;
  }
}
