import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {AfterViewInit, Component, ContentChildren, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild} from '@angular/core';
import {merge} from 'lodash-es';
import {
  interpolate,
  IPlValidator,
  isArray,
  isBoolean,
  isDefinedNotNull,
  isEmpty,
  isFunction,
  isNumber,
  isObject,
  isString,
  isUndefinedOrNull,
  KEYCODES,
  Logger,
  PlAutocompleteCache,
  PlEditAutocompleteComponent,
  PlEditInputGroupDirective,
  PlTranslateService,
  TEditInputFocusEvent,
  TEditInputKeyboardEvent,
  TNgClassSupportedTypes,
  TPlEditAutocompleteRowTemplateFn
} from 'pl-comps-angular';
import {appendValueToQueryFilter} from '../../../../common/utils/utils';
import {EStatusCode} from '../../../../config/constants';
import {IApiQueryRequestConfig, IApiRequestConfig, THttpQueryResponse, TServiceResponse} from '../../../services/api/api.service.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {CGModalService} from '../../cg/modal/cgmodal.service';
import {
  IEntityAutocompleteCustomAction,
  IEntityAutocompleteCustomActionDefinition,
  IEntityAutocompleteMaintenanceProperties,
  IEntityAutocompleteOptions,
  TEntityAutocompleteGetDataFn,
  TEntityAutocompleteGetItemFn
} from '../../entity/entity.autocomplete.definition.interface';
import {IEntity, IEntityMetadataField} from '../../entity/entity.definition.interface';
import {EntityRegistryService} from '../../entity/entity.registry.service';
import {EntityFilterService} from '../../entity/filter/entity.filter.service';
import {
  IEntityMaintenanceEditModalOptions,
  IEntityMaintenanceInstance,
  IEntityMaintenanceListModalOptions,
  TEntityMaintenanceActionMaintenanceEditFn,
  TEntityMaintenanceActionMaintenanceListFn
} from '../../entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../entity/maintenance/entity/entity.maintenance.service';
import {IEntityAutocompleteEventSelected, IEntityAutocompleteFieldsMap, TEntityAutocompleteSelectedKey} from './entity.autocomplete.component.interface';

const REGEX_VALID_CHARACTERS = new RegExp('[a-z]|[0-9]', 'i');

@Component({
  selector: 'entity-autocomplete',
  templateUrl: './entity.autocomplete.component.html'
})
export class EntityAutocompleteComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public attrName: string;
  @Input() public entity: string;
  @Input() public fieldsMap: IEntityAutocompleteFieldsMap;
  @Input() public filter: string;
  @Input() public filterFields: Array<IEntityMetadataField>;
  @Input() public inputClass: TNgClassSupportedTypes;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public model: any;
  @Input() public output: string | 'key' | 'description';
  @Input() public outputKey: string;
  @Input() public outputDescription: string;
  @Input() public placeholder: string;
  @Input() public rowTemplate: string | TPlEditAutocompleteRowTemplateFn;
  @Input() public selectedKey: TEntityAutocompleteSelectedKey;
  @Input() public showFilter: boolean;
  @Input() public selectOnFocus: boolean;
  @Input() public helperMode: boolean;
  @Input() public reportExceptions: boolean;
  @Input() public quickCreateEnabled: boolean;
  @Input() public getDataConfig: IApiQueryRequestConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public getDataFn: TEntityAutocompleteGetDataFn<any>;
  @Input() public getItemConfig: IApiRequestConfig;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public getItemFn: TEntityAutocompleteGetItemFn<any>;
  @Input() public customActions: IEntityAutocompleteCustomActionDefinition | Array<IEntityAutocompleteCustomActionDefinition>;
  @Input() public searchOnShow: boolean;
  @Input() public cacheValues: boolean;
  @Input() public cacheValuesInstanceId: string;
  @Input() public cacheValuesAutoReload: boolean;
  @Input() public loadingDelayDuration: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public validateFn: (search?: string, item?: any) => any | Promise<any>;
  @Input() public actionMaintenanceList: TEntityMaintenanceActionMaintenanceListFn;
  @Input() public actionMaintenanceEdit: TEntityMaintenanceActionMaintenanceEditFn;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() public maintenanceProperties: IEntityAutocompleteMaintenanceProperties<any>;
  @Input() public properties: IEntityAutocompleteOptions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly modelChange: EventEmitter<any>;
  @Output() public readonly selectedKeyChange: EventEmitter<TEntityAutocompleteSelectedKey>;
  @Output() public readonly evtSelectedDescriptionChanged: EventEmitter<string>;
  @Output() public readonly evtSelected: EventEmitter<IEntityAutocompleteEventSelected>;
  @Output() public readonly evtInputValueChanged: EventEmitter<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() public readonly evtSelectPromiseChanged: EventEmitter<Promise<any>>;
  @ContentChildren(PlEditInputGroupDirective, {descendants: true}) public itemGroups: QueryList<PlEditInputGroupDirective>;

  public value: unknown;
  public outputValue: string;
  public entityOutput: string;
  public selectedDescription: string;
  public order: string;
  public searchFields: string;
  public showActions: boolean;
  public maintenanceAllowList: boolean;
  public maintenanceAllowEdit: boolean;
  public maintenanceHeaderList: string;
  public maintenanceHeaderEdit: string;
  public isEntityActionsOpen: boolean;
  public customEntityActions: Array<IEntityAutocompleteCustomAction>;
  public options: IEntityAutocompleteOptions;

  @ViewChild(PlEditAutocompleteComponent) private readonly _plEditAutocomplete: PlEditAutocompleteComponent;
  private readonly _validatorAllowInvalid: IPlValidator<string, unknown>;
  private readonly _defaultOptions: IEntityAutocompleteOptions;
  private _entityData: IEntity;
  private _service: IEntityService;
  private _fieldsMapKeys: Array<string>;
  private _preventOnChangeSelectedKey: boolean;
  private _entityMaintenanceInstance: IEntityMaintenanceInstance;
  private _hasFieldsMap: boolean;
  private _originalEventFocus: TEditInputFocusEvent<string>;
  private _originalEventBlur: TEditInputFocusEvent<string>;
  private _previousOutputValue: string;
  private _revertOutputValue: boolean;
  private _invalidValue: boolean;
  private _forceQuickCreate: boolean;
  private _originalKeyDown: TEditInputKeyboardEvent<unknown>;
  private _entityMaintenanceAllowEdit: boolean;

  constructor(
    private readonly _logger: Logger,
    private readonly _cache: PlAutocompleteCache,
    private readonly _entityRegistryService: EntityRegistryService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _entityFilterService: EntityFilterService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _cgModalService: CGModalService
  ) {
    this._onKeydown = this._onKeydown.bind(this);
    this._onFocus = this._onFocus.bind(this);
    this._onBlur = this._onBlur.bind(this);
    this.modelChange = new EventEmitter<unknown>();
    this.selectedKeyChange = new EventEmitter<TEntityAutocompleteSelectedKey>();
    this.evtSelectedDescriptionChanged = new EventEmitter<string>();
    this.evtSelected = new EventEmitter<IEntityAutocompleteEventSelected>();
    this.evtInputValueChanged = new EventEmitter<string>();
    this.evtSelectPromiseChanged = new EventEmitter<Promise<unknown>>();
    this.showActions = false;
    this.maintenanceAllowList = false;
    this.maintenanceAllowEdit = false;
    this.isEntityActionsOpen = false;
    this._validatorAllowInvalid = {
      message: 'entity.autocomplete.errorAllowInvalid',
      value: undefined,
      validate: this._validateAllowInvalid.bind(this)
    };
    this._defaultOptions = {
      entity: {},
      validators: {
        allowInvalid: this._validatorAllowInvalid
      },
      hideActionsOnDisabled: true,
      showMaintenanceEditAction: true,
      showMaintenanceListAction: true,
      events: {}
    };
    this._preventOnChangeSelectedKey = false;
    this._revertOutputValue = false;
    this._invalidValue = false;
    this._forceQuickCreate = false;
    this._entityMaintenanceAllowEdit = false;
  }

  public ngOnInit(): void {
    this._entityData = this._entityRegistryService.getEntity(this.entity);
    this._service = this._entityServiceBuilder.build(this._entityData);
    this.order = this._entityData.autocomplete.order || this._entityData.metadata.order;
    this.searchFields = this._entityData.autocomplete.searchFields || this._entityData.metadata.searchFields;

    this._handleChanges();

    if (this._entityData.asModule) {
      this.maintenanceAllowList = this.options.showMaintenanceListAction;
      let maintenanceEntity: string | IEntity = this._entityData;
      if (isObject(this.maintenanceProperties) && this.maintenanceProperties.maintenanceEntity) {
        maintenanceEntity = this.maintenanceProperties.maintenanceEntity;
      }
      this._entityMaintenanceInstance = this._entityMaintenanceService.build(maintenanceEntity, {
        ...this.maintenanceProperties,
        actionMaintenanceList: this.actionMaintenanceList,
        actionMaintenanceEdit: this.actionMaintenanceEdit
      });
      this._setMaintenanceHeader();
      this._entityMaintenanceAllowEdit = (this._entityMaintenanceInstance.maintenanceAllowNew || this._entityMaintenanceInstance.maintenanceAllowEdit) && this.options.showMaintenanceEditAction;
      this.maintenanceAllowEdit = this._entityMaintenanceAllowEdit;
    }

    if (this.model || this.model === 0) {
      this._modelChanged(this.model);
    } else if (this.selectedKey || this.selectedKey === 0) {
      this._modelChanged(this.selectedKey);
    }
  }

  public ngOnChanges({
    fieldsMap,
    filter,
    filterFields,
    model,
    output,
    outputDescription,
    outputKey,
    placeholder,
    rowTemplate,
    selectedKey,
    showFilter,
    selectOnFocus,
    helperMode,
    reportExceptions,
    quickCreateEnabled,
    getDataConfig,
    getDataFn,
    getItemConfig,
    getItemFn,
    customActions,
    searchOnShow,
    cacheValues,
    cacheValuesInstanceId,
    cacheValuesAutoReload,
    loadingDelayDuration,
    properties,
    maintenanceProperties
  }: SimpleChanges): void {
    if (properties && !properties.isFirstChange()) {
      this._changedProperties(properties.currentValue);
    }
    if (fieldsMap && !fieldsMap.isFirstChange()) {
      this._changedFieldsMap(fieldsMap.currentValue);
    }
    if (filter && !filter.isFirstChange()) {
      this._changedFilter(filter.currentValue);
    }
    if (filterFields && !filterFields.isFirstChange()) {
      this._changedFilterFields(filterFields.currentValue);
    }
    if (outputKey && !outputKey.isFirstChange()) {
      this._changedOutputKey(outputKey.currentValue);
    }
    if (outputDescription && !outputDescription.isFirstChange()) {
      this._changedOutputDescription(outputDescription.currentValue);
    }
    if (output && !output.isFirstChange()) {
      this._changedOutput(output.currentValue);
    }
    if (placeholder && !placeholder.isFirstChange()) {
      this._changedPlaceholder(placeholder.currentValue);
    }
    if (rowTemplate && !rowTemplate.isFirstChange()) {
      this._changedRowTemplate(rowTemplate.currentValue);
    }
    if (showFilter && !showFilter.isFirstChange()) {
      this._changedShowFilter(showFilter.currentValue);
    }
    if (selectOnFocus && !selectOnFocus.isFirstChange()) {
      this._changedSelectOnFocus(selectOnFocus.currentValue);
    }
    if (helperMode && !helperMode.isFirstChange()) {
      this._changedHelperMode(helperMode.currentValue);
    }
    if (reportExceptions && !reportExceptions.isFirstChange()) {
      this._changedReportExceptions(reportExceptions.currentValue);
    }
    if (quickCreateEnabled && !quickCreateEnabled.isFirstChange()) {
      this._changedQuickCreateEnabled(quickCreateEnabled.currentValue);
    }
    if (getDataConfig && !getDataConfig.isFirstChange()) {
      this._changedGetDataConfig(getDataConfig.currentValue);
    }
    if (getDataFn && !getDataFn.isFirstChange()) {
      this._changedGetDataFn(getDataFn.currentValue);
    }
    if (getItemConfig && !getItemConfig.isFirstChange()) {
      this._changedGetItemConfig(getItemConfig.currentValue);
    }
    if (getItemFn && !getItemFn.isFirstChange()) {
      this._changedGetItemFn(getItemFn.currentValue);
    }
    if (customActions && !customActions.isFirstChange()) {
      this._changedCustomActions(customActions.currentValue);
    }
    if (searchOnShow && !searchOnShow.isFirstChange()) {
      this._changedSearchOnShow(searchOnShow.currentValue);
    }
    if (cacheValues && !cacheValues.isFirstChange()) {
      this._changedCacheValues(cacheValues.currentValue);
    }
    if (cacheValuesInstanceId && !cacheValuesInstanceId.isFirstChange()) {
      this._changedCacheValuesInstanceId(cacheValuesInstanceId.currentValue);
    }
    if (cacheValuesAutoReload && !cacheValuesAutoReload.isFirstChange()) {
      this._changedCacheValuesAutoReload(cacheValuesAutoReload.currentValue);
    }
    if (loadingDelayDuration && !loadingDelayDuration.isFirstChange()) {
      this._changedLoadingDelayDuration(loadingDelayDuration.currentValue);
    }
    if (maintenanceProperties && !maintenanceProperties.isFirstChange()) {
      this._changedMaintenanceProperties(maintenanceProperties.currentValue);
    }

    if (model && !model.isFirstChange()) {
      this._modelChanged(model.currentValue);
    } else if (selectedKey && !selectedKey.isFirstChange()) {
      if (!this._preventOnChangeSelectedKey) {
        this._modelChanged(selectedKey.currentValue);
      }
      this._preventOnChangeSelectedKey = false;
    }
  }

  public ngAfterViewInit(): void {
    if (this.selectOnFocus && isDefinedNotNull(this.value) && this._plEditAutocomplete?.inputField && this._plEditAutocomplete.inputField === window.document.activeElement) {
      setTimeout(() => {
        this._plEditAutocomplete.inputSelectAll();
      });
    }
  }

  public openChanged(value: boolean): void {
    this.isEntityActionsOpen = value;
    if (this.isEntityActionsOpen) {
      this._evaluateCustomActionsVisibility();
    }
  }

  public changedOutputValue(value: string): void {
    if (this._revertOutputValue) {
      this._revertOutputValue = false;
      if (this._previousOutputValue) {
        this.outputValue = this._previousOutputValue;
      }
    } else {
      this.outputValue = value;
    }
  }

  public changedInputValue(value: string): void {
    this.evtInputValueChanged.emit(value);
  }

  public fnGetData = (search: string, page: number, perpage: number, filter: string): Promise<Array<unknown>> => this._getData(search, page, perpage, filter);

  public fnSelect = (inputValue: string, item: unknown): Promise<void> => this._select(inputValue, item);

  public fnValidate = (search: string, value: unknown): Promise<unknown> => this._validate(search, value);

  public fnMaintenanceList = (): Promise<unknown> => this._maintenanceList();

  public fnMaintenanceEdit = (): Promise<unknown> => this._maintenanceEdit();

  public fnMaintenanceNew = (): Promise<unknown> => this._maintenanceNew();

  @HostBinding('attr.data-attr-name')
  public get dataAttrName(): string {
    return this.attrName;
  }

  @HostBinding('attr.data-entity')
  public get dataEntity(): string {
    return this.entity;
  }

  private _render(value: unknown = this.value): void {
    this.model = value;
    this.modelChange.emit(this.model);
  }

  private _handleChanges(): void {
    this._changedProperties();
    this._changedFieldsMap();
    this._changedFilter();
    this._changedFilterFields();
    this._changedOutputKey();
    this._changedOutputDescription();
    this._changedOutput();
    this._changedPlaceholder();
    this._changedRowTemplate();
    this._changedShowFilter();
    this._changedCustomActions();
    this._changedSearchOnShow();
    this._changedCacheValues();
    this._changedCacheValuesInstanceId();
    this._changedCacheValuesAutoReload();
    this._changedLoadingDelayDuration();
    this._changedSelectOnFocus();
    this._changedHelperMode();
    this._changedReportExceptions();
    this._changedQuickCreateEnabled();
    this._changedGetDataConfig();
    this._changedGetDataFn();
    this._changedGetItemConfig();
    this._changedGetItemFn();
    this._changedMaintenanceProperties();
  }

  private _modelChanged(model: unknown = this.model): void {
    let entityDataKey = this.options.entity.keyTarget || this.outputKey;
    if (!isObject(model)) {
      if (this._hasFieldsMap) {
        for (const key of Object.keys(this.fieldsMap)) {
          if (key === entityDataKey) {
            entityDataKey = this.fieldsMap[key];
            break;
          }
        }
      }
      const newModel: unknown = {};
      newModel[entityDataKey] = model;
      model = newModel;
    }
    this.value = model;
    this.selectedKey = this._getOutputKey(this.value, true);
    this.selectedDescription = this._getOutputDescription(this.value, true);
    this._setOutputValue(this._getOutputValue(this.value, true));
    if (this._previousOutputValue) {
      this._previousOutputValue = this.outputValue;
    }
    if (this._plEditAutocomplete && (!this.options.allowInvalid || !this.options.allowEmpty)) {
      this._plEditAutocomplete.runValidators();
    }
  }

  private _getData(search: string, page: number, perpage: number, filter: string): Promise<Array<unknown>> {
    if (!filter && this.filter) {
      filter = this.filter;
    }
    search = search || '';
    if (filter) {
      if (search) {
        search += '&';
      }
      search += filter;
    }
    return !isFunction(this.getDataFn)
      ? this._service
          .query({pesquisa: search, ordena: this.order, pagina: page, porpagina: perpage, campospesq: this.searchFields, ...this.getDataConfig})
          .then((response: THttpQueryResponse<unknown>) => response.body.list)
      : Promise.resolve(
          this.getDataFn({
            pesquisa: search,
            ordena: this.order,
            pagina: page,
            porpagina: perpage,
            campospesq: this.searchFields,
            config: this.getDataConfig,
            service: this._service
          })
        );
  }

  private _select(inputValue: string, item: unknown): Promise<void> {
    if (this._forceQuickCreate) {
      this._forceQuickCreate = false;
      return Promise.resolve();
    }

    let promise: Promise<unknown>;
    let fetchedFromCache = false;

    if (!isObject(item) && !isEmpty(item)) {
      const selectedKey: string = inputValue || <string>item;

      if (this.cacheValues && this.cacheValuesInstanceId) {
        const cachedItem: unknown = this._cache.getInstance(this.cacheValuesInstanceId, selectedKey);
        if (isObject(cachedItem)) {
          promise = Promise.resolve(cachedItem);
          fetchedFromCache = true;
        }
      }

      if (this.helperMode && !fetchedFromCache) {
        this.selectedKey = selectedKey;
        this.selectedKeyChange.emit(this.selectedKey);
        this.selectedDescription = undefined;
        this.evtSelectedDescriptionChanged.emit(this.selectedDescription);
        if (!this.output.includes('{{') && !this.outputKey.includes('{{') && !this.outputDescription.includes('{{')) {
          if (this._previousOutputValue) {
            this._previousOutputValue = this.selectedKey;
          }
          this._invalidValue = false;
          return Promise.resolve();
        }
      }

      const autoReloadCachedValues = this.options.cacheValuesAutoReload !== false;
      if (!fetchedFromCache || autoReloadCachedValues) {
        const getItemPromise: Promise<unknown> = this._getItem(<string>(inputValue || item));

        if (!fetchedFromCache) {
          promise = getItemPromise;
        } else {
          getItemPromise.then((response: unknown) => {
            if (isEmpty(response)) {
              return;
            }
            if (response instanceof HttpResponse) {
              response = (<HttpResponse<unknown>>response).body;
            }
            const key: string = this._getOutputKey(response, false);
            this._cache.setInstance(this.cacheValuesInstanceId, key, response);
          });
        }
      }
    }

    promise = Promise.resolve(promise)
      .then((response: unknown) => {
        if (!isEmpty(response)) {
          if (response instanceof HttpResponse) {
            response = (<HttpResponse<unknown>>response).body;
          }
          item = response;
        }
        this.value = item;
        this._invalidValue = false;
        this._preventOnChangeSelectedKey = true;
        this.selectedKey = this._getOutputKey(this.value, false);
        this.selectedKeyChange.emit(this.selectedKey);
        this.selectedDescription = this._getOutputDescription(this.value, false);
        this.evtSelectedDescriptionChanged.emit(this.selectedDescription);
        this._setOutputValue(this._getOutputValue(this.value, false));
        if (this._previousOutputValue) {
          this._previousOutputValue = this.outputValue;
        }
        if (!this.options.allowInvalid || !this.options.allowEmpty) {
          this._plEditAutocomplete.runValidators();
        }
        if (this.cacheValues && this.cacheValuesInstanceId && (item || item === 0) && !fetchedFromCache) {
          this._cache.setInstance(this.cacheValuesInstanceId, this.selectedKey, item);
        }
        this._render();
        this.evtSelected.emit({inputValue: inputValue, item: this.value});
        if (this.options && isFunction(this.options.onSelect)) {
          this.options.onSelect(inputValue, this.value);
        }
      })
      .catch(async (reason: unknown) => {
        this._logger.error(reason);
        this._invalidValue = true;
        this.value = this.options.allowInvalid || this.helperMode ? inputValue || item : undefined;
        this.selectedKey = <string>this.value;
        this.selectedKeyChange.emit(this.selectedKey);
        this.selectedDescription = undefined;
        this.evtSelectedDescriptionChanged.emit(this.selectedDescription);

        if (this._previousOutputValue) {
          this._previousOutputValue = undefined;
        }
        if (!this.options.allowInvalid || !this.options.allowEmpty) {
          this._plEditAutocomplete.runValidators();
        }

        this._render();

        if (this.quickCreateEnabled && reason instanceof HttpErrorResponse && reason.status === EStatusCode.NotFound) {
          await this._cgModalService.showOkCancel(
            this._plTranslateService.translate('entity.autocomplete.newItem', {attrName: this.attrName}),
            this._plTranslateService.translate('entity.autocomplete.createNewItem', {attrName: this.attrName}),
            {size: 'md'}
          );
          await this._maintenanceNew();
        }
      });

    this.evtSelectPromiseChanged.emit(promise);

    return promise.then(() => undefined);
  }

  private _validate(search: string, value: unknown): Promise<unknown> {
    return new Promise<unknown>((resolve, reject) => {
      let promise: unknown;
      if (isFunction(this.validateFn)) {
        promise = this.validateFn(search, value);
      } else if (isFunction(this.options.validateFn)) {
        promise = this.options.validateFn(search, value);
      }
      Promise.resolve(promise)
        .then(resolve)
        .catch((reason: unknown) => {
          this._invalidValue = true;
          reject(reason);
        });
    });
  }

  private _changedProperties(value: IEntityAutocompleteOptions = this.properties): void {
    this.options = merge({}, this._defaultOptions, this._entityData.autocomplete.properties, this.options, value);
    this.maintenanceAllowEdit = this._entityMaintenanceAllowEdit && !this.options.disabled && !this.options.readonly && !this.options.raw;
    if (!isBoolean(this.options.allowInvalid)) {
      this.options.allowInvalid = true;
    }
    if (!isBoolean(this.options.allowEmpty)) {
      this.options.allowEmpty = true;
    }
    if (this.filter !== this.options.filter) {
      this._changedFilter(this.options.filter);
    }
    if (isFunction(this.options.events.keydown) && this.options.events.keydown !== this._onKeydown) {
      this._originalKeyDown = this.options.events.keydown;
    }
    this.options.events.keydown = this._onKeydown;
    if (isFunction(this.options.events.focus) && this.options.events.focus !== this._onFocus) {
      this._originalEventFocus = this.options.events.focus;
    }
    this.options.events.focus = this._onFocus;
    if (isFunction(this.options.events.blur) && this.options.events.blur !== this._onBlur) {
      this._originalEventBlur = this.options.events.blur;
    }
    this.options.events.blur = this._onBlur;
  }

  private _changedGetDataConfig(value: IApiQueryRequestConfig = this.getDataConfig): void {
    this.getDataConfig = value || this.options.getDataConfig || this._entityData.autocomplete.getDataConfig || undefined;
  }

  private _changedGetDataFn(value: TEntityAutocompleteGetDataFn = this.getDataFn): void {
    this.getDataFn = value || this.options.getDataFn || undefined;
  }

  private _changedGetItemConfig(value: IApiQueryRequestConfig = this.getDataConfig): void {
    this.getItemConfig = value || this.options.getItemConfig || this._entityData.autocomplete.getItemConfig || undefined;
  }

  private _changedGetItemFn(value: TEntityAutocompleteGetItemFn = this.getItemFn): void {
    this.getItemFn = value || this.options.getItemFn || undefined;
  }

  private _changedFieldsMap(value: IEntityAutocompleteFieldsMap = this.fieldsMap): void {
    this.fieldsMap = value || this.options.fieldsMap || this.options.entity.fieldsMap;
    this._hasFieldsMap = isObject(this.fieldsMap);
    if (this._hasFieldsMap) {
      this._fieldsMapKeys = Object.keys(this.fieldsMap);
    }
  }

  private _changedFilter(value: string = this.filter): void {
    this.filter = value || this.options.filter || this._entityData.autocomplete.filter || '';
  }

  private _changedFilterFields(value: Array<IEntityMetadataField> = this.filterFields): void {
    this.filterFields = value || this.options.filterFields || this._entityData.autocomplete.filterFields || this._entityFilterService.buildFields(this._entityData);
  }

  private _changedOutputKey(value: string = this.outputKey): void {
    this.outputKey =
      value ||
      this.options.outputKey ||
      this.options.entity.outputKey ||
      this._entityData.autocomplete.outputKey ||
      this._entityData.metadata.keyName ||
      this._entityData.metadata.fields[0].name ||
      this._entityData.name;
  }

  private _changedOutputDescription(value: string = this.outputDescription): void {
    this.outputDescription =
      value || this.options.outputDescription || this.options.entity.outputDescription || this._entityData.autocomplete.outputDescription || this._entityData.autocomplete.output;
  }

  private _changedOutput(value: string = this.output): void {
    switch (value) {
      case 'key':
        value = this.outputKey;
        break;
      case 'description':
        value = this.outputDescription;
        break;
    }
    this.entityOutput = this.output || this.options.output || this.options.entity.output || this._entityData.autocomplete.output;
    this.output = value || this.entityOutput;
  }

  private _changedPlaceholder(value: string = this.placeholder): void {
    this.placeholder =
      value || this.placeholder || this.options.placeholder || this.options.entity.placeholder || this._entityData.autocomplete.placeholder || this._entityData.searchPlaceholder || '';
  }

  private _changedShowFilter(value: boolean = this.showFilter): void {
    let newValue = value;
    if (!isBoolean(newValue)) {
      newValue = this.options.showFilter;
    }
    if (!isBoolean(newValue)) {
      newValue = this.options.entity.showFilter;
    }
    if (!isBoolean(newValue)) {
      newValue = this._entityData.autocomplete.showFilter;
    }
    if (!isBoolean(newValue)) {
      newValue = true;
    }
    this.showFilter = newValue;
  }

  private _changedRowTemplate(value: string | TPlEditAutocompleteRowTemplateFn = this.rowTemplate): void {
    this.rowTemplate = value || this.options.rowTemplate || this.options.entity.rowTemplate || this._entityData.autocomplete.rowTemplate;
  }

  private _changedMaintenanceProperties(value: IEntityAutocompleteMaintenanceProperties = this.maintenanceProperties): void {
    if (!isObject(value)) {
      value = this.options.maintenanceProperties;
    }
    if (!isObject(value)) {
      value = {};
    }
    this.maintenanceProperties = value;
  }

  private _changedCustomActions(value: IEntityAutocompleteCustomActionDefinition | Array<IEntityAutocompleteCustomActionDefinition> = this.customActions): void {
    value = value || <IEntityAutocompleteCustomAction>this.options.customActions;
    if (!isArray(value) && isObject(value)) {
      value = [value];
    }
    if (!isArray(value)) {
      value = [];
    }
    this.customEntityActions = <Array<IEntityAutocompleteCustomAction>>value;
    this._evaluateCustomActions().finally(() => {
      this._evaluateShowActions();
    });
  }

  private _changedSearchOnShow(value: boolean = this.searchOnShow): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.searchOnShow;
    }
    if (!isBoolean(val)) {
      val = this.options.entity.searchOnShow;
    }
    if (!isBoolean(val)) {
      val = this.options.entity.properties?.searchOnShow;
    }
    if (!isBoolean(val)) {
      val = this._entityData.autocomplete.properties?.searchOnShow;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.searchOnShow = val;
  }

  private _changedCacheValues(value: boolean = this.cacheValues): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.cacheValues;
    }
    if (!isBoolean(val)) {
      val = this.options.entity.cacheValues;
    }
    if (!isBoolean(val)) {
      val = this.options.entity.properties?.cacheValues;
    }
    if (!isBoolean(val)) {
      val = this._entityData.autocomplete.properties?.cacheValues;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.cacheValues = val;
  }

  private _changedCacheValuesInstanceId(value: string = this.cacheValuesInstanceId): void {
    this.cacheValuesInstanceId =
      value || this.options.cacheValuesInstanceId || this.options.entity.properties?.cacheValuesInstanceId || this._entityData.autocomplete.properties?.cacheValuesInstanceId;
  }

  private _changedCacheValuesAutoReload(value: boolean = this.cacheValuesAutoReload): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.cacheValuesAutoReload;
    }
    if (!isBoolean(val)) {
      val = this.options.entity.properties?.cacheValuesAutoReload;
    }
    if (!isBoolean(val)) {
      val = this._entityData.autocomplete.properties?.cacheValuesAutoReload;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.cacheValuesAutoReload = val;
  }

  private _changedLoadingDelayDuration(value: number = this.loadingDelayDuration): void {
    let val: number = value;
    if (!isNumber(val)) {
      val = this.options.loadingDelayDuration;
    }
    if (!isNumber(val)) {
      val = this.options.entity.properties?.loadingDelayDuration;
    }
    if (!isNumber(val)) {
      val = this._entityData.autocomplete.properties?.loadingDelayDuration;
    }
    if (!isNumber(val)) {
      val = undefined;
    }
    this.loadingDelayDuration = val;
  }

  private _changedSelectOnFocus(value: boolean = this.selectOnFocus): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.selectOnFocus;
    }
    if (!isBoolean(val)) {
      val = this.options.entity.selectOnFocus;
    }
    if (!isBoolean(val)) {
      val = true;
    }
    this.selectOnFocus = val;
  }

  private _changedHelperMode(value: boolean = this.helperMode): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.helperMode;
    }
    if (!isBoolean(val)) {
      val = this.options.entity.helperMode;
    }
    if (!isBoolean(val)) {
      val = this._entityData.autocomplete.helperMode;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.helperMode = val;
  }

  private _changedReportExceptions(value: boolean = this.reportExceptions): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.reportExceptions;
    }
    if (!isBoolean(val)) {
      val = this.options.entity.reportExceptions;
    }
    if (!isBoolean(val)) {
      val = this._entityData.autocomplete.reportExceptions;
    }
    if (!isBoolean(val)) {
      val = undefined;
    }
    this.reportExceptions = val;
  }

  private _changedQuickCreateEnabled(value: boolean = this.quickCreateEnabled): void {
    let val: boolean = value;
    if (!isBoolean(val)) {
      val = this.options.quickCreateEnabled;
    }
    if (!isBoolean(val)) {
      val = this.options.entity.quickCreateEnabled;
    }
    if (!isBoolean(val)) {
      val = this._entityData.autocomplete.quickCreateEnabled;
    }
    if (!isBoolean(val)) {
      val = false;
    }
    this.quickCreateEnabled = val;
  }

  private _getOutputKey(item: unknown, withFieldsMap: boolean): string {
    try {
      let template: string = this.outputKey;
      if (withFieldsMap && this._hasFieldsMap) {
        template = this._parseTemplate(template);
      }
      return template.includes('{{') ? interpolate(template)(item) : item[template];
    } catch (error: unknown) {
      return undefined;
    }
  }

  private _getOutputDescription(item: unknown, withFieldsMap: boolean): string {
    try {
      let template: string = this.outputDescription;
      if (withFieldsMap && this._hasFieldsMap) {
        template = this._parseTemplate(template);
      }
      return template.includes('{{') ? interpolate(template)(item) : item[template];
    } catch (error: unknown) {
      return undefined;
    }
  }

  private _getOutputValue(item: unknown, withFieldsMap: boolean): string {
    const parse: (template: string) => string = (template: string) => {
      const hasBindings = template.includes('{{');
      if (withFieldsMap && this._hasFieldsMap) {
        template = this._parseTemplate(template);
      }
      try {
        return hasBindings ? interpolate(template)(item) : item[template];
      } catch (error: unknown) {
        return undefined;
      }
    };

    let value = parse(this.output);
    if (isUndefinedOrNull(value) && this.entityOutput !== this.output) {
      value = parse(this.outputDescription);
    }
    if (isUndefinedOrNull(value) && this.outputDescription !== this.output) {
      value = parse(this.outputDescription);
    }
    if (isUndefinedOrNull(value)) {
      value = parse(this._entityData.autocomplete.output);
    }
    /* This is hacky and should not be repeated. When a complex output is used
     * this avoids user unfriendly outputs, such as ' - '.
     */
    if (isString(value) && !REGEX_VALID_CHARACTERS.test(value)) {
      value = '';
    }
    return !isEmpty(value) ? String(value) : value;
  }

  private _getItem(id: string | number): TServiceResponse<unknown> {
    return !isFunction(this.getItemFn)
      ? this._service.get({
          ...this.getItemConfig,
          id: id,
          reportExceptions: isBoolean(this.reportExceptions) ? this.reportExceptions : !this.options.allowInvalid
        })
      : Promise.resolve(this.getItemFn({id: id, config: this.getItemConfig, service: this._service}));
  }

  /**
   * @description fieldsMap should be used as follows:
   *  fieldsMap => {
   *      [entity field key: string]: [corresponding ``item`` property: string]
   *  }
   */
  private _parseTemplate(template: string): string {
    if (!isObject(this.fieldsMap)) {
      return template;
    }
    if (!template.includes('{{')) {
      return Object.prototype.hasOwnProperty.call(this.fieldsMap, template) ? this.fieldsMap[template] : template;
    }
    for (const entityKey of this._fieldsMapKeys) {
      const mapKey = this.fieldsMap[entityKey];
      template = template.replace(new RegExp(`\\b(${entityKey})\\b`, 'g'), mapKey);
    }
    return template;
  }

  private _setOutputValue(value: string): void {
    if (this.outputValue === value && !isEmpty(this.outputValue) && this._plEditAutocomplete.viewValue && this._plEditAutocomplete.viewValue !== this.outputValue) {
      this._plEditAutocomplete.setViewValue(this.outputValue);
    } else {
      this.outputValue = value || '';
    }
  }

  private _evaluateShowActions(): void {
    this.showActions = (this.maintenanceAllowList && this._entityMaintenanceInstance?.maintenanceAllowList) || this.maintenanceAllowEdit || this.customEntityActions.length > 0;
  }

  private _setMaintenanceHeader(): void {
    this.maintenanceHeaderList = this._entityMaintenanceInstance.maintenanceHeaderList;
    this.maintenanceHeaderEdit = this._entityMaintenanceInstance.maintenanceHeaderEdit;
  }

  private async _maintenanceList(): Promise<unknown> {
    const params: Partial<IEntityMaintenanceListModalOptions> =
      this.maintenanceProperties?.modalOptions || this.maintenanceProperties?.listModalOptions ? merge({}, this.maintenanceProperties.modalOptions, this.maintenanceProperties.listModalOptions) : {};

    if (!params.filter) {
      let filter: string;
      if (isString(this.maintenanceProperties?.filter) && this.maintenanceProperties.filter) {
        filter = this.maintenanceProperties.filter;
      }
      if (isString(this.filter) && this.filter && this.maintenanceProperties?.inheritFilter) {
        filter = appendValueToQueryFilter(filter, this.filter);
      }
      if (filter) {
        params.filter = filter;
      }
    }

    const item: unknown = await this._entityMaintenanceInstance.maintenanceList(params);
    if (isObject(item)) {
      this._forceQuickCreate = false;
      return this._select(undefined, item);
    }
    return Promise.resolve();
  }

  private async _maintenanceEdit(): Promise<unknown> {
    const id: string | number = this.selectedKey;
    let params: Partial<IEntityMaintenanceEditModalOptions>;
    if (this.maintenanceProperties?.modalOptions || this.maintenanceProperties?.detailModalOptions) {
      params = merge({}, this.maintenanceProperties.modalOptions, this.maintenanceProperties.detailModalOptions);
    }
    const item: unknown = await this._entityMaintenanceInstance.maintenanceEdit(id, params);
    if (isObject(item)) {
      this._forceQuickCreate = false;
      return this._select(undefined, item);
    }
    return Promise.resolve();
  }

  private _maintenanceNew(): Promise<unknown> {
    const keyValue: string | number = this.selectedKey || this._plEditAutocomplete.formControl.value;
    if (isEmpty(keyValue)) {
      return this._callMaintenanceNew(keyValue);
    }
    return this._service.get({id: keyValue, reportExceptions: false}).catch((reason: HttpErrorResponse) => {
      if (reason.status === EStatusCode.NotFound) {
        return this._callMaintenanceNew(keyValue);
      }
      return Promise.resolve();
    });
  }

  private _callMaintenanceNew(keyValue: string | number): Promise<unknown> {
    const keyName = this._entityData.metadata.keyName;
    const maintenanceParams = {[keyName]: keyValue};
    return this._entityMaintenanceInstance.maintenanceNew({params: maintenanceParams}).then((item: unknown) => {
      if (isObject(item)) {
        this._forceQuickCreate = false;
        return this._select(undefined, item);
      }
      return Promise.resolve();
    });
  }

  private _evaluateCustomActions(): Promise<Array<IEntityAutocompleteCustomAction>> {
    return Promise.all(
      this.customEntityActions.map(async (customEntityAction: IEntityAutocompleteCustomAction) => {
        customEntityAction.caption = this._plTranslateService.translate(customEntityAction.caption);
        if (isFunction(customEntityAction.action)) {
          const originalFn = customEntityAction.action;
          customEntityAction.action = () => originalFn(this.selectedKey, this.value, customEntityAction);
        }
        if (this.isEntityActionsOpen) {
          await this._evaluateCustomActionVisibility(customEntityAction);
        }
        return customEntityAction;
      })
    );
  }

  private _evaluateCustomActionsVisibility(): void {
    for (const customEntityAction of this.customEntityActions) {
      this._evaluateCustomActionVisibility(customEntityAction);
    }
  }

  private async _evaluateCustomActionVisibility(customEntityAction: IEntityAutocompleteCustomAction): Promise<IEntityAutocompleteCustomAction> {
    if (isBoolean(customEntityAction.visible)) {
      customEntityAction._isVisible = customEntityAction.visible;
    } else if (isFunction(customEntityAction.visible)) {
      customEntityAction._isVisible = await Promise.resolve(customEntityAction.visible(this.selectedKey, this.value, customEntityAction));
    } else {
      customEntityAction._isVisible = true;
    }
    return customEntityAction;
  }

  private _validateAllowInvalid(): boolean {
    return this.options.allowInvalid !== false || !this._invalidValue || this.options.allowEmpty !== false || !isEmpty(this.value);
  }

  private _onKeydown(value: unknown, event: KeyboardEvent): void {
    if (event.key === KEYCODES.ESC && this._previousOutputValue) {
      this._revertOutputValue = true;
    }
    if (isFunction(this._originalKeyDown)) {
      this._originalKeyDown(value, event);
    }
    if (this.quickCreateEnabled && event.key === KEYCODES.ADD) {
      event.preventDefault();
      this._forceQuickCreate = true;
      this._maintenanceNew();
    }
  }

  private _onFocus(value: string, event: FocusEvent, emitEvent: boolean = true): void {
    if (this.selectOnFocus) {
      if (!this._previousOutputValue) {
        this._previousOutputValue = undefined;
        const selectedKey = this._getOutputKey(this.model, true);
        if (!event?.defaultPrevented && !isEmpty(selectedKey)) {
          this._previousOutputValue = this.outputValue;
          this._plEditAutocomplete.setViewValue(selectedKey);
          this._plEditAutocomplete.inputSelectAll();
        }
      }
    }
    if (emitEvent && isFunction(this._originalEventFocus)) {
      this._originalEventFocus(value, event);
    }
  }

  private _onBlur(value: string, event: FocusEvent): void {
    if (this._previousOutputValue) {
      const selectedKey = this._getOutputKey(this.model, true);
      const changed: boolean = this._plEditAutocomplete.viewValue !== selectedKey;
      if (!event.defaultPrevented && this._previousOutputValue && !changed) {
        this._plEditAutocomplete.setViewValue(this._previousOutputValue);
        this._previousOutputValue = undefined;
      } else {
        this._previousOutputValue = undefined;
      }
    }
    if (isFunction(this._originalEventBlur)) {
      this._originalEventBlur(value, event);
    }
  }
}
