import {HttpResponse} from '@angular/common/http';
import {Component, Injector, OnInit} from '@angular/core';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {IPlToolbarItem, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {ModuloEntityDetailComponent} from '../../../components/module/entitydetail/module.entitydetail.component';
import {IJsonConfigTransfArmazem} from '../jsonTransferenciasArmazens.entity.interface';
import {TransferenciasArmazensNewLineModalComponent} from '../modals/newline/transferenciasArmazens.newLine.modal.component';
import {TransferenciasArmazensProcessarModalComponent} from '../modals/processartrfarm/transferenciasArmazens.processar.modal.component';
import {ITransfArmazem} from '../transferenciasArmazens.entity.interface';
import {TransferenciasArmazensService} from '../transferenciasArmazens.entity.service';

@Component({
  selector: 'module-transferencias-armazens',
  templateUrl: './transferenciasArmazens.entity.component.html'
})
export class TransferenciasArmazensModuleComponent extends ModuloEntityDetailComponent<IJsonConfigTransfArmazem> implements OnInit {
  public listaTransfArmazem: Array<ITransfArmazem>;
  public dataGridDefinition: IDevExpressDataGrid;

  private readonly _btnProcess: IPlToolbarItem;
  private _configTransfArmazem: IJsonConfigTransfArmazem;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _cgModalService: CGModalService,
    private readonly _transferenciasArmazensService: TransferenciasArmazensService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.listaTransfArmazem = [];
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'origemNArtigo', dataType: 'string', caption: 'transferenciasarmazens.fields.origemNArtigo'},
        {dataField: 'origemNLoteEspecifico', dataType: 'string', caption: 'transferenciasarmazens.fields.origemNLoteEspecifico'},
        {dataField: 'origemNArmazem', dataType: 'number', caption: 'transferenciasarmazens.fields.origemNArmazem'},
        {dataField: 'destinoNArmazem', dataType: 'number', caption: 'transferenciasarmazens.fields.destinoNArmazem'},
        {dataField: 'origemQtd', dataType: 'double', caption: 'transferenciasarmazens.fields.origemQtd'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtn', headerCellTemplate: 'headerCellTemplateBtn'}
      ],
      dataSource: [],
      export: {filename: 'global.menu.transferenciasarmazens'},
      remoteOperations: false
    };
    this._btnProcess = {
      id: 'processar',
      order: 100,
      type: 'button',
      iconLeft: '<i class="fa fa-gears fa-fw"></i>',
      class: 'btn-success',
      caption: 'global.btn.process',
      disabled: true,
      click: () => this._processarModal()
    };
    this._transferenciasArmazensService.getConfiguracoesTransfArmazens().then((response: HttpResponse<IJsonConfigTransfArmazem>) => {
      this._configTransfArmazem = response.body;
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._btnProcess);
    this.btnSave.visible = false;
  }

  public addLine(): void {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(TransferenciasArmazensNewLineModalComponent, {size: 'md'});
    const componentInstance: TransferenciasArmazensNewLineModalComponent = modalInstance.componentInstance;
    componentInstance.listatransfarmazem = this.listaTransfArmazem.slice();
    componentInstance.configTransfArmazem = this._configTransfArmazem;
    modalInstance.result.then((item: ITransfArmazem) => {
      this.listaTransfArmazem.push(item);
      this._evaluateListaTransfArmazem();
    });
  }

  public deleteLine(item: ITransfArmazem): void {
    this.listaTransfArmazem.splice(item._index, 1);
    this._evaluateListaTransfArmazem();
  }

  public editLine(item: ITransfArmazem): void {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(TransferenciasArmazensNewLineModalComponent, {size: 'md'});
    const componentInstance: TransferenciasArmazensNewLineModalComponent = modalInstance.componentInstance;
    componentInstance.listatransfarmazem = this.listaTransfArmazem.slice();
    componentInstance.linhaTransferencia = item;
    modalInstance.result.then((editedItem: ITransfArmazem) => {
      this.listaTransfArmazem[item._index] = editedItem;
    });
  }

  private async _processarModal(): Promise<void> {
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(TransferenciasArmazensProcessarModalComponent, {size: 'sm'});
    const componentInstance: TransferenciasArmazensProcessarModalComponent = modalInstance.componentInstance;
    componentInstance.listatransfarmazem = this.listaTransfArmazem.slice();
    await modalInstance.result.then(() => {
      this.listaTransfArmazem = [];
      this.dataGridDefinition.dataSource = this.listaTransfArmazem;
      this._plAlertService.success('transferenciasarmazens.messages.transfarmsuccess');
    });
  }

  private _evaluateListaTransfArmazem(): void {
    let index = 0;
    for (const iTransfArmazem of this.listaTransfArmazem) {
      iTransfArmazem._index = index;
      index++;
    }

    this.dataGridDefinition.dataSource = this.listaTransfArmazem;
    this._btnProcess.disabled = !this.listaTransfArmazem.length;
  }
}
