import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {downloadStream, EDateMonth, IPlTabsEventSelected, isDefinedNotNull, isNumber, isObject, isString, PlAlertService} from 'pl-comps-angular';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {DATA_SOURCE_MESES} from '../../../datasources/meses/meses.datasource';
import {EmpresasService} from '../../../services/empresas/empresas.service';
import {ESAFTState, ESaftStep, ESAFTType, ISaftError, ISaftFilters, ISaftFornecedorAf, ISAFTStateParams, ISaftStatus} from '../saft.module.interface';
import {IDataSourceItem} from '../../../components/datasource/datasources.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import {SaftService} from '../saft.module.service';
import {HookMatchCriteria, StateDeclaration, StateObject, Transition, TransitionService} from '@uirouter/core';
import {STATE_NAME_LOGIN} from '../../../states/account/login/login.state.interface';
import {STATE_NAME_DISCONNECTED} from '../../../states/account/disconnected/disconnected.state.interface';
import {isTest} from '../../../../config/constants';
import {TranslateService} from '@ngx-translate/core';
import {formatFileBytes} from '../../../../common/utils/file.utils';
import {ROLE} from '../../../services/role.const';
import {AuthService} from '../../../services/auth/auth.service';

const INTERVAL_TIMEOUT = 2000;
const INITIAL_MONTH_POS = 0;
const LAST_MONTH_POS = 11;

@Component({
  selector: 'saft',
  templateUrl: './saft.module.component.html'
})
export class SaftComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public currentStatus: ISaftStatus;
  @Input() public exportContabilidade: boolean;
  @Input() public exportFaturacao: boolean;
  @Input() public exportSaftType: ESAFTType;

  public readonly tabProcess: string;
  public readonly tabDownload: string;
  public readonly saftSteps: typeof ESaftStep;
  public readonly filename: string;
  public readonly mesesSource: ReadonlyArray<IDataSourceItem<EDateMonth>>;
  public readonly periodosSource: ReadonlyArray<IDataSourceItem<EDateMonth>>;
  public readonly typeGroupItems: ReadonlyArray<IDataSourceItem<ESAFTType>>;
  public readonly saftType: typeof ESAFTType;
  public readonly fornecedoresRowTemplate: string = '{{nConta}} - {{nome}} - {{nContribuint}}';
  public readonly fornecedoresOutput: string = '{{nConta}}';

  public hasContabilidadeRole: boolean;
  public model: ISaftFilters;
  public anosSource: Array<number>;
  public currentStep: ESaftStep;
  public statusDescription: string;
  public statusErrorList: Array<ISaftError>;
  public pbPos: number;
  public isBlocked: boolean;
  public tabActiveId: string;
  public fornecedoresAfSource: Array<ISaftFornecedorAf>;

  private readonly _stateParams: ISAFTStateParams;

  private _intervalId: number;
  private _deRegisterOnStartFn: Function;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _transitionService: TransitionService,
    protected readonly _translateService: TranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _empresasService: EmpresasService,
    private readonly _saftService: SaftService,
    private readonly _plAlertService: PlAlertService,
    private readonly _authService: AuthService
  ) {
    super(_injector);
    this.tabProcess = 'tabProcessamento';
    this.tabDownload = 'tabDownload';
    this.saftSteps = ESaftStep;
    this.saftType = ESAFTType;
    this.filename = 'saf-t.xml';
    this.mesesSource = [{value: <EDateMonth>-1, label: 'global.text.all', name: 'global.text.all'}, ...DATA_SOURCE_MESES.data];
    this.periodosSource = DATA_SOURCE_MESES.data;
    this.typeGroupItems = [
      {value: ESAFTType.CONTABILIDADE_FATURACAO_RECIBOS, label: 'saft.contabilidadeFaturacaoRecibos'},
      {value: ESAFTType.AUTOFATURACAO, label: 'saft.autofaturacao'}
    ];
    this.fornecedoresAfSource = [];
    this._stateParams = <ISAFTStateParams>this._transition.params();
    this.exportSaftType = this._stateParams?.exportSaftType ? this._stateParams?.exportSaftType : this.exportSaftType;
    this._authService.hasAuthority(ROLE.CONTABILIDADE).then((response) => {
      this.hasContabilidadeRole = response;
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.tabActiveId = this.tabProcess;
    this.anosSource = [];
    this.statusErrorList = [];

    this._empresasService.getAnos(this.session.erp.nEmpresa).then((response) => {
      if (response.body.list.length) {
        this.anosSource = response.body.list.map((value) => value.ano);
        if (!this.anosSource.includes(this._configService.configurations.empresa.anoEmCursoIRC + 1)) {
          this.anosSource.unshift(this._configService.configurations.empresa.anoEmCursoIRC + 1);
        }

        if (isDefinedNotNull(this._stateParams.ano) && this._stateParams.ano > -1) {
          this.model.ano = this._stateParams.ano;
        }

        if (isDefinedNotNull(this._stateParams.mes) && this._stateParams.mes > -1) {
          const sourceItem = this.mesesSource.find((item) => item.value === this._stateParams.mes);
          if (isDefinedNotNull(sourceItem)) {
            this.onMesChange(sourceItem);
          }
        }
      }
    });

    this._saftService.getFornecedoresAf().then((response) => {
      this.fornecedoresAfSource = response.body;
    });

    if (this.currentStatus.state === ESAFTState.Timeout) {
      this._showTimeoutModal();
      this._init();
    } else if (this.currentStatus.state === ESAFTState.Error) {
      this.currentStep = ESaftStep.Errors;
      this._handleErrors(this.currentStatus);
    } else if (this.currentStatus.state === ESAFTState.Ended) {
      this._init(this.tabDownload);
    } else if (this.currentStatus.state === ESAFTState.Inactive) {
      this._init();
    } else {
      this.isBlocked = this.currentStatus.userStartedId !== this.session.userId;
      if (!this.isBlocked) {
        this.currentStep = ESaftStep.Processing;
        this._startProcessChecker();
      }
    }

    this.model.type = this.exportSaftType === ESAFTType.AUTOFATURACAO ? ESAFTType.AUTOFATURACAO : ESAFTType.CONTABILIDADE_FATURACAO_RECIBOS;

    setTimeout(() => {
      this._registerOnStart();
    });
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    const pageTitle = this._translateService.instant('saft.title.normal');
    if (this._state.data.pageTitle !== pageTitle) {
      this._state.data.pageTitle = pageTitle;
    }
    this._clearProcessChecker();
    this._deRegisterOnStart();
    this._saftService.cancelProcess();
  }

  public onMesChange(item: IDataSourceItem<EDateMonth>): void {
    this.model.mes.label = item.label;
    this.model.mes.name = item.name;
    this.model.mes.value = item.value;
  }

  public onPeriodoDeChange(item: IDataSourceItem<EDateMonth>): void {
    this.model.mesDe.label = item.label;
    this.model.mesDe.name = item.name;
    this.model.mesDe.value = item.value;
  }
  public onPeriodoAteChange(item: IDataSourceItem<EDateMonth>): void {
    this.model.mesAte.label = item.label;
    this.model.mesAte.name = item.name;
    this.model.mesAte.value = item.value;
  }

  public closeErrorPanel(): void {
    this._init();
  }

  public formatBytes(bytes: number): string {
    return formatFileBytes(bytes);
  }

  public onTabChanged(event: IPlTabsEventSelected): void {
    this.tabActiveId = String(event.nextId);
    this.model.type = this.exportSaftType === ESAFTType.AUTOFATURACAO ? ESAFTType.AUTOFATURACAO : ESAFTType.CONTABILIDADE_FATURACAO_RECIBOS;
  }

  public fornecedoresAfChanged(fornecedor: string | ISaftFornecedorAf): void {
    let nConta: string;
    let nomeClifo: string;
    if (isString(fornecedor)) {
      const fornecedorObject = this.fornecedoresAfSource.find((item) => item.nConta === fornecedor);
      if (fornecedorObject) {
        fornecedor = fornecedorObject;
      }
    }

    if (isObject(fornecedor)) {
      fornecedor = <ISaftFornecedorAf>fornecedor;
      nConta = fornecedor.nConta;
      nomeClifo = fornecedor.nome;
    }

    this.model = {
      ...this.model,
      nConta: nConta,
      nomeClifo: nomeClifo
    };
  }

  public readonly fnVisualProcess = (): Promise<void> => this._visualProcess();

  public doDownload(): Promise<void> {
    return this._saftService.downloadFile().then((blob: HttpResponse<Blob>) => {
      if (!blob) {
        this._plAlertService.error(this._translateService.instant('saft.erroDownload'));
      } else {
        downloadStream(blob);
      }
    });
  }

  protected _onPageUnload(): void {
    super._onPageUnload();
    this._appService.sendBeacon(this._saftService.cancelProcessUrl());
  }

  private _init(activeTab: string = this.tabProcess): void {
    this.currentStep = ESaftStep.Configuration;
    this.statusDescription = '';
    this.statusErrorList = [];
    this.pbPos = 0;
    const captionAll: string = this._translateService.instant('global.text.all');
    const firstMonth: IDataSourceItem<EDateMonth> = DATA_SOURCE_MESES.data[INITIAL_MONTH_POS]; // Janeiro
    const lastMonth: IDataSourceItem<EDateMonth> = DATA_SOURCE_MESES.data[LAST_MONTH_POS]; // Dezembro
    this.model = {
      ano: this._configService.configurations.empresa.anoEmCursoIRC,
      mes: {value: <EDateMonth>-1, label: captionAll, name: captionAll},
      mesDe: {value: firstMonth.value, label: firstMonth.label, name: firstMonth.name},
      mesAte: {value: lastMonth.value, label: lastMonth.label, name: lastMonth.name},
      faturacao: this.exportFaturacao,
      contabilidade: this.exportContabilidade,
      recibos: true,
      type: ESAFTType.CONTABILIDADE_FATURACAO_RECIBOS,
      nConta: '',
      nomeClifo: ''
    };
    if (activeTab.length) {
      this.tabActiveId = activeTab;
    }
  }

  private _visualProcess(): Promise<void> {
    if (!this.currentStatus.ficheiroProcessado) {
      return this._processar();
    }
    return this._cgModalService
      .showOkCancel('saft.promptReprocessTitle', 'saft.promptReprocessMessage', {
        size: 'md',
        btnOkText: 'saft.buttons.yes',
        btnCancelText: 'saft.buttons.no',
        backdrop: 'static',
        keyboard: false
      })
      .then(() => this._processar());
  }

  private _processar(): Promise<void> {
    this.currentStatus.ficheiroProcessado = false;
    const promise =
      this.model.type === ESAFTType.CONTABILIDADE_FATURACAO_RECIBOS
        ? this.hasContabilidadeRole
          ? this._saftService.processarPeriodo(this.model.ano, this.model.mesDe.value, this.model.mesAte.value, this.model.contabilidade, this.model.faturacao, this.model.recibos)
          : this._saftService.processar(this.model.ano, this.model.mes.value, this.model.contabilidade, this.model.faturacao, this.model.recibos)
        : this._saftService.processarAutofaturacao(this.model.ano, this.model.mes.value, this.model.nConta);
    return promise.then(() => {
      this.currentStep = ESaftStep.Processing;
      this._startProcessChecker();
    });
  }

  private _startProcessChecker(): void {
    this._clearProcessChecker();
    this._intervalId = window.setInterval(() => {
      this._saftService.getStatus().then((response: HttpResponse<ISaftStatus>) => {
        this.currentStatus = response.body;
        if (this.currentStatus.state === ESAFTState.Timeout) {
          this._showTimeoutModal();
          this._clearProcessChecker();
          this._init();
          return;
        } else if (this.currentStatus.state === ESAFTState.Error) {
          this.currentStep = ESaftStep.Errors;
          this._handleErrors(this.currentStatus);
          this._clearProcessChecker();
          return;
        } else if (this.currentStatus.state === ESAFTState.Ended) {
          if (this.currentStatus.ficheiroProcessado) {
            this._init(this.tabDownload);
          } else {
            this.statusErrorList.push({nome: 'saft.generation', descricao: 'saft.fileNotProcessed'});
            this.currentStep = ESaftStep.Errors;
            this.tabActiveId = this.tabProcess;
          }
          this._clearProcessChecker();
          return;
        }
        this.pbPos = Math.round((this.currentStatus.position * 100) / this.currentStatus.max);
        this.statusDescription = this.currentStatus.description;
      });
    }, INTERVAL_TIMEOUT);
  }

  private _showTimeoutModal(): Promise<void> {
    return this._cgModalService.showOkCancel('saft.jobTimeoutModalTitle', 'saft.jobTimeoutModalMessage', {
      size: 'md',
      showCancelBtn: false,
      btnOkText: 'saft.buttons.reiniciar',
      backdrop: 'static',
      keyboard: false
    });
  }

  private _handleErrors(status: ISaftStatus): void {
    this.statusErrorList = status.errorList.length ? status.errorList : [{nome: this._translateService.instant('global.text.error'), descricao: status.description}];
  }

  private _clearProcessChecker(): void {
    if (isNumber(this._intervalId)) {
      window.clearTimeout(this._intervalId);
      this._intervalId = undefined;
    }
  }

  private _registerOnStart(): void {
    this._deRegisterOnStart();
    const criteria: HookMatchCriteria = {
      to: (state: StateObject, transition: Transition) => {
        const toState: StateDeclaration = transition.to();
        return transition.from() !== toState && toState.name !== STATE_NAME_LOGIN && toState.name !== STATE_NAME_DISCONNECTED;
      }
    };
    this._deRegisterOnStartFn = this._transitionService.onStart(criteria, () => this._navigationSafeGuard());
  }

  private _deRegisterOnStart(): void {
    if (this._deRegisterOnStartFn) {
      this._deRegisterOnStartFn();
      this._deRegisterOnStartFn = undefined;
    }
  }

  private _navigationSafeGuard(): Promise<void> {
    if (!isTest()) {
      if (this.currentStatus.state === ESAFTState.Ended || this.currentStatus.state === ESAFTState.Started || this.currentStatus.state === ESAFTState.Scheduled) {
        return this._cgModalService.showOkCancel('saft.leavePromptTitle', 'saft.leavePromptMessage', {
          size: 'md',
          backdrop: 'static',
          keyboard: false,
          btnOkText: 'global.btn.yes',
          btnCancelText: 'global.btn.no'
        });
      }
    }
    return Promise.resolve();
  }
}
