import type {Injector, ProviderToken} from '@angular/core';
import type {TranslateService} from '@ngx-translate/core';
import {IPlFilterPanelField, IPlFormTemplate, IPlFormTemplateFieldItemField, IPlTableField, TPlTableFieldType, TValueOrPromise} from 'pl-comps-angular';
import {DeepPartial} from '../../../common/interfaces/interfaces';
import {EEntityStateDetailType} from '../../../common/utils/entity.state.utils';
import {IApiRequestConfig} from '../../services/api/api.service.interface';
import {ICGStateDeclaration, ICGStateDeclarationData} from '../../services/portals/portals.service.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../devexpress/datagrid/devexpress.datagrid.interface';
import {IEntityAutocomplete, IEntityAutocompleteDefinition} from './entity.autocomplete.definition.interface';
import {IEntityFormDefinition, TEntityEditType} from './entity.form.definition.interface';
import {IEntityService, IEntityServiceEvents, TEntityServiceRequestData} from '../../services/entity/entity.service.interface';
import {IModuleHelperLink} from '../module/module.definition.interface';
import {ISiteStateParams} from '../../../common/site';
import {ROLE} from '../../services/role.const';

export type TEntityDefinitionCallable<T> = (this: T, ...args: Array<any>) => void;

export type TEntityMetadataName = 'list' | 'detail' | 'new' | 'edit' | 'filter' | 'search';

export type TEntityEvaluationMethod<T> = (model: T) => string | number;

export type TEntityDeletePromptFn<T> = (args: IEntityDeletePromptArgs<T>) => TValueOrPromise<void | IApiRequestConfig>;

export interface IEntityMetadata {
  fields: Array<IEntityMetadataField>;
  keyName: string;
  descriptionName?: string;
  order?: string;
  listFields?: string;
  detailFields?: string;
  editFields?: string;
  filterFields?: string;
  filterField?: string;
  filterHiddenFields?: string;
  filterPersistentFields?: string;
  newFields?: string;
  searchFields?: string;
  successMessageId?: string | TEntityEvaluationMethod<unknown>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IEntityMetadataField<T = any> extends IPlTableField, IPlFilterPanelField {
  type?: TEntityEditType | TPlTableFieldType<string>;
  formName?: string;
  entity?: IEntityAutocomplete;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validators?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  events?: any;
  visible?: boolean | ((model?: T, field?: IPlFormTemplateFieldItemField) => boolean);
  readonly?: boolean | ((model: T, field: IPlFormTemplateFieldItemField) => boolean);
  disabled?: boolean | ((model: T, field: IPlFormTemplateFieldItemField) => boolean);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any;
  entityData?: string;
  properties?: IEntityMetadataFieldProperties;
}

export interface IEntityMetadataFieldProperties {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [prop: string]: any;

  devExpress?: IEntityMetadataFieldPropertiesDevExpress;
}

export interface IEntityMetadataFieldPropertiesDevExpress {
  dataGrid?: IDevExpressDataGridColumn;
}

export interface IEntityActions {
  new: boolean;
  detail: boolean;
  edit: boolean;
  delete: boolean;
  search?: boolean;
  filter?: boolean;
  duplicate?: boolean;
  batchAdd?: boolean;
  newMultiple?: boolean;
  modernPagination?: boolean;
}

export interface IEntityDefinitionState {
  roles?: Array<ROLE>;
  requiredRoles?: Array<ROLE>;
  pluginsRoles?: Array<ROLE>;
  pageTitle?: string;
  state?: IEntityStateDeclaration;
  template?: IPlFormTemplate;
  helperLinks?: IModuleHelperLink | Array<IModuleHelperLink>;
}

export interface IEntityDefinitionStates {
  list?: IEntityDefinitionState;
  detail?: IEntityDefinitionState;
  new?: IEntityDefinitionState;
  edit?: IEntityDefinitionState;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IEntityDefinition<TEntityJson extends object = any, TEntityService extends IEntityService<TEntityJson> = IEntityService<TEntityJson>> extends IEntityDefinitionStates {
  name: string;
  asModule?: boolean;
  url?: string;
  redirectKey?: string;
  roles?: Array<ROLE>;
  requiredRoles?: Array<ROLE>;
  pluginsRoles?: Array<ROLE>;
  metadata: IEntityMetadata;
  autocomplete?: IEntityAutocompleteDefinition;
  actions?: IEntityActions;
  devExpress?: IEntityDevExpressDefinition;
  searchPlaceholder?: string;
  pageTitle?: string;
  sidebarTitle?: string;
  icon?: string;
  entityUrl?: string;
  urlSuffix?: string;
  helperLinks?: IModuleHelperLink | Array<IModuleHelperLink>;
  serviceMethodsOverride?: IEntityServiceMethodsOverride;
  evaluationMethods?: IEntityEvaluationMethods<TEntityJson>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  serviceInjectable?: [...Array<ProviderToken<any>>, TEntityDefinitionCallable<TEntityService>];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventsInjectable?: [...Array<ProviderToken<any>>, TEntityDefinitionCallable<IEntityServiceEvents<TEntityJson>>];

  getDeleteMessage?(model: TEntityJson, translate: TranslateService): IEntityDeleteMessage;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  service?(this: TEntityService, ...args: Array<ProviderToken<any>>): void;

  events?(this: IEntityServiceEvents<TEntityJson>, ...args: Array<ProviderToken<unknown>>): void;

  deletePromptFactory?(args: IEntityDeletePromptFactoryArgs<TEntityJson>): TValueOrPromise<void | IApiRequestConfig>;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface IEntity<TEntityJson extends object = any, TEntityService extends IEntityService<TEntityJson> = IEntityService<TEntityJson>> extends IEntityDefinition<TEntityJson, TEntityService> {
  list: IEntityState;
  new: IEntityState;
  detail: IEntityState;
  edit: IEntityState;
  devExpress: IEntityDevExpress;

  getMetadata(name: TEntityMetadataName, fields?: string): IEntityMetadata;

  getFieldDef(fieldName: string): IEntityMetadataField;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldEvents(fieldName: string): IEntityFieldEvents<any>;

  getId(model: TEntityJson): string | number;

  setId(model: TEntityJson, value: string | number | DeepPartial<TEntityJson>): void;

  deleteId(model: TEntityJson): void;

  getDescriptionName(model: TEntityJson): string;

  getCaption(detailStateType: EEntityStateDetailType, id: string | number): string;

  getSuccessMessageId(model: TEntityJson): string | number;

  deleteProperty(model: TEntityJson, propertyName: string): void;

  deletePrompt(args: IEntityDeletePromptArgs<TEntityJson>): TValueOrPromise<void | IApiRequestConfig>;
}

export interface IEntityState extends IEntityDefinitionState {
  definition?: IEntityFormDefinition;
}

export interface IEntityStateDeclaration extends ICGStateDeclaration {
  data?: IEntityStateDeclarationData;
}

export interface IEntityStateDeclarationData extends ICGStateDeclarationData {
  getRequestConfig?: TEntityServiceRequestData;
}

export interface IEntityEventFields {
  [fieldName: string]: IEntityEventField;
}

export interface IEntityEventField {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [eventName: string]: (...args: Array<any>) => any;
}

export interface IEntityFieldEvents<T> {
  blur?(callback: (value: T, event: FocusEvent) => void): IEntityFieldEvents<T>;

  focus?(callback: (value: T, event: FocusEvent) => void): IEntityFieldEvents<T>;

  beforeChange?(callback: (newValue: T, oldValue: T) => boolean | Promise<void | boolean>): IEntityFieldEvents<T>;

  change?(callback: (value: T) => void): IEntityFieldEvents<T>;

  keydown?(callback: (value: T, event: KeyboardEvent) => void): IEntityFieldEvents<T>;

  keypress?(callback: (value: T, event: KeyboardEvent) => void): IEntityFieldEvents<T>;

  keyup?(callback: (value: T, event: KeyboardEvent) => void): IEntityFieldEvents<T>;

  mousedown?(callback: (value: T, event: MouseEvent) => void): IEntityFieldEvents<T>;

  mouseenter?(callback: (value: T, event: MouseEvent) => void): IEntityFieldEvents<T>;

  mouseleave?(callback: (value: T, event: MouseEvent) => void): IEntityFieldEvents<T>;

  mousemove?(callback: (value: T, event: MouseEvent) => void): IEntityFieldEvents<T>;

  mouseover?(callback: (value: T, event: MouseEvent) => void): IEntityFieldEvents<T>;

  mouseup?(callback: (value: T, event: MouseEvent) => void): IEntityFieldEvents<T>;

  click?(callback: (value: T, event: MouseEvent) => void): IEntityFieldEvents<T>;

  clearEvents?(fieldName?: string): IEntityFieldEvents<T>;

  build?(type: EEntityStateDetailType): IEntityFormDefinition;
}

export interface IEntityDeleteMessage {
  title: string;
  message: string;
}

export interface IEntityListServiceMethodsOverride {
  query?: string | IEntityService['query'];
}

export interface IEntityDetailServiceMethodsOverride {
  get?: string | IEntityService['get'];
  post?: string | IEntityService['post'];
  put?: string | IEntityService['put'];
  delete?: string | IEntityService['delete'];
}

export interface IEntityServiceMethodsOverride extends IEntityListServiceMethodsOverride, IEntityDetailServiceMethodsOverride {}

export interface IEntityEvaluationMethods<T> {
  evaluateId?: TEntityEvaluationMethod<T>;
  evaluateDescriptionName?: TEntityEvaluationMethod<T>;
}

export interface IEntityDeletePromptFactoryArgs<T> extends IEntityDeletePromptArgs<T> {
  readonly superDeletePrompt: TEntityDeletePromptFn<T>;
}

export interface IEntityDeletePromptArgs<T> {
  readonly model: T;
  readonly injector: Injector;
}

export interface IEntityDetailStateParams<TModel = unknown> extends ISiteStateParams {
  id: string | number;
  model?: TModel;
}

export interface IEntityDevExpressDefinition<TRowData = unknown, TKey = unknown> {
  dataGrid?: IDevExpressDataGrid<TRowData, TKey>;
}

export interface IEntityDevExpress<TRowData = unknown, TKey = unknown> extends IEntityDevExpressDefinition<TRowData, TKey> {
  dataGrid: IEntityDevExpressDataGrid<TRowData, TKey>;
}

export interface IEntityDevExpressDataGrid<TRowData = unknown, TKey = unknown> extends IDevExpressDataGrid<TRowData, TKey> {
  getMetadataColumns(name: TEntityMetadataName, fields?: string): Array<IDevExpressDataGridColumn<TRowData, TKey>>;
}

export const DATAGRID_COLUMN_NAME_ENTITY_LIST_BUTTONS = 'entityListButtons';

export const DATAGRID_TEMPLATE_ENTITY_LIST_HEADER_ACTIONS = 'entityListHeaderActions';

export const DATAGRID_TEMPLATE_ENTITY_LIST_ITEM_ACTIONS = 'entityListItemActions';

export const DATAGRID_TEMPLATE_ENTITY_LIST_ITEM_DETAIL = 'entityListItemMasterDetail';
