<div class="utilizadores-edit entity-detail-form">
  <pl-form>
    <pl-nav-wizard [onFinalize]="fnFinalize">
      <!--GABINETE-->
      <pl-nav-wizard-step caption="assistenteconfigportais.steps.stepGabinete" icon="fa-university">
        <pl-form *plNavWizardStepContent formInstanceName="gabinete" [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <pl-group *ngIf="instalacoes.length">
              <label [translate]="'erputilizadores.fields.erp'"></label>
              <edit>
                <pl-edit-select
                  attrName="instalacao"
                  [model]="centralGestId"
                  (modelChange)="changedInstalacao($event)"
                  [properties]="{select: {list: instalacoes, labelProp: 'name', valueProp: 'centralGestId'}, disallowClear: true}">
                </pl-edit-select>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'assistenteconfigportais.outros.gabinete'"></label>
              <edit>
                <entity-autocomplete
                  entity="empresas"
                  attrName="gabinete"
                  placeholder="empresas.pesquisa"
                  [model]="gabinete"
                  (modelChange)="changedGabinete($event)"
                  [filter]="filterGabinetes"
                  [getDataConfig]="{params: {centralGestId: centralGestId}}">
                </entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
        </pl-form>
      </pl-nav-wizard-step>

      <!--EMPRESA-->
      <pl-nav-wizard-step caption="assistenteconfigportais.steps.stepEmpresa" icon="fa-building-o">
        <pl-form *plNavWizardStepContent formInstanceName="empresa" [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <label [translate]="'assistenteconfigportais.outros.empresa'"></label>
            <edit>
              <entity-autocomplete
                entity="empresas"
                attrName="empresa"
                placeholder="empresas.pesquisa"
                [model]="empresa"
                (modelChange)="changedEmpresa($event)"
                [filter]="filterEmpresas"
                [getDataConfig]="{params: {centralGestId: centralGestId}}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-form>
      </pl-nav-wizard-step>

      <!--UTILIZADOR-->
      <pl-nav-wizard-step caption="assistenteconfigportais.steps.stepUtilizador" icon="fa-user">
        <pl-form *plNavWizardStepContent formInstanceName="utilizador" [properties]="{validators: {required: {value: true}}}">
          <pl-group>
            <label [translate]="'assistenteconfigportais.fields.novoutilizador'"></label>
            <edit>
              <pl-edit attrName="novoUtilizador" type="boolean" [(model)]="novoUtilizador" [properties]="{validators: {required: {value: false}}}"></pl-edit>
            </edit>
          </pl-group>

          <ng-container *ngIf="novoUtilizador">
            <pl-group>
              <pl-group>
                <label [translate]="'assistenteconfigportais.fields.nomeutilizador'"></label>
                <edit>
                  <pl-edit type="string" attrName="username" [(model)]="user.username"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'assistenteconfigportais.fields.email'"></label>
                <edit>
                  <pl-edit type="email" attrName="email" [(model)]="user.email"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'assistenteconfigportais.fields.nome'"></label>
                <edit>
                  <pl-edit type="string" attrName="firstName" [(model)]="user.firstName"></pl-edit>
                </edit>
              </pl-group>

              <pl-group [properties]="{validators: {required: {value: false}}}">
                <label [translate]="'assistenteconfigportais.fields.apelido'"></label>
                <edit>
                  <pl-edit type="string" attrName="lastName" [(model)]="user.lastName"></pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <label [translate]="'global.text.active'"></label>
              <edit>
                <pl-edit type="boolean" attrName="activo" [(model)]="user.active"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'assistenteconfigportais.fields.enviaEmail'"></label>
              <edit>
                <pl-edit type="boolean" attrName="enviaEmail" [(model)]="enviaEmail"></pl-edit>
              </edit>
            </pl-group>

            <pl-group>
              <label [translate]="'assistenteconfigportais.fields.addempregado'"></label>
              <edit>
                <pl-edit type="boolean" attrName="associarEmpregado" [(model)]="associarEmpregado" [properties]="{validators: {required: {value: false}}}"></pl-edit>
              </edit>
            </pl-group>

            <pl-group *ngIf="associarEmpregado">
              <pl-group>
                <label [translate]="'assistenteconfigportais.fields.codempregado'"></label>
                <edit>
                  <entity-autocomplete entity="dgemps" attrName="codEmpregado" [(model)]="empregado" [filter]="filterEmpregados" [properties]="{allowInvalid: false}"></entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>
          </ng-container>
        </pl-form>
      </pl-nav-wizard-step>
    </pl-nav-wizard>
  </pl-form>
</div>
