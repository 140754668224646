<div class="trfba entity-detail-form">
  <pl-split-view [initialSizeLeft]="30" [initialSizeRight]="70">
    <div *plSplitViewLeftSide>
      <fieldset>
        <legend [translate]="'trfba.documentos'"></legend>
        <dx-tree-list
          id="treeListPlainTRFBA"
          [cacheEnabled]="false"
          [dataSource]="dataSourceTreePlain"
          [showBorders]="true"
          [showColumnHeaders]="false"
          (onInitialized)="onInitializedTreePlain($event)"
          (onRowClick)="onRowClickTreePlain($event)"
          (onRowExpanded)="onRowExpanded($event)"
          dataStructure="tree"
          itemsExpr="childNodes"
          hasItemsExpr="hasChildNodes">
          <dxi-column dataField="nodeText"></dxi-column>
        </dx-tree-list>
      </fieldset>
    </div>

    <div *plSplitViewRightSide>
      <div class="d-flex mt-3 list-ativos-totals ms-auto">
        <div class="card">
          <div class="card-header">
            <span class="fa fa-tag float-start"></span>
            <span class="total-listagens float-end" [translate]="'trfba.table.resumo'"></span>
          </div>
          <div class="card-body">
            <dx-data-grid [cgDxDataGrid]="dataGridDefinitionTotals" [dataSource]="trfBATotals"></dx-data-grid>
          </div>
        </div>
      </div>
      <fieldset>
        <legend [translate]="'trfba.pagreg'"></legend>
        <pl-group *ngIf="showRGFormato">
          <label [translate]="'trfba.radio.rgFormato'"></label>
          <edit>
            <pl-edit type="radiogroup" attrName="rgFormato" [(model)]="rgFormato" [properties]="radioGroupRGFormato"></pl-edit>
          </edit>
        </pl-group>

        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          [(selectedRowKeys)]="selectedRowKeys"
          cgDxDataGridInstanceName="cessacaoconttrabmodal"
          [dataSource]="dataGridDefinition.dataSource"
          (onInitialized)="onInitialized($event)"
          (onCellClick)="onCellClick($event)">
          <div *dxTemplate="let item of 'templateToolbarBtns'">
            <pl-button *ngIf="!itemTreeSelected?.nodeValue?.estado" klass="btn-primary btn-sm action-generate me-2" (evtClicked)="gerarFicheiro()">
              <i class="fa fa-fw fa-cog"></i>&nbsp;<span [translate]="'trfba.btn.geraficheiro'"></span>
            </pl-button>

            <pl-button *ngIf="!itemTreeSelected?.nodeValue?.estado" klass="btn-danger btn-sm action-delete" (evtClicked)="deleteTransferencias()">
              <i class="fa fa-fw fa-trash"></i>&nbsp;<span [translate]="'trfba.btn.eliminartransf'"></span>
            </pl-button>

            <pl-button *ngIf="itemTreeSelected?.nodeValue?.estado" klass="btn-primary btn-sm action-refresh" (evtClicked)="alterarEstado()">
              <i class="fa fa-fw fa-refresh"></i>&nbsp;<span [translate]="'trfba.btn.alterarestado'"></span>
            </pl-button>
          </div>
        </dx-data-grid>
      </fieldset>
    </div>
  </pl-split-view>
</div>
