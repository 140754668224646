import {IArtigosConfigOptions} from '../../services/config/options/config.options.service.interface';
import {IConfigOptionsEvaluateInvisibleParams} from '../../components/configoptions/config.options.component.interface';
import {IEntityDefinition, IEntityDetailStateParams} from '../../components/entity/entity.definition.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {IGenericImporterExecutor} from '../../components/genericimporter/generic.importer.component.interface';
import {IJsonArtigo, IJsonArtigosProcessParams, IJsonArtigoStockArmazens} from './jsonArtigo.entity.interface';
import {IApiRequestConfig, TServiceResponse} from '../../services/api/api.service.interface';
import {TDate} from '../../../common/dates';

export interface IArtigosEntityService extends IEntityService<IJsonArtigo>, IGenericImporterExecutor {
  getFirst(): TServiceResponse<IJsonArtigo>;

  getNext(nArtigo: string): TServiceResponse<IJsonArtigo>;

  getPrev(nArtigo: string): TServiceResponse<IJsonArtigo>;

  getLast(): TServiceResponse<IJsonArtigo>;

  getStockArtigoNosArmazens(nArtigo: string): TServiceResponse<IJsonArtigoStockArmazens>;

  processaStockArtigosNosArmazens(artigosProcessParams: IJsonArtigosProcessParams, soQtdTotal: boolean): TServiceResponse<Array<IJsonArtigoStockArmazens>>;

  deletePicture(nArtigo: string): TServiceResponse<void>;

  getPicture(nArtigo: string, config?: IApiRequestConfig): TServiceResponse<Blob>;

  setPicture(nArtigo: string, file: File, config?: IApiRequestConfig): TServiceResponse<void>;
}

export interface IArtigosEntity extends IEntityDefinition<IJsonArtigo, IArtigosEntityService> {}

export interface IArtigosEvaluateConfigOptionInvisibleParams extends IConfigOptionsEvaluateInvisibleParams<boolean, IArtigosConfigOptions> {}

export interface IArtigosParams extends IEntityDetailStateParams<IJsonArtigo> {
  nArtigo?: string;
  dataDoc?: TDate;
}

export interface IArtigo extends IJsonArtigo {
  qtdAdd?: number;
}

export const ENTITY_NAME_ARTIGOS = 'artigos';
