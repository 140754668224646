import type {UnleashClient} from 'unleash-proxy-client';
import type {IFeatureFlagsRepository} from './featureflag.service.interface';

type TFeatureFlagClient = () => UnleashClient;

export class FeatureFlagsRepository implements IFeatureFlagsRepository {
  private _client: UnleashClient;

  constructor(private readonly _getClient: TFeatureFlagClient) {}

  public isEnabled(toggleName: string): boolean {
    if (!this._client) {
      this._client = this._getClient();
      if (!this._client) {
        return false;
      }
    }
    return this._client.isEnabled(toggleName);
  }

  public get languagePicker(): boolean {
    return this.isEnabled('cloud_ui_language_picker');
  }

  public get skin(): boolean {
    return this.isEnabled('cloud_ui_skin');
  }

  public get amaliaVisible(): boolean {
    return this.isEnabled('cloud_ui_amalia_visible');
  }
}
