import {Component, Injector, Input, OnInit} from '@angular/core';
import {
  EDebDiretosSEPAClienteTipoNAutorizacao,
  EDebDiretosSEPAEstadoItem,
  EDebDiretosTitularClifo,
  IDebDiretosSEPAIbanCredorItem,
  IJsonDebDiretosSEPACliente,
  IJsonDebDiretosSEPAClienteContrato,
  IJsonDebDiretosSEPAConfiguracao,
  IJsonDebDiretosSEPAMeiosPagamento
} from '../debDiretosSEPA.module.interface';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {copy, downloadStream, isDefinedNotNull, isEmpty, isUndefinedOrNull, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {IJsonClifo} from '../../../entities/clifos/jsonClifo.entity.interface';
import {DefinicaoEmailService} from '../../../entities/clifos/components/component/definicaoemails/definicaoEmail.service';
import {EClifoExtraValueTipo} from '../../../entities/clifoextravaluetipo/jsonClifoExtraValueTipo.entity.interface';
import {DebDiretosSEPAModuleService} from '../debDiretosSEPA.module.service';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import moment from 'moment';
import {IJsonContratoCab} from '../../../entities/contratos/jsonContrato.entity.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../services/entity/entity.service.interface';
import {ENTITY_NAME_AVENCAS} from '../../../entities/contratos/contrato.entity.interface';
import {DebDiretosSEPAImpressaoClienteModalComponent} from './debDiretosSEPA.impressaoCliente.modal.component';
import {TDate} from '../../../../common/dates';
import {HttpResponse} from '@angular/common/http';
import {IApiQueryResponse} from '../../../services/api/api.service.interface';
import {IJsonClifoExtraValue} from '../../../entities/clifoextravalue/jsonClifoExtraValue.entity.interface';
import {IJsonMeioPagamento} from '../../../entities/meiospagamento/jsonMeioPagamento.entity.interface';

@Component({
  selector: 'ddsepa-cliente-modal',
  templateUrl: './debDiretosSEPA.cliente.modal.component.html'
})
export class DebDiretosSEPAClienteModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public model: IJsonDebDiretosSEPACliente;
  @Input() public editMode: boolean;
  @Input() public listaClientes: Array<IJsonDebDiretosSEPACliente>;

  public readonly pocsOutput: string = '{{nConta}} - {{nome}}';
  public debDiretosSEPAClienteTipoNAutorizacao: typeof EDebDiretosSEPAClienteTipoNAutorizacao;
  public titularClifo: EDebDiretosTitularClifo;
  public uploadUrl: string;
  public contratosFilter: string;
  public contratosTemplate: string;
  public contratosOutput: string;
  public nContaChangePromise: Promise<void>;
  public contratosSource: Array<IJsonDebDiretosSEPAClienteContrato>;
  public contratosModel: Array<number>;
  public ibanBancoCredorSource: Array<IDebDiretosSEPAIbanCredorItem>;
  public documentoPorLiquidarAteDataCount: number;
  public documentoPorLiquidarAteDataTooltipText: string;
  public nAutorizacaoInEditMode: boolean;

  private readonly _contratosService: IEntityService<IJsonContratoCab>;
  private readonly _serviceMeiosPagamento: IEntityService<IJsonMeioPagamento>;
  private _autorizacaoFile: File;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _debDiretosSEPAModuleService: DebDiretosSEPAModuleService,
    private readonly _definicaoEmailService: DefinicaoEmailService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this.nAutorizacaoInEditMode = false;
    this.contratosTemplate = '{{contratoAsSTR}} - {{contratoDescricao}}';
    this.contratosOutput = 'contratoAsSTR';
    this.contratosSource = [];
    this.contratosModel = [];
    this._contratosService = this._entityServiceBuilder.build(ENTITY_NAME_AVENCAS);
    this._serviceMeiosPagamento = this._entityServiceBuilder.build<IJsonMeioPagamento>('meiosPagamento');
    this.debDiretosSEPAClienteTipoNAutorizacao = EDebDiretosSEPAClienteTipoNAutorizacao;
    this.editMode = false;
    this._autorizacaoFile = undefined;
    this.titularClifo = EDebDiretosTitularClifo.NOMECLIFO;
    this.ibanBancoCredorSource = [];
    this.model = {
      codMeioPagamento: undefined,
      contratosAsSTR: '',
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      dataFim: moment(new Date(2050, 12, 31)),
      dataInicio: moment(),
      emailDD: '',
      estado: EDebDiretosSEPAEstadoItem.New,
      estadoSTR: '',
      firstEnviado: undefined,
      nAutorizacao: '',
      temAutorizacaoDebitosDiretos: undefined,
      tipoAsSTR: '',
      titularConta: '',
      ibanDD: '',
      tipoNAutorizacao: EDebDiretosSEPAClienteTipoNAutorizacao.Manual,
      contratos: []
    };
    this.contratosFilter = '';
    this.documentoPorLiquidarAteDataCount = 0;
    this.documentoPorLiquidarAteDataTooltipText = '';
  }

  public async ngOnInit(): Promise<void> {
    await this._debDiretosSEPAModuleService.getConfiguracaoGeral().then((response: HttpResponse<IJsonDebDiretosSEPAConfiguracao>) => {
      if (isDefinedNotNull(response.body)) {
        response.body.listaContasCredoras.forEach((item: IJsonDebDiretosSEPAMeiosPagamento) => {
          this.ibanBancoCredorSource.push({ibanDD: item.iBAN, ibanMasked: this._geraMascaraIban(item.iBAN), codMeioPagamento: item.codMeioPagamento});
        });
        if (this.editMode) {
          this.ibanBancoCredorSource = copy(this.ibanBancoCredorSource);
        }
      }
    });

    if (this.editMode) {
      this.contratosModel = this.model.contratos;
      await this._reloadContratosNConta(this.model.nConta);
      this._updateUploadUrl(this.model.nConta);
      await this._checkDocsPorLiquidar();
    }
  }

  public async nContaChange(item: IJsonClifo): Promise<void> {
    this.model.ibanDD = '';
    this.model.emailDD = '';
    this.nContaChangePromise = this._evaluateNContaChange(item);
    return this.nContaChangePromise;
  }

  public async dataInicioChange(value: TDate): Promise<void> {
    this.model.dataInicio = value;
    await this._checkDocsPorLiquidar();
  }

  public consultarAutorizacao(): void {
    if (isEmpty(this.model.nConta)) {
      this._plAlertService.error('debDiretosSEPA.strings.temSelecionarConta');
      return;
    }
    this._debDiretosSEPAModuleService.getDocumentoAutorizacao(this.model.nConta).then((response: HttpResponse<Blob>) => {
      downloadStream(response);
    });
  }

  public apagarAutorizacao(): Promise<void> {
    if (isEmpty(this.model.nConta)) {
      this._plAlertService.error('debDiretosSEPA.strings.temSelecionarConta');
      return Promise.resolve(undefined);
    }
    return this._debDiretosSEPAModuleService.deleteDocumentoAutorizacao(this.model.nConta).then(() => {
      this.model.temAutorizacaoDebitosDiretos = false;
    });
  }

  public gerarNAutorizacao(): Promise<void> {
    if (isEmpty(this.model.nConta)) {
      this._plAlertService.error('debDiretosSEPA.strings.temSelecionarConta');
      return Promise.resolve();
    }
    return this._debDiretosSEPAModuleService.gerarNumAutorizacao(this.model.nConta).then((response) => {
      this.model.nAutorizacao = response.body;
      this.model.tipoNAutorizacao = EDebDiretosSEPAClienteTipoNAutorizacao.Automatico;
    });
  }

  public addAutorizacaoFile(event: Event): void {
    const file = (<HTMLInputElement>event.target).files[0];
    this._autorizacaoFile = undefined;
    if (file.type.toLowerCase() !== 'application/pdf') {
      this._plAlertService.error('debDiretosSEPA.messages.soPodeAnexarPdf');
      return;
    }
    this._autorizacaoFile = file;
  }

  public cancelAutorizacaoFile(): void {
    this._autorizacaoFile = undefined;
  }

  public isAutorizacaoFilePendingUpload(): boolean {
    return isDefinedNotNull(this._autorizacaoFile);
  }

  public setIBANDD(iban: string): void {
    const item = this.ibanBancoCredorSource.find((i) => iban === i.ibanDD);
    if (isDefinedNotNull(item)) {
      this.model.ibanDD = item.ibanDD;
      this.model.codMeioPagamento = item.codMeioPagamento;
    } else {
      this.model.ibanDD = '';
      this.model.codMeioPagamento = undefined;
    }
  }

  public async closeModal(): Promise<void> {
    if (!this.model.nConta?.length) {
      this._plAlertService.error('debDiretosSEPA.strings.nContaObrigatorio');
      return;
    }

    if (!this.model.emailDD?.length) {
      this._plAlertService.error('debDiretosSEPA.strings.emailDebitosDiretosObrigatorio');
      return;
    }

    if (!this.model.iban?.length) {
      this._plAlertService.error('debDiretosSEPA.strings.ibanClienteObrigatorio');
      return;
    }

    if (this._isForeignIban(this.model.iban) && !this.model.swift?.length) {
      this._plAlertService.error('debDiretosSEPA.strings.swiftClienteObrigatorio');
      return;
    }

    if (isUndefinedOrNull(this.model.codMeioPagamento)) {
      this._plAlertService.error('debDiretosSEPA.messages.contaCredoraObrigatorio');
      return;
    }

    if (isEmpty(this.model.nAutorizacao)) {
      this._plAlertService.error('debDiretosSEPA.messages.nAutorizacaoObrigatorio');
      return;
    }

    if (moment(this.model.dataInicio).isAfter(this.model.dataFim)) {
      this._plAlertService.error('debDiretosSEPA.strings.dataInicioNaoPodeSerSuperiorDataFim');
      return;
    }

    let temDocumentoAutorizacao = false;
    if (isDefinedNotNull(this._autorizacaoFile)) {
      await this._debDiretosSEPAModuleService.uploadDocumentAutorizacao(this._autorizacaoFile, this.model.nConta);
      temDocumentoAutorizacao = true;
    }

    if (!temDocumentoAutorizacao) {
      temDocumentoAutorizacao = await this._nContaTemDocumentoAutorizacao(this.model.nConta);
    }

    if (!temDocumentoAutorizacao) {
      await this._cgModalService.showOkCancel('global.text.confirmation', 'debDiretosSEPA.strings.naoAnexouAutorizacaoPertendeContinuar', {
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        btnOkText: 'global.btn.yes',
        btnCancelText: 'global.btn.no'
      });
    }
    this.model.contratos = [...this.contratosModel];

    if (this.editMode) {
      this.model.estado = EDebDiretosSEPAEstadoItem.Changed;
    } else {
      this.listaClientes.push(this.model);
    }
    await this._save();

    this.close();
  }

  public showClientPrintModal(): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(DebDiretosSEPAImpressaoClienteModalComponent, {size: 'xl', backdrop: 'static'});
    const componentInstance: DebDiretosSEPAImpressaoClienteModalComponent = modalInstance.componentInstance;
    componentInstance.nConta = this.model.nConta;
    componentInstance.nomeConta = this.model.nome;
    componentInstance.email = this.model.emailDD;
    return modalInstance.result;
  }

  public isEnabledObterAutorizacao(): boolean {
    return this.model.nConta?.length > 0 && this.model.emailDD?.length > 0;
  }

  public onIbanChanged(value: string): void {
    this.model.iban = value;
    this.model.swift = undefined;
    if (isDefinedNotNull(this.model.iban)) {
      this._serviceMeiosPagamento.get<string>({url: `trfsepa/iban/${this.model.iban}/swift`}).then((response) => {
        this.model.swift = response.body;
      });
    }
  }

  private _geraMascaraIban(nContaBanco: string): string {
    return nContaBanco
      .replaceAll(' ', '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
  }

  private async _nContaTemDocumentoAutorizacao(nConta: string): Promise<boolean> {
    const response: HttpResponse<boolean> = await this._debDiretosSEPAModuleService.temDocumentoAutorizacao(nConta);
    return response.body;
  }

  private async _evaluateNContaChange(item: IJsonClifo): Promise<void> {
    if (isDefinedNotNull(item)) {
      let email = '';
      const response: HttpResponse<IApiQueryResponse<IJsonClifoExtraValue>> = await this._definicaoEmailService.query(`nConta=${item.nConta}`);
      if (response.body.list.length) {
        let extraItem: IJsonClifoExtraValue = response.body.list.find((eItem: IJsonClifoExtraValue) => eItem.codigo === EClifoExtraValueTipo.EmailDebitosDiretos);
        if (isDefinedNotNull(extraItem)) {
          email = extraItem.valor;
        } else {
          extraItem = response.body.list.find((eItem: IJsonClifoExtraValue) => eItem.codigo === EClifoExtraValueTipo.EmailFatura);
          if (isDefinedNotNull(extraItem)) {
            email = extraItem.valor;
          }
        }
      }
      if (!email.length) {
        email = item.email;
      }
      this.model.emailDD = email;
      this.model.iban = this._geraMascaraIban(item.iban);
      if (!this.editMode && this.ibanBancoCredorSource.length) {
        this.model.ibanDD = this.ibanBancoCredorSource[0].ibanDD;
        this.model.codMeioPagamento = this.ibanBancoCredorSource[0].codMeioPagamento;
        this.model.swift = item.swift;
      }
      this._updateUploadUrl(item.nConta);
      await this.gerarNAutorizacao();
      await this._reloadContratosNConta(item.nConta);
    }
  }

  private _updateUploadUrl(nConta: string): void {
    this.uploadUrl = this._debDiretosSEPAModuleService.uploadUrlDocumentAutorizacao(nConta);
  }

  private _reloadContratosNConta(nConta: string): Promise<void> {
    return this._contratosService.query({filtro: `nClifo=${nConta}`}).then((response: HttpResponse<IApiQueryResponse<IJsonContratoCab>>) => {
      const contratosSource = [];
      response.body.list.forEach((contratoItem: IJsonContratoCab) => {
        contratosSource.push({
          clifoDDContratosID: '',
          contratoAsSTR: contratoItem.nContratoAsString,
          refCtrCAB: contratoItem.refCtrCab,
          contratoDescricao: contratoItem.descricao
        });
      });
      this.contratosSource = contratosSource;
    });
  }

  private async _save(successMessage: string = 'debDiretosSEPA.strings.configClientesSaved'): Promise<void> {
    const changedList: Array<IJsonDebDiretosSEPACliente> = this.listaClientes.filter((item: IJsonDebDiretosSEPACliente) => item.estado !== EDebDiretosSEPAEstadoItem.None);
    await this._debDiretosSEPAModuleService.saveConfiguracaoClientes(changedList);
    this._plAlertService.success(successMessage);
  }

  private _checkDocsPorLiquidar(): Promise<void> {
    this.documentoPorLiquidarAteDataTooltipText = '';
    if (isUndefinedOrNull(this.model.nConta)) {
      return Promise.resolve();
    }
    return this._debDiretosSEPAModuleService.totalDocumentoPorLiquidarAteData(this.model.nConta, this.model.dataInicio).then((response: HttpResponse<number>) => {
      this.documentoPorLiquidarAteDataCount = response.body;
      if (this.documentoPorLiquidarAteDataCount > 0) {
        this.documentoPorLiquidarAteDataTooltipText = this._plTranslateService.translate('debDiretosSEPA.messages.temDocumentosPorLiquidarAteDataIni', {count: this.documentoPorLiquidarAteDataCount});
      }
    });
  }

  private _isForeignIban(iban: string): boolean {
    return !iban.replace(' ', '').startsWith('PT50');
  }
}
