<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'planoscontasalternativos.modals.conta.title'"></h4>
</div>

<div class="modal-body">
  <pl-group>
    <pl-group>
      <label [translate]="'planoscontasalternativos.fields.ncontaEdit'"></label>
      <edit>
        <pl-edit type="text" [(model)]="linha.nconta"></pl-edit>
      </edit>
    </pl-group>
    <pl-group>
      <label [translate]="'planoscontasalternativos.fields.nomeEdit'"></label>
      <edit>
        <pl-edit type="text" [(model)]="linha.nome"></pl-edit>
      </edit>
    </pl-group>
  </pl-group>

  <pl-group>
    <label [translate]="'planoscontasalternativos.fields.tipoConta.title'"></label>
    <edit>
      <pl-edit type="radiogroup" attrName="tipo" [(model)]="linha.tipo" [properties]="radioGroupTipoConta"></pl-edit>
    </edit>
  </pl-group>

  <fieldset>
    <legend [translate]="'planoscontasalternativos.fields.contasradicais'"></legend>
    <div>
      <pl-group>
        <pl-group>
          <label [translate]="'planoscontasalternativos.fields.nradical'"></label>
          <edit>
            <entity-autocomplete
              entity="pocs"
              attrName="nContaPOC"
              [model]="radical"
              (selectedKeyChange)="nRadicalChanged($event)"
              (evtSelectedDescriptionChanged)="radical.nome = $event"
              [properties]="{allowInvalid: true}"
              [fieldsMap]="{nConta: 'nRadicalConta'}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'planoscontasalternativos.fields.incluiexclui'"></label>
          <edit>
            <pl-edit-select [(model)]="radical.incExc" [source]="incExcSource" [properties]="{disallowClear: true}"></pl-edit-select>
          </edit>
          <actions>
            <button type="button" class="btn btn-sm btn-primary" (click)="addRadical()"><i class="fa fa-fw fa-plus"></i>&nbsp;<span [translate]="'global.btn.add'"></span></button>
          </actions>
        </pl-group>
      </pl-group>

      <dx-data-grid [cgDxDataGrid]="dataGridDefinition" [dataSource]="contasAlternativas">
        <div *dxTemplate="let item of 'templateToolbarContasAlt'">
          <span [translate]="'planoscontasalternativos.fields.contasalt'"></span>
        </div>
      </dx-data-grid>
    </div>
  </fieldset>
</div>

<div class="modal-footer" plNavigation>
  <pl-button klass="btn-primary btn-sm action-submit" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.confirm'"></span></pl-button>

  <pl-button klass="btn-light btn-sm action-dismiss" [disabled]="closeDisabled" (evtClicked)="dismiss()">
    <i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span>
  </pl-button>
</div>
