<div class="movimentacoes entity-detail-form">
  <config-options [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="configOptionsToolbarInstanceId"></config-options>

  <pl-tabs [activeId]="activeTabId" (activeIdChange)="activeTabId = $event; evaluateTabChosen()">
    <pl-tab caption="movimentacoes.tabs.entradas" [id]="movimentacoesTabID.Entradas">
      <div *plTabContent>
        <entity-list
          entityName="entradasdiversas"
          [beforeRequest]="fnBeforeRequest"
          (evtDataGridCellClick)="onEntradasESaidasDiversasDocsCellClick($event)"
          (evtDataGridRowPrepared)="onDataGridRowPrepared($event)"
          (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
          #entityListEntradasDiversas>
          <div *entityListItemActions="let item">
            <ng-container *ngTemplateOutlet="templateListItemActions; context: {item: item}"></ng-container>
          </div>
          <div *entityListItemDetail="let item">
            <ng-container *ngTemplateOutlet="templateListItemDetail; context: {clifos: 'fornecedores', item: item}"></ng-container>
          </div>
        </entity-list>

        <br />

        <div class="bancos-extrato-module-legend">
          <cg-table-legend [legend]="tableLegend"></cg-table-legend>
        </div>
      </div>
    </pl-tab>
    <pl-tab caption="movimentacoes.tabs.saidas" [id]="movimentacoesTabID.Saidas">
      <div *plTabContent>
        <entity-list
          entityName="saidasdiversas"
          [beforeRequest]="fnBeforeRequest"
          (evtDataGridCellClick)="onEntradasESaidasDiversasDocsCellClick($event)"
          (evtDataGridRowPrepared)="onDataGridRowPrepared($event)"
          (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
          #entityListSaidasDiversas>
          <div *entityListItemActions="let item">
            <ng-container *ngTemplateOutlet="templateListItemActions; context: {item: item}"></ng-container>
          </div>
          <div *entityListItemDetail="let item">
            <ng-container *ngTemplateOutlet="templateListItemDetail; context: {clifos: 'clientes', item: item}"></ng-container>
          </div>
        </entity-list>

        <br />

        <div class="bancos-extrato-module-legend">
          <cg-table-legend [legend]="tableLegend"></cg-table-legend>
        </div>
      </div>
    </pl-tab>
  </pl-tabs>
</div>

<ng-template #templateListItemDetail let-clifos="clifos" let-item="item">
  <pl-split-view [initialSizeLeft]="60" [initialSizeRight]="40">
    <div *plSplitViewLeftSide>
      <documento-faturacao
        [doc]="item.data._doc"
        [entityNameClifos]="clifos"
        [grupoDoc]="grupo"
        [actionPdf]="fnGetPdf"
        [actionEditarDocumento]="fnEditarDocumento"
        [actionAnexarGDocComercial]="fnAnexarGDocComercial"
        [actionComunicarDocWebServiceAT]="fnComunicarDocWebServiceAT"
        [actionComunicarDocCirculacaoAT]="fnComunicarDocCirculacaoAT"
        [actionInserirCodTranspAT]="fnInserirCodigoTransporteAT"
        [actionAnularDoc]="fnAnularDocumento"
        [actionNotaCreditoDoc]="fnNotaCreditoDocumento"
        [actionTransformarLinhasDoc]="fnTransformarLinhasDocumento"
        [actionDesmarcarDocTransformado]="fnDesmarcarDocTransformado"
        [actionGetDoc]="fnGetDoc"
        [actionDuplicarParaNovoDoc]="fnDuplicarDocumentoEditavel"
        [actionMeiosPagamentoComercial]="fnMeiosPagamentoComercial"
        [actionEncerrarDocumento]="fnEncerrarDocumento"
        [actionReAbreDocumento]="fnReAbreDocumentoActions"
        [docCabFieldsVisibilityOptions]="movimentacoesVisibilityOptions"
        (evtRefresh)="refreshList()">
      </documento-faturacao>
    </div>

    <div *plSplitViewRightSide>
      <div *ngIf="!!item.data._doc.cab.ocrCabDocId">
        <comercialdigital-docviewer [doc]="item.data._digitalGDocViewerDoc"></comercialdigital-docviewer>
        <!--                    [callback]="callbackDocViewer"-->
        <!--                    (evtChangedAttachment)="changedComercialDigitalAttachment($event)">-->
      </div>
      <div *ngIf="!item.data._doc.cab.ocrCabDocId">
        <cg-pdf-viewer [filename]="item.data._fileNamePDF" [pdfSrc]="item.data._urlPDF"></cg-pdf-viewer>
      </div>
    </div>
  </pl-split-view>
</ng-template>

<ng-template #templateListItemActions let-item="item">
  <div ngbDropdown container="body" class="col-main-table-actions">
    <button type="button" class="btn btn-link col-main-table-btn-actions" ngbDropdownToggle><i class="fa fa-cog"></i></button>
    <div ngbDropdownMenu>
      <button type="button" ngbDropdownItem [click]="fnGetDocPdf(item.data)" plPromise><i class="fa fa-file-pdf-o fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.actions.pdf'"></span></button>
      <button *ngIf="showGDocs" type="button" ngbDropdownItem [click]="fnAnexarDocumentoActions(item.data)" plPromise>
        <i class="fa fa-fw fa-paperclip"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.anexos'"></span>
      </button>

      <button *ngIf="!item.data.anulado && item.data.nDocumento > 0" type="button" ngbDropdownItem [click]="fnTransformarLinhasDocumentoActions(item.data)" plPromise>
        <i class="fa fa-fw fa-stack-overflow"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.transformarLinhasDoc'"></span>
      </button>

      <button *ngIf="item.data.nDocumento === 0 || !item.data.terminado" type="button" ngbDropdownItem [click]="fnEditarDocumentoActions(item.data)" plPromise>
        <i class="fa fa-fw fa-pencil-square-o"></i>&nbsp;<span [translate]="'docscomerciais.actions.editarDocumento'"></span>
      </button>

      <button *ngIf="hasComercial && !item.data.anulado && item.data.terminado" type="button" ngbDropdownItem [click]="fnReAbreDocumento(item.data)" plPromise>
        <i class="fa fa-fw fa-pencil-square-o"></i>&nbsp;<span [translate]="'docscomerciais.actions.editarDocumento'"></span>
      </button>

      <button
        *ngIf="item.data.faturaWebServSujAComunicacao && item.data.atEstado !== 2 && item.data.atEstado !== 4"
        type="button"
        ngbDropdownItem
        [click]="fnComunicarDocWebServiceATCab(item.data)"
        plPromise>
        <i class="fa fa-send fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.actions.comunicarDocAt'"></span>
      </button>

      <button *ngIf="!item.data.anulado" type="button" ngbDropdownItem [click]="fnEmitirRecibo(item.data)" plPromise>
        <i class="fa fa-send fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.actions.emitirRecibo'"></span>
      </button>

      <button *ngIf="item.data.podeFazerDevolucao" type="button" ngbDropdownItem (click)="fnDevolucoes(item.data)" plPromise>
        <i class="fa fa-fw fa-share-square-o"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.notaCredito'"></span>
      </button>

      <button type="button" ngbDropdownItem [click]="fnDuplicarDocumentoEditavelActions(item.data)" plPromise>
        <i class="fa fa-copy fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.actions.duplicarDoc'"></span>
      </button>

      <button *ngIf="!item.data.terminado" type="button" ngbDropdownItem [click]="fnEncerrarDocumentoActions(item.data)" plPromise>
        <i class="fa fa-fw fa-file-archive-o"></i>&nbsp;<span [translate]="'docscomerciais.actions.encerrarDocumento'"></span>
      </button>

      <button *ngIf="this.hasContabilidade && item.data.integraContabilidade && item.data.terminado" type="button" ngbDropdownItem [click]="fnMostrarDocContabActions(item.data)" plPromise>
        <i class="fa fa-eye fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.btn.showDocsOnContab'"></span>
      </button>

      <button *ngIf="!item.data.anulado" type="button" ngbDropdownItem [click]="fnRastreabilidadeDocumentoActions(item.data)" plPromise>
        <i class="fa fa-binoculars fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.actions.rastreabilidade'"></span>
      </button>

      <button
        *ngIf="!item.data.anulado && (!item.data.porPagar || item.data.porPagar === item.data.totalDocumento)"
        type="button"
        ngbDropdownItem
        [click]="fnAnularDocumentoActions(item.data)"
        plPromise>
        <i class="fa fa-fw fa-scissors"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.anular'"></span>
      </button>
    </div>
  </div>
</ng-template>
