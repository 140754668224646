<pl-form [properties]="{disallowClear: true, select: {labelProp: 'label'}}">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()" [disabled]="closeDisabled"></button>
    <h5 class="modal-title" [translate]="'dgempsfull.modalSelecaoTabelaIRS.modalTitle'"></h5>
  </div>

  <div class="modal-body" [plPromise]="promise">
    <div class="d-flex">
      <fieldset class="w-25 me-2">
        <legend [translate]="'dgempsfull.modalSelecaoTabelaIRS.groupSelection.title'"></legend>
        <div>
          <pl-group>
            <label [translate]="'dgempsfull.modalSelecaoTabelaIRS.fields.dataEmVigor'"></label>
            <edit>
              <pl-edit-select attrName="dataEmVigor" [model]="currentDataEmVigor" [source]="dataEmVigorDataSource" (modelChange)="dataEmVigorChanged($event)"> </pl-edit-select>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'dgempsfull.modalSelecaoTabelaIRS.fields.residencia'"></label>
            <edit>
              <pl-edit-select attrName="residencia" [source]="residenciaDataSource" [model]="selecaoTabelaIRS.residencia" (modelChange)="residenciaChange($event)"> </pl-edit-select>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'dgempsfull.modalSelecaoTabelaIRS.fields.situacao'"></label>
            <edit>
              <pl-edit-select attrName="situacao" [model]="selecaoTabelaIRS.situacao" [source]="situacaoDataSource" (modelChange)="situacaoChange($event)"> </pl-edit-select>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'dgempsfull.modalSelecaoTabelaIRS.fields.tipoRendimento'"></label>
            <edit>
              <pl-edit-select attrName="tipoRendimento" [model]="selecaoTabelaIRS.tipoRendimento" [source]="tipoRendimentoDataSource" (modelChange)="tipoRendimentoChange($event)"> </pl-edit-select>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'dgempsfull.modalSelecaoTabelaIRS.fields.declaranteDeficiente'"></label>
            <edit>
              <pl-edit-select
                attrName="declaranteDeficiente"
                [model]="selecaoTabelaIRS.declaranteDeficiente"
                [source]="declaranteDeficienteDataSource"
                (modelChange)="declaranteDeficienteChange($event)">
              </pl-edit-select>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'dgempsfull.modalSelecaoTabelaIRS.fields.dependentes'"></label>
            <edit>
              <pl-edit-select attrName="dependentes" [model]="selecaoTabelaIRS.dependentes" [source]="dependentesDataSource" (modelChange)="dependentesChange($event)"> </pl-edit-select>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'dgempsfull.modalSelecaoTabelaIRS.fields.dependentesDeficiente'"></label>
            <edit>
              <pl-edit-select
                attrName="dependentesDeficiente"
                [model]="selecaoTabelaIRS.dependentesDeficiente"
                [source]="dependentesDeficienteDataSource"
                (modelChange)="dependentesDeficienteChange($event)">
              </pl-edit-select>
            </edit>
          </pl-group>
        </div>
      </fieldset>

      <fieldset class="w-75 me-2">
        <legend [translate]="'dgempsfull.modalSelecaoTabelaIRS.groupSelected.title'"></legend>
        <div class="text-center">
          <pl-group>
            <label>{{ outputDesignacaoIRS }}</label>
          </pl-group>
          <pl-group>
            <label *ngIf="outputParcelaAbaterDepende">{{ outputParcelaAbaterDepende }}</label>
          </pl-group>
        </div>
        <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="gridIRSDTEmVigor" [dataSource]="dataGridDefinition.dataSource"> </dx-data-grid>
      </fieldset>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn-success" [onClick]="close" [disabled]="closeDisabled"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.ok'"></span></pl-button>
    <pl-button klass="btn-light action-dismiss" (evtClicked)="dismiss()" [disabled]="closeDisabled"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</pl-form>
