import {Component, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EContabilidadePredefinidosOperation, IPreDefinidoDetailStateParams} from '../../preDefinidosContab.entity.interface';
import {IJsonPreDefinidoContab} from '../../jsonPreDefinidosContab.entity.interface';
import {IModuleEntityListOnDataGridCellClick} from '../../../../../../components/module/entitylist/module.entitylist.interface';
import {ModuloEntityListComponent} from '../../../../../../components/module/entitylist/module.entitylist.component';

@Component({
  selector: 'contratos-list',
  templateUrl: './contabilidade.predefinidos.entity.list.component.html'
})
export class ContabilidadePredefinidosEntityListComponent extends ModuloEntityListComponent<IJsonPreDefinidoContab> implements OnInit {
  public ngOnInit(): void {
    super.ngOnInit();
    this.btnNovo.menu = [
      {
        type: 'button',
        iconLeft: '<i class="fa fa-fw fa-plus"></i>',
        caption: 'predefinidoscontabilidade.operations.newBasedOnDoc',
        click: () => this._newDoc(EContabilidadePredefinidosOperation.NEW_BASE_DOC)
      },
      {
        type: 'button',
        iconLeft: '<i class="fa fa-fw fa-plus"></i>',
        caption: 'predefinidoscontabilidade.operations.newBasedOnExisting',
        click: () => this._newDoc(EContabilidadePredefinidosOperation.NEW_BASE_PREDEFINIDO)
      }
    ];
  }

  public async onDetail(event: IModuleEntityListOnDataGridCellClick<IJsonPreDefinidoContab>): Promise<void> {
    const {data} = event;
    const response: HttpResponse<IJsonPreDefinidoContab> = await this.service.get({id: data.preDefinidosID});
    data.preDefinidosID = response.body.preDefinidosID;
    data.cabecalho = response.body.cabecalho;
    data.cgBanking = response.body.cgBanking;
    data.descricao = response.body.descricao;
    data.empresasSelecionadas = response.body.empresasSelecionadas;
    data.isGeneric = response.body.isGeneric;
    data.linhas = response.body.linhas;
    data.ordemColunas = response.body.ordemColunas;
    data.revision = response.body.revision;
    return super.onDetail(event);
  }

  public openDetailStateInNewTabOrWindow(id: string | number, params?: object): void {
    const preDefinidoStateParams: IPreDefinidoDetailStateParams = {operation: EContabilidadePredefinidosOperation.EDIT};
    super.openDetailStateInNewTabOrWindow(id, {...params, ...preDefinidoStateParams});
  }

  private async _newDoc(operation: EContabilidadePredefinidosOperation): Promise<void> {
    const params: IPreDefinidoDetailStateParams = {operation: operation};
    await this.actionNew({params: params});
  }
}
