/**
 * CentralGest Amalia
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type FeedbackState = 'NEW' | 'FALL_BACK' | 'OPEN' | 'CLOSED';

export const FeedbackState = {
    New: 'NEW' as FeedbackState,
    FallBack: 'FALL_BACK' as FeedbackState,
    Open: 'OPEN' as FeedbackState,
    Closed: 'CLOSED' as FeedbackState
};

