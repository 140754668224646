import {Subscription} from 'rxjs';
import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {isDefined, isFunction, Logger, PlAlertService} from 'pl-comps-angular';
import {AuthService} from '../../../services/auth/auth.service';
import {CalculoDiferimentosInfoModalComponent} from '../../../modules/diferimentos/modals/info/calculoDiferimentos.info.modal.component';
import {CGModalService} from '../../cg/modal/cgmodal.service';
import {CGStateService} from '../../state/cg.state.service';
import {ConfigService} from '../../../services/config/config.service';
import {DiferimentosService} from '../../../modules/diferimentos/diferimentos.module.service';
import {DocContabilidadeService} from '../../../modules/portalcontabilidade/docscontabilidade/components/doccontabilidade/docContabilidade.service';
import {DocsContabilidadePrintModalComponent} from '../../../modules/portalcontabilidade/docscontabilidade/components/printmodal/docsContabilidade.print.modal.component';
import {DocsContabilidadeService} from '../../../modules/portalcontabilidade/docscontabilidade/service/docsContabilidade.service';
import {
  EContabilidadePredefinidosOperation,
  ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS,
  IPreDefinidoDetailStateParams
} from '../../../modules/portalcontabilidade/manutencao/predefinidos/preDefinidosContab.entity.interface';
import {EEntityStateDetailType} from '../../../../common/utils/entity.state.utils';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocContabilidade, IDocContabilidadeLinha} from '../../../modules/portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityMaintenanceService} from '../../entity/maintenance/entity/entity.maintenance.service';
import {GDocContabilidadeModalComponent} from '../../../modules/portalcontabilidade/docscontabilidade/components/gdoccontabilidade/gdoc.contabilidade.modal.component';
import {ICGConfigContabilidade, ICGConfigurations} from '../../../services/config/config.service.interface';
import {IDocumentoContabilidadeToHighlight, TDocumentoContabilidadeFnAction} from './documento.contabilidade.interface';
import {EEntityMaintenanceEditMode, IEntityMaintenanceInstance} from '../../entity/maintenance/entity/entity.maintenance.interface';
import {IJsonDocContabilidade} from '../../../modules/portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IJsonUserRole, TUserSession} from '../../../services/account/jsonUserApi.interface';
import {IModuleMaintenanceInstance} from '../../entity/maintenance/module/module.maintenance.interface';
import {MODULE_NAME_INFO_GLOBAL_ACRESCIMOS, MODULE_NAME_INFO_GLOBAL_DIFERIMENTOS} from '../../../modules/diferimentos/diferimentos.module.interface';
import {ModuleMaintenanceService} from '../../entity/maintenance/module/module.maintenance.service';
import {ROLE} from '../../../services/role.const';
import {ETipoOperacaoDiferimentos, IJsonInfoDiferimentos} from '../../../modules/diferimentos/jsonCalculoDiferimentos.interface';
import {EPortal} from '../../../../common/enums/portals.enums';
import {EMPTY_GUID} from '../../../../config/constants';

const ACTIONS_ALLOWED_ROLES: Array<ROLE> = [ROLE.API, ROLE.CONTABILIDADE, ROLE.ATIVOS];

@Component({
  selector: 'documento-contabilidade',
  templateUrl: './documento.contabilidade.component.html'
})
export class DocumentoContabilidadeComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public doc: IDocContabilidade;
  @Input() public onActionEdited: TDocumentoContabilidadeFnAction;
  @Input() public onActionDeleted: TDocumentoContabilidadeFnAction;
  @Input() public onActionCreatedPredefinidoByDoc: TDocumentoContabilidadeFnAction;
  @Input() public onActionAnulouDiferimentos: TDocumentoContabilidadeFnAction;
  @Input() public showToolbar: boolean;
  @Input() public showErroInformativo: boolean;
  @Input() public readonly: boolean;
  @Input() public highlightNLanc: string;
  @Input() public highlightClass: string;
  @Input() public maintenanceMode: boolean;
  @Input() public showBtnContabDigital: boolean;
  @Input() public activeBtnContabDigital: boolean;
  @Output() public readonly docChange: EventEmitter<IDocContabilidade>;
  @Output() public readonly evtActionEdited: EventEmitter<IDocContabilidade>;
  @Output() public readonly evtActionDeleted: EventEmitter<IDocContabilidade>;
  @Output() public readonly evtActionCreatedPredefinidoByDoc: EventEmitter<IDocContabilidade>;
  @Output() public readonly evtActionAnulouDiferimentos: EventEmitter<IDocContabilidade>;
  @Output() public readonly evtLoading: EventEmitter<boolean>;
  @Output() public readonly evtBtnContabDigital: EventEmitter<void>;

  public readonly config: ICGConfigContabilidade;
  public readonly decimais: number;
  public hasRoleContabilidade: boolean;
  public itemInfo: IDocContabilidadeLinha;
  public linesToHighlight: IDocumentoContabilidadeToHighlight;
  public highLightSelected: IDocumentoContabilidadeToHighlight;
  public showGDocs: boolean;
  public hasContabilidadeDigital: boolean;
  public hintMarcadoComoConsistente: string;
  public tipoOpDiferimentos: typeof ETipoOperacaoDiferimentos;
  public showBtnPreDefinidos: boolean;

  private readonly _docsContabilidadeMaintenanceInstance: IEntityMaintenanceInstance<IJsonDocContabilidade>;
  private readonly _preDefinidosMaintenanceInstance: IEntityMaintenanceInstance;
  private readonly _diferimentosMaintenanceInstance: IModuleMaintenanceInstance;
  private readonly _acrescimosMaintenanceInstance: IModuleMaintenanceInstance;
  private readonly _subscriptionConfigurations: Subscription;
  private _pendingPromises: number;
  private _loading: boolean;
  private readonly _selectedClass: string;

  constructor(
    private readonly _logger: Logger,
    private readonly _plAlertService: PlAlertService,
    private readonly _authService: AuthService,
    private readonly _cgStateService: CGStateService,
    private readonly _cgModalService: CGModalService,
    private readonly _configService: ConfigService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _moduleMaintenanceInstance: ModuleMaintenanceService,
    private readonly _docsContabilidadeService: DocsContabilidadeService,
    private readonly _docContabilidadeService: DocContabilidadeService,
    private readonly _calculoDiferimentosService: DiferimentosService
  ) {
    this.showToolbar = true;
    this.highlightNLanc = undefined;
    this.highlightClass = 'highlight-line';
    this._selectedClass = 'selected-line';
    this.docChange = new EventEmitter<IDocContabilidade>();
    this.evtActionEdited = new EventEmitter<IDocContabilidade>();
    this.evtActionDeleted = new EventEmitter<IDocContabilidade>();
    this.evtActionCreatedPredefinidoByDoc = new EventEmitter<IDocContabilidade>();
    this.evtActionAnulouDiferimentos = new EventEmitter<IDocContabilidade>();
    this.evtLoading = new EventEmitter<boolean>();
    this.evtBtnContabDigital = new EventEmitter<void>();
    this.config = this._configService.configurations.contabilidade;
    this.decimais = this.config.decimais.valor;
    this.hasRoleContabilidade = false;
    this.linesToHighlight = {};
    this.highLightSelected = {};
    this._docsContabilidadeMaintenanceInstance = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._preDefinidosMaintenanceInstance = this._entityMaintenanceService.build(ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS);
    this._diferimentosMaintenanceInstance = this._moduleMaintenanceInstance.build(MODULE_NAME_INFO_GLOBAL_DIFERIMENTOS);
    this._acrescimosMaintenanceInstance = this._moduleMaintenanceInstance.build(MODULE_NAME_INFO_GLOBAL_ACRESCIMOS);
    this._pendingPromises = 0;
    this._loading = false;
    this.tipoOpDiferimentos = ETipoOperacaoDiferimentos;
    this.showBtnPreDefinidos = true;
    this.showBtnContabDigital = false;
    this.activeBtnContabDigital = true;
    this._authService.identity().then((session: TUserSession) => {
      this.hasRoleContabilidade = isDefined(session.roles.find((userRole: IJsonUserRole) => ACTIONS_ALLOWED_ROLES.includes(userRole.role)));
    });
    this._subscriptionConfigurations = this._configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
      this.showGDocs = configurations.acessos.erpcloud.gdocs && !configurations.licenca.modoCGOn;
      this.hasContabilidadeDigital = configurations.empresa.temContabilidadeDigital;
    });

    this.pdf = this.pdf.bind(this);
    this.editarDoc = this.editarDoc.bind(this);
    this.apagarDoc = this.apagarDoc.bind(this);
    this.anularDiferimento = this.anularDiferimento.bind(this);
    this.novoPredefinidoByDocContabilidade = this.novoPredefinidoByDocContabilidade.bind(this);
    this.anexarGDocContabilidade = this.anexarGDocContabilidade.bind(this);
  }

  public ngOnInit(): void {
    this._docChanged();
    this.showToolbar = !this.doc.anulado;
    this.showBtnPreDefinidos = this._cgStateService.getCurrentStateName() === EPortal.CONTABILIDADE;
  }

  public ngOnChanges({doc}: SimpleChanges): void {
    if (doc && !doc.isFirstChange()) {
      this._docChanged(doc.currentValue);
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionConfigurations.unsubscribe();
  }

  public onClickLinha(linha: IDocContabilidadeLinha): void {
    this.highLightSelected = {};
    this.highLightSelected[linha.nLanc] = this._selectedClass;
    this.itemInfo = linha;
    if (linha._isImputacao && (!linha._extPocCabImput || linha._extPocCabImput === EMPTY_GUID) && !linha._promiseLinhaImputada) {
      linha._promiseLinhaImputada = linha._imputaDocPrincipal ? this._docsContabilidadeService.initLinhaImputada(linha) : this._docsContabilidadeService.getDocsPagamentoLinha(linha);
      linha._promiseLinhaImputada.catch(() => {
        linha._promiseLinhaImputada = undefined;
      });
    }
  }

  public openInfoGlobalDiferiAcresc(tipoOperacao: ETipoOperacaoDiferimentos.Diferimento | ETipoOperacaoDiferimentos.Acrescimo): Promise<void> {
    if (tipoOperacao === ETipoOperacaoDiferimentos.Diferimento) {
      return this._diferimentosMaintenanceInstance.maintenance();
    }

    return this._acrescimosMaintenanceInstance.maintenance();
  }

  public pdf(): Promise<void> {
    if (!this.doc) {
      this._plAlertService.error('docscontabilidade.erros.notselected');
      return Promise.reject(new Error('docscontabilidade.erros.notselected'));
    }
    const modalRef = this._cgModalService.showVanilla(DocsContabilidadePrintModalComponent);
    const componentInstance: DocsContabilidadePrintModalComponent = modalRef.componentInstance;
    componentInstance.doc = this.doc;
    return modalRef.result;
  }

  public async editarDoc(): Promise<void> {
    try {
      const id: string = this.doc.extPocCabID;
      if (!this.maintenanceMode) {
        await this._cgStateService.redirectToState({stateOrName: ENTITY_NAME_DOCS_CONTABILIDADE, stateType: EEntityStateDetailType.EDIT, params: {id: id}});
      } else {
        try {
          this.doc = await this._docsContabilidadeMaintenanceInstance.maintenanceEdit(id, {preventDocumentKeyup: false, mode: EEntityMaintenanceEditMode.EditOnly});
          this.docChange.emit(this.doc);
        } catch (reason: unknown) {
          this._logger.error(reason);
        }
      }
      if (isFunction(this.onActionEdited)) {
        await this.onActionEdited(this.doc);
      }
      this.evtActionEdited.emit(this.doc);
    } catch (reason: unknown) {
      this._logger.error(reason);
    }
  }

  public async apagarDoc(): Promise<void> {
    await this._docsContabilidadeService.deleteDocContabilidade(this.doc);
    if (isFunction(this.onActionDeleted)) {
      await this.onActionDeleted(this.doc);
    }
    this.evtActionDeleted.emit(this.doc);
  }

  public async anularDiferimento(): Promise<void> {
    await this._cgModalService.showOkCancel('global.text.confirmation', 'docscontabilidade.text.confirmAnulaDiferimento');
    const promise: Promise<void> = (async () => {
      const docContabilidade: IJsonDocContabilidade = await this._docsContabilidadeService.anularDiferimento(this.doc);
      if (isFunction(this.onActionAnulouDiferimentos)) {
        try {
          await this.onActionAnulouDiferimentos(this.doc);
        } catch (reason: unknown) {
          this._logger.error(reason);
        }
      }
      this._plAlertService.success('docscontabilidade.diferimentosAnuladosComSucesso');
      this.evtActionAnulouDiferimentos.emit(this.doc);
      this.docChange.emit(docContabilidade);
    })();
    this._addLoadingPromise(promise);
    return promise;
  }

  public async novoPredefinidoByDocContabilidade(): Promise<void> {
    const params: IPreDefinidoDetailStateParams = {
      operation: EContabilidadePredefinidosOperation.NEW_BASE_DOC,
      docContabilidade: this.doc.extPocCabID
    };
    if (!this.maintenanceMode) {
      await this._cgStateService.redirectToState({stateOrName: ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS, stateType: EEntityStateDetailType.NEW, params: params});
    } else {
      await this._preDefinidosMaintenanceInstance.maintenanceNew({params: params});
    }
    if (isFunction(this.onActionCreatedPredefinidoByDoc)) {
      await this.onActionCreatedPredefinidoByDoc(this.doc);
    }
    this.evtActionCreatedPredefinidoByDoc.emit(this.doc);
  }

  public anexarGDocContabilidade(): Promise<void> {
    if (!this.showGDocs) {
      return Promise.resolve();
    }
    if (!this.doc) {
      this._plAlertService.error('docscontabilidade.erros.notselected');
      return Promise.reject(new Error('docscontabilidade.erros.notselected'));
    }
    const modalInstance = this._cgModalService.showVanilla(GDocContabilidadeModalComponent);
    const componentInstance: GDocContabilidadeModalComponent = modalInstance.componentInstance;
    componentInstance.doc = this.doc;
    return modalInstance.result;
  }

  public btnContabDigitalClicked(): void {
    this.activeBtnContabDigital = !this.activeBtnContabDigital;
    this.evtBtnContabDigital.emit();
  }

  public readonly fnInfoDiferimento: (extPocCabID: string) => () => Promise<void> = (extPocCabID: string) => (): Promise<void> => this._infoDiferimentos(extPocCabID);

  private _docChanged(value: IJsonDocContabilidade = this.doc): void {
    this.doc = value || this._docContabilidadeService.emptyDoc();
    this._docContabilidadeService.calculaTotais(this.doc, false);
    this.linesToHighlight = {};
    for (const linha of this.doc.linhas) {
      if (linha.nLanc === this.highlightNLanc) {
        this.linesToHighlight[linha.nLanc] = this.highlightClass;
      }
    }
    this.hintMarcadoComoConsistente = this._docContabilidadeService.generateHintMarcadoComoConsistente(this.doc);
  }

  private async _infoDiferimentos(extPocCabID: string): Promise<void> {
    const response: HttpResponse<IJsonInfoDiferimentos> = await this._calculoDiferimentosService.getInfoDiferimentos(extPocCabID);
    const modalInstance = this._cgModalService.showVanilla(CalculoDiferimentosInfoModalComponent, {size: 'xxl'});
    const componentInstance: CalculoDiferimentosInfoModalComponent = modalInstance.componentInstance;
    componentInstance.infoDiferimentos = response.body;
  }

  private _addLoadingPromise(promise: Promise<unknown>): void {
    this._pendingPromises++;
    this._evaluateLoading();
    promise.finally(() => {
      this._pendingPromises--;
      this._evaluateLoading();
    });
  }

  private _evaluateLoading(): void {
    const loading: boolean = this._pendingPromises > 0;
    if (loading !== this._loading) {
      this._loading = loading;
      this.evtLoading.emit(this._loading);
    }
  }
}
