import {Subscription} from 'rxjs';
import {skip} from 'rxjs/operators';
import {Directive, EventEmitter, Injector, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {IPlEditComponentOptionsInput, isNumber} from 'pl-comps-angular';
import {ConfigService} from '../../../../../../services/config/config.service';
import {DocContabilidadeService} from '../docContabilidade.service';
import {EDocContabilidadeField, IDocContabilidadeEventFocus, IDocContabilidadeEventKeyboard, IDocContabilidadeEventValue} from '../docContabilidade.interface';
import {ICGConfigurations} from '../../../../../../services/config/config.service.interface';
import {IDocContabilidade, IDocContabilidadeLinha} from '../../../docsContabilidade.interface';
import {IJsonPreDefinidoContab, IJsonPreDefinidoContabLinha, TPredefinidoField} from '../../../../manutencao/predefinidos/jsonPreDefinidosContab.entity.interface';

@Directive()
export abstract class DocContabilidadeField<TValue, TEvent extends IDocContabilidadeEventValue<TValue> = IDocContabilidadeEventValue<TValue>> implements OnInit, OnChanges, OnDestroy {
  @Input() public model: TValue;
  @Input() public linha: IDocContabilidadeLinha;
  @Input() public docContabilidade: IDocContabilidade;
  @Input() public predefinido: IJsonPreDefinidoContab;
  @Input() public locked: boolean;
  @Output() public readonly evtModelChanged: EventEmitter<TEvent>;
  @Output() public readonly evtFocus: EventEmitter<IDocContabilidadeEventFocus>;
  @Output() public readonly evtBlur: EventEmitter<IDocContabilidadeEventFocus>;
  @Output() public readonly evtKeydown: EventEmitter<IDocContabilidadeEventKeyboard>;

  public previousModel: TValue;
  public properties: IPlEditComponentOptionsInput<unknown>;
  public preDefinidoContabLinha: IJsonPreDefinidoContabLinha;

  protected readonly _docContabilidadeService: DocContabilidadeService;
  protected _appliedValue: boolean;

  private readonly _configService: ConfigService;
  private _configurations: ICGConfigurations;
  private _currentNDocumento: string;
  private _previousNDocumento: string;
  private _subscriptionConfigurations: Subscription;

  protected constructor(
    public readonly fieldName: EDocContabilidadeField,
    protected readonly _injector: Injector,
    protected readonly _predefinidoField?: TPredefinidoField
  ) {
    this.evtModelChanged = new EventEmitter<TEvent>();
    this.evtFocus = new EventEmitter<IDocContabilidadeEventFocus>();
    this.evtBlur = new EventEmitter<IDocContabilidadeEventFocus>();
    this.evtKeydown = new EventEmitter<IDocContabilidadeEventKeyboard>();
    this._docContabilidadeService = this._injector.get<DocContabilidadeService>(DocContabilidadeService);
    this._appliedValue = false;
    this._configService = this._injector.get<ConfigService>(ConfigService);
  }

  public ngOnInit(): void {
    this._handleLinhaChanged(this.linha);
  }

  public ngOnChanges({docContabilidade, linha, locked}: SimpleChanges): void {
    if (docContabilidade) {
      this._currentNDocumento = this.docContabilidade.nDocumento;
      this._previousNDocumento = (<IDocContabilidade>docContabilidade.previousValue)?.nDocumento;
      if (this._previousNDocumento && this._currentNDocumento !== this._previousNDocumento) {
        this._appliedValue = false;
      }
    }
    if ((linha && !linha.isFirstChange()) || (this.linha && locked && !locked.isFirstChange())) {
      this._handleLinhaChanged(this.linha);
    }
  }

  public ngOnDestroy(): void {
    if (this._subscriptionConfigurations) {
      this._subscriptionConfigurations.unsubscribe();
    }
  }

  public modelChanged(value: TValue): void {
    this.previousModel = this.model;
    this.model = value;
    this._appliedValue = true;
  }

  public get configurations(): ICGConfigurations {
    if (!this._subscriptionConfigurations) {
      this._configurations = this._configService.configurations;
      this._subscriptionConfigurations = this._configService
        .configurationsAsObservable()
        .pipe(skip(1))
        .subscribe((configurations: ICGConfigurations) => {
          this._configurations = configurations;
          if (this.linha) {
            this._handleLinhaChanged(this.linha);
          }
        });
    }
    return this._configurations;
  }

  protected _generateProperties(linha: IDocContabilidadeLinha): IPlEditComponentOptionsInput<unknown> {
    return {
      disallowInput: this._disallowInput(linha),
      hasGroup: this._hasGroup(),
      disabled: this._disabled(linha),
      events: {
        focus: (value: unknown, event: FocusEvent) => {
          this._focus(event);
        },
        blur: (value: unknown, event: FocusEvent) => {
          this._blur(event);
        },
        keydown: (value: unknown, event: KeyboardEvent) => {
          this._keydown(event);
        }
      },
      modelOptions: {
        updateOn: 'blur'
      }
    };
  }

  protected _handleLinhaChanged(linha: IDocContabilidadeLinha): void {
    if (this.docContabilidade.isUsingPreDefinido && this.predefinido) {
      if (!isNumber(this.linha.preDefinidoContabLinhaIndice)) {
        this.linha.preDefinidoContabLinhaIndice = 0;
      }
      this.preDefinidoContabLinha = this.predefinido.linhas[this.linha.preDefinidoContabLinhaIndice];
    } else {
      this.preDefinidoContabLinha = undefined;
    }
    this.properties = this._generateProperties(linha);
  }

  protected _disabled(linha: IDocContabilidadeLinha): boolean {
    return this._docContabilidadeService.isLineDisabled(linha) || this.locked;
  }

  protected _disallowInput(linha: IDocContabilidadeLinha): boolean {
    return this._docContabilidadeService.evaluateReadOnly(this.docContabilidade, this._predefinidoField, linha.preDefinidoContabLinhaIndice);
  }

  protected _hasGroup(): boolean {
    return false;
  }

  protected _focus(event: FocusEvent): void {
    this.evtFocus.emit({linha: this.linha, event: event});
  }

  protected _blur(event: FocusEvent): void {
    this.evtBlur.emit({linha: this.linha, event: event});
  }

  protected _keydown(event: KeyboardEvent): void {
    this.evtKeydown.emit({linha: this.linha, event: event});
  }
}
