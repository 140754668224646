import {Subscription} from 'rxjs';
import {Component, Injector, Input, OnDestroy} from '@angular/core';
import {EMouseEventButton} from 'pl-comps-angular';
import {EEntityMaintenanceListModalResult, IEntityMaintenanceListModalResult} from '../../entity/entity.maintenance.interface';
import {EEntityStateListQueryParam, entityStateListQueryParam} from '../../../../../../common/utils/entity.state.utils';
import {EntityMaintenanceModalComponent} from '../entity.maintenance.modal.component';
import {IModuleEntityListOnDataGridCellClick, IModuleEntityListOnNewParams} from '../../../../module/entitylist/module.entitylist.interface';
import {ModuloEntityListComponent} from '../../../../module/entitylist/module.entitylist.component';

@Component({
  selector: 'entity-maintenance-list-modal',
  templateUrl: './entity.maintenance.list.modal.component.html'
})
export class EntityMaintenanceListModalComponent<T extends object, S extends ModuloEntityListComponent<T> = ModuloEntityListComponent<T>>
  extends EntityMaintenanceModalComponent<T | IEntityMaintenanceListModalResult, S>
  implements OnDestroy
{
  @Input() public filter: string;
  @Input() public initialFilter: string;

  private _subscriptionOnNew: Subscription;
  private _subscriptionOnClose: Subscription;
  private _subscriptionOnDataGridCellClick: Subscription;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._clearSubscription();
  }

  public close(result?: T | IEntityMaintenanceListModalResult): void {
    super.close(result);
  }

  protected _transitionParams(): object {
    const params: object = super._transitionParams() || {};
    if (this.filter) {
      const paramFilter: string = entityStateListQueryParam(this.entityMaintenanceInstance.entityName, EEntityStateListQueryParam.Filter);
      params[paramFilter] = this.filter;
    }
    if (this.initialFilter) {
      const paramInitialFilter: string = entityStateListQueryParam(this.entityMaintenanceInstance.entityName, EEntityStateListQueryParam.InitialFilter);
      params[paramInitialFilter] = this.initialFilter;
    }
    return params;
  }

  protected _buildTemplate(resolvedValues: Map<string, unknown>): void {
    super._buildTemplate(resolvedValues);
    this._componentRefInstance.setDisableNavigation(true);
  }

  protected _configToolbar(): void {
    super._configToolbar();
    this._componentRefInstance.btnRefresh.visible = true;
    this._componentRefInstance.btnNovo.visible = this.entityMaintenanceInstance.entity.actions.new;
    this._componentRefInstance.toolSearch.visible = this.entityMaintenanceInstance.entity.actions.search;
  }

  protected _evaluateResolvedValue(): void {
    // no need to implement
  }

  protected _observe(): void {
    this._clearSubscription();
    this._subscriptionOnNew = this._componentRefInstance.evtOnNew().subscribe((params: IModuleEntityListOnNewParams) => {
      this.close({
        result: EEntityMaintenanceListModalResult.ACTION_NEW,
        params: params?.params
      });
    });
    this._subscriptionOnClose = this._componentRefInstance.evtOnCloseMaintenance().subscribe((value: unknown) => {
      this.close(<T>value);
    });
    this._subscriptionOnDataGridCellClick = this._componentRefInstance.evtOnDataGridCellDblClick().subscribe(({data, event, rowType, column}: IModuleEntityListOnDataGridCellClick<T>) => {
      if (!event.defaultPrevented && rowType === 'data' && (!column || !column.type) && (<MouseEvent>event).button === EMouseEventButton.Main) {
        event.preventDefault();
        this.close(data);
      }
    });
  }

  protected _clearSubscription(): void {
    if (this._subscriptionOnNew) {
      this._subscriptionOnNew.unsubscribe();
      this._subscriptionOnNew = undefined;
    }
    if (this._subscriptionOnClose) {
      this._subscriptionOnClose.unsubscribe();
      this._subscriptionOnClose = undefined;
    }
    if (this._subscriptionOnDataGridCellClick) {
      this._subscriptionOnDataGridCellClick.unsubscribe();
      this._subscriptionOnDataGridCellClick = undefined;
    }
  }
}
