import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {KEYCODES, PlAlertService, PlEditTextareaComponent, PlTranslateService} from 'pl-comps-angular';
import {CG_DEFAULT_MAX_FILE_SIZE} from '../../../services/config/config.service.interface';
import {ONE_KIBIBYTE} from '../../../../common/utils/file.utils';

@Component({
  selector: 'cg-gestao-comunicacoes-message-input',
  templateUrl: './messageInput.component.html'
})
export class CGGestaoComunincacaoMessageInputComponent {
  @Input() public showAttachFile: boolean;
  @Input() public showSpeechToText: boolean;
  @Input() public showSendButton: boolean;
  @Input() public showAttachFileMessage: boolean;
  @Input() public marginBottom: boolean;
  @Input() public marginTop: boolean;
  @Output() public readonly evtSubmitMessage: EventEmitter<string>;
  @Output() public readonly evtSubmitFile: EventEmitter<File>;

  @ViewChild('messageInput') public messageInput: PlEditTextareaComponent;
  public message: string;
  public autoResizeEnabled: boolean;
  public file: File;

  constructor(
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    this.evtSubmitMessage = new EventEmitter<string>();
    this.evtSubmitFile = new EventEmitter<File>();
    this.autoResizeEnabled = true;
  }

  public onSendMessageSpeechText(messageText: string): void {
    this.message = messageText;
  }

  public addFile(event: Event): void {
    this.file = (<HTMLInputElement>event.target).files[0];
    if (this.file.size > CG_DEFAULT_MAX_FILE_SIZE * ONE_KIBIBYTE * ONE_KIBIBYTE) {
      this._plAlertService.error(this._plTranslateService.translate('gestaoComunicacoes.common.fileSizeError'));
      return;
    }
    this.evtSubmitFile.emit(this.file);
  }

  public onKeydownEnter(event: KeyboardEvent): void {
    if (!this.showSendButton) {
      this.onClickSendMessage();
      return;
    }

    if (event.key === KEYCODES.ENTER && !event.shiftKey) {
      event.preventDefault();

      if (this.message.trim()) {
        this.onClickSendMessage();
      }
    }
  }

  public onClickSendMessage(): void {
    this.evtSubmitMessage.emit(this.message);
    if (this.showSendButton) {
      this.message = '';
      this.file = undefined;
    }
  }

  public focusMessageInput(): void {
    this.messageInput.inputFocus();
  }
}
