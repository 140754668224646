import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {TabelasIRSModuleComponent} from './components/tabelasIRS.module.component';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_TABELAS_IRS} from './tabelasIRS.module.interface';
import {TabelasIRSModuleService} from './tabelasIRS.module.service';
import {IJsonTirs} from './jsonTabelasIRS.module.interface';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';

export const MODULE_TABELAS_IRS: IModuleDefinition = {
  name: MODULE_NAME_TABELAS_IRS,
  state: {
    url: `/${MODULE_NAME_TABELAS_IRS}`,
    component: TabelasIRSModuleComponent,
    data: {
      roles: [ROLE.RH],
      pageTitle: 'global.menu.tirs'
    },
    resolve: [
      {
        provide: 'tabelasIRS',
        deps: [TabelasIRSModuleService],
        useFactory: (tabelasIRSService: TabelasIRSModuleService) => tabelasIRSService.query().then((response: THttpQueryResponse<IJsonTirs>) => response.body.list)
      }
    ]
  }
};
