<div class="cg-dashboards dashboard-rh">
  <div class="row">
    <div class="col-sm-12">
      <div class="row cg-dashboards-spacing">
        <div class="col-sm-12 col-lg-4 col-xl-2">
          <pl-dashboard [dashboard]="countFuncionariosAtivos"></pl-dashboard>
        </div>

        <div class="col-sm-12 col-lg-4 col-xl-2">
          <pl-dashboard [dashboard]="countEntradasUltimosMeses"></pl-dashboard>
        </div>

        <div class="col-sm-12 col-lg-4 col-xl-2">
          <pl-dashboard [dashboard]="countSaidasUltimosMeses"></pl-dashboard>
        </div>

        <div class="col-sm-12 col-lg-4 col-xl-2">
          <pl-dashboard [dashboard]="countFuncionariosTempoInteiro"></pl-dashboard>
        </div>

        <div class="col-sm-12 col-lg-4 col-xl-2">
          <pl-dashboard [dashboard]="countFuncionariosTempoParcial"></pl-dashboard>
        </div>

        <div class="col-sm-12 col-lg-4 col-xl-2">
          <pl-dashboard [dashboard]="countFuncionariosOutros"></pl-dashboard>
        </div>
      </div>
    </div>

    <div class="col-sm-12">
      <div class="row cg-dashboards-spacing">
        <div class="col-sm-12 col-lg-4">
          <div class="card p-3">
            <dx-pie-chart
              [cgDxPieChart]="chartPieFuncionariosPorSexo"
              [dataSource]="chartPieDataFuncionariosPorSexo"
              (onInitialized)="onInitializedChartPieFuncionariosSexo($event)"
              (onLegendClick)="onLegendClick($event)">
              <div *dxTemplate="let tooltip of 'customTooltip'">
                <span>{{ tooltip.argument }}</span>
              </div>
            </dx-pie-chart>
          </div>
        </div>

        <div class="col-sm-12 col-lg-4">
          <div class="card p-3">
            <dx-pie-chart
              [cgDxPieChart]="chartPieFuncionariosPorIdade"
              [dataSource]="chartPieDataFuncionariosPorIdade"
              (onInitialized)="onInitializedChartPieFuncionariosIdade($event)"
              (onLegendClick)="onLegendClick($event)">
              <div *dxTemplate="let tooltip of 'customTooltip'">
                <span>{{ tooltip.argument }}</span>
              </div>
            </dx-pie-chart>
          </div>
        </div>

        <div class="col-sm-12 col-lg-4">
          <div class="card p-3">
            <dx-pie-chart
              [cgDxPieChart]="chartPieFuncionariosPorDepartamento"
              [dataSource]="chartPieDataFuncionariosPorDepartamento"
              (onInitialized)="onInitializedChartPieFuncionariosPorDepartamento($event)"
              (onLegendClick)="onLegendClick($event)">
              <div *dxTemplate="let tooltip of 'customTooltip'">
                <span>{{ tooltip.argument }}</span>
              </div>
            </dx-pie-chart>
          </div>
        </div>

        <div class="col-sm-12 col-lg-6">
          <div class="card p-3">
            <dx-pie-chart
              [cgDxPieChart]="chartPieFuncionariosPorTipoContrato"
              [dataSource]="chartPieDataFuncionariosPorTipoContrato"
              (onInitialized)="onInitializedChartPieFuncionariosPorTipoContrato($event)"
              (onLegendClick)="onLegendClick($event)">
              <div *dxTemplate="let tooltip of 'customTooltip'">
                <span>{{ tooltip.argument }}</span>
              </div>
            </dx-pie-chart>
          </div>
        </div>

        <div class="col-sm-12 col-lg-6">
          <div class="card p-3">
            <dx-chart
              [cgDxChart]="chartBarCustosFuncionarios"
              [dataSource]="chartBarDataCustosFuncionarios"
              (onInitialized)="onInitializedChartBarCustosFuncionarios($event)"
              (onPointHoverChanged)="onChartPointHoverChanged($event)"
              (onLegendClick)="onChartLegendClick($event)">
              <div *dxTemplate="let item of 'customTooltip'">
                <div>
                  <b>{{ item.argument }}</b>
                </div>
                <div *ngFor="let itemPoint of item.points">
                  <span>{{ itemPoint.seriesName }}: </span>
                  <span>{{ itemPoint.originalValue | cgcCurrency }}</span>
                </div>
              </div>
            </dx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
