import {TDate} from '../../../common/dates';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonContrato, IJsonContratoContadores} from './jsonContrato.entity.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {TServiceResponse} from '../../services/api/api.service.interface';

export interface IListagemContratosFilters {
  declifo: string;
  ateclifo: string;
  dencontrato: number;
  atencontrato: number;
  classes: Array<string>;
  tipos: Array<string>;
  dtdeini: TDate;
  dtateini: TDate;
  dtdefim: TDate;
  dtatefim: TDate;
  dtdedatactr: TDate;
  dtatedatactr: TDate;
  reportname: string;
}

export interface IContratosEntityService extends IEntityService<IJsonContrato> {
  getContadores(): TServiceResponse<IJsonContratoContadores>;
}

export interface IContratosEntity extends IEntityDefinition<IJsonContrato, IContratosEntityService> {}

export const ENTITY_NAME_CONTRATOS = 'contratos';

export const ENTITY_NAME_AVENCAS = 'contratosservicos';
