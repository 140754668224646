import {Component, Injector, OnInit} from '@angular/core';
import {IJsonSOLIC} from '../../jsonSolic.entity.interface';
import {IModuleEntityListOnDataGridCellClick} from '../../../../components/module/entitylist/module.entitylist.interface';
import {ISOLICRequest} from '../../solic.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';

@Component({
  selector: 'module-solic-list',
  templateUrl: './solic.entity.list.component.html'
})
export class SOLICEntityListComponent extends ModuloEntityListComponent<IJsonSOLIC> implements OnInit {
  constructor(protected readonly _injector: Injector) {
    super(_injector);
  }

  public onDataGridCellClick(eventOnSelect: IModuleEntityListOnDataGridCellClick<IJsonSOLIC>): Promise<void> {
    const params: ISOLICRequest = {tipotexto: eventOnSelect.data.tipoTexto};
    return super.onDataGridCellClick({...eventOnSelect, params: params});
  }
}
