<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'efaturacirculacaologin.title'"></h4>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-md-6">
      <pl-group>
        <label [translate]="'docfasNums.modal.consulta.fields.serie'"></label>
        <edit>
          <pl-edit type="text" [(model)]="model.serie" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'docfasNums.modal.consulta.fields.tipoSerie'"></label>
        <edit>
          <pl-edit type="text" [(model)]="model.tipoSerie" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'docfasNums.modal.consulta.fields.classeDoc'"></label>
        <edit>
          <pl-edit type="text" [(model)]="model.classeDoc" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'docfasNums.modal.consulta.fields.tipoDoc'"></label>
        <edit>
          <pl-edit type="text" [(model)]="model.tipoDoc" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'docfasNums.modal.consulta.fields.numInicialSeq'"></label>
        <edit>
          <pl-edit type="text" [(model)]="model.numInicialSeq" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'docfasNums.modal.consulta.fields.dataInicioPrevUtiliz'"></label>
        <edit>
          <pl-edit type="date" [(model)]="model.dataInicioPrevUtiliz" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'docfasNums.modal.consulta.fields.seqUltimoDocEmitido'"></label>
        <edit>
          <pl-edit type="text" [(model)]="model.seqUltimoDocEmitido" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
    </div>
    <div class="col-md-6">
      <pl-group>
        <label [translate]="'docfasNums.modal.consulta.fields.meioProcessamento'"></label>
        <edit>
          <pl-edit type="text" [(model)]="model.meioProcessamento" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'docfasNums.modal.consulta.fields.numCertSWFatur'"></label>
        <edit>
          <pl-edit type="text" [(model)]="model.numCertSWFatur" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'docfasNums.modal.consulta.fields.codValidacaoSerie'"></label>
        <edit>
          <pl-edit type="text" [(model)]="model.codValidacaoSerie" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'docfasNums.modal.consulta.fields.dataRegisto'"></label>
        <edit>
          <pl-edit type="date" [(model)]="model.dataRegisto" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'docfasNums.modal.consulta.fields.estado'"></label>
        <edit>
          <pl-edit type="text" [(model)]="model.estado" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'docfasNums.modal.consulta.fields.dataEstado'"></label>
        <edit>
          <pl-edit type="date" [(model)]="model.dataEstado" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'docfasNums.modal.consulta.fields.nifComunicou'"></label>
        <edit>
          <pl-edit type="text" [(model)]="model.nifComunicou" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>
    </div>
  </div>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light action-close" (evtClicked)="close()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
