import {Component, Injector, Input, OnInit} from '@angular/core';
import {IJsonModelo22AnexoD} from '../../../../jsonModelo22.module.interface';
import {CGModalComponent} from '../../../../../../../components/cg/modal/cgmodal.component';
import {IDataSourceItem} from '../../../../../../../components/datasource/datasources.interface';
import {isEmpty, isNumber, isObject, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {IAnexoDBeneficioMontante, IAnexoDItem041, IAnexoDItem08, IModelo22AnexoDItem121, IModelo22AnexoDItem122, IModelo22ItemPeriodoMontante} from '../../../../modelo22.module.interface';
import {Modelo22Service} from '../../../../modelo22.module.service';
import {TDate} from '../../../../../../../../common/dates';
import moment from 'moment';

@Component({
  selector: 'modal-modelo22-anexod',
  templateUrl: './modelo22.anexoD.modal.component.html'
})
export class Modelo22AnexoDModalComponent extends CGModalComponent<IJsonModelo22AnexoD> implements OnInit {
  @Input() public newLineFor: string;
  @Input() public anexoD: IJsonModelo22AnexoD;
  public readonly dsBeneficioTemplate: string;

  public promise: Promise<void>;
  public dsBeneficio: Array<IDataSourceItem<number>>;
  public codBeneficioView: string;
  public ano: number;
  public montante: number;
  public nif: number;
  public nace: number;
  public valorDonativo: number;
  public tipoDonativo: string;
  public data: TDate;
  public percentagem: number;
  public periodo: number;
  public prejuizosMontante: number;
  public prejuizosSaldo: number;
  public is31A: boolean;
  public is31B: boolean;
  public is32A: boolean;
  public is32B: boolean;
  public is04A: boolean;
  public is041: boolean;
  public is603: boolean;
  public is604: boolean;
  public is605: boolean;
  public is121: boolean;
  public is122: boolean;
  public isQuadro8: boolean;
  public isQuadro9: boolean;
  public isQuadro11A: boolean;

  private _codBeneficio: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _modelo22Service: Modelo22Service
  ) {
    super(_injector);
    this.dsBeneficioTemplate = '{{value}} - {{name}}';
    this.dsBeneficio = [];
    this.codBeneficioView = '';
    this.tipoDonativo = '';
    this._codBeneficio = 0;
    this.montante = 0;
    this.ano = undefined;
    this.valorDonativo = 0;
    this.nif = undefined;
    this.nace = undefined;
    this.data = moment();
    this.percentagem = 0;
    this.periodo = undefined;
    this.prejuizosMontante = 0;
    this.prejuizosSaldo = 0;
  }

  public ngOnInit(): void {
    this.is31A = this.newLineFor === '31A';
    this.is31B = this.newLineFor === '31B';
    this.is32A = this.newLineFor === '32A';
    this.is32B = this.newLineFor === '32B';
    this.is04A = this.newLineFor === '04A';
    this.is041 = this.newLineFor === '041';
    this.is603 = this.newLineFor === '603';
    this.is604 = this.newLineFor === '604';
    this.is605 = this.newLineFor === '605';
    this.is121 = this.newLineFor === '121';
    this.is122 = this.newLineFor === '122';
    this.isQuadro8 = this.newLineFor === 'quadro8';
    this.isQuadro9 = this.newLineFor === 'quadro9';
    this.isQuadro11A = this.newLineFor === 'quadro11A';
    this._setDSBeneficio();
  }

  public evtBeneficioChanged(value: {inputValue: string; item: IDataSourceItem<number>}): void {
    if (isObject(value.item)) {
      this.codBeneficioView = `${value.item.value} - ${value.item.name}`;
      this._codBeneficio = value.item.value;
    }
  }

  public close(): Promise<void> {
    if (this.is31A) {
      if (!isNumber(this._codBeneficio) || this._codBeneficio === 0) {
        this._plAlertService.error('modelo22.messages.codbenefvazio');
        return Promise.resolve();
      }
      if (this.anexoD.quadro03.campo031AList.findIndex((value: IAnexoDBeneficioMontante) => value.codBeneficio === this._codBeneficio) !== -1) {
        this._plAlertService.error('modelo22.messages.codbenefvazioAlredyExists');
        return Promise.resolve();
      }
      this.anexoD.quadro03.campo031AList.push({
        codBeneficio: this._codBeneficio,
        montante: this.montante
      });

      super.close(this.anexoD);
    }
    if (this.is31B) {
      if (!isNumber(this._codBeneficio) || this._codBeneficio === 0) {
        this._plAlertService.error('modelo22.messages.codbenefvazio');
        return Promise.resolve();
      }
      if (this.anexoD.quadro03.campo031BList.findIndex((value: IAnexoDBeneficioMontante) => value.codBeneficio === this._codBeneficio) !== -1) {
        this._plAlertService.error('modelo22.messages.codbenefvazioAlredyExists');
        return Promise.resolve();
      }
      this.anexoD.quadro03.campo031BList.push({
        codBeneficio: this._codBeneficio,
        montante: this.montante
      });

      super.close(this.anexoD);
    }
    if (this.is32A) {
      if (!isNumber(this._codBeneficio) || this._codBeneficio === 0) {
        this._plAlertService.error('modelo22.messages.codbenefvazio');
        return Promise.resolve();
      }
      if (this.anexoD.quadro03.campo032AList.findIndex((value: IAnexoDBeneficioMontante) => value.codBeneficio === this._codBeneficio) !== -1) {
        this._plAlertService.error('modelo22.messages.codbenefvazioAlredyExists');
        return Promise.resolve();
      }
      this.anexoD.quadro03.campo032AList.push({
        codBeneficio: this._codBeneficio,
        montante: this.montante
      });

      super.close(this.anexoD);
    }
    if (this.is32B) {
      if (!isNumber(this._codBeneficio) || this._codBeneficio === 0) {
        this._plAlertService.error('modelo22.messages.codbenefvazio');
        return Promise.resolve();
      }
      if (this.anexoD.quadro03.campo032BList.findIndex((value: IAnexoDBeneficioMontante) => value.codBeneficio === this._codBeneficio) !== -1) {
        this._plAlertService.error('modelo22.messages.codbenefvazioAlredyExists');
        return Promise.resolve();
      }
      this.anexoD.quadro03.campo032BList.push({
        codBeneficio: this._codBeneficio,
        montante: this.montante
      });

      super.close(this.anexoD);
    }
    if (this.is04A) {
      if (!isNumber(this._codBeneficio) || this._codBeneficio === 0) {
        this._plAlertService.error('modelo22.messages.codbenefvazio');
        return Promise.resolve();
      }
      if (this.anexoD.quadro04.campo04AList.findIndex((value: IAnexoDBeneficioMontante) => value.codBeneficio === this._codBeneficio) !== -1) {
        this._plAlertService.error('modelo22.messages.codbenefvazioAlredyExists');
        return Promise.resolve();
      }
      this.anexoD.quadro04.campo04AList.push({
        codBeneficio: this._codBeneficio,
        montante: this.montante
      });

      super.close(this.anexoD);
    }
    if (this.is041) {
      if (!isNumber(this._codBeneficio) || this._codBeneficio === 0) {
        this._plAlertService.error('modelo22.messages.codbenefvazio');
        return Promise.resolve();
      }

      const index41 = this.anexoD.quadro04.campo041List.findIndex((value: IAnexoDItem041) => value.nif === this.nif);
      if (index41 !== -1) {
        if (this.anexoD.quadro04.campo041List[index41].codBeneficio === this._codBeneficio) {
          this._plAlertService.error('modelo22.messages.nifAlredyExists');
          return Promise.resolve();
        }
      }

      this.promise = this._modelo22Service.getValidateNif(this.nif).then(() => {
        this.anexoD.quadro04.campo041List.push({
          codBeneficio: this._codBeneficio,
          nif: this.nif,
          montante: this.montante
        });
        super.close(this.anexoD);
      });
      return this.promise;
    }
    if (this.is603) {
      if (!isNumber(this.ano)) {
        this._plAlertService.error('modelo22.messages.anoVazio');
        return Promise.resolve();
      }

      if (this.anexoD.quadro06.campo603List.findIndex((value: IModelo22ItemPeriodoMontante) => value.periodo === this.ano) !== -1) {
        this._plAlertService.error('modelo22.messages.anoAlredyExists');
        return Promise.resolve();
      }
      this.anexoD.quadro06.campo603List.push({
        periodo: this.ano,
        montante: this.montante
      });
      super.close(this.anexoD);
    }
    if (this.is604) {
      if (!isNumber(this.nace)) {
        this._plAlertService.error('modelo22.messages.naceVazio');
        return Promise.resolve();
      }

      if (this.anexoD.quadro06.campo604List.findIndex((value: number) => value === this.nace) !== -1) {
        this._plAlertService.error('modelo22.messages.naceAlredyExists');
        return Promise.resolve();
      }
      this.anexoD.quadro06.campo604List.push(this.nace);
      super.close(this.anexoD);
    }
    if (this.is605) {
      if (!isNumber(this.nace)) {
        this._plAlertService.error('modelo22.messages.naceVazio');
        return Promise.resolve();
      }

      if (this.anexoD.quadro06.campo605List.findIndex((value: number) => value === this.nace) !== -1) {
        this._plAlertService.error('modelo22.messages.naceAlredyExists');
        return Promise.resolve();
      }
      this.anexoD.quadro06.campo605List.push(this.nace);
      super.close(this.anexoD);
    }
    if (this.isQuadro8) {
      if (isEmpty(this.tipoDonativo)) {
        this._plAlertService.error('modelo22.messages.tipoDonativovazio');
        return Promise.resolve();
      }

      const index8 = this.anexoD.quadro08.lista.findIndex((value: IAnexoDItem08) => value.nif === this.nif);
      if (index8 !== -1) {
        if (this.anexoD.quadro08.lista[index8].tipoDonativo === this.tipoDonativo) {
          this._plAlertService.error('modelo22.messages.niftipoDonativoAlredyExists');
          return Promise.resolve();
        }
      }

      this.promise = this._modelo22Service.getValidateNif(this.nif).then(() => {
        this.anexoD.quadro08.lista.push({
          tipoDonativo: this.tipoDonativo,
          nif: this.nif,
          valor: this.valorDonativo
        });
        super.close(this.anexoD);
      });
      return this.promise;
    }
    if (this.isQuadro9) {
      if (!isNumber(this.nif)) {
        this._plAlertService.error('modelo22.messages.nifEmpty');
        return Promise.resolve();
      }
      if (this.anexoD.quadro09.campo907List.findIndex((value: number) => value === this.nif) !== -1) {
        this._plAlertService.error('modelo22.messages.nifAlredyExists');
        return Promise.resolve();
      }
      this.promise = this._modelo22Service.getValidateNif(this.nif).then(() => {
        this.anexoD.quadro09.campo907List.push(this.nif);
        super.close(this.anexoD);
      });
      return this.promise;
    }
    if (this.isQuadro11A) {
      if (this.anexoD.quadro11A.campo3List.findIndex((value: number) => value === this.nif) !== -1) {
        this._plAlertService.error('modelo22.messages.nifAlredyExists');
        return Promise.resolve();
      }
      this.promise = this._modelo22Service.getValidateNif(this.nif).then(() => {
        this.anexoD.quadro11A.campo3List.push(this.nif);
        super.close(this.anexoD);
      });
      return this.promise;
    }
    if (this.is121) {
      if (this.anexoD.quadro12.lista121.findIndex((value: IModelo22AnexoDItem121) => value.nif === this.nif) !== -1) {
        this._plAlertService.error('modelo22.messages.nifAlredyExists');
        return Promise.resolve();
      }
      this.promise = this._modelo22Service.getValidateNif(this.nif).then(() => {
        this.anexoD.quadro12.lista121.push({
          nif: this.nif,
          data: this.data,
          percentagem: this.percentagem,
          periodo: this.periodo,
          prejuizosMontante: this.prejuizosMontante,
          prejuizosSaldo: this.prejuizosSaldo
        });
        super.close(this.anexoD);
      });
      return this.promise;
    }
    if (this.is122) {
      if (this.anexoD.quadro12.lista122.findIndex((value: IModelo22AnexoDItem122) => value.nif === this.nif) !== -1) {
        this._plAlertService.error('modelo22.messages.nifAlredyExists');
        return Promise.resolve();
      }
      this.promise = this._modelo22Service.getValidateNif(this.nif).then(() => {
        this.anexoD.quadro12.lista122.push({
          nif: this.nif,
          percentagem: this.percentagem,
          periodo: this.periodo,
          prejuizosMontante: this.prejuizosMontante,
          prejuizosSaldo: this.prejuizosSaldo
        });
        super.close(this.anexoD);
      });
      return this.promise;
    }
    return Promise.resolve();
  }

  private _setDSBeneficio(): void {
    if (this.is31A) {
      this.dsBeneficio.push(
        {
          value: 140,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.140')
        },
        {
          value: 141,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.141')
        },
        {
          value: 142,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.142')
        },
        {
          value: 143,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.143')
        },
        {
          value: 149,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.149')
        }
      );
    }

    if (this.is31B) {
      this.dsBeneficio.push(
        {
          value: 40,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.040')
        },
        {
          value: 49,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.049')
        }
      );
    }

    if (this.is32A) {
      this.dsBeneficio.push(
        {
          value: 150,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.150')
        },
        {
          value: 151,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.151')
        },
        {
          value: 159,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.159')
        }
      );
    }

    if (this.is32B) {
      this.dsBeneficio.push(
        {
          value: 120,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.120')
        },
        {
          value: 121,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.121')
        },
        {
          value: 122,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.122')
        },
        {
          value: 129,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.129')
        }
      );
    }

    if (this.is04A) {
      this.dsBeneficio.push(
        {
          value: 100,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.100')
        },
        {
          value: 109,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.109')
        }
      );
    }

    if (this.is041) {
      this.dsBeneficio.push(
        {
          value: 401,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.401')
        },
        {
          value: 409,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.409')
        },
        {
          value: 410,
          name: this._plTranslateService.translate('modelo22.datasources.beneficio.410')
        }
      );
    }
  }
}
