<div class="rhregistarempregadosss-container">
  <pl-tabs [(activeId)]="mainActiveTabId">
    <pl-tab [id]="mainConsultarTabId" caption="rhregistarempregadosss.consultarMainTabTitle">
      <div *plTabContent>
        <div class="flex-wrap-gap mb-2">
          <div class="rhregistarempregadosss-filter-group me-4">
            <label [translate]="'global.text.date'"></label>
            <pl-group style="max-width: 150px">
              <edit>
                <pl-edit-datepicker [(model)]="filtersModel.dataIni"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </div>

          <div class="rhregistarempregadosss-filter-group me-4">
            <label [translate]="'global.text.to'"></label>
            <pl-group style="max-width: 150px">
              <edit>
                <pl-edit-datepicker [(model)]="filtersModel.dataFim"></pl-edit-datepicker>
              </edit>
            </pl-group>
          </div>

          <div class="rhregistarempregadosss-filter-group me-4">
            <label [translate]="'rhregistarempregadosss.strings.empregado'"></label>
            <pl-group style="max-width: 100px">
              <edit>
                <pl-edit type="integer" [(model)]="filtersModel.deCodEmp"></pl-edit>
              </edit>
            </pl-group>
          </div>

          <div class="rhregistarempregadosss-filter-group me-4">
            <label [translate]="'global.text.to'"></label>
            <pl-group style="max-width: 100px">
              <edit>
                <pl-edit type="integer" [(model)]="filtersModel.ateCodEmp"></pl-edit>
              </edit>
            </pl-group>
          </div>

          <div class="rhregistarempregadosss-filter-group">
            <pl-button *ngIf="consultarActiveTabId === consultarVerificarEmpregadosTabId" type="button" klass="btn-primary" [onClick]="fnConsultarEmpregados">
              <i class="fa fa-search"></i>&nbsp;<span [translate]="'rhregistarempregadosss.strings.consultarEmpregados'"></span>
            </pl-button>

            <pl-button *ngIf="consultarActiveTabId === consultarVerificarContratosTabId" type="button" klass="btn-primary" [onClick]="fnVerificarContratos">
              <i class="fa fa-search"></i>&nbsp;<span [translate]="'rhregistarempregadosss.strings.verificarContratos'"></span>
            </pl-button>
          </div>
        </div>
        <pl-tabs [theme]="'style3'" [(activeId)]="consultarActiveTabId">
          <pl-tab [id]="consultarVerificarEmpregadosTabId" caption="rhregistarempregadosss.verificarEmpregadosTabTitle">
            <div *plTabContent>
              <dx-data-grid [cgDxDataGrid]="verificarEmpregadosDataGridDefinition" [cgDxDataGridInstanceName]="'verificarEmpregadosDataGrid'" [dataSource]="verificarEmpregadosDataGridDS">
                <div *dxTemplate="let cell of 'templateToolbarButton'">
                  <pl-button type="button" klass="btn-success btn-sm" [onClick]="fnAtualizarEstadoEmpregados">
                    <i class="fa fa-refresh"></i>&nbsp;<span [translate]="'rhregistarempregadosss.strings.atualizarEstadoEmpregados'"></span>
                  </pl-button>
                </div>
              </dx-data-grid>
            </div>
          </pl-tab>
          <pl-tab [id]="consultarVerificarContratosTabId" caption="rhregistarempregadosss.verificarRegistarContratosTabTitle">
            <div *plTabContent>
              <dx-data-grid
                [cgDxDataGrid]="verificarRegistarContratosDataGridDefinition"
                [cgDxDataGridInstanceName]="'verificarRegistarContratosDataGrid'"
                [dataSource]="verificarRegistarContratosDataGridDS"
                (onInitialized)="onInitializedVerificarRegistarContratos($event)">
                <div *dxTemplate="let item of 'actions'">
                  <pl-tooltip *ngIf="!item.data.isContratoEnviadoParaSS" [config]="{text: 'rhregistarempregadosss.strings.editarContrato', container: 'body'}">
                    <button type="button" *plTooltipContent class="btn btn-xs btn-warning" (click)="editContrato(item.data)"><i class="fa fa-pencil" aria-hidden="true"></i></button>
                  </pl-tooltip>
                </div>

                <div *dxTemplate="let cell of 'templateToolbarButton'">
                  <pl-button type="button" klass="btn-success btn-sm" [onClick]="fnRegistarContratos">
                    <i class="fa fa-check"></i>&nbsp;<span [translate]="'rhregistarempregadosss.strings.registarContratos'"></span>
                  </pl-button>
                </div>
              </dx-data-grid>
            </div>
          </pl-tab>
        </pl-tabs>
      </div>
    </pl-tab>
    <pl-tab [id]="mainRegistarTabId" caption="rhregistarempregadosss.registarMainTabTitle">
      <div *plTabContent>
        <div class="rhregistarempregadosss-filter-group mb-3">
          <label><span [translate]="'rhregistarempregadosss.strings.codempregado'"></span>:</label>
          <pl-group style="width: 350px">
            <edit>
              <entity-autocomplete entity="dgemps" [model]="registarEmpregado" (modelChange)="onRegistarCodEmpChanged($event)" [filter]="registarCodEmpFilter"></entity-autocomplete>
            </edit>
          </pl-group>
        </div>

        <div [plPromise]="promiseLoadEmpregado">
          <div class="container" style="margin: unset; padding: 0">
            <div class="row">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-header">
                    <span [translate]="'rhregistarempregadosss.strings.dadosEmpregado'"></span>
                  </div>
                  <div class="card-body">
                    <div class="d-flex">
                      <div style="flex: 1">
                        <div class="d-flex" style="gap: 10px">
                          <div class="rhregistarempregadosss-filter-group mb-2">
                            <label><span [translate]="'rhregistarempregadosss.strings.dataAdmissao'"></span>:</label>
                            <pl-group style="max-width: 150px">
                              <edit>
                                <pl-edit-datepicker [(model)]="registarModel.dtAdmissao" [properties]="{disabled: true}"></pl-edit-datepicker>
                              </edit>
                            </pl-group>
                          </div>

                          <div class="rhregistarempregadosss-filter-group mb-2">
                            <label><span [translate]="'rhregistarempregadosss.strings.niss'"></span>:</label>
                            <pl-group style="max-width: 150px">
                              <edit>
                                <pl-edit type="text" [(model)]="registarModel.niss" [properties]="{disabled: true}"></pl-edit>
                              </edit>
                            </pl-group>
                          </div>
                        </div>

                        <div class="rhregistarempregadosss-filter-group mb-2">
                          <label><span [translate]="'rhregistarempregadosss.strings.numEstabelecimentoSS'"></span>:</label>
                          <pl-group style="max-width: 150px">
                            <edit>
                              <pl-edit type="text" [(model)]="registarModel.localEstabelec" [properties]="{disabled: true}"></pl-edit>
                            </edit>
                          </pl-group>
                        </div>

                        <div class="rhregistarempregadosss-filter-group mb-2">
                          <label><span [translate]="'rhregistarempregadosss.strings.tipoContratoTrabalho'"></span>:</label>
                          <pl-group style="width: 100%">
                            <edit>
                              <pl-edit type="text" [(model)]="registarModel.tipoContrTrab" [properties]="{disabled: true}"></pl-edit>
                            </edit>
                          </pl-group>
                        </div>

                        <div class="rhregistarempregadosss-filter-group mb-2">
                          <label><span [translate]="'rhregistarempregadosss.strings.assinadoEm'"></span>:</label>
                          <pl-group style="max-width: 150px">
                            <edit>
                              <pl-edit-datepicker [(model)]="registarModel.dtAssinadoEm" [properties]="{disabled: true}"></pl-edit-datepicker>
                            </edit>
                          </pl-group>
                        </div>

                        <div class="rhregistarempregadosss-filter-group mb-2" *ngIf="ui.dtValidoAteVisible">
                          <label><span [translate]="'rhregistarempregadosss.strings.validaAte'"></span>:</label>
                          <pl-group style="max-width: 150px">
                            <edit>
                              <pl-edit-datepicker [(model)]="registarModel.dtValidoAte" [properties]="{disabled: true}"></pl-edit-datepicker>
                            </edit>
                          </pl-group>
                        </div>

                        <div class="d-flex" style="gap: 10px">
                          <div class="rhregistarempregadosss-filter-group mb-2">
                            <label><span [translate]="'rhregistarempregadosss.strings.vencimentoBase'"></span>:</label>
                            <pl-group style="max-width: 250px">
                              <edit>
                                <pl-edit type="currency" [(model)]="registarModel.vencimentoBase" [properties]="{disabled: true}"></pl-edit>
                              </edit>
                            </pl-group>
                          </div>

                          <div class="rhregistarempregadosss-filter-group mb-2">
                            <label><span [translate]="'rhregistarempregadosss.strings.diuturnidades'"></span>:</label>
                            <pl-group style="min-width: 250px; max-width: 350px">
                              <edit>
                                <pl-edit type="currency" [(model)]="registarModel.diuturnidades" [properties]="{disabled: true}"></pl-edit>
                              </edit>
                            </pl-group>
                          </div>
                        </div>
                      </div>
                      <div style="width: 200px">
                        <div class="text-center" [ngClass]="getRegisterEmpInfoColorCssClass()">
                          <div class="mb-3"><i class="fa registerEmpInfoIcon" [ngClass]="getRegisterEmpInfoIcon()"></i></div>
                          <div><label [innerHTML]="getRegisterEmpInfoText()"></label></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <pl-tabs [theme]="'style3'" [(activeId)]="registarActiveTabId">
                  <pl-tab [id]="registarEmpregadosRegistarTabId" caption="rhregistarempregadosss.registarEmpregadosRegistarTabTitle" *ngIf="registarModel.podeVerRegistar">
                    <div *plTabContent>
                      <div class="card">
                        <div class="card-header">
                          <span [translate]="'rhregistarempregadosss.strings.contratoSegSocial'"></span>
                        </div>
                        <div class="card-body">
                          <div class="rhregistarempregadosss-filter-group mb-2">
                            <label><span [translate]="'rhregistarempregadosss.strings.modalidade'"></span>:</label>
                            <pl-group>
                              <edit>
                                <pl-edit-select
                                  [attrName]="'modalidade'"
                                  [model]="registarModel.modalidadeCtrSSEnum"
                                  (modelChange)="modalidadeCtrSSChanged($event)"
                                  [properties]="{select: {list: modalidadeList}, disallowClear: true, disabled: !registarModel.podeRegistar}"></pl-edit-select>
                              </edit>
                            </pl-group>
                          </div>

                          <div class="rhregistarempregadosss-filter-group mb-2">
                            <label><span [translate]="'rhregistarempregadosss.strings.motivo'"></span>:</label>
                            <pl-group>
                              <edit>
                                <pl-edit-select
                                  [attrName]="'motivoCtrSS'"
                                  [model]="registarModel.motivoCtrSSEnum"
                                  (modelChange)="motivoCtrSSChanged($event)"
                                  [properties]="{select: {list: motivoCtrList}, disallowClear: true, disabled: !ui.motivoCtrSSEnabled}"></pl-edit-select>
                              </edit>
                            </pl-group>
                          </div>

                          <div class="rhregistarempregadosss-filter-group mb-4">
                            <label><span [translate]="'rhregistarempregadosss.strings.prestacaoTrabalho'"></span>:</label>
                            <pl-group style="max-width: 200px">
                              <edit>
                                <pl-edit-select
                                  [attrName]="'motivoCtrSS'"
                                  [(model)]="registarModel.prestacaoTrabalhoSSEnum"
                                  [properties]="{select: {list: prestacaoTrabalhoList}, disallowClear: true, disabled: !registarModel.podeRegistar}"></pl-edit-select>
                              </edit>
                            </pl-group>
                          </div>

                          <h4 class="rhregistarempregadosss-title" [translate]="'rhregistarempregadosss.strings.tempoParcial'"></h4>

                          <div class="d-flex" style="gap: 10px">
                            <div class="rhregistarempregadosss-filter-group mb-2">
                              <label><span [translate]="'rhregistarempregadosss.strings.percentagemTrabalho'"></span>:</label>
                              <pl-group style="max-width: 250px">
                                <edit>
                                  <pl-edit type="currency" [(model)]="registarModel.percTrabalhoCtrSS" [properties]="{disabled: !ui.percTrabalhoCtrSSEnabled}"></pl-edit>
                                </edit>
                              </pl-group>
                            </div>

                            <div class="rhregistarempregadosss-filter-group mb-2">
                              <label><span [translate]="'rhregistarempregadosss.strings.horasTrabalho'"></span>:</label>
                              <pl-group style="min-width: 250px; max-width: 350px">
                                <edit>
                                  <pl-edit type="currency" [(model)]="registarModel.horasTrabalhoCtrSS" [properties]="{disabled: !ui.horasTrabalhoCtrSSEnabled}"></pl-edit>
                                </edit>
                              </pl-group>
                            </div>
                          </div>

                          <div class="d-flex" style="gap: 10px">
                            <div class="rhregistarempregadosss-filter-group mb-2">
                              <label><span [translate]="'rhregistarempregadosss.strings.diasTrabalho'"></span>:</label>
                              <pl-group style="max-width: 250px">
                                <edit>
                                  <pl-edit type="currency" [(model)]="registarModel.diasTrabalhoCtrSS" [properties]="{disabled: !ui.diasTrabalhoCtrSSEnabled}"></pl-edit>
                                </edit>
                              </pl-group>
                            </div>

                            <div class="rhregistarempregadosss-filter-group mb-2">
                              <label><span [translate]="'rhregistarempregadosss.strings.codEmpSubstituir'"></span>:</label>
                              <pl-group>
                                <edit>
                                  <entity-autocomplete
                                    entity="dgemps"
                                    [(model)]="registarModel.empregadoSubsttituitSSctr"
                                    [properties]="{disabled: !ui.empregadoSubsttituitSSctrEnabled}"
                                    [filter]="registarCodEmpFilter"></entity-autocomplete>
                                </edit>
                              </pl-group>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer text-end">
                          <pl-button type="button" klass="btn-success btn-sm" [onClick]="fnDoRegistarEmpregado" [disabled]="!registarModel.podeRegistar">
                            <i class="fa fa-check"></i>&nbsp;<span [translate]="'rhregistarempregadosss.registarEmpregadosRegistarTabTitle'"></span>
                          </pl-button>
                        </div>
                      </div>
                    </div>
                  </pl-tab>
                  <pl-tab [id]="registarEmpregadosCessarTabId" caption="rhregistarempregadosss.registarEmpregadosCessarTabTitle" *ngIf="registarModel.podeVerCessar">
                    <div *plTabContent>
                      <div class="card">
                        <div class="card-header">
                          <span [translate]="'rhregistarempregadosss.strings.contratoSegSocial'"></span>
                        </div>
                        <div class="card-body">
                          <div class="rhregistarempregadosss-filter-group mb-2">
                            <label><span [translate]="'rhregistarempregadosss.strings.dataFimVinculo'"></span>:</label>
                            <pl-group style="max-width: 150px">
                              <edit>
                                <pl-edit-datepicker [(model)]="registarModel.dataFimVinculoCessacao" [properties]="{disabled: !registarModel.podeCessar}"></pl-edit-datepicker>
                              </edit>
                            </pl-group>
                          </div>

                          <div class="rhregistarempregadosss-filter-group mb-2">
                            <label><span [translate]="'rhregistarempregadosss.strings.motivo'"></span>:</label>
                            <pl-group>
                              <edit>
                                <pl-edit-select
                                  [attrName]="'motivoCtrSS'"
                                  [model]="registarModel.motivoCessacaoEnum"
                                  (modelChange)="motivoCessacaoChanged($event)"
                                  [properties]="{
                                    select: {list: motivoCessacaoList},
                                    disallowClear: true,
                                    disabled: !registarModel.podeCessar,
                                    validators: {required: {value: registarModel.podeCessar}}
                                  }">
                                </pl-edit-select>
                              </edit>
                            </pl-group>
                          </div>

                          <div class="rhregistarempregadosss-filter-group mb-2">
                            <pl-group>
                              <edit>
                                <pl-edit
                                  type="checkbox"
                                  [model]="registarModel.comunicaDesemprego"
                                  (modelChange)="comunicaDesempregoChanged($event)"
                                  [properties]="{label: 'rhregistarempregadosss.strings.comunicaDesemprego', disabled: !registarModel.podeCessar}"></pl-edit>
                              </edit>
                            </pl-group>
                          </div>

                          <div class="rhregistarempregadosss-filter-group">
                            <label><span [translate]="'rhregistarempregadosss.strings.fundamentacao'"></span>:</label>
                            <pl-group>
                              <edit>
                                <pl-edit type="text" [(model)]="registarModel.fundamentacao" [properties]="{disabled: !ui.fundamentacaoCessacaoEnabled}"></pl-edit>
                              </edit>
                            </pl-group>
                          </div>
                        </div>
                        <div class="card-footer text-end">
                          <pl-button type="button" klass="btn-success btn-sm" [onClick]="fnDoCessarEmpregado" [disabled]="!registarModel.podeCessar">
                            <i class="fa fa-check"></i>&nbsp;<span [translate]="'rhregistarempregadosss.registarEmpregadosCessarTabTitle'"></span>
                          </pl-button>
                        </div>
                      </div>
                    </div>
                  </pl-tab>
                  <pl-tab [id]="registarEmpregadosPerioRendTabId" caption="rhregistarempregadosss.registarEmpregadosPerioRendTabTitle" *ngIf="registarModel.podeVerRegistarPeriodoRendimento">
                    <div *plTabContent>
                      <div class="card">
                        <div class="card-header">
                          <span [translate]="'rhregistarempregadosss.strings.registarPerioRendSS'"></span>
                        </div>
                        <div class="card-body">
                          <div class="rhregistarempregadosss-filter-group mb-2">
                            <label><span [translate]="'rhregistarempregadosss.strings.dataNovoRegistoPeriodo'"></span>:</label>
                            <pl-group style="max-width: 150px">
                              <edit>
                                <pl-edit-datepicker [(model)]="registarModel.ssDataNovoRegistoPeriodo" [properties]="{disabled: !registarModel.podeRegistarPeriodoRendimento}"></pl-edit-datepicker>
                              </edit>
                            </pl-group>
                          </div>

                          <div class="rhregistarempregadosss-filter-group mb-2">
                            <label><span [translate]="'rhregistarempregadosss.strings.vencimentoRegistoPeriodo'"></span>:</label>
                            <pl-group style="max-width: 150px">
                              <edit>
                                <pl-edit type="currency" [(model)]="registarModel.ssVencimentoRegistoPeriodo" [properties]="{disabled: !registarModel.podeRegistarPeriodoRendimento}"></pl-edit>
                              </edit>
                            </pl-group>
                          </div>

                          <div class="rhregistarempregadosss-filter-group mb-2">
                            <label><span [translate]="'rhregistarempregadosss.strings.diuturnidadeRegistoPeriodo'"></span>:</label>
                            <pl-group style="max-width: 150px">
                              <edit>
                                <pl-edit type="currency" [(model)]="registarModel.ssDiuturnidadeRegistoPeriodo" [properties]="{disabled: !registarModel.podeRegistarPeriodoRendimento}"></pl-edit>
                              </edit>
                            </pl-group>
                          </div>
                        </div>
                        <div class="card-footer text-end">
                          <pl-button type="button" klass="btn-success btn-sm" [onClick]="fnDoRegistarPeriodoRendimento" [disabled]="!registarModel.podeRegistarPeriodoRendimento">
                            <i class="fa fa-check"></i>&nbsp;<span [translate]="'rhregistarempregadosss.strings.registar'"></span>
                          </pl-button>
                        </div>
                      </div>
                    </div>
                  </pl-tab>
                </pl-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
