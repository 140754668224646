import {Type} from '@angular/core';
import {ParamDeclaration, ProviderLike} from '@uirouter/core';
import {Ng2StateDeclaration} from '@uirouter/angular';
import {IPlNavbarMenu, IPlSidebarMenu} from 'pl-comps-angular';
import {EPortal} from '../../../common/enums/portals.enums';
import {IJsonPortal} from '../../entities/portal/jsonPortal.entity.interface';
import {IModuleHelperLink} from '../../components/module/module.definition.interface';
import {ModuloComponent} from '../../components/module/module.component';
import {ROLE} from '../role.const';

export type TStatesMap = Map<string, ICGStateDeclaration>;

export interface ICGStateDeclaration extends Ng2StateDeclaration {
  urlSuffix?: string;
  data?: ICGStateDeclarationData;
  params?: ICGStateDeclarationParams;
  component?: Type<ModuloComponent>;
  resolve?: Array<ProviderLike>;
}

export interface ICGStateDeclarationData {
  [key: string]: unknown;

  roles?: Array<ROLE>;
  requiredRoles?: Array<ROLE>;
  pluginsRoles?: Array<ROLE>;
  icon?: string;
  pageTitle?: string;
  sidebarTitle?: string;
  disableRecover?: boolean;
  helperLinks?: IModuleHelperLink | Array<IModuleHelperLink>;
  invisible?: boolean;
  _moduleName?: string;
  _entityName?: string;
  _component?: Type<ModuloComponent>;
}

export interface ICGStateDeclarationParams {
  [paramName: string]: ParamDeclaration;
}

export interface IPortalState {
  state: IPortalStateDeclaration;
  states?: IPortalStates;
  menu: IPortalSidebarMenu;
}

export interface IPortalStateDeclaration extends ICGStateDeclaration {
  component?: Type<ModuloComponent>;
  data?: IPortalStateDeclarationData;
}

export interface IPortalStateDeclarationData extends ICGStateDeclarationData {
  portalId?: string;
  portalUrl?: EPortal;
  portalStateName?: string;
  menu?: Array<IPortalSidebarMenu>;
}

export interface IPortalStates {
  list: ICGStateDeclaration;
  new: ICGStateDeclaration;
  detail: ICGStateDeclaration;
}

export interface IPortalNavbarMenu<T = unknown> extends IPlNavbarMenu {
  menu?: Array<IPortalNavbarMenu<T>>;
  stickyPortal?: boolean;
  state?: string;
  portal?: IJsonPortal;
  data?: T;
}

export interface IPortalSidebarMenu extends IPlSidebarMenu<void> {
  state?: string; // Either state of menu must be defined
  menu?: Array<IPortalSidebarMenu>; // Menu takes precedence over state
  visible?: boolean;
}

export interface IActivePortal {
  readonly portal: EPortal;
  readonly portalId: string;
  readonly portalTitle: string;
  readonly stateName: string;
}

export const STATE_NAME_PORTAL = 'portal';

export const FUTURE_STATE_NAME_PORTAL = `${STATE_NAME_PORTAL}.**`;

export const NAVBAR_ITEM_ID_CHANGE_PORTAL = STATE_NAME_PORTAL;
