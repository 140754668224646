<div class="rhimpvenct entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form>
        <pl-group>
          <pl-group>
            <label [translate]="'global.text.listing'"></label>
            <edit>
              <pl-edit type="reports_rh_imp_venct" attrName="listagem" [(model)]="report"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <pl-group>
              <label [translate]="'rhimpvenct.fields.dataProcesDE'"></label>
              <edit>
                <pl-edit type="date" [(model)]="header.dataProcesDe">
                  <div *inputGroup="{klass: 'hollow-form dropdown-processamento'}">
                    <div ngbDropdown>
                      <button type="button" class="btn btn-secondary btn-sm" ngbDropdownToggle data-capture-click></button>
                      <div ngbDropdownMenu class="rhimpvenct-dropdown">
                        <button *ngFor="let date of header.listaDateDistinct" type="button" ngbDropdownItem (click)="dateDropDownChanged(date.value, true)">{{ date.value | cgcDate }}</button>
                      </div>
                    </div>
                  </div>
                </pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'global.text.to'"></label>
              <edit>
                <pl-edit type="date" [(model)]="header.dataProcesAte">
                  <div *inputGroup="{klass: 'hollow-form'}">
                    <pl-tooltip *ngIf="!promiseProcessamento" [config]="tooltipExcepcoesProcessamento">
                      <i *plTooltipContent class="fa fa-ban text-danger" aria-hidden="true" plDelegateEventDownwardsClick (click)="openExclusionsProcessamento()"></i>
                    </pl-tooltip>
                    <i class="fa fa-spinner fa-pulse fa-fw" aria-hidden="true" *ngIf="promiseProcessamento"></i>
                  </div>
                  <div *inputGroup="{klass: 'hollow-form dropdown-processamento'}">
                    <div ngbDropdown>
                      <button type="button" class="btn btn-secondary btn-sm" ngbDropdownToggle data-capture-click></button>
                      <div ngbDropdownMenu class="rhimpvenct-dropdown">
                        <button *ngFor="let date of header.listaDateDistinct" type="button" ngbDropdownItem (click)="dateDropDownChanged(date.value, false)">{{ date.value | cgcDate }}</button>
                      </div>
                    </div>
                  </div>
                </pl-edit>
              </edit>
            </pl-group>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'rhimpvenct.fields.doCodNum'"></label>
            <edit>
              <entity-autocomplete entity="dgempsfull" attrName="doCodNum" [model]="header" (selectedKeyChange)="header.doCodNum = $event" [fieldsMap]="{codEmp: 'doCodNum'}" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="dgempsfull" attrName="ateCodNum" [model]="header" (selectedKeyChange)="header.ateCodNum = $event" [fieldsMap]="{codEmp: 'ateCodNum'}" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'rhimpvenct.fields.deZona'"></label>
            <edit>
              <entity-autocomplete entity="zonas" attrName="deZona" [model]="header" (selectedKeyChange)="header.deZona = $event" [fieldsMap]="{nome: 'deZona'}" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="zonas" attrName="ateZona" [model]="header" (selectedKeyChange)="header.ateZona = $event" [fieldsMap]="{nome: 'ateZona'}" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'rhimpvenct.fields.deDepto'"></label>
            <edit>
              <entity-autocomplete entity="depto" attrName="deDepto" [model]="header" (selectedKeyChange)="header.deDepto = $event" [fieldsMap]="{nDepto: 'deDepto'}" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="depto" attrName="ateDepto" [model]="header" (selectedKeyChange)="header.ateDepto = $event" [fieldsMap]="{nDepto: 'ateDepto'}" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'rhimpvenct.fields.deSubDepto'"></label>
            <edit>
              <entity-autocomplete entity="subde" attrName="deSubDepto" [model]="header" (selectedKeyChange)="header.deSubDepto = $event" [fieldsMap]="{nSubde: 'deSubDepto'}" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="subde" attrName="ateSubDepto" [model]="header" (selectedKeyChange)="header.ateSubDepto = $event" [fieldsMap]="{nSubde: 'ateSubDepto'}" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'rhimpvenct.fields.deCCusto'"></label>
            <edit>
              <entity-autocomplete
                entity="ccustos"
                attrName="deCCusto"
                [model]="header"
                (selectedKeyChange)="header.deCCusto = $event"
                [fieldsMap]="{nCCusto: 'deCCusto'}"
                output="key"
                [filter]="'encerrado=0'">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="ccustos"
                attrName="ateCCusto"
                [model]="header"
                (selectedKeyChange)="header.ateCCusto = $event"
                [fieldsMap]="{nCCusto: 'ateCCusto'}"
                output="key"
                [filter]="'encerrado=0'">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'rhimpvenct.fields.deAbono'"></label>
            <edit>
              <entity-autocomplete entity="abonos" [model]="header" (selectedKeyChange)="header.deAbono = $event" [fieldsMap]="{codAbDesc: 'deAbono'}" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="abonos" [model]="header" (selectedKeyChange)="header.ateAbono = $event" [fieldsMap]="{codAbDesc: 'ateAbono'}" output="key">
                <div *inputGroup="{klass: 'hollow-form'}" class="complementosTooltipDisplay">
                  <pl-tooltip [config]="tooltipExcepcoesAbono">
                    <i *plTooltipContent class="fa fa-ban text-danger" aria-hidden="true" plDelegateEventDownwardsClick (click)="openExclusions('abono')"></i>
                  </pl-tooltip>
                </div>
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'rhimpvenct.fields.deDesconto'"></label>
            <edit>
              <entity-autocomplete entity="descontos" [model]="header" (selectedKeyChange)="(header.deDesconto)" [fieldsMap]="{codAbDesc: 'deDesconto'}" output="key"></entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="descontos" [model]="header" (selectedKeyChange)="(header.ateDesconto)" [fieldsMap]="{codAbDesc: 'ateDesconto'}" output="key">
                <div *inputGroup="{klass: 'hollow-form'}" class="complementosTooltipDisplay">
                  <pl-tooltip [config]="tooltipExcepcoesDesconto">
                    <i *plTooltipContent class="fa fa-ban text-danger" aria-hidden="true" plDelegateEventDownwardsClick (click)="openExclusions('desconto')"></i>
                  </pl-tooltip>
                </div>
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'rhimpvenct.fields.deSeccao'"></label>
            <edit>
              <entity-autocomplete entity="seccoes" attrName="deSeccao" [model]="header" (selectedKeyChange)="header.deSeccao = $event" [fieldsMap]="{nSeccao: 'deSeccao'}" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete
                entity="seccoes"
                attrName="ateSeccao"
                [model]="header"
                (selectedKeyChange)="header.ateSeccao = $event"
                [fieldsMap]="{nSeccao: 'ateSeccao'}"
                output="key"
                [properties]="{events: {keydown: fnKeydownProcessar}}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-multiselect [model]="empresas" [source]="empresasSource" [template]="empresasTemplate" key="nEmpresa"></pl-multiselect>
      </pl-form>
    </div>
  </cg-card-panel>

  <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
</div>
