import dxDataGrid from 'devextreme/ui/data_grid';
import {Component, EventEmitter, Injector, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  IPlNavWizardCallback,
  IPlNavWizardDefinition,
  IPlNavWizardEventBeforeChange,
  IPlNavWizardEventStep,
  IPlNavWizardInstance,
  IPlNavWizardOptions,
  IPlNavWizardStep,
  IPlNavWizardStepAction,
  isArray,
  isDefinedNotNull,
  isUndefined,
  isUndefinedOrNull,
  PlAlertService,
  PlTranslateService
} from 'pl-comps-angular';
import {HttpResponse} from '@angular/common/http';
import {StateService} from '@uirouter/core';
import {IJsonDocComercial, IJsonDocComercialLinha} from '../../../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {IJsonTrDocLinha, IJsonTrDocLinhaLoteSel} from '../../../../../modules/trdocs/jsonTrDoc.module.interface';
import {EGrupoDoc, EGrupoDocType} from '../../../../../datasources/grupodoc/grupoDoc.datasource.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonArtigoLoteArmazemStock, IJsonLote} from '../../../../../entities/lotes/jsonLote.entity.interface';
import {DocumentosService} from '../../../../../entities/docscomerciais/service/documentos.entity.service';
import {GrupoDocService} from '../../../../../services/grupodoc/grupo.doc.service';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnFocusedCellChanged,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowPrepared,
  IDevExpressDataGridEventOnSelectionChanged,
  IDevExpressDataGridEventOnToolbarPreparing
} from '../../../../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonDocfa} from '../../../../../entities/docfas/jsonDocFa.entity.interface';
import moment from 'moment';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {ENTITY_NAME_LOTES} from '../../../../../entities/lotes/lotes.entity.interface';
import {TrDocsService} from '../../../../../modules/trdocs/service/trDocs.service';
import {IJsonArtigo} from '../../../../../entities/artigos/jsonArtigo.entity.interface';
import {ENTITY_NAME_ARTIGOS, IArtigosEntityService} from '../../../../../entities/artigos/artigos.entity.interface';
import {CUSTO_MEDIO_PONDERADO, FIFO} from '../../../../../datasources/criteriodisponiveis/criterioDisponiveis.datasource.interface';
import {generateUUID, round} from '../../../../../../common/utils/utils';
import {IJsonDocfaNum} from '../../../../../entities/docfasnums/jsonDocfaNum.entity.interface';
import {
  ETrDocsCabsWizardModoAgrupamento,
  ETrDocsCabsWizardNavSteps,
  IJsonTrDocsCabsWizard,
  IJsonTrDocsCabsWizardCabItem,
  IJsonTrDocsCabsWizardCabResumo,
  IJsonTrDocsCabsWizardFilter,
  ITrDocsCabsWizardConfigDocumento,
  ITrDocsCabsWizardIdentificacaoSourceItem,
  ITrDocsCabsWizardLotesHelper,
  ITrDocsDocComercialLinhaComLote
} from './trdocs.cabsWizard.interface';
import CustomStore from 'devextreme/data/custom_store';
import {ConfigService} from '../../../../../services/config/config.service';
import {ENTITY_NAME_DOC_FAS, IDocFasEntityService} from '../../../../../entities/docfas/docFas.entity.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {UntypedFormGroup} from '@angular/forms';
import {merge} from 'lodash-es';
import {IEntityMaintenanceInstance} from '../../../../entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../entity/maintenance/entity/entity.maintenance.service';
import {docsComerciaisGrupoDocToEntityName} from '../../../../../entities/docscomerciais/docsComerciais.entity.interface';
import {TrdocsCabsErrosModalComponent} from '../../modals/cabs/trdocs.cabs.erros.modal.component';
import {CGModalService} from '../../../../cg/modal/cgmodal.service';
import {IApiQueryResponse} from '../../../../../services/api/api.service.interface';
import {Properties} from 'devextreme/ui/button';

const TRANSFORMADO_CSS_CLASS = 'documento-transformado';

@Component({
  selector: 'trdocs-cabs-wizard-component',
  templateUrl: './trdocs.cabsWizard.component.html'
})
export class TrdocsCabsWizardComponent implements OnInit {
  @Input() public grupoDocfa: EGrupoDoc;
  @Input() public docType: EGrupoDocType;
  @Input() public embed: boolean;
  @Input() public wizardHideFooter: boolean;
  @Output() public readonly evtNavWizardSet: EventEmitter<IPlNavWizardInstance>;

  public readonly navWizardDefinition: IPlNavWizardDefinition;
  public readonly navWizardCallback: IPlNavWizardCallback;
  public readonly docfasOutput: string;
  public readonly stepsNavTrDocs: typeof ETrDocsCabsWizardNavSteps;
  public readonly docFaTemplate: string = '{{nDocFa}} - {{nome}}';
  public readonly docFaOutput: string = 'nome';
  public readonly modoAgrupamento: typeof ETrDocsCabsWizardModoAgrupamento;
  public readonly lotesHelper: ITrDocsCabsWizardLotesHelper;
  public readonly stepsNavTrDocsResumoActions: Array<IPlNavWizardStepAction>;

  public proceTrDoc: IJsonTrDocsCabsWizard;
  public docFaDestino: IJsonDocfa;
  public docFaSource: Array<IJsonDocfa>;
  public docfaNumFilter: string;
  public clifoDescription: string;
  public showLotes: boolean;
  public disableNConta: boolean;
  public dataGridCabsDefinition: IDevExpressDataGrid<IJsonTrDocsCabsWizardCabItem, number>;
  public dataGridResumoDefinition: IDevExpressDataGrid<IJsonDocComercial, number>;
  public dataGridLinhasLotesDefinition: IDevExpressDataGrid<IJsonDocComercialLinha, number>;
  public dataGridLotesDefinition: IDevExpressDataGrid<IJsonLote, number>;
  public dataGridIdentificacaoDefinition: IDevExpressDataGrid<ITrDocsCabsWizardConfigDocumento, number>;
  public propertiesNavWizard: IPlNavWizardOptions;
  public showObservacoes: boolean;
  public temLinhasTransformadas: boolean;
  public filterModel: IJsonTrDocsCabsWizardFilter;
  public isOnInit: boolean;
  public isFirstLoad: boolean;
  public wizardSelectedStep: IPlNavWizardStep;
  public formSearch: UntypedFormGroup;
  public formIdentificacao: UntypedFormGroup;
  public resumoSource: Array<IJsonDocComercial>;
  public resumoErrosSource: Array<string>;
  public docFaPromise: Promise<unknown>;
  public docFaReadOnly: boolean;
  public clifoTypeName: string;
  public modoAgrupamentoSource: Array<{value: ETrDocsCabsWizardModoAgrupamento; name: string}>;
  public isCompra: boolean;
  public nDocfaDestinoFilter: string;

  private readonly _lotesService: IEntityService<IJsonLote>;
  private readonly _artigosService: IEntityService<IJsonArtigo>;
  private readonly _docFaService: IDocFasEntityService;
  private readonly _nDecimaisQtd: number;
  private readonly _maintenanceLotes: IEntityMaintenanceInstance<IJsonLote>;

  private _maintenanceDocsComerciais: Map<EGrupoDoc, IEntityMaintenanceInstance<IJsonDocComercial>>;
  private _dataGridInstance: dxDataGrid;
  private _dataGridIdentificacaoInstance: dxDataGrid;
  private _dataGridLinhasLotesInstance: dxDataGrid;
  private _lotesLinha: Array<IJsonTrDocLinhaLoteSel>;
  private _dataGridLotesInstance: dxDataGrid;
  private _dataGridResumoInstance: dxDataGrid;
  private _rowIndex: number;
  private _pesquisaSource: Array<IJsonTrDocsCabsWizardCabItem>;
  private _qtdLotesAutoDistribuida: boolean;
  private _identificacaoSource: Array<ITrDocsCabsWizardIdentificacaoSourceItem>;
  private _isNewLoteToolbarDisabled: boolean;
  private _isNewLoteToolbarVisible: boolean;
  private readonly _btnHomePage: IPlNavWizardStepAction;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _stateService: StateService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _trdocsService: TrDocsService,
    private readonly _documentosService: DocumentosService,
    private readonly _grupoDoc: GrupoDocService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    protected readonly _configService: ConfigService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _cgModalService: CGModalService
  ) {
    this.modoAgrupamento = ETrDocsCabsWizardModoAgrupamento;
    this.isOnInit = true;
    this.isFirstLoad = true;
    this.wizardHideFooter = false;
    this.isCompra = false;
    this.evtNavWizardSet = new EventEmitter<IPlNavWizardInstance>();
    this._lotesService = this._entityServiceBuilder.build<IJsonLote>(ENTITY_NAME_LOTES);
    this._artigosService = this._entityServiceBuilder.build<IJsonArtigo, IArtigosEntityService>(ENTITY_NAME_ARTIGOS);
    this._docFaService = this._entityServiceBuilder.build(ENTITY_NAME_DOC_FAS);
    this._qtdLotesAutoDistribuida = false;
    this._identificacaoSource = [];
    this._maintenanceLotes = this._entityMaintenanceService.build<IJsonLote>(ENTITY_NAME_LOTES);
    this._isNewLoteToolbarDisabled = true;
    this._isNewLoteToolbarVisible = true;
    this.nDocfaDestinoFilter = '';

    this.lotesHelper = {
      lotesCache: new Map<string, Array<IJsonLote>>(),
      lotesArtigoArmazem: new Map<string, Array<IJsonTrDocLinhaLoteSel>>(),
      model: {
        currentIndex: 0,
        currentKeyValue: '',
        sources: new Map<string, Array<ITrDocsDocComercialLinhaComLote>>()
      }
    };
    this.resumoSource = [];
    this.resumoErrosSource = [];
    this._pesquisaSource = [];
    this.modoAgrupamentoSource = [];

    this._lotesLinha = [];

    this.docfasOutput = '{{nDocFa}} - {{nome}}';

    this.stepsNavTrDocs = ETrDocsCabsWizardNavSteps;
    this.navWizardDefinition = {
      items: []
    };
    this.navWizardCallback = {};
    this.proceTrDoc = {
      docsPesquisa: [],
      config: {
        documentos: [],
        encerraDocumentoDestino: false,
        encerraDocumentoOrigem: false,
        nDocfaDestino: undefined,
        nNumerDestino: undefined,
        modoAgrupamento: ETrDocsCabsWizardModoAgrupamento.CONTA
      }
    };
    this.showLotes = false;
    this.disableNConta = false;
    this.showObservacoes = false;

    this.temLinhasTransformadas = false;

    this.finalize = this.finalize.bind(this);

    this._nDecimaisQtd = this._configService.configurations.gestaoComercial.decimais.stocks.quantidades;

    this.dataGridCabsDefinition = {
      columnHidingEnabled: false,
      columns: [
        {
          name: 'transformadoIcon',
          caption: '',
          cellTemplate: 'transformadoCellTemplate',
          alignment: 'center',
          width: 48,
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSorting: false,
          showInColumnChooser: false
        },
        {dataField: 'nDoc', dataType: 'string', caption: 'trDocsCabsWizard.captions.documento'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'trDocsCabsWizard.captions.dataDoc'},
        {dataField: 'nConta', dataType: 'string', caption: 'trDocsCabsWizard.captions.nConta', groupIndex: 0},
        {dataField: 'nContaNome', dataType: 'string', caption: 'trDocsCabsWizard.captions.conta'},
        {dataField: 'nif', dataType: 'string', caption: 'trDocsCabsWizard.captions.nif'},
        {dataField: 'totalLiquido', dataType: 'double', caption: 'trDocsCabsWizard.captions.liquido'},
        {dataField: 'totalIva', dataType: 'double', caption: 'trDocsCabsWizard.captions.iva'},
        {dataField: 'totalDocumento', dataType: 'double', caption: 'trDocsCabsWizard.captions.totalDocumento'},
        {dataField: 'nCCusto', dataType: 'string', caption: 'trDocsCabsWizard.captions.nCCusto', visible: false},
        {dataField: 'nRefProcesso', dataType: 'string', caption: 'trDocsCabsWizard.captions.nRefProcesso', visible: false},
        {dataField: 'nFactFornec', dataType: 'string', caption: 'trDocsCabsWizard.captions.nFactFornec', visible: false}
      ],
      dataSource: new CustomStore({
        key: 'faccbId',
        load: () => this._cabsTableSource()
      }),
      selection: {mode: 'multiple', selectAllMode: 'allPages', showCheckBoxesMode: 'always'},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocumento'},
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'toolbarModoAgrupamento',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton',
          'searchPanel'
        ]
      }
    };

    this.dataGridResumoDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'cab.nDoc', dataType: 'string', caption: 'trDocsCabsWizard.captions.documento'},
        {dataField: 'cab.dataDoc', dataType: 'date', caption: 'trDocsCabsWizard.captions.dataDoc'},
        {dataField: 'cab.nConta', dataType: 'string', caption: 'trDocsCabsWizard.captions.nConta'},
        {dataField: 'cab.nome', dataType: 'string', caption: 'trDocsCabsWizard.captions.conta'},
        {dataField: 'cab.nif', dataType: 'string', caption: 'trDocsCabsWizard.captions.nif'},
        {dataField: 'cab.totalLiquido', dataType: 'double', caption: 'trDocsCabsWizard.captions.liquido'},
        {dataField: 'cab.totalIva', dataType: 'double', caption: 'trDocsCabsWizard.captions.iva'},
        {dataField: 'cab.totalDocumento', dataType: 'double', caption: 'trDocsCabsWizard.captions.totalDocumento'},
        {dataField: 'cab.nCCusto', dataType: 'string', caption: 'trDocsCabsWizard.captions.nCCusto', visible: false},
        {dataField: 'cab.nRefProcesso', dataType: 'string', caption: 'trDocsCabsWizard.captions.nRefProcesso', visible: false},
        {dataField: 'cab.nFactFornec', dataType: 'string', caption: 'trDocsCabsWizard.captions.nFactFornec', visible: false},
        {
          dataField: 'vf_actions',
          fixed: true,
          fixedPosition: 'right',
          caption: ' ',
          cellTemplate: 'cellTemplateActions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false,
          width: 100
        }
      ],
      dataSource: new CustomStore({
        key: 'cab.faccbId',
        load: () => this._resumoTableSource()
      }),
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateBtnToolbar',
            locateInMenu: 'auto'
          }
        ]
      },
      filterRow: {visible: false},
      headerFilter: {visible: false},
      masterDetail: {enabled: true, autoExpandAll: false, template: 'detailTemplateDocumento'}
    };

    this.dataGridLinhasLotesDefinition = {
      columnHidingEnabled: false,
      height: '40vh',
      columns: [
        {dataField: 'nDoc', dataType: 'string', caption: 'trDocsCabsWizard.captions.documento', allowEditing: false},
        {dataField: 'nSeq', dataType: 'number', caption: 'docscomerciais.doc.linhas.nSeq', allowEditing: false},
        {dataField: 'nArtigo', dataType: 'string', caption: 'docscomerciais.doc.linhas.nArtigo', allowEditing: false},
        {dataField: 'nomeArtigo', dataType: 'string', caption: 'docscomerciais.doc.linhas.nome', allowEditing: false},
        {dataField: 'nArmazem', dataType: 'string', caption: 'docscomerciais.doc.linhas.nArmazem', allowEditing: false},
        {dataField: 'prVenda1', dataType: 'double', caption: 'docscomerciais.doc.linhas.preco', allowEditing: false},
        {dataField: 'qtd1', dataType: 'number', caption: 'docscomerciais.doc.linhas.qtd', allowEditing: false},
        {dataField: 'taxaIva', dataType: 'number', caption: 'docscomerciais.doc.linhas.taxaIva', allowEditing: false},
        {dataField: 'valorIva', dataType: 'number', caption: 'docscomerciais.doc.linhas.valorIva', allowEditing: false},
        {dataField: 'prVendaIvaInc', dataType: 'number', caption: 'docscomerciais.doc.linhas.valorComIva', allowEditing: false}
      ],
      dataSource: [],
      keyExpr: 'facliId',
      filterRow: {visible: false},
      headerFilter: {visible: false},
      masterDetail: {
        enabled: true,
        autoExpandAll: false,
        template: 'masterDetailGridLinhasLotes'
      }
    };

    this.dataGridIdentificacaoDefinition = {
      filterRow: {visible: false},
      allowColumnReordering: false,
      columnHidingEnabled: true,
      columnChooser: {mode: 'select'},
      columns: [
        {dataField: 'nConta', dataType: 'string', caption: 'trDocsCabsWizard.captions.nConta', allowEditing: false, visible: false, showInColumnChooser: false},
        {dataField: 'nDoc', dataType: 'string', caption: 'trDocsCabsWizard.captions.documento', allowEditing: false},
        {dataField: 'dataDoc', dataType: 'date', caption: 'trDocsCabsWizard.captions.dataDoc', allowEditing: true, showEditorAlways: true},
        {dataField: 'nFactFornec', dataType: 'string', caption: 'trDocsCabsWizard.captions.nFactFornec', allowEditing: true, showEditorAlways: true},
        {dataField: 'dataDocExterno', dataType: 'date', caption: 'trDocsCabsWizard.captions.dataDocExterno', allowEditing: true, showEditorAlways: true},
        {
          dataField: 'referenciaTextoBase',
          dataType: 'string',
          caption: 'trDocsCabsWizard.captions.texto',
          allowEditing: true,
          editCellTemplate: 'editCellTemplateTexto',
          showEditorAlways: true,
          minWidth: 300
        }
      ],
      dataSource: new CustomStore({
        key: 'id',
        load: () => this._identificacaoTableSource(),
        update: (id: string, values: Partial<ITrDocsCabsWizardIdentificacaoSourceItem>) => {
          const record: ITrDocsCabsWizardIdentificacaoSourceItem = this._identificacaoSource.find((item: ITrDocsCabsWizardIdentificacaoSourceItem) => item.id === id);
          if (record) {
            merge(record, values);
          }
          return Promise.resolve();
        }
      }),
      editing: {allowUpdating: true, mode: 'cell', selectTextOnEditStart: true},
      remoteOperations: false,
      keyboardNavigation: {
        enabled: true,
        enterKeyAction: 'moveFocus',
        enterKeyDirection: 'column'
      }
    };

    this.dataGridLotesDefinition = {
      allowColumnReordering: false,
      columnHidingEnabled: true,
      columnChooser: {mode: 'select'},
      columns: [
        {dataField: 'nLote', dataType: 'number', caption: 'lotes.fields.nLote', visible: false, allowEditing: false, showInColumnChooser: false},
        {dataField: 'nLoteEspecifico', dataType: 'string', caption: 'lotes.fields.nLoteEspecifico', allowEditing: false, showInColumnChooser: false},
        {dataField: 'dataFabrico', dataType: 'date', caption: 'lotes.fields.dataFabrico', visible: false, allowEditing: false},
        {dataField: 'dataValidade', dataType: 'date', caption: 'lotes.fields.dataValidade', visible: false, allowEditing: false},
        {dataField: 'qtdStock', dataType: 'double', caption: 'docscomerciais.doc.linhas.qtdStock', visible: true, format: {decimalsLimit: this._nDecimaisQtd}, allowEditing: false},
        {dataField: 'qtd', dataType: 'double', caption: 'docscomerciais.doc.linhas.qtd', format: {decimalsLimit: this._nDecimaisQtd}, showEditorAlways: true, showInColumnChooser: false}
      ],
      keyExpr: 'nLote',
      filterRow: {visible: false},
      editing: {allowUpdating: true, mode: 'cell', selectTextOnEditStart: true},
      export: {enabled: false},
      remoteOperations: false,
      keyboardNavigation: {
        enabled: true,
        enterKeyAction: 'moveFocus',
        enterKeyDirection: 'column'
      }
    };

    this.propertiesNavWizard = {
      disableNextStep: false
    };

    this.filterModel = {
      onlyPossibleToTransform: true,
      nDocFaList: [],
      nConta: '',
      dataDoc: moment().subtract(1, 'year'),
      dataDocAte: moment()
    };
    this.docFaPromise = Promise.resolve();
    this.docFaReadOnly = true;

    this._btnHomePage = {
      caption: 'trDocsCabsWizard.captions.homePage',
      visible: true,
      class: 'btn-success',
      icon: 'fa fa-fw fa-home',
      click: () => {
        return this._homePage();
      }
    };

    this.stepsNavTrDocsResumoActions = [
      {
        caption: 'trDocsCabsWizard.captions.encerrarDocumentos',
        visible: true,
        class: 'btn-primary me-2',
        icon: 'fa fa-fw fa-check-circle-o',
        click: () => {
          return this._encerrarTodosDocumentos();
        }
      },
      this._btnHomePage
    ];
    this.clifoTypeName = this._plTranslateService.translate('trDocsCabsWizard.captions.nConta');
  }

  public ngOnInit(): void {
    const queryString: string = this.grupoDocfa ? `grupoDocfa=${this.grupoDocfa}` : '';
    if (isDefinedNotNull(this.grupoDocfa)) {
      this.clifoTypeName =
        this.docType === EGrupoDocType.Venda ? this._plTranslateService.translate('trDocsCabsWizard.captions.cliente') : this._plTranslateService.translate('trDocsCabsWizard.captions.fornecedor');
      this.nDocfaDestinoFilter = this._getDocfaFilter();
    }
    this.docFaPromise = this._docFaService.query({pesquisa: queryString}).then((response: HttpResponse<IApiQueryResponse<IJsonDocfa>>) => {
      this.docFaSource = response.body.list;
      if (isDefinedNotNull(this.grupoDocfa)) {
        this.filterModel.nDocFaList = this.docFaSource.map((item) => item.nDocFa);
      }
      this.docFaReadOnly = false;
    });
    this.modoAgrupamentoSource = [
      {value: ETrDocsCabsWizardModoAgrupamento.CONTA, name: this._plTranslateService.translate('trDocsCabsWizard.captions.agruparPorCliente', {name: this.clifoTypeName})},
      {value: ETrDocsCabsWizardModoAgrupamento.DOCUMENTO, name: 'trDocsCabsWizard.captions.naoAgrupar'}
    ];
    if (this.grupoDocfa) {
      this.isCompra = this._grupoDoc.isCompra(this.grupoDocfa) || this._grupoDoc.isEntradaDiversa(this.grupoDocfa);
    }
    this._isNewLoteToolbarVisible = this.isCompra;
  }

  public nDocFaSearchFn(searchTerm: string, item: IJsonDocfa): boolean {
    return item.nDocFa.toString().includes(searchTerm) || item.nome.toLowerCase().includes(searchTerm.toLowerCase());
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
  }

  public onGridIdentificacaoInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridIdentificacaoInstance = component;
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();
    if (this.isFirstLoad) {
      this._dataGridInstance.clearGrouping();
      this._dataGridInstance.columnOption(this.proceTrDoc.config.modoAgrupamento === ETrDocsCabsWizardModoAgrupamento.CONTA ? 'nConta' : 'nDoc', 'groupIndex', 0);
      this._dataGridInstance.selectAll();
      const invalidSelected: Array<number> = this._pesquisaSource.filter((item: IJsonTrDocsCabsWizardCabItem) => item.transformado).map((item: IJsonTrDocsCabsWizardCabItem) => item.faccbId);
      if (invalidSelected.length) {
        this._dataGridInstance.deselectRows(invalidSelected).then(() => undefined);
      }
      this.isFirstLoad = false;
    }
  }

  public onGridIdentificacaoContentReady(): void {
    this._dataGridIdentificacaoInstance.endCustomLoading();
    this._dataGridIdentificacaoInstance.columnOption('nConta', 'visible', this.proceTrDoc.config.modoAgrupamento === ETrDocsCabsWizardModoAgrupamento.CONTA);
    this._dataGridIdentificacaoInstance.columnOption('nConta', 'showInColumnChooser', this.proceTrDoc.config.modoAgrupamento === ETrDocsCabsWizardModoAgrupamento.CONTA);
    this._dataGridIdentificacaoInstance.columnOption('nDoc', 'visible', this.proceTrDoc.config.modoAgrupamento === ETrDocsCabsWizardModoAgrupamento.DOCUMENTO);
    this._dataGridIdentificacaoInstance.columnOption('nDoc', 'showInColumnChooser', this.proceTrDoc.config.modoAgrupamento === ETrDocsCabsWizardModoAgrupamento.DOCUMENTO);
  }

  public finalize(): Promise<void> {
    this.isOnInit = true;
    this.isFirstLoad = true;
    this._pesquisaSource = [];
    this.navWizardCallback.setStep(ETrDocsCabsWizardNavSteps.Pesquisa, true, true);
    return Promise.resolve();
  }

  public onDocFaChanged(docFa: IJsonDocfa): void {
    if (!docFa || isUndefined(docFa.nDocFa) || !this.proceTrDoc) {
      return;
    }

    this.proceTrDoc.config.nDocfaDestino = docFa.nDocFa;
    this.docfaNumFilter = `nDocFa=${docFa.nDocFa}&encerrado=0&visivelERPCloud=1`;
    this.docFaDestino = docFa;
    this.proceTrDoc.config.nNumerDestino = this._getNNumerDefeitoDocfa(this.docFaDestino.docfanumList);
    this._verificaEValidaDocsSeLinhasTemLotes();
  }

  public onGridLinhasLotesInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridLinhasLotesInstance = component;
  }

  public onGridLinhasLotesContentReady(): void {
    this._dataGridLinhasLotesInstance.endCustomLoading();
  }

  public onGridLinhasLotesSelectionChanged(event: IDevExpressDataGridEventOnSelectionChanged<IJsonTrDocLinhaLoteSel>): void {
    this._isNewLoteToolbarDisabled = !event.currentSelectedRowKeys.length;
    if (event.currentSelectedRowKeys.length === 0) {
      this.dataGridLotesDefinition.dataSource = [];
      return;
    }
    const linha = this._getSelLinhaLote();
    this._getLotesTableSource(linha).then((list: Array<IJsonTrDocLinhaLoteSel>) => {
      this.dataGridLotesDefinition.dataSource = list;
    });
    this._dataGridLotesInstance.repaint();
  }

  public onGridLotesToolbarPreparing(event: IDevExpressDataGridEventOnToolbarPreparing): void {
    event.toolbarOptions.items.unshift({
      location: 'after',
      widget: 'dxButton',
      options: {
        visible: this._isNewLoteToolbarVisible,
        disabled: this._isNewLoteToolbarDisabled,
        icon: 'add',
        hint: this._plTranslateService.translate('trDocsCabsWizard.tooltips.criarLoteParaArtigo'),
        onClick: () => this._showMaintenanceLote()
      } satisfies Properties
    });
  }

  public preencheAutomaticamenteLotes(): Promise<void> {
    return this._preencheAutomaticamenteLotes();
  }

  public onGridLotesInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridLotesInstance = component;
    this._dataGridLotesInstance.columnOption('qtd', 'showEditorAlways', true);
    this._dataGridLotesInstance.columnOption('qtdStock', 'visible', true);
  }

  public onGridResumoInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridResumoInstance = component;
  }

  public onGridLotesContentReady(): void {
    this._dataGridLotesInstance.endCustomLoading();
    if (this._lotesLinha.length > 0) {
      this._dataGridLotesInstance.columnOption('qtd', 'showEditorAlways', true);
      this._dataGridLotesInstance.editCell(this._rowIndex ? this._rowIndex : 0, 'qtd');
    }
  }

  public onGridLotesFocusedCellChanged(event: IDevExpressDataGridEventOnFocusedCellChanged<IJsonTrDocLinhaLoteSel>): void {
    this._rowIndex = event.row.rowIndex ? event.row.rowIndex : 0;
  }

  public stepChanged({currentStep}: IPlNavWizardEventStep): void {
    if (currentStep.stepId === ETrDocsCabsWizardNavSteps.Resumo) {
      for (const item of this.navWizardDefinition.items) {
        if (item.stepId !== ETrDocsCabsWizardNavSteps.Resumo) {
          item.disabled = true;
        }
      }
      currentStep.hidePrevious = true;
      this._btnHomePage.visible = this.embed;
    } else if (currentStep.stepId === ETrDocsCabsWizardNavSteps.Lotes) {
      this._setModelSourceIndex(0);
    }
  }

  public onDataGridRowPrepared({data, rowElement, rowType}: IDevExpressDataGridEventOnRowPrepared<IJsonTrDocsCabsWizardCabItem>): void {
    if (rowType === 'data') {
      if (data.transformado) {
        rowElement.classList.add(TRANSFORMADO_CSS_CLASS);
      }
    }
  }

  public applyVariable(variable: string, item: ITrDocsCabsWizardConfigDocumento): void {
    if (item.referenciaTextoBase) {
      item.referenciaTextoBase += variable;
    } else {
      item.referenciaTextoBase = variable;
    }
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonTrDocsCabsWizardCabItem>): void {
    devExpressDataGridExpandDetailHandler(event, () => this._onDetail(event.row.data));
  }

  public onModoAgrupamentoChange(value: ETrDocsCabsWizardModoAgrupamento): void {
    this.proceTrDoc.config.modoAgrupamento = value;
    this._dataGridInstance.clearGrouping();
    this._dataGridInstance.columnOption(value === ETrDocsCabsWizardModoAgrupamento.CONTA ? 'nConta' : 'nDoc', 'groupIndex', 0);
  }

  public getBtnGoForwardCaption(): string {
    return this.showLotes ? 'trDocsCabsWizard.captions.avancar' : 'trDocsCabsWizard.captions.processar';
  }

  public nContaChange(nConta: string): void {
    this.filterModel.nConta = nConta;
  }

  public lotesNavFirst(): void {
    this._setModelSourceIndex(0);
    this._checkAndShowErrorsLotes(this.lotesHelper.model.sources.get(this.lotesHelper.model.currentKeyValue), 0);
  }

  public lotesNavPrev(): void {
    const sourceIndex = this.lotesHelper.model.currentIndex - 1;
    this._setModelSourceIndex(sourceIndex);
    this._checkAndShowErrorsLotes(this.lotesHelper.model.sources.get(this.lotesHelper.model.currentKeyValue), sourceIndex);
  }

  public lotesNavNext(): void {
    const sourceIndex = this.lotesHelper.model.currentIndex + 1;
    this._setModelSourceIndex(sourceIndex);
    this._checkAndShowErrorsLotes(this.lotesHelper.model.sources.get(this.lotesHelper.model.currentKeyValue), sourceIndex);
  }

  public lotesNavLast(): void {
    const sourceIndex = this.lotesHelper.model.sources.size - 1;
    this._setModelSourceIndex(sourceIndex);
    this._checkAndShowErrorsLotes(this.lotesHelper.model.sources.get(this.lotesHelper.model.currentKeyValue), sourceIndex);
  }

  public getLotesNavKeyCaption(): string {
    return this.proceTrDoc.config.modoAgrupamento === ETrDocsCabsWizardModoAgrupamento.DOCUMENTO ? 'trDocsCabsWizard.captions.documento' : 'trDocsCabsWizard.captions.nConta';
  }

  public editarDocumento(doc: IJsonDocComercial): Promise<void> {
    if (!this._maintenanceDocsComerciais) {
      this._maintenanceDocsComerciais = new Map<EGrupoDoc, IEntityMaintenanceInstance<IJsonDocComercial>>();
    }
    const grupoDocfa: EGrupoDoc = doc.cab.grupoDocfa;
    let maintenanceDocsComerciais = this._maintenanceDocsComerciais.get(grupoDocfa);
    if (!maintenanceDocsComerciais) {
      const entityName: string = docsComerciaisGrupoDocToEntityName(grupoDocfa);
      maintenanceDocsComerciais = this._entityMaintenanceService.build<IJsonDocComercial>(entityName);
      this._maintenanceDocsComerciais.set(grupoDocfa, maintenanceDocsComerciais);
    }
    return maintenanceDocsComerciais.maintenanceEdit(doc.cab.faccbId).then((updatedDoc: IJsonDocComercial) => {
      const itemIndex: number = this.resumoSource.findIndex((item: IJsonDocComercial) => item.cab.faccbId === updatedDoc.cab.faccbId);
      this.resumoSource[itemIndex] = updatedDoc;
      this._dataGridResumoInstance.refresh();
    });
  }

  public encerrarDocumento(faccbId: number): Promise<void> {
    return this._trdocsService.encerrarDocumentos([faccbId]).then((response: HttpResponse<Array<IJsonDocComercial>>) => {
      const itemIndex: number = this.resumoSource.findIndex((item: IJsonDocComercial) => item.cab.faccbId === response.body[0].cab.faccbId);
      this.resumoSource[itemIndex] = response.body[0];
      this._checkVisibilityEncerrarTodosButton();
      this._dataGridResumoInstance.refresh();
    });
  }

  public onCellPreparedDataGridLinhasLotes(event: IDevExpressDataGridEventOnCellPrepared<ITrDocsDocComercialLinhaComLote, string>): void {
    if (event.rowType === 'data') {
      if (event.column.command === 'expand' && !event.data.error) {
        (<HTMLElement>event.cellElement.childNodes[0]).classList.remove('dx-datagrid-group-closed');
        event.cellElement.classList.remove('dx-datagrid-expand');
      }
      if (event.data.error) {
        event.cellElement.classList.add('text-danger');
      }
    }
  }

  public fnBeforeChangedStep = (event: IPlNavWizardEventBeforeChange): Promise<boolean> => this._beforeChangedStep(event);

  public readonly fnSearch = (): Promise<void> => this._search();

  public readonly fnGetPdf = (doc: IJsonDocComercial): Promise<void> => this._getPdf(doc);

  public readonly fnGetDoc: (id: number) => Promise<IJsonDocComercial> = (id: number) => this._getDoc(id);

  public readonly fnShowResumoErrosModal = (): Promise<void> => this._showResumoErrosModal();

  @ViewChild('navWizard')
  public set navWizard(value: IPlNavWizardInstance) {
    this.evtNavWizardSet.emit(value);
  }

  private _onDetail(documento: IJsonTrDocsCabsWizardCabItem): Promise<void> {
    return this._documentosService.getDoc(documento.faccbId).then((response: HttpResponse<IJsonDocComercial>) => {
      documento._fullJsonDocComercial = response.body;
    });
  }

  private _beforeChangedStep(event: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (event.type === 'previous') {
      return Promise.resolve(true);
    } else if (event.type === 'set') {
      const currentIndex: number = this.navWizardDefinition.items.findIndex((step: IPlNavWizardStep) => step.stepId === event.currentStep.stepId);
      const nextIndex: number = this.navWizardDefinition.items.findIndex((step: IPlNavWizardStep) => step.stepId === event.nextStep.stepId);
      if (nextIndex < currentIndex) {
        return Promise.resolve(event.currentStep.stepId !== this.stepsNavTrDocs.Resumo);
      }
    }
    if (event.currentStep.stepId === ETrDocsCabsWizardNavSteps.Pesquisa) {
      const selectedPesqDocs: Array<IJsonTrDocsCabsWizardCabItem> = this._dataGridInstance.getSelectedRowsData();

      if (selectedPesqDocs.length === 0) {
        this._plAlertService.error('trdoc.modal.linhas.messages.naoTemLinhasSelecionadas');
        return Promise.resolve(false);
      }

      this.docFaDestino = undefined;
      this._resetProceTrConfig();

      let column = this.dataGridIdentificacaoDefinition.columns.find((c: IDevExpressDataGridColumn<ITrDocsCabsWizardConfigDocumento, number>) => c.dataField === 'nConta');
      column.visible = this.proceTrDoc.config.modoAgrupamento === ETrDocsCabsWizardModoAgrupamento.CONTA;
      column.showInColumnChooser = this.proceTrDoc.config.modoAgrupamento === ETrDocsCabsWizardModoAgrupamento.CONTA;

      column = this.dataGridIdentificacaoDefinition.columns.find((c: IDevExpressDataGridColumn<ITrDocsCabsWizardConfigDocumento, number>) => c.dataField === 'nDoc');
      column.visible = this.proceTrDoc.config.modoAgrupamento === ETrDocsCabsWizardModoAgrupamento.DOCUMENTO;
      column.showInColumnChooser = this.proceTrDoc.config.modoAgrupamento === ETrDocsCabsWizardModoAgrupamento.DOCUMENTO;

      column = this.dataGridIdentificacaoDefinition.columns.find((c: IDevExpressDataGridColumn<ITrDocsCabsWizardConfigDocumento, number>) => c.dataField === 'nFactFornec');
      column.visible = column.showInColumnChooser = isUndefinedOrNull(this.docType) || this.docType === EGrupoDocType.Compra;

      column = this.dataGridIdentificacaoDefinition.columns.find((c: IDevExpressDataGridColumn<ITrDocsCabsWizardConfigDocumento, number>) => c.dataField === 'dataDocExterno');
      column.visible = column.showInColumnChooser = isUndefinedOrNull(this.docType) || this.docType === EGrupoDocType.Compra;

      this._identificacaoSource = [];

      selectedPesqDocs.forEach((item: IJsonTrDocsCabsWizardCabItem) => {
        if (this.proceTrDoc.config.modoAgrupamento === ETrDocsCabsWizardModoAgrupamento.DOCUMENTO) {
          this._identificacaoSource.push({
            id: generateUUID(),
            faccbId: item.faccbId,
            nConta: item.nConta,
            dataDoc: moment(),
            nDoc: item.nDoc,
            nFactFornec: '',
            dataDocExterno: moment(),
            referenciaTextoBase: 'Referência: @NomeDoc @NDoc com a data de: @DtDoc',
            documentos: [item]
          });
        } else {
          const identificacaoSourceItem: ITrDocsCabsWizardIdentificacaoSourceItem = this._identificacaoSource.find((o: ITrDocsCabsWizardIdentificacaoSourceItem) => o.nConta === item.nConta);
          if (isUndefinedOrNull(identificacaoSourceItem)) {
            this._identificacaoSource.push({
              id: generateUUID(),
              faccbId: undefined,
              nConta: item.nConta,
              dataDoc: moment(),
              nDoc: item.nDoc,
              nFactFornec: '',
              dataDocExterno: moment(),
              referenciaTextoBase: 'Referência: @NomeDoc @NDoc com a data de: @DtDoc',
              documentos: [item]
            });
          } else {
            identificacaoSourceItem.documentos.push(item);
          }
        }
      });
    } else if (event.currentStep.stepId === ETrDocsCabsWizardNavSteps.Identificacao) {
      if (!this.formIdentificacao.valid) {
        return Promise.resolve(false);
      }
      if (!this.showLotes) {
        return this._processDocs();
      }
    } else if (event.currentStep.stepId === ETrDocsCabsWizardNavSteps.Lotes) {
      let canContinue = true;

      let sourceIndex = -1;
      let firstSourceIndexWithError = -1;
      this.lotesHelper.model.sources.forEach((source: Array<ITrDocsDocComercialLinhaComLote>) => {
        sourceIndex++;
        canContinue = this._validateLotes(source, sourceIndex, firstSourceIndexWithError === -1);
        if (!canContinue && firstSourceIndexWithError === -1) {
          firstSourceIndexWithError = sourceIndex;
        }
      });

      if (!canContinue) {
        return Promise.resolve(false);
      }

      return this._processDocs();
    } else if (event.currentStep.stepId === ETrDocsCabsWizardNavSteps.Resumo) {
      return Promise.resolve(false);
    }

    return Promise.resolve(true);
  }

  private _validateLotes(rows: Array<ITrDocsDocComercialLinhaComLote>, sourceIndex: number, expandRows: boolean): boolean {
    let isValid = true;
    const sourceKeys: Array<number> = [];
    for (const linhaComLote of rows) {
      linhaComLote.error = '';
      if (linhaComLote.lotes?.length === 0) {
        linhaComLote.error = this._plTranslateService.translate('trdoc.modal.linhas.messages.atribuirLotes');
        isValid = false;
        sourceKeys.push(linhaComLote.facliId);
      }

      let qtdLotes = 0;
      for (const lote of linhaComLote.lotes) {
        qtdLotes += lote.qtd;
      }
      if (qtdLotes !== linhaComLote.qtd1) {
        linhaComLote.error = this._plTranslateService.translate('trdoc.modal.linhas.messages.qtdLotesDifQtdLinha', {
          qtd: qtdLotes,
          nArtigo: linhaComLote.nArtigo,
          nSeq: linhaComLote.nSeq,
          qtd1: linhaComLote.qtd1
        });
        isValid = false;
        sourceKeys.push(linhaComLote.facliId);
      }
    }
    if (expandRows) {
      this._expandGridLinhasLotesRows(sourceIndex, sourceKeys);
    }
    return isValid;
  }

  private _checkAndShowErrorsLotes(rows: Array<ITrDocsDocComercialLinhaComLote>, sourceIndex: number): void {
    const sourceKeys: Array<number> = rows.filter((item) => item.error.length).map((item) => item.facliId);
    if (sourceKeys.length) {
      this._expandGridLinhasLotesRows(sourceIndex, sourceKeys);
    }
  }

  private _processDocs(): Promise<boolean> {
    this.proceTrDoc.config.documentos = [];
    this._identificacaoSource.forEach((sourceItem: ITrDocsCabsWizardIdentificacaoSourceItem) => {
      const sourceKey: string = this.proceTrDoc.config.modoAgrupamento === ETrDocsCabsWizardModoAgrupamento.DOCUMENTO ? sourceItem.nDoc : sourceItem.nConta;

      sourceItem.documentos.forEach((document: IJsonTrDocsCabsWizardCabItem) => {
        const linhas: Array<IJsonTrDocLinha> = document.linhas.map((linha: IJsonDocComercialLinha) => {
          let lotes;
          if (this.lotesHelper.model.sources.has(sourceKey)) {
            const source: Array<ITrDocsDocComercialLinhaComLote> = this.lotesHelper.model.sources.get(sourceKey);
            const artigoComLote: ITrDocsDocComercialLinhaComLote = source.find((linhaComLote: ITrDocsDocComercialLinhaComLote) => {
              return linhaComLote.faccbId === document.faccbId && linhaComLote.facliId === linha.facliId;
            });
            if (isDefinedNotNull(artigoComLote)) {
              lotes = artigoComLote.lotes;
            }
          }
          return {facliId: linha.facliId, qtd: linha.qtd1, lotes: lotes};
        });

        this.proceTrDoc.config.documentos.push({
          faccbId: document.faccbId,
          nConta: document.nConta,
          dataDoc: document.dataDoc,
          nDoc: document.nDoc,
          nFactFornec: sourceItem.nFactFornec,
          dataDocExterno: sourceItem.dataDocExterno,
          referenciaTextoBase: sourceItem.referenciaTextoBase,
          linhas: linhas
        });
      });
    });

    return this._trdocsService.processarDocumentos(this.proceTrDoc.config).then((response: HttpResponse<IJsonTrDocsCabsWizardCabResumo>) => {
      this.resumoSource = response.body.listaDocsGerados;
      this.resumoErrosSource = response.body.listaErros;
      return true;
    });
  }

  private _getPdf(doc: IJsonDocComercial): Promise<void> {
    return this._documentosService.getPdf(doc, doc.cab.nDocumento === 0 || !doc.cab.terminado);
  }

  private _getDoc(id: number): Promise<IJsonDocComercial> {
    return this._documentosService.getDoc(id).then((response: HttpResponse<IJsonDocComercial>) => response.body);
  }

  private _verificaEValidaDocsSeLinhasTemLotes(): void {
    const selectedPesqDocs: Array<IJsonTrDocsCabsWizardCabItem> = this._dataGridInstance.getSelectedRowsData();
    this._setDisableNextStepWizard(false);
    this._validaSeLinhasTemLotes(selectedPesqDocs, this.docFaDestino.integraStocks, this._grupoDoc.isCompra(this.docFaDestino.grupoDocfa), true).finally(() => {
      this._setDisableNextStepWizard(true);
    });
  }

  private _setDisableNextStepWizard(enable: boolean): void {
    this.propertiesNavWizard = {...this.propertiesNavWizard, disableNext: !enable};
  }

  private async _validaSeLinhasTemLotes(list: Array<IJsonTrDocsCabsWizardCabItem>, integraStocks: boolean, isCompra: boolean, validaLotes: boolean): Promise<void> {
    this.lotesHelper.model.currentIndex = 0;
    this.lotesHelper.model.sources.clear();
    this.dataGridLinhasLotesDefinition.dataSource = [];
    this._lotesLinha = [];
    this.dataGridLotesDefinition.dataSource = [];
    this.showLotes = false;

    if (validaLotes && integraStocks) {
      for (const item of list) {
        const sourceKey: string = this.proceTrDoc.config.modoAgrupamento === ETrDocsCabsWizardModoAgrupamento.DOCUMENTO ? item.nDoc : item.nConta;
        // eslint-disable-next-line no-await-in-loop
        const linhasComLote: Array<ITrDocsDocComercialLinhaComLote> = await this._getDocLinhasComLotes(item, isCompra);
        if (linhasComLote.length > 0) {
          this.showLotes = true;
          let sourceItem = this.lotesHelper.model.sources.get(sourceKey);
          if (isUndefinedOrNull(sourceItem)) {
            sourceItem = [];
            this.lotesHelper.model.sources.set(sourceKey, sourceItem);
          }
          linhasComLote.forEach((linha: ITrDocsDocComercialLinhaComLote) => {
            sourceItem.push(linha);
          });
        }
      }
    }
  }

  private async _getDocLinhasComLotes(doc: IJsonTrDocsCabsWizardCabItem, isCompra: boolean): Promise<Array<ITrDocsDocComercialLinhaComLote>> {
    const linhasComLote: Array<ITrDocsDocComercialLinhaComLote> = [];
    const artigos: Array<HttpResponse<IJsonArtigo>> = await Promise.all(doc.linhas.map((linha: IJsonDocComercialLinha) => this._artigosService.get({id: linha.nArtigo})));
    for (let i = 0; i < doc.linhas.length; i++) {
      const linha: IJsonDocComercialLinha = doc.linhas[i];
      const artigo: IJsonArtigo = artigos[i].body;
      if (artigo.temLote && (artigo.codvaloriz === CUSTO_MEDIO_PONDERADO || (artigo.codvaloriz === FIFO && isCompra))) {
        linhasComLote.push({...linha, faccbId: doc.faccbId, error: '', nDoc: doc.nDoc});
      }
    }
    return linhasComLote;
  }

  private async _preencheAutomaticamenteLotesNaLinha(item: ITrDocsDocComercialLinhaComLote, nDecimais: number): Promise<void> {
    const qtdADistribuir: number = item.qtd1;

    let qtdFACDistribuida = 0;
    let qtdLista = 0;

    const lotes: Array<IJsonTrDocLinhaLoteSel> = await this._getLotesSource(item);

    // reset
    lotes.forEach((lote: IJsonTrDocLinhaLoteSel) => {
      this._setLinhaLoteSelQtd(lote, 0, item);
    });

    for (const lote of lotes) {
      //quantidade que é suporto colocar na lista (caso normal)
      let tempQtd: number = round(lote.qtd + qtdADistribuir - qtdFACDistribuida, nDecimais);

      //verificar se a quantidade que vou colocar na linha ultrapassa a quantidade disponivel
      if (tempQtd > lote.qtdStock) {
        tempQtd = lote.qtdStock;
      }

      //se já distribui tudo... vou ver se há quantidades distribuidas a mais
      if (qtdFACDistribuida >= qtdADistribuir) {
        tempQtd = qtdFACDistribuida >= qtdADistribuir ? round(qtdADistribuir - qtdFACDistribuida, nDecimais) : lote.qtd;

        //caso em que existe mais quantidade seleccionada do que qtd fac, reduz para a qtd fac
        if (tempQtd > lote.qtdStock) {
          tempQtd = lote.qtdStock;
        }

        //caso em que já existe mais quantidade distribuida do que era suposto distribuir
        if (qtdFACDistribuida + tempQtd >= qtdADistribuir) {
          tempQtd = round(qtdADistribuir - qtdFACDistribuida, nDecimais);
        }
      }

      //já existe mais quantidade satisfeita na lista do que era suporto distribuir..
      //reduz para a quantidade em falta
      if (qtdLista >= qtdADistribuir) {
        tempQtd = round(qtdADistribuir - qtdLista, nDecimais);
        //caso em que existe mais quantidade seleccionada do que qtd fac, reduz para a qtd fac
        if (tempQtd > lote.qtd) {
          tempQtd = lote.qtdStock;
        }

        //não pode ser negativa
        if (tempQtd < 0) {
          tempQtd = 0;
        }
      }

      this._setLinhaLoteSelQtd(lote, tempQtd, item);
      qtdLista = round(qtdLista + lote.qtd, nDecimais); //soma quantidade total seleccionada na lista
      qtdFACDistribuida = round(tempQtd, nDecimais); //soma quantidade distribuida
    }
  }

  private _getNNumerDefeitoDocfa(docfanumList: Array<IJsonDocfaNum>): number {
    for (const docfanum of docfanumList) {
      if (!docfanum.encerrado && docfanum.visivelERPCloud) {
        return docfanum.nNumer;
      }
    }
    return 1;
  }

  private _cabsTableSource(): Promise<Array<IJsonTrDocsCabsWizardCabItem>> {
    if (this.isOnInit) {
      this._pesquisaSource = [];
      return Promise.resolve([]);
    }
    return this._trdocsService.pesquisarDocumentos(this.filterModel).then((response: HttpResponse<Array<IJsonTrDocsCabsWizardCabItem>>) => {
      this._pesquisaSource = response.body;
      return this._pesquisaSource;
    });
  }

  private _identificacaoTableSource(): Array<ITrDocsCabsWizardIdentificacaoSourceItem> {
    return this._identificacaoSource;
  }

  private _resumoTableSource(): Array<IJsonDocComercial> {
    this._checkVisibilityEncerrarTodosButton();
    return this.resumoSource;
  }

  private _search(): Promise<void> {
    if (this.formSearch.valid) {
      this.isOnInit = false;
      this.isFirstLoad = true;
      return this._dataGridInstance.refresh();
    }
    return Promise.resolve();
  }

  private _getLotesTableSource(linha: ITrDocsDocComercialLinhaComLote): Promise<Array<IJsonTrDocLinhaLoteSel>> {
    if (isUndefinedOrNull(linha)) {
      return Promise.resolve([]);
    }
    return this._getLotesSource(linha);
  }

  private async _getLotesSource(linha: ITrDocsDocComercialLinhaComLote): Promise<Array<IJsonTrDocLinhaLoteSel>> {
    if (isArray(linha.lotes) && linha.lotes.length > 0) {
      return Promise.resolve(linha.lotes);
    }

    const key = `${linha.nArtigo}-${linha.nArmazem}`;
    if (!this.lotesHelper.lotesArtigoArmazem.has(key)) {
      this.lotesHelper.lotesArtigoArmazem.set(key, []);
      if (!this.lotesHelper.lotesCache.has(linha.nArtigo)) {
        const search = `nArtigo=${linha.nArtigo}&terminado=0`;
        const response: HttpResponse<IApiQueryResponse<IJsonLote>> = await this._lotesService.query({pesquisa: search});
        if (response) {
          this.lotesHelper.lotesCache.set(linha.nArtigo, response.body.list);
        }
      }

      const lotesCached: Array<IJsonLote> = this.lotesHelper.lotesCache.get(linha.nArtigo);
      if (lotesCached.length > 0) {
        const lotesArtigoArmazemValue: Array<IJsonTrDocLinhaLoteSel> = [];
        linha.lotes = [];
        for (const item of lotesCached) {
          const stock: IJsonArtigoLoteArmazemStock = item?.stockArmazens?.find((stockArmaz: IJsonArtigoLoteArmazemStock) => stockArmaz?.nArmazem === linha.nArmazem);
          const qtdStock: number = stock ? stock.qtd : 0;
          const value = {
            nLote: item.nLote,
            dataFabrico: item.dataFabrico,
            dataValidade: item.dataValidade,
            nLoteEspecifico: item.nLoteEspecifico,
            qtd: 0,
            qtdStock: qtdStock,
            qtdStockOriginal: qtdStock,
            seq: 0
          };
          linha.lotes.push({...value});
          lotesArtigoArmazemValue.push(value);
        }
        this.lotesHelper.lotesArtigoArmazem.set(key, lotesArtigoArmazemValue);
      }
    } else {
      const lotesAA: Array<IJsonTrDocLinhaLoteSel> = this.lotesHelper.lotesArtigoArmazem.get(key);
      linha.lotes = lotesAA.map((o) => {
        return {...o};
      });
    }

    return Promise.resolve(linha.lotes);
  }

  private _encerrarTodosDocumentos(): Promise<void> {
    const list: Array<number> = this.resumoSource.map((item: IJsonDocComercial) => item.cab.faccbId);
    return this._trdocsService.encerrarDocumentos(list).then((response: HttpResponse<Array<IJsonDocComercial>>) => {
      this.resumoSource = response.body;
      this._dataGridResumoInstance.refresh();
    });
  }

  private _showResumoErrosModal(): Promise<void> {
    const modalRef = this._cgModalService.showVanilla(TrdocsCabsErrosModalComponent);
    const componentInstance: TrdocsCabsErrosModalComponent = modalRef.componentInstance;
    componentInstance.resumoErrosSource = this.resumoErrosSource.map((erroString: string) => {
      return {erro: erroString};
    });
    return modalRef.result;
  }

  private _resetProceTrConfig(): void {
    this.proceTrDoc.config = {
      documentos: [],
      encerraDocumentoDestino: false,
      encerraDocumentoOrigem: false,
      nDocfaDestino: undefined,
      nNumerDestino: undefined,
      modoAgrupamento: this.proceTrDoc.config.modoAgrupamento
    };
  }

  private _setModelSourceIndex(index: number): void {
    if (index > -1 && index < this.lotesHelper.model.sources.size) {
      this.lotesHelper.model.currentIndex = index;
      this.lotesHelper.model.currentKeyValue = Array.from(this.lotesHelper.model.sources.keys())[index];
      this.dataGridLinhasLotesDefinition.dataSource = this.lotesHelper.model.sources.get(this.lotesHelper.model.currentKeyValue);
      if (!this._qtdLotesAutoDistribuida) {
        this._setDisableNextStepWizard(this.lotesHelper.model.currentIndex === this.lotesHelper.model.sources.size - 1);
      }
    }
  }

  private _expandGridLinhasLotesRows(sourceIndex: number, keys: Array<number>): void {
    this._setModelSourceIndex(sourceIndex);
    keys.forEach((key) => {
      this._dataGridLinhasLotesInstance.expandRow(key);
    });
  }

  private _setLinhaLoteSelQtd(linha: IJsonTrDocLinhaLoteSel, qtd: number, artigoComLote: ITrDocsDocComercialLinhaComLote): void {
    linha.qtd = qtd;
    // update qtdStock in cache
    const key = `${artigoComLote.nArtigo}-${artigoComLote.nArmazem}`;
    const lotes = this.lotesHelper.lotesArtigoArmazem.get(key);
    let loteSelObj = lotes.find((o) => o.nLote === linha.nLote);
    if (isDefinedNotNull(loteSelObj)) {
      loteSelObj.qtdStock = qtd === 0 ? loteSelObj.qtdStockOriginal : round(loteSelObj.qtdStock - qtd, this._nDecimaisQtd);
    }

    // update qtdStock in other lines ArtigoComLote
    this.lotesHelper.model.sources.forEach((source) => {
      source.forEach((l) => {
        if (l.facliId !== artigoComLote.facliId) {
          if (l.lotes?.length) {
            loteSelObj = l.lotes.find((o) => o.nLote === linha.nLote);
            if (isDefinedNotNull(loteSelObj)) {
              loteSelObj.qtdStock = qtd === 0 ? loteSelObj.qtdStockOriginal : round(loteSelObj.qtdStock - qtd, this._nDecimaisQtd);
            }
          }
        }
      });
    });
  }

  private _checkVisibilityEncerrarTodosButton(): void {
    const doc = this.resumoSource.find((item) => !item.cab.terminado);
    this.stepsNavTrDocsResumoActions[0].visible = isDefinedNotNull(doc);
  }

  private _showMaintenanceLote(): Promise<void> {
    const linha = this._getSelLinhaLote();
    return this._maintenanceLotes
      .maintenanceNew({
        params: {
          model: {
            nArtigo: linha.nArtigo,
            nomeArtigo: linha.nomeArtigo
          },
          nArtigo: linha.nArtigo,
          nomeArtigo: linha.nomeArtigo
        }
      })
      .then((newLote) => {
        if (this.lotesHelper.lotesCache.has(linha.nArtigo)) {
          this.lotesHelper.lotesCache.get(linha.nArtigo).push(newLote);
        }
        const selRowKeys: Array<number> = this._dataGridLinhasLotesInstance.getSelectedRowKeys();
        if (linha.lotes?.length) {
          linha.lotes.push({
            nLote: newLote.nLote,
            nLoteEspecifico: newLote.nLoteEspecifico,
            dataFabrico: newLote.dataFabrico,
            dataValidade: newLote.dataValidade,
            qtd: 0,
            seq: 0,
            qtdStock: 0
          });
        }
        this._getLotesTableSource(linha).then(() => {
          this._dataGridLinhasLotesInstance.selectRows(selRowKeys, false);
        });
      });
  }

  private _getSelLinhaLote(): ITrDocsDocComercialLinhaComLote {
    const selArray: Array<ITrDocsDocComercialLinhaComLote> = this._dataGridLinhasLotesInstance.getSelectedRowsData();
    if (!selArray.length) {
      return undefined;
    }
    return selArray[0];
  }

  private _getDocfaFilter(): string {
    const filters: Array<string> = [];
    if (isDefinedNotNull(this.grupoDocfa)) {
      if (this.docType === EGrupoDocType.Venda) {
        filters.push(`grupoDocfa=${EGrupoDoc.VendasEfectivas}`);
        filters.push(`grupoDocfa=${EGrupoDoc.EncomendasClientes}`);
        filters.push(`grupoDocfa=${EGrupoDoc.PropostasAClientes}`);
        filters.push(`grupoDocfa=${EGrupoDoc.GuiasTransporteRemessa}`);
      } else if (this.docType === EGrupoDocType.Compra) {
        filters.push(`grupoDocfa=${EGrupoDoc.ComprasEfectivas}`);
        filters.push(`grupoDocfa=${EGrupoDoc.EncomendasFornecedores}`);
      }
    }
    return filters.join('|');
  }

  private _homePage(): Promise<void> {
    return this.finalize();
  }

  private async _preencheAutomaticamenteLotes(): Promise<void> {
    if (this._grupoDoc.isCompra(this.grupoDocfa)) {
      this._plAlertService.error('encomendas.modal.processamento.messages.notAutoSelLotesEntradaStock');
      return Promise.reject(new Error('encomendas.modal.processamento.messages.notAutoSelLotesEntradaStock'));
    }

    if (this.lotesHelper.model.sources.size === 0) {
      this._plAlertService.error('encomendas.modal.processamento.messages.naoTemLinhasComLotes');
      return Promise.reject(new Error('encomendas.modal.processamento.messages.naoTemLinhasComLotes'));
    }

    this._dataGridLinhasLotesInstance.collapseAll(-1);

    // get all items from all sources
    const list: Array<ITrDocsDocComercialLinhaComLote> = [];
    this.lotesHelper.model.sources.forEach((source: Array<ITrDocsDocComercialLinhaComLote>) => {
      source.forEach((linha: ITrDocsDocComercialLinhaComLote) => {
        linha.error = '';
        list.push(linha);
      });
    });

    this._dataGridLinhasLotesInstance.repaint();

    for (const item of list) {
      // eslint-disable-next-line no-await-in-loop
      await this._preencheAutomaticamenteLotesNaLinha(item, this._nDecimaisQtd).then(() => undefined);
    }

    this._qtdLotesAutoDistribuida = true;
    this._setDisableNextStepWizard(true);
    return Promise.resolve();
  }
}
