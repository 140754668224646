<div class="contabilidade-predefinidos" [ngClass]="entityClassName">
  <entity-detail
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [service]="service"
    [(model)]="model"
    [type]="type"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <div *ngIf="type !== stateTypes.NEW" class="contabilidade-predefinidos-edit-header mb-3">
        <ng-container *ngTemplateOutlet="templateBasicContent"></ng-container>
      </div>

      <pl-nav-wizard
        [(model)]="step"
        [definition]="definition"
        [callback]="plNavWizard"
        [beforeStepChange]="fnBeforeChangedStep"
        [onFinalize]="fnFinalize"
        [destroyOnHide]="false"
        (evtStepChange)="changedStep($event.currentStep)">
        <pl-nav-wizard-step
          [stepId]="steps.Basic"
          caption="predefinidoscontabilidade.steps.basic"
          icon="fa-cogs"
          [visible]="type === stateTypes.NEW && operation !== operations.DELETE"
          [required]="true"
          [validator]="fnValidatorStep2">
          <div *plNavWizardStepContent class="row contabilidade-predefinidos-step-basic">
            <div [ngClass]="type === stateTypes.NEW ? 'col-12 col-sm-5 col-lg-4' : ''">
              <div class="entity-detail-form contabilidade-predefinidos-operation-basic">
                <ng-container *ngTemplateOutlet="templateBasicContent"></ng-container>
              </div>
            </div>
          </div>
        </pl-nav-wizard-step>

        <pl-nav-wizard-step
          [stepId]="steps.Header"
          caption="predefinidoscontabilidade.steps.header"
          icon="fa-list-alt"
          [required]="true"
          [visible]="type === stateTypes.EDIT && operation !== operations.DELETE"
          [validator]="fnValidatorStep3">
          <div *plNavWizardStepContent class="row">
            <div class="col-12">
              <predefinidocontabcab [predefinido]="model" [(valid)]="isStep3Valid" [callback]="preDefinidoContabCab"></predefinidocontabcab>
            </div>
          </div>
        </pl-nav-wizard-step>

        <pl-nav-wizard-step [stepId]="steps.Lines" caption="predefinidoscontabilidade.steps.lines" icon="fa-list-ul" [required]="false" [visible]="false" [validator]="fnValidatorStep4">
          <div *plNavWizardStepContent class="row">
            <div class="col-12">
              <predefinidocontablinhas [predefinido]="model" [(valid)]="isStep4Valid"></predefinidocontablinhas>
            </div>
          </div>
        </pl-nav-wizard-step>

        <pl-nav-wizard-step [stepId]="steps.Preview" caption="predefinidoscontabilidade.steps.preview" icon="fa-eye" [required]="true" [visible]="false">
          <div *plNavWizardStepContent>
            <doccontabilidade
              *ngIf="currentStepId === steps.Preview"
              [model]="docContabilidade"
              (modelChange)="changedDocContabPreview($event)"
              [predefinido]="model"
              [onLoadFocusField]="'periodo'"
              [callback]="docCallback"
              [simulation]="true">
            </doccontabilidade>
          </div>
        </pl-nav-wizard-step>

        <pl-nav-wizard-step [stepId]="steps.Companies" caption="predefinidoscontabilidade.steps.companies" icon="fa-exchange" [required]="true" [visible]="operation === operations.DELETE">
          <pl-form *plNavWizardStepContent>
            <pl-group>
              <label class="h4" [translate]="titleCompanies"></label>
              <edit>
                <pl-multiselect
                  [model]="empresasSelecionadas"
                  (evtChanged)="changedEmpresasSelecionadas()"
                  [source]="listaEmpresas"
                  key="nEmpresa"
                  [template]="templateListaEmpresas"
                  [properties]="{
                    leftCaption: 'predefinidoscontabilidade.titles.availableCompanies',
                    rightCaption: titleCompaniesRight,
                    validators: {
                      required: {value: true},
                      minselected: {value: 1}
                    }
                  }">
                </pl-multiselect>
              </edit>
            </pl-group>
          </pl-form>
        </pl-nav-wizard-step>
      </pl-nav-wizard>
    </div>

    <ng-template #templateBasicContent>
      <div class="entity-detail-form contabilidade-predefinidos-operation-basic">
        <pl-form [properties]="{validators: {required: {value: true}}}" (formInstanceChange)="formInstanceBasic = $event">
          <pl-group *ngIf="operation !== operations.EDIT && operation !== operations.DELETE">
            <label [translate]="'predefinidoscontabilidade.fields.preDefinidosID'"></label>
            <edit>
              <pl-edit type="cginteger" attrName="codigo" [(model)]="model.preDefinidosID"></pl-edit>
            </edit>
            <actions>
              <pl-button
                klass="btn-primary btn-sm btn-sugCod"
                attrName="sugerirCodigo"
                text="predefinidoscontabilidade.titles.suggestCodigo"
                [tooltip]="{text: 'predefinidoscontabilidade.titles.suggestCodigoToolTip'}"
                [onClick]="suggestCodeMultiEmpresa">
              </pl-button>
            </actions>
          </pl-group>

          <pl-group *ngIf="operation === operations.EDIT || operation === operations.DELETE">
            <label [translate]="'predefinidoscontabilidade.fields.preDefinidosID'"></label>
            <edit>
              <entity-autocomplete
                entity="predefinidoscontabilidade"
                attrName="codigo"
                [selectedKey]="model.preDefinidosID"
                (selectedKeyChange)="model.preDefinidosID = $event"
                (evtSelectedDescriptionChanged)="model.descricao = $event"
                [properties]="{readonly: operation === operations.DELETE || hardEditing}"
                output="preDefinidosID"
                outputDescription="descricao"
                plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'predefinidoscontabilidade.fields.description'"></label>
            <edit>
              <pl-edit
                type="text"
                attrName="descricao"
                [(model)]="model.descricao"
                [properties]="{readonly: operation === operations.DELETE}"
                plAutoFocus
                [plAutoFocusDisabled]="hardEditing"></pl-edit>
            </edit>
          </pl-group>

          <pl-group *ngIf="operation === operations.NEW_BASE_DOC">
            <label [translate]="'predefinidoscontabilidade.fields.document'"></label>
            <edit>
              <entity-autocomplete entity="docscontabilidade" attrName="documento" [(model)]="docContabilidade" [selectOnFocus]="false"></entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group *ngIf="operation === operations.NEW_BASE_PREDEFINIDO">
            <label [translate]="'predefinidoscontabilidade.fields.copyExisting'"></label>
            <edit>
              <entity-autocomplete entity="predefinidoscontabilidade" attrName="preDefinidoCopiar" (selectedKeyChange)="preDefinidosIDToCopy = $event"></entity-autocomplete>
            </edit>
          </pl-group>

          <pl-group *ngIf="operation !== operations.DELETE">
            <label [translate]="'predefinidoscontabilidade.fields.cgBanking'"></label>
            <edit>
              <pl-edit type="boolean" attrName="cgBanking" [(model)]="model.cgBanking"></pl-edit>
            </edit>
          </pl-group>
        </pl-form>
      </div>
    </ng-template>
  </entity-detail>
</div>
