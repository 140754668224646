import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {IJsonDocTransfArmazem} from './jsonTransferenciasArmazens.entity.interface';
import {TransferenciasArmazensListComponent} from './components/list/transferenciasArmazens.entity.list.component';
import {ENTITY_NAME_TRANSFERENCIAS_ARMAZENS} from './transferenciasArmazens.entity.interface';
import {TransferenciasArmazensModuleComponent} from './components/transferenciasArmazens.entity.component';

export const ENTITY_TRANSFERENCIAS_ARMAZENS: IEntityDefinition<IJsonDocTransfArmazem> = {
  name: ENTITY_NAME_TRANSFERENCIAS_ARMAZENS,
  roles: [ROLE.ERP],
  searchPlaceholder: 'transferenciasarmazens.pesquisa',
  sidebarTitle: 'portals.sidebar.modules.transferenciasarmazens',
  metadata: {
    keyName: 'faccbIdOrigem',
    fields: [
      {name: 'faccbIdOrigem', type: 'cgsmallint', caption: 'transferenciasarmazens.list.fields.faccbIdOrigem', visible: false},
      {name: 'nDocfaOrigem', type: 'cgsmallint', caption: 'transferenciasarmazens.list.fields.nDocfaOrigem', visible: false},
      {name: 'nDocOrigem', type: 'cgsmallint', caption: 'transferenciasarmazens.list.fields.nDocOrigem', visible: false},
      {name: 'nNumerOrigem', type: 'cgsmallint', caption: 'transferenciasarmazens.list.fields.nNumerOrigem', visible: false},
      {name: 'nDocfaDestino', type: 'cgsmallint', caption: 'transferenciasarmazens.list.fields.nDocfaDestino', visible: false},
      {name: 'nDocDestino', type: 'cgsmallint', caption: 'transferenciasarmazens.list.fields.nDocDestino', visible: false},
      {name: 'nNumerDestino', type: 'cgsmallint', caption: 'transferenciasarmazens.list.fields.nNumerDestino', visible: false},
      {name: 'numeroOrigem', type: 'string', caption: 'transferenciasarmazens.list.fields.numeroOrigem'},
      {name: 'numeroDestino', type: 'string', caption: 'transferenciasarmazens.list.fields.numeroDestino'},
      {name: 'dataDoc', type: 'date', caption: 'transferenciasarmazens.list.fields.dataDoc'}
    ],
    searchFields: 'numeroOrigem,numeroDestino,dataDoc',
    order: 'dataDoc'
  },
  actions: {
    new: true,
    detail: false,
    edit: false,
    delete: false,
    search: true,
    filter: true
  },
  list: {
    state: {
      component: TransferenciasArmazensListComponent,
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'transferenciasarmazens.title_list'
      }
    }
  },
  new: {
    state: {
      component: TransferenciasArmazensModuleComponent
    }
  },
  detail: {
    state: {
      component: TransferenciasArmazensModuleComponent
    }
  }
};
