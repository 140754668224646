<div class="documento-contabilidade" *ngIf="doc">
  <div class="card">
    <div class="card-header">
      <div class="card-title documento-contabilidade-header p-0">
        <div class="documento-contabilidade-header-title">
          <div class="hint-marcado-como-consistente" *ngIf="hintMarcadoComoConsistente">
            <i class="fa fa-exclamation-triangle" aria-hidden="true" [attr.title]="hintMarcadoComoConsistente"></i>&nbsp;
          </div>

          <div><strong>Nº:&nbsp;</strong>{{ doc.nDocumento }}</div>

          <div class="badge bg-danger" *ngIf="doc.anulado">
            <strong [translate]="'docscontabilidade.fields.anulado'"></strong>
          </div>

          <div class="documento-contabilidade-header-doc-info resumo-wrapper order-lg-2">
            <div class="resumo">
              <dl>
                <dt><span [translate]="'docscontabilidade.doc.cab.dataLancamento'"></span>:&nbsp;</dt>
                <dd>{{ doc.dataLancamento | cgcDate }}</dd>
                <dt><span [translate]="'docscontabilidade.nDocSeq'"></span>:&nbsp;</dt>
                <dd>{{ doc.periodo + '.' + doc.nDiario + '.' + doc.nDocSeq }}</dd>
              </dl>
            </div>
          </div>

          <div class="documento-contabilidade-header-toolbar order-lg-1" *ngIf="showToolbar">
            <button type="button" class="btn btn-success btn-sm" id="btnPDFDoc" [click]="pdf" plPromise>
              <i class="fa fa-fw fa-file-pdf-o"></i>&nbsp;<span [translate]="'global.btn.processPDF'"></span>
            </button>

            <button type="button" class="btn btn-warning btn-sm" id="btnEditarDoc" *ngIf="!readonly && hasRoleContabilidade && !doc.soDeLeitura" [click]="editarDoc" plPromise>
              <i class="fa fa-fw fa-edit"></i>&nbsp;<span [translate]="'entity.action.edit'"></span>
            </button>

            <button
              type="button"
              class="btn btn-info btn-sm"
              [class.active]="activeBtnContabDigital"
              id="btnContabDigital"
              *ngIf="hasContabilidadeDigital && showBtnContabDigital"
              (click)="btnContabDigitalClicked()">
              <i class="fa fa-fw fa-file-pdf-o"></i>&nbsp;<span [translate]="'docscontabilidade.toolbar.contabilidadeDigital'"></span>
            </button>

            <button type="button" class="btn btn-secondary btn-sm" id="btnAnexarGDoc" *ngIf="!readonly && hasRoleContabilidade && showGDocs" [click]="anexarGDocContabilidade" plPromise>
              <i class="fa fa-fw fa-paperclip"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.anexos'"></span>
            </button>

            <!--  Se documento for só de leitura mas é de natureza 66 deve permitir anular -->
            <button
              type="button"
              class="btn btn-danger btn-sm"
              id="btnApagarDoc"
              *ngIf="!readonly && hasRoleContabilidade && ((doc.soDeLeitura && doc.natureza === 66) || !doc.soDeLeitura || doc.isDocPrincipalDiferimento)"
              [click]="apagarDoc"
              plPromise>
              <i class="fa fa-fw fa-trash"></i>&nbsp;<span [translate]="'entity.action.delete'"></span>
            </button>

            <button
              type="button"
              *ngIf="!readonly && showBtnPreDefinidos && hasRoleContabilidade && !doc.soDeLeitura"
              class="btn btn-info btn-sm"
              id="btnNovoPredefinidoByDocContabilidade"
              [click]="novoPredefinidoByDocContabilidade"
              [attr.title]="'docscontabilidade.toolbar.criarPreDefinidoTooltip' | translate"
              plPromise>
              <i class="fa fa-fw fa-puzzle-piece"></i>&nbsp;<span [translate]="'docscontabilidade.toolbar.criarPreDefinido'"></span>
            </button>

            <div class="btn-group btn-group-sm" *ngIf="doc.isDocDiferimento">
              <button
                type="button"
                class="btn btn-info btn-sm"
                id="btnInfoDiferimentos"
                [title]="'docscontabilidade.toolbar.infoDiferimentosHint' | translate"
                [click]="fnInfoDiferimento(doc.extPocCabID)"
                plPromise>
                <i class="fa fa-fw fa-info"></i>&nbsp;<span [translate]="'docscontabilidade.toolbar.infoDiferimentos'"></span>
              </button>
              <div class="btn-group btn-group-sm" ngbDropdown role="group">
                <button type="button" class="btn btn-info dropdown-toggle dropdown-toggle-split" ngbDropdownToggle>
                  <span class="visually-hidden"></span>
                </button>
                <div ngbDropdownMenu>
                  <button
                    *ngIf="doc.isDocPrincipalDiferimento"
                    type="button"
                    class="dropdown-item"
                    [translate]="'docscontabilidade.toolbar.anularDiferimento'"
                    [click]="anularDiferimento"
                    plPromise></button>
                  <div *ngIf="doc.isDocPrincipalDiferimento" class="dropdown-divider"></div>
                  <button
                    type="button"
                    class="dropdown-item"
                    [translate]="'docscontabilidade.toolbar.infoGlobalDiferimentos'"
                    (click)="openInfoGlobalDiferiAcresc(tipoOpDiferimentos.Diferimento)"></button>
                  <button
                    type="button"
                    class="dropdown-item"
                    [translate]="'docscontabilidade.toolbar.infoGlobalAcrescimos'"
                    (click)="openInfoGlobalDiferiAcresc(tipoOpDiferimentos.Acrescimo)"></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body card-documento">
      <ul class="page-invoice-header-list">
        <li>
          <div class="page-invoice-header-group">
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-title" [translate]="'docscontabilidade.doc.cab.periodo'"></span>
            </div>
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-value">{{ doc.periodo }} - {{ doc.nomePeriodo }}</span>
            </div>
          </div>
        </li>

        <li *ngIf="doc.nContribuinte && doc.nContribuinte.length">
          <div class="page-invoice-header-group">
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-title" [translate]="'docscontabilidade.doc.cab.nContribuinte'"></span>
            </div>
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-value">{{ doc.nContribuinte }}</span>
            </div>
          </div>
        </li>

        <li>
          <div class="page-invoice-header-group">
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-title" [translate]="'docscontabilidade.doc.cab.nDiario'"></span>
            </div>
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-value">{{ doc.nDiario }} - {{ doc.nomeDiario }}</span>
            </div>
          </div>
        </li>

        <li>
          <div class="page-invoice-header-group">
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-title" [translate]="'docscontabilidade.doc.cab.dataVencimento'"></span>
            </div>
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-value">{{ doc.dataVencimento | cgcDate }}</span>
            </div>
          </div>
        </li>

        <li>
          <div class="page-invoice-header-group">
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-title" [translate]="'docscontabilidade.doc.cab.nDocInterno'"></span>
            </div>
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-value">{{ doc.nDocInterno }}</span>
            </div>
          </div>
        </li>

        <li *ngIf="doc.descricao && doc.descricao.length">
          <div class="page-invoice-header-group">
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-title" [translate]="'docscontabilidade.doc.cab.descricao'"></span>
            </div>
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-value">{{ doc.descricao }}</span>
            </div>
          </div>
        </li>

        <li>
          <div class="page-invoice-header-group">
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-title" [translate]="'docscontabilidade.doc.cab.codDescritivo'"></span>
            </div>
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-value">{{ doc.codDescritivo }} - {{ doc.nomeDescritivo }}</span>
            </div>
          </div>
        </li>

        <li *ngIf="doc.documentoExterno && doc.documentoExterno.length">
          <div class="page-invoice-header-group">
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-title" [translate]="'docscontabilidade.doc.cab.documentoExterno'"></span>
            </div>
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-value">{{ doc.documentoExterno }}</span>
            </div>
          </div>
        </li>

        <li>
          <div class="page-invoice-header-group">
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-title" [translate]="'docscontabilidade.doc.cab.codMoeda'"></span>
            </div>
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-value">{{ doc.codMoeda }} - {{ doc.nomeMoeda }}</span>
            </div>
          </div>
        </li>

        <li>
          <div class="page-invoice-header-group">
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-title" [translate]="'docscontabilidade.doc.cab.dataDoc'"></span>
            </div>
            <div class="page-invoice-header-row">
              <span class="page-invoice-header-value">{{ doc.dataDoc | cgcDate }}</span>
            </div>
          </div>
        </li>
      </ul>

      <div class="d-flex justify-content-center" *ngIf="doc.campoErroInformativo && showErroInformativo !== false">
        <pl-alert class="pl-alert-sm" type="danger" theme="light m-0" [closeable]="false">
          <span [innerHTML]="doc.campoErroInformativo | translate"></span>
        </pl-alert>
      </div>

      <hr />

      <div class="table-responsive">
        <table class="table table-hover table-sm table-docs">
          <thead>
            <tr>
              <th [translate]="'docscontabilidade.doc.linhas.nContaDebito'"></th>
              <th [translate]="'docscontabilidade.doc.linhas.nContaCredito'"></th>
              <th class="text-right" [translate]="'docscontabilidade.doc.linhas.valor'"></th>
              <th class="text-right" [translate]="'docscontabilidade.doc.linhas.valorPago'"></th>
              <th class="text-right" [translate]="'docscontabilidade.doc.linhas.nContrib'"></th>
              <th class="text-right" [translate]="'docscontabilidade.doc.linhas.descricao'"></th>
              <th class="text-right" [translate]="'docscontabilidade.doc.linhas.cDecPer'"></th>
              <th class="text-right" [translate]="'docscontabilidade.doc.linhas.cDecAnual'"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let linha of doc.linhas" [ngClass]="highLightSelected[linha.nLanc] || linesToHighlight[linha.nLanc]" (click)="onClickLinha(linha)">
              <td>
                <div *ngIf="linha.dc !== 1">{{ linha.nConta }}</div>
              </td>
              <td>
                <div *ngIf="linha.dc === 1">{{ linha.nConta }}</div>
              </td>
              <td class="text-right">{{ linha.valor | cgcDecimal: decimais }}</td>
              <td class="text-right">{{ linha.valorPago | cgcDecimal: decimais }}</td>
              <td class="text-right">{{ linha.nContrib }}</td>
              <td class="text-right">{{ linha.descricao }}</td>
              <td class="text-right">{{ linha.cDecPer }}</td>
              <td class="text-right">{{ linha.cDecAnual }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <hr />

      <doccontabilidade-totais [doccontabilidade]="doc" [selectedAccount]="itemInfo" [configDecimais]="config.decimais"></doccontabilidade-totais>
    </div>
  </div>
</div>
