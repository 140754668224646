import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Injectable, Injector} from '@angular/core';
import {EGroupName} from '../../../../config/constants';
import {ApiService} from '../../../services/api/api.service';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {ConfigOptionsInstanceService} from '../../../services/config/options/config.options.instance.service';
import {EConfigOptionsInstanceName, IConciliacaoBancariaConfigOptions, IConfigOptionsGroupContabilidade} from '../../../services/config/options/config.options.service.interface';
import {IJsonGDFolder} from '../../../entities/gdoc/JsonGDoc.entity.interface';
import {
  EConciliacaoBancariaCommandType,
  EConciliacaoBancariaEstadoConciliacaoCab,
  EConciliacaoBancariaExtratoBancoTipo,
  IConciliacaoBancariaAddEditLinhaExtratoCGModal,
  IConciliacaoBancariaBancoCab,
  IConciliacaoBancariaEditLinhaExtratoBanco,
  IConciliacaoBancariaImportTemplateData,
  IConciliacaoBancariaLinItem,
  IConciliacaoBancariaNContaAbrevMoedaEstrangeira,
  IConciliacaoBancariaNovaLinhaExtratoBancoModal,
  IConciliacaoBancariaSaldoInicialBanco,
  IConciliacaoBancariaStatus,
  IRestCmdConciliacaoBancaria,
  IRestCmdConciliacaoBancariaAssociarExtrato,
  IRestCmdConciliacaoBancariaAssociarExtratoMultiple,
  IRestCmdConciliacaoBancariaCarregaDocumentosAnteriores,
  IRestCmdConciliacaoBancariaConnectLines,
  IRestCmdConciliacaoBancariaDesassociarExtratoBanco,
  IRestCmdConciliacaoBancariaDesassociarExtratoCG,
  IRestCmdConciliacaoBancariaEditar,
  IRestCmdConciliacaoBancariaEditarLinhaExtratoBanco,
  IRestCmdConciliacaoBancariaEliminaConciliacaoBancaria,
  IRestCmdConciliacaoBancariaEliminaConciliacaoBancariaMultiple,
  IRestCmdConciliacaoBancariaEliminaTodasConciliacaoBancariaDaConta,
  IRestCmdConciliacaoBancariaGetConciliacao,
  IRestCmdConciliacaoBancariaIncluirDocsDaListaNoExtratoCG,
  IRestCmdConciliacaoBancariaLancaLinhasNaContabilidadeEAssocia,
  IRestCmdConciliacaoBancariaLoadImportExtractoBancario,
  IRestCmdConciliacaoBancariaLoadTemplateImportExtractoBancario,
  IRestCmdConciliacaoBancariaMudarEstadoConcilidacao,
  IRestCmdConciliacaoBancariaNovaConciliacao,
  IRestCmdConciliacaoBancariaNovaLinhaExtratoBanco,
  IRestCmdConciliacaoBancariaNovaLinhaExtratoCG,
  IRestCmdConciliacaoBancariaObterSaldos,
  IRestCmdConciliacaoBancariaRemoveLinhaExtratoBanco,
  IRestCmdConciliacaoBancariaRemoveLinhaExtratoCG,
  IRestCmdConciliacaoBancariaRemoveTemplateImportExtractoBancario,
  IRestCmdConciliacaoBancariaSaveTemplateImportExtractoBancario,
  IRestCmdConciliacaoBancariaSetContaPendentesBanco,
  IRestCmdConciliacaoBancariaSetTemplateImportExtractoBancario,
  IRestCmdConciliacaoBancariaUpdateRegDescritivo,
  IRestCmdConciliacaoBancariaUpdateRegDiario,
  TConciliacaoBancariaCommandRawResult,
  TJsonConciliacaoBancariaCommandResponse
} from './conciliacaoBancaria.module.interface';

@Injectable({
  providedIn: 'root'
})
export class ConciliacaoBancariaService extends ConfigOptionsInstanceService<boolean, IConciliacaoBancariaConfigOptions, IConfigOptionsGroupContabilidade> {
  private readonly _path: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _apiService: ApiService
  ) {
    super(_injector, EGroupName.CONTABILIDADE, EConfigOptionsInstanceName.CONCILIACAO_BANCARIA);
    this._path = `${_apiService.path.restapi}/conciliacaoBancaria`;
  }

  public getJobStatus(): Promise<IConciliacaoBancariaStatus> {
    return new Promise<IConciliacaoBancariaStatus>((resolve, reject) => {
      this._apiService
        .get<IConciliacaoBancariaStatus>({
          url: `${this._path}/status`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public getGDocFolderId(nconta: string, periodo: string): TServiceResponse<IJsonGDFolder> {
    return this._apiService.get<IJsonGDFolder>({
      params: {
        nconta: nconta,
        periodo: periodo
      },
      url: `${this._path}/getGDocFolderId`
    });
  }

  public cancelJob(nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult {
    const command: IRestCmdConciliacaoBancaria = {
      nConta: nconta,
      periodo: periodo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancaria>(EConciliacaoBancariaCommandType.CancelJob, command);
  }

  public loadConciliacao(nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult {
    const command: IRestCmdConciliacaoBancaria = {
      nConta: nconta,
      periodo: periodo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancaria>(EConciliacaoBancariaCommandType.LoadConciliacao, command);
  }

  public getConciliacao(nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaGetConciliacao> {
    const command: IRestCmdConciliacaoBancariaGetConciliacao = {
      nConta: nconta,
      periodo: periodo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaGetConciliacao>(EConciliacaoBancariaCommandType.GetConciliacao, command);
  }

  public bancoCabList(): TServiceResponse<Array<IConciliacaoBancariaBancoCab>> {
    return this._apiService.get<Array<IConciliacaoBancariaBancoCab>>({url: `${this._path}/bancoCabList`});
  }

  public novaConciliacao(data: Partial<IConciliacaoBancariaBancoCab>, conciliacaoFechoAno: boolean): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaNovaConciliacao> {
    const command: IRestCmdConciliacaoBancariaNovaConciliacao = {
      nConta: data.nConta,
      periodo: data.periodo,
      saldoInicial: data.saldoInicial,
      saldoFinal: data.saldoFinal,
      saldoInicialDC: data.saldoInicialDC,
      saldoFinalDC: data.saldoFinalDC,
      conciliacaoFechoAno: conciliacaoFechoAno
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaNovaConciliacao>(EConciliacaoBancariaCommandType.NovaConciliacao, command);
  }

  public associarTodoExtratoCGAoExtradoDoBanco(nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult {
    const command: IRestCmdConciliacaoBancaria = {
      nConta: nconta,
      periodo: periodo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancaria>(EConciliacaoBancariaCommandType.AssociarTodoExtratoCGAoExtradoDoBanco, command);
  }

  public associarExtrato(nLanc: string, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaAssociarExtrato> {
    const command: IRestCmdConciliacaoBancariaAssociarExtrato = {
      nConta: nconta,
      periodo: periodo,
      nLanc: nLanc
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaAssociarExtrato>(EConciliacaoBancariaCommandType.AssociarExtrato, command);
  }

  public associarExtratoMultiple(nLancs: Array<string>, oneLine: boolean, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaAssociarExtratoMultiple> {
    const command: IRestCmdConciliacaoBancariaAssociarExtratoMultiple = {
      nConta: nconta,
      periodo: periodo,
      nLancs: nLancs,
      oneLine: oneLine
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaAssociarExtratoMultiple>(EConciliacaoBancariaCommandType.AssociarExtratoMultiple, command);
  }

  public incluirDocsDaListaNoExtratoCG(nLancs: Array<string>, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaIncluirDocsDaListaNoExtratoCG> {
    const command: IRestCmdConciliacaoBancariaIncluirDocsDaListaNoExtratoCG = {
      nConta: nconta,
      periodo: periodo,
      nLancs: nLancs
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaIncluirDocsDaListaNoExtratoCG>(EConciliacaoBancariaCommandType.IncluirDocsDaListaNoExtratoCG, command);
  }

  public novaLinhaExtratoBanco(
    data: IConciliacaoBancariaNovaLinhaExtratoBancoModal,
    tipo: EConciliacaoBancariaExtratoBancoTipo,
    nconta: string,
    periodo: string
  ): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaNovaLinhaExtratoBanco> {
    const command: IRestCmdConciliacaoBancariaNovaLinhaExtratoBanco = {
      nConta: nconta,
      periodo: periodo,
      dc: data.dc,
      valor: data.valor,
      data: data.data,
      descricao: data.descricao,
      descritivo: data.descritivo,
      diario: data.diario,
      nContaLanc: data.radical.includes(data.contaBanco) ? data.radical : `${data.radical}${data.contaBanco}`,
      tipo: tipo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaNovaLinhaExtratoBanco>(EConciliacaoBancariaCommandType.InserirLinhaExtratoBanco, command);
  }

  public addEditLinhaExtratoCG(
    data: IConciliacaoBancariaAddEditLinhaExtratoCGModal,
    nconta: string,
    periodo: string
  ): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaNovaLinhaExtratoCG> {
    const command: IRestCmdConciliacaoBancariaNovaLinhaExtratoCG = {
      nLanc: data.nLanc,
      nConta: nconta,
      periodo: periodo,
      dc: data.dc,
      valor: data.valor,
      data: data.data,
      descricao: data.descricao
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaNovaLinhaExtratoCG>(EConciliacaoBancariaCommandType.AddEditLinhaExtratoCG, command);
  }

  public removeLinhaExtratoBanco(concilBancoLinIDs: Array<string>, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaRemoveLinhaExtratoBanco> {
    const command: IRestCmdConciliacaoBancariaRemoveLinhaExtratoBanco = {
      nConta: nconta,
      periodo: periodo,
      concilBancoLinIDs: concilBancoLinIDs
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaRemoveLinhaExtratoBanco>(EConciliacaoBancariaCommandType.RemoveLinhaExtratoBanco, command);
  }

  public removeTodasLinhasExtratoBanco(nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult {
    const command: IRestCmdConciliacaoBancaria = {
      nConta: nconta,
      periodo: periodo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancaria>(EConciliacaoBancariaCommandType.RemoveTodasLinhasExtratoBanco, command);
  }

  public desassociarExtratoBanco(concilBancoLinIDs: Array<string>, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaDesassociarExtratoBanco> {
    const command: IRestCmdConciliacaoBancariaDesassociarExtratoBanco = {
      nConta: nconta,
      periodo: periodo,
      concilBancoLinIDs: concilBancoLinIDs
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaDesassociarExtratoBanco>(EConciliacaoBancariaCommandType.DesassociarExtratoBanco, command);
  }

  public desassociarExtratoCG(nLancs: Array<string>, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaDesassociarExtratoCG> {
    const command: IRestCmdConciliacaoBancariaDesassociarExtratoCG = {
      nConta: nconta,
      periodo: periodo,
      nLancs: nLancs
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaDesassociarExtratoCG>(EConciliacaoBancariaCommandType.DesassociarExtratoCG, command);
  }

  public eliminaConciliacaoBancaria(concilBancoCabId: string, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaEliminaConciliacaoBancaria> {
    const command: IRestCmdConciliacaoBancariaEliminaConciliacaoBancaria = {
      nConta: nconta,
      periodo: periodo,
      concilBancoCabId: concilBancoCabId
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaEliminaConciliacaoBancaria>(EConciliacaoBancariaCommandType.EliminaConciliacaoBancaria, command);
  }

  public mudarEstadoConcilidacao(
    estado: EConciliacaoBancariaEstadoConciliacaoCab,
    nconta: string,
    periodo: string
  ): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaMudarEstadoConcilidacao> {
    const command: IRestCmdConciliacaoBancariaMudarEstadoConcilidacao = {
      nConta: nconta,
      periodo: periodo,
      estado: estado
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaMudarEstadoConcilidacao>(EConciliacaoBancariaCommandType.MudarEstadoConcilidacao, command);
  }

  public editarConcilidacao(data: Partial<IConciliacaoBancariaBancoCab>, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaEditar> {
    const command: IRestCmdConciliacaoBancariaEditar = {
      concilBancoCabID: data.concilBancoCabID,
      nConta: nconta,
      periodo: periodo,
      saldoInicial: data.saldoInicial,
      saldoFinal: data.saldoFinal,
      saldoInicialDC: data.saldoInicialDC,
      saldoFinalDC: data.saldoFinalDC
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaEditar>(EConciliacaoBancariaCommandType.Editar, command);
  }

  public eliminaConciliacaoBancariaMultiple(
    concilBancoCabIds: Array<string>,
    nconta: string,
    periodo: string
  ): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaEliminaConciliacaoBancariaMultiple> {
    const command: IRestCmdConciliacaoBancariaEliminaConciliacaoBancariaMultiple = {
      nConta: nconta,
      periodo: periodo,
      concilBancoCabIds: concilBancoCabIds
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaEliminaConciliacaoBancariaMultiple>(EConciliacaoBancariaCommandType.EliminaConciliacaoBancariaMultiple, command);
  }

  public carregaDocumentosAnteriores(nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaCarregaDocumentosAnteriores> {
    const command: IRestCmdConciliacaoBancariaCarregaDocumentosAnteriores = {
      nConta: nconta,
      periodo: periodo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaCarregaDocumentosAnteriores>(EConciliacaoBancariaCommandType.CarregaDocumentosAnteriores, command);
  }

  public obterSaldos(nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaObterSaldos> {
    const command: IRestCmdConciliacaoBancariaObterSaldos = {
      nConta: nconta,
      periodo: periodo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaObterSaldos>(EConciliacaoBancariaCommandType.ObterSaldos, command);
  }

  public eliminaTodasConciliacaoBancariaDaConta(
    nContaBanco: string,
    nconta: string,
    periodo: string
  ): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaEliminaTodasConciliacaoBancariaDaConta> {
    const command: IRestCmdConciliacaoBancariaEliminaTodasConciliacaoBancariaDaConta = {
      nContaBanco: nContaBanco,
      nConta: nconta,
      periodo: periodo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaEliminaTodasConciliacaoBancariaDaConta>(EConciliacaoBancariaCommandType.EliminaTodasConciliacaoBancariaDaConta, command);
  }

  public obterSaldoInicialBanco(nconta: string): Promise<IConciliacaoBancariaSaldoInicialBanco> {
    return new Promise<IConciliacaoBancariaSaldoInicialBanco>((resolve, reject) => {
      this._apiService
        .get<IConciliacaoBancariaSaldoInicialBanco>({
          url: `${this._path}/obterSaldoInicialBanco/${nconta}`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public getUltimoExtratoPeriodo(nconta: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this._apiService
        .get<string>({
          url: `${this._path}/getUltimoExtratoPeriodo/${nconta}`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public getProximoExtratoPeriodo(nconta: string, fechoAno: boolean): Promise<string> {
    return this._apiService
      .get<string>({
        params: {fechoAno: fechoAno},
        url: `${this._path}/getProximoExtratoPeriodo/${nconta}`
      })
      .then((response: HttpResponse<string>) => {
        return response.body;
      });
  }

  public getNContaAbrevMoedaEstrangeira(nconta: string): TServiceResponse<IConciliacaoBancariaNContaAbrevMoedaEstrangeira> {
    return this._apiService.get<IConciliacaoBancariaNContaAbrevMoedaEstrangeira>({
      url: `${this._path}/nContaAbrevMoedaEstrangeira/${nconta}`
    });
  }

  public atualizaNotasLinhaExtratoBanco(item: IConciliacaoBancariaLinItem): TServiceResponse<void> {
    return this._apiService.put<void, IConciliacaoBancariaLinItem>({
      url: `${this._path}/atualizanotasextratobanco`,
      body: item
    });
  }

  public removeLinhaExtratoCG(nLancs: Array<string>, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaRemoveLinhaExtratoCG> {
    const command: IRestCmdConciliacaoBancariaRemoveLinhaExtratoCG = {
      nConta: nconta,
      periodo: periodo,
      nLancs: nLancs
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaRemoveLinhaExtratoCG>(EConciliacaoBancariaCommandType.RemoveLinhaExtratoCG, command);
  }

  public editarLinhaExtratoBanco(
    data: IConciliacaoBancariaEditLinhaExtratoBanco,
    nconta: string,
    periodo: string
  ): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaEditarLinhaExtratoBanco> {
    const command: IRestCmdConciliacaoBancariaEditarLinhaExtratoBanco = {
      concilBancoLinID: data.concilBancoLinID,
      nConta: nconta,
      periodo: periodo,
      dc: data.dc,
      valor: data.valor,
      data: data.data,
      descricao: data.descricao
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaEditarLinhaExtratoBanco>(EConciliacaoBancariaCommandType.EditLinhaExtratoBanco, command);
  }

  public lancaLinhasNaContabilidadeEAssocia(
    concilLinIds: Array<string>,
    nconta: string,
    periodo: string,
    nContaLanc: string
  ): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaLancaLinhasNaContabilidadeEAssocia> {
    const command: IRestCmdConciliacaoBancariaLancaLinhasNaContabilidadeEAssocia = {
      nConta: nconta,
      periodo: periodo,
      concilLinIds: concilLinIds,
      nContaLanc: nContaLanc
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaLancaLinhasNaContabilidadeEAssocia>(EConciliacaoBancariaCommandType.LancaLinhasNaContabilidadeEAssocia, command);
  }

  public connectLines(nLancs: Array<string>, concilBancoLinIDs: Array<string>, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaConnectLines> {
    const command: IRestCmdConciliacaoBancariaConnectLines = {
      nConta: nconta,
      periodo: periodo,
      nLancs: nLancs,
      concilBancoLinIDs: concilBancoLinIDs
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaConnectLines>(EConciliacaoBancariaCommandType.ConnectLines, command);
  }

  public importaDocsPendentesBancoConcilAntiga(nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult {
    const command: IRestCmdConciliacaoBancaria = {
      nConta: nconta,
      periodo: periodo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancaria>(EConciliacaoBancariaCommandType.ImportaDocsPendentesBancoConcilAntiga, command);
  }

  public importaDocsPendentesContabConcilAntiga(nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult {
    const command: IRestCmdConciliacaoBancaria = {
      nConta: nconta,
      periodo: periodo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancaria>(EConciliacaoBancariaCommandType.ImportaDocsPendentesContabConcilAntiga, command);
  }

  public conciliarAutomaticamente(nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult {
    const command: IRestCmdConciliacaoBancaria = {
      nConta: nconta,
      periodo: periodo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancaria>(EConciliacaoBancariaCommandType.JustificaExtratoImportado, command);
  }

  public loadImportExtractoBancario(concilBancoCabID: string, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaLoadImportExtractoBancario> {
    const command: IRestCmdConciliacaoBancariaLoadImportExtractoBancario = {
      nConta: nconta,
      periodo: periodo,
      concilBancoCabID: concilBancoCabID
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaLoadImportExtractoBancario>(EConciliacaoBancariaCommandType.LoadImportExtractoBancario, command);
  }

  public saveExtractoBancario(nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult {
    const command: IRestCmdConciliacaoBancaria = {
      nConta: nconta,
      periodo: periodo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancaria>(EConciliacaoBancariaCommandType.GuardaExtractoBancario, command);
  }

  public getImportExtratoBancarioUrl(nconta: string, periodo: string): string {
    return `${this._path}/importExtratoBancario?nconta=${nconta}&periodo=${periodo}`;
  }

  public updateRegDiario(diario: number, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaUpdateRegDiario> {
    const command: IRestCmdConciliacaoBancariaUpdateRegDiario = {
      diario: diario,
      nConta: nconta,
      periodo: periodo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaUpdateRegDiario>(EConciliacaoBancariaCommandType.UpdateRegDiario, command);
  }

  public updateRegDescritivo(descritivo: number, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaUpdateRegDescritivo> {
    const command: IRestCmdConciliacaoBancariaUpdateRegDescritivo = {
      descritivo: descritivo,
      nConta: nconta,
      periodo: periodo
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaUpdateRegDescritivo>(EConciliacaoBancariaCommandType.UpdateRegDescritivo, command);
  }

  public loadTemplateImportExtrato(
    templateName: string,
    loadList: boolean,
    nconta: string,
    periodo: string
  ): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaLoadTemplateImportExtractoBancario> {
    const command: IRestCmdConciliacaoBancariaLoadTemplateImportExtractoBancario = {
      nConta: nconta,
      periodo: periodo,
      templateName: templateName,
      loadList: loadList
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaLoadTemplateImportExtractoBancario>(EConciliacaoBancariaCommandType.LoadTemplateImportExtractoBancario, command);
  }

  public saveTemplateImportExtrato(
    templateName: string,
    data: IConciliacaoBancariaImportTemplateData,
    nconta: string,
    periodo: string
  ): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaSaveTemplateImportExtractoBancario> {
    const command: IRestCmdConciliacaoBancariaSaveTemplateImportExtractoBancario = {
      nConta: nconta,
      periodo: periodo,
      templateName: templateName,
      template: data
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaSaveTemplateImportExtractoBancario>(EConciliacaoBancariaCommandType.SaveTemplateImportExtractoBancario, command);
  }

  public removeTemplateImportExtrato(templateName: string, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaRemoveTemplateImportExtractoBancario> {
    const command: IRestCmdConciliacaoBancariaRemoveTemplateImportExtractoBancario = {
      nConta: nconta,
      periodo: periodo,
      templateName: templateName
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaRemoveTemplateImportExtractoBancario>(EConciliacaoBancariaCommandType.RemoveTemplateImportExtractoBancario, command);
  }

  public setTemplateImportExtrato(templateName: string, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaSetTemplateImportExtractoBancario> {
    const command: IRestCmdConciliacaoBancariaSetTemplateImportExtractoBancario = {
      nConta: nconta,
      periodo: periodo,
      bancoTemplate: templateName
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaSetTemplateImportExtractoBancario>(EConciliacaoBancariaCommandType.SetTemplateImportExtractoBancario, command);
  }

  public setContaPendentesBanco(radicalConta: string, nconta: string, periodo: string): TConciliacaoBancariaCommandRawResult<IRestCmdConciliacaoBancariaSetContaPendentesBanco> {
    const command: IRestCmdConciliacaoBancariaSetContaPendentesBanco = {
      nConta: nconta,
      periodo: periodo,
      radicalConta: radicalConta
    };
    return this._newCommandRaw<IRestCmdConciliacaoBancariaSetContaPendentesBanco>(EConciliacaoBancariaCommandType.SetContaPendentesBanco, command);
  }

  private _newCommandRaw<T extends IRestCmdConciliacaoBancaria>(commandName: EConciliacaoBancariaCommandType, command: T): TConciliacaoBancariaCommandRawResult<T> {
    return new Promise<T>((resolve, reject) => {
      this._sendCommand<T>(commandName, command)
        .then((response: HttpResponse<T>) => {
          resolve(response.body);
        })
        .catch((reason: HttpErrorResponse) => {
          reject(reason);
        });
    });
  }

  private _sendCommand<T>(commandName: string, command: IRestCmdConciliacaoBancaria): TServiceResponse<TJsonConciliacaoBancariaCommandResponse<T>> {
    return this._apiService.patch<TJsonConciliacaoBancariaCommandResponse<T>, IRestCmdConciliacaoBancaria>({
      url: this._path,
      body: command,
      reportExceptions: true,
      params: {command: commandName}
    });
  }
}
