import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {AtRecibosRendasService} from '../../atRecibosRendas.service';
import {IJsonRRNIFConfig} from '../../jsonATRecibosRendas.interface';
import {IRRImportDoc} from '../../atRecibosRendas.module.interface';

@Component({
  selector: 'at-rr-nif-config-modal',
  templateUrl: './atRecibosRendas.nifConfig.modal.html'
})
export class AtRecibosRendasNifConfigModalComponent extends CGModalComponent<IJsonRRNIFConfig> implements OnInit {
  @Input() public nifLocatario: string;
  @Input() public nomeLocatario: string;
  @Input() public docsList: Array<IRRImportDoc>;
  @Input() public model: IJsonRRNIFConfig;

  public pocFilter: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _atFaturasRecibosVerdesService: AtRecibosRendasService
  ) {
    super(_injector);
    this.model = {nif: '', preDefinido: null, usaSempreRecolha: false, contaCorrente: null, nome: '', preDefinidoStr: '', contaCorrenteStr: ''};
  }

  public ngOnInit(): void {
    const filterRadicalClientes = `nConta=%'${this._configService.configurations.clientesFornecedores.radicalClientes}%'`;
    const filterRadicalOutrosDevedoresCredores = `nConta=%'${this._configService.configurations.clientesFornecedores.radicalOutrosDevedoresCredores}%'`;
    const filterRadical = `(${filterRadicalClientes}|${filterRadicalOutrosDevedoresCredores})`;
    this.pocFilter = `nif='${this.nifLocatario}'&cc=true&${filterRadical}`;
  }

  public close(): Promise<void> {
    this.disableClose();
    return this._atFaturasRecibosVerdesService
      .saveNifConfig({nifConfig: this.model, docsList: this.docsList})
      .then(() => {
        this.enableClose();
        super.close(this.model);
      })
      .catch((reason: unknown) => {
        this.enableClose();
        this._logger.error(reason);
      });
  }
}
