<div class="importador-saft-direct-config-modal">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <div class="modal-header-cab">
      <h5 class="modal-title" [translate]="'apuraiva.config.title'"></h5>
    </div>
  </div>

  <div class="modal-body">
    <div class="row">
      <pl-form>
        <pl-group>
          <label>
            <span [translate]="'apuraiva.fields.diarios'"></span>
          </label>
          <edit>
            <entity-autocomplete
              entity="diarios"
              attrName="diario"
              [model]="model"
              (selectedKeyChange)="model.nDiario = $event"
              (evtSelectedDescriptionChanged)="model.nomeDiario = $event"
              [fieldsMap]="{nome: 'nomeDiario'}">
            </entity-autocomplete>
          </edit>
        </pl-group>
        <pl-group>
          <label>
            <span [translate]="'apuraiva.fields.descriptive'"></span>
          </label>
          <edit>
            <entity-autocomplete
              entity="descritivos"
              attrName="descritivo"
              [model]="model"
              (selectedKeyChange)="model.nDescritivo = $event"
              (evtSelectedDescriptionChanged)="model.nomeDescritivo = $event"
              [fieldsMap]="{nDescrit: 'nDescritivo', nome: 'nomeDescritivo'}">
            </entity-autocomplete>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button" plPromise>
    <pl-button klass="btn-primary" [onClick]="close"><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>
    <pl-button klass="btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-ban"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
