<div class="modelo22 entity-detail-form">
  <pl-alert *ngIf="showAlertDerrama" type="error" [closeable]="false">
    <div class="hyperlink" [translate]="'modelo22.messages.derramaErrorMessage'" (click)="openAdicionalDerramaMunicipal()"></div>
  </pl-alert>

  <div *ngIf="errorMessages.erros.length > 0" [plAnimationShake]="errorMessages.erros.length > 0">
    <pl-alert type="danger" *ngIf="errorMessages.erros.length > 0" (evtClosed)="errorMessages.erros.length = 0">
      <div [translate]="errorMessages.tipoValidacao !== eTiposValidar.Default ? errorMessages.mensagemTipoHeader : 'modelo30.errors.errorHeader'"></div>
      <hr class="mt-1 mb-1" />
      <div *ngFor="let message of errorMessages.erros">
        <ng-container [ngSwitch]="!!message.mensagem">
          <ng-container *ngSwitchCase="true">
            <strong [innerHTML]="message.nomeCampo + ': ' + message.mensagem"></strong>
          </ng-container>
          <ng-container *ngSwitchCase="false">
            <strong [innerHTML]="message.mensagemOriginal"></strong>
          </ng-container>
        </ng-container>
      </div>
    </pl-alert>
  </div>

  <pl-tabs [theme]="'style3'" [activeId]="activeTab" [callback]="tabCallback" (evtSelected)="changedTab($event)" [plPromise]="promise">
    <pl-tab [id]="typeNavAnexos.Anexos">
      <div *plTabTitle ngbDropdown>
        <button type="button" class="btn btn-link p-0" ngbDropdownToggle [translate]="'modelo22.tabs.anexos'"></button>
        <div ngbDropdownMenu class="dropdown-menu-anexos">
          <button type="button" ngbDropdownItem class="fw-bold"><span [translate]="'modelo22.tabs.rosto'"></span></button>
          <button type="button" ngbDropdownItem class="fw-bold" *ngIf="hasAnexoA">
            <div class="d-flex align-items-center">
              <span class="me-auto" [translate]="'modelo22.tabs.anexoA'" (click)="activeTab = typeNavAnexos.AnexoA"></span>
              <div class="remove-anexo-icon" (click)="removeAnexo('A')">
                <i class="fa fa-trash-o" aria-hidden="true" style=""></i>
              </div>
            </div>
          </button>
          <button type="button" ngbDropdownItem class="fw-bold" *ngIf="hasAnexoC">
            <div class="d-flex align-items-center">
              <span class="me-auto" [translate]="'modelo22.tabs.anexoC'" (click)="activeTab = typeNavAnexos.AnexoC"></span>
              <div class="remove-anexo-icon" (click)="removeAnexo('C')">
                <i class="fa fa-trash-o" aria-hidden="true" style=""></i>
              </div>
            </div>
          </button>
          <button type="button" ngbDropdownItem class="fw-bold" *ngIf="hasAnexoD">
            <div class="d-flex align-items-center" style="">
              <span class="me-auto" [translate]="'modelo22.tabs.anexoD'" (click)="activeTab = typeNavAnexos.AnexoD"></span>
              <div class="remove-anexo-icon" (click)="removeAnexo('D')">
                <i class="fa fa-trash-o" aria-hidden="true" style=""></i>
              </div>
            </div>
          </button>
          <button type="button" ngbDropdownItem class="fw-bold" *ngIf="hasAnexoE">
            <div class="d-flex align-items-center" style="">
              <span class="me-auto" [translate]="'modelo22.tabs.anexoE'" (click)="activeTab = typeNavAnexos.AnexoE"></span>
              <div class="remove-anexo-icon" (click)="removeAnexo('E')">
                <i class="fa fa-trash-o" aria-hidden="true" style=""></i>
              </div>
            </div>
          </button>
          <button type="button" ngbDropdownItem class="fw-bold" *ngIf="hasAnexoF">
            <div class="d-flex align-items-center" style="">
              <span class="me-auto" [translate]="'modelo22.tabs.anexoF'" (click)="activeTab = typeNavAnexos.AnexoF"></span>
              <div class="remove-anexo-icon" (click)="removeAnexo('F')">
                <i class="fa fa-trash-o" aria-hidden="true" style=""></i>
              </div>
            </div>
          </button>
          <button type="button" ngbDropdownItem class="fw-bold" *ngIf="hasAnexoG">
            <div class="d-flex align-items-center" style="">
              <span class="me-auto" [translate]="'modelo22.tabs.anexoG'" (click)="activeTab = typeNavAnexos.AnexoG"></span>
              <div class="remove-anexo-icon" (click)="removeAnexo('G')">
                <i class="fa fa-trash-o" aria-hidden="true" style=""></i>
              </div>
            </div>
          </button>
          <div class="dropdown-divider"></div>
          <h6 class="dropdown-header" disabled="true" [translate]="'modelo22.tabs.adicionarAnexo'"></h6>
          <div class="dropdown-divider"></div>
          <button type="button" ngbDropdownItem (click)="evaluateMenuAnexos('A', true)" *ngIf="!hasAnexoA" [translate]="'modelo22.tabs.anexoA'"></button>
          <button type="button" ngbDropdownItem (click)="evaluateMenuAnexos('C', true)" *ngIf="!hasAnexoC" [translate]="'modelo22.tabs.anexoC'"></button>
          <button type="button" ngbDropdownItem (click)="evaluateMenuAnexos('D', true)" *ngIf="!hasAnexoD" [translate]="'modelo22.tabs.anexoD'"></button>
          <button type="button" ngbDropdownItem (click)="evaluateMenuAnexos('E', true)" *ngIf="!hasAnexoE" [translate]="'modelo22.tabs.anexoE'"></button>
          <button type="button" ngbDropdownItem (click)="evaluateMenuAnexos('F', true)" *ngIf="!hasAnexoF" [translate]="'modelo22.tabs.anexoF'"></button>
          <button type="button" ngbDropdownItem (click)="evaluateMenuAnexos('G', true)" *ngIf="!hasAnexoG" [translate]="'modelo22.tabs.anexoG'"></button>
        </div>
      </div>
    </pl-tab>

    <pl-tab [id]="typeNavAnexos.Rosto">
      <div *plTabTitle>
        <span class="fw-bold" [translate]="'modelo22.tabs.rosto'"></span>
      </div>

      <div *plTabContent>
        <pl-alert *ngIf="modelo22.rosto.listaDeErros.length > 0" type="warning" [closeable]="true">
          <div *ngFor="let erro of modelo22.rosto.listaDeErros" [innerHTML]="erro | translate"></div>
        </pl-alert>
        <modelo22-anexos-rosto [modelo22]="modelo22" (evtChangedModelo22)="changedModelo22($event)"></modelo22-anexos-rosto>
      </div>
    </pl-tab>

    <pl-tab [id]="typeNavAnexos.AnexoA" *ngIf="hasAnexoA">
      <div *plTabTitle>
        <span [translate]="'modelo22.tabs.anexoA'"></span>
      </div>

      <div *plTabContent>
        <pl-alert *ngIf="modelo22.anexoA.listaDeErros.length > 0" type="warning" [closeable]="true">
          <div *ngFor="let erro of modelo22.anexoA.listaDeErros" [innerHTML]="erro | translate"></div>
        </pl-alert>
        <modelo22-anexos-anexoa [modelo22]="modelo22" (evtChangedModelo22)="changedModelo22($event)"></modelo22-anexos-anexoa>
      </div>
    </pl-tab>

    <pl-tab [id]="typeNavAnexos.AnexoC" *ngIf="hasAnexoC">
      <div *plTabTitle>
        <span [translate]="'modelo22.tabs.anexoC'"></span>
      </div>

      <div *plTabContent>
        <pl-alert *ngIf="modelo22.anexoC.listaDeErros.length > 0" type="warning" [closeable]="true">
          <div *ngFor="let erro of modelo22.anexoC.listaDeErros" [innerHTML]="erro | translate"></div>
        </pl-alert>
        <modelo22-anexos-anexoc [modelo22]="modelo22" (evtChangedModelo22)="changedModelo22($event)"></modelo22-anexos-anexoc>
      </div>
    </pl-tab>

    <pl-tab [id]="typeNavAnexos.AnexoD" *ngIf="hasAnexoD">
      <div *plTabTitle>
        <span [translate]="'modelo22.tabs.anexoD'"></span>
      </div>

      <div *plTabContent>
        <pl-alert *ngIf="modelo22.anexoD.listaDeErros.length > 0" type="warning" [closeable]="true">
          <div *ngFor="let erro of modelo22.anexoD.listaDeErros" [innerHTML]="erro | translate"></div>
        </pl-alert>
        <modelo22-anexos-anexod [modelo22]="modelo22" (evtChangedModelo22)="changedModelo22($event)"></modelo22-anexos-anexod>
      </div>
    </pl-tab>

    <pl-tab [id]="typeNavAnexos.AnexoE" *ngIf="hasAnexoE">
      <div *plTabTitle>
        <span [translate]="'modelo22.tabs.anexoE'"></span>
      </div>

      <div *plTabContent>
        <pl-alert *ngIf="modelo22.anexoE.listaDeErros.length > 0" type="warning" [closeable]="true">
          <div *ngFor="let erro of modelo22.anexoE.listaDeErros" [innerHTML]="erro | translate"></div>
        </pl-alert>
        <modelo22-anexos-anexoe [modelo22]="modelo22" (evtChangedModelo22)="changedModelo22($event)"></modelo22-anexos-anexoe>
      </div>
    </pl-tab>

    <pl-tab [id]="typeNavAnexos.AnexoF" *ngIf="hasAnexoF">
      <div *plTabTitle>
        <span [translate]="'modelo22.tabs.anexoF'"></span>
      </div>

      <div *plTabContent>
        <pl-alert *ngIf="modelo22.anexoF.listaDeErros.length > 0" type="warning" [closeable]="true">
          <div *ngFor="let erro of modelo22.anexoF.listaDeErros" [innerHTML]="erro | translate"></div>
        </pl-alert>
        <modelo22-anexos-anexof [modelo22]="modelo22" (evtChangedModelo22)="changedModelo22($event)"></modelo22-anexos-anexof>
      </div>
    </pl-tab>

    <pl-tab [id]="typeNavAnexos.AnexoG" *ngIf="hasAnexoG">
      <div *plTabTitle>
        <span [translate]="'modelo22.tabs.anexoG'"></span>
      </div>

      <div *plTabContent>
        <pl-alert *ngIf="modelo22.anexoG.listaDeErros.length > 0" type="warning" [closeable]="true">
          <div *ngFor="let erro of modelo22.anexoG.listaDeErros" [innerHTML]="erro | translate"></div>
        </pl-alert>
        <modelo22-anexos-anexog [modelo22]="modelo22" (evtChangedModelo22)="changedModelo22($event)"></modelo22-anexos-anexog>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
