import {EnvironmentProviders, makeEnvironmentProviders} from '@angular/core';
import {AmaliaConfiguration} from '@centralgest/amalia-api-angular';
import {ApiService} from '../core/services/api/api.service';

import {AMALIA_INTENT_HANDLER_CLIENTES_VER_FICHA} from '../core/entities/clifos/amalia/clientes/clientes.ver.ficha.amalia.intent.handler';
import {AMALIA_INTENT_HANDLER_CONTABILIDADE_BALANCETES} from '../core/modules/portalcontabilidade/estatistica/balancetes/amalia/contabilidade.balancetes.amalia.intent.handler';
import {AMALIA_INTENT_HANDLER_FORNECEDORES_VER_FICHA} from '../core/entities/clifos/amalia/fornecedores/fornecedores.ver.ficha.amalia.intent.handler';
import {AMALIA_INTENT_HANDLER_MOVIMENTOS_ABERTO} from '../core/modules/portalcontabilidade/estatistica/movimentosemaberto/amalia/movimentoemaberto.amalia.intent.handler';
import {AMALIA_INTENT_HANDLER_EXTRATOS_GRID} from '../core/modules/portalcontabilidade/estatistica/extratosgrid/amalia/extratosgrid.amalia.intent.handler';
import {AMALIA_INTENT_HANDLER_CONCILIACAO_EFATURAS} from '../core/modules/portalcontabilidade/conciliacaoEFaturas/amalia/conciliacaoEFaturas.amalia.intent.handler';
import {AMALIA_INTENT_HANDLER_DECLARACAO_IVA} from '../core/modules/portalcontabilidade/manutencao/declaracaoiva/periodica/amalia/contabilidade.declaracaoIva.amalia.intent.handler';
import {AMALIA_INTENT_HANDLER_SVAT} from '../core/modules/portalcontabilidade/manutencao/svat/amalia/contabilidade.svat.amalia.intent.handler';

const AMALIA_INTENT_HANDLERS = [
  AMALIA_INTENT_HANDLER_CONTABILIDADE_BALANCETES,
  AMALIA_INTENT_HANDLER_CLIENTES_VER_FICHA,
  AMALIA_INTENT_HANDLER_FORNECEDORES_VER_FICHA,
  AMALIA_INTENT_HANDLER_MOVIMENTOS_ABERTO,
  AMALIA_INTENT_HANDLER_EXTRATOS_GRID,
  AMALIA_INTENT_HANDLER_CONCILIACAO_EFATURAS,
  AMALIA_INTENT_HANDLER_DECLARACAO_IVA,
  AMALIA_INTENT_HANDLER_SVAT
];

export function provideAmaliaIntentHandlers(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: AmaliaConfiguration,
      deps: [ApiService],
      useFactory: (apiService: ApiService) =>
        new AmaliaConfiguration({
          basePath: apiService.path.amalia
        })
    },
    ...AMALIA_INTENT_HANDLERS
  ]);
}
