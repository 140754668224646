import dxChart, {InitializedEvent, LegendClickEvent, PointHoverChangedEvent} from 'devextreme/viz/chart';
import dxChartPie, {InitializedEvent as PieChartInitializedEvent, LegendClickEvent as PieChartLegendClickEvent} from 'devextreme/viz/pie_chart';
import {Component, Injector, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {EPlDashboardType, IPlDashboard, IPlToolbarItem, IPlToolbarMenuItem, PlI18nService} from 'pl-comps-angular';
import {
  CHART_LEGEND,
  CHART_TITLE,
  chartCombineSeriesTooltip,
  chartLabelWithPercent,
  chartPieToggleSeriesVisibility,
  chartToggleSeriesVisibility
} from '../../../../components/devexpress/charts/utilities/devexpress.chart.utilities';
import {DIGITS_INFO_TWO_DECIMALS} from '../../../../../common/data';
import {DEVEXPRESS_CHART_BLUE_COLOR, IDevExpressChart, IDevExpressChartLabelCustomizeText} from '../../../../components/devexpress/charts/devexpress.charts.interface';
import {IDevExpressPieChart} from '../../../../components/devexpress/charts/pie/devexpress.pie.chart.interface';
import {IJsonDashboardsCodigoNumberValor, IJsonDashboardsValor, IJsonDashboardsValorPercentagem} from '../../../../interfaces/jsonDashboards.interface';
import {ModuloComponent} from '../../../../components/module/module.component';
import {DashboardAtivosService} from '../dashboardAtivos.module.service';
import {IJsonDashboardAtivoInconsistencias, IJsonDashboardAtivoResumo, IJsonDashboardAtivoTop} from '../jsonDashboardAtivos.module.interface';
import {EDashboardAtivosRadical, IDashboardAtivosPie} from '../dashboardAtivos.module.interface';
import {monthNameShort} from '../../../../../common/dates';
import {MODULE_NAME_ATIVOS_AQUISICAO_CONTAB} from '../../ativosaquisicaocontab/ativosAquisicaoContab.module.interface';
import {orderBy, upperFirst} from 'lodash-es';
import {MODULE_NAME_ATIVOS_AQUISICAO_INVEST_EM_CURSO_POR_DOC} from '../../ativosaquisicaoinvestemcurso/ativosAquisicaoInvestEmCursoPorDoc.module.interface';

const ULTIMOS_DOZE_MESES = 12;

@Component({
  selector: 'module-dashboards-ativos',
  templateUrl: './dashboardAtivos.module.component.html'
})
export class DashboardAtivosModuleComponent extends ModuloComponent implements OnInit {
  public readonly chartPieAtivosTangiveis: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly chartPieAtivosIntangiveis: IDevExpressPieChart<'DoughnutSeries'>;
  public readonly chartAtivosDepMonth: IDevExpressChart<'BarSeries'>;

  public nFichasAtivos: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public nAquisicoesAno: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public nVendasAbatesAno: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public valorAquisicao: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public depreciacoesAcumuladas: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public valorAtual: Promise<IPlDashboard<EPlDashboardType.Tile>>;
  public ativosTopPorDoc: Promise<IPlDashboard<EPlDashboardType.Table>>;
  public chartPieAtivosTangiveisData: Array<IDashboardAtivosPie>;
  public chartPieAtivosIntangiveisData: Array<IDashboardAtivosPie>;
  public chartAtivosDepMonthData: Array<IJsonDashboardsCodigoNumberValor>;
  public inconsistContab: boolean;
  public inconsistInvestEmCurso: boolean;

  private readonly _menuYears: IPlToolbarItem;

  private _fetchPromise: Promise<IJsonDashboardAtivoResumo>;
  private _chartPieAtivosTangiveisInstance: dxChartPie;
  private _chartPieAtivosIntangiveisInstance: dxChartPie;
  private _chartAtivosDepMonthInstance: dxChart;
  private _selectedYear: IPlToolbarMenuItem<number>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plI18nService: PlI18nService,
    private readonly _dashboardsService: DashboardAtivosService
  ) {
    super(_injector);
    const year: number = this.configurations.empresa.anoEmCursoIRC;

    this.chartPieAtivosTangiveis = {
      type: 'doughnut',
      title: {
        ...CHART_TITLE,
        text: this._translateService.instant('dashboards.ativos.pie.ativosTangiveis')
      },
      series: [
        {
          argumentField: 'name',
          valueField: 'value',
          label: {
            visible: true,
            customizeText: this.customizeLabelChartPieFn,
            connector: {
              visible: true
            }
          }
        }
      ],
      tooltip: {
        enabled: true,
        contentTemplate: 'customTooltipTangiveis'
      },
      legend: CHART_LEGEND
    };
    this.chartPieAtivosIntangiveis = {
      type: 'doughnut',
      title: {
        ...CHART_TITLE,
        text: this._translateService.instant('dashboards.ativos.pie.ativosInTangiveis')
      },
      series: [
        {
          argumentField: 'name',
          valueField: 'value',
          label: {
            visible: true,
            customizeText: this.customizeLabelChartPieFn,
            connector: {
              visible: true
            }
          }
        }
      ],
      tooltip: {
        enabled: true,
        contentTemplate: 'customTooltipIntangiveis'
      },
      legend: CHART_LEGEND
    };
    this.chartAtivosDepMonth = {
      commonSeriesSettings: {
        argumentField: 'nome',
        type: 'bar',
        ignoreEmptyPoints: true
      },
      title: {
        ...CHART_TITLE,
        text: this._translateService.instant('dashboards.ativos.bar.title')
      },
      series: [{valueField: 'valor', color: DEVEXPRESS_CHART_BLUE_COLOR, name: this._translateService.instant('dashboards.ativos.bar.depMonth')}],
      tooltip: {
        location: 'edge',
        paddingLeftRight: 15,
        paddingTopBottom: 10,
        contentTemplate: 'customTooltipDepMonth',
        shared: true
      },
      legend: CHART_LEGEND,
      stickyHovering: true
    };

    this._selectedYear = {
      caption: String(year),
      data: year,
      active: true,
      click: (menuItem: IPlToolbarMenuItem<number>) => {
        this._changedAno(menuItem);
      }
    };
    const anos: Array<IPlToolbarMenuItem<number>> = [
      {
        caption: String(year + 1),
        data: year + 1,
        active: false,
        click: (menuItem: IPlToolbarMenuItem<number>) => {
          this._changedAno(menuItem);
        }
      },
      this._selectedYear
    ];
    this._menuYears = {order: 102, id: 'years', caption: this._getCaptionYear(), type: 'dropdown', menu: anos};
    this._loadData(false).then(() => {
      this.btnRefresh.visible = true;
      this.btnRefresh.click = () => this._loadData(true);
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.toolbar.addButton(this._menuYears);
  }

  public onInitializedChartPieAtivosTangiveis(event: PieChartInitializedEvent): void {
    this._chartPieAtivosTangiveisInstance = event.component;
    this._chartPieAtivosTangiveisInstance.showLoadingIndicator();
  }

  public onInitializedChartPieAtivosIntangiveis(event: PieChartInitializedEvent): void {
    this._chartPieAtivosIntangiveisInstance = event.component;
    this._chartPieAtivosIntangiveisInstance.showLoadingIndicator();
  }

  public onInitializedChartTotDepMonth(event: InitializedEvent): void {
    this._chartAtivosDepMonthInstance = event.component;
    this._chartAtivosDepMonthInstance.showLoadingIndicator();
  }

  public onChartPointHoverChanged(event: PointHoverChangedEvent): void {
    chartCombineSeriesTooltip(event);
  }

  public onChartLegendClick(event: LegendClickEvent): void {
    chartToggleSeriesVisibility(event);
  }

  public onLegendClick(event: PieChartLegendClickEvent): void {
    chartPieToggleSeriesVisibility(event);
  }

  public redirectToInvestEmCurso(): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_ATIVOS_AQUISICAO_INVEST_EM_CURSO_POR_DOC, params: {doSearch: true}});
  }

  public redirectToAquisicaoContab(): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: MODULE_NAME_ATIVOS_AQUISICAO_CONTAB});
  }

  public readonly customizeLabelChartPieFn = (pointInfo: IDevExpressChartLabelCustomizeText): string => this._chartLabelWithPercent(pointInfo);

  protected _formatTable(items: Array<IJsonDashboardAtivoTop>): Array<object> {
    return items.map<object>((item: IJsonDashboardAtivoTop) => {
      const tableItem: object = {};
      tableItem[this._translateService.instant('dashboards.ativos.table.codAmortizacao')] = item.codAmortizacao;
      tableItem[this._translateService.instant('dashboards.ativos.table.nomeAmortizacao')] = item.nomeAmortizacao;
      tableItem[this._translateService.instant('dashboards.ativos.table.nFichas')] = String(item.nFichas);
      tableItem[this._translateService.instant('dashboards.ativos.table.valorDep')] = item.valorDep;
      tableItem[this._translateService.instant('dashboards.ativos.table.valorAq')] = item.valorAq;
      tableItem[this._translateService.instant('dashboards.ativos.table.valorLiq')] = item.valorLiq;
      tableItem[this._translateService.instant('dashboards.ativos.table.percentDep')] = item.percentDep;
      return tableItem;
    });
  }

  private async _loadData(force: boolean): Promise<void> {
    this._evaluateInconsistency();
    this._fetchPromise = this._dashboardsService.ativosResumo(this._selectedYear.data, force).then((response: HttpResponse<IJsonDashboardAtivoResumo>) => response.body);

    this.nFichasAtivos = this._refreshNFichasAtivos();
    this.nAquisicoesAno = this._refreshNAquisicoesAno();
    this.nVendasAbatesAno = this._refreshNVendasAbatesAno();
    this.valorAquisicao = this._refreshValorAquisicao();
    this.depreciacoesAcumuladas = this._refreshDepreciacoesAcumuladas();
    this.valorAtual = this._refreshValorAtual();
    this.ativosTopPorDoc = this._refreshAtivosTopPorDoc();

    await Promise.all([
      this.nFichasAtivos,
      this.nAquisicoesAno,
      this.nVendasAbatesAno,
      this.valorAquisicao,
      this.depreciacoesAcumuladas,
      this.valorAtual,
      this.ativosTopPorDoc,
      this._refreshAtivosDepMonth(),
      this._refreshAtivosTangiveis(),
      this._refreshAtivosIntangiveis()
    ]);
  }

  private async _refreshNFichasAtivos(): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    const ativoResumo: IJsonDashboardAtivoResumo = await this._fetchPromise;
    const valores: IJsonDashboardsValor = ativoResumo.nFichasAtivos.valores[0];
    return {
      type: EPlDashboardType.Tile,
      title: this._translateService.instant('dashboards.ativos.nFichasAtivos'),
      subtitle: this._translateService.instant('dashboards.ativos.bensAtivos'),
      icon: 'fa-building',
      style: 'primary',
      data: valores.valor
    };
  }

  private async _refreshNAquisicoesAno(): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    const ativoResumo: IJsonDashboardAtivoResumo = await this._fetchPromise;
    const valores: IJsonDashboardsValor = ativoResumo.nAquisicoesAno.valores[0];
    return {
      type: EPlDashboardType.Tile,
      title: this._translateService.instant('dashboards.ativos.nAquisicoesAno'),
      subtitle: this._selectedYear.data.toString(),
      icon: 'fa-building',
      style: 'primary',
      data: valores.valor
    };
  }

  private async _refreshNVendasAbatesAno(): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    const ativoResumo: IJsonDashboardAtivoResumo = await this._fetchPromise;
    const valores: IJsonDashboardsValor = ativoResumo.nVendasAbatesAno.valores[0];
    return {
      type: EPlDashboardType.Tile,
      title: this._translateService.instant('dashboards.ativos.nVendasAbatesAno'),
      subtitle: this._selectedYear.data.toString(),
      icon: 'fa-building',
      style: 'primary',
      data: valores.valor
    };
  }

  private async _refreshValorAquisicao(): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    const ativoResumo: IJsonDashboardAtivoResumo = await this._fetchPromise;
    const valores: IJsonDashboardsValor = ativoResumo.valorAquisicao.valores[0];
    return {
      type: EPlDashboardType.Tile,
      title: this._translateService.instant('dashboards.ativos.valorAquisicao'),
      subtitle: this._translateService.instant('dashboards.ativos.bensAtivos'),
      icon: 'fa-money',
      style: 'primary',
      data: this._plI18nService.formatCurrency(valores.valor, DIGITS_INFO_TWO_DECIMALS)
    };
  }

  private async _refreshDepreciacoesAcumuladas(): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    const ativoResumo: IJsonDashboardAtivoResumo = await this._fetchPromise;
    const valores: IJsonDashboardsValor = ativoResumo.depreciacoesAcumuladas.valores[0];
    return {
      type: EPlDashboardType.Tile,
      title: this._translateService.instant('dashboards.ativos.depreciacoesAcumuladas'),
      subtitle: this._translateService.instant('dashboards.ativos.bensAtivos'),
      icon: 'fa-money',
      style: 'primary',
      data: this._plI18nService.formatCurrency(valores.valor, DIGITS_INFO_TWO_DECIMALS)
    };
  }

  private async _refreshValorAtual(): Promise<IPlDashboard<EPlDashboardType.Tile>> {
    const ativoResumo: IJsonDashboardAtivoResumo = await this._fetchPromise;
    const valores: IJsonDashboardsValor = ativoResumo.valorAtual.valores[0];
    return {
      type: EPlDashboardType.Tile,
      title: this._translateService.instant('dashboards.ativos.valorAtual'),
      subtitle: this._translateService.instant('dashboards.ativos.bensAtivos'),
      icon: 'fa-money',
      style: 'primary',
      data: this._plI18nService.formatCurrency(valores.valor, DIGITS_INFO_TWO_DECIMALS)
    };
  }

  private async _refreshAtivosTangiveis(): Promise<void> {
    const ativoResumo: IJsonDashboardAtivoResumo = await this._fetchPromise;

    this.chartPieAtivosTangiveisData = ativoResumo.ativosFixosTangiveis.valores
      .filter((item: IJsonDashboardsValorPercentagem) => item.valor && item.valor !== 0)
      .map<IDashboardAtivosPie>((dataItem: IJsonDashboardsValor) => {
        return {
          nFichas: dataItem.codigo,
          name: dataItem.nome,
          value: dataItem.valor,
          percent: dataItem.percentagem,
          descRadical: this._getDescRadical(dataItem.nome)
        };
      });

    if (!this.destroyed) {
      this._chartPieAtivosTangiveisInstance.hideLoadingIndicator();
    }
    return Promise.resolve();
  }

  private async _refreshAtivosIntangiveis(): Promise<void> {
    const ativoResumo: IJsonDashboardAtivoResumo = await this._fetchPromise;
    this.chartPieAtivosIntangiveisData = ativoResumo.ativosFixosInTangiveis.valores
      .filter((item: IJsonDashboardsValorPercentagem) => item.valor && item.valor !== 0)
      .map<IDashboardAtivosPie>((dataItem: IJsonDashboardsValor) => {
        return {
          nFichas: dataItem.codigo,
          name: dataItem.nome,
          value: dataItem.valor,
          percent: dataItem.percentagem,
          descRadical: this._getDescRadical(dataItem.nome)
        };
      });

    if (!this.destroyed) {
      this._chartPieAtivosIntangiveisInstance.hideLoadingIndicator();
    }
  }

  private async _refreshAtivosDepMonth(): Promise<void> {
    const ativoResumo: IJsonDashboardAtivoResumo = await this._fetchPromise;
    this.chartAtivosDepMonthData = ativoResumo.totDepMonth.valores.map<IJsonDashboardsCodigoNumberValor>((dataItem: IJsonDashboardsCodigoNumberValor) => {
      const month = Number(dataItem.codigo);
      return {
        nome: upperFirst(monthNameShort(month - 1)),
        valor: dataItem.valor,
        codigo: month
      };
    });
    this.chartAtivosDepMonthData = orderBy(this.chartAtivosDepMonthData, (item: IJsonDashboardsCodigoNumberValor) => item.codigo, 'asc');

    // add missing months
    let accumulatedValue = 0;
    for (let i = 1; i <= ULTIMOS_DOZE_MESES; i++) {
      const monthItem: IJsonDashboardsCodigoNumberValor = this.chartAtivosDepMonthData.find((item: IJsonDashboardsCodigoNumberValor) => item.codigo === i);
      if (!monthItem) {
        this.chartAtivosDepMonthData.push({nome: upperFirst(monthNameShort(i - 1)), valor: 0, codigo: i, valorAcumulado: accumulatedValue});
      } else {
        accumulatedValue += monthItem.valor;
        monthItem.valorAcumulado = accumulatedValue;
      }
    }

    if (!this.destroyed) {
      this._chartAtivosDepMonthInstance.hideLoadingIndicator();
    }
  }

  private async _refreshAtivosTopPorDoc(): Promise<IPlDashboard<EPlDashboardType.Table>> {
    const ativoResumo: IJsonDashboardAtivoResumo = await this._fetchPromise;
    return {
      type: EPlDashboardType.Table,
      title: this._translateService.instant('dashboards.ativos.table.topAtivos'),
      data: this._formatTable(ativoResumo.ativoTop)
    };
  }

  private _getCaptionYear(): string {
    return this._translateService.instant('toolbar.year', {value: this._selectedYear.data});
  }

  private _changedAno(menuItem: IPlToolbarMenuItem<number>): void {
    if (menuItem === this._selectedYear) {
      return;
    }
    if (this._selectedYear) {
      this._selectedYear.active = false;
    }
    this._selectedYear = menuItem;
    this._selectedYear.active = true;
    this._menuYears.caption = this._getCaptionYear();
    this.btnRefresh.promise = this._loadData(true);
  }

  private _chartLabelWithPercent(pointInfo: IDevExpressChartLabelCustomizeText): string {
    if (pointInfo.percent === 0) {
      return '';
    }
    return `${pointInfo.argument} - ${chartLabelWithPercent(pointInfo, this._plI18nService, false)}`;
  }

  private _evaluateInconsistency(): void {
    this.inconsistContab = this.inconsistInvestEmCurso = false;
    this._dashboardsService.getAtivosInconsistencias().then((response: HttpResponse<IJsonDashboardAtivoInconsistencias>) => {
      this.inconsistContab = response.body.consisContab;
      this.inconsistInvestEmCurso = response.body.investEmCurso;
    });
  }

  private _getDescRadical(radical: string): string {
    switch (radical) {
      case EDashboardAtivosRadical.Rdl431:
        return this._translateService.instant('dashboards.ativos.radical.rdl431');
      case EDashboardAtivosRadical.Rdl432:
        return this._translateService.instant('dashboards.ativos.radical.rdl432');
      case EDashboardAtivosRadical.Rdl433:
        return this._translateService.instant('dashboards.ativos.radical.rdl433');
      case EDashboardAtivosRadical.Rdl434:
        return this._translateService.instant('dashboards.ativos.radical.rdl434');
      case EDashboardAtivosRadical.Rdl435:
        return this._translateService.instant('dashboards.ativos.radical.rdl435');
      case EDashboardAtivosRadical.Rdl436:
        return this._translateService.instant('dashboards.ativos.radical.rdl436');
      case EDashboardAtivosRadical.Rdl437:
        return this._translateService.instant('dashboards.ativos.radical.rdl437');
      case EDashboardAtivosRadical.Rdl441:
        return this._translateService.instant('dashboards.ativos.radical.rdl441');
      case EDashboardAtivosRadical.Rdl442:
        return this._translateService.instant('dashboards.ativos.radical.rdl442');
      case EDashboardAtivosRadical.Rdl443:
        return this._translateService.instant('dashboards.ativos.radical.rdl443');
      case EDashboardAtivosRadical.Rdl444:
        return this._translateService.instant('dashboards.ativos.radical.rdl444');
      case EDashboardAtivosRadical.Rdl445:
        return this._translateService.instant('dashboards.ativos.radical.rdl445');
      case EDashboardAtivosRadical.Rdl446:
        return this._translateService.instant('dashboards.ativos.radical.rdl446');
      case EDashboardAtivosRadical.Rdl447:
        return this._translateService.instant('dashboards.ativos.radical.rdl447');
      case EDashboardAtivosRadical.Rdl421:
        return this._translateService.instant('dashboards.ativos.radical.rdl421');
      case EDashboardAtivosRadical.Rdl422:
        return this._translateService.instant('dashboards.ativos.radical.rdl422');
      case EDashboardAtivosRadical.Rdl423:
        return this._translateService.instant('dashboards.ativos.radical.rdl423');
      case EDashboardAtivosRadical.Rdl424:
        return this._translateService.instant('dashboards.ativos.radical.rdl424');
      case EDashboardAtivosRadical.Rdl425:
        return this._translateService.instant('dashboards.ativos.radical.rdl425');
      case EDashboardAtivosRadical.Rdl426:
        return this._translateService.instant('dashboards.ativos.radical.rdl426');
      case EDashboardAtivosRadical.Rdl427:
        return this._translateService.instant('dashboards.ativos.radical.rdl427');
    }
    return radical;
  }
}
