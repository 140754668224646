<div class="ativosvenda">
  <div class="entity-detail-form">
    <cg-card-panel #cardPanel="cgCardPanel">
      <div *cgCardPanelContent>
        <pl-form class="px-2 py-1" [submit]="fnPesquisaFaturaVenda" plPromise>
          <pl-group>
            <pl-group>
              <label [translate]="'ativosvenda.datavenda'"></label>
              <edit>
                <pl-edit type="date" attrName="dataAbate" [(model)]="ativosvenda.dataVenda"></pl-edit>
              </edit>
            </pl-group>
            <pl-group class="align-self-end">
              <label [translate]="'ativosvenda.distribuicaovalor'"></label>
              <edit>
                <pl-edit type="radiogroup" attrName="estado" [model]="distribuiValor" (modelChange)="distribuiValor = $event; executeCalc()" [properties]="radioGroupTemplateDistValorVenda"></pl-edit>
              </edit>
            </pl-group>
          </pl-group>
          <pl-group class="align-self-end">
            <edit>
              <pl-button type="submit" klass="btn btn-sm btn-primary"><i class="fa fa-fw fa-search"></i><span [translate]="'global.btn.search'"></span></pl-button>
            </edit>
          </pl-group>
        </pl-form>
      </div>
    </cg-card-panel>

    <div class="ativos-venda-header">
      <fieldset class="ativos-venda-header-fieldset">
        <legend [translate]="'ativosvenda.card.valores'"></legend>

        <div class="row">
          <div class="col-sm-4 text-end fw-bolder">
            <span><span [translate]="'ativosvenda.card.valorsemiva'"></span>:&nbsp;</span>
          </div>
          <div class="col-sm-8 text-default">
            {{ ativosvenda.valorSemIva | cgcDecimal }}
          </div>

          <div class="col-sm-4 text-end fw-bolder">
            <span><span [translate]="'ativosvenda.card.valordoiva'"></span>:&nbsp;</span>
          </div>
          <div class="col-sm-8 text-default">
            {{ ativosvenda.valorDoIva | cgcDecimal }}
          </div>

          <div class="col-sm-4 text-end fw-bolder">
            <span><span [translate]="'ativosvenda.card.total'"></span>:&nbsp;</span>
          </div>
          <div class="col-sm-8 text-default">
            {{ valorTotal | cgcDecimal }}
          </div>
        </div>
      </fieldset>

      <fieldset class="ativos-venda-header-fieldset">
        <legend [translate]="'ativosvenda.card.contas'"></legend>

        <div class="row">
          <div class="col-sm-4 text-end fw-bolder">
            <span><span [translate]="'ativosvenda.card.docliente'"></span>:&nbsp;</span>
          </div>
          <div class="col-sm-8 text-default">
            <span>{{ contaDoCliente }}</span>
          </div>

          <div class="col-sm-4 text-end fw-bolder">
            <span><span [translate]="'ativosvenda.card.maismenosvalia'"></span>:&nbsp;</span>
          </div>
          <div class="col-sm-8 text-default d-grid">
            <span *ngFor="let item of listContaMaisMenosVal">{{ item.nConta }} - {{ item.nomeConta }}</span>
          </div>

          <div class="col-sm-4 text-end fw-bolder">
            <span><span [translate]="'ativosvenda.card.iva'"></span>:&nbsp;</span>
          </div>
          <div class="col-sm-8 text-default d-grid">
            <span *ngFor="let item of listContaDoIva">{{ item.nConta }} - {{ item.nomeConta }}</span>
          </div>
        </div>
      </fieldset>
    </div>

    <hr />

    <h5 [translate]="'ativosvenda.selecionarativos'"></h5>

    <div class="ativosabate-wrapper-dxgrid">
      <div class="ativosabate-table-dxgrid">
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          [cgDxDataGridColumns]="dataGridPorVenderFields"
          [dataSource]="dataGridDefinition.dataSource"
          [filterValue]="['status', '=', 'porVender']"
          (onInitialized)="onInitialized($event, true)"
          (onContentReady)="onContentReady()"
          (onCellClick)="onCellClick($event)"
          (onCellDblClick)="onCellDblClick($event)">
          <dxo-row-dragging group="statusGroup" data="porVender" [onAdd]="fnOnAddVendaTable"></dxo-row-dragging>

          <div *dxTemplate="let item of 'templateToolbar'">
            <span [translate]="'ativosvenda.table.ativosporvender'"></span>
          </div>
        </dx-data-grid>
      </div>

      <div class="abate-btn-actions-dxgrid">
        <button type="button" class="btn btn-primary btn-sm" [disabled]="(!ativosvenda.contaCliente && !ativosvenda.listContaMaisMenosValia?.length) || emptyAtivosPorVenderTable" (click)="allRight()">
          <i class="fa fa-fw fa-angle-double-right"></i>
        </button>

        <button
          type="button"
          class="btn btn-primary btn-sm"
          [disabled]="(!ativosvenda.contaCliente && !ativosvenda.listContaMaisMenosValia?.length) || !selectedAtivoPorVender"
          (click)="selectedRight()">
          <i class="fa fa-fw fa-angle-right"></i>
        </button>

        <button
          type="button"
          class="btn btn-primary btn-sm"
          [disabled]="(!ativosvenda.contaCliente && !ativosvenda.listContaMaisMenosValia?.length) || !selectedAtivoAAvender"
          (click)="selectedLeft()">
          <i class="fa fa-fw fa-angle-left"></i>
        </button>

        <button type="button" class="btn btn-primary btn-sm" [disabled]="(!ativosvenda.contaCliente && !ativosvenda.listContaMaisMenosValia?.length) || emptyAtivosAVenderTable" (click)="allLeft()">
          <i class="fa fa-fw fa-angle-double-left"></i>
        </button>
      </div>

      <div class="ativosabate-table-dxgrid">
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          [cgDxDataGridColumns]="dataGridAVenderFields"
          [dataSource]="dataGridDefinition.dataSource"
          [filterValue]="['status', '=', 'aVender']"
          (onInitialized)="onInitialized($event, false)"
          (onContentReady)="onContentReady()"
          (onCellClick)="onCellClick($event)"
          (onCellDblClick)="onCellDblClick($event)">
          <dxo-row-dragging group="statusGroup" data="aVender" [onAdd]="fnOnAddVendaTable"></dxo-row-dragging>

          <dxo-editing mode="cell" startEditAction="click" [selectTextOnEditStart]="true" [allowUpdating]="true"></dxo-editing>

          <div *dxTemplate="let item of 'templateToolbar'">
            <span [translate]="'ativosvenda.table.ativosavender'"></span>
          </div>
        </dx-data-grid>
      </div>
    </div>
  </div>
</div>
