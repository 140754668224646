<div class="dmrf">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="search" plPromise>
        <pl-group>
          <pl-group>
            <label [translate]="'dmrf.form.periodo'"></label>
            <edit>
              <entity-autocomplete
                entity="periodos"
                attrName="periodo"
                [model]="model"
                (selectedKeyChange)="onPeriodoChange($event)"
                [fieldsMap]="{nome: 'nomePeriodo'}"
                [output]="outputPeriodo"
                outputDescription="nome"
                [properties]="{disallowClear: true}"
                plAutoFocus>
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group class="align-self-end">
            <label></label>
            <edit>
              <pl-edit type="checkbox" class="mt-1" [(model)]="model.frestran" [properties]="{label: 'dmrf.frestran'}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group class="align-self-end">
          <edit>
            <pl-button type="submit" klass="btn btn-sm btn-primary"><i class="fa fa-fw fa-search"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <div class="row">
    <div class="col-md-12">
      <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="dmrfTable" [dataSource]="dataGridDefinition.dataSource" (onInitialized)="onInitialized($event)">
        <div *dxTemplate="let item of 'toolbarTemplate'">
          <pl-toolbar [instanceId]="tbInstanceId"></pl-toolbar>
        </div>

        <div *dxTemplate="let item of 'cellTemplateBtn'">
          <pl-button [title]="'dmrf.edit' | translate" klass="btn-primary btn-xs" (evtClicked)="editRecord(item.data)">
            <i class="fa fa-fw fa-pencil"></i>
          </pl-button>
          <pl-button [title]="'dmrf.delete' | translate" klass="btn-danger btn-xs" (evtClicked)="deleteRecord(item.data)">
            <i class="fa fa-fw fa-trash"></i>
          </pl-button>
        </div>
      </dx-data-grid>
      <hr />
    </div>

    <div *ngIf="tableSummarySource.length" class="col-md-12 text-end">
      <div class="summary-container">
        <h4 class="summary-title" [translate]="'dmrf.summaryTitle'"></h4>
        <dx-data-grid [cgDxDataGrid]="dataGridDefinitionSummary" [dataSource]="dataGridDefinitionSummary.dataSource">
          <div *dxTemplate="let item of 'cellTemplateAlerts'">
            <pl-tooltip
              *ngIf="item.data.valor < 0 || item.data.valor === 0"
              [config]="{text: item.data.valor < 0 ? 'dmrf.messages.valueRetNegative' : 'dmrf.messages.valueRetNull', container: 'body', tooltipClass: 'tooltip-warning'}">
              <i *plTooltipContent class="fa fa-fw fa-exclamation-triangle text-warning" aria-hidden="true"></i>
            </pl-tooltip>
          </div>
        </dx-data-grid>
      </div>
    </div>
  </div>

  <div *ngIf="showLoadingModal" class="dmrf-loading-overlay">
    <div class="dmrf-loading-container">
      <div class="progress">
        <div
          class="progress-bar bg-warning progress-bar-striped progress-bar-animated"
          role="progressbar"
          [attr.aria-valuenow]="100"
          [attr.aria-valuemin]="0"
          [attr.aria-valuemax]="100"
          style="width: 100%"></div>
      </div>
      <div class="dmrf-loading-message" [translate]="loadingModalMessage"></div>
    </div>
  </div>
</div>
