<div class="contratostipos">
  <config-options *ngIf="type !== stateTypes.NEW" [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>

  <entity-detail
    entityName="contratostipos"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL, orientation: formOrientation, validators: {required: {value: true}}}">
        <pl-group>
          <label [translate]="'contratostipos.fields.nomeClasse'"></label>
          <edit>
            <entity-autocomplete
              entity="classescontratos"
              attrName="classescontratos"
              [model]="model"
              (modelChange)="onClasseContratoChanged($event)"
              (selectedKeyChange)="model.nClasseCtr = $event"
              (evtSelectedDescriptionChanged)="model.nClasseCtr = $event"
              [fieldsMap]="{nClasseCtr: 'nClasseCtr', nomeClasseCtr: 'nomeClasse'}"
              [output]="'nomeClasseCtr'"
              [properties]="{readonly: type === stateTypes.DETAIL || type === stateTypes.EDIT}"
              plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group [properties]="{readonly: type === stateTypes.DETAIL}">
            <label [translate]="'contratostipos.fields.nTipoContrato'"></label>
            <edit>
              <pl-edit-text
                attrName="nTipoContrato"
                [(model)]="model.nTipoContrato"
                [placeholder]="'contratostipos.fields.nTipoContratoPlaceholder'"
                [properties]="{readonly: type === stateTypes.DETAIL || type === stateTypes.EDIT}">
              </pl-edit-text>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'contratostipos.fields.nome'"></label>
            <edit>
              <pl-edit-text
                attrName="nome"
                [(model)]="model.nome"
                [placeholder]="'contratostipos.fields.nomePlaceholder'"
                [properties]="{validators: {required: {value: true}, maxlength: {value: 100}}}">
              </pl-edit-text>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="model.classeTipo === 0">
          <label [translate]="'contratostipos.fields.periocidade'"></label>
          <edit>
            <pl-edit [(model)]="model.periocidade" [type]="dataSourceNamePeriocidadeContratoTipo" [properties]="{disabled: model.classeTipo !== 0}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group class="docfa-edit" *ngIf="model.classeTipo === 0">
          <label [translate]="'contratostipos.fields.nDocfa'"></label>
          <edit>
            <entity-autocomplete
              entity="docfas"
              attrName="tipodocumento"
              [model]="model"
              (modelChange)="onDocFaChanged($event)"
              (selectedKeyChange)="model.nDocfa = $event"
              [fieldsMap]="{nDocFa: 'nDocfa', nome: 'nomeDocfa'}"
              [output]="docfasOutput">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group class="docfanum-edit" *ngIf="model.classeTipo === 0 && model.nNumer">
          <label [translate]="'contratostipos.fields.nNumer'"></label>
          <edit>
            <entity-autocomplete
              entity="docfasnums"
              attrName="numeracao"
              [filter]="docfaNumFilter"
              [model]="model"
              (selectedKeyChange)="model.nNumer = $event"
              [output]="'nNumer'"
              [outputKey]="'nNumer'">
            </entity-autocomplete>
          </edit>
          <actions *ngIf="type !== stateTypes.DETAIL">
            <pl-tooltip [config]="{text: 'contratostiposervicos.actions.nnumer', placement: 'left'}">
              <button *plTooltipContent type="button" class="btn btn-primary btn-sm action-add-line" [disabled]="!model.nDocfa" (click)="addNumer()">
                <i class="fa fa-fw fa-plus"></i>
              </button>
            </pl-tooltip>
          </actions>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
