/**
 * CentralGest Amalia
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ArticleType = 'FAQ';

export const ArticleType = {
    Faq: 'FAQ' as ArticleType
};

