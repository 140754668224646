import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ApiService} from '../../../services/api/api.service';
import {
  EImportadorSaftDirectCommandType,
  EImportadorSaftDirectCriterioFormacao,
  EImportadorSaftDirectTipoAnaliticaIS,
  EImportadorSaftDirectTipoFormacaoConta,
  EImportadorSaftDirectTipoLancamDatas,
  EImportadorSaftDirectTipoMercado,
  EImportadorSaftDirectTipoProduto,
  IImportadorSaftDirectProcessFileFilters,
  IJsonImportadorSaftDirectConfigProcess,
  IJsonImportadorSaftDirectStatus,
  IJsonImportadorSaftDirectTaxaIva,
  IJsonImportadorSaftDirectTipoDocumento,
  TImportadorSaftDirectCommandRawResult,
  TImportadorSaftDirectCommandResult,
  TJsonImportadorSaftDirectCommandResponse
} from './importadorSaftDirect.module.interface';
import {
  IRestCmdImportadorSaftDirect,
  IRestCmdImportadorSaftDirectAtribuirAutoDiarioEDescritivoATiposDoc,
  IRestCmdImportadorSaftDirectConfigArtigos,
  IRestCmdImportadorSaftDirectConfigArtigosTodosPorTipo,
  IRestCmdImportadorSaftDirectConfiguracoesMascaraAnalitica,
  IRestCmdImportadorSaftDirectCorrespondenciaContasAtribuirCCusto,
  IRestCmdImportadorSaftDirectCorrespondenciaContasAtribuirConta,
  IRestCmdImportadorSaftDirectCorrespondenciaContasAtribuirRepCC,
  IRestCmdImportadorSaftDirectCorrespondenciaContasAtribuirRetencao,
  IRestCmdImportadorSaftDirectCorrespondenciaContasMercadoChange,
  IRestCmdImportadorSaftDirectCorrespondenciaContasRemoverContaAssociada,
  IRestCmdImportadorSaftDirectCorrespTaxasAtribuirCodIva,
  IRestCmdImportadorSaftDirectCorrespTaxasAtribuirDigitosIva,
  IRestCmdImportadorSaftDirectDatasDocumentosSetDataLanc,
  IRestCmdImportadorSaftDirectDatasDocumentosTipoLancamDatasChange,
  IRestCmdImportadorSaftDirectDeleteGeneratedDocsContab,
  IRestCmdImportadorSaftDirectGetContaFormadaDoArtigo,
  IRestCmdImportadorSaftDirectGetCorrespondenciaContas,
  IRestCmdImportadorSaftDirectGetCorrespTaxas,
  IRestCmdImportadorSaftDirectGetDocumentosImportar,
  IRestCmdImportadorSaftDirectGetGeneratedDocsContab,
  IRestCmdImportadorSaftDirectGetTiposDocumento,
  IRestCmdImportadorSaftDirectLoadConfigArtigos,
  IRestCmdImportadorSaftDirectLoadConfigFormacaoConta,
  IRestCmdImportadorSaftDirectLoadConfigMercados,
  IRestCmdImportadorSaftDirectLoadDatasDocumentos,
  IRestCmdImportadorSaftDirectLoadState,
  IRestCmdImportadorSaftDirectProcessFile,
  IRestCmdImportadorSaftDirectProcessFileCloud,
  IRestCmdImportadorSaftDirectProcessFilePOS,
  IRestCmdImportadorSaftDirectProcessFilePOSWinTouch,
  IRestCmdImportadorSaftDirectSaveConfigArtigos,
  IRestCmdImportadorSaftDirectSaveConfigCorrespContas,
  IRestCmdImportadorSaftDirectSaveConfigCorrespTaxas,
  IRestCmdImportadorSaftDirectSaveConfigFormacaoConta,
  IRestCmdImportadorSaftDirectSaveConfigMercados,
  IRestCmdImportadorSaftDirectSaveDocumentos,
  IRestCmdImportadorSaftDirectSaveTiposDocumento
} from './importadorSaftDirectCommands.module.interface';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {TDate} from '../../../../common/dates';
import {IApiQueryResponse, TServiceResponse} from '../../../services/api/api.service.interface';
import {IJsonIva} from '../../../entities/ivas/jsonIva.entity.interface';

@Injectable({
  providedIn: 'root'
})
export class ImportadorSaftDirectService {
  private readonly _path: string;
  private readonly _pathIvas: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/importadorSaftDirect`;
    this._pathIvas = `${this._apiService.path.restapi}/ivas`;
  }

  public generateSaftUploadUrl(): Observable<string> {
    return buildSessionUrlWithParams(`${this._apiService.path.restapi}/importadorSaftDirect`, {filename: 'saft.xml'});
  }

  public getJobStatus(): Promise<IJsonImportadorSaftDirectStatus> {
    return new Promise<IJsonImportadorSaftDirectStatus>((resolve, reject) => {
      this._apiService
        .get<IJsonImportadorSaftDirectStatus>({
          url: `${this._path}/status`
        })
        .then((response) => {
          resolve(response.body);
        })
        .catch(reject);
    });
  }

  public loadConfigProcess(): TServiceResponse<IJsonImportadorSaftDirectConfigProcess> {
    return this._apiService.get<IJsonImportadorSaftDirectConfigProcess>({
      url: `${this._path}/configprocessdata`
    });
  }

  public processFile(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectProcessFile> {
    const command: IRestCmdImportadorSaftDirectProcessFile = {filename: ''};
    return this._sendCommand<IRestCmdImportadorSaftDirectProcessFile>(EImportadorSaftDirectCommandType.ProcessFile, command);
  }

  public processFileCloud(filters: IImportadorSaftDirectProcessFileFilters): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectProcessFileCloud> {
    const command: IRestCmdImportadorSaftDirectProcessFileCloud = {
      utilizador: filters.utilizadorCloud,
      password: filters.passwordCloud,
      periodo: filters.periodo,
      processaVendas: filters.processaVendas,
      processaCompras: filters.processaCompras,
      processaRecibos: filters.processaRecibos,
      processaPagamentos: filters.processaPagamentos
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectProcessFileCloud>(EImportadorSaftDirectCommandType.ProcessFileCloud, command);
  }

  public processFilePOS(filters: IImportadorSaftDirectProcessFileFilters): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectProcessFilePOS> {
    const command: IRestCmdImportadorSaftDirectProcessFilePOS = {
      utilizador: filters.utilizadorPOS,
      password: filters.passwordPOS,
      periodo: filters.periodo,
      processaVendas: filters.processaVendas,
      processaCompras: filters.processaCompras,
      processaRecibos: filters.processaRecibos,
      processaPagamentos: filters.processaPagamentos
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectProcessFilePOS>(EImportadorSaftDirectCommandType.ProcessFilePOS, command);
  }

  public processFilePOSWinTouch(filters: IImportadorSaftDirectProcessFileFilters): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectProcessFilePOSWinTouch> {
    const command: IRestCmdImportadorSaftDirectProcessFilePOSWinTouch = {
      utilizador: filters.utilizadorPOSWinTouch,
      password: filters.passwordPOSWinTouch,
      periodo: filters.periodo,
      processaVendas: filters.processaVendas,
      processaCompras: filters.processaCompras,
      processaRecibos: filters.processaRecibos,
      processaPagamentos: filters.processaPagamentos
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectProcessFilePOSWinTouch>(EImportadorSaftDirectCommandType.ProcessFilePOSWinTouch, command);
  }

  public loadState(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectLoadState> {
    const command: IRestCmdImportadorSaftDirectLoadState = {
      model: undefined
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectLoadState>(EImportadorSaftDirectCommandType.LoadState, command);
  }

  public getDocumentosImportar(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectGetDocumentosImportar> {
    const command: IRestCmdImportadorSaftDirectGetDocumentosImportar = {
      docs: {
        list: [],
        summary: {
          total: 0,
          totalBase: 0,
          totalDocs: 0,
          totalIva: 0
        }
      },
      payments: {
        list: [],
        summary: {
          total: 0,
          totalCredit: 0,
          totalDebit: 0,
          totalPayments: 0
        }
      }
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectGetDocumentosImportar>(EImportadorSaftDirectCommandType.GetDocumentosImportar, command);
  }

  public getTiposDocumento(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectGetTiposDocumento> {
    const command: IRestCmdImportadorSaftDirectGetTiposDocumento = {
      list: [],
      criaDiariosEDescritivos: false
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectGetTiposDocumento>(EImportadorSaftDirectCommandType.GetTiposDocumento, command);
  }

  public saveConfigTiposDocumento(
    list: Array<IJsonImportadorSaftDirectTipoDocumento>,
    criaDiariosEDescritivos: boolean
  ): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectSaveTiposDocumento> {
    const command: IRestCmdImportadorSaftDirectSaveTiposDocumento = {
      list: list,
      criaDiariosEDescritivos: criaDiariosEDescritivos
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectSaveTiposDocumento>(EImportadorSaftDirectCommandType.SaveConfigTiposDocumento, command);
  }

  public atribuirAutomaticamenteDiarioEDescritivoATiposDoc(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectAtribuirAutoDiarioEDescritivoATiposDoc> {
    const command: IRestCmdImportadorSaftDirectAtribuirAutoDiarioEDescritivoATiposDoc = {
      list: []
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectAtribuirAutoDiarioEDescritivoATiposDoc>(EImportadorSaftDirectCommandType.AtribuirAutomaticamenteDiarioEDescritivoATiposDoc, command);
  }

  public loadConfigMercados(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectLoadConfigMercados> {
    const command: IRestCmdImportadorSaftDirectLoadConfigMercados = {
      valorMercadoComunitario: '',
      valorMercadoNacional: '',
      valorOutrosMercados: ''
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectLoadConfigMercados>(EImportadorSaftDirectCommandType.LoadConfigMercados, command);
  }

  public saveConfigMercados(
    valorMercadoNacional: string,
    valorMercadoComunitario: string,
    valorOutrosMercados: string
  ): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectSaveConfigMercados> {
    const command: IRestCmdImportadorSaftDirectSaveConfigMercados = {
      valorMercadoComunitario: valorMercadoComunitario,
      valorMercadoNacional: valorMercadoNacional,
      valorOutrosMercados: valorOutrosMercados
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectSaveConfigMercados>(EImportadorSaftDirectCommandType.SaveConfigMercados, command);
  }

  public loadConfigFormacaoConta(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectLoadConfigFormacaoConta> {
    const command: IRestCmdImportadorSaftDirectLoadConfigFormacaoConta = {
      tipoFormacaoConta: <EImportadorSaftDirectTipoFormacaoConta>-1,
      criterioFormacao: <EImportadorSaftDirectCriterioFormacao>-1,
      nContaDiversos: '',
      nDigitos: 0,
      radical: '',
      usaDigitosIdentExterna: false
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectLoadConfigFormacaoConta>(EImportadorSaftDirectCommandType.LoadConfigFormacaoConta, command);
  }

  public saveConfigFormacaoConta(
    tipoFormacaoConta: EImportadorSaftDirectTipoFormacaoConta,
    criterioFormacao: EImportadorSaftDirectCriterioFormacao,
    nContaDiversos: string,
    nDigitos: number,
    radical: string,
    usaDigitosIdentExterna: boolean
  ): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectSaveConfigFormacaoConta> {
    const command: IRestCmdImportadorSaftDirectSaveConfigFormacaoConta = {
      tipoFormacaoConta: tipoFormacaoConta,
      criterioFormacao: criterioFormacao,
      nContaDiversos: nContaDiversos,
      nDigitos: nDigitos,
      radical: radical,
      usaDigitosIdentExterna: usaDigitosIdentExterna
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectSaveConfigFormacaoConta>(EImportadorSaftDirectCommandType.SaveConfigFormacaoConta, command);
  }

  public getListIvas(): TServiceResponse<IApiQueryResponse<IJsonIva>> {
    return this._apiService.get({url: this._pathIvas});
  }

  public getCorrespondenciaContas(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectGetCorrespondenciaContas> {
    const command: IRestCmdImportadorSaftDirectGetCorrespondenciaContas = {
      list: []
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectGetCorrespondenciaContas>(EImportadorSaftDirectCommandType.GetCorrespondenciaContas, command);
  }

  public correspContasMercadoChange(customerID: string, mercado: EImportadorSaftDirectTipoMercado): TImportadorSaftDirectCommandResult {
    const command: IRestCmdImportadorSaftDirectCorrespondenciaContasMercadoChange = {
      list: [],
      customerID: customerID,
      mercado: mercado
    };
    return this._sendCommand(EImportadorSaftDirectCommandType.CorrespondenciaContasMercadoChange, command);
  }

  public correspContasRemoverContaAssociada(customers: Array<string>): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectCorrespondenciaContasRemoverContaAssociada> {
    const command: IRestCmdImportadorSaftDirectCorrespondenciaContasRemoverContaAssociada = {
      list: [],
      customers: customers
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectCorrespondenciaContasRemoverContaAssociada>(EImportadorSaftDirectCommandType.CorrespContasRemoverContaAssociada, command);
  }

  public correspContasAtribuirConta(customers: Array<string>, nconta: string): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectCorrespondenciaContasAtribuirConta> {
    const command: IRestCmdImportadorSaftDirectCorrespondenciaContasAtribuirConta = {
      list: [],
      customers: customers,
      nConta: nconta
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectCorrespondenciaContasAtribuirConta>(EImportadorSaftDirectCommandType.CorrespContasAtribuirConta, command);
  }

  public correspContasAtribuirRetencao(customerID: string, codRetencao: string): TImportadorSaftDirectCommandResult {
    const command: IRestCmdImportadorSaftDirectCorrespondenciaContasAtribuirRetencao = {
      list: [],
      customerID: customerID,
      codRetencao: codRetencao
    };
    return this._sendCommand(EImportadorSaftDirectCommandType.CorrespContasAtribuirRetencao, command);
  }

  public correspContasAtribuirCCusto(
    customerIDs: Array<string>,
    nCCusto: string,
    all: boolean = false
  ): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectCorrespondenciaContasAtribuirCCusto> {
    const command: IRestCmdImportadorSaftDirectCorrespondenciaContasAtribuirCCusto = {
      list: [],
      customerIDs: all ? [] : customerIDs,
      nCCusto: nCCusto,
      all: all
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectCorrespondenciaContasAtribuirCCusto>(EImportadorSaftDirectCommandType.CorrespContasAtribuirCCusto, command);
  }

  public correspContasAtribuirRepCC(
    customerIDs: Array<string>,
    nCodRepCC: number,
    all: boolean = false
  ): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectCorrespondenciaContasAtribuirRepCC> {
    const command: IRestCmdImportadorSaftDirectCorrespondenciaContasAtribuirRepCC = {
      list: [],
      customerIDs: all ? [] : customerIDs,
      nCodRepCC: nCodRepCC,
      all: all
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectCorrespondenciaContasAtribuirRepCC>(EImportadorSaftDirectCommandType.CorrespContasAtribuirRepCC, command);
  }

  public saveConfigCorrespContas(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectSaveConfigCorrespContas> {
    const command: IRestCmdImportadorSaftDirectSaveConfigCorrespContas = {};
    return this._sendCommand<IRestCmdImportadorSaftDirectSaveConfigCorrespContas>(EImportadorSaftDirectCommandType.SaveConfigCorrespContas, command);
  }

  public getCorrespTaxas(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectGetCorrespTaxas> {
    const command: IRestCmdImportadorSaftDirectGetCorrespTaxas = {
      list: []
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectGetCorrespTaxas>(EImportadorSaftDirectCommandType.GetCorrespTaxas, command);
  }

  public correspTaxasAtribuirCodIva(taxaIva: IJsonImportadorSaftDirectTaxaIva): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectCorrespTaxasAtribuirCodIva> {
    const command: IRestCmdImportadorSaftDirectCorrespTaxasAtribuirCodIva = {
      taxaIva: taxaIva
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectCorrespTaxasAtribuirCodIva>(EImportadorSaftDirectCommandType.CorrespTaxasAtribuirCodIva, command);
  }

  public correspTaxasAtribuirDigitosIva(taxaIva: IJsonImportadorSaftDirectTaxaIva): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectCorrespTaxasAtribuirDigitosIva> {
    const command: IRestCmdImportadorSaftDirectCorrespTaxasAtribuirCodIva = {
      taxaIva: taxaIva
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectCorrespTaxasAtribuirDigitosIva>(EImportadorSaftDirectCommandType.CorrespTaxasAtribuirDigitosIva, command);
  }

  public saveConfigCorrespTaxas(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectSaveConfigCorrespTaxas> {
    const command: IRestCmdImportadorSaftDirectSaveConfigCorrespTaxas = {};
    return this._sendCommand<IRestCmdImportadorSaftDirectSaveConfigCorrespTaxas>(EImportadorSaftDirectCommandType.SaveConfigCorrespTaxas, command);
  }

  public loadConfigArtigos(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectLoadConfigArtigos> {
    const command: IRestCmdImportadorSaftDirectLoadConfigArtigos = {
      list: []
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectLoadConfigArtigos>(EImportadorSaftDirectCommandType.LoadConfigArtigos, command);
  }

  public configArtigosPorTipo(
    productType: EImportadorSaftDirectTipoProduto,
    nConta: string,
    nContaNotaCredito: string,
    acresceDigitosMercado: boolean,
    acresceDigitosIVA: boolean,
    comRetencao: boolean,
    nCCusto: string,
    nCodRepCC: number
  ): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectConfigArtigosTodosPorTipo> {
    const command: IRestCmdImportadorSaftDirectConfigArtigosTodosPorTipo = {
      productType: productType,
      acresceDigitosIVA: acresceDigitosIVA,
      acresceDigitosMercado: acresceDigitosMercado,
      comRetencao: comRetencao,
      nConta: nConta,
      nContaNotaCredito: nContaNotaCredito,
      artigos: [],
      nCCusto: nCCusto,
      nCodRepCC: nCodRepCC
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectConfigArtigosTodosPorTipo>(EImportadorSaftDirectCommandType.ConfigArtigosTodosPorTipo, command);
  }

  public configArtigos(
    artigosIds: Array<string>,
    nConta: string,
    nContaNotaCredito: string,
    acresceDigitosMercado: boolean,
    acresceDigitosIVA: boolean,
    comRetencao: boolean,
    nCCusto: string,
    nCodRepCC: number
  ): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectConfigArtigos> {
    const command: IRestCmdImportadorSaftDirectConfigArtigos = {
      artigosIds: artigosIds,
      acresceDigitosIVA: acresceDigitosIVA,
      acresceDigitosMercado: acresceDigitosMercado,
      comRetencao: comRetencao,
      nConta: nConta,
      nContaNotaCredito: nContaNotaCredito,
      artigos: [],
      nCCusto: nCCusto,
      nCodRepCC: nCodRepCC
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectConfigArtigos>(EImportadorSaftDirectCommandType.ConfigArtigos, command);
  }

  public saveConfigArtigos(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectSaveConfigArtigos> {
    const command: IRestCmdImportadorSaftDirectSaveConfigArtigos = {};
    return this._sendCommand<IRestCmdImportadorSaftDirectSaveConfigArtigos>(EImportadorSaftDirectCommandType.SaveConfigArtigos, command);
  }

  public loadDatasDocumentos(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectLoadDatasDocumentos> {
    const command: IRestCmdImportadorSaftDirectLoadDatasDocumentos = {
      list: []
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectLoadDatasDocumentos>(EImportadorSaftDirectCommandType.LoadDatasDocumentos, command);
  }

  public datasDocumentosTipoLancamDatasChange(tipo: EImportadorSaftDirectTipoLancamDatas): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectDatasDocumentosTipoLancamDatasChange> {
    const command: IRestCmdImportadorSaftDirectDatasDocumentosTipoLancamDatasChange = {
      tipo: tipo,
      list: []
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectDatasDocumentosTipoLancamDatasChange>(EImportadorSaftDirectCommandType.DatasDocumentosTipoLancamDatasChange, command);
  }

  public datasDocumentosSetDataLanc(dataLancamento: TDate, dataUltimoDoc: TDate): TImportadorSaftDirectCommandResult {
    const command: IRestCmdImportadorSaftDirectDatasDocumentosSetDataLanc = {
      dataLancamento: dataLancamento,
      dataUltimoDoc: dataUltimoDoc
    };
    return this._sendCommand(EImportadorSaftDirectCommandType.DatasDocumentosSetDataLanc, command);
  }

  public saveDocumentos(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectSaveDocumentos> {
    const command: IRestCmdImportadorSaftDirectSaveDocumentos = {};
    return this._sendCommand<IRestCmdImportadorSaftDirectSaveDocumentos>(EImportadorSaftDirectCommandType.SaveDocumentos, command);
  }

  public getGeneratedDocsContab(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectGetGeneratedDocsContab> {
    const command: IRestCmdImportadorSaftDirectGetGeneratedDocsContab = {
      list: []
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectGetGeneratedDocsContab>(EImportadorSaftDirectCommandType.GetGeneratedDocsContab, command);
  }

  public deleteGeneratedDocsContab(extPocCabIDs: Array<string>): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectDeleteGeneratedDocsContab> {
    const command: IRestCmdImportadorSaftDirectDeleteGeneratedDocsContab = {
      extPocCabIDs: extPocCabIDs
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectDeleteGeneratedDocsContab>(EImportadorSaftDirectCommandType.DeleteGeneratedDocsContab, command);
  }

  public getContaFormadaDoArtigo(artigoKey: string): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectGetContaFormadaDoArtigo> {
    const command: IRestCmdImportadorSaftDirectGetContaFormadaDoArtigo = {
      artigoId: artigoKey,
      list: []
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectGetContaFormadaDoArtigo>(EImportadorSaftDirectCommandType.VerContasFormadasArtigo, command);
  }

  public loadConfiguracoesMascaraAnalitica(): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectConfiguracoesMascaraAnalitica> {
    const command: IRestCmdImportadorSaftDirectConfiguracoesMascaraAnalitica = {
      mascara: '',
      usaAnalitica: false,
      tipoAnaliticaIS: EImportadorSaftDirectTipoAnaliticaIS.PorTipoDocumento
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectConfiguracoesMascaraAnalitica>(EImportadorSaftDirectCommandType.LoadConfiguracoesMascaraAnalitica, command);
  }

  public saveConfiguracoesMascaraAnalitica(
    usaAnalitica: boolean,
    mascara: string,
    tipoAnaliticaIS: EImportadorSaftDirectTipoAnaliticaIS
  ): TImportadorSaftDirectCommandRawResult<IRestCmdImportadorSaftDirectConfiguracoesMascaraAnalitica> {
    const command: IRestCmdImportadorSaftDirectConfiguracoesMascaraAnalitica = {
      usaAnalitica: usaAnalitica,
      mascara: mascara,
      tipoAnaliticaIS: tipoAnaliticaIS
    };
    return this._sendCommand<IRestCmdImportadorSaftDirectConfiguracoesMascaraAnalitica>(EImportadorSaftDirectCommandType.SaveConfiguracoesMascaraAnalitica, command);
  }

  public stopMS(stopMS: boolean = true): TServiceResponse<void> {
    return this._apiService.post({url: this.stopMSUrl(stopMS)});
  }

  public stopMSUrl(stopMS: boolean): string {
    return `${this._path}/stop-ms?stopMS=${stopMS}`;
  }

  private _sendCommand<T>(commandName: string, command: IRestCmdImportadorSaftDirect): Promise<TJsonImportadorSaftDirectCommandResponse<T>> {
    return this._apiService
      .patch<TJsonImportadorSaftDirectCommandResponse<T>, IRestCmdImportadorSaftDirect>({
        url: this._path,
        body: command,
        reportExceptions: false,
        params: {command: commandName}
      })
      .then((response: HttpResponse<TJsonImportadorSaftDirectCommandResponse<T>>) => response.body);
  }
}
