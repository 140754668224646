import {Subscription} from 'rxjs';
import {AfterViewInit, Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {HttpResponse} from '@angular/common/http';
import {
  IPlLocale,
  IPlNavWizardCallback,
  IPlNavWizardDefinition,
  IPlNavWizardEventBeforeChange,
  IPlNavWizardStep,
  IPlToolbarItem,
  isArray,
  isFunction,
  isNumber,
  isUndefined,
  PlAlertService,
  PlLocaleService
} from 'pl-comps-angular';
import {ContabilidadePredefinidosService} from '../../service/contabilidade.predefinidos.service';
import {DocContabilidadeService} from '../../../../docscontabilidade/components/doccontabilidade/docContabilidade.service';
import {DocsContabilidadeService} from '../../../../docscontabilidade/service/docsContabilidade.service';
import {
  EContabilidadePredefinidosOperation,
  EContabilidadePredefinidosStep,
  emptyPredefinidoContabilidade,
  ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS,
  IPreDefinidoContab,
  IPreDefinidoDetailStateParams,
  IPreDefinidosOperation
} from '../../preDefinidosContab.entity.interface';
import {EDocContabilidadeOrigem} from '../../../../docscontabilidade/jsonDocContabilidade.interface';
import {EEntityStateDetailType} from '../../../../../../../common/utils/entity.state.utils';
import {IDocContabilidadeCallback} from '../../../../docscontabilidade/components/doccontabilidade/docContabilidade.interface';
import {IDocContabilidade} from '../../../../docscontabilidade/docsContabilidade.interface';
import {IEntityService} from '../../../../../../services/entity/entity.service.interface';
import {IJsonErpUser} from '../../../../../../services/account/jsonUserApi.interface';
import {IJsonPreDefinidoContab} from '../../jsonPreDefinidosContab.entity.interface';
import {IPreDefinidoContabCabCallback} from '../cabecalho/predefinidocontabcab.component.interface';
import {ModuloEntityDetailComponent} from '../../../../../../components/module/entitydetail/module.entitydetail.component';

const STEP_INDEX_BASIC = 0;
const STEP_INDEX_HEADER = 1;
const STEP_INDEX_LINES = 2;
const STEP_INDEX_PREVIEW = 3;
const STEP_INDEX_COMPANIES = 4;
const SAVE_PROMPT_IDENTIFIER = 'contabilidade-predefinidos-preview';

@Component({
  selector: 'contabilidade-predefinidos-entity-detail',
  templateUrl: './contabilidade.predefinidos.entity.detail.component.html'
})
export class ContabilidadePredefinidosEntityDetailComponent
  extends ModuloEntityDetailComponent<IJsonPreDefinidoContab, IEntityService<IPreDefinidoContab>>
  implements OnInit, OnDestroy, AfterViewInit
{
  @Input() public suggestedCode: number;
  @Input() public docContabilidade: IDocContabilidade;

  public readonly operations: typeof EContabilidadePredefinidosOperation;
  public readonly steps: typeof EContabilidadePredefinidosStep;
  public readonly templateListaEmpresas: string;
  public readonly definition: IPlNavWizardDefinition;
  public readonly groupOperations: Array<IPreDefinidosOperation>;
  public readonly plNavWizard: IPlNavWizardCallback;
  public readonly preDefinidoContabCab: IPreDefinidoContabCabCallback;

  public model: IPreDefinidoContab;
  public docContabilidadePreview: IDocContabilidade;
  public textValidatorRequired: string;
  public isStep3Valid: boolean;
  public isStep4Valid: boolean;
  public listaEmpresas: Array<IJsonErpUser>;
  public empresasSelecionadas: Array<IJsonErpUser>;
  public operation: EContabilidadePredefinidosOperation;
  public preDefinidosIDToCopy: number;
  public titleCompanies: string;
  public titleCompaniesRight: string;
  public step: IPlNavWizardStep;
  public showPreview: boolean;
  public hardEditing: boolean;
  public formInstanceBasic: UntypedFormGroup;
  public currentStepId: EContabilidadePredefinidosStep;
  public docCallback: IDocContabilidadeCallback;

  private readonly _toolTitleOperation: IPlToolbarItem;
  private readonly _btnClearDocContab: IPlToolbarItem;
  private readonly _btnSimulateSave: IPlToolbarItem;
  private readonly _entityService: IEntityService<IPreDefinidoContab>;
  private readonly _subscriptionLocale: Subscription;
  private _updating: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plLocaleService: PlLocaleService,
    private readonly _contabilidadePredefinidosService: ContabilidadePredefinidosService,
    private readonly _plAlertService: PlAlertService,
    private readonly _docsContabilidadeService: DocsContabilidadeService,
    private readonly _docContabilidadeService: DocContabilidadeService
  ) {
    super(_injector);
    this.suggestCodeMultiEmpresa = this.suggestCodeMultiEmpresa.bind(this);
    this.docCallback = {};
    this.operations = EContabilidadePredefinidosOperation;
    this.steps = EContabilidadePredefinidosStep;
    this.templateListaEmpresas = '{{nEmpresa}} - {{nomeEmpresa}}';
    this.hardEditing = false;
    this.plNavWizard = {};
    this.preDefinidoContabCab = {};
    this.definition = {
      items: [],
      force: false
    };
    this.groupOperations = [
      {value: EContabilidadePredefinidosOperation.NEW, label: 'predefinidoscontabilidade.operations.new'},
      {value: EContabilidadePredefinidosOperation.NEW_BASE_DOC, label: 'predefinidoscontabilidade.operations.newBasedOnDoc'},
      {value: EContabilidadePredefinidosOperation.NEW_BASE_PREDEFINIDO, label: 'predefinidoscontabilidade.operations.newBasedOnExisting'},
      {value: EContabilidadePredefinidosOperation.EDIT, label: 'predefinidoscontabilidade.operations.edit'},
      {value: EContabilidadePredefinidosOperation.DELETE, label: 'predefinidoscontabilidade.operations.delete'}
    ];
    this.isStep3Valid = false;
    this.isStep4Valid = false;
    this.listaEmpresas = [];
    this.empresasSelecionadas = [];
    this.titleCompaniesRight = 'predefinidoscontabilidade.titles.companiesToSave';
    this.showPreview = false;

    this._toolTitleOperation = {
      order: 1,
      id: 'titleoperation',
      type: 'title',
      caption: '',
      visible: false
    };
    this._btnSimulateSave = {
      id: 'predefinidossimulatesave',
      type: 'button',
      order: 110,
      class: 'btn-info',
      iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>',
      caption: 'predefinidoscontabilidade.btn.simulate',
      visible: false,
      click: () => this.simulateDocContabSave()
    };
    this._btnClearDocContab = {
      id: 'predefinidoscleandoccontab',
      type: 'button',
      order: 111,
      class: 'btn-primary',
      iconLeft: '<i class="fa fa-fw fa-ban"></i>',
      caption: 'predefinidoscontabilidade.btn.clear',
      visible: false,
      click: () => {
        this._clearDocContab();
      }
    };
    this._entityService = this._entityServiceBuilder.build<IPreDefinidoContab>(ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS);
    this._updating = false;

    this._subscriptionLocale = this._plLocaleService.locale().subscribe((locale: IPlLocale) => {
      this.textValidatorRequired = locale.validators.required;
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.btnNovo.menu = [
      {
        type: 'button',
        iconLeft: '<i class="fa fa-fw fa-plus"></i>',
        caption: 'predefinidoscontabilidade.operations.newBasedOnDoc',
        click: () => this.novo({operation: EContabilidadePredefinidosOperation.NEW_BASE_DOC})
      },
      {
        type: 'button',
        iconLeft: '<i class="fa fa-fw fa-plus"></i>',
        caption: 'predefinidoscontabilidade.operations.newBasedOnExisting',
        click: () => this.novo({operation: EContabilidadePredefinidosOperation.NEW_BASE_PREDEFINIDO})
      }
    ];
    this.btnSave.visible = false;

    this.toolbar.addButton(this._toolTitleOperation);
    this.toolbar.addButton(this._btnSimulateSave);
    this.toolbar.addButton(this._btnClearDocContab);

    if (!this.model.preDefinidosID) {
      this.model = this.buildModel();
      if (this.suggestedCode) {
        this.model.preDefinidosID = this.suggestedCode;
      }
    }

    this._setOperationTitle();

    const params: IPreDefinidoDetailStateParams = this.params;
    if (isNumber(params.operation)) {
      this.operation = params.operation;
    }

    this.hardEditing = this.operation === EContabilidadePredefinidosOperation.EDIT;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptionLocale.unsubscribe();
    this._clearForSaveEventListener();
  }

  public ngAfterViewInit(): void {
    if (this.operation === EContabilidadePredefinidosOperation.EDIT) {
      this.plNavWizard.setStep(this.definition.items[STEP_INDEX_HEADER]);
    } else if (this.operation === EContabilidadePredefinidosOperation.DELETE) {
      this.plNavWizard.setStep(this.definition.items[STEP_INDEX_COMPANIES], true);
    } else {
      this.plNavWizard.setStep(this.definition.items[STEP_INDEX_BASIC]);
    }
  }

  public onUpdate(stateType: EEntityStateDetailType): Promise<void> {
    if (
      stateType === EEntityStateDetailType.NEW &&
      this.operation !== EContabilidadePredefinidosOperation.NEW &&
      this.operation !== EContabilidadePredefinidosOperation.NEW_BASE_DOC &&
      this.operation !== EContabilidadePredefinidosOperation.NEW_BASE_PREDEFINIDO
    ) {
      this.operation = EContabilidadePredefinidosOperation.NEW;
    }
    if (stateType === EEntityStateDetailType.EDIT) {
      this.operation = EContabilidadePredefinidosOperation.EDIT;
    }
    this._setOperationTitle();
    return super.onUpdate(stateType);
  }

  public async novo(params?: IPreDefinidoDetailStateParams): Promise<void> {
    if (this.maintenanceMode && params?.operation) {
      this.operation = params.operation;
    }
    return super.novo(params);
  }

  public resetModel(): void {
    super.resetModel();
    if (this.type === EEntityStateDetailType.NEW) {
      if (this.definition.items.length) {
        this.plNavWizard.setStep(this.definition.items[STEP_INDEX_BASIC], true);
      }
      if (isFunction(this.preDefinidoContabCab?.resetPanels)) {
        this.preDefinidoContabCab.resetPanels();
      }
    }
  }

  public buildModel(): IJsonPreDefinidoContab {
    return emptyPredefinidoContabilidade();
  }

  public changedStep(currentStep: IPlNavWizardStep): void {
    this.currentStepId = <EContabilidadePredefinidosStep>currentStep.stepId;
    if (this.currentStepId === EContabilidadePredefinidosStep.Companies) {
      this.setCompaniesTitle();
      this._loadEmpresas();
    }
    if (this.currentStepId === EContabilidadePredefinidosStep.Preview) {
      this._docContabilidadeService.listenForSaveEvent({
        identifier: SAVE_PROMPT_IDENTIFIER,
        callbackGetDoc: () => this.docContabilidadePreview,
        callbackOnSave: () => this.simulateDocContabSave(),
        contabilidadeDigital: false,
        simulation: true
      });
    } else {
      this._clearForSaveEventListener();
    }
    this._initStep4();
  }

  public simulateDocContabSave(): Promise<void> {
    return this._docsContabilidadeService
      .simulaGravacao(EDocContabilidadeOrigem.PreDefinidos, this.docContabilidadePreview)
      .then(() => {
        this._plAlertService.success('predefinidoscontabilidade.success.simulate');
      })
      .catch((reason: unknown) => {
        this._logger.error(reason);
      });
  }

  public changedEmpresasSelecionadas(): void {
    this.model.empresasSelecionadas = this.empresasSelecionadas.map((empresa: IJsonErpUser) => empresa.nEmpresa);
  }

  public setCompaniesTitle(): void {
    let title;
    if (this.operation === EContabilidadePredefinidosOperation.DELETE) {
      title = 'predefinidoscontabilidade.titles.selectColumnsDelete';
      this.titleCompaniesRight = 'predefinidoscontabilidade.titles.companiesToRemove';
    } else {
      title = this.operation === EContabilidadePredefinidosOperation.EDIT ? 'predefinidoscontabilidade.titles.selectColumnsEdit' : 'predefinidoscontabilidade.titles.selectColumns';
      this.titleCompaniesRight = 'predefinidoscontabilidade.titles.companiesToSave';
    }
    this.titleCompanies = title;
  }

  public changedDocContabPreview(value: IDocContabilidade): void {
    this.docContabilidadePreview = value;
  }

  public async suggestCodeMultiEmpresa(): Promise<void> {
    const response: HttpResponse<number> = await this._contabilidadePredefinidosService.sugerirCodigoMultiEmpresa();
    this.model.preDefinidosID = Math.max(0, response.body);
  }

  public readonly fnBeforeChangedStep = (event: IPlNavWizardEventBeforeChange): Promise<boolean> => this._beforeChangedStep(event);

  public readonly fnFinalize: () => Promise<void> = () => this._finalize();

  public readonly fnValidatorStep2: () => boolean = () => this._validatorStep2();

  public readonly fnValidatorStep3: () => boolean = () => this._validatorStep3();

  public readonly fnValidatorStep4: () => boolean = () => this._validatorStep4();

  private async _beforeChangedStep({nextStep, currentStep, type}: IPlNavWizardEventBeforeChange): Promise<boolean> {
    if (currentStep === this.definition.items[STEP_INDEX_BASIC] || (nextStep === this.definition.items[STEP_INDEX_HEADER] && (!type || type === 'set'))) {
      if (this.operation === EContabilidadePredefinidosOperation.EDIT) {
        this.definition.force = true;
      }
      if (this.operation !== EContabilidadePredefinidosOperation.DELETE) {
        if (this.operation === EContabilidadePredefinidosOperation.NEW && type !== 'previous' && !this._validatorStep2()) {
          return false;
        }
        let value: boolean;
        if (type !== 'previous') {
          value = await this._initStep3();
        }
        this.definition.items[STEP_INDEX_HEADER].visible = true;
        this.definition.items[STEP_INDEX_LINES].visible = true;
        this.definition.items[STEP_INDEX_PREVIEW].visible = true;
        this.definition.items[STEP_INDEX_COMPANIES].visible = true;
        return value;
      }
      this.definition.items[STEP_INDEX_HEADER].visible = false;
      this.definition.items[STEP_INDEX_LINES].visible = false;
      this.definition.items[STEP_INDEX_PREVIEW].visible = false;
      this.definition.items[STEP_INDEX_COMPANIES].visible = true;
    }
    return true;
  }

  private _setOperationTitle(): void {
    this._toolTitleOperation.caption =
      this.operation !== EContabilidadePredefinidosOperation.EDIT || !this.model || !isNumber(this.model.preDefinidosID) || !this.model.descricao
        ? ''
        : `${this.model.preDefinidosID} - ${this.model.descricao}`;
    this._toolTitleOperation.visible = Boolean(this._toolTitleOperation.caption);
  }

  private _loadEmpresas(): void {
    const empresas: Array<IJsonErpUser> = this.session.erps;
    let loaded = 0;
    this.listaEmpresas = empresas;
    if (this.operation === EContabilidadePredefinidosOperation.EDIT || this.operation === EContabilidadePredefinidosOperation.DELETE) {
      if (!isArray(this.empresasSelecionadas)) {
        this.empresasSelecionadas = [];
      }
      for (let i = 0; i < this.empresasSelecionadas.length; i++) {
        const modelEmpresa: IJsonErpUser = this.empresasSelecionadas[i];
        const index = this.listaEmpresas.findIndex((accessEmpresa) => modelEmpresa.cgID === accessEmpresa.cgID);
        if (index === -1) {
          this.empresasSelecionadas.splice(i, 1);
        }
      }
      loaded = this.empresasSelecionadas.length;
    }
    if (!loaded || this.operation === EContabilidadePredefinidosOperation.NEW) {
      this.empresasSelecionadas = [this.session.erp];
    }
    this.listaEmpresas = this.listaEmpresas.filter((empresa) => {
      const selecionada = this.empresasSelecionadas.find((toCompare) => toCompare.cgID === empresa.cgID);
      return isUndefined(selecionada);
    });
    this.changedEmpresasSelecionadas();
  }

  private _clearDocContab(): void {
    this.docContabilidade = this._docContabilidadeService.emptyDoc();
  }

  private _setModel(preDefinidoContab: IPreDefinidoContab): void {
    const cgBanking: boolean = this.model.cgBanking;
    this.model = {
      preDefinidosID: this.model.preDefinidosID,
      descricao: this.model.descricao || preDefinidoContab.descricao,
      revision: preDefinidoContab.revision,
      cgBanking: preDefinidoContab.cgBanking,
      isGeneric: preDefinidoContab.isGeneric,
      cabecalho: preDefinidoContab.cabecalho,
      linhas: preDefinidoContab.linhas,
      empresasSelecionadas: preDefinidoContab.empresasSelecionadas,
      ordemColunas: preDefinidoContab.ordemColunas
    };
    if (this.operation === EContabilidadePredefinidosOperation.EDIT) {
      this.model.preDefinidosID = preDefinidoContab.preDefinidosID;
      this.model.cgBanking = cgBanking;
    }
  }

  private _initStep3(): Promise<boolean> {
    if (this.operation !== EContabilidadePredefinidosOperation.NEW) {
      if (this.operation === EContabilidadePredefinidosOperation.EDIT) {
        this._setOperationTitle();
      }
      return new Promise<boolean>((resolve) => {
        if (isFunction(this.preDefinidoContabCab.validatePanels)) {
          this.preDefinidoContabCab.validatePanels();
        }

        switch (this.operation) {
          case EContabilidadePredefinidosOperation.NEW_BASE_DOC:
            this._contabilidadePredefinidosService
              .getFromDocContabilidade(this.docContabilidade.extPocCabID)
              .then((response) => {
                this._setModel(response.body);
                resolve(true);
              })
              .catch(() => {
                this._plAlertService.error(
                  this._translateService.instant('predefinidoscontabilidade.errors.invalidDocContab', {
                    doc: this.docContabilidade.extPocCabID
                  })
                );
                resolve(false);
              });
            break;
          case EContabilidadePredefinidosOperation.NEW_BASE_PREDEFINIDO:
          case EContabilidadePredefinidosOperation.EDIT:
            this._updating = this.operation === EContabilidadePredefinidosOperation.EDIT;
            const id: number = this._updating ? this.model.preDefinidosID : this.preDefinidosIDToCopy;
            this._entityService
              .get({id: id})
              .then((response) => {
                this._setModel(response.body);
                resolve(true);
              })
              .catch(() => {
                resolve(false);
              });
            break;
          default:
            resolve(true);
            break;
        }
      });
    }
    return Promise.resolve(true);
  }

  private _initStep4(): void {
    const isStep4: boolean = this.step === this.definition.items[STEP_INDEX_PREVIEW];
    this.showPreview = isStep4;
    this._btnSimulateSave.visible = isStep4;
    this._btnClearDocContab.visible = isStep4;
  }

  private _validatorStep2(): boolean {
    if (this.formInstanceBasic) {
      return this.formInstanceBasic.valid;
    }
    return true;
  }

  private _validatorStep3(): boolean {
    if (!this.isStep3Valid) {
      this._plAlertService.warning('predefinidocontabcab.errorInvalid');
    }
    return this.isStep3Valid;
  }

  private _validatorStep4(): boolean {
    if (!this.isStep4Valid) {
      this._plAlertService.warning('predefinidocontablinhas.errorInvalid');
    }
    return this.isStep4Valid;
  }

  private async _finalize(): Promise<void> {
    switch (this.operation) {
      case EContabilidadePredefinidosOperation.EDIT:
        const editPromise: Promise<IJsonPreDefinidoContab> = this._entityService
          .put({id: this.model.preDefinidosID, body: this.model, params: {tipooperacao: this.operation}})
          .then((response: HttpResponse<IJsonPreDefinidoContab>) => response.body);
        this._subjectOnSave.next(editPromise);
        const updatedModel: IJsonPreDefinidoContab = await editPromise;
        this._plAlertService.success(this._translateService.instant('predefinidoscontabilidade.success.edit', {id: this.model.preDefinidosID}));
        this.afterSave(updatedModel);
        break;
      case EContabilidadePredefinidosOperation.DELETE:
        const deletePromise: Promise<HttpResponse<void>> = this._entityService.delete({id: this.model.preDefinidosID, body: this.model.empresasSelecionadas});
        this._subjectOnDelete.next(deletePromise.then(() => undefined));
        await deletePromise;
        this._plAlertService.success(this._translateService.instant('predefinidoscontabilidade.success.delete', {id: this.model.preDefinidosID}));
        this.afterDelete(this.model);
        break;
      default:
        await this.save({params: {tipooperacao: this.operation}});
        break;
    }
  }

  private _clearForSaveEventListener(): void {
    this._docContabilidadeService.clearForSaveEventListener(SAVE_PROMPT_IDENTIFIER).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }
}
