import {Component, Injector, OnInit} from '@angular/core';
import {ERadix, PlAlertService} from 'pl-comps-angular';
import {AuthService} from '../../../../services/auth/auth.service';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {DATA_SOURCE_TIPOS_COMUNICACAO_AT} from '../../../../datasources/tipocomunicacaoat/tiposComunicacaoAt.datasource';
import {EMPTY_PASSWORD} from '../../../../../config/constants';
import {ETipoComunicacaoAt} from '../../../../datasources/tipocomunicacaoat/tiposComunicacaoAt.datasource.interface';
import {IDataSourceItem} from '../../../../components/datasource/datasources.interface';
import {ROLE} from '../../../../services/role.const';
import {IJsonEFaturaConfiguracao} from '../../jsonEFatura.module.interface';
import {EFaturaService} from '../../eFatura.module.service';

const DEFAULT_PASSWORD = '**********';

@Component({
  selector: 'efatura-config-modal',
  templateUrl: './eFaturaConfig.modal.component.html'
})
export class EFaturaConfigModalComponent extends CGModalComponent<IJsonEFaturaConfiguracao> implements OnInit {
  public readonly tiposComunicacao: ReadonlyArray<IDataSourceItem<ETipoComunicacaoAt>>;
  public readonly model: IJsonEFaturaConfiguracao;
  public readonly tipoComunicacaoAt: typeof ETipoComunicacaoAt;

  public tipoComunicacao: ETipoComunicacaoAt;
  public modelUsernameNif: string;
  public modelUsernameId: number;
  public promise: Promise<void>;
  public isAdmin: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _authService: AuthService,
    private readonly _efaturaService: EFaturaService
  ) {
    super(_injector);
    this.tipoComunicacao = ETipoComunicacaoAt.Manual;
    this.tipoComunicacaoAt = ETipoComunicacaoAt;
    this.tiposComunicacao = DATA_SOURCE_TIPOS_COMUNICACAO_AT.data;
    this.model = {
      atUsername: '',
      atPassword: EMPTY_PASSWORD,
      temFacturacaoElectronica: false,
      notificaDia10Dia: 2,
      notificaDia10Email: '',
      notificaDia10SeNaoEnviado: false
    };
    this._authService.hasAnyAuthority([ROLE.ADMIN, ROLE.ADMINBASIC]).then((hasAuthority: boolean) => {
      this.isAdmin = hasAuthority;
    });
  }

  public ngOnInit(): void {
    this.modelUsernameNif = this._configService.configurations.empresa.ncontribuemp;
    this.promise = this._efaturaService.getConfiguracaoEFatura().then((response) => {
      if (response.body.atUsername) {
        this._parseModelUsername(response.body.atUsername);
      } else {
        this.modelUsernameId = 0;
      }
      this.model.atUsername = this._getUserName();
      this.model.atPassword = response.body.atPassword ? response.body.atPassword : DEFAULT_PASSWORD;
      this.model.temFacturacaoElectronica = response.body.temFacturacaoElectronica;
      this.model.notificaDia10SeNaoEnviado = response.body.notificaDia10SeNaoEnviado;
      this.model.notificaDia10Dia = response.body.notificaDia10Dia;
      this.model.notificaDia10Email = response.body.notificaDia10Email;
      this.tipoComunicacao = response.body.temFacturacaoElectronica ? ETipoComunicacaoAt.EmTempoReal : ETipoComunicacaoAt.Manual;
    });
  }

  public modelUsernameIdChanged(value: number): void {
    this.modelUsernameId = value;
    this.model.atUsername = this._getUserName();
  }

  public close(): Promise<void> {
    if (this.model.temFacturacaoElectronica) {
      if (!this.model.atUsername) {
        this._plAlertService.error('efaturalogin.messages.errorUsernameEmpty');
        return Promise.resolve();
      }
      if (!this.model.atPassword) {
        this._plAlertService.error('efaturalogin.messages.errorPasswordEmpty');
        return Promise.resolve();
      }
    }

    return this._efaturaService.saveConfiguracaoEFatura(this.model).then(() => {
      super.close(this.model);
    });
  }

  public tipoComunicacaoChanged(value: ETipoComunicacaoAt): void {
    this.tipoComunicacao = value;
    this.model.temFacturacaoElectronica = value === ETipoComunicacaoAt.EmTempoReal;
  }

  private _getUserName(): string {
    return `${this.modelUsernameNif}/${this.modelUsernameId}`;
  }

  private _parseModelUsername(username: string): void {
    const arr = username.split('/');
    this.modelUsernameNif = arr[0];
    this.modelUsernameId = arr.length === 2 ? parseInt(arr[1], ERadix.Decimal) : 0;
    this.model.atUsername = this._getUserName();
  }
}
