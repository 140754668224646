import {Directive, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: 'ng-template[cgcTemplate]',
  standalone: true
})
export class CGCTemplateDirective {
  @Input() public cgcTemplate: undefined | string;

  constructor(public readonly templateRef: TemplateRef<unknown>) {}
}
