import {Component, Injector, Input, OnInit} from '@angular/core';
import {isArray, isDefinedNotNull} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {EImportadorSaftDirectTipoProduto, IJsonImportadorSaftDirectArtigo, IJsonImportadorSaftDirectArtigoModal} from '../../importadorSaftDirect.module.interface';
import {IJsonPOC} from '../../../../../entities/pocs/jsonPOC.entity.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {ImportadorSaftDirectService} from '../../importadorSaftDirect.module.service';
import {HttpErrorResponse} from '@angular/common/http';
import {IRestCmdImportadorSaftDirectConfigArtigos, IRestCmdImportadorSaftDirectConfigArtigosTodosPorTipo} from '../../importadorSaftDirectCommands.module.interface';

const nContaProdutos = '711';
const nContaNCProdutos = '7171';
const nContaServOutros = '721';
const nContaNCServOutros = '7281';

@Component({
  selector: 'importador-saft-direct-artigos-config-modal',
  templateUrl: './importadorSaftDirect.artigos.config.modal.component.html'
})
export class ImportadorSaftDirectArtigosConfigModalComponent
  extends CGModalComponent<IRestCmdImportadorSaftDirectConfigArtigos | IRestCmdImportadorSaftDirectConfigArtigosTodosPorTipo>
  implements OnInit
{
  @Input() public selectedItems: Array<IJsonImportadorSaftDirectArtigo>;
  @Input() public source: Array<IJsonImportadorSaftDirectArtigo>;
  @Input() public tipoProduto: EImportadorSaftDirectTipoProduto;
  @Input() public modalTitle: string;
  @Input() public showCCusto: boolean;
  @Input() public showRepCC: boolean;

  public showArtigoSaftInfo: boolean;
  public model: Partial<IJsonImportadorSaftDirectArtigoModal>;
  public showPb: boolean;
  public applyPromise: Promise<void>;
  private readonly _servicePocs: IEntityService<IJsonPOC>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _importadorSaftDirectService: ImportadorSaftDirectService
  ) {
    super(_injector);
    this.showPb = false;
    this.model = {
      nConta: '',
      nContaNome: '',
      nContaNC: '',
      nContaNCNome: '',
      acresceDigMercado: true,
      acresceDigIVA: true,
      retencao: false,
      nCCusto: '',
      nCodRepCC: 0
    };
    this.showArtigoSaftInfo = false;
    this._servicePocs = this._entityServiceBuilder.build<IJsonPOC>('pocs');
  }

  public ngOnInit(): void {
    if (this.tipoProduto === EImportadorSaftDirectTipoProduto.Produtos) {
      this.model.nConta = nContaProdutos;
      this.model.nContaNC = nContaNCProdutos;
    } else if (this.tipoProduto === EImportadorSaftDirectTipoProduto.Servicos || this.tipoProduto === EImportadorSaftDirectTipoProduto.Outros) {
      this.model.nConta = nContaServOutros;
      this.model.nContaNC = nContaNCServOutros;
    }
    if (isArray(this.selectedItems) && this.selectedItems.length === 1) {
      this.showArtigoSaftInfo = true;
      this.model = this.selectedItems[0];
    }
  }

  public modelChange(item: IJsonPOC): void {
    if (isDefinedNotNull(item)) {
      this.model.nConta = item.nConta;
      this.model.nContaNome = item.nome;
      if (!this.model.nContaNC) {
        let needDescription = false;
        switch (this.model.nConta) {
          case '711':
            needDescription = true;
            this.model.nContaNC = '7181';
            break;
          case '712':
            needDescription = true;
            this.model.nContaNC = '7182';
            break;
          case '721':
            needDescription = true;
            this.model.nContaNC = '7281';
            break;
          case '7871':
            needDescription = true;
            this.model.nContaNC = '6871';
            break;
          default:
            this.model.nContaNC = this.model.nConta;
            this.model.nContaNCNome = this.model.nContaNome;
            break;
        }
        if (needDescription) {
          this._servicePocs.get({id: this.model.nContaNC}).then((response) => {
            if (isDefinedNotNull(response.body)) {
              this.model = {...this.model, nContaNCNome: response.body.nome};
            }
          });
        }
      }
    }
  }

  public fnCloseModal(): void {
    this.showPb = true;
    if (this.selectedItems.length === 0 && isDefinedNotNull(this.tipoProduto)) {
      this.applyPromise = this._importadorSaftDirectService

        .configArtigosPorTipo(
          this.tipoProduto,
          this.model.nConta,
          this.model.nContaNC,
          this.model.acresceDigMercado,
          this.model.acresceDigIVA,
          this.model.retencao,
          this.model.nCCusto,
          this.model.nCodRepCC
        )
        .then((response) => {
          this.close(response);
        })
        .catch((reason: HttpErrorResponse) => {
          this.dismiss(reason);
        })
        .finally(() => {
          this.showPb = false;
        });
    } else {
      let artigosKeyList = [];
      if (this.selectedItems.length !== this.source.length) {
        artigosKeyList = this.selectedItems.map<string>((item) => item.productCode);
      }
      this.applyPromise = this._importadorSaftDirectService
        .configArtigos(artigosKeyList, this.model.nConta, this.model.nContaNC, this.model.acresceDigMercado, this.model.acresceDigIVA, this.model.retencao, this.model.nCCusto, this.model.nCodRepCC)
        .then((response: IRestCmdImportadorSaftDirectConfigArtigos) => {
          this.close(response);
        })
        .catch((reason: HttpErrorResponse) => {
          this.dismiss(reason);
        })
        .finally(() => {
          this.showPb = false;
        });
    }
  }
}
