import {IJsonAnosFaturacao} from '../../anosfaturacao/jsonAnosFaturacao.entity.interface';
import {minDateCG} from '../../../../common/utils/utils';
import {currentYear} from '../../../../common/dates';
import {IPlToolbarMenuItem} from 'pl-comps-angular';

export enum EEstadoEnvioEFatura {
  PorComunicar,
  ParcialmenteComunicado,
  Comunicado,
  Registado,
  Erro
}

export function anosFaturacaoToToolbarItem(anosList: Array<IJsonAnosFaturacao>): Array<IPlToolbarMenuItem<number>> {
  const anos: Array<IPlToolbarMenuItem<number>> = [];
  const currentDateYear: number = currentYear();
  anosList = anosFaturacaoEmFalta(anosList);
  anosList.forEach((item) => {
    const itemAno: IPlToolbarMenuItem<number> = {
      caption: String(item.ano),
      active: item.ano === currentDateYear,
      data: item.ano
    };
    anos.push(itemAno);
  });
  return anos;
}

export function anosFaturacaoEmFalta(anosList: Array<IJsonAnosFaturacao>, ascendent: boolean = false): Array<IJsonAnosFaturacao> {
  const currentDateYear: number = currentYear();
  const minYear = minDateCG().year();

  if (anosList.length === 0) {
    return [{ano: currentDateYear}];
  }

  anosList.sort((a, b) => a.ano - b.ano);

  const completeList: Array<IJsonAnosFaturacao> = [];
  const startYear = Math.max(minYear, anosList[0].ano);
  const endYear = Math.max(currentDateYear, anosList[anosList.length - 1].ano);

  if (ascendent) {
    for (let year = startYear; year <= endYear; year++) {
      completeList.push({ano: year});
    }
  } else {
    for (let year = endYear; year >= startYear; year--) {
      completeList.push({ano: year});
    }
  }

  return completeList;
}
