import {Component, Injector, Input, OnInit} from '@angular/core';
import {isNumber, PlAlertService} from 'pl-comps-angular';
import {IJsonIva} from '../../jsonIva.entity.interface';
import {CriarTaxaIvaModalComponent} from '../../../taxasIva/modals/criataxasiva/criarTaxaIva.modal.component';
import {ModuloEntityDetailComponent} from '../../../../components/module/entitydetail/module.entitydetail.component';
import {minDateCG} from '../../../../../common/utils/utils';
import {IApiRequestConfigWithBody} from '../../../../services/api/api.service.interface';
import {ETiposIVA} from '../../../../datasources/tiposiva/tiposIVA.datasource.interface';

@Component({
  selector: 'ivas-edit',
  templateUrl: './ivas.entity.edit.component.html'
})
export class IvasEditComponent extends ModuloEntityDetailComponent<IJsonIva> implements OnInit {
  @Input() public contabilidade: boolean;

  public readonly tiposIva: typeof ETiposIVA;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.tiposIva = ETiposIVA;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (this.type === this.stateTypes.NEW) {
      this.model.percDedGasoleo = 100;
      this.model.percDedProRata = 100;
      this.model.dataTaxaActual = minDateCG();
    }
  }

  public save(config?: IApiRequestConfigWithBody<IJsonIva>): Promise<IJsonIva> {
    if (this.model.tipoTaxa in [ETiposIVA.Isenta, ETiposIVA.IsentoCEE, ETiposIVA.IsentoForaCEE] && this.model.taxaActual > 0) {
      this.model.ivaMotivosTaxaId = undefined;
    }
    return super.save(config);
  }

  public readonly fnEditTaxaIva = (): Promise<void> => this._editTaxasIva();

  private _editTaxasIva(): Promise<void> {
    if (!isNumber(this.model.codIva)) {
      this._plAlertService.error('taxasiva.codIvaNull');
      return Promise.resolve();
    }
    const modalInstance = this._cgModalService.showVanilla(CriarTaxaIvaModalComponent);
    const componentInstance: CriarTaxaIvaModalComponent = modalInstance.componentInstance;
    componentInstance.codIVA = this.model.codIva;
    return modalInstance.result.then(() => {
      return this.service.get({id: this.model.codIva}).then((response) => {
        this.model = response.body;
      });
    });
  }
}
