import {EDateMonth} from 'pl-comps-angular';
import {TDate} from '../../../common/dates';
import {IDataSourceItem} from '../../components/datasource/datasources.interface';
import {Writeable} from '../../../common/interfaces/interfaces';

export enum ESAFTState {
  Inactive = 'ssInactive',
  Scheduled = 'ssScheduled',
  Started = 'ssStarted',
  Ended = 'ssEnded',
  Timeout = 'ssTimeout',
  Error = 'ssError'
}

export enum ESAFTType {
  CONTABILIDADE_FATURACAO_RECIBOS,
  AUTOFATURACAO,
  TODOS
}

export interface ISaftError {
  nome: string;
  descricao: string;
}

export interface ISaftStatus {
  userStarted: string;
  requestDate: TDate;
  plannedStartDate: TDate;
  position: number;
  description: string;
  state: ESAFTState;
  currentOp: number;
  userStartedId: number;
  filename: string;
  max: number;
  ficheiroProcessado: boolean;
  errorList: Array<ISaftError>;
  ficheiroProcessadoDate: TDate;
  ficheiroProcessadoSize: number;
}

export interface ISaftFilters {
  ano: number;
  mes: Writeable<IDataSourceItem<EDateMonth>>;
  mesDe: Writeable<IDataSourceItem<EDateMonth>>;
  mesAte: Writeable<IDataSourceItem<EDateMonth>>;
  faturacao: boolean;
  contabilidade: boolean;
  recibos: boolean;
  type: ESAFTType;
  nConta: string;
  nomeClifo?: string;
}

export enum ESaftStep {
  Configuration,
  Processing,
  Errors
}

export interface ISaftFornecedorAf {
  nConta: string;
  nome: string;
  nContribuint: string;
}

export interface ISAFTStateParams {
  exportSaftType?: ESAFTType;
  ano?: number;
  mes?: number;
}

export const MODULE_NAME_SAFT = 'saft';
