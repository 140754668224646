import {Injectable} from '@angular/core';
import {ApiService} from '../../../services/api/api.service';
import {IJsonRHSalToTrf, IJsonRHSalToTrfDadosTransf} from './jsonRHSalToTrf.module.interface';
import {IRHSalToTrfHeader} from '../rhfolfer/rhFolfer.module.interface';
import {TDate} from '../../../../common/dates';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {Observable} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {IJsonMeioPagamento} from '../../../entities/meiospagamento/jsonMeioPagamento.entity.interface';

@Injectable({
  providedIn: 'root'
})
export class RHSalToTrfService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/rhsaltotrf`;
  }

  public getMeiosPagamento(): TServiceResponse<Array<IJsonMeioPagamento>> {
    return this._apiService.get<Array<IJsonMeioPagamento>>({
      url: `${this._path}/meiospagamentosalarios`
    });
  }

  public getVencimentos(rhSalToTrfHeader: IRHSalToTrfHeader, exclproc: string): TServiceResponse<Array<IJsonRHSalToTrf>> {
    return this._apiService.get<Array<IJsonRHSalToTrf>>({
      url: `${this._path}/vencimentos`,
      params: {
        codempde: rhSalToTrfHeader.codEmpDe,
        codempate: rhSalToTrfHeader.codEmpAte,
        datade: rhSalToTrfHeader.dataProcesDe,
        dataate: rhSalToTrfHeader.dataProcesAte,
        exclproc: exclproc
      }
    });
  }

  public getTicketsRef(rhSalToTrfHeader: IRHSalToTrfHeader, exclproc: string): TServiceResponse<Array<IJsonRHSalToTrf>> {
    return this._apiService.get<Array<IJsonRHSalToTrf>>({
      url: `${this._path}/tickets`,
      params: {
        codempde: rhSalToTrfHeader.codEmpDe,
        codempate: rhSalToTrfHeader.codEmpAte,
        datade: rhSalToTrfHeader.dataProcesDe,
        dataate: rhSalToTrfHeader.dataProcesAte,
        exclproc: exclproc
      }
    });
  }

  public getDadosNCaixaPagadora(nCaixa: string, ecartaorefeicao: boolean): TServiceResponse<IJsonRHSalToTrfDadosTransf> {
    return this._apiService.get<IJsonRHSalToTrfDadosTransf>({
      url: `${this._path}/dadostransf?ncaixa=${nCaixa}`,
      params: {
        ecartaorefeicao: ecartaorefeicao
      }
    });
  }

  public gravarDadosTransf(nCaixa: string, datatransf: TDate, ecartaorefeicao: boolean, rhsaltotrflist: Array<IJsonRHSalToTrf>): TServiceResponse<void> {
    return this._apiService.post<void, Array<IJsonRHSalToTrf>>({
      url: `${this._path}/dadostransf`,
      body: rhsaltotrflist,
      params: {
        ncaixa: nCaixa,
        datatransf: datatransf,
        ecartaorefeicao: ecartaorefeicao
      }
    });
  }

  public corrigeCartao(bancoemi: string, rhsaltotrf: IJsonRHSalToTrf): TServiceResponse<IJsonRHSalToTrf> {
    return this._apiService.post<IJsonRHSalToTrf>({
      url: `${this._path}/corrigecartao`,
      body: rhsaltotrf,
      params: {
        bancoemi: bancoemi
      }
    });
  }

  public getReport(rhSalToTrfHeader: IRHSalToTrfHeader, reportName: string): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/report`, {
      codempde: rhSalToTrfHeader.codEmpDe,
      codempate: rhSalToTrfHeader.codEmpAte,
      datade: rhSalToTrfHeader.dataProcesDe,
      dataate: rhSalToTrfHeader.dataProcesAte,
      reportname: reportName
    });
  }
}
