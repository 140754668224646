<entity-list
  [entityName]="entityName"
  [maintenanceMode]="maintenanceMode"
  [toolbarInstanceId]="instanceName"
  [dataGridState]="dataGridState"
  [searchValue]="searchValue"
  [filter]="filterValue"
  [initialFilter]="initialFilterValue"
  [serviceMethodsOverride]="serviceMethodsOverride"
  [properties]="listOptions"
  (evtOnRefresh)="onRefreshList()"
  (evtDataGridPreparing)="onDataGridPreparing($event)"
  (evtDataGridCellClick)="onDataGridCellClick($event)"
  (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
  (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
  (evtDataGridStateChanged)="onDataGridStateChanged($event)"
  (evtDataGridStateCleared)="onDataGridStateCleared()">
  <div *entityListItemActions="let item">
    <pl-tooltip [config]="{text: 'docfasNums.btn.comunicarSeries', container: 'body'}">
      <button type="button" *plTooltipContent class="btn btn-xs btn-success" (click)="comunicarSerie(item.data)"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
    </pl-tooltip>
    &nbsp;
    <pl-tooltip [config]="{text: 'docfasNums.btn.logSeries', container: 'body'}">
      <button type="button" *plTooltipContent class="btn btn-xs btn-info" (click)="logSerie(item.data)"><i class="fa fa-list" aria-hidden="true"></i></button>
    </pl-tooltip>
    &nbsp;
    <pl-tooltip [config]="{text: 'docfasNums.btn.consultarSeries', container: 'body'}">
      <pl-button *plTooltipContent klass="btn btn-xs btn-primary" [onClick]="fnConsultarSerie(item.data)"><i class="fa fa-search" aria-hidden="true"></i></pl-button>
    </pl-tooltip>
  </div>
</entity-list>
