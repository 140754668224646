import {ENTITY_NAME_WS_SS_LOGIN, IIWsSSLoginEntity} from './wsSSLogin.entity.interface';
import {ILoginWsSS} from '../../modules/segsocial/loginWsSS.module.interface';
import {ROLE} from '../../services/role.const';

export const ENTITY_WS_SS_LOGIN: IIWsSSLoginEntity = {
  name: ENTITY_NAME_WS_SS_LOGIN,
  asModule: false,
  roles: [ROLE.ADMIN, ROLE.ADMINBASIC, ROLE.ERP, ROLE.CONTABILIDADE],
  metadata: {
    keyName: 'username',
    fields: [
      {
        name: 'username',
        width: '120px',
        caption: 'Username',
        placeholder: 'Username',
        validators: {required: true, min: 1}
      },
      {
        name: 'password',
        caption: 'password',
        placeholder: 'password',
        validators: {required: true}
      }
    ],
    order: 'username',
    searchFields: 'username,password',
    detailFields: 'username,password',
    listFields: 'username,password',
    newFields: 'username,password',
    editFields: 'username,password'
  },
  service: function () {
    this.getFor = (nEmpresa: string) => {
      return this.get({id: nEmpresa});
    };

    this.postFor = (nEmpresa: string, credentials: ILoginWsSS) => {
      return this.post({
        url: nEmpresa,
        body: credentials
      });
    };
  }
};
