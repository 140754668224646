<div class="listagens entity-detail-form">
  <cg-card-panel #cardPanel="cgCardPanel">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1" [submit]="fnPesquisar" plPromise>
        <pl-group>
          <pl-group>
            <pl-group *ngIf="modo === 0">
              <label [translate]="'analisemargemlucro.caption.nrefprocesso'"></label>
              <edit>
                <entity-autocomplete attrName="nRefProcesso" entity="processos" [(selectedKey)]="nRefProcesso" [output]="'{{nRefProcesso}} - {{nome}}'" [helperMode]="true" plAutoFocus>
                </entity-autocomplete>
              </edit>
            </pl-group>
            <pl-group *ngIf="modo === 1">
              <label [translate]="'analisemargemlucro.caption.nccusto'"></label>
              <edit>
                <entity-autocomplete attrName="nCCusto" entity="ccustos" [(selectedKey)]="nCCusto" [output]="'{{nCCusto}} - {{nome}}'" [helperMode]="true" plAutoFocus></entity-autocomplete>
              </edit>
            </pl-group>
          </pl-group>
          <pl-group>
            <label [translate]="'analisemargemlucro.caption.estado'"></label>
            <edit>
              <pl-edit-select [source]="estados" [(model)]="estadoSelecionado" [properties]="{disallowClear: true}"></pl-edit-select>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <pl-group class="datade-edit">
            <label [translate]="'analisemargemlucro.caption.datade'"></label>
            <edit>
              <pl-edit-datepicker attrName="datade" [(model)]="dataDe"></pl-edit-datepicker>
            </edit>
          </pl-group>
          <pl-group class="dataate-edit">
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit-datepicker attrName="dataate" [(model)]="dataAte"></pl-edit-datepicker>
            </edit>
          </pl-group>
        </pl-group>
        <pl-group>
          <edit>
            <pl-button type="submit" klass="btn btn-sm btn-primary" id="btnPesquisar"><i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span></pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <br />

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    [dataSource]="dataGridDefinition.dataSource"
    cgDxDataGridInstanceName="analiseMargemLucroGrid"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()"
    (onCellClick)="onCellClick($event)"
    (onRowExpanding)="onRowExpand($event)"
    (onCellPrepared)="onCellPrepared($event)">
    <div *dxTemplate="let row of 'templateMasterDetail'">
      <dx-data-grid
        [cgDxDataGrid]="dataGridDetailDefinition"
        [cgDxDataGridInstanceName]="'analiseMargemLucroGridDetail'"
        [dataSource]="row.data.detalhe"
        (onInitialized)="onInitializedDetail($event)"
        (onContentReady)="onContentReadyDetail()">
        <div *dxTemplate="let item of 'actions'">
          <pl-tooltip [config]="{text: 'analisemargemlucro.detail.actions.documento', container: 'body'}">
            <button type="button" *plTooltipContent class="btn btn-xs btn-info" (click)="verDocumento(item.data.faccbId)"><i class="fa fa-file-text-o" aria-hidden="true"></i></button>
          </pl-tooltip>
        </div>
      </dx-data-grid>
    </div>
  </dx-data-grid>
</div>
