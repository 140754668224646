import {Component, Input} from '@angular/core';
import {CGStateService} from '../../../../../../components/state/cg.state.service';
import {EContabilidadePredefinidosOperation, ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS, IPreDefinidoDetailStateParams} from '../../preDefinidosContab.entity.interface';
import {EEntityStateDetailType} from '../../../../../../../common/utils/entity.state.utils';
import {IJsonPreDefinidoContab} from '../../jsonPreDefinidosContab.entity.interface';

@Component({
  selector: 'contabilidade-predefinidos-preview',
  templateUrl: './contabilidade.predefinido.preview.component.html'
})
export class ContabilidadePredefinidoPreviewComponent {
  @Input() public predefinido: IJsonPreDefinidoContab;

  constructor(private readonly _cgStateService: CGStateService) {
    this.editarPreDefinido = this.editarPreDefinido.bind(this);
    this.apagarPreDefinido = this.apagarPreDefinido.bind(this);
  }

  public async editarPreDefinido(): Promise<void> {
    if (!this.predefinido?.preDefinidosID) {
      return;
    }
    const id: number = this.predefinido.preDefinidosID;
    const params: IPreDefinidoDetailStateParams = {id: id, operation: EContabilidadePredefinidosOperation.EDIT};
    await this._cgStateService.redirectToState({stateOrName: ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS, stateType: EEntityStateDetailType.EDIT, params: params});
  }

  public async apagarPreDefinido(): Promise<void> {
    if (!this.predefinido?.preDefinidosID) {
      return;
    }
    const id: number = this.predefinido.preDefinidosID;
    const params: IPreDefinidoDetailStateParams = {id: id, operation: EContabilidadePredefinidosOperation.DELETE};
    await this._cgStateService.redirectToState({stateOrName: ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS, stateType: EEntityStateDetailType.EDIT, params: params});
  }
}
