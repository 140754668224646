<div class="rendis">
  <entity-detail
    entityName="rendis"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [formInstance]="form"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL, validators: {required: {value: true}}, orientation: formOrientation}" (formInstanceChange)="form = $event">
        <pl-group>
          <label [translate]="'rendis.fields.tipoDeclaracao'"></label>
          <edit>
            <pl-autocomplete
              attrName="tipoDeclaracao"
              [source]="tiposDeclaracao"
              [model]="tipoDeclaracao"
              (modelChange)="tipoDeclaracaoChanged($event)"
              [rowTemplate]="'descricao'"
              [output]="'descricao'"
              [allowInvalid]="false"
              [allowEmpty]="false">
            </pl-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'rendis.fields.tipRendiment'"></label>
          <edit>
            <pl-edit-text attrName="tipRendiment" [(model)]="model.tipRendiment" [properties]="{validators: {maxlength: {value: 5}}}"></pl-edit-text>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'rendis.fields.nome'"></label>
          <edit>
            <pl-edit-text attrName="nome" [(model)]="model.nome" [properties]="{validators: {maxlength: {value: 240}}}"></pl-edit-text>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
