<div class="modelo22-anexod-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'modelo22.modal.anexod.titles.' + newLineFor"></h5>
  </div>

  <div class="modal-body">
    <pl-form>
      <pl-group *ngIf="!is604 && !is605 && !isQuadro8 && !isQuadro9 && !isQuadro11A && !is603">
        <label [translate]="'modelo22.modal.anexod.codBeneficio'"></label>
        <edit>
          <pl-autocomplete
            [source]="dsBeneficio"
            [model]="codBeneficioView"
            (evtSelected)="evtBeneficioChanged($event)"
            [allowInvalid]="false"
            [rowTemplate]="dsBeneficioTemplate"
            [output]="dsBeneficioTemplate">
          </pl-autocomplete>
        </edit>
      </pl-group>

      <pl-group *ngIf="isQuadro8">
        <label [translate]="'modelo22.modal.anexod.tipoDonativo'"></label>
        <edit>
          <pl-edit type="modelo22tipodonativo" attrName="tipoDonativo" [(model)]="tipoDonativo"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is041 || isQuadro8 || isQuadro9 || isQuadro11A || is121 || is122">
        <label [translate]="'modelo22.modal.anexod.nif' + newLineFor"></label>
        <edit>
          <pl-edit type="cginteger" attrName="nif" [(model)]="nif"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is603">
        <label [translate]="'modelo22.modal.anexod.ano' + newLineFor"></label>
        <edit>
          <pl-edit type="cginteger" attrName="ano" [(model)]="ano" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="!is604 && !is605 && !isQuadro8 && !isQuadro9 && !isQuadro11A">
        <label [translate]="'modelo22.modal.anexod.montante'"></label>
        <edit>
          <pl-edit type="number" attrName="montante" [(model)]="montante"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is604 || is605">
        <label [translate]="'modelo22.modal.anexod.nace' + newLineFor"></label>
        <edit>
          <pl-edit type="number" attrName="nace" [(model)]="nace"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="isQuadro8">
        <label [translate]="'modelo22.modal.anexod.valorDonativo'"></label>
        <edit>
          <pl-edit type="number" attrName="valorDonativo" [(model)]="valorDonativo"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is121">
        <label [translate]="'global.text.date'"></label>
        <edit>
          <pl-edit type="date" attrName="data" [(model)]="data"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is121 || is122">
        <label [translate]="'modelo22.modal.anexod.percentagem'"></label>
        <edit>
          <pl-edit type="number" attrName="percentagem" [(model)]="percentagem"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is121 || is122">
        <label [translate]="'modelo22.modal.anexod.periodo'"></label>
        <edit>
          <pl-edit type="cginteger" attrName="periodo" [(model)]="periodo" [properties]="{validate: false}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is121 || is122">
        <label [translate]="'modelo22.modal.anexod.prejuizosMontante'"></label>
        <edit>
          <pl-edit type="number" attrName="prejuizosMontante" [(model)]="prejuizosMontante"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="is121 || is122">
        <label [translate]="'modelo22.modal.anexod.prejuizosSaldo'"></label>
        <edit>
          <pl-edit type="number" attrName="prejuizosSaldo" [(model)]="prejuizosSaldo"></pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-success action-submit" id="saveNewLine" [onClick]="close"><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'global.btn.save'"></span></pl-button>

    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
  </div>
</div>
