import {Observable, Subject} from 'rxjs';
import {Injectable, OnDestroy} from '@angular/core';
import {IPlToolbarInstance, IPlToolbarItem, IPlToolbarMenuItem, isNumber, PlI18nService} from 'pl-comps-angular';
import {IJsonProcessamentoSalarios} from '../../../entities/dgemps/jsonDGEMP.entity.interface';
import {IPCARHSalariosServiceRefreshedEvent} from './pcaRHSalarios.module.service.interface';
import moment from 'moment';
import {PortalClientAccountService} from '../../../services/portalclientaccount/portalclientaccount.service';
import {TOOLBAR_GROUP_ID_PCA} from '../../../../config/constants';

@Injectable({
  providedIn: 'root'
})
export class PcaRHSalariosService implements OnDestroy {
  private readonly _subjectOnRefresh: Subject<IPCARHSalariosServiceRefreshedEvent>;
  private readonly _dropdownAnos: IPlToolbarItem<number>;
  private readonly _dropdownProcessamentos: IPlToolbarItem<IJsonProcessamentoSalarios>;
  private _processamentos: Array<IJsonProcessamentoSalarios>;
  private _ano: number;
  private _processamento: IJsonProcessamentoSalarios;
  private _observableOnRefresh: Observable<IPCARHSalariosServiceRefreshedEvent>;

  constructor(
    private readonly _portalClientAccountService: PortalClientAccountService,
    private readonly _plI18nService: PlI18nService
  ) {
    this._subjectOnRefresh = new Subject<IPCARHSalariosServiceRefreshedEvent>();
    this._dropdownAnos = {
      groupId: TOOLBAR_GROUP_ID_PCA,
      id: 'pcarhsalariosanos',
      order: 1,
      type: 'dropdown',
      class: 'btn-primary',
      visible: true,
      menu: []
    };
    this._dropdownProcessamentos = {
      groupId: TOOLBAR_GROUP_ID_PCA,
      id: 'pcarhsalariosmeses',
      order: 2,
      type: 'dropdown',
      class: 'btn-primary',
      visible: true,
      menu: []
    };
    this._processamentos = [];
    this._ano = 0;
  }

  public ngOnDestroy(): void {
    this._subjectOnRefresh.complete();
  }

  public init(toolbarInstance: IPlToolbarInstance): Promise<void> {
    this._dropdownProcessamentos.menu = [];
    this._dropdownProcessamentos.caption = '';
    this._dropdownAnos.menu = [];
    this._dropdownAnos.caption = '';
    this._initToolbar(toolbarInstance);
    return this._initAnos();
  }

  public onRefresh(): Observable<IPCARHSalariosServiceRefreshedEvent> {
    if (!this._observableOnRefresh) {
      this._observableOnRefresh = this._subjectOnRefresh.asObservable();
    }
    return this._observableOnRefresh;
  }

  public showDropdownAnos(): void {
    this._dropdownAnos.visible = true;
  }

  public hideDropdownAnos(): void {
    this._dropdownAnos.visible = false;
  }

  public showDropdownProcessamentos(): void {
    this._dropdownProcessamentos.visible = true;
  }

  public hideDropdownProcessamentos(): void {
    this._dropdownProcessamentos.visible = false;
  }

  public selectProcessamento(nProcessamentoOrItem: number | IJsonProcessamentoSalarios): boolean {
    const processamento: IJsonProcessamentoSalarios = this._findProcessamento(nProcessamentoOrItem);
    if (!processamento) {
      return false;
    }

    // Verificar se tem de mudar de ano
    const anoProcessamento: number = moment(processamento.dataProcessamento).year();
    if (anoProcessamento !== this._ano) {
      const anoMenuItem: IPlToolbarMenuItem<number> = this._findAnoMenuItem(anoProcessamento);
      if (anoMenuItem) {
        this._mudaAno(anoMenuItem);
      }
    }

    const processamentoMenuItem: IPlToolbarMenuItem<IJsonProcessamentoSalarios> = this._findProcessamentoMenuItem(processamento);
    if (!processamentoMenuItem) {
      return false;
    }

    this._mudaProcessamento(processamentoMenuItem);
    return true;
  }

  public get ano(): number {
    return this._ano;
  }

  public get processamento(): IJsonProcessamentoSalarios {
    return this._processamento;
  }

  private _initToolbar(toolbarInstance: IPlToolbarInstance): void {
    toolbarInstance.removeGroupId(TOOLBAR_GROUP_ID_PCA);
    toolbarInstance.addButton(this._dropdownAnos);
    toolbarInstance.addButton(this._dropdownProcessamentos);
  }

  private _initAnos(): Promise<void> {
    return this._portalClientAccountService.getProcessamentosSalarios().then((response) => {
      this._processamentos = response.body;
      this._dropdownAnos.menu = [];

      let anos: Array<number> = [];
      for (const processamento of this._processamentos) {
        const ano = moment(processamento.dataProcessamento).year();
        if (!anos.length || !anos.includes(ano)) {
          anos.push(ano);
        }
      }
      anos = anos.sort((a, b) => b - a);
      for (const ano of anos) {
        this._dropdownAnos.menu.push({
          caption: String(ano),
          data: ano,
          click: (menuItem: IPlToolbarMenuItem<number>) => {
            this._mudaAno(menuItem);
          }
        });
      }

      this._dropdownAnos.disabled = true;
      if (this._dropdownAnos.menu.length) {
        this.showDropdownAnos();
        this._mudaAno(this._dropdownAnos.menu[0]);
      } else {
        this.hideDropdownAnos();
      }
      this._dropdownAnos.disabled = false;
    });
  }

  private _mudaAno(menuItem: IPlToolbarMenuItem<number>): void {
    this._dropdownAnos.caption = menuItem.caption;
    this._ano = menuItem.data;
    if (!this._fillProcessamentos(this._ano)) {
      this._refresh();
    }
  }

  private _fillProcessamentos(ano: number): boolean {
    let refreshed = false;
    this._dropdownProcessamentos.menu = [];

    // Config dropdown menu
    let processamentos: Array<IJsonProcessamentoSalarios> = [];
    for (const processamento of this._processamentos) {
      const anoProcessamento = moment(processamento.dataProcessamento).year();
      if (anoProcessamento === ano) {
        processamentos.push(processamento);
      }
    }

    processamentos = processamentos.sort((a, b) => moment(b.dataProcessamento).unix() - moment(a.dataProcessamento).unix());

    for (const processamento of processamentos) {
      this._dropdownProcessamentos.menu.push({
        caption: `${this._plI18nService.formatDate(processamento.dataProcessamento, 'dd MMMM')} - ${processamento.descricao} (${processamento.tipoProceDesc})`,
        data: processamento,
        click: (mnu: IPlToolbarMenuItem<IJsonProcessamentoSalarios>) => {
          this._mudaProcessamento(mnu);
        }
      });
    }

    this._dropdownProcessamentos.disabled = true;
    if (this._dropdownProcessamentos.menu.length) {
      this._mudaProcessamento(this._dropdownProcessamentos.menu[0]);
      this.showDropdownProcessamentos();
      refreshed = true;
    } else {
      this.hideDropdownProcessamentos();
    }
    this._dropdownProcessamentos.disabled = false;
    return refreshed;
  }

  private _mudaProcessamento(menuItem: IPlToolbarMenuItem<IJsonProcessamentoSalarios>): void {
    this._dropdownProcessamentos.caption = menuItem.caption;
    this._processamento = menuItem.data;
    this._refresh();
  }

  private _refresh(): void {
    this._subjectOnRefresh.next({ano: this._ano, processamento: this._processamento});
  }

  private _findAnoMenuItem(ano: number): IPlToolbarMenuItem<number> {
    return this._dropdownAnos.menu.find((anoMenuItem: IPlToolbarMenuItem<number>) => {
      return ano === anoMenuItem.data;
    });
  }

  private _findProcessamento(nProcessamentoOrItem: number | IJsonProcessamentoSalarios): IJsonProcessamentoSalarios {
    const isParamANumber: boolean = isNumber(nProcessamentoOrItem);
    return this._processamentos.find((processamento: IJsonProcessamentoSalarios) => {
      return isParamANumber ? nProcessamentoOrItem === processamento.nProcessamento : (<IJsonProcessamentoSalarios>nProcessamentoOrItem).nProcessamento === processamento.nProcessamento;
    });
  }

  private _findProcessamentoMenuItem(nProcessamentoOrItem: number | IJsonProcessamentoSalarios): IPlToolbarMenuItem<IJsonProcessamentoSalarios> {
    const isParamANumber: boolean = isNumber(nProcessamentoOrItem);
    return this._dropdownProcessamentos.menu.find((processamentoMenuItem: IPlToolbarMenuItem<IJsonProcessamentoSalarios>) => {
      return isParamANumber
        ? nProcessamentoOrItem === processamentoMenuItem.data.nProcessamento
        : (<IJsonProcessamentoSalarios>nProcessamentoOrItem).nProcessamento === processamentoMenuItem.data.nProcessamento;
    });
  }
}
