import {Injectable} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {IJsonExtractoArtigo} from './jsonExtractoArtigo.module.interface';
import {TDate} from '../../../common/dates';
import {Observable} from 'rxjs';
import {buildSessionUrlWithParams} from '../../../common/utils/utils';
import {IApiQueryResponse, TServiceQueryResponse, TServiceResponse} from '../../services/api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ExtractosArtigosService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/artigos`;
  }

  public getExtractosArtigos(
    nArtigoDe: string,
    nArtigoAte: string,
    nArmazemDe: number,
    nArmazemAte: number,
    dataDe: TDate,
    dataAte: TDate,
    calcMovAntesDepoisData: boolean
  ): TServiceQueryResponse<IJsonExtractoArtigo> {
    return this._apiService.get<IApiQueryResponse<IJsonExtractoArtigo>>({
      url: `${this._path}/extratos`,
      params: {
        nartigo: nArtigoDe,
        nartigoate: nArtigoAte,
        narmazemde: nArmazemDe,
        narmazemate: nArmazemAte,
        datadocde: dataDe,
        datadocate: dataAte,
        calcmovantesdepoisdata: calcMovAntesDepoisData
      }
    });
  }

  public getExtractosArtigosXlsUrl(nArtigoDe: string, nArtigoAte: string, nArmazemDe: number, nArmazemAte: number, dataDe: TDate, dataAte: TDate, calcMovAntesDepoisData: boolean): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/extratos/exportToXls`, {
      nartigo: nArtigoDe,
      nartigoate: nArtigoAte,
      narmazemde: nArmazemDe,
      narmazemate: nArmazemAte,
      datadocde: dataDe,
      datadocate: dataAte,
      calcmovantesdepoisdata: calcMovAntesDepoisData
    });
  }

  public getExtractosArtigosXls(
    nArtigoDe: string,
    nArtigoAte: string,
    nArmazemDe: number,
    nArmazemAte: number,
    dataDe: TDate,
    dataAte: TDate,
    calcMovAntesDepoisData: boolean
  ): TServiceResponse<Blob> {
    return this._apiService.get<Blob>({
      url: `${this._path}/extratos/exportToXls`,
      params: {
        nartigo: nArtigoDe,
        nartigoate: nArtigoAte,
        narmazemde: nArmazemDe,
        narmazemate: nArmazemAte,
        datadocde: dataDe,
        datadocate: dataAte,
        calcmovantesdepoisdata: calcMovAntesDepoisData
      },
      responseType: 'blob'
    });
  }

  public getExtractosArtigosPdf(
    nArtigoDe: string,
    nArtigoAte: string,
    nArmazemDe: number,
    nArmazemAte: number,
    dataDe: TDate,
    dataAte: TDate,
    calcMovAntesDepoisData: boolean,
    rptName: string
  ): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/extratos/pdf`, {
      nartigo: nArtigoDe,
      nartigoate: nArtigoAte,
      narmazemde: nArmazemDe,
      narmazemate: nArmazemAte,
      datadocde: dataDe,
      datadocate: dataAte,
      calcmovantesdepoisdata: calcMovAntesDepoisData,
      rptname: rptName
    });
  }
}
