<div class="svat-container">
  <pl-form *ngIf="viewData.length === 0">
    <pl-alert [type]="'info'"><i class="fa fa-exclamation-circle"></i>&nbsp;<span [translate]="'svat.messages.noData'"></span></pl-alert>
  </pl-form>

  <pl-tabs [theme]="'style3'">
    <pl-tab *ngFor="let mainTab of viewData; trackBy: trackMainTabs; let $index = index" [id]="mainTab.tabTitle">
      <div *plTabTitle><span [translate]="mainTab.tabTitle"></span></div>

      <div *plTabContent>
        <div *ngFor="let grandGroup of mainTab.grandGroups; let i = index" [attr.data-index]="i" class="svat-grandGroup">
          <div class="card panel-primary" [ngClass]="{'as-simple-div': grandGroup.title.length === 0}">
            <div class="card-header svat-grandGroup-header" *ngIf="grandGroup.title.length > 0" (click)="builder.toggleCollapseGroup(grandGroup.uid)">
              <h4 class="card-title">
                <span class="svat-collapse-toggle">
                  <i [ngClass]="builder.isCollapsed(grandGroup.uid) ? 'fa-caret-right' : 'fa-caret-down'" class="fa"></i>
                </span>
                <span [translate]="grandGroup.title"></span>
              </h4>
            </div>

            <pl-animation-collapse [collapsed]="builder.isCollapsed(grandGroup.uid)">
              <div class="card-body">
                <div class="svat-grandGroup-body">
                  <div *ngFor="let medGroup of grandGroup.medGroups; let m = index" [attr.data-index]="m" class="svat-medGroup">
                    <div class="card" [ngClass]="{'as-simple-div': medGroup.title.length === 0}">
                      <div class="card-header svat-subGroup-header" *ngIf="medGroup.title.length > 0" (click)="builder.toggleCollapseGroup(medGroup.uid)">
                        <h4 class="card-title">
                          <span class="svat-collapse-toggle" (click)="builder.toggleCollapseGroup(medGroup.uid)">
                            <i [ngClass]="builder.isCollapsed(medGroup.uid) ? 'fa-caret-right' : 'fa-caret-down'" class="fa"></i>
                          </span>
                          <span [translate]="medGroup.title"></span>
                        </h4>
                      </div>
                      <pl-animation-collapse [collapsed]="builder.isCollapsed(medGroup.uid)">
                        <div class="card-body">
                          <div *ngFor="let subGroup of medGroup.subGroups" class="svat-subGroup">
                            <div class="card" *ngIf="subGroup.tableSource.length > 0 || subGroup.tableSource.list.length > 0">
                              <div class="card-header svat-subGroup-header" *ngIf="subGroup.title.length > 0" (click)="builder.toggleCollapseGroup(subGroup.uid)">
                                <h4 class="card-title">
                                  <span class="svat-collapse-toggle">
                                    <i [ngClass]="builder.isCollapsed(subGroup.uid) ? 'fa-caret-right' : 'fa-caret-down'" class="fa"></i>
                                  </span>
                                  <span [translate]="subGroup.title"></span>
                                </h4>
                              </div>
                              <pl-animation-collapse [collapsed]="builder.isCollapsed(subGroup.uid)">
                                <div class="card-body">
                                  <pl-table
                                    class="svat-subGroup-table"
                                    [instanceId]="getTableInstanceId()"
                                    [attr.id]="subGroup.title || 'DemoDosResultados'"
                                    [definition]="subGroup.tableDef"
                                    [source]="subGroup.tableSource"
                                    [properties]="getTableCommomOptions(subGroup.tableSource)"
                                    (evtSelect)="onSelect($event)"
                                    theme="style2">
                                    <div *itemDetail="let subGroupItem; let subGroupIndex = index">
                                      <pl-tabs [theme]="'style3'">
                                        <pl-tab *ngFor="let tab of subGroupItem.subGroup.details[subGroupIndex]; trackBy: trackTabId">
                                          <div *plTabTitle><span [translate]="tab.tabTitle"></span></div>
                                          <div *plTabContent>
                                            <pl-table
                                              [instanceId]="getTableInstanceId()"
                                              [definition]="tab.tableDef"
                                              [source]="tab.tableSource"
                                              theme="style2"
                                              (evtSelect)="onSelect($event)"
                                              [properties]="getTableCommomOptions(tab.tableSource)"
                                              [attr.id]="tab.tabTitle">
                                              <div *itemDetail="let subItem; let subIndex = index">
                                                <ng-container *ngIf="tab.tableDetailsSources[subIndex].length > 0">
                                                  <pl-table
                                                    *ngIf="tab.tableDetailsSources[subIndex].length > 0"
                                                    [instanceId]="getTableInstanceId()"
                                                    [definition]="tab.tableDetailsDef"
                                                    [source]="tab.tableDetailsSources[subIndex]"
                                                    [properties]="getTableCommomOptions(tab.tableDetailsSources[subIndex])"
                                                    [theme]="'style2'"
                                                    [attr.id]="subIndex">
                                                  </pl-table>
                                                </ng-container>
                                              </div>
                                            </pl-table>
                                          </div>
                                        </pl-tab>
                                      </pl-tabs>
                                    </div>
                                    <div *itemActions="let subGroupItem; let subGroupIndex = index">
                                      <button type="button" title="Visualizar balancete" class="btn btn-xs btn-warning btn-viz-balancete" (click)="balanceteClick(subGroupItem, subGroupIndex)">
                                        <i class="fa fa-file-text-o"></i>
                                      </button>
                                    </div>
                                  </pl-table>
                                </div>
                              </pl-animation-collapse>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer svat-total-footer" *ngIf="medGroup.haveTotal">
                          <div class="row" style="margin-left: 0; margin-right: 0">
                            <div class="col-sm-8">
                              <div class="svat-group-total-description text-right">{{ medGroup.total.descricao }}</div>
                            </div>
                            <div class="col-sm-2 text-right">
                              <div class="svat-group-total-box">
                                <span class="svat-group-total-box-title">31/12/{{ anoEmCurso }} : </span><span class="svat-group-total-box-value">{{ medGroup.total.anoN | cgcCurrency }}</span>
                              </div>
                            </div>
                            <div class="col-sm-2 text-right">
                              <div class="svat-group-total-box">
                                <span class="svat-group-total-box-title">31/12/{{ anoEmCurso - 1 }} : </span><span class="svat-group-total-box-value">{{ medGroup.total.anoN1 | cgcCurrency }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </pl-animation-collapse>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-footer svat-total-footer" *ngIf="grandGroup.haveTotal">
                <div class="row" style="margin-left: 0; margin-right: 0">
                  <div class="col-sm-8">
                    <div class="svat-group-total-description text-right">{{ grandGroup.total.descricao }}</div>
                  </div>
                  <div class="col-sm-2 text-right">
                    <div class="svat-group-total-box">
                      <span class="svat-group-total-box-title">31/12/{{ anoEmCurso }} : </span><span class="svat-group-total-box-value">{{ grandGroup.total.anoN | cgcCurrency }}</span>
                    </div>
                  </div>
                  <div class="col-sm-2 text-right">
                    <div class="svat-group-total-box">
                      <span class="svat-group-total-box-title">31/12/{{ anoEmCurso - 1 }} : </span><span class="svat-group-total-box-value">{{ grandGroup.total.anoN1 | cgcCurrency }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </pl-animation-collapse>
          </div>
        </div>
      </div>
    </pl-tab>
  </pl-tabs>
</div>
