import {IDocContabilidadeCallback, TDocContabilidadeFocusField} from '../docscontabilidade/components/doccontabilidade/docContabilidade.interface';
import {IJsonDocContabilidade} from '../docscontabilidade/jsonDocContabilidade.interface';
import {IJsonFRVImportDoc} from './jsonATFaturasRecibosVerdes.interface';
import {IJsonPreDefinidoContab} from '../manutencao/predefinidos/jsonPreDefinidosContab.entity.interface';
import {TDate} from '../../../../common/dates';

export interface IATFaturasRecibosVerdesTipo {
  id: number;
  tipo: string;
}

export interface IATFaturasRecibosVerdesSituacao {
  id: number;
  situacao: string;
}

export interface IFRVImportDocApiList {
  list: Array<IFRVImportDoc>;
  listLancadosIgnorados: Array<IFRVImportDoc>;
}

export interface IFRVImportDoc extends IJsonFRVImportDoc {
  errorText?: string;
  docModel?: IJsonDocContabilidade;
  preDefinidoContab?: IJsonPreDefinidoContab;
  docLoadFocusField?: TDocContabilidadeFocusField;
  docCallback?: IDocContabilidadeCallback;
}

export interface IATFaturasRecibosVerdesFilter {
  fromDate: TDate;
  toDate: TDate;
  tipo?: IATFaturasRecibosVerdesTipo;
  situacao?: IATFaturasRecibosVerdesSituacao;
}

export interface IATFaturasRecibosVerdesDateFilters {
  dataDe: TDate;
  dataAte: TDate;
}

export interface IATFaturasRecibosVerdesResultFindInSource {
  index: number;
  item: IFRVImportDoc | null;
}

export const AT_FATURAS_RECIBOS_VERDES_TIPO_TODOS: IATFaturasRecibosVerdesTipo = Object.freeze({id: 0, tipo: 'TODOS'});

export const AT_FATURAS_RECIBOS_VERDES_SITUACAO_TODOS: IATFaturasRecibosVerdesSituacao = Object.freeze({id: 0, situacao: 'TODOS'});
