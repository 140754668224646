<div class="utl-viat-patronal">
  <cg-card-panel #cardPanel="cgCardPanel" [collapsed]="true">
    <div *cgCardPanelContent>
      <pl-form class="px-2 py-1">
        <pl-group>
          <label [translate]="'utlviatpatronal.tipoUtlViatPatronal.label'"></label>
          <edit>
            <pl-autocomplete attrName="estadoUtlViatura" [(model)]="estadoViatura" [source]="tipoEscolhaUtlViatura" [rowTemplate]="'label'" [output]="'label'"></pl-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <edit>
            <pl-button klass="btn-sm btn-primary" id="btnPesquisar" [onClick]="pesquisar">
              <i class="fa fa-search fa-fw"></i>&nbsp;
              <span [translate]="'global.btn.search'"></span>
            </pl-button>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </cg-card-panel>

  <dx-data-grid
    [cgDxDataGrid]="dataGridDefinition"
    cgDxDataGridInstanceName="utlViatPatronal"
    [dataSource]="dataGridDefinition.dataSource"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReady()">
    <div *dxTemplate="let item of 'cellTemplateBtnsUtlViatPatronalEdit'">
      <div class="btn-group">
        <pl-tooltip [config]="{text: 'global.btn.edit', tooltipClass: 'tooltip-warning', placement: 'left', container: 'body'}">
          <button type="button" *plTooltipContent class="btn btn-warning btn-xs" [click]="fnEditUtlViatPatronal(item.data)" plPromise>
            <i class="fa fa-fw fa-pencil"></i>
          </button>
        </pl-tooltip>
        <pl-tooltip [config]="{text: 'global.btn.delete', tooltipClass: 'tooltip-danger', placement: 'left', container: 'body'}">
          <button type="button" *plTooltipContent class="btn btn-danger btn-xs" [click]="fnDeleteUtlViatPatronal(item.data)" plPromise>
            <i class="fa fa-fw fa-trash"></i>
          </button>
        </pl-tooltip>
      </div>
    </div>
  </dx-data-grid>
</div>
