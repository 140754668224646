import {DashboardsERPModuleComponent} from './components/dashBoardsERP.module.component';
import {IModuleDefinition} from '../../components/module/module.definition.interface';
import {MODULE_NAME_DASHBOARDS_ERP} from './dashBoardsERP.module.interface';
import {ROLE} from '../../services/role.const';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {IJsonAnosFaturacao} from '../../entities/anosfaturacao/jsonAnosFaturacao.entity.interface';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {ENTITY_NAME_ANOS_FATURACAO} from '../../entities/anosfaturacao/anosFaturacao.entity.interface';
import {THttpQueryResponse} from '../../services/api/api.service.interface';

export const MODULE_DASHBOARDS_ERP: IModuleDefinition = {
  name: MODULE_NAME_DASHBOARDS_ERP,
  nameAlias: ['dashboards'],
  state: {
    url: '/dashboard',
    component: DashboardsERPModuleComponent,
    data: {
      roles: [ROLE.ERP],
      pageTitle: 'global.menu.dashboard',
      icon: 'fa-bar-chart'
    },
    resolve: [
      {
        provide: 'anosList',
        deps: [EntityServiceBuilder],
        useFactory: (entityServiceBuilder: EntityServiceBuilder): Promise<Array<IJsonAnosFaturacao>> => {
          const anosFaturacaoService: IEntityService<IJsonAnosFaturacao> = entityServiceBuilder.build<IJsonAnosFaturacao>(ENTITY_NAME_ANOS_FATURACAO);
          return anosFaturacaoService.query({pagina: 1, porpagina: 10, ordena: 'ano desc'}).then((response: THttpQueryResponse<IJsonAnosFaturacao>) => response.body.list);
        }
      }
    ]
  }
};
