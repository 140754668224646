<pl-form (evtSubmitted)="onFormSubmit()">
  <div class="modal-header">
    <h4 class="modal-title" [translate]="'importadorSaftDirect.strings.modalTitleEdit'"></h4>
  </div>

  <div class="modal-body">
    <pl-group>
      <pl-group>
        <label [translate]="'importadorSaftDirect.fields.nDiario'"></label>
        <edit>
          <entity-autocomplete entity="diarios" [(selectedKey)]="model.nDiario" output="key"></entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'importadorSaftDirect.fields.descritivo'"></label>
        <edit>
          <entity-autocomplete entity="descritivos" [(selectedKey)]="model.descritivo" output="key"></entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>
    <pl-group>
      <pl-group>
        <pl-group [properties]="{disabled: model.invoiceType === invoiceTypes.Recibo}">
          <edit>
            <pl-edit type="boolean" [(model)]="model.lancaContaCorrente" [properties]="{label: 'importadorSaftDirect.fields.lancaContaCorrente'}"></pl-edit>
          </edit>
        </pl-group>
        <pl-group [properties]="{disabled: model.invoiceType === invoiceTypes.Recibo}">
          <edit>
            <pl-edit type="boolean" [(model)]="model.lancaCaixa" [properties]="{label: 'importadorSaftDirect.fields.lancaCaixa'}"></pl-edit>
          </edit>
        </pl-group>
      </pl-group>
      <pl-group></pl-group>
    </pl-group>
    <pl-group>
      <pl-group [properties]="{disabled: !model.lancaCaixa, validators: {required: {value: true}}}">
        <label [translate]="'importadorSaftDirect.fields.contaCaixa'"></label>
        <edit>
          <entity-autocomplete entity="pocs" [(selectedKey)]="model.contaCaixa" output="key"></entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group [properties]="{disabled: !canChangeAgrupamentoDoc}">
        <label [translate]="'importadorSaftDirect.fields.agrupamentoDocumentos'"></label>
        <edit>
          <pl-edit-select
            [model]="agrupamentoDocsItem"
            [source]="agrupamentoDocsSource"
            (modelChange)="fnAgrupamentoDocsChanged($event)"
            [properties]="{disallowClear: true, disabled: !canChangeAgrupamentoDoc, select: {labelProp: 'agrupamentoDocumentosStr', valueProp: 'agrupamentoDocumentos'}}"></pl-edit-select>
        </edit>
      </pl-group>
    </pl-group>
    <pl-group>
      <pl-group [properties]="{disabled: model.invoiceType !== invoiceTypes.Recibo}">
        <label [translate]="'importadorSaftDirect.fields.contaDescRecibo'"></label>
        <edit>
          <entity-autocomplete entity="pocs" [(selectedKey)]="model.contaDescRec" output="key"></entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'importadorSaftDirect.fields.tipoRetencao'"></label>
        <edit>
          <pl-edit-select
            [model]="tipoRetencaoItem"
            [source]="tipoRetencaoSource"
            (modelChange)="changedTipoRetencao($event)"
            [properties]="{disallowClear: true, select: {labelProp: 'tipoRetencaoStr', valueProp: 'tipoRetencao'}}"></pl-edit-select>
        </edit>
      </pl-group>
    </pl-group>
    <pl-group>
      <pl-group *ngIf="showCCusto">
        <label [translate]="'importadorSaftDirect.fields.nCCusto'"></label>
        <edit>
          <entity-autocomplete entity="ccustos" [(selectedKey)]="model.nCCusto" output="key" [filter]="'encerrado=0'" [properties]="{allowInvalid: false}"></entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group *ngIf="showRepCC">
        <label [translate]="'importadorSaftDirect.fields.nCodRepCC'"></label>
        <edit>
          <entity-autocomplete entity="reparticoesccustosfaturacao" [(selectedKey)]="model.nCodRepCC" output="key" [properties]="{allowInvalid: false}"></entity-autocomplete>
        </edit>
      </pl-group>
    </pl-group>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button type="submit" klass="btn-primary btn-sm action-close" plAutoFocus><i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalSave'"></span></pl-button>
    <pl-button klass="btn-sm btn-light" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'importadorSaftDirect.strings.modalCancel'"></span></pl-button>
  </div>
</pl-form>
