import {Transition} from '@uirouter/core';
import {ContabilidadePredefinidosEntityDetailComponent} from './components/detail/contabilidade.predefinidos.entity.detail.component';
import {ContabilidadePredefinidosEntityListComponent} from './components/list/contabilidade.predefinidos.entity.list.component';
import {EContabilidadePredefinidosOperation, ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS, IPreDefinidoDetailStateParams} from './preDefinidosContab.entity.interface';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService} from '../../docscontabilidade/docsContabilidade.interface';
import {EntityServiceBuilder} from '../../../../services/entity/entity.service.builder';
import {IEntityDefinition} from '../../../../components/entity/entity.definition.interface';
import {IJsonDocContabilidade} from '../../docscontabilidade/jsonDocContabilidade.interface';
import {IJsonPreDefinidoContab} from './jsonPreDefinidosContab.entity.interface';
import {ROLE} from '../../../../services/role.const';
import {isNumber} from 'pl-comps-angular';
import {ContabilidadePredefinidosService} from './service/contabilidade.predefinidos.service';
import {HttpResponse} from '@angular/common/http';

export const ENTITY_CONTABILIDADE_PREDEFINIDOS: IEntityDefinition<IJsonPreDefinidoContab> = {
  name: ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS,
  url: 'predefinidoscontabilidade/predefinidos',
  roles: [ROLE.CONTABILIDADE, ROLE.ATIVOS],
  sidebarTitle: 'portals.sidebar.modules.predefinidoscontabilidade',
  metadata: {
    keyName: 'preDefinidosID',
    fields: [
      {name: 'preDefinidosID', type: 'number', align: 'left', width: 200, caption: 'predefinidoscontabilidade.fields.preDefinidosID'},
      {name: 'descricao', caption: 'predefinidoscontabilidade.fields.description'},
      {name: 'dados', caption: 'predefinidoscontabilidade.fields.data', visible: false, properties: {devExpress: {dataGrid: {showInColumnChooser: false}}}},
      {name: 'revision', type: 'cginteger', caption: 'predefinidoscontabilidade.fields.revision', visible: false, properties: {devExpress: {dataGrid: {showInColumnChooser: false}}}},
      {name: 'cgBanking', type: 'boolean', caption: 'predefinidoscontabilidade.fields.cgBanking', visible: false}
    ],
    order: 'preDefinidosID',
    searchFields: 'preDefinidosID,descricao,cgBanking'
  },
  autocomplete: {
    rowTemplate: '{{preDefinidosID}} - {{descricao}}',
    output: '{{preDefinidosID}} - {{descricao}}',
    outputDescription: 'predefinidosID, descricao',
    searchFields: 'preDefinidosID,descricao'
  },
  actions: {
    new: true,
    newMultiple: true,
    detail: false,
    edit: true,
    delete: true
  },
  list: {
    state: {
      component: ContabilidadePredefinidosEntityListComponent
    }
  },
  detail: {
    state: {
      component: ContabilidadePredefinidosEntityDetailComponent,
      params: {
        operation: {
          type: 'int',
          value: null
        },
        docContabilidade: {
          type: 'string',
          value: ''
        }
      },
      resolve: [
        {
          provide: 'suggestedCode',
          deps: ['model', Transition, ContabilidadePredefinidosService],
          useFactory: async (model: IJsonPreDefinidoContab, transition: Transition, contabilidadePredefinidosService: ContabilidadePredefinidosService): Promise<number> => {
            const {operation}: IPreDefinidoDetailStateParams = transition.params();
            if (operation === EContabilidadePredefinidosOperation.EDIT || operation === EContabilidadePredefinidosOperation.DELETE || isNumber(model?.preDefinidosID)) {
              return 0;
            }
            try {
              const response: HttpResponse<number> = await contabilidadePredefinidosService.sugerirCodigo();
              return Math.max(0, response.body);
            } catch (reason: unknown) {
              return 0;
            }
          }
        },
        {
          provide: 'docContabilidade',
          deps: [Transition, EntityServiceBuilder],
          useFactory: async (transition: Transition, entityServiceBuilder: EntityServiceBuilder): Promise<undefined | IJsonDocContabilidade> => {
            const {operation, docContabilidade}: IPreDefinidoDetailStateParams = transition.params();
            if (operation !== EContabilidadePredefinidosOperation.NEW_BASE_DOC || !docContabilidade) {
              return undefined;
            }
            const serviceDocsContabilidade = entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
            return (await serviceDocsContabilidade.get({id: docContabilidade})).body;
          }
        }
      ]
    }
  }
};
