import type {SubscriptionLike} from 'rxjs';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {IDevExpressDataGridColumnLookup} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDocContabilidadeCallback, TDocContabilidadeFocusField} from '../docscontabilidade/components/doccontabilidade/docContabilidade.interface';
import {IDocContabilidade} from '../docscontabilidade/docsContabilidade.interface';
import {IEFaturaDocumentoComLinhas, IEFaturaDocumentoLinha} from './service/importadorEfatura.service.interface';
import {IJsonDocContabilidade} from '../docscontabilidade/jsonDocContabilidade.interface';
import {IJsonPreDefinidoContab} from '../manutencao/predefinidos/jsonPreDefinidosContab.entity.interface';
import {TDate} from '../../../../common/dates';

export enum EAtTipo {
  Fatura,
  VendaDinheiroFaturaRecibo,
  NotaDebito,
  NotaCredito,
  ReciboVerdeEletronico,
  FaturaSimplificada,
  FaturaRecibo,
  DocConferencia,
  FaturaEmitidaEstrangeiro,
  ConsultasMesa,
  CreditoConsignacao,
  FaturaConsignacao,
  FolhaObra,
  NotaEncomenda,
  Outros,
  Orcamentos,
  Proforma,
  Desconhecido
}

export enum EImportadorEFaturaDocDigital {
  NaoTem,
  Tem,
  TemSemelhante
}

export interface IImportadorEfaturaErro {
  errorType: number;
  errorMessage: string;
}

export interface IImportadorEFaturaDocumento extends IEFaturaDocumentoComLinhas {
  contaCorrente: string;
  erro: string;
  extPocCabID: string;
  listaErros: Array<IImportadorEfaturaErro>;
  preDefinidoId: number;
  preDefinidoStr: string;
  registadoPorStr: string;
  docDigital: EImportadorEFaturaDocDigital;
  docDigitalDocID: string;
  docDigitalForderID: string;
}

export interface IImportadorEfaturaItem extends IImportadorEFaturaDocumento {
  showDetails: boolean;
  detalhesDataGridInstance: dxDataGrid;
  docCallback: IDocContabilidadeCallback;
  docModel: IJsonDocContabilidade;
  docLoadFocusField: TDocContabilidadeFocusField;
  preDefinidoContab: IJsonPreDefinidoContab;
  source: Array<IEFaturaDocumentoLinha>;
  preDefinido?: string;
  _toolbarInstanceId?: string;
  timeoutAddLine?: SubscriptionLike;
}

export interface IImportadorEfaturaFilterTipo {
  id: number;
  tipo: string;
}

export interface IImportadorEfaturaFilter {
  fromDate: TDate;
  toDate: TDate;
  tipo?: IImportadorEfaturaFilterTipo;
  nifFornecedor?: string;
}

export interface IImportadorEFaturaLastDocItem {
  extPocCabID: string;
  displayName: string;
  hint: string;
  index: number;
  docModel: IDocContabilidade;
  docCallback?: unknown;
  _toolbarInstanceId?: string;
}

export interface IImportadorEFaturaSimplifiedAtItem {
  dataEmissaoDocumento: TDate;
  nifEmitente: string;
  contaCorrente: string;
  valorTotal: number;
  preDefinidoId: number;
  idDocumento: string;
  numerodocumento: string;
  tipoDocumento: string;
  tipoDocumentoDesc: string;
  linhas: Array<IEFaturaDocumentoLinha>;
  nDiario: number;
  periodo: string;
  docDigitalDocID: string;
  docDigitalForderID: string;
  docDigital: number;
}

export interface IImportadorResultFindInSource {
  index: number;
  item: IImportadorEFaturaDocumento | null;
}

export interface IImportadorDateFilters {
  dataDe: TDate;
  dataAte: TDate;
}

export interface IImportadorDocContabilidade {
  doc: IJsonDocContabilidade;
  errors: Array<IImportadorEfaturaErro>;
}

export interface IImportadorEFaturaSource {
  list: Array<IImportadorEfaturaItem>;
  errors: Array<string>;
  warnings: Array<string>;
}

export const DEV_EXPRESS_DATA_GRID_LOOKUP_INPORTADOR_EFATURA_DOC_DIGITAL: IDevExpressDataGridColumnLookup = {
  dataSource: [
    {value: EImportadorEFaturaDocDigital.NaoTem, label: 'importadorEfatura.docDigital.naoTem'},
    {value: EImportadorEFaturaDocDigital.Tem, label: 'importadorEfatura.docDigital.tem'},
    {value: EImportadorEFaturaDocDigital.TemSemelhante, label: 'importadorEfatura.docDigital.temSemelhante'}
  ],
  valueExpr: 'value',
  displayExpr: 'label'
};
