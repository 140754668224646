<div class="ativos-nota-credito">
  <div class="entity-detail-form">
    <div class="ativos-nota-credito-header">
      <div class="row">
        <div class="col-md-8">
          <fieldset class="ativos-nota-credito-header-fieldset">
            <legend [translate]="'ativosnotacredito.header.documento'"></legend>
            <dx-data-grid [cgDxDataGrid]="dataGridDefinitionDoc" [dataSource]="ativosNotaCredito.notaCreditoContab"></dx-data-grid>
          </fieldset>
        </div>
        <div class="col-md-4">
          <fieldset class="ativos-nota-credito-header-fieldset">
            <legend [translate]="'ativosnotacredito.header.resumo'"></legend>

            <div class="row">
              <div class="col-sm-5 text-end fw-bolder">
                <span [translate]="'ativosnotacredito.header.fornecedor'"></span>
              </div>
              <div class="col-sm-7 text-default">
                {{ ativosNotaCredito.fornecedor }}
              </div>

              <div class="col-sm-5 text-end fw-bolder">
                <span [translate]="'ativosnotacredito.header.valornotacredito'"></span>
              </div>
              <div class="col-sm-7 text-default">
                {{ ativosNotaCredito.valorNotaCredito | cgcDecimal }}
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    </div>

    <pl-form>
      <pl-group>
        <label [translate]="'ativosnotacredito.header.distribuicaovalor'"></label>
        <edit>
          <pl-edit type="radiogroup" attrName="estado" [model]="distribuiValor" (modelChange)="distribuiValor = $event; executeCalc()" [properties]="radioGroupTemplateDistValor"> </pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>

  <hr />

  <legend [translate]="'ativosnotacredito.header.selecionaativos'"></legend>

  <div class="ativos-nota-credito-wrapper-dxgrid">
    <div class="ativos-nota-credito-table-dxgrid">
      <dx-data-grid
        [cgDxDataGrid]="dataGridDefinition"
        [cgDxDataGridColumns]="dataGridAtivosDisponiveisFields"
        [dataSource]="dataGridDefinition.dataSource"
        [filterValue]="['status', '=', 'disponivel']"
        (onInitialized)="onInitialized($event, true)"
        (onContentReady)="onContentReady()"
        (onCellClick)="onCellClick($event)"
        (onCellDblClick)="onCellDblClick($event)">
        <dxo-row-dragging group="statusGroup" data="disponivel" [onAdd]="fnOnAddADescontarTable"></dxo-row-dragging>

        <div *dxTemplate="let item of 'templateToolbar'">
          <span [translate]="'ativosnotacredito.table.ativosdisponiveis'"></span>
        </div>
      </dx-data-grid>
    </div>

    <div class="ativos-nota-credito-btn-actions-dxgrid">
      <button type="button" class="btn btn-primary btn-sm" [disabled]="(!ativosNotaCredito.fornecedor && !ativosNotaCredito.valorNotaCredito) || emptyAtivosDisponiveisTable" (click)="allRight()">
        <i class="fa fa-fw fa-angle-double-right"></i>
      </button>

      <button type="button" class="btn btn-primary btn-sm" [disabled]="(!ativosNotaCredito.fornecedor && !ativosNotaCredito.valorNotaCredito) || !selectedAtivoDisponivel" (click)="selectedRight()">
        <i class="fa fa-fw fa-angle-right"></i>
      </button>

      <button type="button" class="btn btn-primary btn-sm" [disabled]="(!ativosNotaCredito.fornecedor && !ativosNotaCredito.valorNotaCredito) || !selectedAtivoADescontar" (click)="selectedLeft()">
        <i class="fa fa-fw fa-angle-left"></i>
      </button>

      <button type="button" class="btn btn-primary btn-sm" [disabled]="(!ativosNotaCredito.fornecedor && !ativosNotaCredito.valorNotaCredito) || emptyAtivosADescontarTable" (click)="allLeft()">
        <i class="fa fa-fw fa-angle-double-left"></i>
      </button>
    </div>

    <div class="ativos-nota-credito-table-dxgrid">
      <dx-data-grid
        [cgDxDataGrid]="dataGridDefinition"
        [cgDxDataGridColumns]="dataGridADescontarFields"
        [dataSource]="dataGridDefinition.dataSource"
        [filterValue]="['status', '=', 'aDescontar']"
        (onInitialized)="onInitialized($event, false)"
        (onContentReady)="onContentReady()"
        (onCellClick)="onCellClick($event)"
        (onCellDblClick)="onCellDblClick($event)">
        <dxo-row-dragging group="statusGroup" data="aDescontar" [onAdd]="fnOnAddADescontarTable"></dxo-row-dragging>

        <dxo-editing mode="cell" startEditAction="click" [selectTextOnEditStart]="true" [allowUpdating]="true"></dxo-editing>

        <div *dxTemplate="let item of 'templateToolbar'">
          <span [translate]="'ativosnotacredito.table.ativosadescontar'"></span>
        </div>
      </dx-data-grid>
    </div>
  </div>
</div>
