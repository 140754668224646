<div class="card documento-faturacao">
  <div class="card-header" [class.doc-anulado]="pagamento.cab.anulado">
    <div class="card-title">
      <div *ngIf="pagamento.cab.anulado" class="badge bg-danger me-2">
        <strong translate="pagamentos.fields.anulado"></strong>
      </div>
      <span class="fatura-title"><strong>Nº: </strong>{{ pagamento.cab.numero }}</span>
      <button *ngIf="!pagamento.cab.anulado" type="button" class="btn btn-sm btn-primary action-pdf" [click]="fnPdf" plPromise>
        <i class="fa fa-fw fa-file-pdf-o"></i><span [translate]="'pagamentos.pagamento.pdf'"></span>
      </button>

      <div *ngIf="!pagamento.cab.anulado" class="d-inline-block ms-1">
        <pl-tooltip [config]="tooltip">
          <button type="button" *plTooltipContent class="btn btn-sm btn-danger action-anular" [click]="fnAnularPagamento" [disabled]="!tooltip.disabled" plPromise>
            <i class="fa fa-fw fa-scissors"></i><span [translate]="'pagamentos.pagamento.revoke'"></span>
          </button>
        </pl-tooltip>
      </div>

      <div class="fatura-data" [translate]="'docscomerciais.doc.cab.dataDoc'">
        :<span class="badge bg-secondary ms-2">{{ pagamento.cab.data | cgcDate }}</span>
      </div>
    </div>
  </div>

  <div class="card-body card-documento">
    <div class="resumo documento-faturacao-resumo-header">
      <dl class="page-documento-header">
        <dt><span [translate]="'pagamentos.pagamento.cab.nConta'"></span>:</dt>
        <dd>{{ pagamento.cab.nConta }}</dd>
        <dt><span [translate]="'pagamentos.pagamento.cab.nome'"></span>:</dt>
        <dd>{{ pagamento.cab.nome }}</dd>
        <ng-container *ngIf="pagamento.cab.nif">
          <dt><span [translate]="'pagamentos.pagamento.cab.nif'"></span>:</dt>
          <dd>{{ pagamento.cab.nif }}</dd>
        </ng-container>
        <ng-container *ngIf="pagamento.cab.descricao">
          <dt><span [translate]="'pagamentos.pagamento.cab.descricao'"></span>:</dt>
          <dd>{{ pagamento.cab.descricao }}</dd>
        </ng-container>
      </dl>
    </div>

    <div class="table-responsive">
      <table class="table table-striped pagamentos" name="tableDocsPagamentos">
        <thead>
          <tr>
            <td [translate]="'pagamentos.pagamento.linhas.tipoDocumento'"></td>
            <td [translate]="'pagamentos.pagamento.linhas.numeroDocumento'"></td>
            <td class="text-end" [translate]="'pagamentos.pagamento.linhas.dataVencimento'"></td>
            <td class="text-end" [translate]="'pagamentos.pagamento.linhas.valor'"></td>
            <td class="text-end" [translate]="'pagamentos.pagamento.linhas.valorDesconto'"></td>
            <td class="text-end" [translate]="'pagamentos.pagamento.linhas.valorAPagar'"></td>
            <td class="text-end" [translate]="'pagamentos.pagamento.linhas.cambio'"></td>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let item of pagamento.linhas">
            <td>{{ item.tipoDocumento }}</td>
            <td>{{ item.numeroDocumento }}</td>
            <td class="text-end">{{ item.dataVencimento | cgcDate }}</td>
            <td class="text-end">{{ item.valor | cgcDecimal: config.valor }}</td>
            <td class="text-end">{{ item.valorDesconto | cgcDecimal: config.desconto }}</td>
            <td class="text-end">{{ item.valorAPagar | cgcDecimal: config.valor }}</td>
            <td class="text-end">{{ item.cambioPagamento | cgcDecimal: nDecimaisCambioDefault }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <br />

    <div class="resumo-wrapper">
      <div class="resumo">
        <h4 class="page-header" [translate]="'pagamentos.pagamento.linhas.summary'"></h4>
        <dl class="page-invoice-amount">
          <dt><span [translate]="'pagamentos.pagamento.totalPago'"></span>:</dt>
          <dd>{{ pagamento.cab.totalPagar | cgcCurrency: config.valor : pagamento.cab.abreviaturaMoeda : 'code' }}</dd>
          <dt><span [translate]="'pagamentos.pagamento.cab.totalDescontos'"></span>:</dt>
          <dd>{{ pagamento.cab.totalDescontos | cgcCurrency: config.valor : pagamento.cab.abreviaturaMoeda : 'code' }}</dd>
          <dt><span [translate]="'global.text.total'"></span>:</dt>
          <dd>{{ pagamento.cab.total | cgcCurrency: config.valor : pagamento.cab.abreviaturaMoeda : 'code' }}</dd>
        </dl>
      </div>
    </div>
  </div>
</div>
