import {Directive, EventEmitter, Injector, Output} from '@angular/core';
import {isDefined} from 'pl-comps-angular';
import {DocContabilidadeField} from './docContabilidade.field';
import {ECampoCalculadoME} from '../../../jsonDocContabilidade.interface';
import {EDocContabilidadeField, IDocContabilidadeEventLock, IDocContabilidadeEventValue} from '../docContabilidade.interface';
import {IDocContabilidadeLinha} from '../../../docsContabilidade.interface';
import {TPredefinidoField} from '../../../../manutencao/predefinidos/jsonPreDefinidosContab.entity.interface';

@Directive()
export abstract class DocContabilidadeFieldInput<TValue, TEvent extends IDocContabilidadeEventValue<TValue> = IDocContabilidadeEventValue<TValue>> extends DocContabilidadeField<TValue, TEvent> {
  @Output() public readonly evtLockCampoME: EventEmitter<IDocContabilidadeEventLock>;

  public moedaPresent: boolean;
  public classLockButton: string;
  public classLockIcon: string;

  protected constructor(
    public readonly fieldName: EDocContabilidadeField,
    protected readonly _injector: Injector,
    protected readonly _predefinidoField?: TPredefinidoField,
    protected readonly _campoCalculadoME?: ECampoCalculadoME
  ) {
    super(fieldName, _injector, _predefinidoField);
    this.evtLockCampoME = new EventEmitter<IDocContabilidadeEventLock>();
    this.moedaPresent = false;
  }

  public doLock(): void {
    if (this.locked) {
      return;
    }
    this.evtLockCampoME.emit({
      campoME: this._campoCalculadoME,
      linha: this.linha
    });
  }

  protected _handleLinhaChanged(linha: IDocContabilidadeLinha): void {
    super._handleLinhaChanged(linha);
    this.moedaPresent = this._isMoedaPresent();
    this._setLockClasses();
  }

  protected _isMoedaPresent(): boolean {
    return this._docContabilidadeService.isMoedaPresent(this.docContabilidade);
  }

  protected _isLocked(type: ECampoCalculadoME): boolean {
    return this._docContabilidadeService.isLocked(this.linha, type);
  }

  protected _setLockClasses(): void {
    if (isDefined(this._campoCalculadoME) && this._isMoedaPresent()) {
      this.classLockButton = this._docContabilidadeService.getLockButtonClass(this.linha, this._campoCalculadoME);
      this.classLockIcon = this._docContabilidadeService.getLockClass(this.linha, this._campoCalculadoME);
    }
  }
}
