import {Component, Injector, OnInit} from '@angular/core';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IAbDesCrabd, IJsonABDES} from '../../jsonABDES.entity.interface';
import {IPlFilterPanelEvtFiltered, IPlToolbarItem} from 'pl-comps-angular';
import {IRHCopiaAbonosDescontosParams, MODULE_NAME_RH_COPIA_ABONOS_DESCONTOS} from '../../../../modules/portalrh/rhcopiaabonosdescontos/rhCopiaAbonosDescontos.module.interface';
import {IModuleMaintenanceInstance} from '../../../../components/entity/maintenance/module/module.maintenance.interface';
import {ModuleMaintenanceService} from '../../../../components/entity/maintenance/module/module.maintenance.service';
import {ENTITY_NAME_ABONOS, ENTITY_NAME_DESCONTOS, ETipoAbonoDesconto, IABDESStateParams} from '../../abdes.entity.interface';
import {IEntityMaintenanceListModalOptions} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {GenerateAbdesModalComponent} from '../../modals/generateabdes/generateAbdes.modal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';

const FILTER = 'abonoDescDesativado=false';

@Component({
  selector: 'abdes-list',
  templateUrl: './abdes.entity.list.component.html'
})
export class AbDesListComponent extends ModuloEntityListComponent<IJsonABDES> implements OnInit {
  public btnCopyAbdes: IPlToolbarItem;

  private readonly _maintenanceInstanceRHCopiaAbonosDescontos: IModuleMaintenanceInstance;

  constructor(
    protected readonly _injector: Injector,
    private readonly _moduleMaintenanceService: ModuleMaintenanceService,
    protected readonly _cgModalService: CGModalService
  ) {
    super(_injector);
    this._maintenanceInstanceRHCopiaAbonosDescontos = this._moduleMaintenanceService.build(MODULE_NAME_RH_COPIA_ABONOS_DESCONTOS);
    const params: Partial<IEntityMaintenanceListModalOptions> = <Partial<IEntityMaintenanceListModalOptions>>this._transition.params();
    this.filterValue = params.filter ? params.filter : FILTER;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.toolbar.addButton({
      id: 'btnCopyAbdes',
      order: 125,
      type: 'button',
      iconLeft: '<i class="fa fa-fw fa-files-o"></i>',
      class: 'btn-primary',
      caption: 'abdes.btn.copiar',
      click: () => this._copyAbdes()
    });

    this.btnNovo.menu = [
      {
        type: 'button',
        iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
        caption: 'abdes.btn.aPartirDeExistente',
        click: () => this.new('existente')
      },
      {
        type: 'button',
        iconLeft: '<i class="fa fa-fw fa-pencil-square-o"></i>',
        caption: 'abdes.btn.aPartirDeConfigSistema',
        click: () => this.new('configsistema')
      }
    ];
  }

  public async new(option: 'semconfig' | 'existente' | 'configsistema'): Promise<void> {
    switch (option) {
      case 'semconfig':
        return super.actionNew();
      case 'existente':
        return this._generateAbdes(false);
      case 'configsistema':
        return this._generateAbdes(true);
      default:
        return super.actionNew();
    }
  }

  public readonly fnOnFiltered: (event: IPlFilterPanelEvtFiltered<string>) => IPlFilterPanelEvtFiltered<string> = (event: IPlFilterPanelEvtFiltered<string>) => this._onFiltered(event);

  private _onFiltered(event: IPlFilterPanelEvtFiltered<string>): IPlFilterPanelEvtFiltered<string> {
    if (!event.serializedFilters.includes(FILTER)) {
      this.filterValue = '';
    }
    return {...event};
  }

  private _copyAbdes(): Promise<void> {
    const params: IRHCopiaAbonosDescontosParams = {
      abdes: this.entityName === ENTITY_NAME_DESCONTOS ? ETipoAbonoDesconto.Desconto : ETipoAbonoDesconto.Abono,
      codAbdes: '',
      nomeCodAbdes: ''
    };

    return this._maintenanceInstanceRHCopiaAbonosDescontos.maintenance({params: params, closeOnSaved: true});
  }

  private async _generateAbdes(tipoConfigAbDescPreConfigurado: boolean): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(GenerateAbdesModalComponent, {size: 'lg'});
    const componentInstance: GenerateAbdesModalComponent = modalInstance.componentInstance;
    componentInstance.abono = this.entityName === ENTITY_NAME_ABONOS;
    componentInstance.tipoConfigAbDescPreConfigurado = tipoConfigAbDescPreConfigurado;
    const result: IAbDesCrabd = await modalInstance.result;
    const params: IABDESStateParams = {defaultABDES: result.abdesc, defaultCRABD: result.cruzamentos, perfilSistemaAbdes: result.perfilSistemaAbdes};
    return this._cgStateService.redirectToState({
      stateOrName: this.entityName === ENTITY_NAME_ABONOS ? ENTITY_NAME_ABONOS : ENTITY_NAME_DESCONTOS,
      stateType: EEntityStateDetailType.NEW,
      params: params
    });
  }
}
