import {Component, Injector, Input, OnInit} from '@angular/core';
import {
  dataSourceRHDependentes,
  dataSourceRHLocalTributacaoRendimento,
  dataSourceRHNaturezaRendimento,
  dataSourceRHSituacaoCivilTitular,
  dataSourceRHTipoDeficiencia,
  ENTITY_NAME_DGEMPS_FULL,
  ERHDependentes,
  ERHLocalTributacaoRendimento,
  ERHNaturezaRendimento,
  ERHSituacaoCivilTitular,
  ERHTipoDeficiencia,
  IDGEMPSFullEntityService,
  IRHDataSourceItem
} from '../../dgempsFull.interface';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {IJsonDGEMPSelecaoTabelaIRS} from '../../jsonDGEMPFull.interface';
import moment from 'moment';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {TranslateService} from '@ngx-translate/core';
import {HttpResponse} from '@angular/common/http';
import {IJsonDate} from '../../../../../../common/interfaces/json';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDataEscalao, IDataIRS} from '../../../tabelasirs/tabelasIRS.module.interface';
import {TabelasIRSModuleService} from '../../../tabelasirs/tabelasIRS.module.service';
import {IJsonTIRSDT} from '../../../tabelasirs/jsonTabelasIRS.module.interface';
import {TDate} from '../../../../../../common/dates';

@Component({
  selector: 'selecao-tabelairs',
  templateUrl: './dgempsFull.selecaoTabelaIrs.modal.component.html'
})
export class SelecaoTabelaIRSModalComponent extends CGModalComponent<IJsonDGEMPSelecaoTabelaIRS> implements OnInit {
  @Input() public selecaoTabelaIRS: IJsonDGEMPSelecaoTabelaIRS;

  public promise: Promise<void>;
  public outputDesignacaoIRS: string;
  public outputParcelaAbaterDepende: string;
  public dataEmVigorDataSource: Array<IDataIRS>;
  public currentDataEmVigor: TDate;

  public readonly residenciaDataSource: Array<IRHDataSourceItem<ERHLocalTributacaoRendimento>>;
  public readonly situacaoDataSource: Array<IRHDataSourceItem<ERHSituacaoCivilTitular>>;
  public readonly tipoRendimentoDataSource: Array<IRHDataSourceItem<ERHNaturezaRendimento>>;
  public readonly declaranteDeficienteDataSource: Array<IRHDataSourceItem<ERHTipoDeficiencia>>;
  public readonly dependentesDataSource: Array<IRHDataSourceItem<ERHDependentes>>;
  public readonly dependentesDeficienteDataSource: Array<IRHDataSourceItem<ERHDependentes>>;
  public readonly dataGridDefinition: IDevExpressDataGrid;

  private readonly _serviceDGEMPSFull: IDGEMPSFullEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _serviceTabelasIRS: TabelasIRSModuleService,
    private readonly _translateService: TranslateService
  ) {
    super(_injector);

    this.dataEmVigorDataSource = [];

    this.residenciaDataSource = dataSourceRHLocalTributacaoRendimento(this._translateService).slice(1);
    this.situacaoDataSource = dataSourceRHSituacaoCivilTitular(this._translateService).slice(1);
    this.tipoRendimentoDataSource = dataSourceRHNaturezaRendimento(this._translateService).slice(1);
    this.declaranteDeficienteDataSource = dataSourceRHTipoDeficiencia(this._translateService);
    this.dependentesDataSource = dataSourceRHDependentes(this._translateService);
    this.dependentesDeficienteDataSource = dataSourceRHDependentes(this._translateService);

    this.selecaoTabelaIRS = {
      dataReferencia: moment(),
      residencia: ERHLocalTributacaoRendimento.RHLTRNone,
      situacao: ERHSituacaoCivilTitular.RHSCTNone,
      tipoRendimento: ERHNaturezaRendimento.RHNRNone,
      declaranteDeficiente: ERHTipoDeficiencia.RHTDNone,
      dependentes: ERHDependentes.RHDSemDependentes,
      dependentesDeficiente: ERHDependentes.RHDSemDependentes,
      nTabIRS: '',
      designacaoIRS: '',
      parcelaAbaterDepende: 0
    };

    this._serviceDGEMPSFull = this._entityServiceBuilder.build(ENTITY_NAME_DGEMPS_FULL);

    this.outputDesignacaoIRS = '';
    this.outputParcelaAbaterDepende = '';

    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'valorILIQ', dataType: 'double', caption: 'dgempsfull.gridIRSDTEmVigor.fields.valorILIQ'},
        {dataField: 'taxaMarginalMaxima', dataType: 'double', caption: 'dgempsfull.gridIRSDTEmVigor.fields.taxaMarginalMaxima'},
        {dataField: 'parcelaAbaterFormulaInfo', dataType: 'string', caption: 'dgempsfull.gridIRSDTEmVigor.fields.parcelaAbaterFormulaInfo'},
        {dataField: 'parcelaAbaterDepende', dataType: 'double', caption: 'dgempsfull.gridIRSDTEmVigor.fields.parcelaAbaterDepende'},
        {dataField: 'txEfetivaMensalEscal', dataType: 'string', caption: 'dgempsfull.gridIRSDTEmVigor.fields.txEfetivaMensalEscal'}
      ],
      dataSource: [],
      paging: {enabled: false},
      export: {enabled: false},
      remoteOperations: false,
      columnChooser: {enabled: false},
      allowColumnReordering: false,
      filterRow: {visible: false}
    };
  }

  public ngOnInit(): void {
    this._getCodigoTabelaIRS();
  }

  public close(): void {
    super.close(this.selecaoTabelaIRS);
  }

  public async residenciaChange(item: ERHLocalTributacaoRendimento): Promise<void> {
    this.selecaoTabelaIRS.residencia = item;
    await this._getCodigoTabelaIRS();
  }

  public async situacaoChange(item: ERHSituacaoCivilTitular): Promise<void> {
    this.selecaoTabelaIRS.situacao = item;
    await this._getCodigoTabelaIRS();
  }

  public async tipoRendimentoChange(item: ERHNaturezaRendimento): Promise<void> {
    this.selecaoTabelaIRS.tipoRendimento = item;
    await this._getCodigoTabelaIRS();
  }

  public async declaranteDeficienteChange(item: ERHTipoDeficiencia): Promise<void> {
    this.selecaoTabelaIRS.declaranteDeficiente = item;
    await this._getCodigoTabelaIRS();
  }

  public async dependentesChange(item: ERHDependentes): Promise<void> {
    this.selecaoTabelaIRS.dependentes = item;
    await this._getCodigoTabelaIRS();
  }

  public async dependentesDeficienteChange(item: ERHDependentes): Promise<void> {
    this.selecaoTabelaIRS.dependentesDeficiente = item;
    await this._getCodigoTabelaIRS();
  }

  public async dataEmVigorChanged(item: TDate): Promise<void> {
    this.currentDataEmVigor = item;
    this.dataGridDefinition.dataSource = this.currentDataEmVigor
      ? await this._serviceTabelasIRS.getTableIRSEscaloesByDate(this.selecaoTabelaIRS.nTabIRS, this.currentDataEmVigor).then((response: HttpResponse<Array<IJsonTIRSDT>>) => response.body)
      : [];
  }

  private _getCodigoTabelaIRS(): Promise<void> {
    if (!this.promise) {
      const params: IJsonDGEMPSelecaoTabelaIRS = {
        ...this.selecaoTabelaIRS,
        dataReferencia: moment()
      };

      this.disableClose();
      this.promise = this._serviceDGEMPSFull
        .getCodigoTabelaIRS(params)
        .then((response: HttpResponse<IJsonDGEMPSelecaoTabelaIRS>) => {
          if (response.body.nTabIRS !== '' && response.body.designacaoIRS !== '') {
            this.selecaoTabelaIRS = response.body;
            this.outputDesignacaoIRS = this._translateService.instant('dgempsfull.modalSelecaoTabelaIRS.tabelaDesignacaoIRS', {designacaoIRS: response.body.designacaoIRS});

            this.outputParcelaAbaterDepende =
              response.body.parcelaAbaterDepende > 0
                ? this._translateService.instant('dgempsfull.modalSelecaoTabelaIRS.parcelaAbaterDepende', {parcelaAbaterDepende: response.body.parcelaAbaterDepende})
                : '';
          } else {
            this.outputDesignacaoIRS = this._translateService.instant('dgempsfull.modalSelecaoTabelaIRS.tabelaNaoDeterminada');
            this.outputParcelaAbaterDepende = '';
          }

          this._serviceTabelasIRS.getDistinctDatasEscaloes(this.selecaoTabelaIRS.nTabIRS).then((response2: HttpResponse<Array<IJsonDate>>) => {
            if (response2.body) {
              this.dataEmVigorDataSource = response2.body.reverse().map((item: IDataEscalao) => {
                item.label = moment(item.value).format(this.format.momentDate);
                return item;
              });
              if (this.dataEmVigorDataSource.length && !this.currentDataEmVigor) {
                this.currentDataEmVigor = this.dataEmVigorDataSource[0].value;
              }
              this.dataEmVigorChanged(this.currentDataEmVigor);
            }
          });
        })
        .finally(() => {
          this.promise = undefined;
          this.enableClose();
        });
    }
    return this.promise;
  }
}
