import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {IDataSourceItem} from '../../../../../../components/datasource/datasources.interface';
import {ContabilidadeDeclaracaoIvaService} from '../../../../../../services/contabilidade.declaracaoiva.service';
import {ETipoAnexo} from '../../../../../../datasources/tiposanexo/tiposAnexo.datasource.interface';
import {ETypePeriodoIva} from '../../contabilidade.declaracaoIva.module.interface';

@Component({
  selector: 'modal-declaracaoivaperiodica-previsualizadeclaracao',
  templateUrl: './declaracaoIvaPeriodica.preVisualizaDeclaracao.modal.component.html'
})
export class DeclaracaoIvaPeriodicaPrevisualizaDeclaracaoModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public periodo: string;
  @Input() public tiposAnexo: Array<IDataSourceItem<ETipoAnexo>>;
  @Input() public periodoIva: ETypePeriodoIva;

  public tipoAnexo: IDataSourceItem<ETipoAnexo>;
  public pdfUrl: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _declaracaoIvaService: ContabilidadeDeclaracaoIvaService
  ) {
    super(_injector);
    this.pdfUrl = '';
  }

  public ngOnInit(): void {
    this.setTipoAnexo(0);
  }

  public setTipoAnexo(item: number): void {
    this.tipoAnexo = this.tiposAnexo[item];
    this._declaracaoIvaService.getDeclaracaoIVAPdfUrl(this.periodo, this.tipoAnexo.value, this.periodoIva).subscribe((url: string) => {
      this.pdfUrl = url;
    });
  }
}
