<div class="rh-dgemps-edit">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [formInstance]="formInstance"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div class="entity-detail-form" *entityDetailContent>
      <pl-alert type="primary" *ngIf="!hasDadosFicha" [seekAttention]="true" theme="light">
        <div class="rh-dgemps-edit-suggestion">
          <span [plTranslate]="'dgempsfull.text.configurarOutrosDados1'"> </span>&nbsp;<a
            class="rh-dgemps-edit-suggestion-apply"
            [plTranslate]="'dgempsfull.text.configurarOutrosDados2'"
            (click)="configureOutrosDados()"></a>
          <span>?</span>
        </div>
      </pl-alert>

      <pl-alert type="danger" theme="light" [closeable]="false" *ngIf="showOutdatedWarning">
        <div [innerHTML]="'dgempsfull.text.oldRecordWarning' | translate"></div>
      </pl-alert>

      <pl-form
        [properties]="{
          readonly: type === stateTypes.DETAIL,
          orientation: formOrientation,
          select: {labelProp: 'label', disallowClear: true, disabled: this.disabledTemDescIRSTxFixa || this.type === this.stateTypes.DETAIL}
        }"
        (formInstanceChange)="formInstance = $event">
        <div class="rh-dgemps-cabecalho d-flex align-items-center">
          <div class="rh-dgemps-cabecalho-fields">
            <pl-group>
              <pl-group>
                <label [translate]="'dgemps.fields.nome'"></label>
                <edit>
                  <pl-edit
                    type="text"
                    attrName="nome"
                    [(model)]="model.nome"
                    (modelChange)="changedNome($event)"
                    [properties]="{
                      placeholder: 'dgemps.fields.nomePlaceholder',
                      validators: {required: {value: true}}
                    }"
                    plAutoFocus>
                  </pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'dgempsfull.fields.codEmp'"></label>
                <edit>
                  <pl-edit type="integer" attrName="codEmp" [(model)]="model.codEmp" [properties]="{disabled: type !== stateTypes.NEW, validators: {required: {value: true}}}"> </pl-edit>
                </edit>
              </pl-group>
            </pl-group>

            <pl-group>
              <pl-group>
                <label [translate]="'dgempsfull.fields.dtMotMov'"></label>
                <edit>
                  <pl-edit-datepicker
                    attrName="dtMotMov"
                    [(model)]="model.dhemp.dtMotMov"
                    (modelChange)="dtMotMovChanged($event)"
                    [dateDisabled]="fnIsDateDisabled"
                    [dateMarked]="fnIsDateMarked"
                    [properties]="{
                      placeholder: 'dgempsfull.fields.dtMotMovPlaceholder',
                      validators: {required: {value: true}}
                    }">
                  </pl-edit-datepicker>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'dgempsfull.fields.motivoRegisto'"></label>
                <edit>
                  <entity-autocomplete
                    entity="tiposregistocadastro"
                    attrName="tiposregistocadastro"
                    [model]="model.dhemp.outrosDados"
                    (selectedKeyChange)="model.dhemp.outrosDados.motivoRegisto = $event"
                    (evtSelectedDescriptionChanged)="model.dhemp.outrosDados.designaBreveMotivoRegisto = $event"
                    [fieldsMap]="{nMotivoMov: 'motivoRegisto', designaBreve: 'designaBreveMotivoRegisto'}">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'dgempsfull.fields.situacaoEmprego'"></label>
                <edit>
                  <entity-autocomplete
                    entity="situacaoempregado"
                    attrName="situacaoempregado"
                    [model]="model.dhemp.outrosDados"
                    (selectedKeyChange)="model.dhemp.outrosDados.situacaoEmprego = $event"
                    (evtSelectedDescriptionChanged)="model.dhemp.outrosDados.designaBreveSituacaoEmprego = $event"
                    [fieldsMap]="{nSitEmpreg: 'situacaoEmprego', designaBreve: 'designaBreveSituacaoEmprego'}">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </pl-group>
          </div>

          <div class="rh-dgemps-cabecalho-capture-image d-flex flex-column" [plPromise]="promiseDHEMPPicture">
            <pl-media-devices-camera-capture-image [model]="dhempPicture" (modelChange)="changedPicture($event)" [disabled]="type === stateTypes.DETAIL" [properties]="propertiesCaptureImage">
            </pl-media-devices-camera-capture-image>

            <pl-button klass="btn btn-sm btn-danger mt-1" [onClick]="fnDeleteFoto" *ngIf="this.type === this.stateTypes.EDIT && this.dhempPicture !== undefined">
              <i class="fa fa-fw fa-trash-o"></i>&nbsp;<span [translate]="'impdadosemp.deleteFoto'"></span>
            </pl-button>
          </div>
        </div>

        <pl-navigator contentMaxHeight="65vh">
          <pl-navigator-item caption="dgempsfull.groups.estadoSS.title" [order]="-1">
            <div *plNavigatorItemContent>
              <div class="d-flex" style="gap: 20px">
                <div>
                  <div class="d-flex" [ngClass]="getEstadoSSCssClass()" style="align-items: center" [plPromise]="promiseEstadoSS">
                    <i class="fa fa-fw fa-2x" [ngClass]="getEstadoSSInfoIcon()"></i>
                    <span [innerHTML]="getEstadoSSInfoText()"></span>
                  </div>
                </div>
                <div>
                  <div class="d-flex" style="gap: 10px">
                    <button type="button" class="btn btn-sm btn-info" [disabled]="type === stateTypes.NEW" [click]="fnEstadoSSVerificar" plPromise>
                      <i class="fa fa-fw fa-flash"></i>&nbsp;<span [translate]="'dgempsfull.groups.estadoSS.verificar'"></span>
                    </button>
                    <button type="button" class="btn btn-sm btn-success" [disabled]="type === stateTypes.NEW" [click]="fnEstadoSSRegistar" plPromise>
                      <i class="fa fa-fw fa-check"></i>&nbsp;<span [translate]="'dgempsfull.groups.estadoSS.registar'"></span>
                    </button>
                    <button type="button" class="btn btn-sm btn-secondary" [disabled]="type === stateTypes.NEW" [click]="fnEstadoSSConsultar" plPromise>
                      <i class="fa fa-fw fa-address-card-o"></i>&nbsp;<span [translate]="'dgempsfull.groups.estadoSS.consultar'"></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </pl-navigator-item>

          <pl-navigator-item caption="dgempsfull.groups.addressContacts.title" [order]="0">
            <div *plNavigatorItemContent>
              <pl-group>
                <label [translate]="'dgempsfull.fields.morada'"></label>
                <edit>
                  <pl-edit type="text" attrName="morada" [(model)]="model.morada" [properties]="{placeholder: 'dgempsfull.fields.moradaPlaceholder'}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <pl-group subGroupClass="col-md-3 col-lg-2">
                  <label [translate]="'dgemps.fields.codPostal'"></label>
                  <edit>
                    <pl-edit
                      type="codpostais"
                      attrName="codPostal"
                      [model]="model.codPostal"
                      (modelChange)="changedCodigoPostal($event)"
                      [properties]="{
                        placeholder: 'dgemps.fields.codPostalPlaceholder',
                        inlineMode: true,
                        validators: {required: {value: true}}
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgemps.fields.nomeLocalida'"></label>
                  <edit>
                    <pl-edit
                      type="text"
                      attrName="nomeLocalida"
                      [(model)]="model.nomeLocalida"
                      [properties]="{
                        placeholder: 'dgemps.fields.nomelocalidaPlaceholder',
                        validators: {required: {value: true}}
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group subGroupClass="col-md-3 col-lg-2">
                  <label [translate]="'dgempsfull.fields.nTelefone'"></label>
                  <edit>
                    <pl-edit type="phone" attrName="nTelefone" [(model)]="model.nTelefone" [properties]="{placeholder: 'dgempsfull.fields.nTelefonePlaceholder'}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgemps.fields.email'"></label>
                  <edit>
                    <pl-edit type="email" attrName="email" [(model)]="model.email" [properties]="{placeholder: 'dgemps.fields.emailPlaceholder'}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>
            </div>
          </pl-navigator-item>

          <pl-navigator-item caption="dgempsfull.groups.identification.title" [order]="1">
            <div *plNavigatorItemContent>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.nbIdentidade'"></label>
                  <edit>
                    <pl-edit
                      type="string"
                      attrName="nbIdentidade"
                      [(model)]="model.nbIdentidade"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.nbIdentidadePlaceholder',
                        validators: {required: {value: true}}
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.numContrib'"></label>
                  <edit>
                    <pl-edit
                      type="nifs"
                      attrName="numContrib"
                      [(model)]="model.numContrib"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.numContribPlaceholder',
                        inlineMode: true,
                        validators: {required: {value: true}}
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.numSegSoc'"></label>
                  <edit>
                    <pl-edit
                      type="text"
                      attrName="numSegSoc"
                      [(model)]="model.numSegSoc"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.numSegSocPlaceholder',
                        modelOptions: {debounce: 500},
                        validators: {numSegSoc: validatorNumSegSoc}
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.dtBiValidade'"></label>
                  <edit>
                    <pl-edit
                      type="date"
                      attrName="dtBiValidade"
                      [(model)]="model.dtBiValidade"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.dtBiValidadePlaceholder',
                        validators: {required: {value: true}}
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.dtNascimento'"></label>
                  <edit>
                    <pl-edit
                      type="date"
                      attrName="dtNascimento"
                      [(model)]="model.dtNascimento"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.dtNascimentoPlaceholder',
                        validators: {required: {value: true}, dtNascimento: validatorDTNascimento}
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.sexo'"></label>
                  <edit>
                    <pl-edit
                      type="radiogroup"
                      attrName="sexo"
                      [(model)]="model.sexo"
                      [properties]="{
                        groupItems: groupSexos,
                        validators: {required: {value: true}}
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.nacionalidad'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="nacionalidades"
                      attrName="nomeLocalida"
                      [model]="model"
                      (selectedKeyChange)="model.nacionalidad = $event"
                      (evtSelectedDescriptionChanged)="model.designacaoBreveNacionalidad = $event"
                      [fieldsMap]="{nNacionalidade: 'nacionalidad', designacaoBreve: 'designacaoBreveNacionalidad'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.nacionalidadPlaceholder',
                        validators: {required: {value: true}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.concelho'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="concelhos"
                      attrName="concelho"
                      [model]="model"
                      (modelChange)="changedConcelho($event)"
                      (selectedKeyChange)="model.concelho = $event"
                      (evtSelectedDescriptionChanged)="model.nomeConcelho = $event"
                      [fieldsMap]="{codConcelho: 'concelho'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.concelhoPlaceholder',
                        validators: {required: {value: true}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.freguesia'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="freguesias"
                      attrName="freguesia"
                      [model]="model"
                      (selectedKeyChange)="model.freguesia = $event"
                      (evtSelectedDescriptionChanged)="model.nomeFreguesia = $event"
                      [fieldsMap]="{nFreguesia: 'freguesia', designacaoBreve: 'nomeFreguesia'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.freguesiaPlaceholder',
                        validators: {required: {value: true}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>
            </div>
          </pl-navigator-item>

          <pl-navigator-item caption="dgempsfull.groups.bankData.title" [order]="2">
            <div *plNavigatorItemContent>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.banco'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="bancos"
                      attrName="banco"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.banco = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.nomeBanco = $event"
                      [helperMode]="true"
                      [fieldsMap]="{codBanco: 'banco', nome: 'nomeBanco'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.iban'"></label>
                  <edit>
                    <pl-edit type="text" attrName="iban" [(model)]="model.dhemp.iban" (modelChange)="onIbanChanged($event)" [properties]="{modelOptions: {debounce: 500}}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.swift'"></label>
                  <edit>
                    <pl-edit type="text" attrName="swift" [(model)]="model.dhemp.swift"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.nCartaoTicketRef'"></label>
                  <edit>
                    <pl-edit type="text" attrName="nCartaoTicketRef" [(model)]="model.nCartaoTicketRef" [properties]="{placeholder: 'dgempsfull.fields.nCartaoTicketRefPlaceholder'}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgemps.fields.formRemun'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="fremun"
                      [model]="model.dhemp"
                      [(selectedKey)]="model.dhemp.nFormRemun"
                      (evtSelectedDescriptionChanged)="model.dhemp.descFormRemun = $event"
                      [fieldsMap]="{nFRemun: 'nFormRemun', designacaoBreve: 'descFormRemun'}"
                      [properties]="{
                        placeholder: 'dgemps.fields.formRemunPlaceholder',
                        validators: {required: {value: true}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group></pl-group>
              </pl-group>
            </div>
          </pl-navigator-item>

          <pl-navigator-item caption="dgempsfull.groups.taxData.title" [order]="3">
            <div *plNavigatorItemContent>
              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.taxData.sections.irs'"></h6>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.nTabIrs'"></label>
                  <edit>
                    <pl-edit type="text" attrName="nTabIrs" [model]="model.dhemp.designaComplNTabIrs" [properties]="{disabled: true}"></pl-edit>
                  </edit>
                  <actions>
                    <pl-button attrName="btnOpenSelecaoTabelaIRS" klass="btn-primary" (evtClicked)="openSelecaoTabelaIRS()" [disabled]="type === stateTypes.DETAIL">
                      <span [translate]="'dgempsfull.actions.selecaoTabelaIRS'"></span>
                    </pl-button>
                  </actions>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.localTributacaoRendi'"></label>
                  <edit>
                    <pl-edit-select
                      attrName="localTributacaoRendi"
                      [model]="model.dhemp.localTributacaoRendi"
                      [source]="localTributacaoRendiDataSource"
                      (modelChange)="localTributacaoRendiChange($event)">
                    </pl-edit-select>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.situacaoCivilTitular'"></label>
                  <edit>
                    <pl-edit-select
                      attrName="situacaoCivilTitular"
                      [model]="model.dhemp.situacaoCivilTitular"
                      [source]="situacaoCivilTitularDataSource"
                      (modelChange)="situacaoCivilTitularChange($event)">
                    </pl-edit-select>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.naturezaRendimento'"></label>
                  <edit>
                    <pl-edit-select attrName="naturezaRendimento" [model]="model.dhemp.naturezaRendimento" [source]="naturezaRendimentoDataSource" (modelChange)="naturezaRendimentoChange($event)">
                    </pl-edit-select>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.numDepend'"></label>
                  <edit>
                    <pl-edit
                      type="smallint"
                      attrName="numDepend"
                      [model]="model.dhemp.numDepend"
                      (modelChange)="numDependChanged($event)"
                      [properties]="{modelOptions: {updateOn: 'blur'}, placeholder: 'dgempsfull.fields.numDependPlaceholder', validators: {min: {value: 0}}}">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label>
                    <span [translate]="'dgempsfull.fields.numDependDef'"></span>
                    <pl-tooltip class="num-depend-def-label" [config]="tooltipNumDependDef">
                      <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>
                    </pl-tooltip>
                  </label>
                  <edit>
                    <pl-edit
                      type="smallint"
                      attrName="numDependDef"
                      [model]="model.dhemp.numDependDef"
                      (modelChange)="numDependDefChanged($event)"
                      [properties]="{modelOptions: {updateOn: 'blur'}, placeholder: 'dgempsfull.fields.numDependDefPlaceholder', validators: {min: {value: 0}}}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.percDefic'"></label>
                  <edit>
                    <pl-edit type="number" attrName="percDefic" [model]="model.dhemp.percDefic" (modelChange)="percDeficChanged($event)" [properties]="{modelOptions: {updateOn: 'blur'}}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label></label>
                  <edit>
                    <pl-edit
                      type="checkbox"
                      attrName="deficienteForcasArma"
                      [model]="model.dhemp.deficienteForcasArma"
                      (modelChange)="deficienteForcasArmaChanged($event)"
                      [properties]="{label: 'dgempsfull.fields.deficienteForcasArmadas'}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label></label>
                  <edit>
                    <pl-edit type="checkbox" attrName="conjugeIncapacidad60" [(model)]="model.dhemp.conjugeIncapacidad60" [properties]="{label: 'dgempsfull.fields.conjugeIncapacidad60'}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label></label>
                  <edit>
                    <pl-edit
                      type="checkbox"
                      attrName="temDescIRSTxFixa"
                      [model]="model.dhemp.temDescIRSTxFixa"
                      (modelChange)="temDescIRSTxFixaChanged($event)"
                      [properties]="{label: 'dgempsfull.fields.temDescIRSTxFixa', disabled: disabledTemDescIRSTxFixa}">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group [ngClass]="{'input-warning': type !== stateTypes.DETAIL && percDescTaxaFixaIRSShowWarning}">
                  <label [translate]="'dgempsfull.fields.percDescTaxaFixaIRS'"></label>
                  <edit>
                    <pl-edit
                      type="number"
                      attrName="percDescTaxaFixaIRS"
                      [model]="model.dhemp.percDescTaxaFixaIRS"
                      (modelChange)="percDescTaxaFixaIRSChanged($event)"
                      [properties]="{disabled: !model.dhemp.temDescIRSTxFixa, validators: {min: {value: minPercDescTaxaFixaIRS}}}">
                      <div *inputGroup="{klass: 'btn-primary'}">
                        <span (click)="openSimuladorTaxaFixaIRS()" data-capture-click>
                          <span class="d-none d-sm-inline" [translate]="'dgempsfull.actions.simula'"></span>
                        </span>
                      </div>
                    </pl-edit>
                    <div class="input-warning-message">
                      <i class="fa fa-fw fa-exclamation-triangle me-1" aria-hidden="true"></i>
                      <span
                        [translate]="'dgempsfull.text.percDescTaxaFixaIRSShowWarning'"
                        [translateParams]="{description: descriptionPercDescTaxaFixaIRS, value: minPercDescTaxaFixaIRS | cgcPercent}">
                      </span>
                    </div>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <label [translate]="'dgempsfull.fields.eExResidente'"></label>
                <edit>
                  <pl-edit
                    type="boolean"
                    attrName="eExResidente"
                    [(model)]="model.dhemp.eExResidente"
                    [properties]="{label: 'dgempsfull.fields.eExResidenteLabel', disabled: disabledEExResidente}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'dgempsfull.fields.naoEfetuaFCT'"></label>
                <edit>
                  <pl-edit type="boolean" attrName="naoEfetuaFCT" [(model)]="model.dhemp.naoEfetuaFCT" [properties]="{label: 'dgempsfull.fields.naoEfetuaFCTLabel'}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'dgempsfull.fields.residenciaFiscal'"></label>
                <edit>
                  <pl-edit
                    type="radiogroup"
                    class="residencia-fiscal"
                    attrName="residenciaFiscal"
                    [model]="model.dhemp.outrosDados.residenciaFiscal"
                    (modelChange)="residenciaFiscalChanged($event)"
                    [properties]="{groupItems: groupResidenciaFiscal}">
                  </pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.sindicato'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="sind"
                      attrName="sindicato"
                      [model]="model"
                      (selectedKeyChange)="model.sindicato = $event"
                      (evtSelectedDescriptionChanged)="model.nomeSindicato = $event"
                      [fieldsMap]="{nSindicato: 'sindicato', nome: 'nomeSindicato'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.ncartsindicato'"></label>
                  <edit>
                    <pl-edit type="text" attrName="numSindicato" [(model)]="model.numSindicato" [properties]="{placeholder: 'dgempsfull.fields.ncartsindicato'}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.taxData.sections.socialSecurity'"></h6>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.caixaPrev'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="crss"
                      attrName="caixaprev"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.caixaPrev = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.nomeCaixaPrev = $event"
                      [fieldsMap]="{ncrss: 'caixaPrev', nome: 'nomeCaixaPrev'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.caixaPrevPlaceholder',
                        validators: {required: {value: true}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.classeCaixa'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="classescaixa"
                      attrName="caixaprev"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.classeCaixa = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.designaBreveClasseCaixa = $event"
                      [fieldsMap]="{nclcx: 'classeCaixa', designacaoBreve: 'designaBreveClasseCaixa'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.classeCaixaPlaceholder',
                        validators: {required: {value: true}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group></pl-group>
              </pl-group>

              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.taxData.sections.financas'"></h6>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.codRepFinancas'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="repfinancas"
                      attrName="repfinancas"
                      [model]="model"
                      (selectedKeyChange)="model.codRepFinancas = $event"
                      (evtSelectedDescriptionChanged)="model.repFinancas = $event"
                      [fieldsMap]="{codFiscal: 'codRepFinancas', nome: 'repFinancas'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.codRepFinancasPlaceholder'
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group></pl-group>

                <pl-group></pl-group>
              </pl-group>

              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.taxData.sections.rendJovensTrab'"></h6>
              <pl-group>
                <pl-group>
                  <label></label>
                  <edit>
                    <pl-edit type="checkbox" attrName="temRendJovensTrab" [(model)]="model.dhemp.outrosDados.temRendJovensTrab" [properties]="{label: 'dgempsfull.fields.temRendJovensTrab'}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.dtInicio'"></label>
                  <edit>
                    <pl-edit
                      type="date"
                      attrName="dtIniRendJovensTrab"
                      [(model)]="model.dhemp.outrosDados.dtIniRendJovensTrab"
                      [properties]="{disabled: !model.dhemp.outrosDados.temRendJovensTrab}"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group></pl-group>
              </pl-group>

              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.taxData.sections.temRendResNaoHabit'"></h6>
              <pl-group>
                <pl-group>
                  <label></label>
                  <edit>
                    <pl-edit
                      type="checkbox"
                      attrName="temRendResNaoHabit"
                      [model]="model.dhemp.outrosDados.temRendResNaoHabit"
                      (modelChange)="temRendResNaoHabitChanged($event)"
                      [properties]="{label: 'dgempsfull.fields.temRendResNaoHabit'}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.dtInicio'"></label>
                  <edit>
                    <pl-edit
                      type="date"
                      attrName="dtIniRendResNaoHabit"
                      [(model)]="model.dhemp.outrosDados.dtIniRendResNaoHabit"
                      [properties]="{disabled: !model.dhemp.outrosDados.temRendResNaoHabit}"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group></pl-group>
              </pl-group>

              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.taxData.sections.titCredHab'"></h6>
              <pl-group>
                <edit>
                  <pl-edit type="checkbox" attrName="temRedIrsCredHabitac" [(model)]="model.dhemp.temRedIrsCredHabitac" [properties]="{label: 'dgempsfull.fields.temRedIrsCredHabitac'}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <edit>
                  <pl-edit type="checkbox" attrName="temRedCtrArrendameHP" [(model)]="model.dhemp.temRedCtrArrendameHP" [properties]="{label: 'dgempsfull.fields.temRedCtrArrendameHP'}"></pl-edit>
                </edit>
              </pl-group>
            </div>
          </pl-navigator-item>

          <pl-navigator-item caption="dgempsfull.groups.contractualData.title" [order]="4">
            <div *plNavigatorItemContent>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.tipoContrato'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="tiposcontrato"
                      attrName="tipocontrato"
                      [model]="model.dhemp"
                      (modelChange)="changedTipoContrato($event)"
                      [fieldsMap]="{nTipoContrato: 'tipocontrato', designacaoBreve: 'designaBreveTipoContrato'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.tipoContratoPlaceholder',
                        validators: {required: {value: true}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.divisaoTrab'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="divisoesTrabalho"
                      attrName="divisaotrab"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.divisaoTrab = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.designaBreveDivisaoTrab = $event"
                      [fieldsMap]="{nDivisao: 'divisaoTrab', designacaoBreve: 'designaBreveDivisaoTrab'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.divisaoTrabPlaceholder',
                        validators: {required: {value: true}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.dtAdmEmpresa'"></label>
                  <edit>
                    <pl-edit
                      type="date"
                      attrName="dtAdmEmpresa"
                      [(model)]="model.dhemp.dtAdmEmpresa"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.dtAdmEmpresaPlaceholder',
                        validators: {required: {value: true}}
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.dtIniContrat'"></label>
                  <edit>
                    <pl-edit
                      type="date"
                      attrName="dtIniContrat"
                      [model]="model.dhemp.dtIniContrat"
                      (modelChange)="changedDtIniContrat($event)"
                      [properties]="{placeholder: 'dgempsfull.fields.dtIniContratPlaceholder'}">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.dtFimContrat'"></label>
                  <edit>
                    <pl-edit
                      type="date"
                      attrName="dtFimContrat"
                      [(model)]="model.dhemp.dtFimContrat"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.dtFimContratPlaceholder',
                        dateDisabled: fnIsDtFimContratDateDisabled,
                        disabled: model.dhemp.tipocontratoCtQPessoal === tipoContratoQuadroPessoal.SemTermo,
                        validators: {
                          required: {
                            value: model.dhemp.tipocontratoCtQPessoal === tipoContratoQuadroPessoal.TermoCerto && !model.dhemp.dtFimContrat
                          },
                          dtFimContrat: validatorDtFimContrato
                        }
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.dataEfetividade'"></label>
                  <edit>
                    <pl-edit
                      type="date"
                      attrName="dataEfetividade"
                      [(model)]="model.dhemp.dataEfetividade"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.dataEfetividadePlaceholder',
                        disabled: model.dhemp.tipocontratoCtQPessoal && model.dhemp.tipocontratoCtQPessoal !== tipoContratoQuadroPessoal.TermoCerto
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.contractualData.sections.timeAndPlaceOfWork'"></h6>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.horasDia'"></label>
                  <edit>
                    <pl-edit
                      type="mask"
                      attrName="horasdia"
                      [model]="horasDia"
                      (modelChange)="changedHorasDia($event)"
                      [properties]="{
                        mask: maskHorasDia,
                        validators: {required: {value: true}}
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.horasSemana'"></label>
                  <edit>
                    <pl-edit
                      type="mask"
                      attrName="horassemana"
                      [model]="horasSemana"
                      (modelChange)="changedHorasSemana($event)"
                      [properties]="{
                        mask: maskHorasSemana,
                        validators: {required: {value: true}}
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.horasMes'"></label>
                  <edit>
                    <pl-edit
                      type="mask"
                      attrName="horasmes"
                      [model]="horasMes"
                      (modelChange)="changedHorasMes($event)"
                      [properties]="{
                        mask: maskHorasMes,
                        validators: {required: {value: true}}
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'rhdadosconfigficha.fields.tipoHorarioTrabalho'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="tipohorario"
                      attrName="tipohorariotrabalho"
                      [model]="model.dhemp.outrosDados"
                      (selectedKeyChange)="model.dhemp.outrosDados.tipoHorarioTrabalho = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.outrosDados.designaBreveTipoHorarioTrabalho = $event"
                      [fieldsMap]="{nTipoHorario: 'tipoHorarioTrabalho', designaBreve: 'designaBreveTipoHorarioTrabalho'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'rhdadosconfigficha.fields.localTrabalho'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="estabelecimento"
                      attrName="estabelecimento"
                      [model]="model.dhemp.outrosDados"
                      (selectedKeyChange)="model.dhemp.outrosDados.localTrabalho = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.outrosDados.nomeLocalTrabalho = $event"
                      [fieldsMap]="{nEstabElec: 'localTrabalho', nome: 'nomeLocalTrabalho'}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'rhdadosconfigficha.fields.localObtencaoRendimentos'"></label>
                  <edit>
                    <pl-edit [(model)]="model.dhemp.outrosDados.localObterRendimento" [type]="dataSourceNameContinentes"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.contractualData.sections.remuneration'"></h6>
              <pl-group>
                <pl-group [properties]="{subGroupClass: 'col-md'}">
                  <label [translate]="'dgempsfull.fields.vencimBase'"></label>
                  <edit>
                    <pl-edit
                      type="number"
                      attrName="vencimBase"
                      [model]="model.dhemp.vencimBase"
                      (modelChange)="changedVencimBase($event)"
                      [properties]="{placeholder: 'dgempsfull.fields.vencimBasePlaceholder', modelOptions: {updateOn: 'blur'}}">
                      <div *inputGroup="{klass: 'btn-primary'}">
                        <span (click)="simulaValorVencimento()" data-capture-click>
                          <span class="d-none d-sm-inline" [translate]="'dgempsfull.actions.simula'"></span>
                        </span>
                      </div>
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.metodPaga'"></label>
                  <edit>
                    <pl-edit
                      type="radiogroup"
                      attrName="metodPaga"
                      [(model)]="model.dhemp.metodPaga"
                      [properties]="{
                        groupItems: groupMetodosCalcSalario,
                        validators: {required: {value: true}}
                      }">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group></pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.remunHora'"></label>
                  <edit>
                    <pl-edit type="number" attrName="remunHora" [model]="model.dhemp.remunHora" (modelChange)="changedRemunHora($event)">
                      <div *inputGroup="{klass: 'hollow-form'}">
                        <pl-tooltip *ngIf="type !== stateTypes.DETAIL && appliedSuggestedRemunHora" [config]="tooltipFormulaCustoHoraEmpregado">
                          <i *plTooltipContent class="fa fa-info-circle text-info" aria-hidden="true"></i>

                          <div *plTooltipTemplate class="rh-dgemps-custo-hora-empregado">
                            <ng-container *ngFor="let abono of custoHoraEmpregado.abonos" [ngSwitch]="abono.hasOwnProperty('nDiuturnidades')">
                              <div *ngSwitchDefault class="rh-dgemps-custo-hora-empregado-abono">
                                <div class="rh-dgemps-custo-hora-empregado-content">
                                  <span [attr.title]="'dgempsfull.titles.designacao' | translate">{{ abono.designacao }}</span
                                  >:&nbsp; <span [attr.title]="'dgempsfull.titles.valorCalculado' | translate">{{ abono.valorCalculado | cgcNumber }}</span
                                  >&nbsp;=&nbsp; (<span [attr.title]="'dgempsfull.titles.valorDesignacao' | translate: {designacao: abono.designacao}">{{ abono.valor | cgcNumber }}</span
                                  >&nbsp;&times;&nbsp; <span [attr.title]="'dgempsfull.titles.meses' | translate">{{ custoHoraEmpregado.meses }}</span
                                  >)&nbsp;/&nbsp; (<span [attr.title]="'dgempsfull.titles.horasSemana' | translate">{{ custoHoraEmpregado.horasSemana }}</span
                                  >&nbsp;&times;&nbsp; <span [attr.title]="'dgempsfull.titles.semanas' | translate">{{ custoHoraEmpregado.semanas }}</span
                                  >)
                                </div>

                                <div class="rh-dgemps-custo-hora-empregado-cruzamentos">
                                  <div class="rh-dgemps-custo-hora-empregado-cruzamento" *ngFor="let cruzamento of abono.cruzamentos">
                                    <span [attr.title]="'dgempsfull.titles.designacao' | translate">{{ cruzamento.designacao }}</span
                                    >:&nbsp; <span [attr.title]="'dgempsfull.titles.valorCalculado' | translate">{{ cruzamento.valorCalculado | cgcNumber }}</span
                                    >&nbsp;=&nbsp; ((<span [attr.title]="'dgempsfull.titles.valorDesignacao' | translate: {designacao: cruzamento.designacao}">{{ cruzamento.valor | cgcNumber }}</span
                                    >&nbsp;&times;&nbsp; (<span [attr.title]="'dgempsfull.titles.percentagem' | translate">{{ cruzamento.percentagem }}</span
                                    >&nbsp;/&nbsp;100))&nbsp;&times;&nbsp; <span [attr.title]="'dgempsfull.titles.meses' | translate">{{ custoHoraEmpregado.meses }}</span
                                    >)&nbsp;/&nbsp; (<span [attr.title]="'dgempsfull.titles.horasSemana' | translate">{{ custoHoraEmpregado.horasSemana }}</span
                                    >&nbsp;&times;&nbsp; <span [attr.title]="'dgempsfull.titles.semanas' | translate">{{ custoHoraEmpregado.semanas }}</span
                                    >)
                                  </div>
                                </div>
                              </div>

                              <div *ngSwitchCase="true" class="rh-dgemps-custo-hora-empregado-abono-diuturnidade">
                                <div class="rh-dgemps-custo-hora-empregado-content">
                                  <span [attr.title]="'dgempsfull.titles.designacao' | translate">{{ abono.designacao }}</span
                                  >:&nbsp; <span [attr.title]="'dgempsfull.titles.valorCalculado' | translate">{{ abono.valorCalculado | cgcNumber }}</span
                                  >&nbsp;=&nbsp; (<span [attr.title]="'dgempsfull.titles.nDiuturnidades' | translate">{{ abono.nDiuturnidades }}</span
                                  >&nbsp;&times;&nbsp; <span [attr.title]="'dgempsfull.titles.valorDesignacao' | translate: {designacao: abono.designacao}">{{ abono.valor | cgcNumber }}</span
                                  >&nbsp;&times;&nbsp; <span [attr.title]="'dgempsfull.titles.meses' | translate">{{ custoHoraEmpregado.meses }}</span
                                  >)&nbsp;/&nbsp; (<span [attr.title]="'dgempsfull.titles.horasSemana' | translate">{{ custoHoraEmpregado.horasSemana }}</span
                                  >&nbsp;&times;&nbsp; <span [attr.title]="'dgempsfull.titles.semanas' | translate">{{ custoHoraEmpregado.semanas }}</span
                                  >)
                                </div>

                                <div class="rh-dgemps-custo-hora-empregado-cruzamentos">
                                  <div class="rh-dgemps-custo-hora-empregado-cruzamento" *ngFor="let cruzamento of abono.cruzamentos">
                                    <span [attr.title]="'dgempsfull.titles.designacao' | translate">{{ cruzamento.designacao }}</span
                                    >:&nbsp; <span [attr.title]="'dgempsfull.titles.valorCalculado' | translate">{{ cruzamento.valorCalculado | cgcNumber }}</span
                                    >&nbsp;=&nbsp; (<span [attr.title]="'dgempsfull.titles.nDiuturnidades' | translate">{{ cruzamento.nDiuturnidades }}</span
                                    >&nbsp;&times;&nbsp;
                                    <span [attr.title]="'dgempsfull.titles.valorDesignacao' | translate: {designacao: cruzamento.designacao}">{{ cruzamento.valor | cgcNumber }}</span
                                    >&nbsp;&times;&nbsp; (<span [attr.title]="'dgempsfull.titles.percentagem' | translate">{{ cruzamento.percentagem }}</span
                                    >&nbsp;/&nbsp;100)&nbsp;&times;&nbsp; <span [attr.title]="'dgempsfull.titles.meses' | translate">{{ custoHoraEmpregado.meses }}</span
                                    >)&nbsp;/&nbsp; (<span [attr.title]="'dgempsfull.titles.horasSemana' | translate">{{ custoHoraEmpregado.horasSemana }}</span
                                    >&nbsp;&times;&nbsp; <span [attr.title]="'dgempsfull.titles.semanas' | translate">{{ custoHoraEmpregado.semanas }}</span
                                    >)
                                  </div>
                                </div>
                              </div>
                            </ng-container>

                            <hr />

                            <div class="rh-dgemps-custo-hora-empregado-total" [translate]="'dgempsfull.text.total'" [translateParams]="{value: custoHoraEmpregado.total | cgcNumber}"></div>
                          </div>
                        </pl-tooltip>
                      </div>

                      <pl-tooltip [config]="tooltipCalcularCustoHoraEmpregado" *inputGroup="{klass: 'btn-primary', promise: promiseBtnCalc}">
                        <i class="fa fa-calculator" aria-hidden="true" *plTooltipContent plDelegateEventDownwardsClick (click)="calcularCustoHoraEmpregado()"></i>
                      </pl-tooltip>
                    </pl-edit>

                    <div class="hyperlink" *ngIf="type !== stateTypes.DETAIL && suggestRemunHora" [translate]="'dgempsfull.actions.applySuggestedRemunHora'" (click)="applySuggestedRemunHora()"></div>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.nDiuturnidad'"></label>
                  <edit>
                    <pl-edit
                      type="smallint"
                      attrName="nDiuturnidad"
                      [model]="model.dhemp.nDiuturnidad"
                      (modelChange)="model.dhemp.nDiuturnidad = $event; valuesForCalcCustoHoraEmpChanged()"
                      [properties]="{modelOptions: {updateOn: 'blur'}}">
                    </pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.valorUnDiutu'"></label>
                  <edit>
                    <pl-edit
                      type="number"
                      attrName="valorUnDiutu"
                      [model]="model.dhemp.valorUnDiutu"
                      (modelChange)="model.dhemp.valorUnDiutu = $event; valuesForCalcCustoHoraEmpChanged()"
                      [properties]="{modelOptions: {updateOn: 'blur'}}">
                    </pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.contractualData.sections.calcPorRendLiq'"></h6>
              <pl-group>
                <edit>
                  <pl-edit-checkbox
                    attrName="crlCalcLiquido"
                    [model]="model.dhemp.crlCalcLiquido"
                    (modelChange)="model.dhemp.crlCalcLiquido = $event"
                    [properties]="{label: 'dgempsfull.fields.crlCalcLiquido', modelOptions: {updateOn: 'blur'}}">
                  </pl-edit-checkbox>
                </edit>
              </pl-group>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.crlValorLiquido'"></label>
                  <edit>
                    <pl-edit type="number" attrName="crlValorLiquido" [(model)]="model.dhemp.crlValorLiquido" [properties]="{disabled: !model.dhemp.crlCalcLiquido}"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <label>&nbsp;</label>
                  <edit>
                    <pl-edit-checkbox
                      attrName="crlValorLiquidoEmMe"
                      [model]="model.dhemp.crlValorLiquidoEmMe"
                      (modelChange)="model.dhemp.crlValorLiquidoEmMe = $event"
                      [properties]="{label: 'dgempsfull.fields.crlValorLiquidoEmMe', disabled: !model.dhemp.crlCalcLiquido, modelOptions: {updateOn: 'blur'}}">
                    </pl-edit-checkbox>
                  </edit>
                </pl-group>
              </pl-group>
              <pl-group>
                <label [translate]="'dgempsfull.fields.crlAbonoLiquido'"></label>
                <edit>
                  <entity-autocomplete
                    entity="abdes"
                    attrName="desconto"
                    [model]="model.dhemp"
                    (selectedKeyChange)="model.dhemp.crlAbonoLiquido = $event"
                    (evtSelectedDescriptionChanged)="model.dhemp.crlAbonoLiquidoDesignBreve = $event"
                    [fieldsMap]="{nCodABDESC: 'crlAbonoLiquido', designacaoBreve: 'crlAbonoLiquidoDesignBreve'}"
                    [outputKey]="'nCodABDESC'"
                    [rowTemplate]="outputAbdes"
                    [output]="outputAbdes"
                    [filter]="filterCRL"
                    [properties]="{disabled: !model.dhemp.crlCalcLiquido}"
                    plAutoFocus>
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </div>
          </pl-navigator-item>

          <pl-navigator-item caption="dgempsfull.groups.professionalData.title" [order]="5">
            <div *plNavigatorItemContent>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.profissao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="profissao"
                      attrName="profissao"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.profissao = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.designaBreveProfissao = $event"
                      [fieldsMap]="{nProfissao: 'profissao', designaBreve: 'designaBreveProfissao'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.profissaoPlaceholder',
                        validators: {required: {value: requiredProfissao}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.sitProfissao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="situacaoprofissional"
                      attrName="sitprofissao"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.sitProfissao = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.designaBreveSitProfissao = $event"
                      [fieldsMap]="{nSitProfiss: 'sitProfissao', designaBreve: 'designaBreveSitProfissao'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.sitProfissaoPlaceholder',
                        validators: {required: {value: requiredSituacaoProfissao}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.codcct'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="cct"
                      attrName="codcct"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.codcct = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.descricaoCodcct = $event"
                      [fieldsMap]="{ncct: 'codcct', designaBreve: 'descricaoCodcct'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.codcctPlaceholder',
                        validators: {required: {value: requiredCCT}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.nivQualific'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="nivql"
                      attrName="nivqualific"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.nivQualific = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.designaBreveNivQualific = $event"
                      [fieldsMap]="{nNivQualific: 'nivQualific', designaBreve: 'designaBreveNivQualific'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.nivQualificPlaceholder',
                        validators: {required: {value: requiredNivelQualif}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <label [translate]="'dgempsfull.fields.codNHABQPessoal'"></label>
                <edit>
                  <entity-autocomplete
                    entity="qphab"
                    attrName="habqpessoal"
                    [model]="model.dhemp"
                    (selectedKeyChange)="model.dhemp.codNHABQPessoal = $event"
                    (evtSelectedDescriptionChanged)="model.dhemp.nomeNHABQPessoal = $event"
                    [fieldsMap]="{codHabilitacao: 'codNHABQPessoal', nome: 'nomeNHABQPessoal'}"
                    [properties]="{
                      placeholder: 'dgempsfull.fields.codNHABQPessoalPlaceholder',
                      validators: {required: {value: true}}
                    }">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </div>
          </pl-navigator-item>

          <pl-navigator-item caption="dgempsfull.groups.subsidies.title" [order]="6">
            <div *plNavigatorItemContent>
              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.subsidies.sections.food'"></h6>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.valorSubAlim'"></label>
                  <edit>
                    <pl-edit type="number" attrName="valorSubAlim" [(model)]="model.dhemp.valorSubAlim" [properties]="{disabled: !model.dhemp.temSubAlim}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.temSubAlim'"></label>
                  <edit>
                    <pl-edit type="boolean" attrName="temSubAlim" [(model)]="model.dhemp.temSubAlim"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.temPreProcessamentoSubAlimen'"></label>
                  <edit>
                    <pl-edit type="boolean" attrName="temPreProcessamentoSubAlimen" [(model)]="model.dhemp.temPreProcessamentoSubAlimen" [properties]="{disabled: !model.dhemp.temSubAlim}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.subsidies.sections.vacations'"></h6>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.valorSFerias'"></label>
                  <edit>
                    <pl-edit type="number" attrName="valorSFerias" [(model)]="model.dhemp.valorSFerias" [properties]="{placeholder: 'dgempsfull.fields.valorSFeriasPlaceholder'}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'rhdadosconfigficha.fields.nMaxDiasUteisFerias'"></label>
                  <edit>
                    <pl-edit type="integer" attrName="nMaxDiasUteisFerias" [(model)]="model.dhemp.outrosDados.nMaxDiasUteisFerias" [properties]="{allowNegative: false}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'rhdadosconfigficha.fields.nDiasTemDireitoFerias'"></label>
                  <edit>
                    <pl-edit type="integer" attrName="nDiasTemDireitoFerias" [(model)]="model.dhemp.outrosDados.nDiasTemDireitoFerias" [properties]="{allowNegative: false}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.nDiasFeriasAnoAnteri'"></label>
                  <edit>
                    <pl-edit type="number" attrName="nDiasFeriasAnoAnteri" [(model)]="model.dhemp.nDiasFeriasAnoAnteri" [properties]="{allowNegative: false}"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group></pl-group>
                <pl-group></pl-group>
              </pl-group>

              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.subsidies.sections.christmas'"></h6>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.valorSNatal'"></label>
                  <edit>
                    <pl-edit type="number" attrName="valorSNatal" [(model)]="model.dhemp.valorSNatal" [properties]="{placeholder: 'dgempsfull.fields.valorSNatalPlaceholder'}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'rhdadosconfigficha.fields.nMaxDiasUteisNatal'"></label>
                  <edit>
                    <pl-edit type="integer" attrName="nMaxDiasUteisNatal" [(model)]="model.dhemp.outrosDados.nMaxDiasUteisNatal" [properties]="{allowNegative: false}"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'rhdadosconfigficha.fields.nDiasTemDireitoNatal'"></label>
                  <edit>
                    <pl-edit type="integer" attrName="nDiasTemDireitoNatal" [(model)]="model.dhemp.outrosDados.nDiasTemDireitoNatal" [properties]="{allowNegative: false}"></pl-edit>
                  </edit>
                </pl-group>
              </pl-group>

              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.subsidies.sections.workAccidentInsurance'"></h6>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.temSegurAcTr'"></label>
                  <edit>
                    <pl-edit type="boolean" attrName="temSegurAcTr" [(model)]="model.dhemp.temSegurAcTr"></pl-edit>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.nSeguradAcTr'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="segur"
                      attrName="seguradactr"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.nSeguradAcTr = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.nomeNSeguradAcTr = $event"
                      [fieldsMap]="{nsegur: 'nSeguradAcTr', designaBreve: 'nomeNSeguradAcTr'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.nSeguradAcTrPlaceholder',
                        validators: {required: {value: true}},
                        disabled: !model.dhemp.temSegurAcTr
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group></pl-group>
              </pl-group>
            </div>
          </pl-navigator-item>

          <pl-navigator-item caption="dgempsfull.groups.twelfths.title" [order]="7">
            <div *plNavigatorItemContent>
              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.twelfths.sections.rulesProcessingTwelfths'"></h6>
              <pl-group>
                <label [translate]="'dgempsfull.fields.regimeTrabalho'"></label>
                <edit>
                  <pl-edit type="radiogroup" attrName="regimeTrabalho" [(model)]="model.dhemp.regimeTrabalho" [properties]="{groupItems: groupRegimeTrabalho}"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'dgempsfull.fields.tipoCalcDuodecimos'"></label>
                <edit>
                  <pl-edit type="radiogroup" attrName="tipoCalcDuodecimos" [(model)]="model.dhemp.tipoCalcDuodecimos" [properties]="{groupItems: groupTipoCalcDuodecimos}"></pl-edit>
                </edit>
              </pl-group>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.calcDuodecVariavel'"></label>
                  <edit>
                    <pl-edit type="boolean" attrName="calcDuodecVariavel" [(model)]="model.dhemp.calcDuodecVariavel"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.duodecimosConfigEmpresa'"></label>
                  <edit>
                    <pl-edit
                      type="boolean"
                      attrName="usaDuodecimosPorEmp"
                      [model]="!model.dhemp.usaDuodecimosPorEmp"
                      (modelChange)="changeUsaDuodecimosPorEmp($event)"
                      [properties]="{readonly: true}"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <pl-group>
                    <label [translate]="'dgempsfull.fields.duodecimosSubNatal'"></label>
                    <edit>
                      <pl-edit type="integer" attrName="duodecimosSubNatalEmpresa" [(model)]="empresaDuodecimosSubNatal" [properties]="{readonly: true}"></pl-edit>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'dgempsfull.fields.duodecimosSubFerias'"></label>
                    <edit>
                      <pl-edit type="integer" attrName="duodecimosSubFeriasEmpresa" [(model)]="empresaDuodecimosSubNatal" [properties]="{readonly: true}"></pl-edit>
                    </edit>
                  </pl-group>
                </pl-group>
              </pl-group>
              <pl-group>
                <pl-group></pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.usaDuodecimosPorEmp'"></label>
                  <edit>
                    <pl-edit type="boolean" attrName="usaDuodecimosPorEmp" [model]="model.dhemp.usaDuodecimosPorEmp" (modelChange)="changeUsaDuodecimosPorEmp($event)"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <pl-group>
                    <label [translate]="'dgempsfull.fields.duodecimosSubNatal'"></label>
                    <edit>
                      <select id="duodecimosSubNatal" class="form-select" [(ngModel)]="model.dhemp.duodecimosSubNatal" [disabled]="disabledValoresDuodecimos || type === stateTypes.DETAIL">
                        <option [ngValue]="value" *ngFor="let value of valoresDuoDecimos">{{ value }}</option>
                      </select>
                    </edit>
                  </pl-group>
                  <pl-group>
                    <label [translate]="'dgempsfull.fields.duodecimosSubFerias'"></label>
                    <edit>
                      <select id="duodecimosSubFerias" class="form-select" [(ngModel)]="model.dhemp.duodecimosSubFerias" [disabled]="disabledValoresDuodecimos || type === stateTypes.DETAIL">
                        <option [ngValue]="value" *ngFor="let value of valoresDuoDecimos">{{ value }}</option>
                      </select>
                    </edit>
                  </pl-group>
                </pl-group>
              </pl-group>
            </div>
          </pl-navigator-item>

          <pl-navigator-item caption="dgempsfull.groups.integration.title" [order]="8">
            <div *plNavigatorItemContent>
              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.integration.sections.integrationGeneralAccounting'"></h6>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.nPocRemuLiq'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="pocs"
                      attrName="npocremuliq"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.nPocRemuLiq = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.nomeNPocRemuLiq = $event"
                      [fieldsMap]="{nConta: 'nPocRemuLiq', nome: 'nomeNPocRemuLiq'}"
                      [filter]="filterPocs"
                      [properties]="{validators: {required: {value: requiredPocRemuLiq}}}">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.categoria'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="categorias"
                      attrName="categoria"
                      [model]="model.dhemp"
                      (modelChange)="changedCategoria($event)"
                      [fieldsMap]="{nCateg: 'categoria', designaBreve: 'designaBreveCategoria'}"
                      output="description"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.categoriaPlaceholder',
                        validators: {required: {value: requiredCategoria}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group></pl-group>
              </pl-group>

              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.integration.sections.integrationAnalyticalAccounting'"></h6>
              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.ccusto'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="ccustos"
                      attrName="ccusto"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.ccusto = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.nomeCCusto = $event"
                      [fieldsMap]="{nCCusto: 'ccusto', nome: 'nomeCCusto'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.ccustoPlaceholder',
                        validators: {required: {value: requiredCentroCusto}}
                      }"
                      [filter]="'encerrado=0'">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.departamento'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="depto"
                      attrName="depto"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.departamento = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.nomeDepartamento = $event"
                      [fieldsMap]="{nome: 'nomeDepartamento'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.departamentoPlaceholder',
                        validators: {required: {value: requiredDepartamento}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.nSubDe'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="subde"
                      attrName="subde"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.nSubDe = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.nomeSubDe = $event"
                      [fieldsMap]="{nome: 'nomeSubDe'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.nSubDePlaceholder',
                        validators: {required: {value: requiredSubDepartamento}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.nZona'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="zonas"
                      attrName="zona"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.nZona = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.nomeZona = $event"
                      [fieldsMap]="{nome: 'nomeZona'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.nZonaPlaceholder',
                        validators: {required: {value: requiredZona}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.seccao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="seccoes"
                      attrName="seccao"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.seccao = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.designaBreveSeccao = $event"
                      [fieldsMap]="{nSeccao: 'seccao', designacaoBreve: 'designaBreveSeccao'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.seccaoPlaceholder',
                        validators: {required: {value: requiredOperacaoSeccao}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.funcao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="funcoes"
                      attrName="funcao"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.funcao = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.designaBreveFuncao = $event"
                      [fieldsMap]="{nFuncao: 'funcao', designaBreve: 'designaBreveFuncao'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.funcaoPlaceholder',
                        validators: {required: {value: requiredFuncao}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.classeTrab'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="cltrb"
                      attrName="classetrab"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.classeTrab = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.designaBreveClasseTrab = $event"
                      [fieldsMap]="{nclasTrab: 'classeTrab', designaBreve: 'designaBreveClasseTrab'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.classeTrabPlaceholder',
                        validators: {required: {value: requiredClasseTrab}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.nCodRepCC'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="reparticoesccustossalarios"
                      attrName="repcc"
                      [model]="model.dhemp"
                      (selectedKeyChange)="model.dhemp.nCodRepCC = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.descricaoCodRepCC = $event"
                      [fieldsMap]="{descricao: 'descricaoCodRepCC'}"
                      [properties]="{
                        placeholder: 'dgempsfull.fields.nCodRepCCPlaceholder',
                        validators: {required: {value: requiredReparticaoCentroCusto}}
                      }">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>
            </div>
          </pl-navigator-item>

          <pl-navigator-item caption="dgempsfull.groups.otherData.title" [order]="9">
            <div *plNavigatorItemContent>
              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.otherData.sections.allowanceCodesEmployeeWageProcessing'"></h6>
              <pl-group>
                <pl-group>
                  <label [translate]="'rhdadosconfigficha.fields.nCodABVencimento'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="abonos"
                      attrName="vencimento"
                      [model]="model.dhemp.outrosDados"
                      (selectedKeyChange)="model.dhemp.outrosDados.nCodABVencimento = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.outrosDados.designaBreveABVencimento = $event"
                      [fieldsMap]="{nCodABDESC: 'nCodABVencimento', designacaoBreve: 'designaBreveABVencimento'}"
                      [rowTemplate]="outputAbdes"
                      [output]="outputAbdes"
                      [filter]="filterVencimento"
                      outputKey="nCodABDESC">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.nCodABSubAlimentacao'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="abonos"
                      attrName="subalimentacao"
                      [model]="model.dhemp.outrosDados"
                      (selectedKeyChange)="model.dhemp.outrosDados.nCodABSubAlimentacao = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.outrosDados.designaBreveABSubAlimentacao = $event"
                      [fieldsMap]="{nCodABDESC: 'nCodABSubAlimentacao', designacaoBreve: 'designaBreveABSubAlimentacao'}"
                      [rowTemplate]="outputAbdes"
                      [output]="outputAbdes"
                      [filter]="filterSubsAlimentacao"
                      outputKey="nCodABDESC">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.nCodABDiuturnidades'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="abonos"
                      attrName="diuturnidades"
                      [model]="model.dhemp.outrosDados"
                      (selectedKeyChange)="model.dhemp.outrosDados.nCodABDiuturnidades = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.outrosDados.designaBreveABDiuturnidades = $event"
                      [fieldsMap]="{nCodABDESC: 'nCodABDiuturnidades', designacaoBreve: 'designaBreveABDiuturnidades'}"
                      [rowTemplate]="outputAbdes"
                      [output]="outputAbdes"
                      [filter]="filterDiuturnidades"
                      outputKey="nCodABDESC">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <pl-group>
                <pl-group>
                  <label [translate]="'dgempsfull.fields.nCodABSubFerias'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="abonos"
                      attrName="subferias"
                      [model]="model.dhemp.outrosDados"
                      (selectedKeyChange)="model.dhemp.outrosDados.nCodABSubFerias = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.outrosDados.designaBreveABSubFerias = $event"
                      [fieldsMap]="{nCodABDESC: 'nCodABSubFerias', designacaoBreve: 'designaBreveABSubFerias'}"
                      [rowTemplate]="outputAbdes"
                      [output]="outputAbdes"
                      [filter]="filterSubsFerias"
                      outputKey="nCodABDESC">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.nCodABSubNatal'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="abonos"
                      attrName="subnatal"
                      [model]="model.dhemp.outrosDados"
                      (selectedKeyChange)="model.dhemp.outrosDados.nCodABSubNatal = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.outrosDados.designaBreveABSubNatal = $event"
                      [fieldsMap]="{nCodABDESC: 'nCodABSubNatal', designacaoBreve: 'designaBreveABSubNatal'}"
                      [rowTemplate]="outputAbdes"
                      [output]="outputAbdes"
                      [filter]="filterSubsNatal"
                      outputKey="nCodABDESC">
                    </entity-autocomplete>
                  </edit>
                </pl-group>

                <pl-group>
                  <label [translate]="'dgempsfull.fields.nCodABFerias'"></label>
                  <edit>
                    <entity-autocomplete
                      entity="abonos"
                      attrName="ferias"
                      [model]="model.dhemp.outrosDados"
                      (selectedKeyChange)="model.dhemp.outrosDados.nCodABFerias = $event"
                      (evtSelectedDescriptionChanged)="model.dhemp.outrosDados.designaBreveABFerias = $event"
                      [fieldsMap]="{nCodABDESC: 'nCodABFerias', designacaoBreve: 'designaBreveABFerias'}"
                      [rowTemplate]="outputAbdes"
                      [output]="outputAbdes"
                      [filter]="filterFerias"
                      outputKey="nCodABDESC">
                    </entity-autocomplete>
                  </edit>
                </pl-group>
              </pl-group>

              <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.otherData.sections.discountCodeEmployeeWage'"></h6>
              <pl-group>
                <label [translate]="'dgempsfull.fields.nCodDescIRS'"></label>
                <edit>
                  <entity-autocomplete
                    entity="descontos"
                    attrName="descirs"
                    [model]="model.dhemp.outrosDados"
                    (selectedKeyChange)="model.dhemp.outrosDados.nCodDescIRS = $event"
                    (evtSelectedDescriptionChanged)="model.dhemp.outrosDados.designaBreveDescIRS = $event"
                    [fieldsMap]="{nCodABDESC: 'nCodDescIRS', designacaoBreve: 'designaBreveDescIRS'}"
                    [rowTemplate]="outputAbdes"
                    [output]="outputAbdes"
                    outputKey="nCodABDESC">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <ng-container *ngIf="type !== stateTypes.NEW">
                <h6 class="rh-dgemps-edit-section" [translate]="'dgempsfull.groups.otherData.sections.comments'"></h6>

                <dx-data-grid [cgDxDataGrid]="dataGridDefinition" cgDxDataGridInstanceName="dgempsFullComment" (onInitialized)="onInitialized($event)" [dataSource]="model.comentarios">
                  <div *dxTemplate="let item of 'cellTemplateBtns'" class="text-center">
                    <button type="button" class="btn btn-warning btn-xs action-edit-folder" [disabled]="type !== stateTypes.EDIT" [click]="fnEditComment(item.data)" plPromise>
                      <i class="fa fa-fw fa-pencil"></i>
                    </button>
                    <button type="button" class="btn btn-danger btn-xs action-edit-folder" [disabled]="type !== stateTypes.EDIT" [click]="fnRemoveComment(item.data)" plPromise>
                      <i class="fa fa-fw fa-times"></i>
                    </button>
                  </div>
                </dx-data-grid>
              </ng-container>
            </div>
          </pl-navigator-item>
        </pl-navigator>
      </pl-form>
    </div>
  </entity-detail>
</div>
