import {Directive, Injector} from '@angular/core';
import {IPlEditComponentOptionsInputNumber} from 'pl-comps-angular';
import {DocContabilidadeFieldInput} from './docContabilidade.field.input';
import {ECampoCalculadoME} from '../../../jsonDocContabilidade.interface';
import {EDocContabilidadeField, IDocContabilidadeEventValue} from '../docContabilidade.interface';
import {IDocContabilidadeLinha} from '../../../docsContabilidade.interface';
import {TPredefinidoField} from '../../../../manutencao/predefinidos/jsonPreDefinidosContab.entity.interface';

@Directive()
export abstract class DocContabilidadeFieldInputNumber<TEvent extends IDocContabilidadeEventValue<number> = IDocContabilidadeEventValue<number>> extends DocContabilidadeFieldInput<number, TEvent> {
  public properties: IPlEditComponentOptionsInputNumber;

  protected constructor(
    public readonly fieldName: EDocContabilidadeField,
    protected readonly _injector: Injector,
    protected readonly _predefinidoField?: TPredefinidoField,
    protected readonly _campoCalculadoME?: ECampoCalculadoME
  ) {
    super(fieldName, _injector, _predefinidoField, _campoCalculadoME);
  }

  protected abstract _decimais(): number;

  protected _generateProperties(linha: IDocContabilidadeLinha): IPlEditComponentOptionsInputNumber {
    return {
      ...super._generateProperties(linha),
      alignRight: true,
      decimalsLimit: this._decimais(),
      allowNegative: false
    };
  }
}
