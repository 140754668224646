import {Subscription} from 'rxjs';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {IPlToolbarMenuItem, isEmpty, isNumber} from 'pl-comps-angular';
import {GenericImporterService} from '../../../../components/genericimporter/generic.importer.service';
import {ENTITY_NAME_CLIENTES, ENTITY_NAME_FORNECEDORES, IClifoGrupo, IClifosEntityService} from '../../clifos.entity.interface';
import {IJsonClifo} from '../../jsonClifo.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {EConfigOptionsInstanceName, IClientesListConfigOptions, IFornecedoresListConfigOptions, TConfigOptions} from '../../../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../../../config/constants';
import {ConfigOptionsService} from '../../../../services/config/options/config.options.service';
import {IConfigOptionsInstance} from '../../../../services/config/options/config.options.instance.interface';
import {IEntityMaintenanceInstance} from '../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {ENTITY_NAME_TXTCL} from '../../../txtcl/txtcl.entity.interface';
import {IClifosIntentParameters} from '../../amalia/clifos.amalia.interface';
import {IDevExpressDataGridEventOnInitialized} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {EEntityStateDetailType} from '../../../../../common/utils/entity.state.utils';
import {EPortal} from '../../../../../common/enums/portals.enums';
import {TEntityListBeforeRequestFn} from '../../../../components/entity/list/entity.list.component.interface';
import {IEntityServiceQueryRequestConfig} from '../../../../services/entity/entity.service.interface';

@Component({
  selector: 'clifos-list',
  templateUrl: './clifos.entity.list.component.html'
})
export class ClifosListComponent extends ModuloEntityListComponent<IJsonClifo, IClifosEntityService> implements OnInit, OnDestroy {
  @Input() public grupo: IClifoGrupo;

  public readonly configOptionsGroupName: EGroupName;
  public configOptionsInstanceName: EConfigOptionsInstanceName;
  public optionShowDesativados: boolean;
  public params: IClifosIntentParameters;

  private readonly _isFromParamsAi: boolean;
  private readonly _btnTxtCl: IPlToolbarMenuItem;
  private readonly _maintenanceTxtCl: IEntityMaintenanceInstance;
  private _configOptionsInstance: IConfigOptionsInstance<boolean, IFornecedoresListConfigOptions | IClientesListConfigOptions>;
  private _dataGridInstance: dxDataGrid<IJsonClifo, IJsonClifo>;
  private _subscriptionConfigOptions: Subscription;

  constructor(
    protected readonly _injector: Injector,
    private readonly _genericImporterService: GenericImporterService,
    private readonly _configOptionsService: ConfigOptionsService
  ) {
    super(_injector);
    this._maintenanceTxtCl = this._entityMaintenanceService.build(ENTITY_NAME_TXTCL);
    this.configOptionsGroupName = EGroupName.ERP;
    this.filterValue = '';
    this.params = <IClifosIntentParameters>this._transition.params();
    this._isFromParamsAi = !isEmpty(this.params.query) || !isEmpty(this.params.nConta);
    this._btnTxtCl = {
      caption: 'artigos.texto_livre',
      iconLeft: '<i class="fa fa-fw fa-font" aria-hidden="true"></i>',
      click: () => this._showTxtClModal()
    };
  }

  public async ngOnInit(): Promise<void> {
    super.ngOnInit();
    this.dropdownActions.visible = true;
    this.dropdownActions.menu.push(this._btnTxtCl);

    if (this.grupo.name === ENTITY_NAME_CLIENTES || this.grupo.name === ENTITY_NAME_FORNECEDORES) {
      this.filterValue = 'desactivado=';
      this.configOptionsInstanceName = this.grupo.name === ENTITY_NAME_CLIENTES ? EConfigOptionsInstanceName.CLIENTES_LIST : EConfigOptionsInstanceName.FORNECEDORES_LIST;
      this._configOptionsInstance = this._configOptionsService.getOptionsErp().get(this.configOptionsInstanceName);
      this._subscriptionConfigOptions = this._configOptionsInstance.options().subscribe((configOptions: TConfigOptions<boolean, IFornecedoresListConfigOptions | IClientesListConfigOptions>) => {
        this.optionShowDesativados = configOptions.get('showDesativados').value;
        this.filterValue = this.optionShowDesativados ? 'desactivado=1' : 'desactivado=';
      });
    }
    if (isNumber(this.grupo.importerType)) {
      this.toolbar.addButton(this._genericImporterService.moduleToolbarButton(this.grupo.importerType, this));
    }
    await this._loadFromAi();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    if (this.grupo.name === ENTITY_NAME_CLIENTES || this.grupo.name === ENTITY_NAME_FORNECEDORES) {
      this._subscriptionConfigOptions.unsubscribe();
      this._configOptionsInstance.setDefaultOptions();
    }
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonClifo, IJsonClifo>): void {
    this._dataGridInstance = component;
  }

  public async onDataGridOnContentReady(): Promise<void> {
    await this._loadFromAi();
  }

  public onConfigChange(): Promise<void> {
    return this.refreshList();
  }

  public readonly fnBeforeRequest: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequest(queryRequestConfig);

  private _beforeRequest(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    queryRequestConfig.pesquisa = !queryRequestConfig.pesquisa ? '' : this.filterValue ? `(${queryRequestConfig.pesquisa})` : queryRequestConfig.pesquisa;
    return queryRequestConfig;
  }

  private _showTxtClModal(): Promise<unknown> {
    return this._maintenanceTxtCl.maintenanceSelectAndEdit();
  }

  private async _loadFromAi(): Promise<void> {
    if (!this._dataGridInstance || !this._isFromParamsAi) {
      return;
    }
    if (this.params.nConta) {
      await this._cgStateService.redirectToState({stateOrName: this.grupo?.name, portal: EPortal.ERP, stateType: EEntityStateDetailType.DETAIL, params: {id: this.params.nConta}});
    } else {
      this._dataGridInstance.searchByText(this.params.query);
    }
  }
}
