import {Component, EventEmitter, Injector, Output} from '@angular/core';
import {isNumber, KEYCODES, timeout} from 'pl-comps-angular';
import {EClassificacaoControlo} from '../../../../../../../../common/enums/contabilidade.enums';
import {IDocContabilidadeLinha} from '../../../../docsContabilidade.interface';
import {ECampoCalculadoME} from '../../../../jsonDocContabilidade.interface';
import {DocsContabilidadeService} from '../../../../service/docsContabilidade.service';
import {EDocContabilidadeField, IDocContabilidadeEvent, IDocContabilidadeEventValue} from '../../docContabilidade.interface';
import {DocContabilidadeFieldInputNumber} from '../docContabilidade.field.input.number';

@Component({
  selector: 'doccontabilidade-valor',
  templateUrl: './docContabilidade.valor.component.html'
})
export class DocContabilidadeValorComponent extends DocContabilidadeFieldInputNumber {
  @Output() public readonly evtValorTaxaChanged: EventEmitter<IDocContabilidadeEventValue<number>>;
  @Output() public readonly evtClosedModal: EventEmitter<void>;
  @Output() public readonly evtObterSaldoConta: EventEmitter<IDocContabilidadeEvent>;
  @Output() public readonly evtRegularizacaoCampo40: EventEmitter<IDocContabilidadeEvent>;

  public allowChangeValor: boolean;
  public promise: Promise<void>;

  private _modelChanged: boolean;
  private _promiseAddValorTaxa: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _docsContabilidadeService: DocsContabilidadeService
  ) {
    super(EDocContabilidadeField.VALOR, _injector, 'valor', ECampoCalculadoME.Valor);
    this.evtValorTaxaChanged = new EventEmitter<IDocContabilidadeEventValue<number>>();
    this.evtClosedModal = new EventEmitter<void>();
    this.evtObterSaldoConta = new EventEmitter<IDocContabilidadeEvent>();
    this.evtRegularizacaoCampo40 = new EventEmitter<IDocContabilidadeEvent>();
    this.allowChangeValor = false;
  }

  public modelChanged(value: number): void {
    super.modelChanged(value);
    this._modelChanged = true;
    this.evtModelChanged.emit({
      linha: this.linha,
      value: value,
      oldValue: this.linha.valor
    });
  }

  public changeValorTaxa(): void {
    if (this.locked) {
      return;
    }
    const linhaOrigemIVA: IDocContabilidadeLinha = this._getLinhaOrigemIVA();
    if (!linhaOrigemIVA) {
      return;
    }
    this._docsContabilidadeService.podeUltrapassarToleranciaIVA(this.docContabilidade.nDiario).then((result: boolean) => {
      this._docContabilidadeService
        .editValorModal(linhaOrigemIVA, 'valorTaxa', 'docscontabilidade.doc.linhas.valorTaxa', result)
        .then((responseLinha: IDocContabilidadeLinha) => {
          this.evtValorTaxaChanged.emit({
            value: responseLinha.valorTaxa,
            linha: linhaOrigemIVA
          });
        })
        .finally(() => {
          this.evtClosedModal.emit();
        });
    });
  }

  public addValorTaxa(value: number): void {
    if (this.locked || this._promiseAddValorTaxa) {
      return;
    }
    const linhaOrigemIVA: IDocContabilidadeLinha = this._getLinhaOrigemIVA();
    if (linhaOrigemIVA) {
      const newValue: number = linhaOrigemIVA.valorTaxa + value;
      this._promiseAddValorTaxa = this._docsContabilidadeService
        .validateAddValorTaxa({
          docContabilidadeLinha: linhaOrigemIVA,
          value: newValue,
          alertTitle: 'docscontabilidade.doc.linhas.valorTaxa'
        })
        .then((valid: boolean) => {
          if (valid) {
            this.evtValorTaxaChanged.emit({
              value: newValue,
              linha: linhaOrigemIVA
            });
            this.evtClosedModal.emit();
          }
        })
        .finally(() => {
          this._promiseAddValorTaxa = undefined;
          this.evtClosedModal.emit();
        });
    }
  }

  public obterSaldoConta(): void {
    this.evtObterSaldoConta.emit({linha: this.linha});
  }

  public openRegularizacaoCampo40Modal(): void {
    this.evtRegularizacaoCampo40.emit({linha: this.linha});
  }

  protected _handleLinhaChanged(linha: IDocContabilidadeLinha): void {
    super._handleLinhaChanged(linha);
    this.allowChangeValor = this.properties.disabled && this.linha?.classificControlo === EClassificacaoControlo.IvaDedutivel;
  }

  protected _decimais(): number {
    let decimais: number = this.docContabilidade.nDecimaisValor;
    if (!isNumber(decimais)) {
      decimais = this.configurations.contabilidade.decimais.valor;
    }
    return decimais;
  }

  protected _disabled(linha: IDocContabilidadeLinha): boolean {
    return super._disabled(linha) || (this._isMoedaPresent() && this._isLocked(ECampoCalculadoME.Valor));
  }

  protected _hasGroup(): boolean {
    return true;
  }

  protected _focus(event: FocusEvent): void {
    this._modelChanged = false;
    super._focus(event);
  }

  protected _keydown(event: KeyboardEvent): void {
    if (event.key.toUpperCase() === KEYCODES.S) {
      this.obterSaldoConta();
    } else if (event.key === KEYCODES.ENTER && this._docContabilidadeService.isLineValueCalculatedOnline(this.linha.poc, this.docContabilidade)) {
      timeout().then(() => {
        if (!this._modelChanged) {
          this.modelChanged(this.linha.valor);
        }
      });
    }
    super._keydown(event);
  }

  private _getLinhaOrigemIVA(): IDocContabilidadeLinha {
    return this.docContabilidade.linhas.find((linha: IDocContabilidadeLinha) => linha.nLanc === this.linha.nLancOrigemIva);
  }
}
