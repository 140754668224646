import {Component, Injector, Input, OnInit} from '@angular/core';
import {IPlDynamicVisualsSecondaryClickAction, IPlFilterPanelEvtFiltered} from 'pl-comps-angular';
import {EEstadoEmp} from '../../../../datasources/estadoemp/estadoEmp.datasource.interface';
import {ENTITY_NAME_REMUN} from '../../rhRecolhaDados.entity.interface';
import {IJsonREMUNFX} from '../../jsonRHRecolhaDados.entity.interface';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import moment, {Moment} from 'moment';
import {IDevExpressDataGridEventOnContextMenuPreparing} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {TEntityListBeforeRequestFn} from '../../../../components/entity/list/entity.list.component.interface';
import {IEntityServiceQueryRequestConfig} from '../../../../services/entity/entity.service.interface';

const CURRENT_DATE: Moment = moment();
const CURRENT_YEAR: number = CURRENT_DATE.year();
const CURRENT_MONTH: number = CURRENT_DATE.month();

@Component({
  selector: 'rh-recolhadados-list',
  templateUrl: './rhRecolhaDados.entity.list.component.html'
})
export class RHRecolhaDadosListComponent extends ModuloEntityListComponent<IJsonREMUNFX> implements OnInit {
  @Input() public anos: Array<number>;

  public isRemun: boolean;
  public filter: string;
  public estadoEmp: EEstadoEmp;
  public anoEmCurso: number;
  public mesEmCurso: number;

  constructor(protected readonly _injector: Injector) {
    super(_injector);
    this.anoEmCurso = CURRENT_YEAR;
    this.mesEmCurso = CURRENT_MONTH + 1;
    this.estadoEmp = EEstadoEmp.Activo;
    this.pesquisar = this.pesquisar.bind(this);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.btnRefresh.visible = false;
    this.isRemun = this.entity.name === ENTITY_NAME_REMUN;
    if (this.isRemun) {
      if (!this.anos.includes(CURRENT_YEAR)) {
        this.anos.unshift(CURRENT_YEAR);
      }
    }
  }

  public onDataGridContextMenuPreparing({target, row, event}: IDevExpressDataGridEventOnContextMenuPreparing<IJsonREMUNFX>): void {
    if (target === 'content' && row?.rowType === 'data') {
      event.preventDefault();
      const id: string = this.isRemun ? row.data.remUNID : row.data.remFXID;
      const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateContextMenuActions(id);
      this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.target, actions);
    }
  }

  public pesquisar(): Promise<void> {
    return this.refreshList();
  }

  public readonly fnOnFiltered: (event: IPlFilterPanelEvtFiltered<string>) => IPlFilterPanelEvtFiltered<string> = (event: IPlFilterPanelEvtFiltered<string>) => this._onFiltered(event);

  public readonly fnBeforeRequest: TEntityListBeforeRequestFn = (queryRequestConfig: IEntityServiceQueryRequestConfig) => this._beforeRequest(queryRequestConfig);

  private _onFiltered(event: IPlFilterPanelEvtFiltered<string>): IPlFilterPanelEvtFiltered<string> {
    return {
      ...event,
      serializedFilters: event.serializedFilters.replace('nomeEmpr', 'codEMP')
    };
  }

  private _beforeRequest(queryRequestConfig: IEntityServiceQueryRequestConfig): IEntityServiceQueryRequestConfig {
    if (!queryRequestConfig.pesquisa) {
      queryRequestConfig.pesquisa = '';
    } else {
      const splited: Array<string> = queryRequestConfig.pesquisa.split('&');

      for (let i = 0; i < splited.length; i++) {
        if (splited[i].includes('|')) {
          splited[i] = splited[i].replaceAll('%|', '|');
          splited[i] = splited[i].replaceAll('|', '%|');
          splited[i] = splited[i].replaceAll('=%%', '=');
          splited[i] = splited[i].replaceAll('=', '=%%');
        } else {
          if (splited[i].includes('nomeAbonoDesconto')) {
            splited[i] = splited[i].replace('=', '=%%');
            splited[i] += '%';
            const strToReplicate = splited[i].replace('nomeAbonoDesconto', 'codABDESC');
            splited[i] += '|';
            splited[i] += strToReplicate;
            splited[i] = `(${splited[i]})`;
          }
          if (splited[i].includes('nomeTipoProcessamento')) {
            splited[i] = splited[i].replace('=', '=%%');
            splited[i] += '%';
            const strToReplicate = splited[i].replace('nomeTipoProcessamento', 'tipoProcessamento');
            splited[i] += '|';
            splited[i] += strToReplicate;
            splited[i] = `(${splited[i]})`;
          }
        }
      }

      queryRequestConfig.pesquisa = splited.join('&');
      queryRequestConfig.pesquisa += '&';
    }

    queryRequestConfig.pesquisa += `estado=${this.estadoEmp}`;
    if (this.isRemun) {
      queryRequestConfig.pesquisa += `&ano=${this.anoEmCurso}`;
      if (this.mesEmCurso > -1) {
        queryRequestConfig.pesquisa += `&mes=${this.mesEmCurso}`;
      }
    }
    return queryRequestConfig;
  }
}
