<div class="gestao-comunicacoes-messages-chat cliente-view">
  <div class="gestao-comunicacoes-messages-container-top" *ngIf="showPaddingTop"></div>
  <div class="gestao-comunicacoes-messages-container pt-3" #messagesContainer>
    <div *ngFor="let group of messagesGrouped; let first = first">
      <span class="gestao-comunicacoes-messages-grouped-date" [ngClass]="{'mt-4': !first}">
        {{ group.read ? group.groupHeader : ('gestaoComunicacoes.modal.topico.mensagensNaoLidas' | translate) }}
      </span>

      <div *ngFor="let mensagem of group.messages">
        <cg-message-topico [mensagemObj]="mensagem" [contabilista]="contabilista"></cg-message-topico>
      </div>
    </div>
  </div>
  <div class="gestao-comunicacoes-messages-container-bottom"></div>
</div>
