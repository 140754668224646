<div class="familias">
  <config-options *ngIf="type !== stateTypes.NEW" [instanceName]="configOptionsInstanceName" [groupName]="configOptionsGroupName" [toolbarInstanceName]="instanceName"></config-options>

  <entity-detail
    entityName="familias"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL, orientation: formOrientation, validators: {required: {value: true}}}">
        <pl-group>
          <pl-group>
            <label [translate]="'familias.fields.nFamilia'"></label>
            <edit>
              <pl-edit type="integer" attrName="nFamilia" [(model)]="model.nFamilia"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'familias.fields.nome'"></label>
            <edit>
              <pl-edit type="text" attrName="nome" [(model)]="model.nome"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="tipoClassificador === classArtTemplate.Familia">
          <pl-group>
            <label [translate]="'familias.fields.codtipoartigo'"></label>
            <edit>
              <entity-autocomplete
                entity="tiposartigo"
                attrName="tiposartigo"
                [model]="model.artigosTemplate"
                (selectedKeyChange)="model.artigosTemplate.tipoArtigo = $event"
                (evtSelectedDescriptionChanged)="model.artigosTemplate.nomeTipoArtigo = $event"
                [fieldsMap]="{codTpArt: 'tipoArtigo', nome: 'nomeTipoArtigo'}"
                [output]="tipoartigoOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'familias.fields.unimovimento'"></label>
            <edit>
              <entity-autocomplete
                entity="unidadesmovimento"
                attrName="unidadesmovimento"
                [model]="model.artigosTemplate"
                (selectedKeyChange)="model.artigosTemplate.uniMov = $event"
                (evtSelectedDescriptionChanged)="model.artigosTemplate.nomeUniMov = $event"
                [fieldsMap]="{codUni: 'uniMov', nome: 'nomeUniMov'}"
                [output]="unidadesMovimentoOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="tipoClassificador === classArtTemplate.Familia">
          <pl-group>
            <label [translate]="'familias.fields.grandesfamilias'"></label>
            <edit>
              <entity-autocomplete
                entity="grandesfamilias"
                attrName="grandesfamilias"
                [model]="model.artigosTemplate"
                (selectedKeyChange)="model.artigosTemplate.nDepart = $event"
                (evtSelectedDescriptionChanged)="model.artigosTemplate.nomeDepart = $event"
                [fieldsMap]="{nDepart: 'nDepart', nome: 'nomeDepart'}"
                [output]="grFamiliaOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'familias.fields.subfamilia'"></label>
            <edit>
              <entity-autocomplete
                entity="subfamilias"
                attrName="subfamilias"
                [model]="model.artigosTemplate"
                (selectedKeyChange)="model.artigosTemplate.nSubfa = $event"
                (evtSelectedDescriptionChanged)="model.artigosTemplate.nomeSubfa = $event"
                [fieldsMap]="{nSubFa: 'nSubfa', nome: 'nomeSubfa'}"
                [output]="subfamiliaOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="optionShowContasVendaCompra || (type === stateTypes.NEW && canEdit && tipoClassificador === classArtTemplate.Familia)">
          <pl-group>
            <label [translate]="'familias.fields.contaVenda'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                attrName="nContaPoc"
                [model]="model.artigosTemplate"
                (selectedKeyChange)="model.artigosTemplate.nContaPoc = $event"
                (evtSelectedDescriptionChanged)="model.artigosTemplate.nomeContaPoc = $event"
                [fieldsMap]="{nConta: 'nContaPoc', nome: 'nomeContaPoc'}"
                [properties]="{disabled: type !== stateTypes.DETAIL && !canEdit, validators: {required: {value: false}}}">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'familias.fields.contaCompra'"></label>
            <edit>
              <entity-autocomplete
                entity="pocs"
                attrName="nContaPoc"
                [model]="model.artigosTemplate"
                (selectedKeyChange)="model.artigosTemplate.nContaPocComp = $event"
                (evtSelectedDescriptionChanged)="model.artigosTemplate.nomeContaPocComp = $event"
                [fieldsMap]="{nConta: 'nContaPocComp', nome: 'nomeContaPocComp'}"
                [properties]="{disabled: type !== stateTypes.DETAIL && !canEdit, validators: {required: {value: false}}}">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="tipoClassificador === classArtTemplate.Familia">
          <pl-group>
            <label [translate]="'familias.fields.nomeIvaVenda'"></label>
            <edit>
              <entity-autocomplete
                entity="ivas"
                attrName="ivasVenda"
                [model]="model.artigosTemplate"
                (selectedKeyChange)="model.artigosTemplate.codIva = $event"
                (evtSelectedDescriptionChanged)="model.artigosTemplate.nomeIva = $event"
                [properties]="{validators: {required: {value: false}}}"
                [fieldsMap]="{codIva: 'codIva', nome: 'nomeIva'}"
                [output]="ivasOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'familias.fields.nomeIvaCompra'"></label>
            <edit>
              <entity-autocomplete
                entity="ivas"
                attrName="ivasCompra"
                [model]="model.artigosTemplate"
                (selectedKeyChange)="model.artigosTemplate.codIvaCompra = $event"
                (evtSelectedDescriptionChanged)="model.artigosTemplate.nomeIvaCompra = $event"
                [properties]="{validators: {required: {value: false}}}"
                [fieldsMap]="{codIva: 'codIvaCompra', nome: 'nomeIvaCompra'}"
                [output]="ivasOutput">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="tipoClassificador === classArtTemplate.Familia">
          <pl-group>
            <label [translate]="'familias.fields.metodoCalcPrecVend'"></label>
            <edit>
              <pl-autocomplete
                [source]="metodoSourceCalcPrVenda"
                attrName="metodoCalcVenda"
                [model]="nomeCodCalcPrVen"
                (evtSelected)="metodoCalcVendaChanged($event)"
                [rowTemplate]="mtdOutput"
                output="nameMetodo">
              </pl-autocomplete>
            </edit>
          </pl-group>
          <pl-group>
            <pl-group>
              <label [translate]="'familias.fields.movimentaStock'"></label>
              <edit>
                <pl-edit type="boolean" attrName="movimentaStock" [(model)]="model.artigosTemplate.movimentaStock"></pl-edit>
              </edit>
            </pl-group>
            <pl-group>
              <label [translate]="'familias.fields.naoPExistNeg'"></label>
              <edit>
                <pl-edit-switch attrName="naoPExistNeg" [falseValue]="0" [trueValue]="-1" [(model)]="model.artigosTemplate.naoPExistNeg"></pl-edit-switch>
              </edit>
            </pl-group>
          </pl-group>
        </pl-group>
        <pl-group *ngIf="type !== stateTypes.NEW && tipoClassificador === classArtTemplate.Familia">
          <label [translate]="'familias.fields.criterio'"></label>
          <edit>
            <pl-autocomplete
              [source]="metodoSourceCriterio"
              attrName="metodoCriterio"
              [model]="nomeCriterio"
              (evtSelected)="metodoCriterioChanged($event)"
              [properties]="{validators: {required: {value: false}}, readonly: true}"
              [rowTemplate]="mtdOutput"
              output="nameMetodo">
            </pl-autocomplete>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
