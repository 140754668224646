import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatestWith, from, Observable, Subscription, switchMap, timer} from 'rxjs';
import {GestaoComunicacoesTopicoService} from './gestaoComunicacoesTopico.module.service';
import {map, tap} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {IJsonGestaoComunicacoesNewMessages} from '../jsonGestaoComunicacoesTopico.module.interface';
import {AuthService} from '../../../../services/auth/auth.service';
import {skipIf} from 'pl-comps-angular';
import {TUserSession} from '../../../../services/account/jsonUserApi.interface';
import {hasAnyAuthority} from '../../../../../common/utils/roles.utils';
import {ROLE} from '../../../../services/role.const';
import {AppService} from '../../../../services/app/app.service';
import {IAppStatus} from '../../../../services/app/app.service.interface';
import {EAppLaunchMode} from '../../../../../common/site';
import moment from 'moment';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const INTERVAL = moment.duration(5, 'minutes').asMilliseconds();

@Injectable({
  providedIn: 'root'
})
export class GestaoComunicacoesNotificacoesService implements OnDestroy {
  private readonly _notificationsCount: BehaviorSubject<number>;
  private readonly _subscription: Subscription;
  private _observableNotificationsCount: Observable<number>;

  constructor(
    private readonly _appService: AppService,
    private readonly _authService: AuthService,
    private readonly _gestaoComunicacoesTopicosService: GestaoComunicacoesTopicoService
  ) {
    this._notificationsCount = new BehaviorSubject<number>(0);
    this._subscription = timer(0, INTERVAL)
      .pipe(
        combineLatestWith(this._appService.status(), this._authService.identityAsObservable()),
        skipIf(
          ([, status, session]: [number, IAppStatus, TUserSession]) =>
            status.launchMode !== EAppLaunchMode.Default || !session || !hasAnyAuthority(session, [ROLE.CONTABILIDADE, ROLE.RH, ROLE.CLIENTCONNECT, ROLE.PCA, ROLE.ERP])
        ),
        switchMap(() => from(this._gestaoComunicacoesTopicosService.getNewMessagesEnterprise())),
        map((response: HttpResponse<IJsonGestaoComunicacoesNewMessages>) => response.body.nMessages),
        tap((count: number) => {
          this._notificationsCount.next(count);
        })
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public notificationsCount(): Observable<number> {
    if (!this._observableNotificationsCount) {
      this._observableNotificationsCount = this._notificationsCount.asObservable();
    }
    return this._observableNotificationsCount;
  }
}
