export interface IMetodoSourceEstadosConta {
  valueMetodo: number;
  nameMetodo: string;
}

export interface IFaturacaoPublicaStateParams {
  faccbID: number;
  stateOrName?: string;
}

export const MODULE_NAME_FATURACAO_PUBLICA = 'faturacaopublica';
