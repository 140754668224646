<entity-detail
  [entityName]="entityName"
  [(model)]="model"
  [type]="type"
  [maintenanceMode]="maintenanceMode"
  [callback]="callback"
  [serviceMethodsOverride]="serviceMethodsOverride"
  (evtUpdate)="onUpdate($event)">
  <div *entityDetailContent>
    <pl-form [(formInstance)]="form">
      <pl-group>
        <pl-group>
          <label [translate]="'pagamentos.pagamento.fornecedor'"></label>
          <edit>
            <entity-autocomplete entity="fornecedores" attrName="fornecedores" [model]="modelClifo" (modelChange)="changedNConta($event)" [output]="fornecedorOutput" plAutoFocus>
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'pagamentos.pagamento.data'"></label>
          <edit>
            <pl-edit-datepicker attrName="cabdata" [model]="model.cab.data" (modelChange)="changedData($event)"></pl-edit-datepicker>
            <div class="form-control-danger" *ngIf="invalidHeaderDate">
              <i class="fa fa-fw fa-exclamation-triangle" aria-hidden="true"></i>
              <span class="btn" [translate]="'pagamentos.erros.invalidHeaderDate'"></span>
            </div>
          </edit>
        </pl-group>
      </pl-group>

      <div *ngIf="!isloading && !model.linhas.length && model.cab.nConta" [plAnimationShake]="model.linhas" [translate]="'pagamentos.info.semMovimentos'"></div>

      <br />

      <div *ngIf="model.linhas.length && !isloading">
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="contasAquisicaoContab"
          [dataSource]="model.linhas"
          (onInitialized)="onInitialized($event)"
          (onCellPrepared)="onCellPrepared($event)"
          (onEditorPreparing)="onEditorPreparing($event)"
          (onCellClick)="onCellClick($event)"
          (onSaved)="onSaved($event)">
          <div *dxTemplate="let item of 'headerCellTemplateBtns'">
            <pl-tooltip [config]="{text: 'pagamentos.btn.pagaTudo', container: 'body'}">
              <dx-button *plTooltipContent class="dx-button-icon-fontawesome" (click)="pagarTudo()" icon="fa fa-fw fa-check" [stylingMode]="'text'"></dx-button>
            </pl-tooltip>

            <pl-tooltip [config]="{text: 'pagamentos.btn.limpaTudo', container: 'body'}">
              <dx-button *plTooltipContent class="dx-button-icon-fontawesome" (click)="limpaValoresLinhas()" icon="fa fa-fw fa-eraser" [stylingMode]="'text'"></dx-button>
            </pl-tooltip>

            <pl-tooltip [config]="{text: 'pagamentos.btn.distribuirValores', container: 'body'}">
              <dx-button *plTooltipContent class="dx-button-icon-fontawesome" (click)="distribuiValor()" icon="fa fa-fw fa-calculator" [stylingMode]="'text'"></dx-button>
            </pl-tooltip>
          </div>

          <div *dxTemplate="let item of 'cellTemplateBtns'">
            <pl-tooltip [config]="{text: 'pagamentos.btn.pagaTudo', container: 'body'}">
              <dx-button *plTooltipContent class="dx-button-icon-fontawesome" (click)="pagarTudoLinha(item.data)" icon="fa fa-fw fa-check" [stylingMode]="'text'"></dx-button>
            </pl-tooltip>

            <pl-tooltip [config]="{text: 'pagamentos.btn.limpaValor', container: 'body'}">
              <dx-button *plTooltipContent class="dx-button-icon-fontawesome" (click)="limpaLinha(item.data)" icon="fa fa-fw fa-eraser" [stylingMode]="'text'"></dx-button>
            </pl-tooltip>
          </div>

          <div *dxTemplate="let item of 'cellTemplateValor'">
            <span class="cell-template-text-pagamentos">{{ item.data.valor | cgcCurrency: config.contabilidade.decimais.valor : item.data.abreviaturaMoeda : 'code' }}</span>
          </div>

          <div *dxTemplate="let item of 'cellTemplateValorPorPagar'">
            <span class="cell-template-text-pagamentos">{{ item.data.valorPorPagar | cgcCurrency: config.contabilidade.decimais.valor : item.data.abreviaturaMoeda : 'code' }}</span>
          </div>

          <div *dxTemplate="let item of 'cellTemplateMontanteRetido'">
            <span class="cell-template-text-pagamentos">{{ item.data.montanteRetido | cgcCurrency: config.contabilidade.decimais.valor : item.data.abreviaturaMoeda : 'code' }}</span>
          </div>

          <div *dxTemplate="let item of 'masterDetailTemplate'">
            <documento-faturacao [doc]="item.data._thedoc" [actionPdf]="fnGetPdf"></documento-faturacao>
          </div>
        </dx-data-grid>

        <div style="display: flex; justify-content: space-between">
          <div class="resumo">
            <h4 class="page-header" style="text-align: left" [translate]="'pagamentos.info.aPagar'"></h4>
            <dl class="page-invoice-amount">
              <dt style="text-align: left"><span [translate]="'pagamentos.info.naoVencido'"></span>:</dt>
              <dd>{{ totalNaoVencido | cgcDecimal: config.contabilidade.decimais.valor }}</dd>

              <dt style="text-align: left"><span [translate]="'pagamentos.info.vencido'" style="text-align: left"></span>:</dt>
              <dd>{{ totalVencido | cgcDecimal: config.contabilidade.decimais.valor }}</dd>

              <dt style="text-align: left"><span [translate]="'global.text.total'" style="text-align: left"></span>:</dt>
              <dd>{{ totalVencido + totalNaoVencido | cgcDecimal: config.contabilidade.decimais.valor }}</dd>
            </dl>
          </div>

          <div class="float-end resumo text-end">
            <h4 class="page-header" [translate]="'documentoFaturacao.summary'"></h4>
            <dl class="page-invoice-amount">
              <ng-container *ngIf="modelPagamentoDistribuir.tipoDistribuicao === tipoDistribuicao.Manual">
                <dt><span [translate]="'pagamentos.pagamento.linhas.valorAPagar'"></span>:</dt>
                <dd>{{ valorDistribuir | cgcDecimal: config.contabilidade.decimais.valor }}</dd>
              </ng-container>

              <dt><span [translate]="'pagamentos.pagamento.liquidado'"></span>:</dt>
              <dd>{{ model.cab.totalPagar | cgcDecimal: config.contabilidade.decimais.valor }}</dd>

              <ng-container *ngIf="model.cab.temRetencao">
                <dt><span [translate]="'pagamentos.pagamento.linhas.montanteAReter'"></span>:</dt>
                <dd>{{ model.cab.totalRetencao | cgcDecimal: config.contabilidade.decimais.valor }}</dd>
              </ng-container>

              <dt><span [translate]="'pagamentos.pagamento.descontos'"></span>:</dt>
              <dd>{{ model.cab.totalDescontos | cgcDecimal: config.contabilidade.decimais.valor }}</dd>

              <dt><span [translate]="'pagamentos.pagamento.cab.totalPagar'"></span>:</dt>
              <dd>{{ model.cab.total | cgcDecimal: config.contabilidade.decimais.valor }}</dd>
            </dl>
          </div>
        </div>
      </div>
    </pl-form>
  </div>
</entity-detail>
