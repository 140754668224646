<div class="declaracaoivaperiodica-previsualizadeclaracao-modal entity-detail-form">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h5 class="modal-title" [translate]="'global.menu.declaracaoiva'"></h5>
  </div>

  <div class="modal-body">
    <pl-group>
      <label><span [translate]="'declaracaoiva.text.attachment'"></span>:</label>
      <edit>
        <pl-edit-select [source]="tiposAnexo" [model]="tipoAnexo" (modelChange)="setTipoAnexo($event)" [properties]="{disallowClear: true}"></pl-edit-select>
      </edit>
    </pl-group>

    <hr />

    <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
  </div>

  <div class="modal-footer" plNavigation selector="pl-button">
    <pl-button klass="btn btn-sm btn-light action-dismiss" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
  </div>
</div>
