<div class="file-viewer">
  <div class="file-viewer-header">
    <div *ngIf="showList && showBox">
      <span class="pe-1"><span [translate]="'fileviewer.header.descBtn'"></span>:&nbsp;</span>
      <div class="btn-group btn-group-sm">
        <button type="button" class="btn btn-sm btn-outline-primary" (click)="typeViewChanged(viewTypes.List)" [ngClass]="{active: viewType === viewTypes.List}">
          <i class="fa fa-fw fa-th-list"></i>
        </button>

        <button type="button" class="btn btn-sm btn-outline-primary" (click)="typeViewChanged(viewTypes.Icon)" [ngClass]="{active: viewType === viewTypes.Icon}">
          <i class="fa fa-fw fa-th-large"></i>
        </button>
      </div>
    </div>

    <div *ngIf="viewType === viewTypes.List" class="btn-actions-top">
      <ng-container *ngTemplateOutlet="templateActions; context: {iconDownload: btnIcons.btnDownload, iconSearch: btnIcons.btnSearch}"></ng-container>
    </div>

    <div class="ms-auto">
      <pl-edit-text attrName="search" [model]="searchModel" (modelChange)="updateSearchModel($event)" [properties]="{modelOptions: {debounce: debounceTime}}">
        <div *inputGroup="{klass: 'btn-primary'}">
          <i class="pl-icon-search" aria-hidden="true"></i>
        </div>
      </pl-edit-text>
    </div>
  </div>

  <div class="file-viewer-container" [ngSwitch]="viewType">
    <!-- List View -->
    <div *ngIf="showList">
      <div *ngSwitchCase="viewTypes.List">
        <dx-data-grid
          [cgDxDataGrid]="dataGridDefinition"
          cgDxDataGridInstanceName="fileViwer"
          [dataSource]="dataGridDefinition.dataSource"
          (onInitialized)="onInitialized($event)"
          (onContentReady)="onContentReady()"
          (onCellClick)="onCellClick($event)"
          (onCellDblClick)="onCellDblClick()">
          <div *dxTemplate="let cellInfo of 'cellTemplateNome'">
            <img alt="extension" [src]="cellInfo.data.urlExtension" class="me-2" style="width: 20px" />
            <ngb-highlight [attr.title]="cellInfo.data.text" [result]="cellInfo.data.text" [term]="searchModel"></ngb-highlight>
          </div>
        </dx-data-grid>
      </div>
    </div>

    <!-- Icon View -->
    <div *ngIf="showBox">
      <div class="file-viewer-icon" *ngSwitchCase="viewTypes.Icon">
        <div class="row">
          <ng-container *ngFor="let file of filteredFiles">
            <div class="file-icon col-12 col-sm-3 col-md-2" [ngClass]="{selected: file === selectedFile}" (click)="selectFile(file)">
              <pl-tooltip
                [config]="{
                  templateRef: templateActions,
                  templateRefContext: {iconDownload: btnIcons.btnDownload},
                  placement: 'bottom',
                  autoClose: false,
                  triggers: 'manual',
                  container: 'body',
                  tooltipClass: 'file-viewer-btns-tooltip'
                }"
                [callback]="file.tooltipCallback">
                <div *plTooltipContent>
                  <div class="file-icon-img-wrapper">
                    <div class="file-icon-img">
                      <img alt="extension" [src]="file.urlExtension" />
                    </div>
                  </div>

                  <div class="file-icon-text">
                    <p class="file-icon-text-p" [attr.title]="file.textExt">{{ file.textExt }}</p>
                  </div>
                </div>
              </pl-tooltip>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #templateActions let-iconDownload="iconDownload" let-iconSearch="iconSearch">
  <div class="btn-group btn-group-sm">
    <pl-tooltip [config]="{text: 'fileviewer.tooltip.onlyPDF', placement: 'bottom', disabled: selectedFile ? selectedFile.mimeType === 'application/pdf' : false}">
      <button type="button" class="btn btn-sm btn-outline-primary" [disabled]="!selectedFile || selectedFile.mimeType !== 'application/pdf'" (click)="previsualizarModal()">
        <i *ngIf="iconSearch" [ngClass]="iconSearch" aria-hidden="true"></i>
        <span [translate]="'fileviewer.tooltip.visualizar'"></span>
      </button>
    </pl-tooltip>
    <a
      [attr.href]="urlPdf"
      rel="noopener noreferrer"
      class="btn btn-sm btn-outline-primary"
      [ngClass]="{disabled: !selectedFile}"
      download="{{ selectedFile ? selectedFile.text : '' }}"
      target="_blank">
      <i *ngIf="iconDownload" [ngClass]="iconDownload" aria-hidden="true"></i>
      <span [translate]="'fileviewer.tooltip.download'"></span>
    </a>
    <input type="file" style="display: none" (change)="addFile($event)" #fileInput />
    <button *ngIf="!readOnly" type="button" class="btn btn-sm btn-outline-primary" (click)="fileInput.click(); fileInput.value = ''">
      <i class="fa fa-plus" aria-hidden="true"></i>
      <span [translate]="'fileviewer.tooltip.upload'"></span>
    </button>
    <button *ngIf="!readOnly" type="button" class="btn btn-sm btn-outline-primary" (click)="removeFile()" [ngClass]="{disabled: !selectedFile}">
      <i class="fa fa-minus" aria-hidden="true"></i>
      <span [translate]="'fileviewer.tooltip.delete'"></span>
    </button>
  </div>
</ng-template>
