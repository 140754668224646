<pl-form (evtSubmitted)="close()">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'docfasNums.auxiliares.novaserie'"></h4>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <pl-group>
        <label [translate]="'docfasNums.fields.nDocfa'"></label>
        <edit>
          <pl-edit type="text" [model]="docfaStr" attrName="nDocfa" [properties]="{readonly: true}"></pl-edit>
        </edit>
      </pl-group>

      <pl-group class="docfa-nnumer-edit">
        <label [translate]="'docfasNums.fields.nNumer'"></label>
        <edit>
          <pl-edit
            type="cgsmallint"
            [properties]="{placeholder: 'docfasNums.fields.nNumerPlaceholder', validators: {required: {value: false}, min: {value: 0}}}"
            [(model)]="model.nNumer"
            attrName="nNumer"
            plAutoFocus></pl-edit>
        </edit>
      </pl-group>

      <pl-group>
        <label [translate]="'docfasNums.fields.descNumer'"></label>
        <edit>
          <pl-edit type="text" [(model)]="model.descNumer" attrName="descNumer"></pl-edit>
        </edit>
      </pl-group>

      <pl-group *ngIf="isAutoFaturacaoComAcordo">
        <label [translate]="'docfasNums.fields.nConta'"></label>
        <edit>
          <entity-autocomplete
            entity="fornecedores"
            attrName="fornecedores"
            [model]="model"
            [properties]="{validators: {maxlength: {value: 75}, required: {value: isAutoFaturacaoComAcordo}}}"
            [(selectedKey)]="model.nConta"
            (evtSelectedDescriptionChanged)="model.nomeClifo = $event"
            [fieldsMap]="{nConta: 'nConta', nome: 'nomeClifo'}"
            [filter]="fornecedoresFilter"
            [output]="fornecedoresOutput">
          </entity-autocomplete>
        </edit>
      </pl-group>

      <pl-group *ngIf="isAutoFaturacaoSemAcordo">
        <label [translate]="'docfasNums.modal.comunicacao.fields.enquadramento'"></label>
        <edit>
          <pl-edit-select
            [(model)]="model.atAFEnquadramento"
            [properties]="{select: {list: enquadramentoList}, validators: {required: {value: isAutoFaturacaoSemAcordo}}, disallowClear: true}"></pl-edit-select>
        </edit>
      </pl-group>

      <pl-group *ngIf="!editAction && !isAutoFaturacao">
        <label [translate]="'docfasNums.fields.paraTodosDoGrupo'" [translateParams]="{nomeGrupo: nomeGrupoDoc}"></label>
        <edit>
          <pl-edit-switch [(model)]="toggleParaTodosDoGrupo" attrName="paraTodosDoGrupo"></pl-edit-switch>
        </edit>
      </pl-group>

      <pl-group *ngIf="!editAction">
        <label [translate]="'docfasNums.fields.visivelERPCloud'"></label>
        <edit>
          <pl-edit-switch [(model)]="model.visivelERPCloud" attrName="visivelERPCloud"></pl-edit-switch>
        </edit>
      </pl-group>
    </div>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="submit" class="btn btn-primary btn-sm" data-focus [plPromise]="promise"><i class="fa fa-fw fa-save"></i> Gravar</button>
    <button type="button" class="btn btn-light btn-sm action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i> Cancelar</button>
  </div>
</pl-form>
