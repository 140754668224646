import {Component, Injector, Input, OnInit} from '@angular/core';
import {copy, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {EGestaoComunicacoesEstadoTipo, IJsonGestaoComunicacoesEstadoOrdem} from '../../../jsonGestaoComunicacoesEstado.module.interface';
import {GestaoComunicacoesEstadoService} from '../../../services/gestaoComunicacoesEstado.module.service';
import {GESTAO_COMUNICACAO_COLOR_DEFAULT, isBackgroundTooLight} from '../../../gestaoComunicacoes.module.interface';
import {FormGroupDirective} from '@angular/forms';

@Component({
  selector: 'gestao-comunicacoes-estados-empresa-config-detail-modal',
  templateUrl: './gestaoComunicacoes.estadosEmpresaConfig.detail.modal.component.html'
})
export class GestaoComunicacoesEstadosEmpresaConfigDetailModalComponent extends CGModalComponent<IJsonGestaoComunicacoesEstadoOrdem> implements OnInit {
  @Input() public estadoDetail: IJsonGestaoComunicacoesEstadoOrdem;
  @Input() public update: boolean;
  @Input() public selectedNEmpresa: string;

  public estadoTitle: string;
  public form: FormGroupDirective;
  public backgroundInvalid: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _gestaoComunicacoesEstadoService: GestaoComunicacoesEstadoService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    if (!this.update) {
      // new estado
      this.estadoDetail = this._emptyEstado();
      this.estadoTitle = this._plTranslateService.translate('gestaoComunicacoes.modal.empresaConfig.empresaConfigEstados.detail.titleAdd');
    } else {
      this.estadoTitle = this._plTranslateService.translate('gestaoComunicacoes.modal.empresaConfig.empresaConfigEstados.detail.title', {nomeEstado: copy(this.estadoDetail.nome)});
    }
  }

  public evaluateBackgroundColorValid(): void {
    this.backgroundInvalid = isBackgroundTooLight(this.estadoDetail.cor);
  }

  public async close(): Promise<void> {
    if (!this.estadoDetail.todasEmpresas) {
      this.estadoDetail.nEmpresa = this.selectedNEmpresa;
    }
    if (this.update) {
      await this._gestaoComunicacoesEstadoService.updateEstado(this.estadoDetail.comEstadoId, this.estadoDetail);
    } else {
      await this._gestaoComunicacoesEstadoService.createEstado(this.estadoDetail);
    }
    this._plAlertService.success('gestaoComunicacoes.modal.empresaConfig.empresaConfigEstados.mensagens.successEstado');
    super.close(this.estadoDetail);
  }

  private _emptyEstado(): IJsonGestaoComunicacoesEstadoOrdem {
    return {
      nome: '',
      tipo: EGestaoComunicacoesEstadoTipo.Utilizador,
      todasEmpresas: false,
      cor: GESTAO_COMUNICACAO_COLOR_DEFAULT,
      comEstadoId: '',
      ordem: 0,
      nEmpresa: this.selectedNEmpresa
    };
  }
}
