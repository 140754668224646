import {ETipoEscolhaUtlViatPatronal, IJsonUtlViatPatronal} from './jsonUtlViatPatronal.module.interface';
import {TranslateService} from '@ngx-translate/core';

export interface IUtlViatPatronal extends IJsonUtlViatPatronal {
  _empDesc: string;
  _abonoDesc: string;
}

export interface ITipoEscolhaUtlViatPatronal {
  value: ETipoEscolhaUtlViatPatronal;
  label: string;
}

export function dataSourceTipoEscolhaUtlViatPatronal(translate: TranslateService): ReadonlyArray<ITipoEscolhaUtlViatPatronal> {
  return [
    {value: ETipoEscolhaUtlViatPatronal.Ativas, label: translate.instant('utlviatpatronal.tipoUtlViatPatronal.ativas')},
    {value: ETipoEscolhaUtlViatPatronal.Inativas, label: translate.instant('utlviatpatronal.tipoUtlViatPatronal.inativas')},
    {value: ETipoEscolhaUtlViatPatronal.Todas, label: translate.instant('utlviatpatronal.tipoUtlViatPatronal.todas')}
  ];
}

export const MODULE_NAME_UTL_VIAT_PATRONAL = 'utlViatPatronal';
