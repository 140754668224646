import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {ApiService} from '../../../services/api/api.service';
import {buildSessionUrlWithParams} from '../../../../common/utils/utils';
import {IATRecibosRendasDateFilters, IRRImportDoc} from './atRecibosRendas.module.interface';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocContabilidade, IDocsContabilidadeEntityService} from '../docscontabilidade/docsContabilidade.interface';
import {IJsonDocContabilidade} from '../docscontabilidade/jsonDocContabilidade.interface';
import {TServiceResponse} from '../../../services/api/api.service.interface';
import {TDate} from '../../../../common/dates';
import {IJsonRRConfig, IJsonRRDocContabilidade, IJsonRRImportDocApiList, IJsonRRNIFConfig, IJsonRRNIFConfigSave} from './jsonATRecibosRendas.interface';

@Injectable({
  providedIn: 'root'
})
export class AtRecibosRendasService {
  private readonly _path: string;
  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;

  constructor(
    private readonly _apiService: ApiService,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    this._path = `${this._apiService.path.restapi}/atRecibosRendas`;
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>('docscontabilidade');
  }

  public getAtList(deData: TDate, ateData: TDate): TServiceResponse<IJsonRRImportDocApiList> {
    return this._apiService.get<IJsonRRImportDocApiList>({
      url: this._path,
      params: {
        deData: deData,
        ateData: ateData,
        tipo: undefined,
        situacao: undefined
      },
      reportExceptions: false
    });
  }

  public getExtratoXlsUrl(deData: TDate, ateData: TDate): Observable<string> {
    return buildSessionUrlWithParams(`${this._path}/exportToXls`, {
      deData: deData,
      ateData: ateData
    });
  }

  public getDocDetailsContabilidade(extPocCabID: string, ano: number = -1): Promise<IDocContabilidade> {
    const contabEnt = this._entityServiceBuilder.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    return this._apiService
      .get<IDocContabilidade>({
        url: `${this._apiService.path.restapi}/${contabEnt.entity.name}/${extPocCabID}`,
        params: {ano: ano}
      })
      .then((response) => response.body);
  }

  public ignorarRecibo(nif: string, numerodocumento: number): TServiceResponse<void> {
    return this._apiService.put<void>({url: `${this._path}/ignorarRecibo?nif=${nif}&numerodocumento=${numerodocumento}`});
  }

  public ignorarSempreNif(nif: string, nome: string): TServiceResponse<void> {
    return this._apiService.put<void>({url: `${this._path}/ignorarPorNif?nif=${nif}&nome=${nome}`});
  }

  public deixarIgnorarRecibo(nif: string, numerodocumento: number): TServiceResponse<unknown> {
    return this._apiService.delete({url: `${this._path}/deixarIgnorarRecibo?nif=${nif}&numerodocumento=${numerodocumento}`});
  }

  public deixarIgnorarNif(nif: string): TServiceResponse<unknown> {
    return this._apiService.delete({url: `${this._path}/deixarIgnorarNif?nif=${nif}`});
  }

  public obterContaDoNif(nif: string): TServiceResponse<string> {
    return this._apiService.get<string>({url: `${this._path}/obterContaDoNIF/${nif}`});
  }

  public getDocContabilidade(extPocCabID: string, ano?: number): TServiceResponse<IDocContabilidade> {
    return this._serviceDocsContabilidade.get({id: extPocCabID, params: {ano: ano || -1}});
  }

  public getDocContabilidadePredefinido(item: IRRImportDoc): TServiceResponse<IJsonRRDocContabilidade> {
    return this._apiService.post<IJsonRRDocContabilidade, IRRImportDoc>({
      url: `${this._path}/docContabilidade`,
      body: item
    });
  }

  public getDatasSegundoNPeriodo(nperiodo: string, onlyPeriodMonth: boolean = false): Promise<IATRecibosRendasDateFilters> {
    return this._apiService
      .get<IATRecibosRendasDateFilters>({
        url: `${this._path}/${nperiodo}/getDatasSegundoNPeriodo`,
        params: {onlyPeriodMonth: onlyPeriodMonth}
      })
      .then((response: HttpResponse<IATRecibosRendasDateFilters>) => response.body);
  }

  public lancarDocsEmSerie(list: Array<IRRImportDoc>): Promise<Array<IRRImportDoc>> {
    return this._apiService.put<Array<IRRImportDoc>>({url: `${this._path}/lancarDocsEmSerie`, body: list}).then((response: HttpResponse<Array<IRRImportDoc>>) => response.body);
  }

  public reprocessEFaturaDoc(doc: IRRImportDoc): TServiceResponse<IRRImportDoc> {
    return this._apiService.post<IRRImportDoc>({
      url: `${this._path}/reprocessEfaturaDoc`,
      body: doc
    });
  }

  public getConfig(): Promise<IJsonRRConfig> {
    return this._apiService.get<IJsonRRConfig>({url: `${this._path}/getConfig`}).then((response: HttpResponse<IJsonRRConfig>) => response.body);
  }

  public saveConfig(predefinidoId: number): TServiceResponse<void> {
    return this._apiService.post<void, number>({url: `${this._path}/saveConfig`, body: predefinidoId});
  }

  public getNifConfig(nifLocatario: string): Promise<IJsonRRNIFConfig> {
    return this._apiService.get<IJsonRRNIFConfig>({url: `${this._path}/getNifConfig`, params: {nifLocatario: nifLocatario}}).then((response: HttpResponse<IJsonRRNIFConfig>) => response.body);
  }

  public saveNifConfig(config: IJsonRRNIFConfigSave): TServiceResponse<void> {
    return this._apiService.post<void, IJsonRRNIFConfigSave>({url: `${this._path}/saveNifConfig`, body: config});
  }

  public getReciboPDFURL(numeroContrato: number, numeroRecibo: number): string {
    return `${this._path}/pdf/${numeroContrato}/${numeroRecibo}`;
  }
}
