import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_COM_CATEGORIA} from './comCategoria.entity.interface';
import {IJsonComCategoria} from './jsonComCategoria.entity.interface';
import {DATA_SOURCE_NAME_TIPO_COM_CATEGORIA} from '../../datasources/tipocomcategoria/tipoComCategoria.datasource.interface';
import {ComCategoriaEntityEditComponent} from './components/edit/comCategoria.entity.edit.component';
import {IDevExpressDataGridColumnCustomizeTextCellInfo} from '../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {PlTranslateService} from 'pl-comps-angular';
import {TInjectorResolvable} from '../../../common/injectors/common';

export const ENTITY_COM_CATEGORIA: TInjectorResolvable = [
  PlTranslateService,
  (plTranslateService: PlTranslateService): IEntityDefinition<IJsonComCategoria> => {
    return {
      name: ENTITY_NAME_COM_CATEGORIA,
      roles: [ROLE.ERP, ROLE.CONTABILIDADE],
      pageTitle: 'global.menu.comcategorias',
      searchPlaceholder: 'entity.placeholder.search',
      metadata: {
        keyName: 'comCategoriaId',
        descriptionName: 'nome',
        fields: [
          {name: 'nome', type: 'text', caption: 'comcategorias.fields.nome', validators: {maxlength: 50}},
          {
            name: 'tipo',
            type: DATA_SOURCE_NAME_TIPO_COM_CATEGORIA,
            caption: 'comcategorias.fields.tipo',
            properties: {
              devExpress: {
                dataGrid: {
                  lookup: {
                    cgDataSource: DATA_SOURCE_NAME_TIPO_COM_CATEGORIA
                  }
                }
              }
            }
          },
          {name: 'cor', type: 'color', caption: 'comcategorias.fields.cor', validators: {maxlength: 10}},
          {name: 'todasEmpresas', type: 'boolean', caption: 'comcategorias.fields.todasEmpresas'},
          {
            name: 'nEmpresa',
            type: 'text',
            caption: 'comcategorias.fields.nEmpresa',
            validators: {maxlength: 3},
            properties: {
              devExpress: {
                dataGrid: {
                  customizeText: (cellInfo: IDevExpressDataGridColumnCustomizeTextCellInfo): string => {
                    if (cellInfo.target === 'row' && cellInfo.valueText === '000') {
                      return plTranslateService.translate('gestaoComunicacoes.common.todasEmpresas');
                    }
                    return cellInfo.valueText;
                  }
                }
              }
            }
          }
        ],
        order: 'comCategoriaId',
        searchFields: 'nome'
      },
      autocomplete: {
        rowTemplate: '{{nome}}',
        output: 'nome',
        searchFields: 'nome'
      },
      actions: {
        new: true,
        detail: true,
        edit: true,
        delete: true,
        search: true,
        filter: true
      },
      detail: {
        state: {
          component: ComCategoriaEntityEditComponent
        }
      }
    };
  }
];
