import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../../../../../components/cg/modal/cgmodal.component';
import {IJsonModelo22, IJsonModelo22ContasDef} from '../../../../../jsonModelo22.module.interface';
import {IJsonPOC} from '../../../../../../../../entities/pocs/jsonPOC.entity.interface';
import {Modelo22Service} from '../../../../../modelo22.module.service';
import {IDevExpressDataGrid} from '../../../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonNumber} from '../../../../../../../../../common/interfaces/json';

@Component({
  selector: 'modal-modelo22-contasdef',
  templateUrl: './modelo22.contasDef.modal.component.html'
})
export class Modelo22ContasDefModalComponent extends CGModalComponent<IJsonModelo22> implements OnInit {
  @Input() public campo: string;
  @Input() public contasDef: Array<IJsonModelo22ContasDef>;
  @Input() public modelo22: IJsonModelo22;
  public readonly dataGridDefinition: IDevExpressDataGrid;

  public promise: Promise<void>;
  public nContaPocDe: Partial<IJsonPOC>;
  public nContaPocAte: Partial<IJsonPOC>;
  public perioAte: string;
  public sinal: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this.dataGridDefinition = {
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nContaDe', dataType: 'string', caption: 'modelo22.modal.rosto.contasdef.table.nContaDe'},
        {dataField: 'nContaAte', dataType: 'string', caption: 'modelo22.modal.rosto.contasdef.table.nContaAte'},
        {dataField: 'perioAte', dataType: 'string', caption: 'modelo22.modal.rosto.contasdef.table.perioAte'},
        {dataField: 'sinal', dataType: 'boolean', caption: 'modelo22.modal.rosto.contasdef.table.sinal'},
        {dataField: 'valor', dataType: 'double', caption: 'modelo22.modal.rosto.contasdef.table.valor'},
        {type: 'buttons', cellTemplate: 'cellTemplateBtnContasDef'}
      ],
      dataSource: [],
      export: {filename: 'modelo22.modal.rosto.contasdef.title'},
      remoteOperations: false
    };
  }

  public ngOnInit(): void {
    this.nContaPocDe = {nConta: ''};
    this.nContaPocAte = {nConta: 'ZZZZ'};
    this.perioAte = '121';
    this.sinal = true;
    this.dataGridDefinition.dataSource = this.contasDef;
  }

  public close(): Promise<void> {
    this.promise = this._modelo22Service.saveCamposContasDef(this.contasDef, this.campo).then(async (response: HttpResponse<IJsonNumber>) => {
      this._setValueByContasDef(this.campo, response.body.value);
      const modelo22: IJsonModelo22 = (await this._modelo22Service.evaluateModelo22(this.modelo22)).body;
      super.close(modelo22);
    });
    return this.promise;
  }

  public nContaDeChanged(event: string): void {
    this.nContaPocDe.nConta = event;
    this.nContaPocAte = {nConta: event};
  }

  public addLine(): Promise<void> {
    if (this.contasDef.findIndex((value: IJsonModelo22ContasDef) => value.nContaDe === this.nContaPocDe.nConta) !== -1) {
      this._plAlertService.error('modelo22.messages.containicialAlredyExists');
      return Promise.resolve();
    }
    this.promise = this._modelo22Service
      .calcNewLineCamposContasDef(this.nContaPocDe.nConta, this.nContaPocAte.nConta, this.perioAte, this.sinal)
      .then((response: HttpResponse<IJsonModelo22ContasDef>) => {
        this.contasDef.push(response.body);
        this.nContaPocDe = {nConta: ''};
        this.nContaPocAte = {nConta: 'ZZZZ'};
        this.perioAte = '121';
        this.sinal = true;
        this.dataGridDefinition.dataSource = this.contasDef;
      });
    return this.promise;
  }

  public removeLine(item: IJsonModelo22ContasDef): void {
    const index = this.contasDef.findIndex((value: IJsonModelo22ContasDef) => item.nContaDe === value.nContaDe);
    if (index !== -1) {
      this.contasDef.splice(index, 1);
    }
    this.dataGridDefinition.dataSource = this.contasDef;
  }

  private _setValueByContasDef(campo: string, result: number): void {
    const value: number = result === 0 ? undefined : result;
    const quadro07Fields = [
      '701',
      '702',
      '703',
      '805',
      '704',
      '705',
      '806',
      '706',
      '707',
      '709',
      '710',
      '711',
      '782',
      '712',
      '713',
      '714',
      '715',
      '717',
      '721',
      '724',
      '725',
      '716',
      '731',
      '726',
      '783',
      '728',
      '727',
      '729',
      '730',
      '732',
      '733',
      '784',
      '734',
      '735',
      '780',
      '785',
      '802',
      '746',
      '737',
      '786',
      '718',
      '719',
      '720',
      '722',
      '723',
      '736',
      '738',
      '739',
      '740',
      '741',
      '742',
      '743',
      '787',
      '744',
      '745',
      '747',
      '748',
      '749',
      '788',
      '750',
      '789',
      '790',
      '751',
      '803',
      '779',
      '797',
      '799',
      '804',
      '752',
      '754',
      '755',
      '756',
      '757',
      '791',
      '758',
      '759',
      '760',
      '761',
      '762',
      '763',
      '781',
      '764',
      '765',
      '766',
      '792',
      '767',
      '768',
      '769',
      '770',
      '793',
      '771',
      '794',
      '772',
      '795',
      '773',
      '796',
      '774',
      '800',
      '801',
      '798',
      '775'
    ];
    const quadro10Fields = ['347A', '347B', '349', '350', '370', '373', '353', '375', '355', '470', '356', '371', '359', '360', '374', '363', '372', '379', '365', '364', '366', '369'];
    const quadro11Fields = ['410', '411', '416'];

    if (quadro07Fields.includes(campo)) {
      this.modelo22.rosto.quadro07[`campo${campo}`] = value;
    } else if (quadro10Fields.includes(campo)) {
      this.modelo22.rosto.quadro10[`campo${campo}`] = value;
    } else if (quadro11Fields.includes(campo)) {
      this.modelo22.rosto.quadro11[`campo${campo}`] = value;
    }
  }
}
