<pl-form>
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title" [translate]="'docfas.modal.new.title'"></h4>
  </div>

  <div class="modal-body">
    <div class="form-group">
      <div class="card">
        <div class="card-header">
          <span [translate]="'docfas.modal.new.cardBase'"></span>
        </div>
        <div class="card-body">
          <pl-group>
            <label [translate]="'docfas.modal.new.nDocfaBase'"></label>
            <edit>
              <entity-autocomplete
                attrName="nDocfaBase"
                entity="docfas"
                [model]="docFas"
                (modelChange)="onDocFasBaseChange($event)"
                [filter]="filterNDocfaBase"
                [output]="docfasOutput"
                plAutoFocus></entity-autocomplete>
            </edit>
          </pl-group>
        </div>
      </div>
      <br />
      <div class="card">
        <div class="card-header">
          <span [translate]="'docfas.modal.new.cardNew'"></span>
          <span *ngIf="showSAFTTipoDoc"> ({{ docFas.codigoSAFTTipoDoc }}-{{ docFas.descricaoSAFTTipoDoc }})</span>
          <span *ngIf="isAutoFaturacao"
            >&nbsp;<small
              ><strong>(<span [translate]="getAutoFacturacaoTitle()"></span>)</strong></small
            ></span
          >
        </div>
        <div class="card-body">
          <pl-group>
            <label [translate]="'docfas.fields.nDocFa'"></label>
            <edit>
              <pl-edit attrName="nDocFa" type="integer" [(model)]="newDocFas.nDocfasNew" [properties]="{placeholder: 'docfas.modal.new.nDocFaPlaceHolder'}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'docfas.fields.nome'"></label>
            <edit>
              <pl-edit attrName="nome" type="string" [(model)]="newDocFas.nomeNew"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label [translate]="'docfas.fields.descricao'"></label>
            <edit>
              <pl-edit attrName="descricao" type="string" [(model)]="newDocFas.descricaoNew"></pl-edit>
            </edit>
          </pl-group>
          <pl-group *ngIf="isAutoFaturacao">
            <label [translate]="'docfas.modal.new.saftTipoDoc'"></label>
            <edit>
              <pl-edit-select [attrName]="'saftTipoDoc'" [(model)]="newDocFas.saftTipoDoc" [properties]="{select: {list: saftTipoDocList}, disallowClear: true}"></pl-edit-select>
            </edit>
          </pl-group>
          <pl-group *ngIf="isVisibleEntregaEfetivaSobreDocGlobal">
            <edit>
              <pl-edit
                (modelChange)="onEntregaEfetivaSobreDocGlobalChange($event)"
                type="checkbox"
                attrName="entregaEfetivaSobreDocGlobal"
                [model]="entregaEfetivaSobreDocGlobal"
                [properties]="{label: 'docfas.fields.entregaEfetivaSobreDocGlobal'}"></pl-edit>
            </edit>
          </pl-group>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer" plNavigation>
    <button type="button" class="btn btn-primary btn-sm" (click)="close()" data-focus><i class="fa fa-fw fa-save"></i>&nbsp;<span [translate]="'global.btn.save'"></span></button>
    <button type="button" class="btn btn-light btn-sm action-dismiss" (click)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></button>
  </div>
</pl-form>
