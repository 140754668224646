export enum EReport {
  Recibos = 'reports_recibos',
  Pagamentos = 'reports_pagamentos',
  Documentos = 'reports_documentos',
  BalancetesAnalitica = 'reports_balancetes_analitica',
  BalancetesNormal = 'reports_balancetes_normal',
  BalancetesComparativo = 'reports_balancetes_comparativo',
  Extratos = 'reports_extratos',
  ExtratosPorConta = 'reports_extratos_por_conta',
  Impdecpe = 'reports_impdecpe',
  MovimentosEmAberto = 'reports_movimentosemaberto',
  Svat = 'reports_svat',
  RecibosSal = 'reports_recibossal',
  Ativos = 'reports_ativos',
  AtivosMapasOficiais = 'reports_ativos_mapas_oficiais',
  ConciliacaoBancaria = 'reports_conciliacao_bancaria',
  RHProceSegSocialMapacx = 'reports_rh_proce_seg_social_mapacx',
  RHProceSegSocialMapacxRe = 'reports_rh_proce_seg_social_mapacx_re',
  RHProceDMRATMapa = 'reports_rh_proce_dmr_at_mapa',
  ProcSeguFolhaFeriasMapa = 'reports_proc_segu_folha_ferias_mapa',
  DocsComerciaisEstatisticas = 'reports_docs_comerciais_estatisticas',
  DocsComerciaisEstatisticasDoc = 'reports_docs_comerciais_estatisticas_doc',
  RecibosVencimento = 'reports_recibos_vencimento',
  RHFolFer = 'reports_rh_folfer',
  Inventarios = 'reports_inventarios',
  RHImpVenct = 'reports_rh_imp_venct',
  ImpCtrIrs = 'reports_imp_ctr_irs',
  ImpFFERPOC = 'reports_imp_fferpoc',
  ImpENCAR = 'reports_imp_encar',
  ImpAvisosCobranca = 'reports_imp_avisos_cobranca',
  AvisosCobranca = 'reports_avisos_cobranca',
  ImpEventosRH = 'reports_eventos_rh',
  ImpDadosEmp = 'reports_imp_dados_emp',
  DemFinSNC = 'reports_dem_fin_snc',
  Contratos = 'reports_contratos',
  ImpDe119 = 'reports_impde119',
  LSindic = 'reports_mapas_sindicato',
  Encomendas = 'reports_encomendas',
  AcrescimosEncargosFerias = 'reports_acrescimos_encargos_ferias',
  Adiantamentos = 'reports_adiantamenos',
  TributAutonomaAnalise = 'reports_tribut_autonoma_analise',
  DDSEPA = 'reports_ddsepa',
  DDSEPACLIENTE = 'reports_ddsepa_cliente',
  LBanc = 'reports_lbanc',
  AnaliseMargemLucro = 'reports_anlaise_margem_lucro',
  ExtratosDT = 'reports_extratosDT',
  ExtratosArtigos = 'reports_extratos_artigos'
}
