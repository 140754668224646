<div class="rhcalculorendimentoliquido entity-detail-form" [plPromise]="promise">
  <pl-nav-wizard
    [onFinalize]="fnFinalize"
    [definition]="definition"
    [beforeStepChange]="fnBeforeStepChange"
    [callback]="plWizardCallback"
    (evtStepChange)="stepChanged($event)"
    (modelChange)="onStepChange($event)">
    <pl-nav-wizard-step caption="rhcalculorendimentoliquido.steps.processos" icon="fa-home" [stepId]="'processos'">
      <div *plNavWizardStepContent>
        <h4 class="page-header"><label [translate]="'rhcalculorendimentoliquido.titles.processamentos'"></label></h4>

        <dx-data-grid
          [cgDxDataGrid]="definitionProcessos"
          cgDxDataGridInstanceName="rendimentoLiqProcessos"
          [dataSource]="rhCalculaRendimentoLiquidoProcessamentoList"
          (onRowClick)="onRowClickProcessos($event)"
          (onRowDblClick)="onRowDblClickProcessos($event)">
          <div *dxTemplate="let item of 'toolbarTemplateProce'">
            <div class="d-flex align-items-center">
              <span class="me-2" [translate]="'global.text.year'"></span>
              <pl-edit-select [model]="year" (modelChange)="yearChanged($event)" [properties]="{select: {list: yearsSource}, disallowClear: true}"></pl-edit-select>
            </div>
          </div>
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step caption="rhcalculorendimentoliquido.steps.empregados" icon="fa-th-list" [stepId]="'empregados'">
      <div *plNavWizardStepContent>
        <h4 class="page-header"><label [translate]="'rhcalculorendimentoliquido.titles.empregados'"></label></h4>

        <dx-data-grid
          [cgDxDataGrid]="definitionEmpregados"
          cgDxDataGridInstanceName="rendimentoLiqEmpregados"
          [dataSource]="rhCalculaRendimentoLiquidoEmpProceList"
          (onRowClick)="onRowClickEmpregados($event)"
          (onRowDblClick)="onRowDblClickEmpregados($event)">
        </dx-data-grid>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step caption="rhcalculorendimentoliquido.steps.calcular" icon="fa-calculator" [stepId]="'calcular'" [captionBtnFinalize]="'rhcalculorendimentoliquido.btn.finalizar'">
      <div *plNavWizardStepContent>
        <h4 class="page-header">
          <label>{{ identificacaoEmp }}</label>
        </h4>
        <div class="row">
          <div class="col-sm-6">
            <pl-form>
              <pl-group>
                <label [translate]="'rhcalculorendimentoliquido.fields.valorLiquido'"></label>
                <edit>
                  <pl-edit type="number" attrName="valorLiquido" [(model)]="rhCalculaRendimentoLiquido.valorLiquido"></pl-edit>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'rhcalculorendimentoliquido.fields.nCodABDESC'"></label>
                <edit>
                  <entity-autocomplete
                    entity="abonos"
                    attrName="nCodABDESC"
                    [selectedKey]="rhCalculaRendimentoLiquido.nCodAbdesc"
                    (selectedKeyChange)="rhCalculaRendimentoLiquido.nCodAbdesc = $event"
                    [fieldsMap]="{nCodABDESC: 'nCodAbdesc'}"
                    [rowTemplate]="outputAbdes"
                    [output]="outputAbdes"
                    outputKey="nCodABDESC">
                  </entity-autocomplete>
                </edit>
              </pl-group>

              <pl-group>
                <label [translate]="'rhcalculorendimentoliquido.fields.adicionaRecolha'"></label>
                <edit>
                  <pl-edit type="boolean" attrName="adicionaRecolha" [(model)]="rhCalculaRendimentoLiquido.adicionaRecolha"></pl-edit>
                </edit>
              </pl-group>
            </pl-form>
          </div>
          <div class="col-sm-6">
            <cg-pdf-viewer [pdfSrc]="pdfUrl"></cg-pdf-viewer>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
