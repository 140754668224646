import {ETipoComunicacaoEFatura} from '../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {TDate} from '../../../common/dates';

export enum EFaturaEstadoDocumento {
  NORMAL = 'efatura.docState.normal',
  ANULADO = 'efatura.docState.nullified',
  FATURADO = 'efatura.docState.billed'
}

export enum EFaturaDocumentoComunicado {
  NAO,
  SIM
}

export interface IJsonEFaturaErrosAvisos {
  numero: number;
  descricao: string;
}

export interface IJsonEFaturaResumoFicheiro {
  numeroFacturas: number;
  totalCredito: number;
  totalDebito: number;
  total: number;
  numeroFacturasPorComunicar: number;
  totalCreditoPorComunicar: number;
  totalDebitoPorComunicar: number;
  totalPorComunicar: number;
  numeroFacturasComunicados: number;
  totalCreditoComunicados: number;
  totalDebitoComunicados: number;
  totalComunicados: number;
}

export interface IJsonEFaturaResumoPorTipoDocumento {
  nDocfa: number;
  nome: string;
  valorTributavel: number;
  valorDoImposto: number;
  montanteTotal: number;
  totalDebito: number;
  totalCredito: number;
  totalAnulado: number;
}

export interface IJsonEFaturaResumoPorIVA {
  codIVA: number;
  nome: string;
  taxa: number;
  valorLiquido: number;
  valorIVA: number;
  montanteTotal: number;
}

export interface IJsonEFaturaDocumento {
  nDocNormalizado: string;
  tipoSAFT: string;
  faccbId: number;
  nDocfa: number;
  nNumer: number;
  nDocumento: number;
  nDocExterno: string;
  dataDoc: TDate;
  nConta: string;
  nomeClifo: string;
  nif: string;
  nomePais: string;
  erroNumero: number;
  erroDescricao: string;
  anulado: boolean;
  posFacturado: boolean;
  jaComunicado: boolean;
  valorTributavel: number;
  valorIVA: number;
  valorTotal: number;
  valorParaSAFT: number;
  totalDebito: number;
  totalCredito: number;
  estado: EFaturaEstadoDocumento;
  comunicado: EFaturaDocumentoComunicado;
  tipoComunicacao?: ETipoComunicacaoEFatura;
  estadoStr?: string;
  comunicadoStr?: string;
}

export interface IJsonEFaturaAnos {
  ano: number;
}

export interface IJsonEFaturaMeses {
  mes: number;
  fileName: string;
}

export interface IJsonEFaturaInexistencia {
  showComunicar: boolean;
  showAnular: boolean;
  msg: string;
}

export interface IJsonEFatura {
  documentos: Array<IJsonEFaturaDocumento>;
  erros: Array<IJsonEFaturaErrosAvisos>;
  avisos: Array<IJsonEFaturaErrosAvisos>;
  resumoFicheiro: IJsonEFaturaResumoFicheiro;
  resumoPorTipoDocumento: Array<IJsonEFaturaResumoPorTipoDocumento>;
  resumoPorIVA: Array<IJsonEFaturaResumoPorIVA>;
  inexistencia: IJsonEFaturaInexistencia;
}

export interface IJsonEFaturaConfiguracao {
  temFacturacaoElectronica: boolean;
  atUsername: string;
  atPassword: string;
  notificaDia10SeNaoEnviado: boolean;
  notificaDia10Dia: number;
  notificaDia10Email: string;
}

export interface IJsonEFaturaLogItem {
  eFaturaLOGid: string;
  operacaoStr: string;
  dataCriacaoReg: TDate;
  horaCriacaoReg: string;
  nDocfa: number;
  nNumer: number;
  nDocumento: number;
  nDocfaAsString: string;
  grupoPOS: number;
  nPEDIDODraft: number;
  tipoLogAsString: string;
  returnCode: number;
  descricao: string;
}
