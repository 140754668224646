import {IGestaoComunicacoesEstado} from './gestaoComunicacoes.module.interface';

export enum EGestaoComunicacoesEstadoTipo {
  None,
  SistemaInicial,
  SistemaFinal,
  Utilizador
}

export interface IJsonGestaoComunicacoesEstado {
  comEstadoId: string;
  nome: string;
  tipo: EGestaoComunicacoesEstadoTipo;
  cor: string;
  todasEmpresas: boolean;
  nEmpresa: string;
}

export interface IJsonGestaoComunicacoesEstadoOrdem extends IGestaoComunicacoesEstado {
  ordem: number;
}
