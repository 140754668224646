import {IDataSource} from '../../components/datasource/input/datasource.input.component.interface';
import {DATA_SOURCE_NAME_PERIODICIDADE, EPeriodicidade} from './periodicidade.datasource.interface';
import {deepFreeze} from 'pl-comps-angular';

export const DATA_SOURCE_PERIODICIDADE: IDataSource<number> = deepFreeze({
  name: DATA_SOURCE_NAME_PERIODICIDADE,
  autocomplete: {
    valueExpr: 'value',
    rowTemplate: '{{name}}',
    output: 'name'
  },
  data: [
    {value: EPeriodicidade.None, name: ''},
    {value: EPeriodicidade.Diaria, name: 'periocidadeContratoTipo.data.diario'},
    {value: EPeriodicidade.Semanal, name: 'periocidadeContratoTipo.data.semanal'},
    {value: EPeriodicidade.Quinzenal, name: 'periocidadeContratoTipo.data.quinzenal'},
    {value: EPeriodicidade.Mensal, name: 'periocidadeContratoTipo.data.mensal'},
    {value: EPeriodicidade.Bimensal, name: 'periocidadeContratoTipo.data.bimensal'},
    {value: EPeriodicidade.Trimestral, name: 'periocidadeContratoTipo.data.trimestral'},
    {value: EPeriodicidade.Semestral, name: 'periocidadeContratoTipo.data.semestral'},
    {value: EPeriodicidade.Anual, name: 'periocidadeContratoTipo.data.anual'}
  ]
});
