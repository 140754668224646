import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_CONCELHOS, IConcelhosEntityService} from '../../../../../../entities/concelhos/concelhos.entity.interface';
import {EntityServiceBuilder} from '../../../../../../services/entity/entity.service.builder';
import {ETiposDadosDerrama} from '../../../modelo22.module.interface';
import {IJsonConcelho, IJsonDadosDerrama, IJsonDerramaCondicoesTaxa} from '../../../../../../entities/concelhos/jsonConcelho.entity.interface';
import {IJsonModelo22, IJsonModelo22CompDerrama} from '../../../jsonModelo22.module.interface';
import {Modelo22Service} from '../../../modelo22.module.service';
import {IDevExpressDataGrid} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnCellClick} from '../../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {TranslateService} from '@ngx-translate/core';
import {PlAlertService} from 'pl-comps-angular';

@Component({
  selector: 'modal-modelo22-derrama',
  templateUrl: './modelo22.derrama.modal.component.html'
})
export class Modelo22DerramaModalComponent extends CGModalComponent<IJsonModelo22> implements OnInit {
  @Input() public derrama: IJsonModelo22CompDerrama;
  @Input() public modelo22: IJsonModelo22;
  @Input() public fromConcelhos: boolean;

  public readonly definitionCondicoes: IDevExpressDataGrid;

  public dadosDerrama: IJsonDadosDerrama;
  public hasPostosTrabalhoCriados: boolean;
  public hasVolumeNegocios: boolean;
  public hasInvestimentoNoAno: boolean;
  public hasTemSedeNoConcelho: boolean;
  public hasAnoFixacaoDaSede: boolean;
  public hasMicroPequenaMediaEmpresa: boolean;
  public hasAcrescimoTrabalhadores: boolean;
  public hasPostosTrabalhoCriados2019: boolean;
  public hasPostosTrabalhoCriados2020: boolean;
  public hasPostosTrabalhoCriados2021: boolean;
  public hasPostosTrabalhoCriados2022: boolean;
  public hasPostosTrabalhoCriados2023: boolean;
  public hasPostosTrabalhoCriados2024: boolean;
  public isManualSelection: boolean;
  public concelhoService: IConcelhosEntityService;
  public selectedRowKeys: Array<IJsonDerramaCondicoesTaxa>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: TranslateService
  ) {
    super(_injector);
    this.hasPostosTrabalhoCriados = false;
    this.hasVolumeNegocios = false;
    this.hasInvestimentoNoAno = false;
    this.hasTemSedeNoConcelho = false;
    this.hasAnoFixacaoDaSede = false;
    this.hasMicroPequenaMediaEmpresa = false;
    this.hasAcrescimoTrabalhadores = false;
    this.hasPostosTrabalhoCriados2019 = false;
    this.hasPostosTrabalhoCriados2020 = false;
    this.hasPostosTrabalhoCriados2021 = false;
    this.hasPostosTrabalhoCriados2022 = false;
    this.hasPostosTrabalhoCriados2023 = false;
    this.hasPostosTrabalhoCriados2024 = false;
    this.fromConcelhos = false;
    this.isManualSelection = false;
    this.concelhoService = this._entityServiceBuilder.build<IJsonConcelho, IConcelhosEntityService>(ENTITY_NAME_CONCELHOS);
    this.selectedRowKeys = [];
    this.definitionCondicoes = {
      columns: [
        {dataField: 'tipoTaxaSTR', dataType: 'string', caption: 'concelhos.modal.fields.tipoTaxaSTR'},
        {dataField: 'taxa', dataType: 'number', caption: 'concelhos.modal.fields.taxa'},
        {dataField: 'ambito', dataType: 'string', caption: 'concelhos.modal.fields.ambito'}
      ],
      searchPanel: {visible: false},
      grouping: {contextMenuEnabled: false},
      columnFixing: {enabled: false},
      export: {enabled: false},
      columnChooser: {enabled: false},
      toolbar: {
        visible: false
      },
      selection: {mode: 'multiple', allowSelectAll: false, showCheckBoxesMode: 'always'},
      dataSource: []
    };
    this.dadosDerrama = {
      ano: 2000,
      condicoesTaxaList: [],
      nomeConcelho: undefined,
      codConcelho: 0,
      dadosIntroduzidosList: []
    };
  }

  public ngOnInit(): void {
    this._evaluateTiposDerrama();
  }

  public onCellClick(event: IDevExpressDataGridEventOnCellClick<IJsonDerramaCondicoesTaxa>): void {
    if (event.rowType !== 'data') {
      return;
    }
    this.selectedRowKeys = [event.data];
  }

  public async close(): Promise<void> {
    const concelhosService: IConcelhosEntityService = this._entityServiceBuilder.build<IJsonConcelho, IConcelhosEntityService>(ENTITY_NAME_CONCELHOS);
    if (this.isManualSelection) {
      if (!this.selectedRowKeys?.length) {
        this._plAlertService.error(this._plTranslateService.instant('concelhos.saveNotPossible'));
        return;
      }

      for (const condicoesTaxa of this.dadosDerrama.condicoesTaxaList) {
        condicoesTaxa.condicaoCumprida = condicoesTaxa.taxa === this.selectedRowKeys[0].taxa && condicoesTaxa.ambito === this.selectedRowKeys[0].ambito;
      }

      const index = this.dadosDerrama.condicoesTaxaList.findIndex(
        (condicoesTaxa: IJsonDerramaCondicoesTaxa) => condicoesTaxa.taxa === this.selectedRowKeys[0].taxa && condicoesTaxa.ambito === this.selectedRowKeys[0].ambito
      );
      if (index !== -1) {
        this.dadosDerrama.dadosIntroduzidosList[0].valor = index.toString();
      }
      await concelhosService.postDadosDerrama(this.dadosDerrama);
    }
    let modelo22: IJsonModelo22;
    if (this.fromConcelhos) {
      await concelhosService.postCalculoDerrama(this.derrama);
    } else {
      await this._modelo22Service.saveCalculoDerrama(this.derrama);
      modelo22 = (await this._modelo22Service.evaluateModelo22(this.modelo22)).body;
    }
    super.close(modelo22);
  }

  private _evaluateTiposDerrama(): void {
    for (const tipo of this.derrama.listaTipos) {
      switch (tipo) {
        case ETiposDadosDerrama.PostosTrabalhoCriados:
          this.hasPostosTrabalhoCriados = true;
          break;
        case ETiposDadosDerrama.VolumeNegocios:
          this.hasVolumeNegocios = true;
          break;
        case ETiposDadosDerrama.InvestimentoNoAno:
          this.hasInvestimentoNoAno = true;
          break;
        case ETiposDadosDerrama.TemSedeNoConcelho:
          this.hasTemSedeNoConcelho = true;
          break;
        case ETiposDadosDerrama.AnoFixacaoDaSede:
          this.hasAnoFixacaoDaSede = true;
          break;
        case ETiposDadosDerrama.MicroPequenaMediaEmpresa:
          this.hasMicroPequenaMediaEmpresa = true;
          break;
        case ETiposDadosDerrama.AcrescimoTrabalhadores:
          this.hasAcrescimoTrabalhadores = true;
          break;
        case ETiposDadosDerrama.PostosTrabalhoCriados2019:
          this.hasPostosTrabalhoCriados2019 = true;
          break;
        case ETiposDadosDerrama.PostosTrabalhoCriados2020:
          this.hasPostosTrabalhoCriados2020 = true;
          break;
        case ETiposDadosDerrama.PostosTrabalhoCriados2021:
          this.hasPostosTrabalhoCriados2021 = true;
          break;
        case ETiposDadosDerrama.PostosTrabalhoCriados2022:
          this.hasPostosTrabalhoCriados2022 = true;
          break;
        case ETiposDadosDerrama.PostosTrabalhoCriados2023:
          this.hasPostosTrabalhoCriados2023 = true;
          break;
        case ETiposDadosDerrama.PostosTrabalhoCriados2024:
          this.hasPostosTrabalhoCriados2024 = true;
          break;
        case ETiposDadosDerrama.SelecaoManual:
          this.isManualSelection = true;
          this._getCondicoesTaxaList();
          break;
      }
    }
  }

  private _getCondicoesTaxaList(): void {
    this.concelhoService.getDadosDerrama(this.derrama.codConcelho, this.derrama.ano).then((responseDerrama: HttpResponse<IJsonDadosDerrama>) => {
      this.dadosDerrama = responseDerrama.body;
      if (!this.dadosDerrama.dadosIntroduzidosList?.length) {
        this.dadosDerrama.dadosIntroduzidosList = [{tipoDados: ETiposDadosDerrama.SelecaoManual, tipoDadosSTR: '', valor: ''}];
      }
      const index: number = this.dadosDerrama.condicoesTaxaList.findIndex((item: IJsonDerramaCondicoesTaxa) => item.condicaoCumprida === true);
      if (index !== -1) {
        this.selectedRowKeys = [this.dadosDerrama.condicoesTaxaList[index]];
      }
    });
  }
}
