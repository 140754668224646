import {NgModule} from '@angular/core';
import {CGCTemplateDirective} from './template.directive';

const DECLARATIONS = [CGCTemplateDirective];

@NgModule({
  imports: DECLARATIONS,
  exports: DECLARATIONS
})
export class CGCTemplateModule {}
