<div class="meiosPagamento">
  <entity-detail
    entityName="meiosPagamento"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [properties]="{readonly: type === stateTypes.DETAIL, orientation: formOrientation}">
        <pl-group>
          <label [translate]="'meiosPagamento.fields.nCaixa'"></label>
          <edit>
            <pl-edit
              type="integer"
              attrName="nCaixa"
              [(model)]="model.nCaixa"
              [properties]="{placeholder: 'meiosPagamento.fields.nCaixaPlaceholder', validators: {required: false}}"
              plAutoFocus></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'meiosPagamento.fields.nome'"></label>
          <edit>
            <pl-edit type="text" attrName="nome" [(model)]="model.nome" [properties]="{validators: {required: {value: true}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'meiosPagamento.fields.tipoMovimento'"></label>
          <edit>
            <pl-edit
              type="radiogroup"
              attrName="tipoMovimento"
              [model]="model.tipoMovimento"
              (modelChange)="onTipoMovimentoChange($event)"
              [properties]="{groupItems: tipoPagamento, disabled: type !== stateTypes.DETAIL && !canEdit, validators: {required: {value: true}}}">
            </pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'meiosPagamento.fields.tiposPagamento'"></label>
          <edit>
            <div class="d-flex flex-wrap gap-3">
              <div class="flex-column">
                <pl-group>
                  <pl-edit
                    type="checkbox"
                    attrName="comercialCompras"
                    [(model)]="caixaAtivaOperacao.comercialCompras"
                    [properties]="{label: 'meiosPagamento.data.caoComercialCompras', disabled: type === stateTypes.DETAIL}">
                  </pl-edit>
                  <pl-edit
                    type="checkbox"
                    attrName="comercialVendas"
                    [(model)]="caixaAtivaOperacao.comercialVendas"
                    [properties]="{label: 'meiosPagamento.data.caoComercialVendas', disabled: type === stateTypes.DETAIL}">
                  </pl-edit>
                  <pl-edit
                    type="checkbox"
                    attrName="financeiroRecibos"
                    [(model)]="caixaAtivaOperacao.financeiroRecibos"
                    [properties]="{label: 'meiosPagamento.data.caoFinanceiroRecibos', disabled: type === stateTypes.DETAIL}">
                  </pl-edit>
                </pl-group>
              </div>

              <div class="flex-column">
                <pl-group>
                  <pl-edit
                    type="checkbox"
                    attrName="financeiroPagamentos"
                    [(model)]="caixaAtivaOperacao.financeiroPagamentos"
                    [properties]="{label: 'meiosPagamento.data.caoFinanceiroPagamentos', disabled: type === stateTypes.DETAIL}"></pl-edit>
                  <pl-edit
                    type="checkbox"
                    attrName="financeiroTransferencias"
                    [(model)]="caixaAtivaOperacao.financeiroTransferencias"
                    [properties]="{label: 'meiosPagamento.data.caoFinanceiroTransferencias', disabled: type === stateTypes.DETAIL}"></pl-edit>
                  <pl-edit
                    type="checkbox"
                    attrName="financeiroRendas"
                    [(model)]="caixaAtivaOperacao.financeiroRendas"
                    [properties]="{label: 'meiosPagamento.data.caoFinanceiroRendas', disabled: type === stateTypes.DETAIL}">
                  </pl-edit>
                </pl-group>
              </div>

              <div class="flex-column">
                <pl-group>
                  <pl-edit
                    type="checkbox"
                    attrName="rHRemuneracao"
                    [(model)]="caixaAtivaOperacao.rHRemuneracao"
                    [properties]="{label: 'meiosPagamento.data.caoRHRemuneracao', disabled: type === stateTypes.DETAIL}">
                  </pl-edit>
                  <pl-edit
                    type="checkbox"
                    attrName="rHTicketRefeicao"
                    [(model)]="caixaAtivaOperacao.rHTicketRefeicao"
                    [properties]="{label: 'meiosPagamento.data.caoRHTicketRefeicao', disabled: type === stateTypes.DETAIL}">
                  </pl-edit>
                </pl-group>
              </div>
            </div>
          </edit>
        </pl-group>
        <pl-group>
          <label [translate]="'meiosPagamento.fields.tipo'"></label>
          <edit>
            <pl-edit type="radiogroup" attrName="tipo" [model]="model.tipo" (modelChange)="onTipoChanged($event)" [properties]="{groupItems: tipoMPaga, validators: {required: {value: true}}}">
            </pl-edit>
          </edit>
        </pl-group>

        <pl-group *ngIf="canSee || canEdit">
          <label [translate]="'meiosPagamento.fields.nConta'"></label>
          <edit>
            <entity-autocomplete
              entity="pocs"
              attrName="nConta"
              [model]="model"
              [selectedKey]="model.nConta"
              (selectedKeyChange)="model.nConta = $event"
              [fieldsMap]="{nConta: 'nConta', nome: 'nomeConta'}"
              [properties]="{disabled: type !== stateTypes.DETAIL && !canEdit, validators: {required: {value: true}}}"
              (modelChange)="onContaChange($event)"
              [filter]="pocFilter">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'meiosPagamento.fields.nib'"></label>
          <edit>
            <pl-edit
              type="text"
              attrName="nib"
              [model]="model.nib"
              (modelChange)="onIbanChanged($event)"
              [properties]="{validators: {required: {value: requiresSwift}}, modelOptions: {debounce: 500}}">
            </pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'meiosPagamento.fields.swift'"></label>
          <edit>
            <pl-edit type="text" attrName="nib" [(model)]="model.swift" [properties]="{validators: {required: {value: requiresSwift}}}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'meiosPagamento.table.actions.porDefeitoPara'"></label>
          <edit>
            <div class="meios-pagamento-boolean-inputs">
              <div class="meios-pagamento-boolean-input" *ngIf="model.tipoMovimento !== tiposMeiosPagamento.Pagamento">
                <pl-edit
                  type="boolean"
                  attrName="porDefeitoRecibos"
                  [model]="porDefeitoRecibos"
                  (modelChange)="onPorDefeitoRecibosChanged($event)"
                  [properties]="{
                    label: 'meiosPagamento.table.actions.recibos',
                    readonly: type === stateTypes.DETAIL,
                    events: {beforeChange: fnBeforeChangeRecibosPorDefeito}
                  }">
                </pl-edit>
              </div>

              <div class="meios-pagamento-boolean-input" *ngIf="model.tipoMovimento !== tiposMeiosPagamento.Recebimento">
                <pl-edit
                  type="boolean"
                  attrName="porDefeitoPagamentos"
                  [model]="porDefeitoPagamentos"
                  (modelChange)="onPorDefeitoPagamentosChanged($event)"
                  [properties]="{
                    label: 'meiosPagamento.table.actions.pagamentos',
                    readonly: type === stateTypes.DETAIL,
                    events: {beforeChange: fnBeforeChangePagamentosPorDefeito}
                  }">
                </pl-edit>
              </div>
            </div>
          </edit>
        </pl-group>
      </pl-form>
    </div>
  </entity-detail>
</div>
