import {Component, Injector, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../../../components/cg/modal/cgmodal.component';
import {IJsonNumber} from '../../../../../../../../../common/interfaces/json';
import {IJsonErpUser} from '../../../../../../../../services/account/jsonUserApi.interface';
import {Modelo22Service} from '../../../../../modelo22.module.service';
import {PlAlertService} from 'pl-comps-angular';
import {AuthService} from '../../../../../../../../services/auth/auth.service';
import {EmpresasService} from '../../../../../../../../services/empresas/empresas.service';
import {IJsonEmpresaErp} from '../../../../../../../../interfaces/jsonEmpresa.interface';

@Component({
  selector: 'modal-modelo22-contasdefcopy',
  templateUrl: './modelo22.contasDefCopy.modal.component.html'
})
export class Modelo22ContasDefCopyModalComponent extends CGModalComponent<IJsonNumber> implements OnInit {
  public readonly msEmpresasTemplate: string;

  public empresasSource: Array<IJsonEmpresaErp>;
  public empresasSel: Array<IJsonEmpresaErp>;
  public temEmpresasSelecionadas: boolean;
  public apagacontasempresadestino: boolean;
  public promise: Promise<void>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _modelo22Service: Modelo22Service,
    private readonly _authService: AuthService,
    private readonly _empresasService: EmpresasService
  ) {
    super(_injector);
    this.msEmpresasTemplate = '{{nempresa}} - {{nome}}';
    this.empresasSel = [];
    this.temEmpresasSelecionadas = false;
    this.apagacontasempresadestino = true;
  }

  public ngOnInit(): void {
    this._loadEmpresas();
  }

  public close(): Promise<void> {
    const empresas = [];
    this.empresasSel.forEach((element) => {
      empresas.push(element.nempresa);
    });
    return this._modelo22Service.copiaContasEmpresas(this.apagacontasempresadestino, empresas).then(() => {
      super.close();
      this._plAlertService.success('modelo22.messages.contasdef.contascopiadascomsucesso');
    });
  }

  public fnEmpresasChanged(): void {
    this.temEmpresasSelecionadas = this.empresasSel.length > 0;
  }

  private _loadEmpresas(): void {
    this.promise = (async () => {
      const session = await this._authService.identity();
      const erps: Array<string> = session.erps.map((erp: IJsonErpUser) => `NEMPRESA=${erp.nEmpresa}`);
      const filter = erps.join('|');
      const response = await this._empresasService.query({pesquisa: filter});
      this.empresasSource = response.body.list.filter((empresa: IJsonEmpresaErp) => {
        return empresa.nempresa !== session.erp.nEmpresa;
      });
    })();
  }
}
