<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h5 class="modal-title" [translate]="'components.contabilidade.relateddocs.relatedDocs'"></h5>
</div>

<div class="modal-body">
  <dx-data-grid [cgDxDataGrid]="relatedDocsDatagrid" cgDxDataGridInstanceName="relatedDocs" [dataSource]="relatedDocs" (onCellClick)="onCellClick($event)">
    <div *dxTemplate="let item of 'detailTemplate'">
      <split-viewer-doccontab-digital [(docContabilidade)]="doc" [hideRightSide]="true" [showToolbar]="true" [maintenanceMode]="true" [readonlyDigital]="true" [highlightNLanc]="item.data.nLanc">
      </split-viewer-doccontab-digital>
    </div>
  </dx-data-grid>
</div>

<div class="modal-footer" plNavigation selector="pl-button">
  <pl-button klass="btn-light action-close" (evtClicked)="dismiss()"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.close'"></span></pl-button>
</div>
