import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {
  downloadStream,
  IPlNavWizardCallback,
  IPlNavWizardDefinition,
  IPlUploadCallback,
  IPlUploadFile,
  IPlUploadParams,
  isDefinedNotNull,
  isNumber,
  PlAlertService,
  PlTranslateService
} from 'pl-comps-angular';
import {EGenericImporterType, IGenericImporterExecutor, IJsonModelosImportacaoExecuteResult} from './generic.importer.component.interface';
import {focusElement} from '../../../common/utils/element.utils';
import {GenericImporterService} from './generic.importer.service';

const WIZARD_STEP_ID_DOWNLOAD_MODEL = 'downloadmodel';
const WIZARD_STEP_ID_UPLOAD_MODEL = 'uploadmodel';
const WIZARD_STEP_ID_ANALYZE_ERRORS = 'analyzeerrors';
const WIZARD_STEP_ID_SUCCESS = 'success';

@Component({
  selector: 'generic-importer',
  templateUrl: './generic.importer.component.html'
})
export class GenericImporterComponent implements OnChanges {
  @Input() public type: EGenericImporterType;
  @Output() public readonly evtSuccess: EventEmitter<void>;

  public readonly navWizardDefinition: IPlNavWizardDefinition;
  public readonly navWizardCallback: IPlNavWizardCallback;
  public readonly uploadCallback: IPlUploadCallback;
  public readonly wizardStepIdDownloadModel: string;
  public readonly wizardStepIdUploadModel: string;
  public readonly wizardStepIdAnalyzeErrors: string;
  public readonly wizardStepIdSuccess: string;
  public textType: string;
  public successCount: number;
  public errorCount: number;
  public uploadUrl: string;
  public uploadParams: IPlUploadParams;
  public uploadedFilledModel: boolean;
  public downloadedErrors: boolean;
  public uploadingFilledModel: boolean;
  public visibleStepAnalyzeErrors: boolean;
  public errorsFile: Blob;

  private readonly _element: HTMLElement;
  private _executor: IGenericImporterExecutor;
  private _errorsFileName: string;

  constructor(
    private readonly _elementRef: ElementRef<HTMLElement>,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _genericImporterService: GenericImporterService
  ) {
    this.evtSuccess = new EventEmitter<void>();
    this.navWizardDefinition = {
      items: [],
      force: false
    };
    this.navWizardCallback = {};
    this.uploadCallback = {};
    this.wizardStepIdDownloadModel = WIZARD_STEP_ID_DOWNLOAD_MODEL;
    this.wizardStepIdUploadModel = WIZARD_STEP_ID_UPLOAD_MODEL;
    this.wizardStepIdAnalyzeErrors = WIZARD_STEP_ID_ANALYZE_ERRORS;
    this.wizardStepIdSuccess = WIZARD_STEP_ID_SUCCESS;
    this._element = this._elementRef.nativeElement;
    this._reset();
  }

  public ngOnChanges({type}: SimpleChanges): void {
    if (type) {
      this._changedType(type.currentValue);
    }
  }

  public onUploadedFilledModel(uploadFile: IPlUploadFile): void {
    this.uploadingFilledModel = false;
    const response: HttpResponse<IJsonModelosImportacaoExecuteResult> = <HttpResponse<IJsonModelosImportacaoExecuteResult>>uploadFile.upload.response;
    this.successCount = response.body.successCount;
    this.errorCount = response.body.errorCount;
    if (response.body.errorCount === 0) {
      this.visibleStepAnalyzeErrors = false;
      this.uploadedFilledModel = true;
      this.navWizardCallback.setStep(this.wizardStepIdSuccess);
    } else {
      this._errorsFileName = response.body.fileName;
      if (response.body.fileWithErrors?.length) {
        this.errorsFile = new Blob([new Uint8Array(response.body.fileWithErrors)], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      }
      this.visibleStepAnalyzeErrors = true;
      setTimeout(() => {
        this.uploadedFilledModel = true;
        this.navWizardCallback.setStep(this.wizardStepIdAnalyzeErrors);
      });
    }
    this.uploadCallback.removeAllFiles();
  }

  public downloadErrors(): void {
    if (this.errorsFile) {
      this.downloadedErrors = true;
      downloadStream(this.errorsFile, this._errorsFileName);
    }
  }

  public retry(): void {
    this.uploadCallback.removeAllFiles();
    this.uploadedFilledModel = false;
    this.downloadedErrors = false;
    this.visibleStepAnalyzeErrors = false;
    this.errorsFile = undefined;
    this._errorsFileName = '';
    this.navWizardCallback.setStep(this.wizardStepIdUploadModel);
    const stepUpload = this.navWizardDefinition.items.find((step) => <string>step.stepId === this.wizardStepIdUploadModel);
    if (isDefinedNotNull(stepUpload)) {
      stepUpload.setIncomplete();
    }
  }

  public readonly fnValidatorStepUploadModel = (): boolean => this._validatorStepUploadModel();

  public readonly fnValidatorStepAnalyzeErrors = (): boolean => this._validatorStepAnalyzeErrors();

  public readonly fnDownloadModel = (): Promise<void> => this._downloadModel();

  public readonly fnFinalize = (): void => {
    this.evtSuccess.emit();
  };

  private _changedType(value: EGenericImporterType): void {
    this.type = value;
    this._reset();
    if (!isNumber(this.type)) {
      this.type = undefined;
    } else {
      this._executor = this._genericImporterService.getExecutor(this.type);
      this.textType = this._plTranslateService.translate(`components.genericImporter.types.${this.type}`);
      this.uploadUrl = this._executor.getExecuteImportacaoUrl();
      this.uploadParams = {tipomodelo: String(this.type)};
    }
  }

  private _reset(): void {
    this.successCount = 0;
    this.errorCount = 0;
    this.textType = undefined;
    this.uploadUrl = undefined;
    this.uploadParams = undefined;
    this.uploadedFilledModel = false;
    this.downloadedErrors = false;
    this.uploadingFilledModel = false;
    this.visibleStepAnalyzeErrors = false;
    this._executor = undefined;
    this.errorsFile = undefined;
    this._errorsFileName = '';
  }

  private _validatorStepUploadModel(): boolean {
    const valid: boolean = this.uploadedFilledModel;
    if (!valid) {
      this._plAlertService.error('components.genericImporter.errors.requiredUploadModel');
    }
    return valid;
  }

  private _validatorStepAnalyzeErrors(): boolean {
    return false;
  }

  private _downloadModel(): Promise<void> {
    return this._executor.getModeloImportacao(this.type).then((response: HttpResponse<Blob>) => {
      downloadStream(response);
      this._focusBtnNext();
    });
  }

  private _focusBtnNext(): void {
    const btnNext: HTMLButtonElement = this._element.querySelector<HTMLButtonElement>('pl-nav-wizard .nav-wizard-item-content-wrapper.active .nav-wizard-item-footer .action-next-step');
    focusElement(btnNext);
  }
}
