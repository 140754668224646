<div [ngClass]="entityClassName">
  <entity-detail
    [entityName]="entityName"
    [service]="service"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailPartialContent>
      <pl-form [properties]="{disabled: type === stateTypes.DETAIL, orientation: formOrientation}">
        <pl-group>
          <label [translate]="'nifs.fields.taxaRet'"></label>
          <edit>
            <entity-autocomplete
              entity="taxasretencao"
              attrName="taxasretencao"
              [model]="model"
              (modelChange)="changedCodRet($event)"
              [fieldsMap]="{codRet: 'codRet', nomeRet: 'nomeRet'}"
              [output]="'{{codRet}} - {{nomeRet}}'">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <fieldset class="mt-3" *ngIf="showModelo30">
          <legend [translate]="'nifs.modelo30.title'"></legend>

          <div class="row">
            <div class="col-sm-12 col-lg-6">
              <pl-group>
                <label [translate]="'nifs.modelo30.fields.nifPt'"></label>
                <edit>
                  <pl-edit type="text" attrName="m30NifPT" [(model)]="model.m30NifPT" [properties]="{validators: {minlength: {value: 9}}}"></pl-edit>
                </edit>
              </pl-group>
            </div>
            <div class="col-sm-12 col-lg-6">
              <pl-group>
                <label [translate]="'nifs.modelo30.fields.nifEmitente'"></label>
                <edit>
                  <pl-edit type="text" attrName="m30NifEmit" [(model)]="model.m30NifEmit" [properties]="{validators: {minlength: {value: 9}}}"></pl-edit>
                </edit>
              </pl-group>
            </div>

            <div class="col-sm-12 col-lg-6">
              <pl-group>
                <label [translate]="'nifs.modelo30.fields.partCapD'"></label>
                <edit>
                  <pl-edit type="number" attrName="m30PartCapD" [(model)]="model.m30PartCapD" [inputClass]="'text-right'" [properties]="{modelOptions: {updateOn: 'blur'}, decimalsLimit: 2}">
                  </pl-edit>
                </edit>
              </pl-group>
            </div>
            <div class="col-sm-12 col-lg-6">
              <pl-group>
                <label [translate]="'nifs.modelo30.fields.partCapS'"></label>
                <edit>
                  <pl-edit type="number" attrName="m30PartCapS" [(model)]="model.m30PartCapS" [inputClass]="'text-right'" [properties]="{modelOptions: {updateOn: 'blur'}, decimalsLimit: 2}">
                  </pl-edit>
                </edit>
              </pl-group>
            </div>
          </div>
        </fieldset>
      </pl-form>
    </div>
  </entity-detail>
</div>
