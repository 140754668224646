import {Observable} from 'rxjs';
import {IJsonPagamento, IJsonPagamentoLinha, IJsonPagamentoMeioPagamento} from '../jsonPagamento.entity.interface';
import {IPagamentoImprimirModalParams} from '../modals/imprimirmodal/pagamento.imprimir.modal.component.interface';
import {IPagamentosSaveModalResult, TPagamentosSaveModalSaveFn} from '../modals/savemodal/pagamentos.save.modal.component.interface';
import {TServiceQueryResponse, TServiceResponse} from '../../../services/api/api.service.interface';

export enum EOptionsSEPA {
  TransfNaoESEPA,
  GuardarERegistarNaGestaoDeFicheirosSEPA,
  GuardarEGerarFicheiroSEPA
}

export interface IPagamentosService {
  atribuiMontanteRetencao(pagamento: IJsonPagamento): void;

  calculaPercentagemDesconto(linha: IJsonPagamentoLinha, percDescSobreValorIVA: boolean): void;

  calculaValorDesconto(linha: IJsonPagamentoLinha, percDescSobreValorIVA: boolean): void;

  calculaValorRetencao(pagamentoLinha: IJsonPagamentoLinha): void;

  calculaTotais(pagamento: IJsonPagamento): void;

  loadLinhas(pagamento: IJsonPagamento): Promise<IJsonPagamento>;

  validaDados(pagamento: IJsonPagamento): Array<unknown>;

  getPdf(params: IPagamentoImprimirModalParams): Promise<void>;

  getPdfUrl(extPocCabID: string, nomereport: string, nconta: string, nvias: number, ano: number): Observable<string>;

  enviaPdfPorEmail(extPocCabID: string, nconta: string, email: string, ano: number): TServiceResponse<void>;

  anular(extPocCabID: string): TServiceResponse<void>;

  saveModal(pagamento: IJsonPagamento, saveFn: TPagamentosSaveModalSaveFn, meioPagamentos: Array<IJsonPagamentoMeioPagamento>, abreviaturaMoeda: string): Promise<IPagamentosSaveModalResult>;

  getPagamentosNoFicheiroSEPA(extPocCabID: string, search: string, order: string, page: number, perPage: number, searchFields: string): TServiceQueryResponse<IJsonPagamento>;
}
