import {Component, Injector, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../components/cg/modal/cgmodal.component';
import {DebDiretosSEPAModuleService} from '../debDiretosSEPA.module.service';
import {IPlNavWizardCallback, IPlNavWizardDefinition, IPlNavWizardOptions, IPlNavWizardStep, IPlUploadCallback, isDefinedNotNull, isUndefinedOrNull, PlAlertService} from 'pl-comps-angular';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IJsonDebDiretosSEPAImportProcessRecord, IJsonDebDiretosSEPAImportProcessResults, IJsonDebDiretosSEPAImportRecord, IJsonDebDiretosSEPAImportResults} from '../debDiretosSEPA.module.interface';
import CustomStore from 'devextreme/data/custom_store';
import {ICGExceptionError} from '../../../components/exceptions/exceptions.service.interface';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {HttpErrorResponse} from '@angular/common/http';
import {IDevExpressDataGridEventOnCellPrepared, IDevExpressDataGridEventOnInitialized} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import dxDataGrid from 'devextreme/ui/data_grid';

@Component({
  selector: 'ddsepa-importador-modal',
  templateUrl: './debDiretosSEPA.importador.modal.component.html'
})
export class DebDiretosSEPAImportadorModalComponent extends CGModalComponent<void> implements OnInit {
  public propertiesNavWizard: IPlNavWizardOptions;
  public uploadUrl: string;
  public showUploadProgressBar: boolean;
  public showProcessProgressBar: boolean;
  public dataGridProcSucessoDefinition: IDevExpressDataGrid<IJsonDebDiretosSEPAImportRecord, string>;
  public dataGridProcErrosDefinition: IDevExpressDataGrid<IJsonDebDiretosSEPAImportRecord, string>;
  public dataGridProcessadoDefinition: IDevExpressDataGrid<IJsonDebDiretosSEPAImportProcessRecord, string>;
  public importResults: IJsonDebDiretosSEPAImportResults;
  public importProcessResults: IJsonDebDiretosSEPAImportProcessResults;
  public activeTabId: string;
  public activeWizardStep: IPlNavWizardStep;
  public ficheiroJaImportado: boolean;

  public readonly plWizardCallback: IPlNavWizardCallback;
  public readonly plUploadCallback: IPlUploadCallback;
  public readonly definitionNavWizard: IPlNavWizardDefinition;
  public readonly plWizardUploadStepId = 'ficheiro';
  public readonly tabDadosFicheiroSucessoId = 'tabDadosFicheiroSucesso';
  public readonly tabDadosFicheiroErrosId = 'tabDadosFicheiroErros';

  private _dataGridProcSucessoInstance: dxDataGrid<IJsonDebDiretosSEPAImportRecord, string>;
  private _dataGridProcessadoInstance: dxDataGrid<IJsonDebDiretosSEPAImportProcessRecord, string>;

  constructor(
    protected readonly _injector: Injector,
    protected readonly _debDiretosSEPAModuleService: DebDiretosSEPAModuleService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgExceptionService: CGExceptionService
  ) {
    super(_injector);
    this.ficheiroJaImportado = false;
    this.propertiesNavWizard = {disableNavigation: false, disablePreviousStep: false, disableNextStep: false, disableFinalize: true};
    this.plUploadCallback = {};
    this.plWizardCallback = {};
    this.showUploadProgressBar = false;
    this.showProcessProgressBar = false;
    this.activeTabId = this.tabDadosFicheiroSucessoId;
    this.definitionNavWizard = {
      items: []
    };
    this.importResults = {
      list: [],
      errorList: [],
      isReembolso: false,
      canImport: true,
      resumo: {
        nRegistosTotal: 0,
        nRegistosOKLiquidacao: 0,
        nRegistosOKDevolucao: 0,
        nRegistosErro: 0,
        valorTotal: 0,
        valorErro: 0,
        valorOKLiquidacao: 0,
        valorOKDevolucao: 0,
        nErros: 0,
        nErrosCG: 0
      }
    };
    this.importProcessResults = {
      list: [],
      resumo: {
        nRegistosLiquidados: 0,
        nRegistosDevolvidos: 0,
        nRegistosNaoLiquidado: 0,
        nRegistosTotal: 0,
        valoresImputado: 0,
        valoresEmAberto: 0,
        valoresDevolvido: 0,
        valoresNaoLiquidado: 0,
        valoresTotal: 0
      }
    };

    this.dataGridProcSucessoDefinition = {
      columns: [
        {dataField: 'nDocAsString', dataType: 'string', caption: 'debDiretosSEPA.fields.docFaturacao'},
        {dataField: 'docContabilidadeAsSTR', dataType: 'string', caption: 'debDiretosSEPA.fields.docContabilidade'},
        {dataField: 'docContabilidadeImportAsSTR', dataType: 'string', caption: 'debDiretosSEPA.fields.docContabilidadeLiquidacao'},
        {dataField: 'importValor', dataType: 'double', caption: 'debDiretosSEPA.fields.valorLiquidacao'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'debDiretosSEPA.fields.dataDoc'},
        {dataField: 'totalDocumento', dataType: 'double', caption: 'debDiretosSEPA.fields.valorDoc'},
        {dataField: 'nConta', dataType: 'string', caption: 'debDiretosSEPA.fields.nConta'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'debDiretosSEPA.fields.nome'}
      ],
      masterDetail: {template: 'cellTemplateErros', autoExpandAll: false, enabled: true},
      dataSource: new CustomStore({
        key: 'nDocAsString',
        load: () => this.importResults.list
      })
    };

    this.dataGridProcessadoDefinition = {
      columns: [
        {dataField: 'nDocAsString', dataType: 'string', caption: 'debDiretosSEPA.fields.docFaturacao'},
        {dataField: 'docContabilidadeAsSTR', dataType: 'string', caption: 'debDiretosSEPA.fields.docContabilidade', visible: false},
        {dataField: 'docContabilidadeImportAsSTR', dataType: 'string', caption: 'debDiretosSEPA.fields.docContabilidadeLiquidacao'},
        {dataField: 'importValor', dataType: 'double', caption: 'debDiretosSEPA.fields.valorDD'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'debDiretosSEPA.fields.dataDoc'},
        {dataField: 'totalDocumento', dataType: 'double', caption: 'debDiretosSEPA.fields.valorDD'},
        {dataField: 'importValorImputado', dataType: 'double', caption: 'debDiretosSEPA.fields.importValorImputado'},
        {dataField: 'importValorEmAberto', dataType: 'double', caption: 'debDiretosSEPA.fields.valorEmAberto'},
        {dataField: 'nConta', dataType: 'string', caption: 'debDiretosSEPA.fields.nConta'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'debDiretosSEPA.fields.nome'}
      ],
      dataSource: new CustomStore({
        load: () => this.importProcessResults.list
      })
    };

    this.dataGridProcErrosDefinition = {
      columns: [
        {dataField: 'nDocAsString', dataType: 'string', caption: 'debDiretosSEPA.fields.docFaturacao'},
        {dataField: 'docContabilidadeAsSTR', dataType: 'string', caption: 'debDiretosSEPA.fields.docContabilidade'},
        {dataField: 'dataDoc', dataType: 'date', caption: 'debDiretosSEPA.fields.dataDoc'},
        {dataField: 'totalDocumento', dataType: 'double', caption: 'debDiretosSEPA.fields.valorDD'},
        {dataField: 'nConta', dataType: 'string', caption: 'debDiretosSEPA.fields.nConta'},
        {dataField: 'nomeConta', dataType: 'string', caption: 'debDiretosSEPA.fields.nome'}
      ],
      masterDetail: {template: 'cellTemplateProcErros', autoExpandAll: true, enabled: true},
      dataSource: new CustomStore({
        load: () => this.importResults.errorList
      })
    };
  }

  public ngOnInit(): void {
    this._debDiretosSEPAModuleService.generateImportUploadUrl().subscribe((url: string) => {
      this.uploadUrl = url;
    });
  }

  public onDataGridProcSucessoInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonDebDiretosSEPAImportRecord, string>): void {
    this._dataGridProcSucessoInstance = component;
    this._configColumnsProcSucesso();
  }

  public onDataGridProcessadoInitialized({component}: IDevExpressDataGridEventOnInitialized<IJsonDebDiretosSEPAImportProcessRecord, string>): void {
    this._dataGridProcessadoInstance = component;
    this._configColumnsProcessado();
  }

  public onWizardStepChange(step: IPlNavWizardStep): void {
    this.activeWizardStep = step;
    if (step.stepId === this.plWizardUploadStepId) {
      step.setIncomplete();
      step.valid = false;
    }
  }

  public onDataGridProcSucessoCellPrepared(event: IDevExpressDataGridEventOnCellPrepared<IJsonDebDiretosSEPAImportRecord>): void {
    if (event.rowType === 'data') {
      if (event.column.command === 'expand' && !event.data.erro) {
        (<HTMLElement>event.cellElement.childNodes[0]).classList.remove('dx-datagrid-group-closed');
        event.cellElement.classList.remove('dx-datagrid-expand');
      }
      if (event.data.erro) {
        event.cellElement.classList.add('row-error');
      }
    }
  }

  public onDataGridProcSucessoContentReady(): void {
    this.importResults?.list.forEach((item) => {
      if (item.erro) {
        this._dataGridProcSucessoInstance.expandRow(item.nDocAsString);
      }
    });
  }

  public readonly fnValidateUpload: () => Promise<boolean> = () => this._validateUpload();
  public readonly fnValidateDadosFicheiro: () => Promise<boolean> = () => this._validateDadosFicheiro();

  private _configColumnsProcSucesso(): void {
    if (isUndefinedOrNull(this.importResults)) {
      return;
    }
    this._dataGridProcSucessoInstance?.columnOption('importValor', 'visible', this.importResults.isReembolso);
    this._dataGridProcSucessoInstance?.columnOption('importValor', 'showInColumnChooser', this.importResults.isReembolso);
    this._dataGridProcSucessoInstance?.columnOption('docContabilidadeImportAsSTR', 'visible', this.importResults.isReembolso);
    this._dataGridProcSucessoInstance?.columnOption('docContabilidadeImportAsSTR', 'showInColumnChooser', this.importResults.isReembolso);
  }

  private _configColumnsProcessado(): void {
    if (isUndefinedOrNull(this.importResults)) {
      return;
    }
    this._dataGridProcessadoInstance?.columnOption('importValor', 'visible', this.importResults.isReembolso);
    this._dataGridProcessadoInstance?.columnOption('importValor', 'showInColumnChooser', this.importResults.isReembolso);
    this._dataGridProcessadoInstance?.columnOption('docContabilidadeImportAsSTR', 'visible', this.importResults.isReembolso);
    this._dataGridProcessadoInstance?.columnOption('docContabilidadeImportAsSTR', 'showInColumnChooser', this.importResults.isReembolso);
  }

  private _validateUpload(): Promise<boolean> {
    this._wizNavControl(false);
    return new Promise<boolean>((resolve) => {
      if (!this.plUploadCallback.getAcceptedFiles().length) {
        this._plAlertService.error('debDiretosSEPA.messages.noFiles');
        this._wizNavControl(true);
        resolve(false);
        return;
      }
      this.showUploadProgressBar = true;
      this.plUploadCallback
        .uploadAll()
        .then(() => {
          this.ficheiroJaImportado = false;
          this._debDiretosSEPAModuleService
            .doImportFile()
            .then((response) => {
              this.importResults = response.body;
              this.activeTabId = this.importResults.errorList.length ? this.tabDadosFicheiroErrosId : this.tabDadosFicheiroSucessoId;
              if (this.importResults.resumo.nErrosCG > 0) {
                this.ficheiroJaImportado = this.importResults.list.findIndex((o) => o.erro && o.erroAvisoDescricao.includes('já está liquidado')) > -1;
                this.importResults.list.sort((a, b) => {
                  return Number(b.erro) - Number(a.erro);
                });
              }
              this._configColumnsProcSucesso();
              resolve(true);
            })
            .catch((reason: HttpErrorResponse) => {
              this._handleException(reason);
              this._wizNavControl(true);
              resolve(false);
            })
            .finally(() => {
              this.showUploadProgressBar = false;
              this._wizNavControl(true);
            });
        })
        .catch((reason: unknown) => {
          this._logger.error(reason);
          this.showUploadProgressBar = false;
          this._wizNavControl(true);
          resolve(false);
        });
    });
  }

  private _validateDadosFicheiro(): Promise<boolean> {
    if (this.importResults.list.length === 0 && this.importResults.errorList.length === 0) {
      this._plAlertService.error('debDiretosSEPA.messages.naoHaNenhumDadoParaImportar');
      return Promise.resolve(false);
    }

    if (!this.importResults.canImport) {
      this._plAlertService.error('debDiretosSEPA.messages.haErrosNosDocumentos');
      return Promise.resolve(false);
    }
    this._wizNavControl(false);
    return new Promise<boolean>((resolve) => {
      this.showProcessProgressBar = true;
      this._debDiretosSEPAModuleService
        .processImportFile()
        .then((response) => {
          this.importProcessResults = response.body;
          resolve(true);
          this._wizNavControl(true, false);
        })
        .catch((reason: HttpErrorResponse) => {
          this._handleException(reason);
          this._wizNavControl(true);
          resolve(false);
        })
        .finally(() => {
          this.showProcessProgressBar = false;
        });
    });
  }

  private _wizNavControl(nav: boolean, disableFinalize: boolean = true): void {
    this.propertiesNavWizard = {disableNavigation: !nav, disableFinalize: disableFinalize};
  }

  private _handleException(ex: HttpErrorResponse): void {
    this._logger.error(ex);
    if (isDefinedNotNull(ex)) {
      const exception: ICGExceptionError = this._cgExceptionService.get(ex);
      this._plAlertService.error(exception.message);
    }
  }
}
