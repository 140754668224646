import {Component, ElementRef, Injector, Input, OnInit, ViewChild} from '@angular/core';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {copy, KEYCODES, PlAlertService} from 'pl-comps-angular';
import {IDevExpressDataGrid} from '../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {EntityServiceBuilder} from '../../../../../services/entity/entity.service.builder';
import {IEntityService} from '../../../../../services/entity/entity.service.interface';
import {IJsonArtigoLoteArmazemStock, IJsonLote} from '../../../../lotes/jsonLote.entity.interface';
import {IJsonDocComercial, IJsonDocComercialLinha, IJsonDocLinhaLote} from '../../../jsonDocComercial.entity.interface';
import {CGModalComponent} from '../../../../../components/cg/modal/cgmodal.component';
import {
  IDevExpressDataGridEventOnFocusedCellChanged,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnKeyDown
} from '../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {THttpQueryResponse} from '../../../../../services/api/api.service.interface';
import {ENTITY_NAME_LOTES} from '../../../../lotes/lotes.entity.interface';
import {focusElement} from '../../../../../../common/utils/element.utils';
import {IEntityMaintenanceInstance} from '../../../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {EntityMaintenanceService} from '../../../../../components/entity/maintenance/entity/entity.maintenance.service';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';

const TIMEOUT_ADD_NOVO_LOTE = 1000;

@Component({
  selector: 'selecao-lotes-linha-modal',
  templateUrl: './selecaoLotes.linha.modal.component.html'
})
export class SelecaoloteslinhaModalComponent extends CGModalComponent<IJsonDocComercialLinha> implements OnInit {
  @Input() public doc: IJsonDocComercial;
  @Input() public linha: IJsonDocComercialLinha;
  @Input() public detail: boolean = false;
  @Input() public diasValidade: number = 0;

  public dataGridDefinition: IDevExpressDataGrid<IJsonLote, number>;

  private readonly _lotes: IEntityService<IJsonLote>;
  private readonly _decimaisQtd: number;
  private _dataGridInstance: dxDataGrid;
  private _elementBtnClose: HTMLButtonElement;
  private _maintenanceLotes: IEntityMaintenanceInstance;
  private _lotesLinha: Array<IJsonDocLinhaLote>;
  private _dismiss: boolean;
  private _rowIndex: number;
  private _onCallLotes: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _translateService: TranslateService,
    private readonly _plAlertService: PlAlertService
  ) {
    super(_injector);
    this._lotes = this._entityServiceBuilder.build<IJsonLote>(ENTITY_NAME_LOTES);
    this._lotesLinha = [];
    this._decimaisQtd = this._configService.configurations.gestaoComercial.decimais.stocks.quantidades;
    this.dataGridDefinition = {
      allowColumnReordering: false,
      columnHidingEnabled: true,
      columnChooser: {mode: 'select'},
      columns: [
        {dataField: 'nLote', dataType: 'number', caption: 'lotes.fields.nLote', visible: false, allowEditing: false, showInColumnChooser: false},
        {dataField: 'nLoteEspecifico', dataType: 'string', caption: 'lotes.fields.nLoteEspecifico', allowEditing: false, showInColumnChooser: false},
        {dataField: 'dataFabrico', dataType: 'date', caption: 'lotes.fields.dataFabrico', visible: false, allowEditing: false},
        {dataField: 'dataValidade', dataType: 'date', caption: 'lotes.fields.dataValidade', visible: false, allowEditing: false},
        {dataField: 'qtdStock', dataType: 'double', caption: 'docscomerciais.doc.linhas.qtdStock', visible: false, format: {decimalsLimit: this._decimaisQtd}, allowEditing: false},
        {dataField: 'qtd', dataType: 'double', caption: 'docscomerciais.doc.linhas.qtd', format: {decimalsLimit: this._decimaisQtd}, showEditorAlways: true, showInColumnChooser: false}
      ],
      dataSource: [],
      keyExpr: 'nLote',
      toolbar: {visible: true},
      filterRow: {visible: false},
      editing: {allowUpdating: true, mode: 'cell', selectTextOnEditStart: true},
      remoteOperations: false,
      keyboardNavigation: {
        enabled: true,
        enterKeyAction: 'moveFocus',
        enterKeyDirection: 'column'
      }
    };
  }

  public ngOnInit(): void {
    this._lotesLinha = copy(this.linha.lotes);
    if (this.detail) {
      this.dataGridDefinition.dataSource = this._lotesLinha;
      this.dataGridDefinition.editing.allowUpdating = false;
      this.dataGridDefinition.editing.selectTextOnEditStart = false;
      this.dataGridDefinition.toolbar.visible = false;
    } else {
      this._getLotesArtigoLinha();
    }
    this._dismiss = false;
    this._rowIndex = 0;
    this._onCallLotes = false;
  }

  public close(): Promise<void> {
    let qtdTotal = 0;

    this.linha.lotes = [];
    for (const item of this._lotesLinha) {
      this.linha.lotes.push(item);
      qtdTotal += item.qtd;
    }

    if (!this._dismiss && qtdTotal === 0) {
      this._plAlertService.error(this._translateService.instant('lotes.errors.artigoDaLinhaNaoTemLote', {nartigo: this.linha.nArtigo}));
      return undefined;
    }

    this.linha.qtd1 = qtdTotal;

    super.close(this.linha);
    return Promise.resolve();
  }

  public dismiss(): void {
    if (this.detail) {
      super.dismiss();
    } else {
      this._lotesLinha = copy(this.linha.lotes);
      this._dismiss = true;
      this.close();
    }
  }

  public novoLote(): Promise<void> {
    this._onCallLotes = true;

    if (!this._maintenanceLotes) {
      this._maintenanceLotes = this._entityMaintenanceService.build(ENTITY_NAME_LOTES);
    }

    const nArtigo = this.linha.nArtigo ? this.linha.nArtigo : '';

    return this._maintenanceLotes
      .maintenanceNew({
        params: {
          model: {nArtigo: nArtigo, nomeArtigo: this.linha.nomeArtigo ? this.linha.nomeArtigo : ''},
          nArtigo: nArtigo,
          dataDoc: this.doc.cab.dataDoc ? this.doc.cab.dataDoc : moment(),
          diasValidade: this.diasValidade ? this.diasValidade : 0
        }
      })
      .then(() => {
        this._getLotesArtigoLinha();
      })
      .catch((reason: unknown) => {
        if (!reason) {
          this._getLotesArtigoLinha();
        }
        this._logger.error(reason);
      })
      .finally(() => {
        window.setTimeout(() => {
          this._onCallLotes = false;
          focusElement(this._element.querySelector<HTMLElement>('.dx-datagrid'));
          this._dataGridInstance.editCell(0, 'qtd');
        }, TIMEOUT_ADD_NOVO_LOTE);
      });
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized): void {
    this._dataGridInstance = component;
    this._dataGridInstance.columnOption('qtd', 'showEditorAlways', !this.detail);
    this._dataGridInstance.columnOption('qtdStock', 'visible', !this.detail);
  }

  public onContentReady(): void {
    this._dataGridInstance.endCustomLoading();

    if (this._onCallLotes) {
      return;
    }

    if (!this.detail) {
      this._dataGridInstance.columnOption('qtd', 'showEditorAlways', !this.detail);
      this._dataGridInstance.editCell(this._rowIndex ? this._rowIndex : 0, 'qtd');
    }
  }

  public onKeydownLastRow({event}: IDevExpressDataGridEventOnKeyDown): void {
    if (event.key === KEYCODES.ENTER) {
      event.preventDefault();
      focusElement(this._elementBtnClose);
    }
  }

  public onFocusedCellChanged(event: IDevExpressDataGridEventOnFocusedCellChanged<IJsonDocLinhaLote>): void {
    this._rowIndex = event.row.rowIndex ? event.row.rowIndex : 0;
  }

  @ViewChild('btnClose')
  public set elementBtnClose(elementRef: ElementRef<HTMLButtonElement>) {
    this._elementBtnClose = elementRef?.nativeElement;
  }

  private _getLotesArtigoLinha(): void {
    this._lotes.query({pesquisa: `nArtigo=${this.linha?.nArtigo}&terminado=0`}).then((response: THttpQueryResponse<IJsonLote>) => {
      const lotes: Array<IJsonLote> = response.body.list;

      if (lotes.length > 0) {
        let lotesAtribuidos: Array<IJsonDocLinhaLote> = [];
        if (this._lotesLinha.length > 0) {
          lotesAtribuidos = copy(this._lotesLinha);
          this._lotesLinha = [];
        }
        let i = 0;
        for (const item of lotes) {
          const lote = lotesAtribuidos?.find((loteAtrib: IJsonDocLinhaLote) => loteAtrib?.nLote === item?.nLote);
          const qtd = lote ? lote.qtd : 0;
          const stock = item?.stockArmazens?.find((stockArmaz: IJsonArtigoLoteArmazemStock) => stockArmaz?.nArmazem === this.linha?.nArmazem);
          const qtdStock = stock ? stock.qtd : 0;
          this._lotesLinha.push({nLote: item.nLote, dataFabrico: item.dataFabrico, dataValidade: item.dataValidade, nLoteEspecifico: item.nLoteEspecifico, qtd: qtd, seq: i, qtdStock: qtdStock});
          i++;
        }
        this.dataGridDefinition.dataSource = this._lotesLinha;
      }
    });
  }
}
