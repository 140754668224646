import {Component, Injector, Input, OnInit} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {isObject, PlAlertService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';
import {IReciboImprimirModalParams, RECIBO1_ESCOLHA_MOEDA_NAME, RECIBO1_NAME} from './recibo.imprimir.modal.component.interface';
import {IRecibosService} from '../../service/recibos.entity.service.interface';
import {IJsonReport} from '../../../reports/jsonReport.interface';
import {EReport} from '../../../reports/reports.interface';
import {ReportsRegistryService} from '../../../../components/reports/reports.registry.service';
import {ConfigErpService} from '../../../../services/configErp.service';
import {HttpResponse} from '@angular/common/http';
import {IJsonConfigERP} from '../../../configserp/jsonConfigERP.entity.interface';

@Component({
  selector: 'recibo-imprimir-modal',
  templateUrl: './recibo.imprimir.modal.component.html'
})
export class ReciboImprimirModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public params: IReciboImprimirModalParams;
  @Input() public recibosService: IRecibosService;

  public readonly rowTemplateListagem: string;
  public form: FormGroupDirective;
  public extPocCabID: string;
  public nConta: string;
  public nomeConta: string;
  public email: string;
  public viewPdf: boolean;
  public url: string;
  public nCopias: number;
  public ano: number;
  public anulado: boolean;
  public reportsSource: Array<IJsonReport>;
  public report: IJsonReport;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService,
    private readonly _reportsRegistryService: ReportsRegistryService,
    private readonly _configErpService: ConfigErpService
  ) {
    super(_injector);
    this.rowTemplateListagem = '{{name}} - {{title}}';
    this.reportsSource = [];
    this.viewPdf = false;
    this.nCopias = 1;
    this.ano = 0;
    this.anulado = false;
    this.sendEmail = this.sendEmail.bind(this);
  }

  public async ngOnInit(): Promise<void> {
    if (isObject(this.params)) {
      this.extPocCabID = this.params.extPocCabID;
      this.nConta = this.params.nConta;
      this.nomeConta = this.params.nomeConta;
      this.email = this.params.email;
      this.ano = (this.params.ano ?? 0) > 0 ? this.params.ano : 0;
      this.anulado = this.params.anulado;
    }
    await this._loadReports();
  }

  public switchView(): void {
    if (!this.viewPdf) {
      this.viewPdf = true;
      this.recibosService.getPdfUrl(this.extPocCabID, this.report?.name, this.nConta, this.nCopias, this.ano).subscribe((url: string) => {
        this.url = url;
      });
    } else {
      this.viewPdf = false;
    }
  }

  public async sendEmail(): Promise<void> {
    try {
      await this.recibosService.enviaPdfPorEmail(this.extPocCabID, this.nConta, this.email, this.nCopias, this.ano);
      this._plAlertService.success(this._translateService.instant('reciboimprimir.email.emailEnviado', {email: this.email}));
    } catch {
      this._plAlertService.error(this._translateService.instant('reciboimprimir.email.emailErro', {email: this.email}));
    }
  }

  public reportChanged(value: {inputValue: string; item: IJsonReport}): void {
    if (isObject(value.item)) {
      this.report = value.item;
    }
  }

  private async _loadReports(): Promise<void> {
    const reports: Array<IJsonReport> = await this._reportsRegistryService.get(EReport.Recibos).query();
    if (reports.length) {
      this._configErpService.getConfiguration('contabilidade.recibos.reportImpressao').then((result: HttpResponse<IJsonConfigERP>) => {
        const defaultReport = !result.body && result.body.value === RECIBO1_ESCOLHA_MOEDA_NAME ? RECIBO1_NAME : <string>result.body.value;

        reports.forEach((report: IJsonReport) => {
          if (report.name !== RECIBO1_ESCOLHA_MOEDA_NAME) {
            this.reportsSource.push(report);
            if (report.name === defaultReport) {
              this.report = report;
            }
          }
        });
        if (!this.report) {
          this.report = reports[0];
        }
      });
    }
  }
}
