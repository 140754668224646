import {ClassProvider, forwardRef, Injectable} from '@angular/core';
import {DI_AMALIA_INTENT_HANDLERS} from '../../../../../services/amalia/intent/amalia.intent.handler.di';
import {IAmaliaIntent, IAmaliaIntentHandler} from '../../../../../services/amalia/intent/amalia.intent.handler.interface';
import {CGStateService} from '../../../../../components/state/cg.state.service';
import {AMALIA_INTENT_NAME_SVAT, ISVATIntentParameters} from './contabilidade.svat.amalia.interface';
import {MODULE_NAME_PCA_MANUTENCAO_SVAT} from '../contabilidade.svat.module.interface';
import {EPortal} from '../../../../../../common/enums/portals.enums';

export const AMALIA_INTENT_HANDLER_SVAT: ClassProvider = {
  provide: DI_AMALIA_INTENT_HANDLERS,
  multi: true,
  useClass: forwardRef(() => SVATAmaliaIntentHandler)
};

@Injectable()
export class SVATAmaliaIntentHandler implements IAmaliaIntentHandler<ISVATIntentParameters> {
  constructor(private readonly _cgStateService: CGStateService) {}

  public intentName(): string {
    return AMALIA_INTENT_NAME_SVAT;
  }

  public async intentHandler(intent: IAmaliaIntent<ISVATIntentParameters>): Promise<void> {
    return this._cgStateService.redirectToState({
      transitionOptions: {reload: true},
      stateOrName: MODULE_NAME_PCA_MANUTENCAO_SVAT,
      portal: EPortal.CONTABILIDADE,
      params: {
        year: intent.parameters.year
      }
    });
  }
}
