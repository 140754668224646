<div class="contabilidade-moeda">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [(formInstance)]="formMain" [model]="model" [properties]="{validators: {required: {value: true}}, readonly: isDetailState, orientation: formOrientation}">
        <pl-group *ngIf="type === stateTypes.NEW">
          <label [translate]="'moeda.fields.codISO'"></label>
          <edit>
            <pl-edit
              attrName="codInternacional"
              type="paisesISO3166"
              [model]="model.codInternacional"
              (modelChange)="codInternacionalChanged($event)"
              [properties]="{
                disabled: isEditState,
                disallowClear: true,
                validators: {required: true}
              }"
              plAutoFocus
              [plAutoFocusDisabled]="type !== stateTypes.NEW"></pl-edit>
          </edit>
        </pl-group>
        <pl-group>
          <pl-group>
            <label [translate]="'moeda.fields.codMoeda'"></label>
            <edit>
              <pl-edit attrName="codMoeda" type="integer" [(model)]="model.codMoeda" [properties]="{disabled: fieldsDisabled || isEditState}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'moeda.fields.nomeMoeda'"></label>
            <edit>
              <pl-edit attrName="nomeMoeda" type="text" [(model)]="model.nomeMoeda" [properties]="{disabled: fieldsDisabled}" plAutoFocus></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'moeda.fields.abreviaturaMoeda'"></label>
            <edit>
              <pl-edit attrName="abreviaturaMoeda" type="text" [(model)]="model.abreviaturaMoeda" [properties]="{disabled: fieldsDisabled}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'moeda.fields.nDecimais'"></label>
            <edit>
              <pl-edit attrName="nDecimais" type="integer" [(model)]="model.nDecimais" [properties]="{disabled: fieldsDisabled || nDecimaisDisabled}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group>
          <pl-group>
            <label [translate]="'moeda.fields.extenso1Unidade'"></label>
            <edit>
              <pl-edit attrName="extenso1Unidade" type="text" [(model)]="model.extenso1Unidade" [properties]="{disabled: fieldsDisabled}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'moeda.fields.extensoNUnidades'"></label>
            <edit>
              <pl-edit attrName="extensoNUnidades" type="text" [(model)]="model.extensoNUnidades" [properties]="{disabled: fieldsDisabled}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group>
            <label [translate]="'moeda.fields.extensoCentimos'"></label>
            <edit>
              <pl-edit attrName="extensoCentimos" type="text" [(model)]="model.extensoCentimos" [properties]="{disabled: fieldsDisabled}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-tabs [theme]="'style3'" style="padding-top: 4px">
          <pl-tab class="contabilidade-moeda-tab-activeexchange" id="activeexchange" caption="moeda.tabs.activeExchange">
            <div *plTabContent>
              <pl-form [(formInstance)]="formActive" [properties]="{validators: {required: {value: true}}, readonly: fieldsDisabled || isDetailState}">
                <div class="d-flex mb-2" style="align-items: flex-start">
                  <label style="margin: 4px 0 0 0"><span [translate]="'moeda.fields.dataReferencia'"></span>:&nbsp;</label>
                  <pl-group style="max-width: 150px; margin: 0">
                    <edit>
                      <pl-edit-datepicker
                        [disabledDates]="dataReferenciaDisabledDates"
                        attrName="dataReferencia"
                        [model]="cambio.dataReferencia"
                        (modelChange)="changedDate($event)"></pl-edit-datepicker>
                    </edit>
                  </pl-group>
                  <button [disabled]="fieldsDisabled || isUpdateCambioDisabled()" type="button" class="btn btn-sm btn-light" (click)="updateCambio()" style="margin-left: 10px; margin-right: 10px">
                    <i class="fa fa-globe"></i>&nbsp;<span [translate]="'moeda.updateCambio'"></span>
                  </button>
                  <span *ngIf="dataRefUpdated">Data de atualização: {{ cambio.dataReferencia | cgcDate }}</span>
                </div>

                <div class="d-flex" style="gap: 15px">
                  <div style="max-width: 370px">
                    <div class="card">
                      <div class="card-header">
                        <div [innerHTML]="'moeda.titles.cambioMoedaEmpresaEmMoedaEstrangeira' | cgcTranslate"></div>
                      </div>
                      <div class="card-body">
                        <pl-group>
                          <label [translate]="'moeda.fields.nDecimais'"></label>
                          <edit>
                            <pl-edit type="integer" class="sm-edit" attrName="nDecimaisCambio1" [(model)]="cambio.nDecimaisCambio1"></pl-edit>
                          </edit>
                        </pl-group>

                        <pl-group>
                          <label [translate]="'moeda.fields.exchange'"></label>
                          <edit>
                            <pl-edit type="number" class="md-edit" attrName="cambioMoedEmpEmEstr" [(model)]="cambio.cambioMoedEmpEmEstr" [properties]="{decimalsLimit: cambio.nDecimaisCambio1}">
                            </pl-edit>
                          </edit>
                        </pl-group>
                      </div>
                    </div>
                  </div>
                  <div style="max-width: 370px">
                    <div class="card">
                      <div class="card-header">
                        <div [innerHTML]="'moeda.titles.cambioMoedaEstrangeiraEmMoedaEmpresa' | cgcTranslate"></div>
                      </div>
                      <div class="card-body">
                        <pl-group>
                          <label [translate]="'moeda.fields.nDecimais'"></label>
                          <edit>
                            <pl-edit type="integer" class="sm-edit" attrName="nDecimaisCambio2" [(model)]="cambio.nDecimaisCambio2"></pl-edit>
                          </edit>
                        </pl-group>

                        <pl-group>
                          <label [translate]="'moeda.fields.exchange'"></label>
                          <edit>
                            <pl-edit type="number" class="md-edit" attrName="cambioMoedEstrEmEmp" [(model)]="cambio.cambioMoedEstrEmEmp" [properties]="{decimalsLimit: cambio.nDecimaisCambio2}">
                            </pl-edit>
                          </edit>
                        </pl-group>
                      </div>
                    </div>
                  </div>
                </div>
              </pl-form>
            </div>
          </pl-tab>

          <pl-tab class="contabilidade-moeda-tab-otherexchange" id="otherexchange" caption="moeda.tabs.otherExchange" *ngIf="type !== stateTypes.NEW">
            <div *plTabContent>
              <pl-form [properties]="{validators: {required: {value: true}}, readonly: fieldsDisabled || isDetailState}">
                <dx-data-grid [cgDxDataGrid]="dataGridDefinition" [dataSource]="dataGridDefinition.dataSource" (onInitialized)="onInitialized($event)">
                  <div *dxTemplate="let item of 'cellTemplateBtn'">
                    <button type="button" class="btn btn-xs btn-warning" *ngIf="!isDetailState" (click)="editCambio(item.data)">
                      <i class="fa fa-fw fa-pencil-square-o"></i>
                    </button>

                    <button type="button" class="btn btn-xs btn-danger ms-2" (click)="removeCambio(item.data)" *ngIf="!item.data.active && !isDetailState">
                      <i class="fa fa-fw fa-times"></i>
                    </button>
                  </div>
                  <div *dxTemplate="let item of 'headerCellTemplateBtn'">
                    <button type="button" class="btn btn-xs btn-success" (click)="addCambio()" *ngIf="!isDetailState">
                      <i class="fa fa-fw fa-plus"></i>
                    </button>
                  </div>
                </dx-data-grid>
              </pl-form>
            </div>
          </pl-tab>
        </pl-tabs>
      </pl-form>
    </div>
  </entity-detail>
</div>
