import dxDataGrid, {Scrollable, ToolbarItem} from 'devextreme/ui/data_grid';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import CustomStore from 'devextreme/data/custom_store';
import {LoadOptions} from 'devextreme/data';
import DxCheckbox from 'devextreme/ui/check_box';
import {merge} from 'lodash-es';
import {fromEvent, Subscription} from 'rxjs';
import {skip} from 'rxjs/operators';
import {Component, HostListener, Injector, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {
  copy,
  EScreenSize,
  IPlDynamicVisualsSecondaryClickAction,
  IPlEditComponentOptionsInputAutocomplete,
  IPlEditComponentOptionsInputDatepicker,
  IPlLifeCycleEvent,
  IPlToolbarInstance,
  IPlToolbarItem,
  isEmpty,
  isFunction,
  isNumber,
  isObject,
  KEYCODES,
  PlAlertService,
  PlEditAutocompleteComponent,
  PlPageWrapperService,
  timeout,
  TValueOrPromise
} from 'pl-comps-angular';
import {AuthService} from '../../../services/auth/auth.service';
import {BancosExtratoModuleAuthorizationsModalComponent} from '../modals/authorizations/bancosExtrato.authorizations.modal.component';
import {BancosExtratoModuleConfigModalComponent} from '../modals/config/bancosExtrato.config.modal.component';
import {BancosExtratoModuleDocsSemelhantesModalComponent} from '../modals/docssemelhantes/bancosExtrato.docssemelhantes.modal.component';
import {BancosExtratoModuleEditBankAccountModalComponent} from '../modals/editbankaccount/bancosExtrato.editbankaccount.modal.component';
import {BancosExtratoModuleImportByReconciliationModalComponent} from '../modals/importbyreconciliation/bancosExtrato.importbyreconciliation.modal.component';
import {BancosExtratoMovabModalComponent} from '../modals/movab/bancosExtrato.movab.modal.component';
import {BancosExtratoModulePredefDescModalComponent} from '../modals/predefdesc/bancosExtrato.predefdesc.modal.component';
import {BancosExtratoUiService} from '../service/bancosExtrato.module.ui.service';
import {CGExceptionService} from '../../../components/exceptions/exceptions.service';
import {CGModalOkCancelComponent} from '../../../components/cg/modal/okcancel/okcancel.modal.component';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {
  DEV_EXPRESS_DATA_GRID_LOOKUP_ENTIDADE_BANCARIA_DOC_DIGITAL,
  DEV_EXPRESS_DATA_GRID_LOOKUP_ENTIDADE_BANCARIA_ESTADO,
  EBancosExtratoSimpleModeFilter,
  EBancosExtratoTableLegendColors,
  evaluateBancosExtratoTransactionActions,
  IBancosExtratoDataGridStoreChange,
  IBancosExtratoGetTransactionsByConciliacaoBancariaResult,
  IBancosExtratoPanelSliderTransaction,
  IBancosExtratoSimpleModeFilter,
  IBancosExtratoTransaction,
  IBancosExtratoTransactionEvaluatedActions,
  TABLE_LEGEND_BANCOS_EXTRATO,
  TABLE_LEGEND_BANCOS_EXTRATO_SIMPLE
} from '../bancosExtrato.module.interface';
import {devExpressDataGridFiltersToQueryFilter, devExpressDataGridSortToOrder} from '../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {DevExpressDataGridUIService} from '../../../services/devexpress/datagrid/devexpress.datagrid.ui.service';
import {DocContabilidadeService} from '../../portalcontabilidade/docscontabilidade/components/doccontabilidade/docContabilidade.service';
import {EConfigOptionsInstanceName, IBancosExtratoConfigOptions, TConfigOptions} from '../../../services/config/options/config.options.service.interface';
import {IContabDigitalDocViewerRecolhaCallback} from '../../../components/arquivodigital/contabilidade/docviewerrecolha/contabilidadedigital.docviewer.recolha.component.interface';
import {
  EDocContabilidadeField,
  IDocContabilidadeContabDigitalAttachment,
  IDocContabilidadeLoadPreDefinidoParams
} from '../../portalcontabilidade/docscontabilidade/components/doccontabilidade/docContabilidade.interface';
import {
  EEntidadeBancariaDocDigital,
  EEntidadeBancariaEstado,
  EEntidadeBancariaProvider,
  IJsonEntidadeBancariaSearchResult,
  IJsonEntidadeBancariaTransaction,
  TEntidadeBancariaEstados
} from '../../../interfaces/jsonEntidadeBancaria.interface';
import {EGroupName} from '../../../../config/constants';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocsContabilidadeEntityService, IDocsContabilidadePostParams} from '../../portalcontabilidade/docscontabilidade/docsContabilidade.interface';
import {EntityMaintenanceService} from '../../../components/entity/maintenance/entity/entity.maintenance.service';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {focusElement} from '../../../../common/utils/element.utils';
import {IBancosExtratoModuleMovabModalResult} from '../modals/movab/bancosExtrato.movab.modal.component.interface';
import {ICGExceptionError} from '../../../components/exceptions/exceptions.service.interface';
import {IContabDigitalGDocViewerRecolhaSearchParams} from '../../../services/contabilidadedigital/contabilidadedigital.interface';
import {IDevExpressDataGrid, IDevExpressDataGridColumn, IDevExpressDataGridEditCellTemplateData} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellPrepared,
  IDevExpressDataGridEventOnContentReady,
  IDevExpressDataGridEventOnContextMenuPreparing,
  IDevExpressDataGridEventOnFocusedRowChanged,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnRowPrepared,
  IDevExpressDataGridEventOnSelectionChanged
} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IEntityAutocompleteCustomActionDefinition} from '../../../components/entity/entity.autocomplete.definition.interface';
import {IEntityMaintenanceInstance} from '../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {
  IJsonBancosExtratoConfigs,
  IJsonBancosExtratoGetTransactionsByConciliacaoBancariaResult,
  IJsonBancosExtratoLancarDocumentosEmSerieData,
  IJsonBancosExtratoSuggestDocContabilidadeData
} from '../jsonBancosExtrato.module.interface';
import {IJsonBankAccount} from '../../../interfaces/jsonBankAccount.interface';
import {IJsonContabDigitalGDocViewerRecolhaSearchResult} from '../../../services/contabilidadedigital/jsonContabDigital.interface';
import {IJsonDocContabilidade, IJsonDocContabilidadeLinha} from '../../portalcontabilidade/docscontabilidade/jsonDocContabilidade.interface';
import {IJsonPreDefinidoContab} from '../../portalcontabilidade/manutencao/predefinidos/jsonPreDefinidosContab.entity.interface';
import {IPagamentoImprimirModalParams} from '../../../entities/pagamentos/modals/imprimirmodal/pagamento.imprimir.modal.component.interface';
import {IReciboImprimirModalParams} from '../../../entities/recibos/modals/imprimirmodal/recibo.imprimir.modal.component.interface';
import {IEntidadeBancariaResult, IEntidadeBancariaResultParams} from '../../../states/oauth2/entidadebancaria/oauth2.entidadebancaria.state.interface';
import {ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS} from '../../portalcontabilidade/manutencao/predefinidos/preDefinidosContab.entity.interface';
import {ModuloComponent} from '../../../components/module/module.component';
import moment from 'moment';
import {PagamentosService} from '../../../entities/pagamentos/service/pagamentos.entity.service';
import {RecibosService} from '../../../entities/recibos/service/recibos.entity.service';
import {ROLE} from '../../../services/role.const';
import {SELECTOR_DEV_EXPRESS_COMPONENT_SELECT_CHECKBOX, SELECTOR_DEV_EXPRESS_DATAGRID_GROUP} from '../../../components/devexpress/widget/devexpress.selectors.interface';
import {TDate} from '../../../../common/dates';
import {TDevExpressDataGridSelectionShowCheckBoxesMode} from '../../../components/devexpress/datagrid/devexpress.datagrid.types.interface';
import {TDevExpressFilterExpression} from '../../../components/devexpress/datalayer/filtering/devexpress.datalayer.filtering.interface';
import {THttpQueryResponse} from '../../../services/api/api.service.interface';
import {TTableLegend} from '../../../components/tablelegend/tablelegend.component.interface';
import {
  EArquivoDigitalDocViewerAddDocsMode,
  EArquivoDigitalDocViewerState,
  IArquivoDigitalDocViewerEvtChangedAttachment
} from '../../../components/arquivodigital/common/docviewer/arquivodigital.docviewer.component.interface';
import {BancosExtratoUnifiedPostAccountModalComponent} from '../modals/unifiedpostaccount/bancosExtrato.unifiedpost.account.modal.component';

const TOOLBAR_NAME = 'toolbar-bancos-extrato-module';
const TOOLBAR_NAME_PANEL_SLIDER = 'toolbar-bancos-extrato-module-panel-slider';
const TOOLBAR_GROUP_ID = 'bancos-extrato-module';
const TOOLBAR_BTN_SAVE = 'save';
const TOOLBAR_BTN_SAVE_WITHOUT_ATTACHMENT = 'saveWithoutAttachment';
const SAVE_PROMPT_IDENTIFIER = 'bancos-extrato-module-doc';
const DATAGRID_INSTANCE_NAME = 'bancosextratomodule';
const DATAGRID_BANK_ACCOUNT_INSTANCE_NAME = 'bancosextratomodulebankaccounts';
const DATAGRID_INSTANCE_NAME_SIMPLE = 'bancosextratosimplemodule';
const DATAGRID_BANK_ACCOUNT_INSTANCE_NAME_SIMPLE = 'bancosextratomodulebankaccountssimple';
const DATAGRID_TOOLBAR_ITEM_INDEX_LANCAR_DOCS_EM_SERIE = 1;
const ESTADOS_NAO_LANCADOS: ReadonlyArray<EEntidadeBancariaEstado> = Object.freeze([EEntidadeBancariaEstado.NaoLancado, EEntidadeBancariaEstado.NaoLancadoComDocPossivel]);

@Component({
  selector: 'module-bancos-extrato',
  templateUrl: './bancosExtrato.module.component.html'
})
export class BancosExtratoModuleComponent extends ModuloComponent implements OnInit, OnDestroy {
  @Input() public simpleMode: boolean;
  @Input() public licencaStoreModePublic: boolean;
  @Input() public empresaTemCGBanking: boolean;
  @Input() public empresaTemContabilidadeDigital: boolean;
  @Input() public cgOnCGBankingExpired: boolean;
  @Input() public contabilidadeDigital: boolean;
  @Input() public acessoBankingAutomation: boolean;
  @Input() public configsBancosExtrato: IJsonBancosExtratoConfigs;
  @Input() public cgStoreUrlBackOffice: string;
  public readonly entidadeBancariaEstados: typeof EEntidadeBancariaEstado;
  public readonly entidadeBancariaDocDigital: typeof EEntidadeBancariaDocDigital;
  public readonly configOptionsInstanceName: EConfigOptionsInstanceName;
  public readonly configOptionsGroupName: EGroupName;
  public readonly dataGrid: IDevExpressDataGrid<IBancosExtratoTransaction, string>;
  public readonly dataGridBankAccounts: IDevExpressDataGrid<IJsonBankAccount, string>;
  public readonly dataGridSource: DataSource<IBancosExtratoTransaction, string>;
  public readonly dataGridSourceBankAccounts: DataSource<IJsonBankAccount, string>;
  public readonly filterPredefinido: string;
  public readonly customActionPreDefinido: IEntityAutocompleteCustomActionDefinition;
  public readonly contabilidadeDigitalSearchParams: Readonly<IContabDigitalGDocViewerRecolhaSearchParams>;
  public readonly propertiesAutocompleteBankAccount: IPlEditComponentOptionsInputAutocomplete;
  public readonly propertiesDate: IPlEditComponentOptionsInputDatepicker;
  public readonly contabDigitalAttachment: IDocContabilidadeContabDigitalAttachment;
  public readonly contabDigitalPanelSliderAttachment: IDocContabilidadeContabDigitalAttachment;
  public readonly panelSliderToolbarInstanceId: string;
  public readonly docViewerState: EArquivoDigitalDocViewerState;
  public readonly docViewerAddDocsMode: EArquivoDigitalDocViewerAddDocsMode;
  public readonly callbackDocViewer: IContabDigitalDocViewerRecolhaCallback;
  public readonly callbackPanelSliderDocViewer: IContabDigitalDocViewerRecolhaCallback;
  public readonly simpleModeFilterSource: Array<IBancosExtratoSimpleModeFilter>;
  public dataGridInstanceName: string;
  public dataGridBankAccountInstanceName: string;
  public bankAccount: IJsonBankAccount;
  public startDate: TDate;
  public endDate: TDate;
  public precision: number;
  public autoSuggestDoc: boolean;
  public allowAutoSuggestDoc: boolean;
  public lancarDocsEmSerie: boolean;
  public toolbarItemLancarDocsEmSerie: boolean;
  public fullExtract: boolean;
  public tableLegend: TTableLegend;
  public selectedTransactions: Array<string>;
  public labelAddPredefDesc: string;
  public labelEditPredefDesc: string;
  public labelRemovePredefDesc: string;
  public disableViewFocusedDocument: boolean;
  public disableIgnoreDocuments: boolean;
  public disableStopIgnoreDocuments: boolean;
  public disableAddPredefDesc: boolean;
  public disableEditPredefDesc: boolean;
  public disableRemovePredefDesc: boolean;
  public disableDoReceipt: boolean;
  public disableDoPayment: boolean;
  public disableSeeSimilarPostedDocs: boolean;
  public mergeMode: boolean;
  public contabilidadeDigitalSearchResult: IJsonContabDigitalGDocViewerRecolhaSearchResult;
  public warnings: Array<string>;
  public panelSliderVisible: boolean;
  public panelSliderTransactions: Array<IBancosExtratoPanelSliderTransaction>;
  public panelSliderTransactionsCollapsed: boolean;
  public panelSliderTransaction: IBancosExtratoTransaction;
  public panelSliderContabilidadeDigitalSearchResult: IJsonContabDigitalGDocViewerRecolhaSearchResult;
  public optionDocViewerFooterCollapsed: boolean;
  public docViewerFooterCollapsed: boolean;
  public simpleModeFilter: IBancosExtratoSimpleModeFilter;
  public promiseActivateCGBanking: Promise<void>;
  public promiseRefreshAccount: Promise<void>;
  public promiseEditBankAccount: Promise<void>;
  public promiseAddBankAccount: Promise<void>;
  public promiseGetTransactionsByConciliacaoBancaria: Promise<void>;

  private readonly _dataGridStore: ArrayStore<IBancosExtratoTransaction, string>;
  private readonly _dataGridStoreBankAccounts: CustomStore<IJsonBankAccount, string>;
  private readonly _registeredToolbarInstances: Map<string, IPlToolbarInstance>;
  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;
  private readonly _dataGridColumnPredefinido: IDevExpressDataGridColumn;
  private readonly _dataGridColumnDocDigital: IDevExpressDataGridColumn;
  private readonly _btnConfigurations: IPlToolbarItem;
  private readonly _btnContabilidadeDigital: IPlToolbarItem;
  private readonly _subscriptionConfigOptions: Subscription;
  private readonly _subscriptionWindowWidth: Subscription;
  private readonly _subscriptionSidebarToggled: Subscription;
  private readonly _subscriptionOnDocumentKeydown: Subscription;
  private readonly _subscriptionOnDocumentKeyup: Subscription;
  private _subscriptionConfigsBancosExtrato: Subscription;
  private _inited: boolean;
  private _dataGridInstance: dxDataGrid<IBancosExtratoTransaction, string>;
  private _dataGridScrollable: Scrollable;
  private _maintenanceDocsContabilidade: IEntityMaintenanceInstance;
  private _maintenancePreDefinidos: IEntityMaintenanceInstance;
  private _conciliacaoBancariaBancoId: string;
  private _focusedTransactionKey: string;
  private _previouslyFocusedTransactionKey: string;
  private _holdingShift: boolean;
  private _holdingCtrl: boolean;
  private _windowWidth: number;
  private _sidebarToggled: boolean;

  constructor(
    protected readonly _injector: Injector,
    private readonly _renderer: Renderer2,
    private readonly _plAlertService: PlAlertService,
    private readonly _plPageWrapperService: PlPageWrapperService,
    private readonly _authService: AuthService,
    private readonly _cgModalService: CGModalService,
    private readonly _cgExceptionService: CGExceptionService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _devExpressDataGridUIService: DevExpressDataGridUIService,
    private readonly _bancosExtratoService: BancosExtratoUiService,
    private readonly _docContabilidadeService: DocContabilidadeService,
    private readonly _recibosService: RecibosService,
    private readonly _pagamentosService: PagamentosService
  ) {
    super(_injector);
    this.entidadeBancariaEstados = EEntidadeBancariaEstado;
    this.entidadeBancariaDocDigital = EEntidadeBancariaDocDigital;
    this.configOptionsInstanceName = EConfigOptionsInstanceName.BANCOS_EXTRATO;
    this.configOptionsGroupName = EGroupName.CONTABILIDADE;
    this._dataGridColumnPredefinido = {
      dataField: 'predefinido',
      dataType: 'string',
      caption: 'bancosextrato.fields.predefinido',
      editCellTemplate: 'editCellTemplatePredefinido'
    };
    this._dataGridColumnDocDigital = {
      dataField: 'docDigital',
      dataType: 'number',
      caption: 'docscontabilidade.fields.temDocDigital',
      cellTemplate: 'dataGridTemplateDocDigital',
      cssClass: 'text-center',
      visible: false,
      allowEditing: false,
      showInColumnChooser: false,
      lookup: DEV_EXPRESS_DATA_GRID_LOOKUP_ENTIDADE_BANCARIA_DOC_DIGITAL
    };
    this.dataGrid = {
      columns: [
        {dataField: 'date', dataType: 'date', caption: 'global.text.date', sortIndex: 0, sortOrder: 'desc', allowEditing: false, width: 92, minWidth: 92},
        {dataField: 'description', dataType: 'string', caption: 'bancosextrato.fields.description', allowEditing: false},
        {dataField: 'amount', dataType: 'double', caption: 'bancosextrato.fields.amount', allowEditing: false},
        {dataField: 'accountBalance', dataType: 'double', caption: 'global.text.saldo', allowEditing: false},
        {dataField: 'predefinidoID', visible: false, showInColumnChooser: false},
        {dataField: 'predefinidoNome', visible: false, showInColumnChooser: false},
        this._dataGridColumnPredefinido,
        {
          dataField: 'estado',
          visible: false,
          allowEditing: false,
          lookup: DEV_EXPRESS_DATA_GRID_LOOKUP_ENTIDADE_BANCARIA_ESTADO
        },
        {
          dataField: 'order',
          dataType: 'number',
          visible: false,
          sortOrder: 'asc',
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          showInColumnChooser: false
        },
        this._dataGridColumnDocDigital,
        {dataField: 'observacoes', dataType: 'string', caption: 'bancosextrato.fields.observacoes'},
        {
          name: 'actions',
          cellTemplate: 'dataGridTemplateActions',
          alignment: 'center',
          width: 48,
          allowEditing: false,
          allowExporting: false,
          allowFiltering: false,
          allowHeaderFiltering: false,
          allowHiding: false,
          allowReordering: false,
          allowResizing: false,
          allowSorting: false,
          showInColumnChooser: false
        }
      ],
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      editing: {
        allowUpdating: true,
        mode: 'cell',
        refreshMode: 'repaint',
        selectTextOnEditStart: true
      },
      filterPanel: {visible: true},
      filterRow: {visible: false},
      focusedRowEnabled: true,
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false},
      height: '65vh',
      masterDetail: {
        enabled: false,
        template: 'dataGridTemplateDetail'
      },
      pager: {visible: true},
      paging: {
        enabled: true,
        pageSize: 50
      },
      scrolling: {preloadEnabled: true},
      searchPanel: {
        visible: true
      },
      selection: {
        mode: 'multiple',
        selectAllMode: 'allPages',
        showCheckBoxesMode: 'none'
      },
      showBorders: true,
      toolbar: {
        items: [
          {
            location: 'before',
            locateInMenu: 'auto',
            template: 'toolbarTemplateActions'
          },
          {
            location: 'after',
            template: 'toolbarTemplateLancarDocsEmSerie'
          },
          'exportButton',
          'columnChooserButton',
          'searchPanel'
        ]
      }
    };
    this.dataGridBankAccounts = {
      columns: [
        {dataField: 'descricao', dataType: 'string', caption: 'bancosextrato.fields.description'},
        {dataField: 'saldo', dataType: 'double', caption: 'bancosextrato.fields.saldo'},
        {dataField: 'dataSyncDados', dataType: 'datetime', caption: 'bancosextrato.fields.dataSyncDados'},
        {dataField: 'nConta', dataType: 'string', caption: 'bancosextrato.fields.nConta'},
        {dataField: 'nomeBanco', dataType: 'string', caption: 'bancosextrato.fields.nomeBanco'},
        {dataField: 'iban', dataType: 'string', caption: 'bancosextrato.fields.iban'},
        {dataField: 'visivel', dataType: 'boolean', caption: 'bancosextrato.fields.visivel', visible: false, showInColumnChooser: true}
      ],
      columnFixing: {enabled: false},
      columnHidingEnabled: false,
      filterPanel: {visible: true},
      filterValue: ['visivel', '=', true],
      focusedRowEnabled: true,
      grouping: {contextMenuEnabled: false},
      groupPanel: {visible: false},
      headerFilter: {visible: false},
      height: 400,
      pager: {visible: false},
      paging: {enabled: false},
      scrolling: {rowRenderingMode: 'virtual'}
    };
    this._dataGridStore = new ArrayStore<IBancosExtratoTransaction, string>({
      key: 'id',
      data: [],
      onLoaded: (result: Array<IBancosExtratoTransaction>) => {
        if (this._focusedTransactionKey) {
          for (const item of result) {
            if (this._focusedTransactionKey === item.id) {
              this._evaluateActions();
            }
          }
        }
      },
      onInserted: (transaction: IBancosExtratoTransaction, transactionId: string) => {
        if (transactionId === this._focusedTransactionKey) {
          this._evaluateActions();
        }
      },
      onUpdated: (transactionId: string, values: Partial<IBancosExtratoTransaction>) => {
        if (transactionId === this._focusedTransactionKey) {
          this._evaluateActions();
        }
        if (Object.prototype.hasOwnProperty.call(values, 'predefinido') && this._dataGridInstance.isRowExpanded(transactionId)) {
          this._suggestTransactionDetail(this._dataGridInstance, [transactionId], false);
        }
        if (Object.prototype.hasOwnProperty.call(values, 'observacoes')) {
          this._bancosExtratoService.updateTransactionObservacoes(transactionId, values.observacoes);
        }
      },
      onRemoved: (transactionId: string) => {
        if (transactionId === this._focusedTransactionKey) {
          this._setFocusedTransaction(undefined);
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onPush: (pushChanges: Array<any>) => {
        if (this._focusedTransactionKey) {
          const focusedTransactionKey: string = this._focusedTransactionKey;
          const changes: Array<IBancosExtratoDataGridStoreChange> = pushChanges;
          for (const change of changes) {
            if (change.key === focusedTransactionKey) {
              if (change.type === 'remove') {
                this._setFocusedTransaction(undefined);
              } else {
                this._setFocusedTransaction(change.key);
              }
            }
          }
        }
      }
    });
    this.dataGridSource = new DataSource({
      store: this._dataGridStore,
      reshapeOnPush: true,
      pushAggregationTimeout: 50
    });
    this._dataGridStoreBankAccounts = new CustomStore({
      key: 'bankAccountId',
      loadMode: 'processed',
      load: (loadOptions: LoadOptions) => {
        return this._bancosExtratoService.checkConsumeLicense().then(() => {
          return this._queryBankAccounts(loadOptions);
        });
      }
    });
    this.dataGridSourceBankAccounts = new DataSource({
      store: this._dataGridStoreBankAccounts
    });
    this.filterPredefinido = 'cgBanking=true';
    this.customActionPreDefinido = {
      caption: 'docscontabilidade.text.maintenancePreDefinidos',
      action: async (selectedKey: number): Promise<void> => {
        if (!this._maintenancePreDefinidos) {
          this._maintenancePreDefinidos = this._entityMaintenanceService.build(ENTITY_NAME_CONTABILIDADE_PREDEFINIDOS);
        }
        await this._maintenancePreDefinidos.maintenanceEdit(selectedKey);
      }
    };
    this.contabilidadeDigitalSearchParams = this._bancosExtratoService.gDocViewerRecolhaSearchParams;
    this.propertiesAutocompleteBankAccount = {
      showFilter: false,
      events: {
        click: (value: string, event: MouseEvent) => {
          if (event.shiftKey && event.ctrlKey) {
            this._openAuthorizations().catch((reason: unknown) => {
              this._logger.error(reason);
            });
          }
        }
      }
    };
    this.propertiesDate = {modelOptions: {updateOn: 'blur'}};
    this.contabDigitalAttachment = {gDocId: undefined, gDocFolderId: undefined, docOCRCabID: undefined};
    this.contabDigitalPanelSliderAttachment = {gDocId: undefined, gDocFolderId: undefined, docOCRCabID: undefined};
    this.panelSliderToolbarInstanceId = TOOLBAR_NAME_PANEL_SLIDER;
    this.docViewerState = EArquivoDigitalDocViewerState.Searching;
    this.docViewerAddDocsMode = EArquivoDigitalDocViewerAddDocsMode.ChooseFolder;
    this.callbackDocViewer = {};
    this.callbackPanelSliderDocViewer = {};
    this.startDate = moment().startOf('month');
    this.endDate = moment().endOf('month');
    this.autoSuggestDoc = true;
    this.allowAutoSuggestDoc = true;
    this.lancarDocsEmSerie = false;
    this.toolbarItemLancarDocsEmSerie = false;
    this.fullExtract = false;
    this.selectedTransactions = [];
    this.mergeMode = false;
    this.warnings = [];
    this.panelSliderVisible = false;
    this.panelSliderTransactionsCollapsed = true;
    this._registeredToolbarInstances = new Map<string, IPlToolbarInstance>();
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IJsonDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
    this._btnConfigurations = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'configurations',
      type: 'button',
      order: this.btnSave.order + 1,
      class: 'btn-sm btn-light',
      iconLeft: '<i class="fa fa-fw fa-list-alt"></i>',
      caption: 'bancosextrato.actions.configurations',
      visible: false,
      click: () => this._openConfigurations()
    };
    this._btnContabilidadeDigital = {
      groupId: TOOLBAR_GROUP_ID,
      id: 'contabilidadeDigital',
      type: 'button',
      order: this._btnConfigurations.order + 1,
      class: 'btn-info',
      iconLeft: '<i class="fa fa-fw fa-file-pdf-o"></i>&nbsp;',
      caption: 'docscontabilidade.toolbar.contabilidadeDigital',
      visible: false,
      click: () => {
        this._toggleContabilidadeDigital();
      }
    };
    this._inited = false;
    this._holdingShift = false;
    this._holdingCtrl = false;
    this.simpleModeFilterSource = [
      {id: EBancosExtratoSimpleModeFilter.None, caption: this._translateService.instant('global.text.notDefined')},
      {id: EBancosExtratoSimpleModeFilter.OnlyEntradas, caption: this._translateService.instant('bancosextrato.simplemodefilter.onlyentradas')},
      {id: EBancosExtratoSimpleModeFilter.OnlySaidas, caption: this._translateService.instant('bancosextrato.simplemodefilter.onlysaidas')},
      {id: EBancosExtratoSimpleModeFilter.OnlyNaoLancado, caption: this._translateService.instant('bancosextrato.simplemodefilter.onlynaoLancado')},
      {id: EBancosExtratoSimpleModeFilter.OnlyLancado, caption: this._translateService.instant('bancosextrato.simplemodefilter.onlylancado')}
    ];
    this.simpleModeFilter = this.simpleModeFilterSource[0];
    const params: IEntidadeBancariaResultParams = <IEntidadeBancariaResultParams>this._transition.params();
    const entidadeBancariaResult: IEntidadeBancariaResult = params.entidadeBancaria;
    if (entidadeBancariaResult) {
      switch (entidadeBancariaResult.provider) {
        case EEntidadeBancariaProvider.Tink:
          if (entidadeBancariaResult.code || entidadeBancariaResult.credentialsId) {
            this._plAlertService.success('bancosextrato.text.successAddBankAccount');
          } else if (entidadeBancariaResult.error && entidadeBancariaResult.error !== 'USER_CANCELLED') {
            let error: string;
            switch (entidadeBancariaResult.error) {
              case 'BAD_REQUEST':
              case 'AUTHENTICATION_ERROR':
              case 'INTERNAL_ERROR':
              case 'TEMPORARY_ERROR':
                error = `bancosextrato.errorsTinkLink.${entidadeBancariaResult.error}`;
                break;
              default:
                error = entidadeBancariaResult.message;
                break;
            }
            this._plAlertService.error(this._translateService.instant('bancosextrato.errors.newBankAccount', {error: error}));
          }
          break;
        case EEntidadeBancariaProvider.NBanks:
          // not implemented
          break;
        case EEntidadeBancariaProvider.UnifiedPost:
          if (entidadeBancariaResult.code || entidadeBancariaResult.credentialsId) {
            this._plAlertService.success('bancosextrato.text.successAddBankAccount');
          } else if (entidadeBancariaResult.error && entidadeBancariaResult.error !== 'USER_CANCELLED') {
            let error: string;
            switch (entidadeBancariaResult.error) {
              case 'BAD_REQUEST':
              case 'AUTHENTICATION_ERROR':
              case 'INTERNAL_ERROR':
              case 'TEMPORARY_ERROR':
                error = `bancosextrato.errorsUnifiedPost.${entidadeBancariaResult.error}`;
                break;
              default:
                error = entidadeBancariaResult.message;
                break;
            }
            this._plAlertService.error(this._translateService.instant('bancosextrato.errors.newBankAccount', {error: error}));
          }
          break;
      }
    }

    this._subscriptionConfigOptions = this._bancosExtratoService.getOptions().subscribe((configOptions: TConfigOptions<boolean, IBancosExtratoConfigOptions>) => {
      this.autoSuggestDoc = configOptions.get('autoSuggestDoc').value;
      this.lancarDocsEmSerie = configOptions.get('lancarDocsEmSerie').value;
      this.optionDocViewerFooterCollapsed = configOptions.get('docViewerFooterCollapsed').value;
      this._evaluateDocViewerFooterCollapsed();
      if (this._inited) {
        this._evaluateDataGrid();
        if (this._dataGridInstance) {
          this._dataGridInstance.endUpdate();
          this._deselectAll();
        }
      }
    });

    this._subscriptionWindowWidth = this._plDocumentService.windowWidth().subscribe((value: number) => {
      this._windowWidth = value;
      this.allowAutoSuggestDoc = this._windowWidth >= EScreenSize.Large;
      if (this._inited) {
        this._evaluateDataGrid();
      }
    });

    this._subscriptionSidebarToggled = this._plPageWrapperService.toggled().subscribe((value: boolean) => {
      this._sidebarToggled = value;
      if (this._inited) {
        this._evaluateDataGridLancarDocsEmSerie();
      }
    });

    this._subscriptionOnDocumentKeydown = fromEvent<KeyboardEvent>(this._document, 'keydown', {passive: true}).subscribe((event: KeyboardEvent) => {
      this._onDocumentKeydown(event);
    });
    this._subscriptionOnDocumentKeyup = fromEvent<KeyboardEvent>(this._document, 'keyup', {passive: true}).subscribe((event: KeyboardEvent) => {
      this._onDocumentKeyup(event);
    });
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._inited = true;
    this.toolbar.addButton(this._btnConfigurations).addButton(this._btnContabilidadeDigital);
    let labelActions: string;
    if (!this.simpleMode) {
      this.dataGridInstanceName = DATAGRID_INSTANCE_NAME;
      this.dataGridBankAccountInstanceName = DATAGRID_BANK_ACCOUNT_INSTANCE_NAME;
      labelActions = 'actions';
      this.tableLegend = TABLE_LEGEND_BANCOS_EXTRATO;
      if (this.empresaTemContabilidadeDigital) {
        if (this.contabilidadeDigital) {
          this._dataGridColumnDocDigital.visible = true;
          this._dataGridColumnDocDigital.showInColumnChooser = this._dataGridColumnDocDigital.visible;
        }
      } else {
        this.contabilidadeDigital = false;
      }
      this._subscriptionConfigsBancosExtrato = this._bancosExtratoService
        .configs()
        .pipe(skip(1))
        .subscribe((configs: IJsonBancosExtratoConfigs) => {
          this.configsBancosExtrato = configs;
        });
    } else {
      this.contabilidadeDigital = false;
      this.dataGrid.filterPanel.visible = false;
      this.dataGridInstanceName = DATAGRID_INSTANCE_NAME_SIMPLE;
      this.dataGridBankAccountInstanceName = DATAGRID_BANK_ACCOUNT_INSTANCE_NAME_SIMPLE;
      labelActions = 'actionssimple';
      this.tableLegend = TABLE_LEGEND_BANCOS_EXTRATO_SIMPLE;
      this._dataGridColumnPredefinido.visible = false;
      this._dataGridColumnPredefinido.showInColumnChooser = this._dataGridColumnPredefinido.visible;
    }
    this.labelAddPredefDesc = this._translateService.instant(`bancosextrato.${labelActions}.addPredefDesc`);
    this.labelEditPredefDesc = this._translateService.instant(`bancosextrato.${labelActions}.editPredefDesc`);
    this.labelRemovePredefDesc = this._translateService.instant(`bancosextrato.${labelActions}.removePredefDesc`);
    this._evaluateToolbar();
    this._evaluateDataGrid();
    this._evaluateDataGridFilterValue();
    this._evaluateActions();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.dataGridSource.dispose();
    this.dataGridSourceBankAccounts.dispose();
    this._subscriptionConfigOptions.unsubscribe();
    this._subscriptionWindowWidth.unsubscribe();
    this._subscriptionSidebarToggled.unsubscribe();
    this._subscriptionOnDocumentKeydown.unsubscribe();
    this._subscriptionOnDocumentKeyup.unsubscribe();
    if (this._subscriptionConfigsBancosExtrato) {
      this._subscriptionConfigsBancosExtrato.unsubscribe();
    }
    for (const toolbarInstanceId of this._registeredToolbarInstances.keys()) {
      this._plToolbarService.unRegisterInstance(toolbarInstanceId);
    }
  }

  public setIsMobile(value: boolean): void {
    super.setIsMobile(value);
    this._evaluateDocViewerFooterCollapsed();
  }

  public activateCGBanking(): Promise<void> {
    if (this.empresaTemCGBanking || this.licencaStoreModePublic) {
      return Promise.resolve();
    }
    this.promiseActivateCGBanking = this._bancosExtratoService.consumeLicense().finally(() => {
      this.promiseActivateCGBanking = undefined;
    });
    return this.promiseActivateCGBanking;
  }

  public refreshAccount(): Promise<void> {
    if (!this.bankAccount?.bankAccountId) {
      return Promise.resolve();
    }
    this.promiseRefreshAccount = this._bancosExtratoService.checkConsumeLicense().then(() => {
      return this._bancosExtratoService.refreshBankAccount(this.bankAccount.bankAccountId).then(() => {
        this._plAlertService.success('bancosextrato.text.successRefreshBankAccount');
      });
    });
    return this.promiseRefreshAccount;
  }

  public editBankAccount(): Promise<void> {
    if (!this.bankAccount) {
      return Promise.resolve();
    }
    this.promiseEditBankAccount = this._bancosExtratoService.checkConsumeLicense().then(() => {
      const modalInstance = this._cgModalService.showVanilla(BancosExtratoModuleEditBankAccountModalComponent, {size: 'md'});
      const componentInstance: BancosExtratoModuleEditBankAccountModalComponent = modalInstance.componentInstance;
      componentInstance.bankAccount = copy(this.bankAccount);
      return modalInstance.result.then((result: IJsonBankAccount) => {
        this.bankAccount = result;
        return Promise.resolve(this._dataGridStore.load()).then((transactions: Array<IJsonEntidadeBancariaTransaction>) => {
          this._dataGridStore.push(
            transactions
              .filter((transaction: IJsonEntidadeBancariaTransaction) => transaction.accountId === result.bankAccountId)
              .map<IBancosExtratoDataGridStoreChange>((transaction: IJsonEntidadeBancariaTransaction) => {
                return {type: 'update', key: transaction.id, data: {nDiarioPag: result.nDiarioRecebimentos, nDiarioRec: result.nDiarioPagamentos}};
              })
          );
        });
      });
    });
    return this.promiseEditBankAccount;
  }

  public addBankAccount(): Promise<void> {
    this.promiseAddBankAccount = this._bancosExtratoService.checkConsumeLicense().then(async () => {
      const state: string = this._uiRouterGlobals.current.name;
      try {
        if (this.configsBancosExtrato.apiProvider === EEntidadeBancariaProvider.Tink) {
          await this._bancosExtratoService.newBankAccount({provider: this.configsBancosExtrato.apiProvider, state: state});
        } else if (this.configsBancosExtrato.apiProvider === EEntidadeBancariaProvider.UnifiedPost) {
          const modalInstance = this._cgModalService.showVanilla(BancosExtratoUnifiedPostAccountModalComponent, {size: 'md'});
          const componentInstance: BancosExtratoUnifiedPostAccountModalComponent = modalInstance.componentInstance;
          componentInstance.state = state;
          await modalInstance.result;
        }
      } catch (reason: unknown) {
        this._logger.error(reason);
      }
    });
    return this.promiseAddBankAccount;
  }

  public changedBankAccount(value: IJsonBankAccount): void {
    this.bankAccount = value;
    if (this.bankAccount) {
      let promise: Promise<void>;
      if (!this.simpleMode && !this.bankAccount.nConta) {
        promise = this._cgModalService
          .showOkCancel('global.text.confirmation', 'bancosextrato.text.confirmEditBankAccount', {
            btnOkText: 'global.btn.yes',
            btnCancelText: 'global.btn.no'
          })
          .then(() => this.editBankAccount());
      }
      Promise.resolve(promise).finally(() => {
        timeout().then(() => {
          const element: HTMLInputElement = this._element.querySelector<HTMLInputElement>('input[name="startdate"]');
          focusElement(element);
        });
      });
    }
  }

  public changedStartDate(value: TDate): void {
    this.startDate = moment(value).startOf('month');
    if (!this.simpleMode) {
      this.endDate = this.startDate.clone().endOf('month');
    }
  }

  public getTransactionsByConciliacaoBancaria(): Promise<void> {
    if (this.simpleMode) {
      return Promise.resolve();
    }
    this.clearWarnings();
    this.promiseGetTransactionsByConciliacaoBancaria = this._bancosExtratoService.checkConsumeLicense().then(() => {
      return this._cgModalService.show(BancosExtratoModuleImportByReconciliationModalComponent, {size: 'md'}).then((result: IBancosExtratoGetTransactionsByConciliacaoBancariaResult) => {
        this._setTransactions(result);
        this.startDate = result.startDate;
        this.endDate = result.endDate;
        this._conciliacaoBancariaBancoId = result.banco.concilBancoCabID;
        this.bankAccount = undefined;
      });
    });
    return this.promiseGetTransactionsByConciliacaoBancaria;
  }

  public async changedLancarDocsEmSerie(value: boolean): Promise<void> {
    await this._bancosExtratoService.setOption('lancarDocsEmSerie', value);
    this._dataGridInstance.collapseAll(-1);
    await this._deselectAll();
    this._evaluateDataGridSelection();
  }

  public toggleFullExtract(): void {
    if (this.simpleMode) {
      return;
    }
    this.fullExtract = !this.fullExtract;
    this._evaluateDataGridFilterValue();
  }

  public changedCellPreDefinido(templateData: IDevExpressDataGridEditCellTemplateData<IBancosExtratoTransaction, string>, value: IJsonPreDefinidoContab): void {
    const preDefinidosID: number = value?.preDefinidosID || null;
    const predefinidoNome: string = value?.descricao || null;
    this._dataGridInstance.cellValue(templateData.rowIndex, 'predefinidoID', preDefinidosID);
    this._dataGridInstance.cellValue(templateData.rowIndex, 'predefinidoNome', predefinidoNome);
    const predefinido: IBancosExtratoTransaction['predefinido'] = preDefinidosID ? `${preDefinidosID} - ${predefinidoNome}` : '';
    templateData.setValue(predefinido, predefinido);
    templateData.component.saveEditData();
  }

  public clearWarnings(): void {
    this.warnings = [];
  }

  public openRowActions(transaction: IJsonEntidadeBancariaTransaction, event: Event): Promise<void> {
    event.stopPropagation();
    const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateRowActions(transaction);
    return this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.target, actions).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public onDataGridInitialized({component}: IDevExpressDataGridEventOnInitialized<IBancosExtratoTransaction, string>): void {
    this._dataGridInstance = component;
  }

  public onDataGridContentReady({component}: IDevExpressDataGridEventOnContentReady): void {
    this._dataGridScrollable = component.getScrollable();
  }

  public onDataGridRowPrepared({data, rowElement, rowType}: IDevExpressDataGridEventOnRowPrepared<IBancosExtratoTransaction, string>): void {
    if (rowType !== 'data') {
      return;
    }
    let cssClass: string;
    if (data.estado === EEntidadeBancariaEstado.Lancado) {
      cssClass = EBancosExtratoTableLegendColors.Lancado;
    } else if (data.estado === EEntidadeBancariaEstado.Ignorado) {
      cssClass = EBancosExtratoTableLegendColors.Ignorado;
    } else if (data.estado === EEntidadeBancariaEstado.NaoLancadoComDocPossivel) {
      cssClass = EBancosExtratoTableLegendColors.ComDocumentoSemelhante;
    } else if (data.nContaCorrente || data.predefinidoID) {
      cssClass = EBancosExtratoTableLegendColors.ComConfig;
    }
    if (cssClass) {
      this._renderer.addClass(rowElement, cssClass);
    } else if (rowElement.style.backgroundColor) {
      this._renderer.removeClass(rowElement, cssClass);
    }
  }

  public onDataGridCellPrepared({rowType, data, column, cellElement}: IDevExpressDataGridEventOnCellPrepared<IBancosExtratoTransaction, string>): void {
    if (!this.simpleMode && rowType === 'data' && !ESTADOS_NAO_LANCADOS.includes(data.estado)) {
      // Desativar checkbox de seleção para linhas já lançadas
      if (column.command === 'select') {
        const elementSelectCheckbox: HTMLElement = cellElement.querySelector(SELECTOR_DEV_EXPRESS_COMPONENT_SELECT_CHECKBOX);
        if (elementSelectCheckbox) {
          this._renderer.addClass(elementSelectCheckbox, 'd-none');
          const dxCheckbox: DxCheckbox = <DxCheckbox>DxCheckbox.getInstance(elementSelectCheckbox);
          if (dxCheckbox) {
            dxCheckbox.option('disabled', true);
          }
        }
      }
      // Desativar sugerir lançamento para linhas já lançadas
      else if (column.command === 'expand') {
        const elementGroup: HTMLElement = cellElement.querySelector(SELECTOR_DEV_EXPRESS_DATAGRID_GROUP);
        if (elementGroup) {
          this._renderer.addClass(elementGroup, 'd-none');
        }
      }
    }
  }

  public onDataGridFocusedRowChanged({row}: IDevExpressDataGridEventOnFocusedRowChanged<IBancosExtratoTransaction, string>): void {
    this._previouslyFocusedTransactionKey = this._focusedTransactionKey;
    const transaction: IBancosExtratoTransaction = row?.rowType === 'data' ? row.data : undefined;
    this._setFocusedTransaction(transaction?.id);
  }

  public async onDataGridCellClick({component, key, column, rowType, event}: IDevExpressDataGridEventOnCellClick<IBancosExtratoTransaction, string>): Promise<void> {
    const isDetailExpand = column?.type === 'detailExpand';
    if (this.simpleMode || rowType !== 'data' || (column.type && !isDetailExpand) || column.dataField === 'predefinido' || column.dataField === 'observacoes') {
      return;
    }
    let keys: Array<string> = [key];
    if (!this.lancarDocsEmSerie) {
      // Prevent master detail from openning immediately
      if (isDetailExpand) {
        event.preventDefault();
        event.stopImmediatePropagation();
      }

      if (this._dataGridInstance.isRowSelected(key)) {
        await this._dataGridInstance.deselectRows(keys);
        return;
      }

      const shiftKey: boolean = event.shiftKey;
      const ctrlKey: boolean = event.ctrlKey;
      this._holdingShift = shiftKey;
      this._holdingCtrl = ctrlKey;

      if (!shiftKey) {
        await this._dataGridInstance.selectRows(keys, ctrlKey || !this.allowAutoSuggestDoc || !this.autoSuggestDoc);
      } else {
        keys = this._calculateMultipleSelectionKeys(component, ESTADOS_NAO_LANCADOS);
        await this._dataGridInstance.selectRows(keys, true);
      }
    } else if (column.type !== 'selection') {
      if (isDetailExpand) {
        if (this._dataGridInstance.isRowExpanded(key)) {
          return;
        }
        // Prevent master detail from opening immediately
        event.preventDefault();
        event.stopImmediatePropagation();

        // Make sure this is a master detail than can be opened
        keys = this._filterTransactionsIds(component, keys, ESTADOS_NAO_LANCADOS);
        if (keys.length) {
          await this._openTransactionDetail(component, keys);
        }
      } else if (!this._dataGridInstance.isRowSelected(key)) {
        if (event.shiftKey && this._previouslyFocusedTransactionKey) {
          keys = this._calculateMultipleSelectionKeys(component, ESTADOS_NAO_LANCADOS);
        }
        await this._dataGridInstance.selectRows(keys, true);
      } else {
        await this._dataGridInstance.deselectRows(keys);
      }
    }
  }

  public async onDataGridSelectionChanged({component, currentDeselectedRowKeys, selectedRowKeys}: IDevExpressDataGridEventOnSelectionChanged<IBancosExtratoTransaction, string>): Promise<void> {
    this.selectedTransactions = selectedRowKeys;
    if (this.selectedTransactions.length > 0) {
      const estados: Array<EEntidadeBancariaEstado> = [EEntidadeBancariaEstado.Lancado];
      if (!this.allowAutoSuggestDoc || !this.autoSuggestDoc || this.lancarDocsEmSerie || this._holdingShift || this._holdingCtrl) {
        estados.push(EEntidadeBancariaEstado.Ignorado);
      }
      const invalidSelectedTransactions: Array<IBancosExtratoTransaction> = this._getSelectedTransactions(component, estados);
      if (invalidSelectedTransactions.length) {
        return component.deselectRows(invalidSelectedTransactions.map((transaction: IBancosExtratoTransaction) => transaction.id)).then(() => undefined);
      }
    }
    this._evaluateDataGridSelection();
    this._evaluateActions();
    if (!this.simpleMode && !this.lancarDocsEmSerie && currentDeselectedRowKeys.length) {
      await Promise.all(currentDeselectedRowKeys.map((key: string) => (component.isRowExpanded(key) ? component.collapseRow(key) : undefined)));
    }
    const transacoesSelecionadasNaoLancadas: Array<string> = this._getSelectedTransactionsIds(component, ESTADOS_NAO_LANCADOS);
    if (
      this.simpleMode ||
      this.lancarDocsEmSerie ||
      transacoesSelecionadasNaoLancadas.length !== 1 ||
      !this.allowAutoSuggestDoc ||
      !this.autoSuggestDoc ||
      this._holdingShift ||
      this._holdingCtrl ||
      this.panelSliderVisible
    ) {
      return Promise.resolve();
    }
    await this._openTransactionDetail(component, transacoesSelecionadasNaoLancadas);
    return Promise.resolve();
  }

  public onDataGridContextMenuPreparing({target, row, event}: IDevExpressDataGridEventOnContextMenuPreparing<IBancosExtratoTransaction, string>): void {
    if (target !== 'content' || !row || row.rowType !== 'data') {
      return;
    }
    event.preventDefault();
    const transaction: IBancosExtratoTransaction = row.data;
    const actions: Array<IPlDynamicVisualsSecondaryClickAction> = this._generateRowActions(transaction);
    if (!this.simpleMode && this.lancarDocsEmSerie) {
      actions.unshift(
        {caption: 'bancosextrato.actions.selectAll', click: () => this._selectAll()},
        {caption: 'bancosextrato.actions.selectAllMesmoPredefinido', click: () => this._selectAllMesmoPredefinido()},
        {caption: 'bancosextrato.actions.selectAllComPredefinido', click: () => this._selectAllComPredefinido()},
        {caption: 'bancosextrato.actions.selectAllComDocDigital', click: () => this._selectAllComDocDigital()},
        {caption: 'bancosextrato.actions.selectAllMesmaConfgPorDesc', click: () => this._selectAllMesmaConfgPorDesc(row.data.bankPreDefCfgId)},
        {caption: 'bancosextrato.actions.deselectAll', click: () => this._deselectAll()},
        {divider: true}
      );
    }
    this._devExpressDataGridUIService.openContextMenu(<HTMLElement>event.target, actions).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public onDataGridBankAccountFocusedRowChanged(event: IDevExpressDataGridEventOnFocusedRowChanged<IJsonBankAccount, string>, autocomplete: PlEditAutocompleteComponent): void {
    if (event.row?.rowType === 'data') {
      autocomplete.selectItem(event.row.data);
    }
  }

  public onDataGridWheel(event: WheelEvent): void {
    if (this.simpleMode || this.lancarDocsEmSerie || !this.allowAutoSuggestDoc || !this.autoSuggestDoc || (!this._holdingShift && !this._holdingCtrl) || !this._dataGridScrollable) {
      return;
    }
    event.preventDefault();
    this._dataGridScrollable.scrollBy({left: event.deltaX, top: event.deltaY});
  }

  public docContabilidadeInit({element}: IPlLifeCycleEvent, transaction: IBancosExtratoTransaction, contabDigitalAttachment: IDocContabilidadeContabDigitalAttachment): void {
    this._docContabilidadeService.listenForSaveEvent({
      identifier: `${SAVE_PROMPT_IDENTIFIER}-${transaction.id}`,
      callbackGetDoc: () => transaction.jsonDocContabilidade,
      callbackOnSave: (attachDigitalDocs: boolean) => this._saveTableDoc(transaction, attachDigitalDocs, contabDigitalAttachment),
      simulation: false,
      contabilidadeDigital: () => this.contabilidadeDigital,
      whenNode: element
    });
  }

  public docContabilidadeDestroy(transaction: IBancosExtratoTransaction): void {
    this._docContabilidadeService.clearForSaveEventListener(`${SAVE_PROMPT_IDENTIFIER}-${transaction.id}`);
  }

  public changedContabDigitalAttachment(contabDigitalAttachment: IDocContabilidadeContabDigitalAttachment, {folder, attachment}: IArquivoDigitalDocViewerEvtChangedAttachment): void {
    contabDigitalAttachment.gDocId = undefined;
    contabDigitalAttachment.gDocFolderId = undefined;
    contabDigitalAttachment.docOCRCabID = undefined;
    if (this.contabilidadeDigital) {
      if (isObject(folder)) {
        contabDigitalAttachment.gDocFolderId = folder.folderID;
      }
      if (isObject(attachment)) {
        contabDigitalAttachment.gDocId = attachment.cab.docID;
        contabDigitalAttachment.docOCRCabID = attachment.cab.docOCRCabID;
      }
    }
  }

  public changedDocViewerFooterCollapsed(value: boolean): void {
    this._bancosExtratoService.setOption('docViewerFooterCollapsed', value).catch((reason: unknown) => {
      this._logger.error(reason);
    });
  }

  public onResizerValuesChanged(callback: IContabDigitalDocViewerRecolhaCallback): void {
    if (isObject(callback.pdf) && isFunction(callback.pdf.updateSize)) {
      setTimeout(callback.pdf.updateSize);
    }
  }

  public simpleModeFilterChanged(item: IBancosExtratoSimpleModeFilter): void {
    if (!item) {
      item = this.simpleModeFilterSource[0];
    }
    this.simpleModeFilter = item;
    this._evaluateDataGridFilterValue();
  }

  public readonly fnSearch = (): Promise<void> => this._search();

  public readonly fnViewFocusedDocument = (): Promise<void> => this._viewFocusedDocument();

  public readonly fnIgnoreDocuments = (): Promise<void> => this._ignoreDocuments();

  public readonly fnAddPredefDescFocusedTransaction = (): Promise<void> => this._addPredefDescByKey(this._focusedTransactionKey);

  public readonly fnEditPredefDescFocusedTransaction = (): Promise<void> => this._editPredefDescByKey(this._focusedTransactionKey);

  public readonly fnRemovePredefDescFocusedTransaction = (): Promise<void> => this._removePredefDescByKey(this._focusedTransactionKey);

  public readonly fnDoReceiptFocusedTransaction = (): Promise<void> => this._doReceiptByKey(this._focusedTransactionKey);

  public readonly fnDoPaymentFocusedTransaction = (): Promise<void> => this._doPaymentByKey(this._focusedTransactionKey);

  public readonly fnOnLoadPreDefinido =
    (transaction: IBancosExtratoTransaction) =>
    (params: IDocContabilidadeLoadPreDefinidoParams): TValueOrPromise<IJsonDocContabilidade> => {
      return this._onLoadPreDefinido(transaction, params);
    };

  public readonly fnDocContabilidadeAfterInitDocument = (transaction: IBancosExtratoTransaction) => (): void => {
    this._docContabilidadeAfterInitDocument(transaction);
  };

  public readonly fnDoLancarDocs = (): Promise<void> => this._lancarDocs();

  @HostListener('document:click', ['$event'])
  public onDocumentClick(event: MouseEvent): void {
    const element: HTMLElement = <HTMLElement>event.target;
    // Prevent clicks inside dropdown datagrid from closing autocomplete
    if (element.matches('.dx-overlay-content') || element.classList.value.includes('dx-filterbuilder') || element.closest('.dx-overlay-content')) {
      event.stopImmediatePropagation();
    }
  }

  protected _onConfigurationsChanged(): void {
    this.licencaStoreModePublic = this.configurations.licenca.modoCGOn;
    this.empresaTemCGBanking = this.configurations.empresa.temCGBanking;
    this.empresaTemContabilidadeDigital = this.configurations.empresa.temContabilidadeDigital;
    this.cgOnCGBankingExpired = this.configurations.empresa.cgon.cgBankingExpired;
    this.acessoBankingAutomation = this.configurations.acessos.erpcloud.addons.bankingAutomation.acesso;
    this.precision = this.configurations.contabilidade.decimais.valor;
    if (this._inited) {
      this._evaluateToolbar();
    }
  }

  private _queryBankAccounts(loadOptions: LoadOptions): Promise<Array<IJsonBankAccount>> {
    let search = '';
    if (loadOptions.filter) {
      const queryFilter: string = devExpressDataGridFiltersToQueryFilter(this._dataGridInstance, loadOptions.filter);
      if (queryFilter) {
        search += search ? `&(${queryFilter})` : queryFilter;
      }
    }
    const order: string = loadOptions.sort ? devExpressDataGridSortToOrder(loadOptions.sort) : undefined;
    return this._bancosExtratoService.queryBankAccounts({pesquisa: search, ordena: order}).then((response: THttpQueryResponse<IJsonBankAccount>) => {
      return response.body.list;
    });
  }

  private _getTransactions(): Promise<void> {
    if (!this.bankAccount?.bankAccountId) {
      this._plAlertService.error('bancosextrato.errors.emptyBankAccount');
      return Promise.resolve();
    }
    this._conciliacaoBancariaBancoId = undefined;
    this.clearWarnings();
    return this._bancosExtratoService
      .getTransactions(this.bankAccount.bankAccountId, this.startDate, this.endDate, this.simpleMode, {reportExceptions: false})
      .then((response: HttpResponse<IJsonEntidadeBancariaSearchResult>) => {
        this._setTransactions(response.body);
      })
      .catch((reason: HttpErrorResponse) => {
        const exception: ICGExceptionError = this._cgExceptionService.get(reason);
        if (exception.class === 'ECGBankingBankAccountConsentExpiredException') {
          const renewBankAccountUrl: string = exception.message;
          return this._cgModalService
            .showOkCancel('bancosextrato.text.bankAccountConsentExpiredTitle', 'bancosextrato.text.bankAccountConsentExpiredMessage', {
              btnOkText: 'global.btn.yes',
              btnCancelText: 'global.btn.no'
            })
            .then(() => {
              const state: string = this._uiRouterGlobals.current.name;
              this._bancosExtratoService.renewBankAccount(renewBankAccountUrl, {provider: this.configsBancosExtrato.apiProvider, state: state});
            });
        }
        this._plAlertService.error(exception.message);
        return Promise.resolve();
      });
  }

  private _setTransactions({transactions, warnings}: IJsonEntidadeBancariaSearchResult): void {
    this._dataGridStore.clear();
    let promise: Promise<unknown>;
    if (this._dataGridInstance) {
      this._dataGridInstance.beginUpdate();
      this._setDataGridFocusedTransaction(undefined);
      this._dataGridInstance.collapseAll(-1);
      promise = this._dataGridInstance
        .getDataSource()
        .reload()
        .catch((reason: unknown) => {
          this._logger.error(reason);
        });
    }
    Promise.resolve(promise).finally(() => {
      this._dataGridStore.push(
        transactions.map<IBancosExtratoDataGridStoreChange>((transaction: IJsonEntidadeBancariaTransaction) => {
          return {type: 'insert', data: this._transformEntidadeBancariaTransaction(transaction)};
        })
      );
      this._previouslyFocusedTransactionKey = undefined;
      if (this._dataGridInstance) {
        this._dataGridInstance.endUpdate();
      }
      this.warnings = warnings;
    });
  }

  private _transformEntidadeBancariaTransaction(transaction: IJsonEntidadeBancariaTransaction): IBancosExtratoTransaction {
    const hasValidPredefinido: boolean = isNumber(transaction.predefinidoID) && transaction.predefinidoID > 0;
    return {
      ...transaction,
      predefinidoID: hasValidPredefinido ? transaction.predefinidoID : undefined,
      predefinidoNome: hasValidPredefinido ? transaction.predefinidoNome : undefined,
      predefinido: hasValidPredefinido ? `${transaction.predefinidoID} - ${transaction.predefinidoNome}` : ''
    };
  }

  private _setDataGridFocusedTransaction(transaction: IBancosExtratoTransaction): void {
    const key: string = transaction?.id;
    if (this._dataGridInstance) {
      this._dataGridInstance.option('focusedRowKey', key);
    } else {
      this.dataGrid.focusedRowKey = key;
    }
  }

  private _setFocusedTransaction(transactionId: string): void {
    this._focusedTransactionKey = transactionId;
    this._evaluateActions();
  }

  private _evaluateToolbar(): void {
    this._btnConfigurations.visible = (this.empresaTemCGBanking || !this.licencaStoreModePublic) && this.acessoBankingAutomation;
    this._btnContabilidadeDigital.visible = !this.simpleMode && this._btnConfigurations.visible && this.empresaTemContabilidadeDigital;
    this._btnContabilidadeDigital.class = !this.contabilidadeDigital ? 'btn-info' : 'btn-info active';
  }

  private _evaluateDataGrid(): void {
    if (this._dataGridInstance) {
      this._dataGridInstance.beginUpdate();
    }
    this._evaluateDataGridSelection();
    this._evaluateDataGridMasterDetail();
    this._evaluateDataGridLancarDocsEmSerie();
    if (this._dataGridInstance) {
      this._dataGridInstance.endUpdate();
    }
  }

  private _evaluateDataGridFilterValue(): void {
    let filterValue: TDevExpressFilterExpression =
      !this.simpleMode && !this.fullExtract ? [['estado', '=', EEntidadeBancariaEstado.NaoLancado], 'or', ['estado', '=', EEntidadeBancariaEstado.NaoLancadoComDocPossivel]] : [];
    if (this.simpleMode && !filterValue.length) {
      switch (this.simpleModeFilter.id) {
        case EBancosExtratoSimpleModeFilter.None:
          break;
        case EBancosExtratoSimpleModeFilter.OnlyEntradas:
          filterValue = ['amount', '>', 0];
          break;
        case EBancosExtratoSimpleModeFilter.OnlySaidas:
          filterValue = ['amount', '<', 0];
          break;
        case EBancosExtratoSimpleModeFilter.OnlyNaoLancado:
          filterValue = ['estado', '=', EEntidadeBancariaEstado.NaoLancado];
          break;
        case EBancosExtratoSimpleModeFilter.OnlyLancado:
          filterValue = ['estado', '=', EEntidadeBancariaEstado.Lancado];
          break;
      }
    }
    if (this._dataGridInstance) {
      this._dataGridInstance.option('filterValue', filterValue);
    } else {
      this.dataGrid.filterValue = filterValue;
    }
  }

  private _evaluateDataGridSelection(): void {
    const showCheckBoxesMode: TDevExpressDataGridSelectionShowCheckBoxesMode =
      this.simpleMode === false && (this.lancarDocsEmSerie || !this.allowAutoSuggestDoc || !this.autoSuggestDoc || this._holdingShift || this._holdingCtrl) ? 'always' : 'none';
    if (this._dataGridInstance) {
      if (this._dataGridInstance.option('selection.showCheckBoxesMode') !== showCheckBoxesMode) {
        this._dataGridInstance.option('selection.showCheckBoxesMode', showCheckBoxesMode);
      }
    } else if (showCheckBoxesMode !== this.dataGrid.selection.showCheckBoxesMode) {
      this.dataGrid.selection.showCheckBoxesMode = showCheckBoxesMode;
    }
  }

  private _evaluateDataGridMasterDetail(): void {
    const masterDetailEnabled: boolean = this.simpleMode === false && this.allowAutoSuggestDoc && this.autoSuggestDoc && !this._holdingShift && !this._holdingCtrl;
    if (this._dataGridInstance) {
      this._dataGridInstance.option('masterDetail.enabled', masterDetailEnabled);
    } else {
      this.dataGrid.masterDetail.enabled = masterDetailEnabled;
    }
  }

  private _evaluateDataGridLancarDocsEmSerie(): void {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    this.toolbarItemLancarDocsEmSerie = !this.simpleMode && ((this._sidebarToggled && this._windowWidth >= 1585) || (!this._sidebarToggled && this._windowWidth >= 1335));
    if (!this._dataGridInstance) {
      (<ToolbarItem>this.dataGrid.toolbar.items[DATAGRID_TOOLBAR_ITEM_INDEX_LANCAR_DOCS_EM_SERIE]).visible = this.toolbarItemLancarDocsEmSerie;
    } else {
      this._dataGridInstance.option(`toolbar.items[${DATAGRID_TOOLBAR_ITEM_INDEX_LANCAR_DOCS_EM_SERIE}].visible`, this.toolbarItemLancarDocsEmSerie);
    }
  }

  private _evaluateActions(): void {
    if (!this._focusedTransactionKey) {
      this.disableViewFocusedDocument = true;
      this.disableIgnoreDocuments = true;
      this.disableStopIgnoreDocuments = true;
      this.disableAddPredefDesc = true;
      this.disableEditPredefDesc = true;
      this.disableRemovePredefDesc = true;
      this.disableDoReceipt = true;
      this.disableDoPayment = true;
      this.disableSeeSimilarPostedDocs = true;
    } else {
      this._dataGridStore.byKey(this._focusedTransactionKey).then((focusedTransaction: IBancosExtratoTransaction) => {
        this.disableViewFocusedDocument = focusedTransaction.estado !== EEntidadeBancariaEstado.Lancado;
        this.disableIgnoreDocuments = !this._getSelectedTransactions(this._dataGridInstance, ESTADOS_NAO_LANCADOS).length;
        this.disableStopIgnoreDocuments = focusedTransaction.estado !== EEntidadeBancariaEstado.Ignorado;
        const evaluatedActions: IBancosExtratoTransactionEvaluatedActions = evaluateBancosExtratoTransactionActions(focusedTransaction);
        this.disableAddPredefDesc = !evaluatedActions.addPredefDesc;
        this.disableEditPredefDesc = !evaluatedActions.editPredefDesc;
        this.disableRemovePredefDesc = !evaluatedActions.removePredefDesc;
        this.disableDoReceipt = !this.simpleMode || !evaluatedActions.doReceipt;
        this.disableDoPayment = !this.simpleMode || !evaluatedActions.doPayment;
        this.disableSeeSimilarPostedDocs = !evaluatedActions.seeSimilarPostedDocs;
      });
    }
  }

  private _generateRowActions(transaction: IJsonEntidadeBancariaTransaction): Array<IPlDynamicVisualsSecondaryClickAction> {
    const actions: Array<IPlDynamicVisualsSecondaryClickAction> = [];
    if (!this.simpleMode) {
      actions.push(
        {caption: 'bancosextrato.actions.viewDocument', disabled: this.disableViewFocusedDocument, click: () => this._viewFocusedDocument()},
        {caption: 'bancosextrato.actions.ignoreDocuments', disabled: this.disableIgnoreDocuments, click: () => this._ignoreDocuments()},
        {caption: 'bancosextrato.actions.stopIgnoreDocuments', disabled: this.disableStopIgnoreDocuments, click: () => this._stopIgnoreDocuments()}
      );
    } else {
      actions.push(
        {caption: 'bancosextrato.actions.doReceipt', icon: 'fa-user-plus', disabled: this.disableDoReceipt, click: () => this._doReceipt(transaction)},
        {caption: 'bancosextrato.actions.doPayment', icon: 'fa-credit-card-alt', disabled: this.disableDoPayment, click: () => this._doPayment(transaction)}
      );
    }
    actions.push(
      {divider: true},
      {caption: 'bancosextrato.actions.addPredefDesc', icon: 'fa-plus', disabled: this.disableAddPredefDesc, click: () => this._addPredefDesc(transaction)},
      {caption: 'bancosextrato.actions.editPredefDesc', icon: 'fa-pencil', disabled: this.disableEditPredefDesc, click: () => this._editPredefDesc(transaction)},
      {caption: 'bancosextrato.actions.removePredefDesc', icon: 'fa-ban', disabled: this.disableRemovePredefDesc, click: () => this._removePredefDesc(transaction)},
      {divider: true},
      {caption: 'bancosextrato.actions.seeSimilarPostedDocs', icon: 'fa-files-o', disabled: this.disableSeeSimilarPostedDocs, click: () => this._seeSimilarPostedDocs(transaction)}
    );
    return actions;
  }

  private _openTransactionDetail(component: dxDataGrid, transactionsIds: Array<string>): Promise<void> {
    if (!transactionsIds.length) {
      return Promise.resolve();
    }
    return this._suggestTransactionDetail(component, transactionsIds, true).then(() => {
      const transactionId: string = transactionsIds[0];
      if (component.isRowExpanded(transactionId)) {
        return Promise.resolve();
      }
      return component.expandRow(transactionId);
    });
  }

  private async _suggestTransactionDetail(component: dxDataGrid, transactionsIds: Array<string>, refresh: boolean): Promise<void> {
    component.beginCustomLoading(undefined);
    const transactions: Array<IBancosExtratoTransaction> = this._transactionsIdsToTransactions(component, transactionsIds);
    if (!transactions.length) {
      return;
    }
    const transaction: IBancosExtratoTransaction = transactions[0];
    if (this.contabilidadeDigital) {
      if (isFunction(this.callbackDocViewer.clear)) {
        await this.callbackDocViewer.clear();
      }
      if (transaction.docDigital !== EEntidadeBancariaDocDigital.NaoTem) {
        const responseSearchResult = await this._bancosExtratoService.searchContabilidadeAttachments(transaction.dateShort, transaction.amount);
        this.contabilidadeDigitalSearchResult = responseSearchResult.body;
        transaction.docOCRCabID = this.contabilidadeDigitalSearchResult.anexos.length > 0 ? this.contabilidadeDigitalSearchResult.anexos[0].cab.docOCRCabID : undefined;
      }
    }
    const docContabilidade: IJsonDocContabilidade = await this._suggestDocContabilidade(transactions, undefined, undefined, transaction.docOCRCabID).finally(() => {
      component.endCustomLoading();
    });
    if (refresh) {
      // Since previously expanded row's views aren't destroyed we have to force refresh jsons
      delete transaction.jsonDocContabilidade;
      delete transaction.jsonPreDefinido;
      await timeout();
    }
    this._initTransactionDetail(transaction, docContabilidade, this.contabDigitalAttachment);
  }

  private _initTransactionDetail(transaction: IBancosExtratoTransaction, docContabilidade: IJsonDocContabilidade, contabDigitalAttachment: IDocContabilidadeContabDigitalAttachment): void {
    // Init models
    transaction.jsonDocContabilidade = docContabilidade;
    transaction.jsonPreDefinido = transaction.jsonDocContabilidade.predefinido;
    transaction.callbackDocContabilidade = {};
    transaction.loadPreDefinidoFirstTime = true;

    // Init toolbar
    if (!transaction.toolbarInstanceId) {
      transaction.toolbarInstanceId = `${TOOLBAR_NAME}-${transaction.id}`;
    }
    const toolbarInstance: IPlToolbarInstance = this._plToolbarService.getInstance(transaction.toolbarInstanceId);
    toolbarInstance.setItems([
      {
        groupId: TOOLBAR_GROUP_ID,
        id: TOOLBAR_BTN_SAVE,
        order: 110,
        type: 'button',
        iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>',
        caption: 'entity.action.save',
        class: 'btn-primary',
        click: () => this._saveTableDoc(transaction, true, contabDigitalAttachment)
      },
      {
        groupId: TOOLBAR_GROUP_ID,
        id: TOOLBAR_BTN_SAVE_WITHOUT_ATTACHMENT,
        order: 111,
        type: 'button',
        iconLeft: '<i class="fa fa-fw fa-floppy-o"></i>&nbsp;',
        caption: 'docscontabilidade.toolbar.saveWithoutAttach',
        class: 'btn-light',
        visible: this.contabilidadeDigital,
        click: () => this._saveTableDoc(transaction, false, contabDigitalAttachment)
      }
    ]);
    this._registeredToolbarInstances.set(transaction.toolbarInstanceId, toolbarInstance);
  }

  private _toggleContabilidadeDigital(): void {
    if (!this.empresaTemContabilidadeDigital) {
      return;
    }

    this.contabilidadeDigital = !this.contabilidadeDigital;
    this._evaluateToolbar();

    // Handle datagrid column
    if (this._dataGridInstance) {
      this._dataGridInstance.columnOption(this._dataGridColumnDocDigital.dataField, {
        visible: this.contabilidadeDigital,
        showInColumnChooser: this.contabilidadeDigital
      });
    } else {
      this._dataGridColumnDocDigital.visible = this.contabilidadeDigital;
      this._dataGridColumnDocDigital.showInColumnChooser = this._dataGridColumnDocDigital.visible;
    }

    // Handle master detail toolbars
    for (const toolbarInstance of this._registeredToolbarInstances.values()) {
      const btnSave = toolbarInstance.find(TOOLBAR_BTN_SAVE_WITHOUT_ATTACHMENT);
      if (btnSave) {
        btnSave.visible = this.contabilidadeDigital;
      }
    }
  }

  private _suggestDocContabilidade(transactions: Array<IJsonEntidadeBancariaTransaction>, periodo?: string, nDiario?: number, docOCRCabID?: string): Promise<IJsonDocContabilidade> {
    const data: IJsonBancosExtratoSuggestDocContabilidadeData = {
      transactions: transactions,
      modoMerge: this.mergeMode,
      periodo: periodo,
      nDiario: nDiario,
      docOCRCabID: docOCRCabID
    };
    return this._bancosExtratoService.suggestDocContabilidade(data).then((response: HttpResponse<IJsonDocContabilidade>) => response.body);
  }

  private _search(): Promise<void> {
    return this._bancosExtratoService.checkConsumeLicense().then(() => this._getTransactions());
  }

  private _viewDocument(extPocCabID: string): Promise<void> {
    if (!this._maintenanceDocsContabilidade) {
      this._maintenanceDocsContabilidade = this._entityMaintenanceService.build(ENTITY_NAME_DOCS_CONTABILIDADE);
    }
    return this._maintenanceDocsContabilidade.maintenanceEdit(extPocCabID).then(() => undefined);
  }

  private _viewFocusedDocument(): Promise<void> {
    if (this.disableViewFocusedDocument) {
      return Promise.resolve();
    }
    return this._getTransactionByKey(this._focusedTransactionKey).then((transaction: IJsonEntidadeBancariaTransaction) => this._viewDocument(transaction.extPocCabID));
  }

  private _ignoreDocuments(): Promise<void> {
    const transactions: Array<IJsonEntidadeBancariaTransaction> = this._getSelectedTransactions(this._dataGridInstance, ESTADOS_NAO_LANCADOS);
    if (!transactions.length) {
      return Promise.resolve();
    }
    return this._cgModalService.showOkCancel('bancosextrato.text.confirmIgnoreDocumentsTitle', 'bancosextrato.text.confirmIgnoreDocumentsMessage').then(() => {
      this._dataGridInstance.beginCustomLoading(undefined);
      return this._bancosExtratoService
        .ignoreTransactions(transactions)
        .then(() => {
          const keys: Array<string> = transactions.map<string>((transaction: IJsonEntidadeBancariaTransaction) => transaction.id);
          this._dataGridStore.push(
            keys.map<IBancosExtratoDataGridStoreChange>((transactionId: string) => {
              return {type: 'update', key: transactionId, data: {estado: EEntidadeBancariaEstado.Ignorado}};
            })
          );
          return this._dataGridInstance.deselectRows(keys).then(() => {
            this._plAlertService.success('bancosextrato.text.successIgnoreDocuments');
          });
        })
        .finally(() => {
          this._dataGridInstance.endCustomLoading();
        });
    });
  }

  private _stopIgnoreDocuments(): Promise<void> {
    if (!this._focusedTransactionKey) {
      return Promise.resolve();
    }
    return this._getTransactionByKey(this._focusedTransactionKey).then((transaction: IBancosExtratoTransaction) => {
      if (transaction.estado !== EEntidadeBancariaEstado.Ignorado) {
        return Promise.resolve();
      }
      const transactions: Array<IBancosExtratoTransaction> = [transaction];
      this._dataGridInstance.beginCustomLoading(undefined);
      return this._bancosExtratoService
        .stopIgnoreTransactions(transactions)
        .then(() => {
          this._dataGridStore.push(
            transactions.map<IBancosExtratoDataGridStoreChange>((transactionItem: IBancosExtratoTransaction) => {
              return {type: 'update', key: transactionItem.id, data: {estado: EEntidadeBancariaEstado.NaoLancado}};
            })
          );
          this._plAlertService.success('bancosextrato.text.successStopIgnoreDocuments');
        })
        .finally(() => {
          this._dataGridInstance.endCustomLoading();
        });
    });
  }

  private _addPredefDescByKey(transactionId: string): Promise<void> {
    return this._getTransactionByKey(transactionId).then((transaction: IJsonEntidadeBancariaTransaction) => this._addPredefDesc(transaction));
  }

  private _editPredefDescByKey(transactionId: string): Promise<void> {
    return this._getTransactionByKey(transactionId).then((transaction: IJsonEntidadeBancariaTransaction) => this._editPredefDesc(transaction));
  }

  private _removePredefDescByKey(transactionId: string): Promise<void> {
    return this._getTransactionByKey(transactionId).then((transaction: IJsonEntidadeBancariaTransaction) => this._removePredefDesc(transaction));
  }

  private _doReceiptByKey(transactionId: string): Promise<void> {
    return this._getTransactionByKey(transactionId).then((transaction: IJsonEntidadeBancariaTransaction) => this._doReceipt(transaction));
  }

  private _doPaymentByKey(transactionId: string): Promise<void> {
    return this._getTransactionByKey(transactionId).then((transaction: IJsonEntidadeBancariaTransaction) => this._doPayment(transaction));
  }

  private _addPredefDesc(transaction: IJsonEntidadeBancariaTransaction): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(BancosExtratoModulePredefDescModalComponent, {size: 'md'});
    const componentInstance: BancosExtratoModulePredefDescModalComponent = modalInstance.componentInstance;
    componentInstance.simpleMode = this.simpleMode;
    componentInstance.transaction = transaction;
    componentInstance.initialData = {
      descriptionKey: transaction.description,
      preDefinidosID: transaction.predefinidoID,
      preDefinidosNome: transaction.predefinidoNome
    };
    return modalInstance.result.then(() => {
      this._dataGridInstance.beginCustomLoading(undefined);
      return this._refreshTransactionsPredefinidoByBankPreDefCfgId('').finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
    });
  }

  private _editPredefDesc(transaction: IJsonEntidadeBancariaTransaction): Promise<void> {
    const bankPreDefCfgId: string = transaction.bankPreDefCfgId;
    const modalInstance = this._cgModalService.showVanilla(BancosExtratoModulePredefDescModalComponent, {size: 'md'});
    const componentInstance: BancosExtratoModulePredefDescModalComponent = modalInstance.componentInstance;
    componentInstance.simpleMode = this.simpleMode;
    componentInstance.transaction = transaction;
    componentInstance.initialData = {bankPreDefCfgId: bankPreDefCfgId};
    return modalInstance.result.then(() => {
      this._dataGridInstance.beginCustomLoading(undefined);
      return this._refreshTransactionsPredefinidoByBankPreDefCfgId(bankPreDefCfgId).finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
    });
  }

  private _removePredefDesc(transaction: IJsonEntidadeBancariaTransaction): Promise<void> {
    const bankPreDefCfgId: string = transaction.bankPreDefCfgId;
    return this._cgModalService.showOkCancel('bancosextrato.text.confirmDeletePredefDescTitle', 'bancosextrato.text.confirmDeletePredefDescMessage', {size: 'md'}).then(() => {
      this._dataGridInstance.beginCustomLoading(undefined);
      return this._bancosExtratoService
        .deletePredefDesc(bankPreDefCfgId)
        .then(() => this._refreshTransactionsPredefinidoByBankPreDefCfgId(bankPreDefCfgId))
        .then(() => {
          this._plAlertService.success('bancosextrato.text.deletePredefDesc');
        })
        .finally(() => {
          this._dataGridInstance.endCustomLoading();
        });
    });
  }

  private _doReceipt(transaction: IJsonEntidadeBancariaTransaction): Promise<void> {
    return this._doMovab(transaction, true);
  }

  private _doPayment(transaction: IJsonEntidadeBancariaTransaction): Promise<void> {
    return this._doMovab(transaction, false);
  }

  private _doMovab(transaction: IJsonEntidadeBancariaTransaction, receipt: boolean): Promise<void> {
    if (!this.simpleMode) {
      return Promise.resolve();
    }
    const modalInstance = this._cgModalService.showVanilla(BancosExtratoMovabModalComponent, {size: 'xxl'});
    const componentInstance: BancosExtratoMovabModalComponent = modalInstance.componentInstance;
    componentInstance.transaction = transaction;
    componentInstance.receipt = receipt;
    return modalInstance.result.then(({extPocCabID, estado, nConta, nomeConta, email}: IBancosExtratoModuleMovabModalResult) => {
      this._dataGridStore.update(transaction.id, {extPocCabID: extPocCabID, estado: estado});
      this.dataGridSource.reload();
      const message = receipt ? 'bancosextrato.text.promptPrintReceipt' : 'bancosextrato.text.promptPrintPayment';
      return this._cgModalService.showOkCancel('global.text.confirmation', message, {size: 'md'}).then(() => {
        const params: IReciboImprimirModalParams | IPagamentoImprimirModalParams = {
          extPocCabID: extPocCabID,
          nConta: nConta,
          nomeConta: nomeConta,
          email: email
        };
        return receipt ? this._recibosService.getPdf(params) : this._pagamentosService.getPdf(params);
      });
    });
  }

  private _openAuthorizations(): Promise<void> {
    return this._bancosExtratoService.checkConsumeLicense().then(() => {
      return this._authService.hasAuthority(ROLE.ADMIN).then((hasAuthority: boolean) => {
        if (!hasAuthority) {
          return Promise.resolve();
        }
        return this._cgModalService.show(BancosExtratoModuleAuthorizationsModalComponent, {size: 'xxl'});
      });
    });
  }

  private async _openConfigurations(): Promise<void> {
    await this._bancosExtratoService.checkConsumeLicense();
    const modalInstance = this._cgModalService.showVanilla(BancosExtratoModuleConfigModalComponent, {size: 'md'});
    const componentInstance: BancosExtratoModuleConfigModalComponent = modalInstance.componentInstance;
    componentInstance.simpleMode = this.simpleMode;
    return modalInstance.result.then(() => undefined);
  }

  private _seeSimilarPostedDocs(transaction: IJsonEntidadeBancariaTransaction): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(BancosExtratoModuleDocsSemelhantesModalComponent, {size: 'xxl'});
    const componentInstance: BancosExtratoModuleDocsSemelhantesModalComponent = modalInstance.componentInstance;
    componentInstance.transaction = transaction;
    return modalInstance.result
      .then(() => this._bancosExtratoService.loadTransactionDefaultValues(transaction))
      .then((response: HttpResponse<IJsonEntidadeBancariaTransaction>) => {
        const updatedTransaction: IBancosExtratoTransaction = this._transformEntidadeBancariaTransaction(response.body);
        this._dataGridStore.push([
          {type: 'remove', key: updatedTransaction.id},
          {type: 'insert', key: updatedTransaction.id, data: updatedTransaction}
        ]);
      });
  }

  private _selectAll(): Promise<void> {
    return this._dataGridInstance.selectAll();
  }

  private _selectAllMesmoPredefinido(): Promise<void> {
    if (!this._focusedTransactionKey) {
      return Promise.resolve();
    }
    return this._getTransactionByKey(this._focusedTransactionKey).then((focusedTransaction: IJsonEntidadeBancariaTransaction) => {
      const predefinidoID: number = focusedTransaction.predefinidoID;
      const rows: Array<IJsonEntidadeBancariaTransaction> = this._dataGridInstance.getDataSource().items();
      const rowsToSelect: Array<string> = rows
        .filter((transaction: IJsonEntidadeBancariaTransaction) => transaction.predefinidoID === predefinidoID)
        .map((transaction: IJsonEntidadeBancariaTransaction) => transaction.id);
      return this._dataGridInstance.selectRows(rowsToSelect, false).then(() => undefined);
    });
  }

  private _selectAllComPredefinido(): Promise<void> {
    const rows: Array<IJsonEntidadeBancariaTransaction> = this._dataGridInstance.getDataSource().items();
    const rowsToSelect: Array<string> = rows
      .filter((transaction: IJsonEntidadeBancariaTransaction) => {
        return (isNumber(transaction.predefinidoID) && transaction.predefinidoID > 0) || !isEmpty(transaction.nContaCorrente);
      })
      .map((transaction: IJsonEntidadeBancariaTransaction) => transaction.id);
    return this._dataGridInstance.selectRows(rowsToSelect, false).then(() => undefined);
  }

  private _selectAllComDocDigital(): Promise<void> {
    const rows: Array<IJsonEntidadeBancariaTransaction> = this._dataGridInstance.getDataSource().items();
    const rowsToSelect: Array<string> = rows
      .filter((transaction: IJsonEntidadeBancariaTransaction) => transaction.docDigital === EEntidadeBancariaDocDigital.Tem)
      .map((transaction: IJsonEntidadeBancariaTransaction) => transaction.id);
    return this._dataGridInstance.selectRows(rowsToSelect, false).then(() => undefined);
  }

  private _selectAllMesmaConfgPorDesc(bankPreDefCfgId: string): Promise<void> {
    const rows: Array<IJsonEntidadeBancariaTransaction> = this._dataGridInstance.getDataSource().items();
    const rowsToSelect: Array<string> = rows
      .filter((transaction: IJsonEntidadeBancariaTransaction) => transaction.bankPreDefCfgId === bankPreDefCfgId)
      .map((transaction: IJsonEntidadeBancariaTransaction) => transaction.id);
    return this._dataGridInstance.selectRows(rowsToSelect, false).then(() => undefined);
  }

  private _deselectAll(): Promise<void> {
    return this._dataGridInstance.deselectAll();
  }

  private async _onLoadPreDefinido(transaction: IBancosExtratoTransaction, {predefinido, docContabilidade}: IDocContabilidadeLoadPreDefinidoParams): Promise<IJsonDocContabilidade> {
    if (transaction.loadPreDefinidoFirstTime) {
      transaction.loadPreDefinidoFirstTime = false;
      docContabilidade.predefinido = predefinido;
      return docContabilidade;
    }
    const transactions: Array<IBancosExtratoTransaction> = this._transactionsIdsToTransactions(this._dataGridInstance, this.selectedTransactions);
    const docOCRCabID: string = transactions.length > 0 ? transactions[0].docOCRCabID : undefined;
    const updatedDocContabilidade: IJsonDocContabilidade = await this._suggestDocContabilidade(transactions, docContabilidade.periodo, docContabilidade.nDiario, docOCRCabID);
    transaction.loadPreDefinidoFirstTime = true;
    return updatedDocContabilidade;
  }

  private _docContabilidadeAfterInitDocument(transaction: IBancosExtratoTransaction): void {
    timeout()
      .then(() => {
        if (this.configsBancosExtrato.movAbAuto) {
          return transaction.callbackDocContabilidade.doMovimentosAbertoForAllLines();
        }
        return Promise.resolve();
      })
      .finally(() => {
        if (transaction.jsonDocContabilidade) {
          let field: EDocContabilidadeField;
          for (let i = 0; i < transaction.jsonDocContabilidade.linhas.length; i++) {
            const linha: IJsonDocContabilidadeLinha = transaction.jsonDocContabilidade.linhas[i];
            if (this._docContabilidadeService.isLineDisabled(linha)) {
              continue;
            }
            if (!linha.nContaDebito && !linha.nContaCredito) {
              field = EDocContabilidadeField.CONTA_DEBITO;
            } else if (!linha.valor) {
              field = EDocContabilidadeField.VALOR;
            }
            if (field) {
              setTimeout(() => {
                transaction.callbackDocContabilidade.focusFieldLinha(field, i);
              });
              break;
            }
          }
          if (!field) {
            if (this._docContabilidadeService.isDocumentoSaldado(transaction.jsonDocContabilidade)) {
              const transactionToolbar: IPlToolbarInstance = this._plToolbarService.getInstance(transaction.toolbarInstanceId);
              if (transactionToolbar) {
                setTimeout(() => {
                  transactionToolbar.focusItem(TOOLBAR_BTN_SAVE);
                });
              }
            } else {
              transaction.callbackDocContabilidade.addLine();
            }
          }
        }
      });
  }

  private _saveTableDoc(transaction: IBancosExtratoTransaction, attachDigitalDocs: boolean, contabDigitalAttachment: IDocContabilidadeContabDigitalAttachment): Promise<void> {
    const promise: Promise<void> = this._saveDoc(transaction.jsonDocContabilidade, attachDigitalDocs, contabDigitalAttachment).then((docContabilidade: IJsonDocContabilidade) => {
      this._plAlertService.success(this._translateService.instant('docscontabilidade.saved', {id: docContabilidade.nDocumento}));
      if (transaction === this.panelSliderTransaction) {
        this.panelSliderVisible = false;
      }
      this._dataGridStore.push(
        this.selectedTransactions.map<IBancosExtratoDataGridStoreChange>((transactionId: string) => {
          return {type: 'remove', key: transactionId};
        })
      );
      return this._dataGridInstance.deselectRows(this.selectedTransactions).then(() => undefined);
    });
    const transactionToolbar: IPlToolbarInstance = this._plToolbarService.getInstance(transaction.toolbarInstanceId);
    if (transactionToolbar) {
      const btnSave: IPlToolbarItem = transactionToolbar.find(!this.contabilidadeDigital || attachDigitalDocs ? TOOLBAR_BTN_SAVE : TOOLBAR_BTN_SAVE_WITHOUT_ATTACHMENT);
      if (btnSave) {
        btnSave.promise = promise;
      }
    }
    return promise;
  }

  private _saveDoc(docContabilidade: IJsonDocContabilidade, attachDigitalDocs: boolean, contabDigitalAttachment: IDocContabilidadeContabDigitalAttachment): Promise<IJsonDocContabilidade> {
    const saldado: boolean = this._docContabilidadeService.isDocumentoSaldado(docContabilidade);
    if (!saldado) {
      const message: string = this._translateService.instant('docscontabilidade.erros.notSaldado');
      this._plAlertService.error(message);
      return Promise.reject(new Error(message));
    }
    const params: IDocsContabilidadePostParams = {anexarGDocId: undefined, anexarGDocFolderId: undefined};
    if (this.contabilidadeDigital && attachDigitalDocs) {
      params.anexarGDocId = contabDigitalAttachment.gDocId;
      params.anexarGDocFolderId = contabDigitalAttachment.gDocFolderId;
    }
    return this._serviceDocsContabilidade.post({body: docContabilidade, params: params}).then((response: HttpResponse<IJsonDocContabilidade>) => response.body);
  }

  private async _lancarDocs(): Promise<void> {
    if (!this.simpleMode) {
      if (this.lancarDocsEmSerie) {
        return this._lancarDocsEmSerie();
      }
      const transactions: Array<IBancosExtratoTransaction> = this._getSelectedTransactions(this._dataGridInstance, ESTADOS_NAO_LANCADOS);
      if (!transactions.length) {
        return Promise.resolve();
      }
      const transaction: IBancosExtratoTransaction = transactions[0];
      if (this.contabilidadeDigital) {
        if (isFunction(this.callbackPanelSliderDocViewer.clear)) {
          await this.callbackPanelSliderDocViewer.clear();
        }
        if (transaction.docDigital !== EEntidadeBancariaDocDigital.NaoTem) {
          const responseSearchResult = await this._bancosExtratoService.searchContabilidadeAttachments(transaction.dateShort, transaction.amount);
          this.panelSliderContabilidadeDigitalSearchResult = responseSearchResult.body;
          transaction.docOCRCabID = this.panelSliderContabilidadeDigitalSearchResult.anexos.length > 0 ? this.panelSliderContabilidadeDigitalSearchResult.anexos[0].cab.docOCRCabID : undefined;
        }
      }
      this._dataGridInstance.beginCustomLoading(undefined);
      const docContabilidade: IJsonDocContabilidade = await this._suggestDocContabilidade(transactions, undefined, undefined, transaction.docOCRCabID).finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
      this.panelSliderTransactions = transactions.map<IBancosExtratoPanelSliderTransaction>((transactionItem: IBancosExtratoTransaction) => {
        return {
          id: transactionItem.id,
          amount: transactionItem.amount,
          dateShort: transactionItem.dateShort,
          description: transactionItem.description,
          accountBalance: transactionItem.accountBalance
        };
      });
      this.panelSliderTransaction = merge({}, transaction, {toolbarInstanceId: this.panelSliderToolbarInstanceId});
      this._initTransactionDetail(this.panelSliderTransaction, docContabilidade, this.contabDigitalPanelSliderAttachment);
      if (this._dataGridInstance) {
        this._dataGridInstance.collapseAll(-1);
      }
      this.panelSliderVisible = true;
    }
    return Promise.resolve();
  }

  private async _lancarDocsEmSerie(): Promise<void> {
    if (this.simpleMode) {
      return Promise.resolve();
    }

    // Confirmation prompt
    const message = !this.contabilidadeDigital ? 'bancosextrato.text.confirmLancarDocsEmSerie' : 'bancosextrato.text.confirmLancarDocsEmSerieContabilidadeDigital';
    const modalInstance = this._cgModalService.showOkCancelVanilla('bancosextrato.actions.lancarDocsEmSerie', message);
    const componentInstance: CGModalOkCancelComponent = modalInstance.componentInstance;
    componentInstance.btnOkText = 'bancosextrato.actions.lancarDocs';
    await modalInstance.result;

    // Execution
    const transactions: Array<IJsonEntidadeBancariaTransaction> = this._getSelectedTransactions(this._dataGridInstance, ESTADOS_NAO_LANCADOS);
    if (!transactions.length) {
      this._plAlertService.info('bancosextrato.errors.emptyDocs');
      return Promise.resolve();
    }
    const data: IJsonBancosExtratoLancarDocumentosEmSerieData = {
      transactions: transactions,
      modoMerge: this.mergeMode,
      contabilidadeDigital: this.contabilidadeDigital
    };
    this._dataGridInstance.beginCustomLoading(undefined);
    return this._bancosExtratoService
      .lancarDocumentosEmSerie(data)
      .then(() => {
        this._plAlertService.success('bancosextrato.text.successLancarDocsEmSerie');
        return this._refreshTransactionsWithLastOperation();
      })
      .finally(() => {
        this._dataGridInstance.endCustomLoading();
      });
  }

  private _getTransactionByKey(transactionId: string): Promise<IBancosExtratoTransaction> {
    return this._dataGridStore.byKey(transactionId);
  }

  private _refreshTransactionsWithLastOperation(): Promise<void> {
    if (this.bankAccount) {
      return this._getTransactions();
    }
    if (this._conciliacaoBancariaBancoId) {
      this.clearWarnings();
      return this._bancosExtratoService
        .getTransactionsByConciliacaoBancaria(this._conciliacaoBancariaBancoId)
        .then((response: HttpResponse<IJsonBancosExtratoGetTransactionsByConciliacaoBancariaResult>) => {
          this._setTransactions(response.body);
          this.startDate = response.body.startDate;
          this.endDate = response.body.endDate;
        });
    }
    return Promise.resolve();
  }

  private _refreshTransactionsPredefinidoByBankPreDefCfgId(bankPreDefCfgId: string): Promise<void> {
    const checkEmpty: boolean = isEmpty(bankPreDefCfgId);
    const transactions: Array<IJsonEntidadeBancariaTransaction> = this._dataGridInstance.getDataSource().items();
    const transactionsToRefresh: Array<IJsonEntidadeBancariaTransaction> = transactions.filter((transaction: IJsonEntidadeBancariaTransaction) => {
      return checkEmpty ? isEmpty(transaction.bankPreDefCfgId) : transaction.bankPreDefCfgId === bankPreDefCfgId;
    });
    return this._refreshTransactionsPredefinido(transactionsToRefresh);
  }

  private _refreshTransactionsPredefinido(transactions: Array<IJsonEntidadeBancariaTransaction>): Promise<void> {
    if (!transactions.length) {
      return Promise.resolve();
    }
    return this._bancosExtratoService.loadTransactionsPredefinido(transactions).then((response: HttpResponse<Array<IJsonEntidadeBancariaTransaction>>) => {
      const changesDelete: Array<IBancosExtratoDataGridStoreChange> = [];
      const changesInsert: Array<IBancosExtratoDataGridStoreChange> = [];
      for (const transaction of response.body) {
        changesDelete.push({type: 'remove', key: transaction.id});
        changesInsert.push({type: 'insert', key: transaction.id, data: this._transformEntidadeBancariaTransaction(transaction)});
      }
      const changes: Array<IBancosExtratoDataGridStoreChange> = changesDelete.concat(changesInsert);
      this._dataGridStore.push(changes);
    });
  }

  private _onDocumentKeydown(event: KeyboardEvent): void {
    if (this._document.activeElement?.matches('input,textarea') || this._cgModalService.hasOpenModals()) {
      return;
    }
    switch (event.key) {
      case KEYCODES.SHIFT:
        this._holdingShift = true;
        if (!this.simpleMode && !this.lancarDocsEmSerie) {
          this._evaluateDataGrid();
        }
        break;
      case KEYCODES.CONTROL:
        this._holdingCtrl = true;
        if (!this.simpleMode && !this.lancarDocsEmSerie) {
          this._evaluateDataGrid();
        }
        break;
    }
  }

  private _onDocumentKeyup(event: KeyboardEvent): void {
    const keyShift: boolean = event.key === KEYCODES.SHIFT;
    const keyCtrl: boolean = event.key === KEYCODES.CONTROL;
    if (keyShift) {
      this._holdingShift = false;
    } else if (keyCtrl) {
      this._holdingCtrl = false;
    }

    const target: HTMLElement = <HTMLElement>event.target;
    if (
      this.simpleMode ||
      this.lancarDocsEmSerie ||
      (!keyShift && !keyCtrl) ||
      this._holdingShift ||
      this._holdingCtrl ||
      target.matches('input') ||
      target.closest('doccontabilidade') ||
      this._cgModalService.hasOpenModals()
    ) {
      return;
    }

    if (this.allowAutoSuggestDoc && this.autoSuggestDoc) {
      this._lancarDocs().then(() => {
        this._evaluateDataGrid();
      });
    }
  }

  private _getSelectedTransactionsIds(component: dxDataGrid, estados?: TEntidadeBancariaEstados): Array<string> {
    let transactions: Array<IBancosExtratoTransaction> = this._transactionsIdsToTransactions(component, this.selectedTransactions);
    if (estados?.length) {
      transactions = transactions.filter((transaction: IJsonEntidadeBancariaTransaction) => estados.includes(transaction.estado));
    }
    return transactions.map((transaction: IBancosExtratoTransaction) => transaction.id);
  }

  private _getSelectedTransactions(component: dxDataGrid, estados?: TEntidadeBancariaEstados): Array<IBancosExtratoTransaction> {
    let transactions: Array<IBancosExtratoTransaction> = this._transactionsIdsToTransactions(component, this.selectedTransactions);
    if (estados?.length) {
      transactions = transactions.filter((transaction: IJsonEntidadeBancariaTransaction) => estados.includes(transaction.estado));
    }
    return transactions;
  }

  private _transactionsIdsToTransactions(component: dxDataGrid, transactionsIds: Array<string>): Array<IBancosExtratoTransaction> {
    const transactions = <Array<IBancosExtratoTransaction>>component.getDataSource().items();
    return transactions.filter((transaction: IBancosExtratoTransaction) => transactionsIds.includes(transaction.id));
  }

  private _filterTransactionsIds(component: dxDataGrid, transactionsIds: Array<string>, estados: TEntidadeBancariaEstados): Array<string> {
    return this._transactionsIdsToTransactions(component, transactionsIds)
      .filter((transaction: IBancosExtratoTransaction) => estados.includes(transaction.estado))
      .map((transaction: IBancosExtratoTransaction) => transaction.id);
  }

  private _calculateMultipleSelectionKeys(component: dxDataGrid, estados?: TEntidadeBancariaEstados): Array<string> {
    return this._calculateMultipleSelection(component, estados).map((transaction: IBancosExtratoTransaction) => transaction.id);
  }

  private _calculateMultipleSelection(component: dxDataGrid, estados?: TEntidadeBancariaEstados): Array<IBancosExtratoTransaction> {
    let selection: Array<IBancosExtratoTransaction> = [];
    if (this._previouslyFocusedTransactionKey && this._focusedTransactionKey) {
      const transactions: Array<IBancosExtratoTransaction> = component.getDataSource().items();
      const startIndex = transactions.findIndex((transaction: IBancosExtratoTransaction) => transaction.id === this._previouslyFocusedTransactionKey);
      const endIndex = transactions.findIndex((transaction: IBancosExtratoTransaction) => transaction.id === this._focusedTransactionKey);
      if (startIndex > -1 && endIndex > -1) {
        if (endIndex > startIndex) {
          selection = transactions.slice(startIndex, endIndex + 1);
        } else if (startIndex > endIndex) {
          selection = transactions.slice(endIndex, startIndex + 1);
        } else {
          selection = transactions.slice(startIndex, endIndex);
        }
      }
    }
    if (estados?.length) {
      selection = selection.filter((transaction: IJsonEntidadeBancariaTransaction) => estados.includes(transaction.estado));
    }
    return selection;
  }

  private _evaluateDocViewerFooterCollapsed(): void {
    this.docViewerFooterCollapsed = !this.isMobile && this.optionDocViewerFooterCollapsed;
  }
}
