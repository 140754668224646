import {HttpResponse} from '@angular/common/http';
import {isObject} from 'pl-comps-angular';
import {EntityServiceBuilder} from '../../services/entity/entity.service.builder';
import {IEntityService} from '../../services/entity/entity.service.interface';
import {ROLE} from '../../services/role.const';
import {IJsonClasseContrato} from '../classescontratos/jsonClasseContrato.entity.interface';
import {IJsonContratoTipo} from '../contratostipos/jsonContratoTipo.entity.interface';
import {ContratosEditComponent} from './components/edit/contratos.entity.edit.component';
import {IJsonContrato, IJsonContratoCab, IJsonContratoContadores} from './jsonContrato.entity.interface';
import {ContratosListComponent} from './components/list/contratos.entity.list.component';
import {ENTITY_NAME_CLASSES_CONTRATOS} from '../classescontratos/classesContratos.entity.interface';
import {ENTITY_NAME_CONTRATOS, IContratosEntity} from './contrato.entity.interface';
import {ENTITY_NAME_CONDICOES_COMERCIAIS} from '../condicoescomerciais/condicoesComerciais.entity.interface';
import {ENTITY_NAME_CONTRATOS_TIPOS} from '../contratostipos/contratosTipos.entity.interface';

export const ENTITY_CONTRATOS: IContratosEntity = {
  name: ENTITY_NAME_CONTRATOS,
  roles: [ROLE.ERP],
  searchPlaceholder: 'contratos.pesquisa',
  pageTitle: 'global.menu.contratos',
  metadata: {
    keyName: 'refCtrCab',
    descriptionName: 'cab.nContratoSemVersaoAsString',
    fields: [
      {name: 'refCtrCab', caption: 'contratos.fields.cab.refCtrCab', visible: false, properties: {disabled: true}},
      {name: 'nClasseCtr', caption: 'contratos.fields.cab.nClasseCtr', placeholder: 'contratos.fields.cab.nTipoContrato', visible: false, validators: {required: true}},
      {name: 'nTipoContrato', caption: 'contratos.fields.cab.nTipoContrato', placeholder: 'contratos.fields.cab.nTipoContratoPlaceholder', visible: false, validators: {required: true}},
      {name: 'nContrato', caption: 'contratos.fields.cab.nContrato', placeholder: 'contratos.fields.cab.nContratoPlaceholder', visible: false, validators: {required: true}},
      {name: 'verContrato', caption: 'contratos.fields.cab.verContrato', placeholder: 'contratos.fields.cab.verContratoPlaceholder', visible: false, validators: {required: true}},
      {name: 'nContratoAsString', caption: 'contratos.fields.cab.nContratoAsString', visible: false, properties: {devExpress: {dataGrid: {allowFiltering: false, allowSearch: false}}}},
      {name: 'nContratoSemVersaoAsString', caption: 'contratos.fields.cab.nContratoSemVersaoAsString', properties: {devExpress: {dataGrid: {allowFiltering: false, allowSearch: false}}}},
      {name: 'classeCtrNome', caption: 'contratos.fields.cab.nClasseCtr', visible: false},
      {name: 'tipoContratoNome', caption: 'contratos.fields.cab.nTipoContrato', visible: false},
      {name: 'descricao', caption: 'contratos.fields.cab.descricao', placeholder: 'contratos.fields.cab.descricaoPlaceholder', validators: {required: true}},
      {
        name: 'nClifo',
        caption: 'contratos.fields.cab.nClifo',
        placeholder: 'contratos.fields.cab.nClifoPlaceholder',
        entity: {name: 'clifos', keyTarget: 'nClifo', outputTarget: 'ClifoNome'},
        validators: {required: true}
      },
      {name: 'clifoNome', caption: 'contratos.fields.cab.clifoNome'},
      {
        name: 'idIdeChav',
        caption: 'contratos.fields.cab.idIdeChav',
        placeholder: 'contratos.fields.cab.idIdeChavPlaceHolder',
        entity: {name: 'idide', keyTarget: 'idIdeChav', outputTarget: 'idideDescricao'},
        validators: {required: false}
      },
      {name: 'dataCtr', type: 'date', caption: 'contratos.fields.cab.dataCtr', placeholder: 'contratos.fields.cab.dataCtrPlaceHolder', validators: {required: false}},
      {name: 'dataInicio', type: 'date', caption: 'contratos.fields.cab.dataInicio', placeholder: 'contratos.fields.cab.dataInicioPlaceholder', validators: {required: false}},
      {name: 'dataFim', type: 'date', caption: 'contratos.fields.cab.dataFim', placeholder: 'contratos.fields.cab.dataFimPlaceholder', validators: {required: false}},
      {
        name: 'estado',
        caption: 'contratos.fields.cab.estado',
        placeholder: 'contratos.fields.cab.estadoPlaceHolder',
        entity: {name: 'estados', keyTarget: 'estado', outputTarget: 'estadoNome'},
        validators: {required: false}
      },
      {name: 'terminado', caption: 'contratos.fields.cab.terminado', validators: {required: false}},
      {name: 'valorGlobal', type: 'double', caption: 'contratos.fields.cab.valorGlobal', visible: false, validators: {required: false}},
      {name: 'totalLiquido', type: 'double', caption: 'contratos.fields.cab.totalLiquido', validators: {required: false}},
      {
        name: 'ccusto',
        caption: 'contratos.fields.cab.ccusto',
        placeholder: 'contratos.fields.cab.ccustoPlaceHolder',
        entity: {name: 'ccustos', keyTarget: 'ccusto', outputTarget: 'ccustoNome'},
        validators: {required: false}
      },
      {
        name: 'nRefProcesso',
        caption: 'contratos.fields.cab.nRefProcesso',
        placeholder: 'contratos.fields.cab.nRefProcessoPlaceHolder',
        entity: {name: 'estados', keyTarget: 'nRefProcesso', outputTarget: 'nRefProcesso'},
        validators: {required: false}
      },
      {
        name: 'codMoeda',
        caption: 'contratos.fields.cab.codMoeda',
        placeholder: 'contratos.fields.cab.codMoedaPlaceHolder',
        entity: {name: 'moedas', keyTarget: 'codMoeda', outputTarget: 'moedaNome'},
        validators: {required: false}
      },
      {name: 'refExterna', caption: 'contratos.fields.cab.refExterna', placeholder: 'contratos.fields.cab.refExternaPlaceholder', validators: {required: false}},
      {
        name: 'codCondComerciais',
        caption: 'contratos.fields.cab.codCondComerciais',
        placeholder: 'contratos.fields.cab.codCondComerciaisPlaceHolder',
        entity: {name: ENTITY_NAME_CONDICOES_COMERCIAIS, keyTarget: 'codCondComerciais', outputTarget: 'condComerciaisNome'},
        validators: {required: false}
      }
    ],
    order: 'refCtrCab',
    listFields: 'nContratoSemVersaoAsString,classeCtrNome,tipoContratoNome,descricao,nClifo,clifoNome,dataInicio,dataFim,totalLiquido',
    searchFields: 'nClasseCtr,nTipoContrato,nContrato,descricao,nClifo,clifoNome,idIdeChav,dataCtr,dataInicio,dataFim,estado,ccusto,nRefProcesso'
  },
  autocomplete: {
    rowTemplate: '{{refCtrCab}} - {{descricao}}',
    output: 'descricao',
    searchFields: 'nClasseCtr,nTipoContrato,nContrato,verContrato,descricao,nClifo,clifoNome'
  },
  actions: {
    new: true,
    detail: true,
    edit: true,
    search: true,
    delete: true,
    filter: true,
    batchAdd: true
  },
  evaluationMethods: {
    evaluateId: (model: IJsonContrato) => (isObject(model) ? (isObject(model.cab) ? model.cab.refCtrCab : (<IJsonContratoCab>model).refCtrCab) : undefined),
    evaluateDescriptionName: (model: IJsonContrato) =>
      isObject(model) ? (isObject(model.cab) ? model.cab.nContratoSemVersaoAsString : (<IJsonContratoCab>model).nContratoSemVersaoAsString) : undefined
  },
  list: {
    state: {
      component: ContratosListComponent
    }
  },
  detail: {
    state: {
      component: ContratosEditComponent,
      resolve: [
        {
          provide: 'classecontrato',
          deps: ['model', EntityServiceBuilder],
          useFactory: (model: IJsonContrato, entityServiceBuilder: EntityServiceBuilder): Promise<IJsonClasseContrato> => {
            if (!model?.cab.nClasseCtr) {
              return Promise.resolve(undefined);
            }
            const serviceClasseContrato = entityServiceBuilder.build<IJsonClasseContrato, IEntityService<IJsonClasseContrato>>(ENTITY_NAME_CLASSES_CONTRATOS);
            return serviceClasseContrato.get({id: model.cab.nClasseCtr, reportExceptions: false}).then((response: HttpResponse<IJsonClasseContrato>) => response.body);
          }
        },
        {
          provide: 'contratotipo',
          deps: ['model', EntityServiceBuilder],
          useFactory: (model: IJsonContrato, entityServiceBuilder: EntityServiceBuilder): Promise<IJsonContratoTipo> => {
            if (!model?.cab.nClasseCtr || !model?.cab.nTipoContrato) {
              return Promise.resolve(undefined);
            }
            const serviceTipoContrato = entityServiceBuilder.build<IJsonContratoTipo, IEntityService<IJsonContratoTipo>>(ENTITY_NAME_CONTRATOS_TIPOS);
            return serviceTipoContrato.get({id: `${model.cab.nClasseCtr}_${model.cab.nTipoContrato}`, reportExceptions: false}).then((response: HttpResponse<IJsonContratoTipo>) => response.body);
          }
        }
      ]
    }
  },
  service: function () {
    this.getContadores = () => {
      return this.get<IJsonContratoContadores>({id: 'contadores'});
    };
  }
};
