import {deepFreeze} from 'pl-comps-angular';
import {IRadioGroup} from '../../../common/interfaces/interfaces';
import {ETemplateSEPASalarios, ETrfBaRGFormato, IJsonTrfBaItemTree, IJsonTrfBaRegistoFich} from './jsonTrfBa.module.interface';
import {TDate} from '../../../common/dates';

export enum ETrfBaNodeRef {
  Document,
  Formato,
  Operacao,
  Data,
  Iban
}

export enum ETrfOperacaoBancaria {
  None,
  Fornecedores,
  Ordenados,
  Transferencias,
  RendaCasa,
  TicketRefeicao
}

export interface ITrfOpenGerarFicheiroModalParams {
  inputs: ITrfGerarFicheiroModalParams;
  showOkCancel?: boolean;
  showOkCancelMessage?: string;
}

export interface ITrfGerarFicheiroModalParams {
  trfBaIDS: string;
  trfBaRegistoFich: IJsonTrfBaRegistoFich;
  rgFormato: ETrfBaRGFormato;
  showDadosFicheiroUploadDGT?: boolean;
  showDataLanc?: boolean;
  showPrioridade?: boolean;
  dataLanc?: TDate;
}

export interface ITrfBaItemTree extends IJsonTrfBaItemTree {
  trfBaNodeRef?: ETrfBaNodeRef;
}

export interface ITRFBAParams {
  moduleName: string;
}

export const RADIO_GROUP_TRF_BA_RG_FORMATO: IRadioGroup<ETrfBaRGFormato> = deepFreeze<IRadioGroup<ETrfBaRGFormato>>({
  groupItems: [
    {value: ETrfBaRGFormato.PS2XML, label: 'trfba.radio.ps2xml'},
    {value: ETrfBaRGFormato.CGD, label: 'trfba.radio.cgd'}
  ]
});

export const RADIO_GROUP_TRF_BA_CONFIG_TEMPLATE: IRadioGroup<ETemplateSEPASalarios> = deepFreeze<IRadioGroup<ETemplateSEPASalarios>>({
  groupItems: [
    {value: ETemplateSEPASalarios.Geral, label: 'global.text.general'},
    {value: ETemplateSEPASalarios.IGCP, label: 'trfba.radio.igcp'}
  ]
});

export interface ITrfBATotals {
  caption: string;
  totalLinhas: number;
  totalValor: number;
}

export const MODULE_NAME_TRF_BA = 'trfba';
export const MODULE_NAME_TRF_BA_RH = 'trfbarh';
export const MODULE_NAME_TRF_BA_COMERCIAL = 'trfbacomercial';
