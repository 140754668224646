import {merge} from 'lodash-es';
import {firstValueFrom} from 'rxjs';
import {take} from 'rxjs/operators';
import type dxDataGrid from 'devextreme/ui/data_grid';
import ArrayStore from 'devextreme/data/array_store';
import {Component, Injector, Input, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {TranslateService} from '@ngx-translate/core';
import {copy, isEmpty, isObject, isString, PlAlertService, toInteger} from 'pl-comps-angular';
import {
  ECDigitalOrigPeriodoLancAuto,
  EEntidadeHabitual,
  ETipoOrdenacaoVisualizador,
  IJsonContabDigitalChaveSincronizacao,
  IJsonContabDigitalConfigs,
  IJsonContabDigitalConfigsPasta,
  IJsonContabDigitalNIFDuplicarQRCode
} from '../../../../services/contabilidadedigital/jsonContabDigital.interface';
import {CGModalComponent} from '../../../cg/modal/cgmodal.component';
import {
  ERegContabDigitalNifEDPViaVerde,
  IContabDigitalConfigs,
  IContabDigitalConfigsPasta,
  RADIO_GROUP_ORIGEM_PERIO_LANC_AUTO_TEMPLATE,
  TArquivoDigitalConfigValidationCallbackData,
  TConfigPastasCellDocFaType
} from './arquivoDigital.config.modal.interface';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {IDevExpressDataGrid, IDevExpressDataGridColumn, IDevExpressDataGridEditCellTemplateData} from '../../../devexpress/datagrid/devexpress.datagrid.interface';
import {CGModalService} from '../../../cg/modal/cgmodal.service';
import {ContabilidadeDigitalUIService} from '../../../../services/contabilidadedigital/contabilidadedigital.ui.service';
import {DATA_SOURCE_NAME_ENTIDADE_HABITUAL} from '../../../../datasources/entidadehabitual/entidadeHabitual.datasource.interface';
import {
  IDevExpressDataGridEventOnEditingStart,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventOnInitNewRow,
  IDevExpressDataGridEventOnRowInserting
} from '../../../devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {IJsonDiario} from '../../../../entities/diarios/jsonDiario.entity.interface';
import {IJsonReparticoesCCusto} from '../../../../entities/reparticoesccusto/jsonReparticoesCCusto.entity.interface';
import {IJsonDescritivo} from '../../../../entities/descritivos/jsonDescritivo.entity.interface';
import {IJsonString} from '../../../../../common/interfaces/json';
import {ContabDigitalClientConnectSynchronizationKeyModalComponent} from './clientconnectsynchronizationkey/contabdigital.clientconnectsynchronizationkey.modal.component';
import {EContabilidadeDigitalActivateLicenseType} from '../activatelicense/contabilidadedigital.activatelicensemodal.component.interface';
import {ROLE} from '../../../../services/role.const';
import {hasAnyAuthority} from '../../../../../common/utils/roles.utils';
import {IJsonUserEmpresa} from '../../../../services/account/jsonUserApi.interface';
import {IJsonDocfa} from '../../../../entities/docfas/jsonDocFa.entity.interface';
import {IJsonProcesso} from '../../../../entities/processos/jsonProcesso.entity.interface';
import {IJsonCCusto} from '../../../../entities/ccustos/jsonCCusto.entity.interface';
import {EGrupoDoc} from '../../../../datasources/grupodoc/grupoDoc.datasource.interface';

@Component({
  selector: 'contab-digital-config-modal',
  templateUrl: './arquivoDigital.config.modal.component.html'
})
export class ArquivoDigitalConfigModalComponent extends CGModalComponent<IJsonContabDigitalConfigs> implements OnInit {
  @Input() public comercialDigital: boolean;
  @Input() public session: IJsonUserEmpresa;

  public readonly regContabDigitalNifEDPViaVerde: typeof ERegContabDigitalNifEDPViaVerde;
  public readonly radioGroupOrigemPeriodoLancAuto: IRadioGroup<ECDigitalOrigPeriodoLancAuto>;
  public readonly dataGridDefinitionOCR: IDevExpressDataGrid<IJsonContabDigitalNIFDuplicarQRCode>;
  public readonly docfaFilter: string;

  public dataGridDefinitionConfigsPastas: IDevExpressDataGrid<IJsonContabDigitalConfigsPasta, string>;
  public configs: IContabDigitalConfigs;
  public dataGridSourceConfigsPastas: ArrayStore<IContabDigitalConfigsPasta, string>;
  public nContribuinte: string;
  public promise: Promise<void>;

  private _dataGridInstanceConfigsPastas: dxDataGrid<IContabDigitalConfigsPasta, string>;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plAlertService: PlAlertService,
    private readonly _translateService: TranslateService,
    private readonly _cgModalService: CGModalService,
    private readonly _contabilidadeDigitalUIService: ContabilidadeDigitalUIService
  ) {
    super(_injector);
    this.regContabDigitalNifEDPViaVerde = ERegContabDigitalNifEDPViaVerde;
    this.radioGroupOrigemPeriodoLancAuto = RADIO_GROUP_ORIGEM_PERIO_LANC_AUTO_TEMPLATE;
    this.docfaFilter = `grupoDocfa=${EGrupoDoc.VendasEfectivas}|grupoDocfa=${EGrupoDoc.ComprasEfectivas}`;
    this.dataGridDefinitionOCR = {
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      columns: [
        {dataField: 'nif', dataType: 'string', caption: 'arquivodigital.configs.tabs.opcoesocr.table.nif', width: 200},
        {dataField: 'descricao', dataType: 'string', caption: 'arquivodigital.configs.tabs.opcoesocr.table.descricao'}
      ],
      dataSource: [],
      export: {enabled: false},
      filterRow: {visible: false},
      headerFilter: {visible: false},
      height: '60vh',
      pager: {visible: false},
      paging: {enabled: false, pageSize: 100},
      keyExpr: 'nif',
      scrolling: {columnRenderingMode: 'virtual', rowRenderingMode: 'virtual'},
      remoteOperations: false,
      searchPanel: {visible: true},
      toolbar: {
        items: [
          {
            location: 'before',
            locateInMenu: 'auto',
            template: 'templateToolbarAddNIF'
          },
          {
            location: 'after',
            locateInMenu: 'auto',
            template: 'templateToolbarAddEDPVIAVERDE'
          },
          'searchPanel'
        ]
      }
    };
    this.configs = {
      anexaVerbete: false,
      reportVerbete: undefined,
      anexaDocPassaParaProximoNDoc: true,
      syncFolders: [],
      lastFolder: undefined,
      abreSempreVisualizador: true,
      ordenacaoVisualizador: ETipoOrdenacaoVisualizador.NifData,
      usaEFaturaOCR: true,
      recolhaAnexaAuto: true,
      dataLancamIgualDataDoc: false,
      searchEFaturaSoNdocParcial: true,
      searchEFaturaSoMesmoDiario: false,
      lancaDocsAuto: false,
      removePaginasBrancoAuto: false,
      origemPeriodoLancAuto: ECDigitalOrigPeriodoLancAuto.DataPastaDoc,
      modoLancarDocsAutoDisponivel: false,
      usaDiarioConfigSugestLancam: true,
      listaNIFsDuplicarQRCode: []
    };
    this.promise = firstValueFrom(this._contabilidadeDigitalUIService.configs.getConfigs(true).pipe(take(1))).then((configs: IJsonContabDigitalConfigs) => {
      const syncFolders: Array<IContabDigitalConfigsPasta> = configs.syncFolders.map<IContabDigitalConfigsPasta>((configsPasta: IJsonContabDigitalConfigsPasta) => {
        return {
          ...configsPasta,
          nCodRepcc: !configsPasta.nCodRepcc || configsPasta.nCodRepcc === 0 ? undefined : configsPasta.nCodRepcc,
          diario: configsPasta.nDiario ? `${configsPasta.nDiario} - ${configsPasta.nomeDiario}` : '',
          descritivo: configsPasta.nDescritivo ? `${configsPasta.nDescritivo} - ${configsPasta.nomeDescritivo}` : '',
          repcc: configsPasta.nCodRepcc ? `${configsPasta.nCodRepcc} - ${configsPasta.nomeCodRepcc}` : '',
          _saved: true
        };
      });
      this.configs = merge<object, IContabDigitalConfigs, IJsonContabDigitalConfigs, Partial<IContabDigitalConfigs>>({}, this.configs, configs, {syncFolders: syncFolders});
      this.dataGridSourceConfigsPastas = new ArrayStore<IContabDigitalConfigsPasta, string>({
        key: 'nomePasta',
        data: syncFolders
      });
    });
  }

  public ngOnInit(): void {
    this.dataGridDefinitionConfigsPastas = {
      columnChooser: {enabled: false},
      columnHidingEnabled: false,
      columns: this._evaluateConfigsPastasColumns(),
      editing: {
        allowAdding: true,
        allowUpdating: true,
        mode: 'cell',
        newRowPosition: 'last'
      },
      export: {enabled: false},
      filterRow: {visible: false},
      remoteOperations: false,
      searchPanel: {visible: true}
    };
  }

  public async close(): Promise<void> {
    if (this._dataGridInstanceConfigsPastas) {
      await this._dataGridInstanceConfigsPastas.saveEditData();
    }
    const configs: IJsonContabDigitalConfigs = copy(this.configs);
    const configsPastas: Array<IContabDigitalConfigsPasta> = await this.dataGridSourceConfigsPastas.load();
    configs.syncFolders = configsPastas.map<IJsonContabDigitalConfigsPasta>((configsPasta: IContabDigitalConfigsPasta) => {
      return {
        nomePasta: configsPasta.nomePasta,
        nDiario: configsPasta.nDiario,
        nomeDiario: configsPasta.nomeDiario,
        nDescritivo: configsPasta.nDescritivo,
        nomeDescritivo: configsPasta.nomeDescritivo,
        visivel: configsPasta.visivel,
        entidadeHabitual: configsPasta.entidadeHabitual,
        nCodRepcc: configsPasta.nCodRepcc,
        nomeCodRepcc: configsPasta.nomeCodRepcc,
        fazOCR: configsPasta.fazOCR,
        eFaturaOCR: configsPasta.eFaturaOCR,
        sugereBanking: configsPasta.sugereBanking,
        nDocfaFT: configsPasta.nDocfaFT,
        nomeDocfaFT: configsPasta.nomeDocfaFT,
        nDocfaFR: configsPasta.nDocfaFR,
        nomeDocfaFR: configsPasta.nomeDocfaFR,
        nDocfaNC: configsPasta.nDocfaNC,
        nomeDocfaNC: configsPasta.nomeDocfaNC,
        nDocfaNCPagaNoAto: configsPasta.nDocfaNCPagaNoAto,
        nomeDocfaNCPagaNoAto: configsPasta.nomeDocfaNCPagaNoAto,
        nCCusto: configsPasta.nCCusto,
        nomeCCusto: configsPasta.nomeCCusto,
        nRefProcesso: configsPasta.nRefProcesso,
        nomeProcesso: configsPasta.nomeProcesso
      };
    });
    await this._contabilidadeDigitalUIService.configs.save(configs);
    this._plAlertService.success('arquivodigital.configs.success');
    super.close(configs);
  }

  public onDataGridConfigsPastasInitialized(event: IDevExpressDataGridEventOnInitialized<IContabDigitalConfigsPasta, string>): void {
    this._dataGridInstanceConfigsPastas = event.component;
  }

  public onDataGridConfigsPastasDisposing(): void {
    this._dataGridInstanceConfigsPastas = undefined;
  }

  public onDataGridConfigsPastasEditingStart(event: IDevExpressDataGridEventOnEditingStart<IContabDigitalConfigsPasta>): void {
    if (event.data._saved && event.column.dataField === 'nomePasta') {
      event.cancel = true;
    }
  }

  public onDataGridConfigsPastasNewRow(event: IDevExpressDataGridEventOnInitNewRow<IContabDigitalConfigsPasta>): void {
    event.data.visivel = true;
    event.data.fazOCR = true;
    event.data.entidadeHabitual = EEntidadeHabitual.Desconhecido;
    event.data._saved = false;
  }

  public onDataGridConfigsPastasRowInserting(event: IDevExpressDataGridEventOnRowInserting<IContabDigitalConfigsPasta>): void {
    if (!event.data.nomePasta) {
      event.cancel = true;
    }
  }

  public changedConfigPastasCellDiario(cellInfo: IDevExpressDataGridEditCellTemplateData<IJsonContabDigitalConfigsPasta>, value: string | IJsonDiario): void {
    if (isString(value)) {
      const valueAsNumber = toInteger(value);
      cellInfo.data.nDiario = Number.isNaN(valueAsNumber) ? valueAsNumber : null;
      cellInfo.data.nomeDiario = null;
    } else {
      cellInfo.data.nDiario = value.nDiario;
      cellInfo.data.nomeDiario = value.nome;
    }

    cellInfo.component.getDataSource().store().update(cellInfo.data.nomePasta, cellInfo.data);
  }

  public changedConfigPastasCellRepcc(cellInfo: IDevExpressDataGridEditCellTemplateData<IJsonContabDigitalConfigsPasta>, value: string | IJsonReparticoesCCusto): void {
    if (isString(value)) {
      const valueAsNumber = toInteger(value);
      cellInfo.data.nCodRepcc = Number.isNaN(valueAsNumber) ? valueAsNumber : null;
      cellInfo.data.nomeCodRepcc = null;
    } else if (isObject(value)) {
      cellInfo.data.nCodRepcc = value.nCodRepCC;
      cellInfo.data.nomeCodRepcc = value.descricao;
    } else {
      cellInfo.data.nCodRepcc = null;
      cellInfo.data.nomeCodRepcc = null;
    }
    cellInfo.component.getDataSource().store().update(cellInfo.data.nomePasta, cellInfo.data);
  }

  public changedConfigPastasCellDescritivo(cellInfo: IDevExpressDataGridEditCellTemplateData<IJsonContabDigitalConfigsPasta>, value: string | IJsonDescritivo): void {
    if (isString(value)) {
      const valueAsNumber = toInteger(value);
      cellInfo.data.nDescritivo = Number.isNaN(valueAsNumber) ? valueAsNumber : null;
      cellInfo.data.nomeDescritivo = null;
    } else {
      cellInfo.data.nDescritivo = value.nDescrit;
      cellInfo.data.nomeDescritivo = value.nome;
    }
    cellInfo.component.getDataSource().store().update(cellInfo.data.nomePasta, cellInfo.data);
  }

  public changedConfigPastasCellDocFa(cellInfo: IDevExpressDataGridEditCellTemplateData<IJsonContabDigitalConfigsPasta>, value: string | IJsonDocfa, type: TConfigPastasCellDocFaType): void {
    switch (type) {
      case 'FT':
        if (isString(value)) {
          const valueAsNumber = toInteger(value);
          cellInfo.data.nDocfaFT = Number.isNaN(valueAsNumber) ? valueAsNumber : null;
          cellInfo.data.nomeDocfaFT = null;
        } else {
          cellInfo.data.nDocfaFT = value.nDocFa;
          cellInfo.data.nomeDocfaFT = value.nome;
        }
        break;
      case 'FR':
        if (isString(value)) {
          const valueAsNumber = toInteger(value);
          cellInfo.data.nDocfaFR = Number.isNaN(valueAsNumber) ? valueAsNumber : null;
          cellInfo.data.nomeDocfaFR = null;
        } else {
          cellInfo.data.nDocfaFR = value.nDocFa;
          cellInfo.data.nomeDocfaFR = value.nome;
        }
        break;
      case 'NC':
        if (isString(value)) {
          const valueAsNumber = toInteger(value);
          cellInfo.data.nDocfaNC = Number.isNaN(valueAsNumber) ? valueAsNumber : null;
          cellInfo.data.nomeDocfaNC = null;
        } else {
          cellInfo.data.nDocfaNC = value.nDocFa;
          cellInfo.data.nomeDocfaNC = value.nome;
        }
        break;
      case 'NCPagaNoAto':
        if (isString(value)) {
          const valueAsNumber = toInteger(value);
          cellInfo.data.nDocfaNCPagaNoAto = Number.isNaN(valueAsNumber) ? valueAsNumber : null;
          cellInfo.data.nomeDocfaNCPagaNoAto = null;
        } else {
          cellInfo.data.nDocfaNCPagaNoAto = value.nDocFa;
          cellInfo.data.nomeDocfaNCPagaNoAto = value.nome;
        }
        break;
    }
    cellInfo.component.getDataSource().store().update(cellInfo.data.nomePasta, cellInfo.data);
  }

  public changedConfigPastasCellCCusto(cellInfo: IDevExpressDataGridEditCellTemplateData<IJsonContabDigitalConfigsPasta>, value: string | IJsonCCusto): void {
    if (isString(value)) {
      cellInfo.data.nCCusto = !isEmpty(value) ? value : null;
      cellInfo.data.nomeCCusto = null;
    } else {
      cellInfo.data.nCCusto = value.nCCusto;
      cellInfo.data.nomeCCusto = value.nome;
    }
    cellInfo.component.getDataSource().store().update(cellInfo.data.nomePasta, cellInfo.data);
  }

  public changedConfigPastasCellRefProcesso(cellInfo: IDevExpressDataGridEditCellTemplateData<IJsonContabDigitalConfigsPasta>, value: string | IJsonProcesso): void {
    if (isString(value)) {
      cellInfo.data.nRefProcesso = !isEmpty(value) ? value : null;
      cellInfo.data.nomeProcesso = null;
    } else {
      cellInfo.data.nRefProcesso = value.nRefProcesso;
      cellInfo.data.nomeProcesso = value.nome;
    }
    cellInfo.component.getDataSource().store().update(cellInfo.data.nomePasta, cellInfo.data);
  }

  public addNContribuinte(): Promise<void> {
    if (this.configs.listaNIFsDuplicarQRCode.findIndex((item: IJsonContabDigitalNIFDuplicarQRCode) => item.nif === this.nContribuinte) !== -1) {
      this._plAlertService.error('arquivodigital.configs.messages.opcocranifalreadyexists');
      return Promise.resolve();
    }
    return this._contabilidadeDigitalUIService.configs.getDescricaoNIFConfigs(this.nContribuinte).then((response: HttpResponse<IJsonString>) => {
      this.configs.listaNIFsDuplicarQRCode = [...this.configs.listaNIFsDuplicarQRCode, {nif: this.nContribuinte, descricao: response.body.value}];
      this.nContribuinte = '';
      this._plAlertService.success('arquivodigital.configs.messages.opcocraddednif');
    });
  }

  public addEDPViaVerde(value: ERegContabDigitalNifEDPViaVerde): Promise<void> {
    return this._contabilidadeDigitalUIService.configs.getConfigsNIFEDPViaVerde(value).then((response: HttpResponse<Array<IJsonContabDigitalNIFDuplicarQRCode>>) => {
      this.configs.listaNIFsDuplicarQRCode = [
        ...this.configs.listaNIFsDuplicarQRCode,
        ...response.body.filter((item: IJsonContabDigitalNIFDuplicarQRCode) => {
          return this.configs.listaNIFsDuplicarQRCode.findIndex((nifsDuplicarQRCode: IJsonContabDigitalNIFDuplicarQRCode) => nifsDuplicarQRCode.nif === item.nif) === -1;
        })
      ];
      const message: string = value === ERegContabDigitalNifEDPViaVerde.EPD ? 'arquivodigital.configs.messages.opcocraddednifedp' : 'arquivodigital.configs.messages.opcocraddednifviaverde';
      this._plAlertService.success(message);
    });
  }

  public readonly fnGetSynchronizationKey = (): Promise<void> => this._getSynchronizationKey();

  public readonly fnActivateClientConnect = (): Promise<void> => this._activateClientConnect();

  public get modoCGOn(): boolean {
    return this.configurations.licenca.modoCGOn;
  }

  public get temContabilidadeDigitalClientConnect(): boolean {
    return this.configurations.empresa.temContabilidadeDigitalClientConnect;
  }

  private async _getSynchronizationKey(): Promise<void> {
    if (!this.temContabilidadeDigitalClientConnect) {
      await this._consumeLicenseClientConnect();
    }
    const responseKey: HttpResponse<IJsonContabDigitalChaveSincronizacao> = await this._contabilidadeDigitalUIService.configs.getClientConnectSynchronizationKey();
    const modalInstance: NgbModalRef = this._cgModalService.showVanilla(ContabDigitalClientConnectSynchronizationKeyModalComponent, {size: 'md'});
    const componentInstance: ContabDigitalClientConnectSynchronizationKeyModalComponent = modalInstance.componentInstance;
    componentInstance.key = responseKey.body.chaveSincronizacao;
    await modalInstance.result;
  }

  private async _activateClientConnect(): Promise<void> {
    if (this.temContabilidadeDigitalClientConnect) {
      this._plAlertService.info('arquivodigital.configs.messages.clientConnectAlreadyActivated');
      return Promise.resolve();
    }
    await this._consumeLicenseClientConnect();
    this._plAlertService.success('arquivodigital.configs.messages.activatedClientConnect');
    return Promise.resolve();
  }

  private _consumeLicenseClientConnect(): Promise<void> {
    return this._contabilidadeDigitalUIService.consumeLicense(EContabilidadeDigitalActivateLicenseType.ClientConnect);
  }

  private _validateNDiario(options: TArquivoDigitalConfigValidationCallbackData): boolean {
    return options.data?.nDiario > 0;
  }

  private _validateDescritivo(options: TArquivoDigitalConfigValidationCallbackData): boolean {
    return options.data?.nDescritivo > 0;
  }

  private _evaluateConfigsPastasColumns(): Array<IDevExpressDataGridColumn> {
    const hasContabilidadeRole = hasAnyAuthority(this.session, [ROLE.CONTABILIDADEPREMIUM, ROLE.CONTABILIDADE]);

    const columns: Array<IDevExpressDataGridColumn> = [{dataField: 'nomePasta', dataType: 'string', caption: 'arquivodigital.configs.fields.pasta'}];

    if (hasContabilidadeRole) {
      columns.push(
        {
          dataField: 'nomeDiario',
          dataType: 'string',
          caption: 'arquivodigital.configs.fields.nDiario',
          editCellTemplate: 'editCellDiario',
          calculateCellValue: (rowData: IJsonContabDigitalConfigsPasta): string => {
            return rowData.nDiario > 0 ? `${rowData.nDiario} - ${rowData.nomeDiario}` : '';
          },
          validationRules: [
            {
              type: 'custom',
              message: this._translateService.instant('datagrid.column.required'),
              validationCallback: (options: TArquivoDigitalConfigValidationCallbackData) => this._validateNDiario(options)
            }
          ]
        },
        {
          dataField: 'nomeDescritivo',
          dataType: 'string',
          caption: 'arquivodigital.configs.fields.nDescritivo',
          editCellTemplate: 'editCellDescritivo',
          calculateCellValue: (rowData: IJsonContabDigitalConfigsPasta): string => {
            return rowData.nDescritivo > 0 ? `${rowData.nDescritivo} - ${rowData.nomeDescritivo}` : '';
          },
          validationRules: [
            {
              type: 'custom',
              message: this._translateService.instant('datagrid.column.required'),
              validationCallback: (options: TArquivoDigitalConfigValidationCallbackData) => this._validateDescritivo(options)
            }
          ]
        },
        {
          dataField: 'nomeCodRepcc',
          dataType: 'number',
          caption: 'arquivodigital.configs.fields.nCodRepcc',
          headerCellTemplate: 'headerCellTemplateRepcc',
          editCellTemplate: 'editCellRepcc',
          calculateCellValue: (rowData: IJsonContabDigitalConfigsPasta): string => {
            return rowData.nCodRepcc > 0 ? `${rowData.nCodRepcc} - ${rowData.nomeCodRepcc}` : '';
          }
        }
      );
    }

    if (this.comercialDigital) {
      columns.push(
        {
          dataField: 'nomeDocfaFT',
          dataType: 'string',
          caption: 'arquivodigital.configs.fields.nDocfaFT',
          editCellTemplate: 'editCellNDocfaFT',
          calculateCellValue: (rowData: IJsonContabDigitalConfigsPasta): string => {
            return rowData.nDocfaFT > 0 ? `${rowData.nDocfaFT} - ${rowData.nomeDocfaFT}` : '';
          }
        },
        {
          dataField: 'nomeDocfaFR',
          dataType: 'string',
          caption: 'arquivodigital.configs.fields.nDocfaFR',
          editCellTemplate: 'editCellNDocfaFR',
          calculateCellValue: (rowData: IJsonContabDigitalConfigsPasta): string => {
            return rowData.nDocfaFR > 0 ? `${rowData.nDocfaFR} - ${rowData.nomeDocfaFR}` : '';
          }
        },
        {
          dataField: 'nomeDocfaNC',
          dataType: 'string',
          caption: 'arquivodigital.configs.fields.nDocfaNC',
          editCellTemplate: 'editCellNDocfaNC',
          calculateCellValue: (rowData: IJsonContabDigitalConfigsPasta): string => {
            return rowData.nDocfaNC > 0 ? `${rowData.nDocfaNC} - ${rowData.nomeDocfaNC}` : '';
          }
        },
        {
          dataField: 'nomeDocfaNCPagaNoAto',
          dataType: 'string',
          caption: 'arquivodigital.configs.fields.nDocfaNCPagaNoAto',
          editCellTemplate: 'editCellNDocfaNCPagaNoAto',
          calculateCellValue: (rowData: IJsonContabDigitalConfigsPasta): string => {
            return rowData.nDocfaNCPagaNoAto > 0 ? `${rowData.nDocfaNCPagaNoAto} - ${rowData.nomeDocfaNCPagaNoAto}` : '';
          }
        },
        {
          dataField: 'nomeCCusto',
          dataType: 'string',
          caption: 'arquivodigital.configs.fields.nCCusto',
          editCellTemplate: 'editCellNCCusto',
          calculateCellValue: (rowData: IJsonContabDigitalConfigsPasta): string => {
            return !isEmpty(rowData.nCCusto) ? `${rowData.nCCusto} - ${rowData.nomeCCusto}` : '';
          }
        },
        {
          dataField: 'nomeProcesso',
          dataType: 'string',
          caption: 'arquivodigital.configs.fields.nRefProcesso',
          editCellTemplate: 'editCellNRefProcesso',
          calculateCellValue: (rowData: IJsonContabDigitalConfigsPasta): string => {
            return !isEmpty(rowData.nRefProcesso) ? `${rowData.nRefProcesso} - ${rowData.nomeProcesso}` : '';
          }
        }
      );
    }

    columns.push(
      {dataField: 'visivel', dataType: 'boolean', caption: 'arquivodigital.configs.fields.visivel', width: 100},
      {dataField: 'fazOCR', dataType: 'boolean', caption: 'arquivodigital.configs.fields.fazOCR', width: 150},
      {dataField: 'eFaturaOCR', dataType: 'boolean', caption: 'arquivodigital.configs.fields.eFaturaOCR', width: 158}
    );

    if (hasContabilidadeRole) {
      columns.push(
        {dataField: 'sugereBanking', dataType: 'boolean', caption: 'arquivodigital.configs.fields.sugereBanking', width: 168},
        {dataField: 'entidadeHabitual', dataType: 'number', caption: 'arquivodigital.configs.fields.entidadeHabitual', width: 150, lookup: {cgDataSource: DATA_SOURCE_NAME_ENTIDADE_HABITUAL}}
      );
    }

    return columns;
  }
}
