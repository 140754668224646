<entity-detail
  [entityName]="entityName"
  [(model)]="model"
  [type]="type"
  [maintenanceMode]="maintenanceMode"
  [formOrientation]="formOrientation"
  [callback]="callback"
  (evtUpdate)="onUpdate($event)"
  [formInstance]="formInstance">
  <div *entityDetailContent>
    <pl-form [properties]="{readonly: type === stateTypes.DETAIL, orientation: formOrientation}" (formInstanceChange)="formInstance = $event">
      <pl-group>
        <label [translate]="'condicoescomerciais.fields.codCondComerciais'"></label>
        <edit>
          <pl-edit
            attrName="codCondComerciais"
            type="cgsmallint"
            [(model)]="model.codCondComerciais"
            [properties]="{placeholder: 'condicoescomerciais.fields.codCondComerciaisPlaceholder', validators: {required: {value: true}}}"
            plAutoFocus></pl-edit>
        </edit>
      </pl-group>
      <pl-group>
        <label [translate]="'condicoescomerciais.fields.descricao'"></label>
        <edit>
          <pl-edit-text
            attrName="descricao"
            [(model)]="model.descricao"
            [properties]="{placeholder: 'condicoescomerciais.fields.descricaoPlaceholder', validators: {required: {value: true}}}"></pl-edit-text>
        </edit>
      </pl-group>
      <pl-group>
        <edit>
          <pl-edit-checkbox
            attrName="origemCondPagamento"
            [model]="model.origemCondPagamento"
            (modelChange)="onOrigemCondPagamentoChange($event)"
            [falseValue]="0"
            [trueValue]="1"
            [properties]="{label: 'condicoescomerciais.fields.origemCondPagamento'}"></pl-edit-checkbox>
        </edit>
      </pl-group>
      <pl-group [properties]="{readonly: type === stateTypes.DETAIL || !!model.origemCondPagamento, validators: {required: {value: !model.origemCondPagamento}}}">
        <label [translate]="'condicoescomerciais.fields.nomeCondPagamento'"></label>
        <edit>
          <entity-autocomplete
            attrName="condPagamento"
            entity="condicoespagamento"
            [model]="model"
            [selectedKey]="model.condPagamento"
            (selectedKeyChange)="model.condPagamento = $event"
            (evtSelectedDescriptionChanged)="model.nomeCondPagamento = $event"
            [outputKey]="'nCondPag'"
            [outputDescription]="'descricao'"
            [fieldsMap]="{nCondPag: 'condPagamento', descricao: 'nomeCondPagamento'}">
          </entity-autocomplete>
        </edit>
      </pl-group>
      <pl-group>
        <edit>
          <pl-edit-checkbox
            attrName="origemDataVencimento"
            [model]="model.origemDataVencimento"
            (modelChange)="onOrigemDataVencimentoChange($event)"
            [falseValue]="0"
            [trueValue]="1"
            [properties]="{label: 'condicoescomerciais.fields.origemDataVencimento'}"></pl-edit-checkbox>
        </edit>
      </pl-group>
      <pl-group [properties]="{readonly: type === stateTypes.DETAIL || model.origemDataVencimento}">
        <label [translate]="'condicoescomerciais.fields.numDiasDataVenc'"></label>
        <edit>
          <pl-edit
            attrName="numDiasDataVenc"
            type="cgsmallint"
            [(model)]="model.numDiasDataVenc"
            [properties]="{label: 'condicoescomerciais.fields.numDiasDataVenc', validators: {required: {value: false}, min: {value: 0}}}"></pl-edit>
        </edit>
      </pl-group>
      <pl-group [properties]="{readonly: type === stateTypes.DETAIL || !!model.origemDataVencimento, validators: {required: {value: !model.origemDataVencimento}}}">
        <label [translate]="'condicoescomerciais.fields.tipoResumoMensal'"></label>
        <edit>
          <pl-edit attrName="tipoResumoMensal" [type]="'tipoResumoMensal'" [(model)]="model.tipoResumoMensal"></pl-edit>
        </edit>
      </pl-group>
    </pl-form>
  </div>
</entity-detail>
