<div class="docsComerciaisConfigNomeExportFile">
  <fieldset class="mb-2">
    <legend>
      <span class="me-1" [translate]="'configNomeExportFile.caption.groupConfigurador'"></span
      ><i (click)="toggleCollapse()" [ngClass]="collapsedMasks ? 'fa fa-fw fa-caret-right' : 'fa fa-fw fa-caret-down'"></i>
    </legend>
    <pl-animation-collapse [collapsed]="collapsedMasks">
      <pl-group>
        <pl-group>
          <label [translate]="'configNomeExportFile.caption.filesType'"></label>
          <edit>
            <pl-autocomplete
              [source]="exportMaskNameFilesTypeList"
              [model]="model.tipo"
              (evtSelected)="exportMaskNameFilesTypeChanged($event.item)"
              [rowTemplate]="outputExportMaskNameFilesTypeList"
              [output]="outputExportMaskNameFilesTypeList"
              plAutoFocus>
            </pl-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'configNomeExportFile.caption.nDocFa'"></label>
          <edit>
            <entity-autocomplete
              entity="docfas"
              [model]="modelDocfa"
              (modelChange)="nDocfaChanged($event)"
              [filter]="nDocfaFilter"
              [properties]="{disabled: model.tipo?.tipo !== maskTiposEnum.Faturacao}">
            </entity-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label [translate]="'configNomeExportFile.caption.fieldType'"></label>
          <edit>
            <pl-autocomplete
              [source]="exportMaskNameFilesTypeFieldList"
              [model]="model.tipoCampo"
              [rowTemplate]="rowExportMaskNameFilesTypeFieldList"
              [output]="outputExportMaskNameFilesTypeFieldList"
              (evtSelected)="exportMaskNameFilesTypeFieldChanged($event.item)"
              [properties]="{disabled: (model.tipo?.tipo === maskTiposEnum.Faturacao && modelDocfa.nDocFa === 0) || exportMaskNameFilesTypeFieldList?.length === 0}">
            </pl-autocomplete>
          </edit>
        </pl-group>

        <pl-group>
          <label translate="configNomeExportFile.caption.valor"></label>
          <edit>
            <pl-edit type="text" [(model)]="model.tipoConfig.value" [properties]="{disabled: model.tipoCampo?.tipoCampo !== maskFieldsEnum.CaracterFixo}"></pl-edit>
          </edit>
          <actions>
            <pl-button attrName="btnAdicionar" klass="btn-success" [onClick]="adicionarMascara" [disabled]="model.tipoCampo?.tipoCampo === maskTiposEnum.None">
              <i class="fa fa-fw fa-plus-circle"></i><span [translate]="'global.btn.add'"></span>
            </pl-button>
          </actions>
        </pl-group>
      </pl-group>
      <dx-data-grid [cgDxDataGrid]="dataGridDefinitionMasks" [dataSource]="dataSourceMasks">
        <div *dxTemplate="let item of 'actionsTemplate'">
          <dx-button class="dx-button-icon-fontawesome" [hint]="'global.btn.delete' | translate" (click)="deleteMascara(item.rowIndex)" icon="fa fa-fw fa-eraser" [stylingMode]="'text'"></dx-button>
        </div>
      </dx-data-grid>

      <div class="docs-comerciais-export-file-mascara">
        <span *ngIf="previewStr">
          <span>
            <span class="preview-title me-2"> <span [translate]="'configNomeExportFile.caption.mascaraLabel'"></span>: </span>
            <span class="preview-str">{{ previewStr }}</span>
          </span>
        </span>
        <pl-button klass="btn-sm btn-primary" [onClick]="save"><i class="fa fa-fw fa-floppy-o"></i><span [translate]="'global.btn.save'"></span></pl-button>
      </div>
    </pl-animation-collapse>
  </fieldset>

  <h5 class="text-primary pt-2 pb-2" [translate]="'configNomeExportFile.caption.groupConfigs'"></h5>

  <dx-data-grid [cgDxDataGrid]="dataGridDefinitionConfig" [dataSource]="dataSourceConfig">
    <div *dxTemplate="let item of 'actionsTemplate'">
      <dx-button class="dx-button-icon-fontawesome" [hint]="'global.btn.edit' | translate" (click)="editarConfig(item.data)" icon="fa fa-fw fa-pencil" [stylingMode]="'text'"></dx-button>
      <dx-button class="dx-button-icon-fontawesome" [hint]="'global.btn.delete' | translate" (click)="deleteConfig(item.rowIndex)" icon="fa fa-fw fa-eraser" [stylingMode]="'text'"></dx-button>
    </div>
  </dx-data-grid>
</div>
