import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {AtivosAbateService} from './ativosAbate.module.service';
import {MODULE_NAME_ATIVOS_ABATE} from './ativosAbate.module.interface';
import {AtivosAbateModuleComponent} from './components/ativosAbate.module.component';
import {HttpResponse} from '@angular/common/http';
import {IJsonAtivosAbate} from './jsonAtivosAbate.module.interface';

export const MODULE_ATIVOS_ABATE: IModuleDefinition = {
  name: MODULE_NAME_ATIVOS_ABATE,
  state: {
    url: `/${MODULE_NAME_ATIVOS_ABATE}`,
    component: AtivosAbateModuleComponent,
    data: {
      roles: [ROLE.ATIVOS],
      pageTitle: 'global.menu.ativosabate',
      icon: 'fa-pencil'
    },
    params: {
      codAtivo: {
        type: 'int',
        value: null
      }
    },
    resolve: [
      {
        provide: 'ativosabate',
        deps: [AtivosAbateService],
        useFactory: (ativosAbateServiceService: AtivosAbateService) => ativosAbateServiceService.getAtivosPorAbater().then((response: HttpResponse<IJsonAtivosAbate>) => response.body)
      }
    ]
  }
};
