export enum EPeriodicidade {
  None,
  Diaria,
  Semanal,
  Quinzenal,
  Mensal,
  Bimensal,
  Trimestral,
  Quadrimestral,
  Semestral,
  Anual
}

export const DATA_SOURCE_NAME_PERIODICIDADE = 'periodicidade';
