import {Pipe, PipeTransform} from '@angular/core';
import {isString} from '../common/utilities/utilities';

const CHAR_CODE_NEW_LINE = 10;
const CHAR_CODE_CARRIAGE_RETURN = 13;
const CRLF = '\r\n';
const CRLF_ESCAPED = '\\r\\n';
const CRLF_MARKER = '<CRLF>';
const LF = '\n';
const LF_ESCAPED = '\\n';
const LF_MARKER = '<LF>';
const CR_MARKER = '<CR>';
const HTML_NEW_LINE = '<br/>';

@Pipe({
  name: 'cgcNewLine',
  standalone: true
})
export class CGCNewLinePipe implements PipeTransform {
  public transform(value: string): string {
    return newLine(value);
  }
}

export function newLine(value: string): string {
  if (!isString(value)) {
    return value;
  }
  let result = '';
  let i = 0;
  while (i < value.length) {
    // Handle CRLF
    if (value.charCodeAt(i) === CHAR_CODE_CARRIAGE_RETURN && value.charCodeAt(i + 1) === CHAR_CODE_NEW_LINE) {
      result += HTML_NEW_LINE;
      i += CRLF.length;
    } else if (
      value.charAt(i) === '\\' &&
      value.charAt(i + 1) === 'r' &&
      value.charAt(i + 2) === '\\' &&
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      value.charAt(i + 3) === 'n'
    ) {
      result += HTML_NEW_LINE;
      i += CRLF_ESCAPED.length;
    }
    // Handle CRLF marker
    else if (
      value.charAt(i) === '<' &&
      value.charAt(i + 1) === 'C' &&
      value.charAt(i + 2) === 'R' &&
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      value.charAt(i + 3) === 'L' &&
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      value.charAt(i + 4) === 'F' &&
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      value.charAt(i + 5) === '>'
    ) {
      result += HTML_NEW_LINE;
      i += CRLF_MARKER.length;
    }
    // Handle LF
    else if (value.charCodeAt(i) === CHAR_CODE_NEW_LINE) {
      result += HTML_NEW_LINE;
      i += LF.length;
    } else if (value.charAt(i) === '\\' && value.charAt(i + 1) === 'n') {
      result += HTML_NEW_LINE;
      i += LF_ESCAPED.length;
    }
    // Handle LF marker
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    else if (value.charAt(i) === '<' && value.charAt(i + 1) === 'L' && value.charAt(i + 2) === 'F' && value.charAt(i + 3) === '>') {
      result += HTML_NEW_LINE;
      i += LF_MARKER.length;
    }
    // Handle CR marker
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    else if (value.charAt(i) === '<' && value.charAt(i + 1) === 'C' && value.charAt(i + 2) === 'R' && value.charAt(i + 3) === '>') {
      result += '\\r';
      i += CR_MARKER.length;
    }
    // Handle normal characters
    else {
      result += value[i];
      i++;
    }
  }
  return result;
}
