import {
  ESAFTContabProgressState,
  IJsonSAFTContabBalanceteSaldado,
  IJsonSAFTContabConta,
  IJsonSAFTContabContaCorrente,
  IJsonSAFTContabContaSemConversao,
  IJsonSAFTContabConvertConta,
  IJsonSAFTContabDiario,
  IJsonSAFTContabObjFullModel,
  IJsonSAFTContabPlanoContas,
  IJsonSAFTContabTreeDetail
} from './jsonSaftContab.module.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';

export const JOB_NOT_FOUND_ERROR = 'A tarefa não foi encontrada ou não foi inicializada';

export enum ESaftContabCommandType {
  SetTipoImportacao = 'SetTipoImportacao',
  ProcessFile = 'ProcessFile',
  LoadState = 'LoadState',
  StopJob = 'StopJob',
  GetAssociacaoDiarioList = 'GetAssociacaoDiarioList',
  SetAssociacaoDiarioList = 'SetAssociacaoDiarioList',
  GetContasExistentesList = 'GetContasExistentesList',
  GetContasSemConversaoList = 'GetContasSemConversaoList',
  AddConvertConta = 'AddConvertConta',
  ReplaceConvertConta = 'ReplaceConvertConta',
  DeleteConvertConta = 'DeleteConvertConta',
  ReplaceConvertExistent = 'ReplaceConvertExistent',
  AddSemConvertConta = 'AddSemConvertConta',
  GetConvertContaList = 'GetConvertContaList',
  MarcarTodosParaCriar = 'MarcarTodosParaCriar',
  LoadContasCorrentes = 'LoadContasCorrentes',
  UpdateContasCorrentes = 'UpdateContasCorrentes',
  Import = 'Import',
  GetTreeDetail = 'GetTreeDetail',
  SearchContaCorrente = 'SearchContaCorrente',
  GetIsBalanceteSaftSaldado = 'GetIsBalanceteSaftSaldado',
  GetPlanoContasList = 'GetPlanoContasList',
  GetIsBalanceteSaldado = 'GetIsBalanceteSaldado',
  GetPeriodo = 'GetPeriodo'
}

export enum ETipoImportacaoSAFTContab {
  ImportarExtratoComImportacaoSaldosIniciais,
  ImportarExtratoSemImportacaoSaldosIniciais,
  ImportarSoSaldosIniciais,
  ImportarApenasContasCorrentes
}

export enum EFilterPlanoContas {
  ContasComErro,
  ContasComConversao,
  ContasSemConversao
}

export type TSaftContabCommandRawResult<T extends IRestCmdSaftContab = IRestCmdSaftContab> = Promise<T>;

export type TSaftContabCommandResponse<T> = T & {
  commandName: string;
  jobState: ESAFTContabProgressState;
};

export interface ISaftContabWizStepData {
  valid: boolean;
  _index: number;
  _needLoadNext: boolean;
}

export interface ISaftContabWizStepUploadData extends ISaftContabWizStepData {
  showUploader: boolean;
  showProcessFileProgressBar: boolean;
  processFileIntervalId: number;
}

export interface ISaftContabWizStepImportType extends ISaftContabWizStepData {
  tipoImport: ETipoImportacaoSAFTContab;
}

export interface ISaftContabWizStepAssociacaoDiarios extends ISaftContabWizStepData {
  list: Array<IJsonSAFTContabDiario>;
}

export interface ISaftContabWizStepPlanoContas extends ISaftContabWizStepData {
  list: Array<IJsonSAFTContabPlanoContas>;
  _dataGridInstance?: dxDataGrid;
}

export interface ISaftContabWizStepConvertContas extends ISaftContabWizStepData {
  list: Array<IJsonSAFTContabConvertConta>;
}

export interface ISaftContabWizStepContasExistentes extends ISaftContabWizStepData {
  list: Array<IJsonSAFTContabConvertConta>;
}

export interface ISaftContabWizStepContasSemConvert extends ISaftContabWizStepData {
  list: Array<IJsonSAFTContabContaSemConversao>;
  _dataGridInstance?: dxDataGrid;
}

export interface ISaftContabWizStepContasCorrentes extends ISaftContabWizStepData {
  list: Array<IJsonSAFTContabContaCorrente>;
  apenasContasMovimentadas: boolean;
  efetuaConversaoContasCorrentes: boolean;
}

export interface ISaftContabWizStepConfigImport extends ISaftContabWizStepData {
  nDescritivo: number;
  nomeDescritivo: string;
  nDiarioApuramento: number;
  nomeDiarioApuramento: string;
  trocaGLPostingDateComTransactionDate: boolean;
  periodoSaldosIniciais: string;
  nDiarioSaldosIniciais: number;
  nomeDiarioSaldosIniciais: string;
  nDescritivoSaldosIniciais: number;
  nomeDescritivoSaldosIniciais: string;
  verificaBalancete: boolean;
  justificaContasCorrenteMovAb: boolean;
}

export interface ISaftContabWizSteps {
  importType: ISaftContabWizStepImportType;
  upload: ISaftContabWizStepUploadData;
  associacaoDiarios: ISaftContabWizStepAssociacaoDiarios;
  planoContas: ISaftContabWizStepPlanoContas;
  contasCorrentes: ISaftContabWizStepContasCorrentes;
  configImport: ISaftContabWizStepConfigImport;
}

export interface IRestCmdSaftContab {
  jobState?: ESAFTContabProgressState;
}

export interface IRestCmdSaftContabTipoImport extends IRestCmdSaftContab {
  tipoImportacao: ETipoImportacaoSAFTContab;
}

export interface IRestCmdSaftContabProcessFile extends IRestCmdSaftContab {
  filename: string;
}

export interface IRestCmdSaftContabLoadState extends IRestCmdSaftContab {
  model: IJsonSAFTContabObjFullModel;
}

export interface IRestCmdSaftContabStopJob extends IRestCmdSaftContab {}

export interface IRestCmdSaftContabGetAssociacaoDiarioList extends IRestCmdSaftContab {
  list: Array<IJsonSAFTContabDiario>;
}

export interface IRestCmdSaftContabSetAssociacaoDiarioList extends IRestCmdSaftContabGetAssociacaoDiarioList {}

export interface IRestCmdSaftContabGetContasExistentesList extends IRestCmdSaftContab {
  list: Array<IJsonSAFTContabConvertConta>;
}

export interface IRestCmdSaftContabGetContasSemConversaoList extends IRestCmdSaftContab {
  list: Array<IJsonSAFTContabContaSemConversao>;
}

export interface IRestCmdSaftContabAddContasSemConversaoList extends IRestCmdSaftContab {
  list: Array<IJsonSAFTContabPlanoContas>;
  nConta: string;
  nomeConta: string;
  criaConta: boolean;
  erro: string;
  codControlIvaDebito: string;
  codControlIvaCredito: string;
}

export interface IRestCmdSaftContabAddConvertConta extends IRestCmdSaftContab {
  list: Array<IJsonSAFTContabPlanoContas>;
  listContasSemConv: Array<IJsonSAFTContabPlanoContas>;
  nContaOrigem: string;
  nContaDestino: string;
}

export interface IRestCmdSaftContabReplaceConvertConta extends IRestCmdSaftContabAddConvertConta {}

export interface IRestCmdSaftContabReplaceConvertExistent extends IRestCmdSaftContab {
  list: Array<IJsonSAFTContabPlanoContas>;
  nContaOrigem: string;
  nContaDestino: string;
}

export interface IRestCmdSaftContabDeleteConvertConta extends IRestCmdSaftContab {
  list: Array<IJsonSAFTContabPlanoContas>;
  listContasSemConv: Array<IJsonSAFTContabPlanoContas>;
  nContaOrigem: string;
}

export interface IRestCmdSaftContabGetConvertContaList extends IRestCmdSaftContab {
  list: Array<IJsonSAFTContabConvertConta>;
}

export interface IRestCmdSaftContabMarcarTodosParaCriar extends IRestCmdSaftContab {
  list: Array<IJsonSAFTContabPlanoContas>;
  cria: boolean;
}

export interface IRestCmdSaftContabLoadContasCorrentes extends IRestCmdSaftContab {
  list: Array<IJsonSAFTContabContaCorrente>;
}

export interface IRestCmdSaftContabLoadPlanoContasList extends IRestCmdSaftContab {
  list: Array<IJsonSAFTContabPlanoContas>;
}

export interface IRestCmdSaftContabUpdateContasCorrentes extends IRestCmdSaftContab {
  list: Array<IJsonSAFTContabContaCorrente>;
  nConta: string;
  nif: string;
}

export interface IRestCmdSaftContabGetIsBalanceteSaftSaldado extends IRestCmdSaftContab {
  balanceteSaldado: IJsonSAFTContabBalanceteSaldado;
}

export interface IRestCmdSaftContabGetPeriodo extends IRestCmdSaftContab {
  periodo: string;
}

export interface IRestCmdSaftContabGetIsBalanceteSaldado extends IRestCmdSaftContabGetIsBalanceteSaftSaldado {}

export interface IRestCmdSaftContabImport extends IRestCmdSaftContab {
  apenasContasCorrentesComMovimento: boolean;
  verificaBalancete: boolean;
  trocaGLPostingDateComTransactionDate: boolean;
  nDiarioApuramento: number;
  nDescritivo: number;
  nDescritivoSaldosIniciais: number;
  periodoSaldosIniciais: string;
  nDiarioSaldosIniciais: number;
  justificaContasCorrenteMovAb: boolean;
  efetuaConversaoContasCorrentes: boolean;
}

export interface IRestCmdSaftContabGetTreeDetail extends IRestCmdSaftContab {
  treeDetail: IJsonSAFTContabTreeDetail;
  nconta: string;
}

export interface IRestCmdSaftContabSearchContaCorrente extends IRestCmdSaftContab {
  list: Array<IJsonSAFTContabContaCorrente>;
  text: string;
}

export interface ISAFTContabPlanoContas extends IJsonSAFTContabPlanoContas {
  treeDetail?: ISAFTContabTreeDetail;
}

export interface ISAFTContabTreeDetail extends IJsonSAFTContabTreeDetail {
  treeCG: Array<ISAFTContabConta>;
  treeSaft: Array<ISAFTContabConta>;
}

export interface ISAFTContabConta extends IJsonSAFTContabConta {
  items?: Array<IJsonSAFTContabConta>;
  parent?: string;
}

export interface IFilterPlanoContas {
  valueMetodo: EFilterPlanoContas;
  nameMetodo: string;
}

export const MODULE_NAME_SAFT_CONTAB = 'saftcontab';
