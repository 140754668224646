import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {inject} from '@angular/core';
import {HttpEvent, HttpEventType, HttpHandlerFn, HttpHeaders, HttpRequest} from '@angular/common/http';
import {API_PATH} from '../common/api';
import {APP_API_KEY, APP_USER_FEEDBACK_TOKEN} from '../common/app';
import {AuthService} from '../core/services/auth/auth.service';
import {ConfigService} from '../core/services/config/config.service';
import {HTTP_HEADER_X_AUTH_NEMPRESA, HTTP_HEADER_X_AUTH_TOKEN, HTTP_HEADER_X_AUTH_USER_FEEDBACK_TOKEN, HTTP_HEADER_X_SET_AUTH_TOKEN} from '../config/constants';
import {ICGConfigurations} from '../core/services/config/config.service.interface';
import {TUserSession} from '../core/services/account/jsonUserApi.interface';

export function interceptorRequest(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const authService: AuthService = inject(AuthService);
  const configService: ConfigService = inject(ConfigService);

  let modoCGOn = false;

  configService.configurationsAsObservable().subscribe((configurations: ICGConfigurations) => {
    modoCGOn = configurations?.licenca.modoCGOn ?? false;
  });

  if (request.url.startsWith(API_PATH.host)) {
    if (request.method === 'DELETE') {
      const headers = request.headers.set('Content-Type', 'application/json');
      request = request.clone({headers: headers});
    }

    const session: TUserSession = authService.identityValue;
    if (session) {
      let headers: HttpHeaders = request.headers.set(HTTP_HEADER_X_AUTH_NEMPRESA, session.erp.nEmpresa);
      if (modoCGOn) {
        headers = headers.set('x-auth-userid', session.userId.toString());
        headers = headers.set('x-auth-installation', session.erp.centralGestId.toString());
      }
      request = request.clone({headers: headers});
    }

    if (APP_API_KEY.value) {
      request = request.clone({headers: request.headers.set(HTTP_HEADER_X_AUTH_TOKEN, APP_API_KEY.value)});
    }
  }

  if (request.url.includes('/api/amalia')) {
    const token = authService.identityValue?.token;
    if (token) {
      request = request.clone({headers: request.headers.set('Authorization', `Bearer ${token}`)});
    }
  }

  return next(request).pipe(
    tap((response: HttpEvent<unknown>) => {
      if ((response.type === HttpEventType.Response || response.type === HttpEventType.ResponseHeader) && response.url.startsWith(API_PATH.host)) {
        const userToken: string = response.headers.get(HTTP_HEADER_X_SET_AUTH_TOKEN) || '';
        if (userToken) {
          authService.setIdentityToken(userToken);
        }

        const userFeedbackToken: string = response.headers.get(HTTP_HEADER_X_AUTH_USER_FEEDBACK_TOKEN) || '';
        if (userFeedbackToken) {
          APP_USER_FEEDBACK_TOKEN.next(userFeedbackToken);
        }
      }
    })
  );
}
