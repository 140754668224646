<div class="listagens grupo-docfa-configuracoes">
  <config-options [groupName]="configOptionsGroupName" [toolbarInstanceName]="toolbarInstanceName"></config-options>

  <div class="form-body">
    <pl-group>
      <label [translate]="'gruposdocfas.configuracoes.modofuncionamento.label'"></label>
      <edit>
        <pl-edit-select [source]="modosFuncionamento" [(model)]="modoSelecionado" [properties]="{disallowClear: true}"></pl-edit-select>
      </edit>
    </pl-group>
    <br />
    <div class="card d-flex">
      <div class="card-header"><i class="fa fa-money fa-fw"></i>&nbsp;<span class="nav-item-title" [translate]="'gruposdocfas.configuracoes.gastos.title'"></span></div>
      <div class="card-body">
        <div class="row mt-2">
          <div class="col-sm-12 col-xl-6">
            <dx-data-grid
              [cgDxDataGrid]="dataGridDefinitionDocfasCustoEstimadoList"
              cgDxDataGridInstanceName="definitionDocfasCustoEstimadoList"
              [dataSource]="this.dataDocfasCustoEstimadoList"
              (onInitialized)="onInitializedDefinitionDocfasCustoEstimadoList($event)"
              (onSaving)="onSavingConfigDocfas($event)"
              (onSaved)="onSavedConfigDocfasCustoEstimado($event)">
            </dx-data-grid>
          </div>

          <div class="col-sm-12 col-xl-6">
            <dx-data-grid
              [cgDxDataGrid]="dataGridDefinitionDocfasCustoRealList"
              cgDxDataGridInstanceName="definitionDocfasCustoRealList"
              [dataSource]="this.dataDocfasCustoRealList"
              (onInitialized)="onInitializedDefinitionDocfasCustoRealList($event)"
              (onSaving)="onSavingConfigDocfas($event)"
              (onSaved)="onSavedConfigDocfasCustoReal($event)">
            </dx-data-grid>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="card d-flex">
      <div class="card-header"><i class="fa fa-stack-overflow fa-fw"></i>&nbsp;<span class="nav-item-title" [translate]="'gruposdocfas.configuracoes.rendimentos.title'"></span></div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-xl-6">
            <dx-data-grid
              [cgDxDataGrid]="dataGridDefinitionDocfasProveitoEstimadoList"
              cgDxDataGridInstanceName="definitionDocfasProveitoEstimadoList"
              [dataSource]="this.dataDocfasProveitoEstimadoList"
              (onInitialized)="onInitializedDefinitionDocfasProveitoEstimadoList($event)"
              (onSaving)="onSavingConfigDocfas($event)"
              (onSaved)="onSavedConfigDocfasProveitoEstimado($event)">
            </dx-data-grid>
          </div>

          <div class="col-sm-12 col-xl-6">
            <dx-data-grid
              [cgDxDataGrid]="dataGridDefinitionDocfasProveitoRealList"
              cgDxDataGridInstanceName="definitionDocfasProveitoRealList"
              [dataSource]="this.dataDocfasProveitoRealList"
              (onInitialized)="onInitializedDefinitionDocfasProveitoRealList($event)"
              (onSaving)="onSavingConfigDocfas($event)"
              (onSaved)="onSavedConfigDocfasProveitoReal($event)">
            </dx-data-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
