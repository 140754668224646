<div class="gestao-comunicacoes-topico-message-modal gestao-comunicacoes-messages" style="height: 100vh">
  <div class="modal-header">
    <button type="button" class="btn-close" (click)="dismiss()"></button>
    <h4 class="modal-title">{{ topico.assunto }}</h4>
    <div class="py-1 px-2 rounded-1 d-inline-block text-white" [ngStyle]="{'background-color': estadoDados.estadoCor}">{{ estadoDados.estadoNome }}</div>
  </div>

  <div class="modal-body chat-interface-body">
    <div class="row">
      <div class="col-sm-12 col-lg-6">
        <pl-group>
          <label [translate]="'gestaoComunicacoes.modal.topico.user'"></label>
          <edit>
            <pl-edit attrName="nomeUtilizador" type="text" [model]="topico.utilizadoresPara[0]?.nome" [properties]="{disabled: true}"></pl-edit>
          </edit>
        </pl-group>
      </div>

      <div class="col-sm-12 col-lg-6">
        <label [translate]="'gestaoComunicacoes.modal.topico.categoria'"></label>
        <edit>
          <div class="py-1 px-2 rounded-1 d-inline-block" [ngStyle]="{'background-color': categoryBackgroundColor, color: topico.categoriaCor}">{{ topico.categoriaNome }}</div>
        </edit>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-sm-12">
        <h5 class="gestao-comunicacoes-messages-title text-center" [translate]="'gestaoComunicacoes.modal.topico.mensagensTitle'"></h5>
      </div>
    </div>

    <cg-gestao-comunicacoes-messages-container #containerMessages [mensagens]="mensagens" [showPaddingTop]="true" [contabilista]="true"></cg-gestao-comunicacoes-messages-container>
  </div>

  <div class="modal-footer">
    <cg-gestao-comunicacoes-message-input
      class="w-100"
      #inputMessage
      [showAttachFile]="true"
      [showSendButton]="true"
      [showSpeechToText]="true"
      [marginBottom]="true"
      (evtSubmitFile)="evtSubmitFile($event)"
      (evtSubmitMessage)="evtSubmitMessage($event)"></cg-gestao-comunicacoes-message-input>
  </div>
</div>
