import {Injectable} from '@angular/core';
import {ApiService} from '../api/api.service';
import {IJsonDocComercial, IJsonDocComercialLinha} from '../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {ETipoDevolucao, IJsonArtigoDevolucao, IJsonDevolucao, IJsonDevolucaoMotivo, IJsonLinhaParaDevolucao} from './jsonDevolucao.interface';
import {TDate} from '../../../common/dates';
import {TServiceResponse} from '../api/api.service.interface';

@Injectable({
  providedIn: 'root'
})
export class DevolucoesService {
  private readonly _path: string;

  constructor(private readonly _apiService: ApiService) {
    this._path = `${this._apiService.path.restapi}/devolucoes`;
  }

  public getMotivos(loadMotivosSistema: boolean): TServiceResponse<Array<IJsonDevolucaoMotivo>> {
    return this._apiService.get<Array<IJsonDevolucaoMotivo>>({
      url: `${this._path}/motivosdevolucao`,
      params: {
        loadmotivossistema: loadMotivosSistema
      }
    });
  }

  public adicionarMotivo(motivo: string): TServiceResponse<IJsonDevolucaoMotivo> {
    return this._apiService.post<IJsonDevolucaoMotivo, string>({
      url: `${this._path}/motivo`,
      body: motivo
    });
  }

  public getNArtigoDevolucao(): TServiceResponse<IJsonArtigoDevolucao> {
    return this._apiService.get<IJsonArtigoDevolucao>({url: `${this._path}/artigodevolucao`});
  }

  public getLinhasDevolucao(
    tipoDevolucao: number,
    faccbIdOrigem: number = 0,
    nConta: string = '',
    nDocFa: number = 0,
    dataDoc: TDate = 0,
    deNArtigo: string = '',
    ateNArtigo: string = ''
  ): TServiceResponse<Array<IJsonLinhaParaDevolucao>> {
    return this._apiService.get<Array<IJsonLinhaParaDevolucao>>({
      url: `${this._path}/linhasdevolucao`,
      params: {
        tipodevolucao: tipoDevolucao,
        faccbid: faccbIdOrigem,
        nconta: nConta,
        ndocfa: nDocFa,
        datadoc: dataDoc,
        denartigo: deNArtigo,
        atenartigo: ateNArtigo
      }
    });
  }

  public devolucao(devolucao: IJsonDevolucao, faccbIdDestino: number, tipo: ETipoDevolucao): TServiceResponse<IJsonDocComercial> {
    if (faccbIdDestino > 0) {
      if (tipo === ETipoDevolucao.ArtigoValor) {
        return this.devolucaoValorLinhaParaDoc(devolucao, faccbIdDestino);
      }
      if (tipo === ETipoDevolucao.ArtigoQuantidade) {
        return this.devolucaoQtdParaDoc(devolucao, faccbIdDestino);
      }
      if (tipo === ETipoDevolucao.DocumentoExterno) {
        return this.devolucaoLinhaDocExternoParaDocumento(devolucao, faccbIdDestino);
      }
    } else {
      if (tipo === ETipoDevolucao.ArtigoValor) {
        return this.devolucaoValorLinha(devolucao);
      }
      if (tipo === ETipoDevolucao.ArtigoQuantidade) {
        return this.devolucaoQtd(devolucao);
      }
    }
    if (tipo === ETipoDevolucao.Documento) {
      return this.devolucaoValorDocumento(devolucao);
    }
    return undefined;
  }

  public devolucaoLinhaDocExternoParaDocumento(devolucao: IJsonDevolucao, faccbid: number): TServiceResponse<IJsonDocComercial> {
    return this._apiService.put<IJsonDocComercial, IJsonDevolucao>({
      url: `${this._path}/${faccbid}/linhadocexterno`,
      body: devolucao
    });
  }

  public devolucaoQtdParaDoc(devolucao: IJsonDevolucao, faccbid: number): TServiceResponse<IJsonDocComercial> {
    return this._apiService.put<IJsonDocComercial, IJsonDevolucao>({
      url: `${this._path}/${faccbid}/qtd`,
      body: devolucao
    });
  }

  public devolucaoValorLinhaParaDoc(devolucao: IJsonDevolucao, faccbid: number): TServiceResponse<IJsonDocComercial> {
    return this._apiService.put<IJsonDocComercial, IJsonDevolucao>({
      url: `${this._path}/${faccbid}/valorlinha`,
      body: devolucao
    });
  }

  public devolucaoValorDocumento(devolucao: IJsonDevolucao): TServiceResponse<IJsonDocComercial> {
    return this._apiService.post<IJsonDocComercial, IJsonDevolucao>({
      url: `${this._path}/valordocumento`,
      body: devolucao
    });
  }

  public devolucaoValorLinha(devolucao: IJsonDevolucao): TServiceResponse<IJsonDocComercial> {
    return this._apiService.post<IJsonDocComercial, IJsonDevolucao>({
      url: `${this._path}/valorlinha`,
      body: devolucao
    });
  }

  public devolucaoQtd(devolucao: IJsonDevolucao): TServiceResponse<IJsonDocComercial> {
    return this._apiService.post<IJsonDocComercial, IJsonDevolucao>({
      url: `${this._path}/qtd`,
      body: devolucao
    });
  }

  public devolucaoDocExterno(doc: IJsonDocComercial): TServiceResponse<IJsonDocComercial> {
    return this._apiService.post<IJsonDocComercial>({
      url: `${this._path}/docexterno`,
      body: doc
    });
  }

  public devolucaoLinhaDocExterno(linha: IJsonDocComercialLinha, faccbId: number): TServiceResponse<IJsonDocComercial> {
    return this._apiService.put<IJsonDocComercial, IJsonDocComercialLinha>({
      url: `${this._path}/${faccbId}/linhadocexterno`,
      body: linha
    });
  }

  public linhaOrigem(facliIdDestino: number): TServiceResponse<IJsonLinhaParaDevolucao> {
    return this._apiService.get<IJsonLinhaParaDevolucao>({
      url: `${this._path}/linhaorigem`,
      params: {
        facliiddestino: facliIdDestino
      }
    });
  }

  public linhasOrigem(nDocfa: number, nNumer: number, nDocumento: number): TServiceResponse<Array<IJsonLinhaParaDevolucao>> {
    return this._apiService.get<Array<IJsonLinhaParaDevolucao>>({
      url: `${this._path}/linhasorigem`,
      params: {
        ndocfa: nDocfa,
        nnumer: nNumer,
        ndocumento: nDocumento
      }
    });
  }

  public linhaDocOrigem(nDocfa: number, nNumer: number, nDocumento: number): TServiceResponse<IJsonLinhaParaDevolucao> {
    return this._apiService.get<IJsonLinhaParaDevolucao>({
      url: `${this._path}/linhadocorigem`,
      params: {
        ndocfa: nDocfa,
        nnumer: nNumer,
        ndocumento: nDocumento
      }
    });
  }
}
