import {Component, Injector, OnInit} from '@angular/core';
import {ModuloEntityListComponent} from '../../../../components/module/entitylist/module.entitylist.component';
import {IJsonRecibosSerie} from '../../jsonRecibosSerie.entity.interface';
import {DocfasNumsComunicacaoLoginService} from '../../../../services/docfasNumsComunicacaoLogin/docfasNumsComunicacaoLogin.service';
import {IPlToolbarItem, IPlToolbarMenuItem} from 'pl-comps-angular';
import {ISimpleLoginCredentials} from '../../../../modules/simplelogin/simpleLogin.module.interface';
import {SimpleLoginService} from '../../../../modules/simplelogin/simpleLogin.module.service';
import {IDocfasNumsComunicacaoLogin} from '../../../../services/docfasNumsComunicacaoLogin/docfasNumsComunicacaoLogin.service.interface';
import {SeriesPorComunicarATModalComponent} from '../../../../modules/comunicacaoseriesat/comunicacao/seriesporcomunicarat/seriesPorComunicarAt.modal.component';
import {DocFasNumsComunicacaoLoginModalComponent} from '../../../../modules/docfasnumscomunicacaologin/docFasNumsComunicacaoLogin.modal.component';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {EComunicacaoSeriesATType} from '../../../../modules/comunicacaoseriesat/comunicacaoseriesat.interface';
import {ComunicacaoSeriesATModalComponent} from '../../../../modules/comunicacaoseriesat/comunicacao/comunicacaoseriesat/comunicacaoSeriesAt.modal.component';
import {ComunicacaoSeriesAtService} from '../../../../modules/comunicacaoseriesat/comunicacaoseriesat.service';
import {ETipoAutoFaturacao} from '../../../docfas/jsonDocFa.entity.interface';
import {LogComunicacaoSerieAtModalComponent} from '../../../../modules/comunicacaoseriesat/logs/logComunicacaoSerieAt.modal.component';
import {ConsultaSerieAtModalComponent} from '../../../../modules/comunicacaoseriesat/consulta/consultaSerieAt.modal.component';
import {IJsonDocfaNumATConsulta} from '../../../docfasnums/jsonDocfaNum.entity.interface';

@Component({
  selector: ' recibos-serie-entity-list',
  templateUrl: './recibosSerie.entity.list.component.html'
})
export class RecibosSerieEntityListComponent extends ModuloEntityListComponent<IJsonRecibosSerie> implements OnInit {
  private readonly _btnComunicacaoSeries: IPlToolbarItem;
  private readonly _btnLoginWebServiceAT: IPlToolbarMenuItem;
  private _docFasNumsComunicacaoLogin: IDocfasNumsComunicacaoLogin;
  private _atLogin: ISimpleLoginCredentials;

  constructor(
    protected readonly _injector: Injector,
    private readonly _cgModalService: CGModalService,
    private readonly _simpleLoginService: SimpleLoginService,
    private readonly _serviceDocFasNumsComunicacaoLogin: DocfasNumsComunicacaoLoginService,
    private readonly _comunicacaoSeriesAtService: ComunicacaoSeriesAtService
  ) {
    super(_injector);
    this._btnComunicacaoSeries = {
      id: 'btn-gestao-docs-editaveis-toolbar',
      type: 'button',
      class: 'btn-info',
      iconLeft: '<i class="fa fa-fw fa-paper-plane"></i>',
      caption: 'docfas.modals.comunicacaoSeriesAT',
      order: this.btnNovo.order + 1,
      click: () => this._comunicacaoSeriesAT()
    };
    this._btnLoginWebServiceAT = {
      order: this._btnComunicacaoSeries.order + 1,
      caption: 'docfas.loginAT',
      iconLeft: '<i class="fa fa-fw fa-key" aria-hidden="true"></i>',
      click: () => this._configLoginAt()
    };
    this._atLogin = {
      username: '',
      password: ''
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this._simpleLoginService.getAtCredentials().then((result) => {
      this._atLogin = result.body;
    });
    this._serviceDocFasNumsComunicacaoLogin.get().then((response) => {
      this._docFasNumsComunicacaoLogin = response.body;
    });
    this.btnConfig.visible = true;
    this.btnConfig.type = 'dropdown';
    this.btnConfig.menu = [this._btnLoginWebServiceAT];
    this.toolbar.addButton(this._btnComunicacaoSeries);
  }

  public comunicarSerie(serie: IJsonRecibosSerie): void {
    if (this._docFasNumsComunicacaoLogin.username || this._atLogin.username) {
      this._callComunicarSerieAT(serie.nSerie);
    } else {
      const instanceComunicacaoLoginModal = this._cgModalService.showVanilla(DocFasNumsComunicacaoLoginModalComponent, {size: 'sm'});
      instanceComunicacaoLoginModal.result.then((result: IDocfasNumsComunicacaoLogin) => {
        this._docFasNumsComunicacaoLogin = result;
        this._callComunicarSerieAT(serie.nSerie);
      });
    }
  }

  public logSerie(serie: IJsonRecibosSerie): void {
    if (this._docFasNumsComunicacaoLogin.username || this._atLogin.username) {
      const instanceLogComunicacao = this._cgModalService.showVanilla(LogComunicacaoSerieAtModalComponent, {size: 'xl'});
      instanceLogComunicacao.componentInstance.serieType = EComunicacaoSeriesATType.RECIBOS;
      instanceLogComunicacao.componentInstance.nDocfa = 0;
      instanceLogComunicacao.componentInstance.nNumer = serie.nSerie;
    } else {
      const instanceComunicacaoLoginModal = this._cgModalService.showVanilla(DocFasNumsComunicacaoLoginModalComponent, {size: 'sm'});
      instanceComunicacaoLoginModal.result.then((result: IDocfasNumsComunicacaoLogin) => {
        this._docFasNumsComunicacaoLogin = result;
        const instanceLogComunicacao = this._cgModalService.showVanilla(LogComunicacaoSerieAtModalComponent, {size: 'xl'});
        instanceLogComunicacao.componentInstance.serieType = EComunicacaoSeriesATType.RECIBOS;
        instanceLogComunicacao.componentInstance.nDocfa = 0;
        instanceLogComunicacao.componentInstance.nNumer = serie.nSerie;
      });
    }
  }

  public readonly fnConsultarSerie: (item: IJsonRecibosSerie) => () => Promise<void> = (item: IJsonRecibosSerie) => (): Promise<void> => this._consultarSerie(item);

  private _comunicacaoSeriesAT(): Promise<void> {
    if (this._docFasNumsComunicacaoLogin.username || this._atLogin.username) {
      const modalRef = this._cgModalService.showVanilla(SeriesPorComunicarATModalComponent, {size: 'xxl'});
      const componentInstance: SeriesPorComunicarATModalComponent = modalRef.componentInstance;
      componentInstance.serieType = EComunicacaoSeriesATType.RECIBOS;
      return modalRef.result.then(() => {
        this.refreshList().then(() => undefined);
      });
    }
    return this._configLoginAt().then(() => {
      const modalRef = this._cgModalService.showVanilla(SeriesPorComunicarATModalComponent, {size: 'xxl'});
      const componentInstance: SeriesPorComunicarATModalComponent = modalRef.componentInstance;
      componentInstance.serieType = EComunicacaoSeriesATType.RECIBOS;
      return modalRef.result.then(() => {
        this.refreshList().then(() => undefined);
      });
    });
  }

  private _configLoginAt(): Promise<void> {
    const instance = this._cgModalService.showVanilla(DocFasNumsComunicacaoLoginModalComponent, {size: 'sm'});
    return instance.result.then((result: IDocfasNumsComunicacaoLogin) => {
      this._docFasNumsComunicacaoLogin = result;
    });
  }

  private _callComunicarSerieAT(nSerie: number): Promise<void> {
    return this._comunicacaoSeriesAtService.getSerieComunicadaAT(EComunicacaoSeriesATType.RECIBOS, nSerie).then((result) => {
      const modalInstance = this._cgModalService.showVanilla(ComunicacaoSeriesATModalComponent, {size: 'md'});
      const componentInstance: ComunicacaoSeriesATModalComponent = modalInstance.componentInstance;
      componentInstance.serieType = EComunicacaoSeriesATType.RECIBOS;
      componentInstance.nSerie = nSerie;
      componentInstance.model = result.body;
      componentInstance.autoFaturacao = ETipoAutoFaturacao.None;
      modalInstance.result.then((comunicaResult: IJsonDocfaNumATConsulta) => {
        if (comunicaResult.sucesso) {
          this.refreshList().then(() => undefined);
        }
      });
    });
  }

  private async _consultarSerie(serie: IJsonRecibosSerie): Promise<void> {
    if (this._docFasNumsComunicacaoLogin.username || this._atLogin.username) {
      const result = await this._comunicacaoSeriesAtService.consultaSerieNaAT(EComunicacaoSeriesATType.RECIBOS, serie.nSerie);
      const instanceConsultaSerie = this._cgModalService.showVanilla(ConsultaSerieAtModalComponent, {size: 'lg'});
      instanceConsultaSerie.componentInstance.model = result.body;
      await instanceConsultaSerie.result;
    } else {
      const instanceComunicacaoLoginModal = this._cgModalService.showVanilla(DocFasNumsComunicacaoLoginModalComponent, {size: 'sm'});
      this._docFasNumsComunicacaoLogin = await instanceComunicacaoLoginModal.result;
      const data = await this._comunicacaoSeriesAtService.consultaSerieNaAT(EComunicacaoSeriesATType.RECIBOS, serie.nSerie);
      const instanceConsultaSerie = this._cgModalService.showVanilla(ConsultaSerieAtModalComponent, {size: 'lg'});
      instanceConsultaSerie.componentInstance.model = data.body;
      await instanceConsultaSerie.result;
    }
  }
}
