import {IEntityDefinition} from '../../../components/entity/entity.definition.interface';
import {ROLE} from '../../../services/role.const';
import {ENTITY_NAME_AT_SERIES_LOGS} from './atSeriesLogs.entity.interface';
import {IJsonAtSerieLog} from './jsonAtSerieLog.entity.interface';

export const ENTITY_AT_SERIES_LOGS: IEntityDefinition<IJsonAtSerieLog> = {
  name: ENTITY_NAME_AT_SERIES_LOGS,
  asModule: false,
  roles: [ROLE.ERP],
  searchPlaceholder: 'docfasNumsLog.pesquisa',
  metadata: {
    keyName: 'docfaNumLogId',
    fields: [
      {name: 'docfaNumLogId', caption: 'docfaNumLog.fields.docfaNumLogId'},
      {name: 'nDocfa', caption: 'docfaNumLog.fields.nDocfa'},
      {name: 'nNumer', caption: 'docfaNumLog.fields.nNumer'},
      {name: 'operacao', caption: 'docfaNumLog.fields.operacao'},
      {name: 'atSerie', caption: 'docfaNumLog.fields.atSerie'},
      {name: 'atTipoSerie', caption: 'docfaNumLog.fields.atTipoSerie'},
      {name: 'atClasseDoc', caption: 'docfaNumLog.fields.atClasseDoc'},
      {name: 'atTipoDocSTR', caption: 'docfaNumLog.fields.atTipoDocSTR'},
      {name: 'atEstadoSerie', caption: 'docfaNumLog.fields.atEstadoSerie'},
      {name: 'atNumInicialSeq', caption: 'docfaNumLog.fields.atNumInicialSeq'},
      {name: 'atDataInicioPrev', caption: 'docfaNumLog.fields.atDataInicioPrev'},
      {name: 'atRegistoManual', caption: 'docfaNumLog.fields.atRegistoManual'},
      {name: 'atMeioProcessamento', caption: 'docfaNumLog.fields.atMeioProcessamento'},
      {name: 'erro', caption: 'docfaNumLog.fields.erro'},
      {name: 'respostaCodigo', caption: 'docfaNumLog.fields.respostaCodigo'},
      {name: 'respostaMensagem', caption: 'docfaNumLog.fields.respostaMensagem'},
      {name: 'codValidacaoSerie', caption: 'docfaNumLog.fields.codValidacaoSerie'},
      {name: 'operacaoSTR', caption: 'docfaNumLog.fields.operacaoSTR'},
      {name: 'atTipoSerieDescricao', caption: 'docfaNumLog.fields.atTipoSerieDescricao'},
      {name: 'atClasseDocDescricao', caption: 'docfaNumLog.fields.atClasseDocDescricao'},
      {name: 'atEstadoSerieDescricao', caption: 'docfaNumLog.fields.atEstadoSerieDescricao'},
      {name: 'atMeioProcessamentoDescricao', caption: 'docfaNumLog.fields.atMeioProcessamentoDescricao'}
    ]
  }
};
