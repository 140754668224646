import {Component, Injector, OnInit} from '@angular/core';
import {IPlToolbarItem, isNumber, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {CGModalService} from '../../../../components/cg/modal/cgmodal.service';
import {ModuloComponent} from '../../../../components/module/module.component';
import {round} from '../../../../../common/utils/utils';
import {IRadioGroup} from '../../../../../common/interfaces/interfaces';
import {IDevExpressDataGrid, IDevExpressDataGridColumn} from '../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {
  IDevExpressDataGridEventOnCellClick,
  IDevExpressDataGridEventOnCellDblClick,
  IDevExpressDataGridEventOnInitialized,
  IDevExpressDataGridEventRowDraggingOnAdd
} from '../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import ArrayStore from 'devextreme/data/array_store';
import {confirm} from 'devextreme/ui/dialog';
import {IJsonAtivoNotaCreditoDisponivel, IJsonNotaCredito} from '../jsonAtivosNotaCredito.module.interface';
import {
  EDistribuiValorAtivosNotaCredito,
  IAtivoNotaCreditoDisponivel,
  INotaCreditoPesquisaContab,
  RADIO_GROUP_ATIVO_NOTA_CREDITO_DIST_VALOR,
  TAtivoNotaCreditoStatus
} from '../ativosNotaCredito.module.interface';
import {AtivosNotaCreditoService} from '../ativosNotaCredito.module.service';
import {AtivosNotaCreditoContabModalComponent} from '../modals/contab/ativosNotaCredito.contab.modal.component';

const NUMBER_TWO = 2;
const FORMAT_VALUE = '#,##0.00';

@Component({
  selector: 'module-ativos-nota-credito',
  templateUrl: './ativosNotaCredito.module.component.html'
})
export class AtivosNotaCreditoModuleComponent extends ModuloComponent implements OnInit {
  public readonly pocsOutput: string;
  public readonly radioGroupTemplateDistValor: IRadioGroup<EDistribuiValorAtivosNotaCredito>;

  public readonly dataGridDefinition: IDevExpressDataGrid;
  public readonly dataGridDefinitionDoc: IDevExpressDataGrid;
  public readonly dataGridAtivosDisponiveisFields: Array<IDevExpressDataGridColumn<IAtivoNotaCreditoDisponivel, number>>;
  public readonly dataGridADescontarFields: Array<IDevExpressDataGridColumn<IAtivoNotaCreditoDisponivel, number>>;

  public selectedAtivoDisponivel: IAtivoNotaCreditoDisponivel;
  public selectedAtivoADescontar: IAtivoNotaCreditoDisponivel;
  public ativosNotaCredito: IJsonNotaCredito;
  public distribuiValor: EDistribuiValorAtivosNotaCredito;
  public fornecedor: string;
  public valorNotaCreditoTotal: number;

  public emptyAtivosDisponiveisTable: boolean;
  public emptyAtivosADescontarTable: boolean;

  private _dataGridInstanceAtivosDisponiveis: dxDataGrid;
  private _dataGridInstanceADescontar: dxDataGrid;
  private _dataGridStore: ArrayStore;
  private _btnAplicaNotaCredito: IPlToolbarItem;
  private _btnPesquisaFatura: IPlToolbarItem;

  constructor(
    protected readonly _injector: Injector,
    private readonly _ativosNotaCreditoService: AtivosNotaCreditoService,
    private readonly _cgModalService: CGModalService,
    private readonly _plAlertService: PlAlertService,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this.dataGridDefinitionDoc = {
      columns: [
        {dataField: 'nContaDebito', dataType: 'string', caption: 'ativosnotacredito.table.nContaDebito'},
        {dataField: 'nContaCredito', dataType: 'string', caption: 'ativosnotacredito.table.nContaCredito'},
        {dataField: 'valor', dataType: 'double', caption: 'ativosnotacredito.table.valor'},
        {dataField: 'descricao', dataType: 'string', caption: 'ativosnotacredito.table.descricao'}
      ],
      filterRow: {visible: false},
      columnChooser: {enabled: false},
      export: {enabled: false},
      remoteOperations: false
    };
    this.dataGridDefinition = {
      export: {filename: 'portals.sidebar.ativos.ativosNotaCredito'},
      height: '60vh',
      headerFilter: {visible: false},
      columnHidingEnabled: false,
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      repaintChangesOnly: true,
      remoteOperations: false,
      columnAutoWidth: true,
      scrolling: {rowRenderingMode: 'virtual'},
      summary: {
        totalItems: [
          {column: 'valorDesconto', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorAtivoBrutoContab', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'},
          {column: 'valorDepreciacoesAcumuladasContab', displayFormat: '{0}', skipEmptyValues: true, summaryType: 'sum', valueFormat: 'double'}
        ]
      },
      toolbar: {
        items: [
          {
            location: 'before',
            template: 'templateToolbar',
            locateInMenu: 'auto'
          },
          'exportButton',
          'columnChooserButton'
        ]
      }
    };
    const formatValue = FORMAT_VALUE;
    const capCodAtivo: string = this._plTranslateService.translate('ativosnotacredito.table.codAtivo');
    const capDesignacao: string = this._plTranslateService.translate('ativosnotacredito.table.designacao');
    const capRefExterna: string = this._plTranslateService.translate('ativosnotacredito.table.refExterna');
    const capDataAquisicao: string = this._plTranslateService.translate('ativosnotacredito.table.dataAquisicao');
    const capValorAtivoBrutoContab: string = this._plTranslateService.translate('ativosnotacredito.table.valorAtivoBrutoContab');
    const capValorDepreciacoesAcumuladasContab: string = this._plTranslateService.translate('ativosnotacredito.table.valorDepreciacoesAcumuladasContab');
    const capvalorNotaCredito: string = this._plTranslateService.translate('ativosnotacredito.table.valorNotaCredito');
    this.dataGridAtivosDisponiveisFields = [
      {dataField: 'codAtivo', dataType: 'number', caption: capCodAtivo, width: 95},
      {dataField: 'designacao', dataType: 'string', caption: capDesignacao},
      {dataField: 'refExterna', dataType: 'string', caption: capRefExterna, width: 90},
      {dataField: 'dataAquisicao', dataType: 'date', caption: capDataAquisicao, width: 95},
      {dataField: 'valorAtivoBrutoContab', dataType: 'number', caption: capValorAtivoBrutoContab, format: formatValue, alignment: 'right', width: 120},
      {dataField: 'valorDepreciacoesAcumuladasContab', dataType: 'number', caption: capValorDepreciacoesAcumuladasContab, format: formatValue, alignment: 'right', width: 120},
      {dataField: 'status', dataType: 'string', visible: false, showInColumnChooser: false, allowSearch: false},
      {dataField: 'valorDesconto', dataType: 'number', allowSearch: false, visible: false, showInColumnChooser: false}
    ];
    this.dataGridADescontarFields = [
      {dataField: 'codAtivo', dataType: 'number', caption: capCodAtivo, allowEditing: false, width: 95},
      {dataField: 'designacao', dataType: 'string', caption: capDesignacao, allowEditing: false},
      {
        dataField: 'valorDesconto',
        dataType: 'number',
        caption: capvalorNotaCredito,
        allowSearch: false,
        format: formatValue,
        alignment: 'right',
        allowEditing: true,
        width: 120
      },
      {dataField: 'refExterna', dataType: 'string', caption: capRefExterna, allowEditing: false, width: 90},
      {dataField: 'dataAquisicao', dataType: 'date', caption: capDataAquisicao, allowEditing: false, width: 95},
      {dataField: 'valorAtivoBrutoContab', dataType: 'number', caption: capValorAtivoBrutoContab, format: formatValue, alignment: 'right', allowEditing: false, width: 120},
      {
        dataField: 'valorDepreciacoesAcumuladasContab',
        dataType: 'number',
        caption: capValorDepreciacoesAcumuladasContab,
        format: formatValue,
        alignment: 'right',
        allowEditing: false,
        width: 120
      },
      {dataField: 'status', dataType: 'string', visible: false, showInColumnChooser: false, allowSearch: false, allowEditing: false}
    ];
    this.pocsOutput = '{{nConta}} - {{nome}}';
    this.selectedAtivoDisponivel = undefined;
    this.selectedAtivoADescontar = undefined;
    this.fornecedor = '';
    this.valorNotaCreditoTotal = 0;
    this.distribuiValor = EDistribuiValorAtivosNotaCredito.PropLiquido;
    this.radioGroupTemplateDistValor = RADIO_GROUP_ATIVO_NOTA_CREDITO_DIST_VALOR;
  }

  public ngOnInit(): void {
    super.ngOnInit();
    if (!this.ativosNotaCredito) {
      this.ativosNotaCredito = this._emptyAtivosNotaCredito();
    }
    this._btnAplicaNotaCredito = {
      id: 'realizarNotaCredito',
      order: 2,
      type: 'button',
      iconLeft: '<i class="fa fa-check fa-fw"></i>',
      class: 'btn-success',
      caption: 'ativosnotacredito.btn.aplicaDesconto',
      disabled: true,
      click: () => this._realizarNotaCredito()
    };
    this._btnPesquisaFatura = {
      id: 'pesquisafaturavenda',
      order: 1,
      type: 'button',
      iconLeft: '<i class="fa fa-search fa-fw"></i>',
      class: 'btn-primary',
      caption: 'ativosnotacredito.btn.pesquisanota',
      click: () => this._pesquisafaturavenda()
    };
    this.toolbar.addButton(this._btnAplicaNotaCredito);
    this.toolbar.addButton(this._btnPesquisaFatura);
  }

  public async allRight(): Promise<void> {
    return this._dataGridInstanceAtivosDisponiveis
      .getDataSource()
      .store()
      .load()
      .then(async (dataSourceDisponiveis: Array<IAtivoNotaCreditoDisponivel>) => {
        const values: Partial<IAtivoNotaCreditoDisponivel> = {status: 'aDescontar'};
        for (const ativoDisponivel of dataSourceDisponiveis) {
          this._updateDataGridStoreStatus(ativoDisponivel.codAtivo, values);
        }
        await this.executeCalc();
        this._dataGridInstanceAtivosDisponiveis?.deselectAll();
        this._dataGridInstanceADescontar?.deselectAll();
        this.selectedAtivoDisponivel = undefined;
        this.selectedAtivoADescontar = undefined;
      });
  }

  public async selectedRight(): Promise<void> {
    if (this.selectedAtivoDisponivel) {
      this._dataGridInstanceAtivosDisponiveis.beginCustomLoading(undefined);
      this._dataGridInstanceADescontar.beginCustomLoading(undefined);
      await this._updateToRightAtivos(this.selectedAtivoDisponivel.hasGrandesReparacoes, this.selectedAtivoDisponivel.codAtivo);
      this.selectedAtivoDisponivel = undefined;
    }
  }

  public async selectedLeft(): Promise<void> {
    if (this.selectedAtivoADescontar) {
      this._dataGridInstanceAtivosDisponiveis.beginCustomLoading(undefined);
      this._dataGridInstanceADescontar.beginCustomLoading(undefined);
      const values: Partial<IAtivoNotaCreditoDisponivel> = {status: 'disponivel'};
      this.selectedAtivoADescontar.valorDesconto = 0;
      await this._updateDataGridStoreStatus(this.selectedAtivoADescontar.codAtivo, values);
      await this.executeCalc();
      this.selectedAtivoADescontar = undefined;
    }
  }

  public async allLeft(): Promise<void> {
    this._dataGridInstanceAtivosDisponiveis.beginCustomLoading(undefined);
    this._dataGridInstanceADescontar.beginCustomLoading(undefined);
    const dataSourceADescontar: Array<IAtivoNotaCreditoDisponivel> = this._dataGridInstanceADescontar.getDataSource().items();
    for (const aDescontar of dataSourceADescontar) {
      aDescontar.valorDesconto = 0;
    }
    const values: Partial<IAtivoNotaCreditoDisponivel> = {status: 'disponivel'};
    for (const aDescontar of dataSourceADescontar) {
      this._updateDataGridStoreStatus(aDescontar.codAtivo, values);
    }
    this.selectedAtivoDisponivel = undefined;
    this.selectedAtivoADescontar = undefined;
    this._dataGridInstanceAtivosDisponiveis?.deselectAll();
    await this._dataGridInstanceADescontar?.deselectAll();
    this.valorNotaCreditoTotal = 0;
  }

  public async executeCalc(): Promise<void> {
    switch (this.distribuiValor) {
      case EDistribuiValorAtivosNotaCredito.PropLiquido:
        await this._calculaByValorLiquido();
        break;
      case EDistribuiValorAtivosNotaCredito.PropBruto:
        await this._calculaByValorBruto();
        break;
      case EDistribuiValorAtivosNotaCredito.Manualmente:
        await this._calculaByManual();
    }
  }

  public onInitialized({component}: IDevExpressDataGridEventOnInitialized, disponiveis: boolean): void {
    if (disponiveis) {
      this._dataGridInstanceAtivosDisponiveis = component;
    } else {
      this._dataGridInstanceADescontar = component;
    }
  }

  public onCellClick({row}: IDevExpressDataGridEventOnCellClick<IAtivoNotaCreditoDisponivel>): void {
    if (row.data.status === 'disponivel') {
      this.selectedAtivoDisponivel = row.data;
    } else {
      this.selectedAtivoADescontar = row.data;
    }
  }

  public onCellDblClick({data}: IDevExpressDataGridEventOnCellDblClick<IAtivoNotaCreditoDisponivel>): Promise<void> {
    if (!this.ativosNotaCredito.fornecedor && !this.ativosNotaCredito.valorNotaCredito) {
      return Promise.resolve();
    }

    if (data.status === 'disponivel') {
      this.selectedAtivoDisponivel = data;
      return this.selectedRight();
    }

    this.selectedAtivoADescontar = data;
    return this.selectedLeft();
  }

  public onContentReady(): void {
    this.emptyAtivosDisponiveisTable = this._dataGridInstanceAtivosDisponiveis?.totalCount() === 0;
    this.emptyAtivosADescontarTable = this._dataGridInstanceADescontar?.totalCount() === 0;
    const valorNotaCreditoTot: number = this._dataGridInstanceADescontar?.getTotalSummaryValue('valorDesconto');
    this.valorNotaCreditoTotal = isNumber(valorNotaCreditoTot) ? round(valorNotaCreditoTot, NUMBER_TWO) : 0;

    this._validateBtnRealizaNotaCredito();
    this._dataGridInstanceAtivosDisponiveis.endCustomLoading();
    this._dataGridInstanceADescontar.endCustomLoading();
  }

  public readonly fnOnAddADescontarTable = (event: IDevExpressDataGridEventRowDraggingOnAdd<TAtivoNotaCreditoStatus, IAtivoNotaCreditoDisponivel>): Promise<void> => this._onAddADescontarTable(event);

  private async _realizarNotaCredito(): Promise<void> {
    if (round(this._dataGridInstanceADescontar?.getTotalSummaryValue('valorDesconto'), NUMBER_TWO) !== this.ativosNotaCredito.valorNotaCredito) {
      this._plAlertService.error('ativosnotacredito.message.valorNaoValido');
      return Promise.reject(new Error('ativosnotacredito.message.valorNaoValido'));
    }
    const listaAtivosADescontar: Array<IAtivoNotaCreditoDisponivel> = this._dataGridInstanceADescontar.getDataSource().items();
    const notaCredito: IJsonNotaCredito = {
      valorNotaCredito: this.ativosNotaCredito.valorNotaCredito,
      valorAquisicaoNovo: this.ativosNotaCredito.valorAquisicaoNovo,
      extPocCabId: this.ativosNotaCredito.extPocCabId,
      fornecedor: this.ativosNotaCredito.fornecedor,
      notaCreditoContab: this.ativosNotaCredito.notaCreditoContab,
      listaAtivos: listaAtivosADescontar
    };
    await this._ativosNotaCreditoService.postAssociaNotaDeCreditoSerie(notaCredito);
    this._plAlertService.success('ativosnotacredito.message.success');
    for (const ativoDisponivel of listaAtivosADescontar) {
      this._dataGridStore.remove(ativoDisponivel.codAtivo);
    }
    this.ativosNotaCredito = this._emptyAtivosNotaCredito();
    this.selectedAtivoADescontar = undefined;
    return this._dataGridInstanceADescontar.refresh();
  }

  private _validateBtnRealizaNotaCredito(): void {
    this._btnAplicaNotaCredito.disabled = this.emptyAtivosADescontarTable || this.valorNotaCreditoTotal !== this.ativosNotaCredito.valorNotaCredito;
    this._btnAplicaNotaCredito.tooltip = {
      placement: 'bottom',
      text: 'ativosnotacredito.message.valorNaoValido',
      disabled: this.valorNotaCreditoTotal === this.ativosNotaCredito.valorNotaCredito,
      tooltipClass: 'tooltip-danger'
    };
  }

  private async _pesquisafaturavenda(): Promise<void> {
    const notaCreditoPesquisaContab: Array<INotaCreditoPesquisaContab> = (await this._ativosNotaCreditoService.getPesquisaNotaCreditoContab()).body;
    const instance = this._cgModalService.showVanilla(AtivosNotaCreditoContabModalComponent);
    const componentInstance: AtivosNotaCreditoContabModalComponent = instance.componentInstance;
    componentInstance.notaCreditoList = notaCreditoPesquisaContab;
    const result: IJsonNotaCredito = await instance.result;
    this.fornecedor = result.fornecedor;
    this.valorNotaCreditoTotal = result.valorNotaCredito;
    this.ativosNotaCredito = result;
    await this._initAtivosNotaCreditoStore(false);
  }

  private async _calculaByValorLiquido(): Promise<void> {
    const listaAtivosADescontar: Array<IAtivoNotaCreditoDisponivel> = await this._dataGridStore.load();
    let soma = 0;
    let liquido = 0;
    for (const item of listaAtivosADescontar) {
      if (item.status === 'aDescontar') {
        liquido = item.valorAtivoBrutoContab - item.valorDepreciacoesAcumuladasContab;
        soma += liquido;
      }
    }

    let atribuido = 0;
    let peso = 0;
    if (soma > 0) {
      for (const item of listaAtivosADescontar) {
        if (item.status === 'aDescontar') {
          liquido = item.valorAtivoBrutoContab - item.valorDepreciacoesAcumuladasContab;

          peso = (liquido * 100) / soma;
          item.valorDesconto = round((this.ativosNotaCredito.valorNotaCredito * peso) / 100, NUMBER_TWO);
          atribuido += item.valorDesconto;

          if (item.codAtivo === listaAtivosADescontar[listaAtivosADescontar.length - 1].codAtivo) {
            item.valorDesconto += this.ativosNotaCredito.valorNotaCredito - atribuido;
          }

          this._dataGridStore.update(item.codAtivo, item);
        }
      }
    }
  }

  private async _calculaByValorBruto(): Promise<void> {
    const listaAtivosADescontar: Array<IAtivoNotaCreditoDisponivel> = await this._dataGridStore.load();
    let soma = 0;
    for (const item of listaAtivosADescontar) {
      if (item.status === 'aDescontar') {
        soma += item.valorAtivoBrutoContab;
      }
    }

    let atribuido = 0;
    let peso = 0;
    if (soma > 0) {
      for (const item of listaAtivosADescontar) {
        if (item.status === 'aDescontar') {
          peso = (item.valorAtivoBrutoContab * 100) / soma;
          item.valorDesconto = round((this.ativosNotaCredito.valorNotaCredito * peso) / 100, NUMBER_TWO);
          atribuido += item.valorDesconto;

          if (item.codAtivo === listaAtivosADescontar[listaAtivosADescontar.length - 1].codAtivo) {
            item.valorDesconto += round(this.ativosNotaCredito.valorNotaCredito - atribuido, NUMBER_TWO);
          }
          this._dataGridStore.update(item.codAtivo, item);
        }
      }
    }
  }

  private async _calculaByManual(): Promise<void> {
    const listaAtivos: Array<IAtivoNotaCreditoDisponivel> = await this._dataGridStore.load();
    const ativosAVender: Array<IAtivoNotaCreditoDisponivel> = listaAtivos.filter((item: IAtivoNotaCreditoDisponivel) => item.status === 'aDescontar');
    if (ativosAVender.length === 1) {
      ativosAVender[0].valorDesconto = this.ativosNotaCredito.valorNotaCredito;
      this._dataGridStore.update(ativosAVender[0].codAtivo, ativosAVender[0]);
    }
  }

  private async _initAtivosNotaCreditoStore(isFirst = false): Promise<void> {
    this.selectedAtivoDisponivel = undefined;
    this.selectedAtivoADescontar = undefined;
    this.emptyAtivosADescontarTable = true;
    this.valorNotaCreditoTotal = 0;

    const notaCreditoList: Array<IAtivoNotaCreditoDisponivel> = this.ativosNotaCredito.listaAtivos.map<IAtivoNotaCreditoDisponivel>((ativoNotaCredito: IJsonAtivoNotaCreditoDisponivel) => {
      return {
        codAtivo: ativoNotaCredito.codAtivo,
        designacao: ativoNotaCredito.designacao,
        refExterna: ativoNotaCredito.refExterna,
        dataAquisicao: ativoNotaCredito.dataAquisicao,
        valorAtivoBrutoContab: ativoNotaCredito.valorAtivoBrutoContab,
        valorDepreciacoesAcumuladasContab: ativoNotaCredito.valorDepreciacoesAcumuladasContab,
        codAtivoPai: ativoNotaCredito.codAtivoPai,
        isGrandeReparacao: ativoNotaCredito.isGrandeReparacao,
        hasGrandesReparacoes: ativoNotaCredito.hasGrandesReparacoes,
        valorDesconto: ativoNotaCredito.valorDesconto,
        status: 'disponivel'
      };
    });

    this._dataGridStore = new ArrayStore({
      key: 'codAtivo',
      data: notaCreditoList
    });
    this.dataGridDefinition.dataSource = {
      store: this._dataGridStore,
      reshapeOnPush: true
    };
    if (!isFirst) {
      await this._dataGridInstanceAtivosDisponiveis?.refresh();
      await this._dataGridInstanceADescontar?.refresh();
    }
    this._validateBtnRealizaNotaCredito();
  }

  private async _onAddADescontarTable(event: IDevExpressDataGridEventRowDraggingOnAdd<TAtivoNotaCreditoStatus, IAtivoNotaCreditoDisponivel>): Promise<void> {
    if (!this.ativosNotaCredito?.valorNotaCredito && !this.ativosNotaCredito?.fornecedor) {
      return;
    }

    this._dataGridInstanceADescontar?.beginCustomLoading('');
    this._dataGridInstanceAtivosDisponiveis?.beginCustomLoading('');
    const key: number = event.itemData.codAtivo;
    const values: Partial<IAtivoNotaCreditoDisponivel> = {status: event.toData};
    await this._updateDataGridStoreStatus(key, values);
    await this.executeCalc();
  }

  private async _updateDataGridStoreStatus(key: number, values: Partial<IAtivoNotaCreditoDisponivel>): Promise<void> {
    await this._dataGridStore.update(key, values);
    this._dataGridStore.push([
      {
        type: 'update',
        key,
        data: values
      }
    ]);
  }

  private _emptyAtivosNotaCredito(): IJsonNotaCredito {
    return {
      valorNotaCredito: undefined,
      valorAquisicaoNovo: undefined,
      extPocCabId: '',
      fornecedor: '',
      notaCreditoContab: [],
      listaAtivos: []
    };
  }

  private async _updateToRightAtivos(hasGrandesReparacoes: boolean, codAtivo: number): Promise<void> {
    const keyList: Array<number> = [codAtivo];
    if (hasGrandesReparacoes) {
      const list: Array<IAtivoNotaCreditoDisponivel> = this._dataGridInstanceAtivosDisponiveis.getDataSource().items();
      const listGrandesReparacoes: Array<number> = list
        .filter((notaCredito: IAtivoNotaCreditoDisponivel) => notaCredito.codAtivoPai === codAtivo)
        .map((notaCredito: IAtivoNotaCreditoDisponivel) => notaCredito.codAtivo);
      if (listGrandesReparacoes.length > 0) {
        const confirmGrandesReparacoes: boolean = await confirm(
          this._translateService.instant('ativosnotacredito.message.ativoHasGrandeReparacoes', {ativo: codAtivo}),
          this._translateService.instant('global.text.confirmation')
        );
        if (confirmGrandesReparacoes) {
          for (const grandeReparacao of listGrandesReparacoes) {
            keyList.push(grandeReparacao);
          }
        }
      }
    }
    for (const key of keyList) {
      this._updateDataGridStoreStatus(key, {status: 'aDescontar'}).then(() => {
        this.executeCalc();
      });
    }
  }
}
