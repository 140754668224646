<div class="documento-faturacao" *ngIf="doc?.cab">
  <div class="card">
    <div class="card-header" [class.doc-anulado]="doc.cab.anulado">
      <div class="card-title documento-faturacao-header">
        <div class="documento-faturacao-header-title">
          <div class="badge bg-success" *ngIf="doc.cab.comunicadoAoEFatura">
            <strong><span [translate]="'docscomerciais.info.comunicadoAT'"></span></strong>
          </div>
          <div class="badge bg-danger" *ngIf="doc.cab.anulado">
            <strong>{{ doc.cab.anuladoStr }}</strong>
          </div>
          <div>
            <strong>{{ doc.cab.docNome }} Nº: </strong>{{ doc.cab.nDoc }}
          </div>
          <div class="badge bg-warning" *ngIf="doc.cab.transformado">
            <strong><span [translate]="'docscomerciais.fields.transformado'"></span></strong>
          </div>

          <br />
          <div class="documento-faturacao-header-doc-info resumo-wrapper order-lg-2">
            <span [translate]="'docscomerciais.doc.cab.dataDoc'"></span>:<span class="badge bg-secondary ms-2">{{ doc.cab.dataDoc | cgcDate }}</span>
          </div>

          <div class="documento-faturacao-header-toolbar order-lg-1">
            <button
              type="button"
              class="btn btn-primary btn-sm action-pdf"
              *ngIf="actionPdf && doc.cab.grupoDocfa !== gruposDocFa.EntradasDiversas && doc.cab.grupoDocfa !== gruposDocFa.SaidasDiversas"
              (click)="pdf(doc, true)">
              <i class="fa fa-fw fa-file-pdf-o"></i>&nbsp;<span [translate]="'global.btn.pdf'"></span>
            </button>

            <a rel="noopener noreferrer" class="btn btn-primary btn-sm action-pdf-url" *ngIf="actionPdfUrl" [href]="doc._pdfUrl" [attr.download]="doc._downloadUrl" target="_blank">
              <i class="fa fa-fw fa-file-pdf-o"></i>&nbsp;<span [translate]="'global.btn.downloadPdf'"></span>
            </a>

            <button
              type="button"
              class="btn btn-warning btn-sm action-editar-documento"
              *ngIf="actionEditarDocumento && (doc.cab.nDocumento === 0 || !doc.cab.terminado) && doc.cab.hashEstado <= faccbHASHEstado.DocSemAssinatura"
              (click)="editarDocumento()">
              <i class="fa fa-fw fa-pencil-square-o"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.editarDocumento'"></span>
            </button>

            <div ngbDropdown>
              <button
                type="button"
                class="btn btn-info btn-sm"
                ngbDropdownToggle
                *ngIf="
                  actionAnexarGDocComercial ||
                  actionComunicarDocWebServiceAT ||
                  actionComunicarDocCirculacaoAT ||
                  actionInserirCodTranspAT ||
                  actionTransformarLinhasDoc ||
                  actionDesmarcarDocTransformado ||
                  actionNotaCreditoDoc ||
                  actionMeiosPagamentoComercial
                ">
                <i class="fa fa-fw fa fa-bars"></i>&nbsp;<span class="caret" [translate]="'global.btn.actions'"></span>
              </button>

              <div ngbDropdownMenu>
                <button type="button" class="action-gdoc-anexo-comercial" ngbDropdownItem *ngIf="actionAnexarGDocComercial && showGDocs" (click)="anexarGDocComercial()">
                  <i class="fa fa-fw fa-paperclip"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.anexos'"></span>
                </button>

                <button type="button" class="action-meios-pagamento-comercial" ngbDropdownItem *ngIf="actionMeiosPagamentoComercial && doc.meiosPagamento.length > 0" (click)="showMeiosPagamento()">
                  <i class="fa fa-credit-card"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.meiosPagam'"></span>
                </button>

                <button
                  type="button"
                  class="action-reabrir-documento"
                  ngbDropdownItem
                  *ngIf="hasComercial && actionReAbreDocumento && !doc.cab.anulado && (doc.cab.terminado || doc.cab.hashEstado === faccbHASHEstado.DocAssinado)"
                  (click)="reAbrirDocumento()">
                  <i class="fa fa-fw fa-pencil-square-o"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.editarDocumento'"></span>
                </button>

                <button
                  type="button"
                  class="action-comunicar-doc-web-service-at"
                  ngbDropdownItem
                  *ngIf="actionComunicarDocWebServiceAT && doc.cab.faturaWebServSujAComunicacao && doc.cab.atEstado !== 2 && doc.cab.atEstado !== 4"
                  (click)="comunicarDocWebServiceAT()">
                  <i class="fa fa-fw fa-paper-plane"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.communicateFaturaAT'"></span>
                </button>

                <button
                  type="button"
                  class="action-comunicar-at"
                  ngbDropdownItem
                  *ngIf="
                    actionComunicarDocCirculacaoAT &&
                    doc.cab.isDocTransporteSAFT &&
                    !doc.cab.anulado &&
                    doc.cab.nDocumento > 0 &&
                    doc.cab.atDocCodeID === '' &&
                    doc.cab.estadoEnvio !== 2 &&
                    doc.cab.estadoEnvio !== 3
                  "
                  (click)="comunicarDocCirculacaoAT()">
                  <i class="fa fa-fw fa-paper-plane"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.communicateDocTransporteAT'"></span>
                </button>

                <button
                  type="button"
                  class="action-inserir-cod-transp-at"
                  ngbDropdownItem
                  *ngIf="
                    actionInserirCodTranspAT &&
                    doc.cab.isDocTransporteSAFT &&
                    !doc.cab.anulado &&
                    doc.cab.nDocumento > 0 &&
                    doc.cab.atDocCodeID === '' &&
                    doc.cab.estadoEnvio !== 2 &&
                    doc.cab.estadoEnvio !== 3
                  "
                  (click)="inserirCodigoTransporteAT()">
                  <i class="fa fa-fw fa-volume-control-phone"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.insertATCode'"></span>
                </button>

                <button
                  type="button"
                  class="action-transformar-linhas-doc"
                  ngbDropdownItem
                  *ngIf="actionTransformarLinhasDoc && !doc.cab.anulado && doc.cab.nDocumento > 0 && showTransformarLinhasDoc"
                  (click)="transformarLinhasDocumento()">
                  <i class="fa fa-fw fa-stack-overflow"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.transformarLinhasDoc'"></span>
                </button>

                <button
                  type="button"
                  class="action-desmarcar-doc-transformado"
                  ngbDropdownItem
                  *ngIf="actionDesmarcarDocTransformado && doc.cab.transformado && !doc.cab.anulado"
                  (click)="desmarcarDocTransformado()">
                  <i class="fa fa-fw fa-share-square-o"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.desmarcadoDocTransformado'"></span>
                </button>

                <button type="button" class="action-devolucao-doc" ngbDropdownItem *ngIf="actionNotaCreditoDoc && doc.cab.podeFazerDevolucao" (click)="notaCreditaDocumento()">
                  <i class="fa fa-fw fa-share-square-o"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.notaCredito'"></span>
                </button>

                <button type="button" class="action-duplicar-doc" ngbDropdownItem *ngIf="actionDuplicarDoc" (click)="duplicarDocumento()">
                  <i class="fa fa-fw fa-copy"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.duplicarDoc'"></span>
                </button>

                <button type="button" class="action-duplicar-doc" ngbDropdownItem *ngIf="actionDuplicarParaNovoDoc" (click)="duplicarDocumentoEditavel()">
                  <i class="fa fa-fw fa-copy"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.duplicarDoc'"></span>
                </button>

                <button type="button" class="action-copiarlinhas-doc" ngbDropdownItem *ngIf="actionCopiarLinhas" (click)="copiarLinhas()">
                  <i class="fa fa-fw fa-copy"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.copiarLinhas'"></span>
                </button>

                <button *ngIf="entityNameClifos && !doc.cab.anulado" type="button" ngbDropdownItem (click)="adiantRegistaDoc(doc)">
                  <i class="fa fa-fw fa-pencil-square-o"></i>&nbsp;<span [translate]="'docscomerciais.actions.adiantRegDoc'"></span>
                </button>

                <button
                  *ngIf="entityNameClifos && !doc.cab.anulado && doc.cab.terminado && (grupoDoc === gruposDocFa.VendasEfectivas || grupoDoc === gruposDocFa.ComprasEfectivas)"
                  type="button"
                  ngbDropdownItem
                  (click)="regularizarAdiantamentosModal(doc)">
                  <i class="fa fa-fw fa-list-alt"></i>&nbsp;<span [translate]="'docscomerciais.actions.adiantConsult'"></span>
                </button>

                <button type="button" class="action-encerrar-doc" ngbDropdownItem *ngIf="actionEncerrarDocumento && !doc.cab.terminado" (click)="encerrarDocumento()">
                  <i class="fa fa-fw fa-file-archive-o"></i>&nbsp;<span [translate]="'docscomerciais.btn.finalizar'"></span>
                </button>

                <button type="button" ngbDropdownItem *ngIf="this.hasContabilidade && doc.cab.integraContabilidade && doc.cab.terminado" (click)="showDocContab()">
                  <i class="fa fa-eye fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.btn.showDocsOnContab'"></span>
                </button>

                <button type="button" ngbDropdownItem (click)="showRastreabilidade()">
                  <i class="fa fa-binoculars fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.actions.rastreabilidade'"></span>
                </button>
              </div>
            </div>

            <button
              type="button"
              class="btn btn-danger btn-sm action-anular-doc"
              *ngIf="actionAnularDoc && podeAnularDoc"
              [disabled]="doc.cab.comunicadoAoEFatura"
              [attr.title]="hintBtnAnular"
              (click)="anularDocumento()">
              <i class="fa fa-fw fa-scissors"></i>&nbsp;<span [translate]="'documentoFaturacao.btn.anular'"></span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="card-body card-documento">
      <pl-form [properties]="{orientation: 'horizontal'}" [formInstanceName]="'documento-faturacao-card'">
        <pl-group class="docfa-edit">
          <label><span [translate]="'docscomerciais.doc.cab.nDocFa'"></span>:</label>
          <edit>
            <pl-edit type="text" [model]="doc.nDocFaOutput" [properties]="{disabled: true}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group class="clifo-edit" *ngIf="!isTipoDocStocks">
          <label
            ><span>{{ clifoDescription }}</span
            >:</label
          >
          <edit>
            <pl-edit type="text" [model]="doc.clifo.nContribuint" [properties]="{disabled: true}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group class="clifo-nome-edit" *ngIf="!isTipoDocStocks">
          <label><span [translate]="'docscomerciais.doc.clifo.nome'"></span>:</label>
          <edit>
            <pl-edit type="text" attrName="nomeClifo" [(model)]="doc.clifo.nome" [properties]="{disabled: true}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group *ngIf="!isTipoDocStocks">
          <label>&nbsp;</label>
          <edit>
            <span style="font-size: 12px; font-weight: 700" class="clifo-info" *ngIf="getClifoUpdateDescrition() || doc.clifo.nContribuint === cgDefaultAtNif">
              {{ getClifoUpdateDescrition() }}
            </span>
          </edit>
        </pl-group>

        <pl-group *ngIf="!isTipoDocStocks" class="clifo-morada-info">
          <label></label>
          <edit class="clifo-info">
            <div>{{ doc.clifo.rua }}</div>
            <div>{{ doc.clifo.codPostal }}</div>
            <div>{{ doc.clifo.localidade }}</div>
            <div *ngIf="nContaNifSituacaoMsg && nContaNifSituacaoMsg.length">
              Situação: <span [class.text-danger]="nContaNifSituacaoMsg !== 'ACTIVA'">{{ nContaNifSituacaoMsg }}</span>
            </div>
          </edit>
        </pl-group>

        <pl-group *ngIf="!isTipoDocStocks && docCabFieldsVisibilityOptions.showDocExterno">
          <pl-group class="cab-options-edit">
            <label><span [translate]="'docscomerciais.doc.cab.dataDocExterno'"></span>:</label>
            <edit>
              <pl-edit-datepicker attrName="dataDocExterno" [model]="doc.cab.dataDocExterno" [properties]="{disabled: true}"></pl-edit-datepicker>
            </edit>
          </pl-group>

          <pl-group class="cab-options-edit">
            <label><span [translate]="'docscomerciais.doc.cab.nFactFornec'"></span>:</label>
            <edit>
              <pl-edit type="text" attrName="nFactFornec" [model]="doc.cab.nFactFornec" [properties]="{disabled: true}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group *ngIf="!isTipoDocStocks && docCabFieldsVisibilityOptions.showDataVenc">
          <pl-group class="cab-options-edit">
            <label><span [translate]="'docscomerciais.doc.cab.nCondPagam'"></span>:</label>
            <edit>
              <pl-edit type="text" attrName="nCondPagOutput" [model]="doc.nCondPagOutput" [properties]="{disabled: true}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group class="cab-options-edit">
            <label><span [translate]="'docscomerciais.doc.cab.dataVenc'"></span>:</label>
            <edit>
              <pl-edit-datepicker attrName="dataVenc" [model]="doc.cab.dataVenc" [properties]="{disabled: true}"></pl-edit-datepicker>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group class="moeda-edit" *ngIf="!isTipoDocStocks && doc.cab.codMoeda && docCabFieldsVisibilityOptions.showMoeda">
          <pl-group>
            <label><span [translate]="'docscomerciais.doc.cab.codMoeda'"></span>:</label>
            <edit>
              <pl-edit type="text" [model]="doc.moedaOutput" [properties]="{disabled: true}"></pl-edit>
            </edit>
          </pl-group>

          <pl-group *ngIf="docCabFieldsVisibilityOptions.showMoeda && doc.cab.codMoeda !== codMoedaEmpresa">
            <label><span [translate]="'docscomerciais.doc.cab.cambio'"></span>:</label>
            <edit>
              <pl-edit type="number" [inputClass]="'text-end'" [properties]="{disabled: true, decimalsLimit: 5}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>

        <pl-group class="cab-options-edit" *ngIf="!isTipoDocStocks && docCabFieldsVisibilityOptions.showCCusto">
          <label><span [translate]="'docscomerciais.doc.cab.nCCusto'"></span>:</label>
          <edit>
            <pl-edit type="text" attrName="nCondPagOutput" [model]="doc.nCCustoOutput" [properties]="{disabled: true}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group class="cab-options-edit" *ngIf="!isTipoDocStocks && docCabFieldsVisibilityOptions.showNRefProcesso">
          <label><span [translate]="'docscomerciais.doc.cab.nRefProcesso'"></span>:</label>
          <edit>
            <pl-edit type="text" attrName="nRefProcesso" [model]="doc.cab.nrefprocesso" [properties]="{disabled: true}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group class="cab-options-edit" *ngIf="!isTipoDocStocks && docCabFieldsVisibilityOptions.showNRequisicao">
          <label><span [translate]="'docscomerciais.doc.cab.nRequisicao'"></span>:</label>
          <edit>
            <pl-edit type="text" attrName="nRequisicao" [(model)]="doc.cab.nRequisicao" [properties]="{disabled: true}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group class="cab-options-edit" *ngIf="!isTipoDocStocks && docCabFieldsVisibilityOptions.showObservacoes">
          <label><span [translate]="'docscomerciais.doc.cab.observacoes'"></span>:</label>
          <edit>
            <pl-edit type="text" attrName="observacoes" [(model)]="doc.cab.observacoes" [properties]="{disabled: true}"></pl-edit>
          </edit>
        </pl-group>

        <pl-group>
          <pl-group>
            <label><span [translate]="'docscomerciais.doc.cab.estadoEnvioStr'"></span>:</label>
            <edit>
              <pl-edit type="text" attrName="estadoEnvioStr" [(model)]="doc.cab.estadoEnvioStr" [properties]="{disabled: true}"></pl-edit>
            </edit>
          </pl-group>
          <pl-group>
            <label><span [translate]="'docscomerciais.doc.cab.atDocCodeID'"></span>:</label>
            <edit>
              <pl-edit type="text" attrName="atDocCodeID" [(model)]="doc.cab.atDocCodeID" [properties]="{disabled: true}"></pl-edit>
            </edit>
          </pl-group>
        </pl-group>
      </pl-form>

      <div class="gestaocomercial-widget-container" style="padding-bottom: 20px">
        <div class="gestaocomercial-widget" style="width: 100%">
          <pl-tabs [theme]="'style3'" [(activeId)]="activeMainTab" (evtSelected)="changedTab($event.nextId)">
            <pl-tab [id]="mainTabLinhasId">
              <div *plTabTitle><i class="fa fa-list fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.linhas'"></span></div>
              <div *plTabContent>
                <div class="gestaocomercial-widget-body">
                  <div class="gestaocomercial-linhas-wrapper">
                    <div class="gestaocomercial-linhas">
                      <div class="table-responsive">
                        <table class="table-artigos cg-dx-table-theme">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th [translate]="'docscomerciais.doc.linhas.nArtigo'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.nome'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.qtd'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.nArmazem'" *ngIf="docCabFieldsVisibilityOptions.showNArmazem"></th>
                              <th [translate]="'docscomerciais.doc.linhas.preco'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.codIva'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.taxaIva'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.valorIva'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.valorComIva'" *ngIf="docCabFieldsVisibilityOptions.showValorCIva"></th>
                              <th [translate]="'docscomerciais.doc.linhas.liquido'" *ngIf="docCabFieldsVisibilityOptions.showLiquido"></th>
                              <th [translate]="'docscomerciais.doc.linhas.codUnidMov'" *ngIf="docCabFieldsVisibilityOptions.showCodUnidMov"></th>
                              <th [translate]="'docscomerciais.doc.linhas.nVendedor'" *ngIf="docCabFieldsVisibilityOptions.showNVendedor"></th>
                              <th [translate]="'docscomerciais.doc.linhas.nCCusto'" *ngIf="docCabFieldsVisibilityOptions.showCCusto"></th>
                              <th [translate]="'docscomerciais.doc.linhas.nRefProcesso'" *ngIf="docCabFieldsVisibilityOptions.showNRefProcesso"></th>
                              <th [translate]="'docscomerciais.doc.linhas.desconto'"></th>
                              <th [translate]="'docscomerciais.doc.linhas.d2'" *ngIf="docCabFieldsVisibilityOptions.showD2"></th>
                              <th [translate]="'docscomerciais.doc.linhas.d3'" *ngIf="docCabFieldsVisibilityOptions.showD3"></th>
                              <th [translate]="'docscomerciais.doc.linhas.dataEntregaPrev'" *ngIf="showDataEntregaPrev"></th>
                              <th [translate]="'docscomerciais.doc.linhas.descEmValor'" *ngIf="docCabFieldsVisibilityOptions.showDescEmValor"></th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr
                              *ngFor="let item of paginatedLines; trackBy: trackByFn; index as $index; first as isFirst"
                              class="doccomercial-linha"
                              [attr.data-row-id]="paginationLinhas.offset + $index">
                              <td style="min-width: 50px; max-width: 50px; vertical-align: middle">{{ item.nSeq }}</td>

                              <!--NARTIGO-->
                              <td class="doccomercial-linha-artigo-td" style="min-width: 180px; width: 15%">
                                <pl-edit type="text" [model]="item.nArtigo" [properties]="{disabled: true}"></pl-edit>
                                <div class="doccomercial-linha-devolucao-motivo text-info" *ngIf="item.docRegularizMotivo">
                                  <i class="fa fa-fw fa-info-circle" aria-hidden="true"></i>&nbsp;<span>Motivo: {{ item.docRegularizMotivo }}</span>
                                </div>
                              </td>

                              <!--NOME ARTIGO-->
                              <td style="min-width: 300px; width: 30%">
                                <pl-edit type="text" [model]="item.nomeArtigo" [properties]="{disabled: true}"></pl-edit>

                                <!-- TEXTO LIVRE -->
                                <textarea
                                  *ngIf="item.textoLivre"
                                  class="mt-1 form-control"
                                  rows="2"
                                  [attr.name]="'descricao' + (paginationLinhas.offset + $index)"
                                  [ngModel]="item.textoLivre"
                                  disabled="true">
                                </textarea>
                              </td>

                              <!--QTD-->
                              <td style="min-width: 180px; width: 11%">
                                <pl-edit type="number" [model]="item.qtd1" [properties]="{disabled: true}"></pl-edit>
                              </td>

                              <!--NARMAZEM-->
                              <td *ngIf="docCabFieldsVisibilityOptions.showNArmazem" style="min-width: 100px">
                                <pl-edit type="text" [model]="item.nArmazem" [properties]="{disabled: true, decimalsLimit: 0}"></pl-edit>
                              </td>

                              <!--PRECO-->
                              <td style="min-width: 200px; width: 12%">
                                <pl-edit type="number" [model]="item.prVenda1" [properties]="{disabled: true}"></pl-edit>
                              </td>

                              <!--CODIVA-->
                              <td style="min-width: 80px; width: 6%">
                                <pl-edit type="number" [model]="item.codIva" [properties]="{disabled: true, decimalsLimit: 0}"></pl-edit>
                              </td>

                              <!--TAXAIVA-->
                              <td style="min-width: 80px; width: 6%">
                                <pl-edit type="text" [inputClass]="'text-end'" [model]="item.taxaIva" [properties]="{disabled: true}"></pl-edit>
                              </td>

                              <!--VALORIVA-->
                              <td style="min-width: 150px; width: 10%">
                                <pl-edit type="number" [model]="item.valorIvaME" [inputClass]="'text-end'" [properties]="{disabled: true}"></pl-edit>
                              </td>

                              <!--VALORCOMIVA-->
                              <td *ngIf="docCabFieldsVisibilityOptions.showValorCIva" style="min-width: 150px">
                                <pl-edit type="number" [model]="item.prVendaIvaIncME" [inputClass]="'text-end'" [properties]="{disabled: true}"></pl-edit>
                              </td>

                              <!--LIQUIDO-->
                              <td *ngIf="docCabFieldsVisibilityOptions.showLiquido" style="min-width: 150px">
                                <pl-edit type="number" [model]="item.liquido" [inputClass]="'text-end'" [properties]="{disabled: true}"></pl-edit>
                              </td>

                              <!--CODUNIDMOV-->
                              <td *ngIf="docCabFieldsVisibilityOptions.showCodUnidMov" style="min-width: 115px">
                                <pl-edit type="number" [model]="item.codUnidMov" [properties]="{disabled: true, decimalsLimit: 0}"></pl-edit>
                              </td>

                              <!--NVENDEDOR-->
                              <td *ngIf="docCabFieldsVisibilityOptions.showNVendedor" style="min-width: 100px">
                                <pl-edit type="number" [model]="item.nVendedor" [properties]="{disabled: true, decimalsLimit: 0}"></pl-edit>
                              </td>

                              <!--NCCUSTO-->
                              <td *ngIf="docCabFieldsVisibilityOptions.showCCusto" style="min-width: 100px">
                                <pl-edit type="text" [model]="item.nCCusto" [properties]="{disabled: true}"></pl-edit>
                              </td>

                              <!--NREFPROCESSO-->
                              <td *ngIf="docCabFieldsVisibilityOptions.showNRefProcesso" style="min-width: 100px">
                                <pl-edit type="text" [model]="item.nrefprocesso" [properties]="{disabled: true}"></pl-edit>
                              </td>

                              <td style="min-width: 200px; width: 4%">
                                <pl-edit type="number" [model]="item.d1" [inputClass]="'text-end'" [properties]="{disabled: true}"></pl-edit>
                              </td>

                              <td style="min-width: 200px; width: 4%" *ngIf="docCabFieldsVisibilityOptions.showD2">
                                <pl-edit type="number" attrName="d2" [model]="item.d2" [inputClass]="'text-end'" [properties]="{disabled: true}"></pl-edit>
                              </td>

                              <td style="min-width: 200px; width: 4%" *ngIf="docCabFieldsVisibilityOptions.showD3">
                                <pl-edit type="number" attrName="d3" [model]="item.d3" [inputClass]="'text-end'" [properties]="{disabled: true}"></pl-edit>
                              </td>

                              <td style="min-width: 140px; width: 4%" *ngIf="showDataEntregaPrev">
                                <pl-edit-datepicker attrName="dataEntregaPrev" [model]="item.dataEntregaPrev" [properties]="{disabled: true}"></pl-edit-datepicker>
                              </td>

                              <!--DESCONTO-->
                              <td style="min-width: 200px; width: 4%" *ngIf="docCabFieldsVisibilityOptions.showDescEmValor">
                                <pl-edit type="number" [model]="item.desconto" [inputClass]="'text-end'" [properties]="{disabled: true}"></pl-edit>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <pl-pagination
                      *ngIf="doc.linhas.length > paginationMinimumPerPage"
                      [page]="paginationLinhas.page"
                      [perPage]="paginationLinhas.perPage"
                      [total]="doc.linhas.length"
                      [lines]="paginationLinhas.allowedPageSizes"
                      (pageChange)="paginationLinhas.page = $event; paginate()"
                      (perPageChange)="paginationLinhas.perPage = $event; paginate()">
                    </pl-pagination>
                  </div>
                </div>

                <div class="gestaocomercial-widget-container" style="margin-left: 0; margin-right: 0">
                  <div class="d-flex" style="justify-content: flex-end">
                    <div>
                      <div class="gestaocomercial-widget" style="height: 100%; margin-right: 10px" *ngIf="doc.cab.codRet">
                        <h4 class="page-header-cg text-right">Retenção</h4>
                        <div class="gestaocomercial-widget-body resumo">
                          <dl>
                            <dt><span [translate]="'docscomerciais.doc.cab.taxaRet'"></span>:</dt>
                            <dd>{{ doc.cab.taxaRet }}%</dd>
                            <dt><span [translate]="'docscomerciais.doc.cab.retencaoIncidencia'"></span>:</dt>
                            <dd>{{ doc.cab.retencaoIncidencia | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>
                            <dt><span [translate]="'docscomerciais.doc.cab.retencaoValor'"></span>:</dt>
                            <dd>{{ doc.cab.retencaoValor | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>
                          </dl>
                        </div>
                      </div>
                      <div class="gestaocomercial-widget" style="height: 100%">
                        <h4 class="page-header-cg text-right" [translate]="'documentoFaturacao.summary'"></h4>
                        <div class="gestaocomercial-widget-body resumo">
                          <dl>
                            <dt><span [translate]="'docscomerciais.doc.cab.totalIliquido'"></span>:</dt>
                            <dd>{{ doc.cab.totalIliquido | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>

                            <dt><span [translate]="'docscomerciais.doc.cab.totalDesconto'"></span>:</dt>
                            <dd>{{ doc.cab.totalDesconto | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>

                            <dt><span [translate]="'docscomerciais.doc.cab.totalLiquido'"></span>:</dt>
                            <dd>{{ doc.cab.totalLiquido | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>

                            <dt><span [translate]="'docscomerciais.doc.cab.totalIva'"></span>:</dt>
                            <dd>{{ doc.cab.totalIva | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>
                          </dl>

                          <hr />

                          <dl class="total-doc">
                            <dt><span [translate]="'global.text.total'"></span>:</dt>
                            <dd>{{ doc.cab.totalDocumento | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>
                            <dt *ngIf="codMoedaEmpresa !== doc.cab.codMoeda"></dt>
                            <dd *ngIf="codMoedaEmpresa !== doc.cab.codMoeda">{{ doc.cab.totalDocumentoME | cgcCurrency: 2 : abreviaturaMoedaCliente : 'code' }}</dd>
                          </dl>
                          <ng-container *ngIf="doc.cab.codRet">
                            <hr />
                            <dl class="retencao-doc">
                              <dt>Retenção:</dt>
                              <dd>{{ doc.cab.retencaoValor | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>
                              <dt>Total Líquido:</dt>
                              <dd>{{ doc.cab.totalDocumento - doc.cab.retencaoValor | cgcCurrency: 2 : abreviaturaMoedaEmpresa : 'code' }}</dd>
                            </dl>
                          </ng-container>
                          <span style="font-size: 0.7rem" *ngIf="arredManualString !== ''" [translate]="arredManualString"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </pl-tab>
            <pl-tab [id]="mainTabCargaId" [disabled]="!mainTabCargaEnabled">
              <div *plTabTitle><i class="fa fa-truck fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.transporte'"></span></div>
              <div *plTabContent>
                <div class="gestaocomercial-widget-body">
                  <pl-form>
                    <div class="row">
                      <div class="col-sm-12 col-lg-6">
                        <div class="card mb-3">
                          <div class="card-header"><i class="fa fa-address-card fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.tabs.carga'"></span></div>
                          <div class="card-body">
                            <pl-group>
                              <pl-group>
                                <label [translate]="'docscomerciais.doc.cab.dataInicTran'"></label>
                                <edit>
                                  <pl-edit-datepicker attrName="dataInicTran" [model]="doc.cab.dataInicTran" [properties]="{disabled: true}"></pl-edit-datepicker>
                                </edit>
                              </pl-group>

                              <pl-group>
                                <label [translate]="'docscomerciais.doc.cab.horaInicTran'"></label>
                                <edit>
                                  <pl-edit-timepicker [model]="doc.cab.horaInicTran" [properties]="{disabled: true}"></pl-edit-timepicker>
                                </edit>
                              </pl-group>
                            </pl-group>

                            <pl-group>
                              <label [translate]="'docscomerciais.doc.moradaCarga.rua'"></label>
                              <pl-group>
                                <edit>
                                  <pl-edit type="text" [model]="doc.moradaCarga.rua" [properties]="{disabled: true}"></pl-edit>
                                  <pl-edit type="text" [model]="doc.moradaCarga.morada" [properties]="{disabled: true}"></pl-edit>
                                </edit>
                              </pl-group>
                            </pl-group>

                            <pl-group>
                              <pl-group>
                                <label [translate]="'docscomerciais.doc.moradaCarga.codPostal'"></label>
                                <edit>
                                  <pl-edit type="text" [model]="doc.moradaCarga.codPostal" [properties]="{disabled: true}"></pl-edit>
                                </edit>
                              </pl-group>

                              <pl-group>
                                <label [translate]="'docscomerciais.doc.moradaCarga.localidade'"></label>
                                <edit>
                                  <pl-edit type="text" [model]="doc.moradaCarga.localidade" [properties]="{disabled: true}"></pl-edit>
                                </edit>
                              </pl-group>
                            </pl-group>

                            <pl-group>
                              <pl-group>
                                <label [translate]="'docscomerciais.doc.moradaCarga.codPais'"></label>
                                <edit>
                                  <pl-edit type="text" [model]="doc.moradaCarga.codPais" [properties]="{disabled: true}"></pl-edit>
                                </edit>
                              </pl-group>
                            </pl-group>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12 col-lg-6">
                        <div class="card mb-3">
                          <div class="card-header"><i class="fa fa-address-card-o fa-flip-horizontal fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.tabs.descarga'"></span></div>
                          <div class="card-body">
                            <pl-group>
                              <label [translate]="'docscomerciais.doc.moradaDescarga.moralId'"></label>
                              <edit>
                                <pl-edit type="text" [model]="doc.moradaDescarga.morada" [properties]="{disabled: true}"></pl-edit>
                              </edit>
                            </pl-group>

                            <pl-group>
                              <label [translate]="'docscomerciais.doc.moradaDescarga.rua'"></label>
                              <edit>
                                <pl-edit type="text" [model]="doc.moradaDescarga.rua" [properties]="{disabled: true}"></pl-edit>
                                <pl-edit type="text" [model]="doc.moradaDescarga.morada" [properties]="{disabled: true}"></pl-edit>
                              </edit>
                            </pl-group>

                            <pl-group>
                              <pl-group>
                                <label [translate]="'docscomerciais.doc.moradaDescarga.codPostal'"></label>
                                <pl-edit type="text" [model]="doc.moradaDescarga.codPostal" [properties]="{disabled: true}"></pl-edit>
                              </pl-group>

                              <pl-group>
                                <label [translate]="'docscomerciais.doc.moradaDescarga.localidade'"></label>
                                <edit>
                                  <pl-edit type="text" [model]="doc.moradaDescarga.localidade" [properties]="{disabled: true}"></pl-edit>
                                </edit>
                              </pl-group>
                            </pl-group>

                            <pl-group>
                              <pl-group>
                                <label [translate]="'docscomerciais.doc.moradaDescarga.codPais'"></label>
                                <edit>
                                  <pl-edit type="text" [model]="doc.moradaDescarga.codPais" [properties]="{disabled: true}"></pl-edit>
                                </edit>
                              </pl-group>

                              <pl-group>
                                <label [translate]="'docscomerciais.doc.moradaDescarga.observacoes'"></label>
                                <edit>
                                  <pl-edit type="text" [model]="doc.moradaDescarga.observacoes" [properties]="{disabled: true}"></pl-edit>
                                </edit>
                              </pl-group>
                            </pl-group>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12 col-lg-6">
                        <div class="card mb-3">
                          <div class="card-header"><i class="fa fa-truck fa-flip-horizontal fa-fw"></i>&nbsp;<span [translate]="'docscomerciais.tabs.transportadora'"></span></div>
                          <div class="card-body">
                            <pl-group>
                              <label [translate]="'docscomerciais.doc.transportador.matricula'"></label>
                              <edit>
                                <pl-edit type="text" [model]="doc.transportador.matricula" [properties]="{disabled: true}"></pl-edit>
                              </edit>
                            </pl-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </pl-form>
                </div>
              </div>
            </pl-tab>

            <pl-tab [id]="mainTabPrestacoesId" *ngIf="docCabFieldsVisibilityOptions.showPrestacoes && (isComprasEfectivas || isVendasEfectivas)">
              <div *plTabTitle>
                <i class="fa fa-calendar fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.prestacoes'"></span>&nbsp;<span
                  class="badge"
                  [translate]="prestacoesTableSource.length.toString()"></span>
              </div>
              <div *plTabContent>
                <div class="gestaocomercial-widget-body">
                  <dx-data-grid [cgDxDataGrid]="dataGridPrestacoesDefinition" [dataSource]="dataGridPrestacoesDefinition.dataSource"></dx-data-grid>
                </div>
              </div>
            </pl-tab>

            <pl-tab [id]="mainTabFaturacaoPublicaId" *ngIf="docFa && docFa.faturacaoPublica && doc.clifo.facPublicaModelo !== 0">
              <div *plTabTitle><i class="fa fa-file-powerpoint-o fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.faturacaoPublica'"></span></div>
              <div *plTabContent>
                <div class="gestaocomercial-widget-body">
                  <pl-group class="cab-faturacao-publica-options-edit">
                    <pl-group class="cab-faturacao-publica-options-edit">
                      <label [translate]="'docscomerciais.doc.cab.ctRefExterna'"></label>
                      <edit>
                        <pl-edit attrName="ctRefExterna" type="text" [model]="doc.cab.ctRefExterna" [properties]="{disabled: true}"></pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group>
                      <label [translate]="'docscomerciais.doc.cab.acordoQuadro'"></label>
                      <edit>
                        <pl-edit type="text" [model]="doc.cab.acordoQuadro" [properties]="{disabled: true}"></pl-edit>
                      </edit>
                    </pl-group>

                    <pl-group>
                      <label [translate]="'docscomerciais.doc.cab.compromisso'"></label>
                      <edit>
                        <pl-edit type="text" [model]="doc.cab.compromisso" [properties]="{disabled: true}"></pl-edit>
                      </edit>
                    </pl-group>
                  </pl-group>
                </div>
              </div>
            </pl-tab>

            <pl-tab [id]="mainTabAnexosId" *ngIf="showGDocAnexos">
              <div *plTabTitle><i class="fa fa-paperclip fa-fw"></i>&nbsp;<span class="d-none d-sm-inline" [translate]="'docscomerciais.tabs.anexos'"></span></div>

              <div *plTabContent>
                <div style="padding: 0 12px">
                  <gdocs-viewer
                    [folderId]="gDocFolderId"
                    [showBox]="false"
                    [readOnly]="true"
                    [showTreeViewer]="false"
                    [treeCollapsed]="false"
                    [tipoDocEntity]="gDocTipoDocEntity"
                    [callback]="callbackGDocs"></gdocs-viewer>
                </div>
              </div>
            </pl-tab>
          </pl-tabs>
        </div>
      </div>
    </div>
  </div>
</div>
