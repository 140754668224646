import {ROLE} from '../../services/role.const';
import {ENTITY_NAME_RECIBOS_SERIE, EPaymentsSAFTDescricao} from './recibosSerie.entity.interface';
import {IEntityDefinition} from '../../components/entity/entity.definition.interface';
import {IJsonRecibosSerie} from './jsonRecibosSerie.entity.interface';
import {RecibosSerieEntityListComponent} from './components/list/recibosSerie.entity.list.component';

export const ENTITY_RECIBOS_SERIE: IEntityDefinition<IJsonRecibosSerie> = {
  name: ENTITY_NAME_RECIBOS_SERIE,
  roles: [ROLE.ERP],
  searchPlaceholder: 'recibosserie.pesquisa',
  metadata: {
    keyName: 'nSerie',
    fields: [
      {name: 'nSerie', caption: 'recibosserie.fields.nSerie'},
      {name: 'descritivo', caption: 'recibosserie.fields.descritivo', validators: {required: true}},
      {name: 'porOmissao', caption: 'recibosserie.fields.porOmissao', type: 'boolean'},
      {name: 'atTipoDocDescricao', caption: 'recibosserie.fields.atTipoDoc'},
      {name: 'atEstadoSerieDescricao', caption: 'recibosserie.fields.atEstadoSerieDescricao'},
      {name: 'atcud', caption: 'recibosserie.fields.atcud'},
      {
        name: 'atTipoDoc',
        caption: 'recibosserie.fields.atTipoDoc',
        type: 'select',
        validators: {required: true},
        properties: {
          select: {
            list: [
              {name: 'Regime Geral', value: EPaymentsSAFTDescricao.RegimeGeral},
              {name: 'Regime IVA de Caixa', value: EPaymentsSAFTDescricao.RegimeIVAdeCaixa}
            ]
          }
        }
      }
    ],
    order: 'nSerie',
    detailFields: 'nSerie,descritivo,atTipoDoc,porOmissao',
    listFields: 'nSerie,descritivo,porOmissao,atTipoDocDescricao,atEstadoSerieDescricao,atcud',
    editFields: 'nSerie,descritivo,atTipoDoc,porOmissao'
  },
  autocomplete: {
    rowTemplate: '{{nSerie}} {{descritivo}}',
    output: 'nSerie',
    searchFields: 'nSerie,descritivo'
  },
  actions: {
    new: true,
    edit: true,
    detail: true,
    delete: true,
    search: true,
    filter: true
  },
  list: {
    state: {
      component: RecibosSerieEntityListComponent,
      data: {
        roles: [ROLE.ERP],
        pageTitle: 'global.menu.recibosserie'
      }
    }
  }
};
