import {Component, Injector, Input, OnInit} from '@angular/core';
import {IPlTabsCallback, IPlToolbarItem, isDefined, PlAlertService, PlTranslateService} from 'pl-comps-angular';
import {ModuloComponent} from '../../../components/module/module.component';
import {
  EFEAEstado,
  ETabEstado,
  IJsonFaturacaoPublica,
  IJsonFaturacaoPublicaAnexos,
  IJsonFaturacaoPublicaConfig,
  IJsonFaturacaoPublicaDoc,
  IJsonFaturacaoPublicaHistorico
} from '../jsonFaturacaoPublica.module.interface';
import {FaturacaoPublicaService} from '../faturacaoPublica.module.service';
import {HttpResponse} from '@angular/common/http';
import {CGModalService} from '../../../components/cg/modal/cgmodal.service';
import {FaturacaoPublicaHistoricoModalComponent} from '../modals/historico/faturacaoPublica.historico.modal.component';
import {FaturacaoPublicaObservacoesModalComponent} from '../modals/observacoes/faturacaoPublica.observacoes.modal.component';
import {IEntityMaintenanceInstance} from '../../../components/entity/maintenance/entity/entity.maintenance.interface';
import {ENTITY_NAME_CLIENTES} from '../../../entities/clifos/clifos.entity.interface';
import {EntityMaintenanceService} from '../../../components/entity/maintenance/entity/entity.maintenance.service';
import {IJsonDocComercial} from '../../../entities/docscomerciais/jsonDocComercial.entity.interface';
import {IDocsComerciaisEntityService} from '../../../entities/docscomerciais/docsComerciais.entity.interface';
import {FaturacaoPublicaDocComercialModalComponent} from '../modals/doccomercial/faturacaoPublica.docComercial.modal.component';
import {EntityServiceBuilder} from '../../../services/entity/entity.service.builder';
import {FaturacaoPublicaConfigModalComponent} from '../modals/config/faturacaoPublica.config.modal.component';
import {orderBy} from 'lodash-es';
import {IDevExpressDataGrid} from '../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnInitialized, IDevExpressDataGridEventOnSelectionChanged} from '../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import type dxDataGrid from 'devextreme/ui/data_grid';
import {FaturacaoPublicaComunicacaoManualModalComponent} from '../modals/comunicacaomanual/faturacaoPublica.comunicacaoManual.modal.component';
import {EmailClifoSendMailModalComponent} from '../../../components/emailsCliFo/modal/emailClifo.modal.component';
import {IFaturacaoPublicaStateParams} from '../faturacaoPublica.module.interface';
import {IApiQueryResponse} from '../../../services/api/api.service.interface';

const NUM_EMAILS_DEFAULT = 1;

@Component({
  selector: 'module-faturacao-publica',
  templateUrl: './faturacaoPublica.module.component.html'
})
export class FaturacaoPublicaModuleComponent extends ModuloComponent implements OnInit {
  @Input() public faturacaoPublica: IJsonFaturacaoPublica;

  public readonly dataGridDefinition: IDevExpressDataGrid<IJsonFaturacaoPublicaDoc, number>;
  public readonly dataGridDefinitionComunicados: IDevExpressDataGrid<IJsonFaturacaoPublicaDoc, number>;
  public readonly tabCallback: IPlTabsCallback;
  public activeTab: ETabEstado;
  public tabEstadoComunicacao: typeof ETabEstado;
  public promise: Promise<void>;
  public captionTabDocsPorComunicarManual: string;
  public captionTabDocsPorComunicarWebServ: string;
  public captionTabDocsEmValidacao: string;
  public captionTabDocsComErro: string;
  public historico: Array<IJsonFaturacaoPublicaHistorico>;

  private readonly _clifosMaintenanceInstance: IEntityMaintenanceInstance;
  private readonly _serviceDocsComerciais: IDocsComerciaisEntityService;
  private readonly _params: IFaturacaoPublicaStateParams;

  private _btnComunicarManual: IPlToolbarItem;
  private _btnComunicaWebServ: IPlToolbarItem;
  private _dataGridInstanceDocsComErro: dxDataGrid<IJsonFaturacaoPublicaDoc, number>;
  private _dataGridInstanceDocsEmValidacao: dxDataGrid<IJsonFaturacaoPublicaDoc, number>;
  private _dataGridInstanceDocsPorComunicarManual: dxDataGrid<IJsonFaturacaoPublicaDoc, number>;
  private _dataGridInstanceDocsPorComunicarWebServ: dxDataGrid<IJsonFaturacaoPublicaDoc, number>;
  private _dataGridInstanceComunicados: dxDataGrid<IJsonFaturacaoPublicaDoc, number>;
  private _faccbID: number;

  constructor(
    protected readonly _injector: Injector,
    private readonly _faturacaoPublicaService: FaturacaoPublicaService,
    private readonly _plAlertService: PlAlertService,
    private readonly _cgModalService: CGModalService,
    private readonly _entityMaintenanceService: EntityMaintenanceService,
    private readonly _entityServiceBuilder: EntityServiceBuilder,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
    this._clifosMaintenanceInstance = this._entityMaintenanceService.build(ENTITY_NAME_CLIENTES);
    this._serviceDocsComerciais = this._entityServiceBuilder.build<IJsonDocComercial, IDocsComerciaisEntityService>('docscomerciais');
    this.tabCallback = {};
    this.tabEstadoComunicacao = ETabEstado;
    this.activeTab = ETabEstado.PorComunicarManual;
    this.historico = [];
    this.dataGridDefinition = {
      columns: [
        {dataField: 'ndocAsStringDraftSufixo', dataType: 'string', caption: 'faturacaopublica.table.ndocAsStringDraftSufixo'},
        {dataField: 'datadoc', dataType: 'date', caption: 'faturacaopublica.table.datadoc'},
        {dataField: 'nClifo', dataType: 'string', caption: 'faturacaopublica.table.nClifo'},
        {dataField: 'nomeClifo', dataType: 'string', caption: 'faturacaopublica.table.nomeClifo'},
        {dataField: 'facPublicaModeloStr', dataType: 'string', caption: 'faturacaopublica.table.facPublicaModeloStr'},
        {dataField: 'estadoDescricao', dataType: 'string', caption: 'faturacaopublica.table.estadoDescricao'},
        {
          dataField: 'vf_actions',
          caption: ' ',
          cellTemplate: 'actions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false,
          showInColumnChooser: false
        }
      ],
      keyExpr: 'faccbID',
      export: {filename: 'global.menu.faturacaopublica'},
      height: '60vh',
      paging: {enabled: false, pageSize: 100},
      pager: {visible: false},
      selection: {mode: 'multiple', selectAllMode: 'allPages', allowSelectAll: false, showCheckBoxesMode: 'onClick'},
      columnHidingEnabled: false
    };

    this.historico = [];
    this.dataGridDefinitionComunicados = {
      columns: [
        {dataField: 'ndocAsStringDraftSufixo', dataType: 'string', caption: 'faturacaopublica.table.ndocAsStringDraftSufixo'},
        {dataField: 'datadoc', dataType: 'date', caption: 'faturacaopublica.table.datadoc'},
        {dataField: 'nClifo', dataType: 'string', caption: 'faturacaopublica.table.nClifo'},
        {dataField: 'nomeClifo', dataType: 'string', caption: 'faturacaopublica.table.nomeClifo'},
        {dataField: 'facPublicaModeloStr', dataType: 'string', caption: 'faturacaopublica.table.facPublicaModeloStr'},
        {
          dataField: 'vf_actions',
          caption: ' ',
          cellTemplate: 'actions',
          allowHiding: false,
          allowSearch: false,
          allowReordering: false,
          allowSorting: false,
          allowFiltering: false,
          allowResizing: false,
          allowHeaderFiltering: false,
          allowGrouping: false,
          allowFixing: false,
          allowEditing: false,
          allowExporting: false,
          showInColumnChooser: false
        }
      ],
      dataSource: [],
      height: '60vh',
      paging: {enabled: true, pageSize: 100},
      pager: {visible: true},
      columnHidingEnabled: false
    };
    this._params = <IFaturacaoPublicaStateParams>this._transition.params();
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.btnBack.visible = isDefined(this._params) && this._params.stateOrName !== '';

    this.btnRefresh.click = this._refresh.bind(this);
    this.btnRefresh.order = this.btnBack.visible ? this.btnBack.order + 1 : 1;
    this.btnRefresh.visible = true;

    this._btnComunicarManual = {
      id: 'enviarEmail',
      order: this.btnRefresh.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-share-square fa-fw"></i>',
      class: 'btn btn-sm btn-primary',
      caption: 'faturacaopublica.btn.comunicarManual',
      visible: this.activeTab === ETabEstado.PorComunicarManual,
      click: () => this._comunicarManualDoc()
    };
    this.toolbar.addButton(this._btnComunicarManual);

    this._btnComunicaWebServ = {
      id: 'comunica',
      order: this._btnComunicarManual.order + 1,
      type: 'button',
      iconLeft: '<i class="fa fa-share-square fa-fw"></i>',
      class: 'btn-primary',
      caption: 'faturacaopublica.btn.comunicarWebServ',
      visible: this.activeTab === ETabEstado.PorComunicarWebServ || this.activeTab === ETabEstado.ComErros,
      click: () => this.comunicarDocsSel()
    };
    this.toolbar.addButton(this._btnComunicaWebServ);

    this._faccbID = this._params?.faccbID || 0;

    this._updateCaptionsTabs();
  }

  public back(): Promise<void> {
    return this._cgStateService.redirectToState({stateOrName: this._params.stateOrName});
  }

  public changedTab(tabId: ETabEstado): void {
    this.activeTab = tabId;
    this.btnRefresh.visible = this.activeTab !== ETabEstado.Comunicados;
    this._btnComunicarManual.visible = this.activeTab === ETabEstado.PorComunicarManual;
    this._btnComunicaWebServ.visible = this.activeTab === ETabEstado.PorComunicarWebServ || this.activeTab === ETabEstado.ComErros;

    this.dataGridDefinition.selection =
      this.activeTab === ETabEstado.ComunicadoEmValidacao
        ? {mode: 'none', selectAllMode: 'page', allowSelectAll: false, showCheckBoxesMode: 'none'}
        : this.activeTab === ETabEstado.PorComunicarManual
          ? {mode: 'multiple', selectAllMode: 'allPages', allowSelectAll: false, showCheckBoxesMode: 'onClick'}
          : {mode: 'multiple', selectAllMode: 'allPages', allowSelectAll: true, showCheckBoxesMode: 'always'};

    if (this.activeTab === ETabEstado.Comunicados) {
      this._refreshComunicados();
    }
  }

  public comunicarDocsSel(): Promise<void> {
    let faccbids: string;
    if (this.activeTab === ETabEstado.PorComunicarWebServ) {
      faccbids = this._dataGridInstanceDocsPorComunicarWebServ
        .getSelectedRowsData()
        .map((value: IJsonFaturacaoPublicaDoc) => value.faccbID)
        .join(',');
    }
    if (this.activeTab === ETabEstado.ComErros) {
      faccbids = this._dataGridInstanceDocsComErro
        .getSelectedRowsData()
        .map((value: IJsonFaturacaoPublicaDoc) => value.faccbID)
        .join(',');
    }
    this.promise = this._faturacaoPublicaService.comunicarDocumentos(faccbids, this._getFeaEstado(this.activeTab)).then((response: HttpResponse<IJsonFaturacaoPublica>) => {
      this.faturacaoPublica = response.body;
      this._updateCaptionsTabs();
    });
    return this.promise;
  }

  public comunicarManualDoc(item: IJsonFaturacaoPublicaDoc): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(FaturacaoPublicaObservacoesModalComponent);
    const componentInstance: FaturacaoPublicaObservacoesModalComponent = modalInstance.componentInstance;
    componentInstance.faccbID = item.faccbID;
    componentInstance.estado = this._getFeaEstado(this.activeTab);
    modalInstance.result.then((result: IJsonFaturacaoPublica) => {
      this.faturacaoPublica = result;
      this._updateCaptionsTabs();
    });
    return this.promise;
  }

  public comunicacaoManual(item: IJsonFaturacaoPublicaDoc): Promise<void> {
    const modalInstance = this._cgModalService.showVanilla(FaturacaoPublicaComunicacaoManualModalComponent, {size: 'md'});
    const componentInstance: FaturacaoPublicaComunicacaoManualModalComponent = modalInstance.componentInstance;
    componentInstance.faccbID = item.faccbID;
    componentInstance.nDoc = item.ndocAsStringDraftSufixo;
    modalInstance.result.then((comunicacaoManualContent: IJsonFaturacaoPublicaAnexos) => {
      if (comunicacaoManualContent) {
        const anexos = [comunicacaoManualContent.fileNamePdf, comunicacaoManualContent.fileNameXml];
        this._faturacaoPublicaService.getMailContent(item.faccbID).then((result) => {
          const modalMailInstance = this._cgModalService.showVanilla(EmailClifoSendMailModalComponent);
          modalMailInstance.componentInstance.emailContent = result.body;
          modalMailInstance.componentInstance.numEmails = NUM_EMAILS_DEFAULT;
          modalMailInstance.componentInstance.anexos = anexos;
          modalMailInstance.result.then((emailContent) => {
            this._faturacaoPublicaService.sendMail(emailContent, item.faccbID, this._getFeaEstado(this.activeTab)).then(() => {
              this._plAlertService.success(this._plTranslateService.translate('emailclifos.sendMail.messageSuccess', {emailList: emailContent.emails}));
            });
          });
        });
        this._updateCaptionsTabs();
      }
    });
    return this.promise;
  }

  public historicoDoc(item: IJsonFaturacaoPublicaDoc): Promise<void> {
    this.promise = this._faturacaoPublicaService.historicoDocumento(item.nDocFa, item.nNumer, item.nDocumento).then((response: HttpResponse<Array<IJsonFaturacaoPublicaHistorico>>) => {
      const modalInstance = this._cgModalService.showVanilla(FaturacaoPublicaHistoricoModalComponent);
      const componentInstance: FaturacaoPublicaHistoricoModalComponent = modalInstance.componentInstance;
      componentInstance.historico = orderBy(response.body, 'data', 'desc');
    });
    return this.promise;
  }

  public consultarCliente(line: IJsonFaturacaoPublicaDoc): void {
    this._clifosMaintenanceInstance.maintenanceEdit(line.nClifo);
  }

  public openDocModal(line: IJsonFaturacaoPublicaDoc): Promise<void> {
    return this._serviceDocsComerciais.get({id: line.faccbID}).then((response: HttpResponse<IJsonDocComercial>) => {
      const modalInstance = this._cgModalService.showVanilla(FaturacaoPublicaDocComercialModalComponent);
      const componentInstance: FaturacaoPublicaDocComercialModalComponent = modalInstance.componentInstance;
      componentInstance.doc = response.body;
    });
  }

  public onInitializedDocsComErro({component}: IDevExpressDataGridEventOnInitialized<IJsonFaturacaoPublicaDoc, number>): void {
    this._dataGridInstanceDocsComErro = component;
  }

  public onContentReadyDocsComErro(): void {
    this._dataGridInstanceDocsComErro.endCustomLoading();
  }

  public onInitializedDocsEmValidacao({component}: IDevExpressDataGridEventOnInitialized<IJsonFaturacaoPublicaDoc, number>): void {
    this._dataGridInstanceDocsEmValidacao = component;
  }

  public onContentReadyDocsEmValidacao(): void {
    this._dataGridInstanceDocsEmValidacao.endCustomLoading();
  }

  public onInitializedDocsPorComunicarManual({component}: IDevExpressDataGridEventOnInitialized<IJsonFaturacaoPublicaDoc, number>): void {
    this._dataGridInstanceDocsPorComunicarManual = component;
  }

  public onContentReadyDocsPorComunicarManual(): void {
    if (this._faccbID > 0) {
      const doc: IJsonFaturacaoPublicaDoc = this.faturacaoPublica.documentosPorComunicarManual.find((documento: IJsonFaturacaoPublicaDoc) => documento.faccbID === this._faccbID);
      if (doc) {
        this._dataGridInstanceDocsPorComunicarManual.selectRows([doc.faccbID], true);
      } else {
        this._dataGridInstanceDocsPorComunicarManual.deselectAll();
      }
    } else {
      this._dataGridInstanceDocsPorComunicarManual.deselectAll();
    }
    this._dataGridInstanceDocsPorComunicarManual.endCustomLoading();
  }

  public onSelectionChangedDocsPorComunicarManual(event: IDevExpressDataGridEventOnSelectionChanged<IJsonFaturacaoPublicaDoc>): void {
    if (event.selectedRowKeys.length > 1) {
      const faccbId = event.currentSelectedRowKeys;
      this._dataGridInstanceDocsPorComunicarManual.deselectAll().then(() => {
        const id = <number>faccbId[0];
        this._dataGridInstanceDocsPorComunicarManual.selectRows([id], true);
      });
    }
  }

  public onInitializedDocsPorComunicarWebServ({component}: IDevExpressDataGridEventOnInitialized<IJsonFaturacaoPublicaDoc, number>): void {
    this._dataGridInstanceDocsPorComunicarWebServ = component;
  }

  public onContentReadyDocsPorComunicarWebServ(): void {
    if (this._faccbID > 0) {
      const doc: IJsonFaturacaoPublicaDoc = this.faturacaoPublica.documentosPorComunicarManual.find((documento: IJsonFaturacaoPublicaDoc) => documento.faccbID === this._faccbID);
      if (doc) {
        this._dataGridInstanceDocsPorComunicarManual.selectRows([doc.faccbID], true);
      }
    } else {
      this._dataGridInstanceDocsPorComunicarManual.selectAll();
    }
    this._dataGridInstanceDocsPorComunicarManual.endCustomLoading();
  }

  public onInitializedComunicados({component}: IDevExpressDataGridEventOnInitialized<IJsonFaturacaoPublicaDoc, number>): void {
    this._dataGridInstanceComunicados = component;
  }

  public onContentReadyComunicados(): void {
    this._dataGridInstanceComunicados.endCustomLoading();
  }

  public configFaturacaoPublica(): Promise<void> {
    return this._faturacaoPublicaService.getConfigFaturacaoPublica().then((response: HttpResponse<Array<IJsonFaturacaoPublicaConfig>>) => {
      const modalInstance = this._cgModalService.showVanilla(FaturacaoPublicaConfigModalComponent, {size: 'lg'});
      const componentInstance: FaturacaoPublicaConfigModalComponent = modalInstance.componentInstance;
      componentInstance.configs = response.body;
      return modalInstance.result;
    });
  }

  private _refresh(): Promise<void> {
    this.promise = this._faturacaoPublicaService.loadDocumentos().then((response: HttpResponse<IJsonFaturacaoPublica>) => {
      this.faturacaoPublica = response.body;
      this._plAlertService.success('faturacaopublica.messages.refreshsuccess');
      if (this.activeTab !== ETabEstado.PorComunicarManual) {
        this.tabCallback.select(EFEAEstado.PorComunicar);
        this.activeTab = ETabEstado.PorComunicarManual;
        this._btnComunicaWebServ.visible = false;
      }
      this._updateCaptionsTabs();
    });
    return this.promise;
  }

  private _setCaptionTabDocsPorComunicarManual(): void {
    const nDocs = this.faturacaoPublica?.documentosPorComunicarManual?.length || 0;
    const strNDocs = nDocs === 0 ? ' ' : `(${nDocs})`;
    const tabStr: string = this._translateService?.instant('faturacaopublica.tab.porComunicarManual') || '';
    this.captionTabDocsPorComunicarManual = `${tabStr} ${strNDocs}`;
  }

  private _setCaptionTabDocsPorComunicarWebServ(): void {
    const nDocs = this.faturacaoPublica?.documentosPorComunicarWebServ?.length || 0;
    const strNDocs = nDocs === 0 ? ' ' : `(${nDocs})`;
    const tabStr: string = this._translateService?.instant('faturacaopublica.tab.porComunicarWebServ') || '';
    this.captionTabDocsPorComunicarWebServ = `${tabStr} ${strNDocs}`;
  }

  private _setCaptionTabDocsEmValidacao(): void {
    const nDocs = this.faturacaoPublica?.documentosEmValidacao?.length || 0;
    const strNDocs = nDocs === 0 ? ' ' : `(${nDocs})`;
    const tabStr: string = this._translateService?.instant('faturacaopublica.tab.comunicadoEmValidacao') || '';
    this.captionTabDocsEmValidacao = `${tabStr} ${strNDocs}`;
  }

  private _setCaptionTabDocsComErro(): void {
    const nDocs = this.faturacaoPublica?.documentosComErros?.length || 0;
    const strNDocs = nDocs === 0 ? ' ' : `(${nDocs})`;
    const tabStr: string = this._translateService?.instant('faturacaopublica.tab.comunicadoComErro') || '';
    this.captionTabDocsComErro = `${tabStr} ${strNDocs}`;
  }

  private _refreshComunicados(): Promise<void> {
    this.promise = this._faturacaoPublicaService.docsComunicados().then((response: HttpResponse<IApiQueryResponse<IJsonFaturacaoPublicaDoc>>) => {
      if (response) {
        this.dataGridDefinitionComunicados.dataSource = response.body.list;
      }
    });
    return this.promise;
  }

  private _comunicarManualDoc(): Promise<void> {
    const docs: Array<IJsonFaturacaoPublicaDoc> = this._dataGridInstanceDocsPorComunicarManual.getSelectedRowsData();
    if (docs && docs.length > 0) {
      this.promise = this.comunicacaoManual(docs[0]);
    }
    return this.promise;
  }

  private _getFeaEstado(activeTab: ETabEstado): EFEAEstado {
    return activeTab === ETabEstado.PorComunicarManual || activeTab === ETabEstado.PorComunicarWebServ
      ? EFEAEstado.PorComunicar
      : activeTab === ETabEstado.ComunicadoEmValidacao
        ? EFEAEstado.ComunicadoEmValidacao
        : activeTab === ETabEstado.ComErros
          ? EFEAEstado.ComunicadoComErro
          : EFEAEstado.Received;
  }

  private _updateCaptionsTabs(): void {
    this._setCaptionTabDocsPorComunicarManual();
    this._setCaptionTabDocsPorComunicarWebServ();
    this._setCaptionTabDocsEmValidacao();
    this._setCaptionTabDocsComErro();
  }
}
