/**
 * CentralGest Amalia
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ArticleFeedbackState = 'NEW' | 'CLOSED';

export const ArticleFeedbackState = {
    New: 'NEW' as ArticleFeedbackState,
    Closed: 'CLOSED' as ArticleFeedbackState
};

