<div id="pl-sidebar-wrapper" class="pl-sidebar">
  <div class="pl-sidebar-base">
    <div class="pl-sidebar-main">
      <button type="button" class="btn pl-sidebar-toggle" (click)="toggleSidebar()">
        <div class="menu-title">
          <ng-content select="pl-sidebar-title"></ng-content>
        </div>
        <div class="menu-close pl-icon-close"></div>
      </button>
    </div>

    <div class="pl-sidebar-list-wrapper">
      <ng-container *ngFor="let item of menus">
        <div *ngIf="!item.isFiltered" class="pl-sidebar-list pl-sidebar-root-level" [ngClass]="{active: item.active, 'active-sub': item.menu && item.isOpened}">
          <a rel="noopener noreferrer" class="pl-sidebar-list-item" [attr.href]="item.href" (click)="go(item, $event)">
            <div class="pl-sidebar-list-item-toggle">
              <button *ngIf="item.menu" type="button" class="btn btn-sm">
                <i class="fa fa-fw fa-angle-right" [class.fa-rotate-90]="item.isOpened"></i>
              </button>
            </div>
            <div class="menu-title" [innerHTML]="item.title | cgcHighlight: filterValue : filterActive && (!item.menu || !item.menu.length) : true | cgcBindHtml"></div>
            <div *ngIf="item.icon && !item.iconTemplate" class="menu-icon" [cgcIcon]="item.icon"></div>
            <div *ngIf="item.iconTemplate" class="menu-icon">
              <ng-container *ngTemplateOutlet="item.iconTemplate; context: {$implicit: item, menu: item}"></ng-container>
            </div>
          </a>

          <div *ngIf="item.isOpened" class="pl-sidebar-second-level">
            <ng-container *ngFor="let sub of item.menu">
              <div *ngIf="!sub.isFiltered" class="pl-sidebar-list" [class.active]="sub.active">
                <a rel="noopener noreferrer" class="pl-sidebar-list-item" [attr.href]="sub.href" (click)="go(sub, $event, item)">
                  <div class="menu-title">
                    <span [innerHTML]="sub.title | cgcHighlight: filterValue : filterActive : true | cgcBindHtml"></span>
                    <span *ngIf="sub.suffix" [innerHTML]="sub.suffix | cgcHighlight: filterValue : filterActive : true | cgcBindHtml"></span>
                  </div>
                  <div *ngIf="sub.icon && !sub.iconTemplate" class="menu-icon" [cgcIcon]="sub.icon"></div>
                  <div *ngIf="sub.iconTemplate" class="menu-icon">
                    <ng-container *ngTemplateOutlet="sub.iconTemplate; context: {$implicit: sub, menu: sub, parent: item}"></ng-container>
                  </div>
                </a>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>

    <div class="pl-sidebar-list-search">
      <div class="input-group input-group-sm input-group-toggle">
        <input
          type="text"
          class="form-control"
          autocapitalize="off"
          autocomplete="off"
          [attr.placeholder]="placeholder"
          [ngModel]="filterValue"
          (ngModelChange)="filterValue = $event; filterValueChanged()"
          (keyup)="filterKeyUp($event)" />
        @if (filterActive) {
          <button type="button" class="btn btn-primary" (click)="clearFilter()">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        }
      </div>
    </div>
  </div>

  <div *ngIf="hideTogglerHelper !== true" class="pl-sidebar-toggle-helper" (mouseenter)="mouseEnterTogglerHelper()" (mouseleave)="mouseLeaveTogglerHelper()"></div>
</div>
