import {Component, Injector, Input, OnInit} from '@angular/core';
import {CGModalComponent} from '../../../../../../components/cg/modal/cgmodal.component';
import {ENTITY_NAME_DOCS_CONTABILIDADE, IDocContabilidade, IDocContabilidadeLinha, IDocsContabilidadeEntityService} from '../../../../docscontabilidade/docsContabilidade.interface';
import {IDevExpressDataGrid} from '../../../../../../components/devexpress/datagrid/devexpress.datagrid.interface';
import {IDevExpressDataGridEventOnCellClick} from '../../../../../../components/devexpress/datagrid/events/devexpress.datagrid.events.interface';
import {devExpressDataGridExpandDetailHandler} from '../../../../../../components/devexpress/datagrid/utilities/devexpress.datagrid.utilities';
import {HttpResponse} from '@angular/common/http';
import {EntityServiceBuilder} from '../../../../../../services/entity/entity.service.builder';
import {IJsonDocContabilidade} from '../../../../docscontabilidade/jsonDocContabilidade.interface';

@Component({
  selector: 'contabilidade-extratosgrid-relateddocs-modal',
  templateUrl: './contabilidade.extratosGrid.relatedDocs.modal.component.html'
})
export class ContabilidadeExtratosGridRelatedDocsModalComponent extends CGModalComponent<void> implements OnInit {
  @Input() public relatedDocs: Array<IDocContabilidadeLinha>;

  public doc: IDocContabilidade;
  public relatedDocsDatagrid: IDevExpressDataGrid<IDocContabilidadeLinha, string>;

  private readonly _serviceDocsContabilidade: IDocsContabilidadeEntityService;

  constructor(
    protected readonly _injector: Injector,
    private readonly _entityServiceBuilder: EntityServiceBuilder
  ) {
    super(_injector);
    this.relatedDocs = [];
    this._serviceDocsContabilidade = this._entityServiceBuilder.build<IDocContabilidade, IDocsContabilidadeEntityService>(ENTITY_NAME_DOCS_CONTABILIDADE);
  }

  public ngOnInit(): void {
    this.relatedDocsDatagrid = {
      columns: [
        {dataField: '_nDocImput', caption: 'components.contabilidade.relateddocs.linhas.nDoc', dataType: 'string', allowSorting: false},
        {dataField: 'nDocExterno', caption: 'components.contabilidade.relateddocs.linhas.docExterno', dataType: 'string'},
        {dataField: 'dataDoc', caption: 'components.contabilidade.relateddocs.linhas.dataDoc', dataType: 'date'},
        {dataField: 'descricao', caption: 'components.contabilidade.relateddocs.linhas.descricao', dataType: 'string'},
        {dataField: 'valor', caption: 'components.contabilidade.relateddocs.linhas.valor', dataType: 'double'}
      ],
      masterDetail: {enabled: true, template: 'detailTemplate'}
    };
  }

  public async onCellClick(event: IDevExpressDataGridEventOnCellClick<IDocContabilidadeLinha>): Promise<void> {
    await devExpressDataGridExpandDetailHandler(event, (): Promise<void> => this._onDetail(event.data));
  }

  private async _onDetail(item: IDocContabilidadeLinha): Promise<void> {
    const result: HttpResponse<IJsonDocContabilidade> = await this._serviceDocsContabilidade.get({id: item.extPocCabID});
    this.doc = result.body;
  }
}
