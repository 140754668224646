<div class="modal-header">
  <button type="button" class="btn-close" (click)="dismiss()"></button>
  <h4 class="modal-title" [translate]="'debDiretosSEPA.strings.importadorModalTitle'"></h4>
</div>

<div class="modal-body">
  <pl-nav-wizard
    [definition]="definitionNavWizard"
    [properties]="propertiesNavWizard"
    [callback]="plWizardCallback"
    [model]="activeWizardStep"
    (modelChange)="onWizardStepChange($event)"
    [onFinalize]="close">
    <pl-nav-wizard-step [stepId]="plWizardUploadStepId" icon="fa-upload fa-fw" caption="debDiretosSEPA.fields.fileName" [validator]="fnValidateUpload" [valid]="false">
      <div *plNavWizardStepContent>
        <ng-container *ngIf="!showUploadProgressBar">
          <pl-upload
            [url]="uploadUrl"
            [autoProcessQueue]="false"
            [maxFiles]="1"
            [callback]="plUploadCallback"
            [hideActionCancel]="true"
            [hideActionRemoveAll]="true"
            [hideActionRetry]="true"
            [hideActionUploadAll]="true"
            [hideActionUpload]="true"
            [zip]="true"
            [maxFileSize]="8"
            [uploadMultiple]="false"
            acceptedFiles=".xml">
          </pl-upload>
        </ng-container>
        <ng-container *ngIf="showUploadProgressBar">
          <div class="uploadProcessarFicheiro-container">
            <div class="uploadProcessarFicheiroLabel" [translate]="'debDiretosSEPA.strings.importarFicheiro'"></div>
            <div class="progress">
              <div
                class="progress-bar bg-info progress-bar-striped progress-bar-animated"
                role="progressbar"
                [attr.aria-valuenow]="100"
                [attr.aria-valuemin]="0"
                [attr.aria-valuemax]="100"
                style="width: 100%"></div>
            </div>
          </div>
        </ng-container>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step stepId="dadosFicheiro" icon="fa-file-text-o fa-fw" caption="debDiretosSEPA.strings.dados" [validator]="fnValidateDadosFicheiro">
      <div *plNavWizardStepContent>
        <ng-container *ngIf="!showProcessProgressBar">
          <div class="d-flex" style="gap: 15px">
            <div style="flex: 1; overflow: auto">
              <pl-alert class="mb-2" type="warning" [closeable]="false" *ngIf="ficheiroJaImportado">
                <i class="fa fa-fw fa-exclamation-triangle" aria-hidden="true"></i>
                <span [translate]="'debDiretosSEPA.messages.esteFicheiroJaFoiImportado'"></span>
              </pl-alert>
              <pl-tabs [theme]="'style3'" [activeId]="activeTabId">
                <pl-tab [id]="tabDadosFicheiroSucessoId">
                  <div *plTabTitle>
                    <span [translate]="'debDiretosSEPA.strings.liquidacoes'"></span><span *ngIf="importResults.list.length" class="badge ms-2">{{ importResults.list.length }}</span>
                  </div>
                  <div *plTabContent>
                    <dx-data-grid
                      [cgDxDataGrid]="dataGridProcSucessoDefinition"
                      [dataSource]="dataGridProcSucessoDefinition.dataSource"
                      (onContentReady)="onDataGridProcSucessoContentReady()"
                      (onCellPrepared)="onDataGridProcSucessoCellPrepared($event)"
                      (onInitialized)="onDataGridProcSucessoInitialized($event)">
                      <div *dxTemplate="let item of 'cellTemplateErros'">
                        <div class="text-danger" *ngIf="item.data.erro" style="font-size: 12px">
                          <i class="fa fa-fw fa-times-circle text-danger me-1" aria-hidden="true"></i><span [translate]="item.data.erroAvisoDescricao"></span>
                        </div>
                      </div>
                    </dx-data-grid>
                  </div>
                </pl-tab>
                <pl-tab [id]="tabDadosFicheiroErrosId">
                  <div *plTabTitle>
                    <span [translate]="'debDiretosSEPA.strings.erros'"></span> <span *ngIf="importResults.errorList.length" class="badge ms-2">{{ importResults.errorList.length }}</span>
                  </div>

                  <div *plTabContent>
                    <dx-data-grid [cgDxDataGrid]="dataGridProcErrosDefinition" [dataSource]="dataGridProcErrosDefinition.dataSource" cgDxDataGridInstanceName="dataGridProcErro">
                      <div *dxTemplate="let item of 'cellTemplateProcErros'">
                        <div class="text-danger" *ngIf="item.data.erro" style="font-size: 12px">
                          <i class="fa fa-fw fa-times-circle text-danger me-1" aria-hidden="true"></i><span [translate]="item.data.erroAvisoDescricao"></span>
                        </div>
                      </div>
                    </dx-data-grid>
                  </div>
                </pl-tab>
              </pl-tabs>
            </div>
            <div style="flex: 0 0 250px">
              <h5 class="ddsepa-title" [translate]="'debDiretosSEPA.strings.resumoLiquidacao'" style="line-height: 22px"></h5>
              <table class="ddsepa-table">
                <tbody>
                  <tr>
                    <td style="width: 110px"><span [translate]="'debDiretosSEPA.fields.nRegistos'"></span>:</td>
                    <td class="ddsepa-resumo-value">{{ importResults?.resumo.nRegistosTotal }}</td>
                  </tr>
                  <tr>
                    <td><span [translate]="'debDiretosSEPA.fields.liquidacao'"></span>:</td>
                    <td class="ddsepa-resumo-value">{{ importResults?.resumo.nRegistosOKLiquidacao }}</td>
                  </tr>
                  <tr>
                    <td><span [translate]="'debDiretosSEPA.fields.devolucao'"></span>:</td>
                    <td class="ddsepa-resumo-value">{{ importResults?.resumo.nRegistosOKDevolucao }}</td>
                  </tr>
                  <tr>
                    <td><span [translate]="'debDiretosSEPA.fields.comErro'"></span>:</td>
                    <td class="ddsepa-resumo-value">{{ importResults?.resumo.nRegistosErro }}</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td><span [translate]="'debDiretosSEPA.fields.valorTotal'"></span>:</td>
                    <td class="ddsepa-resumo-value">{{ importResults?.resumo.valorTotal | cgcDecimal }}</td>
                  </tr>
                  <tr>
                    <td><span [translate]="'debDiretosSEPA.fields.liquidacao'"></span>:</td>
                    <td class="ddsepa-resumo-value">{{ importResults?.resumo.valorOKLiquidacao | cgcDecimal }}</td>
                  </tr>
                  <tr>
                    <td><span [translate]="'debDiretosSEPA.fields.devolucao'"></span>:</td>
                    <td class="ddsepa-resumo-value">{{ importResults?.resumo.valorOKDevolucao | cgcDecimal }}</td>
                  </tr>
                  <tr>
                    <td><span [translate]="'debDiretosSEPA.fields.comErro'"></span>:</td>
                    <td class="ddsepa-resumo-value">{{ importResults?.resumo.valorErro | cgcDecimal }}</td>
                  </tr>
                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td><span [translate]="'debDiretosSEPA.fields.errosNoProcessamento'"></span>:</td>
                    <td class="ddsepa-resumo-value">{{ importResults?.resumo.nErrosCG }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-container>
        <ng-container *ngIf="showProcessProgressBar">
          <div class="uploadProcessarFicheiro-container">
            <div class="uploadProcessarFicheiroLabel" [translate]="'debDiretosSEPA.strings.processarFicheiro'"></div>
            <div class="progress">
              <div
                class="progress-bar bg-info progress-bar-striped progress-bar-animated"
                role="progressbar"
                [attr.aria-valuenow]="100"
                [attr.aria-valuemin]="0"
                [attr.aria-valuemax]="100"
                style="width: 100%"></div>
            </div>
          </div>
        </ng-container>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step stepId="processFicheiro" icon="fa-check fa-fw" caption="debDiretosSEPA.strings.resumo">
      <div *plNavWizardStepContent>
        <div class="d-flex" style="gap: 15px">
          <div style="flex: 1; overflow: auto">
            <dx-data-grid [cgDxDataGrid]="dataGridProcessadoDefinition" [dataSource]="dataGridProcessadoDefinition.dataSource" (onInitialized)="onDataGridProcessadoInitialized($event)"></dx-data-grid>
          </div>
          <div style="flex: 0 0 300px">
            <table class="ddsepa-table">
              <tbody>
                <tr>
                  <td colspan="2"><h5 class="ddsepa-title" [translate]="'debDiretosSEPA.strings.nDeRegistos'" style="line-height: 22px"></h5></td>
                </tr>
                <tr>
                  <td style="width: 110px"><span [translate]="'debDiretosSEPA.strings.liquidados'"></span>:</td>
                  <td class="ddsepa-resumo-value">{{ importProcessResults?.resumo.nRegistosLiquidados }}</td>
                </tr>
                <tr>
                  <td><span [translate]="'debDiretosSEPA.strings.devolvidos'"></span>:</td>
                  <td class="ddsepa-resumo-value">{{ importProcessResults?.resumo.nRegistosDevolvidos }}</td>
                </tr>
                <tr>
                  <td><span [translate]="'debDiretosSEPA.strings.naoLiquidados'"></span>:</td>
                  <td class="ddsepa-resumo-value">{{ importProcessResults?.resumo.nRegistosNaoLiquidado }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold"><span [translate]="'debDiretosSEPA.fields.nRegistos'"></span>:</td>
                  <td class="ddsepa-resumo-value">{{ importProcessResults?.resumo.nRegistosTotal }}</td>
                </tr>
                <tr>
                  <td colspan="2"><h5 class="ddsepa-title" [translate]="'debDiretosSEPA.strings.valores'" style="line-height: 22px"></h5></td>
                </tr>
                <tr>
                  <td><span [translate]="'debDiretosSEPA.strings.imputado'"></span>:</td>
                  <td class="ddsepa-resumo-value">{{ importProcessResults?.resumo.valoresImputado | cgcDecimal }}</td>
                </tr>
                <tr>
                  <td><span [translate]="'debDiretosSEPA.strings.liquidadoSemImputacao'"></span>:</td>
                  <td class="ddsepa-resumo-value">{{ importProcessResults?.resumo.valoresEmAberto | cgcDecimal }}</td>
                </tr>
                <tr>
                  <td><span [translate]="'debDiretosSEPA.strings.devolvido'"></span>:</td>
                  <td class="ddsepa-resumo-value">{{ importProcessResults?.resumo.valoresDevolvido | cgcDecimal }}</td>
                </tr>
                <tr>
                  <td><span [translate]="'debDiretosSEPA.strings.naoLiquidado'"></span>:</td>
                  <td class="ddsepa-resumo-value">{{ importProcessResults?.resumo.valoresNaoLiquidado | cgcDecimal }}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold"><span [translate]="'global.text.total'"></span>:</td>
                  <td class="ddsepa-resumo-value">{{ importProcessResults?.resumo.valoresTotal | cgcDecimal }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
