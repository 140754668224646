import {IJsonAtivoAbate} from './jsonAtivosAbate.module.interface';

export type TAtivoAbateStatus = 'porAbater' | 'aAbater';

export interface IAtivoAbate extends IJsonAtivoAbate {
  status: TAtivoAbateStatus;
}

export interface IAtivosAbateParams {
  codAtivo?: number;
}

export const MODULE_NAME_ATIVOS_ABATE = 'ativosabate';
