import {Component, Injector, OnInit} from '@angular/core';
import {PlTranslateService} from 'pl-comps-angular';
import {CGModalComponent} from '../../../../components/cg/modal/cgmodal.component';

@Component({
  selector: 'abdes-entity-edit-unlock-prompt-modal-component',
  templateUrl: './abdes.entity.edit.unlockPrompt.modal.component.html'
})
export class AbdesEntityEditUnlockPromptModalComponent extends CGModalComponent<boolean> implements OnInit {
  public isAbono: boolean;
  public perfilSistemaAbdesNome: string;
  public titleAbonosDescontos: string;

  constructor(
    protected readonly _injector: Injector,
    private readonly _plTranslateService: PlTranslateService
  ) {
    super(_injector);
  }

  public ngOnInit(): void {
    this.titleAbonosDescontos = this.isAbono ? this._plTranslateService.translate('abdes.abonos').toLowerCase() : this._plTranslateService.translate('abdes.descontos').toLowerCase();
  }
}
