<div class="importador-saft-direct">
  <div *ngIf="blockedByUser" class="blocked-by-other-user-container">
    <div class="block-inline">
      <i class="fa fa-lock" aria-hidden="true"></i>
    </div>
    <div class="block-inline">
      <h5><span [translate]="'importadorSaftDirect.messages.importInProcessModalMessageByOtherUser'"></span>:</h5>
      <div class="blocked-by-username">{{ currentStatus.userStarted }}</div>
    </div>
  </div>

  <pl-nav-wizard
    *ngIf="!blockedByUser"
    [definition]="definitionNavWizard"
    [onFinalize]="finalize"
    [properties]="propertiesNavWizard"
    [callback]="plWizardCallback"
    (modelChange)="wizardModelChange($event)"
    [beforeStepChange]="fnBeforeStepChange">
    <pl-nav-wizard-step icon="fa-cog" caption="importadorSaftDirect.steps.escolhaTipoImportacao.wizardCaption" [stepId]="enumStepIds.TipoImportacao" [validator]="fnValidateTipoImportacaoSelected">
      <div *plNavWizardStepContent>
        <h4 [translate]="'importadorSaftDirect.steps.escolhaTipoImportacao.text1'"></h4>
        <hr />
        <label [translate]="'importadorSaftDirect.steps.escolhaTipoImportacao.tiposImportacao.title'"></label>
        <pl-group>
          <edit>
            <pl-edit type="radiogroup" [(model)]="tipoImportacaoEscolhido" [properties]="tiposImportacao"></pl-edit>
          </edit>
        </pl-group>
        <div class="row">
          <div class="col-sm-12">
            <pl-alert *ngIf="tipoImportacaoEscolhido === enumTiposImportacao.CentralGestPos || tipoImportacaoEscolhido === enumTiposImportacao.CentralGestPosWinTouch" type="info" [closeable]="true">
              <div>
                {{ 'importadorSaftDirect.steps.processaFilePOS.contactPOS' | translate }}
                <a
                  href="mailto:{{ 'importadorSaftDirect.steps.processaFilePOS.emailComercial' | translate }}?subject={{ 'importadorSaftDirect.steps.processaFilePOS.emailSubject' | translate }}"
                  class="text-white"
                  >{{ 'importadorSaftDirect.steps.processaFilePOS.emailComercial' | translate }}</a
                >
              </div>
            </pl-alert>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      icon="fa-upload"
      caption="importadorSaftDirect.steps.upload.wizardCaption"
      [visible]="tipoImportacaoEscolhido === enumTiposImportacao.FicheiroSaft"
      [valid]="wizSteps.upload.valid"
      [stepId]="enumStepIds.Upload"
      [validator]="fnValidateUpload">
      <div *plNavWizardStepContent>
        <h4 [translate]="'importadorSaftDirect.steps.upload.title'"></h4>

        <hr />

        <div class="mb-3" *ngIf="licencaStoreMode === storeModes.Public">
          <p class="text-info mb-3">
            <strong [translate]="'importadorSaftDirect.steps.welcome.contentText4'"></strong>
          </p>
          <p class="text-info mb-1">
            <strong
              [translate]="
                !hasRoleContabilidadePremium
                  ? 'importadorSaftDirect.steps.welcome.uploadLimit'
                  : isHoursBeforeSix
                    ? 'importadorSaftDirect.steps.welcome.uploadLimitPremium'
                    : 'importadorSaftDirect.steps.welcome.uploadLimitPremiumExtended'
              "></strong>
          </p>
          <p class="m-0" *ngIf="!hasRoleContabilidadePremium">* <small [translate]="'importadorSaftDirect.steps.welcome.uploadLimitInfo'"></small></p>
        </div>

        <div class="current-file-in-process-container" *ngIf="inProcess">
          <div>
            <i class="fa fa-file-code-o" aria-hidden="true"></i>
          </div>
          <div class="current-file-in-process-filename" [translate]="inProcessFilename"></div>
          <button type="button" class="btn btn-danger btn-sm" (click)="cancelProcess()" [translate]="'importadorSaftDirect.steps.upload.cancel'"></button>
        </div>

        <ng-container *ngIf="!inProcess">
          <pl-upload
            *ngIf="wizSteps.upload.showUploader"
            [url]="uploadUrl"
            [autoProcessQueue]="false"
            [maxFiles]="1"
            [maxFileSize]="importacaoSAFTMaxFileSize"
            [callback]="plUploadCallback"
            [hideActionCancel]="true"
            [hideActionRemoveAll]="true"
            [hideActionRetry]="true"
            [hideActionUploadAll]="true"
            [hideActionUpload]="true"
            [zip]="true"
            [uploadMultiple]="false"
            acceptedFiles=".xml">
          </pl-upload>

          <div *ngIf="wizSteps.upload.showProcessFileProgressBar" class="uploadProcessarFicheiro-container">
            <div class="uploadProcessarFicheiroLabel" [translate]="'importadorSaftDirect.steps.upload.processarFicheiroLabel'"></div>
            <div class="progress">
              <div
                class="progress-bar bg-info progress-bar-striped progress-bar-animated"
                role="progressbar"
                [attr.aria-valuenow]="pbProcessPos"
                [attr.aria-valuemin]="0"
                [attr.aria-valuemax]="100"
                [style.width.%]="pbProcessPercentage"></div>
            </div>

            <div class="procLabel" [translate]="pbProcessLabel"></div>
            <pl-button klass="btn-danger action-cancel" [onClick]="cancelImportProcess"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
          </div>
        </ng-container>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      icon="fa-cog"
      caption="importadorSaftDirect.steps.processaFileCloud.wizardCaption"
      [visible]="tipoImportacaoEscolhido === enumTiposImportacao.CentralGestCloud"
      [stepId]="enumStepIds.ProcessaFileCloud"
      [validator]="fnValidateProcessFileCloud">
      <div *plNavWizardStepContent>
        <pl-form [(formInstance)]="processFileCloudForm" [properties]="{validators: {required: {value: true}}}">
          <h4 [translate]="'importadorSaftDirect.steps.processaFileCloud.text1'"></h4>
          <p [translate]="'importadorSaftDirect.steps.processaFileCloud.text2'"></p>
          <hr />

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4">
              <pl-group>
                <label [translate]="'importadorSaftDirect.steps.processaFileCloud.periodo'"></label>
                <edit>
                  <entity-autocomplete
                    entity="periodos"
                    [model]="processFileFilters"
                    (selectedKeyChange)="processFileFilters.periodo = $event"
                    [properties]="{validators: {required: {value: true}}}"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <pl-group>
                <label [translate]="'importadorSaftDirect.steps.processaFileCloud.valoresAImportar'"></label>
                <edit>
                  <div class="d-flex" style="gap: 1rem">
                    <div class="d-flex flex-column">
                      <pl-edit
                        type="checkbox"
                        [model]="processFileFilters.processaVendas"
                        (modelChange)="processFileFilters.processaVendas = $event; checkCheckBoxValues(1)"
                        [properties]="{label: 'importadorSaftDirect.steps.processaFileCloud.importarVendas'}"></pl-edit>
                      <pl-edit
                        type="checkbox"
                        [model]="processFileFilters.processaRecibos"
                        (modelChange)="processFileFilters.processaRecibos = $event; checkCheckBoxValues(2)"
                        [properties]="{label: 'importadorSaftDirect.steps.processaFileCloud.importarRecibos'}"></pl-edit>
                    </div>
                    <div class="d-flex flex-column">
                      <pl-edit
                        type="checkbox"
                        [model]="processFileFilters.processaCompras"
                        (modelChange)="processFileFilters.processaCompras = $event; checkCheckBoxValues(3)"
                        [properties]="{label: 'importadorSaftDirect.steps.processaFileCloud.importarCompras'}"></pl-edit>
                      <pl-edit
                        type="checkbox"
                        [model]="processFileFilters.processaPagamentos"
                        (modelChange)="processFileFilters.processaPagamentos = $event; checkCheckBoxValues(4)"
                        [properties]="{label: 'importadorSaftDirect.steps.processaFileCloud.importarPagamentos'}"></pl-edit>
                    </div>
                  </div>
                </edit>
              </pl-group>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
              <pl-group>
                <label [translate]="'importadorSaftDirect.steps.processaFileCloud.user'"></label>
                <edit>
                  <pl-edit type="text" attrName="utilizador" [(model)]="processFileFilters.utilizadorCloud" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
              <pl-group>
                <label [translate]="'importadorSaftDirect.steps.processaFileCloud.password'"></label>
                <edit>
                  <pl-edit
                    type="password"
                    attrName="password"
                    [(model)]="processFileFilters.passwordCloud"
                    [properties]="{validators: {required: {value: true}}, events: {keydown: fnKeydownProcessarFile}}"></pl-edit>
                </edit>
              </pl-group>
            </div>
          </div>
        </pl-form>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      icon="fa-cog"
      caption="importadorSaftDirect.steps.processaFilePOS.wizardCaption"
      [visible]="tipoImportacaoEscolhido === enumTiposImportacao.CentralGestPos"
      [stepId]="enumStepIds.ProcessaFilePOS"
      [validator]="fnValidateProcessFilePOS">
      <div *plNavWizardStepContent>
        <pl-form [(formInstance)]="processFileCloudForm" [properties]="{validators: {required: {value: true}}}">
          <h4 [translate]="'importadorSaftDirect.steps.processaFilePOS.text1'"></h4>
          <hr />

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4">
              <pl-group>
                <label [translate]="'importadorSaftDirect.steps.processaFilePOS.periodo'"></label>
                <edit>
                  <entity-autocomplete
                    entity="periodos"
                    [model]="processFileFilters"
                    (selectedKeyChange)="processFileFilters.periodo = $event"
                    [properties]="{validators: {required: {value: true}}}"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <pl-group>
                <label [translate]="'importadorSaftDirect.steps.processaFilePOS.valoresAImportar'"></label>
                <edit>
                  <div class="d-flex" style="gap: 1rem">
                    <div class="d-flex flex-column">
                      <pl-edit
                        type="checkbox"
                        [model]="processFileFilters.processaVendas"
                        (modelChange)="processFileFilters.processaVendas = $event; checkCheckBoxValues(1)"
                        [properties]="{label: 'importadorSaftDirect.steps.processaFilePOS.importarVendas'}"></pl-edit>
                      <pl-edit
                        type="checkbox"
                        [model]="processFileFilters.processaRecibos"
                        (modelChange)="processFileFilters.processaRecibos = $event; checkCheckBoxValues(2)"
                        [properties]="{label: 'importadorSaftDirect.steps.processaFilePOS.importarRecibos'}"></pl-edit>
                    </div>
                    <div class="d-flex flex-column">
                      <pl-edit
                        type="checkbox"
                        [model]="processFileFilters.processaCompras"
                        (modelChange)="processFileFilters.processaCompras = $event; checkCheckBoxValues(3)"
                        [properties]="{label: 'importadorSaftDirect.steps.processaFilePOS.importarCompras'}"></pl-edit>
                      <pl-edit
                        type="checkbox"
                        [model]="processFileFilters.processaPagamentos"
                        (modelChange)="processFileFilters.processaPagamentos = $event; checkCheckBoxValues(4)"
                        [properties]="{label: 'importadorSaftDirect.steps.processaFilePOS.importarPagamentos'}"></pl-edit>
                    </div>
                  </div>
                </edit>
              </pl-group>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
              <pl-group>
                <label [translate]="'importadorSaftDirect.steps.processaFilePOS.user'"></label>
                <edit>
                  <pl-edit type="text" attrName="utilizador" [(model)]="processFileFilters.utilizadorPOS" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
              <pl-group>
                <label [translate]="'importadorSaftDirect.steps.processaFilePOS.password'"></label>
                <edit>
                  <pl-edit
                    type="password"
                    attrName="password"
                    [(model)]="processFileFilters.passwordPOS"
                    [properties]="{validators: {required: {value: true}}, events: {keydown: fnKeydownProcessarFile}}"></pl-edit>
                </edit>
              </pl-group>
            </div>
          </div>
        </pl-form>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      icon="fa-cog"
      caption="importadorSaftDirect.steps.processaFilePOSWinTouch.wizardCaption"
      [visible]="tipoImportacaoEscolhido === enumTiposImportacao.CentralGestPosWinTouch"
      [stepId]="enumStepIds.ProcessaFilePOSWinTouch"
      [validator]="fnValidateProcessFilePOSWinTouch">
      <div *plNavWizardStepContent>
        <pl-form [(formInstance)]="processFileCloudForm" [properties]="{validators: {required: {value: true}}}">
          <h4 [translate]="'importadorSaftDirect.steps.processaFilePOSWinTouch.text1'"></h4>
          <hr />

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4">
              <pl-group>
                <label [translate]="'importadorSaftDirect.steps.processaFilePOSWinTouch.periodo'"></label>
                <edit>
                  <entity-autocomplete
                    entity="periodos"
                    [model]="processFileFilters"
                    (selectedKeyChange)="processFileFilters.periodo = $event"
                    [properties]="{validators: {required: {value: true}}}"
                    output="key">
                  </entity-autocomplete>
                </edit>
              </pl-group>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12">
              <pl-group>
                <label [translate]="'importadorSaftDirect.steps.processaFilePOSWinTouch.valoresAImportar'"></label>
                <edit>
                  <div class="d-flex" style="gap: 1rem">
                    <div class="d-flex flex-column">
                      <pl-edit
                        type="checkbox"
                        [model]="processFileFilters.processaVendas"
                        (modelChange)="processFileFilters.processaVendas = $event; checkCheckBoxValues(1)"
                        [properties]="{label: 'importadorSaftDirect.steps.processaFilePOSWinTouch.importarVendas'}"></pl-edit>
                      <pl-edit
                        type="checkbox"
                        [model]="processFileFilters.processaRecibos"
                        (modelChange)="processFileFilters.processaRecibos = $event; checkCheckBoxValues(2)"
                        [properties]="{label: 'importadorSaftDirect.steps.processaFilePOSWinTouch.importarRecibos'}"></pl-edit>
                    </div>
                    <div class="d-flex flex-column">
                      <pl-edit
                        type="checkbox"
                        [model]="processFileFilters.processaCompras"
                        (modelChange)="processFileFilters.processaCompras = $event; checkCheckBoxValues(3)"
                        [properties]="{label: 'importadorSaftDirect.steps.processaFilePOSWinTouch.importarCompras'}"></pl-edit>
                      <pl-edit
                        type="checkbox"
                        [model]="processFileFilters.processaPagamentos"
                        (modelChange)="processFileFilters.processaPagamentos = $event; checkCheckBoxValues(4)"
                        [properties]="{label: 'importadorSaftDirect.steps.processaFilePOSWinTouch.importarPagamentos'}"></pl-edit>
                    </div>
                  </div>
                </edit>
              </pl-group>
            </div>
          </div>

          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
              <pl-group>
                <label [translate]="'importadorSaftDirect.steps.processaFilePOSWinTouch.user'"></label>
                <edit>
                  <pl-edit type="text" attrName="utilizador" [(model)]="processFileFilters.utilizadorPOSWinTouch" [properties]="{validators: {required: {value: true}}}"></pl-edit>
                </edit>
              </pl-group>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-4 col-xl-2">
              <pl-group>
                <label [translate]="'importadorSaftDirect.steps.processaFilePOSWinTouch.password'"></label>
                <edit>
                  <pl-edit
                    type="password"
                    attrName="password"
                    [(model)]="processFileFilters.passwordPOSWinTouch"
                    [properties]="{validators: {required: {value: true}}, events: {keydown: fnKeydownProcessarFile}}"></pl-edit>
                </edit>
              </pl-group>
            </div>
          </div>
        </pl-form>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-file-text-o" caption="importadorSaftDirect.steps.viewDocsToImport.wizardCaption" [stepId]="enumStepIds.DocsToImport" [validator]="fnValidateImportDocsStep">
      <div *plNavWizardStepContent>
        <h4 [translate]="'importadorSaftDirect.steps.viewDocsToImport.title'"></h4>
        <p [translate]="'importadorSaftDirect.steps.viewDocsToImport.helpText'"></p>
        <hr />

        <div *ngIf="typeHasDocsData === enumTypesHasDocsData.LoadingData">
          <div class="uploadProcessarFicheiro-container">
            <div class="uploadProcessarFicheiroLabel" [translate]="'importadorSaftDirect.steps.processaFileCloud.processarNaCentralGestCloud'"></div>
            <div class="progress">
              <div
                class="progress-bar bg-info progress-bar-striped progress-bar-animated"
                role="progressbar"
                [attr.aria-valuenow]="pbProcessPos"
                [attr.aria-valuemin]="0"
                [attr.aria-valuemax]="100"
                [style.width.%]="pbProcessPercentage"></div>
            </div>

            <div class="procLabel" [translate]="pbProcessLabel"></div>
            <pl-button klass="btn-danger action-cancel" [onClick]="cancelImportProcess"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
          </div>
        </div>

        <div *ngIf="typeHasDocsData === enumTypesHasDocsData.NoDocsData">
          <h6 [translate]="'importadorSaftDirect.steps.common.semDados'"></h6>
        </div>

        <pl-tabs [theme]="'style3'" [(activeId)]="activeTabDocsToImport" *ngIf="typeHasDocsData === enumTypesHasDocsData.HasDocsData">
          <pl-tab [id]="tabsId.TabDocs" caption="importadorSaftDirect.steps.viewDocsToImport.tabDocsTitle" *ngIf="wizSteps.importDocs.showTabDocs">
            <div *plTabContent>
              <div class="row">
                <div class="col-md-12">
                  <cg-table-legend [legend]="importadorSaftDirectLegend"></cg-table-legend>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <dx-data-grid
                    [cgDxDataGrid]="dataGridDefinitionDocs"
                    cgDxDataGridInstanceName="importadorSaftDirectViewDocsToImport"
                    [dataSource]="dataGridDefinitionDocs.dataSource"
                    (onInitialized)="onInitialized($event)"
                    (onCellPrepared)="onCellPrepared($event)">
                    <div *dxTemplate="let item of 'templateToolbarBefore'">
                      <pl-edit
                        type="boolean"
                        [model]="wizSteps.importDocs.showDocsWithErrorOrWarningOnly"
                        (modelChange)="onShowDocsWithErrorOrWarningOnlyChange($event)"
                        [properties]="{label: 'importadorSaftDirect.steps.viewDocsToImport.showDocsWithErrorOrWarningOnly'}">
                      </pl-edit>
                    </div>

                    <div *dxTemplate="let item of 'cellTemplateIcon'">
                      <div *ngIf="item.data.tipoInfo !== typeImportadorSaftDirectTipoInfo.None">
                        <i class="fa fa-fw fa-exclamation-triangle" *ngIf="item.data.tipoInfo === typeImportadorSaftDirectTipoInfo.Aviso" aria-hidden="true"></i>
                        <i class="fa fa-fw fa-times-circle" *ngIf="item.data.tipoInfo === typeImportadorSaftDirectTipoInfo.Erro" aria-hidden="true"></i>
                      </div>
                    </div>

                    <div *dxTemplate="let item of 'detailTemplateMessage'">
                      <div *ngIf="item.data.info.length">
                        <pl-alert [type]="item.data.tipoInfo === typeImportadorSaftDirectTipoInfo.Erro ? 'error' : 'warning'" [closeable]="false" theme="docFaturacaoDoc-alert">
                          <i
                            class="fa"
                            [ngClass]="{
                              'fa-exclamation-triangle': item.data.tipoInfo !== typeImportadorSaftDirectTipoInfo.Erro,
                              'fa-times-circle': item.data.tipoInfo === typeImportadorSaftDirectTipoInfo.Erro
                            }"
                            aria-hidden="true"></i>
                          &nbsp;<span>{{ item.data.info }}</span>
                        </pl-alert>
                      </div>
                    </div>
                  </dx-data-grid>
                </div>

                <div class="col-md-3">
                  <div class="card">
                    <div class="card-header">
                      <h6 class="card-title" [translate]="'importadorSaftDirect.steps.viewDocsToImport.valoresNoFicheiroSAFT'"></h6>
                    </div>
                    <div class="card-body entity-detail-form">
                      <pl-form>
                        <pl-group>
                          <label [translate]="'importadorSaftDirect.steps.viewDocsToImport.nTotalFaturas'"></label>
                          <edit>
                            <div class="form-control-align">{{ wizSteps.importDocs.summaryDocs.totalDocs }}</div>
                          </edit>
                        </pl-group>
                        <pl-group>
                          <label [translate]="'importadorSaftDirect.steps.viewDocsToImport.totalBaseTributavel'"></label>
                          <edit>
                            <div class="form-control-align">{{ wizSteps.importDocs.summaryDocs.totalBase | cgcCurrency }}</div>
                          </edit>
                        </pl-group>
                        <pl-group>
                          <label [translate]="'importadorSaftDirect.steps.viewDocsToImport.totalIva'"></label>
                          <edit>
                            <div class="form-control-align">{{ wizSteps.importDocs.summaryDocs.totalIva | cgcCurrency }}</div>
                          </edit>
                        </pl-group>
                        <pl-group>
                          <label [translate]="'global.text.total'"></label>
                          <edit>
                            <div class="form-control-align">{{ wizSteps.importDocs.summaryDocs.total | cgcCurrency }}</div>
                          </edit>
                        </pl-group>
                      </pl-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </pl-tab>
          <pl-tab [id]="tabsId.TabPayments" caption="importadorSaftDirect.steps.viewDocsToImport.tabPaymentsTitle" *ngIf="wizSteps.importDocs.showTabPayments">
            <div *plTabContent>
              <div class="row">
                <div class="col-md-12">
                  <cg-table-legend [legend]="importadorSaftDirectLegend"></cg-table-legend>
                </div>
              </div>

              <div class="row">
                <div class="col-md-9">
                  <dx-data-grid
                    [cgDxDataGrid]="dataGridDefinitionPayments"
                    cgDxDataGridInstanceName="importadorSaftDirectTabPaymentsTitle"
                    [dataSource]="dataGridDefinitionPayments.dataSource"
                    (onCellPrepared)="onCellPrepared($event)">
                    <div *dxTemplate="let item of 'templateToolbarBefore'">
                      <pl-edit
                        type="boolean"
                        [model]="wizSteps.importDocs.showRecibosWithErrorOrWarningOnly"
                        (modelChange)="onShowRecibosWithErrorOrWarningOnlyChange($event)"
                        [properties]="{label: 'importadorSaftDirect.steps.viewRecibosToImport.showRecibosWithErrorOrWarningOnly'}">
                      </pl-edit>
                    </div>

                    <div *dxTemplate="let item of 'cellTemplateIcon'">
                      <div *ngIf="item.data.tipoInfo !== typeImportadorSaftDirectTipoInfo.None">
                        <i class="fa fa-fw fa-exclamation-triangle" *ngIf="item.data.tipoInfo === typeImportadorSaftDirectTipoInfo.Aviso" aria-hidden="true"></i>
                        <i class="fa fa-fw fa-times-circle" *ngIf="item.data.tipoInfo === typeImportadorSaftDirectTipoInfo.Erro" aria-hidden="true"></i>
                      </div>
                    </div>

                    <div *dxTemplate="let item of 'detailTemplateMessage'">
                      <div *ngIf="item.data.info.length">
                        <pl-alert [type]="item.data.tipoInfo === typeImportadorSaftDirectTipoInfo.Erro ? 'error' : 'warning'" [closeable]="false" theme="docFaturacaoDoc-alert">
                          <i
                            class="fa"
                            [ngClass]="{
                              'fa-exclamation-triangle': item.data.tipoInfo !== typeImportadorSaftDirectTipoInfo.Erro,
                              'fa-times-circle': item.data.tipoInfo === typeImportadorSaftDirectTipoInfo.Erro
                            }"
                            aria-hidden="true"></i>
                          &nbsp;<span>{{ item.data.info }}</span>
                        </pl-alert>
                      </div>
                    </div>
                  </dx-data-grid>
                </div>

                <div class="col-md-3">
                  <div class="card">
                    <div class="card-header">
                      <h6 class="card-title" [translate]="'importadorSaftDirect.steps.viewDocsToImport.valoresNoFicheiroSAFT'"></h6>
                    </div>
                    <div class="card-body entity-detail-form">
                      <pl-form>
                        <pl-group>
                          <label [translate]="'importadorSaftDirect.steps.viewRecibosToImport.nTotalRecibos'"></label>
                          <edit>
                            <div class="form-control-align">{{ wizSteps.importDocs.summaryRecibos.totalPayments }}</div>
                          </edit>
                        </pl-group>
                        <pl-group>
                          <label [translate]="'importadorSaftDirect.steps.viewRecibosToImport.totalCredito'"></label>
                          <edit>
                            <div class="form-control-align">{{ wizSteps.importDocs.summaryRecibos.totalCredit | cgcCurrency }}</div>
                          </edit>
                        </pl-group>
                        <pl-group>
                          <label [translate]="'importadorSaftDirect.steps.viewRecibosToImport.totalDebito'"></label>
                          <edit>
                            <div class="form-control-align">{{ wizSteps.importDocs.summaryRecibos.totalDebit | cgcCurrency }}</div>
                          </edit>
                        </pl-group>
                        <pl-group>
                          <label [translate]="'global.text.total'"></label>
                          <edit>
                            <div class="form-control-align">{{ wizSteps.importDocs.summaryRecibos.total | cgcCurrency }}</div>
                          </edit>
                        </pl-group>
                      </pl-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </pl-tab>
        </pl-tabs>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-cog" caption="importadorSaftDirect.steps.cfgTiposDocs.wizardCaption" [stepId]="enumStepIds.TiposDocs">
      <div *plNavWizardStepContent>
        <h4 [translate]="'importadorSaftDirect.steps.cfgTiposDocs.title'"></h4>
        <hr />
        <div class="row">
          <div class="col-md-12 configTiposDocs-topbar">
            <pl-edit
              type="boolean"
              [model]="wizSteps.configTiposDocs.criaDiariosEDescritivos"
              (modelChange)="changedCriaDiariosDescritivos($event)"
              [properties]="{label: 'importadorSaftDirect.steps.cfgTiposDocs.criaDiariosEDescritivos'}">
            </pl-edit>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <dx-data-grid
              [cgDxDataGrid]="wizSteps.configTiposDocs.datagridDefinition"
              cgDxDataGridInstanceName="importadorSaftDirectCfgTiposDocs"
              [dataSource]="wizSteps.configTiposDocs.dataSource"
              (onInitialized)="onInitializedTipoDocs($event)"
              (onCellPrepared)="onCellPreparedTipoDocs($event)"
              (onEditorPreparing)="onEditorPreparingTipoDocs($event)"
              (onContentReady)="configMascaraAnaliticaUpdateColumns()"
              (onSaved)="onSavedTipoDocs($event)">
              <div *dxTemplate="let item of 'cellTemplateActions'">
                <pl-tooltip [config]="{text: 'global.btn.edit', placement: 'left', container: 'body'}">
                  <button type="button" *plTooltipContent class="btn btn-success btn-sm btn-edit-row" (click)="editConfigTiposDocsRow(item.data)">
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                </pl-tooltip>
                <pl-tooltip *ngIf="!item.data._isValidRow" [config]="{text: item._errorMsg, placement: 'left', container: 'body'}">
                  <button type="button" *plTooltipContent [title]="item.data._errorMsg" class="btn btn-link btn-sm btn-error">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </button>
                </pl-tooltip>
              </div>
              <div *dxTemplate="let item of 'nDiarioTemplate'">
                <entity-autocomplete entity="diarios" [(selectedKey)]="item.data.nDiario" output="key"></entity-autocomplete>
              </div>
              <div *dxTemplate="let item of 'descritivoTemplate'">
                <entity-autocomplete entity="descritivos" [(selectedKey)]="item.data.descritivo" output="key"></entity-autocomplete>
              </div>
              <div *dxTemplate="let cell of 'cCustoTemplate'" class="nCCustoTemplate">
                <entity-autocomplete
                  entity="ccustos"
                  [model]="cell.data"
                  (modelChange)="nCCustoChanged(cell, $event)"
                  output="key"
                  [filter]="'encerrado=0'"
                  [properties]="{allowInvalid: false}"></entity-autocomplete>
              </div>
              <div *dxTemplate="let item of 'nCodRepCCTemplate'" class="nCodRepCCTemplate">
                <entity-autocomplete entity="reparticoesccustos" [(selectedKey)]="item.data.nCodRepCC" output="key" [properties]="{allowInvalid: false}"></entity-autocomplete>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      icon="fa-cogs"
      caption="importadorSaftDirect.steps.contasCorrentes.wizardCaption"
      [stepId]="enumStepIds.ConfigContasCorrentes"
      [validator]="fnValidateConfigContasCorrentesStep">
      <div *plNavWizardStepContent>
        <h4 [translate]="'importadorSaftDirect.steps.contasCorrentes.title'"></h4>
        <p [translate]="'importadorSaftDirect.steps.contasCorrentes.helpText'"></p>
        <hr />
        <pl-form (evtFieldValueChanged)="setWizStepState(wizSteps.configFormacaoContas)">
          <pl-group>
            <label [translate]="'importadorSaftDirect.steps.contasCorrentes.tipoFormacaoContaTitle'"></label>
            <edit>
              <pl-edit type="radiogroup" [(model)]="wizSteps.configFormacaoContas.tipoFormacaoConta" [properties]="{groupItems: wizSteps.configFormacaoContas.tipoFormacaoContaGroupItems}"> </pl-edit>
            </edit>
          </pl-group>
          <pl-group *ngIf="isVisible('criterioFormacao')" class="py-2">
            <label [translate]="'importadorSaftDirect.steps.contasCorrentes.criterioFormacaoTitle'"></label>
            <edit class="radiogroup-group-vertical">
              <pl-edit
                type="radiogroup"
                [model]="wizSteps.configFormacaoContas.criterioFormacao"
                (modelChange)="onCriterioFormacaoChange($event)"
                [properties]="{groupItems: wizSteps.configFormacaoContas.criterioFormacaoGroupItems}">
              </pl-edit>
            </edit>
          </pl-group>
          <ng-container *ngIf="isVisible('radicalAndDigitos')">
            <pl-group *ngIf="isVisible('radical')" class="col-md-6">
              <label [translate]="'importadorSaftDirect.steps.contasCorrentes.radicalLabel'"></label>
              <edit>
                <pl-edit type="cginteger" [(model)]="wizSteps.configFormacaoContas.radical"></pl-edit>
              </edit>
            </pl-group>
            <pl-group *ngIf="isVisible('nDigitos')" class="col-md-6">
              <label [translate]="'importadorSaftDirect.steps.contasCorrentes.nDigitosLabel'"></label>
              <edit>
                <pl-edit type="cginteger" [(model)]="wizSteps.configFormacaoContas.nDigitos" [properties]="{disabled: isNDigitosDisabled()}"></pl-edit>
              </edit>
              <actions>
                <ng-container *ngIf="isVisible('usaDigitosIdentExternaCheckbox')">
                  <pl-edit
                    type="checkbox"
                    [(model)]="wizSteps.configFormacaoContas.usaDigitosIdentExterna"
                    [properties]="{label: 'importadorSaftDirect.steps.contasCorrentes.usaDigitosIdentExterna'}"></pl-edit>
                </ng-container>
              </actions>
            </pl-group>
            <p><small [translate]="wizSteps.configFormacaoContas.nDigitosHelperText"></small></p>
          </ng-container>

          <ng-container *ngIf="isVisible('nContaDiversos')">
            <pl-group class="col-md-6">
              <label [translate]="'importadorSaftDirect.steps.contasCorrentes.nContaDiversos'"></label>
              <edit>
                <entity-autocomplete
                  entity="clifos"
                  [model]="wizSteps.configFormacaoContas"
                  [(selectedKey)]="wizSteps.configFormacaoContas.nContaDiversos"
                  [fieldsMap]="{nConta: 'nContaDiversos'}"
                  output="nConta"></entity-autocomplete>
              </edit>
            </pl-group>
          </ng-container>
        </pl-form>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step
      icon="fa-link"
      caption="importadorSaftDirect.steps.correspContasCorrentes.wizardCaption"
      [stepId]="enumStepIds.CorrespContasCorrentes"
      [validator]="fnValidateCorrespContasCorrentesStep">
      <div *plNavWizardStepContent>
        <h4 [translate]="'importadorSaftDirect.steps.correspContasCorrentes.title'"></h4>
        <p [translate]="'importadorSaftDirect.steps.correspContasCorrentes.helpText'"></p>
        <hr />
        <div class="row">
          <ng-container *ngIf="!wizSteps.correspContasCorrentes.showPb">
            <div class="col-md-12">
              <dx-data-grid
                [cgDxDataGrid]="wizSteps.correspContasCorrentes.datagridDefinition"
                cgDxDataGridInstanceName="importadorSaftDirectCorrespContasCorrentes"
                [dataSource]="wizSteps.correspContasCorrentes.dataSource"
                (onInitialized)="onInitializedCorrespContasCorrentes($event)"
                (onContentReady)="configMascaraAnaliticaUpdateColumns()"
                (onCellClick)="onCorrespContasCorrentesCellClicked($event)">
                <div *dxTemplate="let item of 'cellTemplateActions'">
                  <pl-tooltip [config]="{text: 'global.btn.edit', placement: 'left', container: 'body'}">
                    <button type="button" *plTooltipContent class="btn btn-success btn-sm btn-edit-row" (click)="editCorrespContasCorrentesRow(item.data)">
                      <i class="fa fa-pencil" aria-hidden="true"></i>
                    </button>
                  </pl-tooltip>
                  <pl-tooltip *ngIf="!item.data._isValidRow" [config]="{text: item._errorMsg, placement: 'left', container: 'body'}">
                    <button type="button" *plTooltipContent [title]="item.data._errorMsg" class="btn btn-link btn-sm btn-error">
                      <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                    </button>
                  </pl-tooltip>
                </div>
                <div *dxTemplate="let item of 'templateToolbar'">
                  <ng-container>
                    <div class="importador-saft-direct-btn-group align-items-center">
                      <div ngbDropdown>
                        <button type="button" class="btn btn-info btn-sm" ngbDropdownToggle>
                          <i class="fa fa-check-square-o fw"></i>&nbsp;<span class="caret" [translate]="'importadorSaftDirect.steps.correspContasCorrentes.selecionados'"></span>
                        </button>

                        <div ngbDropdownMenu>
                          <button type="button" (click)="associarContaCorrenteClick()" ngbDropdownItem>
                            <i class="fa fa-link"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.correspContasCorrentes.associarContaCorrente'"></span>
                          </button>
                          <button type="button" (click)="removerAssociarContaCorrenteClick()" ngbDropdownItem>
                            <i class="fa fa-chain-broken"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.correspContasCorrentes.removerAssociarContaCorrente'"></span>
                          </button>

                          <ng-container
                            *ngIf="wizSteps.configTiposDocs?.configMascaraAnalitica.usaAnalitica && wizSteps.configTiposDocs.configMascaraAnalitica.tipoAnaliticaIS === tipoAnaliticaISType.PorCliente">
                            <button type="button" (click)="contaCorrenteAtribuirCCustoClick()" ngbDropdownItem>
                              <i class="fa fa-link"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.correspContasCorrentes.atribuirCCusto'"></span>
                            </button>
                            <button type="button" (click)="contaCorrenteRemoverCCustoClick()" ngbDropdownItem>
                              <i class="fa fa-chain-broken"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.correspContasCorrentes.removerCCusto'"></span>
                            </button>
                          </ng-container>

                          <ng-container
                            *ngIf="
                              wizSteps.configTiposDocs?.configMascaraAnalitica.usaAnalitica &&
                              wizSteps.configTiposDocs.configMascaraAnalitica.tipoAnaliticaIS === tipoAnaliticaISType.PorClienteReparticao
                            ">
                            <button type="button" (click)="contaCorrenteAtribuirRepCCClick()" ngbDropdownItem>
                              <i class="fa fa-link"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.correspContasCorrentes.atribuirRepCC'"></span>
                            </button>
                            <button type="button" (click)="contaCorrenteRemoverRepCCClick()" ngbDropdownItem>
                              <i class="fa fa-chain-broken"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.correspContasCorrentes.removerRepCC'"></span>
                            </button>
                          </ng-container>
                        </div>
                      </div>

                      <pl-edit
                        type="boolean"
                        [model]="wizSteps.correspContasCorrentes.mostrarContasComErro"
                        (modelChange)="changedMostrarContasComErro($event)"
                        [properties]="{label: 'importadorSaftDirect.steps.correspContasCorrentes.mostrarContasComErro'}">
                      </pl-edit>
                      <pl-edit
                        type="boolean"
                        [model]="wizSteps.correspContasCorrentes.mostrarNIFInvalid"
                        (modelChange)="changedMostrarNIFInvalid($event)"
                        [properties]="{label: 'importadorSaftDirect.steps.correspContasCorrentes.mostrarNIFInvalid'}">
                      </pl-edit>
                    </div>
                  </ng-container>
                </div>
              </dx-data-grid>
            </div>
          </ng-container>

          <div class="col-md-12" *ngIf="wizSteps.correspContasCorrentes.showPb">
            <div class="step-progress-bar-container">
              <div class="step-progress-bar-label" [translate]="wizSteps.correspContasCorrentes.pbText"></div>
              <div class="progress">
                <div
                  class="progress-bar bg-info progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  [attr.aria-valuenow]="pbProcessPos"
                  [attr.aria-valuemin]="0"
                  [attr.aria-valuemax]="100"
                  [style.width.%]="pbProcessPercentage"></div>
              </div>

              <div class="procLabel" [translate]="pbProcessLabel"></div>
              <pl-button klass="btn-danger action-cancel" [onClick]="cancelImportProcess"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
            </div>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-link" caption="importadorSaftDirect.steps.correspTaxasIva.wizardCaption" [stepId]="enumStepIds.CorrespTaxasIva" [validator]="fnValidateCorrespTaxasIvaStep">
      <div *plNavWizardStepContent>
        <h4 [translate]="'importadorSaftDirect.steps.correspTaxasIva.title'"></h4>
        <p [translate]="'importadorSaftDirect.steps.correspTaxasIva.helpText'"></p>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <dx-data-grid
              [cgDxDataGrid]="wizSteps.correspTaxasIva.datagridDefinition"
              cgDxDataGridInstanceName="importadorSaftDirectTaxasIva"
              [dataSource]="wizSteps.correspTaxasIva.dataSource"
              (onInitialized)="onInitializedCorrespTaxasIva($event)"
              (onCellPrepared)="onCellPreparedCorrespTaxasIva($event)"
              (onSaved)="onSavedCorrespTaxasIva($event)">
              <div *dxTemplate="let item of 'cellTemplateActions'">
                <pl-tooltip [config]="{text: 'global.btn.edit', placement: 'left', container: 'body'}">
                  <button type="button" *plTooltipContent class="btn btn-success btn-sm btn-edit-row" (click)="editTaxasIvaRow(item.data)">
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                </pl-tooltip>
                <pl-tooltip *ngIf="!item.data._isValidRow" [config]="{text: item._errorMsg, placement: 'left', container: 'body'}">
                  <button type="button" *plTooltipContent [title]="item.data._errorMsg" class="btn btn-link btn-sm btn-error">
                    <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                  </button>
                </pl-tooltip>
              </div>

              <div *dxTemplate="let cell of 'codIvaTemplate'">
                <entity-autocomplete
                  entity="ivas"
                  attrName="codIva"
                  [model]="cell.data"
                  (modelChange)="onIvaChanged(cell, $event)"
                  [filter]="getTaxPercentage(cell)"
                  [properties]="{allowInvalid: false}"
                  output="key">
                </entity-autocomplete>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-cog" caption="importadorSaftDirect.steps.cfgArtigos.wizardCaption" [stepId]="enumStepIds.Artigos" [validator]="fnValidateConfigArtigosStep">
      <div *plNavWizardStepContent>
        <h4 [translate]="'importadorSaftDirect.steps.cfgArtigos.title'"></h4>
        <p [translate]="'importadorSaftDirect.steps.cfgArtigos.helpText'"></p>
        <hr />
        <div class="col-md-12">
          <dx-data-grid
            [cgDxDataGrid]="wizSteps.configArtigos.datagridDefinition"
            cgDxDataGridInstanceName="importadorSaftDirectCfgArtigos"
            [dataSource]="wizSteps.configArtigos.dataSource"
            (onContentReady)="configMascaraAnaliticaUpdateColumns()"
            (onInitialized)="onInitializedCfgArtigos($event)">
            <div *dxTemplate="let item of 'templateToolbar'">
              <div class="importador-saft-direct-btn-group align-items-center" *ngIf="!wizSteps.configArtigos.showPb">
                <pl-button klass="btn btn-sm btn-light" (evtClicked)="configArtigosSelectNewsClick()">
                  <i class="fa fa-check-square-o"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.cfgArtigos.selectNews'"></span>
                </pl-button>
                <div ngbDropdown>
                  <div class="btn-group">
                    <button type="button" class="btn btn-sm btn-light" id="importador-saft-direct-config-artigos-config-sel-drop" (click)="showConfigArtigosConfigSelected()">
                      <i class="fa fa-cog"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.cfgArtigos.configArtigosSelected'"></span>
                    </button>
                    <button type="button" class="btn btn-sm btn-light dropdown-toggle dropdown-toggle-split" ngbDropdownToggle>
                      <span class="caret"></span>
                    </button>
                  </div>
                  <div class="dropdown-menu" role="menu" [attr.aria-labelledby]="'importador-saft-direct-config-artigos-config-sel-drop'" ngbDropdownMenu>
                    <button type="button" class="dropdown-item" (click)="showConfigArtigosModal([], tipoDeProduto.Produtos)">
                      <i class="fa fa-cog"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.cfgArtigos.configTodosArtigosTipoProduto'"></span>
                    </button>
                    <button type="button" class="dropdown-item" (click)="showConfigArtigosModal([], tipoDeProduto.Servicos)">
                      <i class="fa fa-cog"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.cfgArtigos.configTodosArtigosTipoServicos'"></span>
                    </button>
                    <button type="button" class="dropdown-item" (click)="showConfigArtigosModal([], tipoDeProduto.Outros)">
                      <i class="fa fa-cog"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.cfgArtigos.configTodosArtigosTipoOutros'"></span>
                    </button>
                    <button type="button" class="dropdown-item" (click)="showConfigArtigosModal([], tipoDeProduto.ImpostoEspeciais)">
                      <i class="fa fa-cog"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.cfgArtigos.configTodosArtigosTipoImpostosEspeciaisConsumo'"></span>
                    </button>
                    <button type="button" class="dropdown-item" (click)="showConfigArtigosModal([], tipoDeProduto.ImpostosParaFiscais)">
                      <i class="fa fa-cog"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.cfgArtigos.configTodosArtigosTipoImpostosTaxasEncargosParafiscais'"></span>
                    </button>
                  </div>
                </div>
                <pl-button klass="btn-sm btn-light" (evtClicked)="configArtigosRefreshClick()">
                  <i class="fa fa-refresh"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.cfgArtigos.toolbarRefresh'"></span>
                </pl-button>
                <pl-button klass="btn-sm btn-light" (evtClicked)="maintenanceInstancePocs.maintenanceList()">
                  <span [translate]="'importadorSaftDirect.steps.cfgArtigos.toolbarPlanoContas'"></span>
                </pl-button>
                <pl-edit
                  type="boolean"
                  [model]="wizSteps.configArtigos.mostrarApenasComErro"
                  (modelChange)="changedConfigArtigosMostrarApenasComErro($event)"
                  [properties]="{label: 'importadorSaftDirect.steps.cfgArtigos.toolbarMostrarApenasComErro'}">
                </pl-edit>
              </div>
            </div>
            <div *dxTemplate="let item of 'cellTemplateActions'">
              <pl-tooltip [config]="{text: 'importadorSaftDirect.strings.verContasFormadasPeloArtigo', placement: 'left', container: 'body'}">
                <button type="button" *plTooltipContent class="btn btn-light btn-sm btn-edit-row" (click)="showContaFormada(item.data)">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </button>
              </pl-tooltip>
              <pl-tooltip [config]="{text: 'importadorSaftDirect.strings.modalTitleEdit', placement: 'left', container: 'body'}">
                <button type="button" *plTooltipContent class="btn btn-success btn-sm btn-edit-row" (click)="editConfigArtigosRow(item.data)">
                  <i class="fa fa-pencil" aria-hidden="true"></i>
                </button>
              </pl-tooltip>
            </div>
          </dx-data-grid>
        </div>
        <div class="row">
          <div class="col-md-12" *ngIf="wizSteps.configArtigos.showPb">
            <div class="step-progress-bar-container">
              <div class="step-progress-bar-label" [translate]="'importadorSaftDirect.strings.savingProductsConfig'"></div>
              <div class="progress">
                <div
                  class="progress-bar bg-info progress-bar-striped progress-bar-animated"
                  role="progressbar"
                  [attr.aria-valuenow]="pbProcessPos"
                  [attr.aria-valuemin]="0"
                  [attr.aria-valuemax]="100"
                  [style.width.%]="pbProcessPercentage"></div>
              </div>

              <div class="procLabel" [translate]="pbProcessLabel"></div>
              <pl-button klass="btn-danger action-cancel" [onClick]="cancelImportProcess"><i class="fa fa-fw fa-times"></i>&nbsp;<span [translate]="'global.btn.cancel'"></span></pl-button>
            </div>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-cog" caption="importadorSaftDirect.steps.dataDocs.wizardCaption" [stepId]="enumStepIds.DataDocs" [validator]="fnValidateDataDocsStep">
      <div *plNavWizardStepContent>
        <h4 [translate]="'importadorSaftDirect.steps.dataDocs.title'"></h4>
        <hr />
        <div class="row">
          <div class="col-md-12">
            <pl-group>
              <edit>
                <label [translate]="'importadorSaftDirect.steps.dataDocs.dataLancContabTitle'"></label>
                <pl-edit
                  type="radiogroup"
                  [model]="wizSteps.datasDocumentos.dataLancContab"
                  (modelChange)="changedDataLancContab($event)"
                  [properties]="{groupItems: wizSteps.datasDocumentos.dataLancContabGroupItems}">
                </pl-edit>
              </edit>
            </pl-group>
          </div>
        </div>
        <div class="row" *ngIf="isVisible('dataLancContabTableRow')">
          <div class="col-md-12">
            <dx-data-grid
              [cgDxDataGrid]="wizSteps.datasDocumentos.datagridDefinition"
              cgDxDataGridInstanceName="importadorSaftDirectDataDocs"
              [dataSource]="wizSteps.datasDocumentos.dataSource"
              (onInitialized)="onInitializedDataDocs($event)"
              (onCellClick)="onDataDocsCellClicked($event)">
              <div *dxTemplate="let item of 'cellTemplateActions'">
                <pl-tooltip [config]="{text: 'global.btn.edit', placement: 'left', container: 'body'}">
                  <button type="button" *plTooltipContent class="btn btn-success btn-sm btn-edit-row" (click)="editDatasDocumentosRow(item.data)">
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>
                </pl-tooltip>
              </div>
            </dx-data-grid>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>

    <pl-nav-wizard-step icon="fa-book" caption="importadorSaftDirect.steps.genDocsContab.wizardCaption" [stepId]="enumStepIds.DocsContab">
      <div *plNavWizardStepContent>
        <h4 [translate]="'importadorSaftDirect.steps.genDocsContab.title'"></h4>
        <hr />
        <div class="pbSaveDocs-container" *ngIf="wizSteps.genDocs.showPb">
          <div class="pbSaveDocs-container-label" [translate]="wizSteps.genDocs.pbMessage"></div>
          <div class="progress">
            <div
              class="progress-bar bg-warning progress-bar-striped progress-bar-animated"
              role="progressbar"
              [attr.aria-valuenow]="wizSteps.genDocs.pbValue"
              [attr.aria-valuemin]="0"
              [attr.aria-valuemax]="100"
              [style.width.%]="wizSteps.genDocs.pbValue"></div>
          </div>
        </div>
        <div class="row" *ngIf="!wizSteps.genDocs.showPb">
          <div class="col-md-9">
            <dx-data-grid
              [cgDxDataGrid]="this.wizSteps.genDocs.datagridDefinition"
              cgDxDataGridInstanceName="importadorSaftDirectGenDocs"
              [dataSource]="this.wizSteps.genDocs.dataSource"
              (onInitialized)="onInitializedGenDocs($event)"
              (onCellPrepared)="onCellPrepared($event)">
              <div *dxTemplate="let item of 'cellTemplateActionsGenDocs'">
                <pl-tooltip [config]="{text: 'importadorSaftDirect.strings.verGenDoc', placement: 'left', container: 'body'}">
                  <button type="button" *plTooltipContent class="btn btn-light btn-sm btn-edit-row" (click)="viewGenDoc(item.data)">
                    <i class="fa fa-eye" aria-hidden="true"></i>
                  </button>
                </pl-tooltip>
              </div>
              <ng-container *itemDetail="let item">
                <div *ngIf="item.errorType !== 0">
                  <pl-alert [type]="item.errorType === 2 ? 'error' : 'warning'" [closeable]="false" theme="docFaturacaoDoc-alert">
                    <i class="fa" [ngClass]="{'fa-exclamation-triangle': item.errorType === 1, 'fa-times-circle': item.errorType === 2}" aria-hidden="true"></i>&nbsp;<span
                      [translate]="item.erro"></span>
                  </pl-alert>
                </div>
              </ng-container>
              <div *dxTemplate="let item of 'templateToolbarGenDocs'">
                <div class="col-md-12">
                  <div class="importador-saft-direct-btn-group">
                    <pl-button klass="btn-sm btn-light" (evtClicked)="genDocDeleteDocSelected()">
                      <i class="fa fa-trash-o"></i>&nbsp;<span [translate]="'importadorSaftDirect.steps.genDocsContab.deleteDocSelected'"></span>
                    </pl-button>
                    <pl-edit
                      type="boolean"
                      [model]="wizSteps.genDocs.mostrarApenasComErro"
                      (modelChange)="changedGenDocsMostrarApenasComErro($event)"
                      [properties]="{label: 'importadorSaftDirect.steps.genDocsContab.toolbarMostrarApenasComErro'}">
                    </pl-edit>
                  </div>
                </div>
              </div>
            </dx-data-grid>
            <cg-table-legend [legend]="importadorSaftDirectLegend"></cg-table-legend>
          </div>
          <div class="col-md-3">
            <div class="card" style="margin-top: 10px">
              <div class="card-header">
                <h3 class="card-title" [translate]="'importadorSaftDirect.steps.genDocsContab.valoresNoFicheiroSAFT'"></h3>
              </div>
              <div class="card-body">
                <pl-group>
                  <label [translate]="'importadorSaftDirect.fields.valorBase'"></label>
                  <edit>
                    <pl-edit type="number" [model]="wizSteps.genDocs.totalValorBase" [properties]="{disabled: true}"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'importadorSaftDirect.fields.valorIVA'"></label>
                  <edit>
                    <pl-edit type="number" [model]="wizSteps.genDocs.totalValorIVA" [properties]="{disabled: true}"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'importadorSaftDirect.fields.valor'"></label>
                  <edit>
                    <pl-edit type="number" [model]="wizSteps.genDocs.totalValor" [properties]="{disabled: true}"></pl-edit>
                  </edit>
                </pl-group>
              </div>
            </div>
            <div class="card">
              <div class="card-header">
                <h3 class="card-title" [translate]="'importadorSaftDirect.steps.genDocsContab.valoresCG'"></h3>
              </div>
              <div class="card-body">
                <pl-group>
                  <label [translate]="'importadorSaftDirect.fields.valorBaseCG'"></label>
                  <edit>
                    <pl-edit type="number" [model]="wizSteps.genDocs.totalValorBaseCG" [properties]="{disabled: true}"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'importadorSaftDirect.fields.valorIVACG'"></label>
                  <edit>
                    <pl-edit type="number" [model]="wizSteps.genDocs.totalValorIVACG" [properties]="{disabled: true}"></pl-edit>
                  </edit>
                </pl-group>
                <pl-group>
                  <label [translate]="'importadorSaftDirect.fields.valorCG'"></label>
                  <edit>
                    <pl-edit type="number" [model]="wizSteps.genDocs.totalValorCG" [properties]="{disabled: true}"></pl-edit>
                  </edit>
                </pl-group>
              </div>
            </div>
          </div>
        </div>
      </div>
    </pl-nav-wizard-step>
  </pl-nav-wizard>
</div>
