import {sortBy} from 'lodash-es';
import {Subscription} from 'rxjs';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import {IPlToolbarInstance, IPlToolbarItemDefinition, IPlToolbarItemTemplateContext, isFunction, PlDocumentService, PlToolbarService, PlTranslateService} from 'pl-comps-angular';
import {ConfigOptionsService} from '../../services/config/options/config.options.service';
import {EConfigOptionsInstanceName, IConfigOption, IConfigOptionBoolean, IConfigOptions, TConfigOptions} from '../../services/config/options/config.options.service.interface';
import {EGroupName} from '../../../config/constants';
import {IConfigChanged, TConfigOptionsEvaluateInvisibleFn, TConfigOptionsKey, TConfigOptionsKeys} from './config.options.component.interface';
import {IConfigOptionsInstance} from '../../services/config/options/config.options.instance.interface';

const BTN_PREFIX = 'btnConfigOptions';

@Component({
  selector: 'config-options',
  templateUrl: './config.options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigOptionsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public instanceName: EConfigOptionsInstanceName;
  @Input() public groupName: EGroupName;
  @Input() public disabled: boolean;
  @Input() public toolbarInstanceName: string;
  @Input() public toolbarButton: Partial<IPlToolbarItemDefinition>;
  @Input() public hideButton: boolean;
  @Input() public hideRevertToDefault: boolean;
  @Input() public sortKeys: boolean;
  @Input() public evaluateInvisibleFn: TConfigOptionsEvaluateInvisibleFn<boolean, IConfigOptions<boolean>>;
  @Output() public readonly collapsedChange: EventEmitter<boolean>;
  @Output() public readonly configChange: EventEmitter<IConfigChanged>;
  @Output() public readonly revertOptionsToDefault: EventEmitter<void>;

  public optionsKeys: TConfigOptionsKeys;
  public options: TConfigOptions<boolean>;
  public btnOptions: IPlToolbarItemDefinition;
  public isMobile: boolean;

  private readonly _subscriptionIsMobile: Subscription;
  private _configOptions: IConfigOptionsInstance<boolean>;
  private _configOptionsFirstTime: boolean;
  private _toolbarInstance: IPlToolbarInstance;
  private _toolbarOwned: boolean;
  private _addedBtnOptions: boolean;
  private _subscriptionOptions: Subscription;
  private _templateRefOptions: TemplateRef<IPlToolbarItemTemplateContext>;

  constructor(
    private readonly _plDocumentService: PlDocumentService,
    private readonly _plTranslateService: PlTranslateService,
    private readonly _plToolbarService: PlToolbarService,
    private readonly _configOptionsService: ConfigOptionsService
  ) {
    this.collapsedChange = new EventEmitter<boolean>();
    this.configChange = new EventEmitter<IConfigChanged>();
    this.revertOptionsToDefault = new EventEmitter<void>();
    this.disabled = false;
    this.optionsKeys = [];
    this._configOptionsFirstTime = true;
    this._toolbarOwned = false;
    this._addedBtnOptions = false;
    this._subscriptionIsMobile = this._plDocumentService.isMobile().subscribe((isMobile: boolean) => {
      this.isMobile = isMobile;
    });
  }

  public ngOnInit(): void {
    this._evaluateConfigOptions();
    this._changedToolbarInstanceName();
    this._changedHideButton();
    if (!this.hideButton) {
      this._changedToolbarButton();
      this._addBtnOptions();
    }
  }

  public ngOnChanges({instanceName, groupName, toolbarInstanceName, toolbarButton, hideButton}: SimpleChanges): void {
    const changedInstanceName: boolean = instanceName && !instanceName.isFirstChange();
    const changedGroupName: boolean = groupName && !groupName.isFirstChange();
    const changedToolbarInstanceName: boolean = toolbarInstanceName && !toolbarInstanceName.isFirstChange();
    const changedToolbarButton: boolean = toolbarButton && !toolbarButton.isFirstChange();
    const changedHideButton: boolean = hideButton && !hideButton.isFirstChange();

    if (this._addedBtnOptions && (changedInstanceName || changedToolbarInstanceName || changedToolbarButton || (changedHideButton && this.hideButton))) {
      this._removeBtnOptions();
    }

    if (changedInstanceName || changedGroupName) {
      this._configOptionsFirstTime = true;
      this._evaluateConfigOptions();
    }

    if (changedToolbarInstanceName || changedToolbarButton || changedHideButton) {
      if (changedToolbarInstanceName) {
        this._changedToolbarInstanceName(toolbarInstanceName.currentValue);
      }
      if (changedToolbarButton) {
        this._changedToolbarButton(toolbarButton.currentValue);
      }
      if (changedHideButton) {
        this._changedHideButton(hideButton.currentValue);
      }

      if (!this._addedBtnOptions && !this.hideButton) {
        this._addBtnOptions();
      }
    }
  }

  public ngOnDestroy(): void {
    this._subscriptionIsMobile.unsubscribe();
    if (this._subscriptionOptions) {
      this._subscriptionOptions.unsubscribe();
    }
    this._removeBtnOptions();
    this._unRegisterToolbarInstance();
  }

  public async revertToDefault(): Promise<void> {
    await this._configOptions.setDefaultOptions();
    this.revertOptionsToDefault.emit();
  }

  public async changedConfig(configOption: string | number, value: boolean): Promise<void> {
    if (this.disabled) {
      return;
    }
    await this._configOptions.setOption(configOption, value);
    this.configChange.emit({
      configOption: configOption,
      value: value
    });
  }

  public dropdownOpenChanged(dropdown: NgbDropdown, open: boolean): void {
    if (open && (this.disabled || this.btnOptions?.disabled)) {
      dropdown.close();
    }
  }

  @ViewChild('templateOptions', {read: TemplateRef})
  public set templateOptions(value: TemplateRef<IPlToolbarItemTemplateContext>) {
    this._templateRefOptions = value;
    if (this.btnOptions) {
      this.btnOptions.templateRef = this._templateRefOptions;
    }
  }

  private _evaluateConfigOptions(): void {
    const group: ReadonlyMap<unknown, IConfigOptionsInstance<boolean>> = this._configOptionsService.getGroupOptions(this.groupName);
    this._configOptions = group.get(this.instanceName);
    if (this._subscriptionOptions) {
      this._subscriptionOptions.unsubscribe();
    }
    this._subscriptionOptions = this._configOptions?.options().subscribe((configOptions: TConfigOptions<boolean>) => {
      this.options = configOptions;
      if (this._configOptionsFirstTime) {
        this._configOptionsFirstTime = false;
        const invisibleOptions: Array<string | number> = [];
        if (isFunction(this.evaluateInvisibleFn)) {
          for (const [optionName, option] of this.options) {
            const invisible: undefined | boolean = this.evaluateInvisibleFn({
              key: optionName,
              configOption: option
            });
            if (invisible === true) {
              invisibleOptions.push(optionName);
            }
          }
        }
        this.optionsKeys = Array.from(this.options.keys())
          .filter((key: keyof IConfigOptionBoolean) => {
            const option: IConfigOption<boolean> = this.options.get(key);
            return !option.invisible && !invisibleOptions.includes(key);
          })
          .map<TConfigOptionsKey>((key: keyof IConfigOptionBoolean) => {
            return [key, this._plTranslateService.translate(this.options.get(key).caption)];
          });
        if (this.sortKeys !== false) {
          this.optionsKeys = sortBy<TConfigOptionsKey>(this.optionsKeys, (optionsKey: TConfigOptionsKey) => optionsKey[1]);
        }
      }
    });
  }

  private _changedToolbarInstanceName(value: string = this.toolbarInstanceName): void {
    if (this._toolbarInstance) {
      this._unRegisterToolbarInstance();
    }
    this.toolbarInstanceName = value || undefined;
    this._evaluateToolbarInstance();
  }

  private _changedToolbarButton(value: Partial<IPlToolbarItemDefinition> = this.toolbarButton): void {
    this.btnOptions = {
      id: this.instanceName ? `${BTN_PREFIX}_${this.instanceName}` : BTN_PREFIX,
      type: 'custom',
      order: 500,
      align: 'right',
      templateRef: this._templateRefOptions,
      ...value
    };
  }

  private _changedHideButton(value: boolean = this.hideButton): void {
    this.hideButton = value === true;
  }

  private _addBtnOptions(): void {
    if (this.btnOptions && this._toolbarInstance) {
      this._toolbarInstance.addButton(this.btnOptions);
      this._addedBtnOptions = true;
    }
  }

  private _removeBtnOptions(): void {
    if (this.btnOptions && this._toolbarInstance) {
      this._toolbarInstance.removeButton(this.btnOptions);
      this._addedBtnOptions = false;
    }
  }

  private _evaluateToolbarInstance(): void {
    if (this._toolbarInstance) {
      this._toolbarInstance = undefined;
    }
    if (!this.toolbarInstanceName) {
      return;
    }
    this._toolbarOwned = !this._plToolbarService.isRegistered(this.toolbarInstanceName);
    this._toolbarInstance = this._plToolbarService.getInstance(this.toolbarInstanceName);
  }

  private _unRegisterToolbarInstance(): void {
    if (this._toolbarInstance && this._toolbarOwned) {
      this._plToolbarService.unRegisterInstance(this._toolbarInstance);
    }
    this._toolbarInstance = undefined;
    this._toolbarOwned = false;
  }
}
