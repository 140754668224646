/**
 * CentralGest Amalia
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MessageBodyIntent { 
    /**
     * The message type
     */
    type: MessageBodyIntent.TypeEnum;
    /**
     * The message
     */
    message: string;
    /**
     * The detected intent
     */
    intent: string;
    /**
     * The parameters of message
     */
    parameters?: object;
}
export namespace MessageBodyIntent {
    export type TypeEnum = 'TEXT' | 'FAQ' | 'INTENT' | 'INTENT_DETECT' | 'UNKNOWN';
    export const TypeEnum = {
        Text: 'TEXT' as TypeEnum,
        Faq: 'FAQ' as TypeEnum,
        Intent: 'INTENT' as TypeEnum,
        IntentDetect: 'INTENT_DETECT' as TypeEnum,
        Unknown: 'UNKNOWN' as TypeEnum
    };
}


