<div class="docfas-list">
  <entity-list
    [entityName]="entityName"
    [maintenanceMode]="maintenanceMode"
    [dataGridState]="dataGridState"
    [searchValue]="searchValue"
    [filter]="filterValue"
    [initialFilter]="initialFilterValue"
    [filterCollapsed]="true"
    [toolbarInstanceId]="instanceName"
    [properties]="listOptions"
    (evtOnRefresh)="onRefreshList()"
    (evtDataGridPreparing)="onDataGridPreparing($event)"
    (evtDataGridCellClick)="onDataGridCellClick($event)"
    (evtDataGridCellDblClick)="onDataGridCellDblClick($event)"
    (evtDataGridContextMenuPreparing)="onDataGridContextMenuPreparing($event)"
    (evtDataGridStateChanged)="onDataGridStateChanged($event)"
    (evtDataGridStateCleared)="onDataGridStateCleared()">
    <div *entityListItemActions="let item">
      <pl-tooltip [config]="{text: 'docfas.list.actions.new'}">
        <button type="button" *plTooltipContent class="btn btn-primary btn-xs" (click)="actionNew({docFaBase: item.data})"><i class="fa fa-fw fa-plus-circle"></i></button>
      </pl-tooltip>
    </div>
  </entity-list>
</div>
