<cg-card-panel #cardPanel="cgCardPanel">
  <div *cgCardPanelContent>
    <div class="px-2 py-1">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-xl-3 mb-2">
          <pl-group>
            <label [translate]="'pca.deData'"></label>
            <edit>
              <pl-edit type="date" [(model)]="filters.dataDe"></pl-edit>
            </edit>
          </pl-group>
        </div>

        <div class="col-sm-12 col-md-6 col-xl-3 mb-2">
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <pl-edit type="date" [(model)]="filters.dataAte"></pl-edit>
            </edit>
          </pl-group>
        </div>

        <div class="col-sm-12 col-md-6 col-xl-3 mb-2">
          <pl-group>
            <label [translate]="'pca.deCodemp'"></label>
            <edit>
              <entity-autocomplete entity="dgempsfull" attrName="codEmpDe" [model]="filters" (selectedKeyChange)="filters.codEmpDe = $event" [fieldsMap]="{codEmp: 'codEmpDe'}" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </div>

        <div class="col-sm-12 col-md-6 col-xl-3 mb-2">
          <pl-group>
            <label [translate]="'global.text.to'"></label>
            <edit>
              <entity-autocomplete entity="dgempsfull" attrName="codEmpAte" [model]="filters" (selectedKeyChange)="filters.codEmpAte = $event" [properties]="{allowInvalid: true}" output="key">
              </entity-autocomplete>
            </edit>
          </pl-group>
        </div>

        <div class="col-sm-12 col-md-6 col-xl-3 mb-2">
          <pl-group>
            <edit>
              <pl-edit-switch [(model)]="filters.empShowInactive" [label]="'pca.mostraEmpInativos'"></pl-edit-switch>
            </edit>
          </pl-group>
        </div>

        <div class="col-sm-12 col-md-6 col-xl-3 align-self-end">
          <pl-group>
            <edit>
              <button type="button" class="btn btn-sm btn-primary" name="pesquisar" id="btnPesquisar" (click)="fnReloadReport()" data-focus>
                <i class="fa fa-search fa-fw"></i>&nbsp;<span [translate]="'global.btn.search'"></span>
              </button>
            </edit>
          </pl-group>
        </div>
      </div>
    </div>
  </div>
</cg-card-panel>

<div class="mb-2">
  <pl-group>
    <label [translate]="'global.text.listing'"></label>
    <edit>
      <pl-edit-select
        [properties]="{
          select: {
            list: reportSource,
            valueProp: 'name',
            labelProp: 'title'
          },
          disallowClear: true
        }"
        [model]="reportModel"
        (modelChange)="reportChanged($event)"
        plAutoFocus></pl-edit-select>
    </edit>
  </pl-group>
</div>

<cg-pdf-viewer [pdfSrc]="pdfUrl" [toolbarInstanceId]="instanceName" [hideToolbar]="true"></cg-pdf-viewer>
