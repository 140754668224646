import {AtRecibosRendasModuleComponent} from './atRecibosRendas.module.component';
import {IModuleDefinition} from '../../../components/module/module.definition.interface';
import {ROLE} from '../../../services/role.const';
import {MODULE_NAME_AT_RECIBOS_RENDAS} from './atRecibosRendas.module.interface';

export const MODULE_AT_RECIBOS_RENDAS: IModuleDefinition = {
  name: MODULE_NAME_AT_RECIBOS_RENDAS,
  state: {
    url: `/${MODULE_NAME_AT_RECIBOS_RENDAS}`,
    component: AtRecibosRendasModuleComponent,
    data: {
      roles: [ROLE.CONTABILIDADE],
      sidebarTitle: 'portals.sidebar.modules.atRecibosRendas'
    }
  }
};
