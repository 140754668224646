<div class="card entity-filter">
  <div class="card-header">
    <a class="card-title" [translate]="'global.text.filters'" (click)="filterCollapsed = !filterCollapsed"></a>
  </div>

  <pl-animation-collapse [collapsed]="filterCollapsed">
    <div class="card-body">
      <div class="row">
        <div class="col-md-3">
          <div style="margin-bottom: 15px">
            <pl-group>
              <label>Ano:</label>
              <edit style="display: inline-block; vertical-align: middle; width: 100%">
                <pl-edit-select
                  [source]="anosSource"
                  [model]="anoEmCurso"
                  (modelChange)="anosChanged($event)"
                  [properties]="{select: {rowTemplate: anosRowTemplate, outputProp: anosOutput}, disallowClear: true}"></pl-edit-select>
              </edit>
            </pl-group>
          </div>
        </div>

        <div class="col-md-9">
          <pl-filter [fields]="filterFields" [filters]="filters" (evtFiltered)="onFilter($event)"></pl-filter>
        </div>
      </div>
    </div>
  </pl-animation-collapse>
</div>

<pl-table theme="style2" [definition]="tableDefinition" [source]="fnTableSource" [properties]="tableOptions" [callback]="tableCallback" [onSelect]="fnOnSelect">
  <div *itemDetail="let item">
    <documento-contabilidade [(doc)]="item._doc" [showToolbar]="false"></documento-contabilidade>
  </div>
</pl-table>
