<div class="amortizacao">
  <entity-detail
    [entityName]="entityName"
    [(model)]="model"
    [type]="type"
    [maintenanceMode]="maintenanceMode"
    [callback]="callback"
    [serviceMethodsOverride]="serviceMethodsOverride"
    [formOrientation]="formOrientation"
    (evtUpdate)="onUpdate($event)">
    <div *entityDetailContent>
      <pl-form [model]="model" [definition]="formDefinition" [template]="formTemplate" [properties]="{readonly: type === stateTypes.DETAIL, orientation: formOrientation}"></pl-form>

      <dx-data-grid [cgDxDataGrid]="dataGrid" cgDxDataGridInstanceName="codigoDepreciacaoTable" [dataSource]="dataGrid.dataSource" (onInitialized)="onInitialized($event)">
        <div *dxTemplate="let item of 'actions'">
          <button type="button" class="btn btn-warning btn-xs action-edit-line" *ngIf="isEditing" [click]="fnEditLine(item.data)" plPromise>
            <i class="fa fa-fw fa-pencil"></i>
          </button>
          <button type="button" class="btn btn-danger btn-xs action-remove-line" *ngIf="isEditing" [click]="fnRemoveLine(item.data.dataValorID)" plPromise>
            <i class="fa fa-fw fa-minus"></i>
          </button>
        </div>
      </dx-data-grid>
    </div>
  </entity-detail>
</div>
